import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import H3 from '../../../components/H3'
import P from '../../../components/H3'
import { translations } from '../../../config'
import style from './style'

const ChartWrapper = props => {
  const { title, children, classes, className } = props

  return (
    <div className={classes.wrapper}>
      <div><H3 value={title} className={classes.title} /></div>
      <div className={`${classes.chartContainer} ${className}`}>
        {
          children ||
          <P
            value={translations('Insufficient Data')}
            className={classes.noDataText}
            type='large'
          />
        }
      </div>
    </div>
  )
}

export default withStyles(style)(ChartWrapper)
