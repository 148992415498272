import * as constants from './constants'
import * as selectors from './selectors'

import { selectors as authSelectors } from '../auth'

import digitalStoreSdk from '../../../digitalStoreSdk'

class InvitedUsersActions {
  fetchInvitedUsers = ({ query, filters } = {}) => ({
    type: constants.SEARCH_INVITED_USERS_FRESH,
    query,
    filters,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      const stateParams = selectors.getParams(state)
      const params = { ...stateParams, query, ...filters }
      return digitalStoreSdk.invitedUsers.fetchInvitedUsers({
        organisationId,
        params
      })
    }
  })
}

export default new InvitedUsersActions()
