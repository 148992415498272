import { withState, withHandlers, compose, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import { connect } from 'react-redux'

import { selectors as drugDictionarySelectors } from '../../../../store/modules/drugDictionary'

import ProductsSearchBar from './ProductsSearchBar'

const mapStateToProps = (state) => {
  const isLoading = drugDictionarySelectors.getIsLoading(state)
  return {
    isLoading
  }
}

const enhance = compose(
  connect(mapStateToProps),
  // this is exactly the text that appears in the search input
  withState('query', 'setQuery', ''),
  withPropsOnChange(
    ['search'],
    ({ search }) => ({ debouncedSearch: _.debounce(search, 500) })
  ),
  withHandlers({
    handleSearch: ({ debouncedSearch, setQuery }) => (e) => {
      const query = e.target.value
      debouncedSearch(query)
      setQuery(query)
    }
  })
)

export default enhance(ProductsSearchBar)
