const styles = theme => {
  return {
    container: {
      paddingTop: 25,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 0,
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
      '& + &': {
        background: theme.palette.grey['100'],
        marginTop: -1,
        borderTop: `1px solid ${theme.palette.grey['200']}`
      },
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 20,
        paddingRight: 20
      }
    },
    formControl: {
      width: '100%'
    },
    formControlToggle: {
      paddingTop: 10,
      paddingBottom: 10,
      alignSelf: 'center',
      [theme.breakpoints.up('lg')]: {
        alignSelf: 'start'
      }
    },
    filterBlock: {
      paddingBottom: theme.spacing(2)
    }
  }
}

export default styles
