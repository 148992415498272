const style = theme => ({
  headerRightContent: {
    marginRight: 20
  },
  buttonsMobile: {
    margin: '15px auto 0'
  },
  warningContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  scrollableGridItem: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 20
    }
  },
  footerButton: {
    margin: '10px 0'
  }
})

export default style
