import { useState, useEffect } from 'react'

export const useGeolocation = (options) => {
  const [position, setPosition] = useState({
    timestamp: Date.now(),
    coords: {}
  })
  const [error, setError] = useState(null)

  useEffect(() => {
    if(options.invokedLocation) {
      navigator.geolocation.getCurrentPosition(setPosition, setError, options)
    }
  }, [options])

  return { position, error }
}