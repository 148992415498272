import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose } from 'recompose'
import NotFoundScreen from './NotFoundScreen'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

const mapStateToProps = state => ({
  pageTitle: platformSelectors.getPageTitle(state)('404 Heading')
})

const enhancer = compose(
  connect(
    mapStateToProps,
    (dispatch) => ({
      onBackClick: (e) => {
        e.preventDefault()
        dispatch(push('/'))
      }
    })
  )
)

export default enhancer(NotFoundScreen)
