import React, { Fragment, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import { selectors, actions } from '../../../../store/modules/announcementManagement'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import VisuallyHidden from '../../../../components/VisuallyHidden'
import LoadingBar from '../../../../components/LoadingBar'
import P from '../../../../components/P'
import DataTable from '../../../../components/DataTable'
import ListViewLoadMore from '../../../../components/ListViewLoadMore'
import AnnouncementManagementRow from '../AnnouncementManagementRow'
import AnnouncementManagementSearchFilters from '../AnnouncementManagementSearchFilters'
import styles from './style'
import { translations } from '../../../../config'
import { AddAnnouncementButtonSmall } from './AddAnnouncementButton'
import { useFetchMaster } from '../../../../store/modules/announcementManagement/hooks'

const RightContent = styled('div')(({ theme }) => ({
	paddingRight: '10px'
}))

const headers = [
	{ id: 'title', label: translations('Announcement Management - Title') },
	{ id: 'publishType', label: translations('Announcement Management - Publish Type'), options: { align: 'center' } },
	{ id: 'author', label: translations('Announcement Management - Author'), options: { align: 'center' } },
	{ id: 'createdAt', label: translations('Announcement Management - Created At'), options: { align: 'center' } },
	{ id: 'lastEditedBy', label: translations('Announcement Management - Last Modified By'), options: { align: 'center' } },
	{ id: 'lastEditedAt', label: translations('Announcement Management - Last Modified At'), options: { align: 'right' } }
]

const renderResults = ({ data, isLoading, isEmpty, isError, errorMessage, classes }) => {
	let statusText = null
	switch (true) {
		case isEmpty:
			statusText = 'Announcement Management - No Results'
			break
		case isLoading:
			statusText = 'Announcement Management - Loading'
			break
		case isError:
			statusText = 'Announcement Management - Error'
			break
		default:
			break
	}
	if (statusText) {
		return (<div className={classes.message}>
			<P role='status' value={translations(statusText)} />
		</div>)
	}

	return (
		<Fragment>
			<DataTable
				headers={headers}
				data={data}
				tableId={'announcement-management-table'}
				tableHeading={'Announcements'}
				rowComponent={AnnouncementManagementRow}
			/>
		</Fragment>
	)
}

const AnnouncementManagement = (props) => {
	const { entity, isLoading, isEmpty, isError } = useSelector(selectors.genericSelectors.getMaster)
	const filters = useSelector(selectors.genericSelectors.getMasterFilters)
	const page = useSelector(selectors.genericSelectors.getMasterPageNumber)
	const canLoadMore = useSelector(selectors.genericSelectors.getCanLoadMore)
	const dispatch = useDispatch()

	useFetchMaster({ dispatch, page, filters, isEmpty, isLoading })

	useEffect(() => {
		return () => {
			dispatch(actions.resetStore())
		}
	}, [dispatch])

	const loadMore = useCallback(() => {
		dispatch(actions.incrementPage())
	}, [dispatch])

	const goToNewAnnouncement = useCallback(() => {
		dispatch(push('/communications/announcement-management/new'))
	}, [dispatch])

	const {
		classes,
		pageTitle = {},
	} = props
	return (
		<div className={classes.container}>
			<VisuallyHidden>
				<h1>{pageTitle.title}</h1>
			</VisuallyHidden>
			<Helmet>
				<title>{pageTitle.titleWithName}</title>
			</Helmet>
			<SubHeader
				leftContent={(
					<BackBar />
				)}
				rightContent={
					<RightContent>
						<AddAnnouncementButtonSmall
							onClick={goToNewAnnouncement}
						/>
					</RightContent>
				}
			/>
			<AnnouncementManagementSearchFilters />
			<div className={classes.content}>
				<LoadingBar isLoading={isLoading} />
				{renderResults({
					classes,
					headers,
					data: entity,
					isLoading,
					isEmpty,
					isError
				})}
				{canLoadMore && <ListViewLoadMore isLoading={isLoading} onClick={loadMore} />}
			</div>
		</div>
	)
}

AnnouncementManagement.propTypes = {
	classes: PropTypes.shape({}).isRequired,
	pageTitle: PropTypes.shape({
		title: PropTypes.string.isRequired,
		titleWithName: PropTypes.string
	}).isRequired,
	isLoading: PropTypes.bool.isRequired,
	hasResults: PropTypes.bool,
	canLoadMore: PropTypes.bool,
	headers: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired
}

export default withStyles(styles)(AnnouncementManagement)
