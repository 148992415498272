import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import _ from 'lodash'

export const getIsLoading = state => state.medDRA.status === PENDING
export const getError = state => state.medDRA.error
export const getIsInitial = state => !state.medDRA.status
export const getStatus = state => state.medDRA.status
export const getPage = state => state.medDRA.page
export const getSize = state => state.medDRA.size
export const getTotal = state => state.medDRA.total
export const getHasResults = state => _.get(state, 'medDRA.results') && _.get(state, 'medDRA.results').length
export const getDrugs = state => state.medDRA.results
export const getDrugsForDropdown = state => _.map(state.medDRA.results, (result) => result.name)
export const getDrugsForSearchResults = state => _.map(state.medDRA.results, (result) => {
  return {
    ...result,
    label: result.name
  }
})

export const getHasMore = createSelector([
  getTotal,
  getDrugs
], (total, results) => {
  return total && results.length < total
})

export const getVersions = state => _.get(state, 'medDRA.versions', [])
export const getVersionsAsOptions = createSelector([
  getVersions
], (versions) => (versions.map(version => ({ label: version, value: version }))))
