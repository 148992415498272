"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.psrTypeOptions = exports.psrStatusIsNotSet = exports.isNotApplication = exports.isApproved = exports.isApplication = exports.isAnalysisOrClosure = exports.ifApprovedThenApplication = exports.clearIfNotApplication = exports.adverseDatesHidden = exports.PSR_TYPES = exports.PSR_STATUS = void 0;

var computedHidden = _interopRequireWildcard(require("../../../../../../formHelpers/computedHidden"));

var computedValue = _interopRequireWildcard(require("../../../../../../formHelpers/computedValue"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var PSR_TYPES = {
  APPLICATION_FOR_PSR: '1',
  PERIODIC_ANALYSIS_UPDATE: '2',
  CLOSURE_PSR: '3'
};
exports.PSR_TYPES = PSR_TYPES;
var analysisOrClosureValues = [PSR_TYPES.PERIODIC_ANALYSIS_UPDATE, PSR_TYPES.CLOSURE_PSR];
var PSR_STATUS = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  CLOSED: 'Closed'
};
exports.PSR_STATUS = PSR_STATUS;
var isApplication = computedHidden.fieldIs('psrType', PSR_TYPES.APPLICATION_FOR_PSR);
exports.isApplication = isApplication;
var isAnalysisOrClosure = computedHidden.fieldIs('psrType', analysisOrClosureValues);
exports.isAnalysisOrClosure = isAnalysisOrClosure;
var adverseDatesHidden = computedHidden.fieldIsNot('psrType', analysisOrClosureValues);
exports.adverseDatesHidden = adverseDatesHidden;
var isNotApplication = computedHidden.visibleIf(isAnalysisOrClosure);
exports.isNotApplication = isNotApplication;
var clearIfNotApplication = computedValue.clearIfNot(isAnalysisOrClosure);
exports.clearIfNotApplication = clearIfNotApplication;
var isApproved = computedHidden.fieldIs('status', PSR_STATUS.APPROVED);
exports.isApproved = isApproved;
var psrStatusIsNotSet = computedHidden.fieldIsFalsey('status');
exports.psrStatusIsNotSet = psrStatusIsNotSet;
var ifApprovedThenApplication = computedValue.branch(psrStatusIsNotSet)(computedValue.always(PSR_TYPES.APPLICATION_FOR_PSR), computedValue.clearIf(isApplication));
exports.ifApprovedThenApplication = ifApprovedThenApplication;
var psrTypeOptions = computedValue.branch(isApproved)(function () {
  return [{
    label: 'Periodic analysis update',
    value: PSR_TYPES.PERIODIC_ANALYSIS_UPDATE
  }, {
    label: 'Closure PSR',
    value: PSR_TYPES.CLOSURE_PSR
  }];
}, function () {
  return [{
    label: 'Application for PSR',
    value: PSR_TYPES.APPLICATION_FOR_PSR
  }];
});
exports.psrTypeOptions = psrTypeOptions;