import _ from 'lodash'
import { promiseReducer } from '../../util'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class ExportManagementReducers {
  fetchExportFresh = promiseReducer(
    (state, action) => {
      const results = _.get(action, 'result.results')
      return {
        ...state,
        page: 1,
        results,
        canLoadMore: _.size(results) > 0
      }
    }
  )

  fetchExportNext = promiseReducer(
    (state, action) => {
      const { results } = state
      const newResults = _.get(action, 'result.results')
      return {
        ...state,
        page: state.page + 1,
        results: [...results, ...newResults],
        canLoadMore: _.size(newResults) > 0
      }
    }
  )

  fetchExportById = promiseReducer(
    (state, action) => {
      const result = _.get(action, 'result')
      return {
        ...state,
        selectedRecordDetails: result,
        selectedRecord: _.get(result, 'id')
      }
    }
  )

  changeFilter = (state, action) => {
    const { filter } = action
    const { filters } = state
    return {
      ...state,
      filters: {
        ...filters,
        [filter.name]: filter.value
      }
    }
  }

  changeFilters = (state, action) => {
    const { filters } = action
    return {
      ...state,
      filters
    }
  }

  fetchExportFile = (state, action) => {
    return {
      ...state,
      link: action.result
    }
  }

  fetchExportFile = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          link: action.result,
          status: SUCCESS,
          action: undefined,
          error: undefined
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          action: undefined,
          error: action.error.message
        }
      default:
        return state
    }
  }

  clearLink = (state, action) => {
    return {
      ...state,
      link: undefined,
      status: undefined,
      error: undefined
    }
  }

  regenerateExport = (state, action) => {
    return {
      ...state
    }
  }

}

export default new ExportManagementReducers()
