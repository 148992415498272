const styles = {
  logoContainer: {
    // width: 300,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 100,
    margin: 0,
    marginBottom: '40px'
  },
  logo: {
    maxHeight: '55px'
  }
}
export default theme => styles
