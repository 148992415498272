export const FETCH_ALL_USER_GROUPS_FOR_CURRENT_PLATFORM = 'digitalStore/userGroups/FETCH_ALL_USER_GROUPS_FOR_CURRENT_PLATFORM'
export const FETCH_ALL_USER_GROUPS_FOR_ORGANISATION = 'digitalStore/userGroups/FETCH_ALL_USER_GROUPS_FOR_ORGANISATION'
export const FETCH_USER_GROUP = 'digitalStore/userGroups/FETCH_USER_GROUP'
export const CREATE_USER_GROUP_FOR_ORGANISATION = 'digitalStore/userGroups/CREATE_USER_GROUP_FOR_ORGANISATION'
export const UPDATE_USER_GROUP = 'digitalStore/userGroups/UPDATE_USER_GROUP'
export const DELETE_USER_GROUP = 'digitalStore/userGroups/DELETE_USER_GROUP'
export const FETCH_USER_GROUP_USERS = 'digitalStore/userGroups/FETCH_USER_GROUP_USERS'
export const UPDATE_USER_GROUP_USERS = 'digitalStore/userGroups/UPDATE_USER_GROUP_USERS'
export const FETCH_USER_GROUP_LOCATIONS = 'digitalStore/userGroups/FETCH_USER_GROUP_LOCATIONS'
export const UPDATE_USER_GROUP_LOCATIONS = 'digitalStore/userGroups/UPDATE_USER_GROUP_LOCATIONS'
export const RESET_STORE = 'digitalStore/userGroups/RESET_STORE'