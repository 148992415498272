import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class DeveloperActions {
  getDefaultApplication = ({ organisationId } = {}) => ({
    type: constants.CREATE_OR_FETCH_APPLICATION,
    promise: async () => {
      const application = await digitalStoreSdk.developer.getDefaultApplication({ organisationId })
        .catch(async (error) => {
          if (error.code === 'RA-MHR-181-04') {
            return digitalStoreSdk.developer.createDefaultApplication({ organisationId })
          }
          throw error
        })

      return application
    }
  })
  getApiKeys = ({ organisationId, applicationId } = {}) => ({
    type: constants.FETCH_API_KEYS,
    payload: { organisationId, applicationId },
    promise: () => {
      return digitalStoreSdk.developer.getApplicationApiKeys({ organisationId, applicationId })
    }
  })
  createAPIKey = ({ organisationId, applicationId, name } = {}) => ({
    type: constants.CREATE_API_KEY,
    payload: { organisationId, applicationId, name },
    promise: () => {
      return digitalStoreSdk.developer.createAPIKey({ organisationId, applicationId, name })
    }
  })
  removeAPIKey = ({ organisationId, applicationId, apiKeyId } = {}) => ({
    type: constants.REMOVE_API_KEY,
    payload: { organisationId, applicationId, apiKeyId },
    promise: () => {
      return digitalStoreSdk.developer.removeAPIKey({ organisationId, applicationId, apiKeyId })
    }
  })
  createApplication = ({ organisationId, submissionServiceUser, application }) => ({
    type: constants.CREATE_APPLICATION,
    payload: { organisationId, submissionServiceUser, application  },
    promise: () => {
      return digitalStoreSdk.developer.createApplication({ organisationId, submissionServiceUser, application })
    }
  })
  deleteApplication = ({ id, submissionServiceUser }) => ({
    type: constants.DELETE_APPLICATION,
    payload: { id, submissionServiceUser },
    promise: () => {
      return digitalStoreSdk.developer.deleteApplication({ id })
    }
  })
  updateApplication = ({ id, application, submissionServiceUser }) => ({
    type: constants.UPDATE_APPLICATION,
    payload: { id, application, submissionServiceUser },
    promise: () => {
      return digitalStoreSdk.developer.updateApplication({ id, application, submissionServiceUser })
    }
  })
  fetchApplications = ({ organisationId, submissionServiceUsers }) => ({
    type: constants.FETCH_APPLICATIONS,
    payload: { organisationId, submissionServiceUsers },
    promise: () => {
      return digitalStoreSdk.developer.fetchApplications({ organisationId, submissionServiceUsers })
    }
  })
  clearTemporaryApiKey = {
    type: constants.CLEAR_TEMPORARY_KEY
  }
}

export default new DeveloperActions()
