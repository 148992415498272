"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _mhraR2Report = _interopRequireDefault(require("./mhraR2Report"));

var _mhraR3Report = _interopRequireDefault(require("./mhraR3Report"));

var _mhraR3Ack = _interopRequireDefault(require("./mhraR3Ack"));

var _devicesReport = _interopRequireDefault(require("./devicesReport"));

var _trilogyReport = _interopRequireDefault(require("./trilogyReport"));

var _psrReport = _interopRequireDefault(require("./psrReport"));

var _saeReport = _interopRequireDefault(require("./saeReport"));

var _trendReport = _interopRequireDefault(require("./trendReport"));

var _fscaReport = _interopRequireDefault(require("./fscaReport"));

var _sabreSAE = _interopRequireDefault(require("./sabreSAE"));

var _sabreSAR = _interopRequireDefault(require("./sabreSAR"));

var _login = _interopRequireDefault(require("./standard/login"));

var _registration = _interopRequireDefault(require("./standard/registration"));

var _forgotPassword = _interopRequireDefault(require("./standard/forgotPassword"));

var _resetPassword = _interopRequireDefault(require("./standard/resetPassword"));

var _updateUser = _interopRequireDefault(require("./standard/updateUser"));

var _updatePassword = _interopRequireDefault(require("./standard/updatePassword"));

var _aboutYou = _interopRequireDefault(require("./standard/aboutYou"));

var _reportSubmissionGuide = _interopRequireDefault(require("./standard/reportSubmissionGuide"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  mhraR2Report: _mhraR2Report["default"],
  mhraR3Report: _mhraR3Report["default"],
  mhraR3Ack: _mhraR3Ack["default"],
  devicesReport: _devicesReport["default"],
  trilogyReport: _trilogyReport["default"],
  psrReport: _psrReport["default"],
  saeReport: _saeReport["default"],
  trendReport: _trendReport["default"],
  fscaReport: _fscaReport["default"],
  sabreSAE: _sabreSAE["default"],
  sabreSAR: _sabreSAR["default"],
  login: _login["default"],
  registration: _registration["default"],
  forgotPassword: _forgotPassword["default"],
  resetPassword: _resetPassword["default"],
  updateUser: _updateUser["default"],
  updatePassword: _updatePassword["default"],
  aboutYou: _aboutYou["default"],
  reportSubmissionGuide: _reportSubmissionGuide["default"]
};
exports["default"] = _default;