
const styles = theme => {
  return {
    tabContainer: {
      paddingTop: 25
    },
    tabRoot: {
      fontWeight: 700
    },
    warning: {
      marginTop: 15
    }
  }
}

export default styles
