import React, { Component } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import style from './style'

// `tabs` prop:
// array of objects of shape
// {
//   label: <string>,
//   content: <Jsx><Stuff /></Jsx>,
//   value: <string> or <number> or anything
// }

class TabbedContent extends Component {
  renderContent () {
    const { tabs, tabValue, classes } = this.props

    return tabs.map(tab => {
      const currentTab = tab.value === tabValue
      const wrapperClass = classNames({
        [classes.displayNone]: !currentTab,
        [classes.contentContainer]: true
      })
      return (
        <div className={wrapperClass} key={tab.value}>
          {tab.content}
        </div>
      )
    })
  }

  render () {
    const { tabs, classes, tabValue, handleTabChange, noDivider, bigTabs, mediumTabs, lightDivider, containerClass, centered = false } = this.props
    const tabClasses = {
      root: classNames(
        classes.tabRoot,
        { [classes.tabRootBig]: bigTabs }
      ),
      labelContainer: classes.tabLabelContainer,
      label: classNames({
        [classes.tabLabelBig]: bigTabs,
        [classes.tabLabelMedium]: mediumTabs
      })
    }
    const tabsClasses = {
      root: classNames(
        classes.tabsRoot,
        {
          [classes.tabsBorder]: !noDivider,
          [classes.tabsBorderLight]: lightDivider
        }
      )
    }
    const containerClasses = classNames(
      classes.container,
      {
        [containerClass]: containerClass
      }
    )

    if (tabs && tabs.length) {
      return (
        <div
          className={containerClasses}
        >
          <Tabs
            centered={centered}
            value={tabValue}
            onChange={handleTabChange}
            fullWidth
            TabIndicatorProps={{
              style: {
                backgroundColor: '#000',
                height: '3px'
              }
            }}
            classes={tabsClasses}
            indicatorClassName={
              bigTabs
              ? classes.tabsIndicatorBig
              : classes.tabsIndicator
            }
          >
            {
              tabs.map(({ label, value }, i) => (
                <Tab
                  label={label}
                  key={label + i}
                  classes={tabClasses}
                  value={value}
                  disableRipple
                />
              ))
            }
          </Tabs>
          {this.renderContent()}
        </div>
      )
    } else {
      return null
    }
  }
}

export default withStyles(style)(TabbedContent)
