import { colors } from '../../../config/theme'

const styles = {
  fieldContainer: {
    display: 'flex',
    marginBottom: -20
  },
  floatingLabelFocusStyle: {
    color: colors.accent
  },
  inputContainer: {
    display: 'flex',
    flex: 2,
    marginLeft: 10,
    flexDirection: 'column'
  },
  dropdownContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  errorStyle: { color: colors.error },
  container: {
    display: 'flex'
  }
}

export default theme => styles
