import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CREATE_FILE:
      return reducers.createFile(state, action)
    case constants.UPDATE_FILE:
      return reducers.updateFile(state, action)
    case constants.DELETE_FILE:
      return reducers.deleteFile(state, action)
    case constants.FETCH_FILES:
      return reducers.fetchFiles(state, action)
    default:
      return state
  }
}
