import React, { useCallback, useMemo, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { compose } from 'recompose'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { translations } from '../../../../config'
import Dropdown from '../../../../components/Fields/Dropdown'
import { sortOptions } from './options'
import { withStyles } from '@material-ui/core/styles'
import { actions } from '../../../../store/modules/terminology'
import * as filtersLogic from './reducer'
import styles from './style'
import { useDispatch, useSelector } from 'react-redux'
import TerminologyToggleGroup from '../TerminologyToggleGroup'
import { selectors } from '../../../../store/modules/terminology'
import Input from '../../../../components/Fields/Input/Input'

export const handleFilter = ({ type, dispatch }) => event => {
  return dispatch({
    type,
    payload: {
      value: _.has(event, 'target') ? event.target.value : event
    }
  })
}
const TerminologyFilters = (props) => {
  const {
    classes,
  } = props
  const filters = useSelector(selectors.genericSelectors.getMasterFilters)
  const [stateFilters, dispatch] = useReducer(
    filtersLogic.reducer,
    {},
    filtersLogic.initialiseState(filters)
  )
  const dispatchRedux = useDispatch()

  const onFilterChange = useMemo(() => {
    return _.debounce(filters => {
      dispatchRedux(actions.updateFilters({ filters }))
    }, 300)
  }, [dispatchRedux])
  
  useEffect(() => {
    onFilterChange(stateFilters)
    return () => {
      onFilterChange.cancel();
    }
  }, [stateFilters, onFilterChange])

  const contextTypes = useSelector(selectors.getAvaliableDictionariesContextTypes)
  const showActionFilters = useSelector(selectors.isUsageTypeAction)
  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={2} justify={'center'} alignItems={'center'} className={classes.filterBlock}>
          {showActionFilters &&
            <Grid item xs={12} md={12} lg={6} >
              <FormControl className={classes.formControl}>
                <div className={classes.formControlToggle}>
                  <TerminologyToggleGroup
                    options={contextTypes}
                    value={stateFilters.actionType}
                    onChange={handleFilter({ type: filtersLogic.types.SET_FILTER_CONTEXT_TYPE, dispatch })}
                  />
                </div>
              </FormControl>
            </Grid>
          }
          <Grid item xs={12} md={6} lg={4}>
            <FormControl className={classes.formControl} >
              <Input
                label={translations('Search')}
                name={`searchTerm`}
                value={stateFilters.searchTerm}
                onChange={handleFilter({ type: filtersLogic.types.SET_SEARCH_TERM, dispatch })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <FormControl className={classes.formControl} >
              <Dropdown
                label={translations('Sort')}
                name={'sort'}
                value={stateFilters.sort}
                onChange={handleFilter({ type: filtersLogic.types.SET_SORT, dispatch })}
                options={sortOptions}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

TerminologyFilters.propTypes = {
  classes: PropTypes.shape({}).isRequired,
}

export default compose(
  withStyles(styles)
)(TerminologyFilters)
