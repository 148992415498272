import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { reset } from 'redux-form'
import AddLanguageForm, { formName } from './AddLanguageForm'
import { actions as translationsActions } from '../../../../../store/modules/translations'
import _ from 'lodash'

const enhancer = compose(
  connect(),
  withHandlers({
    handleSubmit: ({ dispatch, onSubmit }) => async ({ language }) => {
      _.isFunction(onSubmit) ? 
        await onSubmit(language) :
        await dispatch(translationsActions.createTranslations(language))
      dispatch(reset(formName))
    }
  })
)

export default enhancer(AddLanguageForm)
