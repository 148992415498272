import * as constants from './constants'
import { selectors as organisationDetailsSelectors } from '../organisationDetails'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'

export const searchLocations = ({ query = '' }) => ({
  type: constants.SEARCH_LOCATIONS,
  promise: async (dispatch, getState) => {
    const state = getState()
    const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(state)

    return await digitalStoreSdk.organisations.fetchLocationsForOrganisation({ query, organisationId })
  }
})

export const fetchOrganisationLocations = ({ organisationId, search = '' }) => ({
  type: constants.FETCH_ORGANISATION_LOCATIONS,
  promise: digitalStoreSdk.location.fetchOrganisationLocations({ organisationId, query: { search } })
})

export const fetchLocationProviders = () => ({
  type: constants.FETCH_LOCATION_PROVIDERS,
  promise: () => digitalStoreSdk.location.fetchLocationProviders()
})

export const resetStore = () => ({
  type: constants.RESET_STORE
})
