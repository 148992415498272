const util = require('util')
const _ = require('lodash')
const env = require('../config/configFiles/environments/local.json')

// Log any given item w/ date
export default (item, type = 'LOG') => {
  if (env.LOGS) {
    const date = new Date()
    if ((typeof item) === 'object') {
      console.log(`${date} - [${type}]: ${String.prototype.concat(util.inspect(item, false, null))}`)
    } else if (item) {
      console.log(`${date} - [${type}]: ${item}`)
    } else {
      console.log(`${date} - [${type}]: Couldn't find type to log [ERROR]`)
      return _.noop
    }
  }
}
