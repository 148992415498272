import _ from 'lodash'
import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors } from './'
import { selectors as authSelectors } from './../auth'

class ExportManagementActions {
  fetchExportFresh = ({ dateFrom, dateTo, exportType }) => ({
    type: constants.FETCH_EXPORTS_FRESH,
    promise: (_dispatch, getState) => {
      const size = selectors.getSearchSize(getState())
      const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
      return digitalStoreSdk.fileExport.fetchExports({ organisationId, page: 1, size, dateFrom, dateTo, exportType })
    }
  })

  fetchExportNext = () => ({
    type: constants.FETCH_EXPORTS_NEXT,
    promise: (_dispatch, getState) => {
      const size = selectors.getSearchSize(getState())
      const page = selectors.getSearchPage(getState())
      const filter = selectors.getFilters(getState())
      const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
      return digitalStoreSdk.fileExport.fetchExports({ organisationId, page: page + 1, size, ...filter })
    }
  })

  fetchExportById = ({id}) => ({
    type: constants.FETCH_EXPORT,
    promise: (_dispatch, getState) => {
      return digitalStoreSdk.fileExport.fetchExport({ id })
    }
  })

  changeFilters = ({ filters }) => {
    return {
      type: constants.EXPORTS_FILTERS,
      page: 1,
      filters
    }
  }

  changeFilter = ({ filter }) => ({
    type: constants.EXPORTS_FILTER,
    filter
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })

  fetchExportFile = ({ id }) => {
    return {
      type: constants.FETCH_FILE,
      promise: () => {
        return digitalStoreSdk.fileExport
          .fetchExportFile({ id })
          .catch(error => {
            throw error
          })
      }
    }
  }

  regenerateExport = ({ id }) => {
    return {
      type: constants.REGENERATE_EXPORT,
      promise: () => {
        return digitalStoreSdk.fileExport
          .regenerateExport({ id })
          .catch(error => {
            throw error
          })
      }
    }
  }

  clearLink = () => ({
    type: constants.CLEAR_LINK
  })

}

export default new ExportManagementActions()
