import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Form from '../../../../components/Form'
import FormBody from '../../../../components/Form/FormBody'
import FormError from '../../../../components/Form/FormError'
import FormSubmit from '../../../../components/Form/FormSubmit'
import PersonalDetails from '../../../../components/Form/FormSections/PersonalDetails'
import CommunicationPreferences from '../../../../components/Form/FormSections/CommunicationPreferences'
import BottomBar from '../../../../components/BottomBar'
import schema from '../../../../components/Form/commonSchemas/userPersonalDetails'
import SetPassword from '../../../Users/UserRegistrationScreen/UserRegistrationForm/SetPassword'
import RolesOrganisationsProfessions from '../../UserForm/RolesOrganisationsProfessions'
import Terms from './Terms'

import { translations } from '../../../../config'
import { formStyle } from './style'
import { useDispatch } from 'react-redux'
import { change } from 'redux-form'
import _ from 'lodash'


export const formId = 'user-registration'

const UserRegistrationForm = Form(formId)

const UserRegistrationFormWrapper = ({
  onSubmit,
  error,
  submitting,
  invalid,
  classes,
  roleId,
  isFormValid,
  isPublic,
  telephone,
  isOrganisationInvite,
  organisationInvite,
  isMoreRegistration
}) => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(change(formId, 'email', _.get(organisationInvite, 'email')))
  }, [organisationInvite])

  return (
    <div>
      <UserRegistrationForm onSubmit={onSubmit} submitLabel={translations('Create user')}>
        <FormBody>
          <div>
            <PersonalDetails
              formId={formId}
              schema={schema({ disableEmail: isOrganisationInvite })}
            />
            { !isMoreRegistration && 
              <RolesOrganisationsProfessions
                formId={formId}
                constituent
                isPublic={isPublic}
              /> 
            }
            <CommunicationPreferences
              formId={formId}
              telephone={telephone}
            />
            <SetPassword />
            {isPublic ? <Terms /> : null}
          </div>
        </FormBody>
        <FormError />
        <FormSubmit
          ContainerComponent={BottomBar}
          label={translations('Create user')}
          disabled={isFormValid}
        />
      </UserRegistrationForm>
    </div>
  )
}

UserRegistrationFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
}

export default withStyles(formStyle)(UserRegistrationFormWrapper)
