import { theme } from '../../../config'
const { totalHeaderHeight } = theme

export default theme => ({
  listContainer: {
    margin: '20px 7.5% 0'
  },
  groupedListContainer: {
    margin: '0 7.5%'
  },
  buttonsContainer: {
    display: 'flex',
    
    padding: '10px 0',
    margin: '0px -5px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'stretch'
    }
  },
  buttonContainer: {
    position: 'relative',
    zIndex: 1,
    padding: '5px',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0
    }
  },
  noDataPlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 19,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    height: `calc(100vh - ${totalHeaderHeight}px)`
  }
})
