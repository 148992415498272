import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import ViewOrganisationScreen from './ViewOrganisationScreen'
import NewOrganisationScreen from './NewOrganisationScreen'
import OrganisationManagementScreen from './OrganisationManagementScreen'
import ManageTranslationsScreen from './ManageTranslationsScreen'
import UploadTranslationsScreen from './ManageTranslationsScreen/UploadTranslations'
import TranslationsHistoryScreen from './ManageTranslationsScreen/TranslationsHistory'
import ManagePagesScreen from './ManagePagesScreen'
import EditPageScreen from './EditPageScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/organisations' path='/organisations' exact component={TimedLogoutComponent(OrganisationManagementScreen)} />,
  <PrivateRoute key='/organisations/new' path='/organisations/new' exact component={TimedLogoutComponent(NewOrganisationScreen)} />,
  <PrivateRoute key='/organisations/:id' path='/organisations/:id' exact component={TimedLogoutComponent(ViewOrganisationScreen)} />,
  <PrivateRoute key='/organisations/:id/translations' path='/organisations/:id/translations' exact component={TimedLogoutComponent(ManageTranslationsScreen)} />,
  <PrivateRoute key='/organisations/:id/translations/upload' path='/organisations/:id/translations/upload' exact component={TimedLogoutComponent(UploadTranslationsScreen)} />,
  <PrivateRoute key='/organisations/:id/translations/history' path='/organisations/:id/translations/history' exact component={TimedLogoutComponent(TranslationsHistoryScreen)} />,
  <PrivateRoute key='/organisations/:id/page-management' path='/organisations/:id/page-management' exact component={TimedLogoutComponent(ManagePagesScreen)} />,
  <PrivateRoute key='/my-organisation/page-management' path='/my-organisation/page-management' exact component={TimedLogoutComponent(ManagePagesScreen)} />,
  <PrivateRoute key='/organisations/:id/page-management/:pageId' path='/organisations/:id/page-management/:pageId' exact component={TimedLogoutComponent(EditPageScreen)} />
]
