import fp from 'lodash/fp'
import _ from 'lodash'

class HealthCheckReducers {
  getOrganisationHealthCheck = (state, action) => {
    const { result } = action
    return {
      ...state,
      result
    }
  }
  /**
   * Change filters such as the organisation ID or time period.
   * @param {object} state
   * @param {{type: string, filters: any, [x: string]: any}} action
   */
  changeFilters = (state, action) => {
    const { filters } = action
    const nextFilters = fp.merge(state.filters, filters)

    if (!_.isEmpty(filters.organisationIds)) {
      nextFilters.organisationIds = filters.organisationIds
    }

    return {
      ...state,
      filters: nextFilters
    }
  }
}


export default new HealthCheckReducers()
