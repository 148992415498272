import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

const Text = styled.span`
  display: block;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.errorText};
  font-size: ${props => `${props.theme.type.label.small}px`};
  line-height: 1.25;
  ${media.greaterThan('small')`
    font-size: ${props => `${props.theme.type.label.large}px`};
    line-height: 1.4;
  `}
`

/**
 * Displays validation for a field 
 */
const ErrorMessage = (props) => {
  const { error } = props
  return (
    <Text>{error}</Text>
  )
}

ErrorMessage.propTypes = {
  /** The error message */
  error: PropTypes.string.isRequired
}


export default ErrorMessage
