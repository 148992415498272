const styles = (theme) => {
  return {
    container: {
      position: 'relative'
    },
    buttonContainer : {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'flex-end'
    },
    headerRightContent: {
      marginRight: 20
    },
    buttonsMobile: {
      margin: '15px auto 0'
    },
    listContainer: {
      padding: 18,
    },
    list: {
      margin: '0 auto',
      listStyle: 'none',
      maxWidth: 540,
      paddingLeft: 0
    },
    listItem: {
      width: '100%',
      justifyContent: 'start',
      backgroundColor: '#fff',
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: 14,
      marginBottom: 14,
      transition: '.3s border-color',
      '&:hover, &:active, &:focus': {
        borderColor: theme.palette.primary.main
      },
      '&:focus $label': {
        textDecoration: 'underline'
      }
    }
  }
}

export default styles
