import _ from 'lodash'
import React, { useState, useCallback, useEffect, memo } from 'react'
import { Chip } from '@material-ui/core'
import { withStyles, styled } from '@material-ui/core/styles'
import styles from './style'
import { translations } from '../../../../../config'

import EditTranslationModal from '../EditTranslationModal'
import KeyTags from './KeyTags'
import modalService from '../../../../../services/modalService'

import ZoomInIcon from '@material-ui/icons/ZoomIn'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'


const isOverflown = (element) => {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    )
  }

const ChipWrapper = styled('div')({
  lineHeight: '15px',
})

const GridCellExpand = memo(({
  id,
  field,
  colDef,
  value,
  api,
  canUpdate,
  tags,
  showKeyTag,
  isCellGlobal,
  updateType,
  classes,
  showSource
}) => {
  const wrapper = React.useRef(null)
  const cellDiv = React.useRef(null)
  const cellValue = React.useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showFullCell, setShowFullCell] = useState(false)
  const [showPopper, setShowPopper] = useState(false)
  const width = colDef.computedWidth - 20
  const showZoomInIcon = showPopper && canUpdate && !isCellGlobal && showFullCell && anchorEl !== null

  const showEditModal = useCallback(() => {
    modalService.open({
      component: EditTranslationModal,
      largeModal: false,
      value,
      onSubmitTranslationChange: (newValue) => {
        api.setEditCellValue({id, field, value: newValue})
        api.commitCellChange({id, field})
        api.setCellMode(id, field, 'view')
        modalService.close()
      },
      onClose: () => modalService.close()
    })
  }, [value])

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue?.current)
    setShowPopper(isCurrentlyOverflown)
    setAnchorEl(cellDiv.current)
    setShowFullCell(true)
  }

  const handleMouseLeave = () => {
    setShowFullCell(false)
  }

  useEffect(() => {
    if (!showFullCell) {
      return undefined
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [setShowFullCell, showFullCell])

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'start',
        lineHeight: '24px',
        width,
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box sx={{display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center'}}>
        <Box
          ref={cellValue}
          sx={{ 
            width, 
            height: 24,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis' 
          }}
        >
          {value}
        </Box>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
              <Typography variant='body2' style={{ padding: 8 }}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
        {showKeyTag &&
          <KeyTags tags={tags} />
        }
        {showZoomInIcon && (
          <IconButton aria-label='open' onClick={showEditModal} anchorEl={anchorEl} className={classes.zoomInIconButton}>
            <ZoomInIcon />
          </IconButton>
        )}
      </Box>

      {(showSource && updateType) &&
        <ChipWrapper>
          <Chip size="small" className={`${classes.chip} ${updateType === 'IMPORTED' ? classes.purple : classes.teal}`} label={translations(`Manage Translations - Source - ${updateType}`)} />
        </ChipWrapper>
      }
    </Box>
  )
})

export default withStyles(styles)(GridCellExpand)