import { push } from 'connected-react-router'
import { env } from '../../config/environments'

// CODE TO HELP WITH DEBUGGING IN CORDOVA APP
export const debug = ({ dispatch, getState }) => {
  if (env !== 'production') {
    // You can call this from safari devtools
    // i.e window.__nav__('/news')
    window.__nav__ = (path = '/') => {
      dispatch(push(path))
    }

    window.__action__ = (action = {}) => {
      dispatch(action)
    }

    window.__getstate__ = () => {
      console.log(getState())
    }
  }

  return next => action => next(action)
}
