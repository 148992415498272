import { createSelector } from 'reselect'
import _ from 'lodash'
import { translations } from '../../../config'

import { selectors as newsFeedsSelectors } from '../newsFeeds'
import { selectors as authSelectors } from '../auth'
import { selectors as languagesSelectors } from '../languages'

export const getFeedsWithLanguage = createSelector(
  [languagesSelectors.getLanguages, newsFeedsSelectors.getFeeds],
  (languages, feeds) => {
    return feeds.map(feed => {
      const matchedLang = _.find(languages, l => l.id === feed.languageId)
      if (!matchedLang) return feed
      return {
        ...feed,
        language: translations(`Language - ${matchedLang.isoCode}`)
      }
    })
  }
)

export const getFeedLanguageOptionsForSelectedOrg = createSelector(
  [authSelectors.getUserSelectedOrganisationId, getFeedsWithLanguage],
  (orgId, feeds) => {
    return _.chain(feeds)
      .filter({
        organisationId: orgId,
        isInternalFeed: true,
        url: 'organisation@URL.com'
      })
      .map(feed => {
        return {
          value: feed.id,
          label: feed.language
        }
      })
      .value()
  }
)
