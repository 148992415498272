import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  status: undefined,
  error: undefined,
  theme: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.CREATE_THEME:
      return reducers.createTheme(state, action)
    case constants.CREATE_ORGANISATION_THEME:
      return reducers.createTheme(state, action)
    case constants.CREATE_PLATFORM_THEME:
      return reducers.createTheme(state, action)
    case constants.UPDATE_THEME:
      return reducers.updateTheme(state, action)
    case constants.FETCH_THEME_DETAILS:
      return reducers.fetchThemeDetails(state, action)
    default:
      return state
  }
}
