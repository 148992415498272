import React from 'react'
import { styled } from '@material-ui/core/styles'

import ReportsSearchScreen from '../ReportManagementScreen'

const Content = styled('div')(({ theme }) => ({}))

const ReportManagementPanel = (props) => {
  return (
    <Content>
      <ReportsSearchScreen />
    </Content>
  )
}

export default ReportManagementPanel
