const styles = (theme) => {
  return {
    container: {
      fontSize: 14
    },
    pre: {
      textAlign: 'left',
      whiteSpace: 'pre',
      wordSpacing: 'normal',
      wordBreak: 'normal',
      overflowWrap: 'normal',
      lineHeight: '1.5em',
      tabSize: 4,
      hyphens: 'none',
      padding: '1em',
      overflow: 'auto',
      '& .token-line': {
        lineHeight: '1.5em',
        height: '1.5em'
      }
    },
    code: {
      background: 'none',
      textShadow: 'white 0px 1px',
      fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
      fontSize: '1em',
      textAlign: 'left',
      whiteSpace: 'pre',
      wordSpacing: 'normal',
      wordBreak: 'normal',
      overflowWrap: 'normal',
      lineHeight: '1.5em',
      tabSize: 4,
      hyphens: 'none'
    },
    line: {
      display: 'table-row'
    },
    lineNumber: {
      display: 'table-cell',
      textAlign: 'right',
      paddingRight: '1em',
      userSelect: 'none',
      opacity: 0.5
    },
    lineContent: {
      display: 'table-cell'
    }
  }
}

export default styles
