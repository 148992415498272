import React from 'react'

import { FormControl, Grid, styled } from '@material-ui/core'
import OrganisationAssociationLookup from '../../../../components/Fields/OrganisationAssociationLookup'

const FullWidthFormControl = styled(FormControl)(() => ({
  width: '100%'
}))

const OrganisationSearchFilter = ({ label, onChange }) => {
  return (
    <Grid xs={12} md={2}>
      <FullWidthFormControl>
        <OrganisationAssociationLookup
          label={label}
          handleChange={onChange}
          getCustomValue={(organisation) => organisation.id}
          fetchInitial
        />
      </FullWidthFormControl>
    </Grid>
  )
}

export default OrganisationSearchFilter
