import React, { useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import style from './style'

import { translations } from '../../../../../config'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import Button from '../../../../../components/Button'
import TextField from '@material-ui/core/TextField'

const ActionContainer = styled('div')({
  'margin-top': '20px',
  display: 'flex',
  gap: '10px'
})

const CloseButton = styled(Button)({
  'margin-left': 'auto'
})


const EditTranslationModal = ({ value, onSubmitTranslationChange, onClose, classes, field, keyValue }) => {
  const [newValue, setNewValue] = useState('')

  useEffect(() => {
    if (value) {
      setNewValue(value)
    }
  }, [value])

  const handleSubmitTranslationChange = () => {
    onSubmitTranslationChange(newValue)
  }

  const onValueChange = (e) => {
    const value = e.target.value
    setNewValue(value.replace(/\n/g, ''))
  }

  const onKeyDown = (e) => {
    if (e.key== 'Enter' && value !== newValue) {
      handleSubmitTranslationChange()
    }
  }

  return (
    <BasicModalOverlay title={translations('Manage Translations - Edit Translation')}>
      <TextField
        label={translations('Manage Translations - New Translation')}
        value={newValue}
        multiline
        variant='outlined'
        onChange={onValueChange}
        onKeyDown={onKeyDown}
        className={classes.textField}
      />
      <ActionContainer>
        <CloseButton onClick={() => onClose()} raised color='primary'>{translations('Manage Translations - Save Changes Close Button')}</CloseButton>
        <Button disabled={value === newValue} onClick={handleSubmitTranslationChange} raised color='primary'>{translations('Done')}</Button>
      </ActionContainer>
    </BasicModalOverlay>
  )
}

export default withStyles(style)(EditTranslationModal)