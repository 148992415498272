import React from 'react'
import _ from 'lodash'

import { styled } from '@material-ui/core'

import P from '../../../../components/P'
import { DataTable } from '../../../../components/DataTable'
import ReporterRow from '../ReporterRow'

import {
    hooks as reportConfigurationHooks
} from '../../../../store/modules/reportConfiguration'

import { translations } from '../../../../config'

const NoResultsContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    textTransform: 'uppercase'
}))

const ReportersTable = ({ isLoading, formViewId }) => {
    const reporters = reportConfigurationHooks.useFormViewReporters({ formViewId })
    if (isLoading && _.isEmpty(reporters)) {
        return null
    }
    if (_.isEmpty(reporters)) {
        return (
            <NoResultsContainer>
                <P role='status' value={translations('No results found')} />
            </NoResultsContainer>
        )
    }
    return (
        <DataTable
            data={reporters}
            rowComponent={ReporterRow}
            isLoading={isLoading}
            tableId={'reporters-table'}
            tableHeading={'Reporters'}
            headers={[
                { id: 'name', label: translations('User'), options: { align: 'left' } },
                { id: 'email', label: translations('Email'), options: { align: 'left' } },
                { id: 'latestReportDate', label: translations('Reporters - Last Report Date Heading'), options: { align: 'left' } },
                { id: 'fuTotalSent', label: translations('Reporters - Total FollowUps Sent Heading'), options: { align: 'center' } },
                { id: 'fuResponseRate', label: translations('Reporters - FollowUps Response Rate Heading'), options: { align: 'center' } }
            ]}
        />
    )
}

export default ReportersTable
