import { connect } from 'react-redux'
import { compose, withStateHandlers, withHandlers, withPropsOnChange } from 'recompose'
import { push } from 'connected-react-router'
import _ from 'lodash'
import uuid from 'uuid/v4'

import ReportConfiguration from './ReportConfiguration'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as receiverSelectors } from '../../../store/modules/selectedReceiver'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'

import { selectors as formViewSelectors, actions as formViewActions } from '../../../store/modules/formViews'

import modalService from '../../../services/modalService'

const enhancer = compose(
  connect(state => ({
    pageTitle: platformSelectors.getPageTitle(state)(
      'Report Configuration Title'
    ),
    formSchemas: receiverSelectors.getAllSelectedReceiverReportSchemas(state),
    formViews: formViewSelectors.getAllFormViews(state),
    orgDetails: authCombinedSelectors.getDetailsForSelectedOrganisation(state)
  })),
  withStateHandlers(
    () => ({
      isEditing: null,
      type: null,
      name: null,
      category: null
    }),
    {
      toggleEdit: () => id => ({
        isEditing: id
      }),
      setNewReportType: () => value => ({
        type: value
      }),
      setName: () => value => ({
        name: value
      }),
      setCategory: () => value => ({
        category: value
      })
    }
  ),
  withHandlers({
    openModal: () => component => {
      modalService.open({
        component
      })
    },
    closeModal: () => () => {
      modalService.close()
    },
    submitNewReport: ({ dispatch, type, name, category, formViews, orgDetails }) => () => {
      if (!name) return
      const doesNotPreviouslyExist = !formViews.find(el => el.name === name || name.toLowerCase() === 'standard')
      if (doesNotPreviouslyExist) {
        if (type && name && category) {
          const getVersion = _.get(type, 'formViewVersions')
          let viewJSON
          if (getVersion && _.has(getVersion, '0.viewJSON')) {
            viewJSON = getVersion[0].viewJSON
          } else {
            const formSchemaLibrary = new FormSchemaLibrary()
            let schemaVersion = type.name === 'devicesReport' ? _.toString(_.get(orgDetails, 'devicesSchemaVersion')) : null
            const standardBase = formSchemaLibrary.getDefaultView(type.name, schemaVersion)
            viewJSON = standardBase.sections
          }
          /** @type {string} The uuid within the ID top level of the form schema or when based off a form view, the ID nested formSchema object. */
          const formSchemaId = _.find(_.at(type, ['formSchema.id', 'id']))
          const schemaName = _.find(_.at(type, ['formSchema.name', 'name']))
          const audience = _.get(type, 'audience')

          dispatch(formViewActions.setNewSchemaBase({ name, category, schemaName, formSchemaId, viewJSON, audience }))
          dispatch(formViewActions.setViewJSON(viewJSON))
          dispatch(push({
            pathname: `report-configuration/new/${uuid()}`,
            state: {
              viewJSON
            }
          }))
          modalService.close()
        }
      }
    }
  }),
  withPropsOnChange(['name'], props => {
    const { name } = props
    return name
  })
)

export default enhancer(ReportConfiguration)
