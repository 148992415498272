import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  size: 30,
  page: 1,
  users: [],
  total: undefined,
  error: undefined,
  status: undefined,
  filters: {
    statuses: ['PENDING'],
    query: ''
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SEARCH_INVITED_USERS_FRESH:
      return reducers.searchInvitedUsersFresh(state, action)
    case constants.SEARCH_INVITED_USERS_NEXT:
      return reducers.searchInvitedUsersNext(state, action)
    case constants.UPDATE_QUERY_FILTER:
      return reducers.updateQueryFilter(state, action)
    case constants.UPDATE_STATUS_FILTERS:
      return reducers.updateStatusFilters(state, action)
    default:
      return state
  }
}
