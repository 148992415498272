import fsService from '../../../services/fsService'
import modalService from '../../../services/modalService'

import { translations } from '../../../config'

import * as json2xmlConstants from './constants'

class Json2xmlMiddleware {
  downloadXmlOnJson2XmlSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === json2xmlConstants.JSON_2_XML && action.status === 'SUCCESS') {
      fsService.downloadFile({
        data: action.result,
        fileName: 'exported-report.xml',
        mimeType: 'text/xml'
      })
    }
  }

  downloadXmlOnAckJson2XmlSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === json2xmlConstants.ACK_JSON_2_XML && action.status === 'SUCCESS') {
      fsService.downloadFile({
        data: action.result,
        fileName: 'exported-ack.xml',
        mimeType: 'text/xml'
      })
    }
  }
}

export default new Json2xmlMiddleware()
