import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import Header from '../../Header'
import Footer from '../../Footer'
import RootMenu from '../RootMenu'
import MultipartUploadBox from '../../MultipartUploadBox'
import FileManager from '../../FileManager'
import Modal from '../../../components/Modal'
import ToastContainer from '../../../components/ToastContainer'
import NetworkNotification from '../../../components/NetworkNotification'
import ErrorScreen from '../../../components/ErrorScreen'
import SkipToContentLink from '../../../components/SkipToContentLink'
import { env } from '../../../config/environments'
import { translations } from '../../../config'

class RootScreen extends Component {
  state = {
    hasError: false
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.location.hash &&
      this.props.location.hash !== prevProps.location.path
    ) {
      setTimeout(
        () => {
          const element = document.getElementById(
            this.props.location.hash.replace('#', '')
          )
          if (element) {
            element.scrollIntoView()
          }
        },
        0
      )
      return
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      setTimeout(() => {
        window.scrollTo(0, 1)
        setTimeout(() => window.scrollTo(0, 0), 0)
      }, 50)
    }
  }

  componentDidCatch (error, info) {
    this.setState({ hasError: true })
    console.error({ error, info })
  }

  resetError = () => {
    const { goHome } = this.props
    goHome()
    this.setState({ hasError: false })
  }

  render () {
    const {
      children,
      classes,
      footerLogo,
      footerLogoAlt,
      footerLink,
      footerMobileLogo,
      isUnsecuredPage,
      isPublicPage,
      isAppAllowed,
      isLoggedIn,
      isUserAssessor,
      isAssessorsPage,
      displayMode
    } = this.props
    const { hasError } = this.state

    const showHeader = !(isUnsecuredPage || displayMode === 'embedded' || isPublicPage)

    const screenContainerClassName = showHeader ? classes.screenContainer : undefined

    let content = children
    if (isLoggedIn && !isAppAllowed || (isUserAssessor && !isAssessorsPage)) {
      content = <div
        className={classes.noPermissionMessage}
        children={translations('You do not have permission to view this screen')}
      />
    }
    if (hasError) {
      content = <ErrorScreen
        title={translations('App Crash - Message')}
        buttonText={translations('App Crash - Button')}
        onClick={this.resetError}
      />
    }

    const headerComponents = [
      <Header key={'header'} />
    ]

    if (isLoggedIn) {
      headerComponents.push(<RootMenu key={'rootMenu'} />)
    }

    return (
      <div className={classes.container}>
        <SkipToContentLink
          text={translations(`Skip to main content`)}
          contentId='#content'
        />
        <Modal index={0} />
        <Modal index={1} />
        <Modal index={2} />
        <ToastContainer />
        <NetworkNotification />
        {env !== 'production' && <div aria-hidden className={classes.environment}>environment: {env}</div>}
        {showHeader && headerComponents}
        <main id='content' className={screenContainerClassName}>
          {content}
        </main>
        <Footer
          src={footerLogo}
          mobileSrc={footerMobileLogo}
          alt={footerLogoAlt}
          link={footerLink}
        />
        <MultipartUploadBox />
        <FileManager/>
      </div>
    )
  }
}

RootScreen.defaultProps = {
  isLoggedIn: false
}

RootScreen.propTypes = {
  openMenu: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  classes: PropTypes.shape({}).isRequired,
  footerLogo: PropTypes.string,
  footerLogoAlt: PropTypes.string,
  footerLink: PropTypes.string,
  footerMobileLogo: PropTypes.string,
  isUnsecuredPage: PropTypes.bool,
  isPublicPage: PropTypes.bool,
  isUserAssessor: PropTypes.bool,
  isAssessorsPage: PropTypes.bool,
  isAppAllowed: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string.isRequired
  }),
  goHome: PropTypes.func.isRequired
}

export default withStyles(style)(RootScreen)
