import React, { useMemo } from 'react'
import { styled } from '@material-ui/core/styles'
import _ from 'lodash'

import { translations } from '../../../../../config'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import Button from '../../../../../components/Button'
import Types from '../Table/types'

const ChangeList = styled('div')({
  display: 'flex',
  'flex-direction': 'column',
  gap: '10px'
})

const DeleteKey = styled('pre')(({ theme }) => ({
  'background-color': theme.palette.success.light,
  padding: '10px',
  'border-radius': '5px',
  'white-space': 'pre-wrap',
  margin: 0
}))

const ActionContainer = styled('div')({
  'margin-top': '20px',
  display: 'flex',
  gap: '10px'
})

const CloseButton = styled(Button)({
  'margin-left': 'auto'
})

const DeleteTranslationModal = ({ changes, onSubmitTranslationDelete, onClose }) => (
  <BasicModalOverlay title={translations('Manage Translations - Confirm deletion')}>
    {changes && (
      <ChangeList>
        <h3>{translations('Manage Translations - Save Changes Deletion Header')}</h3>
        {changes.map(({ key }) => (
          <DeleteKey>{key.value}</DeleteKey>
        ))}
      </ChangeList>
    )}
    <ActionContainer>
      <CloseButton onClick={() => onClose()} raised color="primary">{translations('Manage Translations - Save Changes Close Button')}</CloseButton>
      <Button onClick={() => onSubmitTranslationDelete(changes)} raised color="primary">{translations('Manage Translations - Save Changes Button')}</Button>
    </ActionContainer>
  </BasicModalOverlay>
)

export default DeleteTranslationModal
