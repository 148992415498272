import { FormHelperText } from '@material-ui/core'

const styles = (theme) => {
    return {
      container: {
        paddingTop: 20,
        paddingLeft: 10,
        paddingRight: 10,
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.up('xs')]: {
          paddingLeft: 20,
          paddingRight: 20
        }
      },
      addSectionContainer : {
        border: '1px solid grey'
      },
      mobileEditButtons: {
        textAlign: 'center'
      },
      addSection : {
        display: 'flex',
        width: '100%',
        padding: 10
      },
      buttonContainer : {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
      },
      checkboxContainer: {
        height: '100px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        border: '1px solid grey',
        padding: 10
      },
      checkbox: {
        width: '100%'
      },
      headerRightContent: {
        marginRight: 20
      },
      buttonsMobile: {
        margin: '15px auto 0'
      },
      buttonStyle: {
          marginRight:10
      },
      listContainer: {
        padding: 18,
      },
      scrollContainer: {
        height: `100%`,
        overflowY: 'auto'
      },
      list: {
        margin: '0 auto',
        listStyle: 'none',
        maxWidth: 540,
        paddingLeft: 0
      },
      listItem: {
        width: '100%',
        justifyContent: 'start',
        backgroundColor: '#fff',
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: 14,
        marginBottom: 14,
        transition: '.3s border-color',
        '&:hover, &:active, &:focus': {
          borderColor: theme.palette.primary.main
        },
        '&:focus $label': {
          textDecoration: 'underline'
        }
      },
      configureButtonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          marginRight: 10
        }
      },
      configureItem: {
        marginRight: 5
      }
    }
  }
  
  export default styles
  