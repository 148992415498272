import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { useSidebar } from '../../../../../contexts'
import { translations } from '../../../../../config'
import { openAddRepeatableInstanceModal } from './utils'

import InsertButton from '../../../../../components/FormviewEditor/InsertButton/InsertButton'
import RepeatableInstance from './components/RepeatableInstance/RepeatableInstance'
import modalService from '../../../../../services/modalService'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
`

const RepeatableInstancesSection = ({ repeatableOptions }) => {
  const { dispatch, actions, state } = useSidebar()

  const {
    repeatableInstances = {}
  } = state

  const {
    data = []
  } = repeatableInstances

  const onAddRepeatableInstance = (payload) => {
    dispatch(actions.addRepeatableInstance(payload))
    modalService.close()
  }

  const onEditRepeatableInstance = (payload) => {
    dispatch(actions.updateRepeatableInstance(payload))
    modalService.close()
  }

  const onDeleteRepeatableInstance = (payload) => {
    dispatch(actions.deleteRepeatableInstance(payload))
    modalService.close()
  }

  return (
    <>
      <Container>
        {data.map(repeatableInstance =>
          <RepeatableInstance
            repeatableInstance={repeatableInstance}
            repeatableOptions={repeatableOptions}
            onEdit={onEditRepeatableInstance}
            onDelete={onDeleteRepeatableInstance}
          />
        )}
      </Container>
      <InsertButton
        onClick={() => openAddRepeatableInstanceModal(onAddRepeatableInstance, repeatableOptions)}
        fullWidth
        aria-label={translations('Report Configuration - Add Repeatable Instance')}
      >
        {translations('Report Configuration - Add Repeatable Instance')}
      </InsertButton>
    </>
  )
}

export default RepeatableInstancesSection