"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schema = void 0;

var _schema = require("../../sabreSAE/1.0.0/schema");

var _schema2 = require("./schema");

var _view = _interopRequireDefault(require("./view"));

var _constants = require("../../../constants");

var _fp = _interopRequireDefault(require("lodash/fp"));

var _formSchemaModifiers = require("./formSchemaModifiers");

var _utils = require("../../mhraR3Report/1.0.0/collections/utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var schema = {
  type: _constants.FORM_TYPE_CONSTANTS.REPORT,
  getFormSchema: function getFormSchema(validators) {
    var formSchema = (0, _utils.combineGetSchemas)([(0, _utils.KeepAndAlterFormSchemaItems)({
      fields: _formSchemaModifiers.sabreSAEFields,
      getFormSchema: _schema.getFormSchema
    }), _schema2.getFormSchema])(validators);
    return formSchema;
  },
  getFormView: function getFormView() {
    return JSON.parse(JSON.stringify(_view["default"]));
  },
  getLocationField: function getLocationField(viewJSON) {
    return undefined;
  },
  attachmentFields: ['footnotes', 'notificationAttachments', 'confirmationAttachments'],
  attachmentTypeGroups: {
    "default": 'footnotes',
    footnotes: 'footnotes',
    notification: 'notificationAttachments',
    confirmation: 'confirmationAttachments'
  }
};
exports.schema = schema;