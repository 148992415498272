import React from 'react'
import PropTypes from 'prop-types'
import style from './style'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

export const H2 = ({value, className, classes}) => (
  <div className={classNames(classes.h2, className)}>
    {value}
  </div>
)

H2.propTypes = {
  value: PropTypes.any
}

export default withStyles(style)(H2)
