const withProvider = (Provider) => {
  if (!Provider) {
    throw new Error('withProvider: Missing Provider')
  }
  return (Component) => {
    if(!Component){
      throw new Error('withProvider: Missing Component')
    }
    return (props) => (
      <Provider>
        <Component {...props} />
      </Provider>
    )
  }
}

export default withProvider