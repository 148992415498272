import React from 'react'
import { styled } from '@material-ui/core/styles'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import ScreenHeading from '../../../components/ScreenHeading'
import VisuallyHidden from '../../../components/VisuallyHidden'
import { Helmet } from 'react-helmet'

import TerminologyTabs from './TerminologyTabs'
import TerminologyFilters from './TerminologyFilters'
import TerminologyEntriesTable from './TerminologyEntriesTable/terminologyEntriesTable'
import TerminologySide from './TerminologySidebar'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from '../../../store/modules/terminology'
import { AddDictionaryButtonSmall } from './AddDictionaryButton'
import NoDictionariesView from './NoDictionariesView'
import { useAbilities } from '../../../ability/ability-context'

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  top: (theme.headerHeight + theme.subheaderHeight),
  left: 0,
  right: 0,
  bottom: 0
}))

const RightContent = styled('div')(({ theme }) => ({
  paddingRight: '10px'
}))

const Main = styled('section')(({ theme }) => ({
  flex: 1,
  background: '#fff',
  height: '100%',
  overflow: 'auto'
}))

const Side = styled('aside')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: 340,
    background: theme.palette.grey[50],
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  }
}))


const TerminologyScreen = (props) => {
  const { isEmpty, isError } = useSelector(selectors.getTerminologyArrangements)
  const {
    pageTitle = {},
  } = props
  const dispatch = useDispatch()
  const ability = useAbilities()
  const canAddDictionary = ability.can('addDictionary', 'terminology')
  React.useEffect(() => {
    return () => {
      dispatch(actions.resetStore())
    }
  }, [dispatch])
  return (
    <div>
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={<ScreenHeading label={'App Name - Terminology'} />}
        rightContent={(!isEmpty && !isError && canAddDictionary) && <RightContent><AddDictionaryButtonSmall/></RightContent>}
      />
      {isEmpty ? <NoDictionariesView/> : <Content>
        <Main>
          <TerminologyTabs />
          <TerminologyFilters />
          <TerminologyEntriesTable />
        </Main>
        <Side>
          <TerminologySide />
        </Side>
      </Content>
      }
    </div>
  )
}

export default TerminologyScreen
