import _ from 'lodash'
import digitalStoreSdk from '../../../digitalStoreSdk'

import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import { getCurrentPlatformId } from '../platforms/selectors'

/**
 * Convert repeatable payload for organisation receivers into format the API/Database will allow
 * @param {array} receiverSchemas - List of organisation receiver from form repeatable
 * @returns {array} Receivers with conditions
 */
const _formatReceiverSchemasForDB = (receiverSchemas = []) => {
  const PREFIX = 'condition_'
  const formattedReceivers = _.map(receiverSchemas, (receiver) => {
    /**
     * Create object from values that start with the prefix `condition_`
     * `condition_example` would become `{ example: 'value' }`
     */
    const conditionValues = _.reduce(receiver, (memo, item, key) => {
      if (_.startsWith(key, PREFIX)) {
        const conditionKey = _.last(_.split(key, PREFIX))
        if (_.isString(item) || _.isArray(item)) {
          memo[conditionKey] = item
        }
      }
      return memo
    }, {})

    /**
     * Turn object into syntax for a condition
     */
    const conditions = _.map(conditionValues, (condition, key) => {
      const currentConditions = _.get(receiver, `conditions.all`) || _.get(receiver, `conditions.any`)
      const matchedCondition = _.find(currentConditions, { fact: key })

      const formattedCondition = {
        fact: key
      }

      // Set the correct operator for the condition or default to `equal`
      // If part of a repeatable then set the 'path' to the fact
      if (matchedCondition) {
        formattedCondition.operator = matchedCondition.operator
        if (_.isString(matchedCondition.path)) {
          formattedCondition.path = matchedCondition.path
        }
      } else {
        formattedCondition.operator = 'equal'
      }

      // Convert value to correct type
      if (formattedCondition.operator === 'inList') {
        formattedCondition.value = _.split(condition, ',')
      } else {
        formattedCondition.value = condition
      }

      return formattedCondition
    })

    return {
      ...receiver,
      conditions: !_.isEmpty(conditions) ? { all: conditions } : {}
    }
  })
  return formattedReceivers
}

const _formatOrgForDB = (fields) => {
  return {
    name: fields.senderorganization || fields.name,
    themeId: fields.themeId,
    regionId: fields.regionId,
    receiverId: fields.receiverId,
    receiverSchemas: _formatReceiverSchemasForDB(fields.receiverSchemas),
    extOrganisationId: fields.extOrganisationId,
    sourceOrganisationId: fields.sourceOrganisationId,
    country: fields.country,
    templateRoleType: fields.templateRoleType,
    details: _.omit(fields, ['name', 'themeId', 'regionId', 'extOrganisationId', 'receiverId', 'receiverSchemas', 'sourceOrganisationId', 'country'])
  }
}

class OrganisationDetailsActions {
  fetchOrganisationById = ({ id, allowForWorkspace = false }) => ({
    type: constants.FETCH_ORGANISATION_BY_ID,
    promise: () => {
      if (allowForWorkspace) {
        return digitalStoreSdk.organisations.fetchOrganisationFromWorkspace({ organisationId: id })
      }
      return digitalStoreSdk.organisations.fetchOrganisation({ id })
    }
  })
  fetchOrganisationReceivers = ({ organisationId }) => {
    return {
      type: constants.FETCH_ORGANISATION_RECEIVERS,
      promise: () => digitalStoreSdk.organisations.fetchOrganisationReceivers({ organisationId })
    }
  }
  createOrganisation = ({ fields }) => {
    return {
      type: constants.CREATE_ORGANISATION,
      promise: (dispatch, getState) => {
        let newOrg = _formatOrgForDB(fields)
        newOrg.regionId = authSelectors.getUserSelectedRegionId(getState())
        newOrg.platformId = getCurrentPlatformId(getState())
        return digitalStoreSdk.organisations.createOrganisation(newOrg)
      }
    }
  }
  updateOrganisation = ({ id, params }) => ({
    type: constants.UPDATE_ORGANISATION,
    payload: { id, ...params },
    promise: () => {
      let updatedOrg = _formatOrgForDB(params)
      return digitalStoreSdk.organisations.updateOrganisation({ id, params: updatedOrg })
    }
  })
  createDrugsLists = (paramsForEachDrugsList = []) => ({
    type: constants.CREATE_DRUGS_LISTS,
    promise: (dispatch, getState) => {
      return Promise.all(paramsForEachDrugsList.map(params => {
        return digitalStoreSdk.drugs.createDrugsList(_.pick(params, [
          'name', 'languageId', 'file', 'organisationId', 'provider'
        ]))
      }))
        .then(() => {
          return this._refetchOrganisation(getState())
        })
    }
  })
  importDrugMeta = (params) => ({
    type: constants.IMPORT_DRUG_META,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.drugs.importDrugMeta(_.pick(params, [
        'file', 'organisationId', 'name'
      ]))
        .then(() => {
          return this._refetchOrganisation(getState())
        })
    }
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
  setProfessionsForOrganisation = ({ organisationId, professions }) => ({
    type: constants.SET_PROFESSIONS_FOR_ORGANISATION,
    promise: () => digitalStoreSdk.professions.setProfessionsForOrganisation({ organisationId, professions })
  })
  setProfessionGroupsForOrganisation = ({ organisationId, professionGroups }) => ({
    type: constants.SET_PROFESSION_GROUPS_FOR_ORGANISATION,
    promise: () => digitalStoreSdk.professionGroups.setProfessionGroupsForOrganisation({ organisationId, professionGroups })
  })
  setLocationsForOrganisation = ({ organisationId, locations, locationProvider }) => ({
    type: constants.SET_LOCATIONS_FOR_ORGANISATION,
    promise: () => digitalStoreSdk.organisations.setLocationsForOrganisation({
      organisationId,
      locations,
      locationProvider
    })
  })
  _refetchOrganisation = (state) => {
    const id = selectors.getCurrentOrganisationId(state)
    return digitalStoreSdk.organisations.fetchOrganisation({ id })
  }
}

export default new OrganisationDetailsActions()
