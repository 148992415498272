import React from 'react'
import fp from 'lodash/fp'
import Button from '@material-ui/core/Button'
import { useShallowEqualSelector } from '../../hooks'
import { useClearFileManager } from './hooks'
import { selectors as fileManagerSelectors, enums as fileManagerEnums } from '../../store/modules/fileManager'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import LargeFileManager from './largeFileManager/largeFileManager'
import Drawer from '@material-ui/core/Drawer'
import CloseIcon from '@material-ui/icons/Close'
import { translations } from '../../config'
import Heading from '../../components/Heading'
import { styled } from '@material-ui/core/styles'

const Header = styled('div')(({ theme }) => ({
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main
}))

const HeaderButton = styled(Button)({ padding: '0' })

const Content = styled(CardContent)({ padding: '0' })
const ManagerCard = styled(Card)({
    padding: '0',
    position: 'fixed',
    right: 10,
    bottom: 10,
    width: '100%',
    maxWidth: 400,
    transition: `0.25s ease-out all`,
    zIndex: 2001
})

const ManagerCardActions = styled(CardActions)(({ theme }) => {
    return {
        backgroundColor: theme.palette.grey['100']
    }
})

const ManagerBox = styled(Box)(({ theme }) => {
    return {
        minHeight: 100,
        padding: '0px 15px'
    }
})

const ManagerStatus = styled('div')(({ theme }) => {
    return {
        padding: '10px 0px'
    }
})

const ManagerDetails = styled('div')(({ theme }) => {
    return {
        paddingBottom: '10px'
    }
})


const ProgressManager = () => {
    const fileManagerStatus = useShallowEqualSelector(fileManagerSelectors.getFileManagerStatus)
    const files = useShallowEqualSelector(fileManagerSelectors.getFiles)
    const total = useShallowEqualSelector(fileManagerSelectors.getTotalFiles)
    const errorCount = useShallowEqualSelector(fileManagerSelectors.getErrorCount)
    const currentIndex = useShallowEqualSelector(fileManagerSelectors.getCurrentOperationIndex)
    const progress = useShallowEqualSelector(fileManagerSelectors.getProgressPercentage)
    const buffer = useShallowEqualSelector(fileManagerSelectors.getBufferPercentage)
    const [isDrawOpen, setIsDrawOpen] = React.useState(false);
    let body = {
        subheading: '',
        detail: '',
    }

    const fileName = fp.getOr('', [currentIndex, 'fileName'], files)
    switch (fileManagerStatus) {
        case fileManagerEnums.fileManagerStatus.IDLE:
            body.subheading = translations('File Manager - Idle Heading')
            body.detail = translations('File Manager - Idle Detail')
            break;
        case fileManagerEnums.fileManagerStatus.IN_PROGRESS:
            body.subheading = translations(`File Manager - In Progress Title`, { total, currentIndex })
            body.detail = translations('File Manager - In Progress Detail', { fileName })
            break;
        case fileManagerEnums.fileManagerStatus.CANCELLED:
            body.subheading = translations('File Manager - Cancelled Title')
            body.detail = translations('File Manager - Cancelled Detail')
            break;
        case fileManagerEnums.fileManagerStatus.COMPLETED:
            body.subheading = translations('File Manager - Completed Title')
            if (errorCount > 0) {
                body.detail = translations('File Manager - Completed With Errors Detail', { errorCount })
            } else {
                body.detail = translations('File Manager - Completed Detail')
            }

            break;
        default:
            body.subheading = translations('File Manager - Idle Heading')
            body.detail = translations('File Manager - Idle Detail')
            break;
    }

    if (fileManagerStatus === fileManagerEnums.fileManagerStatus.UNINTIALISED) {
        return null
    }

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawOpen(!isDrawOpen)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const clearFileManager = useClearFileManager()

    if (!isDrawOpen) {
        return (
            <ManagerCard>
                <Content>
                    <Header>
                        <Heading variant='h2' component='h2'>{translations('File Manager')}</Heading>
                        <HeaderButton
                            onClick={clearFileManager}
                            enabled={fileManagerStatus !== fileManagerEnums.fileManagerStatus.CANCELLED}
                        >
                            <CloseIcon
                                titleAccess={translations('Close')}

                            />
                        </HeaderButton>
                    </Header>
                    {<LinearProgress variant='buffer' value={progress} valueBuffer={buffer} />}
                    <ManagerBox>
                        <ManagerStatus>
                            <Heading variant='h3' component='h3'>{body.subheading}</Heading>
                        </ManagerStatus>
                        <ManagerDetails>
                            {body.detail}
                        </ManagerDetails>
                    </ManagerBox>
                </Content>
                <ManagerCardActions>
                    <Button size='small' onClick={toggleDrawer}>{translations('File Manager - View Files')}</Button>
                </ManagerCardActions>
            </ManagerCard>
        )
    }

    return (
        <Drawer
            anchor='right'
            open={isDrawOpen}
            onClose={toggleDrawer}>
            <LargeFileManager onClose={toggleDrawer} />
        </Drawer>
    )
}


export default ProgressManager

