import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { translations } from '../../../../../config'
import { selectors as platformSelectors } from '../../../../../store/modules/platforms'
import { useAbilities } from '../../../../../ability/ability-context'

import Button from '../../../../../components/Button'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import styled from 'styled-components'

const OptionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`

const PostCreateUserActionsModal = ({
  user: passedUser,
  dismiss
}) => {
  const dispatch = useDispatch()
  const ability = useAbilities()

  const isPermissionsEditorEnabled = useSelector(platformSelectors.getHasPermissionsEditorEnabled)
  const canUserViewPermissions = ability.can('view', 'permissionsEditor')
  const canUserEditPermissions = ability.can('editPermissions', 'permissionsEditor')

  const user = useMemo(() => {
    if (!passedUser) {
      return {}
    }
    return ({
      id: passedUser.id,
      firstName: passedUser.firstName,
      lastName: passedUser.lastName,
      displayName: passedUser.displayName || `${passedUser.firstName} ${passedUser.lastName}`
    })
  }, [passedUser])

  const onViewUser = useCallback(() => {
    dispatch(push(`/users/${_.get(user, 'id')}`))
    dismiss()
  }, [dispatch, dismiss, user])

  const onEditPermisions = useCallback(() => {
    dispatch(push(`/permissions`, { user }))
    dismiss()
  }, [dispatch, dismiss, user])

  const actions = [
    {
      name: translations('Post Create User Actions Modal - View User'),
      action: onViewUser,
      visible: true
    },
    {
      name: translations('Post Create User Actions Modal - Edit User Permissions'),
      action: onEditPermisions,
      visible: isPermissionsEditorEnabled && canUserViewPermissions && canUserEditPermissions
    }
  ]

  return (
    <BasicModalOverlay
      title={translations('Post Create User Actions Modal - Header')}
    >
      <p>{translations('Post Create User Actions Modal - Message')}</p>
      <OptionsContainer>
        {actions.map(action => action.visible && (
          <Button buttonType='primary' onClick={action.action}>{action.name}</Button>
        ))}
      </OptionsContainer>
    </BasicModalOverlay>
  )
}

export default PostCreateUserActionsModal