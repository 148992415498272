"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  'label': '%',
  'value': '02'
}, {
  'label': '+',
  'value': '20'
}, {
  'label': '-',
  'value': '18'
}, {
  'label': '/HPF',
  'value': 'C3'
}, {
  'label': '/MM**3',
  'value': 'C4'
}, {
  'label': '/min',
  'value': '24'
}, {
  'label': 'Bq',
  'value': '38'
}, {
  'label': 'CM',
  'value': 'C67'
}, {
  'label': 'Ci',
  'value': '42'
}, {
  'label': 'Copies/ML',
  'value': 'C61'
}, {
  'label': 'DL',
  'value': 'C74'
}, {
  'label': 'Decreased',
  'value': 'C90'
}, {
  'label': 'Degrees C',
  'value': 'C79'
}, {
  'label': 'F',
  'value': '22'
}, {
  'label': 'FL',
  'value': 'C5'
}, {
  'label': 'G',
  'value': 'C71'
}, {
  'label': 'G/24 Hr',
  'value': 'C6'
}, {
  'label': 'G/DL',
  'value': 'C7'
}, {
  'label': 'G/L',
  'value': 'C8'
}, {
  'label': 'GBq',
  'value': '39'
}, {
  'label': 'HR',
  'value': 'C55'
}, {
  'label': 'IU/L',
  'value': '13'
}, {
  'label': 'IU/mL',
  'value': '06'
}, {
  'label': 'Inches',
  'value': 'C92'
}, {
  'label': 'Increased',
  'value': 'C88'
}, {
  'label': 'KG',
  'value': 'C70'
}, {
  'label': 'KPA',
  'value': 'C65'
}, {
  'label': 'KU/L',
  'value': 'C87'
}, {
  'label': 'L',
  'value': 'C73'
}, {
  'label': 'M',
  'value': 'C66'
}, {
  'label': 'MBq',
  'value': '40'
}, {
  'label': 'MCG',
  'value': 'C72'
}, {
  'label': 'MCG/24 Hr',
  'value': 'C46'
}, {
  'label': 'MCG/DL',
  'value': 'C10'
}, {
  'label': 'MCG/L',
  'value': 'C44'
}, {
  'label': 'MCG/ML',
  'value': 'C11'
}, {
  'label': 'MCKAT/L',
  'value': 'C84'
}, {
  'label': 'MCL',
  'value': 'C76'
}, {
  'label': 'MCMOL/L',
  'value': 'C13'
}, {
  'label': 'MEQ/L',
  'value': 'C16'
}, {
  'label': 'MG/L',
  'value': 'C62'
}, {
  'label': 'MIN',
  'value': 'C54'
}, {
  'label': 'MIU/ML',
  'value': 'C19'
}, {
  'label': 'ML',
  'value': 'C75'
}, {
  'label': 'ML/Min',
  'value': 'C64'
}, {
  'label': 'MM',
  'value': 'C68'
}, {
  'label': 'MM**3',
  'value': 'C60'
}, {
  'label': 'MM/HR',
  'value': 'C56'
}, {
  'label': 'MMOL/KG/Day',
  'value': 'C86'
}, {
  'label': 'MMOL/L',
  'value': 'C21'
}, {
  'label': 'M[iU]',
  'value': '51'
}, {
  'label': 'NG/DL',
  'value': 'C45'
}, {
  'label': 'NG/ML',
  'value': 'C38'
}, {
  'label': 'PG',
  'value': 'C22'
}, {
  'label': 'PG/ML',
  'value': 'C47'
}, {
  'label': 'Pounds',
  'value': 'C91'
}, {
  'label': 'SEC',
  'value': 'C25'
}, {
  'label': 'U',
  'value': '14'
}, {
  'label': 'U/L',
  'value': 'C27'
}, {
  'label': 'U/ML',
  'value': 'C28'
}, {
  'label': 'X10**12/L',
  'value': 'C29'
}, {
  'label': 'X10**3/MCL',
  'value': 'C30'
}, {
  'label': 'X10**3/MM**3',
  'value': 'C31'
}, {
  'label': 'X10**6/MCL',
  'value': 'C32'
}, {
  'label': 'X10**9/L',
  'value': 'C34'
}, {
  'label': '[iU]',
  'value': '49'
}, {
  'label': '[iU]/kg',
  'value': '52'
}, {
  'label': 'kBq',
  'value': '41'
}, {
  'label': 'k[iU]',
  'value': '50'
}, {
  'label': 'mCi',
  'value': '43'
}, {
  'label': 'meq',
  'value': '53'
}, {
  'label': 'mg',
  'value': '01'
}, {
  'label': 'mg/dL',
  'value': '26'
}, {
  'label': 'mg/kg',
  'value': '31'
}, {
  'label': 'mg/m2',
  'value': '33'
}, {
  'label': 'mmHg',
  'value': '25'
}, {
  'label': 'mmol',
  'value': '47'
}, {
  'label': 'mol',
  'value': '46'
}, {
  'label': 'nCi',
  'value': '45'
}, {
  'label': 'ng',
  'value': '30'
}, {
  'label': 'ng/mL',
  'value': '21'
}, {
  'label': 'uCi',
  'value': '44'
}, {
  'label': 'uL',
  'value': '37'
}, {
  'label': 'ug',
  'value': '29'
}, {
  'label': 'ug/kg',
  'value': '32'
}, {
  'label': 'ug/m2',
  'value': '34'
}, {
  'label': 'umol',
  'value': '48'
}];
exports["default"] = _default;