import React from 'react'
import PropTypes from 'prop-types'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'

import { translations } from '../../../../../config'

const RemoveFromBlockModal = (props) => {
  const { onConfirmation, dismiss, title } = props

  return (
    <BasicModalOverlay
      title={title}
      text={translations('Remove From Block Text')}
      actions={[
        {
          text: translations('Confirm'),
          primary: true,
          onClick: () => {
            onConfirmation()
            dismiss()
          }
        },
        {
          text: translations('Cancel'),
          onClick: dismiss
        }
      ]}
    />
  )
}

RemoveFromBlockModal.propTypes = {
  onConfirmation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export default RemoveFromBlockModal
