import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectors as edqmSelectors, actions as edqmActions } from '../../store/modules/edqm'

import LookupInputModal from '../LookupInputModal'

const SEARCH_PLACEHOLDER = 'Search EDQM'

const EDQMLookupInputModal = ({ onChange, onClose, modalIndex, lookupOptions }) => {
  const dispatch = useDispatch()

  const results = useSelector(edqmSelectors.getDrugsForSearchResults)
  const noResults = !useSelector(edqmSelectors.getHasResults)
  const isLoading = useSelector(edqmSelectors.getIsLoading)
  const hasMore = useSelector(edqmSelectors.getHasMore) && !isLoading
  const error = useSelector(edqmSelectors.getError)

  const onSearch = useCallback(({ query }) => {
    dispatch(edqmActions.searchEdqm({ query, lookupOptions }))
  }, [dispatch])

  const onSearchNext = useCallback(({ query }) => {
    dispatch(edqmActions.searchEdqmNext({ query, lookupOptions }))
  }, [dispatch])

  const onCloseLookup = useCallback(() => {
    onClose({ modalIndex })
    dispatch(edqmActions.resetStore())
  }, [dispatch])

  const onChangeLookup = useCallback((value) => {
    onChange(value)
  }, [])

  return (
    <LookupInputModal
      results={results}
      noResults={noResults}
      hasMore={hasMore}
      error={error}
      searchPlaceholder={SEARCH_PLACEHOLDER}
      onSearch={onSearch}
      onSearchNext={onSearchNext}
      onChange={onChangeLookup}
      onClose={onCloseLookup}
    />
  )
}

export default EDQMLookupInputModal
