import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import EditPageScreen from './EditPageScreen'

import { selectors as pagesSelectors } from '../../../store/modules/pages'
import { selectors as organisationDetailsSelectors } from '../../../store/modules/organisationDetails'
import { selectors as languagesSelectors } from '../../../store/modules/languages'

const enhancer = compose(
  connect(state => ({
    page: pagesSelectors.getActivePage(state),
    organisationId: organisationDetailsSelectors.getCurrentOrganisationId(state),
    languageOptions: languagesSelectors.getLanguagesAsOptions(state)
  }))
)

export default enhancer(EditPageScreen)
