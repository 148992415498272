import _ from 'lodash'
import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import uploadService from '../../../services/uploadService'

const CHUNK_SIZE = 6 * 1024 * 1024

class MultipartUploadActions {
  _handleUpload = async ({ dispatch, getState, file, fileName, fileType, uploadId, uploadType }) => {
    if (!file) throw new Error('no file')
    if (!uploadId) throw new Error('no upload id')
    const fileSize = file.size
    const numberOfChunks = Math.ceil(fileSize / CHUNK_SIZE)

    const uploadPromises = []

    for (let index = 1; index <= numberOfChunks; index++) {
      let start = (index - 1) * CHUNK_SIZE
      let end = index * CHUNK_SIZE
      let blob = index < numberOfChunks ? file.slice(start, end) : file.slice(start)

      // Generate presigned URL for each part
      const { url } = await digitalStoreSdk.files.getUploadUrl({
        fileName,
        uploadId,
        partNumber: index,
        uploadType
      })

      // Upload part
      const onProgress = _.debounce((evt) => {
        dispatch(this.setProgress({
          partNumber: index,
          loaded: evt.loaded
        }))
      }, 100)
      const uploadPromise = uploadService.uploadToUrl({
        file: blob,
        url,
        onProgress
      })
      uploadPromises.push(uploadPromise)
    }

    const uploadResponses = await Promise.all(uploadPromises)

    const parts = uploadResponses.map((response, index) => {
      const ETag = response.getResponseHeader('etag')
      const PartNumber = index + 1
      return { ETag, PartNumber }
    })

    return dispatch(this._completeUpload({ fileName, parts, uploadId, uploadType }))
  }

  upload = ({ file, fileName, fileType, uploadType, organisationId }) => ({
    type: constants.UPLOAD,
    promise: (dispatch, getState) => {
      return dispatch(this._startUpload({ file, fileName, fileType, uploadType, organisationId }))
        .then(({ uploadId, fileName }) => {
          return this._handleUpload({ dispatch, getState, file, fileName, fileType, uploadId, uploadType })
        })
    }
  })

  _startUpload = ({ file, fileName, fileType, uploadType, organisationId }) => ({
    type: constants.START_UPLOAD,
    file,
    fileName,
    fileType,
    uploadType,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.files.startUpload({ fileName, fileType, uploadType, organisationId })
    }
  })

  _completeUpload = ({ fileName, parts, uploadId, uploadType }) => ({
    type: constants.COMPLETE_UPLOAD,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.files.completeUpload({
        fileName,
        parts,
        uploadId,
        uploadType
      })
    }
  })

  clear = () => ({
    type: constants.CLEAR
  })

  setProgress = ({ partNumber, loaded }) => ({
    type: constants.SET_PROGRESS,
    partNumber,
    loaded
  })
}

export default new MultipartUploadActions()
