import { translations } from '../../../../../../config'

const schema = [
  {
    id: 'name',
    field: 'Input',
    props: {
      label: translations('Name'),
      name: 'name',
      required: true
    }
  },
  {
    id: 'description',
    field: 'Input',
    props: {
      label: translations('Description'),
      name: 'description',
    }
  }
]

export default schema