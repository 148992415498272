import React from 'react'
import PropTypes from 'prop-types'
import toastService from '../../../../services/toastService'
import { translations } from '../../../../config'
import AddQuestionSelectModal from '../Modals/AddQuestionSelectModal'
import Button from '../../../../components/Button'

const EditButtons = ({
  classes,
  viewJSON,
  sectionNames,
  closeModal,
  openModal,
  addQuestion,
  addSection,
  questionNames,
  editing,
  onSave,
  noPadding = true
}) => (
    <div className={classes.mobileEditButtons}>
      <Button
        className={classes.buttonStyle}
        disabled={!viewJSON.length}
        raised
        color='primary'
        onClick={() =>
          openModal(
            () => (
              <AddQuestionSelectModal
                classes={classes}
                sectionNames={sectionNames}
                closeModal={closeModal}
                openModal={openModal}
                addQuestion={addQuestion}
                addSection={addSection}
                questionNames={questionNames}
              />)
            , noPadding
          )
        }
      >
        {translations('Add')}
      </Button>
      <Button
        raised
        disabled={!editing}
        color='primary'
        onClick={async () => {
          try {
            await onSave()
            toastService.action({
              type: 'success',
              message: translations('Successfully - Save Report Config')
            })
          } catch (error) {
            toastService.action({
              type: 'error',
              message: translations('Failure - Save Report Config')
            })
          }
        }}
      >
        {translations('Save')}
      </Button>
    </div>
  )

EditButtons.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  viewJSON: PropTypes.array,
  sectionNames: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  addQuestion: PropTypes.func,
  addSection: PropTypes.func,
  questionNames: PropTypes.func,
  editing: PropTypes.bool,
  onSave: PropTypes.func,
  noPadding: PropTypes.bool
}

export default EditButtons
