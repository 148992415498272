import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'

import { FormControl } from '@material-ui/core'
import { Dropdown, Input as EditableInput } from '../../../../../components/EditableFields'
import Input from '../../../../../components/Fields/Input'
import * as validators from '../../../../../validators'
import { translations } from '../../../../../config'
import style from '../../style'
import { useAbilities } from '../../../../../ability/ability-context'

const Organisations = (props) => {
  const {
    classes,
    editing,
    formattedSelectedOrganisationNames,
    handleChange,
    isMultiSelector,
    organisationOptions,
    selectedRole,
    isPublic,
    isRolesAndOrganisationsEditable,
    isRolesAndOrganisationsViewable
  } = props
  const ability = useAbilities()
  const canUpdateOrganisation = ability.can('changeUserOrganisations', 'userManagement')

  if (isPublic) return null
  if (!isRolesAndOrganisationsViewable) return null

  const selectedRoleIsAdmin = _.get(selectedRole, 'isAdmin')
  const displayValue = (
    selectedRoleIsAdmin
      ? translations('All Organisations')
      : formattedSelectedOrganisationNames
  )

  const label = translations('User Form - Organisations Dropdown Label')
  const isNotEditable = !isRolesAndOrganisationsEditable || selectedRoleIsAdmin || !canUpdateOrganisation

  return <Grid item xs={12} sm={6}>
    <FormControl className={classes.dropDownStandard}>
      {
        isNotEditable ? (
          // have to do this because cant pass in value to
          // EditableInput because value from redux-form
          // overrides it
          editing
            ? <Input
              label={label}
              value={displayValue}
              disabled
            />
            : <EditableInput
              label={label}
              value={displayValue}
            />
        )
          : <Dropdown
            label={label}
            name={'organisationIds'}
            options={organisationOptions}
            editing={editing}
            validate={[validators.organisationRequired]}
            disabled={!selectedRole}
            multiple={isMultiSelector}
            displayValue={displayValue}
            classes={{ root: classes.dropDownStandard }}
          />
      }
    </FormControl>
  </Grid>
}

export default withStyles(style)(Organisations)
