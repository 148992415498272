import React, { createContext, useEffect, useMemo, useReducer, useState } from 'react'

import _ from 'lodash'
import { isDevMode } from '../../helpers'
import reducer, { moveResource } from '../FormViewContext/reducers'
import * as actions from '../FormViewContext/actions'
import logging from 'use-reducer-logger'
import { usePrevious } from 'react-use'

const initialState = {
  sections: {},
  fields: {},
  isEdited: false,
  versionId: 2
}

const followUpStore = createContext(initialState)
const { Provider } = followUpStore

const FollowUpProvider = ({ children, viewJson = {} }) => {
  const [state, dispatch] = useReducer(
    isDevMode ? logging(reducer) : reducer,
    initialState
  )
  const [sections, setSections] = useState({})
  const [fields, setFields] = useState({})
  const prevSections = usePrevious(sections)
  const prevFields = usePrevious(fields)

  useEffect(() => {
    dispatch(actions.loadFormView(viewJson))
  }, [viewJson])

  useEffect(() => {
    const { sections, fields } = state
    if (!_.isEqual(sections, prevSections)) {
      setSections(sections)
    }
    if (!_.isEqual(fields, prevFields)) {
      setFields(fields)
    }
  }, [state])

  const moveResourceFunction = (payload) => {
    return moveResource(_.cloneDeep(state), payload)
  }
  const getSections = useMemo(() => {
    return _.sortBy(sections, ['index'])
  }, [sections])

  const getFields = useMemo(() => {
    return _.sortBy(fields, ['index'])
  }, [fields])

  return (
    <Provider
      value={{
        state,
        dispatch,
        actions,
        getSections,
        getFields,
        moveResourceFunction
      }}>
      {children}
    </Provider>
  )
}

export default { FollowUpProvider, followUpStore }
