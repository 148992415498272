import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import { promiseReducer } from '../../util'

class LanguagesReducers {
  fetchLanguages = promiseReducer(
    (state, action) => {
      return {
        ...state,
        result: action.result
      }
    }
  )
}

export default new LanguagesReducers()
