import { connect } from 'react-redux'
import { compose } from 'recompose'
import HospitalsUKLookupInput from './HospitalsUKLookupInput'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'

export default compose(
  connect(state => ({
    isUsingHospitalsUKList: authCombinedSelectors.getIsUsingHospitalsUKListForSelectedOrganisation(state)
  }))
)(HospitalsUKLookupInput)
