import _ from 'lodash'
import React, { useEffect } from 'react'
import { getWebsites, getWebsitesStatus } from '../../../store/modules/websiteManagement/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { fetchWebsites } from '../../../store/modules/websiteManagement/asyncActions'

const WebsiteSelectorScreen = () => {
  const dispatch = useDispatch()

  const websites = useSelector(getWebsites)
  const status = useSelector(getWebsitesStatus)
  const websitesExist = !_.isEmpty(websites)
  const isLoading = !_.includes(['FULFILLED', 'REJECTED', 'IS_EMPTY'], status)

  useEffect(() => {
    dispatch(fetchWebsites())
  }, [])

  if (!isLoading) {
    if (!websitesExist) {
      dispatch(push('/website-management/new'))
    } else {
      /**
       * Gets the first one, as limited to one website per org
       * This may change, hence this component exists
       */
      const websiteId = _.get(websites, '0.id')
      dispatch(push(`/website-management/${websiteId}`))
    }
  }

  return (<></>)
}

export default WebsiteSelectorScreen