export default theme => ({
  screen: {
    marginLeft: 15,
    marginRight: 10,
    [theme.breakpoints.up('sm')]: {
      marginLeft: '7.5%',
      marginRight: '7.5%'
    }
  },
  followingList: {
    marginTop: 30,
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  inList: {
    marginTop: 25,
    marginBottom: 25
  },
  withMarginTop: {
    marginTop: 30
  },
  withMarginBottom: {
    marginBottom: 30
  }
})
