import { colors } from '../../../config/theme'

const styles = {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  fieldWrapperDate: {
    width: '50%',
    marginRight: '20px'
  },
  fieldWrapperTime: {
    width: '45%'
  },
  errorStyle: { color: colors.error },
  floatingLabelFocusStyle: { color: colors.accent },
}

export default theme => styles
