import { colors } from '../../../config/theme'
import formStyle from '../../../components/Form/style'

const styles = {
  generalError: {
    height: 13,
    textAlign: 'center',
    textTransform: 'capitalize',
    color: colors.error
  },
  field: {
    maxWidth: '300px',
    width: '100%'
  }
}

export default theme => ({
  ...formStyle,
  ...styles
})
