import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import { styled } from '@material-ui/core/styles'

import { translations } from '../../../config'

const Container = styled('div')(({ theme, justify }) => ({
  display: 'flex',
  justifyContent: justify,
  flexWrap: 'wrap'
}))

const Flag = styled('span')(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(0.25),
  marginBottom: theme.spacing(0.25),
  marginLeft: theme.spacing(1),
  padding: theme.spacing(0.75),
  textTransform: 'uppercase',
  fontSize: 9,
  borderColor: theme.palette.grey[300],
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 5,
  background: '#fff'
}))

/**
 * List of meta data or flag with an optional tooltip
 */
export const Flags = (props) => {
  const { flags, justify } = props

  return (
    <Container justify={justify}>
      {_.map((flags), (flag) => {
        const { id, label, tooltip } = flag
        if (tooltip) {
          return (
            <Tooltip key={id} title={translations(tooltip)} placement='top'>
              <Flag>{translations(label)}</Flag>
            </Tooltip>
          )
        }
        return <Flag key={id}>{translations(label)}</Flag>
      })}
    </Container>
  )
}

Flags.defaultProps = {
  flags: [],
  justify: 'flex-start'
}

Flags.propTypes = {
  /** Array of flags */
  flags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string
  })),
  /** Alignment on the X axis */
  justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly'])
}

export default Flags
