
import React, { useEffect } from 'react'
import _ from 'lodash'
import styles from './style'
import OrganisationPagination from '../../OrganisationPagination/OrganisationPagination'
import FormContentBox from '../../../components/FormContentBox'
import Editable from '../../../components/Editable'
import { actions as organisationsActions, selectors as organisationSelectors } from '../../../store/modules/organisations'

import { getCurrentPlatformId } from '../../../store/modules/platforms/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Can } from '../../../ability/ability-context'
import { translations } from '../../../config'
import RegistrationButton from '../../../components/RegistrationButton'
import { getSenderType } from '../../Platforms/PlatformOrganisations/PlatformOrganisations'

const formId = 'OrganisationManagementForm'
const OrganisationManagementForm = Editable(FormContentBox(formId))

const OrganisationManagementScreen = (props) => {
  const {
    classes
  } = props

  const id = 'organisationManagement'
  const platformId = useSelector(getCurrentPlatformId)
  const organisations = useSelector(organisationSelectors.getOrganisationSearchResults)
  const organisationCount = useSelector(organisationSelectors.getOrganisationSearchCount)
  const dispatch = useDispatch()

  const fetch = ({ query, offset = 0 }) => {
    if (platformId) {
      dispatch(organisationsActions.searchOrganisations({ 
        query, 
        platformId, 
        limit: 100, 
        offset, 
        includeUnassigned: false 
      }))
    }
  }

  useEffect(() => {
    if(platformId) {
      fetch({ query: '' })
    }
  }, [platformId])

  useEffect(() => {
    return (() => {
      dispatch(organisationsActions.clearPaginatedOrganisations())
    })
  }, [])

  const headers = [
    { label: 'Organisation', id: 'organisation', type: 'label', width: '60%' },
    { label: 'Sender Id', id: 'senderId', type: 'label', width: '20%' },
    { label: 'Type', id: 'senderType', type: 'label', width: '20%' },
  ]

  const parseOrganisations = (organisations) => {
   return  _.map(organisations, organisation => {
      const { id, displayName, details } = organisation
      const senderevid = _.get(details, 'senderevid')
      const sendertype = _.get(details, 'sendertype')
      return ({
        id,
        organisation: displayName,
        senderId: senderevid,
        senderType: getSenderType(sendertype),
        assigned: true
      })
    })
  }
  
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <OrganisationPagination
          id={id}
          classes={classes}
          editable={false}
          label={'Organisation Management'}
          Form={OrganisationManagementForm}
          formId={formId}
          fetch={fetch}
          platformId={platformId}
          organisations={organisations}
          organisationCount={organisationCount}
          clickable={true}
          rowPerPageOptions={[5, 10, 50, 100]}
          headers={headers}
          hasAssigned={false}
          hasUnassigned={false}
          parseOrganisations={parseOrganisations}
        />
        <Can I="createOrganisation" an="organisationManagement">
          <RegistrationButton to='/organisations/new'>
            {translations('Add New Organisation')}
          </RegistrationButton>
        </Can>
      </div>
    </div>
  )
}

export default withStyles(styles)(OrganisationManagementScreen)