import { connect } from 'react-redux'

import InvitedUserSearchInput from './InvitedUsersSearchInput'
import {
  actions as invitedUserActions,
  selectors as invitedUserSelectors
} from '../../../../store/modules/invitedUsers'
import { translations } from '../../../../config'

const mapStateToProps = state => ({
  query: invitedUserSelectors.getQuery(state),
  placeholder: translations('Search invited users')
})

const mapDispatchToProps = dispatch => ({
  searchUsers: ({ query }) => dispatch(invitedUserActions.fetchInvitedUsers({ query }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitedUserSearchInput)
