import _ from 'lodash'
import { useEffect, useMemo, useState } from "react"
import sdk from "../../../../../../../digitalStoreSdk"
import TextWithInfoTooltip from "../../../../../../../components/TextWithInfoTooltip"
import { translations } from "../../../../../../../config"

const ReportLocation = ({
  reportId,
  location
}) => {  
  const [changedLocation, setChangedLocation] = useState()
  const locationName = useMemo(() => getLocationName(location), [location])
  const changedLocationName = useMemo(() => getLocationName(changedLocation), [changedLocation])
  
  useEffect(() => {
    (async () => {
      const result = await sdk.reports.checkIfReportLocationHasChanged({ id: reportId })
      setChangedLocation(result)
    })()
  }, [])

  if(changedLocation) {
    return (
      <TextWithInfoTooltip
        text={locationName}
        message={translations('Report Summary Modal - Changed Location Message', { locationName: changedLocationName })}
      />
    )
  }

  return locationName
}

const getLocationName = (location) => {
  const locationName = _.get(location, 'name')
  const parentName = _.get(location, 'parent.name')

  if (!locationName) {
    return null
  }

  if (locationName && !parentName) {
    return locationName
  }

  return `${locationName}, ${parentName}`
}

export default ReportLocation