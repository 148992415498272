import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import Editable from '../Editable'
import P from '../../P'
import styles from './style'
import { EditableDropdown } from '../../Fields'
import * as validators from '../../../validators'

const getValueFromOptions = ({ value, options }) => {
  const matchedOption = _.find(options, (opt) => {
    if (opt && opt.value) return opt.value === value
    return value === opt
  })

  if (matchedOption && matchedOption.label) return matchedOption.label

  return value
}

const mapValuesFromArray = ({ values, options }) => {
  const mapped = _.map(values, value => {
    return getValueFromOptions({ value, options })
  })

  return mapped.join(', ')
}

const EditableSelect = Editable({
  input: (props) => {
    const { validate = [] } = props

    return (
      <EditableDropdown {...props} validate={[ ...validate, validators.valueBlocklist(['#add']) ]} />
    )
  },
  display: ({ label, value, options, ...rest }) => {
    const modifiedValue = value instanceof Array ? mapValuesFromArray({values: value, options}) : getValueFromOptions({ value, options })
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={modifiedValue} />
      </div>
    )
  }
})

export default withStyles(styles)(EditableSelect)
