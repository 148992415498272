
import React, { useEffect } from 'react'
import _ from 'lodash'
import OrganisationPagination from '../../../OrganisationPagination'
import FormContentBox from '../../../../components/FormContentBox'
import FormBody from '../../../../components/Form/FormBody/FormBody'
import Editable from '../../../../components/Editable'
import { Typography } from '@material-ui/core'
import { constants as userDetailsConstants } from '../../../../store/modules/userDetails'
import { actions as userDetailsActions } from '../../../../store/modules/userDetails'
import { actions as organisationsActions } from '../../../../store/modules/organisations'
import { getSelectedUserId, isSelectedUserAdmin } from '../../../../store/modules/userDetails/selectors'
import { getPaginatedOrganisations, getPaginatedOrganisationsCount } from '../../../../store/modules/organisations/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { translations, organisationTypes } from '../../../../config'
import { getCurrentPlatformId } from '../../../../store/modules/platforms/selectors'
import { parseOrganisations } from '../../../Platforms/PlatformOrganisations/PlatformOrganisations'

const formId = userDetailsConstants.USER_ORGANISATIONS_FORM_NAME
const UserOrganisationsForm = Editable(FormContentBox(formId))

const UserOrganisations = (props) => {
  const {
    classes,
    editable,
  } = props

  const id = 'userOrganisations'
  const userId = useSelector(getSelectedUserId)
  const platformId = useSelector(getCurrentPlatformId)

  const organisations = useSelector(getPaginatedOrganisations)
  const organisationCount = useSelector(getPaginatedOrganisationsCount)
  const isAdmin = useSelector(isSelectedUserAdmin)

  const headers = [
    { label: 'Organisation', id: 'organisation', type: 'label', width: '80%' },
    { label: 'Assigned', id: 'assigned', type: 'checkbox', width: '20%' }
  ]
  
  const dispatch = useDispatch()

  const fetch = ({ query, limit, offset }) => {
    if(userId && platformId) {
      dispatch(organisationsActions.fetchPaginatedOrganisations({ userId, platformId, query, limit: 100, offset }))
    }
  }
  const update = (params) => {
    dispatch(userDetailsActions.updateUser({ ...params, id: userId }))
  }

  useEffect(() => {
    return(() => {
      dispatch(organisationsActions.clearPaginatedOrganisations())
    })
  }, [])

  return (
    !isAdmin ?
    <OrganisationPagination
      id={id}
      classes={classes}
      editable={editable}
      label={'User Organisations'}
      Form={UserOrganisationsForm}
      formId={formId}
      fetch={fetch}
      update={update}
      organisations={organisations}
      organisationCount={organisationCount}
      headers={headers}
      parseOrganisations={parseOrganisations}
    />
    :
    <UserOrganisationsForm
      editable={false}
      givenClass={classes.formContainer}
      boxName={translations('User Organisations')}
    >
      <FormBody>
        <Typography variant='body2'>{translations('All Organisations')}</Typography>
      </FormBody>
    </UserOrganisationsForm> 
  )
}

export default UserOrganisations