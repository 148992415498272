import React from 'react'
import style from './style'
import PropTypes from 'prop-types'
import P from '../../../components/P'
import A from '../../../components/A'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../config'

const AppDetails = ({
  userFullName,
  organisationName,
  privacyPolicyLink,
  versionNo,
  buildNo,
  classes
}) => {
  return (
    <div className={classes.container}>
      <P value={userFullName} />
      <P value={organisationName} />
      <P value={`${versionNo}.${buildNo}`} />
      {privacyPolicyLink && <A target='_blank' href={privacyPolicyLink} value={translations('Privacy Policy')} />}
    </div>
  )
}

export default withStyles(style)(AppDetails)
