const style = theme => ({
  root: {
    boxShadow: 'none',
    fontWeight: 500,
    minWidth: 0,
    // whiteSpace: 'nowrap',
    '&:active': {
      boxShadow: 'none'
    },
    'a > &': {
      // has to be inline-block to stop underline in <a>
      textDecoration: 'none',
      display: 'inline-block'
    }
  },
  white: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
  },
  big: {
    paddingTop: 15,
    paddingBottom: 15,
    width: '100%',
    maxWidth: 380
  },
  small: {
    fontSize: 12,
    padding: theme.spacing(1),
    minHeight: 32
  },
  fullWidth: {
    display: 'block',
    width: '100%',
    maxWidth: 'none'
  },
  rightIcon: {
    paddingRight: 10
  },
  hyperlink: {
    textTransform: 'initial',
    textDecoration: 'underline',
    fontWeight: 400
  },
  hyperlinkIcon: {
    verticalAlign: 'text-top'
  }
})

export default style
