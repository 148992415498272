"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schema = void 0;

var _schema = require("../../devicesReport/7.2.0/schema");

var _FSCA = require("../../devicesReport/7.2.0/collections/schemas/FSCA");

var _formHelpers = require("../../devicesReport/7.2.0/formHelpers");

var _utils = require("../../mhraR3Report/1.0.0/collections/utils");

var _formSchemaModifiers = require("./formSchemaModifiers");

var _fp = _interopRequireDefault(require("lodash/fp"));

var _view = _interopRequireDefault(require("./view"));

var _constants = require("../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var schema = {
  type: _constants.FORM_TYPE_CONSTANTS.REPORT,
  getFormSchema: function getFormSchema(validators) {
    var formSchema = (0, _utils.combineGetSchemas)([(0, _utils.KeepAndAlterFormSchemaItems)({
      fields: _formSchemaModifiers.deviceFields,
      getFormSchema: _schema.getFormSchema
    }), _FSCA.getFormSchema])(validators);
    return formSchema;
  },
  getFormView: function getFormView() {
    var viewJSON = _fp["default"].cloneDeep(_view["default"]);

    return viewJSON;
  },
  getLocationField: function getLocationField(viewJSON) {
    return undefined;
  },
  getCountryCode: function getCountryCode(value) {
    return (0, _formHelpers.castToCountryCode)(value);
  }
};
exports.schema = schema;