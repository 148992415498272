
import React from 'react'
import PropTypes from 'prop-types'
import { translations } from '../../../config'
import { FormError } from '../../../components/Form'

import ResetPasswordForm from '../../ResetPassword/ResetPasswordFrom/ResetPasswordForm'

const MigrationFormWrapper = ({
  onSubmit,
  formError
}) => {
  return (
    <div>
      { formError && <FormError>{translations(formError)}</FormError> }
      <ResetPasswordForm onSubmit={onSubmit} />
    </div>
  )
}

MigrationFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formError: PropTypes.string
}

export default MigrationFormWrapper
