import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import Field from '../Field'
import FieldMeta from '../FieldMeta'

const CheckCircle = styled.div`
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  width: ${35}px;
  height: ${35}px;
  border: 1px solid
    ${(p) => p.theme.colors.inputText};
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  input:checked ~ &:before {
    background-color: ${'black'};
  }
  input:checked ~ & {
    background-color: ${'black'};
  }
  &:before {
    display: flex;
    content: '';
    align-items: center;
    justify-content: center;
    width: ${25}px;
    height: ${25}px;
    border: 3px solid white;
    border-radius: 100%;
  }
  ${media.greaterThan('small')`
    width: ${40}px;
    height: ${40}px;
    &:before {
      width: ${30}px;
      height: ${30}px;
      border: 5px solid white;
    }
  `}
`
const RadioInput = styled.input`
  opacity: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  &:focus ~ ${CheckCircle}, &:active ~ ${CheckCircle} {
    outline: 0;
    box-shadow: ${({ theme }) => `0 0 0 3px ${theme.colors.primary}`};
  }
  &:disabled ~ ${CheckCircle} {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`

const RadioLabel = styled.div`
  > div, label {
    padding: 0;
    margin: 0;
  }
  ${props => props.disabled && `> div, label {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }`}
`

const RadioWrapper = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  user-select: none;
  margin: 0 12px;
`

/**
 * Radio 
 */
const Radio = (props) => {
  const {
    id,
    label,
    disabled,
    input: {
      checked,
      value,
      name,
      onChange
    },
  } = props
  return (
    <Field>
      <Container>
        <RadioWrapper>
          <RadioInput
            id={id}
            type='radio'
            name={name}
            checked={checked}
            value={value}
            onChange={onChange}
            disabled={disabled}   
          />
          <CheckCircle/>
        </RadioWrapper>
        <RadioLabel disabled={disabled}>
          <FieldMeta
            name={id}
            label={label}
            required={true}
            disabled={disabled}
          />
        </RadioLabel>
      </Container>
    </Field>
  )
}

Radio.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Whether the radio is disabled */
  disabled: PropTypes.bool,
    /** Input props based from React Final Form
   * 
   * `checked` - Whether the radio is currently selected
   * 
   * `name` - Used to associate label and input
   *
   * `value` - Value of the input
   * 
   * `onChange` - Function called when value of the field has changed
  */
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func
  }),
}

Radio.defaultProps = {
  disabled: false
}

export default Radio
