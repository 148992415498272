const styles = {
  columnStyle: {
    padding: '0 20px',
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
}

export default theme => styles
