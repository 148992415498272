import _ from 'lodash'
import { connect } from 'react-redux'
import { destroy as formDestroy } from 'redux-form'
import { compose, withHandlers, withProps, lifecycle, withPropsOnChange } from 'recompose'
import { goBack } from 'connected-react-router'
import NewAcknowledgement from './NewAcknowledgement'
import { actions as ackDetailsActions, constants as ackDetailsConstants, selectors as ackDetailsSelectors } from '../../../store/modules/ackDetails'
import { selectors as reportDetailsSelectors } from '../../../store/modules/reportDetails'
import { selectors as selectedReceiverSelectors } from '../../../store/modules/selectedReceiver'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as organisationDetailsSelectors, actions as organisationDetailsActions } from '../../../store/modules/organisationDetails'
import FullScreenLoader from '../../../components/FullScreenLoader'

const mapStateToProps = state => {
  return {
    isLoadingReportForAck: reportDetailsSelectors.getIsLoadingForAck(state),
    isLoadingAckDetails: ackDetailsSelectors.getIsLoading(state),
    isLoadingOrganisation: organisationDetailsSelectors.getIsLoading(state),
    organisationId: authSelectors.getUserSelectedOrganisationId(state),
    receiverAckFormSchemaName: selectedReceiverSelectors.getAckFormSchemaName(state),
    getPageTitle: platformSelectors.getPageTitle(state),
    ackFormSchema: ackDetailsSelectors.getAckFormSchema(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganisationReceivers: (organisationId) => {
      return dispatch(organisationDetailsActions.fetchOrganisationReceivers({ organisationId }))
    }
  }
}

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { receiverAckFormSchemaName, fetchOrganisationReceivers, organisationId } = this.props
      if (!receiverAckFormSchemaName) {
        fetchOrganisationReceivers(organisationId)
      }
    }
  }),
  withProps(({ isLoadingReportForAck, isLoadingAckDetails, isLoadingOrganisation, ...props }) => {
    const param = _.get(props, 'match.params.id')
    const isNewFormMode = param && param === 'new'
    const reportId = _.get(props, 'match.params.reportid')
    const isLoadingDetails = isNewFormMode ? isLoadingReportForAck : isLoadingAckDetails

    const isLoading = isLoadingDetails || isLoadingOrganisation
    const editing = isNewFormMode

    return {
      editing,
      isNewFormMode,
      reportId,
      isLoading
    }
  }),
  withPropsOnChange(['receiverAckFormSchemaName', 'ackFormSchema'], (props) => {
    const { receiverAckFormSchemaName, ackFormSchema } = props

    return {
      isSchemaAvailable: _.isString(receiverAckFormSchemaName),
      ackFormSchemaName: _.get(ackFormSchema, 'name')
    }
  }),
  withHandlers({
    onBackClick: ({ dispatch }) => () => {
      dispatch(formDestroy(ackDetailsConstants.NEW_ACK_FORM_NAME))
      dispatch(ackDetailsActions.resetStore())
      dispatch(goBack())
    }
  }),
  FullScreenLoader
)

export default enhancer(NewAcknowledgement)
