import { colors } from '../../config/theme'

const style = theme => ({
  buttonContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    minHeight: theme.bottomBarHeight,
    background: colors.lightGrey,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 5%',
    [theme.breakpoints.down('xs')]: {
      padding: '8px'
    },
    zIndex: 1
  },
  absolute: {
    position: 'absolute'
  },
  relative: {
    position: 'relative'
  }
})

export default style
