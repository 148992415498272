import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'

import ReportConfigurationScreen from './ReportConfigurationScreen'
import ReportConfigurationEditorScreen from './ReportConfigurationEditorScreen'

export default [
  <PrivateRoute key='/report-configuration-beta' path='/report-configuration-beta' exact component={TimedLogoutComponent(ReportConfigurationScreen)} />,
  <PrivateRoute key='/report-configuration-beta/:id' path='/report-configuration-beta/:id' exact component={TimedLogoutComponent(ReportConfigurationEditorScreen)} />,
  <PrivateRoute key='/report-configuration-beta/:id/:tab' path='/report-configuration-beta/:id/:tab' exact component={TimedLogoutComponent(ReportConfigurationEditorScreen)} />
]
