import React from 'react'
import HealthCheckContent from '../HealthCheckContent'
import HealthCheckHeader from '../HealthCheckHeader'

const HealthCheckView = (props) => {
  return (
    <>
      <HealthCheckHeader />
      <HealthCheckContent />
    </>
  )
}

export default HealthCheckView
