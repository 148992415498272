import _ from 'lodash'
import withWidth from '@material-ui/core/withWidth'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { compose, withProps, withHandlers } from 'recompose'
import LookupInput from './LookupInput'
import modalService from '../../../services/modalService'
import {  formValueSelector } from 'redux-form'

const enhancer = compose(
  withWidth(),
  connect(state => ({ state }), dispatch => ({
    changeField: (...props) => dispatch(change(...props))
  })),
  withProps(({ formName, id, state, clearable }) => {
    if (clearable === true) {
      return { inputValue: formValueSelector(formName)(state, id)}
    }
  }),
  withHandlers({
    onClearClick: ({ formName, changeField, id }) => () => {
      changeField(formName, id, '')
    },
    onLookupClick: ({ schema, changeField, id, formValues, label, formName, component, modalIndex = 1, width, lookupOptions, handleChangeOtherFields, ...props }) => (e) => {
      modalService.open({
        component,
        fullScreen: width === 'xs',
        formName,
        label,
        lookupOptions,
        modalIndex,
        onChange: (value) => {
          changeField(formName, id, value.name)

          if (_.isFunction(handleChangeOtherFields)) {
            handleChangeOtherFields(value)
          }
          modalService.close({ modalIndex })
        },
        onClose: () => modalService.close({ modalIndex })
      })
    }
  })
)

export default enhancer(LookupInput)
