import { translations } from '../../../../config'

export const approvalStatuses = [
    { label: translations('Change Request - Approved'), value: 'APPROVED' },
    { label: translations('Change Request - Rejected'), value: 'REJECTED' },
    { label: translations('Change Request - Pending'), value: 'PENDING' },
]

export const changeTypes = [
    { label: translations("Change Request - Type Filter Org User"), value: 'ORG_USER' },
    { label: translations("Change Request - Type Filter New Org"), value: 'NEW_ORG' }
]
