import React, { createContext, useReducer, useEffect, useContext } from 'react'
import { isDevMode } from '../../helpers'
import reducer from './reducers'
import * as actions from './actions'
import * as selectors from './selectors'
import logging from 'use-reducer-logger'

export const initialState = {
  visibility: {
    public: false,
    publish: false,
    sources: []
  },
  general: {
    category: undefined,
    subCategory: undefined,
    audienceId: undefined,
    senderevid: undefined,
    reporttype: undefined,
    observestudytype: undefined,
    emailTemplateId: undefined,
  },
  advanced: {
    isLateralFlowReport: undefined,
    isSyringeReport: undefined,
    aefiLogic: undefined,
    nullFlavours: undefined,
    useEuRules: undefined,
    mandatoryMedDRA: undefined
  },
  timeframe: {
    reminderTimeframe: undefined
  },
  repeatableInstances: {},
  openSections: {
    visibility: true,
    general: false,
    advanced: false,
    repeatableInstances: false
  },
  isEdited: false,
  repeatableInstancesEdited: false
}

const reportConfigurationSidebarStore = createContext(initialState)
const { Provider } = reportConfigurationSidebarStore

const ReportConfigurationSidebarProvider = ({ formView, children }) => {
  const [state, dispatch] = useReducer(
    isDevMode ? logging(reducer) : reducer,
    initialState
  )
  useEffect(() => {
    dispatch(actions.loadFormView(formView))
  }, [formView])
  return (
    <Provider
      value={{
        state,
        dispatch,
        actions,
        selectors
      }}>
      {children}
    </Provider>
  )
}

export const useSidebar = () => useContext(reportConfigurationSidebarStore)

export default { ReportConfigurationSidebarProvider, reportConfigurationSidebarStore }
