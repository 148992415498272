import _ from 'lodash'
import { promiseReducer } from '../../util'

class ReportAcknowledgementsReducers {
  fetchReportAcknowledgements = promiseReducer(
    (state, action) => {
      const results = _.get(action, 'result.results')
      const total = _.get(action, 'result.total')
      return {
        ...state,
        results,
        total,
        page: 1
      }
    }
  )

  fetchReportAcknowledgementsNext = promiseReducer(
    (state, action) => {
      const { page, results } = state
      const newResults = _.get(action, 'result.results')
      const total = _.get(action, 'result.total')

      return {
        ...state,
        results: [...results, ...newResults],
        total,
        page: page + 1
      }
    }
  )
}

export default new ReportAcknowledgementsReducers()
