import { connect } from 'react-redux'
import { compose } from 'recompose'

import MigrationScreen from './MigrationScreen'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

const enhancer = compose(
  connect(state => ({
    pageTitle: platformSelectors.getPageTitle(state)('Account upgrade')
  }))
)

export default enhancer(MigrationScreen)
