export const LOAD_FORM_VIEW = 'reportConfigurationSidebar/LOAD_FORM_VIEW'
export const UPDATE_PUBLIC = 'reportConfigurationSidebar/UPDATE_PUBLIC'
export const UPDATE_PUBLISHED = 'reportConfigurationSidebar/UPDATE_PUBLISHED'
export const UPDATE_SOURCES = 'reportConfigurationSidebar/UPDATE_SOURCES'
export const UPDATE_GENERAL_DETAILS = 'reportConfigurationSidebar/UPDATE_GENERAL_DETAILS'
export const TOGGLE_SECTION = 'reportConfigurationSidebar/TOGGLE_SECTION'
export const UPDATE_ADVANCED_DETAILS = 'reportConfigurationSidebar/UPDATE_ADVANCED_DETAILS'
export const SET_UNEDITED = 'reportConfigurationSidebar/SET_UNEDITED'
export const UPDATE_TIMEFRAME_DETAILS = 'reportConfigurationSidebar/UPDATE_TIMEFRAME_DETAILS'
export const UPDATE_REPEATABLE_INSTANCES = 'reportConfigurationSidebar/UPDATE_REPEATABLE_INSTANCES'
export const ADD_REPEATABLE_INSTANCE = 'reportConfigurationSidebar/ADD_REPEATABLE_INSTANCE'
export const UPDATE_REPEATABLE_INSTANCE = 'reportConfigurationSidebar/UPDATE_REPEATABLE_INSTANCE'
export const DELETE_REPEATABLE_INSTANCE = 'reportConfigurationSidebar/DELETE_REPEATABLE_INSTANCE'

export const ARTEFACT_TYPES = {
    XML_ONLY: 'xmlOnly',
    XML_JSON_FREE_TEXT_DUPLICATION: 'xmlJsonWithFreeTextDuplication'
}