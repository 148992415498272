import { connect } from 'react-redux'

import InvitedUserFilters from './InvitedUsersFilters'
import {
  actions as invitedUserActions,
  selectors as invitedUserSelectors
} from '../../../../store/modules/invitedUsers'

const mapStateToProps = state => ({
  filters: invitedUserSelectors.getFilters(state)
})

const mapDispatchToProps = dispatch => ({
  searchUsers: ({ filters }) => dispatch(invitedUserActions.fetchInvitedUsers({ filters }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitedUserFilters)
