export default () => ({
  formWrapper: {
    marginBottom: 20
  },
  submitWrapper: {
    // width: '100%',
    // display: 'flex'
  },
  submit: {
    // marginLeft: 'auto'
  }
})
