import digitalStoreSdk from '../../../../digitalStoreSdk'
import { store } from '../../../../store'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import * as meddraSelectors from '../../../../store/modules/meddra/selectors'
import i18next from 'i18next'
import fp from 'lodash/fp'

export const createImplementation = () => ({
    minimumSearchLength: 3,
    asyncFunction: async ({ value }) => {
        const state = store.getState()
        const organisationId = authSelectors.getUserSelectedOrganisationId(state)
        const size = meddraSelectors.getSize(state)
        const languageCode = i18next.language

        const searchParams = {
            query: value,
            size,
            organisationId,
            languageCode
        }

        const data = await digitalStoreSdk
            .meddras
            .searchMeddras(searchParams)

        return fp.map(({ name, id, code }) => ({
            label: name,
            value: id,
            meta: {
                code
            }
        }), data.rows)
    },
    renderOption: (option) => {
        return <>
            <span>{option.label}</span>
            <span>{option.meta.code}</span>
        </>

    }
})
