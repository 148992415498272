import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Form, { FormBody } from '../../../components/Form'
import BasicModalOverlay from '../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../config'
import WarningIcon from '@material-ui/icons/Warning'
import toastService from '../../../services/toastService'
import style from './style'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import modalService from '../../../services/modalService'
import { actions as usersActions } from '../../../store/modules/users'

const FORM_NAME = 'USER_PERMANENT_DELETE_FORM'
const UserPermanentDeleteForm = Form(FORM_NAME)

const DeletionModal = (props) => {
  const {
    handleSubmit,
    initialValues,
    classes,
  } = props

  const [ declaration, setDeclaration ] = useState(false)
  const [ retypeText, setRetypeText ] = useState(null)
  const [ enableDelete, setEnableDelete ] = useState(false)
  const dispatch = useDispatch()

  const permanentlyDelete = 'PERMANENTLY DELETE'
  const { id } = initialValues
  
  useEffect(() => {
    if (declaration === true && retypeText === permanentlyDelete) {
      setEnableDelete(true)
    } else {
      setEnableDelete(false)
    }
  }, [declaration, retypeText])

  const { email } = initialValues;

  const closeModal = () => {
    modalService.close()
  }

  let schema = [{
    id: 'declaration',
    field: 'Checkbox',
    props: {
      shrink: true,
      label: translations('User Deletion - Declaration'),
      name: 'declaration',
      onClick: () => setDeclaration(!declaration),
      value: declaration
    }
  },{
    id: 'retype',
    field: 'Input',
    props: {
      shrink: true,
      label: translations('User Deletion - Retype'),
      name: 'retype',
      placeholder: 'PERMANENTLY DELETE',
      onChange: (e) => setRetypeText(e.target.value),
      value: retypeText
    }
  }]

  const submitDelete = async () => {
    const result = await onPermanentDelete()

    if (result) {
      toastService.action({
        type: 'success',
        message: translations('User Deletion - Delete Success')
      })
      closeModal()
      dispatch(push('/users'))
    } else {
      toastService.action({
        type: 'error',
        message: translations('User Deletion - Delete Fail')
      })   
    }
  }

  const actions = [
    {
      success: true,
      text: translations('User Deletion - Confirm Deletion'),
      onClick: submitDelete,
      primary: true,
      className: classes.permanentDeleteButton,
      disabled: !enableDelete
    },
    {
      text: translations('Cancel'),
      onClick: closeModal,
    }
  ]

  const onPermanentDelete = async () => {
    try {
      const reason = 'PERMANENTLY DELETE'
      const requestedAt = new Date()

      const result = await dispatch(usersActions.deleteUserPermanentlyById({ userId: id, reason, requestedAt }))
        
      if (result.success) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  return (
    <BasicModalOverlay
      title={translations('User Deletion - Permanently delete user')}
      actions={actions}
    >
      <UserPermanentDeleteForm onSubmit={handleSubmit} initialValues={initialValues}>
        <div className={classes.warningBar}>
          <div className={classes.warningSign}>
            <WarningIcon/>
          </div>
          <div className={classes.warningMessage}>
            {translations('User Deletion - Statement', {email})}
          </div>
        </div>
        <ContentBoxBody>
          <div className={classes.container}>
            <FormBody
              schema={schema}
              editing
              fullWidthFields
            />
          </div>
        </ContentBoxBody>
      </UserPermanentDeleteForm>
    </BasicModalOverlay>
  )
}

DeletionModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object
}

export default withStyles(style)(DeletionModal)
