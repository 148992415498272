import { colors } from '../../../config/theme'
import logoStyles from '../../../components/MainLogo/style'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background
  },
  innerContainer: {
    position: 'relative',
    padding: '20px'
  },
  forgotLinkWrapper: {
    textAlign: 'center',
    marginTop: 30
  },
  forgotLink: {
    fontSize: 14,
    color: '#000'
  },
  forgotPasswordButton: {
    textTransform: 'initial',
    textDecoration: 'underline'
  }
}

export default theme => ({
  ...styles,
  ...logoStyles
})
