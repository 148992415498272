import ContinueOverlay from '../../components/ContinueOverlay'
import ActionModalOverlay from '../../components/ActionModalOverlay'
import { translations } from '../../config'

import _ from 'lodash'

class ModalService {
  constructor () {
    this._deferred = this._deferred.bind(this)
    this.connect = this.connect.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this._open = this._open.bind(this)
    this.continue = this.continue.bind(this)
    this.close = this.close.bind(this)

    // 2 = the number of modal layers we can handle.
    // if you want more, you will also need to add
    // more <Modal/>s to RootScreen.js
    this._modals = _.times(3, this._deferred)
  }

  _getModal = (modalIndex = 0) => {
    if (modalIndex >= this._modals.length) {
      throw new Error('modalIndex exceeds the number of modals set up')
    } else {
      return this._modals[modalIndex]
    }
  }

  connect ({ open, close, modalIndex }) {
    this._getModal(modalIndex).resolve({ open, close })
  }

  disconnect ({ modalIndex = 0 } = {}) {
    this._modals[modalIndex] = this._deferred()
  }

  close ({ modalIndex } = {}) {
    return this._getModal(modalIndex).promise
      .then(({ close }) => {
        close()
      })
  }

  _open ({ component, props, fullScreen, smallModal, wideModal, largeModal, zoomModal, modalIndex, disableBackdropClick, overflowContent }) {
    return this._getModal(modalIndex).promise
      .then(({ open }) => {
        open({ component, props, fullScreen, smallModal, wideModal, largeModal, zoomModal, disableBackdropClick, overflowContent })
      })
  }

  continue ({ modalIndex, title, text, confirmButtonText = translations('Continue'), wideModal = false, largeModal = false, success = () => {} }) {
    const confirmDeferred = this._deferred()
    const actions = [
      {
        success: true,
        primary: true,
        disabled: false,
        text: confirmButtonText,
        onClick: _.wrap(success, (fn, ...args) => {
          this.close({ modalIndex })
          const onClickResult = fn ? fn(...args) : undefined
          confirmDeferred.resolve(onClickResult)
        })
      }
    ]
    return this._open({
      modalIndex,
      component: ContinueOverlay,
      wideModal,
      largeModal,
      props: {
        title,
        text,
        actions
      }
    })
    .then(() => confirmDeferred.promise)
  }

  action ({
    modalIndex,
    title,
    text,
    actions = [],
    disableBackdropClick = false,
    automaticActionTimeout = 20000,
    ...rest
  }) {
    const { confirmDeferred, actions: wrappedActions } = this._wrapActions(actions, modalIndex)
    return this._open({
      modalIndex,
      component: ActionModalOverlay,
      disableBackdropClick,
      props: {
        ...rest,
        title,
        text,
        actions: wrappedActions,
        automaticActionTimeout
      }
    })
    .then(() => confirmDeferred.promise)
  }

  open ({
    modalIndex,
    component: Component,
    actions = [],
    fullScreen = false,
    smallModal = false,
    wideModal = false,
    largeModal = false,
    zoomModal = false,
    disableBackdropClick = false,
    overflowContent = false,
    ...rest
  }) {
    const { confirmDeferred, actions: wrappedActions } = this._wrapActions(actions, modalIndex)
    return this._open({
      modalIndex,
      component: Component,
      fullScreen,
      smallModal,
      wideModal,
      zoomModal,
      largeModal,
      disableBackdropClick,
      overflowContent,
      props: {
        ...rest,
        actions: wrappedActions,
        dismiss: () => this.close({ modalIndex })
      }
    })
    .then(() => confirmDeferred.promise)
  }
  _wrapActions = (actions, modalIndex) => {
    const confirmDeferred = this._deferred()
    const wrappedActions = _.chain(actions)
      .map(action => {
        if(action.hasFormValidation) {
          return {
            ...action,
            onClick: action.onClick
          }
        }
        if (action.success) {
          return {
            ...action,
            onClick: action.onClick
              ? _.wrap(action.onClick, (fn, ...args) => {
                this.close({ modalIndex })
                confirmDeferred.resolve(fn(...args))
              })
              : action.onClick
          }
        } else {
          return {
            ...action,
            onClick: _.wrap(action.onClick, (fn, ...args) => {
              this.close({ modalIndex })
              const fnResult = fn ? fn(...args) : undefined
              confirmDeferred.reject(fnResult)
            })
          }
        }
      })
      .value()
    return {
      actions: wrappedActions,
      confirmDeferred
    }
  }

  _deferred () {
    let pResolve
    let pReject
    const promise = new Promise((resolve, reject) => {
      pResolve = resolve
      pReject = reject
    })
    return {
      resolve: pResolve,
      reject: pReject,
      promise: promise
    }
  }
}

export default new ModalService()
