import _ from 'lodash'
import { LOCATION_CHANGE, replace } from 'connected-react-router'
import qs from 'qs'
import { selectors as reportsSelectors } from '../auth'
import reportsActions from './actions'
import { REHYDRATE } from 'redux-persist'

import { translations } from '../../../config'
import { constants as reportsConstants } from '../reports'
import modalService from '../../../services/modalService'
import { constants as authConstants } from '../auth'

class ReportsMiddleware {
  loadInitialReportsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const isRoutingToReports = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/report-management'
    const isFirstRendering = _.get(action, 'payload.isFirstRendering') || _.get(action, 'payload.action') === 'PUSH'
    const isLoggedIn = reportsSelectors.getIsLoggedIn(getState())
    if (isRoutingToReports && isLoggedIn && isFirstRendering) {
      const search = action.payload.location.search
      const parseSearch = qs.parse(search, { ignoreQueryPrefix: true })
      const { query } = parseSearch
      if (query) {
        const organisationId = reportsSelectors.getUserSelectedOrganisationId(getState())
        dispatch(reportsActions.changeFilters({ filters: { query, organisationId } }))
        dispatch(reportsActions.searchReportsFresh())
        dispatch(replace('/report-management'))
      } else {
        dispatch(reportsActions.searchReportsRefresh({}))
      }
    }
  }

  deleteReportSuccessMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === reportsConstants.DELETE_REPORT_BY_ID && action.status === 'SUCCESS') {
      modalService.action({
        title: translations('Success'),
        text: translations('Draft report successfully deleted'),
        actions: [
          {
            text: translations('Okay')
          }
        ]
      })
      dispatch(reportsActions.searchReportsRefresh())
    }
  }

  deleteReportFailedMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === reportsConstants.DELETE_REPORT_BY_ID && action.status === 'FAIL') {
      modalService.action({
        title: translations('Error'),
        text: translations('The draft report could not be deleted at this time. Please try again later.'),
        actions: [
          {
            text: translations('Okay')
          }
        ]
      })
      dispatch(reportsActions.searchReportsRefresh())
    }
  }

  resetStoreOnUserLogout = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === authConstants.LOGOUT && action.status === 'SUCCESS') {
      dispatch(reportsActions.resetStore())
    }
  }

  setOrganisationFilterOnOrgSelect = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === authConstants.SELECT_ORGANISATION) {
      const orgId = _.get(action, 'organisationId')
      if (orgId) {
        dispatch(reportsActions.changeFilter({ filter: { name: 'organisationId', value: orgId } }))
      }
    }
  }

  setOrganisationFilterOnRehydrate = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === REHYDRATE) {
      const orgId = _.get(action, 'payload.auth.user.selectedOrganisationId')
      if (orgId) {
        dispatch(reportsActions.changeFilter({ filter: { name: 'organisationId', value: orgId } }))
      }
    }
  }
}

export default new ReportsMiddleware()
