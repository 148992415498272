import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import FullScreenLoader from '../../../components/FullScreenLoader'
import ResourcesScreen from './ResourcesScreen'
import { selectors as resourcesSelectors, actions as resourcesActions } from '../../../store/modules/resources'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as multipartUploadSelectors } from '../../../store/modules/multipartUpload'

const mapStateToProps = state => {
  const resourcesLoading = resourcesSelectors.getIsResourcesLoading(state)
  const linkLoading = resourcesSelectors.getIsLinkLoading(state)
  const resources = resourcesSelectors.getResources(state)
  const isLoading = resourcesLoading || linkLoading
  return {
    hasFailed: resourcesSelectors.getHasFailed(state),
    isLoading,
    isEmpty: !isLoading && (!resources || resources.length === 0),
    resources,
    pageTitle: platformSelectors.getPageTitle(state)('Resources Header')
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    viewResource: ({ dispatch }) => ({ key }) => {
      dispatch(resourcesActions.fetchResource({ key }))
    },
    deleteResource: ({ dispatch }) => ({ key }) => {
      return dispatch(resourcesActions.deleteResource({ key }))
    }, 
  }),
  FullScreenLoader
)(ResourcesScreen)
