import { PENDING, SUCCESS, FAIL } from '../middleware/redux-promise'

/**
 * Handles on success/fail/pending for a Promise action
 * @param {function} mapSuccessState(state, action) - Reducer if success
 * @param {function} mapPendingState(state, action) - Reducer if pending
 * @returns {Function} => Reducer => ReducedState
 */
export const promiseReducer = (
  mapSuccessState = state => state,
  mapPendingState = state => state,
  mapFailState = state => state
) => (state, action) => {
  switch (action.status) {
    case PENDING:
      return {
        ...(mapPendingState(state, action)),
        status: PENDING,
        action: action.type
      }
    case SUCCESS:
      return {
        ...(mapSuccessState(state, action)),
        status: SUCCESS,
        action: undefined,
        error: undefined
      }
    case FAIL:
      return {
        ...(mapFailState(state, action)),
        status: FAIL,
        action: undefined,
        error: action.error.message
      }
  }
}
