import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import ResourceItem from '../../../components/ResourceItem'
import { translations } from '../../../config'

import FileBrowserEnhancer from '../../../components/FileBrowserEnhancer'
import Button from '../../../components/Button'
import toastService from '../../../services/toastService'
import { useSelector } from 'react-redux'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { actions as resourceAction } from '../../../store/modules/resources'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'

import style from './style'
import digitalStoreSdk from '../../../digitalStoreSdk'
import ConfirmationModal from '../../../components/ConfirmationModal'
import modalService from '../../../services/modalService'
import { useDispatch } from 'react-redux'

const ResourcesScreen = ({ resources, viewResource, classes, isEmpty, hasFailed, pageTitle, deleteResource }) => {
  const organisationId = useSelector(authSelectors.getUserSelectedOrganisationIdOrTemplateId)
  const dispatch = useDispatch()
  const [ isLoading, setIsLoading ] = useState(false)

  const canUploadResource = useSelector(authCombinedSelectors.canUploadResource)

  const onClickUpload = async e => {

    setIsLoading(true)
    const file = e.target.files[0]

    try {
      await digitalStoreSdk.resources.uploadResources({ file, organisationId })

      toastService.action({
        type: 'success',
        message: translations('Upload Resource - Upload Successfully'),
        autoHideDuration: 3000
      })

      await dispatch(resourceAction.fetchResourcesForCurrentOrganisation())

      setIsLoading(false)

    } catch (error) {
      toastService.action({
        type: 'error',
        message: error.message,
        autoHideDuration: 3000
      })
      setIsLoading(false)

    }
  }

  function getSubstringAfterSlash(str) {
    return str.split('/').pop()
  }

  const onClickDeleteResource = ({key}) => {
    const fileName = getSubstringAfterSlash(key)
    modalService.open({
      component: ConfirmationModal,
      title: translations('Resources - Confirm Deletion'),
      message: translations('Resources - Delete Message', { fileName }),
      onConfirmation: () => deleteResource({key}).then( async () => {
        setIsLoading(true)
        modalService.close()
        toastService.action({
          type: 'success',
          message: translations('Resources - Delete Success'),
          autoHideDuration: 6000
        })
        await dispatch(resourceAction.fetchResourcesForCurrentOrganisation())
        setIsLoading(false)
      }).catch(error => {
        toastService.action({
          type: 'error',
          message: error.message,
          autoHideDuration: 6000
        })
        setIsLoading(false)
      })
    })
  }

  const UploadButton = () => {
    return (
      <div className={classes.uploadButton}>
        <FileBrowserEnhancer
          onChange={onClickUpload}
          renderContent={({ onClick }) => (
            <Button
              children={translations('Upload Button')}
              onClick={onClick}
              raised
              color={'primary'}
            />
            
          )}
        />
      </div>
    )
  }

  return (
    <div>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={(
          <Fragment>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
        rightContent={canUploadResource && <UploadButton />}
      />
      <div className={classes.listContainerTest}>
        {isLoading && <p>{translations('Loading')}</p>}
        {hasFailed && <div className={classes.resourceListMessage}><Heading variant='h3' component='h2'>{translations('Failed Resources Message')}</Heading></div>}
        {!hasFailed && isEmpty && <div className={classes.resourceListMessage}><Heading variant='h3' component='h2'>{translations('Empty Resources Message')}</Heading></div>}
        {!hasFailed && resources && (
          <ul>{resources.map(resource => <ResourceItem resourceKey={resource.Key} lastModified={resource.LastModified} viewResource={() => viewResource({ key: resource.Key })} onClickDeleteResource={onClickDeleteResource} {...resource} />)}</ul>
        )}
      </div>
    </div>
  )
}

ResourcesScreen.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape({})),
  viewResource: PropTypes.func.isRequired,
  deleteResource: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  isEmpty: PropTypes.bool,
  hasFailed: PropTypes.bool,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(style)(ResourcesScreen)