const sortWithPriority = (arr, topItemCodes, sortBy) => {
  const sortedArr = arr
    .slice()
    .sort((a, b) => {
      if (a[sortBy] > b[sortBy]) return 1
      if (a[sortBy] < b[sortBy]) return -1
      return 1
    })
    .filter((item) => {
      return !topItemCodes.find(i => item[sortBy] === i[sortBy])
    })
  const finalArray = arr
     .filter(item => topItemCodes.find(i => item[sortBy] === i[sortBy]))
     .concat(sortedArr)
  return finalArray
}

export default sortWithPriority
