import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'

import { styled } from '@material-ui/core/styles'

import Heading from '../../Heading'
import Flags from '../Flags'
import { useMoreOptions } from '../../MoreMenu'
import DragHandle from '../DragHandle'

import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import AddIcon from '@material-ui/icons/Add'

import { translations } from '../../../config'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import DividerHeading from '../DividerHeading'
import Conditions from '../../Conditions'
import { useConditions } from '../../../contexts'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(3),
  border: `1px solid ${theme.palette.grey[300]}`
}))

const Header = styled('header')(({ theme, collapsible, disabled, disableDrag }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(disableDrag ? 2 : 5),
  cursor: collapsible ? 'pointer' : 'default',
  backgroundImage: disabled ? `linear-gradient(45deg, ${theme.palette.grey[100]} 25%, #eaeaea 25%, #eaeaea 50%, ${theme.palette.grey[100]} 50%, ${theme.palette.grey[100]} 75%, #eaeaea 75%, #eaeaea 100%)` : 'none',
  backgroundColor: disabled ? theme.palette.grey[100] : '#fff',
  backgroundSize: disabled ? '8px 8px' : 'initial'
}))

const Meta = styled('p')(({ theme }) => ({
  fontSize: 14,
  margin: 0,
  marginTop: 4,
  opacity: 0.7
}))

const Icon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 34,
  height: 34,
  color: theme.palette.grey[800],
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 32,
  background: '#fff'
}))

const Content = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(5)
}))

const Menu = styled('div')(({ theme }) => ({
  color: theme.palette.grey[800]
}))

/**
 * A section groups questions into logical groups.
 */
export const Section = (props) => {
  const {
    id,
    children,
    name,
    isOpen,
    onOpen,
    onEdit,
    onMove,
    onDelete,
    onToggleVisibility,
    displayName,
    visible,
    hideFromForm,
    isFirst,
    isLast,
    index,
    disableDrag,
    conditions,
    onManageConditions,
    onDeleteCondition,
    onCloseAllBlocks,
    canHaveConditions
  } = props
  const [flags, setFlags] = useState([])
  const [disabled, setDisabled] = useState(false)
  const collapsible = _.isFunction(onOpen)
  const draggableId = `${id}`
  const { fieldLookup } = useConditions()

  const { MoreMenu, moreMenuProps, hasMoreMenu } = useMoreOptions({ onEdit, onMove, onDelete, onToggleVisibility, onCloseAllBlocks, visible, isFirst, isLast })

  const onOpenToggle = (event, value) => {
    event.stopPropagation()
    onOpen(!value)
  }

  const getCollapseButton = ({ isOpen, collapsible }) => {
    if (!collapsible) {
      return null
    }
    return (
      <Grid item onClick={(event) => onOpenToggle(event, isOpen)}>
        <IconButton size='small' aria-label={translations(isOpen ? 'Close' : 'Open')}>
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Grid>
    )
  }

  const getHeaderAction = (event, { isOpen, collapsible }) => {
    if (!collapsible) {
      return null
    }
    onOpenToggle(event, isOpen)
  }

  useEffect(() => {
    const flags = []

    if (hideFromForm) {
      flags.push({
        id: 'readonly',
        label: 'Read only',
        tooltip: 'Report Configuration - Read Only help'
      })
    }

    setFlags(flags)
  }, [hideFromForm])

  useEffect(() => {
    setDisabled(!visible)
  }, [visible])

  const conditionsActions = () => {
    const actions = []

    actions.push({
      label: translations('Add condition'),
      onClick: () => onManageConditions(),
      iconComponent: AddIcon
    })

    return actions
  }

  return (
    <Draggable draggableId={draggableId} index={index}>
      {provided => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {!disableDrag && <DragHandle dragHandleProps={provided.dragHandleProps} />}
          <Header collapsible={collapsible} disabled={disabled} disableDrag={disableDrag} onClick={(event) => getHeaderAction(event, { isOpen, collapsible })}>
            <Grid container spacing={2} alignItems='center'>
              {getCollapseButton({ isOpen, collapsible })}
              {!visible && (
                <Grid item>
                  <Icon>
                    <VisibilityOffIcon fontSize={'small'} />
                  </Icon>
                </Grid>
              )}
              <Grid item xs>
                <Grid container spacing={0} alignItems='center'>
                  <Grid item xs>
                    <Grid item xs={12}>
                      <Heading component='h2'>{displayName || name}</Heading>
                    </Grid>
                    <Grid item xs={12}>
                      {displayName && <Meta>{name}</Meta>}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={0} justify='flex-end'>
                      <Grid item>
                        <Flags flags={flags} justify='flex-end' />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {hasMoreMenu && (
                <Grid item>
                  <Menu>
                    <MoreMenu id={id} {...moreMenuProps} />
                  </Menu>
                </Grid>
              )}
            </Grid>
          </Header>
          <Collapse in={isOpen} timeout='auto' unmountOnExit>
            <Content>
              {canHaveConditions &&
                <>
                  <DividerHeading heading={translations('Conditions')} actions={conditionsActions()} />
                  <Conditions
                    conditions={conditions}
                    fieldLookup={fieldLookup}
                    onDelete={onDeleteCondition}
                    showCombinator={true}
                  />
                </>
              }
              <DividerHeading heading={translations('Questions')} />
              {children}
            </Content>
          </Collapse>
        </Container>
      )
      }
    </Draggable >
  )
}

Section.defaultProps = {
  isOpen: true,
  displayName: '',
  hideFromForm: false,
  visible: true,
  isFirst: false,
  isLast: false,
  disableDrag: false
}

Section.propTypes = {
  /** Content to display */
  children: PropTypes.any.isRequired,
  /** Name of section */
  name: PropTypes.string.isRequired,
  /** Overrides the default name in the front end */
  displayName: PropTypes.string,
  /** If the content is visible, requires `onOpen` to be a function */
  isOpen: PropTypes.bool,
  /** Function to use when changing open/closed state */
  onOpen: PropTypes.func,
  /** Section is hidden from the front end forms */
  hideFromForm: PropTypes.bool,
  /** Is the section visible in the form */
  visible: PropTypes.bool,
  /** Callback when section is requested to be edited */
  onEdit: PropTypes.func,
  /** Callback when section is requested to be moved, will be passed the direction */
  onMove: PropTypes.func,
  /** Callback when section is requested to be deleted */
  onDelete: PropTypes.func,
  /** Callback when section's visibility is toggled */
  onToggleVisibility: PropTypes.func,
  /** Is first section in the list */
  isFirst: PropTypes.bool,
  /** Is last section in the list */
  isLast: PropTypes.bool
}

export default Section
