import React from 'react'

import { Rule } from 'react-querybuilder'

import { styled } from '@material-ui/core/styles'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  '.ruleGroup-header + &': {
    marginTop: theme.spacing(2)
  },
  '& .rule': {
    paddingRight: theme.spacing(1) + 30,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  '& .rule-fields, & .rule-operators, & .rule-value': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0
    }
  },
  '& .rule-fields': {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '40%'
    }
  },
  '& .rule-fields .MuiFormLabel-root': {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    height: 0,
    width: 0
  },
  '& .rule-fields .MuiInput-root': {
    margin: 0
  },
  '& .rule-operators': {
    width: '100%',
    maxWidth: 90
  },
  '& .rule-value': {
    flex: 1,
    paddingBottom: 0
  },
  '& .rule-remove': {
    position: 'absolute',
    top: '50%',
    right: theme.spacing(1),
    transform: 'translateY(-50%)'
  }
}))

const ConditionsRule = (props) => {
  return (
    <Container className='rule-row'>
      <Rule {...props} />
    </Container>
  )
}

export default ConditionsRule
