import _ from 'lodash'
import styled from 'styled-components'

import { Tooltip } from '@material-ui/core'

const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 5px;
  justify-content: center;
  z-index: 100;
  color: rgba(0, 0, 0, 0.54);
`

const TooltipIcon = ({
  title = '',
  icon = null
}) => {

  return (
    <TagContainer>
      <Tooltip title={title}>
        {icon}
      </Tooltip>
    </TagContainer>
  )
}

export default TooltipIcon