import _ from 'lodash'
import { createSelector } from 'reselect'

import { PENDING, FAIL } from '../../middleware/redux-promise'
import * as constants from './constants'

export const getHasFailed = state => _.get(state.receivers, 'status') === FAIL
export const getReceivers = state => _.get(state.receivers, 'receivers')
export const getIsLoading = state => _.get(state.receivers, 'status') === PENDING && _.get(state.receivers, 'action') === constants.FETCH_RECEIVERS

export const getReceiversForDropdown = createSelector([
  getReceivers
], (receivers) => {
  return _.map(receivers, receiver => {
    return {
      value: receiver.id,
      label: receiver.name
    }
  })
})
