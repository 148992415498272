import _ from 'lodash'
import { PENDING } from '../../middleware/redux-promise'
export const getIsLoading = state => _.get(state, 'ackDetails.status') === PENDING

export const getError = state => _.get(state, 'ackDetails.error')
export const getStatus = state => _.get(state, 'ackDetails.status')
export const getDetails = state => _.get(state, 'ackDetails.ack.acknowledgementJSON')
export const getXML = state => _.get(state, 'ackDetails.ack.acknowledgementXML')
export const getFileName = state => _.get(state, 'ackDetails.ack.fileName')
export const getAckFormSchema = state => _.get(state, 'ackDetails.ack.formSchema')
export const getAckStatus = state => _.get(state, 'ackDetails.ack.status')
export const getAckId = state => _.get(state, 'ackDetails.ack.id')
export const getAckUserId = state => _.get(state, 'ackDetails.ack.userId')
