// Handle the toggle state between edit and not edit mode
import { translations } from '../../config'

export default ({ input, display }) => {
  return ({ label, displayValue, editing, required, editable = true, ...props }) => {
    // Check for undefined to allow falsy values like 0 or false
    const isUndefined = typeof displayValue === 'undefined'
    return (
      (editable && editing)
        ? input({ label: translations(label), required, ...props })
        : display({ label: `${translations(label)}${required ? ' *' : ''}`, isEmpty: isUndefined, value: isUndefined ? '-' : displayValue, ...props })
    )
  }
}
