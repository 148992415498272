import theme, { colors } from '../../config/theme'

const styles = theme => ({
  tabRoot: {
    textTransform: 'none',
    fontWeight: 'bold',
    minWidth: 0,
    flex: 1,
    opacity: '1 !important',
    marginRight: 10,
    marginLeft: 10,
  },
  tabRootBig: {
    height: 56
  },
  tabLabelContainer: {
    padding: 0
  },
  tabLabelBig: {
    ...theme.h2,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    }
  },
  tabLabelMedium: {
    ...theme.h3,
    fontSize: 15,
    textTransform: 'uppercase'
  },
  tabsRoot: {
    flexShrink: 0
  },
  tabsBorder: {
    borderBottom: `1px solid ${colors.chartLegendsGrey}`
  },
  tabsBorderLight: {
    borderBottom: `1px solid ${colors.border}`
  },
  tabsIndicator: {
    height: '3px'
  },
  tabsIndicatorBig: {
    height: '5px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentContainer: {
    flex: 1,
    overflow: 'auto',
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  displayNone: {
    display: 'none'
  }
})

export default theme => styles(theme)
