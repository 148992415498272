import React, { useState, useMemo } from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/styles'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import P from '../../../../../components/P'
import { assets, translations } from '../../../../../config'
import modalService from "../../../../../services/modalService"
import toastService from "../../../../../services/toastService"

import Diff from './Diff'
import styles from './styles'
import { getChanges } from './helpers'

const toastMergeError = () => toastService.action({
    type: 'error',
    message: translations('Failure - Merging new schema')
})
const toastMergeSuccess = () => toastService.action({
    type: 'success',
    message: translations('Success - Merging new schema')
})

const UpdateModal = (props) => {
    const [isSubmitting, setSubmitting] = useState(false)

    const {
        classes,
        viewJSON,
        formViewDispatch,
        formViewActions,
        edited = false,
        schemaName,
        formViewVersionMeta
    } = props

    const {
        hasUpdates = false,
        addedCount = 0,
        removedCount = 0,
        addedFields,
        removedFields,
        unifiedViewJSON
    } = useMemo(() => {
        return getChanges({ viewJSON, schemaName, formViewVersionMeta })
    }, [viewJSON, schemaName, formViewVersionMeta])

    const actions = useMemo(() => _.compact([
        (hasUpdates || edited) && {
            success: true,
            text: translations('Accept Changes'),
            submitting: isSubmitting,
            onClick: () => handleSubmit(),
            primary: true,
            disabled: isSubmitting
        },
        {
            text: translations('Cancel'),
            onClick: () => modalService.close()
        }
    ]), [edited, isSubmitting])

    const handleSubmit = () => {
        setSubmitting(true)
        try {
            formViewDispatch(formViewActions.loadFormView(unifiedViewJSON))
            formViewDispatch(formViewActions.setEdited())
            formViewDispatch(formViewActions.setFormViewSchemaUpdated())
            modalService.close()
            toastMergeSuccess()
        } catch (ex) {
            toastMergeError()
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <BasicModalOverlay
            title={translations('Schema Updates Check - Modal Heading')}
            actions={actions}
        >
            <ContentBoxBody>
                {edited && (
                    <P type='alert' value={translations('Schema Updates Check - Warning')} />
                )}
                {(hasUpdates && !edited) && (
                    <div className={classes.lists}>
                        <Diff
                            title={translations('Schema Updates Check - Added Fields', { count: addedCount })}
                            count={addedCount}
                            fields={addedFields}
                            direction='ADD'
                        />
                        <Diff
                            title={translations('Schema Updates Check - Removed Fields', { count: removedCount })}
                            count={removedCount}
                            fields={removedFields}
                            direction='REMOVE'
                        />
                    </div>
                )}
                {(!hasUpdates && !edited) && (
                    <div className={classes.checkContainer}>
                        <img className={classes.checkIcon} src={assets.checkcircle} alt='' />
                        <P className={classes.checkLabel} value={translations('Everything is up to date')} />
                    </div>
                )}
            </ContentBoxBody>
        </BasicModalOverlay >
    )
}

export default withStyles(styles)(UpdateModal)
