import theme, { colors } from '../../../config/theme'

const styles = {
  hideLabel: theme.visuallyHidden,
  errorText: {
    color: colors.error,
    marginTop: 0,
    '&:empty': {
      display: 'none'
    }
  }
}

export default () => styles
