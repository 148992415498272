import React from 'react'
import EditableDropdown from '../EditableDropdown'

const ThemeSelect = (props) => {
  return (
    <EditableDropdown {...props} />
  )
}

export default ThemeSelect
