import _ from 'lodash'
import React, { useEffect, useRef } from 'react'
import ScreenHeading from '../../../components/ScreenHeading'
import SubHeader from '../../../components/SubHeader'
import WebsiteManagementTabs from '../WebsiteManagementTabs'
import WebsiteManagementSidebar from '../WebsiteManagementSidebar'
import EditorLayout from '../../../components/EditorLayout'
import WebsiteManagementContext, { useWebsites } from '../contexts'
import SaveWebsite from '../SaveWebsite'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWebsite } from '../../../store/modules/websiteManagement/asyncActions'
import { getSelectedWebsite, getSelectedWebsiteStatus } from '../../../store/modules/websiteManagement/selectors'
import { destroy } from 'redux-form'
import { SETTINGS_FORM_NAME, THEME_FORM_NAME } from '../../../store/modules/websiteManagement/constants'
import WebsiteManagementBackBar from './BackBar'
import PageOverlay from '../PageOverlay'

const WebsiteManagementScreen = ({ }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const id = _.get(params, 'id')
  const isNewWebsite = _.eq(id, 'new')

  const selectedWebsite = useSelector(getSelectedWebsite)
  const selectedWebsiteStatus = useSelector(getSelectedWebsiteStatus)
  const isLoading = selectedWebsiteStatus === 'PENDING'

  useEffect(() => {
    if (id && !isNewWebsite) {
      dispatch(fetchWebsite({ id }))
    }
    return () => {
      dispatch(destroy(SETTINGS_FORM_NAME))
      dispatch(destroy(THEME_FORM_NAME))
    }
  }, [])

  return (
    <WebsiteManagementContext.WebsiteManagementProvider isNewWebsite={isNewWebsite} selectedWebsite={selectedWebsite} isLoading={isLoading}>
      <PageOverlay />
      <EditorLayout
        isLoading={false}
        header={
          <SubHeader
            leftContent={<WebsiteManagementBackBar />}
            centerContent={<ScreenHeading label={'App Name - Website Management'} />}
            rightContent={
              <SaveWebsite
                isNewWebsite={isNewWebsite}
              />
            }
            isLoading={false}
          />
        }
        main={<WebsiteManagementTabs />}
        side={<WebsiteManagementSidebar />}
      />
    </WebsiteManagementContext.WebsiteManagementProvider>
  )
}

export default WebsiteManagementScreen