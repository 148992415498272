"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateVaccineBranch = exports.isTreatmentNotAnswered = exports.isTreatment = exports.isStudyBranch = exports.isNotTreatment = exports.isCovid19VaccineLogicNotActive = exports.isCovid19VaccineLogicActive = exports.isCovid19LogicActive = exports.hasTestedPositivePostVaccine = exports.hasTestedPositive = exports.hasTakenVaccine = exports.hasNotTestedPositivePostVaccine = exports.hasNotTakenVaccine = exports.hasKnownVaccineDate = exports.hasKnownSymptomsStartDate = exports.hasKnownSymptomsEndDate = exports.hasExperiencedSymptoms = exports.handleDrugIndicationVisibility = exports.generateVaccineStateSelectors = exports.dosage3 = exports.dosage2 = exports.computeWasCovid19Treatment = exports.computeVisibleDrugsSection = exports.computeTestResult = exports.computeReactionOutcomeOptions = exports.computeReactionDrugValidation = exports.computePartialDateValidation = exports.computeNoIfVaccineTaken = exports.computeHelperDateText = exports.computeDrugIndication = exports.clearIfVaccineTaken = exports.clearIfVaccineNotTaken = exports.clearIfVaccineKnownDate = exports.clearIfSymptomsStartDateNotKnown = exports.clearIfSymptomsEndDateNotKnown = exports.clearIfNoSymptoms = exports.clearIfHasNotTestedPositivePostVaccine = exports.booster1 = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var computedHidden = _interopRequireWildcard(require("../computedHidden"));

var computedValue = _interopRequireWildcard(require("../computedValue"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var isCovid19LogicActive = computedHidden.fieldIs('_parentFormValues.covid19logic', true);
exports.isCovid19LogicActive = isCovid19LogicActive;
var isCovid19VaccineLogicActive = computedValue.isFieldLogicActive('covid19vaccinelogic');
exports.isCovid19VaccineLogicActive = isCovid19VaccineLogicActive;
var isCovid19VaccineLogicNotActive = computedValue.not(isCovid19VaccineLogicActive);
exports.isCovid19VaccineLogicNotActive = isCovid19VaccineLogicNotActive;
var hasExperiencedSymptoms = computedHidden.fieldIs('covid19symptomsexperienced', 'Yes');
exports.hasExperiencedSymptoms = hasExperiencedSymptoms;
var hasKnownSymptomsStartDate = computedHidden.fieldIs('covid19symptomsstartknown', 'Yes');
exports.hasKnownSymptomsStartDate = hasKnownSymptomsStartDate;
var hasKnownSymptomsEndDate = computedHidden.fieldIs('covid19symptomsendknown', 'Yes');
exports.hasKnownSymptomsEndDate = hasKnownSymptomsEndDate;
var hasNotTakenVaccine = computedHidden.fieldIs('covid19patienttakenvaccine', 'No');
exports.hasNotTakenVaccine = hasNotTakenVaccine;
var hasTakenVaccine = computedValue.not(hasNotTakenVaccine);
exports.hasTakenVaccine = hasTakenVaccine;

var computeVisibleDrugsSection = function computeVisibleDrugsSection(formValues) {
  var hasTakenBooster = _fp["default"].compose(_fp["default"].any(function (response) {
    return response === 'Yes';
  }), _fp["default"].at(['covid19vaccinebooster1taken']))(formValues);

  if (hasTakenBooster) {
    return false;
  }

  var aDosageHasNotBeenTaken = _fp["default"].compose(_fp["default"].any(function (response) {
    return response === 'No';
  }), _fp["default"].at(['covid19patienttakenvaccine', 'covid19vaccinedosage2taken', 'covid19vaccinedosage3taken']))(formValues);

  return aDosageHasNotBeenTaken;
};

exports.computeVisibleDrugsSection = computeVisibleDrugsSection;
var hasKnownVaccineDate = computedHidden.fieldIs('covid19patientknowndate', 'Yes');
exports.hasKnownVaccineDate = hasKnownVaccineDate;
var clearIfVaccineKnownDate = computedValue.clearIfNot(hasKnownVaccineDate);
exports.clearIfVaccineKnownDate = clearIfVaccineKnownDate;
var clearIfVaccineTaken = computedValue.clearIfNot(hasNotTakenVaccine);
exports.clearIfVaccineTaken = clearIfVaccineTaken;
var clearIfVaccineNotTaken = computedValue.clearIfNot(hasTakenVaccine);
exports.clearIfVaccineNotTaken = clearIfVaccineNotTaken;
var computeNoIfVaccineTaken = computedValue.noopOrCallback(hasNotTakenVaccine, _fp["default"].always('2'));
exports.computeNoIfVaccineTaken = computeNoIfVaccineTaken;

var generateVaccineStateSelectors = function generateVaccineStateSelectors(fields) {
  var _ref;

  var hasKnownDate = computedHidden.fieldIs(fields.knownDate, 'Yes');
  var hasNotTaken = computedHidden.fieldIs(fields.taken, 'No');
  var hasTaken = computedValue.not(hasNotTaken);
  var clearIfHasTaken = computedValue.clearIfNot(hasNotTaken);

  var checkPreceedingDosageDate = function checkPreceedingDosageDate(validators) {
    return validators.isDateAfterOrEqual(fields.preceedingDosageDueDate, 'previous dosage');
  };

  return _ref = {
    hasTaken: hasTaken,
    hasNotTaken: hasNotTaken,
    visibleIfHasNotTaken: computedHidden.visibleIf(hasNotTaken),
    clearIfHasTaken: clearIfHasTaken,
    hasKnownDate: hasKnownDate,
    visibleIfHasKnownDate: computedHidden.visibleIf(hasKnownDate),
    clearIfNoKnownDate: computedValue.clearIfNot(hasKnownDate)
  }, _defineProperty(_ref, "hasTaken", hasTaken), _defineProperty(_ref, "checkPreceedingDosageDate", checkPreceedingDosageDate), _ref;
};

exports.generateVaccineStateSelectors = generateVaccineStateSelectors;
var dosage2 = generateVaccineStateSelectors({
  knownDate: 'covid19vaccinedosage2knowndate',
  taken: 'covid19vaccinedosage2taken',
  preceedingDosageDueDate: 'covid19vaccineduedate'
});
exports.dosage2 = dosage2;
var dosage3 = generateVaccineStateSelectors({
  knownDate: 'covid19vaccinedosage3knowndate',
  taken: 'covid19vaccinedosage3taken',
  preceedingDosageDueDate: 'covid19vaccinedosage2duedate'
});
exports.dosage3 = dosage3;
var booster1 = generateVaccineStateSelectors({
  knownDate: 'covid19vaccinebooster1knowndate',
  taken: 'covid19vaccinebooster1taken',
  preceedingDosageDueDate: 'covid19vaccinebooster1duedate'
});
exports.booster1 = booster1;
var hasTestedPositive = computedHidden.fieldIs('covid19test', 'Yes - Positive COVID-19 test');
exports.hasTestedPositive = hasTestedPositive;
var hasTestedPositivePostVaccine = computedHidden.fieldIs('covid19postvaccinepositive', 'Yes');
exports.hasTestedPositivePostVaccine = hasTestedPositivePostVaccine;
var hasNotTestedPositivePostVaccine = computedValue.not(hasTestedPositivePostVaccine);
exports.hasNotTestedPositivePostVaccine = hasNotTestedPositivePostVaccine;
var clearIfHasNotTestedPositivePostVaccine = computedValue.clearIfNot(hasTestedPositivePostVaccine);
exports.clearIfHasNotTestedPositivePostVaccine = clearIfHasNotTestedPositivePostVaccine;
var computeTestResult = computedValue.clearOrCallback(hasTestedPositive, _fp["default"].always('Coronavirus test positive'));
exports.computeTestResult = computeTestResult;
var clearIfNoSymptoms = computedValue.clearIfNot(hasExperiencedSymptoms);
exports.clearIfNoSymptoms = clearIfNoSymptoms;
var clearIfSymptomsStartDateNotKnown = computedValue.clearIfNot(hasKnownSymptomsStartDate);
exports.clearIfSymptomsStartDateNotKnown = clearIfSymptomsStartDateNotKnown;
var clearIfSymptomsEndDateNotKnown = computedValue.clearIfNot(hasKnownSymptomsEndDate);
exports.clearIfSymptomsEndDateNotKnown = clearIfSymptomsEndDateNotKnown;
var isTreatment = computedHidden.fieldIs('covid19drugindication', "1");
exports.isTreatment = isTreatment;
var isNotTreatment = computedHidden.fieldIs('covid19drugindication', "2");
exports.isNotTreatment = isNotTreatment;

var isTreatmentNotAnswered = _fp["default"].anyPass([computedHidden.fieldIs('covid19drugindication', undefined), computedHidden.fieldIs('covid19drugindication', null)]);

exports.isTreatmentNotAnswered = isTreatmentNotAnswered;

var isDrugAVaccine = _fp["default"].allPass([isCovid19VaccineLogicActive, computedHidden.fieldIs('drugcharacterization', '1')]);

var computeDrugIndication = _fp["default"].cond([[isDrugAVaccine, computedValue.always('COVID-19 vaccination')], [isTreatment, computedValue.always('Corona virus infection')], [_fp["default"].identity, computedValue.noop]]);

exports.computeDrugIndication = computeDrugIndication;

var computeWasCovid19Treatment = _fp["default"].cond([[isDrugAVaccine, computedValue.always('Yes')], [_fp["default"].allPass([isCovid19LogicActive, isTreatmentNotAnswered, computedHidden.fieldIsDefined('drugindication')]), computedValue.always('No, it was for another reason')], [_fp["default"].identity, computedValue.noop]]);

exports.computeWasCovid19Treatment = computeWasCovid19Treatment;

var handleDrugIndicationVisibility = _fp["default"].cond([[isDrugAVaccine, computedHidden.hide], [isCovid19LogicActive, _fp["default"].cond([[isTreatment, computedHidden.hide], [isNotTreatment, computedHidden.show], [isTreatmentNotAnswered, computedHidden.hide]])], [_fp["default"].identity, computedHidden.show]]);

exports.handleDrugIndicationVisibility = handleDrugIndicationVisibility;
var isStudyBranch = computedValue.branch(isCovid19VaccineLogicActive);
exports.isStudyBranch = isStudyBranch;
var computeReactionOutcomeOptions = isStudyBranch(function () {
  return [{
    value: '1',
    label: 'Recovered/resolved'
  }, {
    value: '2',
    label: 'Recovering/resolving'
  }, {
    value: '3',
    label: 'Not recovered/not resolved'
  }, {
    value: '4',
    label: 'Recovered/resolved with sequelae'
  }, {
    value: '6',
    label: 'Unknown'
  }];
}, function () {
  return [{
    value: '1',
    label: 'Recovered/resolved'
  }, {
    value: '2',
    label: 'Recovering/resolving'
  }, {
    value: '3',
    label: 'Not recovered/not resolved'
  }, {
    value: '4',
    label: 'Recovered/resolved with sequelae'
  }, {
    value: '5',
    label: 'Fatal'
  }, {
    value: '6',
    label: 'Unknown'
  }];
});
exports.computeReactionOutcomeOptions = computeReactionOutcomeOptions;
var validateVaccineBranch = computedValue.branch(function (_value, formValues) {
  return isCovid19VaccineLogicActive(formValues);
});
exports.validateVaccineBranch = validateVaccineBranch;

var computePartialDateValidation = function computePartialDateValidation(validators) {
  return validators.handle(validateVaccineBranch(validators.isAcceptedDateFormat(['YYYYMMDD']), validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])));
};

exports.computePartialDateValidation = computePartialDateValidation;
var computeHelperDateText = isStudyBranch(function () {
  return 'YYYY/MM/DD';
}, function () {
  return 'YYYY, YYYY/MM, YYYY/MM/DD';
});
exports.computeHelperDateText = computeHelperDateText;

var computeReactionDrugValidation = function computeReactionDrugValidation(validators) {
  return validators.handle(validateVaccineBranch(validators.earliestDateisAfter('_parentFormValues.drug', 'drugstartdate', '_parentFormValues.reaction', 'reactionstartdate', 'Reaction Start Date'), validators.earliestDateisBefore('_parentFormValues.drug', 'drugstartdate', '_parentFormValues.reaction', 'reactionstartdate', 'Reaction Start Date', function (drug) {
    return drug.drugcharacterization === '1';
  })));
};

exports.computeReactionDrugValidation = computeReactionDrugValidation;