"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var getAudienceIds = function getAudienceIds(values) {
  return _lodash["default"].uniq(values.formViews.map(function (formView) {
    return _lodash["default"].get(formView, 'audienceId');
  }));
};

var isOnlyOneOccupation = function isOnlyOneOccupation(values) {
  var audienceIds = getAudienceIds(values);
  return audienceIds.length === 1;
};

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'occupation',
    field: 'Dropdown',
    computedHidden: isHiddenOccupation,
    computedOptions: calculateOccupationOptions,
    computedValue: function computedValue(values) {
      var audienceIds = getAudienceIds(values);

      if (isOnlyOneOccupation(values)) {
        return audienceIds[0];
      }
    },
    props: {
      shrink: true,
      name: 'occupation',
      label: 'Are you a member of the public or a healthcare professional?',
      required: true
    }
  }, {
    id: 'reportingAbout',
    field: 'Dropdown',
    computedHidden: isHiddenReportingAbout,
    computedValue: function computedValue(values) {
      var newValue = isHiddenReportingAbout(values) ? '' : values.reportingAbout;
      return newValue;
    },
    computedOptions: calculateFormSchemaOptions,
    props: {
      name: 'reportingAbout',
      label: 'What are you reporting about?',
      shrink: true,
      required: true
    }
  }, {
    id: 'relatesTo',
    field: 'Dropdown',
    computedHidden: isHiddenRelatesTo,
    computedValue: function computedValue(values) {
      return isHiddenRelatesTo(values) ? '' : values.relatesTo;
    },
    computedOptions: calculateRelatesToOptions,
    props: {
      name: 'relatesTo',
      label: 'What does your report relate to?',
      shrink: true,
      required: true
    }
  }, {
    id: 'formViewId',
    field: 'Radiolist',
    computedHidden: isHiddenFormViewSelection,
    computedOptions: filterFormViews,
    props: {
      name: 'formViewId',
      label: 'Please select from the available report forms below:',
      emptyMessage: 'No matching report forms found',
      shrink: true,
      required: true
    }
  }];
};

exports.getFormSchema = getFormSchema;

var isHiddenOccupation = function isHiddenOccupation(values) {
  var existingUserWithOccupation = values.existingUserWithOccupation;
  return existingUserWithOccupation || isOnlyOneOccupation(values) && values.occupation !== 1;
};

var isHiddenReportingAbout = function isHiddenReportingAbout(values) {
  if (isOnlyOneOccupation(values) && values.occupation === 1) {
    return true;
  }

  return !_lodash["default"].includes([2, 3], _lodash["default"].toNumber(values.occupation));
};

var isHiddenRelatesTo = function isHiddenRelatesTo(values) {
  if (isHiddenReportingAbout(values)) {
    return true;
  }

  return !_lodash["default"].includes(['medicine', 'vaccine'], values.reportingAbout);
};

var isHiddenFormViewSelection = function isHiddenFormViewSelection(values) {
  var isVisibleReportingAbout = !isHiddenReportingAbout(values);
  var isVisibleRelatesTo = !isHiddenRelatesTo(values);

  if (isVisibleReportingAbout && isVisibleRelatesTo && values.relatesTo) {
    return false;
  }

  if (isVisibleReportingAbout && !isVisibleRelatesTo && values.reportingAbout) {
    return false;
  }

  return true;
};

var calculateCategoryOptions = function calculateCategoryOptions(initialOptions) {
  return function (values) {
    var audienceId = values.occupation;
    var categories = {};
    var formViews = values.formViews;

    var _iterator = _createForOfIteratorHelper(formViews),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var formView = _step.value;
        var category = formView.category;
        var formViewAudienceId = formView.audienceId;

        var matchingAudience = _lodash["default"].toString(formViewAudienceId) === _lodash["default"].toString(audienceId);

        if (!categories[category] && matchingAudience) {
          categories[category] = category;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    var options = _lodash["default"].chain(categories).map(function (category) {
      var option = initialOptions[category];
      return option;
    }).compact().sortBy('priority').value();

    return options;
  };
};

var calculateOccupationOptions = function calculateOccupationOptions(values) {
  var initialOptions = [{
    label: 'Healthcare professional',
    value: 2
  }, {
    label: 'Member of the public',
    value: 3
  }];
  var audienceIds = getAudienceIds(values);
  var occupations = initialOptions.filter(function (occupation) {
    return audienceIds.includes(occupation.value);
  });
  return occupations;
};

var calculateFormSchemaOptions = function calculateFormSchemaOptions(values) {
  var initialOptions = {
    'medicine': {
      label: 'Medicine',
      value: 'medicine',
      priority: 0
    },
    'vaccine': {
      label: 'Vaccine',
      value: 'vaccine',
      priority: 1
    },
    'devices': {
      label: 'Devices',
      value: 'devices',
      priority: 2
    }
  };
  var options = calculateCategoryOptions(initialOptions)(values);
  return options;
};

var calculateRelatesToOptions = function calculateRelatesToOptions(values) {
  var initialOptions = {
    medicine: {
      label: 'A suspected side effect',
      value: 'sideEffect',
      priority: 0
    },
    fake: {
      label: 'A suspected fake product',
      value: 'fake',
      priority: 1
    },
    defective: {
      label: 'A suspected defective product',
      value: 'defective',
      priority: 2
    }
  };
  var options = calculateCategoryOptions(initialOptions)(values);
  return options;
};

var filterFormViews = function filterFormViews(values) {
  var formViews = values.formViews;
  var audienceId = Number(values.occupation);
  var category = values.reportingAbout;
  var relatesTo = values.relatesTo;
  var filteredFormViews = [];
  var filterValue = category;

  if (!isHiddenFormViewSelection(values)) {
    if (audienceId && category) {
      if (!isHiddenRelatesTo(values)) {
        if (_lodash["default"].includes(['defective', 'fake'], relatesTo)) {
          filterValue = relatesTo;
        }
      }

      filteredFormViews = _lodash["default"].filter(formViews, {
        audienceId: audienceId,
        category: filterValue
      });

      if (filteredFormViews.length === 1) {
        values.forceSubmit(values);
      }
    }

    return filteredFormViews;
  }
};