import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import CompanyAccountRequestForm from '../CompanyAccountRequestForm'
import MainLogo from '../../../components/MainLogo'
import BackBar from '../../../components/BackBar'
import VisuallyHidden from '../../../components/VisuallyHidden'

const CompanyAccountRequestScreen = ({ classes, success, goBack, pageTitle }) => (
  <div className={classes.container}>
    <div className={classes.innerContainer}>
      <MainLogo />
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <CompanyAccountRequestForm success={success} showCompanyAccountRequestModal />
      <div className={classes.forgotLinkWrapper}>
        <BackBar onClick={goBack} showTextXs />
      </div>
    </div>
  </div>
)

CompanyAccountRequestScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  success: PropTypes.func,
  goBack: PropTypes.func,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(style)(CompanyAccountRequestScreen)
