import { colors } from '../../config/theme'
export default theme => ({
  termsText: {
    ...theme.typography.body1,
    color: colors.accent,
    textAlign: 'justify'
  },
  formControl: {
    marginBottom: 30
  }
})
