"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  value: 'Notification',
  label: 'Notification'
}, {
  value: 'Confirmation',
  label: 'Confirmation'
}];
exports["default"] = _default;