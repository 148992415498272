import theme, { colors } from '../../config/theme'

const styles = {
  h2: {
    ...theme.h2,
    color: colors.text
  }
}

export default theme => styles
