import _ from 'lodash'
import { translations } from '../../../../config'

// remove fields in the json with the property {editable: false}
export const editableFields = (viewJSON) => {
    function recurse(viewJSON){
      return _.map(viewJSON, (field) => {
          if (_.size(_.get(field, 'fields'))) {
            const filter = _.filter(field.fields, (element) => element.editable !== false)
            _.set(field, 'fields', filter)
            recurse(field.fields)
          }
          return field
      })
    }
    return recurse(viewJSON)
}

const uniqueName = (data) => (value) => {
  if (value) {
    const checkExists = _.find(
      data,
      (name) => _.get(name, 'label', name).toLowerCase() === value.toLowerCase()

    )
    const errorMessage = translations('Error - Must Be A Unique Name')
    return checkExists ? errorMessage : undefined
  }
}
export const uniqueNameValidator = (data) => uniqueName(data)
