import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class SSOProviderReducers {
  fetchSSOProvider = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: action.type,
          ssoProvider: { ...action.result.ssoProvider }
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          action: action.type
        }
      default:
        return state
    }
  }

  updateSSOProvider = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: action.type,
          ssoProvider: { ...action.result.ssoProvider }
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          action: action.type
        }
      default:
        return state
    }
  }

  createSSOProvider = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: action.type,
          ssoProvider: { ...action.result.ssoProvider }
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          action: action.type
        }
      default:
        return state
    }
  }

  deleteSSOProvider = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: action.type,
          ssoProvider: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          action: action.type
        }
      default:
        return state
    }
  }
}

export default new SSOProviderReducers()
