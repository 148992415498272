import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import ProductsSearchScreen from '../ProductsSearchScreen'

import style from './style'

const WatchListSearchScreen = props => {
  return <ProductsSearchScreen watchListOnly />
}

export default withStyles(style)(WatchListSearchScreen)
