export default theme => ({
  container: {
    paddingTop: 10
  },
  warningSign: {
    padding: '5px'
  },
  warningBar: {
    backgroundColor: '#f1c40f',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row'
  },
  warningMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  permanentDeleteButton: {
    backgroundColor: 'red',
    '&:hover, &:active, &:focus': {
      backgroundColor: 'red'
    },
  }
})
