import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

const StyledButton = styled.button`
  -webkit-appearance: none;
  border-radius: 0;
  background: ${props => props.light ? props.theme.colors.white : props.theme.colors.secondary};
  opacity: ${props => props.disabled ? 0.5 : 1};
  color: ${props => props.light ? props.theme.colors.secondary : props.theme.colors.white};
  display: inline-block;
  vertical-align: center;
  padding: 10px 15px;
  font-size: ${props => `${props.theme.type.button.small}px`};
  line-height: 1;
  border: ${props => `1px solid ${props.light ? props.theme.colors.secondary : 'transparent'}`};
  font-weight: 700;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  margin: 0;
  white-space: nowrap;
  width: ${props => {
    return props.fullWidth ? '100%' : 'auto'
  }};
  ${media.greaterThan('medium')`
    font-size: ${props => `${props.theme.type.button.large}px`};
    padding: 12px 25px;
  `};
  &:not(:disabled):hover, &:not(:disabled):active, &:not(:disabled):focus {
    outline: none;
    box-shadow: ${({ theme }) => `0 0 0 3px ${theme.colors.primary}`};
  }
  &:active {
    transform: translate(1px, 1px);
  }
`

const SecondaryButton = styled.button`
  border: 0;
  background-color: white;
  font-size: ${props => `${props.theme.type.button.small}px`};
  margin: 0;
  cursor: pointer;
  ${media.greaterThan('medium')`
    font-size: ${props => `${props.theme.type.button.large}px`};
`};
`

/**
 * Primary button
 */
const Button = (props) => {
  const { children, onClick, type, disabled, light, style, fullWidth, secondary, key } = props

  const handleClick = (e) => {
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }

  if (secondary) {
    return <SecondaryButton onClick={handleClick} key={key}>{children}</SecondaryButton>
  }

  return (
    <StyledButton key={key} type={type} disabled={disabled} onClick={handleClick} light={light} style={style} fullWidth={fullWidth}>{children}</StyledButton>
  )
}

Button.propTypes = {
  /** The text value of the button */
  children: PropTypes.string.isRequired,
  /** Function to call when clicking the button. Function is passed the `event` */
  onClick: PropTypes.func,
  /** Disable the button */
  disabled: PropTypes.bool,
  /** The default behavior of the button */
  type: PropTypes.oneOf(['submit', 'reset', 'button'])
}

Button.defaultProps = {
  disabled: false,
  type: 'button'
}

export default Button
