import React, { useCallback } from 'react'
import { styled, withStyles } from '@material-ui/core/styles'

import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'


import styles from './style'
import ConfigurePanel from './ConfigurePanel'

const Content = styled('div')(({ theme }) => ({}))
const DrawerActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  background: theme.palette.primary.main,
  height: '65px'
}))
const MobileDrawer = styled(Drawer)(({ theme }) => ({}))

const ReportConfigurationSide = ({ classes }) => {
  const [sidebarOpen, setSideBarOpen]  = React.useState(false)

  const toggleSidebar = useCallback(() => {
    setSideBarOpen(!sidebarOpen)
  }, [sidebarOpen])

  return (
    <Content>
      <Hidden mdUp>
        <IconButton
          color='secondary'
          onClick={toggleSidebar}
          aria-label='Open'
        >
          <ChevronLeftIcon />
        </IconButton>
        <MobileDrawer
          open={sidebarOpen}
          onClose={toggleSidebar}
          anchor='right'
          variant='temporary'
          PaperProps={{
            className: classes.paper
          }}
        >
          <DrawerActions>
            <IconButton
              color='secondary'
              onClick={toggleSidebar}
              aria-label='Close'
            >
              <CloseIcon />
            </IconButton>
          </DrawerActions>
        <ConfigurePanel/>
        </MobileDrawer>
      </Hidden>
      <Hidden smDown>
        <ConfigurePanel />
      </Hidden>
    </Content>
  )
}

export default withStyles(styles)(ReportConfigurationSide)
