import { translations } from '../../../../../config'
const schema = [
  {
    id: 'manuallyProcessedReason',
    field: 'Input',
    props: {
      label: translations('Case Management - Manually Processed Reason'),
      required: true
    }
  }
]
export default schema