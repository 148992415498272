import React, { useContext } from 'react'

import { styled, withStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Heading from '../../../../../components/Heading'
import { translations } from '../../../../../config'
import { useSidebar } from '../../../../../contexts'

const Section = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  marginBottom: theme.spacing(1)
}))
const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  cursor: 'pointer'
}))
const ContentCollapse = styled(Collapse)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))
const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3)
}))
const ExpandIcon = (isIn) => {
  return isIn ? <ExpandLessIcon /> : <ExpandMoreIcon />
}

const CollapsableSection = (props) => {
  const { sectionName, title, children } = props
  const { dispatch, actions, state } = useSidebar()
  const { openSections } = state

  const isSectionOpen = (section) => {
    return openSections[section]
  }

  const toggleSection = () => {
    dispatch(actions.toggleSection(sectionName))
  }

  return (
    <Section>
      <Header
        onClick={() => toggleSection()}>
        <Heading variant='h3' component='h3'>{translations(title)}</Heading>
        {ExpandIcon(isSectionOpen(sectionName))}
      </Header>
      <ContentCollapse in={isSectionOpen(sectionName)}>
        <Content>
          {children}
        </Content>
      </ContentCollapse>
    </Section>
  )
}

export default CollapsableSection