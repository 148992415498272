import textContentStyle from '../../../components/Form/TextContent/style'
import formStyles from '../../../components/Form/style'

const styles = (theme) => ({
  contentContainer: {
    padding: 50,
    [theme.breakpoints.down('xs')]: {
      padding: 15
    }
  },
  duplicateContainer: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: 10,
    marginBottom: 35
  },
  duplicateIcon: {
    marginRight: 5
  },
  duplicateBoxBody: {
    display: 'block',
    '& ul': {
      paddingLeft: 20,
      marginBottom: 0
    },
    '& li': {
      marginTop: 5
    }
  },
  formContainer: {
    width: '100%'
  },
})

export default styles

export const formStyle = theme => ({
  ...formStyles,
  ...textContentStyle,
  registrationButton: styles.registrationButton
})
