import React from 'react'
import { styled } from '@material-ui/core/styles'

import { translations } from '../../../../../config'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import Button from '../../../../../components/Button'

const ActionsContainer = styled('div')({
  display: 'flex',
  'flex-direction': 'row',
  gap: '10px',
  'margin-left': 'auto'
})

const DeleteLanguageModal = ({ languageName, languageId, onDelete, onCancel }) => (
  <BasicModalOverlay title="Delete language">
    <p>{translations('Manage Translations - Delete Language Confirmation Text', { languageName })}</p>
    <p>{translations('Manage Translations - Delete Language Description Text')}</p>
    <ActionsContainer>
      <Button onClick={onCancel} color="primary">No</Button>
      <Button onClick={() => onDelete(languageId)}>Yes</Button>
    </ActionsContainer>
  </BasicModalOverlay>
)

export default DeleteLanguageModal
