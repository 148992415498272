
import React, { useEffect } from 'react'
import _ from 'lodash'
import OrganisationPagination from '../../OrganisationPagination/OrganisationPagination'
import FormContentBox from '../../../components/FormContentBox'
import Editable from '../../../components/Editable'
import { constants as platformDetailsConstants } from '../../../store/modules/platformDetails'
import { actions as platformDetailsActions } from '../../../store/modules/platformDetails'
import { actions as organisationsActions } from '../../../store/modules/organisations'
import { getCurrentPlatformId } from '../../../store/modules/platformDetails/selectors'
import { getPaginatedOrganisations, getPaginatedOrganisationsCount } from '../../../store/modules/organisations/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { organisationTypes } from '../../../config'

const formId = platformDetailsConstants.EDIT_PLATFORM_ORGANISATIONS_FORM_NAME
const PlatformOrganisationsForm = Editable(FormContentBox(formId))

const PlatformOrganisations = (props) => {
  const {
    classes,
    editable,
    platformDetails
  } = props

  const id = 'platformOrganisations'
  const platformId = useSelector(getCurrentPlatformId)
  const organisations = useSelector(getPaginatedOrganisations)
  const organisationCount = useSelector(getPaginatedOrganisationsCount)

  const headers = [
    { label: 'Organisation', id: 'organisation', type: 'label', width: '80%' },
    { label: 'Assigned', id: 'assigned', type: 'checkbox', width: '20%' }
  ]

  const dispatch = useDispatch()

  const fetch = ({ query, limit, offset }) => {
    if(platformId) {
      dispatch(organisationsActions.fetchPaginatedOrganisations({ query, platformId, limit: 100, offset }))
    }
  }
  const update = (params) => {
    dispatch(platformDetailsActions.updatePlatform({ id: platformId, params }))
  }

  useEffect(() => {
    return(() => {
      dispatch(organisationsActions.clearPaginatedOrganisations())
    })
  }, [])

  return (
    <OrganisationPagination
      id={id}
      classes={classes}
      editable={editable}
      label={'Platform Organisations'}
      Form={PlatformOrganisationsForm}
      formId={formId}
      fetch={fetch}
      update={update}
      platformId={platformId}
      submitDetails={platformDetails}
      organisations={organisations}
      organisationCount={organisationCount}
      headers={headers}
      parseOrganisations={parseOrganisations}
    />
  )
}

export const getSenderType = (type) => {
  const matchedType = _.find(organisationTypes, orgType => orgType.value === type)
  return matchedType ? matchedType.label : type
}

export const parseOrganisations = (organisations, hasUnassigned = true) => {
  const assigned = organisations.assigned
  const unassigned = organisations.unassigned
  const parsedAssigned = _.map(assigned, organisation => {
    const { id, displayName, details: { sendertype, senderevid } } = organisation
    return ({
      id: organisation.id,
      organisation: displayName,
      senderId: senderevid,
      senderType: getSenderType(sendertype),
      assigned: true
    })
  })

  if(!hasUnassigned) {
    return parsedAssigned
  }

  const parsedUnassigned = _.map(unassigned, organisation => ({
    id: organisation.id,
    organisation: organisation.displayName,
    assigned: false
  }))

  const parsedOrganisations = [...parsedUnassigned, ...parsedAssigned]
  return parsedOrganisations
}

export default PlatformOrganisations