import { connect } from 'react-redux'
import { compose } from 'recompose'
import MedDRALookupInput from './MedDRALookupInput'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { selectors as formViewSelectors } from '../../../store/modules/formViews'
import _ from 'lodash'

// RC mandatory medDRA should trump organisation medDRA
const determineMandatoryMedDRA = (state) => {
  const organisationMedDRAMandatory = authCombinedSelectors.getIsMandatoryMedDRAForSelectedOrganisation(state)
  const selectedReport = formViewSelectors.getSelectedReportType(state)
  const reportConfigMedDRAMandatory = _.get(selectedReport, 'details.mandatoryMedDRA', null)
  const reportRevision = _.get(selectedReport, 'formSchema.details.revision') || _.get(selectedReport, 'details.revision')
  
  if (reportRevision === 3) return true
  return !_.isNil(reportConfigMedDRAMandatory) ? reportConfigMedDRAMandatory : organisationMedDRAMandatory
}

export default compose(
  connect(state => ({
    isUsingMedDRATerms: authCombinedSelectors.getIsUsingMedDRAForSelectedOrganisation(state),
    isMandatoryMedDRA: determineMandatoryMedDRA(state)
  }))
)(MedDRALookupInput)
