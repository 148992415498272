
import React, { useState, useEffect } from 'react'
import { FormBody } from '../../components/Form'
import { translations, organisationTypes } from '../../config'
import { change, submit } from 'redux-form'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

const OrganisationPagination = (props) => {
  const {
    id,
    givenClass,
    columnStyle,
    rowStyle,
    editable,
    label,
    Form,
    formId,
    fetch,
    update,
    submitDetails = {},
    organisations,
    organisationCount,
    clickable,
    rowsPerPageOptions,
    hasAssigned = true,
    hasUnassigned = true,
    enableSearch = true,
    headers,
    parseOrganisations
  } = props

  const dispatch = useDispatch()

  const [loadedOrganisations, setLoadedOrganisations] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')

  const changeOrganisations = ({ limit, offset, fresh }) => {
    const count = limit + offset
    const loaded = _.size(loadedOrganisations)
    if (count > loaded && loaded < organisationCount || fresh) {
      setLoading(true)
      fetch({ query: searchQuery, limit, offset })
    }
  }

  const onInputChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const onOptionClick = (id) => {
    dispatch(push(`/organisations/${id}`))
  }

  useEffect(() => {
    if (loading && !_.isEmpty(organisations)) {
      if(parseOrganisations && _.isFunction(parseOrganisations)) {
        const parsedOrganisations = parseOrganisations(organisations, hasUnassigned)
        setLoadedOrganisations([...loadedOrganisations, ...parsedOrganisations])
      } else {
        setLoadedOrganisations([...loadedOrganisations, ...organisations])
      }
      setLoading(false)
    }
  }, [organisations])

  useEffect(() => {
    if(hasAssigned) {
      const sortedAlphabeticallyOrgs = _.sortBy(loadedOrganisations, 'organisation')
      const sortedAssignedOrganisations = sortedAlphabeticallyOrgs.sort(
        (a, b) => Number(b.assigned) - Number(a.assigned))
      dispatch(change(formId, id, sortedAssignedOrganisations))
    } else {
      dispatch(change(formId, id, loadedOrganisations))
    }
  }, [loadedOrganisations])

  useEffect(() => {
    setLoadedOrganisations([])
    changeOrganisations({ limit: 100, offset: 0, fresh: true })
  }, [searchQuery])

  const schema = [
    {
      id: id,
      field: 'TableList',
      props: {
        name: id,
        required: false,
        headers,
        count: organisationCount,
        onPageChange: changeOrganisations,
        onRowsPerPageChange: changeOrganisations,
        onInputChange: onInputChange,
        onOptionClick: clickable ? onOptionClick : null,
        query: searchQuery,
        enableSearch,
        rowsPerPageOptions: rowsPerPageOptions,
        defaultRowsPerPage: 10
      }
    }
  ]

  const handleSubmit = async (params) => {
    const localParams = { ...params, ...submitDetails }
    const organisations = localParams[id]
    const difference = _.difference(organisations, loadedOrganisations)

    localParams.organisations = {}

    const assigned = _.filter(difference, org => org.assigned)
    localParams.organisations.assigned = _.map(assigned, 'id')

    const unassigned = _.filter(difference, org => !org.assigned)
    localParams.organisations.unassigned = _.map(unassigned, 'id')

    return update(localParams)
  }

  const handleCancel = () => {
    setSearchQuery('')
    setLoadedOrganisations([])
    changeOrganisations({ limit: 100, offset: 0, fresh: true })
  }

  return (
    <Form
      editable={editable}
      givenClass={givenClass}
      boxName={translations(label)}
      formId={formId}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <FormBody
        schema={schema}
        globalClasses={{
          col: columnStyle,
          row: rowStyle,
        }}
        layout={[`${id}:12`]}
      />
    </Form>
  )
}

export default OrganisationPagination
