import { promiseReducer } from '../../util'
class SelectedReceiverReducers {
  fetchSelectedReceiver = promiseReducer(
    (state, action) => ({
      ...state,
      receiver: action.result
    })
  )

  fetchSelectedReceiverForPublicForm = promiseReducer(
    (state, action) => ({
      ...state,
      receiver: action.result
    })
  )
}

export default new SelectedReceiverReducers()
