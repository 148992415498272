"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isReactionExperiencedMultiSelectDefined = exports.isNotHealthcareProfessional = exports.isMalePatient = exports.isMaleParent = exports.hasSelectedReactionFromList = exports.hasSelectedAnotherReaction = exports.hasNotExperiencedReactionOrUndefined = exports.hasNotExperiencedReaction = exports.hasExperiencedReaction = exports.getHCPProfessionOptions = exports.convertPatientDobToYears = exports.convertPatientDobToMonths = exports.convertPatientDobToDays = exports.computeReactionRequired = exports.computePatientOnsetAgeValue = exports.computePatientOnsetAgeUnitR3 = exports.computePatientOnsetAgeUnit = exports.computeLegacyOptionPatientDiagnosedOrTreated = exports.clearIfNotPersonalDetailsOccupation = exports.clearIfNotExperiencedReactionOrUndefined = exports.clearIfNotExperiencedReaction = exports.clearIfMalePatient = exports.clearIfMaleParent = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var computedHidden = _interopRequireWildcard(require("../computedHidden"));

var computedValue = _interopRequireWildcard(require("../computedValue"));

var _moment = _interopRequireDefault(require("moment"));

var aefi = _interopRequireWildcard(require("./aefi"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var isMalePatient = computedHidden.fieldIs('patientsex', '1');
exports.isMalePatient = isMalePatient;
var clearIfMalePatient = computedValue.clearIf(isMalePatient);
exports.clearIfMalePatient = clearIfMalePatient;
var isMaleParent = computedHidden.fieldIs('parentsex', '1');
exports.isMaleParent = isMaleParent;
var clearIfMaleParent = computedValue.clearIf(isMaleParent);
exports.clearIfMaleParent = clearIfMaleParent;
var hasExperiencedReaction = computedHidden.fieldIsNot('hasexperiencedreaction', '2');
exports.hasExperiencedReaction = hasExperiencedReaction;
var hasNotExperiencedReaction = computedHidden.fieldIs('hasexperiencedreaction', '2');
exports.hasNotExperiencedReaction = hasNotExperiencedReaction;
var hasNotExperiencedReactionOrUndefined = computedHidden.fieldIs('hasexperiencedreaction', ['2', undefined]);
exports.hasNotExperiencedReactionOrUndefined = hasNotExperiencedReactionOrUndefined;
var clearIfNotExperiencedReactionOrUndefined = computedValue.clearIf(hasNotExperiencedReaction);
exports.clearIfNotExperiencedReactionOrUndefined = clearIfNotExperiencedReactionOrUndefined;
var clearIfNotExperiencedReaction = computedValue.clearIf(hasNotExperiencedReaction);
exports.clearIfNotExperiencedReaction = clearIfNotExperiencedReaction;
var hasSelectedAnotherReaction = computedHidden.selectedInMultipleOption('reactionexperienced', 'anotherReaction');
exports.hasSelectedAnotherReaction = hasSelectedAnotherReaction;
var hasSelectedReactionFromList = computedHidden.isRepeatableOrMultipleOptionIsNotEmpty('reactionexperienced');
exports.hasSelectedReactionFromList = hasSelectedReactionFromList;
var isReactionExperiencedMultiSelectDefined = computedHidden.fieldIs('reactionexperienced', null);
exports.isReactionExperiencedMultiSelectDefined = isReactionExperiencedMultiSelectDefined;

var computeReactionRequired = function computeReactionRequired() {
  switch (true) {
    case hasSelectedAnotherReaction.apply(void 0, arguments):
      return true;

    case hasSelectedReactionFromList.apply(void 0, arguments):
      return false;

    case isReactionExperiencedMultiSelectDefined.apply(void 0, arguments):
      return false;

    case aefi.hasHadAdverseEvent.apply(aefi, arguments):
      return false;

    case hasNotExperiencedReactionOrUndefined.apply(void 0, arguments):
      return true;

    default:
      return true;
  }
};

exports.computeReactionRequired = computeReactionRequired;

var computePatientOnsetAgeUsingDob = function computePatientOnsetAgeUsingDob(fn) {
  return computedValue.noopOrCallback(_fp["default"].allPass([computedHidden.fieldIs('usedobforpatientonsetage', true), _fp["default"].compose(function (dob) {
    return (0, _moment["default"])(dob, 'YYYYMMDD', true).isValid();
  }, computedValue.get('patientbirthdate'))]), fn);
};

var convertPatientDobToYears = _fp["default"].compose(computedValue.getYearsSince, computedValue.get('patientbirthdate'));

exports.convertPatientDobToYears = convertPatientDobToYears;

var convertPatientDobToMonths = _fp["default"].compose(computedValue.getMonthsSince, computedValue.get('patientbirthdate'));

exports.convertPatientDobToMonths = convertPatientDobToMonths;

var convertPatientDobToDays = _fp["default"].compose(computedValue.getDaysSince, computedValue.get('patientbirthdate'));

exports.convertPatientDobToDays = convertPatientDobToDays;

var computePatientOnsetAgeMatch = function computePatientOnsetAgeMatch() {
  return computePatientOnsetAgeUsingDob(computedValue.match({
    NEWBORN: _fp["default"].compose(function (months) {
      return months < 1;
    }, convertPatientDobToMonths),
    INFANT: _fp["default"].compose(function (months) {
      return months >= 1 && months <= 12;
    }, convertPatientDobToMonths),
    DEFAULT: _fp["default"].compose(function (months) {
      return months > 12;
    }, convertPatientDobToMonths)
  }).apply(void 0, arguments));
};

var computePatientOnsetAgeValue = computePatientOnsetAgeMatch({
  NEWBORN: convertPatientDobToDays,
  INFANT: convertPatientDobToMonths,
  DEFAULT: convertPatientDobToYears
});
exports.computePatientOnsetAgeValue = computePatientOnsetAgeValue;
var computePatientOnsetAgeUnit = computePatientOnsetAgeMatch({
  NEWBORN: computedValue.always('804'),
  INFANT: computedValue.always('802'),
  DEFAULT: computedValue.always('801')
});
exports.computePatientOnsetAgeUnit = computePatientOnsetAgeUnit;
var computePatientOnsetAgeUnitR3 = computePatientOnsetAgeMatch({
  NEWBORN: computedValue.always('d'),
  INFANT: computedValue.always('mo'),
  DEFAULT: computedValue.always('a')
});
exports.computePatientOnsetAgeUnitR3 = computePatientOnsetAgeUnitR3;

var getHCPProfessionOptions = function getHCPProfessionOptions(values, _parentFormValues, _org, _formview, professions) {
  return _lodash["default"].chain(professions).filter(function (profession) {
    return profession.type === 'Health Care Professional';
  }).map(function (profession) {
    return {
      value: profession.id,
      label: profession.name
    };
  }).value();
};

exports.getHCPProfessionOptions = getHCPProfessionOptions;
var isNotHealthcareProfessional = computedHidden.fieldIs('personaldetailsoccupation', 'No');
exports.isNotHealthcareProfessional = isNotHealthcareProfessional;
var clearIfNotPersonalDetailsOccupation = computedValue.clearIf(isNotHealthcareProfessional);
exports.clearIfNotPersonalDetailsOccupation = clearIfNotPersonalDetailsOccupation;

var computeLegacyOptionPatientDiagnosedOrTreated = function computeLegacyOptionPatientDiagnosedOrTreated(formValues) {
  var patientdiagnosed = _lodash["default"].get(formValues, 'patientdiagnosedOrTreated', []);

  var legacyOption = 'Has an illness, not listed above, which reduces the immune response (immunodeficiency)';
  var newOptionValue = 'Has other conditions, not listed above, known to lower the immune response';

  var hasLegacyIndex = _lodash["default"].indexOf(patientdiagnosed, legacyOption);

  if (hasLegacyIndex !== -1) {
    var withNewOption = [].concat(_toConsumableArray(patientdiagnosed), [newOptionValue]);

    var withFilteredOptions = _lodash["default"].filter(withNewOption, function (value) {
      return value !== legacyOption;
    });

    return withFilteredOptions;
  }
};

exports.computeLegacyOptionPatientDiagnosedOrTreated = computeLegacyOptionPatientDiagnosedOrTreated;