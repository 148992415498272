import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  platform: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_PLATFORM:
      return reducers.fetchPlatform(state, action)
    case constants.UPDATE_PLATFORM:
      return reducers.updatePlatform(state, action)
    case constants.CREATE_PLATFORM:
      return reducers.createPlatform(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
