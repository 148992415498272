import { visibility, colors } from '../../../config/theme'

const styles = theme => {
  const { hideForExtraSmall } = visibility(theme)
  return {
    hideForExtraSmall,
    container: {
      fontSize: '19px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1,
      alignItems: 'stretch',
      fontWeight: 'bold',
      minHeight: 48,
      [theme.breakpoints.up('sm')]: {
        minHeight: 60
      }
    },
    inner: {
      margin: '0 8px',
      alignItems: 'center',
      textTransform: 'uppercase',
      position: 'relative',
      display: 'flex',
    },
    buttonGridItem: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    cancelButton: {
      justifyContent: 'flex-start',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        order: 3,
        justifyContent: 'center',
        marginBottom: '10px'
      }
    },
    centerContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}

export default theme => styles(theme)
