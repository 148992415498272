import React, { Component } from 'react'
import _ from 'lodash'
import { compose } from 'recompose'

import { FormControl } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../config'
import Dropdown from '../../../../components/Fields/Dropdown'
import { withFiltersContainer } from '../../../../components/FiltersContainer'

import styles from './style'

class NewsSearchFilters extends Component {
  state = {
    tags: this.props.filters.tags,
    source: this.props.filters.source
  }

  updateFilters = _.debounce((filter) => {
    const { updateFilters } = this.props
    updateFilters(filter)
  }, 300)

  updateTags = (e) => {
    this.setState({ tags: e.target.value })
    this.updateFilters({ name: 'tags', value: _.castArray(e.target.value) })
  }

  updateSource = (e) => {
    this.setState({ source: e.target.value })
    this.updateFilters({ name: 'source', value: _.castArray(e.target.value) })
  }



  render() {
    const { classes, tagsOptions, sourceOptions } = this.props


    return (
      <div className={classes.container}>
        <Grid item xs={12} md={3} className={classes.leftContent}>
          <FormControl className={classes.tagsFilter}>
            <Dropdown
              multiple
              disabled={_.isEmpty(tagsOptions) || !tagsOptions}
              label={translations('Tags')}
              value={this.state.tags || null}
              options={tagsOptions}
              onChange={this.updateTags}
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} className={classes.leftContent}>
          <FormControl className={classes.tagsFilter}>
            <Dropdown
              multiple
              disabled={_.isEmpty(sourceOptions) || !sourceOptions}
              label={translations('Source')}
              value={this.state.source || null}
              options={sourceOptions}
              onChange={this.updateSource}
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
      </div>
    )
  }
}

export default compose(
  withStyles(styles),
  withFiltersContainer
)(NewsSearchFilters)
