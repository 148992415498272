import React from 'react'
import {
  TableHeader,
  TableCell
} from '../../../../components/Table'
import PropTypes from 'prop-types'
import { translations } from '../../../../config'

const Header = ({classes}) => (
  <TableHeader>
    <TableCell xs={2} sm={2} md={2} lg={2} xl={2} heading>
      {translations('Report Name')}
    </TableCell>
    <TableCell xs={2} sm={2} md={2} lg={2} xl={2} heading>
      {translations('Report Type')}
    </TableCell>
    <TableCell xs={2} sm={2} md={2} lg={2} xl={2} heading className={classes.tableCenter}>
      {translations('Publish Report')}
    </TableCell>
    <TableCell xs={2} sm={2} md={2} lg={2} xl={2} heading className={classes.tableCenter}>
      {translations('Public')}
    </TableCell>
    <TableCell xs={2} sm={2} md={2} lg={2} xl={2} heading>
      {translations('')}
    </TableCell>
  </TableHeader>
);

Header.propTypes = {
  classes: PropTypes.shape({}),
}

export default Header
