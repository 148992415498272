import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import {
  Badge,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Paper,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core'
import {
  FilterList as FilterListIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@material-ui/icons'

import styles from './style'
import { translations } from '../../../../config'
import { addQuery } from '../../../../helpers/query'
import modalService from '../../../../services/modalService'
import CohortModal from '../CohortModal'
import { actions as reportConfigurationActions } from '../../../../store/modules/reportConfiguration'
import toastService from '../../../../services/toastService'
import ConfirmationModal from '../../modals/ConfirmationModal'
import InfoModal from '../../modals/InfoModal'

const toastError = (message) => toastService.action({
  type: 'error',
  message
})
const toastSuccess = (message) => toastService.action({
  type: 'success',
  message
})

const CohortItem = ({
  id,
  name,
  description,
  conditions,
  editable = true,
  classes,
  createdAt,
  updatedAt,
  otherCohortNames = [],
  formViewId,
  followUps
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()

  const isAllReporters = name === 'All Reporters'
  const hasConditions = _.size(conditions) > 0
  const followUpCount = _.size(followUps)
  const hasFollowups = followUpCount > 0
  const isComplete = !editable || (isAllReporters && hasFollowups) || (hasConditions && hasFollowups)
  const formattedCreatedAt = moment(createdAt).format('LLL')
  const formattedUpdatedAt = moment(updatedAt).format('LLL')
  const wasUpdated = updatedAt !== createdAt
  let statusTooltip = ''

  if (!hasConditions && !isAllReporters) {
    statusTooltip = translations('Cohort - incomplete conditions')
  }
  if (!hasFollowups) {
    statusTooltip = translations('Cohort - incomplete followups')
  }
  if (!hasConditions && !hasFollowups && !isAllReporters) {
    statusTooltip = translations('Cohort - incomplete')
  }

  const search = _.debounce(() => dispatch(reportConfigurationActions.searchCohortsFresh({ formViewId })), 300)

  const onManageConditions = () => {
    addQuery({
      'cohort-conditions': {
        id,
        name
      }
    })
  }

  const handleMoreClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMoreClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const editCohort = async (params) => {
    try {
      const res = await dispatch(reportConfigurationActions.updateCohort({ params, formViewId, cohortId: id }))
      modalService.close()
      toastSuccess(translations('Success - Save Cohort'))
      search()
      return res
    } catch (err) {
      toastError(translations('Failure - Save Cohort'))
    }
  }

  const deleteCohort = async () => {
    try {
      const res = dispatch(reportConfigurationActions.deleteCohort({ cohortId: id }))
      toastSuccess(translations('Success - Delete Cohort'))
      setAnchorEl(null)
      search()
      return res
    } catch (err) {
      toastError(translations('Failure - Delete Cohort'))
    }
  }

  const openEditCohortModal = () => {
    modalService.open({
      title: translations('Cohorts - Edit Cohort'),
      component: CohortModal,
      disableBackdropClick: true,
      onSubmit: editCohort,
      closeModal: () => modalService.close(),
      otherCohortNames,
      initialValues: {
        name,
        description: _.isNil(description) ? undefined : description
      }
    })
  }

  const openDeleteCohortModal = () => {
    modalService.open({
      component: ConfirmationModal,
      disableBackdropClick: true,
      message: translations('Cohort - Confirm Delete Message'),
      title: translations('Cohort - Confirm Delete Title'),
      onConfirmation: deleteCohort
    })
  }

  const openCannotDeleteCohortModal = () => {
    modalService.open({
      component: InfoModal,
      disableBackdropClick: true,
      message: (
        <Fragment>
          <Typography>{translations('Cohort - Cannot Delete Message', { name, followUpCount })}</Typography>
          <List dense>
            {_.map(followUps, ({ formView: { name } }) => (
              <ListItem key={name}><ListItemText>{name}</ListItemText></ListItem>
            ))}
          </List>
        </Fragment>
      ),
      title: translations('Cohort - Cannot Delete Title')
    })
  }

  return (
    <Paper className={classes.item} variant="outlined">
      <div className={classes.cohortHeader}>
        <Typography variant='subtitle1' component='h2'>{name}</Typography>
        <Typography variant='subtitle2' component='div'>{description}</Typography>
        <Typography variant='caption' component='div' gutterBottom={!wasUpdated}>{translations('Created')}: {formattedCreatedAt}</Typography>
        {wasUpdated && <Typography variant='caption' component='div' gutterBottom>{translations('Last Updated')}: {formattedUpdatedAt}</Typography>}
        {!isComplete && (
          <Tooltip title={statusTooltip} placement='top'>
            <Chip
              className={classes.incompleteChip}
              size='small'
              label={'Incomplete'}
            />
          </Tooltip>
        )}
      </div>
      <div>
        {editable && !isAllReporters &&
          <Fragment>
            <Tooltip title='Conditions' placement='top'>
              <IconButton aria-label='Conditions' onClick={() => onManageConditions()}>
                <Badge badgeContent={_.size(conditions)} color='primary'>
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>

            <IconButton aria-label='More options' size='small' aria-controls={`cohort-more-menu-${id}`} aria-haspopup='true' onClick={handleMoreClick} color='inherit'>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`cohort-more-menu-${id}`}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMoreClose}
            >
              <ListItem button onClick={openEditCohortModal}>
                <ListItemIcon>
                  <EditIcon fontSize='small' />
                </ListItemIcon>
                {translations('Edit')}
              </ListItem>
              <ListItem button onClick={hasFollowups ? openCannotDeleteCohortModal : openDeleteCohortModal}>
                <ListItemIcon>
                  <DeleteIcon fontSize='small' />
                </ListItemIcon>
                {translations('Delete')}
              </ListItem>
            </Menu>
          </Fragment>
        }
      </div >
    </Paper >
  )
}

export default withStyles(styles)(CohortItem)
