import React, { useEffect } from 'react'
import { styled } from '@material-ui/core/styles'

import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import ScreenHeading from '../../../../components/ScreenHeading'

import IntegrationManagementTabs from '../IntegrationManagementTabs'
import { removeQuery } from '../../../../helpers/query'

const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  maxWidth: 1200,
  margin: '0 auto'
}))

const IntegrationManagementScreen = () => {
  useEffect(() => {
    removeQuery(['organisationId'])
  }, [])
  return (
    <div>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={<ScreenHeading label={'App Name - Integration Management'} />}
      />
      <Content>
        <IntegrationManagementTabs />
      </Content>
    </div>
  )
}

export default IntegrationManagementScreen
