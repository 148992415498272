import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import rolesActions from './actions'
import * as rolesSelectors from './selectors'
import * as authSelectors from '../auth/selectors'

class RolesMiddleware {
  loadRolesOnRouteToUserMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToNewUser = action.payload.location.pathname === '/users/add'
      const isRoutingToViewUser = matchPath(action.payload.location.pathname, { path: '/users/:id', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const rolesOrganisationIsNotLoaded = rolesSelectors.getIsInitial(getState())
      const rolesOrganisationHasError = rolesSelectors.getHasError(getState())
      if (isLoggedIn && (isRoutingToNewUser || isRoutingToViewUser) && (rolesOrganisationIsNotLoaded || rolesOrganisationHasError)) {
        dispatch(rolesActions.fetchAllRoles())
      }
    }
  }
  loadRolesOnRouteToUsersMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToMyProfile = action.payload.location.pathname === '/my-profile'
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const rolesOrganisationIsNotLoaded = rolesSelectors.getIsInitial(getState())
      const rolesOrganisationHasError = rolesSelectors.getHasError(getState())
      if (isLoggedIn && (isRoutingToMyProfile) && (rolesOrganisationIsNotLoaded || rolesOrganisationHasError)) {
        dispatch(rolesActions.fetchAllRoles())
      }
    }
  }
}

export default new RolesMiddleware()
