import { colors } from '../../../../../../config'

export default theme => ({
  content: {
    margin: '10px 0 25px 0'
  },
  list: {
    listStyle: 'none outside',
    margin: 0,
    padding: 0
  },
  listItem: {
    padding: '8px 10px',
    margin: '0 0 2px 0',
    backgroundColor: colors.lightGrey,
    borderLeft: `4px solid ${colors.mediumGrey}`
  },
  listItemAdd: {
    borderLeftColor: colors.success
  },
  listItemRemove: {
    borderLeftColor: colors.error
  },
  blankState: {
    padding: '8px 10px',
    backgroundColor: colors.lightGrey,
    borderLeft: `4px solid ${colors.mediumGrey}`,
    fontStyle: 'italic'
  }
})
