import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Button from '../Button'
import style from './style'
import classNames from 'classnames'
import { translations } from '../../config'

const BackBar = ({ onClick, className, classes, showTextXs }) => {
  const textEl = <span className={classes.buttonText}>{translations('Back')}</span>

  return <div className={classNames(classes.container, className)} >
    <Button
      type='button'
      onClick={onClick}
      className={classes.button}
    >
      <div className={classes.arrow} />
      {
        showTextXs
        ? textEl
        : <Hidden xsDown>{textEl}</Hidden>
      }
    </Button>
  </div>
}

BackBar.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default withStyles(style)(BackBar)
