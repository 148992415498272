import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import LookupIcon from '@material-ui/icons/Search'
import _ from 'lodash'

import { translations } from '../../../config'
import Editable from '../Editable'
import { Input } from '../../../components/Fields'
import P from '../../P'
import style from './style'
import ClearIcon from '@material-ui/icons/HighlightOff'

const LookupInput = Editable({
  input: ({ classes, onLookupClick, lookUpOnly, clearable, onClearClick, ...props }) => {
    const inputContainerProps = lookUpOnly ? {
      onClick: onLookupClick,
      type: 'button',
      className: classes.readOnlyInputContainer
    } : { className: classes.inputContainer }
    const InputContainer = lookUpOnly ? 'button' : 'div'
    return (
      <div className={classes.container}>
        <InputContainer {...inputContainerProps}>
          <Input {..._.omit(props, ['classes', 'onLookupClick'])} readOnly={lookUpOnly}/>
        </InputContainer>
        <div>
          <IconButton aria-label={translations('Search')} onClick={onLookupClick}><LookupIcon /></IconButton>
          {clearable && <IconButton aria-label={translations('Clear')} onClick={onClearClick} disabled={!props.inputValue}><ClearIcon/></IconButton>}
        </div>
      </div>
    )
  },
  display: ({ label, value, format, hideIfEmpty, isEmpty }) => {
    if (hideIfEmpty && isEmpty) return null
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={format ? format(value) : value} />
      </div>
    )
  }
})

export default withStyles(style)(LookupInput)
