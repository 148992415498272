/**
 * @file Provide hooks regarding the SDK for ADR clients.
 */
/**
 * @typedef {Object} SdkContext
 * @property {Object} SDK The initalised digital store client sdk for ADR client projects.
 * @property {string} organisationId The Organisation ID the root component was called with.
 * @property {string} languageCode Chosen Language Code i.e. `"en-GB"`
 */
import React, { useReducer, useCallback, useEffect } from 'react'
import { SdkContext } from './context'
import fp from 'lodash/fp'
import { useResolvePromise, reducer, reducerInitialState } from '../utilities'
/**
 * Access the initialised SDK from context.
 * All hooks are composed using this initially to prevent duplication for data preparation.
 * @returns {SdkContext} The formatted SDK object and additional information required such as organisation ID.
 */
export const useSdk = () => {
  const sdkContextData = React.useContext(SdkContext)
  return sdkContextData
}

/**
 * Hook to use methods from the SDK
 * @param {String} endpoint Path to the method within the SDK
 * @param {Object} options Options for the SDK method
 */
export const useSDKRequest = (endpoint, options) => {
  const [state, dispatch] = useReducer(reducer, reducerInitialState)
  const { sdk } = useSdk()

  const makeRequest = useCallback(async () => {
    const sdkMethod = fp.get(endpoint, sdk)

    if (options.disableRequest) {
      dispatch({ status: 'CANCELLED' })
      return
    }

    dispatch({ status: 'PENDING' })
    try {
      const response = await sdkMethod(fp.omit(['disableRequest'], options))
      dispatch({ status: 'SUCCESS', response })
    } catch (error) {
      dispatch({ status: 'ERROR', error })
    }
  }, [endpoint])

  return [state, makeRequest]
}

/**
 * Fetch details belonging to the organisation.
 */
export const useOrganisationDetails = () => {
  const { organisationId } = useSdk()
  const options = { id: organisationId }
  const [organisationDetails, getOrganisationDetails] = useSDKRequest('organisations.fetchOrganisation', options)

  useEffect(() => { getOrganisationDetails() }, [])
  return organisationDetails
}

/**
 * Fetch form view by id.
 */
export const useFormView = ({ id, disableRequest }) => {
  const [formView, getFormView] = useSDKRequest('formView.fetchFormViewById', { id, disableRequest })
  useEffect(() => { getFormView() }, [])
  return formView
}

/**
 * Fetch followUp by followUpUserId.
 */
export const useFollowUp = ({ followUpUserId, disableRequest }) => {
  const [followUp, getFollowUp] = useSDKRequest('followUp.fetchFollowUpForCurrentUserById', { followUpUserId, disableRequest })
  useEffect(() => { getFollowUp() }, [])
  return followUp
}

/**
 * Search Meddra Terms
 * @param {object} arguments
 * @param {string} arguments.query
 * @param {number} arguments.size
 */
export const useMeddraSearch = ({ query = '', size = 10 } = {}) => {
  const { sdk, organisationId, languageCode } = useSdk()
  const searchMeddras = fp.get('meddras.searchMeddras', sdk)
  const organisationDetails = useResolvePromise(searchMeddras({ organisationId, query, languageCode, size }))
  return organisationDetails
}

/**
 * Search Hospitals
 * @param {object} arguments
 * @param {string} arguments.query
 * @param {number} arguments.size
 */
export const useHospitalSearch = ({ query = '', size = 10 } = {}) => {
  const { sdk } = useSdk()
  const searchHospitals = fp.get('hospitals.searchHospitals', sdk)
  const organisationDetails = useResolvePromise(searchHospitals({ query, size }))
  return organisationDetails
}
