export const FETCH_FORM_VIEWS = 'digitalStore/reportConfiguration/FETCH_FORM_VIEWS'
export const FETCH_FORMVIEW_BY_ID = 'digitalStore/reportConfiguration/FETCH_FORMVIEW_BY_ID'
export const CHANGE_REPORTS_FILTERS = 'digitalStore/reportConfiguratiion/CHANGE_REPORTS_FILTERS'
export const CHANGE_DEFAULT_FILTERS = 'digitalStore/reportConfiguratiion/CHANGE_DEFAULT_FILTERS'
export const SEARCH_REPORTS_FRESH = 'digitalStore/reportConfiguratiion/SEARCH_REPORTS_FRESH'
export const FETCH_REPORT_FOR_ID = 'digitalStore/reportConfiguratiion/FETCH_REPORT_FOR_ID'
export const SEARCH_REPORTS_NEXT = 'digitalStore/reportConfiguratiion/SEARCH_REPORTS_NEXT'
export const SEARCH_FORMVIEW_REPORTERS_FRESH = 'digitalStore/reportConfiguration/SEARCH_FORMVIEW_REPORTERS_FRESH'
export const SEARCH_FORMVIEW_REPORTERS_NEXT = 'digitalStore/reportConfiguration/SEARCH_FORMVIEW_REPORTERS_NEXT'
export const CHANGE_FORMVIEW_REPORTERS_QUERY = 'digitalStore/reportConfiguration/CHANGE_FORMVIEW_REPORTERS_QUERY'
export const UPDATE_FORMVIEW_CONDITIONS = 'digitalStore/reportConfiguration/UPDATE_FORMVIEW_CONDITIONS'
export const UPDATE_FORMVIEW_REPORTERS_FILTERS = 'digitalStore/reportConfiguration/UPDATE_FORMVIEW_REPORTERS_FILTERS'
export const UPDATE_FOLLOWUP_FILTERS = 'digitalStore/reportConfiguration/UPDATE_FOLLOWUP_FILTERS'
export const SEARCH_FOLLOWUP_FRESH = 'digitalStore/reportConfiguration/SEARCH_FOLLOWUP_FRESH'
export const SET_INITIAL_EDITOR_VALUES = 'digitalStore/reportConfiguration/SET_INITIAL_EDITOR_VALUES'
export const CREATE_REPORT_CONFIGURATION = 'digitalStore/reportConfiguration/CREATE_REPORT_CONFIGURATION'
export const UPDATE_REPORT_CONFIGURATION = 'digitalStore/reportConfiguration/UPDATE_REPORT_CONFIGURATION'
export const UPDATE_REPORT_CONFIGURATION_VERSION = 'digitalStore/reportConfiguration/UPDATE_REPORT_CONFIGURATION_VERSION'
export const UPDATE_FORM_VIEW_FILTERS = 'digitalStore/reportConfiguration/UPDATE_FORM_VIEW_FILTERS'
export const CREATE_FOLLOW_UP = 'digitalStore/reportConfiguration/CREATE_FOLLOW_UP'
export const UPDATE_FOLLOW_UP = 'digitalStore/reportConfiguration/UPDATE_FOLLOW_UP'
export const DELETE_FOLLOW_UP = 'digitalStore/reportConfiguration/DELETE_FOLLOW_UP'
export const FETCH_FOLLOW_UP_REPORT_CONFIGURATION = 'digitalStore/reportConfiguration/FETCH_FOLLOW_UP_REPORT_CONFIGURATION'
export const SEARCH_COHORTS_FRESH = 'digitalStore/reportConfiguration/SEARCH_COHORTS_FRESH'
export const CREATE_COHORT = 'digitalStore/reportConfiguration/CREATE_COHORT'
export const UPDATE_COHORT = 'digitalStore/reportConfiguration/UPDATE_COHORT'
export const DELETE_COHORT = 'digitalStore/reportConfiguration/DELETE_COHORT'
export const FETCH_FORMVIEW_REPORTER_FOLLOWUPS = 'digitalStore/reportConfiguration/SEARCH_FORMVIEW_REPORTER_FOLLOWUPS'
export const UPDATE_FOLLOWUP_USER = 'digitalStore/reportConfiguration/UPDATE_FOLLOWUP_USER'
export const CREATE_INDIVIDUAL_FOLLOW_UP = 'digitalStore/reportConfiguration/CREATE_INDIVIDUAL_FOLLOW_UP'
export const FETCH_LATEST_REPORT_VERSION_FOR_REPORTER = 'digitalStore/reportConfiguration/FETCH_LATEST_REPORT_VERSION_FOR_REPORTER'
export const UPDATE_FOLLOWUP_PUBLISH_STATE = 'digitalStore/reportConfiguration/UPDATE_FOLLOWUP_PUBLISH_STATE'

export const SECTION_FORM = 'digitalStore/reportConfiguration/SECTION_FORM'
export const FIELD_FORM = 'digitalStore/reportConfiguration/FIELD_FORM'
export const CHANGE_SECTION_FORM = 'digitalStore/reportConfiguration/CHANGE_SECTION_FORM'
export const IMPORT_CONFIGURATION_FORM = 'digitalStore/reportConfiguration/IMPORT_CONFIGURATION_FORM'
export const EXPORT_CONFIGURATION_FORM = 'digitalStore/reportConfiguration/EXPORT_CONFIGURATION_FORM'
export const CHANGE_BLOCK_FORM = 'digitalStore/reportConfiguration/CHANGE_BLOCK_FORM'
export const ADD_TO_BLOCK_FORM = 'digitalStore/reportConfiguration/ADD_TO_BLOCK_FORM'
export const CONFIGURE_CONDITIONS_FORM = 'digitalStore/reportConfiguration/CONFIGURE_CONDITIONS_FORM'
export const COHORTS_FORM = 'digitalStore/reportConfiguration/COHORTS_FORM'
