import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import AccountDeletionRequestList from './AccountDeletionRequestList'
import ScreenHeading from '../../../components/ScreenHeading'

import styles from './style'

const ViewAccountDeletionRequestList = ({
  onUserClick,
  onBackClick,
  classes,
  inModal
}) => {
  const scrollContainerClass = (inModal) ? classes.scrollContainerInModal : classes.scrollContainer

  return (
    <div className={classes.container}>
      <ScreenHeading label='Account Deletion Requests' hideHeading />
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
      />
      <div className={scrollContainerClass}>
        <AccountDeletionRequestList onUserClick={onUserClick} />
      </div>
    </div>
  )
}

export default withStyles(styles)(ViewAccountDeletionRequestList)
