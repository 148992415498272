import React from 'react'
import { Route } from 'react-router'

import { VerifyOtaScreen } from './VerifyOtaScreen'

const routes = [
  <Route key='/verify-ota' path='/verify-ota' exact component={VerifyOtaScreen} />,
]

export default routes
