import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'

import ReportConfigurationTabs from '../ReportConfigurationTabs/ReportConfigurationTabs'
import { useRCEditor } from '../../../contexts'
import { useQuery } from '../../../hooks'

const Content = styled('div')(({ theme }) => ({}))

const ReportConfigurationEditor = (props) => {
  const { formViewId, handleSave } = props
  const { actions, dispatch } = useRCEditor()
  const { followUp: followUpMeta } = useQuery()
  const { followUpId } = followUpMeta || {}
  useEffect(() => {
    dispatch(actions.setFormViewId(formViewId))
  }, [followUpId, formViewId, actions, dispatch])
  return (
    <Content>
      <ReportConfigurationTabs formViewId={formViewId} handleSave={handleSave} />
    </Content>
  )
}

ReportConfigurationEditor.propTypes = {
  formViewId: PropTypes.string.isRequired
}

export default ReportConfigurationEditor
