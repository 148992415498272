import React, { useEffect, useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { styled } from '@material-ui/core/styles'

import FieldBase from '../FieldBase'
import FieldGroup from '../FieldGroup'
import { DragDropContext } from 'react-beautiful-dnd'

const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '&:last-child': {
    marginBottom: 0
  }
}))

/**
 * Each `Field` is an element within the form that represents a visual element.
 */
export const Field = (props) => {
  const { fields, type, saveDraggedResult, moveAnyResource, onEdit, onToggleVisibility, onDelete, onMove, onChangeSection, onChangeBlock, onAddToBlock, initialValues, ...fieldProps } = props
  const { id: fieldId, visible, isCustom, sectionId, isHeading } = fieldProps
  const [repeatableSnapshot, setSnapshot] = useState(undefined)
  const [hasBeenDragged, setDragged] = useState(false)
  const orderedFields = useMemo(() => {
    return _.sortBy(fields, ['index'])
  }, [fields])

  useEffect(() => {
    if (hasBeenDragged) {
      setDragged(false)
    } else if (!_.isEqual(orderedFields, repeatableSnapshot)) {
      setSnapshot(orderedFields)
    }
  }, [orderedFields, repeatableSnapshot])

  const onDragEnd = useCallback((payload) => {
    const movedResources = moveAnyResource(payload)
    if (movedResources) {
      const movedFields = _.get(movedResources, 'fields')
      const filteredFields = _.filter(movedFields, { sectionId })
      const currentFields = _.get(_.find(filteredFields, { id: fieldId }), fields, [])
      const sortedFields = _.orderBy(currentFields, ['index'])
      const moveProps = _.get(movedResources, 'moveProps')
      setSnapshot(sortedFields)
      setDragged(true)
      saveDraggedResult(moveProps)
    }
  }, [repeatableSnapshot])

  const getOnAddToBlock = () => {
    if (onAddToBlock) {
      return () => onAddToBlock({ id: fieldId, name: _.get(fieldProps, 'name') })
    }
    return undefined
  }

  return (
    <Container>
      <FieldBase
        {...fieldProps}
        type={type}
        onEdit={() => onEdit({ fieldType: type, meta: { id: fieldId }, props: { initialValues } })}
        onToggleVisibility={() => onToggleVisibility({ resourceType: 'FIELD', id: fieldId })}
        onMove={(props) => onMove({ resourceType: 'FIELD', id: fieldId, ...props })}
        onChangeSection={() => onChangeSection({ fieldType: type, id: fieldId, name: _.get(fieldProps, 'name'), currentSectionId: _.get(props, 'sectionId') })}
        onChangeBlock={onChangeBlock && onChangeBlock}
        onAddToBlock={getOnAddToBlock()}
        {...((isCustom === true || isHeading === true) && {
          onDelete: () => onDelete({ resourceType: 'FIELD', id: fieldId })
        })}
      />
      {_.size(fields) > 0 && (

        <DragDropContext onDragEnd={onDragEnd}>
          <FieldGroup
            fieldId={fieldId}
            visible={visible}
            fields={orderedFields}
            onEdit={onEdit}
            onToggleVisibility={onToggleVisibility}
            onDelete={onDelete}
            onMove={onMove}
          />
        </DragDropContext>
      )}
    </Container>
  )
}

Field.defaultProps = {
  type: 'QUESTION',
  isCustom: false,
  mandatory: false,
  visible: true,
  hideFromForm: false,
  displayName: '',
  fields: [],
  editable: true,
  initialValues: {}
}

Field.propTypes = {
  /** System ID for question  */
  id: PropTypes.string.isRequired,
  /** Name used when displayed on the front end */
  name: PropTypes.string.isRequired,
  /** Overrides the default name in the front end */
  displayName: PropTypes.string,
  /**
   * Type of field
   *
   * **QUESTION** - Form inputs that are part of the schema
   *
   * **HEADING** - Used to label sections of a form
   *
   * **REPEATABLE** - A field that is an array of answers
   */
  type: PropTypes.oneOf(['QUESTION', 'HEADING', 'REPEATABLE']),
  /** If the question has been created by the user  */
  isCustom: PropTypes.bool,
  /** Is the field visible in the form */
  visible: PropTypes.bool,
  /** Field is required to be completed in the form */
  mandatory: PropTypes.bool,
  /** Field is hidden from the front end forms */
  hideFromForm: PropTypes.bool,
  /** List of fields associated to the parent field */
  fields: PropTypes.array,
  /** Is field part of schema logic, system fields will be marked as `editable: false` */
  editable: PropTypes.bool,
  /** Method to update existing field */
  onEdit: PropTypes.func,
  /** Callback when section is requested to be moved, will be passed the direction */
  onMove: PropTypes.func,
  /** Callback when section is requested to be deleted */
  onDelete: PropTypes.func,
  /** Callback when section's visibility is toggled */
  onToggleVisibility: PropTypes.func,
  /** Is first field in the list */
  isFirst: PropTypes.bool,
  /** Is last field in the list */
  isLast: PropTypes.bool,
  /** Initial values used when editing the field */
  initialValues: PropTypes.object
}

export default Field
