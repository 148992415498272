import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ContentBoxBody from './ContentBoxBody'
import ContentBoxHeader from './ContentBoxHeader'

import style from './style'

const ContentBox = ({ children, givenContentClass, classes, id, contentId, hidden }) => {
  const headerContent = children.find(child => child.type === ContentBoxHeader)
  const bodyContent = children.find(child => child.type === ContentBoxBody)

  return (
    <section className={classes.container}>
      {headerContent}
      <div
        id={contentId}
        aria-labelledby={id}
        className={`${classes.content} ${givenContentClass}`}
        hidden={hidden}
      >{bodyContent}</div>
    </section>
  )
}

ContentBox.defaultProps = {
  hidden: false
}

ContentBox.propTypes = {
  id: PropTypes.string,
  contentId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  classes: PropTypes.shape({}).isRequired,
  hidden: PropTypes.bool,
  givenContentClass: PropTypes.string
}

export default withStyles(style)(ContentBox)
