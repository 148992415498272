import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  status: undefined,
  error: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CREATE_USER:
    case constants.REGISTER_USER:
      return reducers.createUser(state, action)
    case constants.FETCH_USER_DETAILS:
      return reducers.fetchUserDetails(state, action)
    case constants.FETCH_CURRENT_USER_DETAILS:
      return reducers.fetchUserDetails(state, action)
    case constants.UPDATE_USER:
      return reducers.updateUser(state, action)
    // return the same data as an update
    case constants.CHANGE_USER_PASSWORD:
      return reducers.updateUser(state, action)
    case constants.CHANGE_CURRENT_USER_PASSWORD:
      return reducers.updateUser(state, action)
    case constants.USER_ACCOUNT_STATUS_TOGGLE:
      return reducers.updateUser(state, action)
    case constants.FETCH_USER_ORGANISATION_PREFERENCES_COUNT:
      return reducers.fetchUserPreferencesCount(state, action)
    case constants.FETCH_USER_ORGANISATION_PREFERENCES:
      return reducers.fetchUserPreferences(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
