/**
 * @typedef DownloadFileParams
 * @property {Blob} blob
 * @property {string} filename
 * 
 * @param {DownloadFileParams} params 
 */
export const downloadFile = ({ blob, filename }) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  a.parentNode.removeChild(a) // a.remove may not be supported on IE11
}

export const b64toBlob = ({ b64Data, contentType = 'application/zip', sliceSize = 512 }) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

/**
 * @typedef blobTob64Params
 * @property {Blob} blob
 * @property {FileReader} fileReader
 */
export const blobTob64 = ({ fileReader = new FileReader(), blob }) => {
  return new Promise((resolve, reject) => {
    try {
      fileReader.addEventListener('load', () => {
        resolve(fileReader.result)
      })
      fileReader.readAsDataURL(blob)
    } catch (ex) {
      reject(ex)
    }
  })
}
