"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var versions = [{
  version: '1.0.0',
  path: require('./1.0.0')
}];

var _default = function _default(version) {
  var matched = _lodash["default"].find(versions, {
    version: version
  });

  var latest = _lodash["default"].last(versions);

  return {
    schema: !version || !matched ? latest.path.schema : matched.path.schema,
    versions: versions
  };
};

exports["default"] = _default;