"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'email',
    field: 'Input',
    props: {
      shrink: true,
      name: 'email',
      label: 'Email',
      required: true,
      validate: [validators.isValidEmail]
    }
  }, {
    id: 'password',
    field: 'Input',
    props: {
      shrink: true,
      name: 'password',
      label: 'Password',
      type: 'password',
      required: true
    }
  }, {
    id: 'stayLoggedIn',
    field: 'Checkbox',
    props: {
      shrink: true,
      name: 'stayLoggedIn',
      label: 'Keep me signed in',
      required: false
    }
  }];
};

exports.getFormSchema = getFormSchema;