import moment from 'moment'
import _ from 'lodash'
import { translations } from '../../../../config'

export const handleInviteMessage = ({ inviteStatus, updatedAt, createdAt }) => {
  switch (inviteStatus) {
    case 'ACCEPTED':
      if (updatedAt === createdAt) {
        return translations("Invited Users - Message - Automatically Accepted")
      }
      const momentUpdatedAt = moment(updatedAt).format('DD/MM/YYYY hh:mm')
      return translations("Invited Users - Message - Accepted Manually", { acceptedAt: momentUpdatedAt, interpolation: { escapeValue: false } })
    case 'REJECTED':
    case 'PENDING':
    default:
      return '-'
  }
}
