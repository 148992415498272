import _ from 'lodash'

export const columns = [
  'Date',
  'Sender',
  'User',
  'Report Form',
  'Type of Report',
  'Message Number',
  'Safety Report Id',
  'Status',
  'Application',
  'Source',
  'Latest Version',
  'Manufacturer Reference Number',
  'Reaction / Event related to',
  'Reaction type / Event category + Storage Subcategory',
  'Incident Date',
  'Local Ref No',
  'Reporter action',
  'MHRA Status',
  'SHOT Status',
  'Location'
]

const invisibleColumnByDefault = [
  'Reaction / Event related to',
  'Reaction type / Event category + Storage Subcategory',
  'Incident Date',
  'Local Ref No',
  'Reporter action',
  'MHRA Status',
  'SHOT Status',
  'Location',
]

export const getReportManagementColumns = (columnMappings) => {
  const reportManagementColumns = _.map(
    columns,
    (columnName) => getColumnData(columnName, columnMappings)
  )
  return reportManagementColumns
}

const getColumnData = (columnName, columnMappings) => {
  return {
    columnName,
    isVisible: isColumnVisible(columnName, columnMappings),
    mappedName: getColumnName(columnName, columnMappings)
  }
}

export const getColumnName = (column, columnMappings) => {
  const name = _.get(columnMappings, `${column}.label`, column)
  return name
}

export const isColumnVisible = (column, columnMappings) => {
  const isVisible = _.get(columnMappings, `${column}.visible`, _.includes(invisibleColumnByDefault, column) ? false : true)
  return isVisible
}

export const manufacturerReferenceNumbersReportForm = ['devicesReport', 'fscaReport', 'trendReport']