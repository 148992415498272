import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'

import { DataTableRow, DataTableCell } from '../../../../components/DataTable'
import { translations } from '../../../../config'
import { addQuery } from '../../../../helpers/query'

const Row = styled(DataTableRow)({
  cursor: 'pointer'
})

const ReporterRow = (props) => {
  const { entry } = props
  const { id, firstName = '', lastName = '', email = '', latestReportDate, FuCompleted, FuTotalSent } = entry
  const fuResponseRate = _.defaultTo(_.round((FuCompleted / FuTotalSent) * 100), 0)
  const hasNoFollowUps = (_.isNil(FuTotalSent) || FuTotalSent === 0) && 'n/a'

  const handleClick = () => {
    addQuery({
      reporter: id
    })
  }

  return (
    <Row hover onClick={handleClick}>
      <DataTableCell align={'left'}>{`${firstName} ${lastName}`}</DataTableCell>
      <DataTableCell align={'left'}>{email}</DataTableCell>
      <DataTableCell align={'left'}>{moment(latestReportDate).format('DD/MM/YYYY HH:mm')}</DataTableCell>
      <DataTableCell align={'center'}>{hasNoFollowUps || FuTotalSent}</DataTableCell>
      <DataTableCell align={'center'}>{hasNoFollowUps || translations('Reporters - FollowUps Response Rate', { fuResponseRate })}</DataTableCell>
    </Row>
  )
}

ReporterRow.propTypes = {
  entry: PropTypes.object.isRequired
}

export default ReporterRow
