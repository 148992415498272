import _ from 'lodash'
import { PENDING } from '../../middleware/redux-promise'
import { createSelector } from 'reselect'
import uuid from 'uuid'
import { defaultTranslations } from '../../../config'
import * as constants from './constants'

export const getIsLoading = state => state.organisationDetails.status === PENDING
export const getCurrentOrganisation = state => _.get(state, 'organisationDetails.organisation', {})
export const getCurrentOrganisationId = state => _.get(state, 'organisationDetails.organisation.id')
export const getCurrentOrganisationDetails = state => _.get(state, 'organisationDetails.organisation.details')
export const getOrganisationDetailsForForm = state => {
  const org = _.get(state, 'organisationDetails.organisation', {})
  return {
    ...org.details,
    regionId: org.regionId,
    themeId: org.themeId,
    receiverId: org.receiverId,
    extOrganisationId: org.extOrganisationId,
    country: org.country
  }
}
export const getCurrentOrganisationName = state => _.get(state, 'organisationDetails.organisation.name')
export const getCurrentOrganisationDrugsLists = state => _.get(state, 'organisationDetails.organisation.drugsLists', [])
export const getCurrentOrganisationDrugMetaImports = state => _.get(state, 'organisationDetails.organisation.drugMetaImports', [])
export const getCurrentOrganisationPlatform = state => _.get(state, 'organisationDetails.organisation.platform')
export const getCurrentOrganisationProfessions = state => _.get(state, 'organisationDetails.organisation.professions', [])
export const getCurrentOrganisationProfessionGroups = state => _.get(state, 'organisationDetails.organisation.professionGroups', [])
export const getCurrentOrganisationLocations = state => _.get(state, 'organisationDetails.organisation.locations', [])
export const getCurrentOrganisationLocationProvider = state => _.get(state, 'organisationDetails.organisation.locationProviderId')
export const getCurrentOrganisationReceivers = state => _.get(state, 'organisationDetails.organisation.receivers', [])
export const uniqueOrgReceivers = createSelector([
  getCurrentOrganisationReceivers
], orgReceivers => {
  return _.map(orgReceivers, config => {
    const conditions = _.get(config, 'conditions.all') || _.get(config, 'conditions.any')
    const flattenConditions = _.reduce(conditions, (memo, condition) => {
      const { fact, value } = condition
      memo[`condition_${fact}`] = value
      return memo
    }, {})

    return {
      id: uuid(),
      schema: config.formSchemaId,
      receiver: config.receiverId,
      source: config.sourceId,
      conditions: config.conditions,
      email: config.email,
      holdForReview: config.holdForReview,
      anonymise: config.anonymise,
      ...flattenConditions
    }
  })
})
export const getCurrentOrganisationCountry = state => _.get(state, 'organisationDetails.organisation.country')

export const getCurrentOrganisationTranslations = state => _.get(state, 'organisationDetails.organisation.translations', {})
export const getCurrentOrganisationLanguagesAsOptions = createSelector(
  [state => _.get(state, 'organisationDetails.organisation._translations', [])],
  (_translations) => {
    if (!_translations.map) return []
    return _translations.map(t => {
      const label = _.get(t, 'language.name')
      const value = _.get(t, 'language.id')
      const isoCode = _.get(t, 'language.isoCode')
      return { label, value, isoCode }
    })
  }
)

export const getCurrentOrganisationTranslationsForOrgDetails = createSelector(
  [getCurrentOrganisationTranslations],
  (translations) => {
    if (!translations.en) {
      translations.en = {}
    }
    const mapped = _.mapValues(translations, (obj, key) => {
      const allTranslations = key === 'en' ? _.merge(defaultTranslations, obj) : obj
      return {
        isoCode: key,
        total: _.keys(allTranslations).length
      }
    })
    return _.values(mapped)
  }
)

export const getIsUploading = state => {
  const action = _.get(state, 'organisationDetails.action')
  switch (action) {
    case constants.CREATE_DRUGS_LISTS:
    case constants.IMPORT_DRUG_META:
      return true
    default:
      return false
  }
}

export const getCurrentOrganisationSelector = createSelector(
  [getCurrentOrganisation],
  (organisation) => organisation
)

export const getLocationProviderId = state => _.get(state, 'organisationDetails.organisation.locationProviderId')
