import fp from 'lodash/fp'
import { NAME } from './constants'

const getByFormSchemaId = ({ id, path = [] }) => fp.get([NAME, 'master', 'byFormSchemaId', id, ...path])

const getByFormViewId = ({ id, path = [] }) => fp.get([NAME, 'master', 'byFormViewId', id, ...path])

export const getFetchData = ({ id, method, path = ['data'] }) => {
  switch (method) {
    case 'FORM_SCHEMA':
      return getByFormSchemaId({ id, path })
    case 'FORM_VIEW':
      return getByFormViewId({ id, path })
    default:
      return undefined
  }
}