import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { fileHelper } from '../../helpers'
import { translations, dateFormat, longDateFormat } from '../../config'
import P from '../P'
import Button from '../Button'
import style from './style'
import { getImage } from '../Images'
import Spacing from '../Spacing'
import { authCombinedSelectors } from '../../store/modules/combinedSelectors'

const ResourceItem = ({ resourceKey, name, text, createdAt, classes, viewResource, deleteResource, className, onClickDeleteResource, lastModified }) => {
  // if none just use 'File'
  const fileType = fileHelper.getExtension(resourceKey) || 'File'
  const image = getImage('fileIcon')
  const containerClass = classNames(classes.container, className)
  const canDeleteResource = useSelector(authCombinedSelectors.canDeleteResource)

  return (
    <li className={containerClass}>
      <div className={classes.leftContainer}>
        <img src={image} className={classes.icon} alt='' />
        <div className={classes.textContainer}>
          <P type='large' value={name} className={classes.resourceKey} />
          <P value={text || fileType} className={classes.typeText} />
          {createdAt && <P value={moment(createdAt).format(dateFormat)} className={classes.typeText} />}
          {lastModified && <P value={moment(lastModified).format(longDateFormat)} className={classes.typeText} />}
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        {
          deleteResource
            ? <IconButton className={classes.deleteButton} onClick={deleteResource}>
              <img src={getImage('trashIcon')} className={classes.deleteIcon} />
            </IconButton>
            : null
        }
        {deleteResource && viewResource ? <Spacing width={5} /> : null}
        {
          viewResource
            ? <Button raised color='primary' className={classes.button} onClick={viewResource}>
              {translations('View')}
            </Button>
            : null
        }
        {onClickDeleteResource && viewResource && canDeleteResource ? <Spacing width={5} /> : null}
        {
          onClickDeleteResource && canDeleteResource
            ? (<IconButton className={classes.deleteButton} onClick={() => onClickDeleteResource({ key: resourceKey })}>
              <img src={getImage('trashIcon')} className={classes.deleteIcon} />
            </IconButton>
            ) : null
        }
      </div>
    </li>
  )
}

ResourceItem.propTypes = {
  resourceKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  viewResource: PropTypes.func.isRequired,
}

export default withStyles(style)(ResourceItem)
