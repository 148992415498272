export default theme => ({
  noResultsMessage: {
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  viewAllContainer: {
    textAlign: 'center',
    marginTop: 20
  }
})
