import { promiseReducer } from '../../util'

class LocationsReducers {
  searchLocations = promiseReducer(
    (state, action) => {
      const { result, status } = action
      const { results } = result
      const { count, rows } = results

      return {
        ...state,
        status,
        rows,
        count,
        page: 1
      }
    }
  )

  fetchOrganisationLocations = promiseReducer((state, action) => {
    const { result, status } = action

    if (status === 'SUCCESS') {
      const { regions, districts } = result

      return {
        ...state,
        organisationLocations: {
          regions,
          districts
        }
      }
    }

    return state
  })

  fetchLocationProviders = promiseReducer(
    (state, action) => {
      return ({
        ...state,
        providers: action.result.locationProviders,
        nextSyncDate: action.result.nextSyncDate
      })
    }
  )
}

export default new LocationsReducers()
