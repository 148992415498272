import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../config'
import P from '../P'
import style from './style'
import classNames from 'classnames'

const NetworkNotification = ({ isOffline, classes }) => (
  <div
    className={classNames(classes.container, { [classes.active]: isOffline })}
    role='alert'
    aria-hidden={!isOffline}
  >
    <P className={classes.content} type='large' value={translations('Offline notification')} />
  </div>
)

NetworkNotification.propTypes = {
  isOffline: PropTypes.bool
}

NetworkNotification.defaultProps = {
  isOffline: false
}

export default withStyles(style)(NetworkNotification)
