import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { getFormError, SubmissionError, submit } from 'redux-form'
import { useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Form, { FormBody } from '../../../../components/Form'
import P from '../../../../components/P'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import ConditionsBuilder from '../../../../components/Conditions/ConditionsBuilder'

import { styled } from '@material-ui/core/styles'
import toastService from '../../../../services/toastService'
import { translations } from '../../../../config'
import { useShallowEqualSelector } from '../../../../hooks'
import { Typography } from '@material-ui/core'
import ActionsBuilder from '../../components/ActionsBuilder'
import * as validators from '../../../../validators'
import { actionEnum } from '../../constants'

const FORM_NAME = 'integrationManagement/incomingReports/conditionsForm'
const ConditionsForm = Form(FORM_NAME)

const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2)
}))

const Spacing = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1)
}))

const ConditionsModal = (props) => {
  const { onSubmit, dismiss, fields, dictionaries, initialValues, editing, availableActions } = props
  const id = _.get(initialValues, 'id')
  const dispatch = useDispatch()
  const [conditions, setConditions] = useState(_.get(initialValues, 'conditions', {}))
  const [actions, setActions] = useState(_.get(initialValues, 'actions', []))
  const formError = useShallowEqualSelector(getFormError(FORM_NAME))

  const transformActions = (actions) => actions?.map((action) => {
    switch (action.action) {
      case actionEnum.SEND_EMAIL: {
        const { emails } = action.details

        if (!emails) {
          throw new SubmissionError({ _error: translations('Integration Management - Invalid Emails') })
        }

        emails.split(',').forEach(email => {
          const trimmedEmail = email.trim()

          if (!trimmedEmail || (trimmedEmail && validators.email(trimmedEmail))) {
            throw new SubmissionError({ _error: translations('Integration Management - Invalid Emails') })
          }
        })

        return {
          ...action,
          details: {
            ...action.details,
            emails: emails
          }
        }
      }

      default:
        return action
    }
  })

  const handleSubmit = useCallback(async ({ name, description }) => {
    if (_.size(actions) < 1) {
      throw new SubmissionError({ _error: translations('Conditions - Empty actions error') })
    }

    try {
      await onSubmit({
        id,
        name,
        description,
        conditions,
        actions: transformActions(actions)
      })
      dismiss()
    } catch (error) {
      if (error instanceof SubmissionError) {
        throw error
      }

      const errorCode = _.get(error, 'code', 'unknown')
      const errorMessage = translations('Conditions - Failed to save', { code: errorCode })

      toastService.action({
        type: 'error',
        message: errorMessage
      })
      throw new SubmissionError({ error: errorMessage })
    }
  }, [onSubmit, dismiss, conditions, actions, id])

  const actionButtons = useMemo(() => {
    return [
      {
        success: true,
        text: translations('Confirm'),
        primary: true,
        onClick: () => {
          dispatch(submit(FORM_NAME))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [dismiss, dispatch])

  const schema = useMemo(() => {
    return [
      {
        id: 'name',
        field: 'Input',
        props: {
          label: translations('Name'),
          name: 'name',
          required: true
        }
      },
      {
        id: 'description',
        field: 'Input',
        props: {
          label: translations('Description'),
          name: 'description',
          multiline: true
        }
      }
    ]
  }, [])

  const title = editing ? translations('Edit conditions') : translations('Add conditions')

  return (
    <BasicModalOverlay actions={actionButtons} title={title}>
      <ConditionsForm onSubmit={handleSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <Content>
            <Grid container spacing={2}>
              {_.isString(formError) && (
                <Grid item xs={12}>
                  <Spacing>
                    <P value={formError} type={'error'} />
                  </Spacing>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormBody
                  schema={schema}
                  layout={['name:6', 'description:12']}
                  editing
                  fullWidthFields
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2' component='p'>{translations('Conditions')}</Typography>
                <Spacing>
                  <ConditionsBuilder
                    query={_.get(initialValues, 'conditions')}
                    fields={fields}
                    onQueryChange={(conditions) => setConditions(conditions)}
                  />
                </Spacing>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2' component='p'>{translations('Actions')}</Typography>
                <Spacing>
                  <ActionsBuilder
                    actions={availableActions}
                    query={_.get(initialValues, 'actions', [])}
                    fields={fields}
                    dictionaries={dictionaries}
                    onQueryChange={(actions) => setActions(actions)}
                  />
                </Spacing>
              </Grid>
            </Grid>
          </Content>
        </ContentBoxBody>
      </ConditionsForm>
    </BasicModalOverlay>
  )
}

ConditionsModal.props = {}

ConditionsModal.propTypes = {
  dismiss: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired
}

export default ConditionsModal
