import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import style from './style'

const LoadingBar = ({ isLoading, classes, className }) => (
  <div className={`${classes.container} ${className}`}>
    {isLoading && <LinearProgress mode='indeterminate' style={{ height: 2 }} />}
  </div>
)

LoadingBar.propTypes = {
  isLoading: PropTypes.bool
}

export default withStyles(style)(LoadingBar)
