
export const RESET_STORE = 'digitalStore/fileManager/RESET_STORE'

// FILE MANAGER
// INITIALISING
export const INITALISE_FILE_MANAGER_BEGIN = 'digitalStore/fileManager/INITALISE_FILE_MANAGER_BEGIN'
export const INITALISE_FILE_MANAGER_FINISH = 'digitalStore/fileManager/INITALISE_FILE_MANAGER_FINISH'
export const INITALISE_FILE_MANAGER_ERROR = 'digitalStore/fileManager/INITALISE_FILE_MANAGER_ERROR'
// ACTIVE STATES
export const FILE_MANAGER_STATUS_IN_PROGRESS = 'digitalStore/fileManager/FILE_MANAGER_STATUS_IN_PROGRESS'
export const FILE_MANAGER_STATUS_IDLE = 'digitalStore/fileManager/FILE_MANAGER_STATUS_IDLE'
export const FILE_MANAGER_STATUS_COMPLETED = 'digitalStore/fileManager/FILE_MANAGER_STATUS_COMPLETE'
export const FILE_MANAGER_STATUS_CANCELLED = 'digitalStore/fileManager/FILE_MANAGER_STATUS_CANCELLED'

// INDIVIDUAL FILES
export const SET_FILE = 'digitalStore/fileManager/SET_FILE'
export const FILE_UPLOAD_PENDING = 'digitalStore/fileManager/FILE_UPLOAD_PENDING'
export const FILE_UPLOAD_SUCCESS = 'digitalStore/fileManager/FILE_UPLOAD_SUCCESS'
export const FILE_UPLOAD_FAILURE = 'digitalStore/fileManager/FILE_UPLOAD_FAILURE'
export const FILE_UPDATE_PROGRESS = 'digitalStore/fileManager/FILE_UPDATE_PROGRESS'


// ATTACHMENTS
export const ATTACHMENTS_UPLOAD_PENDING = 'digitalStore/fileManager/ATTACHMENTS_UPLOAD_PENDING'
export const ATTACHMENTS_UPLOAD_SUCCESS = 'digitalStore/fileManager/ATTACHMENTS_UPLOAD_SUCCESS'
export const ATTACHMENTS_UPLOAD_FAILURE = 'digitalStore/fileManager/ATTACHMENTS_UPLOAD_FAILURE'

