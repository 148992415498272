import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'
import getFormSchemaLabel from '../../../helpers/getFormSchemaLabel'

const formSchemaLibrary = new FormSchemaLibrary()

export const getAllFormSchemas = state => _.get(state, 'formSchemas.results')

export const getIsLoading = state => _.get(state, 'formSchemas.results.status') === PENDING
export const getError = state => _.get(state, 'formSchemas.results.error')
export const getResults = state => _.get(state, 'formSchemas.results')

export const getReportSchemas = createSelector([
  getAllFormSchemas
], (schemas) => {
  return _.filter(schemas, schema => schema.type === 'report')
})

export const checkSchemaExistsinLibrary = createSelector([
  getAllFormSchemas
], (schemas) => {
  return _.filter(schemas, (schema) => (
    formSchemaLibrary.checkSchemaExists(schema.name)
  ))
})

export const getSchemasForDropdown = createSelector([
  checkSchemaExistsinLibrary
], (schemas) => (
  _.map(schemas, schema => ({
    value: schema.id,
    label: `${getFormSchemaLabel(schema.name)}${schema.version ? `-${schema.version}` : ''}`
  }))
))

export const getSchemaByName = (formSchemaName) => createSelector([
  getAllFormSchemas
], (schemas) => {
  return _.get(_.find(schemas, { name: formSchemaName }) || {}, 'id')
})

export const getSchemaById = (formSchemaId) => createSelector([
  getAllFormSchemas
], (schemas) => {
  const formSchema = _.find(schemas, { id: formSchemaId }) || {}
  return {
    ...formSchema,
    friendlyName: getFormSchemaLabel(formSchema.name)
  }
})
