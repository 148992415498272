const styles = () => {
  return {
    checkboxContainer: {
      height: '100px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      border: '1px solid grey',
      padding: 10
    },
    checkbox: {
      width: '100%'
    },
    addSectionContainer: {
      border: '1px solid grey'
    },
    addSection: {
      display: 'flex',
      width: '100%',
      padding: 10
    },
    buttonContainer: {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        marginRight: 10
      }
    },
    errorText: {
      color: 'red',
      margin: 0,
      fontSize: '0.75rem',
      textAlign: 'left',
      minHeight: '1em',
      lineHeight: '1em'

    }
  }
}

export default styles
