/* eslint-disable react/display-name */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Editable from '../Editable'
import { OptionSelector } from '../../Fields'
import P from '../../P'

import styles from './style'

const EditableOptionSelector = Editable({
  input: (props) => {
    return (
      <>
        <OptionSelector {...props} />
      </>
    )
  },
  display: (props) => {
    const { label, value } = props
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={value} />
      </div>
    )
  }
})

export default withStyles(styles)(EditableOptionSelector)
