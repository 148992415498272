import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'

class SSOProviderActions {
  fetchSSOProvider = ({ id }) => ({
    type: constants.FETCH_SSO_PROVIDER,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.ssoProvider.fetchSSOProvider({ id })
    }
  })
  updateSSOProvider = ({ id, ...params }) => ({
    type: constants.UPDATE_SSO_PROVIDER,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.ssoProvider.updateSSOProvider({ id, ...params })
    }
  })
  createSSOProvider = ({ ...params }) => ({
    type: constants.CREATE_SSO_PROVIDER,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.ssoProvider.createSSOProvider({ ...params })
    }
  })
  deleteSSOProvider = ({ id }) => ({
    type: constants.DELETE_SSO_PROVIDER,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.ssoProvider.deleteSSOProvider({ id })
    }
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new SSOProviderActions()
