import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import Button from '../../../../components/Button'

class ReportAcknowledgementsFilters extends Component {
  render () {
    const { classes, downloadAcks } = this.props

    return (
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={1} className={classes.buttonCol}>
            <Button onClick={downloadAcks}>
              <DownloadIcon />
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ReportAcknowledgementsFilters)
