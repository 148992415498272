import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import Label from '../Label'
import Field from '../Field'
import ErrorMessage from '../ErrorMessage'

const Heading = styled.div`
  margin: ${props => props.error ? '0' : '0 -10px'};
  ${media.greaterThan('small')`
    margin: ${props => props.error ? '0' : '0 -15px'};
  `}
`

/**
 * Displays a label for a repeatable field
*/
const RepeatableLabel = (props) => {
  const { label, required, error, submitFailed, disabled } = props
  const errorMessage = (error && submitFailed) ? error : undefined

  return (
    <Heading error={errorMessage}>
      <Field error={errorMessage}>
        <Label as='h3' required={required} disabled={disabled}>{label}</Label>
        {errorMessage && <ErrorMessage error={errorMessage} />}
      </Field>
    </Heading>
  )
}

RepeatableLabel.propTypes = {
  /**
   * Name of the repeatable
   */
  label: PropTypes.string.isRequired,
  /**
   * If the repeatable is required
   */
  required: PropTypes.bool,
  /**
   * Error message to display
   */
  error: PropTypes.string,
  /**
   * If the form step has failed to submit, as you can not 'touch' a repeatable
   */
  submitFailed: PropTypes.bool
}

RepeatableLabel.defaultProps = {
  required: false,
  submitFailed: false
}

export default RepeatableLabel