import React from 'react'


export const ErrorInfo = ({ error }) => {
  return (
    <div>
      <h2>Something went wrong.</h2>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {error && error.toString()}
        <br />
        {error && error.componentStack}
      </details>
    </div>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      const Fallback = this.props.fallback
      // Error path
      return <Fallback error={this.state.errorInfo}/>

    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundary