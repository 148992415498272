import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import PublicRoute from '../../components/PublicRoute'
import NewsSearchScreen from './NewsSearchScreen'
import NewsFeedScreen from './NewsFeedScreen'
import NewsWebviewScreen from './NewsWebviewScreen'
import CreateNewsScreen from './CreateNewsScreen'
import ViewNewsScreen from './ViewNewsScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/news' path='/news' exact component={TimedLogoutComponent(NewsSearchScreen)} allowOffline />,
  <PrivateRoute key='/news/feeds' path='/news/feeds' exact component={TimedLogoutComponent(NewsFeedScreen)} />,
  <PrivateRoute key='/news/create' path='/news/create' exact component={TimedLogoutComponent(CreateNewsScreen)} />,
  <PrivateRoute key='/news/:id/edit' path='/news/:id/edit' exact component={TimedLogoutComponent(CreateNewsScreen)} />,
  <PrivateRoute key='/news/:id' path='/news/:id' exact component={TimedLogoutComponent(NewsWebviewScreen)} allowOffline />,
  <PrivateRoute key='/news/:id/view' path='/news/:id/view' exact component={TimedLogoutComponent(ViewNewsScreen)} allowOffline />,
  <PublicRoute key='/news/public/:id' path='/news/public/:id' exact component={ViewNewsScreen} />
]
