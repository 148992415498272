import _ from 'lodash'

export const getUnloaded = (state) => {
  const { visibility, general, advanced, timeframe } = state
  const nonDetailsKeys = ['category', 'subCategory', 'sources']
  const [category, subCategory, sources] = _.at(general, nonDetailsKeys)
  const cleanGeneral = _.omit(general, nonDetailsKeys)

  return {
    ...visibility,
    category,
    subCategory,
    details: {
      ...cleanGeneral,
      ...advanced
    },
    timeframe,
    sources: visibility.sources
  }
}

export const getRepeatableInstances = (state) => {
  return state.repeatableInstances
}