import _ from 'lodash'
import React from 'react'
import Section, { SectionTypes } from '../Section'
import { ActionButton } from '../ReportSummaryModal'
import { translations } from '../../../../../config'
import { actions as reportsActions } from '../../../../../store/modules/reports'
import { selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import { authCombinedSelectors } from '../../../../../store/modules/combinedSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { useAbilities } from '../../../../../ability/ability-context'

const ExportActions = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection,
    report
  } = props

  const dispatch = useDispatch()
  const ability = useAbilities()

  const hasXML = useSelector(reportDetailsSelectors.getHasXML)
  const hasJSONReportExport = useSelector(reportDetailsSelectors.getHasJSONReportExport)
  const canExportPdf = useSelector(reportDetailsSelectors.getCanExportPdf)
  const canExportAssessmentPdf = useSelector(authCombinedSelectors.canExportAssessessmentPdf)
  
  if(!hasXML && !hasJSONReportExport && !canExportPdf) {
    return <></>
  }
  
  const exportXML = () => {
    dispatch(reportsActions.fetchReportForId({ id: report.id, format: 'xml' }))
  }
  
  const exportJSON = () => {
    dispatch(reportsActions.fetchReportForId({ id: report.id, format: 'json' }))
  }
  
  const exportPDF = () => {
    dispatch(reportsActions.fetchReportForId({ id: report.id, format: 'pdf' }))
  }

  const exportAssessmentPdf = () => {
    dispatch(reportsActions.fetchReportForId({ 
      id: report.id, 
      format: 'pdf', 
      options: { showAssessmentFields: true } 
    }))
  }

  const canDownloadReportJSON = ability.can('downloadReportJSON', 'reportManagement')

  const exportAsPDF = 
    <ActionButton disabled={!canExportPdf} onClick={exportPDF}>{translations('PDF')}</ActionButton>
  const exportAsAssessmentPDF = 
    <ActionButton disabled={!canExportPdf} onClick={exportAssessmentPdf}>{translations('Assessment PDF')}</ActionButton>
  const exportAsXML = 
    <ActionButton disabled={!hasXML} onClick={exportXML}>{translations('XML')}</ActionButton>
  const exportAsJSON = 
    <ActionButton disabled={!hasJSONReportExport} onClick={exportJSON}>{translations('JSON')}</ActionButton>

  const rows = [
    { type: SectionTypes.ACTION, value: canExportPdf ? exportAsPDF : null },
    { type: SectionTypes.ACTION, value: canExportAssessmentPdf ? exportAsAssessmentPDF : null },
    { type: SectionTypes.ACTION, value: hasXML ? exportAsXML : null },
    { type: SectionTypes.ACTION, value: hasJSONReportExport && canDownloadReportJSON ? exportAsJSON : null },
  ]
  

  return (
    <Section
      name='Export As...'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
    />
  )
}

export default ExportActions