"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deviceFields = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var fscaHelpers = _interopRequireWildcard(require("../../devicesReport/7.2.0/collections/schemas/FSCA/formHelpers"));

var _utils = require("../../mhraR3Report/1.0.0/collections/utils");

var _general = require("../../../formHelpers/devices/general");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var setComputedRequiredAsRequiredForAuthRep = function setComputedRequiredAsRequiredForAuthRep() {
  return _fp["default"].set('computedRequired', fscaHelpers.isAuthorisedRep);
};

var deviceFields = [{
  id: 'canSaveDraft'
}, {
  id: 'audienceid'
}, {
  id: 'ncaName',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(200, 'Field')];
  })]
}, {
  id: 'reportType',
  changes: [_utils.modifiers.setDropDownOptions([{
    label: 'Initial',
    value: 'Initial'
  }, {
    label: 'Follow Up',
    value: 'Follow Up'
  }, {
    label: 'Final',
    value: 'Final'
  }])]
}, {
  id: 'ncaReportNo',
  changes: [_utils.modifiers.setComputedDisabled(fscaHelpers.isReportTypeNotAFollowUpOrFinal), _utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'statusReporter',
  changes: [_utils.modifiers.setDropDownOptions([{
    value: 'Manufacturer',
    label: 'Manufacturer'
  }, {
    value: 'Authorised Representative',
    label: 'Authorised Representative'
  }, {
    value: 'Others',
    label: 'Other, please specify'
  }])]
}, {
  id: 'reporterOtherText'
}, {
  id: 'mfrName',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(200, 'Field')];
  })]
}, {
  id: 'mfrEmailAddress'
}, {
  id: 'mfrPhone',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'mfrCountry'
}, {
  id: 'mfrAddress',
  changes: [_utils.modifiers.addPropRequiredTrue, _utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(200, 'Field')];
  })]
}, {
  id: 'mfrCity',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'mfrPostcode',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'ARName',
  changes: [setComputedRequiredAsRequiredForAuthRep, _utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(200, 'Field')];
  })]
}, {
  id: 'AREmailAddress',
  changes: [setComputedRequiredAsRequiredForAuthRep, _utils.modifiers.setValidators(function (validators) {
    return [validators.isValidEmail, validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'ARPhone',
  changes: [setComputedRequiredAsRequiredForAuthRep, _utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'ARCountry',
  changes: [setComputedRequiredAsRequiredForAuthRep]
}, {
  id: 'ARAddress',
  changes: [setComputedRequiredAsRequiredForAuthRep]
}, {
  id: 'ARCity',
  changes: [setComputedRequiredAsRequiredForAuthRep, _utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'ARPostCode',
  changes: [setComputedRequiredAsRequiredForAuthRep]
}, {
  id: 'nomenclatureSystem'
}, {
  id: 'nomenclatureSystemOther',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'nomenclatureCode',
  changes: [_utils.modifiers.setComputedValue(fscaHelpers.clearIfIsNotNomenclatureSystemGMDN), _utils.modifiers.setComputedHidden(fscaHelpers.visibleIfIsNomenclatureSystemGMDN), _utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'brandName',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'modelNum',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(2000, 'Field')];
  })]
}, {
  id: 'catalogNum',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(2000, 'Field')];
  })]
}, {
  id: 'serialNum',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(2000, 'Field')];
  })]
}, {
  id: 'batchNum',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(2000, 'Field')];
  })]
}, {
  id: 'deviceMfrDate'
}, {
  id: 'deviceExpiryDate'
}, {
  id: 'nbIdNum',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'deviceAccessories',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(2000, 'Field')];
  })]
}, {
  id: 'deviceSoftwareVer',
  changes: [_utils.modifiers.setValidators(function (validators) {
    return [validators.maxLength(50, 'Field')];
  })]
}, {
  id: 'attachment'
}, {
  id: 'distribution_all',
  changes: [_utils.modifiers.setComputedRequired(fscaHelpers.areNoDistributionOptionsSelected), _utils.modifiers.setLabel('All EEA, Great Britain, candidate countries and Switzerland'), _utils.modifiers.setValidators(function (validators) {
    return [validators.minimumOf1(_general.distributionFieldsValidation.fields, _general.distributionFieldsValidation.error)];
  })]
}];
exports.deviceFields = deviceFields;