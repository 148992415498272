import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import VisuallyHidden from '../VisuallyHidden'
import Heading from '../Heading'

import { hooks as platformHooks } from '../../store/modules/platforms'

const ScreenHeading = (props) => {
  const { label, hideHeading } = props
  const pageTitle = platformHooks.usePageTitle(label)

  return (
    <Fragment>
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      {!hideHeading && <Heading component='h1' uppercase>{pageTitle.title}</Heading>}
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
    </Fragment>
  )
}

ScreenHeading.propTypes = {
  label: PropTypes.string.isRequired
}

export default ScreenHeading
