import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'


class PagesReducers {
  getPagesForOrganisation = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.getPagesForOrganisationPending(state, action)
      case SUCCESS:
        return this.getPagesForOrganisationSuccess(state, action)
      case FAIL:
        return this.getPagesForOrganisationFail(state, action)
      default:
        return state
    }
  }
  getPagesForOrganisationPending = (state, action) => {
    return {
      ...state,
      status: PENDING
    }
  }
  getPagesForOrganisationFail = (state, action) => {
    return {
      ...state,
      status: FAIL
    }
  }
  getPagesForOrganisationSuccess = (state, action) => {
    let pages = {}
    if(action.result) {
      for(const page of action.result) {
        pages[page.id] = {
          id: page.id, 
          title: page.title, 
          content: page.content, 
          languageId: page.languageId, 
          organisationId: page.organisationId,
          path: page.path
        }
      }
    }
    return {
      ...state,
      status: SUCCESS,
      pages: pages
    }
  }

  getPage = (state, action) => {
    const pageId = action.payload.id
    return {
      ...state,
      pages: {
        ...state.pages,
        [pageId]: action.result
      }
    }
  }

  setActivePageId = (state, action) => {
    return {
      ...state,
      activePageId: action.payload.pageId
    }
  }

  updatePage = (state, action) => {
    const pageId = action.payload.id
    return {
      ...state,
      pages: {
        ...state.pages,
        [pageId]: action.result
      }
    }
  }

  deletePage = (state, action) => {
    const pageId = action.payload.id
    return {
      ...state,
      pages: _.omit(state.pages, pageId)
    }
  }
}

export default new PagesReducers()
