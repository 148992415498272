import React from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectors as reportAuditSelectors } from '../../../../../store/modules/reportAudit'
import EmptyResultBanner from '../../../../../components/EmptyResultBanner'
import AuditRow from '../../../../../components/AuditRow'
import { Grid, styled, Chip} from '@material-ui/core'
import DividerHeading from '../../../../../components/FormviewEditor/DividerHeading'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued'
import beautify from 'xml-beautifier'
import { translations } from '../../../../../config'
import P from '../../../../../components/P'
import { downloadXml, mapAmendDetails } from '../ReportAuditRow/helpers'

import FullScreenLoader from '../../../../../components/FullScreenLoader/FullScreenLoader'
import ReportAuditContext from '../contexts/ReportAuditContext'

const Content = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(5)
}))

const BlankState = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  borderLeft: `4px solid ${theme.palette.grey[300]}`
}))

const ReportAuditTable = () => {
  const { status, data: tableData } = ReportAuditContext.useAudit()
  const { id: reportId } = useParams()
  const extReportId = useSelector(reportAuditSelectors.getExtReportByReportId({ id: reportId }))

  if (status === 'PENDING') {
    return <FullScreenLoader />
  }

  if (_.isEmpty(tableData) && status === 'FULFILLED') {
    return <EmptyResultBanner />
  }

  return (
    <>
      {
        _.map(tableData, (data, index) => {
          const {
            id,
            previousXml,
            currentXml,
            createdAt,
            action,
            description,
            amendDetails = {}
          } = data

          const rowDescription = (
            <>
              {description && translations(description)}
              {amendDetails && mapAmendDetails({ amendDetails })}
            </>
          )

          const rightGridItems = [
            {
              component: <Chip label={translations('Changes made')} variant="outlined" />,
              visible: !_.eq(currentXml, previousXml)
            }
          ]
          
          const moreMenuOptions = [
            {
              label: translations('Download XML'),
              onClick: downloadXml({ xml: currentXml, fileName: extReportId })
            }
          ]

          return (
            <AuditRow
              key={id}
              id={id}
              createdAt={createdAt}
              startOpen={index === 0}
              collapseContent={<CollapseBody previousXml={previousXml} currentXml={currentXml} />}
              moreMenuOptions={moreMenuOptions}
              rightGridItems={rightGridItems}
              rowDescription={rowDescription}
              rowTitle={translations(`Report Audit - ${action}`)}
            />
          )
        })
      }
    </>
  )
}


const CollapseBody = React.memo(({ previousXml, currentXml }) => {
  const hasChanges = !_.eq(currentXml, previousXml)

  if (!hasChanges) {
    return <></>
  }

  return (
    <Content>
      <DividerHeading heading={translations('Report Audit - Diff Header')} />
      {
        !hasChanges && (
          <BlankState>
            <P value={translations('No Difference to show')} />
          </BlankState>
        )}
      {
        hasChanges && (
          <Grid item xs={12}>
            <ReactDiffViewer
              oldValue={beautify(previousXml)}
              newValue={beautify(currentXml)}
              useDarkTheme={false}
              showDiffOnly
              extraLinesSurroundingDiff={1}
              compareMethod={DiffMethod.LINES}
              styles={{
                diffContainer: {
                  fontSize: 14
                },
                codeFold: {
                  height: 32
                }
              }}
            />
          </Grid>
        )
      }
    </Content>
  )
})


export default ReportAuditTable
