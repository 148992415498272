import { colors } from '../../../../config/theme'

const getClearButtonCrossLineStyle = angle => ({
  content: '""',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(-50%,-50%) rotate(${angle}deg)`,
  width: 14,
  height: 2,
  borderRadius: 1,
  backgroundColor: 'white'
})

export default theme => ({
  inputInkbar: {
    '&:after': {
      backgroundColor: colors.text
    }
  },
  container: {
    position: 'relative',
    width: '100%'
  },
  inputContainer: {
    marginTop: 20,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    minWidth: '100%',
    width: '100%', // Ensure component fills width of field
    // padding: '8px 0', // would be good if this could work with virtualized list
    zIndex: '1300'
  },
  clearButtonContainer: {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    right: 30,
    width: 20,
    height: 20
  },
  clearButton: {
    width: 20,
    height: 20,
    backgroundColor: 'black',
    borderRadius: 10,
    opacity: 0.25,
    cursor: 'pointer',
    transition: '0.1s linear opacity',
    '&:hover': {
      opacity: 0.5
    },
    '&:before': getClearButtonCrossLineStyle(45),
    '&:after': getClearButtonCrossLineStyle(135)
  },

  selectInput: {
    width: '100%',
    paddingRight: 70,
    cursor: 'pointer'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.border}`,
    padding: '0 12px'
  },
  searchBar: {
    backgroundColor: 'rgba(0,0,0,0.05)',
    position: 'relative',
    flex: 1
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 5,
    width: 20,
    height: 20
  },
  searchInput: {
    padding: '0 10px 0 30px'
  },
  selectIcon: {
    position: 'absolute',
    top: 'calc(50% - .5em)',
    right: 0,
    pointerEvents: 'none'
  }
})
