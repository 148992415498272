import { colors } from '../../config/theme'

export default theme => {
  return {
    chip: {
      textTransform: 'uppercase',
      fontSize: 10,
      backgroundColor: '#ffffff',
      border: `1px solid ${theme.palette.grey[400]}`,
      color: colors.darkGrey,
      [theme.breakpoints.up('sm')]: {
        fontSize: 11
      },
      '&$disabled': {
        opacity: 1
      }
    },
    primary: {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    secondary: {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    },
    success: {
      borderColor: colors.success,
      color: colors.success
    },
    successClickable: {
      '&:hover, &:active, &:focus': {
        backgroundColor: colors.success,
        color: '#fff'
      }
    },
    error: {
      borderColor: colors.error,
      backgroundColor: colors.error,
      color: '#ffffff'
    },
    errorClickable: {
      '&:hover, &:active, &:focus': {
        borderColor: colors.errorDark,
        backgroundColor: colors.errorDark
      }
    },
    errorOutlined: {
      borderColor: colors.error,
      color: colors.error,
    },
    errorOutlinedClickable: {
      '&:hover, &:active, &:focus': {
        borderColor: colors.errorDark,
        backgroundColor: colors.error,
        color: '#ffffff'
      }
    },
    warning: {
      borderColor: colors.warning,
      color: colors.warning
    },
    warningClickable: {
      '&:hover, &:active, &:focus': {
        backgroundColor: colors.warning,
        color: '#fff'
      }
    },
    small: {},
    large: {},
    disabled: {}
  }
}
