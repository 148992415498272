import React from 'react'
import LookupInput from '../LookupInput'
import DrugLookupInputModal from '../../DrugLookupInputModal'

export default ({ restrictFreeformEntry, ...props }) => {
  const lookUpOnly = restrictFreeformEntry
  return (
    <LookupInput component={DrugLookupInputModal} lookUpOnly={lookUpOnly} clearable={lookUpOnly} {...props} />
  )
}
