import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Can from '../Can'
import ButtonLink from '../ButtonLink'

import style from './style'

const KeyValueCard = props => {
  const { data = [], classes, className, deactivated, actions, ...rest } = props

  // data should be an array that looks like:
  // [[key, value], [key, value], ...]
  // or an object, which will be transformed into such an array

  const filterData = (data) => {
    if(_.isArray(data)) {
      return data.filter(e => e)
    }
    return _.map(data, (value, key) => {
      if(key && value) {
        return ([key, value])
      }
    })
  }

  const keyValuePairs = filterData(data)
    .map(([key, value, keyValuePairProps]) => {
      let {
        className: keyValuePairClassName,
        style,
        cardDatumWidth,
        onClick,
        ...restKeyValuePairProps
      } = keyValuePairProps || {}

      style = (
        cardDatumWidth === undefined
        ? style
        : { width: cardDatumWidth, maxWidth: cardDatumWidth, ...style }
      )

      return <div
        key={key}
        className={cx(
          classes.keyValuePairContainer,
          keyValuePairClassName
        )}
        style={style}
        {...restKeyValuePairProps}
      >
        <dt className={classes.key} children={key} />
        <dd
          className={classes.value}
          children={
            (!onClick) ? value : <ButtonLink onClick={onClick}>{value}</ButtonLink>
          }
          />
      </div>
    })

  const listClass = cx(classes.card, { [classes.faded]: deactivated, [classes.containerWithActions]: !!actions })

  return (
    <div className={cx(classes.container, className)}>
      <dl className={listClass} {...rest}>
        {keyValuePairs}
      </dl>
      {actions && <div className={classes.actionsContainer}>
        {
          actions.map((action, key) => {
            const actionComponent = (
              <IconButton key={key} aria-label={action.label} onClick={(e) => action.onClick(e)}>
                {action.icon}
              </IconButton>
            )
            return (action.permissions)
              ? <Can actions={action.permissions}>{actionComponent}</Can>
              : actionComponent
          })
        }
      </div>}
    </div>
  )
}

KeyValueCard.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.object
  })),
  deactivated: PropTypes.bool
}

export default withStyles(style)(KeyValueCard)
