import _ from 'lodash'
import { push, LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import { translations } from '../../../config'

import modalService from '../../../services/modalService'
import PostCreateUserActionsModal from '../../../containers/Users/UserRegistrationScreen/components/PostCreateUserActionsModal'

import { constants as userDetailsConstants, actions as userDetailActions, selectors as userDetailSelectors } from '../userDetails'
import { actions as organisationActions } from '../organisations'
import { selectors as authSelectors } from '../auth'
import { selectors as platformSelectors } from '../platforms'

class UserDetailsMiddleware {
  createUserSuccessMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && (action.type === userDetailsConstants.CREATE_USER || action.type === userDetailsConstants.REGISTER_USER)) {
      const { id, firstName, lastName, requiresApproval } = action.result
      const fullName = `${firstName} ${lastName}`

      if (action.type === userDetailsConstants.CREATE_USER) {
        const hasOpenActionModalEnabled = platformSelectors.getHasActionsModalAfterUserCreationEnabled(getState())
        if (hasOpenActionModalEnabled) {
          dispatch(push(`/`))
          return modalService.open({
            component: PostCreateUserActionsModal,
            user: { id, firstName, lastName }
          })
        } else {
          dispatch(push(`/users/${id}`))
        }
      }

      if (action.type === userDetailsConstants.REGISTER_USER) {
        dispatch(push(`/`))
      }

      const state = getState()
      let text = translations(`New user created`)

      const isMoreRegistration = platformSelectors.getHasMoreRegistrationEnabled(state)
      if (isMoreRegistration) {
        text = requiresApproval
          ? translations(`New user created - Pending approval`, { firstName, lastName })
          : translations(`New user created - Other`, { firstName, lastName })
      }

      modalService.continue({ title: fullName, text })
    }
  }

  loadUserMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const result = matchPath(action.payload.location.pathname, { path: '/users/:id', exact: true })
      const isRoutingToUserDetailsView = result && result.params.id !== 'add' && result.params.id !== 'invited' && result.params.id !== 'deletion-requested'
      const isLoggedIn = authSelectors.getIsLoggedIn(state)
      if (isRoutingToUserDetailsView && isLoggedIn) {
        const userId = result.params.id
        const platformId = platformSelectors.getCurrentPlatformId(getState())
        const currentUser = authSelectors.getCurrentUser(getState())
        if (currentUser.id === userId) {
          dispatch(userDetailActions.fetchUserDetails('@me'))
        } else {
          dispatch(userDetailActions.fetchUserDetails(userId))
        }
        dispatch(userDetailActions.fetchUserOrganisationPreferencesCount({ userId, platformId }))
        dispatch(userDetailActions.fetchUserOrganisationPreferences({ userId, platformId, limit: 100, offset: 0 }))
        dispatch(organisationActions.fetchPaginatedOrganisations({ userId, platformId, limit: 100, offset: 0 }))
      } else {
        const selectedUser = userDetailSelectors.getSelectedUser(state)
        if (selectedUser) {
          dispatch(userDetailActions.resetStore())
        }
      }
    }
  }

  loadUserProfileMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToMyProfileView = matchPath(action.payload.location.pathname, { path: '/my-profile', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const userId = _.get(authSelectors.getCurrentUser(getState()), 'id')
      const platformId = platformSelectors.getCurrentPlatformId(getState())
      if (isRoutingToMyProfileView && isLoggedIn) {
        dispatch(userDetailActions.fetchUserDetails('@me'))
        dispatch(userDetailActions.fetchUserOrganisationPreferencesCount({ userId, platformId }))
        dispatch(userDetailActions.fetchUserOrganisationPreferences({ userId, platformId, limit: 100, offset: 0 }))
        dispatch(organisationActions.fetchPaginatedOrganisations({ userId, platformId, limit: 100, offset: 0 }))
      }
    }
  }

  showChangePasswordModalMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === userDetailsConstants.CHANGE_USER_PASSWORD && action.status === 'SUCCESS') {
      // changing other users password
      const userBefore = userDetailSelectors.getSelectedUser(getState())
      const { firstName, lastName } = action.result
      const fullName = `${firstName} ${lastName}`

      const text = userBefore.accountLocked
        ? `You have successfully unlocked the account for ${fullName}`
        : `You have successfully changed the password for ${fullName}`
      const title = userBefore.accountLocked
        ? translations('Account Unlocked')
        : translations('Password Changed')

      modalService.continue({ title, text })
    } else if (action.type === userDetailsConstants.CHANGE_CURRENT_USER_PASSWORD && action.status === 'SUCCESS') {
      // changing your own password
      modalService.continue({
        title: translations('User Details - Change Own Password Logout Warning Modal - Title'),
        text: translations('User Details - Change Own Password Logout Warning Modal - Text'),
        disableBackdropClick: true
      })
    }
  }
}

export default new UserDetailsMiddleware()
