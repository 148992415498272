import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Chip from '../../../../components/Chip'
import { DataTableRow, DataTableCell } from '../../../../components/DataTable'
import { translations } from '../../../../config'
import styles from './style'
import { handleApprovalStatus, handleOrigin } from '../../handlers'
import { useChangeManagementModal } from '../hooks'


const SubmissionReportsRow = (props) => {
  const {
    classes,
    entry
  } = props
  const approvalStatusCharacteristics = handleApprovalStatus({ approvalStatus: entry.approvalStatus })
  const originCharacteristics = handleOrigin({ entry })
  const showChangeManagementModal = useChangeManagementModal({ changeRequestId: entry.id })
  return (
    <DataTableRow className={classes.rowClass} >
      <DataTableCell>{originCharacteristics.message}</DataTableCell>
      <DataTableCell>
        <Chip variant={approvalStatusCharacteristics.chipVariant} clickable onClick={showChangeManagementModal} label={translations(approvalStatusCharacteristics.chipLabel)} />
      </DataTableCell>
      <DataTableCell compact nowrap>{originCharacteristics.raisedBy}</DataTableCell>
      <DataTableCell compact nowrap>{originCharacteristics.createdAt}</DataTableCell>
    </DataTableRow>
  )
}

SubmissionReportsRow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  entry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    methodType: PropTypes.oneOf([
      'CREATE',
      'UPDATE',
      'DELETE'
    ]),
    validationStatus: PropTypes.oneOf([
      'APPROVED',
      'REJECTED',
      'PENDING'
    ]),
    origin: PropTypes.oneOf([
      'orgUser'
    ])
  }).isRequired,
}

export default withStyles(styles)(SubmissionReportsRow)
