import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import PlatformsManagementScreen from './PlatformsManagementScreen'
import NewPlatformScreen from './NewPlatformScreen'
import ViewPlatformScreen from './ViewPlatformScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/platforms' path='/platforms' exact component={TimedLogoutComponent(PlatformsManagementScreen)} />,
  <PrivateRoute key='/platforms/new' path='/platforms/new' exact component={TimedLogoutComponent(NewPlatformScreen)} />,
  <PrivateRoute key='/platforms/:id' path='/platforms/:id' exact component={TimedLogoutComponent(ViewPlatformScreen)} />
]
