"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  label: 'AD',
  value: '47'
}, {
  label: 'Ampoule',
  value: 'C30'
}, {
  label: 'Applicatorfull',
  value: '12'
}, {
  label: 'Bq',
  value: '39'
}, {
  label: 'Capsule',
  value: 'C32'
}, {
  label: 'Centigram',
  value: '14'
}, {
  label: 'Cubic Centimeter',
  value: 'C31'
}, {
  label: 'Curies',
  value: '22'
}, {
  label: 'Dermatological Preparation',
  value: '08'
}, {
  label: 'Dosage forms',
  value: '23'
}, {
  label: 'Dram',
  value: '18'
}, {
  label: 'Drop',
  value: 'C33'
}, {
  label: 'GBq',
  value: '40'
}, {
  label: 'Grain',
  value: '17'
}, {
  label: 'Gram',
  value: '01'
}, {
  label: 'IU (International Unit)',
  value: '05'
}, {
  label: 'IU/kg',
  value: '45'
}, {
  label: 'International units millions',
  value: '25'
}, {
  label: 'International units thousands',
  value: '24'
}, {
  label: 'Kilogram',
  value: '15'
}, {
  label: 'Liters',
  value: '20'
}, {
  label: 'Lozenge',
  value: 'C34'
}, {
  label: 'MBq',
  value: '41'
}, {
  label: 'Microgram',
  value: '03'
}, {
  label: 'Microgram/Kilogram',
  value: 'C43'
}, {
  label: 'Microgram/kg/hr',
  value: 'C46'
}, {
  label: 'Microgram/kg/min',
  value: 'C47'
}, {
  label: 'Micromol',
  value: '29'
}, {
  label: 'Millicuries',
  value: '21'
}, {
  label: 'Milliequivalents',
  value: '26'
}, {
  label: 'Milligram',
  value: '02'
}, {
  label: 'Milligram/Kilogram',
  value: 'C42'
}, {
  label: 'Milligram/Milliliters',
  value: '09'
}, {
  label: 'Milligram/kg/hr',
  value: 'C44'
}, {
  label: 'Milligram/kg/min',
  value: 'C45'
}, {
  label: 'Milliliter',
  value: 'C35'
}, {
  label: 'Millimol',
  value: '28'
}, {
  label: 'Minim',
  value: 'C41'
}, {
  label: 'Mol',
  value: '27'
}, {
  label: 'Nanogram',
  value: '04'
}, {
  label: 'Other',
  value: '99'
}, {
  label: 'Ounce',
  value: '19'
}, {
  label: 'PUFF',
  value: 'C48'
}, {
  label: 'Percent',
  value: '10'
}, {
  label: 'Picogram',
  value: '13'
}, {
  label: 'Pound',
  value: '16'
}, {
  label: 'Sachet',
  value: '06'
}, {
  label: 'Suppository',
  value: 'C36'
}, {
  label: 'Tablet',
  value: 'C37'
}, {
  label: 'Teaspoon',
  value: 'C38'
}, {
  label: 'Topical Preparation',
  value: '11'
}, {
  label: 'Troche',
  value: 'C39'
}, {
  label: 'Unit',
  value: 'C40'
}, {
  label: 'Unknown',
  value: '98'
}, {
  label: 'kbq',
  value: '42'
}, {
  label: 'mg/kg',
  value: '33'
}, {
  label: 'mg/m2',
  value: '35'
}, {
  label: 'ml',
  value: '37'
}, {
  label: 'nci',
  value: '44'
}, {
  label: 'uCi',
  value: '43'
}, {
  label: 'ug/kg',
  value: '34'
}, {
  label: 'ug/m2',
  value: '36'
}, {
  label: 'ul',
  value: '38'
}];
exports["default"] = _default;