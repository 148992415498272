import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FullScreenProgress from '../../components/FullScreenLoader'
import { Helmet } from 'react-helmet'

const ConfigProvider = ({ children, theme, title, favicon }) => {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <link rel='shortcut icon' href={favicon} />
      </Helmet>
      {children(theme)}
    </Fragment>
  )
}

ConfigProvider.propTypes = {
  children: PropTypes.func.isRequired,
  theme: PropTypes.object,
  title: PropTypes.string,
  favicon: PropTypes.string
}

export default FullScreenProgress(ConfigProvider)
