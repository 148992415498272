"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'email',
    field: 'Input',
    props: {
      shrink: true,
      name: 'email',
      label: 'Email Address',
      required: true,
      validate: [validators.isValidEmail]
    }
  }];
};

exports.getFormSchema = getFormSchema;