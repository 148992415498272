import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../config'
import Button from '../../../../components/Button'
import Dropdown from '../../../../components/Fields/Dropdown'
import style from './style'

class MoveSectionModal extends Component {
  state = {
    selectedSection: null
  }
  selectSection = (value) => {
    this.setState({
      selectedSection: value
    })
  }
  render() {
    const {
      sectionKey,
      sectionNames,
      nestedSections,
      closeModal,
      questionKey,
      moveQuestionToSection,
      classes,
      isHeading
    } = this.props
    const { selectedSection } = this.state
    const sections = sectionNames()
    sections.splice(sectionKey, 1)
    return (
      <div>
        <h3>{translations('Move Question To Section Title')}</h3>
        <p>{translations('Move Question To Section')}</p>
        <Dropdown
          options={sections}
          value={selectedSection}
          onChange={(e) => this.selectSection(e.target.value)}
        />
        <div className={classes.buttonContainer}>
          <Button
            raised
            buttonType='white'
            style={{ marginRight: 10 }}
            onClick={() => closeModal()}
          >
            {translations('Cancel')}
          </Button>
          <Button
            raised
            buttonType='primary'
            onClick={() => {
              if (selectedSection) {
                moveQuestionToSection({
                  questionKey,
                  moveToSections: [selectedSection],
                  nestedSections,
                  isHeading
                })
              }
            }}
          >
            {translations('Confirm')}
          </Button>
        </div>
      </div>
    )
  }
}

MoveSectionModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  sectionKey: PropTypes.number,
  sectionNames: PropTypes.func,
  closeModal: PropTypes.func,
  questionKey: PropTypes.number,
  moveQuestionToSection: PropTypes.func
}

export default withStyles(style)(MoveSectionModal)
