import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { styled } from '@material-ui/core/styles'

import QuestionIcon from '@material-ui/icons/ListAltSharp'
import RepeatableIcon from '@material-ui/icons/Repeat'
import HeadingIcon from '@material-ui/icons/TextFields'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import Flags from '../Flags'
import DragHandle from '../DragHandle'
import { useMoreOptions } from '../../MoreMenu'

import { translations } from '../../../config'
import { Draggable } from 'react-beautiful-dnd'

const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '&:last-child': {
    marginBottom: 0
  }
}))

const FieldContainer = styled(Paper)(({ theme, disabled = false, disableDrag }) => ({
  position: 'relative',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(disableDrag ? 1 : 4),
  backgroundImage: disabled ? `linear-gradient(45deg, ${theme.palette.grey[100]} 25%, #eaeaea 25%, #eaeaea 50%, ${theme.palette.grey[100]} 50%, ${theme.palette.grey[100]} 75%, #eaeaea 75%, #eaeaea 100%)` : 'none',
  backgroundColor: disabled ? theme.palette.grey[100] : '#fff',
  backgroundSize: disabled ? '8px 8px' : 'initial',
  transition: 'border-color .3s ease-in-out',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: disabled ? theme.palette.grey[200] : theme.palette.grey[300],
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.grey[500]
  }
}))

const Icon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 34,
  height: 34,
  color: theme.palette.grey[800],
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 32,
  background: '#fff'
}))

const Label = styled('p')(({ theme }) => ({
  ...theme.typography.body2,
  lineHeight: 1.4,
  margin: 0
}))

const Meta = styled(Label)(({ theme }) => ({
  fontSize: 11,
  marginRight: theme.spacing(1)
}))

const Menu = styled('div')(({ theme }) => ({
  color: theme.palette.grey[800]
}))

/**
 * Each `FieldBase` is an element within the form that represents a visual element.
 */
export const FieldBase = (props) => {
  const {
    id,
    name,
    field,
    displayName,
    type = 'QUESTION',
    isCustom,
    visible,
    mandatory,
    hideFromForm,
    editable,
    onEdit,
    onMove,
    onChangeSection,
    onChangeBlock,
    onRemoveFromBlock,
    onAddToBlock,
    onDelete,
    onToggleVisibility,
    isFirst,
    isLast,
    index,
    disableDrag
  } = props
  const [flags, setFlags] = useState([])
  const { MoreMenu, moreMenuProps, hasMoreMenu } = useMoreOptions({ onEdit, onMove, onDelete, onToggleVisibility, visible, isFirst, isLast, onChangeSection, onChangeBlock, onAddToBlock, onRemoveFromBlock })
  const draggableId = `${id}`

  const getIcon = ({ type, visible }) => {
    const iconProps = { fontSize: 'small' }
    if (!visible) {
      return <VisibilityOffIcon {...iconProps} />
    }
    switch (type) {
      case 'REPEATABLE':
        return <RepeatableIcon {...iconProps} />
      case 'HEADING':
        return <HeadingIcon {...iconProps} />
      default:
        return <QuestionIcon {...iconProps} />
    }
  }

  useEffect(() => {
    const flags = []

    if (mandatory) {
      flags.push({
        id: 'mandatory',
        label: 'Required'
      })
    }

    if (isCustom) {
      flags.push({
        id: 'custom',
        label: 'Custom'
      })
      if (field) {
        flags.push({
          id: 'field',
          label: translations(`Field type - ${field}`)
        })
      }
    }

    if (hideFromForm) {
      flags.push({
        id: 'readonly',
        label: 'Read only',
        tooltip: 'Report Configuration - Read Only help'
      })
    }

    setFlags(flags)
  }, [mandatory, isCustom, hideFromForm])

  if (editable === false) {
    return null
  }

  return (
    <Draggable draggableId={draggableId} index={index}>
      {provided => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <FieldContainer variant='outlined' disabled={!visible} disableDrag={disableDrag} onClick={onEdit}>
            {!disableDrag && <DragHandle dragHandleProps={provided.dragHandleProps} />}
            <Grid container spacing={1} alignItems='center'>
              <Grid item>
                <Icon>
                  <Tooltip title={translations(`Report Configuration - Question Type - ${type}`)} placement='top'>
                    {getIcon({ type, visible })}
                  </Tooltip>
                </Icon>
              </Grid>
              <Grid item xs>
                <Grid container spacing={0} alignItems='center'>
                  <Grid item xs>
                    <Grid item xs={12}>
                      <Label>{displayName || name || id}</Label>
                    </Grid>
                    <Grid item xs={12}>
                      {displayName && <Meta>{name}</Meta>}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Grid container spacing={0} justify='flex-end'>
                      <Grid item>
                        <Flags flags={flags} justify='flex-end' />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {hasMoreMenu && (
                <Grid item>
                  <Menu>
                    <MoreMenu id={id} {...moreMenuProps} />
                  </Menu>
                </Grid>
              )}
            </Grid>
          </FieldContainer>
        </Container>
      )}
    </Draggable>
  )
}

FieldBase.defaultProps = {
  type: 'QUESTION',
  isCustom: false,
  mandatory: false,
  visible: true,
  hideFromForm: false,
  displayName: '',
  editable: true,
  isFirst: false,
  isLast: false,
  disableDrag: false
}

FieldBase.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  type: PropTypes.oneOf(['QUESTION', 'HEADING', 'REPEATABLE']),
  isCustom: PropTypes.bool,
  visible: PropTypes.bool,
  mandatory: PropTypes.bool,
  hideFromForm: PropTypes.bool,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  onMove: PropTypes.func,
  onDelete: PropTypes.func,
  onToggleVisibility: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool
}

export default FieldBase
