import { colors } from '../../../config/theme'

export default theme => {
  return {
    container: {
      padding: '20px 20px 10px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 20
      }
    },
    meta: {
      marginTop: 5,
      marginBottom: 6,
      [theme.breakpoints.up('sm')]: {
        marginTop: 2,
        marginBottom: 0
      }
    },
    actions: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex'
    },
    primaryChip: {
      backgroundColor: colors.darkGrey,
      color: '#ffffff'
    },
    secondaryChip: {
      backgroundColor: '#ffffff',
      border: `1px solid ${colors.mediumGrey}`
    },
    chip: {
      marginRight: 3,
      marginLeft: 3,
      marginTop: 6,
      textTransform: 'uppercase',
      fontSize: 10,
      [theme.breakpoints.up('sm')]: {
        marginRight: 6,
        marginLeft: 0,
        fontSize: 11
      }
    }
  }
}
