import _ from 'lodash'
import fp from 'lodash/fp'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class UserReducers {
  searchUserFresh = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchUserFreshPending(state, action)
      case SUCCESS:
        return this.searchUserFreshSuccess(state, action)
      case FAIL:
        return this.searchUserFreshFail(state, action)
      default:
        return state
    }
  }

  searchUserFreshPending = (state, action) => {
    const changes = fp.compose(
      fp.omitBy(fp.isUndefined),
      fp.pick([
        'query',
        'roleIds',
        'organisationIds',
        'includeDeactivated',
        'changeRequestStatuses',
        'accountTypes'
      ])
    )(action)
    return {
      ...state,
      ...changes,
      status: PENDING,
      action: action.type
    }
  }

  searchUserFreshSuccess = (state, action) => {
    const { total, results } = action.result
    return {
      ...state,
      total,
      page: 1,
      results,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  searchUserFreshFail = (state, action) => ({
    ...state,
    error: action.error.message,
    status: FAIL,
    action: undefined
  })

  searchUserNext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchUserNextPending(state, action)
      case SUCCESS:
        return this.searchUserNextSuccess(state, action)
      case FAIL:
        return this.searchUserNextFail(state, action)
      default:
        return state
    }
  }

  searchUserNextPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  searchUserNextSuccess = (state, action) => {
    const { total, results, query, organisationIds, roleIds, includeDeactivated } = action.result
    const { page, results: currentResults, query: currentQuery, organisationIds: currentOrganisationIds, roleIds: currentRoleIds, includeDeactivated: currentIncludeDeactivated } = state
    if (
      query === currentQuery &&
      includeDeactivated === currentIncludeDeactivated &&
      _.isEqual(roleIds, currentRoleIds) &&
      _.isEqual(organisationIds, currentOrganisationIds)
    ) {
      return {
        ...state,
        total,
        page: page + 1,
        results: [...currentResults, ...results],
        status: SUCCESS,
        action: undefined,
        error: undefined
      }
    } else {
      return state
    }
  }

  searchUserNextFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  updateUserInList = (state, action) => {
    // results may not exist if the user has not searched for a user yet (clicked the burger menu option)
    return {
      ...state,
      results: state.results
        ? state.results.map(user => user.id === action.user.id ? action.user : user)
        : []
    }
  }
}

export default new UserReducers()
