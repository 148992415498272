import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InfiniteScroll from 'react-infinite-scroller'
import _ from 'lodash'
import { getImage } from '../../Images'
import { Table, TableHeader, TableRow, TableCell } from '../../Table'
import { translations } from '../../../config'
import styles from './style'

class LookupInputModalTable extends Component {
  renderError = () => {
    const { classes } = this.props
    return <div className={classes.content}>Sorry, an error has occurred. Please try again.</div>
  }

  renderTableRows = () => {
    const { results, classes, onResultClick } = this.props
    return _.chain(results)
      .map(result => {
        const formattedResult = _.isObject(result) && result.label ? result.label : result
        return (
          <TableRow key={formattedResult} onClick={() => onResultClick(result)}>
            <TableCell xs={12} sm={12}>{formattedResult}</TableCell>
          </TableRow>
        )
      })
      .value()
  }

  render () {
    const { error, noResults, hasSearched, onSearchNext, onSearch, hasMore, results, classes } = this.props
    if (error) {
      return this.renderError()
    } else {
      return (
        <InfiniteScroll
          pageStart={0}
          className={classes.container}
          loadMore={onSearchNext}
          hasMore={hasMore}
          initialLoad={false}
          useWindow={false}
        >
          {
            results &&
            <Table
              header={(
                <TableHeader>
                  <TableCell xs={12} sm={12}>{translations('Results')}</TableCell>
                </TableHeader>
              )}
              rows={this.renderTableRows()}
            />
          }
          {noResults && hasSearched &&
            <div className={`${classes.content} ${classes.uppercase}`}>No results found</div>
          }
        </InfiniteScroll>
      )
    }
  }
}

export default withStyles(styles)(LookupInputModalTable)
