import { connect } from 'react-redux'
import UsersSearchFilters from './UsersSearchFilters'
import { actions as usersActions, selectors as usersSelectors } from '../../../../store/modules/users'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { authCombinedSelectors } from '../../../../store/modules/combinedSelectors'
import { selectors as roleSelectors } from '../../../../store/modules/roles'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const roleIds = usersSelectors.getRoleIds(state)
  const organisationIds = usersSelectors.getOrganisationIds(state)
  const includeDeactivated = usersSelectors.getIncludeDeactivated(state)
  const accountTypes = usersSelectors.getAccountTypes(state)
  const searchUsers = ({ roleIds, organisationIds, changeRequestStatuses, includeDeactivated, accountTypes }) => dispatch(usersActions.searchUsersFresh({ roleIds, organisationIds, changeRequestStatuses, includeDeactivated, accountTypes }))
  const loggedInUserOrganisationsOptions = authSelectors.getUserOrganisationsOptions(state)
  const rolesForPlatform = roleSelectors.getRoleOptionsForPlatform(state)
  const numberOfActiveFilters = usersSelectors.getNumberOfActiveFilters(state)
  const hasApprovalRequirementForJoiningOrganisations = platformSelectors.getRequireApprovalForJoiningOrganisations(state)

  return {
    ...ownProps,
    searchUsers,
    includeDeactivated,
    roleIds,
    organisationIds,
    loggedInUserOrganisationsOptions,
    allRolesOptions: rolesForPlatform,
    numberOfActiveFilters,
    hasApprovalRequirementForJoiningOrganisations,
    accountTypes
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UsersSearchFilters)
