import { createBrowserHistory, createHashHistory } from 'history'
import createStore from './setup/createStore'

let history
if (window.cordova) {
  history = createHashHistory()
} else {
  history = createBrowserHistory()
}

const { store, persistor } = createStore({ history })

export { store, persistor, history }
export default store
