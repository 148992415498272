import _ from 'lodash'
import styled from 'styled-components'

import { IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'

import { translations } from '../../../../../../../config'
import { openDeleteRepeatableInstanceModal, openEditRepeatableInstanceModal } from '../../utils'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 5px;
`

const Name = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 600;
`

const RepeatableType = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 200;
`

const RepeatableInstance = ({
  repeatableInstance,
  repeatableOptions,
  onEdit,
  onDelete
}) => {

  return (
    <Container>
      <TextContainer>
        <Name>{repeatableInstance.name}</Name>
        <RepeatableType>{getRepeatableTypeLabel(repeatableInstance.repeatableType, repeatableOptions)}</RepeatableType>
      </TextContainer>
      <IconButton
        aria-label={translations('Report Configuration - Edit Repeatable Instance')}
        onClick={() => openEditRepeatableInstanceModal(onEdit, repeatableInstance, repeatableOptions)}
      >
        <Edit />
      </IconButton>
      <IconButton
        aria-label={translations('Report Configuration - Delete Repeatable Instance')}
        onClick={() => openDeleteRepeatableInstanceModal(onDelete, repeatableInstance.id)}
      >
        <Delete />
      </IconButton>
    </Container>
  )
}

const getRepeatableTypeLabel = (value, options) => {
  return _.get(_.find(options, option => option.value === value), 'label')
}

export default RepeatableInstance