import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  result: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.EXPORT_ANALYTICS:
      return reducers.exportAnalytics(state, action)
    case constants.GET_ORGANISATION_ANALYTICS:
      return reducers.getOrganisationAnalytics(state, action)
    case constants.CHANGE_REPORTS_FILTERS:
      return reducers.changeFilters(state, action)
    default:
      return state
  }
}
