import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { translations } from '../../config'
import { FormSubmit, FormBody, FormError } from '../Form'
import style from './style'
import SubHeader from '../SubHeader'
import Heading from '../Heading'

const NewRepeatableForm = ({
  handleSubmit,
  onSubmit,
  pristine,
  reset,
  submitting,
  invalid,
  children,
  error,
  initialValues,
  editing = true,
  givenClass,
  classes,
  schema,
  label,
  closeModal,
  editMode,
  Form,
  validate,
  isDisabled,
  ...props
}) => {
  return (
    <div className={classes.container}>
      <SubHeader
        centerContent={(
          <Heading component='h1'>{label}</Heading>
        )}
        rightContent={(
          <div>
            <IconButton aria-label={translations('Close')} onClick={() => closeModal()}><CloseIcon /></IconButton>
          </div>
        )}
      />
      <div className={`${classes.root} ${givenClass}`} style={{ width: '100%' }}>
        <Form onSubmit={onSubmit} initialValues={initialValues} editing={editing} validate={validate}>
          <FormError />
          <FormBody schema={schema} />
          {editing && <FormSubmit big fullWidth noContainer className={classes.footerButton} disabled={isDisabled}>{editMode ? translations('Update') : translations('Add')}</FormSubmit>}
        </Form>
      </div>
    </div>
  )
}

export default withStyles(style)(NewRepeatableForm)
