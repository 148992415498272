import { promiseReducer } from '../../util'

class ThemeDetailsReducers {
  fetchThemeDetails = promiseReducer(
    (state, action) => {
      return {
        ...state,
        theme: action.result
      }
    }
  )
  updateTheme = promiseReducer(
    (state, action) => {
      return {
        ...state
      }
    }
  )
  createTheme = promiseReducer(
    (state, action) => {
      return {
        ...state
      }
    }
  )
}

export default new ThemeDetailsReducers()
