import React from 'react'
import _ from 'lodash'

import { Helmet } from 'react-helmet'
import { styled } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { selectors as reportDetailsSelectors } from '../../../store/modules/reportDetails'
import { translations } from '../../../config'
import VisuallyHidden from '../../../components/VisuallyHidden'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import XMLEditor from './XMLEditor'
import { getPageTitle } from '../../../store/modules/platforms/selectors'
import Heading from '../../../components/Heading'

const Container = styled('div')({
  position: 'relative'
})

const Content = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const ReportAmendmentScreen = ({}) => {

  const report = useSelector(reportDetailsSelectors.getReport)
  const pageTitle = useSelector(getPageTitle)('Report Amendment Screen')

  const reportId = _.get(report, 'id')
  const reportXML = _.get(report, 'reportXML', '')
  const reportType = _.get(report, 'formSchema.name')

  return (
    <Container>
      <VisuallyHidden>
        <h1>{translations('Report Amendment')}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={
          <Heading component='h1' uppercase>
            {pageTitle.title}
          </Heading>
        }
      />
      <Content>
        <XMLEditor 
          reportId={reportId}
          reportXML={reportXML}
          reportType={translations(`Form Schema - ${reportType}`)}
        />
      </Content>
    </Container>
  )
}

export default ReportAmendmentScreen
