import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import IntegrationManagementScreen from './IntegrationManagementScreen'

import TimedLogoutComponent from '../../components/TimedLogout'
import IntegrationManagementEditReportFormScreen from './IntegrationManagementEditReportForm'

const routes = [
  <PrivateRoute key='/integration-management' path='/integration-management' exact component={TimedLogoutComponent(IntegrationManagementScreen)} />,
  <PrivateRoute key='/integration-management/edit' path='/integration-management/edit' exact component={TimedLogoutComponent(IntegrationManagementEditReportFormScreen)} />
]

export default routes
