import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import _ from 'lodash'

export const getIsLoading = state => state.reportAcknowledgements.status === PENDING
export const getError = state => state.reportAcknowledgements.error
export const getIsInitial = state => !state.reportAcknowledgements.status
export const getStatus = state => state.reportAcknowledgements.status
export const getPage = state => state.reportAcknowledgements.page
export const getSize = state => state.reportAcknowledgements.size
export const getTotal = state => state.reportAcknowledgements.total
export const getHasResults = state => _.get(state, 'reportAcknowledgements.results') && _.get(state, 'reportAcknowledgements.results').length
export const getResults = state => _.get(state, 'reportAcknowledgements.results', [])

export const getHasMore = createSelector([
  getTotal,
  getResults
], (total, results) => {
  return total && results.length < total
})
