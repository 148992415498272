import OrganisationReportingFullList from './OrganisationReportingFullList'
import { connect } from 'react-redux'
import { selectors } from '../../../../../store/modules/platformHealthCheck'
import moment from 'moment'
import _ from 'lodash'

const mapStateToProps = state => {
  const organisationHealthCheck = selectors.getSelectedOrganisationHealthCheck(state)
  const lastUpdated = moment(_.get(organisationHealthCheck, 'lastUpdated', moment())).format('hh:mm:ss - DD/MM/YYYY')
  return {
    organisationHealthCheck,
    lastUpdated
  }
}

export default connect(mapStateToProps)(OrganisationReportingFullList)
