import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class ThemeActions {
  fetchAllThemes = () => ({
    type: constants.FETCH_ALL_THEMES,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.themes
        .fetchAllThemes()
    }
  })
  fetchCurrentTheme = ({ id }) => ({
    type: constants.FETCH_CURRENT_THEME,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.themes
        .fetchTheme({ id })
    }
  })
}

export default new ThemeActions()
