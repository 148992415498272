import React, { Fragment } from 'react'
import { Field, FieldArray } from 'redux-form'
import { Input, Grid, Button } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import * as validators from '../../../validators'

const Container = styled('div')(({ theme, divider = false }) => ({
  borderTop: divider ? `1px solid ${theme.palette.grey[200]}` : 'none',
  marginTop: divider ? theme.spacing(1) : 0,
  paddingTop: divider ? theme.spacing(2) : 0
}))

const renderOption = ({
  label,
  input
}) => (
  <Input
    placeholder={label}
    {...input}
    fullWidth
  />
)

const renderOptions = ({
  fields
}) => (
  <Grid container spacing={2} >
    <Grid item xs={12}>
      <Button
        onClick={() => fields.push({})}
        startIcon={<AddIcon />}
      >Add Option</Button>
    </Grid>
    {fields.map((option, index) => (
      <Fragment key={index}>
        <Grid item xs={5}>
          <Field
            name={`${option}.value`}
            component={renderOption}
            label={'Value'}
            validate={validators.required}
          />
        </Grid>
        <Grid item xs={5}>
          <Field
            name={`${option}.label`}
            component={renderOption}
            label={'Label'}
            validate={validators.required}
          />
        </Grid>
        <Grid item xs={2}>
        <Button onClick={() => fields.remove(index)}>
            <DeleteIcon fontSize='small' />
          </Button>
        </Grid>
      </Fragment>
    ))}
  </Grid>
)

const OptionArray = (props) => {
  const {
    label,
    divider,
    name
  } = props
  
  return (
    <Container divider={divider}>
      <FieldArray
        name={name}
        label={label}
        component={renderOptions}
      />
    </Container>
  )
}

export default OptionArray
