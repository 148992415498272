import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { withStyles } from '@material-ui/core/styles'

import { actions as usersActions, selectors as usersSelectors } from '../../../../store/modules/users'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { translations } from '../../../../config'
import { getFullName } from '../../../../helpers'
import { getImage } from '../../../../components/Images'

import UsersRowIcon from '../UsersRowIcon'
import UsersSearchTable from './UsersSearchTable'
import style from './style'

const deactivatedIcon = getImage('deactivatedIcon')

const mapStateToProps = state => {
  const results = usersSelectors.getResults(state)
  const hasMore = usersSelectors.getHasMore(state)
  const isLoading = usersSelectors.getIsLoading(state)
  const hasSearched = usersSelectors.getUsersHasBeenSearched(state)
  const error = usersSelectors.getError(state)
  const currentUserOrganisation = authSelectors.getUserOrganisations(state)
  const noResults = !isLoading && !(results || []).length

  return {
    isLoading,
    hasMore,
    results,
    noResults,
    hasSearched,
    error,
    currentUserOrganisation
  }
}

const formatOrganisationContent = (role, organisations = [], currentUserOrganisation = []) => {
  if (role.isAdmin) {
    return translations('All Organisations')
  }

  const userOrgsOnPlatforms = _.intersectionBy(organisations, currentUserOrganisation, 'id')
  if (_.size(userOrgsOnPlatforms) > 2) {
    return `${userOrgsOnPlatforms[0].name}, ${userOrgsOnPlatforms[1].name} (+${userOrgsOnPlatforms.length - 2} more)`
  }

  return userOrgsOnPlatforms.map(p => p.name).join(', ')
}

const formatRole = (role) => translations(`Role - ${role.name}`)

export default compose(
  withStyles(style),
  connect(mapStateToProps),
  withHandlers({
    searchUsers: ({ dispatch, isLoading }) => () => {
      if (!isLoading) {
        dispatch(usersActions.searchUsersNext())
      }
    },
    onUserClick: ({ dispatch, onUserClick, disabledUserId }) => user => {
      if (disabledUserId !== user.id) {
        if (onUserClick) {
          onUserClick(user)
        } else {
          dispatch(push(`/users/${user.id}`))
        }
      }
    }
  }),
  withPropsOnChange(
    ['results'],
    props => {
      const { results, onUserClick, classes, currentUserOrganisation } = props
      let rows = []

      if (results && results.length) {
        rows = results
          .map(user => {
            const { id, role, organisations, accountDeactivated, disabledUserId, accountType } = user
            const onClick = () => onUserClick(user)

            const userDeactivatedIndicator = <div className={classes.lockContainer}>
              <UsersRowIcon
                accountDeactivated={accountDeactivated}
                deactivatedIcon={deactivatedIcon}
              />
            </div>

            const isUserDisabled = disabledUserId && id === disabledUserId

            const data = [
              ['', userDeactivatedIndicator, { xs: 1, className: classes.hideXs }],
              ['Users Name', getFullName(user), { xs: 3, cardDatumWidth: '50%', onClick }],
              ['Users Role', formatRole(role), { xs: 3, cardDatumWidth: '50%' }],
              ['Users Organisation', formatOrganisationContent(role, organisations, currentUserOrganisation), { xs: 5, cardDatumWidth: '100%' }],
              ['Users Account Type', accountType, { xs: 3, cardDatumWidth: '50%' }]
            ].map(datum => {
              const [label, value, props] = datum
              return [translations(label), value, props]
            })

            return {
              key: id,
              onClick,
              data,
              deactivated: accountDeactivated || isUserDisabled
            }
          })
      }
      return { rows }
    }
  )
)(UsersSearchTable)
