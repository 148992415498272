import { colors } from '../../../config/theme'

const styles = {
  fieldWrap: {
    marginTop: 16
  },
  emptyLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      color: colors.darkGrey,
      fontSize: 14,
      margin: 0
    }
  }
}

export default theme => styles
