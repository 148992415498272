import _ from 'lodash'

class DevicesReducers {
  fetchDevices =
    (state, action) => {
      return {
        ...state,
        results: _.get(action, 'results')
      }
    }

  searchDevices = 
    (state, action) => {
      const results = _.get(action, 'results')
      const total = _.size(results)
      return {
        ...state,
        results,
        total,
        page: 1
      }
    }

}

export default new DevicesReducers()
