import React from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import FormSchemaLibrary from '@redant/mhra-form-schema-library'

import { withStyles, styled } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'

import { hooks as reportConfigurationHooks, actions as reportConfigurationActions } from '../../../store/modules/reportConfiguration'

import { translations } from '../../../config'
import InsertButton from '../../../components/FormviewEditor/InsertButton'
import modalService from "../../../services/modalService"
import toastService from "../../../services/toastService"

import FollowUpModal from './FollowUpModal'
import FollowUpFilters from './FollowUpFilters'
import FollowUpTimeframe from './FollowUpTimeframe'
import FollowUpRow from './FollowUpRow'
import styles from './style'
import { useFollowUpFilters } from '../../../store/modules/reportConfiguration/hooks'

const toastError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Save Report Config')
})
const toastSuccess = () => toastService.action({
  type: 'success',
  message: translations('Successfully - Save Report Config')
})
const duplicateError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Save Duplicate Report Config'),
  autoHideDuration: 3000
})

const InfoAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2)
}))

const AddFollowUpButton = ({
  addFollowUp,
  followUpNames,
  otherFollowUpNames,
  formViewId,
  organisationLanguages
}) => {
  const openNewFollowUpModal = () => {
    modalService.open({
      component: FollowUpModal,
      disableBackdropClick: true,
      wideModal: true,
      followUpNames,
      onSubmit: addFollowUp,
      closeModal: () => modalService.close(),
      title: translations('FollowUp - Modal Header'),
      formViewId,
      otherFollowUpNames,
      organisationLanguages
    })
  }
  return (
    <InsertButton
      fullWidth
      size='large'
      onClick={openNewFollowUpModal}
    >
      {translations('FollowUp - Add New Followup')}
    </InsertButton>

  )
}

const ReportConfigurationFollowUpDetails = ({ organisationLanguages }) => {
  const { id: formViewId } = useParams()
  const dispatch = useDispatch()
  const formSchemaLibrary = new FormSchemaLibrary()

  const followUps = reportConfigurationHooks.useTimeframeSortedFollowUps(formViewId) || []
  const timeframes = reportConfigurationHooks.useTimeframeById(formViewId) || []
  const activeFormView = reportConfigurationHooks.useFormViewById(formViewId)

  const filters = reportConfigurationHooks.useFollowUpFilters(formViewId)
  const {
    cohorts: cohortsFilter,
    triggers: triggersFilter
  } = filters

  const addFollowUp = (params) => {
    const schemaName = _.get(activeFormView, 'formSchema.name')
    const standardBase = formViewId && _.get(formSchemaLibrary.getDefaultView(schemaName, null), 'sections', [])
    dispatch(reportConfigurationActions.createFollowUp({ params: { ...params, viewJSON: standardBase }, formViewId }))
      .then(res => {
        modalService.close()
        toastSuccess()
        dispatch(reportConfigurationActions.searchFollowUpFresh({ formViewId }))
        return res
      })
      .catch(err => {
        // if duplicate FormView error 
        if (err.code === 'RA-MHR-157-06') {
          duplicateError()
        } else {
          toastError()
        }
      })
  }
  const filteredFollowUps = _.filter(followUps, followUp=>{
    const currentTrigger = _.get(followUp, 'followUpTrigger.id')
    const currentCohort = _.get(followUp, 'cohort.id')
    const matchesTrigger = !triggersFilter || currentTrigger === triggersFilter
    const matchesCohort = !cohortsFilter || currentCohort === cohortsFilter
    return matchesTrigger && matchesCohort
  })
  const groupedFollowUps = _.groupBy(filteredFollowUps, 'followUpTimeframeId')
  const sortedGroupedFollowUps = _.mapValues(groupedFollowUps, (followUps) => {
    return _.sortBy(followUps, (followUp) => {
      return _.get(followUp, 'formView.createdAt')
    })
  })
  const otherFollowUpNames = _.without(_.map(followUps, ({ formView: { name } = {} }) => name), null, undefined, '')

  return (
    <>
      <FollowUpFilters />
      {
        _.isEmpty(followUps)
          ? (
            <InfoAlert severity='info'>
              {translations('FollowUp - No Existing Followups')}
            </InfoAlert>
          )
          : (
            _.map(sortedGroupedFollowUps, (activeGroup, timeframeId) => {
              const activeTimeframe = _.find(timeframes, { id: timeframeId })
              return (
                <FollowUpTimeframe followUpTimeframe={activeTimeframe}>
                  { _.map(activeGroup, followUp => {
                    return ( <FollowUpRow followUp={followUp} formViewId={formViewId} organisationLanguages={organisationLanguages} />)
                  })}
                </FollowUpTimeframe>)
            })
          )
      }
      <AddFollowUpButton
        addFollowUp={addFollowUp}
        formViewId={formViewId}
        otherFollowUpNames={otherFollowUpNames}
        organisationLanguages={organisationLanguages}
      />
    </>
  )
}

export default withStyles(styles)(ReportConfigurationFollowUpDetails)