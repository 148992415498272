// export const CREATE_FORMVIEW = 'digitalStore/formView/CREATE_FORMVIEW'
export const FETCH_FORMVIEW_BY_ID = 'digitalStore/formView/FETCH_FORMVIEW_BY_ID'
export const FETCH_ALL_FORMVIEWS = 'digitalStore/formView/FETCH_ALL_FORMVIEWS'
export const UPDATE_FORMVIEW = 'digitalStore/formView/UPDATE_FORMVIEW'
export const CREATE_FORM_VERSION = 'digitalStore/formView/CREATE_NEW_VERSION'
export const UPDATE_FORM_VERSION = 'digitalStore/formView/UPDATE_VERSION'
export const TOGGLE_VISIBILITY = 'digitalStore/formView/TOGGLE_VISIBILITY'

export const MOVE_QUESTION = 'digitalStore/formView/MOVE_QUESTION'
export const MOVE_QUESTION_TO_SECTION = 'digitalStore/formView/MOVE_QUESTION_TO_SECTION'
export const ADD_QUESTION = 'digitalStore/formView/ADD_QUESTION'
export const CONFIGURE_FIELD = 'digitalStore/formView/CONFIGURE_FIELD'
export const ADD_SECTION = 'digitalStore/formView/ADD_SECTION'
export const DELETE_SECTION = 'digitalStore/formView/DELETE_SECTION'
export const DELETE_QUESTION = 'digitalStore/formView/DELETE_QUESTION'
export const EDIT_REPEATABLES = 'digitalStore/formView/EDIT_REPEATABLES'

export const NEW_SCHEMA_BASE = 'digitalStore/formView/NEW_SCHEMA_BASE'
export const SELECT_REPORT_TYPE = 'digitalStore/formView/SELECT_REPORT_TYPE'
export const SET_VIEW_JSON = 'digitalStore/formView/SET_VIEW_JSON'
export const SET_NOT_EDITABLE_JSON = 'digitalStore/formView/SET_NOT_EDITABLE_JSON'
export const CLEAR_NOT_EDITABLE_JSON = 'digitalStore/formView/CLEAR_NOT_EDITABLE_JSON'

export const RESET_STORE = 'digitalStore/formView/RESET_STORE'
export const RESET_BASE_SCHEMA = 'digitalStore/formView/RESET_BASE_SCHEMA'

export const REPORT_CONFIGURATION_FORM = 'digitalStore/formView/REPORT_CONFIGURATION_FORM'
export const REPEATABLE_CONFIGURATION_FORM = 'digitalStore/formView/REPEATABLE_CONFIGURATION_FORM'

export const CONFIGURE_REPORT_FORM = 'digitalStore/formView/CONFIGURE_REPORT_FORM'
export const CONFIGURE_SECTION_FORM = 'digitalStore/formView/CONFIGURE_SECTION_FORM'
export const IMPORT_CONFIGURATION_FORM = 'digitalStore/formView/IMPORT_CONFIGURATION_FORM'
export const EXPORT_CONFIGURATION_FORM = 'digitalStore/formView/EXPORT_CONFIGURATION_FORM'
export const SET_FORM_SCHEMA_UPDATED = 'digitalStore/formView/SET_FORM_SCHEMA_UPDATED'
export const SET_FORM_SCHEMA_UPDATE_SAVED = 'digitalStore/formView/SET_FORM_SCHEMA_UPDATE_SAVED'
export const GENERATE_REPORT_CONFIGURATION_TRANSLATIONS_CSV = 'digitalStore/formView/GENERATE_REPORT_CONFIGURATION_TRANSLATIONS_CSV'
