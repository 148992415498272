import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import FullScreenLoader from '../../../components/FullScreenLoader'
import { selectors as filesSelectors, actions as filesActions } from '../../../store/modules/files'
import { actions as multipartUploadActions, selectors as multipartUploadSelectors } from '../../../store/modules/multipartUpload'
import { actions as resourcesActions } from '../../../store/modules/resources'
import { selectors as platformsSelectors } from '../../../store/modules/platforms'
import { selectors as authSelectors } from '../../../store/modules/auth'
import linkService from '../../../services/linkService'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

import ViewPharmaceuticalReferralsScreen from './ViewPharmaceuticalReferralsScreen'

const enhance = compose(
  connect(state => {
    const retval = {
      isLoading: filesSelectors.getIsLoading(state),
      files: filesSelectors.getFiles(state),
      isUploadHappening: multipartUploadSelectors.getIsUploadHappening(state),
      getPageTitle: platformsSelectors.getPageTitle(state),
      canEditFiles: authSelectors.getCanEditFiles(state),
      userOrganisationIds: authSelectors.getUserOrganisationIds(state),
      isAdmin: authSelectors.getIsUserAdmin(state)
    }
    return retval
  }),
  withPropsOnChange(
    ['files', 'userOrganisationIds', 'canEditFiles', 'isAdmin'],
    ({ files, userOrganisationIds, canEditFiles, isAdmin, dispatch }) => {
      const filteredFiles = (
        canEditFiles
        ? files
        : files.filter(file => !!_.get(file, 'url'))
      )
      const sortedFiles = _.orderBy(filteredFiles, 'createdAt', 'desc')
      const shapedFiles = _.map(sortedFiles, (file) => {
        const { id, url, name, details, organisationId } = file

        // if an S3 url, get signed url to read with.
        // else just, open the url.
        let viewResource
        if (url) {
          const awsDomain = 'amazonaws.com/'
          const indexOfAwsDomain = url.indexOf(awsDomain)
          if (indexOfAwsDomain > -1) {
            const bucketAndKey = url.slice(indexOfAwsDomain + awsDomain.length)
            const indexOfFirstSlash = bucketAndKey.indexOf('/')
            const bucketName = bucketAndKey.slice(0, indexOfFirstSlash)
            const encodedKey = bucketAndKey.slice(indexOfFirstSlash + 1)
            const key = decodeURIComponent(encodedKey).replace(/\+/g, ' ')
            viewResource = () => {
              dispatch(resourcesActions.fetchResource({ key, bucketName }))
            }
          } else {
            viewResource = () => {
              linkService.open({ link: url })
            }
          }
        }

        let deleteResource
        const canAccessOrganisation = isAdmin || (organisationId && (userOrganisationIds || []).includes(organisationId))
        if (canEditFiles && canAccessOrganisation) {
          const deleteFile = () => dispatch(filesActions.deleteFile({ id }))
          deleteResource = () => modalService.action({
            title: translations('Pharmaceutical Referrals - Delete Modal Title', { name }),
            text: translations('Pharmaceutical Referrals - Delete Modal Text', { name }),
            actions: [
              { text: translations('Confirm'), onClick: deleteFile, success: true, primary: true },
              { text: translations('Cancel') }
            ]
          })
        }

        let text
        if (!url) {
          if (_.get(details, 'uploadFailed')) {
            text = translations('Pharmaceutical Referrals - File Upload Failed')
          } else {
            text = translations('Pharmaceutical Referrals - File Upload Incomplete')
          }
        }

        return { ...file, viewResource, deleteResource, text }
      })
      return { files: shapedFiles }
    }
  ),
  withHandlers({
    handleFile: ({ dispatch }) => (file) => {
      if (file) {
        const fileName = file.name
        const fileType = file.type
        const uploadType = 'PHARMACEUTICAL_REFERRAL'
        dispatch(filesActions.createFile({
          name: fileName,
          details: { uploadType }
        }))
          .then(createdFile => {
            const createdFileId = _.get(createdFile, 'id')
            return dispatch(multipartUploadActions.upload({
              file,
              fileName,
              fileType,
              uploadType
            }))
              .then(uploadResult => {
                const { Location: url } = uploadResult
                return dispatch(filesActions.updateFile({
                  id: createdFileId,
                  url,
                  details: {
                    uploadCompletedAt: moment().toISOString(),
                    uploadType
                  }
                }))
              })
              .catch(() => {
                return dispatch(filesActions.updateFile({
                  id: createdFileId,
                  details: {
                    uploadFailed: true,
                    uploadType
                  }
                }))
              })
          })
      }
    }
  }),
  FullScreenLoader
)

export default enhance(ViewPharmaceuticalReferralsScreen)
