import _ from 'lodash'
import fp from 'lodash/fp'
import qs from 'qs'
import { connect } from 'react-redux'
import { getFormValues, isDirty } from 'redux-form'
import { compose, withHandlers, withPropsOnChange, lifecycle } from 'recompose'
import { goBack } from 'connected-react-router'
import NewReport from './NewReport'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { actions as reportDetailsActions, constants as reportDetailsConstants, selectors as reportDetailsSelectors } from '../../../store/modules/reportDetails'
import { selectors as selectedReceiverSelectors } from '../../../store/modules/selectedReceiver'
import { selectors as organisationDetailsSelectors } from '../../../store/modules/organisationDetails'
import { authCombinedSelectors, platformCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as formViewSelectors } from '../../../store/modules/formViews'
import { selectors as formSchemaSelectors } from '../../../store/modules/formSchemas'

import modalService from '../../../services/modalService'
import FullScreenLoader from '../../../components/FullScreenLoader'
import { translations, getCurrentLanguage } from '../../../config'
import FormSchemaLibrary, { utilities } from '@redant/mhra-form-schema-library'

const enhancer = compose(
  connect((state) => {
    return {
      reportStatus: reportDetailsSelectors.getReportStatus(state),
      isDirty: isDirty(reportDetailsConstants.NEW_FORM_NAME)(state),
      initialValues: reportDetailsSelectors.getDetails(state),
      reportValues: reportDetailsSelectors.getReport(state),
      formValues: getFormValues(reportDetailsConstants.NEW_FORM_NAME)(state) || {},
      isLoading: selectedReceiverSelectors.getIsLoading(state) || reportDetailsSelectors.getIsLoading(state),
      isSchemaAvailable: selectedReceiverSelectors.getIsReportSchemaAvailable(state),
      reportUserId: reportDetailsSelectors.getReportUserId(state),
      userDetails: authSelectors.getCurrentUser(state),
      organisationId: _.get(authSelectors.getSelectedOrganisation(state), 'id'),
      organisationDetails: organisationDetailsSelectors.getCurrentOrganisationDetails(state),
      reportSchemas: formSchemaSelectors.getReportSchemas(state),
      userId: authSelectors.getActiveUserId(state),
      canLoadReports: platformCombinedSelectors.getHasLoadReportsPermissions(state),
      canUpdateReports: authCombinedSelectors.getHasUpdateReportsPermissions(state),
      showUpdates: authCombinedSelectors.userCanSeeReportUpdates(state),
      getPageTitle: platformSelectors.getPageTitle(state),
      hasNewerVersion: reportDetailsSelectors.getHasNewerVersion(state),
      organisationCombinedDetails: authCombinedSelectors.getDetailsForSelectedOrganisation(state),
      selectedReport: formViewSelectors.getSelectedReportType(state),
      viewJSON: formViewSelectors.getViewJSON(state),
      newSchemaBase: formViewSelectors.getNewSchemaBase(state),
      isPublicOrUnknownRoleUser: authSelectors.isPublicOrUnknownRole(state),
      isReadOnlyReport: reportDetailsSelectors.isReadOnlyReport(state),
      reportId: reportDetailsSelectors.getReportId(state),
      isUninitialised: reportDetailsSelectors.getIsUninitialised(state),
      selectedOrganisation: authSelectors.getSelectedOrganisation(state),
      isWorkspace: authSelectors.isLoggedIntoWorkspace(state),
      existingReport: reportDetailsSelectors.getExistingReport(state)
    }
  }),
  withPropsOnChange(['selectedReport', 'newSchemaBase', 'reportValues', 'initialValues', 'organisationCombinedDetails', 'match', 'reportSchemas', 'location', 'userDetails', 'selectedOrganisation', 'isWorkspace'], (props) => {
    const {
      selectedReport,
      reportValues,
      organisationCombinedDetails: orgCombinedDetails,
      reportSchemas,
      location,
      isPublicOrUnknownRoleUser,
      initialValues = {},
      userDetails,
      selectedOrganisation,
      isWorkspace
    } = props
    const queryString = _.get(location, 'search')
    const { drug } = qs.parse(queryString, { ignoreQueryPrefix: true })
    const product = _.get(location, 'state.product')
    const reportViewJSON = _.get(reportValues, 'formViewVersion.viewJSON')
    const reportSchemaId = _.get(reportValues, 'formSchema.id')
    const reportSchemaName = _.get(reportValues, 'formSchema.name')
    const organisationId = isWorkspace ? undefined : _.get(selectedOrganisation, 'id')

    const organisationCombinedDetails = isWorkspace ? {} : _.merge(
      orgCombinedDetails,
      _.get(selectedOrganisation, 'details', {}),
      { country: _.get(selectedOrganisation, 'country') }
    )

    const formSchemaLibrary = new FormSchemaLibrary()
    let viewJSON, schemaName, title, formView, formViewVersion, formViewVersionId, schemaId, formViewDetails, formSchemaOptions = {}
    let defaultValues = {}
    const defaultAudience = 1

    if (selectedReport) {
      formViewVersion = _.get(selectedReport, 'formViewVersions')
      formViewVersionId = _.chain(formViewVersion).head().get('id').value()
      schemaName = formViewVersion ? _.get(selectedReport, 'formSchema.name') : _.get(selectedReport, 'name')
      formSchemaOptions = _.get(selectedReport, 'details')
      viewJSON = formViewVersion ? { sections: _.chain(formViewVersion).head().get('viewJSON').value() } : undefined
      schemaId = formViewVersion ? _.get(selectedReport, 'formSchema.id') : _.get(selectedReport, 'id')
      title = formViewVersion ? _.get(selectedReport, 'name') : _.get(selectedReport, 'publicName') || _.get(selectedReport, 'name')
      formViewDetails = _.get(selectedReport, 'details')
      formView = selectedReport
    } else {
      schemaName = reportSchemaName
      viewJSON = reportViewJSON ? { sections: reportViewJSON } : undefined
      title = false
      formViewVersionId = _.get(reportValues, 'formViewVersionId')
      formViewDetails = _.get(reportValues, 'formViewVersion.formViews.details', {})
      formView = _.get(reportValues, 'formViewVersion.formViews')
    }
    let formSchema = null
    let latestViewForm = {}
    if (_.isString(schemaName)) {
      formSchema = formSchemaLibrary.processSchema(schemaName, viewJSON, translations, formSchemaOptions, { showAssessmentFields: !isPublicOrUnknownRoleUser })

      const generatedDefaultValues = utilities.generateInitialValues({
        isWorkspace,
        schemaName,
        initialValues,
        userDetails,
        organisationId,
        organisationDetails: organisationCombinedDetails,
        productDetails: product,
        formView
      })

      defaultValues = _.merge(defaultValues, generatedDefaultValues)
      const schemaVersion = schemaName === 'devicesReport' ? _.get(organisationCombinedDetails, 'devicesSchemaVersion') : null
      latestViewForm = formSchemaLibrary.getDefaultView(schemaName, schemaVersion)
    }

    // Pass users current language to the forms
    defaultValues = _.set(defaultValues, 'language', getCurrentLanguage())

    // the ability to load existing reports needs to now be done at a report selection level
    const loadableReports = _.includes([
      'devicesReport',
      'mhraR2Report',
      'fscaReport',
      'sabreSAE',
      'sabreSAR'
    ], schemaName)

    // if no audience, not a formView, use Industry as default audience
    if (!_.get(formViewDetails, 'audienceId')) {
      _.set(formViewDetails, 'audienceId', defaultAudience)
    }

    // Set initial audience value
    defaultValues = _.set(defaultValues, 'audienceid', _.get(formViewDetails, 'audienceId', defaultAudience))

    return {
      formSchema,
      formTitle: title,
      viewJSON,
      schemaName,
      formSchemaId: reportSchemaId || schemaId,
      formViewVersionId,
      initialFieldMap: _.isString(schemaName) && formSchemaLibrary.getSchemaInitialFieldMap(schemaName),
      defaultValues,
      latestViewForm,
      canLoadReports: loadableReports,
      formSchemaLibrary,
      formViewDetails,
      drug,
      product,
      selectedOrganisation,
      attachmentFields: _.isString(schemaName) && formSchemaLibrary.getSchemaAttachmentFields(schemaName),
      editable: _.get(reportValues, 'editable'),
    }
  }),
  withPropsOnChange(['formValues', 'match', 'organisationDetails', 'userDetails', 'isLoading', 'isUninitialised'], (props) => {
    const reportId = _.get(props, 'match.params.id')
    const notificationId = _.get(props, 'match.params.notificationId')
    const path = _.get(props, 'match.path')
    const isPublicNewFormMode = path === '/reports/new/public/:orgId'
    const isNewFormMode = (
      (reportId && reportId === 'new') ||
      isPublicNewFormMode
    )
    const searchQuery = _.get(props, 'location.search')
    const isOpenedInEdit = searchQuery && searchQuery.includes('edit=true')
    const editing = isNewFormMode || isOpenedInEdit
    const canSaveDraft = _.get(props, 'formValues.canSaveDraft')
    let defaultValues = _.get(props, 'defaultValues')
    let isLoading = props.isLoading

    if (!isNewFormMode) {
      defaultValues = _.set(defaultValues, 'isUpdate', true)
      isLoading = props.isUninitialised || isLoading
    }
    const loggedInUserId = _.get(props, 'userDetails.id')
    const newProps = {
      isLoading,
      canEdit: props.reportUserId === loggedInUserId,
      editing,
      isNewFormMode,
      isPublicNewFormMode,
      canSaveDraft,
      loadReport: props.canLoadReports && !isPublicNewFormMode,
      followUpNotificationId: notificationId,
      defaultValues
    }

    if (isPublicNewFormMode) {
      newProps.organisationId = _.get(props, 'match.params.orgId')
      newProps.isGuestSubmissionAllowed = _.get(props, 'organisationDetails.isGuestSubmissionAllowed')
    }

    return newProps
  }),
  withHandlers({
    onLoadExisting: ({ userId, organisationId, dispatch, formSchemaId }) => (e) => {
      const file = e.target.files[0]
      dispatch(reportDetailsActions.loadExistingReport({ file, formSchemaId }))
    },
    onSaveDraft: ({ dispatch, formViewVersionId, formSchemaId, attachmentFields, canSaveDraft }) => () => canSaveDraft && dispatch(reportDetailsActions.submitReportDraft({ formViewVersionId, formSchemaId, attachmentFields })),
    onUpdateDraft: ({ dispatch, formViewVersionId, formSchemaId, attachmentFields, canSaveDraft }) => () => canSaveDraft && dispatch(reportDetailsActions.updateReportDraft({ formViewVersionId, formSchemaId, attachmentFields })),
    onBackClick: ({ dispatch, isDirty, isNewFormMode, reportStatus, canSaveDraft, schemaName, formViewVersionId }) => ({ editing }) => {
      const _showUnsaveableModal = () => {
        modalService.action({
          title: translations('Warning'),
          text: translations(schemaName === 'devicesReport' ? `Discard Devices Report Notice Text` : `Discard Report Notice Text`),
          actions: [
            { text: translations('Cancel') },
            {
              text: translations('Discard changes and go back'),
              onClick: () => {
                dispatch(goBack())
              }
            }
          ]
        })
      }

      const _showSaveDraftModal = () => {
        modalService.action({
          title: translations('Save report as a draft?'),
          text: translations('You have made changes to this report, would you like to save as a draft?'),
          actions: [
            {
              success: true,
              text: translations('Save draft'),
              onClick: () => {
                dispatch(reportDetailsActions.submitReportDraft({ formViewVersionId }))
              },
              primary: true
            },
            { text: translations('Cancel') },
            {
              text: translations('Discard changes and go back'),
              onClick: () => {
                dispatch(goBack())
              }
            }
          ]
        })
      }

      if (editing) {
        if (isDirty && canSaveDraft) {
          _showSaveDraftModal()
        } else if (isDirty) {
          _showUnsaveableModal()
        } else {
          dispatch(goBack())
        }
      } else {
        dispatch(goBack())
      }
    }
  }),
  lifecycle({
    // componentDidMount() {
    //   if (!this.props.selectedReport && !this.props.reportValues) {
    //     this.props.dispatch(goBack())
    //   }
    // }
    componentWillUnmount() {
      this.props.dispatch(reportDetailsActions.resetReportFollowUpNotification())
    }
  }),
  FullScreenLoader
)

export default enhancer(NewReport)
