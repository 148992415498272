import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import fp from 'lodash/fp'

import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import { translation } from '../../services/translations'
import { composeValidators, requiredCheckbox, requiredValidator, requiredRepeatable } from '../../services/utilities/validators'

import Repeatable from '../Repeatable'
import ImperialToggle from '../ImperialToggle'
import Checklist from '../CheckList'
import CombinedRepeatable from '../CombinedRepeatable'

/**
 * Takes a field from a schema and maps the props
 */
const ReportFormField = (props) => {
  const {
    component: Component,
    field,
    id,
    fieldProps,
    change,
    getFieldState,
    getState
  } = props

  const {
    name,
    type,
    label,
    required,
    editable,
    disabled,
    disabledMessage,
    emptyMessage,
    helperText,
    value,
    options,
    validate = [],
    multiple,
    autocomplete,
    schema,
    searchType,
    parse,
    repeatableLabelPlaceholder,
    repeatableLabel,
    repeatableInformation,
    repeatableSeparator,
    repeatableDefaultValues,
    accept,
    displayOriginalFileName = false,
    enableDownloadFile = false,
    startYear,
    endYearOffset,
    imperialToggle,
    lookupOptions,
    restrictInput,
    showInitially,
    detailsList,
    checkboxInterfaceActive = true,
    checkboxInterfaceInactive = false,
    handleChangeOtherFields,
    nullFlavours,
    enableNullFlavours,
    freeSolo,
    readOnly,
    disableEdit,
    disableDelete,
    singleRow,
    privacyPolicyLink,
    formatResults,
    displayResults,
    lookupOptionsRequired,
    groupOptions,
    groupAttribute,
    parent,
    parentValue,
    parentLabel,
    actionLabels,
    maxLength
  } = fieldProps

  const commonFieldProps = {
    type,
    id,
    name,
    label: translation(label),
    editable,
    disabled,
    disabledMessage,
    emptyMessage,
    required,
    hint: helperText,
    searchType,
    parse,
    accept,
    startYear,
    endYearOffset,
    isMulti: multiple,
    displayOriginalFileName,
    enableDownloadFile
  }

  if (readOnly) {
    commonFieldProps.disabled = true
    commonFieldProps.readOnly = true
  }

  if (field === 'Repeatable') {
    commonFieldProps.disableEdit = disableEdit
    commonFieldProps.disableDelete = disableDelete
  }

  if (enableNullFlavours && !_.isEmpty(nullFlavours)) {
    commonFieldProps.nullFlavours = nullFlavours
  }

  if (field === 'Dropdown') {
    commonFieldProps.placeholder = translation('Please select')
    commonFieldProps.groupOptions = groupOptions
    commonFieldProps.groupAttribute = groupAttribute
    commonFieldProps.parent = parent
    commonFieldProps.parentValue = parentValue
    commonFieldProps.parentLabel = parentLabel
  }

  if (field === 'InfoList') {
    commonFieldProps.helpText = fieldProps.helpText
  }

  if (field === 'Input') {
    commonFieldProps.helpText = fieldProps.helpText
  }


  const listType = _.includes(['Checklist', 'Radiolist'], field)

  // React Final Form does not re-render when validators are updated.
  // Re-creating the key is a suggested method to make the field re-render.
  // https://final-form.org/docs/react-final-form/types/FieldProps
  const key = `${name}_${required ? 1 : 0}`

  const optionsWithPlaceholder = useMemo(() => {
    const stringToValueAndLabel = (option) => fp.isObject(option) ? option : ({ label: _.toString(option), value: _.toString(option) })
    const translateOptions = ({ label, value, ...props }) => ({ label: translation(label), value, ...props })
    const placeholderOption = { label: translation('Please select'), value: '' }
    const shouldShowPlaceholder = (conditions, options, placeholderOption) => {
      const findTrue = _.find(conditions, condition => (condition === true))
      return findTrue ? options : [placeholderOption, ...options]
    }

    const optionsWithPlaceholder = fp.compose(
      fp.thru((options) => {
        return shouldShowPlaceholder([multiple, autocomplete, listType], options, placeholderOption)
      }),
      fp.map(translateOptions),
      fp.map(stringToValueAndLabel)
    )(options)

    return optionsWithPlaceholder
  }, [options, multiple, autocomplete])

  if (validate) {
    let fieldValidators = [...validate]
    if (required) {
      if (field === 'Repeatable') {
        fieldValidators = [...fieldValidators]
      }
      else {
        const requiredValidatorForFieldType = (field === 'Checkbox') ? requiredCheckbox : requiredValidator
        fieldValidators = [requiredValidatorForFieldType, ...fieldValidators]
      }
    }
    commonFieldProps.validate = composeValidators(fieldValidators)
  }

  if (field === 'Heading') {
    return <Component>{value}</Component>
  }

  if (field === 'Repeatable') {
    const repeatableProps = {
      repeatableLabelPlaceholder,
      repeatableLabel,
      repeatableInformation,
      repeatableSeparator,
      repeatableDefaultValues,
      actionLabels,
      maxLength
    }
    const combinedProps = {
      sourceInitialValues: _.get(fieldProps, 'sourceInitialValues'),
      targets: _.get(fieldProps, 'targets'),
      targetLabelKey: _.get(fieldProps, 'targetLabelKey')
    }
    return (
      <FieldArray
        key={key}
        schema={schema}
        component={Repeatable}
        {...commonFieldProps}
        {...repeatableProps}
        {...combinedProps}
      />
    )
  }

  if (field === 'CombinedRepeatable') {
    return (
      <FieldArray
        key={key}
        schema={schema}
        component={CombinedRepeatable}
        change={change}
        getFieldState={getFieldState}
        getState={getState}
        {...commonFieldProps}
        {...fieldProps}
      />
    )
  }

  if (lookupOptions) {
    commonFieldProps['lookupOptions'] = lookupOptions
  }

  if (showInitially) {
    commonFieldProps['showInitially'] = showInitially
  }

  if (restrictInput) {
    commonFieldProps['restrictInput'] = restrictInput
  }

  if (formatResults) {
    commonFieldProps['formatResults'] = formatResults
  }

  if (lookupOptionsRequired) {
    commonFieldProps['lookupOptionsRequired'] = lookupOptionsRequired
  }

  if (displayResults) {
    commonFieldProps['displayResults'] = displayResults
  }

  if (detailsList) {
    commonFieldProps['detailsList'] = detailsList
  }

  if (_.isFunction(handleChangeOtherFields)) {
    commonFieldProps['handleChangeOtherFields'] = handleChangeOtherFields
  }
  if (imperialToggle) {
    return (
      <Field
        key={key}
        component={ImperialToggle}
        options={optionsWithPlaceholder}
        imperialToggle={imperialToggle}
        change={change}
        getFieldState={getFieldState}
        getState={getState}
        {...commonFieldProps}
      />
    )
  }

  if (['Checkbox', 'Checklist'].includes(field) || type === 'checkbox') {
    commonFieldProps.checkboxInterfaceActive = checkboxInterfaceActive
    commonFieldProps.checkboxInterfaceInactive = checkboxInterfaceInactive
  }

  if (field === 'TermsCheckbox') {
    commonFieldProps.privacyPolicyLink = privacyPolicyLink
  }

  if (field === 'Checklist') {
    return (
      <FieldArray
        key={key}
        options={options}
        component={Checklist}
        singleRow={singleRow}
        {...commonFieldProps}
      />
    )
  }

  if (field === 'AutoComplete') {
    commonFieldProps.freeSolo = freeSolo
  }

  return (
    <Field
      key={key}
      component={Component}
      options={optionsWithPlaceholder}
      {...commonFieldProps}
    />
  )
}

ReportFormField.propTypes = {
  /** Form component to use */
  component: PropTypes.func,
  /** The field type defined in the schema */
  field: PropTypes.string.isRequired,
  /** The props object from the schema */
  fieldProps: PropTypes.object.isRequired
}

export default ReportFormField
