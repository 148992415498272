import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import { promiseReducer } from '../../util'

class TranslationsReducers {
  generateTranslationsCSV = (state, action) => {
    return {
      ...state
    }
  }

  createTranslation = (state, action) => {
    if (action.status === SUCCESS) {
      return {
        ...state,
        createStatus: action.status,
        createError: undefined
      }
    }

    return {
      ...state,
      createStatus: action.status,
      createError: action.error ? action.error.message : undefined
    }
  }

  uploadTranslations = (state, action) => {
    if (action.status === SUCCESS) {
      return {
        ...state,
        uploadStatus: action.status,
        uploadError: undefined
      }
    }

    return {
      ...state,
      uploadStatus: action.status,
      uploadError: action.error ? action.error.message : undefined
    }
  }

  fetchTranslations = (state, action) => {
    if (action.status === SUCCESS) {
      return {
        ...state,
        fetchStatus: action.status,
        fetchError: undefined,
        translations: action.result
      }
    }

    return {
      ...state,
      fetchStatus: action.status,
      fetchError: action.error ? action.error.message : undefined
    }
  }

  setTranslations = (state, action) => {
    if (action.status === SUCCESS) {
      return {
        ...state,
        setStatus: action.status,
        setError: undefined
      }
    }

    return {
      ...state,
      setStatus: action.status,
      setError: action.error ? action.error.message : undefined
    }
  }

  searchTranslations = (state, action) => {
    if (action.status === SUCCESS) {
      const { translations: searchedTranslations, totalCount: totalTranslations } = action.result
      return {
        ...state,
        searchedTranslations,
        totalTranslations,
        searchStatus: action.status,
        searchError: undefined
      }
    }

    return {
      ...state,
      searchStatus: action.status,
      searchError: action.error ? action.error.message : undefined
    }
  }

  getTranslationDifferences = (state, action) => {
    if (action.status === SUCCESS) {
      return {
        ...state,
        differences: action.result
      }
    }

    return state
  }

  setOffset = (state, action) => {
    const { offset } = action.payload

    return {
      ...state,
      offset: offset
    }
  }

  setSearchValue = (state, action) => {
    const { searchValue } = action.payload

    return {
      ...state,
      searchValue
    }
  }

  fetchTranslationTags = (state, action) => {
    return {
      ...state,
      tags: action.result
    }
  }

  fetchTranslationAuditTrailForOrganisation = (state, action) => {
    return {
      ...state,
      auditTrail: action.result
    }
  }

  findOrganisationLanguages = (state, action) => {
    return {
      ...state,
      languages: action.result
    }
  }
}

export default new TranslationsReducers()
