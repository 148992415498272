import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import SettingsIcon from '@material-ui/icons/Settings'
import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import Button from '../../../components/Button'

import { translations } from '../../../config'
import style from './style'

// eslint-disable-next-line react/prop-types
const CommunicationsHeader = ({pageTitle, onConfigure, classes}) => {
  const { title, titleWithName } = pageTitle
  return (
    <SubHeader
      leftContent={<BackBar />}
      centerContent={<Fragment>
        <Heading component='h1' uppercase>{title}</Heading>
        <Helmet>
          <title>{titleWithName}</title>
        </Helmet>
      </Fragment>}
      rightContent={(
        <div className={classes.configureWrapper}>
          <Button
            buttonType='primary'
            raised
            onClick={onConfigure}
            aria-label={translations('Communications - Configure Communications')}
          >
            <SettingsIcon />
          </Button>
        </div>
      )}
    />
  )
}

CommunicationsHeader.propTypes = {
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired,
  classes: PropTypes.object.isRequired,
  onConfigure: PropTypes.func.isRequired
}

export default withStyles(style)(CommunicationsHeader)
