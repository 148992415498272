import React, { useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import VisuallyHidden from '../../../../components/VisuallyHidden'
import ReportsSearchInput from '../ReportsSearchInput'
import ReportsSearchTable from '../ReportsSearchTable'
import ReportsSearchFilters from '../ReportsSearchFilters'
import ReportsLoadingBar from '../../ReportConfigurationLoadingBar'

import styles from './style'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { hooks as reportConfigurationHooks, actions as reportConfigurationActions } from '../../../../store/modules/reportConfiguration'

const ReportsSearchScreen = ({ classes, pageTitle }) => {
  const dispatch = useDispatch()
  const { id: formViewId } = useParams()
  const isLoading = reportConfigurationHooks.useReportsPending()
  const filters = reportConfigurationHooks.useFilters(formViewId)
  const defaultFilters = reportConfigurationHooks.useDefaultFilters()
  useEffect(() => {
    if (_.isEmpty(filters)) {
      dispatch(reportConfigurationActions.changeReportFilters({ formViewId, filters: defaultFilters }))
      dispatch(reportConfigurationActions.searchReportsFresh({ formViewId, filters: defaultFilters }))
    }
  }, [])
  return (
    <div className={classes.container}>
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <ReportsLoadingBar isLoading={isLoading} />
      <ReportsSearchInput />
      <div className={classes.scrollContainer}>
        <ReportsSearchFilters formViewId={formViewId} />
        <ReportsSearchTable />
      </div>
    </div>
  )
}

ReportsSearchScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(ReportsSearchScreen)
