import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import FilePicker from '../../../components/Fields/FilePicker'
import ResourceItem from '../../../components/ResourceItem'
import Container from '../../../components/Container'
import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import { translations } from '../../../config'

import style from './style'

const renderHeader = (props) => {
  const { getPageTitle } = props
  const pageTitle = getPageTitle('Pharmaceutical Referrals - Page Title')
  return <SubHeader
    leftContent={<BackBar />}
    centerContent={<Fragment>
      <Heading component='h1' uppercase>{pageTitle.title}</Heading>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
    </Fragment>}
  />
}

const renderUploadBox = (props) => {
  const { classes, canEditFiles, handleFile, isUploadHappening } = props
  if (!canEditFiles || window.cordova) return null
  const label = translations('Pharmaceutical Referrals - File Picker Label')
  return <Container withMarginTop withMarginBottom className={classes.uploadBox}>
    <p><strong>{translations('Pharmaceutical Referrals - File Picker Title')}</strong></p>
    <p>Choose a file to upload and distribute to the MHRA.</p>
    <p>Once you have uploaded your file a representative from the MHRA will be notified to download the document.</p>
    <FilePicker
      label={label}
      onChange={handleFile}
      disabledText={translations('Upload In Progress')}
      disabled={isUploadHappening}
    />
  </Container>
}

const renderFiles = (props) => {
  const { files } = props
  return <Container withMarginTop withMarginBottom>
    {
      files && files.length
      ? files.map(file => {
        const { name, url, viewResource, deleteResource, text, createdAt } = file
        const riProps = { name, resourceKey: url, viewResource, deleteResource, text, createdAt }
        return <ResourceItem {...riProps} />
      })
      : <Heading component='h3' alignCenter>
        {translations('Pharmaceutical Referrals - No Results')}
      </Heading>
    }
  </Container>
}

const ViewPharmaceuticalReferralsScreen = (props) => {
  return <div>
    {renderHeader(props)}
    {renderUploadBox(props)}
    {renderFiles(props)}
  </div>
}

export default withStyles(style)(ViewPharmaceuticalReferralsScreen)
