import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import MenuGrid from '../../../components/MenuGrid'
import MenuWidget from '../../../components/MenuWidget'
import VisuallyHidden from '../../../components/VisuallyHidden'
import style from './style'
import AlertBanner from '../../../components/AlertBanner'
import Announcements from './Announcements'

const HomeScreen = (props) => {
  const {
    widgets,
    onMenuItemClick,
    pageTitle,
    bannerContent,
    displayAnnouncements
  } = props

  return (
    <Fragment>
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      {bannerContent && <AlertBanner content={bannerContent} />}
      {displayAnnouncements && <Announcements />}
      <MenuGrid widgets={widgets} renderWidget={(widget) => (
        <MenuWidget
          onClick={() => onMenuItemClick(widget.id)}
          id={widget.id} {...widget}
        />
      )} />
    </Fragment>
  )
}

HomeScreen.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    action: PropTypes.object,
    title: PropTypes.string
  })),
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(style)(HomeScreen)
