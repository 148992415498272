import formStyle from '../../../components/Form/style'
import logoStyles from '../../../components/MainLogo/style'

const styles = {
  field: {
    textAlign: 'left',
    maxWidth: '300px',
    width: '100%'
  }
}

const workspaceAlternative = {
  workspaceAlternative: {
    padding: '15px'
  }
}

export default theme => ({
  ...formStyle,
  ...styles,
  ...logoStyles,
  ...workspaceAlternative
})
