import React from 'react'

import AnalyticsMenu from '../AnalyticsMenu'

const widgets = [
  { id: 'analytics/user', title: 'User Analytics', image: 'analyticsImg', icon: 'analyticsIcon' },
  { id: 'analytics/report', title: 'Report Analytics', image: 'analyticsImg', icon: 'analyticsIcon' },
]

const AnalyticsDetailScreen = () => {
  return (
    <>
      <AnalyticsMenu widgets={widgets} isDetail={true}/>
    </>
  )
}

export default AnalyticsDetailScreen
