"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FORM_TYPE_CONSTANTS = exports.FORM_SCHEMAS_CONSTANTS = exports.AUDIENCE_CONSTANTS = void 0;
var AUDIENCE_CONSTANTS = {
  INDUSTRY: 1,
  HCP: 2,
  MOP: 3
};
exports.AUDIENCE_CONSTANTS = AUDIENCE_CONSTANTS;
var FORM_SCHEMAS_CONSTANTS = {
  MHRA_R2_REPORT: 'mhraR2Report',
  MHRA_R3_REPORT: 'mhraR3Report',
  MHRA_R3_ACK: 'mhraR3Ack',
  MIR_REPORT: 'devicesReport',
  FSCA_REPORT: 'fscaReport',
  PSR_REPORT: 'psrReport',
  SAE_REPORT: 'saeReport',
  TREND_REPORT: 'trendReport',
  SABRE_SAE_REPORT: 'sabreSAE',
  SABRE_SAR_REPORT: 'sabreSAR'
};
exports.FORM_SCHEMAS_CONSTANTS = FORM_SCHEMAS_CONSTANTS;
var FORM_TYPE_CONSTANTS = {
  REPORT: 'report',
  ACKNOWLEDGEMENT: 'acknowledgement',
  STANDARD: 'standard'
};
exports.FORM_TYPE_CONSTANTS = FORM_TYPE_CONSTANTS;