import { colors } from '../../../config/theme'

const styles = {
  chartWrapper: {
    fontWeight: 'bold'
  },
  total: {
    fontWeight: 'bold',
    color: colors.chartLegendsGrey,
    fontSize: 12,
    position: 'absolute',
    bottom: 12,
    right: 0,
    textTransform: 'uppercase'

  }
}

export default theme => styles
