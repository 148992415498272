import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import withWidth from '@material-ui/core/withWidth'
import { compose, withHandlers } from 'recompose'

import { selectors as newsArticleSelectors } from '../../../store/modules/newsArticles'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

import ViewNewsScreen from './ViewNewsScreen'
import FullScreenLoader from '../../../components/FullScreenLoader'

export default compose(
  withWidth(),
  connect(state => {
    const article = newsArticleSelectors.getActiveArticle(state)
    const articleLoading = newsArticleSelectors.getIsActiveArticleLoading(state)
    const isLoading = !article && articleLoading

    return {
      activeArticleId: newsArticleSelectors.getActiveArticleId(state),
      article,
      isLoading,
      getPageTitle: platformSelectors.getPageTitle(state)
    }
  }),
  withHandlers({
    onEditClick: ({ dispatch, article }) => () => dispatch(push(`/news/${article.id}/edit`))
  }),
  FullScreenLoader
)(ViewNewsScreen)
