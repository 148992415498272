const contactTypes = [
  {
    name: 'general', 
    type: 'GENERAL'
  },
  {
    name: 'inpr', 
    type: 'REPORT_PROMPT'
  }
]

export default contactTypes