import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import InvitedUsersTable from './InvitedUsersTable'
import UsersLoadingBar from '../UsersSearchScreen/UsersLoadingBar'
import ScreenHeading from '../../../components/ScreenHeading'

import styles from './style'
import InvitedUsersFilters from './InvitedUsersFilters'
import InvitedUsersSearch from './InvitedUsersSearch'

const ViewInvitedUsersScreen = ({
  onUserClick,
  onBackClick,
  classes,
  inModal
}) => {
  const scrollContainerClass = (inModal) ? classes.scrollContainerInModal : classes.scrollContainer
  return (
    <div className={classes.container}>
      <UsersLoadingBar />
      <ScreenHeading label='Invited Users Title' hideHeading />
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <InvitedUsersSearch />
        )}
      />
      <div className={scrollContainerClass}>
        <InvitedUsersFilters />
        <InvitedUsersTable onUserClick={onUserClick} />
      </div>
    </div>
  )
}

export default withStyles(styles)(ViewInvitedUsersScreen)
