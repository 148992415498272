import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { DataTableRow, DataTableCell } from '../../../../components/DataTable'
import ButtonLink from '../../../../components/ButtonLink'
import Button from '../../../../components/Button'
import DisplayModal from '../DisplayModal'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import styles from './style'
import _ from 'lodash'
import Chip from '../../../../components/Chip'
import moment from 'moment'
import { translations } from '../../../../config'
import modalService from '../../../../services/modalService'
import ExportSharedWithTable from '../../ExportSharedWithTable'
import DownloadExportButton from '../../DownloadExportButton/DownloadExportButton'

export const statusMap = {
  'SUCCESS': 'success',
  'PROCESSING': 'warning',
  'PENDING': 'primary',
  'FAILURE': 'error',
  'EXPIRED': 'secondary'
}

const ExportManagementRow = (props) => {
  const {
    entry,
    classes
  } = props

  const {
    name,
    author,
    sharedWith,
    status,
    createdAt,
    id,
    organisationId
  } = entry

  const authorName = author.firstName + ' ' + author.lastName
  const createdAtMoment = moment(createdAt).format('DD/MM/YYYY HH:mm')
  
  const dispatch = useDispatch()

  const onDetails = useCallback(() => {
    dispatch(push(`/export-management/${id}`))
  }, [id])

  const showSharedWith = () => {
    modalService.open({
      component: DisplayModal,
      disableBackdropClick: true,
      title: translations('Export Management - Shared With'),
      children: <ExportSharedWithTable data={sharedWith} />
    })
  }

  return (
    <DataTableRow>
      <DataTableCell align={'left'}>
        <ButtonLink
          onClick={onDetails}
          className={classes.rowHeader}
        >
          {name}
        </ButtonLink>
      </DataTableCell>
      <DataTableCell compact align={'center'} nowrap>
        <Chip variant={_.get(statusMap, status)} label={translations(`Export Management Status - ${status}`)} />
      </DataTableCell>
      <DataTableCell compact align={'center'} nowrap>{authorName}</DataTableCell>
      <DataTableCell compact align={'center'} nowrap>
        {_.size(sharedWith) === 1 && <Chip variant={'small'} onClick={showSharedWith} label={_.get(sharedWith, '0.firstName') + ' ' + _.get(sharedWith, '0.lastName')} />}
        {_.size(sharedWith) > 1 && <Chip variant={'small'} onClick={showSharedWith} label={translations(`Export Management - Shared With`, { number: _.size(sharedWith) })} />}
      </DataTableCell>
      <DataTableCell compact align={'center'} nowrap>{createdAtMoment}</DataTableCell>
      <DataTableCell compact align={'center'} nowrap>
        <DownloadExportButton id={id} recordStatus={status}/>
      </DataTableCell>
    </DataTableRow>
  )
}

ExportManagementRow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  status: PropTypes.func.isRequired
}

export default withStyles(styles)(ExportManagementRow)
