export const TOGGLE_SIDE_MENU = 'digitialStore/app/TOGGLE_SIDE_MENU'
export const TOGGLE_BASKET = 'digitalStore/app/TOGGLE_BASKET'
export const OPEN_WEBVIEW = 'digitialStore/app/OPEN_WEBVIEW'
export const APP_READY = 'digitalStore/app/APP_READY'
export const ON_API_FAIL = 'digitalStore/app/ON_API_FAIL'
export const ON_API_DISMISS = 'digitalStore/app/ON_API_DISMISS'
export const CHANGE_CLIENT_BOOK_TAB = 'digitalStore/app/CHANGE_CLIENT_BOOK_TAB'
export const CLIENT_BOOK_CONTACT_DETAILS = 'contact-details'
export const CLIENT_BOOK_ACTIVITIES_LIST = 'activities-list'
export const CHANGE_SNAPSHOT_METRIC_TAB = 'digitalStore/app/CHANGE_SNAPSHOT_METRIC_TAB'
export const SNAPSHOT_THIS_MONTH = 'SNAPSHOT_THIS_MONTH'
export const SNAPSHOT_THIS_YEAR = 'SNAPSHOT_THIS_YEAR'
export const SNAPSHOT_LIFETIME = 'SNAPSHOT_LIFETIME'
export const SNAPSHOT_WISHLIST = 'SNAPSHOT_WISHLIST'
export const SNAPSHOT_PURCHASES = 'SNAPSHOT_PURCHASES'
export const SNAPSHOT_RECOMMENDATIONS = 'SNAPSHOT_RECOMMENDATIONS'
export const CHANGE_SNAPSHOT_CAROUSEL_TAB = 'digitalStore/app/CHANGE_SNAPSHOT_CAROUSEL_TAB'
