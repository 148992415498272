import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import style from './style'

const ButtonsContainer = props => {
  const { children, classes, align, wrap, className, innerClassName, isModal, column } = props
  const containerClass = classNames(
    className,
    classes.container
  )
  const innerClass = classNames(
    classes.inner,
    innerClassName,
    {
      [classes.alignLeft]: align === 'left',
      [classes.alignCenter]: align === 'center',
      [classes.alignRight]: align === 'right',
      [classes.modalColumn]: isModal,
      [classes.column]: column,
      [classes.wrap]: wrap
    }
  )
  // container inner layout is needed to make width 100% and
  // negative margins work nicely
  return <div className={containerClass}>
    <div className={innerClass}>
      {children}
    </div>
  </div>
}

export default withStyles(style)(ButtonsContainer)
