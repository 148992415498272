import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'
import ReportsSearchFilters from './newsSearchFilters'
import { actions as newsActions, selectors as newsSelectors } from '../../../../store/modules/news'
import { actions as newsFeedActions, selectors as newsFeedSelectors } from '../../../../store/modules/newsFeeds'
import { selectors as tagsSelectors } from '../../../../store/modules/tags'

const mapStateToProps = state => {
  const filters = newsSelectors.getFilters(state)
  const tagsOptions = tagsSelectors.getNewsFeedContentTagsAsDropdownOptions(state)
  const sourceOptions = newsFeedSelectors.getNewsFeedSourcesAsDropdownOptions(state)
  return {
    filters,
    tagsOptions,
    sourceOptions
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    updateFilters: ({ dispatch, filters, newsType }) => filter => {
      const newFilters = {
        ...filters,
        [filter.name]: filter.value
      }
      dispatch(newsActions.changeFilters({ filters: newFilters, newsType }))
      dispatch(newsActions.fetchNews({ filters: newFilters, newsType }))
    }
  }),
  lifecycle({
    componentDidMount () {
      this.props.dispatch(newsFeedActions.fetchDistinctNewsFeedSources())
    }
  })
)(ReportsSearchFilters)
