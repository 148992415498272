import _ from 'lodash'
import { promiseReducer } from '../../util'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'

class FormSchemaReducers {
  fetchAllFormSchemas = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllFormSchemasPending(state, action)
      case SUCCESS:
        return this.fetchAllFormSchemasSuccess(state, action)
      case FAIL:
        return this.fetchAllFormSchemasFail(state, action)
      default:
        return state
    }
  }
  fetchAllFormSchemasPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  _getFormSchemaFields = (formSchemas) => {
    const schemas = []
    const formSchemaLibrary = new FormSchemaLibrary()
    _.forEach(_.castArray(formSchemas), (formSchema) => {
      const formSchemaName = _.get(formSchema, 'name', {})
      if (_.isString(formSchemaName)) {
        schemas.push({
          ...formSchema,
          fields: formSchemaLibrary.getAllFields(formSchemaName),
          defaultView: formSchemaLibrary.getDefaultView(formSchemaName)
        })
      } else {
        schemas.push(formSchema)
      }
    })

    return schemas
  }
  fetchAllFormSchemasSuccess = (state, action) => {
    /**
     * Using this map only increases time by a couple of milliseconds but allows access to the form schemas fields
     *
     * Returning without map: 0.02099609375 ms
     * Returning with map: 16.508056640625 ms
     */
    return {
      ...state,
      results: this._getFormSchemaFields(action.result),
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchAllFormSchemasFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }
  updateFormSchema = promiseReducer(
    (state, action) => {
      return {
        ...state,
        results: action.result
      }
    }
  )
  togglePermission = (state, action) => {
    const { values } = action
    return {
      ...state,
      permissions: { ...state.permissions, ...values }
    }
  }
}
export default new FormSchemaReducers()
