import { utilities } from '@redant/mhra-form-schema-library'
import _ from 'lodash'
import {
  LOAD_FORM_VIEW,
  ADD_SECTION,
  REMOVE_SECTION,
  UPDATE_SECTION,
  ADD_FIELD,
  REMOVE_FIELD,
  UPDATE_FIELD,
  TOGGLE_VISIBILITY,
  REMOVE_RESOURCE,
  MOVE_RESOURCE,
  CHANGE_SECTION,
  SET_UNEDITED,
  SET_EDITED,
  CHANGE_BLOCK,
  REMOVE_FROM_BLOCK,
  ADD_TO_BLOCK,
  OPEN_BLOCK,
  CLOSE_BLOCK,
  SET_FORM_SCHEMA_UPDATED,
  SET_FORM_SCHEMA_UPDATE_SAVED
} from './constants'

const _toSortedArrays = ({ sections, fields } = {}) => {
  const sectionArray = []
  const fieldsArray = []
  _.forOwn(sections, (section, ownSectionId) => {
    if (ownSectionId !== '0') {
      sectionArray.push({ id: ownSectionId, ...section })
    }
  })
  _.forOwn(fields, (field, ownFieldId) => {
    if (ownFieldId !== '0') {
      fieldsArray.push({ id: ownFieldId, ...field })
    }
  })
  return { sections: _.sortBy(sectionArray, 'index'), fields: _.sortBy(fieldsArray, 'index') }
}

const _toSortedObject = (state) => {
  const { sections, fields } = _toSortedArrays(state)
  const sectionsObject = {}
  const fieldsObject = {}
  _.forEach(sections, (section) => {
    const id = _.get(section, 'id')
    _.set(sectionsObject, id, section)
  })
  _.forEach(fields, (field) => {
    const id = _.get(field, 'id')
    _.set(fieldsObject, id, field)
  })
  return { sections: sectionsObject, fields: fieldsObject }
}

const _generateId = ({ value, items, prefix }) => {
  let id = _.join(_.compact([prefix, _.camelCase(value)]), '-')

  if (_.get(items, id)) {
    const suffix = '--'
    const existingItems = _.filter(_.keys(items), (key) => _.startsWith(key, `${id}${suffix}`))
    const existingCounts = _.map(existingItems, (item) => _.toNumber(_.last(_.split(item, suffix))))
    const nextCount = _.add(_.max(existingCounts), 1)
    id = `${id}${suffix}${nextCount}`
  }

  return id
}

const _getResourcePath = (type) => {
  switch (type) {
    case 'SECTION':
      return 'sections'
    case 'FIELD':
    case 'REPEATABLE':
    case 'BLOCK':
      return 'fields'
    default:
      return false
    // Unhandled type, ignore
  }
}

export const addSection = (state, payload) => {
  const section = _.clone(payload)
  const currentSections = _.get(state, 'sections')

  if (!section.name) {
    state.error = 'Missing Section Name'
  }

  const newSectionId = _generateId({ value: section.name, items: currentSections })

  if (!section.index) {
    _.set(section, 'index', _.keys(currentSections).length)
  }
  _.set(state, `sections`, { ...currentSections, [newSectionId]: section })

  return { ...state, ..._toSortedObject(state) }
}

export const removeSection = (state, payload) => {
  const section = _.clone(payload)
  const currentSections = _.get(state, 'sections')
  const hasUncategorisedFields = _.get(currentSections, 'uncategorised')
  const { id: oldSectionId } = section
  const oldSection = _.get(state, `sections.${oldSectionId}`)
  const oldSectionFields = _.filter(_.get(state, 'fields'), { sectionId: oldSectionId })
  const uncategorisedFields = _.filter(_.get(state, 'fields'), { sectionId: 'uncategorised' })
  const sectionSchemaFields = _.filter(oldSectionFields, ({ isCustom }) => !isCustom)
  let nextMaxUncategorisedIndex = _.size(uncategorisedFields)
  let nextState = _.cloneDeep(state)

  _.forEach(sectionSchemaFields, (field) => {
    nextState = updateField(nextState, { ...field, sectionId: 'uncategorised', index: nextMaxUncategorisedIndex })
    nextMaxUncategorisedIndex += 1
  })

  if (!_.isUndefined(oldSection)) {
    const newSections = _.omit(currentSections, oldSectionId)
    nextState = { ...nextState, sections: newSections, isEdited: true }
    if (_.isUndefined(hasUncategorisedFields)) {
      nextState = addSection(nextState, { name: 'Uncategorised', isCustom: true })
    }
    return nextState
  }
  return { ...nextState, error: 'SectionId not found' }
}

export const _flattenBlocksForIds = ({ currentFields }) => {
  const currentIds = _.keys(currentFields)
  const nextFields = {}
  _.forEach(currentIds, (id) => {
    const field = _.get(currentFields, id)
    nextFields[id] = field
    const internalFields = _.get(field, 'fields', [])
    if (_.size(internalFields) > 0) {
      _.forEach(_.keys(internalFields), (internalId) => {
        nextFields[internalId] = internalFields[internalId]
      })
    }
  })
  return nextFields
}

export const updateSection = (state, payload) => {
  const section = _.clone(payload)
  const currentSections = _.get(state, 'sections')
  const { id: toUpdateSectionId } = section
  if (!toUpdateSectionId) {
    return { ...state, error: 'Missing sectionId' }
  }
  const currentSectionValue = _.get(currentSections, toUpdateSectionId)
  if (!currentSectionValue) {
    return { ...state, error: 'Section does not exist' }
  }
  const cleanedSection = { ...currentSectionValue, ...section }
  return {
    ...state,
    ..._toSortedObject({
      ...state,
      sections: {
        ...currentSections,
        [toUpdateSectionId]: cleanedSection
      }
    }),
    isEdited: true
  }
}

export const addField = (state, payload) => {
  const field = _.clone(payload)
  const currentFields = _.get(state, 'fields')
  const newFieldId = _generateId({
    value: field.name,
    items: currentFields,
    ...((_.get(field, 'isHeading') === true) && {
      prefix: 'heading'
    }),
    ...((_.get(field, 'isBlock') === true) && {
      prefix: 'block'
    })
  })

  if (_.get(field, 'fields')) {
    const flattenedFields = _flattenBlocksForIds({ currentFields })
    const internalFields = _.clone(_.get(field, 'fields'))

    const nextInternalFields = {}
    _.forEach(internalFields, (internalField, internalFieldId) => {
      const nextId = _generateId({
        value: internalFieldId,
        items: flattenedFields,
        ...((_.get(internalField, 'isHeading') === true) && {
          prefix: 'heading'
        })
      })
      nextInternalFields[nextId] = {
        ...internalField,
        id: nextId
      }
    })
    _.set(field, 'fields', nextInternalFields)
  }

  if (!_.isNumber(field.index)) {
    _.set(field, 'index', _.keys(currentFields).length)
  }

  return {
    ...state,
    ..._toSortedObject({
      ...state,
      fields: {
        ...currentFields,
        [newFieldId]: { ...field, id: newFieldId }
      }
    }),
    isEdited: true
  }
}

const __spillBlock = ({ sectionFields, sectionId, blockFields }) => {
  const clonedBlockFields = _.cloneDeep(blockFields)
  const startIndex = _.size(sectionFields)
  let offset = 0
  const blockKeys = _.keys(clonedBlockFields)
  _.forEach(blockKeys, (k) => {
    _.set(clonedBlockFields, k,
      {
        ...clonedBlockFields[k],
        index: startIndex + offset,
        sectionId,
        blockId: undefined
      }
    )
    offset += 1
  })
  return clonedBlockFields
}

export const removeBlock = (state, payload) => {
  const id = _.get(payload, 'id')
  const currentFields = _.get(state, 'fields')
  const block = _.find(currentFields, { id })
  const sectionId = _.get(block, 'sectionId')
  const blockFields = _.get(block, 'fields', [])
  const { [id]: removedBlock, ...remainingFields } = currentFields
  const sectionFields = _.filter(remainingFields, { sectionId })
  const nextFields = __spillBlock({ sectionFields, sectionId, blockFields })
  return {
    ...state,
    ..._toSortedObject({
      ...state,
      fields: {
        ...remainingFields,
        ...nextFields
      }
    }),
    isEdited: true
  }
}

export const removeField = (state, payload) => {
  const field = _.clone(payload)
  const currentFields = _.get(state, 'fields')
  const { id: fieldId, fields: nestedFields = {} } = field
  const { id: nestedFieldId } = nestedFields
  const currentField = _.get(currentFields, fieldId)

  if (currentField) {
    if (_.get(currentField, `fields.${nestedFieldId}`)) {
      const { fields: nextFields } = removeField({ fields: currentField.fields }, { id: nestedFieldId })
      return {
        ...state,
        ..._toSortedObject({
          ...state,
          fields: {
            ...currentFields,
            [fieldId]: {
              ...currentField,
              fields: nextFields
            }
          }
        }),
        isEdited: true
      }
    } else {
      const nextFields = _.omit(currentFields, fieldId)
      return { ...state, fields: nextFields, isEdited: true }
    }
  }
  return { ...state, error: 'FieldId not found' }
}

export const updateField = (state, payload) => {
  const field = _.clone(payload)
  const currentFields = _.get(state, 'fields')

  const { id: toUpdateFieldId } = field
  if (!toUpdateFieldId) {
    return { ...state, error: 'Missing fieldId' }
  }
  const currentFieldValue = _.get(currentFields, toUpdateFieldId)
  if (!currentFieldValue) {
    return { ...state, error: 'Field does not exist' }
  }

  let nextField = { ...currentFieldValue }
  const nestedId = _.get(field, 'fields.id', false)
  const nestedField = _.get(field, `fields`, {})

  /** A payload within `fields` indicates the field payload is for a repeatable */
  if (nestedId && _.get(currentFieldValue, `fields.${nestedId}`, false)) {
    const nextFields = _.clone(_.get(currentFieldValue, 'fields', []))
    _.set(nextFields, nestedId, nestedField)
    _.set(nextField, 'fields', nextFields)
  } else if (!_.isEmpty(field.fields)) {
    const currentFields = _.get(currentFieldValue, 'fields', {})
    const { fields: nextFields } = addField({ fields: currentFields }, { fieldId: field.id, ...field.fields })
    nextField = { ...nextField, ...field }
    _.set(nextField, 'fields', nextFields)
  } else {
    nextField = { ...nextField, ...field }
  }

  return {
    ...state,
    ..._toSortedObject({
      ...state,
      fields: {
        ...currentFields,
        [toUpdateFieldId]: nextField
      }
    }),
    isEdited: true
  }
}

const _formatPayload = ({ state, id, nestedId, resourcePath, resourceType, onEdit }) => {
  const currentResource = _.get(state, `${resourcePath}.${id}`, {})
  let payload = {}

  if (nestedId && resourceType === 'FIELD') {
    const nestedFields = _.get(currentResource, 'fields', [])
    const nestedResource = _.find(nestedFields, { id: nestedId })

    payload = {
      ...currentResource,
      [resourcePath]: {
        ...nestedResource,
        ...onEdit(nestedResource)
      }
    }
  } else {
    payload = {
      ..._.omit(currentResource, ['fields']),
      ...onEdit(currentResource)
    }
  }

  return payload
}

export const toggleVisibility = (state, payload = {}) => {
  const { resourceType, id, nestedId } = payload
  const resourcePath = _getResourcePath(resourceType)
  const onEdit = (payload) => ({
    visible: Boolean(!_.get(payload, 'visible'))
  })
  const nextPayload = _formatPayload({ state, id, nestedId, resourcePath, resourceType, onEdit })
  switch (resourceType) {
    case 'SECTION':
      return updateSection(state, nextPayload)
    case 'FIELD':
    case 'BLOCK':
    case 'REPEATABLE':
      return updateField(state, nextPayload)
    default:
      return state
  }
}

export const removeResource = (state, payload = {}) => {
  const { resourceType, id, nestedId } = payload

  const nextPayload = { id }

  switch (resourceType) {
    case 'SECTION':
      return removeSection(state, nextPayload)
    case 'FIELD':
      if (nestedId) {
        nextPayload.fields = {
          id: nestedId
        }
      }
      return removeField(state, nextPayload)
    case 'BLOCK':
      return removeBlock(state, nextPayload)
    default:
      return state
  }
}

/**
 * Update index property on an object when changing position
 * @param {object} parameters
 * @param {string} parameters.path - Property to assign the returned object to
 * @param {object} parameters.resources - Data to update the indexes on
 * @param {number} parameters.directionModifier - Direction of index change i.e. 1 for downwards or -1 for upwards
 * @param {number} parameters.places - Number of places the resource has moved Drag and drop will allow multiple places. Defaults to 1.
 * @param {string} parameters.id - The resource ID that is changing
 * @returns {object} Modified with updated index values
 */
const _reorderResources = ({ id, path, resources, directionModifier, places = 1 }) => {
  const { index: currentIndex } = _.get(resources, id)
  const count = _.size(resources)
  const newIndex = _.add(currentIndex, (directionModifier * places))
  const [minIndex, maxIndex] = _.sortBy([currentIndex, newIndex])

  const nextIndex = (resourceIndex, key) => {
    let position = resourceIndex
    if (key === id) {
      position = newIndex
    } else if (resourceIndex >= minIndex && resourceIndex <= maxIndex) {
      position = _.add(resourceIndex, -(directionModifier))
    }
    return _.clamp(position, 0, count)
  }

  const orderedResources = _.reduce(resources, (memo, resource, key) => {
    const { index: resourceIndex } = resource
    memo[key] = { ...resource, index: nextIndex(resourceIndex, key) }
    return memo
  }, {})
  return { [path]: orderedResources }
}

export const moveResource = (state, payload = {}) => {
  const nextState = { ...state, isEdited: true }
  const { direction, id, nestedId, places = 1, resourceType } = payload
  const directionModifier = _.get({ UP: -1, DOWN: 1 }, direction, 0)
  const resourcePath = _getResourcePath(resourceType)
  const updatePath = _.isString(nestedId) ? `${resourcePath}.${id}.${resourcePath}` : resourcePath
  const { [resourcePath]: orderedResources } = _reorderResources({
    id: nestedId || id,
    path: resourcePath,
    resources: _.get(nextState, updatePath, {}),
    directionModifier,
    places
  })

  _.set(nextState, updatePath, orderedResources)
  return nextState
}

export const changeSection = (state, payload = {}) => {
  const nextState = { ...state, isEdited: true }
  const { fieldId, sectionId } = payload
  const nextIndex = _.size(_.filter(nextState.fields, { sectionId }))

  return {
    ...nextState,
    fields: {
      ...nextState.fields,
      [fieldId]: {
        ...nextState.fields[fieldId],
        sectionId,
        index: nextIndex
      }
    }
  }
}

export const changeNestedToAnother = (state, payload = {}) => {
  const { source, destination, id } = payload
  if (!_.isUndefined(source) && !_.isUndefined(destination) && !_.isUndefined(id)) {
    const nextState = { ...state, isEdited: true }
    const sourcePath = `fields.${source}.fields`
    const destinationPath = `fields.${destination}.fields.${id}`
    const sourceObject = _.get(state, sourcePath, {})
    const nestedObject = _.cloneDeep(_.get(state, `${sourcePath}.${id}`))
    const omittedSource = _.omit(sourceObject, [id])
    _.set(sourceObject, 'index', _.keys(_.get(nextState, destinationPath)).length)
    _.set(nextState, destinationPath, nestedObject)
    _.set(nextState, sourcePath, omittedSource)
    return nextState
  }
  return state
}
const __getSectionsNextIndex = ({ fields, sectionId }) => {
  _.size(_.map(fields).filter(({ sectionId: fieldSectionId }) => fieldSectionId === sectionId))
}
export const removeFromNested = (state, payload = {}) => {
  const { source, sectionId, id } = payload
  if (!_.isUndefined(source) && !_.isUndefined(sectionId) && !_.isUndefined(id)) {
    const nextState = { ...state, isEdited: true }
    const sourcePath = `fields.${source}.fields`
    const sourceObject = _.get(state, sourcePath, {})
    const destinationPath = `fields.${id}`
    const nestedObject = _.cloneDeep(_.get(state, `${sourcePath}.${id}`))
    const nextIndex = __getSectionsNextIndex({ fields: _.get(state, 'fields'), sectionId })
    const newObject = { ...nestedObject, sectionId, index: nextIndex }
    const omittedSource = _.omit(sourceObject, [id])
    _.set(nextState, destinationPath, newObject)
    _.set(nextState, sourcePath, omittedSource)
    return nextState
  }
  return state
}
const __recalculateIndexes = ({ sectionId, fields }) => {
  const nextFields = _.cloneDeep(fields)
  const sectionFields = _.chain(fields)
    .map(fields)
    .filter([sectionId])
    .orderBy(['index'])
    .value()
  const reorderedFields = sectionFields.map((value, index) => {
    const { id } = value
    nextFields[id] = { ...value, index }
  })
  return nextFields
}
export const addToNested = (state, payload = {}) => {
  const { destination, id } = payload
  if (!_.isUndefined(destination) && !_.isUndefined(id)) {
    const nextState = { ...state, isEdited: true }
    const fieldBase = _.get(nextState, `fields`)
    const sourcePath = `fields.${id}`
    const destinationPath = `fields.${destination}.fields.${id}`
    const sourceObject = _.get(nextState, sourcePath)
    const omittedFields = _.omit(fieldBase, [id])
    const sectionId = _.get(sourceObject, 'sectionId')
    const reorganisedFields = __recalculateIndexes({ fields: omittedFields, sectionId })
    _.set(sourceObject, 'index', _.keys(_.get(nextState, destinationPath)).length)
    _.set(nextState, `fields`, reorganisedFields)
    _.set(nextState, destinationPath, sourceObject)
    return nextState
  }
  return state
}

export const loadFormView = (state, viewJson) => {
  const nextState = _.isEmpty(viewJson) ? {} : utilities.flattenViewJSON({ viewJson })
  return {
    ...state,
    ...nextState
  }
}

export const setUnedited = (state) => {
  return {
    ...state,
    isEdited: false
  }
}

export const setEdited = (state) => {
  return {
    ...state,
    isEdited: true
  }
}

export const openBlock = (state, payload) => {
  const { blocks, sectionId } = payload
  const currentSection = _.get(state, `sections.${sectionId}`)
  const blockArray = _.castArray(blocks)
  return {
    ...state,
    sections: {
      ..._.get(state, 'sections'),
      [sectionId]: {
        ...currentSection,
        openBlocks: [
          ..._.get(currentSection, `openBlocks`, []),
          ...blockArray
        ]
      }
    }
  }
}

export const closeBlock = (state, payload) => {
  const { blocks, sectionId } = payload
  const blockArray = _.castArray(blocks)
  const currentOpenBlocks = _.get(state, `sections.${sectionId}.openBlocks`)
  const nextOpenBlocks = _.filter(currentOpenBlocks, (block) => !_.includes(blockArray, block))
  return {
    ...state,
    sections: {
      ..._.get(state, 'sections'),
      [sectionId]: {
        ..._.get(state, `sections.${sectionId}`),
        openBlocks: nextOpenBlocks
      }
    }
  }
}

export const setFormViewSchemaUpdated = (state) => {
  return {
    ...state,
    formSchemaUpdated: true
  }
}

export const setFormViewSchemaUpdateSaved = (state) => {
  return {
    ...state,
    formSchemaUpdated: false
  }
}

export default (state, action) => {
  state.error = undefined
  const { type, payload } = action
  switch (type) {
    case LOAD_FORM_VIEW:
      return loadFormView(state, payload)
    case ADD_SECTION:
      return addSection(state, payload)
    case REMOVE_SECTION:
      return removeSection(state, payload)
    case UPDATE_SECTION:
      return updateSection(state, payload)
    case ADD_FIELD:
      return addField(state, payload)
    case REMOVE_FIELD:
      return removeField(state, payload)
    case UPDATE_FIELD:
      return updateField(state, payload)
    case TOGGLE_VISIBILITY:
      return toggleVisibility(state, payload)
    case REMOVE_RESOURCE:
      return removeResource(state, payload)
    case MOVE_RESOURCE:
      return moveResource(state, payload)
    case CHANGE_SECTION:
      return changeSection(state, payload)
    case CHANGE_BLOCK:
      return changeNestedToAnother(state, payload)
    case REMOVE_FROM_BLOCK:
      return removeFromNested(state, payload)
    case ADD_TO_BLOCK:
      return addToNested(state, payload)
    case SET_UNEDITED:
      return setUnedited(state, payload)
    case SET_EDITED:
      return setEdited(state, payload)
    case OPEN_BLOCK:
      return openBlock(state, payload)
    case CLOSE_BLOCK:
      return closeBlock(state, payload)
    case SET_FORM_SCHEMA_UPDATED:
      return setFormViewSchemaUpdated(state, payload)
    case SET_FORM_SCHEMA_UPDATE_SAVED:
      return setFormViewSchemaUpdateSaved(state, payload)
    default: return state
  }
}
