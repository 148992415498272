import { PUBLISH_STATES } from "../../../store/modules/announcementManagement/constants"

export const handlePublicationStatus = ({ publicationStatus }) => {
    switch (publicationStatus) {
        case PUBLISH_STATES.PUBLISHED:
            return {
                chipVariant: 'success',
                chipLabel: 'Announcement Management - Published'
            }
        case PUBLISH_STATES.UNPUBLISHED:
            return {
                chipVariant: 'default',
                chipLabel: 'Announcement Management - Unpublished'
            }
        default:
            return {
                variant: 'default'
            }
    }
  }
