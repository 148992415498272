"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showIfShouldShowReportField = exports.showIfReporterOther = exports.showIfReactionTypeNtfOther = exports.showIfReactionTypeCnfOther = exports.showIfIsConfirmation = exports.showIfComponentNtfOther = exports.showIfComponentCnfOther = exports.lockIfIsConfirmation = exports.isReporterOther = exports.isReactionTypeNtfOther = exports.isReactionTypeCnfOther = exports.isNotNotification = exports.isHighImputability = exports.isConfirmation = exports.isComponentNtfOther = exports.isComponentCnfOther = exports.dobIsNotDefined = exports.clearIfShouldNotShowReportField = exports.clearIfNotReporterOther = exports.clearIfNotReactionTypeNtfOther = exports.clearIfNotReactionTypeCnfOther = exports.clearIfNotComponentNtfOther = exports.clearIfNotComponentCnfOther = exports.ageIsNotDefined = exports.ageCompletion = exports.REPORTER_STATUSES = exports.REGULATOR_STATUSES = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var computedValue = _interopRequireWildcard(require("../../../formHelpers/computedValue"));

var computedHidden = _interopRequireWildcard(require("../../../formHelpers/computedHidden"));

var _formHelpers = require("../../sabreSAE/1.0.0/formHelpers");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var REPORTER_STATUSES = {
  SUBMIT_NOTIFICATION: 'Submit Notification',
  NO_ACTION: 'No Action',
  SUBMIT_CONFIRMATION: 'Submit Confirmation',
  FOOTNOTE_REQUIRED: 'Footnote required'
};
exports.REPORTER_STATUSES = REPORTER_STATUSES;
var REGULATOR_STATUSES = {
  NOT_RECEIVED: 'Not received',
  REVIEW_PENDING: 'Review pending',
  CONFIRMATION_REQUIRED: 'Confirmation required',
  PENDING_MHRA_REVIEW: 'Pending MHRA review',
  CLOSED: 'Closed',
  EXCLUDED: 'Excluded'
};
exports.REGULATOR_STATUSES = REGULATOR_STATUSES;
var HIGH_IMPUTABILITY = ['2', '3'];
var isReactionTypeNtfOther = computedHidden.fieldIs('reactionTypeNtf', _formHelpers.OTHER);
exports.isReactionTypeNtfOther = isReactionTypeNtfOther;
var showIfReactionTypeNtfOther = computedHidden.visibleIf(isReactionTypeNtfOther);
exports.showIfReactionTypeNtfOther = showIfReactionTypeNtfOther;
var clearIfNotReactionTypeNtfOther = computedValue.clearIfNot(isReactionTypeNtfOther);
exports.clearIfNotReactionTypeNtfOther = clearIfNotReactionTypeNtfOther;
var isReactionTypeCnfOther = computedHidden.fieldIs('reactionTypeCnf', _formHelpers.OTHER);
exports.isReactionTypeCnfOther = isReactionTypeCnfOther;
var showIfReactionTypeCnfOther = computedHidden.visibleIf(isReactionTypeCnfOther);
exports.showIfReactionTypeCnfOther = showIfReactionTypeCnfOther;
var clearIfNotReactionTypeCnfOther = computedValue.clearIfNot(isReactionTypeCnfOther);
exports.clearIfNotReactionTypeCnfOther = clearIfNotReactionTypeCnfOther;
var isComponentNtfOther = computedHidden.selectedInMultipleOption('componentNtf', _formHelpers.OTHER);
exports.isComponentNtfOther = isComponentNtfOther;
var showIfComponentNtfOther = computedHidden.visibleIf(isComponentNtfOther);
exports.showIfComponentNtfOther = showIfComponentNtfOther;
var clearIfNotComponentNtfOther = computedValue.clearIfNot(isComponentNtfOther);
exports.clearIfNotComponentNtfOther = clearIfNotComponentNtfOther;
var isComponentCnfOther = computedHidden.selectedInMultipleOption('componentCnf', _formHelpers.OTHER);
exports.isComponentCnfOther = isComponentCnfOther;
var showIfComponentCnfOther = computedHidden.visibleIf(isComponentCnfOther);
exports.showIfComponentCnfOther = showIfComponentCnfOther;
var clearIfNotComponentCnfOther = computedValue.clearIfNot(isComponentCnfOther);
exports.clearIfNotComponentCnfOther = clearIfNotComponentCnfOther;
var isReporterOther = computedHidden.fieldIs('originalReporter', _formHelpers.OTHER);
exports.isReporterOther = isReporterOther;
var showIfReporterOther = computedHidden.visibleIf(isReporterOther);
exports.showIfReporterOther = showIfReporterOther;
var clearIfNotReporterOther = computedValue.clearIfNot(isReporterOther);
exports.clearIfNotReporterOther = clearIfNotReporterOther;
var isConfirmation = computedHidden.fieldIs('reportStatus', _formHelpers.REPORT_STATUSES.CONFIRMATION);
exports.isConfirmation = isConfirmation;
var lockIfIsConfirmation = computedHidden.fieldIs('reportStatus', _formHelpers.REPORT_STATUSES.CONFIRMATION);
exports.lockIfIsConfirmation = lockIfIsConfirmation;
var showIfIsConfirmation = computedHidden.visibleIf(isConfirmation);
exports.showIfIsConfirmation = showIfIsConfirmation;
var isNotNotification = computedHidden.fieldIsNot('reporterStatus', REPORTER_STATUSES.SUBMIT_NOTIFICATION);
exports.isNotNotification = isNotNotification;
var isHighImputabilityNtf = computedHidden.fieldIs('imputabilityNtf', HIGH_IMPUTABILITY);
var isHighImputabilityCnf = computedHidden.fieldIs('imputabilityCnf', HIGH_IMPUTABILITY);

var isHighImputability = _fp["default"].anyPass([isHighImputabilityNtf, isHighImputabilityCnf]);

exports.isHighImputability = isHighImputability;

var shouldShowReportField = _fp["default"].allPass([isHighImputability, isConfirmation]);

var showIfShouldShowReportField = computedHidden.visibleIf(shouldShowReportField);
exports.showIfShouldShowReportField = showIfShouldShowReportField;
var clearIfShouldNotShowReportField = computedValue.clearIfNot(shouldShowReportField);
exports.clearIfShouldNotShowReportField = clearIfShouldNotShowReportField;
var ageIsNotDefined = computedValue.not(computedHidden.fieldIsDefined('age'));
exports.ageIsNotDefined = ageIsNotDefined;
var dobIsNotDefined = computedValue.not(computedHidden.fieldIsDefined('dob'));
exports.dobIsNotDefined = dobIsNotDefined;
var ageCompletion = {
  fields: ['dob', 'age'],
  error: 'Error - Either age or date of birth must be completed'
};
exports.ageCompletion = ageCompletion;