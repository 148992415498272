const styles = theme => {
  const { headerHeight, subheaderHeight, footerHeight, tabHeight } = theme
  return {
    gridContainer: {
      width: '100%',
      height: `calc(100vh - ${headerHeight + subheaderHeight + footerHeight + tabHeight}px)`,
      overflow: 'auto',
      margin: '0 auto',
      padding: '20px 10px 0 10px',
      boxSizing: 'border-box',

      [theme.breakpoints.up('xs')]: {
        paddingLeft: 20,
        paddingRight: 20
      }
    },
    gridContainerFullHeight: {},
    inModal: {
      padding: 0
    },
    container: {
      position: 'relative'
    },
    scollContainerInModal: {
      height: `100%`,
      overflowY: 'auto'
    },
    scrollContainer: {
      height: `100%`,
      overflowY: 'auto'
    },
    noResults: {
      textAlign: 'center',
      marginTop: '10%'
    },
    card: {
      marginBottom: '1rem'
    }
  }
}

export default styles
