import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormBody } from '../../../components/Form'
import ContentBox from '../../../components/ContentBox'
import ContentBoxHeader from '../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../components/Form/TextContent'
import RightButtonContent from '../../../components/Form/RightButtonContent'
import ToggleIconButton from '../../../components/ToggleIconButton'
import Heading from '../../../components/Heading'
import style from './style'
import { translations } from '../../../config'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import modalService from '../../../services/modalService'

const ContentFormSection = (props) => {
  const { initialOpen, schema, initialValues, id, title, classes, isSystemEmailTemplateBool, removeContentSection } = props

  const [isOpen, setIsOpen] = useState(initialOpen)

  const deleteLanguageTemplate = () => {
    modalService.action({
      title: translations('Communications - Delete Template Language Modal Heading'),
      text: translations('Communications - Delete Template Language Modal Description', { language: translations(title) }),
      actions: [
        {
          text: translations('Cancel')
        },
        {
          text: translations('Delete'),
          onClick: () => {
            removeContentSection({ id })
          },
          primary: true
        }
      ]
    })
  }
 
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const renderFormBody  = () => {
    return <FormBody
      initialValues={initialValues}
      schema={schema}
      fullWidthFields
      editing
      hidden={!isOpen}
    />
  }

  const contentId = `${id}Content`
  const controlId = `${id}Controls`

  return (
    <ContentBox id={id} contentId={contentId} hidden={!isOpen}>
      <ContentBoxHeader xsLeft={2} xsText={8} xsRight={2}>
        <TextContent>
          <Heading id={id}>{translations(title)}</Heading>
        </TextContent>
        <RightButtonContent>
          <div className={classes.rightButtonContent}>
            {isSystemEmailTemplateBool && id !== 'en' && <IconButton
              aria-label={translations('Delete')}
              onClick={deleteLanguageTemplate}
              children={<DeleteIcon />}
            />}
            <ToggleIconButton
              id={controlId}
              targetId={contentId}
              onClick={toggleOpen}
              active={isOpen}
            />
          </div>
        </RightButtonContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        {renderFormBody()}
      </ContentBoxBody>
    </ContentBox>
  )
}

export default withStyles(style)(ContentFormSection)
