export default [
  { label: 'AFGHANISTAN', value: '001' },
  { label: 'ALAND ISLANDS', value: '299' },
  { label: 'ALBANIA', value: '002' },
  { label: 'ALGERIA', value: '003' },
  { label: 'AMERICAN SAMOA', value: '201' },
  { label: 'ANDORRA', value: '004' },
  { label: 'ANGOLA', value: '005' },
  { label: 'ANGUILLA', value: '202' },
  { label: 'ANTARCTICA', value: '203' },
  { label: 'ANTIGUA AND BARBUDA', value: '006' },
  { label: 'ARGENTINA', value: '007' },
  { label: 'ARMENIA', value: '204' },
  { label: 'ARUBA', value: '205' },
  { label: 'AUSTRALIA', value: '008' },
  { label: 'AUSTRIA', value: '009' },
  { label: 'AZERBAIJAN', value: '207' },
  { label: 'BAHAMAS', value: '010' },
  { label: 'BAHRAIN', value: '011' },
  { label: 'BANGLADESH', value: '012' },
  { label: 'BARBADOS', value: '208' },
  { label: 'BELARUS', value: '209' },
  { label: 'BELGIUM', value: '013' },
  { label: 'BELIZE', value: '014' },
  { label: 'BENIN', value: '015' },
  { label: 'BERMUDA', value: '016' },
  { label: 'BHUTAN', value: '210' },
  { label: 'BOLIVIA, PLURINATIONAL STATE OF', value: '017' },
  { label: 'BONAIRE, SINT EUSTATIUS AND SABA', value: '312' },
  { label: 'BOSNIA and HERZEGOVINA', value: '211' },
  { label: 'BOTSWANA', value: '019' },
  { label: 'BOUVET ISLAND', value: '275' },
  { label: 'BRAZIL', value: '020' },
  { label: 'BRITISH INDIAN OCEAN TERRITORY', value: '276' },
  { label: 'BRITISH VIRGIN ISLANDS', value: '212' },
  { label: 'BRUNEI DARUSSALAM', value: '021' },
  { label: 'BULGARIA', value: '022' },
  { label: 'BURKINA FASO', value: '023' },
  { label: 'BURUNDI', value: '025' },
  { label: 'CAMBODIA', value: '026' },
  { label: 'CAMEROON', value: '027' },
  { label: 'CANADA', value: '028' },
  { label: 'CAPE VERDE ISLANDS', value: '029' },
  { label: 'CAYMAN ISLANDS', value: '030' },
  { label: 'CENTRAL AFRICAN REPUBLIC', value: '031' },
  { label: 'CHAD REPUBLIC', value: '032' },
  { label: 'CHILE', value: '034' },
  { label: 'CHINA, PRC', value: '035' },
  { label: 'CHRISTMAS ISLAND', value: '213' },
  { label: 'COCOS (KEELING) ISLANDS', value: '214' },
  { label: 'COLOMBIA', value: '036' },
  { label: 'COMOROS, FED. and ISLAMIC REPUBLIC', value: '037' },
  { label: 'CONGO', value: '038' },
  { label: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE', value: '277' },
  { label: 'COOK ISLANDS', value: '215' },
  { label: 'CORE', value: '309' },
  { label: 'COSTA RICA', value: '039' },
  { label: 'CROATIA', value: '216' },
  { label: 'CUBA', value: '040' },
  { label: 'CURACAO', value: '313' },
  { label: 'CYPRUS', value: '041' },
  { label: 'CZECH REPUBLIC', value: '217' },
  { label: 'DENMARK', value: '044' },
  { label: 'DJIBOUTI', value: '045' },
  { label: 'DOMINICA', value: '046' },
  { label: 'DOMINICAN REPUBLIC', value: '279' },
  { label: 'EAST TIMOR', value: '280' },
  { label: 'ECUADOR', value: '047' },
  { label: 'EGYPT', value: '048' },
  { label: 'EL SALVADOR', value: '050' },
  { label: 'EQUATORIAL GUINEA', value: '051' },
  { label: 'ERITREA', value: '220' },
  { label: 'ESTONIA', value: '221' },
  { label: 'ETHIOPIA', value: '052' },
  { label: 'EUROPE', value: '053' },
  { label: 'FAEROE ISLANDS', value: '223' },
  { label: 'FALKLAND ISLANDS', value: '222' },
  { label: 'FIJI', value: '054' },
  { label: 'FINLAND', value: '055' },
  { label: 'FRANCE', value: '056' },
  { label: 'FRENCH GUIANA', value: '057' },
  { label: 'FRENCH POLYNESIA', value: '058' },
  { label: 'FRENCH SOUTHERN TERRITORIES', value: '282' },
  { label: 'GABON', value: '059' },
  { label: 'GAMBIA', value: '060' },
  { label: 'GEORGIA', value: '225' },
  { label: 'GERMANY', value: '062' },
  { label: 'GHANA', value: '064' },
  { label: 'GIBRALTAR', value: '065' },
  { label: 'GLOBAL', value: '351' },
  { label: 'GREECE', value: '066' },
  { label: 'GREENLAND', value: '226' },
  { label: 'GRENADA', value: '067' },
  { label: 'GUADELOUPE', value: '068' },
  { label: 'GUAM', value: '228' },
  { label: 'GUATEMALA', value: '069' },
  { label: 'GUERNSEY', value: '305' },
  { label: 'GUINEA, PEOPLES REVOLUTIONARY', value: '070' },
  { label: 'GUINEA-BISSAU', value: '071' },
  { label: 'GUYANA', value: '072' },
  { label: 'HAITI', value: '073' },
  { label: 'HEARD ISLAND AND MCDONALD ISLANDS', value: '283' },
  { label: 'HOLY SEE (VATICAN CITY STATE)', value: '284' },
  { label: 'HONDURAS', value: '074' },
  { label: 'HONG KONG', value: '075' },
  { label: 'HUNGARY', value: '076' },
  { label: 'IB', value: '308' },
  { label: 'ICELAND', value: '077' },
  { label: 'INDIA', value: '078' },
  { label: 'INDONESIA', value: '079' },
  { label: 'INTERNATIONAL', value: '000' },
  { label: 'IRAN, ISLAMIC REPUBLIC OF', value: '080' },
  { label: 'IRAQ', value: '081' },
  { label: 'IRELAND', value: '229' },
  { label: 'ISLE OF MAN', value: '306' },
  { label: 'ISRAEL', value: '082' },
  { label: 'ITALY', value: '083' },
  { label: 'IVORY COAST', value: '084' },
  { label: 'JAMAICA', value: '086' },
  { label: 'JAPAN', value: '087' },
  { label: 'JERSEY', value: '307' },
  { label: 'JORDAN', value: '088' },
  { label: 'KAZAKHSTAN', value: '230' },
  { label: 'KENYA', value: '089' },
  { label: 'KIRIBATI', value: '231' },
  { label: 'KOREA', value: '090' },
  { label: 'KOSOVO', value: '404' },
  { label: 'KUWAIT', value: '091' },
  { label: 'KYRGYZSTAN', value: '232' },
  { label: `LAO PEOPLE'S DEMOCRATIC REPUBLIC`, value: '092' },
  { label: 'LATVIA', value: '287' },
  { label: 'LEBANON', value: '093' },
  { label: 'LESOTHO', value: '095' },
  { label: 'LIBERIA', value: '096' },
  { label: 'LIBYAN ARAB JAMAHIRIYA', value: '097' },
  { label: 'LIECHTENSTEIN', value: '233' },
  { label: 'LITHUANIA', value: '234' },
  { label: 'LUXEMBOURG', value: '098' },
  { label: 'MACAO', value: '235' },
  { label: 'MACEDONIA', value: '236' },
  { label: 'MADAGASCAR', value: '099' },
  { label: 'MALAWI', value: '100' },
  { label: 'MALAYSIA', value: '101' },
  { label: 'MALDIVES', value: '237' },
  { label: 'MALI', value: '102' },
  { label: 'MALTA', value: '103' },
  { label: 'MARSHALL ISLANDS', value: '238' },
  { label: 'MARTINIQUE', value: '104' },
  { label: 'MAURITANIA', value: '105' },
  { label: 'MAURITIUS', value: '106' },
  { label: 'MAYOTTE ISLANDS', value: '239' },
  { label: 'MEXICO', value: '107' },
  { label: 'MICRONESIA, FEDERATED STATES OF', value: '240' },
  { label: 'MOLDOVA, REPUBLIC OF', value: '242' },
  { label: 'MONACO', value: '108' },
  { label: 'MONGOLIA', value: '109' },
  { label: 'MONTENEGRO', value: '304' },
  { label: 'MONTSERRAT', value: '243' },
  { label: 'MOROCCO', value: '110' },
  { label: 'MOZAMBIQUE', value: '111' },
  { label: 'MYANMAR', value: '244' },
  { label: 'NAMIBIA', value: '112' },
  { label: 'NAURU', value: '245' },
  { label: 'NEPAL', value: '113' },
  { label: 'NETHERLANDS', value: '114' },
  { label: 'NEW CALEDONIA', value: '116' },
  { label: 'NEW ZEALAND', value: '118' },
  { label: 'NICARAGUA', value: '119' },
  { label: 'NIGER REPUBLIC', value: '120' },
  { label: 'NIGERIA', value: '121' },
  { label: 'NIUE', value: '247' },
  { label: 'NORFOLK ISLAND', value: '248' },
  { label: 'NORTHERN MARIANA ISLANDS', value: '288' },
  { label: 'NORWAY', value: '122' },
  { label: 'Not applicable', value: ' C1' },
  { label: 'OMAN', value: '123' },
  { label: 'PAKISTAN', value: '124' },
  { label: 'PALAU', value: '249' },
  { label: 'PALESTINIAN NATIONAL AUTHORITY', value: '403' },
  { label: 'PALESTINIAN TERRITORY,OCCUPIED', value: '300' },
  { label: 'PANAMA', value: '125' },
  { label: 'PAPUA NEW GUINEA', value: '250' },
  { label: 'PARAGUAY', value: '126' },
  { label: 'PERU', value: '127' },
  { label: 'PHILIPPINES', value: '128' },
  { label: 'PITCAIRN', value: '289' },
  { label: 'POLAND', value: '129' },
  { label: 'PORTUGAL', value: '130' },
  { label: 'PUERTO RICO', value: '131' },
  { label: 'QATAR', value: '132' },
  { label: 'REUNION', value: '134' },
  { label: 'ROMANIA', value: '135' },
  { label: 'RUSSIAN FEDERATION', value: '251' },
  { label: 'RWANDA', value: '136' },
  { label: 'SAMOA', value: '290' },
  { label: 'SAN MARINO', value: '258' },
  { label: 'SAO TOME AND PRINCIPE', value: '137' },
  { label: 'SAUDI ARABIA', value: '259' },
  { label: 'SENEGAL', value: '138' },
  { label: 'SERBIA', value: '303' },
  { label: 'SEYCHELLES', value: '139' },
  { label: 'SIERRA LEONE', value: '140' },
  { label: 'SINGAPORE', value: '141' },
  { label: 'SLOVAK REPUBLIC', value: '260' },
  { label: 'SLOVENIA', value: '261' },
  { label: 'SOLOMON ISLANDS', value: '262' },
  { label: 'SOMALIA', value: '142' },
  { label: 'SOUTH AFRICA', value: '143' },
  { label: 'SOUTH GEORGIA AND SOUTH SANDWICH ISLANDS', value: '291' },
  { label: 'SOUTH SUDAN', value: '314' },
  { label: 'SPAIN', value: '144' },
  { label: 'SPC', value: '802' },
  { label: 'SRI LANKA', value: '145' },
  { label: 'ST. HELENA', value: '252' },
  { label: 'ST. KITTS', value: '253' },
  { label: 'ST. LUCIA', value: '254' },
  { label: 'ST. VINCENT', value: '256' },
  { label: 'ST.BARTHELEMY', value: '310' },
  { label: 'ST.MARTIN', value: '311' },
  { label: 'ST.PIERRE & MIQUELON', value: '146' },
  { label: 'SUDAN', value: '147' },
  { label: 'SURINAM', value: '148' },
  { label: 'SVALBARD AND JAN MAYEN ISLANDS', value: '293' },
  { label: 'SWAZILAND', value: '149' },
  { label: 'SWEDEN', value: '150' },
  { label: 'SWITZERLAND', value: '151' },
  { label: 'SYRIAN ARAB REPUBLIC', value: '152' },
  { label: 'TAIWAN', value: '153' },
  { label: 'TAJIKISTAN', value: '263' },
  { label: 'TANZANIA, UNITED REPUBLIC OF', value: '154' },
  { label: 'THAILAND', value: '155' },
  { label: 'TIMOR-LESTE', value: '302' },
  { label: 'TOGO', value: '157' },
  { label: 'TOKELAU', value: '294' },
  { label: 'TONGA ISLANDS', value: '264' },
  { label: 'TRINIDAD', value: '159' },
  { label: 'TUNISIA', value: '160' },
  { label: 'TURKEY', value: '161' },
  { label: 'TURKMENISTAN', value: '266' },
  { label: 'TURKS AND CAICOS ISLANDS', value: '267' },
  { label: 'TUVALU', value: '268' },
  { label: 'UGANDA', value: '165' },
  { label: 'UKRAINE', value: '269' },
  { label: 'UNITED ARAB EMIRATES', value: '166' },
  { label: 'UNITED KINGDOM', value: '167' },
  { label: 'UNITED STATES MINOR OUTLYING ISLANDS', value: '295' },
  { label: 'URUGUAY', value: '168' },
  { label: 'USA', value: '163' },
  { label: 'UZBEKISTAN', value: '270' },
  { label: 'VANUATU', value: '271' },
  { label: 'VENEZUELA, BOLIVARIAN REPUBLIC OF', value: '178' },
  { label: 'VIETNAM', value: '179' },
  { label: 'VIRGIN ISLANDS, BRITISH', value: '181' },
  { label: 'VIRGIN ISLANDS, U.S.', value: '296' },
  { label: 'WALLIS AND FUTUNA', value: '183' },
  { label: 'WESTERN SAHARA', value: '297' },
  { label: 'WORLD (GENERAL)', value: '184' },
  { label: 'YEMEN', value: '298' },
  { label: 'YUGOSLAVIA', value: '187' },
  { label: 'ZAMBIA', value: '189' },
  { label: 'ZIMBABWE', value: '190' }
]
