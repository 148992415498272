import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormHelperText } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import InputLabel from '../../InputLabel'
import ClearButton from './clearButton'
import { translations } from '../../../config'

import style from './style'

const InputField = ({
  type,
  value,
  label,
  onChange: passedOnChange,
  onBlur,
  passedProps,
  meta: { error, touched } = {},
  classes,
  shrink,
  autoFocus,
  getRef,
  placeholder,
  disabled,
  multiline,
  rows,
  readOnly,
  disableUnderline,
  style,
  onClearButtonPress,
  clearable,
  help,
  noErrorTextLabel = false,
  handleChange,
  endAdornmentText
}) => {

  const onChange = (e) => {
    passedOnChange(e)
    if (handleChange) {
      handleChange(e)
    }
  }

  return ([
    <InputLabel
      htmlFor={label}
      key='label'
      shrink={shrink}
    >
      {label}
    </InputLabel>,
    <Input
      inputRef={getRef}
      id={label}
      error={!!(touched && error)}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      placeholder={placeholder}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      readOnly={readOnly}
      disableUnderline={disableUnderline}
      {...passedProps}
      key='input'
      type={
        type && type !== 'number' && type !== 'price'
          ? type
          : 'text'
      }
      style={style}
      endAdornment={
        <>
          { endAdornmentText && <p className={classes.endAdornmentTextStyle}>{endAdornmentText}</p> }
          <ClearButton clearable={clearable} value={value} onClearButtonPress={onClearButtonPress} />
        </>
      }
    />,
    help && <FormHelperText key='help-text'>{translations(help)}</FormHelperText>,
    noErrorTextLabel ? null : <FormHelperText className={classes.errorStyle} key='helper-text' role='alert'>{touched && error && error}</FormHelperText>
  ])
}

export default withStyles(style)(InputField)
