import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { useQuery } from '../../../../hooks'
import { removeQuery } from '../../../../helpers/query'
import Drawer from '../../../../components/Drawer'
import {
    hooks as RCHooks,
    actions as RCActions
} from '../../../../store/modules/reportConfiguration'

import ManageCohortConditionsContent from './ManageCohortConditionsContent'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'

const ManageCohortConditionsDrawer = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(true)
    const { 'cohort-conditions': cohortDetails } = useQuery()
    const { id: formViewId } = useParams()
    const cohorts = RCHooks.useFormViewCohorts({ formViewId })

    const cohortId = _.get(cohortDetails, 'id', undefined)
    const activeCohort = _.find(cohorts, { id: cohortId })
    const currentConditions = _.get(activeCohort, 'conditions')

    useEffect(() => {
        dispatch(RCActions.searchFollowUpFresh({ formViewId }))
    }, [])

    const onClose = () => {
        setOpen(false)
        removeQuery(['cohort-conditions'])
    }

    const search = _.debounce(() => { dispatch(RCActions.searchCohortsFresh({ formViewId })) }, 300)

    const onSave = ((activeConditions) => {
        const params = { ...activeCohort, conditions: activeConditions }
        dispatch(RCActions.updateCohort({ params, formViewId, cohortId }))
        search()
        onClose()
    })

    useEffect(() => {
        setOpen(!_.isUndefined(cohortDetails))
    }, [cohortDetails])

    return (
        <Drawer heading={'Conditions'} anchor={'right'} open={open} onClose={onClose}>
            <ManageCohortConditionsContent
                conditions={currentConditions}
                onClose={onClose}
                onSave={onSave}
            />
        </Drawer>
    )
}

export default ManageCohortConditionsDrawer
