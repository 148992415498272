import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { REHYDRATE } from 'redux-persist'
import { SUCCESS } from '../../middleware/redux-promise'
import { actions as platformsActions, selectors as platformSelectors } from '../platforms'
import { selectors as platformDetailsSelectors, constants as platformDetailsConstants } from '../platformDetails'
import { constants as authConstants } from '../auth'
import { constants as platformDetailsContants } from '../platformDetails'
import { selectors as themeSelectors } from '../themes'
import { getCurrentPlatformId, isAnnouncementManagementEnabled } from './selectors'

class PlatformsMiddleware {
  fetchAllPlatforms = ({ dispatch, getState }) => next => action => {
    next(action)
    const onLogin = action.type === authConstants.LOGIN && action.status === SUCCESS
    const isRoutingToPlatform = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/platforms'
    if (onLogin || isRoutingToPlatform) {
      dispatch(platformsActions.fetchAllPlatforms())
    }
  }

  fetchPlatformForDomain = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === REHYDRATE || (action.type === authConstants.LOGOUT && action.status === 'SUCCESS')) {
      const domain = themeSelectors.getDomainForTheme()
      dispatch(platformsActions.fetchPlatformForDomain({ domain }))
    }
  }

  fetchPlatformForDomainOnPlatformUpdate = ({ dispatch, getState }) => next => action => {
    next(action)
    if (
      (
        action.type === platformDetailsContants.CREATE_PLATFORM ||
        action.type === platformDetailsContants.UPDATE_PLATFORM
      )
      && action.status === 'SUCCESS'
    ) {
      const domain = themeSelectors.getDomainForTheme()
      dispatch(platformsActions.fetchPlatformForDomain({ domain }))
    }
  }

  fetchPlatformAnnouncements = ({ dispatch, getState }) => next => action => {
    next(action)
    const platformId = getCurrentPlatformId(getState())
    const fetchAnnouncements = isAnnouncementManagementEnabled(getState())
    const isRoutingToHomePage = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/'
    if (isRoutingToHomePage && fetchAnnouncements) {
      dispatch(platformsActions.fetchPlatformAnnouncements({ platformId }))
    }
  }
}

export default new PlatformsMiddleware()
