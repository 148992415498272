import React, { Component, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import style from './style'
import LoginForm from '../LoginForm'
import { translations } from '../../../config'
import P from '../../../components/P'
import MainLogo from '../../../components/MainLogo'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import VisuallyHidden from '../../../components/VisuallyHidden'
import { reportShareUrls } from '../constants'

const renderForgotPasswordButton = (props) => {
  const { classes } = props
  return <div className={classes.linkWrapper}>
    <Link to='/forgot-password'>
      <P value={translations('Login - Forgot Password Button')} />
    </Link>
  </div>
}

const renderRequestCompanyAccountButton = (props) => {
  const { classes, isRequestCompanyAccountAllowed } = props
  if (!isRequestCompanyAccountAllowed) return null
  return <div className={classes.linkWrapper} >
    <Link to='/account/request'>
      <P value={translations('Login - Request Company Account Button')} />
    </Link>
  </div>
}

const renderCreateAccountButton = (props) => {
  const { classes, isPublicUserRegistrationAllowed, showCreateAccountSelectOrganisationModal } = props
  if (!isPublicUserRegistrationAllowed) return null
  return <div className={classes.linkWrapper} >
    <a
      onClick={showCreateAccountSelectOrganisationModal}
      aria-modal
      role='dialog'
    >
      <P value={translations('Login - Create Account')} />
    </a>
  </div>
}

const renderAssessorLoginButton = ({classes, setIsAssessorLogin, isAssessorLogin}) => {
  return <div className={classes.linkWrapper} >
    <a
      onClick={() => setIsAssessorLogin(!isAssessorLogin)}
      aria-modal
      role='dialog'
    >
      <P value={isAssessorLogin ? translations('Back') : translations('Login - Login as Assessor')} />
    </a>
  </div>
}

const renderAdminSignInButton = (props) => {
  const { classes, onForceShowStandardLogin } = props
  return <div className={classes.linkWrapper} style={{ marginTop: 10 }}>
    <a
      onClick={() => onForceShowStandardLogin()}
      aria-modal
      role='dialog'
    >
      <P value={translations('Admin Sign-in')} />
    </a>
  </div >
}

const renderViewAllLoginOptsButton = (props) => {
  const { classes, onForceShowStandardLogin } = props
  return <div className={classes.linkWrapper}>
    <a
      onClick={() => onForceShowStandardLogin()}
      aria-modal
      role='dialog'
    >
      <P value={translations('View all login options')} />
    </a>
  </div >
}

const SSOLogin = ({
  ssoLogins,
  classes,
  openSSO,
  organisations,
  showDigitalStoreLogin,
  onForceShowStandardLogin,
  ...props
}) => {
  return (
    <Card className={classes.formContainer} style={{ textAlign: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', width: 300 }}>
      <div>
        {showDigitalStoreLogin && <span>{translations('SSO Login')}</span>}
        {_.map(ssoLogins, (ssoLoginMethod) => {
          const organisation = _.find(organisations, (org) => {
            return org.loginMethods.includes(ssoLoginMethod)
          })
          return (
            <Button
              fullWidth
              buttonType={'primary'}
              onClick={() => {
                openSSO(ssoLoginMethod)
              }}
              style={{ marginTop: 10 }}
            >
              Login to {organisation.name}
            </Button>
          )
        })}
      </div>
      {!showDigitalStoreLogin && renderAdminSignInButton({ classes, onForceShowStandardLogin })}
    </Card>
  )
}

const StandardLogin = (props) => {
  const { classes, forceShowStandardLogin, from, isFromReportSharePage, setIsAssessorLogin, isAssessorLogin } = props

  return (
    <div className={classes.innerContainer} style={{ width: 300 }}>
      <LoginForm from={from} isAssessorLogin={isAssessorLogin} />
      <div className={classes.linksContainer}>
        {!isAssessorLogin && renderForgotPasswordButton(props)}
        {!isAssessorLogin && renderCreateAccountButton(props)}
        {isFromReportSharePage && renderAssessorLoginButton({classes, setIsAssessorLogin, isAssessorLogin})}
        {renderRequestCompanyAccountButton(props)}
        {forceShowStandardLogin && renderViewAllLoginOptsButton(props)}
      </div>
    </div>
  )
}

const LoginScreen = (props) => {
  const { loginMethods, classes, pageTitle, location } = props
  
  const [forceShowStandardLogin, setForceShowStandardLogin] = useState(false)
  const [isAssessorLogin, setIsAssessorLogin] = useState(false)
  
  const from = _.get(location, 'state.from')
  const isFromReportSharePage = _.some(reportShareUrls, (url) => _.includes(from?.pathname, url))

  let showDigitalStoreLogin = _.find(loginMethods, (lm) => lm === 'DIGITAL_STORE_LOGIN')
  const ssoLogins = _.filter(loginMethods, (lm) => lm !== 'DIGITAL_STORE_LOGIN')
  if (loginMethods.length === 0) {
    showDigitalStoreLogin = true
  }

  return (
    <div className={classes.container}>
        <div className={classes.innerContainer}>
          <MainLogo className={classes.logo} />
          <VisuallyHidden>
            <h1>{pageTitle.title}</h1>
          </VisuallyHidden>
          <Helmet>
            <title>{pageTitle.titleWithName}</title>
          </Helmet>
          <div className={classes.loginWrapper} style={{ display: 'flex' }}>
            {(showDigitalStoreLogin || forceShowStandardLogin) && <StandardLogin
                {...props}
                forceShowStandardLogin={forceShowStandardLogin}
                onForceShowStandardLogin={() => setForceShowStandardLogin(false)}
                from={from}
                isFromReportSharePage={isFromReportSharePage}
                setIsAssessorLogin={setIsAssessorLogin}
                isAssessorLogin={isAssessorLogin}
              />}
            {((ssoLogins && ssoLogins.length > 0) && !forceShowStandardLogin) && <SSOLogin
              {...props}
              ssoLogins={ssoLogins}
              showDigitalStoreLogin={showDigitalStoreLogin}
              onForceShowStandardLogin={() => setForceShowStandardLogin(true)}
            />}
          </div>
        </div>
      </div>
  )
}

export default withStyles(style)(LoginScreen)
