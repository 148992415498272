import _ from 'lodash'
import { promiseReducer } from '../../util'
import Color from 'color'

const _formatThemeResult = (action) => {
  let theme = _.get(action, 'result')
  const headerBgColor = _.get(theme, 'details.headerBackgroundColor')
  const primaryColor = _.get(theme, 'details.primaryColor')

  if (headerBgColor) {
    theme.details.headerContrastColor = Color(headerBgColor).isLight() ? '#000000' : '#FFFFFF'
  }

  if (primaryColor) {
    theme.details.primaryContrastColor = Color(primaryColor).isLight() ? '#000000' : '#FFFFFF'
  }

  return theme
}

class ThemesReducers {
  fetchCurrentTheme = promiseReducer(
    (state, action) => {
      return {
        ...state,
        currentTheme: _formatThemeResult(action)
      }
    }
  )
  fetchAllThemes = promiseReducer(
    (state, action) => {
      return {
        ...state,
        allThemes: action.result
      }
    }
  )
}

export default new ThemesReducers()
