import _ from 'lodash'
import Professions from '../Professions'
import { useSelector } from 'react-redux'
import { useCurrentOrganisationProfessionsOptions } from '../../../../../../store/modules/organisationDetails/hooks'
import { getCurrentOrganisationName } from '../../../../../../store/modules/organisationDetails/selectors'

const CreateUserProfessions = ({ editing }) => {
  const professionOptions = useCurrentOrganisationProfessionsOptions()
  const organisationName = useSelector(getCurrentOrganisationName)

  if (!professionOptions) {
    return <></>
  }

  return (
    <Professions
      professionOptions={professionOptions}
      organisationName={organisationName}
      editing={editing}
    />
  )
}

export default CreateUserProfessions