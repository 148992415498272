import react from 'react'
import TerminologyModal from '../TerminologyModal'
import modalService from '../../../../../services/modalService'


export const useCreateModal = config => (props) => react.useCallback(() => {
    const { schema, formName, title, onSubmit } = config(props)
    modalService.open({
        component: TerminologyModal,
        formName,
        title,
        schema,
        onSubmit,
        ...props,
        closeModal: () => modalService.close()
    })
})
