import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import style from './style'

const FormFieldset = ({ children, classes, title }) => {
  return (
    <fieldset className={classes.root}>
      <legend className={classes.legend}>{title}</legend>
      {children}
    </fieldset>
  )
}

FormFieldset.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  classes: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired
}

export default withStyles(style)(FormFieldset)
