export const SLICE_NAME = 'terminology'

export const ARRANGEMENT_PATH = ['arrangements']

export const MASTER_PAGE_SIZE = 50

export const ACTION_OPTION_TOGGLE_TYPES = {
    actioned: {
        label: 'Actioned',
        value: 'ACTIONED'
    },
    unprocessed: {
        label: 'Unprocessed',
        value: 'NONE'
    },
    ignored: {
        label: 'Ignored',
        value: 'IGNORE'
    },
}

export const ACTION_ENTRY_CONFIGURE_OPTIONS = [
    {
        label: 'Terminology Entry Configure Action Options - Ignore',
        value: 'IGNORE'
    },
    {
        label: 'Terminology Entry Configure Action Options - Apply mapping',
        value: 'APPLY_MAPPING'
    },
    {
        label: 'Terminology Entry Configure Action Options - Remove repeatable',
        value: 'REMOVE_REPEATABLE'
    },
    {
        label: 'Terminology Entry Configure Action Options - None',
        value: 'NONE'
    },
]
