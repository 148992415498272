import { PureComponent } from 'react'
import PropTypes from 'prop-types'

class Can extends PureComponent {
  render () {
    const { allowed, children } = this.props
    return allowed ? children : null
  }
}

Can.defaultProps = {
  allowed: false
}

Can.propTypes = {
  children: PropTypes.any,
  allowed: PropTypes.bool.isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired
}

export default Can
