import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  compose,
  withHandlers,
  withProps
} from 'recompose'

import AddSectionModal from './AddSectionModal'
import modalService from '../../../../../services/modalService'

const enhancer = compose(
  withProps(props => {
    const {sectionNames} = props
    return { sectionNames: _.compact(sectionNames()) }
  }),
  withHandlers({
    handleSubmit: ({ addSection }) => async (formData) => {
      try {
        const { newsectionname } = formData
        await addSection({ sectionName: newsectionname })
      } catch (error) {
        console.log(error)
      }
    },
    openModal: () => component => {
      modalService.open({
        component
      })
    },
    closeModal: () => () => {
      modalService.close()
    }
  })
)

export default enhancer(AddSectionModal)
