import _ from 'lodash'

import {
  ADD_CONDITION,
  UPDATE_CONDITION,
  REMOVE_CONDITION,
  SET_UNEDITED,
  SET_EDITED,
  UPDATE_FIELDS,
  SET_CONDITIONS,
  ATTACH_TO_RESOURCE,
  REMOVE_FROM_RESOURCE,
  REPLACE_RESOURCE_CONDITIONS,
  SET_RESOURCE_CONDITIONS
} from './constants'

const addCondition = (state, action) => {
  const { payload } = action
  return {
    ...state,
    conditions: [...state.conditions, _.omitBy(payload, _.isUndefined)],
    isEdited: true
  }
}

const updateCondition = (state, action) => {
  const { payload } = action
  const { conditions } = state
  const nextConditions = _.clone(conditions)
  const fieldIndex = _.findIndex(nextConditions, { id: payload.id })

  _.set(nextConditions, fieldIndex, payload)

  return {
    ...state,
    conditions: nextConditions,
    isEdited: true
  }
}

const removeCondition = (state, action) => {
  const { payload: { id: conditionId } } = action
  const { conditions } = state
  const nextConditions = _.reject(conditions, { id: conditionId })

  return { ...state, conditions: nextConditions, isEdited: true }
}

const setUnedited = (state) => {
  return { ...state, isEdited: false }
}

const setEdited = (state) => {
  return { ...state, isEdited: true }
}

const updateFields = (state, action) => {
  const { payload } = action
  const { fields } = payload

  const questionFields = _.filter(fields, (field) => {
    const isHeading = _.get(field, 'isHeading', false)
    const isBlock = _.get(field, 'isBlock', false)

    return !isBlock && !isHeading
  })

  return {
    ...state,
    fields: questionFields
  }
}

const setConditions = (state, action) => {
  const { payload } = action
  return { ...state, conditions: payload }
}

const attachToResource = (state, action) => {
  const nextState = _.clone(state)
  const { payload } = action
  const { resourceId, resourceType, conditionId } = payload
  const currentResource = _.get(nextState, `resources.${resourceType}.${resourceId}`, [])
  _.set(nextState, `resources.${resourceType}.${resourceId}`, [...currentResource, conditionId])
  return { ...nextState, isEdited: true }
}

const removeFromResource = (state, action) => {
  const nextState = _.clone(state)
  const { payload } = action
  const { resourceId, resourceType, conditionId } = payload
  const currentResource = _.get(nextState, `resources.${resourceType}.${resourceId}`)
  _.set(nextState, `resources.${resourceType}.${resourceId}`, _.without(currentResource, conditionId))
  return { ...nextState, isEdited: true }
}

const replaceResourceConditions = (state, action) => {
  const nextState = _.clone(state)
  const { payload } = action
  const { resourceId, resourceType, conditionIds = [] } = payload
  _.set(nextState, `resources.${resourceType}.${resourceId}`, conditionIds)
  return { ...nextState, isEdited: true }
}

const setResourceConditions = (state, action) => {
  const nextState = _.clone(state)
  const { payload } = action
  _.set(nextState, 'resources', payload)
  return { ...nextState }
}

export default (state, action) => {
  const { type } = action
  switch (type) {
    case ADD_CONDITION:
      return addCondition(state, action)
    case UPDATE_CONDITION:
      return updateCondition(state, action)
    case REMOVE_CONDITION:
      return removeCondition(state, action)
    case SET_UNEDITED:
      return setUnedited(state)
    case UPDATE_FIELDS:
      return updateFields(state, action)
    case SET_CONDITIONS:
      return setConditions(state, action)
    case ATTACH_TO_RESOURCE:
      return attachToResource(state, action)
    case REMOVE_FROM_RESOURCE:
      return removeFromResource(state, action)
    case REPLACE_RESOURCE_CONDITIONS:
      return replaceResourceConditions(state, action)
    case SET_RESOURCE_CONDITIONS:
      return setResourceConditions(state, action)
    default:
      return state
  }
}
