const AuditTypes = {
  DELETE_LANGUAGE: 'DELETE_LANGUAGE',
  NEW_LANGUAGE: 'NEW_LANGUAGE',
  UPLOAD_TRANSLATIONS: 'UPLOAD_TRANSLATIONS',
  NEW_KEY: 'NEW_KEY',
  UPDATE_TRANSLATIONS: 'UPDATE_TRANSLATIONS',
  DELETE_KEYS: 'DELETE_KEYS'
}

export default AuditTypes
