import uuid from 'uuid/v4'
import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as platformSelectors } from '../platforms'
import { selectors as authSelectors } from '../auth'

class NewsArticleActions {
  setActiveArticle = (id) => ({
    type: constants.SET_ACTIVE_ARTICLE,
    payload: { id }
  })

  getArticle = (id) => ({
    type: constants.GET_ARTICLE,
    payload: { id },
    promise: () => digitalStoreSdk.news.fetchNewsArticleById({ id })
  })

  createArticle = ({ newsFeedId, ...articleData }) => ({
    type: constants.CREATE_ARTICLE,
    // Generate a localId to keep track of the article before its saved
    payload: { localId: uuid() },
    promise: (_, getState) => {
      const platformId = platformSelectors.getCurrentPlatformId(getState())

      return digitalStoreSdk.news.createNews({
        newsFeedId,
        platformId,
        ...articleData
      })
    }
  })

  updateArticle = (id, articleData) => ({
    type: constants.UPDATE_ARTICLE,
    payload: { id },
    promise: () => digitalStoreSdk.news.updateNewsArticleById({ id, ...articleData })
  })

  deleteArticle = (id) => ({
    type: constants.DELETE_ARTICLE,
    payload: { id },
    promise: () => digitalStoreSdk.news.deleteNews({ id })
  })
}

export default new NewsArticleActions()
