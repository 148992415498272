import { LOCATION_CHANGE, goBack } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'
import { translations } from '../../../config'
import modalService from '../../../services/modalService'
import { selectors as authSelectors } from '../auth'
import {
  actions as platformDetailsActions,
  constants as platformDetailsConstants
} from '../platformDetails'
import {
  actions as organisationActions,
} from '../organisations'
import {
  actions as pwaActions
} from '../pwa'
import { fetchPlatformWebsites, fetchPlatformApp, fetchPlatformApps } from '../pwa/asyncActions'

class PlatformDetailsMiddleware {
  fetchInitialPlatformDetails = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const result = authSelectors.getIsLoggedIn(getState()) && matchPath(path, { path: '/platforms/:id', exact: true })
      const platformId = _.get(result, 'params.id')
      if (result && platformId !== 'new') {
        dispatch(platformDetailsActions.fetchPlatform({ id: platformId }))
        dispatch(organisationActions.fetchPaginatedOrganisations({ platformId, limit: 100, offset: 0 }))
        dispatch(fetchPlatformWebsites({ platformId }))
        dispatch(fetchPlatformApps({ platformId }))
      }
    }
  }

  clearPlatformDetailsFormOnNavigateAwayMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/platforms/:id', exact: true })
      if (!result) {
        dispatch(platformDetailsActions.resetStore())
        dispatch(pwaActions.resetStore())
      }
    }
  }

  platformCreateSuccessMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === platformDetailsConstants.CREATE_PLATFORM && action.status === 'SUCCESS') {
      modalService.action({
        title: translations('Success'),
        text: translations('Platform successfully created'),
        actions: [
          {
            primary: true,
            text: translations('Okay')
          }
        ]
      })
      dispatch(goBack())
    }
  }

  platformCreateFailMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === platformDetailsConstants.CREATE_PLATFORM && action.status === 'FAIL') {
      modalService.action({
        title: translations(translations('Create Platform Fail')),
        text: translations(translations(action.error.code)),
        actions: [
          {
            primary: true,
            text: translations('Okay')
          }
        ]
      })
    }
  }
}

export default new PlatformDetailsMiddleware()
