import React, { useState } from 'react'
import Dropdown from '../../../../components/Fields/Dropdown'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'
import RightButtonContent from '../../../../components/Form/RightButtonContent'
import ToggleIconButton from '../../../../components/ToggleIconButton'
import Heading from '../../../../components/Heading'

import { translations } from '../../../../config'
import _ from 'lodash'
import { getProducts } from '../actions'


const SelectProductDropdown = (props) => {
  const {
    classes,
    options,
    selectedProduct,
    selectProduct,
    dispatch
  } = props

  const [isOpen, setIsOpen] = useState(true)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const fetchProducts = (search) => {
    getProducts(dispatch)(search)
  }

  return(
    <div className={classes.formContentWrapper}>
    <ContentBox
      id={'select-product'}
      contentId={'select-product'}
      hidden={!isOpen}
    >
      <ContentBoxHeader xsLeft={2} xsText={8} xsRight={2}>
        <TextContent>
          <Heading id={'select-product'}>
            {translations('Select product')}
          </Heading>
        </TextContent>
        <RightButtonContent>
          <div className={classes.rightButtonContent}>
            <ToggleIconButton
              id={'select-product'}
              targetId={'select-product'}
              onClick={toggleOpen}
              active={isOpen}
            />
          </div>
        </RightButtonContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <div className={classes.bodyContainer}>
          <Dropdown
            style={{ width: '100%' }}
            label={translations('New Report Product Search - Label')}
            fetch={fetchProducts}
            autocomplete
            options={options}
            value={selectedProduct ? selectedProduct.id : null}
            onChange={(e) => {
              selectProduct(e) 
            }}
            givenClasses={{ overridingRootClasses: classes.dropdownRoot }}
          />
        </div>
      </ContentBoxBody>
    </ContentBox>
  </div>
  )
}

export default SelectProductDropdown