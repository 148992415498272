import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import Drawer from '../../../../components/Drawer'
import { useQuery } from '../../../../hooks'

import { removeQuery } from '../../../../helpers/query'
import ManageConditionsContents from './ManageConditionsContents'

const ManageConditionsDrawer = () => {
  const [open, setOpen] = useState(true)
  const { conditions: conditionFilters } = useQuery()

  const onClose = () => {
    setOpen(false)
    removeQuery(['conditions'])
  }

  useEffect(() => {
    setOpen(!_.isUndefined(conditionFilters))
  }, [conditionFilters])

  return (
    <Drawer heading={'Conditions'} anchor={'right'} open={open} onClose={onClose}>
      <ManageConditionsContents onClose={onClose} />
    </Drawer>
  )
}

ManageConditionsDrawer.props = {}

ManageConditionsDrawer.propTypes = {}

export default ManageConditionsDrawer
