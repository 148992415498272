import { isEqual } from 'lodash'
import { useState } from 'react';

export const useDeepPrevious = (value) => {
  const [state, setState] = useState({
    value: value,
    prev: null,
  });
  const current = state.value;
  if (!isEqual(value, current)) {
    setState({
        value: value,
        prev: current,
      });
  }

  return state.prev;
};
