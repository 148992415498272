import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'
import WebsiteManagementScreen from './WebsiteManagementScreen'
import WebsiteSelectorScreen from './WebsiteSelectorScreen'

const routes = [
  <PrivateRoute key='/website-management' path='/website-management' exact component={TimedLogoutComponent(WebsiteSelectorScreen)} />,
  <PrivateRoute key='/website-management/:id' path='/website-management/:id' exact component={TimedLogoutComponent(WebsiteManagementScreen)} />
]

export default routes
