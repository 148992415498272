import React from 'react'
import LookupInput from '../LookupInput'
import EDQMLookupInputModal from '../../EDQMLookupInputModal'

const EDQMLookupInput = ({ restrictFreeformEntry, ...props }) => (
  <LookupInput
    component={EDQMLookupInputModal}
    lookUpOnly={restrictFreeformEntry}
    clearable={restrictFreeformEntry}
    {...props}
  />
)

export default EDQMLookupInput
