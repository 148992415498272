import React, { useEffect } from 'react'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { translations } from '../../../../config'
import { useDispatch, useSelector } from 'react-redux'
import { asyncActions as organisationInviteAsyncActions } from '../../../../store/modules/organisationInvites'
import { getCurrentUser, getUserSelectedOrganisationId } from '../../../../store/modules/auth/selectors'
import { getHasOrganisationInvitesEnabled } from '../../../../store/modules/platforms/selectors'

import MoreMenu from '../../../../components/MoreMenu/MoreMenu'
import OrganisationInviteModal from './OrganisationInviteModal'
import modalService from '../../../../services/modalService'
import toastService from '../../../../services/toastService'
import { submit } from 'redux-form'

const UsersMoreMenu = (props) => {

  const dispatch = useDispatch()
  const author = _.get(useSelector(getCurrentUser), 'id')
  const currentOrganisationId = useSelector(getUserSelectedOrganisationId)
  const hasUserInvitesEnabled = useSelector(getHasOrganisationInvitesEnabled)

  const openOrganisationInviteModal = () => {
    return modalService.open({
      component: OrganisationInviteModal,
      onSubmit: onSubmitOrganisationInvite
    })
  }

  const onSubmitOrganisationInvite = async (data) => {
    const fields = {
      email: data.email,
      userGroupId: _.first(data.accountTypes),
      organisationId: data.ukrpAssociation || data.mfrAssociation || data.authRepAssociation || currentOrganisationId,
      author
    }
    const result = await dispatch(organisationInviteAsyncActions.createOrganisationInvite(fields)).unwrap()
    if (result.status === 'FULFILLED') {
      toastService.action({
        type: 'success',
        message: translations('Organisation Invite - Successfully sent email', { email: data.email }),
        autoHideDuration: 6000
      })
    }
    modalService.close()
  }

  let options = [
    { label: translations("User Management More Menu - View Account Deletion Requests"), onClick: () => { dispatch(push('/users/deletion-requested')) } }
  ]

  if (hasUserInvitesEnabled) {
    options.push({ label: translations('User Management More Menu - Invite user by email'), onClick: openOrganisationInviteModal })
    options.push({ label: translations("Invited User"), onClick: () => { dispatch(push('users/invited')) } })
  }

  return (
    <MoreMenu options={options} />
  )
}
export default UsersMoreMenu