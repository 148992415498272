import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useState } from 'react'
import { ComboboxOption } from '@reach/combobox'

import { useSdk } from '../../services/sdk/hooks'
import { translation } from '../../services/translations'
import { useNetworkStatus } from '../../hooks/useNetworkStatus'

import styled from 'styled-components'
import FindMyLocation from './FindMyLocation'
import AutoComplete from '../AutoComplete/AutoComplete'
import LocationError from './LocationError'

export const RegionOption = styled(ComboboxOption)`
  && {
    padding: 8px;
    background-color: #F7F7F7;
  }
`

export const DistrictOption = styled(ComboboxOption)`
  && {
    display: block;
    padding-left: 16px;
  }
`

const ErrorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: -10px;
  margin-left: 16px;
  gap: 10px;
`

const LocationDropdown = (props) => {
  const {
    input: {
      value,
      onChange
    } = {},
    disabled
  } = props

  const isOnline = useNetworkStatus()
  const sdk = useSdk()

  const [locations, setLocations] = useState([])
  const [apiError, setApiError] = useState()
  const [callbackError, setCallbackError] = useState()
  const [hasUsedFindLocation, setHasUsedFindLocation] = useState(false)

  const displayValue = useMemo(() => value ? value.label : null, [value])

  const options = useMemo(() => {
    const regions = locations.regions
    const districts = locations.districts

    const mappedRegions = _.map(regions, region => {
      return ({
        label: region.name,
        value: region.locationId,
        category: 'Regions'
      })
    })

    const mappedDistricts = _.map(districts, district => {
      return ({
        label: district.name,
        value: district.locationId,
        parentId: district.parentId
      })
    })

    return [
      ...mappedRegions,
      ...mappedDistricts
    ]
  }, [locations])

  const fetchOrganisationLocations = useCallback(async (search) => {
    try {
      setApiError(null)
      const locations = await sdk.fetchOrganisationLocations({ query: { search } })
      setLocations(locations)
    } catch (error) {
      setApiError(error)
    }
  }, [])

  const mapOptions = useCallback((results) => {
    const groupedOptions = _.groupBy(results, 'parentId')

    const regionIds = _.keys(groupedOptions)
    const regionIdsSet = new Set(regionIds)

    const undefinedRegions = groupedOptions['undefined'] || []
    const unaddedRegions = undefinedRegions.filter(reg => !regionIdsSet.has(reg.value))

    const mappedOptions = []

    for (const regionId of regionIds) {
      if (regionId === 'undefined') {
        continue
      }
      const categoryOption = _.find(options, option => option.value === regionId)
      const regionData = groupedOptions[regionId]

      const regionOption = <RegionOption
        key={categoryOption.value}
        value={categoryOption.label}
      />

      const districtsBoxes = regionData.map((item, index) => {
        const value = item.value
        const label = item.label
        return (
          <DistrictOption key={`${index}-${value}`} value={label} />
        )
      })

      mappedOptions.push(regionOption)
      mappedOptions.push(...districtsBoxes)
    }

    for (const unaddedRegion of unaddedRegions) {
      const regionOption = <RegionOption
        key={unaddedRegion.value}
        value={unaddedRegion.label}
      />
      mappedOptions.push(regionOption)
    }
    return mappedOptions
  }, [options])

  const findMyLocationCallback = useCallback(({
    value,
    error
  }) => {
    if (error) {
      setCallbackError(error.message)
    }
    if (value) {
      onChange(value)
      setHasUsedFindLocation(true)
    }
  }, [onChange])

  const findMyLocationCancelCallback = useCallback(() => {
    setHasUsedFindLocation(false)
  }, [setHasUsedFindLocation])

  const handleChange = useCallback((value) => {
    if (_.isString(value)) {
      if (value.length < 3) {
        setLocations([])
      } else {
        fetchOrganisationLocations(value)
      }
    }
    const location = _.find(options, loc => (
      loc.label === value || loc.value === value
    ))
    if (location) {
      return onChange(location.value)
    }
    return onChange(value)
  }, [options])

  return (
    <>
      <AutoComplete
        {...props}
        options={options}
        hideBrowserAutocomplete={'off'}
        overrideMapOptions={mapOptions}
        freeSolo={hasUsedFindLocation}
        actionComponent={
          <FindMyLocation
            callback={findMyLocationCallback}
            cancelCallback={findMyLocationCancelCallback}
            disabled={disabled}
            isOnline={isOnline}
            hasBeenUsed={hasUsedFindLocation}
          />
        }
        hint={translation('Location Component - Hint')}
        input={{
          ...props.input,
          onChange: handleChange
        }}
        displayValue={displayValue}
        disabled={disabled || hasUsedFindLocation}
      />
      <ErrorContainer>
        {
          !isOnline && (
            <LocationError
              message={translation('Location Component - Offline Message')}
            />
          )
        }
        {
          apiError && isOnline && (
            <LocationError
              message={`${translation('Failed to load')},`}
              action={fetchOrganisationLocations}
              actionLabel={translation('Retry')}
            />
          )
        }
        {
          callbackError && isOnline && (
            <LocationError message={callbackError} />
          )
        }
      </ErrorContainer>
    </>
  )
}

export default LocationDropdown
