export default theme => ({
  button: {
    width: 'auto'
  },
  container: {
    minHeight: `calc(100vh - ${theme.headerHeight}px)`,
    display: 'flex',
    flexDirection: 'column'
  },
  outerBox: {
    margin: '0px auto',
    position: 'relative',
    padding: '24px 24px 48px',
    maxWidth: '1200px',
    width: '100%'
  },
  bottomBar: {
    marginTop: 'auto'
  },
  formDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  title: {
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    '& dt': {
      width: '200px',
      fontWeight: 'bold'
    }
  }
})
