import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import { styled } from '@material-ui/core/styles'

import { translations } from '../../../../config'
import { useQuery } from '../../../../hooks'
import { addQuery } from '../../../../helpers/query'

import IntegrationManagementReportingFormsPanel from '../IntegrationManagementReportingFormsPanel'

const REPORTING_FORMS = 'reportingForms'

const TabBar = styled(AppBar)(({ theme }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.grey[200]}`
}))

const ReportConfigurationTabs = (props) => {
  const currentQuery = useQuery()

  const tabs = useMemo(() => {
    return [
      { label: translations('Reporting Forms'), value: REPORTING_FORMS }
    ]
  }, [])

  useEffect(() => {
    const isInTabs = _.findIndex(tabs, { value: currentQuery.currentTab }) !== -1
    if (_.isUndefined(currentQuery.currentTab) || !isInTabs) {
      addQuery({ currentTab: REPORTING_FORMS })
    }
  }, [currentQuery, tabs])

  const handleChange = (e, selectedTab) => {
    addQuery({ currentTab: selectedTab })
  }

  const currentTab = currentQuery.currentTab || REPORTING_FORMS

  return (
    <>
      <TabContext value={currentTab}>
        <TabBar position='static' color='transparent' variant='outlined'>
          <TabList
            variant={'scrollable'}
            scrollButtons={'auto'}
            onChange={handleChange}
            aria-label={translations('Integration Management tabs')}
            indicatorColor={'primary'}
          >
            {_.map(tabs, ({ label, value }) => {
              return (
                <Tab label={label} style={{ textTransform: 'none' }} value={value} />
              )
            })}
          </TabList>
        </TabBar>
        <TabPanel value={REPORTING_FORMS}>
          <IntegrationManagementReportingFormsPanel />
        </TabPanel>
      </TabContext >
    </>
  )
}

export default ReportConfigurationTabs
