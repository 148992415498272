import _ from 'lodash'
import { promiseReducer } from '../../util'

class AckDetailsReducers {
  fetchAck = promiseReducer(
    (state, action) => {
      const fields = _.get(action, 'result.reportJSON')
      const ackStatus = _.get(action, 'result.status')
      const ackId = _.get(action, 'result.id')
      return {
        ...state,
        ack: action.result,
        fields,
        ackId,
        ackStatus
      }
    }
  )
  submitAck = promiseReducer(
    (state, action) => {
      return {
        ...state
      }
    }
  )
}

export default new AckDetailsReducers()
