import _ from 'lodash'
import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dropdown from '../Dropdown'
import Button from '../../Button'
import style from './style'
import { translations } from '../../../config'

const _renderButton = (props) => {
  const { input = {}, onAdd, onEdit, meta = {} } = props
  const disabled = !input.value
  const isAddPredicate = input.value === '#add' || !input.value
  const buttonText = isAddPredicate ? 'Add' : 'Edit'
  const buttonAction = isAddPredicate ? onAdd : onEdit

  return <Button disabled={disabled} onClick={() => buttonAction({ value: input.value, formName: meta.form })} raised color={'white'}>{translations(buttonText)}</Button>
}

const dropdownOptions = ({ options }) => {
  return _.concat(
    [{ value: '#add', label: translations('Create new') }],
    options
  )
}

const EditableDropdown = ({ classes, ...props }) => {
  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        <Dropdown {...props} options={dropdownOptions(props)} />
      </div>
      <div className={classes.buttonContainer}>
        {_renderButton(props)}
      </div>
    </div>
  )
}

export default withStyles(style)(EditableDropdown)
