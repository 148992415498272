import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import actions from './actions'
import * as authSelectors from '../auth/selectors'

class SourcesMiddleware {
  fetchSourcesMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const organisationParams = matchPath(action.payload.location.pathname, { path: '/organisations/:id', exact: true })
      const isRoutingToOwnOrganisation = matchPath(action.payload.location.pathname, { path: '/my-organisation', exact: true })
      const state = getState()
      const isLoggedIn = authSelectors.getIsLoggedIn(state)
      const organisationId = _.get(organisationParams, 'params.id')
      if (isLoggedIn && _.isString(organisationId)) {
        dispatch(actions.fetchOrganisationSources({ organisationId }))
      }
      if (isLoggedIn && isRoutingToOwnOrganisation) {
        const ownOrganisationId = authSelectors.getUserSelectedOrganisationId(state)
        dispatch(actions.fetchOrganisationSources({ organisationId: ownOrganisationId }))
      }
    }
  }
}

export default new SourcesMiddleware()
