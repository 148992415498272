import InfoIcon from '@material-ui/icons/Info'
import { styled } from '@material-ui/core/styles'

const AlertContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  [theme.breakpoints.down('lg')]: {
    margin: '50px 50px 0 50px'
  },
  [theme.breakpoints.up('lg')]: {
    margin: '69px 69px 0 69px'
  },
  [theme.breakpoints.down('sm')]: {
    margin: '20px 20px 0 20px'
  },
}))

const Content = styled('div')(({ theme }) => ({
  maxWidth: '750px',
  fontSize: theme.typography.pxToRem(14),
  '& p': {
    marginTop: 0
  }
}))

const AlertBanner = ({ content }) => (
  <AlertContainer>
    <InfoIcon fontSize={'large'} />
    <Content dangerouslySetInnerHTML={{ __html: content }} />
  </AlertContainer>
)

export default AlertBanner
