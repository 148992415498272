import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import modalService from '../../../services/modalService'
import FullScreenLoader from '../../../components/FullScreenLoader'
import ConfigureModal from '../ConfigureModal'
import CommunicationsHeader from './CommunicationsHeader'
import _ from 'lodash'

const mapStateToProps = (state, ownProps) => {
  return {
    pageTitle: _.get(ownProps, 'pageTitle'),
    channel: _.get(ownProps, 'channel')
  }
}

const enhance = compose(
  connect(mapStateToProps),
  withHandlers({
    onConfigure: ({channel}) => () => {
      modalService.open({
        component: ConfigureModal,
        channel
      })
    }
  }),
  FullScreenLoader
)

export default enhance(CommunicationsHeader)
