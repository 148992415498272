"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialFieldMap = exports.getFormSchema = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var _config = require("../../../config");

var _moment = _interopRequireDefault(require("moment"));

var _formHelpers = require("../formHelpers");

var _sideEffects = require("../../../formHelpers/sideEffects");

var _imperialToggle = require("../../../formHelpers/imperialToggle");

var _options = require("../../../formHelpers/options");

var computedValue = _interopRequireWildcard(require("../../../formHelpers/computedValue"));

var _computedHidden = require("../../../formHelpers/computedHidden");

var _validators = require("../../../formHelpers/validators");

var _utilities = require("../../../utilities");

var EDQM = _interopRequireWildcard(require("../../../formHelpers/edqm"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var initialFieldMap = {
  medicinalProduct: {
    group: 'medicine',
    field: 'medicinalproduct'
  }
};
exports.initialFieldMap = initialFieldMap;
var dateFormats = {
  yyyymmddhhmmss: '204',
  yyyymmddhhmm: '203',
  yyyymmdd: '102',
  yyyymm: '610',
  yyyy: '602'
};
var timeMeasurements = {
  decade: '800',
  year: '801',
  month: '802',
  week: '803',
  day: '804',
  hour: '805'
};
var getDurationInYears = computedValue.getDuration(timeMeasurements, 'years');

var getMHRAFormatForDate = function getMHRAFormatForDate(value) {
  return (0, _formHelpers.getFormatForDate)(value, dateFormats);
};

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'terms',
    field: 'TermsCheckbox',
    computedHidden: function computedHidden(values) {
      return values.isAuthorised;
    },
    props: {
      shrink: true,
      name: 'terms',
      label: 'Terms and conditions',
      required: true
    }
  }, {
    id: 'pregnancyAtTimeOfReaction',
    field: 'Dropdown',
    computedValue: computedValue.clearIfNot(_sideEffects.pregnancy.canBearChildren({
      getDurationInYears: getDurationInYears,
      timeMeasurements: timeMeasurements
    })),
    computedHidden: _sideEffects.audience.match({
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.canBearChildren({
        getDurationInYears: getDurationInYears,
        timeMeasurements: timeMeasurements
      })),
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.canBearChildren({
        getDurationInYears: getDurationInYears,
        timeMeasurements: timeMeasurements
      })),
      DEFAULT: _computedHidden.hide
    }),
    computedOptions: _sideEffects.pregnancy.generatePregnancyAtTimeOfReactionOptions,
    props: {
      shrink: true,
      required: false,
      name: 'pregnancyAtTimeOfReaction',
      label: 'Information about use of medicines during pregnancy are valuable to increase our knowledge of their safety.\n\nWas a pregnancy taking place at the time of the adverse reaction?'
    }
  }, {
    id: 'pregnancyBreastfeedingExposureToMedicine',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.canBearChildren({
        getDurationInYears: getDurationInYears,
        timeMeasurements: timeMeasurements
      })),
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.canBearChildren({
        getDurationInYears: getDurationInYears,
        timeMeasurements: timeMeasurements
      })),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      required: false,
      name: 'pregnancyBreastfeedingExposureToMedicine',
      label: 'Are you breastfeeding?',
      options: (0, _options.mapValuesAt1)(['Yes', 'No'])
    }
  }, {
    id: 'reportInvolvesAdverseReactionDuringPregnancy',
    field: 'Dropdown',
    computedValue: computedValue.clearIfNot(_sideEffects.pregnancy.isParentSubmittingReport),
    computedHidden: _sideEffects.audience.match({
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.isParentSubmittingReport),
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.isParentSubmittingReport),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      required: false,
      name: 'reportInvolvesAdverseReactionDuringPregnancy',
      label: 'Information about use of medicines during pregnancy are valuable to increase our knowledge of their safety.\n\nDoes this report involve an adverse reaction that occurred as a result of an exposure during pregnancy?',
      options: (0, _options.mapValuesAt1)(['Yes', 'No'])
    }
  }, {
    id: 'childDevelopmentMilestones',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.isReportingForChild),
    computedValue: computedValue.clearIfNot(_sideEffects.pregnancy.isReportingForChild),
    props: {
      shrink: true,
      name: 'childDevelopmentMilestones',
      label: 'To date has your child met their key development milestones?'
    }
  }, {
    id: 'didMedicineHaveAdverseEffectOnPregnancy',
    field: 'Dropdown',
    computedValue: computedValue.clearIfNot(_sideEffects.pregnancy.yesWithConsequence),
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.yesWithConsequence),
    props: {
      shrink: true,
      required: false,
      name: 'didMedicineHaveAdverseEffectOnPregnancy',
      label: 'Do you think the medicine had an adverse effect on any aspect of the pregnancy?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No', 'Unsure'])
    }
  }, {
    id: 'pregnancyAdverseEffectsDetails',
    field: 'Input',
    computedValue: computedValue.clearIfNot(_sideEffects.pregnancy.medicineHadAdverseEffect),
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.medicineHadAdverseEffect),
    props: {
      shrink: true,
      required: false,
      name: 'pregnancyAdverseEffectsDetails',
      label: 'If yes, please provide details of the suspected adverse effects on the pregnancy. If an adverse effect occurred that is unrelated to the pregnancy, details can be provided at a later stage.',
      validate: [validators.maxLength(250, 'Field')],
      maxLength: 250
    }
  }, {
    id: 'pregnancyExposureToMedicine',
    field: 'Dropdown',
    computedValue: (0, _utilities.callEveryFunctionWithArgs)([(0, _utilities.castFieldStringToArray)('pregnancyExposureToMedicine'), computedValue.clearIfNot(_sideEffects.pregnancy.isReportingForChildOrPregnancy)]),
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.isReportingForChildOrPregnancy),
    computedOptions: _sideEffects.pregnancy.computePregnancyExposureToMedicineOptions,
    props: {
      shrink: true,
      required: false,
      multiple: true,
      name: 'pregnancyExposureToMedicine',
      label: 'When did the exposure to the medicine occur during the pregnancy?'
    }
  }, {
    id: 'pregnancyEstimatedDueDate',
    field: 'Date',
    computedValue: computedValue.clearIfNot(_sideEffects.pregnancy.yesCurrently),
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.yesCurrently),
    computedHelperText: _sideEffects.covid19.computeHelperDateText,
    props: {
      validate: [_sideEffects.covid19.computePartialDateValidation(validators)],
      shrink: true,
      required: false,
      name: 'pregnancyEstimatedDueDate',
      label: 'Estimated due date',
      endYearOffset: 1
    }
  }, {
    id: 'pregnancyDetailsOfResultsOfScans',
    field: 'Input',
    computedValue: computedValue.clearIfNot(_sideEffects.pregnancy.isReportingForChildOrPregnancy),
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.isReportingForChildOrPregnancy),
    props: {
      shrink: true,
      required: false,
      name: 'pregnancyDetailsOfResultsOfScans',
      label: 'Please can you provide the details of any results of scans or investigations relating to the pregnancy?',
      validate: [validators.maxLength(250, 'Field')],
      maxLength: 250
    }
  }, {
    id: 'previousPregnancyDetails',
    field: 'Input',
    computedValue: computedValue.clearIfNot(_sideEffects.pregnancy.isReportingForChildOrPregnancy),
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.isReportingForChildOrPregnancy),
    props: {
      shrink: true,
      required: false,
      name: 'previousPregnancyDetails',
      label: 'Please provide any additional details of this and any previous pregnancies.',
      validate: [validators.maxLength(250, 'Field')],
      maxLength: 250
    }
  }, {
    id: 'pregnancyFolicAcid',
    field: 'Dropdown',
    computedValue: computedValue.clearIfNot(_sideEffects.pregnancy.isReportingForChildOrPregnancy),
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.isReportingForChildOrPregnancy),
    computedOtherValues: _sideEffects.pregnancy.appendFolicAcidInConcomitantDrugs,
    props: {
      shrink: true,
      required: false,
      name: 'pregnancyFolicAcid',
      label: 'Were there any folic acid supplements taken during the pregnancy?',
      options: (0, _options.mapValuesAt1)(['Prescribed dose (5mg)', 'Over the counter Folic Acid (400mcg)', 'Unknown dose', 'No'])
    }
  }, {
    id: 'canSaveDraft',
    field: 'Input',
    computedValue: function computedValue(values) {
      return values.safetyreportid && values.messagenumb;
    },
    props: {
      type: 'hidden',
      name: 'canSaveDraft',
      label: 'Can save draft',
      required: false
    }
  }, {
    id: 'causalityAssessmentRequired',
    field: 'Input',
    computedValue: function computedValue(values) {
      var reportType = values.reporttype === '2';

      var primarysource = _lodash["default"].chain(values).get('primarysource').some(function (v) {
        return v.observestudytype === '1';
      }).value();

      var isRequired = reportType && primarysource;
      return isRequired ? 'true' : 'false';
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'causalityAssessmentRequired',
      label: 'Causality required',
      required: false
    }
  }, {
    id: 'audienceid',
    field: 'Input',
    computedValue: function computedValue(_formValues, _parentFormValues, _organisationDetails, formViewDetails) {
      return _lodash["default"].get(formViewDetails, 'audienceId');
    },
    props: {
      type: 'hidden',
      name: 'audienceid'
    }
  }, {
    id: 'aefilogic',
    field: 'Input',
    computedValue: _fp["default"].T,
    props: {
      shrink: true,
      name: 'aefilogic',
      label: 'Modify other fields based on AEFI logic',
      required: false,
      type: 'hidden'
    }
  }, {
    id: 'susarEditable',
    field: 'Input',
    computedValue: function computedValue(values) {
      return values.reporttype === '2' && !!values.primarysource && !!_lodash["default"].find(values.primarysource, function (v) {
        return v.observestudytype === '1';
      }) ? 'true' : 'false';
    },
    props: {
      type: 'hidden',
      name: 'susarEditable'
    }
  }, {
    id: 'spontaneousEditable',
    field: 'Input',
    computedValue: function computedValue(values) {
      return values.reporttype === '1' ? 'true' : 'false';
    },
    props: {
      type: 'hidden',
      name: 'spontaneousEditable'
    }
  }, {
    id: 'messagetype',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'messagetype',
      required: true,
      label: 'Message Type',
      options: (0, _options.mapValueAndLabel)(['ICHICSR', 'BACKLOG'])
    }
  }, {
    id: 'safetyreportid',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'safetyreportid',
      label: "Sender's Safety Report Unique Identifier",
      required: true,
      validate: [validators.maxLength(100, 'Field'), validators.safetyReportIdFormat],
      maxLength: 100
    }
  }, {
    id: 'messagenumb',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'messagenumb',
      label: 'Message Number',
      validate: [validators.maxLength(100, 'Field')],
      maxLength: 100,
      required: true
    }
  }, {
    id: 'primarysourcecountry',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'primarysourcecountry',
      label: 'Primary source country',
      autocomplete: true,
      options: _config.countryCodesDropdownOptions,
      required: true
    }
  }, {
    id: 'occurcountry',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'occurcountry',
      label: 'Country where the reaction/event occurred',
      autocomplete: true,
      options: _config.countryCodesDropdownOptions,
      required: false
    }
  }, {
    id: 'transmissiondateformat',
    field: 'Input',
    computedValue: _sideEffects.audience.match({
      INDUSTRY: function INDUSTRY(values) {
        return getMHRAFormatForDate(values.transmissiondate);
      }
    }),
    props: {
      type: 'hidden',
      shrink: true,
      name: 'transmissiondateformat',
      label: 'Transmission Date Format',
      required: true
    }
  }, {
    id: 'transmissiondate',
    field: 'Date',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    computedValue: _sideEffects.audience.match({
      INDUSTRY: function INDUSTRY() {
        return (0, _moment["default"])().format('YYYYMMDD');
      }
    }),
    props: {
      disabled: true,
      shrink: true,
      name: 'transmissiondate',
      label: 'Date of this transmission',
      required: true,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'messagedateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.messagedate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'messagedateformat',
      label: 'Message Date Format'
    }
  }, {
    id: 'messagedate',
    field: 'Date',
    props: {
      type: 'hidden',
      shrink: true,
      name: 'messagedate',
      label: 'Date the report was initially started'
    }
  }, {
    id: 'reporttype',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'reporttype',
      label: 'Type of report',
      options: [{
        value: '1',
        label: 'Spontaneous'
      }, {
        value: '2',
        label: 'Report from study'
      }, {
        value: '3',
        label: 'Other'
      }, {
        value: '4',
        label: 'Not available to sender (unknown)'
      }],
      required: true
    }
  }, {
    id: 'receivedateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.receivedate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'receivedateformat',
      label: 'Received Date Format',
      required: true
    }
  }, {
    id: 'receivedate',
    field: 'Date',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive)
    }),
    props: {
      shrink: true,
      name: 'receivedate',
      label: 'Date report was first received from source',
      required: true,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('receivedateformat', dateFormats), validators.isDateAfterOrEqual('reaction', 'Reaction start date', 'reactionstartdate')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'receiptdateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.receiptdate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'receiptdateformat',
      label: 'Receipt Date Format',
      required: true
    }
  }, {
    id: 'receiptdate',
    field: 'Date',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'receiptdate',
      label: 'Date of receipt of the most recent information for this report',
      required: true,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('receiptdateformat', dateFormats), validators.isDateAfterOrEqual('reaction', 'Reaction start date', 'reactionstartdate')],
      helperText: 'YYYY/MM/DD',
      startYear: 1920
    }
  }, {
    id: 'fulfillexpeditecriteria',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'fulfillexpeditecriteria',
      label: 'Does this case fulfill the local criteria for an expedited report?',
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox'],
      required: true
    }
  }, {
    id: 'authoritynumb',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    computedEditable: function computedEditable(values) {
      return !values.companynumb;
    },
    computedRequired: function computedRequired(values) {
      return !values.companynumb;
    },
    props: {
      shrink: true,
      name: 'authoritynumb',
      label: "Regulatory authority's case report number",
      validate: [validators.maxLength(100, 'Field'), validators.safetyReportIdFormat],
      maxLength: 100
    }
  }, {
    id: 'companynumb',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    computedEditable: function computedEditable(values) {
      return !values.authoritynumb;
    },
    computedRequired: function computedRequired(values) {
      return !values.authoritynumb;
    },
    props: {
      shrink: true,
      name: 'companynumb',
      label: "Other sender's case report number",
      validate: [validators.maxLength(100, 'Field')],
      maxLength: 100
    }
  }, {
    id: 'medicallyconfirm',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'medicallyconfirm',
      label: 'Was the case medically confirmed, if not initially from health professional?',
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox'],
      required: false
    }
  }, {
    id: 'patienttitle',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'patienttitle',
      label: 'Patient title',
      required: false,
      validate: [validators.maxLength(50, 'Field')],
      maxLength: 50
    }
  }, {
    id: 'patientgivename',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'patientgivename',
      label: 'Patient given name',
      required: false,
      validate: [validators.maxLength(35, 'Field')],
      maxLength: 35
    }
  }, {
    id: 'patientfamilyname',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'patientfamilyname',
      label: 'Patient family name',
      required: false,
      validate: [validators.maxLength(50, 'Field')],
      maxLength: 50
    }
  }, {
    id: 'patientaddresshousenameornumber',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'patientaddresshousenameornumber',
      label: 'Patient house number or name',
      required: false,
      validate: [validators.maxLength(30, 'Field')],
      maxLength: 30
    }
  }, {
    id: 'patientaddressline1',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'patientaddressline1',
      label: 'Patient address line 1',
      required: false,
      validate: [validators.maxLength(30, 'Field')],
      maxLength: 30
    }
  }, {
    id: 'patientaddressline2',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'patientaddressline2',
      label: 'Patient address line 2',
      required: false,
      validate: [validators.maxLength(30, 'Field')],
      maxLength: 30
    }
  }, {
    id: 'patientstreet',
    field: 'Input',
    computedValue: _sideEffects.audience.match({
      MOP: _sideEffects.aefi.joinPatientStreetNameAndAddress,
      HCP: _sideEffects.aefi.joinPatientStreetNameAndAddress
    }),
    computedHidden: _sideEffects.audience.match({
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'patientstreet',
      label: 'Patient street',
      required: false,
      validate: [validators.maxLength(100, 'Field')],
      maxLength: 100
    }
  }, {
    id: 'patientcity',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'patientcity',
      label: 'Patient city',
      required: false,
      validate: [validators.maxLength(35, 'Field')],
      maxLength: 35
    }
  }, {
    id: 'patientstate',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'patientstate',
      label: 'Patient state or province',
      required: false,
      validate: [validators.maxLength(40, 'Field')],
      maxLength: 40
    }
  }, {
    id: 'patientpostcode',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'patientpostcode',
      label: 'Patient postcode',
      required: false,
      validate: [validators.maxLength(15, 'Field')],
      maxLength: 15
    }
  }, {
    id: 'patientcountry',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide,
      DEFAULT: _computedHidden.hide
    }),
    computedValue: _sideEffects.audience.match({
      MOP: _sideEffects.aefi.computeAefiCountry,
      HCP: _sideEffects.aefi.computeAefiCountry
    }),
    props: {
      shrink: true,
      name: 'patientcountry',
      label: 'Patient country code',
      autocomplete: true,
      options: _config.countryCodesDropdownOptions,
      required: false
    }
  }, {
    id: 'healthcarefacilityorganization',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'healthcarefacilityorganization',
      label: 'Healthcare facility organisation',
      required: false,
      validate: [validators.maxLength(50, 'Field')],
      maxLength: 50
    }
  }, {
    id: 'healthcarefacilityaddressline1',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'healthcarefacilityaddressline1',
      label: 'Healthcare facility address line 1',
      required: false,
      validate: [validators.maxLength(45, 'Field')],
      maxLength: 45
    }
  }, {
    id: 'healthcarefacilityaddressline2',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'healthcarefacilityaddressline2',
      label: 'Healthcare facility address line 2',
      required: false,
      validate: [validators.maxLength(45, 'Field')],
      maxLength: 45
    }
  }, {
    id: 'healthcarefacilitystreet',
    field: 'Input',
    computedValue: _sideEffects.audience.match({
      MOP: _sideEffects.aefi.joinHealthcareFacilityStreetNameAndAddress,
      HCP: _sideEffects.aefi.joinHealthcareFacilityStreetNameAndAddress
    }),
    computedHidden: _sideEffects.audience.match({
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'healthcarefacilitystreet',
      label: 'Healthcare facility street',
      required: false
    }
  }, {
    id: 'healthcarefacilitycity',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'healthcarefacilitycity',
      label: 'Healthcare facility city',
      required: false,
      validate: [validators.maxLength(35, 'Field')],
      maxLength: 35
    }
  }, {
    id: 'healthcarefacilitystate',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'healthcarefacilitystate',
      label: 'Healthcare facility state or province',
      required: false,
      validate: [validators.maxLength(40, 'Field')],
      maxLength: 40
    }
  }, {
    id: 'healthcarefacilitypostcode',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'healthcarefacilitypostcode',
      label: 'Healthcare facility postcode',
      required: false,
      validate: [validators.maxLength(15, 'Field')],
      maxLength: 15
    }
  }, {
    id: 'healthcarefacilitycountry',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide,
      DEFAULT: _computedHidden.hide
    }),
    computedValue: _sideEffects.audience.match({
      MOP: _sideEffects.aefi.computeAefiCountry,
      HCP: _sideEffects.aefi.computeAefiCountry
    }),
    props: {
      shrink: true,
      name: 'healthcarefacilitycountry',
      label: 'Healthcare facility country code',
      autocomplete: true,
      required: false
    }
  }, {
    id: 'duplicate',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'duplicate',
      label: 'Duplicate',
      options: [{
        value: '1',
        label: 'Yes'
      }],
      required: false
    }
  }, {
    id: 'reportduplicate',
    field: 'Repeatable',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    computedEditable: function computedEditable(values) {
      return values.duplicate === '1';
    },
    props: {
      shrink: true,
      name: 'reportduplicate',
      label: 'Report Duplicates',
      repeatableLabel: ['duplicatesource'],
      schema: [{
        id: 'duplicatesource',
        field: 'Input',
        props: {
          shrink: true,
          name: 'duplicatesource',
          label: 'Duplicate Source',
          required: true,
          validate: [validators.maxLength(50, 'Field')],
          maxLength: 50
        }
      }, {
        id: 'duplicatenumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'duplicatenumb',
          label: 'Duplicate number',
          required: false,
          validate: [validators.maxLength(100, 'Field')],
          maxLength: 100
        }
      }]
    }
  }, {
    id: 'linkedreport',
    field: 'Repeatable',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    computedEditable: function computedEditable(values) {
      return values.duplicate === '1';
    },
    props: {
      shrink: true,
      name: 'linkedreport',
      label: 'Linked Reports',
      repeatableLabel: ['linkreportnumb'],
      schema: [{
        id: 'linkreportnumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'linkreportnumb',
          label: 'Linked report number',
          required: true,
          validate: [validators.maxLength(100, 'Field')],
          maxLength: 100
        }
      }]
    }
  }, {
    id: 'casenullification',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'casenullification',
      label: 'Report Nullification',
      options: [{
        value: '1',
        label: 'Yes'
      }],
      required: false
    }
  }, {
    id: 'nullificationreason',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    computedEditable: function computedEditable(values) {
      return values.casenullification === '1';
    },
    computedRequired: function computedRequired(values) {
      return values.casenullification === '1';
    },
    props: {
      shrink: true,
      name: 'nullificationreason',
      label: 'Reason for nullification',
      validate: [validators.maxLength(200, 'Field')],
      maxLength: 200
    }
  }, {
    id: 'sendertype',
    field: 'Input',
    computedValue: _sideEffects.company.populateSenderType,
    props: {
      type: 'hidden',
      shrink: true,
      name: 'sendertype',
      label: 'Which best describes you?',
      required: true
    }
  }, {
    id: 'personaldetailsoccupation',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'personaldetailsoccupation',
      label: 'Are you a healthcare professional?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'personaldetailsprofession',
    field: 'Dropdown',
    computedHidden: function computedHidden(values) {
      return values.personaldetailsoccupation !== 'Yes';
    },
    computedRequired: function computedRequired(values) {
      return values.personaldetailsoccupation === 'Yes';
    },
    computedOptions: _sideEffects.general.getHCPProfessionOptions,
    computedValue: _sideEffects.general.clearIfNotPersonalDetailsOccupation,
    props: {
      shrink: true,
      name: 'personaldetailsprofession',
      label: 'What is your profession?',
      optionsTranslationKeyPrefix: 'Profession - '
    }
  }, {
    id: 'personaldetailsqualification',
    field: 'Input',
    computedValue: _sideEffects.company.getProfessionOfPersonalDetailsXml,
    props: {
      shrink: true,
      name: 'personaldetailsqualification',
      label: 'What is your profession?',
      type: 'hidden'
    }
  }, {
    id: 'professionofreporter',
    field: 'Dropdown',
    computedOptions: _sideEffects.audience.getProfessionOptions,
    props: {
      shrink: true,
      name: 'professionofreporter',
      label: 'Which best describes you?',
      required: true,
      optionsTranslationKeyPrefix: 'Profession - ',
      groupOptions: true,
      groupAttribute: 'professionGroupId',
      parent: 'professionGroup',
      parentValue: 'id',
      parentLabel: 'name'
    }
  }, {
    id: 'personaldetailstitle',
    field: 'Input',
    props: {
      shrink: true,
      name: 'personaldetailstitle',
      label: 'Title',
      required: false,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'personaldetailsgivename',
    field: 'Input',
    props: {
      shrink: true,
      name: 'personaldetailsgivename',
      label: 'First Name',
      required: true,
      validate: [validators.maxLength(35, 'Field')],
      maxLength: 35
    }
  }, {
    id: 'personaldetailsfamilyname',
    field: 'Input',
    props: {
      shrink: true,
      name: 'personaldetailsfamilyname',
      label: 'Last Name',
      required: true,
      validate: [validators.maxLength(50, 'Field')],
      maxLength: 50
    }
  }, {
    id: 'personaldetailsemailaddress',
    field: 'Input',
    computedRequired: _sideEffects.audience.match({
      MOP: _sideEffects.audience.addressFieldsIncompleteForPersonalDetails,
      HCP: _sideEffects.audience.addressFieldsIncompleteForPersonalDetails
    }),
    props: {
      shrink: true,
      name: 'personaldetailsemailaddress',
      label: 'Email',
      validate: [validators.isValidEmail, _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPersonalDetails.fields, _sideEffects.audience.addressValidationForPersonalDetails.error))]
    }
  }, {
    id: 'sendergivename',
    field: 'Input',
    computedValue: _sideEffects.company.orClear(computedValue.get('personaldetailsgivename')),
    props: {
      type: 'hidden',
      shrink: true,
      name: 'sendergivename',
      label: 'Sender First Name'
    }
  }, {
    id: 'senderfamilyname',
    field: 'Input',
    computedValue: _sideEffects.company.orClear(computedValue.get('personaldetailsfamilyname')),
    props: {
      type: 'hidden',
      shrink: true,
      name: 'senderfamilyname',
      label: 'Sender Last Name'
    }
  }, {
    id: 'senderemailaddress',
    computedValue: computedValue.get('personaldetailsemailaddress'),
    field: 'Input',
    props: {
      shrink: true,
      type: 'hidden',
      name: 'senderemailaddress',
      label: 'Email'
    }
  }, {
    id: 'senderorganization',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.company.isOfProfessionType),
    computedValue: _sideEffects.company.orClear(computedValue.noop),
    props: {
      shrink: true,
      name: 'senderorganization',
      label: 'Company Name',
      required: true
    }
  }, {
    id: 'qualification_primarysource',
    field: 'Input',
    computedValue: _sideEffects.company.populateReporterProfessionXml,
    props: {
      shrink: true,
      name: 'qualification_primarysource',
      label: 'Which best describes the primary source?',
      type: 'hidden'
    }
  }, {
    id: 'professionofprimarysource',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.company.isOfProfessionType),
    computedValue: _sideEffects.company.noopOrCallback(computedValue.clearField),
    computedOptions: _sideEffects.audience.getProfessionOptions,
    props: {
      shrink: true,
      name: 'professionofprimarysource',
      label: 'Which best describes the primary source?',
      required: true,
      optionsTranslationKeyPrefix: 'Profession - ',
      groupOptions: true,
      groupAttribute: 'professionGroupId',
      parent: 'professionGroup',
      parentValue: 'id',
      parentLabel: 'name'
    }
  }, {
    id: 'reportertitle_primarysource',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.company.isOfProfessionType),
    computedValue: _sideEffects.company.populateReporterTitle,
    props: {
      shrink: true,
      name: 'reportertitle',
      label: 'Title',
      required: false,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'reportergivename_primarysource',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.company.isOfProfessionType),
    computedValue: _sideEffects.company.populateReporterFirstName,
    props: {
      shrink: true,
      name: 'reportergivename_primarysource',
      label: 'First Name',
      required: true,
      validate: [validators.maxLength(35, 'Field')],
      maxLength: 35
    }
  }, {
    id: 'reporterfamilyname_primarysource',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.company.isOfProfessionType),
    computedValue: _sideEffects.company.populateReporterLastName,
    props: {
      shrink: true,
      name: 'reporterfamilyname_primarysource',
      label: 'Last Name',
      required: true,
      validate: [validators.maxLength(50, 'Field')],
      maxLength: 50
    }
  }, {
    id: 'reporteremailaddress_primarysource',
    field: 'Input',
    computedValue: computedValue.get('personaldetailsemailaddress'),
    props: {
      type: 'hidden',
      shrink: true,
      name: 'reporteremailaddress_primarysource',
      label: 'Reporter Email'
    }
  }, {
    id: 'reportertel_primarysource',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.show,
      HCP: _computedHidden.show,
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'reportertel_primarysource',
      label: 'Telephone',
      required: false,
      validate: [validators.maxLength(15, 'Field')],
      maxLength: 15
    }
  }, {
    id: 'reporteraddresshousenameornumber',
    field: 'Input',
    computedRequired: _sideEffects.audience.match({
      MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails,
      HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails
    }),
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.show,
      HCP: _computedHidden.show,
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'reporteraddresshousenameornumber',
      label: 'House Number or Name',
      required: false,
      validate: [validators.maxLength(30, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPersonalDetails.fields, _sideEffects.audience.addressValidationForPersonalDetails.error))],
      maxLength: 30
    }
  }, {
    id: 'reporteraddressline1',
    field: 'Input',
    computedRequired: _sideEffects.audience.match({
      MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails,
      HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails
    }),
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.show,
      HCP: _computedHidden.show,
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'reporteraddressline1',
      label: 'Address Line 1',
      required: false,
      validate: [validators.maxLength(30, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPersonalDetails.fields, _sideEffects.audience.addressValidationForPersonalDetails.error))],
      maxLength: 30
    }
  }, {
    id: 'reporteraddressline2',
    field: 'Input',
    computedRequired: _sideEffects.audience.match({
      MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails,
      HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails
    }),
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.show,
      HCP: _computedHidden.show,
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'reporteraddressline2',
      label: 'Address Line 2',
      required: false,
      validate: [validators.maxLength(30, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPersonalDetails.fields, _sideEffects.audience.addressValidationForPersonalDetails.error))],
      maxLength: 30
    }
  }, {
    id: 'reporterstreet_primarysource',
    field: 'Input',
    computedValue: _sideEffects.audience.match({
      MOP: _sideEffects.audience.joinStreetNameAndAddress,
      HCP: _sideEffects.audience.joinStreetNameAndAddress
    }),
    computedHidden: _sideEffects.audience.match({
      DEFAULT: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'reporterstreet_primarysource',
      label: 'Reporter street',
      required: false,
      validate: [validators.maxLength(100, 'Field')],
      maxLength: 100
    }
  }, {
    id: 'reportercity_primarysource',
    field: 'Input',
    computedRequired: _sideEffects.audience.match({
      MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails,
      HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails
    }),
    props: {
      shrink: true,
      name: 'reportercity_primarysource',
      label: 'Reporter city',
      required: false,
      validate: [validators.maxLength(35, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPersonalDetails.fields, _sideEffects.audience.addressValidationForPersonalDetails.error))],
      maxLength: 35
    }
  }, {
    id: 'reporterstate_primarysource',
    field: 'Input',
    computedRequired: _sideEffects.audience.match({
      MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails,
      HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails
    }),
    props: {
      shrink: true,
      name: 'reporterstate_primarysource',
      label: 'Reporter state or province',
      required: false,
      validate: [validators.maxLength(40, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPersonalDetails.fields, _sideEffects.audience.addressValidationForPersonalDetails.error))],
      maxLength: 40
    }
  }, {
    id: 'reporterpostcode_primarysource',
    field: 'Input',
    computedRequired: _sideEffects.audience.match({
      MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails,
      HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPublicDetails
    }),
    props: {
      shrink: true,
      name: 'reporterpostcode_primarysource',
      label: 'Reporter postcode',
      required: false,
      validate: [validators.maxLength(15, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPersonalDetails.fields, _sideEffects.audience.addressValidationForPersonalDetails.error))],
      maxLength: 15
    }
  }, {
    id: 'reportercountry_primarysource',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    computedValue: _sideEffects.audience.match({
      MOP: _sideEffects.audience.computeReporterCountry,
      HCP: _sideEffects.audience.computeReporterCountry
    }),
    props: {
      shrink: true,
      name: 'reportercountry_primarysource',
      label: 'Country',
      autocomplete: true,
      options: _config.countryCodesDropdownOptions,
      required: true
    }
  }, {
    id: 'primarysource',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'primarysource',
      label: 'Reporter(s)',
      repeatableLabelPlaceholder: 'Reporter',
      repeatableLabel: ['reportergivename', 'reporterfamilyname'],
      repeatableSeparator: ' ',
      required: true,
      schema: [{
        id: 'reportertitle',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reportertitle',
          label: 'Reporter Title',
          required: false,
          validate: [validators.maxLength(50, 'Field')],
          maxLength: 50
        }
      }, {
        id: 'reportergivename',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reportergivename',
          label: 'Reporter given name',
          required: false,
          validate: [validators.maxLength(35, 'Field')],
          maxLength: 35
        }
      }, {
        id: 'reporterfamilyname',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reporterfamilyname',
          label: 'Reporter family name',
          required: false,
          validate: [validators.maxLength(50, 'Field')],
          maxLength: 50
        }
      }, {
        id: 'reporteremailaddress',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reporteremailaddress',
          label: 'Email',
          required: false,
          validate: [validators.isValidEmail, _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPrimarySource.fields, _sideEffects.audience.addressValidationForPrimarySource.error))]
        }
      }, {
        id: 'reportertel',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reportertel',
          label: 'Reporter Telephone',
          required: false,
          validate: [validators.maxLength(15, 'Field')],
          maxLength: 15
        }
      }, {
        id: 'reporterorganization',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'reporterorganization',
          label: 'Reporter organization',
          required: false,
          validate: [validators.maxLength(60, 'Field')],
          maxLength: 60
        }
      }, {
        id: 'reporteraddresshousenameornumber',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.show,
          HCP: _computedHidden.show,
          DEFAULT: _computedHidden.hide
        }),
        computedRequired: _sideEffects.audience.match({
          MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource,
          HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource
        }),
        props: {
          shrink: true,
          name: 'reporteraddresshousenameornumber',
          label: 'House Number or Name',
          required: false,
          validate: [validators.maxLength(30, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPrimarySource.fields, _sideEffects.audience.addressValidationForPrimarySource.error))],
          maxLength: 30
        }
      }, {
        id: 'reporteraddressline1',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.show,
          HCP: _computedHidden.show,
          DEFAULT: _computedHidden.hide
        }),
        computedRequired: _sideEffects.audience.match({
          MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource,
          HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource
        }),
        props: {
          shrink: true,
          name: 'reporteraddressline1',
          label: 'Address Line 1',
          required: false,
          validate: [validators.maxLength(30, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPrimarySource.fields, _sideEffects.audience.addressValidationForPrimarySource.error))],
          maxLength: 30
        }
      }, {
        id: 'reporteraddressline2',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.show,
          HCP: _computedHidden.show,
          DEFAULT: _computedHidden.hide
        }),
        computedRequired: _sideEffects.audience.match({
          MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource,
          HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource
        }),
        props: {
          shrink: true,
          name: 'reporteraddressline2',
          label: 'Address Line 2',
          required: false,
          validate: [validators.maxLength(30, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPrimarySource.fields, _sideEffects.audience.addressValidationForPrimarySource.error))],
          maxLength: 30
        }
      }, {
        id: 'reporterstreet',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide,
          DEFAULT: _computedHidden.show
        }),
        computedValue: _sideEffects.audience.match({
          MOP: _sideEffects.audience.joinStreetNameAndAddress,
          HCP: _sideEffects.audience.joinStreetNameAndAddress
        }),
        props: {
          shrink: true,
          name: 'reporterstreet',
          label: 'Reporter street',
          required: false,
          validate: [validators.maxLength(100, 'Field')],
          maxLength: 100
        }
      }, {
        id: 'reportercity',
        field: 'Input',
        computedRequired: _sideEffects.audience.match({
          MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource,
          HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource
        }),
        props: {
          shrink: true,
          name: 'reportercity',
          label: 'Reporter city',
          required: false,
          validate: [validators.maxLength(35, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPrimarySource.fields, _sideEffects.audience.addressValidationForPrimarySource.error))],
          maxLength: 35
        }
      }, {
        id: 'reporterstate',
        field: 'Input',
        computedRequired: _sideEffects.audience.match({
          MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource,
          HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource
        }),
        props: {
          shrink: true,
          name: 'reporterstate',
          label: 'Reporter state or province',
          required: false,
          validate: [validators.maxLength(40, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPrimarySource.fields, _sideEffects.audience.addressValidationForPrimarySource.error))],
          maxLength: 40
        }
      }, {
        id: 'reporterpostcode',
        field: 'Input',
        computedRequired: _sideEffects.audience.match({
          MOP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource,
          HCP: _sideEffects.audience.allAddressFieldsAreRequiredForPrimarySource
        }),
        props: {
          shrink: true,
          name: 'reporterpostcode',
          label: 'Reporter postcode',
          required: false,
          validate: [validators.maxLength(15, 'Field'), _sideEffects.audience.validatorForHPRAndMOP(validators.isOneFieldSetComplete(_sideEffects.audience.addressValidationForPrimarySource.fields, _sideEffects.audience.addressValidationForPrimarySource.error))],
          maxLength: 15
        }
      }, {
        id: 'reportercountry',
        field: 'Dropdown',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        computedValue: _sideEffects.audience.match({
          MOP: _sideEffects.audience.computeReporterCountry,
          HCP: _sideEffects.audience.computeReporterCountry
        }),
        props: {
          shrink: true,
          name: 'reportercountry',
          label: 'Reporter country code',
          autocomplete: true,
          options: _config.countryCodesDropdownOptions,
          required: false
        }
      }, {
        id: 'qualification',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'qualification',
          label: 'Reporter qualification',
          options: [{
            value: '1',
            label: 'Physician'
          }, {
            value: '2',
            label: 'Pharmacist'
          }, {
            value: '3',
            label: 'Other Health Professional'
          }, {
            value: '4',
            label: 'Lawyer'
          }, {
            value: '5',
            label: 'Consumer or other non health professional'
          }],
          required: true,
          optionsTranslationKeyPrefix: 'Profession - '
        }
      }, {
        id: 'literaturereference',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'literaturereference',
          label: 'Literature reference(s)',
          required: false,
          validate: [validators.maxLength(500, 'Field')],
          maxLength: 500
        }
      }, {
        id: 'studyname',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        computedRequired: function computedRequired(_ref) {
          var observestudytype = _ref.observestudytype;
          return observestudytype === '1';
        },
        props: {
          shrink: true,
          name: 'studyname',
          label: 'Study name - Include EudraCT number#abbreviated study name',
          required: false,
          validate: [validators.maxLength(100, 'Field')],
          maxLength: 100
        }
      }, {
        id: 'sponsorstudynumb',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        computedRequired: function computedRequired(_ref2) {
          var observestudytype = _ref2.observestudytype;
          return observestudytype === '1';
        },
        props: {
          shrink: true,
          name: 'sponsorstudynumb',
          label: 'Sponsor study number',
          required: false,
          validate: [validators.maxLength(35, 'Field')],
          maxLength: 35
        }
      }, {
        id: 'observestudytype',
        field: 'Dropdown',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide,
          DEFAULT: function DEFAULT(values, parentFormValues) {
            return parentFormValues.reporttype !== '2';
          }
        }),
        computedRequired: function computedRequired(values, parentFormValues) {
          return parentFormValues.reporttype === '2';
        },
        props: {
          shrink: true,
          name: 'observestudytype',
          label: 'Study type',
          options: [{
            value: '1',
            label: 'Clinical trials'
          }, {
            value: '2',
            label: 'Individual patient use'
          }, {
            value: '3',
            label: 'Other studies'
          }],
          required: false
        }
      }]
    }
  }, {
    id: 'patientinitial',
    field: 'Input',
    props: {
      shrink: true,
      name: 'patientinitial',
      label: 'Patient initials',
      required: false,
      validate: [validators.maxLength(10, 'Field'), _sideEffects.audience.validatorForMOP(validators.minimumOf1(_sideEffects.audience.MOPpatientValidation.fields, _sideEffects.audience.MOPpatientValidation.error)), _sideEffects.audience.validatorForHPR(validators.minimumOf1(_sideEffects.audience.HPRpatientValidation.fields, _sideEffects.audience.HPRpatientValidation.error))],
      maxLength: 10
    }
  }, {
    id: 'patientgpmedicalrecordnumb',
    field: 'Input',
    props: {
      shrink: true,
      name: 'patientgpmedicalrecordnumb',
      label: 'Patient record number',
      required: false,
      validate: [validators.maxLength(20, 'Field'), _sideEffects.audience.validatorForHPR(validators.minimumOf1(_sideEffects.audience.HPRpatientValidation.fields, _sideEffects.audience.HPRpatientValidation.error))],
      maxLength: 20
    }
  }, {
    id: 'patientspecialistrecordnumb',
    field: 'Input',
    props: {
      shrink: true,
      name: 'patientspecialistrecordnumb',
      label: 'Patient specialist record number',
      required: false,
      validate: [validators.maxLength(20, 'Field')],
      maxLength: 20
    }
  }, {
    id: 'patientethnicity',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'patientethnicity',
      label: 'Patient ethnicity',
      required: false,
      options: _config.ethnicityOptions
    }
  }, {
    id: 'patientonsetage',
    field: 'Input',
    computedRequired: function computedRequired(values) {
      return !!values.patientonsetageunit;
    },
    computedValue: _sideEffects.general.computePatientOnsetAgeValue,
    computedHidden: (0, _computedHidden.fieldIsDefined)('usedobforpatientonsetage'),
    props: {
      shrink: true,
      name: 'patientonsetage',
      label: 'Patient age at time of onset of reaction/event',
      required: false,
      validate: [_sideEffects.audience.validatorForHPRAndMOP(validators.handle((0, _validators.checkPatientAge)({
        getDurationInYears: getDurationInYears,
        upperBoundary: 120
      }))), validators.maxNumericLength(5, 'Field'), _sideEffects.audience.validatorForMOP(validators.minimumOf1(_sideEffects.audience.MOPpatientValidation.fields, _sideEffects.audience.MOPpatientValidation.error)), _sideEffects.audience.validatorForHPR(validators.minimumOf1(_sideEffects.audience.HPRpatientValidation.fields, _sideEffects.audience.HPRpatientValidation.error))]
    }
  }, {
    id: 'patientonsetageunit',
    field: 'Dropdown',
    computedRequired: function computedRequired(values) {
      return !!values.patientonsetage;
    },
    computedValue: _sideEffects.general.computePatientOnsetAgeUnit,
    computedHidden: (0, _computedHidden.fieldIsDefined)('usedobforpatientonsetage'),
    props: {
      shrink: true,
      name: 'patientonsetageunit',
      label: 'Patient age unit',
      required: false,
      options: [{
        value: '801',
        label: 'Year'
      }, {
        value: '802',
        label: 'Month'
      }, {
        value: '803',
        label: 'Week'
      }, {
        value: '804',
        label: 'Day'
      }, {
        value: '805',
        label: 'Hour'
      }, {
        value: '800',
        label: 'Decade'
      }]
    }
  }, {
    id: 'usedobforpatientonsetage',
    field: 'Input',
    computedValue: _fp["default"].T,
    props: {
      shrink: true,
      name: 'usedobforpatientonsetage',
      label: 'Hide the onset age and Use date of birth to set it.',
      required: false,
      type: 'hidden'
    }
  }, {
    id: 'patientagegroup',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'patientagegroup',
      label: 'Patient age group',
      required: false,
      options: [{
        value: '1',
        label: 'Neonate'
      }, {
        value: '2',
        label: 'Infant'
      }, {
        value: '3',
        label: 'Child'
      }, {
        value: '4',
        label: 'Adolescent'
      }, {
        value: '5',
        label: 'Adult'
      }, {
        value: '6',
        label: 'Elderly'
      }]
    }
  }, {
    id: 'patientbirthdateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.patientbirthdate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'patientbirthdateformat',
      label: 'Patient date of birth Date Format',
      required: false
    }
  }, {
    id: 'patientbirthdate',
    field: 'Date',
    props: {
      shrink: true,
      name: 'patientbirthdate',
      label: 'Patient date of birth',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('patientbirthdateformat', dateFormats), validators.isDateOnOrAfterStart((0, _utilities.getOffsetDate)(120)), _sideEffects.audience.validatorForMOP(validators.minimumOf1(_sideEffects.audience.MOPpatientValidation.fields, _sideEffects.audience.MOPpatientValidation.error)), _sideEffects.audience.validatorForHPR(validators.minimumOf1(_sideEffects.audience.HPRpatientValidation.fields, _sideEffects.audience.HPRpatientValidation.error))],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'patientweight',
    field: 'Input',
    computedValue: function computedValue(values) {
      return values.patientweightmetric;
    },
    props: {
      shrink: true,
      name: 'patientweight',
      label: 'Patient weight',
      required: false,
      validate: [validators.numeric, validators.maxLength(6, 'Weight(kg)'), validators.isBelowUnit({
        field: 'Weight',
        limit: 500,
        unit: 'kg'
      }), validators.isNumericField({
        field: 'patientweightimperial.Stone'
      }), validators.isNumericField({
        field: 'patientweightimperial.Pounds'
      })],
      imperialToggle: {
        tabLabels: ['KG', 'LB'],
        initial: {
          value: 'KG',
          label: 'Kilograms'
        },
        formatFieldName: 'patientweightformat',
        metricFieldName: 'patientweightmetric',
        imperialFieldName: 'patientweightimperial',
        imperialOptions: ['Stone', 'Pounds']
      }
    }
  }, {
    id: 'patientweightformat',
    field: 'Input',
    props: {
      shrink: true,
      name: 'patientweightformat',
      label: 'Patient weight format',
      required: true,
      type: 'hidden'
    }
  }, {
    id: 'patientweightimperial',
    field: 'Input',
    computedValue: function computedValue(values) {
      return _imperialToggle.weight.imperialWeight({
        values: values
      });
    },
    props: {
      shrink: true,
      name: 'patientweightimperial',
      label: 'Patient weight imperial',
      required: true,
      type: 'hidden'
    }
  }, {
    id: 'patientweightmetric',
    field: 'Input',
    computedValue: function computedValue(values) {
      return _imperialToggle.weight.metricWeight({
        values: values
      });
    },
    props: {
      shrink: true,
      name: 'patientweightmetric',
      label: 'Patient weight metric',
      required: true,
      type: 'hidden'
    }
  }, {
    id: 'patientheight',
    field: 'Input',
    computedValue: function computedValue(values) {
      return values.patientheightmetric;
    },
    props: {
      shrink: true,
      name: 'patientheight',
      label: 'Patient height',
      required: false,
      validate: [validators.numeric, validators.maxLength(3, 'Height(cm)'), validators.isBelowUnit({
        field: 'Height',
        limit: 300,
        unit: 'cm'
      }), validators.isNumericField({
        field: 'patientheightimperial.Feet'
      }), validators.isNumericField({
        field: 'patientheightimperial.Inches'
      })],
      imperialToggle: {
        tabLabels: ['CM', 'FT'],
        initial: {
          value: 'CM',
          label: 'Centimetres'
        },
        formatFieldName: 'patientheightformat',
        metricFieldName: 'patientheightmetric',
        imperialFieldName: 'patientheightimperial',
        imperialOptions: ['Feet', 'Inches']
      }
    }
  }, {
    id: 'patientheightformat',
    field: 'Input',
    props: {
      shrink: true,
      name: 'patientheightformat',
      label: 'Patient height format',
      required: true,
      type: 'hidden'
    }
  }, {
    id: 'patientheightimperial',
    field: 'Input',
    computedValue: function computedValue(values) {
      return _imperialToggle.height.imperialHeight({
        values: values
      });
    },
    props: {
      shrink: true,
      name: 'patientheightimperial',
      label: 'Patient height imperial',
      required: true,
      type: 'hidden'
    }
  }, {
    id: 'patientheightmetric',
    field: 'Input',
    computedValue: function computedValue(values) {
      return _imperialToggle.height.metricHeight({
        values: values
      });
    },
    props: {
      shrink: true,
      name: 'patientheightmetric',
      label: 'Patient height metric',
      required: true,
      type: 'hidden'
    }
  }, {
    id: 'patientsex',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'patientsex',
      label: 'Patient sex',
      required: false,
      options: [{
        value: '1',
        label: 'Male'
      }, {
        value: '2',
        label: 'Female'
      }],
      validate: [_sideEffects.audience.validatorForMOP(validators.minimumOf1(_sideEffects.audience.MOPpatientValidation.fields, _sideEffects.audience.MOPpatientValidation.error)), _sideEffects.audience.validatorForHPR(validators.minimumOf1(_sideEffects.audience.HPRpatientValidation.fields, _sideEffects.audience.HPRpatientValidation.error))]
    }
  }, {
    id: 'gestationperiod',
    field: 'Input',
    computedHidden: function computedHidden(values) {
      return values.patientsex !== '2';
    },
    props: {
      hideIfEmpty: true,
      shrink: true,
      name: 'gestationperiod',
      label: 'Gestation period',
      required: false,
      validate: [validators.maxNumericLength(3, 'Field')]
    }
  }, {
    id: 'gestationperiodunit',
    field: 'Dropdown',
    computedHidden: function computedHidden(values) {
      return values.patientsex !== '2';
    },
    props: {
      shrink: true,
      hideIfEmpty: true,
      name: 'gestationperiodunit',
      label: 'Gestation period unit',
      required: false,
      options: [{
        value: '802',
        label: 'Month'
      }, {
        value: '803',
        label: 'Week'
      }, {
        value: '804',
        label: 'Day'
      }, {
        value: '810',
        label: 'Trimester'
      }]
    }
  }, {
    id: 'lastmenstrualdateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.patientlastmenstrualdate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'lastmenstrualdateformat',
      label: 'Last menstrual period date format',
      required: true
    }
  }, {
    id: 'patientlastmenstrualdate',
    field: 'Date',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.pregnancy.canPatientMenstruate({
      getDurationInYears: getDurationInYears,
      timeMeasurements: timeMeasurements
    })),
    props: {
      shrink: true,
      hideIfEmpty: true,
      name: 'patientlastmenstrualdate',
      label: 'Last menstrual period date',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.dateTimeNotInFuture('lastmenstrualdateformat', dateFormats)],
      helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
    }
  }, {
    id: 'medicalhistoryepisode',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'medicalhistoryepisode',
      label: 'Medical history episode',
      repeatableLabelPlaceholder: 'Episode',
      repeatableLabel: ['patientepisodename'],
      required: false,
      schema: [{
        id: 'patientepisodenamemeddraversion',
        field: 'Input',
        computedValue: function computedValue(values, parentValues, orgDetails) {
          return orgDetails.meddraVersion;
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientepisodenamemeddraversion',
          label: 'Patient medical history MedDRA Version',
          required: true
        }
      }, {
        id: 'patientepisodename',
        field: 'MedDRALookupInput',
        props: {
          shrink: true,
          name: 'patientepisodename',
          label: 'Patient medical history',
          required: false,
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250
        }
      }, {
        id: 'patientmedicalstartdateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.patientmedicalstartdate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientmedicalstartdateformat',
          label: 'Patient medical history start date format',
          required: true
        }
      }, {
        id: 'patientmedicalstartdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'patientmedicalstartdate',
          label: 'Patient medical history start date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.dateTimeNotInFuture('patientmedicalstartdateformat', dateFormats), validators.isDateBeforeOrEqual('patientmedicalenddate', 'Patient medical history end date')],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
          startYear: 1920
        }
      }, {
        id: 'patientmedicalcontinue',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientmedicalcontinue',
          label: 'Patient medical history continuing?',
          required: false,
          options: [{
            value: '1',
            label: 'Yes'
          }, {
            value: '2',
            label: 'No'
          }, {
            value: '3',
            label: 'Unknown'
          }]
        }
      }, {
        id: 'patientmedicalenddateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.patientmedicalenddate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientmedicalenddateformat',
          label: 'Patient medical history end date format',
          required: true
        }
      }, {
        id: 'patientmedicalenddate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'patientmedicalenddate',
          label: 'Patient medical history end date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.dateTimeNotInFuture('patientmedicalenddateformat', dateFormats), validators.isDateAfterOrEqual('patientmedicalstartdate', 'Patient medical history start date')],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
          startYear: 1920
        }
      }, {
        id: 'patientmedicalcomment',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientmedicalcomment',
          label: 'Patient medical history comment',
          validate: [validators.maxLength(100, 'Field')],
          maxLength: 100,
          required: false
        }
      }]
    }
  }, {
    id: 'patientmedicalhistorytext',
    field: 'Input',
    props: {
      shrink: true,
      name: 'patientmedicalhistorytext',
      label: 'Patient medical history text',
      validate: [validators.maxLength(10000, 'Field')],
      maxLength: 10000,
      required: false,
      multiline: true
    }
  }, {
    id: 'patientdiagnosedOrTreated',
    field: 'Dropdown',
    computedValue: _sideEffects.general.computeLegacyOptionPatientDiagnosedOrTreated,
    props: {
      shrink: true,
      name: 'patientdiagnosedOrTreated',
      label: 'We would like to know if you have any illnesses, or are taking any medicines, that might affect your immune response. Please can you review the below list and select any that apply to you',
      required: false,
      multiple: true,
      options: _config.patientDiagnosedOptions
    }
  }, {
    id: 'patientpastdrugtherapy',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'patientpastdrugtherapy',
      label: 'Patient past drug therapy',
      repeatableLabelPlaceholder: 'Therapy',
      repeatableLabel: ['patientdrugname'],
      required: false,
      schema: [{
        id: 'patientdrugname',
        field: 'DrugLookupInput',
        computedRequired: function computedRequired(values) {
          return !!values.patientdrugstartdate || !!values.patientdrugenddate;
        },
        props: {
          shrink: true,
          name: 'patientdrugname',
          label: 'Past drug name',
          validate: [validators.maxLength(100, 'Field')],
          maxLength: 100
        }
      }, {
        id: 'patientdrugstartdateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.patientdrugstartdate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientdrugstartdateformat',
          label: 'Past drug start date format',
          required: true
        }
      }, {
        id: 'patientdrugstartdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'patientdrugstartdate',
          label: 'Past drug start date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.dateTimeNotInFuture('patientdrugstartdateformat', dateFormats), validators.isDateBeforeOrEqual('patientdrugenddate', 'Past drug end date')],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
          startYear: 1920
        }
      }, {
        id: 'patientdrugenddateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.patientdrugenddate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientdrugenddateformat',
          label: 'Past drug end date format',
          required: true
        }
      }, {
        id: 'patientdrugenddate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'patientdrugenddate',
          label: 'Past drug end date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.dateTimeNotInFuture('patientdrugenddateformat', dateFormats), validators.isDateAfterOrEqual('patientdrugstartdate', 'Past drug start date')],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
          startYear: 1920
        }
      }, {
        id: 'patientindicationmeddraversion',
        field: 'Input',
        computedValue: function computedValue(values, parentValues, orgDetails) {
          return orgDetails.meddraVersion;
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientindicationmeddraversion',
          label: 'Past drug indication MedDRA Version',
          required: true
        }
      }, {
        id: 'patientdrugindication',
        field: 'MedDRALookupInput',
        props: {
          shrink: true,
          name: 'patientdrugindication',
          label: 'Past drug indication',
          required: false,
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250
        }
      }, {
        id: 'patientdrgreactionmeddraversion',
        field: 'Input',
        computedValue: function computedValue(values, parentValues, orgDetails) {
          return orgDetails.meddraVersion;
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientdrgreactionmeddraversion',
          label: 'Past drug reaction MedDRA Version',
          required: true
        }
      }, {
        id: 'patientdrugreaction',
        field: 'MedDRALookupInput',
        props: {
          shrink: true,
          name: 'patientdrugreaction',
          label: 'Past drug reaction',
          required: false,
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250
        }
      }]
    }
  }, {
    id: 'patientdeathdateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.patientdeathdate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'patientdeathdateformat',
      label: 'Date of death format'
    }
  }, {
    id: 'patientdeathdate',
    field: 'Date',
    computedHidden: _sideEffects.audience.match({
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.serious.isFatal),
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.serious.isFatal),
      DEFAULT: _computedHidden.show
    }),
    computedValue: _sideEffects.audience.match({
      HCP: _sideEffects.serious.clearIfReactionWasNotFatal,
      MOP: _sideEffects.serious.clearIfReactionWasNotFatal,
      DEFAULT: computedValue.noop
    }),
    props: {
      shrink: true,
      name: 'patientdeathdate',
      label: 'Date of death',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.dateTimeNotInFuture('patientdeathdateformat', dateFormats), validators.isDateAfterOrEqual('reaction', 'Reaction start date', 'reactionstartdate'), validators.isDateAfterOrEqual('reaction', 'Reaction end date', 'reactionenddate'), validators.isDateAfterOrEqual('drug', 'Drug start date', 'drugstartdate'), validators.isDateAfterOrEqual('drug', 'Drug end date', 'drugenddate')],
      helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
      startYear: 1920
    }
  }, {
    id: 'patientdeathcause',
    field: 'Repeatable',
    computedEditable: function computedEditable(values) {
      return !!values.patientdeathdate;
    },
    computedRequired: function computedRequired(values) {
      return !!values.patientdeathdate;
    },
    props: {
      shrink: true,
      label: 'Cause of death',
      name: 'patientdeathcause',
      hideIfEmpty: true,
      required: false,
      repeatableLabelPlaceholder: 'Cause of death',
      repeatableLabel: ['patientdeathreport'],
      schema: [{
        id: 'patientdeathreportmeddraversion',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        computedValue: function computedValue(values, parentValues, orgDetails) {
          return orgDetails.meddraVersion;
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientdeathreportmeddraversion',
          label: 'Reported cause of death MedDRA Version',
          required: true
        }
      }, {
        id: 'patientdeathreport',
        field: 'MedDRALookupInput',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          required: false,
          shrink: true,
          name: 'patientdeathreport',
          label: 'Reported cause of death',
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250
        }
      }]
    }
  }, {
    id: 'patientautopsyyesno',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      HCP: (0, _computedHidden.visibleIf)(_sideEffects.serious.isFatal),
      MOP: (0, _computedHidden.visibleIf)(_sideEffects.serious.isFatal),
      DEFAULT: _computedHidden.show
    }),
    computedRequired: function computedRequired(values) {
      return !!values.patientdeathdate;
    },
    computedValue: _sideEffects.audience.match({
      HCP: _sideEffects.serious.clearIfReactionWasNotFatal,
      MOP: _sideEffects.serious.clearIfReactionWasNotFatal,
      DEFAULT: computedValue.noop
    }),
    props: {
      shrink: true,
      name: 'patientautopsyyesno',
      label: 'Was autopsy done?',
      required: false,
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }, {
        value: '3',
        label: 'Unknown'
      }]
    }
  }, {
    id: 'patientautopsy',
    field: 'Repeatable',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    computedEditable: function computedEditable(values) {
      return values.patientautopsyyesno === '1';
    },
    props: {
      shrink: true,
      name: 'patientautopsy',
      hideIfEmpty: true,
      required: true,
      label: 'Patient Autopsy',
      repeatableLabelPlaceholder: 'Patient Autopsy',
      repeatableLabel: ['patientdetermineautopsy'],
      schema: [{
        id: 'patientdetermautopsmeddraversion',
        field: 'Input',
        computedValue: function computedValue(values, parentValues, orgDetails) {
          return orgDetails.meddraVersion;
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientdetermautopsmeddraversion',
          label: 'Autopsy-determined cause of death MedDRA Version',
          required: true
        }
      }, {
        id: 'patientdetermineautopsy',
        field: 'MedDRALookupInput',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'patientdetermineautopsy',
          label: 'Autopsy-determined cause of death',
          required: false,
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250
        }
      }]
    }
  }, {
    id: 'parentidentification',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'parentidentification',
      label: 'Parent initials',
      validate: [validators.maxLength(10, 'Field')],
      maxLength: 10
    }
  }, {
    id: 'parentage',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'parentage',
      label: 'Parent age (years)',
      validate: [validators.maxNumericLength(2, 'Field')]
    }
  }, {
    id: 'parentageunit',
    field: 'Input',
    computedValue: function computedValue(values) {
      return values.parentage ? '801' : null;
    },
    props: {
      shrink: true,
      name: 'parentageunit',
      label: 'Parent age unit',
      type: 'hidden'
    }
  }, {
    id: 'parentlastmenstrualdateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.parentlastmenstrualdate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'parentlastmenstrualdateformat',
      label: 'Parent last menstrual period date format',
      required: true
    }
  }, {
    id: 'parentweight',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'parentweight',
      label: 'Parent weight (kg)',
      validate: [validators.maxNumericLength(6, 'Field')]
    }
  }, {
    id: 'parentheight',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'parentheight',
      label: 'Parent height (cm)',
      validate: [validators.maxNumericLength(3, 'Field')]
    }
  }, {
    id: 'parentsex',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'parentsex',
      label: 'Parent sex',
      required: false,
      options: [{
        value: '1',
        label: 'Male'
      }, {
        value: '2',
        label: 'Female'
      }]
    }
  }, {
    id: 'parentlastmenstrualdate',
    field: 'Date',
    computedEditable: function computedEditable(values) {
      return values.parentsex === '2';
    },
    props: {
      hideIfEmpty: true,
      shrink: true,
      name: 'parentlastmenstrualdate',
      label: 'Parent last menstrual period date',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'parentmedicalhistoryepisode',
    field: 'Repeatable',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'parentmedicalhistoryepisode',
      label: 'Parent medical history episode',
      repeatableLabelPlaceholder: 'Medical history episode',
      repeatableLabel: ['parentmedicalepisodename'],
      required: false,
      schema: [{
        id: 'parentmdepisodemeddraversion',
        field: 'Input',
        computedValue: function computedValue(values, parentValues, orgDetails) {
          return orgDetails.meddraVersion;
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'parentmdepisodemeddraversion',
          label: 'Parent medical history MedDRA Version',
          required: true
        }
      }, {
        id: 'parentmedicalepisodename',
        field: 'MedDRALookupInput',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'parentmedicalepisodename',
          label: 'Parent medical history',
          required: false,
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250
        }
      }, {
        id: 'parentmedicalstartdateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.parentmedicalstartdate);
        },
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'parentmedicalstartdateformat',
          label: 'Parent medical history start date format',
          required: true
        }
      }, {
        id: 'parentmedicalstartdate',
        field: 'Date',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'parentmedicalstartdate',
          label: 'Parent medical history start date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.isDateBeforeOrEqual('parentmedicalenddate', 'Parent medical history end date')],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
        }
      }, {
        id: 'parentmedicalcontinue',
        field: 'Dropdown',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'parentmedicalcontinue',
          label: 'Parent medical history continuing?',
          required: false,
          options: [{
            value: '1',
            label: 'Yes'
          }, {
            value: '2',
            label: 'No'
          }, {
            value: '3',
            label: 'Unknown'
          }]
        }
      }, {
        id: 'parentmedicalenddateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.parentmedicalenddate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'parentmedicalenddateformat',
          label: 'Parent medical history end date format',
          required: true
        }
      }, {
        id: 'parentmedicalenddate',
        field: 'Date',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'parentmedicalenddate',
          label: 'Parent medical history end date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.isDateAfterOrEqual('parentmedicalstartdate', 'Parent medical history start date')],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
        }
      }]
    }
  }, {
    id: 'parentmedicalrelevanttext',
    field: 'Input',
    computedHidden: _sideEffects.audience.match({
      MOP: _computedHidden.hide,
      HCP: _computedHidden.hide
    }),
    props: {
      shrink: true,
      name: 'parentmedicalrelevanttext',
      label: 'Parent medical history relevant text',
      validate: [validators.maxLength(10000, 'Field')],
      maxLength: 10000
    }
  }, {
    id: 'hasexperiencedreaction',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasTakenVaccine),
    computedValue: _sideEffects.covid19.computeNoIfVaccineTaken,
    props: {
      noNullOption: true,
      shrink: true,
      required: true,
      defaultValue: '2',
      name: 'hasexperiencedreaction',
      label: 'Have you experienced any side effects?',
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }]
    }
  }, {
    id: 'adverseEventsExperienced',
    field: 'Dropdown',
    computedRequired: _sideEffects.aefi.hasNotOtherReactionBeenSubmitted,
    computedHidden: _sideEffects.general.hasNotExperiencedReaction,
    computedValue: _sideEffects.general.clearIfNotExperiencedReaction,
    props: {
      shrink: true,
      name: 'adverseEventsExperienced',
      label: 'Adverse Event(s)',
      required: false,
      multiple: true,
      options: _sideEffects.aefi.aefiReactionOptions
    }
  }, {
    id: 'adverseEventsFirstTime',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.aefi.hasHadAdverseEvent),
    computedValue: _sideEffects.aefi.clearIfNoAdverseEvent,
    computedRequired: (0, _computedHidden.fieldIsDefined)('adverseEventsFirstTimeUnit'),
    props: {
      shrink: true,
      name: 'adverseEventsFirstTime',
      label: 'Time interval between beginning of suspect drug administration and start of reaction/event',
      validate: [validators.maxNumericLength(5, 'Field')],
      required: false
    }
  }, {
    id: 'adverseEventsFirstTimeUnit',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.aefi.hasHadAdverseEvent),
    computedValue: _sideEffects.aefi.clearIfNoAdverseEvent,
    computedRequired: (0, _computedHidden.fieldIsDefined)('adverseEventsFirstTime'),
    props: {
      shrink: true,
      name: 'adverseEventsFirstTimeUnit',
      label: 'Time interval between beginning of suspect drug administration and start of reaction/event time interval unit',
      required: false,
      options: [{
        value: '801',
        label: 'Year'
      }, {
        value: '802',
        label: 'Month'
      }, {
        value: '803',
        label: 'Week'
      }, {
        value: '804',
        label: 'Day'
      }, {
        value: '805',
        label: 'Hour'
      }, {
        value: '806',
        label: 'Minute'
      }, {
        value: '807',
        label: 'Second'
      }]
    }
  }, {
    id: 'adverseEventsStartDateFormat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.adverseEventsStartDate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'adverseEventsStartDate',
      label: 'Adverse Events Start Date Format',
      required: false
    }
  }, {
    id: 'adverseEventsStartDate',
    field: 'Date',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.aefi.hasHadAdverseEvent),
    computedValue: _sideEffects.aefi.clearIfNoAdverseEvent,
    computedHelperText: _sideEffects.covid19.computeHelperDateText,
    props: {
      shrink: true,
      name: 'adverseEventsStartDate',
      label: 'Date the adverse events started',
      required: false,
      validate: [_sideEffects.covid19.computePartialDateValidation(validators), validators.dateTimeNotInFuture('adverseEventsStartDateFormat', dateFormats), validators.isDateBeforeOrEqual('reactionenddate', 'Reaction end date'), validators.isDateAfterOrEqual('patientbirthdate', 'Patient date of birth'), validators.isDateBeforeOrEqual('transmissiondate', 'Date of this transmission'), validators.isDateBeforeOrEqual('receivedate', 'Date report was first received from source'), validators.isDateBeforeOrEqual('receiptdate', 'Date of receipt of the most recent information for this report'), validators.isDateBeforeOrEqual('patientdeathdate', 'Date of death'), validators.isDateAfterOrEqual('drug', 'Drug Start Date', 'drugstartdate')]
    }
  }, {
    id: 'adverseEventsOutcome',
    field: 'Dropdown',
    computedOptions: _sideEffects.covid19.computeReactionOutcomeOptions,
    computedRequired: _sideEffects.aefi.hasHadAdverseEvent,
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.aefi.hasHadAdverseEvent),
    computedValue: _sideEffects.aefi.clearIfNoAdverseEvent,
    props: {
      shrink: true,
      name: 'adverseEventsOutcome',
      label: 'Adverse Event Outcome'
    }
  }, {
    id: 'reaction',
    field: 'Repeatable',
    computedValue: _sideEffects.general.clearIfNotExperiencedReaction,
    computedHidden: _sideEffects.general.hasNotExperiencedReaction,
    computedRequired: _sideEffects.general.computeReactionRequired,
    props: {
      name: 'reaction',
      shrink: true,
      label: 'Reaction',
      repeatableLabelPlaceholder: 'Reaction',
      repeatableLabel: ['primarysourcereaction', 'reactionmeddrallt'],
      schema: [{
        id: 'primarysourcereaction',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          HCP: _computedHidden.hide,
          MOP: _computedHidden.hide
        }),
        computedValue: _sideEffects.audience.match({
          HCP: _fp["default"].get('reactionmeddrallt'),
          MOP: _fp["default"].get('reactionmeddrallt')
        }),
        props: {
          shrink: true,
          name: 'primarysourcereaction',
          label: 'Reaction as reported by primary source',
          required: true,
          validate: [validators.maxLength(200, 'Field')],
          maxLength: 200,
          multiline: true
        }
      }, {
        id: 'reactionmeddraversionllt',
        field: 'Input',
        computedValue: function computedValue(values, parentValues, orgDetails) {
          return orgDetails.meddraVersion;
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'reactionmeddraversionllt',
          label: 'Reaction (MedDRA LLT) MedDRA Version',
          required: true
        }
      }, {
        id: 'reactionmeddrallt',
        field: 'MedDRALookupInput',
        props: {
          shrink: true,
          name: 'reactionmeddrallt',
          label: 'Reaction (MedDRA LLT)',
          required: true,
          validate: [validators.maxLength(200, 'Field')],
          maxLength: 200
        }
      }, {
        id: 'reactionstartdateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.reactionstartdate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'reactionstartdateformat',
          label: 'Reaction start date format',
          required: true
        }
      }, {
        id: 'reactionstartdate',
        field: 'Date',
        computedHelperText: _sideEffects.covid19.computeHelperDateText,
        props: {
          shrink: true,
          name: 'reactionstartdate',
          label: 'Reaction start date',
          required: false,
          validate: [_sideEffects.covid19.computePartialDateValidation(validators), validators.dateTimeNotInFuture('reactionstartdateformat', dateFormats), validators.isDateBeforeOrEqual('reactionenddate', 'Reaction end date'), validators.isDateAfterOrEqual('_parentFormValues.patientbirthdate', 'Patient date of birth'), validators.isDateBeforeOrEqual('_parentFormValues.transmissiondate', 'Date of this transmission'), validators.isDateBeforeOrEqual('_parentFormValues.receivedate', 'Date report was first received from source'), validators.isDateBeforeOrEqual('_parentFormValues.patientdeathdate', 'Date of death'), validators.isDateAfterOrEqual('_parentFormValues.drug', 'Drug Start Date', 'drugstartdate')],
          startYear: 1920
        }
      }, {
        id: 'reactionenddateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.reactionenddate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'reactionenddateformat',
          label: 'Reaction end date format',
          required: true
        }
      }, {
        id: 'reactionenddate',
        field: 'Date',
        computedHelperText: _sideEffects.covid19.computeHelperDateText,
        props: {
          shrink: true,
          name: 'reactionenddate',
          label: 'Reaction end date',
          required: false,
          validate: [_sideEffects.covid19.computePartialDateValidation(validators), validators.dateTimeNotInFuture('reactionenddateformat', dateFormats), validators.isDateAfterOrEqual('reactionstartdate', 'Reaction start date'), validators.isDateBeforeOrEqual('_parentFormValues.patientdeathdate', 'Date of death'), validators.isDateAfterOrEqual('_parentFormValues.drug', 'Drug Start Date', 'drugstartdate')],
          startYear: 1920
        }
      }, {
        id: 'reactionfirsttime',
        field: 'Input',
        computedRequired: (0, _computedHidden.fieldIsDefined)('reactionfirsttimeunit'),
        props: {
          shrink: true,
          name: 'reactionfirsttime',
          label: 'Time interval between beginning of suspect drug administration and start of reaction/event',
          required: false,
          validate: [validators.maxNumericLength(5, 'Field')]
        }
      }, {
        id: 'reactionfirsttimeunit',
        field: 'Dropdown',
        computedRequired: (0, _computedHidden.fieldIsDefined)('reactionfirsttime'),
        props: {
          shrink: true,
          name: 'reactionfirsttimeunit',
          label: 'Time interval between beginning of suspect drug administration and start of reaction/event time interval unit',
          required: false,
          options: [{
            value: '801',
            label: 'Year'
          }, {
            value: '802',
            label: 'Month'
          }, {
            value: '803',
            label: 'Week'
          }, {
            value: '804',
            label: 'Day'
          }, {
            value: '805',
            label: 'Hour'
          }, {
            value: '806',
            label: 'Minute'
          }, {
            value: '807',
            label: 'Second'
          }]
        }
      }, {
        id: 'reactionoutcome',
        field: 'Dropdown',
        computedOptions: _sideEffects.covid19.computeReactionOutcomeOptions,
        props: {
          shrink: true,
          name: 'reactionoutcome',
          label: 'Reaction outcome',
          required: true
        }
      }]
    }
  }, {
    id: 'serious',
    field: 'Dropdown',
    computedHidden: _sideEffects.audience.match({
      MOP: _sideEffects.general.hasNotExperiencedReaction,
      HCP: _sideEffects.general.hasNotExperiencedReaction,
      DEFAULT: _computedHidden.show
    }),
    computedValue: _sideEffects.audience.match({
      MOP: _sideEffects.general.clearIfNotExperiencedReactionOrUndefined,
      HCP: _sideEffects.general.clearIfNotExperiencedReactionOrUndefined
    }),
    props: {
      noNullOption: true,
      shrink: true,
      required: true,
      name: 'serious',
      label: 'Do you consider the reaction to be serious?',
      defaultValue: null,
      options: (0, _options.mapValuesAt1)(['Yes', 'No'])
    }
  }, {
    id: 'seriousnessdeath',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.serious.reactionWasExperiencedAndSerious),
    computedValue: _sideEffects.serious.isSeriousDeath,
    props: {
      disabled: true,
      noNullOption: true,
      disabledMessage: 'You must select a fatal outcome in a reaction',
      shrink: true,
      name: 'seriousnessdeath',
      label: 'Results in death',
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox']
    }
  }, {
    id: 'seriousnesslifethreatening',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.serious.reactionWasExperiencedAndSerious),
    computedValue: _sideEffects.serious.clearIfReactionWasNotSerious,
    props: {
      noNullOption: true,
      shrink: true,
      name: 'seriousnesslifethreatening',
      label: 'Life threatening',
      validate: [validators.handle(_sideEffects.serious.checkOneSeriousnessIsYes)],
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox']
    }
  }, {
    id: 'seriousnesshospitalization',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.serious.reactionWasExperiencedAndSerious),
    computedValue: _sideEffects.serious.clearIfReactionWasNotSerious,
    props: {
      noNullOption: true,
      shrink: true,
      name: 'seriousnesshospitalization',
      label: 'Caused/prolonged hospitalization',
      validate: [validators.handle(_sideEffects.serious.checkOneSeriousnessIsYes)],
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox']
    }
  }, {
    id: 'seriousnessdisabling',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.serious.reactionWasExperiencedAndSerious),
    computedValue: _sideEffects.serious.clearIfReactionWasNotSerious,
    props: {
      noNullOption: true,
      shrink: true,
      name: 'seriousnessdisabling',
      label: 'Disabling/Incapacitating',
      validate: [validators.handle(_sideEffects.serious.checkOneSeriousnessIsYes)],
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox']
    }
  }, {
    id: 'seriousnessaffectlifestyle',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.serious.reactionWasExperiencedAndSerious),
    computedValue: _sideEffects.serious.clearIfReactionWasNotSerious,
    props: {
      noNullOption: true,
      shrink: true,
      name: 'seriousnessaffectlifestyle',
      label: 'Caused significant or long term incapacity',
      validate: [validators.handle(_sideEffects.serious.checkOneSeriousnessIsYes)],
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox']
    }
  }, {
    id: 'seriousnessuncomfortable',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.serious.reactionWasExperiencedAndSerious),
    computedValue: _sideEffects.serious.clearIfReactionWasNotSerious,
    props: {
      noNullOption: true,
      shrink: true,
      name: 'seriousnessuncomfortable',
      label: 'Uncomfortable, a nuisance or irritation',
      validate: [validators.handle(_sideEffects.serious.checkOneSeriousnessIsYes)],
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox']
    }
  }, {
    id: 'seriousnessmild',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.serious.reactionWasExperiencedAndSerious),
    computedValue: _sideEffects.serious.clearIfReactionWasNotSerious,
    props: {
      noNullOption: true,
      shrink: true,
      name: 'seriousnessmild',
      label: 'Mild or slightly uncomfortable',
      validate: [validators.handle(_sideEffects.serious.checkOneSeriousnessIsYes)],
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox']
    }
  }, {
    id: 'seriousnesscongenitalanomali',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.serious.reactionWasExperiencedAndSerious),
    computedValue: _sideEffects.serious.clearIfReactionWasNotSerious,
    props: {
      noNullOption: true,
      shrink: true,
      name: 'seriousnesscongenitalanomali',
      label: 'Congenital anomaly/birth defect',
      validate: [validators.handle(_sideEffects.serious.checkOneSeriousnessIsYes)],
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox']
    }
  }, {
    id: 'seriousnessother',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.serious.reactionWasExperiencedAndSerious),
    computedValue: _sideEffects.serious.clearIfReactionWasNotSerious,
    props: {
      noNullOption: true,
      shrink: true,
      name: 'seriousnessother',
      label: 'Other medically important',
      validate: [validators.handle(_sideEffects.serious.checkOneSeriousnessIsYes)],
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox']
    }
  }, {
    id: 'seriousnessnotserious',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.serious.reactionWasExperiencedAndSerious),
    computedValue: _sideEffects.serious.clearIfReactionWasNotSerious,
    props: {
      noNullOption: true,
      shrink: true,
      name: 'seriousnessnotserious',
      label: 'Not serious',
      validate: [validators.handle(_sideEffects.serious.checkOneSeriousnessIsYes)],
      defaultValue: null,
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }],
      supportedFieldTypes: ['Checkbox']
    }
  }, {
    id: 'relateToThromboticEvents',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'relateToThromboticEvents',
      label: 'Does your report relate to thrombotic events?',
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }]
    }
  }, {
    id: 'relateToMyocarditis',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'relateToMyocarditis',
      label: 'Does your report relate to Myocarditis?',
      options: [{
        value: '1',
        label: 'Yes'
      }, {
        value: '2',
        label: 'No'
      }]
    }
  }, {
    id: 'drug',
    field: 'Repeatable',
    computedHidden: _sideEffects.covid19.computeVisibleDrugsSection,
    props: {
      shrink: true,
      name: 'drug',
      label: 'Drugs',
      formId: 'drugrepeatable',
      repeatableLabelPlaceholder: 'Drug',
      repeatableLabel: ['medicinalproduct', 'drugcharacterization'],
      required: true,
      schema: [{
        id: 'drugcharacterization',
        field: 'Dropdown',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        computedValue: _sideEffects.audience.match({
          MOP: computedValue.always('1'),
          HCP: computedValue.always('1')
        }),
        props: {
          shrink: true,
          name: 'drugcharacterization',
          label: 'Drug characterisation',
          required: true,
          options: [{
            value: '1',
            label: 'Suspect'
          }, {
            value: '2',
            label: 'Concomitant'
          }, {
            value: '3',
            label: 'Interacting'
          }]
        }
      }, {
        id: 'medicinalproduct',
        field: 'DrugLookupInput',
        props: {
          shrink: true,
          name: 'medicinalproduct',
          label: 'Medicinal product',
          required: true,
          validate: [validators.maxLength(70, 'Field')],
          maxLength: 70
        }
      }, {
        id: 'activesubstancename',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        computedValue: _sideEffects.audience.match({
          MOP: _fp["default"].get('medicinalproduct'),
          HCP: _fp["default"].get('medicinalproduct')
        }),
        props: {
          shrink: true,
          name: 'activesubstancename',
          label: 'Active substance',
          required: false,
          validate: [validators.maxLength(100, 'Field')],
          maxLength: 100
        }
      }, {
        id: 'obtaindrugcountry',
        field: 'Dropdown',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          autocomplete: true,
          options: _config.countryCodesDropdownOptions,
          name: 'obtaindrugcountry',
          label: 'Country where drug was obtained',
          required: false
        }
      }, {
        id: 'drugbatchnumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'drugbatchnumb',
          label: 'Batch/Lot number',
          required: false,
          validate: [validators.maxLength(35, 'Field')],
          maxLength: 35
        }
      }, {
        id: 'drugauthorizationholder',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugauthorizationholder',
          label: 'Drug authorisation holder',
          required: false,
          validate: [validators.maxLength(60, 'Field')],
          maxLength: 60
        }
      }, {
        id: 'drugstructuredosagenumb',
        field: 'Input',
        computedRequired: function computedRequired(_ref3) {
          var drugcharacterization = _ref3.drugcharacterization,
              drugstructuredosageunit = _ref3.drugstructuredosageunit;
          return !!drugcharacterization && !!drugstructuredosageunit;
        },
        props: {
          shrink: true,
          name: 'drugstructuredosagenumb',
          label: 'Dose (number)',
          required: false,
          validate: [validators.decimal, validators.maxDecimalLength(8, 'Field')]
        }
      }, {
        id: 'drugstructuredosageunit',
        field: 'Dropdown',
        computedRequired: function computedRequired(_ref4) {
          var drugstructuredosagenumb = _ref4.drugstructuredosagenumb;
          return !!drugstructuredosagenumb;
        },
        props: {
          shrink: true,
          name: 'drugstructuredosageunit',
          label: 'Dose (unit)',
          required: false,
          validate: [validators.maxNumericLength(3, 'Field')],
          options: [{
            value: '001',
            label: 'kg kilogram(s)'
          }, {
            value: '002',
            label: 'G gram(s)'
          }, {
            value: '003',
            label: 'Mg milligram(s)'
          }, {
            value: '004',
            label: 'µg microgram(s)'
          }, {
            value: '005',
            label: 'ng nanogram(s)'
          }, {
            value: '006',
            label: 'pg picogram(s)'
          }, {
            value: '007',
            label: 'mg/kg milligram(s)/kilogram'
          }, {
            value: '008',
            label: 'µg/kg microgram(s)/kilogram'
          }, {
            value: '009',
            label: 'mg/m 2 milligram(s)/sq. meter'
          }, {
            value: '010',
            label: 'µg/ m 2 microgram(s)/ sq. Meter'
          }, {
            value: '011',
            label: 'l litre(s)'
          }, {
            value: '012',
            label: 'ml millilitre(s)'
          }, {
            value: '013',
            label: 'µl microlitre(s)'
          }, {
            value: '014',
            label: 'Bq becquerel(s)'
          }, {
            value: '015',
            label: 'GBq gigabecquerel(s)'
          }, {
            value: '016',
            label: 'MBq megabecquerel(s)'
          }, {
            value: '017',
            label: 'Kbq kilobecquerel(s)'
          }, {
            value: '018',
            label: 'Ci curie(s)'
          }, {
            value: '019',
            label: 'MCi millicurie(s)'
          }, {
            value: '020',
            label: 'µCi microcurie(s)'
          }, {
            value: '021',
            label: 'NCi nanocurie(s)'
          }, {
            value: '022',
            label: 'Mol mole(s)'
          }, {
            value: '023',
            label: 'Mmol millimole(s)'
          }, {
            value: '024',
            label: 'µmol micromole(s)'
          }, {
            value: '025',
            label: 'Iu international unit(s)'
          }, {
            value: '026',
            label: 'Kiu iu(1000s)'
          }, {
            value: '027',
            label: 'Miu iu(1,000,000s)'
          }, {
            value: '028',
            label: 'iu/kg iu/kilogram'
          }, {
            value: '029',
            label: 'Meq milliequivalent(s)'
          }, {
            value: '030',
            label: '% percent'
          }, {
            value: '031',
            label: 'Gtt drop(s)'
          }, {
            value: '032',
            label: 'DF dosage form'
          }]
        }
      }, {
        id: 'drugseparatedosagenumb',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugseparatedosagenumb',
          label: 'Number of separate doses',
          required: false,
          validate: [validators.maxNumericLength(3, 'Field')]
        }
      }, {
        id: 'drugintervaldosageunitnumb',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugintervaldosageunitnumb',
          label: 'Number of units in interval',
          required: false,
          validate: [validators.maxNumericLength(3, 'Field')]
        }
      }, {
        id: 'drugintervaldosagedefinition',
        field: 'Dropdown',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugintervaldosagedefinition',
          label: 'Dosage time interval unit',
          required: false,
          options: [{
            value: '801',
            label: 'Year'
          }, {
            value: '802',
            label: 'Month'
          }, {
            value: '803',
            label: 'Week'
          }, {
            value: '804',
            label: 'Day'
          }, {
            value: '805',
            label: 'Hour'
          }, {
            value: '806',
            label: 'Minute'
          }]
        }
      }, {
        id: 'drugcumulativedosagenumb',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugcumulativedosagenumb',
          label: 'Cumulative dose to first reaction',
          required: false,
          validate: [validators.maxNumericLength(10, 'Field')]
        }
      }, {
        id: 'drugcumulativedosageunit',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugcumulativedosageunit',
          label: 'Cumulative dose to first reaction (unit)',
          required: false,
          validate: [validators.maxLength(3, 'Field')],
          maxLength: 3
        }
      }, {
        id: 'drugdosagetext',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugdosagetext',
          label: 'Dosage text',
          required: false,
          validate: [validators.maxLength(100, 'Field')],
          maxLength: 100,
          multiline: true
        }
      }, {
        id: 'drugdosageform',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'drugdosageform',
          label: 'Pharmaceutical form',
          required: false,
          options: _config.drugDosageFormOptions,
          maxLength: 50
        }
      }, {
        id: 'drugdosageorder',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'drugdosageorder',
          label: 'Which Dose is this?',
          required: false,
          validate: [validators.handle((0, _validators.isFieldUniqueInRepeatable)({
            fieldName: 'drugdosageorder',
            inRepeatable: 'drug',
            unitName: 'dose',
            translationCallback: _formHelpers.getDoseTranslation
          }))],
          options: (0, _options.mapValuesAt1)(['1', '2'])
        }
      }, {
        id: 'drugadministrationroute',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'drugadministrationroute',
          label: 'Route of administration',
          required: false,
          options: _config.drugAdministrationRoutes
        }
      }, {
        id: 'drugparadministration',
        field: 'Dropdown',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugparadministration',
          label: 'Parent route of administration',
          required: false,
          options: _config.drugAdministrationRoutes
        }
      }, {
        id: 'reactiongestationperiod',
        field: 'Input',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide,
          DEFAULT: function DEFAULT(values, parentValues) {
            return parentValues.patientsex !== '2';
          }
        }),
        props: {
          hideIfEmpty: true,
          shrink: true,
          name: 'reactiongestationperiod',
          label: 'Gestation period at time of exposure',
          required: false,
          validate: [validators.maxNumericLength(3, 'Field')]
        }
      }, {
        id: 'reactiongestationperiodunit',
        field: 'Dropdown',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide,
          DEFAULT: function DEFAULT(values, parentValues) {
            return parentValues.patientsex !== '2';
          }
        }),
        props: {
          shrink: true,
          name: 'reactiongestationperiodunit',
          label: 'Gestation period at time of exposure (unit)',
          required: false,
          options: [{
            value: '802',
            label: 'Month'
          }, {
            value: '803',
            label: 'Week'
          }, {
            value: '804',
            label: 'Day'
          }, {
            value: '810',
            label: 'Trimester'
          }]
        }
      }, {
        id: 'drugindicationmeddraversion',
        field: 'Input',
        computedValue: function computedValue(values, parentValues, orgDetails) {
          return orgDetails.meddraVersion;
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'drugindicationmeddraversion',
          label: 'Drug indication MedDRA Version',
          required: true
        }
      }, {
        id: 'covid19drugindication',
        field: 'Dropdown',
        computedValue: _sideEffects.covid19.computeWasCovid19Treatment,
        computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.isCovid19LogicActive),
        props: {
          shrink: true,
          name: 'covid19drugindication',
          label: 'Were you taking this this medicine for COVID-19 Treatment?',
          options: (0, _options.mapValuesAt1)(['Yes', 'No, it was for another reason']),
          required: true
        }
      }, {
        id: 'drugindication',
        field: 'MedDRALookupInput',
        computedValue: _sideEffects.covid19.computeDrugIndication,
        computedHidden: _sideEffects.covid19.handleDrugIndicationVisibility,
        props: {
          shrink: true,
          name: 'drugindication',
          label: 'Reason for taking medicine',
          required: false,
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250
        }
      }, {
        id: 'drugstartdateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.drugstartdate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'drugstartdateformat',
          label: 'Drug start date format',
          required: true
        }
      }, {
        id: 'drugstartdate',
        field: 'Date',
        computedHelperText: _sideEffects.covid19.computeHelperDateText,
        props: {
          shrink: true,
          name: 'drugstartdate',
          label: 'Drug start date',
          required: false,
          validate: [_sideEffects.covid19.computePartialDateValidation(validators), validators.dateTimeNotInFuture('drugstartdateformat', dateFormats), validators.isDateBeforeOrEqual('drugenddate', 'Drug end date'), validators.isDateAfterOrEqual('_parentFormValues.patientbirthdate', 'Patient date of birth'), _sideEffects.covid19.computeReactionDrugValidation(validators), validators.isDateBeforeOrEqual('_parentFormValues.patientdeathdate', 'Date of death')],
          startYear: 1920
        }
      }, {
        id: 'drugstartperiodunit',
        field: 'Dropdown',
        computedRequired: function computedRequired(values) {
          return !!values.drugstartperiod;
        },
        computedHidden: _sideEffects.audience.match({
          MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
          HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
          DEFAULT: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugstartperiodunit',
          label: 'Drug start period unit',
          required: false,
          options: [{
            value: '801',
            label: 'Year'
          }, {
            value: '802',
            label: 'Month'
          }, {
            value: '803',
            label: 'Week'
          }, {
            value: '804',
            label: 'Day'
          }, {
            value: '805',
            label: 'Hour'
          }, {
            value: '806',
            label: 'Minute'
          }, {
            value: '807',
            label: 'Second'
          }]
        }
      }, {
        id: 'drugstartperiod',
        field: 'Input',
        computedRequired: function computedRequired(values) {
          return !!values.drugstartperiodunit;
        },
        computedHidden: _sideEffects.audience.match({
          MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
          HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
          DEFAULT: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugstartperiod',
          label: 'Drug start period',
          required: false,
          validate: [validators.maxNumericLength(5, 'Field')]
        }
      }, {
        id: 'drugenddateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.drugenddate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'drugenddateformat',
          label: 'Drug end date format',
          required: true
        }
      }, {
        id: 'drugenddate',
        field: 'Date',
        computedHelperText: _sideEffects.covid19.computeHelperDateText,
        props: {
          shrink: true,
          name: 'drugenddate',
          label: 'Drug end date',
          required: false,
          validate: [_sideEffects.covid19.computePartialDateValidation(validators), validators.dateTimeNotInFuture('drugenddateformat', dateFormats), validators.isDateAfterOrEqual('drugstartdate', 'Drug start date'), validators.isDateBeforeOrEqual('_parentFormValues.patientdeathdate', 'Date of death')],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
          startYear: 1920
        }
      }, {
        id: 'drugexpirydate',
        field: 'Date',
        computedHidden: _sideEffects.audience.match({
          MOP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
          HCP: (0, _computedHidden.visibleIf)(_sideEffects.aefi.isAefiLogicActive),
          DEFAULT: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugexpirydate',
          label: 'Drug expiry date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
          startYear: 2020,
          endYearOffset: 5
        }
      }, {
        id: 'actiondrug',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'actiondrug',
          label: 'Action taken with drug',
          required: false,
          options: [{
            value: '1',
            label: 'Drug withdrawn'
          }, {
            value: '2',
            label: 'Dose reduced'
          }, {
            value: '3',
            label: 'Dose increased'
          }, {
            value: '4',
            label: 'Dose not changed'
          }, {
            value: '5',
            label: 'Unknown'
          }, {
            value: '6',
            label: 'Not applicable'
          }]
        }
      }, {
        id: 'drugrecurreadministration',
        field: 'Dropdown',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        props: {
          shrink: true,
          name: 'drugrecurreadministration',
          label: 'Did reaction recur on readministration?',
          required: false,
          options: [{
            value: '1',
            label: 'Yes'
          }, {
            value: '2',
            label: 'No'
          }, {
            value: '3',
            label: 'Unknown'
          }]
        }
      }, {
        id: 'drugrecuractionmeddraversion',
        field: 'Input',
        computedValue: function computedValue(values, parentValues, orgDetails) {
          return orgDetails.meddraVersion;
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'drugrecuractionmeddraversion',
          label: 'Drug recuration MedDRA Version',
          required: true
        }
      }, {
        id: 'drugrecuraction',
        field: 'MedDRALookupInput',
        computedHidden: _sideEffects.audience.match({
          MOP: _computedHidden.hide,
          HCP: _computedHidden.hide
        }),
        computedEditable: function computedEditable(values) {
          return values.drugrecurreadministration === '1';
        },
        props: {
          shrink: true,
          name: 'drugrecuraction',
          label: 'If yes, which reaction(s)/event(s) recurred?',
          required: false,
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250
        }
      }, {
        id: 'susarEditable',
        field: 'Input',
        computedValue: function computedValue(values, parentFormValues) {
          return (0, _formHelpers.susarEditable)(parentFormValues) ? 'true' : 'false';
        },
        props: {
          type: 'hidden',
          name: 'susarEditable'
        }
      }, {
        id: 'spontaneousEditable',
        field: 'Input',
        computedValue: function computedValue(values, parentFormValues) {
          return (0, _formHelpers.spontaneousEditable)(parentFormValues) ? 'true' : 'false';
        },
        props: {
          type: 'hidden',
          name: 'spontaneousEditable'
        }
      }, {
        id: 'diluentCharacterization',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'diluentCharacterization',
          label: 'Was there an issue with the diluent?',
          required: false,
          options: (0, _options.mapValuesAt1)(['Yes', 'No'])
        }
      }, {
        id: 'diluentProduct',
        field: 'Input',
        props: {
          shrink: true,
          name: 'diluentProduct',
          label: 'Diluent Name',
          required: false,
          validate: [validators.maxLength(30, 'Field')],
          maxLength: 30
        }
      }, {
        id: 'diluentBatchNumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'diluentBatchNumb',
          label: 'Diluent batch/lot number',
          required: false,
          validate: [validators.maxLength(15, 'Field')],
          maxLength: 15
        }
      }, {
        id: 'diluentExpiryDate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'diluentExpiryDate',
          label: 'Diluent expiry date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
          startYear: 2020,
          endYearOffset: 5
        }
      }, {
        id: 'diluentReconstitutionDate',
        field: 'Input',
        props: {
          shrink: true,
          name: 'diluentReconstitutionDate',
          label: 'Reconstitution date and time',
          required: false
        }
      }]
    }
  }, {
    id: 'otherdrug_drug',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'otherdrug_drug',
      label: 'Other medicines',
      formId: 'otherdrugrepeatable',
      repeatableLabelPlaceholder: 'Other medicines',
      repeatableLabel: ['medicinalproduct'],
      schema: [{
        id: 'medicinalproduct',
        field: 'DrugLookupInput',
        props: {
          shrink: true,
          name: 'medicinalproduct',
          label: 'Medicinal product',
          required: true,
          validate: [validators.maxLength(70, 'Field')],
          maxLength: 70
        }
      }, {
        id: 'drugcharacterization',
        field: 'Input',
        computedValue: _fp["default"].constant('2'),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'drugcharacterization',
          label: 'Drug characterisation'
        }
      }, {
        id: 'drugindication',
        field: 'MedDRALookupInput',
        props: {
          shrink: true,
          name: 'drugindication',
          label: 'Reason for taking medicine',
          required: false,
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250
        }
      }, {
        id: 'drugstartdateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.drugstartdate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'drugstartdateformat',
          label: 'Drug start date format',
          required: true
        }
      }, {
        id: 'drugstartdate',
        field: 'Date',
        computedHelperText: _sideEffects.covid19.computeHelperDateText,
        props: {
          shrink: true,
          name: 'drugstartdate',
          label: 'Drug start date',
          required: false,
          validate: [_sideEffects.covid19.computePartialDateValidation(validators), validators.dateTimeNotInFuture('drugstartdateformat', dateFormats), validators.isDateBeforeOrEqual('drugenddate', 'Drug end date'), validators.isDateAfterOrEqual('_parentFormValues.patientbirthdate', 'Patient date of birth'), _sideEffects.covid19.computeReactionDrugValidation(validators), validators.isDateBeforeOrEqual('_parentFormValues.patientdeathdate', 'Date of death')],
          startYear: 1920
        }
      }, {
        id: 'drugenddateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.drugenddate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'drugenddateformat',
          label: 'Drug end date format',
          required: true
        }
      }, {
        id: 'drugenddate',
        field: 'Date',
        computedHelperText: _sideEffects.covid19.computeHelperDateText,
        props: {
          shrink: true,
          name: 'drugenddate',
          label: 'Drug end date',
          required: false,
          validate: [_sideEffects.covid19.computePartialDateValidation(validators), validators.dateTimeNotInFuture('drugenddateformat', dateFormats), validators.isDateAfterOrEqual('drugstartdate', 'Drug start date'), validators.isDateBeforeOrEqual('_parentFormValues.patientdeathdate', 'Date of death')],
          startYear: 1920
        }
      }, {
        id: 'drugbatchnumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'drugbatchnumb',
          label: 'Batch/Lot number',
          required: false,
          validate: [validators.maxLength(35, 'Field')],
          maxLength: 35
        }
      }]
    }
  }, {
    id: 'causality',
    field: 'Input',
    props: {
      type: 'hidden'
    }
  }, {
    id: 'causalityAssessment',
    field: 'CombinedRepeatable',
    props: {
      name: 'causalityAssessment',
      label: 'Causality',
      layout: ['causality:12'],
      sourceField: {
        id: 'drug',
        groupKey: 'id',
        labelKey: 'medicinalproduct',
        labelExtra: [],
        filterValues: {
          drugcharacterization: ['1', '3']
        }
      },
      targetField: {
        id: 'reaction',
        labelKey: 'reactionmeddrallt'
      },
      repeatableGroupId: 'causality',
      requiredFieldValue: 'causalityAssessmentRequired',
      prepopulate: true,
      emptyTranslationKey: 'Causality Empty',
      schema: [{
        id: 'causality',
        field: 'Repeatable',
        props: {
          hideIfEmpty: false,
          formId: 'causalityForm',
          name: 'causalityForm',
          label: 'Causality test',
          repeatableLabelPlaceholder: 'Reaction',
          repeatableLabel: ['targetName', 'drugassessmentsource', 'drugassessmentmethod', 'drugresult'],
          shrink: true,
          modalIndex: 1,
          schema: [{
            id: 'sourceName',
            field: 'Input',
            props: {
              shrink: true,
              name: 'sourceName',
              label: 'Medicinal product',
              disabled: true,
              required: false
            }
          }, {
            id: 'targetId',
            field: 'Dropdown',
            computedOptions: function computedOptions(values, parentFormValues) {
              return _lodash["default"].map(parentFormValues.reaction, function (reaction) {
                return {
                  value: reaction.id,
                  label: _lodash["default"].get(reaction, 'reactionmeddrallt', _lodash["default"].get(reaction, 'primarysourcereaction'))
                };
              });
            },
            props: {
              shrink: true,
              name: 'targetId',
              label: 'Reaction assessed',
              required: true,
              disabledWhenPrepopulate: true
            }
          }, {
            id: 'targetName',
            field: 'Input',
            computedValue: function computedValue(values, parentFormValues) {
              var reaction = _lodash["default"].find(parentFormValues.reaction, {
                id: values.targetId
              });

              return _lodash["default"].get(reaction, 'reactionmeddrallt', _lodash["default"].get(reaction, 'primarysourcereaction'));
            },
            props: {
              type: 'hidden',
              shrink: true,
              name: 'targetName',
              label: 'Reaction assessed name'
            }
          }, {
            id: 'drugassessmentsource',
            field: 'Input',
            props: {
              shrink: true,
              name: 'drugassessmentsource',
              label: 'Source of assessment',
              required: true,
              validate: [validators.maxLength(60, 'Field')],
              maxLength: 60
            }
          }, {
            id: 'drugassessmentmethod',
            field: 'Input',
            props: {
              shrink: true,
              name: 'drugassessmentmethod',
              label: 'Method of assessment',
              required: true,
              validate: [validators.maxLength(35, 'Field')],
              maxLength: 35
            }
          }, {
            id: 'drugresult',
            field: 'Input',
            props: {
              shrink: true,
              name: 'drugresult',
              label: 'Result of assessment',
              required: true,
              validate: [validators.maxLength(35, 'Field')],
              maxLength: 35
            }
          }]
        }
      }]
    }
  }, {
    id: 'test',
    field: 'Repeatable',
    props: {
      shrink: true,
      name: 'test',
      label: 'Test',
      repeatableLabel: ['testdate', 'testname'],
      schema: [{
        id: 'testdateformat',
        field: 'Input',
        computedValue: function computedValue(values) {
          return getMHRAFormatForDate(values.testdate);
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'testdateformat',
          label: 'Reaction end date format',
          required: true
        }
      }, {
        id: 'testdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'testdate',
          label: 'Test date',
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.dateTimeNotInFuture('testdateformat', dateFormats)],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
          startYear: 1920
        }
      }, {
        id: 'testname',
        field: 'MedDRALookupInput',
        props: {
          shrink: true,
          name: 'testname',
          label: 'Test name',
          validate: [validators.maxLength(100, 'Field')],
          maxLength: 100
        }
      }, {
        id: 'testresult',
        field: 'Input',
        props: {
          shrink: true,
          name: 'testresult',
          label: 'Test Result',
          validate: [validators.maxLength(50, 'Field')],
          maxLength: 50
        }
      }, {
        id: 'testunit',
        field: 'Input',
        props: {
          shrink: true,
          name: 'testunit',
          label: 'Test result (unit)',
          validate: [validators.maxLength(35, 'Field')],
          maxLength: 35
        }
      }]
    }
  }, {
    id: 'resultstestsprocedures',
    field: 'Input',
    props: {
      shrink: true,
      name: 'resultstestsprocedures',
      label: 'Test additional text',
      validate: [validators.maxLength(2000, 'Field')],
      maxLength: 2000
    }
  }, {
    id: 'narrativeincludeclinical',
    field: 'Input',
    props: {
      shrink: true,
      name: 'narrativeincludeclinical',
      label: 'Case narrative',
      validate: [validators.maxLength(20000, 'Field')],
      maxLength: 20000,
      multiline: true
    }
  }, {
    id: 'reportercomment',
    field: 'Input',
    props: {
      shrink: true,
      name: 'reportercomment',
      label: "Reporter's comments",
      validate: [validators.maxLength(500, 'Field')],
      maxLength: 500
    }
  }, {
    id: 'sendercomment',
    field: 'Input',
    props: {
      shrink: true,
      name: 'sendercomment',
      label: "Sender's comments",
      validate: [validators.maxLength(2000, 'Field')],
      maxLength: 2000
    }
  }, {
    id: 'covid19symptomsexperienced',
    field: 'Dropdown',
    props: {
      required: true,
      shrink: true,
      name: 'covid19symptomsexperienced',
      label: 'Have you had symptoms associated with COVID-19?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No', 'Unsure'])
    }
  }, {
    id: 'covid19symptomsstartknown',
    field: 'Dropdown',
    computedRequired: _sideEffects.covid19.hasExperiencedSymptoms,
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasExperiencedSymptoms),
    computedValue: _sideEffects.covid19.clearIfNoSymptoms,
    props: {
      shrink: true,
      name: 'covid19symptomsstartknown',
      label: 'Do you know the date you started showing symptoms?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'Unsure'])
    }
  }, {
    id: 'covid19symptomsstartdateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.covid19symptomsstartdate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'covid19symptomsstartdateformat',
      label: 'covid19 symptoms start date format',
      required: true
    }
  }, {
    id: 'covid19symptomsstartdate',
    field: 'Date',
    computedRequired: _sideEffects.covid19.hasKnownSymptomsStartDate,
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasKnownSymptomsStartDate),
    computedValue: _sideEffects.covid19.clearIfSymptomsStartDateNotKnown,
    props: {
      shrink: true,
      name: 'covid19symptomsstartdate',
      label: 'Start Date of COVID-19 Symptoms',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('covid19symptomsstartdateformat', dateFormats), validators.isDateBeforeOrEqual('covid19symptomsenddate', 'end date of COVID-19 symptoms')],
      helperText: 'YYYY/MM/DD',
      startYear: 2019
    }
  }, {
    id: 'covid19symptomsendknown',
    field: 'Dropdown',
    computedRequired: _sideEffects.covid19.hasExperiencedSymptoms,
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasExperiencedSymptoms),
    computedValue: _sideEffects.covid19.clearIfNoSymptoms,
    props: {
      shrink: true,
      name: 'covid19symptomsendknown',
      label: 'Do you know the date you stopped showing symptoms?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'Unsure', 'Not recovered'])
    }
  }, {
    id: 'covid19symptomsenddateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.covid19symptomsenddate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'covid19symptomsenddateformat',
      label: 'covid19 symptoms end date format',
      required: true
    }
  }, {
    id: 'covid19symptomsenddate',
    field: 'Date',
    computedRequired: _sideEffects.covid19.hasKnownSymptomsEndDate,
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasKnownSymptomsEndDate),
    computedValue: _sideEffects.covid19.clearIfSymptomsEndDateNotKnown,
    props: {
      shrink: true,
      name: 'covid19symptomsenddate',
      label: 'End date of COVID-19 symptoms',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('covid19symptomsenddateformat', dateFormats), validators.isDateAfterOrEqual('covid19symptomsstartdate', 'start date of COVID-19 symptoms')],
      helperText: 'YYYY/MM/DD',
      startYear: 2019
    }
  }, {
    id: 'covid19test',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'covid19test',
      label: 'Have you tested positive for COVID-19?',
      options: [{
        value: 'Yes - Positive COVID-19 test',
        label: 'Yes - Positive COVID-19 test'
      }, {
        value: 'No - Negative COVID-19 test',
        label: 'No - Negative COVID-19 test'
      }, {
        value: 'Not tested/Inconclusive test',
        label: 'Not had a COVID-19 test'
      }, {
        value: 'Inconclusive test',
        label: 'Inconclusive test'
      }]
    }
  }, {
    id: 'testname_test',
    field: 'Input',
    computedValue: _sideEffects.covid19.computeTestResult,
    props: {
      shrink: true,
      name: 'testname_test',
      label: 'COVID-19 Test Name',
      type: 'hidden',
      required: false
    }
  }, {
    id: 'testdateformat_test',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values['testdate_test']);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'testdateformat_test',
      label: 'Date of COVID-19 test format',
      required: false
    }
  }, {
    id: 'testdate_test',
    field: 'Date',
    props: {
      shrink: true,
      name: 'testdate_test',
      label: 'If you were tested, what was the date?',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('testdateformat_test', dateFormats)],
      helperText: 'YYYY/MM/DD',
      startYear: 2019
    }
  }, {
    id: 'covid19clinicalstudyenrollment',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'covid19clinicalstudyenrollment',
      label: 'Are you / Is the patient currently enrolled in a study or clinical trial?',
      required: true,
      options: (0, _options.mapValueAndLabel)(['Yes', 'No', 'Unsure'])
    }
  }, {
    id: 'covid19clinicalstudydetails',
    field: 'Input',
    props: {
      shrink: true,
      name: 'covid19clinicalstudydetails',
      label: 'Please provide any details, such as the study name, if known',
      required: false
    }
  }, {
    id: 'covid19logic',
    field: 'Input',
    computedValue: _fp["default"].T,
    props: {
      shrink: true,
      name: 'covid19logic',
      label: 'Conditionally hide other fields based on COVID-19 logic',
      required: false,
      type: 'hidden'
    }
  }, {
    id: 'covid19vaccinelogic',
    field: 'Input',
    computedValue: _fp["default"].T,
    props: {
      shrink: true,
      name: 'covid19logic',
      label: 'Conditionally hide other fields based on COVID-19 Vaccine Study logic',
      required: false,
      type: 'hidden'
    }
  }, {
    id: 'covid19patienttakenvaccine',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      defaultValue: 'No',
      name: 'covid19patienttakenvaccine',
      label: 'Have you already had the COVID-19 vaccine?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'covid19patientknowndate',
    field: 'Dropdown',
    computedRequired: _sideEffects.covid19.hasNotTakenVaccine,
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasNotTakenVaccine),
    computedValue: _sideEffects.covid19.clearIfVaccineTaken,
    props: {
      shrink: true,
      required: false,
      name: 'covid19patientknowndate',
      label: 'Do you know when you are due to have the COVID-19 vaccine?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'covid19vaccineduedateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.covid19vaccineduedate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'covid19vaccineduedateformat',
      label: 'covid19 symptoms due date format',
      required: true
    }
  }, {
    id: 'covid19vaccineduedate',
    field: 'Date',
    computedRequired: _sideEffects.covid19.hasKnownVaccineDate,
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasKnownVaccineDate),
    computedValue: _sideEffects.covid19.clearIfVaccineKnownDate,
    props: {
      shrink: true,
      name: 'covid19vaccineduedate',
      label: 'When are you due to have the COVID-19 vaccine?',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeTodayOrFuture],
      helperText: 'YYYY/MM/DD',
      startYear: 2020,
      endYearOffset: 1
    }
  }, {
    id: 'covid19postvaccinepositive',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'covid19postvaccinepositive',
      label: 'Since having the vaccine, have you tested positive for COVID-19?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'covid19postvaccinepositivestartdateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.covid19postvaccinepositivestartdate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'covid19postvaccinepositivestartdateformat',
      label: 'covid19 post vaccine positive start date format',
      required: true
    }
  }, {
    id: 'covid19postvaccinepositivestartdate',
    field: 'Date',
    computedRequired: _sideEffects.covid19.hasTestedPositivePostVaccine,
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasTestedPositivePostVaccine),
    computedValue: _sideEffects.covid19.clearIfHasNotTestedPositivePostVaccine,
    props: {
      shrink: true,
      name: 'covid19postvaccinepositivestartdate',
      label: 'Please provide the date you tested positive',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('covid19postvaccinepositivestartdateformat', dateFormats), validators.isDateBeforeOrEqual('covid19postvaccinepositiveenddate', 'end date of COVID-19 symptoms'), validators.isDateAfterOrEqual('covid19vaccineduedate', 'due date of COVID-19 vaccine'), validators.isDateAfterOrEqual('drug', 'Drug Start Date', 'drugstartdate')],
      helperText: 'YYYY/MM/DD',
      startYear: 2020,
      endYearOffset: 1
    }
  }, {
    id: 'covid19postvaccinepositiveoutcome',
    field: 'Dropdown',
    computedRequired: _sideEffects.covid19.hasTestedPositivePostVaccine,
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasTestedPositivePostVaccine),
    computedValue: _sideEffects.covid19.clearIfHasNotTestedPositivePostVaccine,
    computedOptions: _sideEffects.covid19.computeReactionOutcomeOptions,
    props: {
      shrink: true,
      name: 'covid19postvaccinepositiveoutcome',
      label: 'Please provide the outcome of your infection'
    }
  }, {
    id: 'covid19postvaccinepositiveenddateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.covid19postvaccinepositiveenddate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'covid19postvaccinepositiveenddateformat',
      label: 'covid19 post vaccine positive end date format',
      required: true
    }
  }, {
    id: 'covid19postvaccinepositiveenddate',
    field: 'Date',
    computedRequired: _sideEffects.covid19.hasNotTakenVaccine,
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasTestedPositivePostVaccine),
    computedValue: _sideEffects.covid19.clearIfHasNotTestedPositivePostVaccine,
    props: {
      shrink: true,
      name: 'covid19postvaccinepositiveenddate',
      label: 'If you have recovered from your infection, please provide the date you recovered',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('covid19postvaccinepositiveenddateformat', dateFormats), validators.isDateAfterOrEqual('covid19postvaccinepositivestartdate', 'start date of COVID-19 symptoms'), validators.isDateAfterOrEqual('covid19vaccineduedate', 'due date of COVID-19 vaccine'), validators.isDateAfterOrEqual('drug', 'Drug Start Date', 'drugstartdate')],
      helperText: 'YYYY/MM/DD',
      startYear: 2020,
      endYearOffset: 1
    }
  }, {
    id: 'covid19vaccinedosage2taken',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      defaultValue: 'No',
      name: 'covid19vaccinedosage2taken',
      label: 'Have you already had the second dosage of COVID-19 vaccine?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'covid19vaccinedosage2knowndate',
    field: 'Dropdown',
    computedRequired: _sideEffects.covid19.dosage2.hasNotTaken,
    computedHidden: _sideEffects.covid19.dosage2.visibleIfHasNotTaken,
    computedValue: _sideEffects.covid19.dosage2.clearIfHasTaken,
    props: {
      shrink: true,
      required: false,
      name: 'covid19vaccinedosage2knowndate',
      label: 'Do you know when you are due to have the second dosage of COVID-19 vaccine?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'covid19vaccinedosage2duedateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.covid19vaccinedosage2knowndate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'covid19vaccinedosage2duedateformat',
      label: 'covid19 dosage 2 due date format',
      required: true
    }
  }, {
    id: 'covid19vaccinedosage2duedate',
    field: 'Date',
    computedRequired: _sideEffects.covid19.dosage2.hasKnownDate,
    computedHidden: _sideEffects.covid19.dosage2.visibleIfHasKnownDate,
    computedValue: _sideEffects.covid19.dosage2.clearIfNoKnownDate,
    props: {
      shrink: true,
      name: 'covid19vaccinedosage2duedate',
      label: 'When are you due to have the second dosage of COVID-19 vaccine?',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeTodayOrFuture, _sideEffects.covid19.dosage2.checkPreceedingDosageDate(validators)],
      helperText: 'YYYY/MM/DD',
      startYear: 2020,
      endYearOffset: 1
    }
  }, {
    id: 'covid19vaccinedosage3taken',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      defaultValue: 'No',
      name: 'covid19vaccinedosage3taken',
      label: 'Have you already had the third dosage of COVID-19 vaccine?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'covid19vaccinedosage3knowndate',
    field: 'Dropdown',
    computedRequired: _sideEffects.covid19.dosage3.hasNotTaken,
    computedHidden: _sideEffects.covid19.dosage3.visibleIfHasNotTaken,
    computedValue: _sideEffects.covid19.dosage3.clearIfHasTaken,
    props: {
      shrink: true,
      required: false,
      name: 'covid19vaccinedosage3knowndate',
      label: 'Do you know when you are due to have the third dosage of COVID-19 vaccine?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'covid19vaccinedosage3duedateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.covid19vaccinedosage2knowndate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'covid19vaccinedosage3duedateformat',
      label: 'covid19 dosage 3 due date format',
      required: true
    }
  }, {
    id: 'covid19vaccinedosage3duedate',
    field: 'Date',
    computedRequired: _sideEffects.covid19.dosage3.hasKnownDate,
    computedHidden: _sideEffects.covid19.dosage3.visibleIfHasKnownDate,
    computedValue: _sideEffects.covid19.dosage3.clearIfNoKnownDate,
    props: {
      shrink: true,
      name: 'covid19vaccinedosage3duedate',
      label: 'When are you due to have the third dosage of COVID-19 vaccine?',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeTodayOrFuture, _sideEffects.covid19.dosage3.checkPreceedingDosageDate(validators)],
      helperText: 'YYYY/MM/DD',
      startYear: 2020,
      endYearOffset: 1
    }
  }, {
    id: 'covid19vaccinebooster1taken',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_sideEffects.covid19.hasTakenVaccine),
    computedValue: _sideEffects.covid19.clearIfVaccineNotTaken,
    props: {
      shrink: true,
      required: true,
      defaultValue: 'No',
      name: 'covid19vaccinebooster1taken',
      label: 'Has a COVID-19 vaccine booster already been administered?',
      options: [{
        value: 'Yes',
        label: 'Yes'
      }, {
        value: 'No',
        label: 'No'
      }, {
        value: 'Not currently eligible',
        label: 'No, I am currently not eligible for a booster'
      }, {
        value: 'Not receiving',
        label: 'No, I will not be receiving a booster'
      }]
    }
  }, {
    id: 'covid19vaccinebooster1knowndate',
    field: 'Dropdown',
    computedRequired: _sideEffects.covid19.booster1.hasNotTaken,
    computedHidden: _sideEffects.covid19.booster1.visibleIfHasNotTaken,
    computedValue: _sideEffects.covid19.booster1.clearIfHasTaken,
    props: {
      shrink: true,
      required: false,
      name: 'covid19vaccinebooster1knowndate',
      label: 'Do you know when the COVID-19 vaccine booster is due?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'covid19vaccinebooster1duedateformat',
    field: 'Input',
    computedValue: function computedValue(values) {
      return getMHRAFormatForDate(values.covid19vaccinedosage2knowndate);
    },
    props: {
      type: 'hidden',
      shrink: true,
      name: 'covid19vaccinebooster1duedateformat',
      label: 'covid19 booster due date format',
      required: true
    }
  }, {
    id: 'covid19vaccinebooster1duedate',
    field: 'Date',
    computedRequired: _sideEffects.covid19.booster1.hasKnownDate,
    computedHidden: _sideEffects.covid19.booster1.visibleIfHasKnownDate,
    computedValue: _sideEffects.covid19.booster1.clearIfNoKnownDate,
    props: {
      shrink: true,
      name: 'covid19vaccinebooster1duedate',
      label: 'When are you due to have the booster of COVID-19 vaccine?',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeTodayOrFuture, _sideEffects.covid19.dosage3.checkPreceedingDosageDate(validators)],
      helperText: 'YYYY/MM/DD',
      startYear: 2020,
      endYearOffset: 1
    }
  }];
};

exports.getFormSchema = getFormSchema;