import _  from 'lodash'
import * as constants from './constants'
import { promiseReducer } from '../../util'

class DrugDetailsReducers {
  fetchDrug = promiseReducer(
    (state, action) => {
      const drug = action.result
      const metaDistribution = _.get(drug, 'metaDistributions.0.value')

      return {
        ...state,
        drug,
        metaDistribution
      }
    }
  )

  changeMetaDistribution = promiseReducer(
    (state, action) => {
      const { result: fetchedDrug, metaDistribution } = action
      const existingDrug = state.drug || {}

      // merge the metas together, because if there's a
      // drug meta provider (WHO etc) then each fetch drug request
      // only contains results for one distribution
      const drug = {
        ...fetchedDrug,
        meta: {
          ...existingDrug.meta,
          ...fetchedDrug.meta
        }
      }

      return {
        ...state,
        drug,
        metaDistribution,
        isLoadingMeta: false
      }
    },
    (state, action) => {
      const { metaDistribution } = action
      return {
        ...state,
        metaDistribution,
        isLoadingMeta: true
      }
    }
  )
}

export default new DrugDetailsReducers()
