import * as constants from './constants'
import { push } from 'connected-react-router'
import { actions as organisationDetailsActions } from '../organisationDetails'
import _ from 'lodash'

class OrganisationInvitesMiddleware {
  fetchOrganisationInvite = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === constants.FETCH_ORGANISATION_INVITE_REJECTED) {
      dispatch(push('/not-found'))
    }
    if (action.type === constants.FETCH_ORGANISATION_INVITE_FULFILLED) {
      const organisationId = _.get(getState(), 'organisationInvites.loaded.organisationId')
      dispatch(organisationDetailsActions.fetchOrganisationById({
        id: organisationId
      }))
    }
  }
}

export default new OrganisationInvitesMiddleware()