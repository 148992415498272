const styles = theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px'
    },
    tableCenter: {
      justifyContent: 'center'
    },
    checkboxStyle: {
      marginRight: 0,
      paddingLeft: '10%',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '15%'
      }
    },
    gridContainer: {
      width: '100%',
      overflow: `auto`,
      margin: '0 auto',
      padding: '20px 10px 0 10px',
      boxSizing: 'border-box',

      [theme.breakpoints.up('xs')]: {
        paddingLeft: 20,
        paddingRight: 20
      }
    },
    contentContainer: {
      overflow: 'auto',
      width: '100%',
      margin: '0 auto',
      padding: '20px 10px 0 10px',
      boxSizing: 'border-box',
  
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 20,
        paddingRight: 20
      }
    },
    content: {
      width: '100%',
      marginTop: 30,
      textAlign: 'center',
      fontSize: 19,
      fontWeight: 'bold'
    },
    uppercase: {
      textTransform: 'uppercase'
    },
    lockContainer: {
      width: '50px',
      padding: 0
    },
    cardContainer: {
      width: '100%',
      maxWidth: 400,
      padding: '7.5px 0'
    },
    editButton: {
      marginRight: '10px'
    },
    card: {
      marginBottom: '1rem',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'center'
      }
    }
  })
  
  export default styles
  