import React from 'react'
import _ from 'lodash'
import { OptionLabel, OptionMeta, VerticalOption } from './Components'
import TextField from '@material-ui/core/TextField'

import { translations } from '../../../../../../config'

export const getOptionLabel = (option) => {
  return option.name
}

export const renderOption = (option) => {
  const { name, displayName, id } = option
  return (
    <VerticalOption>
      <OptionLabel variant={'body1'}>{displayName || name}</OptionLabel>
      {_.isString(displayName) && <OptionMeta variant={'caption'}>{name}</OptionMeta>}
      <OptionMeta>{id}</OptionMeta>
    </VerticalOption>
  )
}

export const renderInput = (params) => {
  return <TextField
    {...params}
    label={translations('Name')}
    size={'small'}
    InputLabelProps={{
      shrink: true
    }}
  />
}

export const filterCustom = (allFields) => {
  let additionalOptions = []
  const filterFn = (({ isBlock, isHeader, isCustom, fields }) => {
    if (isBlock && fields) {
      additionalOptions.push(..._.filter(fields, filterFn))
    }
    return !isBlock && !isHeader && isCustom
  })
  return [..._.filter(allFields, filterFn), ...additionalOptions]
}
export const filterBlocks = (allFields) => {
  const filterFn = ({ isBlock }) => isBlock
  return _.filter(allFields, filterFn)
}

export const filterHeadings = (allFields) => {
  const filterFn = ({ isHeader }) => isHeader
  return _.filter(allFields, filterFn)
}