"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flattenViewJSON = exports.castFieldStringToArray = exports.callEveryFunctionWithArgs = void 0;
Object.defineProperty(exports, "generateAckInitialValues", {
  enumerable: true,
  get: function get() {
    return _generateAckInitialValues.generateAckInitialValues;
  }
});
Object.defineProperty(exports, "generateInitialValues", {
  enumerable: true,
  get: function get() {
    return _generateInitialValues.generateInitialValues;
  }
});
exports.generateReportSchema = exports.generatePdfSchema = void 0;
Object.defineProperty(exports, "generateSafetyReportId", {
  enumerable: true,
  get: function get() {
    return _generateSafetyReportId.generateSafetyReportId;
  }
});
exports.unflattenViewJSON = exports.sectionShouldRender = exports.parseSectionFields = exports.parseDynamicViewJSON = exports.parseDynamicSchemaSections = exports.parseDynamicSchema = exports.moveSystemFields = exports.isDevMode = exports.hasAnyKeys = exports.hasAllKeys = exports.handleComputedOtherValues = exports.getReportStatus = exports.getOffsetDate = exports.getCustomQuestionsToRoot = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = _interopRequireDefault(require("lodash/fp"));

var _moment = _interopRequireDefault(require("moment"));

var _jsonRulesService = _interopRequireDefault(require("../services/jsonRulesService"));

var _validators = require("../validators");

var _formSchemas = _interopRequireDefault(require("../formSchemas"));

var _generateInitialValues = require("./generateInitialValues");

var _generateAckInitialValues = require("./generateAckInitialValues");

var _generateSafetyReportId = require("./generateSafetyReportId");

var _computedValue = require("../formHelpers/computedValue");

var _excluded = ["computedEditable", "computedValue", "computedDisabled", "disabled", "computedRequired", "computedNullFlavours", "computedHidden", "computedOptions", "computedHelperText", "onSubmit", "formViewDetails", "computedField", "computedLookUpOptionsListName", "computedInitialValue", "iscomputedInitialValueActive"],
    _excluded2 = ["fields", "blocks", "id", "name", "visible"],
    _excluded3 = ["id", "fields", "fields"],
    _excluded4 = ["id"];

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var handleComputedOtherValues = function handleComputedOtherValues() {
  var actionCreator = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _fp["default"].noop;
  var formName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var computedOtherValuesArgs = arguments.length > 3 ? arguments[3] : undefined;
  var optionsArg = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

  var options = _fp["default"].defaultsDeep({
    hooks: {
      beforeActionCreator: _fp["default"].identity,
      afterActionCreator: _fp["default"].identity
    }
  }, optionsArg);

  var ChangeRequests = _lodash["default"].invoke.apply(_lodash["default"], [field, 'computedOtherValues'].concat(_toConsumableArray(computedOtherValuesArgs), [field.id]));

  _fp["default"].forEach(function (changeRequest) {
    var _fp$pick = _fp["default"].pick(['field', 'value'], changeRequest),
        _fp$pick$field = _fp$pick.field,
        OtherField = _fp$pick$field === void 0 ? '' : _fp$pick$field,
        _fp$pick$value = _fp$pick.value,
        value = _fp$pick$value === void 0 ? '' : _fp$pick$value;

    _fp["default"].compose(options.hooks.afterActionCreator, function (value) {
      (_fp["default"].isFunction(actionCreator) ? actionCreator : _fp["default"].noop)(formName, OtherField, value);
      return value;
    }, options.hooks.beforeActionCreator)(value);
  }, ChangeRequests);
};

exports.handleComputedOtherValues = handleComputedOtherValues;
var visibilityTypes = {
  STANDARD: 'standard',
  ALL: 'all'
};

var generateReportSchema = function generateReportSchema(report, schema) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _fp$compose = _fp["default"].compose(_fp["default"].at(['context.formViewDetails', 'context.organisationDetails', 'context.professions', 'visibility']), _fp["default"].defaults({
    visibility: 'standard',
    dateReplacer: '-'
  }))(options),
      _fp$compose2 = _slicedToArray(_fp$compose, 5),
      formViewDetails = _fp$compose2[0],
      organisationDetails = _fp$compose2[1],
      professions = _fp$compose2[2],
      visibility = _fp$compose2[3],
      dateReplacer = _fp$compose2[4];

  var visibilityIsStandard = visibilityTypes.STANDARD === visibility;

  var _resolveValues = function _resolveValues(schemaItem) {
    var checkForNestedRepeatables = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (checkForNestedRepeatables && _isRepeatable(schemaItem)) {
      return _handleRepeatableValues(schemaItem);
    }

    var _ref = schemaItem || {},
        id = _ref.id,
        field = _ref.field,
        computedOptions = _ref.computedOptions,
        props = _ref.props;

    var _ref2 = props || {},
        label = _ref2.label,
        type = _ref2.type;

    var computedHiddenIsTrue = _lodash["default"].invoke(schemaItem, 'computedHidden', report, {}, organisationDetails, formViewDetails, professions) === true;

    if (computedHiddenIsTrue && visibilityIsStandard) {
      type = 'hidden';
    }

    return {
      id: id,
      field: field,
      label: label,
      type: type,
      valueAlias: _fp["default"].concat(_lodash["default"].isFunction(computedOptions) ? computedOptions(report, report, organisationDetails, formViewDetails, professions) : [], _lodash["default"].get(props, 'options', []), _lodash["default"].get(props, 'nullFlavors', []))
    };
  };

  var _isRepeatable = _fp["default"].compose(function (value) {
    return value === 'Repeatable';
  }, _fp["default"].get('field'));

  var _isCombinedRepeatable = _fp["default"].compose(function (value) {
    return value === 'CombinedRepeatable';
  }, _fp["default"].get('field'));

  var _isHeading = _fp["default"].compose(function (value) {
    return value === 'Heading';
  }, _fp["default"].get('field'));

  var _handleSingleValues = function _handleSingleValues(item) {
    return _resolveValues(item);
  };

  var _handleHeading = function _handleHeading(item) {
    return {
      id: item.id,
      label: _lodash["default"].get(item, 'props.value'),
      type: 'Heading'
    };
  };

  var _handleSectionValues = function _handleSectionValues(schemaValue) {
    if (_isHeading(schemaValue)) {
      return _handleHeading(schemaValue);
    }

    switch (true) {
      case _isRepeatable(schemaValue):
        return _handleRepeatableValues(schemaValue);

      case _isCombinedRepeatable(schemaValue):
        return _handleCombinedRepeatableValues(schemaValue);

      default:
        return _handleSingleValues(schemaValue);
    }
  };

  var _handleRepeatableValues = function _handleRepeatableValues(repeatableItem) {
    var _ref3 = repeatableItem || {},
        id = _ref3.id,
        field = _ref3.field,
        props = _ref3.props;

    var _ref4 = props || {},
        label = _ref4.label,
        schema = _ref4.schema;

    var repeatableFieldValue = _resolveValues({
      id: id,
      field: field,
      props: {
        label: label
      }
    });

    var repeatableValues = _lodash["default"].map(schema, function (schemaItem) {
      return _resolveValues(schemaItem, true);
    });

    return _objectSpread(_objectSpread({}, repeatableFieldValue), {}, {
      fields: repeatableValues
    });
  };

  var _handleCombinedRepeatableValues = function _handleCombinedRepeatableValues(repeatableItem) {
    var _ref5 = repeatableItem || {},
        field = _ref5.field,
        props = _ref5.props;

    var _ref6 = props || {},
        label = _ref6.label,
        schema = _ref6.schema,
        repeatableGroupId = _ref6.repeatableGroupId;

    var repeatableFieldValue = _resolveValues({
      id: repeatableGroupId,
      field: field,
      props: {
        label: label
      }
    });

    var repeatableValues = _lodash["default"].flatMap(schema, function (schemaItem) {
      return _handleRepeatableValues(schemaItem);
    });

    return _objectSpread(_objectSpread({}, repeatableFieldValue), {}, {
      fields: _lodash["default"].flatMap(repeatableValues, 'fields')
    });
  };

  var _handleSections = _fp["default"].compose(_fp["default"].omitBy({
    type: 'hidden'
  }), _fp["default"].map(_handleSectionValues));

  var _formatValueAndTitle = function _formatValueAndTitle(fieldSchema, value, key) {
    var item = _lodash["default"].pick(fieldSchema, ['valueAlias', 'label', 'field', 'type']);

    if ((item.type === 'hidden' || _lodash["default"].isUndefined(item.label)) && visibilityIsStandard) {
      return null;
    }

    var alias = null;

    if (item.field === 'Date') {
      try {
        var inputLength = _lodash["default"].size(value);

        var inputFormats = {
          4: 'YYYY',
          6: 'YYYYMM',
          8: 'YYYYMMDD'
        };
        var outputFormats = {
          4: 'YYYY',
          6: 'YYYY-MM',
          8: 'YYYY-MM-DD'
        };
        var inputFormat = inputFormats[inputLength] || 'YYYYMMDD';
        var outputFormat = outputFormats[inputLength] || 'YYYY-MM-DD';

        if (value) {
          var date = (0, _moment["default"])(value, inputFormat);
          alias = date.isValid() ? date.format(outputFormat) : dateReplacer;
        } else {
          alias = dateReplacer;
        }
      } catch (e) {
        console.log("moment value ".concat(value, " failed for:"), item);
        console.log(e);
        alias = '';
      }
    }

    if (_lodash["default"].isObject(value) && !_lodash["default"].isArray(value)) {
      return null;
    }

    if (_fp["default"].size(item.valueAlias)) {
      alias = _fp["default"].compose(_fp["default"].get('label'), _fp["default"].find({
        value: value
      }))(item.valueAlias);
    }

    if (_lodash["default"].isArray(value)) {
      if (!_isCombinedRepeatable(item) && !_isRepeatable(item)) {
        var valueAliases = _fp["default"].isArray(item.valueAlias) ? item.valueAlias : [];
        return {
          title: item.label,
          value: _fp["default"].compose(_fp["default"].join(', '), _fp["default"].map(function (singleOption) {
            return _lodash["default"].get(_lodash["default"].find(valueAliases, {
              value: singleOption
            }), 'label', singleOption);
          }))(value),
          key: key
        };
      }

      var repeatables = _lodash["default"].map(value, function (v) {
        return _lodash["default"].omit(v, ['id']);
      });

      var childrenFieldSchemas = _lodash["default"].get(fieldSchema, 'fields', []);

      var repeatableFieldValues = _lodash["default"].map(repeatables, function (repeatableInstance) {
        return _lodash["default"].map(childrenFieldSchemas, function (fieldSchema) {
          var id = fieldSchema.id;

          var fieldValue = _lodash["default"].get(repeatableInstance, id) || _lodash["default"].get(repeatableInstance, "custom.".concat(id));

          if (_lodash["default"].isNil(fieldValue)) {
            return null;
          }

          return _formatValueAndTitle(fieldSchema, fieldValue, id);
        });
      });

      if (!_fp["default"].isNil(item.label)) {
        return {
          title: item.label,
          value: repeatableFieldValues
        };
      }
    }

    if (!_fp["default"].isNil(item.label)) {
      return {
        title: item.label,
        value: _fp["default"].isNull(alias) || _fp["default"].isUndefined(alias) ? value : alias,
        type: item.type,
        key: key
      };
    }

    return null;
  };

  var _mapSchemaReportValues = function _mapSchemaReportValues(value) {
    var _ref7 = value || {},
        title = _ref7.title,
        fields = _ref7.fields,
        key = _ref7.key;

    var fieldsObj = {};

    _lodash["default"].forEach(fields, function (schemaItem, schemaKey) {
      var value = report[schemaKey];

      if (_fp["default"].isNil(value)) {
        value = _fp["default"].get(['custom', key, schemaKey], report);
      }

      fieldsObj[schemaKey] = _formatValueAndTitle(schemaItem, value, key);
    });

    results[key] = {
      title: title,
      fields: fieldsObj
    };
  };

  var _handleSchema = function _handleSchema(section, sectionId) {
    var _ref8 = section || {},
        title = _ref8.title,
        schema = _ref8.schema;

    return {
      key: sectionId,
      title: title,
      fields: _lodash["default"].keyBy(_handleSections(schema), 'id')
    };
  };

  var _handleReport = _fp["default"].compose(_fp["default"].map(_mapSchemaReportValues), function (s) {
    return _lodash["default"].map(s, _handleSchema);
  });

  var results = {};

  _handleReport(schema);

  return results;
};

exports.generateReportSchema = generateReportSchema;

var generatePdfSchema = function generatePdfSchema(reportSchema, viewJSON, reportJSON, name) {
  var professions = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
  var parentFormValues = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
  var organisationDetails = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : {};
  var formViewDetails = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : {};
  var translation = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : {};

  var audienceId = _lodash["default"].get(formViewDetails, 'audienceId', 1);

  var translations = function translations(string) {
    if (translation[string]) {
      return translation[string];
    }

    return string;
  };

  var formSchema;

  try {
    var validators = (0, _validators.getValidators)(translations);

    var getFormSchema = _lodash["default"].get(_formSchemas["default"][name](), 'schema.getFormSchema');

    formSchema = getFormSchema(validators);
  } catch (error) {
    console.error(error);
  }

  if (!viewJSON) {
    try {
      var getFormView = _lodash["default"].get(_formSchemas["default"][name](), 'schema.getFormView');

      viewJSON = getFormView().sections;
    } catch (error) {
      console.error(error);
    }
  }

  return _lodash["default"].reduce(viewJSON, function (acc, section) {
    var sectionId = _lodash["default"].get(section, 'id');

    var sectionTitle = _lodash["default"].get(section, 'displayName') || _lodash["default"].get(section, 'name');

    var sectionFields = parseSectionFields(_lodash["default"].get(section, 'fields'));

    var sectionVisibility = _lodash["default"].get(section, 'visible');

    if (!sectionVisibility) {
      return _objectSpread({}, acc);
    }

    var reportSectionFields = _lodash["default"].reduce(sectionFields, function (sectionFieldsAcc, field) {
      var fieldId = _lodash["default"].get(field, 'id');

      var fieldTitle = _lodash["default"].get(field, 'name');

      var fieldRenamedTitle = _lodash["default"].get(field, 'displayName');

      var fieldVisibility = _lodash["default"].get(field, 'visible');

      var fieldIsCustom = _lodash["default"].get(field, 'isCustom');

      var fieldEditable = audienceId === 1 || _lodash["default"].get(field, 'editable');

      var fieldCustomDropdownOptions = _lodash["default"].get(field, 'dropdownOptions');

      var hasCustomDropdownOptions = _lodash["default"].isArray(fieldCustomDropdownOptions) && _lodash["default"].size(fieldCustomDropdownOptions) > 0;
      var schemaItem;
      var computedEditable;
      var computedHidden;
      var isPropTypeHidden = false;
      var isHeading = false;
      var schemaPropLabel;

      var reportSchemaField = _lodash["default"].get(reportSchema, [sectionId, 'fields', fieldId]);

      try {
        schemaItem = _lodash["default"].find(formSchema, function (formSchemaItem) {
          return formSchemaItem.id === fieldId;
        });
        isPropTypeHidden = _lodash["default"].get(schemaItem, 'props.type') === 'hidden';
        schemaPropLabel = _lodash["default"].get(schemaItem, 'props.label');
        isHeading = _lodash["default"].get(reportSchemaField, 'type') === 'Heading';
        computedEditable = _lodash["default"].get(schemaItem, 'computedEditable') ? _lodash["default"].get(schemaItem, 'computedEditable')(_objectSpread(_objectSpread({}, reportJSON), {}, {
          _parentFormValues: parentFormValues
        }), parentFormValues, organisationDetails, formViewDetails, professions) : true;
        computedHidden = _lodash["default"].get(schemaItem, 'computedHidden') ? _lodash["default"].get(schemaItem, 'computedHidden')(_objectSpread(_objectSpread({}, reportJSON), {}, {
          _parentFormValues: parentFormValues
        }), parentFormValues, organisationDetails, formViewDetails, professions) : false;
        var isCombinedRepeatable = _lodash["default"].get(schemaItem, 'field') === 'CombinedRepeatable';

        if (isCombinedRepeatable && !reportSchemaField) {
          var repeatableGroupId = _lodash["default"].get(schemaItem, 'props.repeatableGroupId');

          reportSchemaField = _lodash["default"].get(reportSchema, [sectionId, 'fields', repeatableGroupId]);
        }
      } catch (error) {
        console.error(error);
      }

      if (!fieldVisibility || fieldEditable === false || computedHidden || computedEditable === false || isHeading || isPropTypeHidden || !reportSchemaField) {
        return sectionFieldsAcc;
      }

      var reportSchemaFieldValue = _lodash["default"].get(reportSchemaField, 'value');

      var reportJSONFieldValue = _lodash["default"].get(reportJSON, fieldId);

      var reportCustomFieldValue = _lodash["default"].get(reportJSON, ['custom', sectionId, fieldId]);

      var fieldValue = !_lodash["default"].isUndefined(reportSchemaFieldValue) ? reportSchemaFieldValue : reportJSONFieldValue;

      var schemaField = _lodash["default"].find(formSchema, function (formSchemaItem) {
        return formSchemaItem.id === fieldId;
      });

      var type = _lodash["default"].get(schemaField, 'field');

      var schemaFieldOptions = _lodash["default"].get(schemaField, 'props.options', []);

      var isMultiSelectable = _lodash["default"].includes(['Dropdown', 'Checklist'], type) && schemaFieldOptions.length > 0;
      var resolvedFieldValue;

      switch (true) {
        case fieldIsCustom:
          resolvedFieldValue = reportSchemaFieldValue || reportCustomFieldValue || fieldValue;
          break;

        case hasCustomDropdownOptions:
          var customDropdownOption = _lodash["default"].find(fieldCustomDropdownOptions, {
            value: reportJSONFieldValue
          });

          resolvedFieldValue = _lodash["default"].get(customDropdownOption, 'label');
          break;

        default:
          resolvedFieldValue = fieldValue;
          break;
      }

      if (_lodash["default"].isArray(resolvedFieldValue)) {
        var repeatableTranslation = translateRepeatables(translations, resolvedFieldValue);
        sectionFieldsAcc[fieldId] = {
          title: translations(fieldRenamedTitle || schemaPropLabel || fieldTitle),
          value: repeatableTranslation
        };
        return sectionFieldsAcc;
      }

      if (isMultiSelectable) {
        var foundValuesFromOptions = _lodash["default"].filter(schemaFieldOptions, function (_ref9) {
          var optionLabel = _ref9.label;

          var isPartOfLabel = _lodash["default"].includes(fieldValue, optionLabel);

          if (isPartOfLabel) {
            if (!_lodash["default"].includes(fieldValue, ',')) {
              var isSameOrGreaterCharacterCount = _lodash["default"].size(optionLabel) >= _lodash["default"].size(fieldValue);

              return isSameOrGreaterCharacterCount;
            } else {
              if (_lodash["default"].includes(optionLabel, ',')) {
                return true;
              } else {
                var terms = _lodash["default"].split(fieldValue, ',').map(function (term) {
                  return term.trim();
                });

                return _lodash["default"].includes(terms, optionLabel);
              }
            }
          }
        });

        var translatedValues = _lodash["default"].map(foundValuesFromOptions, function (_ref10) {
          var label = _ref10.label;
          return translations(label);
        });

        var translatedMultiSelect = _lodash["default"].join(translatedValues, ', ');

        sectionFieldsAcc[fieldId] = {
          title: translations(fieldRenamedTitle || schemaPropLabel || fieldTitle),
          value: translatedMultiSelect
        };
        return sectionFieldsAcc;
      }

      var valueTranslation = translations(resolvedFieldValue);
      sectionFieldsAcc[fieldId] = {
        title: translations(fieldRenamedTitle || schemaPropLabel || fieldTitle),
        value: valueTranslation
      };
      return sectionFieldsAcc;
    }, {});

    if (_lodash["default"].size(reportSectionFields) > 0) {
      acc[sectionId] = {
        title: translations(sectionTitle),
        fields: reportSectionFields
      };
    }

    return acc;
  }, {});
};

exports.generatePdfSchema = generatePdfSchema;

var translateRepeatables = function translateRepeatables(translations, resolvedFieldValue) {
  return _lodash["default"].map(resolvedFieldValue, function (repeatableItemArray) {
    if (_lodash["default"].isArray(repeatableItemArray)) {
      return _lodash["default"].map(repeatableItemArray, function (repeatableItem) {
        if (repeatableItem && _lodash["default"].isObject(repeatableItem)) {
          return _objectSpread(_objectSpread({}, repeatableItem), {}, {
            title: translations(repeatableItem.title),
            value: translations(repeatableItem.value)
          });
        }
      });
    }
  });
};

var useLookup = function useLookup(_ref11) {
  var lookup = _ref11.lookup,
      predicateBy = _ref11.predicateBy,
      resultBy = _ref11.resultBy,
      initialDataValue = _ref11.initialDataValue;
  var dataValue = initialDataValue;
  var lookupPredicate = predicateBy ? _defineProperty({}, predicateBy, dataValue) : dataValue;

  var valueAtCursor = _lodash["default"].find(lookup, lookupPredicate);

  return _fp["default"].getOr(valueAtCursor, resultBy, valueAtCursor);
};

var useModify = function useModify(_ref13) {
  var initialDataValue = _ref13.initialDataValue,
      dataValueModification = _ref13.dataValueModification;
  return dataValueModification(initialDataValue);
};

var parseDynamicSchema = function parseDynamicSchema() {
  var formValues = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var previousFormValues = arguments.length > 1 ? arguments[1] : undefined;
  var changeField = arguments.length > 2 ? arguments[2] : undefined;
  var formName = arguments.length > 3 ? arguments[3] : undefined;
  var openedInEditMode = arguments.length > 4 ? arguments[4] : undefined;
  var parentFormValues = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
  var organisationDetails = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : {};
  var formViewDetails = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : {};
  var professions = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : [];
  var options = arguments.length > 9 && arguments[9] !== undefined ? arguments[9] : {};
  var appHooks = arguments.length > 10 ? arguments[10] : undefined;
  return function (schema) {
    var batchChanges = options.batchChanges;

    var isFunctionBatchChanges = _lodash["default"].isFunction(batchChanges);

    var changes = [];
    var nextSchema = schema.map(function (field) {
      var iscomputedInitialValueActive = _lodash["default"].isFunction(field.computedInitialValue);

      return _objectSpread(_objectSpread({}, field), {}, {
        computedField: field.computedField ? field.computedField(formValues, parentFormValues, organisationDetails) : field.field,
        computedEditable: field.computedEditable ? field.computedEditable(formValues, parentFormValues, organisationDetails) : true,
        computedHidden: field.computedHidden ? field.computedHidden(formValues, parentFormValues, organisationDetails, formViewDetails, professions) : false,
        computedDisabled: field.computedDisabled ? field.computedDisabled(formValues, parentFormValues, organisationDetails) : false,
        computedNullFlavours: field.computedNullFlavours ? field.computedNullFlavours(formValues, parentFormValues, organisationDetails) : false,
        computedValue: _lodash["default"].isFunction(field.computedValue) ? field.computedValue(formValues, parentFormValues, organisationDetails, formViewDetails, professions) : null,
        computedRequired: field.computedRequired ? field.computedRequired(formValues, parentFormValues, organisationDetails, formViewDetails) : false,
        computedOptions: field.computedOptions ? field.computedOptions(formValues, parentFormValues, organisationDetails, formViewDetails, professions) : [],
        computedHelperText: _lodash["default"].isFunction(field.computedHelperText) ? field.computedHelperText(formValues, parentFormValues, organisationDetails, formViewDetails, professions) : null,
        computedLookUpOptionsListName: _lodash["default"].isFunction(field.computedLookUpOptionsListName) ? field.computedLookUpOptionsListName(formValues, parentFormValues, organisationDetails, formViewDetails, professions) : null,
        computedInitialValue: iscomputedInitialValueActive ? field.computedInitialValue(formValues, parentFormValues, organisationDetails, formViewDetails, professions) : null,
        iscomputedInitialValueActive: iscomputedInitialValueActive,
        onSubmit: field.onSubmit ? field.onSubmit(formValues, parentFormValues) : false,
        props: _objectSpread({
          formName: formName
        }, field.props)
      });
    }).map(function (dynamicField) {
      var fieldOverrides = _lodash["default"].get(options, "overrideSchema.".concat(dynamicField.id));

      if (_lodash["default"].isObject(fieldOverrides)) {
        _lodash["default"].forEach(fieldOverrides, function (value, path) {
          dynamicField = _lodash["default"].set(dynamicField, path, value);
        });
      }

      return dynamicField;
    }).map(function (dynamicField) {
      var computedEditable = dynamicField.computedEditable,
          computedValue = dynamicField.computedValue,
          computedDisabled = dynamicField.computedDisabled,
          disabled = dynamicField.disabled,
          computedRequired = dynamicField.computedRequired,
          computedNullFlavours = dynamicField.computedNullFlavours,
          computedHidden = dynamicField.computedHidden,
          computedOptions = dynamicField.computedOptions,
          computedHelperText = dynamicField.computedHelperText,
          onSubmit = dynamicField.onSubmit,
          formViewDetails = dynamicField.formViewDetails,
          computedField = dynamicField.computedField,
          computedLookUpOptionsListName = dynamicField.computedLookUpOptionsListName,
          computedInitialValue = dynamicField.computedInitialValue,
          iscomputedInitialValueActive = dynamicField.iscomputedInitialValueActive,
          field = _objectWithoutProperties(dynamicField, _excluded);

      var value = _lodash["default"].get(formValues, field.id);

      var previousValue = _lodash["default"].get(previousFormValues, field.id);

      var isNotHeading = _lodash["default"].get(field, 'props.isHeading', false) !== true;
      var isValidValue = computedValue || computedValue === '' || computedValue === false || computedValue === 0;
      var isNewValue = computedValue !== previousValue;

      if (isNotHeading && isValidValue && isNewValue) {
        if (!(_lodash["default"].isUndefined(previousValue) && computedValue === '')) {
          if (isFunctionBatchChanges) {
            changes.push(function () {
              return changeField(formName, field.id, computedValue);
            });
          } else {
            changeField(formName, field.id, computedValue);
          }
        }
      }

      if (iscomputedInitialValueActive) {
        if (_lodash["default"].isUndefined(value) && _lodash["default"].isUndefined(previousValue)) {
          if (isFunctionBatchChanges) {
            changes.push(function () {
              return changeField(formName, field.id, computedInitialValue);
            });
          } else {
            changeField(formName, field.id, computedInitialValue);
          }
        }
      }

      if ((_lodash["default"].isNull(computedValue) || _lodash["default"].isUndefined(computedValue)) && _lodash["default"].isUndefined(formValues[field.id])) {
        var defaultValue = _lodash["default"].get(field, 'props.defaultValue');

        if (!_lodash["default"].isUndefined(defaultValue)) {
          if (isFunctionBatchChanges) {
            changes.push(function () {
              return changeField(formName, field.id, defaultValue);
            });
          } else {
            changeField(formName, field.id, defaultValue);
          }
        }
      }

      var fieldProps = _objectSpread(_objectSpread({}, field), {}, {
        field: computedField,
        props: _objectSpread(_objectSpread({}, field.props), {}, {
          options: field.props.options || computedOptions,
          editable: field.props.editable || computedEditable,
          disabled: field.props.disabled || computedDisabled,
          required: field.props.required || computedRequired,
          helperText: field.props.helperText || computedHelperText,
          nullFlavours: computedNullFlavours ? computedNullFlavours : field.props.nullFlavours
        })
      });

      if (computedLookUpOptionsListName) {
        _lodash["default"].set(fieldProps, 'props.lookupOptions.listName', computedLookUpOptionsListName);
      }

      if (computedHidden) {
        fieldProps.props.type = 'hidden';
      }

      if (_fp["default"].isFunction(dynamicField.computedOtherValues)) {
        fieldProps = _fp["default"].set('props.onChange', function (event) {
          var eventValue = _fp["default"].get('target.value', event);

          handleComputedOtherValues(changeField, formName, field, [eventValue, formValues, parentFormValues], !_fp["default"].isNil(appHooks) ? appHooks : {});
        }, fieldProps);
      }

      if (!_fp["default"].isNil(dynamicField.otherFieldsDataMap)) {
        fieldProps = _fp["default"].set('props.handleChangeOtherFields', function (valueObj) {
          if (_fp["default"].isObject(valueObj) || _fp["default"].isString(valueObj)) {
            _lodash["default"].forIn(dynamicField.otherFieldsDataMap, function (dataColumn, fieldId) {
              var cursor = _fp["default"].getOr(dataColumn, 'path', dataColumn);

              var formValuePaths = _fp["default"].get('formValuePaths', dataColumn);

              if (formValuePaths && !_fp["default"].isArray(formValuePaths)) {
                return;
              }

              var condition = _fp["default"].get('condition', dataColumn);

              if (condition && _lodash["default"].isFunction(condition)) {
                var newPath = condition(formValuePaths, valueObj);

                if (!newPath) {
                  return;
                }

                changeField(formName, fieldId, _fp["default"].getOr('', newPath, formValues));
                return;
              }

              var dataValueModification = _fp["default"].get('dataValueModification', dataColumn);

              if (_fp["default"].isString(cursor) || _lodash["default"].isEqual(cursor, dataColumn) && _lodash["default"].isFunction(dataValueModification)) {
                var lookup = _fp["default"].get('lookup', dataColumn);

                var predicateBy = _fp["default"].get('predicateBy', dataColumn);

                var resultBy = _fp["default"].get('resultBy', dataColumn);

                var initialDataValue = _fp["default"].getOr('', cursor, valueObj);

                if (dataValueModification) {
                  changeField(formName, fieldId, useModify({
                    initialDataValue: initialDataValue,
                    dataValueModification: dataValueModification
                  }));
                  return;
                }

                if (lookup) {
                  changeField(formName, fieldId, useLookup({
                    lookup: lookup,
                    predicateBy: predicateBy,
                    resultBy: resultBy,
                    initialDataValue: initialDataValue
                  }));
                  return;
                }

                changeField(formName, fieldId, initialDataValue);
              }

              if (_fp["default"].isArray(cursor)) {
                var concatSeparator = _fp["default"].getOr(' ', 'concatSeparator', dataColumn);

                var initialDataValues = _fp["default"].map(function (path) {
                  return _fp["default"].getOr('', path, valueObj);
                }, cursor);

                var joinedDataValue = _lodash["default"].join(initialDataValues, concatSeparator);

                var trimmedDataValue = _lodash["default"].trimEnd(joinedDataValue);

                var _dataValueModification = _fp["default"].get('dataValueModification', dataColumn);

                if (_dataValueModification) {
                  changeField(formName, fieldId, useModify({
                    initialDataValue: initialDataValues,
                    dataValueModification: _dataValueModification
                  }));
                  return;
                }

                changeField(formName, fieldId, trimmedDataValue);
                return;
              }
            });
          }

          if (_lodash["default"].isNull(valueObj)) {
            var otherFieldChanges = [];

            _lodash["default"].forIn(dynamicField.otherFieldsDataMap, function (_dataColumn, fieldId) {
              var changeFieldArguments = [formName, fieldId, (0, _computedValue.clearField)()];

              if (isFunctionBatchChanges) {
                otherFieldChanges.push(function () {
                  return changeField.apply(void 0, changeFieldArguments);
                });
              } else {
                changeField.apply(void 0, changeFieldArguments);
              }
            });

            if (otherFieldChanges.length > 0 && isFunctionBatchChanges) {
              batchChanges(function () {
                _lodash["default"].forEach(otherFieldChanges, function (change) {
                  change();
                });
              });
            }
          }
        }, fieldProps);
      }

      return fieldProps;
    });

    if (changes.length > 0 && isFunctionBatchChanges) {
      batchChanges(function () {
        _lodash["default"].forEach(changes, function (change) {
          change();
        });
      });
    }

    return nextSchema;
  };
};

exports.parseDynamicSchema = parseDynamicSchema;

var sectionShouldRender = function sectionShouldRender(_ref14) {
  var _ref14$fields = _ref14.fields,
      fields = _ref14$fields === void 0 ? [] : _ref14$fields;
  var render = false;

  _lodash["default"].forEach(fields, function (field) {
    var fieldProps = _lodash["default"].get(field, 'props');

    if (fieldProps.type !== 'hidden' || fieldProps.visible && !fieldProps.isEditable) {
      render = true;
    }
  });

  return render;
};

exports.sectionShouldRender = sectionShouldRender;

var getReportStatus = function getReportStatus() {
  var formValues = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var status = 'complete';

  var hasExperiencedReaction = _lodash["default"].get(formValues, 'hasexperiencedreaction', null);

  if (hasExperiencedReaction === '2') {
    status = 'inProgress';
  }

  return status;
};

exports.getReportStatus = getReportStatus;

var __getDependents = function __getDependents(fieldCondition, sectionFields) {
  var hasValidProps = hasAnyKeys({
    keys: ['all', 'any'],
    object: fieldCondition
  });

  if (hasValidProps) {
    var allFacts = _lodash["default"].get(fieldCondition, 'all', []);

    var anyFacts = _lodash["default"].get(fieldCondition, 'any', []);

    var facts = _lodash["default"].merge(allFacts, anyFacts);

    var dependents = _lodash["default"].map(facts, function (fact) {
      var factsHasConditions = hasAnyKeys({
        keys: ['all', 'any'],
        object: fact
      });

      if (factsHasConditions) {
        return __getDependents(fact, sectionFields);
      }

      var fieldId = _lodash["default"].get(fact, 'fact');

      if (_lodash["default"].find(sectionFields, {
        id: fieldId
      })) {
        return _lodash["default"].get(fact, 'fact');
      }

      return undefined;
    });

    var flattenedDependents = _lodash["default"].flattenDeep(dependents);

    var uniqueDependents = _lodash["default"].uniq(flattenedDependents);

    return _lodash["default"].compact(uniqueDependents);
  }

  return [];
};

var filterFields = function () {
  var _ref16 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(_ref15) {
    var section, _ref15$formValues, formValues, changeField, formName, batchChanges, fields, sectionConditions, changes, isFunctionBatchChanges, isFunctionChangeField, results, initiallyFilteredResults, finalResults;

    return regeneratorRuntime.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            section = _ref15.section, _ref15$formValues = _ref15.formValues, formValues = _ref15$formValues === void 0 ? {} : _ref15$formValues, changeField = _ref15.changeField, formName = _ref15.formName, batchChanges = _ref15.batchChanges;
            fields = _lodash["default"].get(section, 'schema', []);
            sectionConditions = _lodash["default"].get(section, 'conditions', {});
            changes = [];
            isFunctionBatchChanges = _lodash["default"].isFunction(batchChanges);
            isFunctionChangeField = _lodash["default"].isFunction(changeField);
            results = _lodash["default"].map(fields, function () {
              var _ref17 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(field) {
                var fieldPath, fieldCondition, combinedConditions, hasValidProps, validateField, fieldVisible;
                return regeneratorRuntime.wrap(function _callee$(_context) {
                  while (1) {
                    switch (_context.prev = _context.next) {
                      case 0:
                        fieldPath = _lodash["default"].get(field, 'props.name', _lodash["default"].get(field, 'id'));
                        fieldCondition = _lodash["default"].get(field, 'props.conditions', {});
                        combinedConditions = _lodash["default"].merge(fieldCondition, sectionConditions);
                        hasValidProps = hasAnyKeys({
                          keys: ['all', 'any'],
                          object: fieldCondition
                        }) || hasAnyKeys({
                          keys: ['all', 'any'],
                          object: sectionConditions
                        });

                        if (!hasValidProps) {
                          _context.next = 24;
                          break;
                        }

                        _context.prev = 5;
                        _context.next = 8;
                        return _jsonRulesService["default"].validateConditions({
                          conditions: combinedConditions,
                          data: _lodash["default"].omitBy(formValues, _lodash["default"].isUndefined)
                        });

                      case 8:
                        validateField = _context.sent;
                        fieldVisible = _lodash["default"].get(validateField, 'success') === true;

                        if (!fieldVisible) {
                          _context.next = 14;
                          break;
                        }

                        return _context.abrupt("return", field);

                      case 14:
                        if (isFunctionBatchChanges) {
                          changes.push(function () {
                            return changeField(formName, fieldPath, '');
                          });
                        } else if (isFunctionChangeField) {
                          changeField(formName, fieldPath, '');
                        }

                        return _context.abrupt("return", undefined);

                      case 16:
                        _context.next = 22;
                        break;

                      case 18:
                        _context.prev = 18;
                        _context.t0 = _context["catch"](5);
                        console.log('RulesEngine(filterFields): ', _context.t0.message);
                        return _context.abrupt("return", field);

                      case 22:
                        _context.next = 25;
                        break;

                      case 24:
                        return _context.abrupt("return", field);

                      case 25:
                      case "end":
                        return _context.stop();
                    }
                  }
                }, _callee, null, [[5, 18]]);
              }));

              return function (_x2) {
                return _ref17.apply(this, arguments);
              };
            }());
            _context2.next = 9;
            return Promise.all(results);

          case 9:
            initiallyFilteredResults = _context2.sent;
            finalResults = _lodash["default"].map(initiallyFilteredResults, function (field) {
              var fieldCondition = _lodash["default"].get(field, 'props.conditions', []);

              var fieldPath = _lodash["default"].get(field, 'props.name', _lodash["default"].get(field, 'id'));

              var hasValidProps = hasAnyKeys({
                keys: ['all', 'any'],
                object: fieldCondition
              });

              if (hasValidProps) {
                var fieldDependents = __getDependents(fieldCondition, fields);

                var dependentsAreShown = _lodash["default"].map(fieldDependents, function (dependentId) {
                  if (_lodash["default"].find(initiallyFilteredResults, {
                    id: dependentId
                  })) {
                    return true;
                  }
                }).every(Boolean);

                if (dependentsAreShown) {
                  return field;
                } else {
                  if (isFunctionBatchChanges) {
                    changes.push(function () {
                      return changeField(formName, fieldPath, '');
                    });
                  } else {
                    changeField(formName, fieldPath, '');
                  }

                  return undefined;
                }
              }

              return field;
            });

            if (changes.length > 0 && isFunctionBatchChanges) {
              batchChanges(function () {
                _lodash["default"].forEach(changes, function (change) {
                  change();
                });
              });
            }

            return _context2.abrupt("return", _lodash["default"].compact(finalResults));

          case 13:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));

  return function filterFields(_x) {
    return _ref16.apply(this, arguments);
  };
}();

var isSectionVisible = function () {
  var _ref19 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(_ref18) {
    var _ref18$formValues, formValues, section, isVisible, sectionConditions, hasValues, hasConditions, validateConditionsResult;

    return regeneratorRuntime.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            _ref18$formValues = _ref18.formValues, formValues = _ref18$formValues === void 0 ? {} : _ref18$formValues, section = _ref18.section;
            isVisible = _lodash["default"].get(section, 'visible', false);
            sectionConditions = _lodash["default"].get(section, 'conditions');
            hasValues = !_lodash["default"].isEmpty(formValues);
            hasConditions = !_lodash["default"].isEmpty(sectionConditions);

            if (!(hasValues && hasConditions)) {
              _context3.next = 16;
              break;
            }

            _context3.prev = 6;
            _context3.next = 9;
            return _jsonRulesService["default"].validateConditions({
              conditions: sectionConditions,
              data: _lodash["default"].omitBy(getCustomQuestionsToRoot(formValues), _lodash["default"].isUndefined)
            });

          case 9:
            validateConditionsResult = _context3.sent;
            isVisible = _lodash["default"].get(validateConditionsResult, 'success') === true;
            _context3.next = 16;
            break;

          case 13:
            _context3.prev = 13;
            _context3.t0 = _context3["catch"](6);
            console.log('RulesEngine(isSectionVisible): ', _context3.t0.message);

          case 16:
            return _context3.abrupt("return", isVisible);

          case 17:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3, null, [[6, 13]]);
  }));

  return function isSectionVisible(_x3) {
    return _ref19.apply(this, arguments);
  };
}();

var getCustomQuestionsToRoot = function getCustomQuestionsToRoot(allValues) {
  var customQuestions = _lodash["default"].get(allValues, 'custom');

  var exposedQuestions = {};

  if (_lodash["default"].size(customQuestions) !== 0) {
    _lodash["default"].forEach(customQuestions, function (section) {
      var keys = _lodash["default"].keys(section);

      _lodash["default"].forEach(keys, function (questionKey) {
        exposedQuestions[questionKey] = section[questionKey];
      });
    });
  }

  return _objectSpread(_objectSpread({}, allValues), exposedQuestions);
};

exports.getCustomQuestionsToRoot = getCustomQuestionsToRoot;

var parseDynamicSchemaSections = function parseDynamicSchemaSections(_ref20) {
  var _ref20$schema = _ref20.schema,
      schema = _ref20$schema === void 0 ? {} : _ref20$schema,
      _ref20$formValues = _ref20.formValues,
      formValues = _ref20$formValues === void 0 ? {} : _ref20$formValues,
      _ref20$denyList = _ref20.denyList,
      denyList = _ref20$denyList === void 0 ? [] : _ref20$denyList,
      changeField = _ref20.changeField,
      formName = _ref20.formName,
      batchChanges = _ref20.batchChanges;

  var schemaSections = _lodash["default"].omit(schema, denyList);

  var values = _lodash["default"].assign({}, formValues);

  var tasks = [];

  var _loop = function _loop(sectionKey) {
    var section = schemaSections[sectionKey];
    tasks.push(function () {
      return Promise.all([isSectionVisible({
        section: section,
        formValues: values
      }), filterFields({
        section: section,
        formValues: getCustomQuestionsToRoot(values),
        changeField: changeField,
        formName: formName,
        batchChanges: batchChanges
      })]).then(function (_ref22) {
        var _ref23 = _slicedToArray(_ref22, 2),
            isVisible = _ref23[0],
            filteredFields = _ref23[1];

        return {
          sectionKey: sectionKey,
          isVisible: isVisible,
          section: _lodash["default"].assign({}, section, {
            schema: filteredFields
          })
        };
      });
    });
  };

  for (var sectionKey in schemaSections) {
    _loop(sectionKey);
  }

  return Promise.all(_lodash["default"].map(tasks, function (task) {
    return task();
  })).then(function (sections) {
    var parsedSchema = {};

    _lodash["default"].forEach(sections, function (_ref21) {
      var sectionKey = _ref21.sectionKey,
          isVisible = _ref21.isVisible,
          section = _ref21.section;

      if (isVisible) {
        parsedSchema[sectionKey] = section;
      }
    });

    return parsedSchema;
  });
};

exports.parseDynamicSchemaSections = parseDynamicSchemaSections;

var parseDynamicViewJSON = function () {
  var _ref25 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(_ref24) {
    var _ref24$viewJSON, viewJSON, _ref24$formValues, formValues, values, parsedViewJSON, _iterator, _step, section, isVisible, nextSectionFields;

    return regeneratorRuntime.wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            _ref24$viewJSON = _ref24.viewJSON, viewJSON = _ref24$viewJSON === void 0 ? [] : _ref24$viewJSON, _ref24$formValues = _ref24.formValues, formValues = _ref24$formValues === void 0 ? {} : _ref24$formValues;
            values = _lodash["default"].clone(formValues);
            parsedViewJSON = [];
            _iterator = _createForOfIteratorHelper(viewJSON);
            _context4.prev = 4;

            _iterator.s();

          case 6:
            if ((_step = _iterator.n()).done) {
              _context4.next = 17;
              break;
            }

            section = _step.value;
            _context4.next = 10;
            return isSectionVisible({
              section: section,
              formValues: values
            });

          case 10:
            isVisible = _context4.sent;
            _context4.next = 13;
            return filterFields({
              section: section,
              formValues: formValues
            });

          case 13:
            nextSectionFields = _context4.sent;
            parsedViewJSON.push(_objectSpread(_objectSpread({}, section), {}, {
              visible: isVisible,
              schema: nextSectionFields
            }));

          case 15:
            _context4.next = 6;
            break;

          case 17:
            _context4.next = 22;
            break;

          case 19:
            _context4.prev = 19;
            _context4.t0 = _context4["catch"](4);

            _iterator.e(_context4.t0);

          case 22:
            _context4.prev = 22;

            _iterator.f();

            return _context4.finish(22);

          case 25:
            return _context4.abrupt("return", parsedViewJSON);

          case 26:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4, null, [[4, 19, 22, 25]]);
  }));

  return function parseDynamicViewJSON(_x4) {
    return _ref25.apply(this, arguments);
  };
}();

exports.parseDynamicViewJSON = parseDynamicViewJSON;

var flattenViewJSON = function flattenViewJSON(_ref26) {
  var viewJson = _ref26.viewJson;
  var flattened = {
    sections: {},
    fields: {}
  };

  _lodash["default"].forEach(viewJson, function (_ref27, index) {
    var fields = _ref27.fields,
        blocks = _ref27.blocks,
        sectionId = _ref27.id,
        name = _ref27.name,
        visible = _ref27.visible,
        other = _objectWithoutProperties(_ref27, _excluded2);

    _lodash["default"].set(flattened, "sections.".concat(sectionId), _objectSpread({
      id: sectionId,
      name: name,
      visible: visible,
      index: index
    }, other));

    var tempSectionFields = [];

    _lodash["default"].forEach(fields, function (_ref28, index) {
      var fieldId = _ref28.id,
          subFields = _ref28.fields,
          fields = _ref28.fields,
          other = _objectWithoutProperties(_ref28, _excluded3);

      var fieldObject = _objectSpread(_objectSpread({
        id: fieldId,
        sectionId: sectionId
      }, other), {}, {
        index: index
      });

      if (subFields) {
        _lodash["default"].forEach(subFields, function (_ref29, fieldIndex) {
          var repeatableId = _ref29.id,
              props = _objectWithoutProperties(_ref29, _excluded4);

          var index = props && props.index ? props.index : fieldIndex;

          var existingFields = _lodash["default"].get(fieldObject, 'fields', {});

          var subFieldObject = _objectSpread(_objectSpread({
            fieldId: fieldId,
            id: repeatableId
          }, props), {}, {
            index: index
          });

          _lodash["default"].set(fieldObject, 'fields', _objectSpread(_objectSpread({}, existingFields), {}, _defineProperty({}, repeatableId, subFieldObject)));
        });
      }

      tempSectionFields.push(fieldObject);
    });

    var sortedSectionFields = _lodash["default"].sortBy(tempSectionFields, ['index']);

    _lodash["default"].forEach(sortedSectionFields, function (field, index) {
      var id = _lodash["default"].get(field, 'id');

      _lodash["default"].set(flattened, "fields.".concat(id), _objectSpread(_objectSpread({}, field), {}, {
        index: index
      }));
    });
  });

  return flattened;
};

exports.flattenViewJSON = flattenViewJSON;

var __lintAdditional = function __lintAdditional(toLint) {
  if (toLint.index !== undefined) {
    delete toLint.index;
  }

  if (toLint.sectionId !== undefined) {
    delete toLint.sectionId;
  }

  if (toLint.fieldId !== undefined) {
    delete toLint.fieldId;
  }

  if (_lodash["default"].isArray(toLint.fields)) {
    _lodash["default"].map(toLint.fields, function (field) {
      return __lintAdditional(field);
    });
  }

  return toLint;
};

var moveSystemFields = function moveSystemFields(viewJSON) {
  var systemFields = _lodash["default"].uniqBy(_lodash["default"].flatten(_lodash["default"].map(viewJSON, function () {
    var _ref30 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref30$fields = _ref30.fields,
        fields = _ref30$fields === void 0 ? [] : _ref30$fields;

    return _lodash["default"].filter(fields, {
      editable: false
    });
  })), 'id');

  var _$find = _lodash["default"].find(viewJSON, {
    visible: true
  }),
      firstVisibleSectionId = _$find.id;

  var nextViewJSON = _lodash["default"].map(viewJSON, function (section) {
    var nextSection = _lodash["default"].cloneDeep(section);

    nextSection.fields = _lodash["default"].reject(nextSection.fields, {
      editable: false
    });

    if (section.id === firstVisibleSectionId) {
      nextSection.fields = _lodash["default"].concat(nextSection.fields, systemFields);
    }

    return nextSection;
  });

  return nextViewJSON;
};

exports.moveSystemFields = moveSystemFields;

var filterConditions = function filterConditions(conditions) {
  return _lodash["default"].map(conditions, function (condition) {
    return _lodash["default"].pick(condition, ['id', 'conditionVersionId']);
  });
};

var unflattenViewJSON = function unflattenViewJSON(flattenedJson, getConditionsForResource) {
  var _ref31 = flattenedJson || {},
      sections = _ref31.sections,
      fields = _ref31.fields;

  var combinedSections = _lodash["default"].merge({}, sections);

  var combinedFields = _lodash["default"].merge({}, fields);

  var unflat = [];

  _lodash["default"].forOwn(combinedSections, function (section, ownSectionId) {
    _lodash["default"].forOwn(combinedFields, function (field, fieldId) {
      var sectionId = _lodash["default"].get(field, 'sectionId');

      if (sectionId === ownSectionId) {
        var currentFields = _lodash["default"].get(section, 'fields', []);

        if (getConditionsForResource && !!_lodash["default"].get(field, 'isBlock')) {
          var blockConditions = getConditionsForResource({
            resourceType: 'BLOCK',
            resourceId: fieldId
          });

          _lodash["default"].set(field, 'conditions', filterConditions(blockConditions));
        }

        if (!!_lodash["default"].get(field, 'fields')) {
          field.fields = _lodash["default"].sortBy(field.fields, 'index');
        }

        _lodash["default"].set(section, 'fields', [].concat(_toConsumableArray(currentFields), [_objectSpread({
          id: fieldId
        }, field)]));
      }
    });

    if (getConditionsForResource) {
      var sectionConditions = getConditionsForResource({
        resourceType: 'SECTION',
        resourceId: ownSectionId
      });

      _lodash["default"].set(section, 'conditions', filterConditions(sectionConditions));
    }

    if (ownSectionId && ownSectionId !== '0') {
      var sortedFields = _lodash["default"].sortBy(_lodash["default"].get(section, 'fields', []), 'index');

      var nextSection = _objectSpread(_objectSpread({
        id: ownSectionId
      }, section), {}, {
        fields: sortedFields
      });

      unflat.push(nextSection);
    }
  });

  return moveSystemFields(_lodash["default"].map(_lodash["default"].sortBy(unflat, 'index'), function (section) {
    return __lintAdditional(section);
  }));
};

exports.unflattenViewJSON = unflattenViewJSON;

var parseSectionFields = function parseSectionFields() {
  var fields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var parsedFields = [];

  _lodash["default"].forEach(fields, function (field) {
    var _field$isBlock = field.isBlock,
        isBlock = _field$isBlock === void 0 ? false : _field$isBlock,
        _field$fields = field.fields,
        fields = _field$fields === void 0 ? [] : _field$fields,
        conditions = field.conditions,
        visible = field.visible;

    if (isBlock === true && visible === true && _lodash["default"].size(fields) > 0) {
      var nextFields = _lodash["default"].map(fields, function (field) {
        return _objectSpread({
          conditions: conditions
        }, field);
      });

      parsedFields.push.apply(parsedFields, _toConsumableArray(nextFields));
    } else {
      parsedFields.push(field);
    }
  });

  return parsedFields;
};

exports.parseSectionFields = parseSectionFields;

var hasAllKeys = function hasAllKeys(_ref32) {
  var keys = _ref32.keys,
      object = _ref32.object;

  var requiredKeys = _lodash["default"].castArray(keys);

  return _lodash["default"].every(requiredKeys, _lodash["default"].partial(_lodash["default"].has, object));
};

exports.hasAllKeys = hasAllKeys;

var hasAnyKeys = function hasAnyKeys(_ref33) {
  var keys = _ref33.keys,
      object = _ref33.object;

  var requiredKeys = _lodash["default"].castArray(keys);

  return _lodash["default"].some(requiredKeys, _lodash["default"].partial(_lodash["default"].has, object));
};

exports.hasAnyKeys = hasAnyKeys;

var getOffsetDate = function getOffsetDate(offset) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'years';
  return (0, _moment["default"])().subtract(offset, unit).format('YYYYMMDD');
};

exports.getOffsetDate = getOffsetDate;

var computeDevMode = function computeDevMode() {
  var production = 'production';

  if (typeof window !== 'undefined') {
    return _lodash["default"].get(window, 'env.REACT_APP_CONFIG_ENV') !== production;
  }

  if (typeof process !== 'undefined') {
    var appConfigEnv = _lodash["default"].get(process, 'env.REACT_APP_CONFIG_ENV');

    if (appConfigEnv) {
      return appConfigEnv !== production;
    }

    var nodeEnv = _lodash["default"].get(process, 'env.REACT_APP_CONFIG_ENV');

    if (nodeEnv) {
      return appConfigEnv !== production;
    }
  }

  return false;
};

var isDevMode = computeDevMode();
exports.isDevMode = isDevMode;

var callEveryFunctionWithArgs = function callEveryFunctionWithArgs(funcs) {
  return function () {
    return _fp["default"].compose(_fp["default"].findLast(function (value) {
      return value !== undefined;
    }), _fp["default"].over(funcs)).apply(void 0, arguments);
  };
};

exports.callEveryFunctionWithArgs = callEveryFunctionWithArgs;

var castFieldStringToArray = function castFieldStringToArray(field) {
  return function (values) {
    var value = _lodash["default"].get(values, field);

    if (_lodash["default"].isString(value) && value.length > 0) {
      return _fp["default"].castArray(value);
    }
  };
};

exports.castFieldStringToArray = castFieldStringToArray;