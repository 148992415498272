import { tableActions } from './enums'

export const handleRequestSort = (dispatch, state) => property => {
    if (state.sort.field === property) {
        dispatch({ type: tableActions.CHANGE_SORT_ORDER })
    } else {
        dispatch({ type: tableActions.CHANGE_SORT_FIELD, payload: { field: property } })
    }
}

export const handleChangePage = dispatch => (_event, newPage) => {
    dispatch({ type: tableActions.CHANGE_PAGE, payload: { page: newPage } })
}

export const handleChangeRowsPerPage = dispatch => event => {
    dispatch({ type: tableActions.CHANGE_PAGE_SIZE, payload: { size: parseInt(event.target.value, 10) } })
    dispatch({ type: tableActions.CHANGE_PAGE, payload: { page: 0 } })
};