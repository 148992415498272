import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import CommunicationsHeader from '../../CommunicationsHeader'
import TemplateSection from '../../TemplateSection'
import style from './style'

const renderTemplateSections = (types) => {
  return _.map(types, (type) => {
    return <TemplateSection channel='push' key={type} type={type} />
  })
}

const CommunicationsPushScreen = (props) => {
  const { pageTitle, classes, pushTemplateTypes } = props

  return <Fragment>
    <CommunicationsHeader channel='push' pageTitle = {pageTitle} />
    <div className={classes.container}>
      {renderTemplateSections(pushTemplateTypes)}
    </div>
  </Fragment>
}

CommunicationsPushScreen.propTypes = {
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired,
  classes: PropTypes.object.isRequired,
  pushTemplateTypes: PropTypes.array,
}

export default withStyles(style)(CommunicationsPushScreen)
