const styles = theme => {
  return {
    row: {
      height: 48
    },
    cell: {
      backgroundColor: theme.palette.grey['200'],
      textTransform: 'uppercase',
      color: theme.palette.grey['900'],
      fontSize: '13px',
      fontWeight: 'bold',
      alignItems: 'center',
      borderBottom: 'none'
    },
    center: {
      textAlign: 'center'
    },
    right: {
      textAlign: 'right'
    }
  }
}

export default styles
