"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  'label': 'Algeria',
  'value': 'C56'
}, {
  'label': 'Argentina',
  'value': '01'
}, {
  'label': 'Australia',
  'value': '02'
}, {
  'label': 'Austria',
  'value': '03'
}, {
  'label': 'Baltic States',
  'value': 'C72'
}, {
  'label': 'Belarus',
  'value': 'C73'
}, {
  'label': 'Belgium',
  'value': '04'
}, {
  'label': 'Bosnia and Herzegovina',
  'value': 'C77'
}, {
  'label': 'Brazil',
  'value': '05'
}, {
  'label': 'Bulgaria',
  'value': 'C57'
}, {
  'label': 'Cambodia',
  'value': 'C71'
}, {
  'label': 'Canada',
  'value': '06'
}, {
  'label': 'Chile',
  'value': '07'
}, {
  'label': 'China',
  'value': 'C49'
}, {
  'label': 'Colombia',
  'value': '08'
}, {
  'label': 'Croatia',
  'value': 'C58'
}, {
  'label': 'Czech Republic',
  'value': '09'
}, {
  'label': 'Denmark',
  'value': 'C53'
}, {
  'label': 'Dominican Republic',
  'value': 'C59'
}, {
  'label': 'Dubai',
  'value': 'C69'
}, {
  'label': 'EEMRO',
  'value': '11'
}, {
  'label': 'Ecuador',
  'value': '10'
}, {
  'label': 'Egypt',
  'value': 'C60'
}, {
  'label': 'Finland',
  'value': 'C52'
}, {
  'label': 'France',
  'value': '12'
}, {
  'label': 'Germany',
  'value': '13'
}, {
  'label': 'Global',
  'value': 'C82'
}, {
  'label': 'Greece',
  'value': '14'
}, {
  'label': 'Guatemala',
  'value': '15'
}, {
  'label': 'Gulf',
  'value': 'C79'
}, {
  'label': 'Hong Kong',
  'value': '16'
}, {
  'label': 'Hungary',
  'value': '17'
}, {
  'label': 'India',
  'value': '18'
}, {
  'label': 'Indonesia',
  'value': '19'
}, {
  'label': 'Iran',
  'value': 'C61'
}, {
  'label': 'Ireland',
  'value': 'C78'
}, {
  'label': 'Israel',
  'value': '20'
}, {
  'label': 'Italy',
  'value': '21'
}, {
  'label': 'Japan',
  'value': '22'
}, {
  'label': 'Kazakhstan',
  'value': 'C75'
}, {
  'label': 'Korea',
  'value': '23'
}, {
  'label': 'Levant',
  'value': 'C80'
}, {
  'label': 'Libya',
  'value': 'C62'
}, {
  'label': 'MEARO',
  'value': '25'
}, {
  'label': 'Malaysia',
  'value': '24'
}, {
  'label': 'Mexico',
  'value': '26'
}, {
  'label': 'NENWA',
  'value': 'C81'
}, {
  'label': 'Netherlands',
  'value': '27'
}, {
  'label': 'New Zealand',
  'value': '28'
}, {
  'label': 'Norway',
  'value': 'C54'
}, {
  'label': 'Pakistan',
  'value': '29'
}, {
  'label': 'Paraguay',
  'value': 'C63'
}, {
  'label': 'Peru',
  'value': '30'
}, {
  'label': 'Philippines',
  'value': '31'
}, {
  'label': 'Poland',
  'value': '32'
}, {
  'label': 'Portugal',
  'value': '33'
}, {
  'label': 'Puerto Rico',
  'value': '34'
}, {
  'label': 'Romania',
  'value': 'C50'
}, {
  'label': 'Russia',
  'value': '46'
}, {
  'label': 'Saudi Arabia',
  'value': 'C64'
}, {
  'label': 'Serbia',
  'value': 'C65'
}, {
  'label': 'Singapore',
  'value': '36'
}, {
  'label': 'Slovak Republic',
  'value': '47'
}, {
  'label': 'Slovenia',
  'value': 'C66'
}, {
  'label': 'South Africa',
  'value': '37'
}, {
  'label': 'Spain',
  'value': '38'
}, {
  'label': 'Sweden',
  'value': 'C55'
}, {
  'label': 'Switzerland',
  'value': '39'
}, {
  'label': 'Taiwan',
  'value': '40'
}, {
  'label': 'Thailand',
  'value': '41'
}, {
  'label': 'Tunisia',
  'value': 'C67'
}, {
  'label': 'Turkey',
  'value': '42'
}, {
  'label': 'UK',
  'value': '43'
}, {
  'label': 'Ukraine',
  'value': 'C68'
}, {
  'label': 'United Arab Emirates',
  'value': 'C76'
}, {
  'label': 'Uruguay',
  'value': '44'
}, {
  'label': 'Uzbekistan',
  'value': 'C74'
}, {
  'label': 'Venezuela',
  'value': '45'
}, {
  'label': 'Vietnam',
  'value': 'C51'
}];
exports["default"] = _default;