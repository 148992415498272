import { useState, useEffect } from 'react'
import { useIsSSR } from './useIsSSR'

export const useNetworkStatus = () => {
  const isSSR = useIsSSR()
  const [isOnline, setIsOnline] = useState()

  const onlineHandler = () => {
    setIsOnline(true)
  }

  const offlineHandler = () => {
    setIsOnline(false)
  }

  useEffect(() => {
    if(!isSSR) {
      setIsOnline(navigator.onLine)

      window.addEventListener("online", onlineHandler)
      window.addEventListener("offline", offlineHandler)
  
      return () => {
        window.removeEventListener("online", onlineHandler)
        window.removeEventListener("offline", offlineHandler)
      }
    }
  }, [isSSR])

  return isOnline
}