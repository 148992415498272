import React from 'react'
import PropTypes from 'prop-types'
import style from './style'
import { withStyles } from '@material-ui/core/styles'

const Li = ({ value, givenClass, classes }) => (
  <div className={classes.ulBullet}>
    <div className={`${classes.li} ${givenClass}`}>
      {value}
    </div>
  </div>
)

Li.propTypes = {
  value: PropTypes.any
}

export default withStyles(style)(Li)
