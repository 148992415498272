import _ from 'lodash'
import { promiseReducer } from '../../util'

class ReportsReducers {
  searchReportsFresh = promiseReducer(
    (state, action) => {
      const { query, storeIds, dateFrom, dateTo, reportStatus, reportType, reportSource } = action
      const results = _.get(action, 'result.results')
      const total = _.get(action, 'result.total')
      return {
        ...state,
        page: 1,
        total,
        results,
        query,
        storeIds,
        dateFrom,
        dateTo,
        reportStatus,
        reportType,
        reportSource
      }
    }
  )

  searchReportsNext = promiseReducer(
    (state, action) => {
      const { results } = state
      const { query, storeIds, dateFrom, dateTo, reportStatus, reportType, reportSource } = action
      const newResults = _.get(action, 'result.results')
      const total = _.get(action, 'result.total')
      return {
        ...state,
        total,
        page: state.page + 1,
        results: [ ...results, ...newResults ],
        query,
        storeIds,
        dateFrom,
        dateTo,
        reportStatus,
        reportType,
        reportSource
      }
    }
  )

  changeFilter = (state, action) => {
    const { filter } = action
    const { filters } = state
    return {
      ...state,
      filters: {
        ...filters,
        [filter.name]: filter.value
      }
    }
  }

  changeFilters = (state, action) => {
    const { filters } = action
    return {
      ...state,
      filters
    }
  }

  fetchReportsByExtId = promiseReducer(
    (state, action) => {
      const { count, rows } = action.result
      return {
        ...state,
        total: count,
        results: rows,
        page: 1
      }
    }
  )
}
export default new ReportsReducers()
