import { useEffect, useState } from 'react'

const useInputType = (type) => {
  const [isBrowserCompatible, setIsBrowserCompatible] = useState(false)

  useEffect(() => {
    let input = document.createElement("input")
    input.setAttribute("type", type)
    setIsBrowserCompatible(input.type == type)
    return () => input = null
  })

  return isBrowserCompatible
}

export default useInputType