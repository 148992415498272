const styles = theme => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}

export default styles
