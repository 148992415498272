import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import P from '../../../../../components/P'
import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../../../config'
import { constants as formViewConstants } from '../../../../../store/modules/formViews'

import styles from './style'

const ExportForm = Form(formViewConstants.EXPORT_CONFIGURATION_FORM)

const ExportModal = (props) => {
  const {
    classes,
    actions,
    initialValues
  } = props

  const isSaved = !_.isEmpty(initialValues)

  return (
    <BasicModalOverlay
      title={translations('Export Report Configuration - Modal Heading')}
      actions={actions}
    >
      {!isSaved && <P value={translations('Export Report Configuration - New Configuration')} />}
      {isSaved && <ExportForm initialValues={initialValues}>
        <ContentBoxBody>
          <div className={classes.container}>
            <div className={classes.intro}>
              <P value={translations('Export Report Configuration - Intro')} />
              <P value={translations('Export Report Configuration - Intro ID')} />
              <P value={translations('Export Report Configuration - Intro viewJSON')} />
            </div>
            <FormBody
              schema={[
                {
                  id: 'formViewId',
                  field: 'Input',
                  props: {
                    label: translations('Export Report Configuration - Report configuration ID'),
                    name: 'formViewId',
                    disabled: true
                  }
                },
                {
                  id: 'viewJSON',
                  field: 'Input',
                  props: {
                    label: translations('Export Report Configuration - viewJSON'),
                    name: 'viewJSON',
                    multiline: true,
                    rows: 10,
                    disabled: true
                  }
                }
              ]}
              editing
              fullWidthFields
            />
          </div>
        </ContentBoxBody>
      </ExportForm>}
    </BasicModalOverlay>
  )
}

ExportModal.propTypes = {
  classes: PropTypes.any,
  actions: PropTypes.array.isRequired,
  initialValues: PropTypes.shape({
    formViewId: PropTypes.string.isRequired,
    viewJSON: PropTypes.string
  })
}

export default withStyles(styles)(ExportModal)
