import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../config'
import style from './style'

const SkipToContentLink = ({ classes, text, contentId }) => {
  return (
    <a className={classes.skipLink} href={contentId}>{text}</a>
  )
}

SkipToContentLink.propTypes = {
  text: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired
}

export default withStyles(style)(SkipToContentLink)
