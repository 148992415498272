export default [
  { label: 'Auricular (otic)', value: '29' },
  { label: 'Buccal', value: '01' },
  { label: 'Caudal', value: 'C34' },
  { label: 'Conjunctival', value: '02' },
  { label: 'Cutaneous', value: '30' },
  { label: 'Dental', value: '03' },
  { label: 'Endocervical', value: '31' },
  { label: 'Endosinusial', value: '32' },
  { label: 'Endotracheal', value: '33' },
  { label: 'Epidural', value: '34' },
  { label: 'Extra-amniotic', value: '35' },
  { label: 'Gastroenteral use', value: '94' },
  { label: 'Gingival use', value: '95' },
  { label: 'Hemodialysis', value: '36' },
  { label: 'Implant', value: '04' },
  { label: 'Inhalation', value: '05' },
  { label: 'Injection', value: '85' },
  { label: 'Insufflation', value: '07' },
  { label: 'Intra corpus cavernosum', value: '37' },
  { label: 'Intra-amniotic', value: '38' },
  { label: 'Intra-arterial', value: '08' },
  { label: 'Intra-articular', value: '09' },
  { label: 'Intra-muscular', value: 'C11' },
  { label: 'Intra-nasal', value: '12' },
  { label: 'Intra-tracheal', value: '16' },
  { label: 'Intra-uterine', value: 'C41' },
  { label: 'Intrabursal use', value: '96' },
  { label: 'Intracardial', value: '06' },
  { label: 'Intracavernous', value: '42' },
  { label: 'Intracavity', value: 'C35' },
  { label: 'Intracerebral', value: '43' },
  { label: 'Intracervical', value: '44' },
  { label: 'Intracisternal', value: '45' },
  { label: 'Intracorneal', value: '46' },
  { label: 'Intracoronary', value: '47' },
  { label: 'Intradermal', value: '48' },
  { label: 'Intradiscal (intraspinal)', value: '49' },
  { label: 'Intraductal', value: 'C36' },
  { label: 'Intrahepatic', value: '50' },
  { label: 'Intralesional', value: '51' },
  { label: 'Intralymphatic', value: '52' },
  { label: 'Intralyphatic', value: 'C37' },
  { label: 'Intramedullar (bone marrow)', value: '53' },
  { label: 'Intrameningeal', value: '54' },
  { label: 'Intramuscular', value: '55' },
  { label: 'Intramuscular and Intravenous', value: 'C38' },
  { label: 'Intraneural', value: '91' },
  { label: 'Intraocular', value: '56' },
  { label: 'Intrapericardial', value: '57' },
  { label: 'Intraperitendineal', value: '90' },
  { label: 'Intraperitoneal', value: '58' },
  { label: 'Intrapleural', value: '59' },
  { label: 'Intrapulmonary', value: '86' },
  { label: 'Intrasalpingal', value: '88' },
  { label: 'Intraspinal', value: 'C30' },
  { label: 'Intrasternal use', value: '97' },
  { label: 'Intrasynovial', value: '60' },
  { label: 'Intrathecal', value: '15' },
  { label: 'Intrathoracic', value: '63' },
  { label: 'Intratumor', value: '61' },
  { label: 'Intrauterine', value: '17' },
  { label: 'Intravenous', value: '18' },
  { label: 'Intravenous (not otherwise specified)', value: '66' },
  { label: 'Intravenous bolus', value: '64' },
  { label: 'Intravenous drip', value: '65' },
  { label: 'Intraventricular', value: 'C42' },
  { label: 'Intravesical', value: '67' },
  { label: 'Iontophoresis', value: '68' },
  { label: 'Irrigation', value: 'C29' },
  { label: 'Jejunal', value: 'C102' },
  { label: 'Local infiltration', value: '19' },
  { label: 'NASOGASTRIC', value: 'C47' },
  { label: 'Nasal', value: '69' },
  { label: 'Nasal J-tube', value: 'C49' },
  { label: 'Occlusive dressing technique', value: '70' },
  { label: 'Ocular use', value: '92' },
  { label: 'Ophthalmic', value: '71' },
  { label: 'Oral', value: '72' },
  { label: 'Oral and Intramuscular', value: 'C44' },
  { label: 'Oral and Intravenous', value: 'C33' },
  { label: 'Oromucosal use', value: '93' },
  { label: 'Oropharingeal', value: '73' },
  { label: 'Other', value: '99' },
  { label: 'Otic', value: 'C43' },
  { label: 'Paravenous', value: '22' },
  { label: 'Parenteral', value: '74' },
  { label: 'Per oral', value: '20' },
  { label: 'Per rectal', value: '21' },
  { label: 'Per semen', value: 'C101' },
  { label: 'Percutaneous J-tube', value: 'C48' },
  { label: 'Periarticular', value: '75' },
  { label: 'Perineural', value: '76' },
  { label: 'Rectal', value: '77' },
  { label: 'Respiratory (inhalation)', value: '78' },
  { label: 'Retrobulbar', value: '79' },
  { label: 'Route of administration not applicable', value: '100' },
  { label: 'Subcutaneous', value: '23' },
  { label: 'Subdermal', value: '81' },
  { label: 'Sublingual', value: '24' },
  { label: 'Sunconjunctival', value: '80' },
  { label: 'Topical', value: '25' },
  { label: 'Transdermal', value: '82' },
  { label: 'Transmammary', value: '83' },
  { label: 'Transplacental', value: '84' },
  { label: 'Unknown', value: '98' },
  { label: 'Urethral', value: '26' },
  { label: 'Vaginal', value: '27' },
  { label: 'Wound irrigation', value: '28' },
  { label: 'subdural', value: '89' }
]
