import { connect } from 'react-redux'

import ReporterFollowUpDrawer from './ReporterFollowUpDrawer'

import { selectors as organisationDetailsSelectors } from '../../../../store/modules/organisationDetails'

const mapStateToProps = state => {
  const organisationLanguages = organisationDetailsSelectors.getCurrentOrganisationLanguagesAsOptions(state)

  return {
    organisationLanguages
  }
}

export default connect(mapStateToProps)(ReporterFollowUpDrawer)
