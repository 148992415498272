import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class UserDetailsActions {
  createUser = (fields) => ({
    type: constants.CREATE_USER,
    promise: () => digitalStoreSdk.users
      .createUser(fields)
  })

  registerUser = (fields) => ({
    type: constants.REGISTER_USER,
    promise: () => digitalStoreSdk.users
      .createUser(fields)
  })  

  fetchUserDetails = (id) => {
    return {
      type: id === '@me' ? constants.FETCH_CURRENT_USER_DETAILS : constants.FETCH_USER_DETAILS,
      promise: () => digitalStoreSdk.users
        .fetchUserDetails({id})
    }
  }

  updateUser = (params) => {
    return {
      type: constants.UPDATE_USER,
      promise: () => digitalStoreSdk.users
        .updateUser(params)
    }
  }

  changeUserPassword = ({ id, password }) => {
    return {
      type: constants.CHANGE_USER_PASSWORD,
      promise: () => digitalStoreSdk.auth
        .changePasswordByUserId({ id, password })
    }
  }

  changeCurrentUserPassword = ({ id, password, currentPassword }) => {
    return {
      type: constants.CHANGE_CURRENT_USER_PASSWORD,
      promise: () => digitalStoreSdk.auth
        .changePasswordByUserId({ id, password, currentPassword })
    }
  }

  userAccountStatusToggle = (id) => {
    return {
      type: constants.USER_ACCOUNT_STATUS_TOGGLE,
      promise: () => digitalStoreSdk.users
        .toggleDeactivateUser({id})
    }
  }

  fetchUserOrganisationPreferencesCount = ({ userId, platformId }) => {
    return {
      type: constants.FETCH_USER_ORGANISATION_PREFERENCES_COUNT,
      promise: () => digitalStoreSdk.users.fetchUserOrganisationPreferencesCount({ userId, platformId })
    }
  }

  fetchUserOrganisationPreferences = ({ userId, platformId, limit, offset }) => {
    return {
      type: constants.FETCH_USER_ORGANISATION_PREFERENCES,
      promise: () => digitalStoreSdk.users.fetchUserOrganisationPreferences({ userId, platformId, limit, offset })
    }
  }

  updateUserOrganisationPreferences = ({ userId, preferences }) => {
    return {
      type: constants.UPDATE_USER_ORGANISATION_PREFERENCES,
      promise: () => digitalStoreSdk.users.updateUserOrganisationPreferences({ userId, preferences })
    }
  }

  resetStore = () => {
    return {
      type: constants.RESET_STORE
    }
  }
}

export default new UserDetailsActions()
