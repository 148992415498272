import _ from 'lodash'
import { createSelector } from 'reselect'

export const getFeeds = state => state.newsFeeds.results
export const getDistinctNewsFeedSources = state => state.newsFeeds.distinctNewsSources
export const getCreateError = state => state.newsFeeds.createError
export const getCreateStatus = state => state.newsFeeds.createStatus

/**
 * get NewsFeed sources as dropdown options
 */
export const getNewsFeedSourcesAsDropdownOptions = createSelector([getDistinctNewsFeedSources], (results = []) => {
  const sources = _.map(results, (result) => {
    // uses distinct newsFeed sources for the dropdown
    // cannot use the id as the dropdown value because source is not unique
    return {
      value: result.source,
      label: result.source
    }
  })
  return _.sortBy(sources, 'value')
})
