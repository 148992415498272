
class NetworkReducers {
  networkConnectionOn = (state, action) => {
    return {
      ...state,
      connected: true
    }
  }
  networkConnectionOff = (state, action) => {
    return {
      ...state,
      connected: false
    }
  }
}

export default new NetworkReducers()
