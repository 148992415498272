import { initialize } from 'redux-form'
import { constants as themeDetailsConstants } from '../themeDetails'
import { actions as themeActions } from '../themes'

class ThemeDetailsMiddleware {
  loadEditThemeDetails = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === themeDetailsConstants.FETCH_THEME_DETAILS_FOR_FORM && action.status === 'SUCCESS') {
      const theme = action.result
      if (theme) {
        dispatch(initialize(themeDetailsConstants.THEME_FORM_NAME, {
          id: theme.id,
          name: theme.name,
          domain: theme.domain,
          ...theme.details
        }))
      }
    }
  }

  updateOrganisationFormOnThemeCreateSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    if (
        (action.type === themeDetailsConstants.CREATE_ORGANISATION_THEME && action.status === 'SUCCESS') ||
        (action.type === themeDetailsConstants.UPDATE_THEME && action.status === 'SUCCESS')
    ) {
      const theme = action.result
      if (theme && theme.id) {
        dispatch(themeActions.fetchAllThemes())
      }
    }
  }

  updatePlatformFormOnThemeCreateSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    if (
        (action.type === themeDetailsConstants.CREATE_PLATFORM_THEME && action.status === 'SUCCESS') ||
        (action.type === themeDetailsConstants.UPDATE_THEME && action.status === 'SUCCESS')
    ) {
      const theme = action.result
      if (theme && theme.id) {
        dispatch(themeActions.fetchAllThemes())
      }
    }
  }
}

export default new ThemeDetailsMiddleware()
