import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import VisuallyHidden from '@reach/visually-hidden'

import { translation } from '../../services/translations'

// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/CircularProgress/CircularProgress.js

const SIZE = 44

const circularDashKF = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`

const circularRotateKF = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  animation: ${circularRotateKF} 1.8s linear infinite;
  color: ${props => (props.color || props.theme.colors.primary)};
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`

const Circle = styled.circle`
  stroke: currentColor;
  fill: none;
`

const ForegroundCircle = styled(Circle)`
  animation: ${circularDashKF} 1.8s ease-in-out infinite;
  strokedasharray: 80px, 200px;
  strokedashoffset: 0px;
`

const BackgroundCircle = styled(Circle)`
  opacity: 0.25;
`

const CircularProgress = (props) => {
  const { color, size, thickness, label, className } = props

  const circleProps = {
    cx: SIZE,
    cy: SIZE,
    r: (SIZE - thickness) / 2,
    strokeWidth: thickness
  }

  return (
    <Wrapper
      size={size}
      color={color}
      aria-live='assertive'
      role='progressbar'
      className={className}
    >
      <svg
        style={{ display: 'block' }}
        viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}
      >
        <BackgroundCircle {...circleProps} />
        <ForegroundCircle {...circleProps} />
      </svg>
      <VisuallyHidden>
        <p>{translation(label)}</p>
      </VisuallyHidden>
    </Wrapper>
  )
}

CircularProgress.propTypes = {
  /**
   * Override the spinners default color
   */
  color: PropTypes.string,
  /**
   * The label that describes the reason for the loader. This is visually hidden.
   */
  label: PropTypes.string,
  /**
   * The width and height of the spinner
   */
  size: PropTypes.number,
  /**
   * The width of the border within the circle
   */
  thickness: PropTypes.number,
  /**
   * Additional CSS class to pass to the spinner
   */
  className: PropTypes.string
}

CircularProgress.defaultProps = {
  label: 'Loading',
  size: 40,
  thickness: 5
}

export default CircularProgress
