import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as organisationDetailsSelectors } from '../organisationDetails'
import digitalStoreSdk from '../../../digitalStoreSdk'

const _searchEdqm = (next = false) => ({ query, lookupOptions }) => ({
  type: next ? constants.SEARCH_EDQM_NEXT : constants.SEARCH_EDQM,
  query,
  promise: (dispatch, getState) => {
    const state = getState()
    const size = selectors.getSize(state)
    const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(state)

    const listName = lookupOptions?.listName

    const searchParams = {
      listName,
      size,
      query,
      organisationId
    }

    if (next) searchParams.page = selectors.getPage(state) + 1

    return digitalStoreSdk.edqm.searchEdqm(searchParams)
  }
})

export const searchEdqm = _searchEdqm(false)

export const searchEdqmNext = _searchEdqm(true)

export const resetStore = () => ({
  type: constants.RESET_STORE
})
