// There is no functionality to hide selectall checkbox for x-data-grid. 
// Suggested workaround for hiding the SelectAll checkbox https://github.com/mui/mui-x/issues/1904

const styles = theme => ({
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
      display: "none"
    }
  },
  chip: {
    height: 16,
    fontSize: 10,
    fontWeight: 'bold',
    color: 'white'
  },
  teal: {
    backgroundColor: 'teal'
  },
  purple: {
    backgroundColor: 'purple'
  },
  zoomInIconButton: {
    padding: '0 5px'
  }
})

export default styles
