import { createStatefulThunk } from '../toolkit-utils'
import { VERIFY_OTA } from './constants'

import digitalStoreSdk from '../../../digitalStoreSdk'

export const verifyOta = createStatefulThunk({
  typePrefix: VERIFY_OTA,
  path: 'verifyOta',
  asyncFunction: async ({ otaId, retry }) => digitalStoreSdk.ota.verifyOta({ otaId, retry })
})
