import { connect } from 'react-redux'
import { compose } from 'recompose'

import ReportsSearchScreen from './ReportsSearchScreen'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'

const enhancer = compose(
  connect(state => ({
    pageTitle: platformSelectors.getPageTitle(state)('Reports Management Title')
  }))
)

export default enhancer(ReportsSearchScreen)
