import { colors } from '../../config/theme'

export default (theme) => ({
  label: {
    fontSize: 12,
    lineHeight: '13px',
    position: 'static',
    transform: 'none',
    marginBottom: '4px'
  }
})
