import userAddressSchema from '../../../components/Form/commonSchemas/userAddress'
import * as validators from '../../../validators'
import {
  titles,
  translations,
  auth,
  loginTypes,
  countriesDirectory
} from '../../../config'
import _ from 'lodash'

const usernameRequired = auth.loginType === loginTypes.USERNAME

const smallTextLengthValidator = validators.maxLength(50, 'Text')

const schema = (
  {
    disableEmail = false,
    allFieldsMandatory = false,
    customFields = [],
    allowedFields = []
  } = {}
) => {
  const schema = [
    {
      id: 'title',
      field: 'Dropdown',
      props: {
        label: translations('Title'),
        name: 'title',
        options: titles,
        required: allFieldsMandatory,
        shrink: true
      }
    },
    {
      id: 'firstName',
      field: 'Input',
      props: {
        label: translations('First Name'),
        name: 'firstName',
        validate: [smallTextLengthValidator],
        required: true,
        shrink: true
      }
    },
    {
      id: 'lastName',
      field: 'Input',
      props: {
        label: translations('Last Name'),
        name: 'lastName',
        validate: [smallTextLengthValidator],
        required: true,
        shrink: true
      }
    },
    {
      id: 'email',
      field: 'Email',
      props: {
        label: translations('Email'),
        name: 'email',
        validate: [validators.email, smallTextLengthValidator],
        required: true,
        showEmailButton: false,
        shrink: true,
        disabled: disableEmail
      }
    },
    {
      id: 'telephoneCountryCode',
      field: 'Dropdown',
      props: {
        label: translations('Telephone Country Code'),
        name: 'telephoneCountryCode',
        validate: [
          validators.countryIsoCode,
          validators.requiredWith({ fieldWith: 'telephone' })
        ],
        options: countriesDirectory,
        required: allFieldsMandatory,
        shrink: true
      }
    },
    {
      id: 'telephone',
      field: 'Input',
      props: {
        label: translations('Telephone'),
        name: 'telephone',
        validate: [
          validators.simplePhoneNumber,
          validators.requiredWith({ fieldWith: 'telephoneCountryCode', withName: translations('Telephone Country Code'), fieldName: translations('Telephone') })
        ],
        required: allFieldsMandatory,
        shrink: true
      }
    },
    {
      id: 'telephoneExtension',
      field: 'Input',
      props: {
        label: translations('Telephone Extension'),
        name: 'telephoneExtension',
        validate: [validators.numeric],
        required: allFieldsMandatory,
        shrink: true
      }
    },
    ...userAddressSchema({ allFieldsMandatory })
  ]
  const filteredSchema = filterSchemaByAllowedFields({ schema, allowedFields })
  const schemaWithCustomFields = addCustomFields({ schema: filteredSchema, customFields })
  return schemaWithCustomFields
}

const filterSchemaByAllowedFields = ({ schema, allowedFields }) => {
  if(_.isEmpty(allowedFields)) {
    return schema
  }
  return _.filter(schema, field => _.includes(allowedFields, field.id))
}

const addCustomFields = ({ schema, customFields }) => {
  const schemaWithCustomFields = _.clone(schema)
  for (const field of customFields) {
    const position = field.position || schemaWithCustomFields.length
    const newField = field.value
    schemaWithCustomFields.splice(position, 0, newField)
  }
  return schemaWithCustomFields
}

if (usernameRequired) {
  schema.push({
    id: 'username',
    field: 'Input',
    props: {
      label: translations('Registration Username'),
      name: 'username',
      required: true,
      shrink: true
    }
  })
}

export default schema
