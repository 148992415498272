import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'

import _ from 'lodash'
import { isDevMode } from '../../helpers'
import reducer, { moveResource } from './reducers'
import * as actions from './actions'
import logging from 'use-reducer-logger'
import { usePrevious } from 'react-use'

const initialState = {
  sections: {},
  fields: {},
  isEdited: false,
  versionId: 2,
  openBlocks: []
}

const formViewStore = createContext(initialState)
const { Provider } = formViewStore

const FormViewProvider = ({ children, viewJson = {}, formViewVersionMeta = {} }) => {
  const [state, dispatch] = useReducer(
    isDevMode ? logging(reducer) : reducer,
    initialState
  )
  const [sections, setSections] = useState({})
  const [fields, setFields] = useState({})
  const prevSections = usePrevious(sections)
  const prevFields = usePrevious(fields)

  useEffect(() => {
    dispatch(actions.loadFormView(viewJson))
  }, [viewJson])

  useEffect(() => {
    const { sections, fields } = state
    if (!_.isEqual(sections, prevSections)) {
      setSections(sections)
    }
    if (!_.isEqual(fields, prevFields)) {
      setFields(fields)
    }
  }, [state])

  const moveResourceFunction = (payload) => {
    return moveResource(_.cloneDeep(state), payload)
  }
  const getSections = useMemo(() => {
    return _.sortBy(sections, ['index'])
  }, [sections])

  const getFields = useMemo(() => {
    return _.sortBy(fields, ['index'])
  }, [fields])

  const getRepeatableFields = useMemo(() => {
    const repeatableFields = _.filter(fields, field => {
      const innerFields = field.fields
      return _.size(innerFields) > 0
    })
    return _.sortBy(repeatableFields, ['index'])
  }, [fields])

  const getRepeatableFieldOptions = useMemo(() => {
    return _.map(getRepeatableFields, repeatableField => ({
      label: repeatableField.name,
      value: repeatableField.id
    }))
  }, [getRepeatableFields])

  return (
    <Provider
      value={{
        state,
        dispatch,
        actions,
        getSections,
        getFields,
        getRepeatableFields,
        getRepeatableFieldOptions,
        moveResourceFunction,
        viewJson,
        formViewVersionMeta
      }}>
      {children}
    </Provider>
  )
}

export const useFormViews = () => useContext(formViewStore)

export default { FormViewProvider, formViewStore }
