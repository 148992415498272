export default theme => ({
  container: {
    flex: 1,
    zIndex: 1,
    width: '100%',
    maxWidth: '100%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%'
    }
  }
})
