import { colors } from '../../config/theme'

const styles = theme => ({
  appBar: {
    position: 'fixed',
    backgroundColor: theme.header.backgroundColor,
    zIndex: 1299,
    boxShadow: 'none',
    top: 0,
    borderBottom: `1px solid ${colors.lightGrey}`
  },
  toolBar: {
    backgroundColor: theme.header.backgroundColor,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.headerHeight,
    minHeight: theme.headerHeight
  },
  titleContainer: {
    flex: 1,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  title: {
    fontWeight: 'normal',
    fontSize: 17,
    color: theme.header.textColor
  },
  iconStyleLeft: {
    padding: 0
  },
  basketIcon: {
    padding: 0,
    minWidth: 0,
    display: 'flex'
  },
  whiteLetters: {
    color: 'white',
    maxWidth: '300px',
    height: 55
  },
  logoContainer: {
    fontWeight: 100,
    margin: 0,
    position: 'absolute',
    right: 10,
    top: 5,
    [theme.breakpoints.down('xs')]: {
      right: '50%',
      transform: 'translateX(50%)'
    }
  },
  burger: {
    width: '19px',
    display: 'flex'
  }
})

export default styles
