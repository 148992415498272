import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  deleteError: undefined,
  deleteStatus: undefined,

  createError: undefined,
  createStatus: undefined,

  status: undefined,
  results: [],
  distinctNewsSources: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_NEWS_FEEDS:
      return reducers.fetchNewsFeeds(state, action)
    case constants.FETCH_DISTINCT_NEWS_FEED_SOURCES:
      return reducers.fetchDistinctNewsFeedSources(state, action)
    case constants.CREATE_NEWS_FEED:
      return reducers.createNewsFeed(state, action)
    case constants.DELETE_NEWS_FEED:
      return reducers.deleteNewsFeed(state, action)
    case constants.CLEAR_NEWS_FEED_ERRORS:
      return reducers.clearNewsFeedErrors(state, action)
    default:
      return state
  }
}
