import {
  compose,
  withHandlers,
  withStateHandlers,
  withPropsOnChange
} from 'recompose'

import NewReportModal from './NewReportModal'
import modalService from '../../../../../services/modalService'

const enhancer = compose(
  withPropsOnChange(['fields'], (props) => {
    const {fields} = props
    return fields
  }),
  withStateHandlers(),
  withHandlers({
    openModal: () => component => {
      modalService.open({
        component
      })
    },
    closeModal: () => () => {
      modalService.close()
    }
  })
)

export default enhancer(NewReportModal)
