import React, { useEffect, useMemo } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { FormControl } from '@material-ui/core'
import { Dropdown } from '../../../../../components/EditableFields'
import * as validators from '../../../../../validators'
import { translations } from '../../../../../config'
import style from '../../style'
import _ from 'lodash'

const Professions = (props) => {
  const {
    classes,
    editing,
    professionOptions,
    selectedProfessionName,
    organisationName = ''
  } = props

  const professionGroups = useMemo(() => {
    return _.chain(professionOptions)
      .map('professionGroup')
      .compact()
      .uniqBy('id')
      .value()
  }, [professionOptions])

  const options = useMemo(() => {
    if (_.isEmpty(professionGroups)) {
      return professionOptions
    }
    const groupedProfessions = _.groupBy(professionOptions, 'professionGroupId')
    const groupIds = _.keys(groupedProfessions)
    for (const groupId of groupIds) {
      const group = _.get(groupedProfessions, groupId)
      const professionGroup = _.find(professionGroups, professionGroup => professionGroup.id === groupId)
      if (professionGroup) {
        group.unshift({ isSubheader: true, label: professionGroup.name, id: professionGroup.id })
      }
      groupedProfessions[groupId] = group
    }
    return groupedProfessions
  }, [professionOptions, professionGroups])

  return (
    <Grid item xs={12} sm={6}>
      <FormControl className={classes.dropDownStandard}>
        <Dropdown
          label={translations('User Form - Profession Dropdown Label', { OrgName: organisationName })}
          name={'professionId'}
          options={options}
          editing={editing}
          validate={[validators.required]}
          classes={{ root: classes.dropDownStandard }}
          displayValue={selectedProfessionName}
          strict
        />
      </FormControl>
    </Grid>
  )
}

export default withStyles(style)(Professions)
