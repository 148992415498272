import React, { Component } from 'react'
import PropTypes from 'prop-types'
import marked from 'marked'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { getImage } from '../../components/Images'
import { translations } from '../../config'

import style from './style'

const closeIcon = getImage('closeIconBlack')

class MarkdownScreen extends Component {
  render () {
    const { classes, markdownData, success, inModal = false } = this.props
    const dataHTML = marked(markdownData)
    const containerClass = inModal ? classes.modalContainer : null
    return (
      <div className={containerClass}>
        {inModal ? (
          <div className={classes.buttonContainer}>
            <IconButton
              onClick={success}
              aria-label={translations('Close')}
            >
              <img src={closeIcon} alt='' />
            </IconButton>
          </div>
        ) : (
            ''
          )}
        <div className={classes.markdownWrapper}>
          <div dangerouslySetInnerHTML={{ __html: dataHTML }} className={classes.mainMarkdownContainer}>
          </div>
        </div>
      </div>
    )
  }
}

MarkdownScreen.propTypes = {
  classes: PropTypes.shape({})
}

export default withStyles(style)(MarkdownScreen)
