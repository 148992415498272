import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Input,
  FormHelperText
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'

import { translations } from '../../../config'
import InputLabel from '../../InputLabel'
import Heading from '../../Heading'

import style from './style'

const MultiLanguageInput = ({
  label,
  onChange,
  value,
  shrink,
  languages,
  classes,
  help,
  noErrorTextLabel = false,
  meta: { error, touched } = {}
}) => {
  const [expanded, setExpanded] = useState(false)
  const defaultLanguage = 'English'
  const moreLanguages = _.filter(languages, lang => lang.label !== defaultLanguage)
  const handleChange = (event) => {
    const langChanged = _.find(languages, lang => lang.label === event.target.id)
    const newValue = event.target.value
    onChange({
      ...value || {},
      [langChanged.value]: newValue,
      default: langChanged.label === defaultLanguage ? newValue : _.get(value, 'default')
    })
  }

  return ([
    <InputLabel
      htmlFor={defaultLanguage}
      key={defaultLanguage}
      shrink={shrink}
    >
      {label}
    </InputLabel>,
    <FormGroup key='group' onChange={handleChange}>
      <Input key={defaultLanguage} id={defaultLanguage} value={value.default} className={classes.input}/>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          id="multilang-header"
          aria-controls="multilang-content"
          expandIcon={<ExpandMore/>}
        >
          <Heading variant='h4' key={'translations-label'}>{translations('Manage Translations')}&nbsp;</Heading>
          <FormHelperText dense className={classes.noMargin}>({label})</FormHelperText>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          { moreLanguages.map(({ label, value: languageId }) => {
            return (
              <Fragment key={label}>
                <InputLabel
                  htmlFor={label}
                  shrink={shrink}
                >
                  {label}
                </InputLabel>
                <Input id={label} value={_.get(value, languageId, '')} className={classes.input}/>
              </Fragment>
            )
          })
          }
        </AccordionDetails>
      </Accordion>
    </FormGroup>,
    help && <FormHelperText key='help-text'>{translations(help)}</FormHelperText>,
    noErrorTextLabel ? null : <FormHelperText className={classes.errorStyle} key='helper-text' role='alert'>{touched && error && error}</FormHelperText>
  ])
}

export default withStyles(style)(MultiLanguageInput)
