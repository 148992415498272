import * as constants from './constants'
import actions from './actions'
import { SUCCESS, PENDING, FAIL } from '../../middleware/redux-promise'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

class MultipartUploadMiddleware {
  showModalOnCompletedUploadMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)

    if (action.type === constants.UPLOAD) {
      if (action.status === SUCCESS || action.status === FAIL) {
        const key = action.status === SUCCESS ? 'Success' : 'Fail'
        modalService.continue({
          title: translations(`Multipart Upload - Upload ${key} Modal Title`),
          text: translations(`Multipart Upload - Upload ${key} Modal Text`)
        })
        dispatch(actions.clear())
      }
    }
  }

  _preventUnloadDuringUpload = (e) => {
    const message = translations('Multipart Upload - Prevent Unload');
    (e || window.event).returnValue = message
    return message
  }
  preventUnloadDuringUploadMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === constants.UPLOAD) {
      if (action.status === PENDING) {
        window.addEventListener('beforeunload', this._preventUnloadDuringUpload)
      } else {
        window.removeEventListener('beforeunload', this._preventUnloadDuringUpload)
      }
    }
  }
}

export default new MultipartUploadMiddleware()
