import React, { useState, useContext, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/core/styles'

import { useParams } from 'react-router-dom'
import { hooks as reportConfigurationHooks } from '../../../../../store/modules/reportConfiguration'

import Section from '../../../../../components/FormviewEditor/Section'
import Field from '../../../../../components/FormviewEditor/Field'
import Block from '../../../../../components/FormviewEditor/Block'
import InsertButton from '../../../../../components/FormviewEditor/InsertButton'

import { ConditionsContext, useConditions } from '../../../../../contexts'
import { translations } from '../../../../../config'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

const Actions = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  marginTop: theme.spacing(2),
  '&:first-child': {
    paddingTop: 0,
    marginTop: 0
  }
}))

export const EditorSection = (props) => {
  const {
    section,
    isOpen: initialOpen,
    onEditSection,
    onAddField,
    onEditField,
    onToggleVisibility,
    onDelete,
    onMove,
    onChangeSection,
    onChangeBlock,
    onRemoveFromBlock,
    onAddToBlock,
    onCloseAllBlocks,
    isFirst,
    isLast,
    saveDraggedResult,
    moveAnyResource,
    onManageConditions,
    formViewStore
  } = props

  const { id: formViewId } = useParams()
  const capabilities = reportConfigurationHooks.useFormViewVersionCapabilities(formViewId)
  const canHaveConditions = _.get(capabilities, 'conditions', false)
  const canAddBlocks = _.get(capabilities, 'blocks', false)

  const { id: sectionId, isCustom } = section
  const dropZoneId = `__${sectionId}_DROP_ZONE`
  const formViewContext = useContext(formViewStore)
  const { getFields: fields, state: { sections } } = formViewContext
  const [fieldsSnapshot, setFieldsSnapshot] = useState(undefined)
  const [hasBeenDragged, setDragged] = useState(false)
  const openBlocks = _.get(sections, `${sectionId}.openBlocks`)
  const {
    getConditionsForResource,
    removeFromResource
  } = useConditions()

  const [isOpen, setIsOpen] = useState(initialOpen)
  const onOpen = (toggle) => setIsOpen(toggle)

  const sectionFields = _.filter(fields, { sectionId })

  useEffect(() => {
    if (hasBeenDragged) {
      setDragged(false)
    } else if (!_.isEqual(sectionFields, fieldsSnapshot)) {
      setFieldsSnapshot(sectionFields)
    }
  }, [fieldsSnapshot, sectionFields])

  const insertMeta = { sectionId }

  const onDragEnd = useCallback((payload) => {
    const movedResources = moveAnyResource(payload)
    if (movedResources) {
      const movedFields = _.get(movedResources, 'fields')
      const filteredFields = _.filter(movedFields, { sectionId })
      const sortedFields = _.orderBy(filteredFields, ['index'])
      const moveProps = _.get(movedResources, 'moveProps')
      setFieldsSnapshot(sortedFields)
      setDragged(true)
      saveDraggedResult(moveProps)
    }
  }, [fieldsSnapshot])
  const sectionConditons = canHaveConditions && getConditionsForResource({ resourceType: 'SECTION', resourceId: section.id })
  return (
    <Section
      {...section}
      canHaveConditions={canHaveConditions}
      conditions={sectionConditons}
      onEdit={() => onEditSection({ props: { initialValues: section } })}
      isFirst={isFirst}
      isLast={isLast}
      isOpen={isOpen}
      onOpen={onOpen}
      onToggleVisibility={() => onToggleVisibility({ resourceType: 'SECTION', id: sectionId })}
      onCloseAllBlocks={() => onCloseAllBlocks({ sectionId, blocks: openBlocks })}
      onMove={(props) => onMove({ resourceType: 'SECTION', id: sectionId, ...props })}
      {...(isCustom === true && {
        onDelete: () => onDelete({ resourceType: 'SECTION', id: sectionId })
      })}
      onManageConditions={() => {
        onManageConditions({
          resourceType: 'SECTION',
          resourceId: sectionId
        })
      }}
      onDeleteCondition={({ id: conditionId }) => {
        removeFromResource({
          resourceType: 'SECTION',
          resourceId: sectionId,
          conditionId
        })
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={dropZoneId} type={'FIELD'}>
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {
                _.map(fieldsSnapshot || [], (field, index) => {
                  const { isBlock, isHeading, fields: internalFields } = field
                  let type
                  if (isHeading) {
                    type = 'HEADING'
                  }
                  if (_.size(internalFields) > 0) {
                    type = 'REPEATABLE'
                  }
                  if (isBlock) {
                    type = 'BLOCK'
                  }
                  if (isBlock) {
                    const conditions = getConditionsForResource({ resourceType: 'BLOCK', resourceId: field.id })

                    return (
                      <Block
                        initialValues={field}
                        {...field}
                        fields={internalFields}
                        onEdit={onEditField}
                        onToggleVisibility={onToggleVisibility}
                        onDelete={onDelete}
                        onMove={onMove}
                        onChangeSection={onChangeSection}
                        onChangeBlock={onChangeBlock}
                        onRemoveFromBlock={onRemoveFromBlock}
                        isFirst={index === 0}
                        isLast={index === (sectionFields.length - 1)}
                        saveDraggedResult={saveDraggedResult}
                        moveAnyResource={moveAnyResource}
                        sectionId={sectionId}
                        conditions={conditions}
                        onManageConditions={() => {
                          onManageConditions({
                            resourceType: 'BLOCK',
                            resourceId: field.id
                          })
                        }}
                        onDeleteCondition={({ id: conditionId }) => {
                          removeFromResource({
                            resourceType: 'BLOCK',
                            resourceId: field.id,
                            conditionId
                          })
                        }}
                      />
                    )
                  }
                  return (
                    <Field
                      key={field.id}
                      {...field}
                      initialValues={field}
                      type={type}
                      onEdit={onEditField}
                      onToggleVisibility={onToggleVisibility}
                      onDelete={onDelete}
                      onMove={onMove}
                      onChangeSection={onChangeSection}
                      onAddToBlock={onAddToBlock}
                      isFirst={index === 0}
                      isLast={index === (sectionFields.length - 1)}
                      saveDraggedResult={saveDraggedResult}
                      moveAnyResource={moveAnyResource}
                      sectionId={sectionId}
                    />
                  )
                })
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Actions>
        <Grid container spacing={2}>
          <Grid item xs>
            <InsertButton
              onClick={() => onAddField({
                fieldType: 'QUESTION',
                meta: insertMeta
              })}
              fullWidth
              size='medium'>
              {translations('Add Question')}
            </InsertButton>
          </Grid>
          <Grid item xs>
            <InsertButton
              onClick={() => onAddField({
                fieldType: 'HEADING',
                meta: insertMeta
              })}
              fullWidth
              size='medium'>
              {translations('Add Heading')}
            </InsertButton>
          </Grid>
          {canAddBlocks &&
            <Grid item xs>
              <InsertButton
                onClick={() => onAddField({
                  fieldType: 'BLOCK',
                  meta: insertMeta
                })}
                fullWidth
                size='medium'>
                {translations('Add Block')}
              </InsertButton>
            </Grid>
          }
        </Grid>
      </Actions>
    </Section >
  )
}

EditorSection.defaultProps = {
  isFirst: false,
  isLast: false
}

EditorSection.propTypes = {
  section: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onAddField: PropTypes.func.isRequired,
  onEditField: PropTypes.func.isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool
}

export default EditorSection
