import {
  ADD_SECTION,
  UPDATE_SECTION,
  REMOVE_SECTION,
  ADD_FIELD,
  UPDATE_FIELD,
  REMOVE_FIELD,
  LOAD_FORM_VIEW,
  TOGGLE_VISIBILITY,
  REMOVE_RESOURCE,
  MOVE_RESOURCE,
  CHANGE_SECTION,
  SET_UNEDITED,
  SET_EDITED,
  ADD_BLOCK,
  CHANGE_BLOCK,
  REMOVE_FROM_BLOCK,
  ADD_TO_BLOCK,
  OPEN_BLOCK,
  CLOSE_BLOCK,
  SET_FORM_SCHEMA_UPDATED,
  SET_FORM_SCHEMA_UPDATE_SAVED
} from './constants'

export const addSection = (payload) => ({ type: ADD_SECTION, payload })
export const updateSection = (payload) => ({ type: UPDATE_SECTION, payload })
export const removeSection = (payload) => ({ type: REMOVE_SECTION, payload })
export const addField = (payload) => ({ type: ADD_FIELD, payload })
export const addBlock = (payload) => ({ type: ADD_BLOCK, payload })
export const updateField = (payload) => ({ type: UPDATE_FIELD, payload })
export const removeField = (payload) => ({ type: REMOVE_FIELD, payload })
export const toggleVisibility = (payload) => ({ type: TOGGLE_VISIBILITY, payload })
export const removeResource = (payload) => ({ type: REMOVE_RESOURCE, payload })
export const moveResource = (payload) => ({ type: MOVE_RESOURCE, payload })
export const changeSection = (payload) => ({ type: CHANGE_SECTION, payload })
export const loadFormView = (payload) => ({ type: LOAD_FORM_VIEW, payload })
export const setUnedited = () => ({ type: SET_UNEDITED })
export const setEdited = () => ({ type: SET_EDITED })
export const setFormViewSchemaUpdated = () => ({ type: SET_FORM_SCHEMA_UPDATED })
export const setFormViewSchemaUpdateSaved = () => ({ type: SET_FORM_SCHEMA_UPDATE_SAVED })
export const changeBlock = (payload) => ({ type: CHANGE_BLOCK, payload })
export const removeFromBlock = (payload) => ({ type: REMOVE_FROM_BLOCK, payload })
export const addToBlock = (payload) => ({ type: ADD_TO_BLOCK, payload })
export const openBlock = (payload) => ({ type: OPEN_BLOCK, payload })
export const closeBlock = (payload) => ({ type: CLOSE_BLOCK, payload })
