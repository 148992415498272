import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const ListValueEditor = ({
    id,
    options = [],
    getOptionLabel,
    defaultValue,
    value = [],
    freeSolo = true,
    onChange,
    renderInput
}) => {
    return (
        <Autocomplete
            multiple
            id={id}
            options={options}
            getOptionLabel={
                _.isFunction(getOptionLabel)
                    ? getOptionLabel
                    : (option) => _.get(option, 'label') || option
            }
            defaultValue={defaultValue}
            value={value}
            freeSolo={freeSolo}
            onChange={onChange}
            renderInput={
                _.isFunction(renderInput)
                    ? renderInput
                    : (params) => (
                        <TextField
                            {...params}
                        />
                    )
            }
        />
    )

}

ListValueEditor.propTypes = {
    id: PropTypes.string,
    options: PropTypes.array,
    getOptionLabel: PropTypes.func,
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    freeSolo: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    renderInput: PropTypes.func
}

ListValueEditor.defaultValues = {
    options: []
}

export default ListValueEditor
