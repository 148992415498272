import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'

import ForgotPasswordForm from './ForgotPasswordForm'
import { selectors as themeSelectors } from '../../../store/modules/themes'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { actions as authActions } from '../../../store/modules/auth'

const enhancer = compose(
  connect(state => ({
    themeId: themeSelectors.getCurrentThemeId(state),
    platformId: platformSelectors.getCurrentPlatformId(state)
  })),
  withPropsOnChange(['onSubmissionComplete'], (props) => {
    const onSubmissionComplete = _.get(props, 'onSubmissionComplete')
    return {
      onSubmissionComplete
    }
  }),
  withHandlers({
    onSubmit: ({ themeId, platformId, onSubmissionComplete, dispatch }) => ({ email, username }) => {
      dispatch(authActions.resetPassword({ email, username, themeId, platformId }))
        .then(onSubmissionComplete)
        .catch(onSubmissionComplete)
    }
  })
)

export default enhancer(ForgotPasswordForm)
