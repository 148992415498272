import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import actions from './actions'
import * as selectors from './selectors'
import qs from 'qs'

class SubmissionsMiddleware {
    fetchSubmissionReportsMiddleware = ({ dispatch, getState }) => next => (action) => {
        next(action)
        const isRoustingToTransmissionManagement = action.type === LOCATION_CHANGE &&  action.payload.location.pathname === '/transmission-management'
        if (isRoustingToTransmissionManagement) {
            const parsedParams = qs.parse(action.payload.location.search, { ignoreQueryPrefix: true })
            if (parsedParams) {    
                const filters = selectors.getFilters(getState())
                const newFilters = { ...filters, ...parsedParams }
                dispatch(actions.changeFilters({ filters: newFilters }))
            }
        }
      }
}

export default new SubmissionsMiddleware()
