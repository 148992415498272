import * as constants from './constants'
import reducers from './reducers'

// This store is designed to work well offline.
// each object is a hashmap, with the id as the key.
// This means all articles will remain in the store
// and are therefore loadable without a network connection.
// i.e.
//
// entries: {
//  "b18c534b-4b48-4d50-b927-ed6f54b5d027": { ...article },
//  "d036a6fa-9675-4811-b28a-c5cb5cda9229": { ...article },
// },
// statuses: {
//  "b18c534b-4b48-4d50-b927-ed6f54b5d027": "PENDING",
//  "d036a6fa-9675-4811-b28a-c5cb5cda9229": "SUCCESS",
// }
const defaultState = {
  activeArticleId: undefined,
  entries: {},
  statuses: {},
  errors: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_ACTIVE_ARTICLE:
      return reducers.setActiveArticle(state, action)
    case constants.GET_ARTICLE:
      return reducers.getArticle(state, action)
    case constants.CREATE_ARTICLE:
      return reducers.createArticle(state, action)
    case constants.UPDATE_ARTICLE:
      return reducers.updateArticle(state, action)
    case constants.DELETE_ARTICLE:
      return reducers.deleteArticle(state, action)
    default:
      return state
  }
}
