import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { defaultMemoize } from 'reselect'

import GroupList from '../GroupList'

const dateFormat = 'dddd Do MMMM YYYY'

const dateSort = (a, b) => new Date(b.items[0].createdAt) - new Date(a.items[0].createdAt)

const getGroupedItems = items => {
  return _.chain(items)
  .groupBy(item => moment(item.createdAt).format(dateFormat))
  .map((items) => {
    const formattedDate = moment(items[0].createdAt).format(dateFormat)
    const isDateToday = moment(items[0].createdAt).isSame(moment(), 'day')
    return {
      name: isDateToday ? 'Today' : formattedDate,
      items
    }
  })
  .sort(dateSort)
  .value()
}

const memoizedGetGroupedItems = defaultMemoize(getGroupedItems)

const DatedList = props => {
  const {
    items,
    ...rest
  } = props

  const groupedItems = memoizedGetGroupedItems(items)

  return <GroupList data={groupedItems} boxStyle noBorder noMargin {...rest} />
}

DatedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({}))
}

export default DatedList
