import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Form, { FormSubmit } from '../../../../../components/Form'
import { constants as ackDetailsFormConstants } from '../../../../../store/modules/ackDetails'
import FormSection from '../FormSection'
import Button from '../../../../../components/Button'
import { translations } from '../../../../../config'
import style from './style'

const AcknowledgementForm = Form(ackDetailsFormConstants.NEW_ACK_FORM_NAME, { destroyOnUnmount: false })

const LegacyAcknowledgementForm = ({ classes, onChange, onSubmit, formSections, openSections, editing, initialValues, onDownloadXml, json2xmlLoading, ...props }) => {
  return (
    <div className={classes.container}>
      <AcknowledgementForm initialValues={initialValues} onChange={onChange} onSubmit={onSubmit}>
        <div className={classes.formContentWrapper}>
          { _.map(formSections, (section, key) => {
            return (
              <FormSection
                key={key}
                id={key}
                initialOpen={!editing || section.initialOpen || false}
                initialValues={initialValues}
                editing={editing}
                {...section}
              />
            )
          })}
        </div>
        <Button disabled={json2xmlLoading} onClick={onDownloadXml} big fullWidth className={classes.footerButton} raised>{translations('Export XML')}</Button>
        {editing && <FormSubmit noContainer big fullWidth className={classes.footerButton}>{translations('Validate & Send')}</FormSubmit>}
      </AcknowledgementForm>
    </div>
  )
}

export default withStyles(style)(LegacyAcknowledgementForm)
