import _ from 'lodash'

import { getFormValues } from 'redux-form'

import { useShallowEqualSelector } from '../../../hooks'
import { selectors as reportDetailsSelectors, constants as reportDetailsConstants } from '../reportDetails'
import { getIsLoading as selectedReceiverLoading } from '../selectedReceiver/selectors'
import { authCombinedSelectors } from '../combinedSelectors'
import { getIsLoading as getJson2xmlLoading } from '../json2xml/selectors'

export const useNewReportDetailsMeta = () => {
    const {
        getDetailsForSelectedOrganisation,
        getOrganisationProfessions,
        canExportAssessessmentPdf
    } = authCombinedSelectors

    const {
        getReportId,
        getReportStatus,
        getDetails,
        getHasXML,
        getHasJSON,
        getCanExportPdf,
        getIsLoading: reportDetailsLoading,
        //getFormSections
    } = reportDetailsSelectors

    return {
        //formSections: useShallowEqualSelector(getFormSections),
        reportId: useShallowEqualSelector(getReportId),
        getReportStatus: useShallowEqualSelector(getReportStatus),
        initialValues: useShallowEqualSelector(getDetails),
        hasXML: useShallowEqualSelector(getHasXML),
        hasJSON: useShallowEqualSelector(getHasJSON),
        canExportPdf: useShallowEqualSelector(getCanExportPdf),
        // eslint-disable-next-line react-hooks/rules-of-hooks
        isLoading: useShallowEqualSelector(reportDetailsLoading) || useShallowEqualSelector(selectedReceiverLoading),
        organisationDetails: useShallowEqualSelector(getDetailsForSelectedOrganisation),
        professions: useShallowEqualSelector(getOrganisationProfessions),
        canExportAssessmentPdf: useShallowEqualSelector(canExportAssessessmentPdf),
        json2xmlLoading: useShallowEqualSelector(getJson2xmlLoading)
    }
}

export const useNewReportFormValues = () => {
    return useShallowEqualSelector(getFormValues(reportDetailsConstants.NEW_FORM_NAME))
}
