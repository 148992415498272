import _ from 'lodash'
import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SubmissionError, clearSubmitErrors, getFormValues, isInvalid } from 'redux-form'

import { translations } from '../../../../config'
import {
  selectors as organisationDetailsSelectors,
  actions as organisationDetailsActions,
  constants as organisationDetailsConstants
} from '../../../../store/modules/organisationDetails'
import { getLocationProvidersAsOptions, getNextSyncDate } from '../../../../store/modules/locations/selectors'
import { getHasLocationProvidersEnabled } from '../../../../store/modules/platforms/selectors'
import { FormBody, FormError } from '../../../../components/Form'
import * as schema from '../../formSchemas/organisationDetailsSchema'
import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'

const OrganisationLocationsForm = Editable(FormContentBox(organisationDetailsConstants.ORGANISATION_LOCATIONS_FORM_NAME))

const LocationManagement = ({
  classes,
  canEditOrg,
  organisationId
}) => {
  const dispatch = useDispatch()
  const locations = useSelector(organisationDetailsSelectors.getCurrentOrganisationLocations)
  const locationProvider = useSelector(organisationDetailsSelectors.getCurrentOrganisationLocationProvider)
  const locationProviders = useSelector(getLocationProvidersAsOptions)
  const nextLocationSyncDate = useSelector(getNextSyncDate)
  const hasLocationProvidersEnabled = useSelector(getHasLocationProvidersEnabled)
  const locationsFormValues = useSelector(getFormValues(organisationDetailsConstants.ORGANISATION_LOCATIONS_FORM_NAME))
  const isLocationsFormInvalid = useSelector(isInvalid(organisationDetailsConstants.ORGANISATION_LOCATIONS_FORM_NAME))
  const locationsSchema = useMemo(() => schema.locationsConfiguration.schema({ locationProviders, hasLocationProvidersEnabled, nextLocationSyncDate }),
    [locationProviders, hasLocationProvidersEnabled, nextLocationSyncDate]
  )

  useEffect(() => {
    if (isLocationsFormInvalid) {
      dispatch(clearSubmitErrors(organisationDetailsConstants.ORGANISATION_LOCATIONS_FORM_NAME))
    }
  }, [locationsFormValues])

  const onSubmitLocations = (formValues) => {
    const locationProvider = _.get(formValues, 'locationProvider', null)
    const newLocationsList = _.get(formValues, 'locations', [])

    const newUniqueLocations = _.uniqWith(_.map(newLocationsList, location => ({
      name: _.trim(_.get(location, 'name').toLowerCase()),
      parentId: _.get(location, 'parentId', null)
    })), _.isEqual)

    const hasDuplicates = newLocationsList.length !== newUniqueLocations.length

    if (hasDuplicates) {
      throw new SubmissionError({ _error: translations('Location management - Duplicated Locations') })
    } else {
      return dispatch(organisationDetailsActions.setLocationsForOrganisation({
        organisationId,
        locations: newLocationsList,
        locationProvider
      }))
    }
  }

  return (
    <OrganisationLocationsForm
      editable={canEditOrg}
      enableReinitialize
      initialValues={{
        locations,
        locationProvider
      }}
      givenClass={classes.formContainer}
      onSubmit={onSubmitLocations}
      boxName={translations('Location Management')}
      formId={organisationDetailsConstants.ORGANISATION_LOCATIONS_FORM_NAME}>
      <FormBody
        schema={locationsSchema}
        layout={hasLocationProvidersEnabled ? ['locationProvider:12', 'locations:12'] : ['locations:12']}
        globalClasses={{
          col: classes.columnStyle,
          row: classes.rowStyle
        }}
      />
      <FormError translate />
    </OrganisationLocationsForm>
  )
}

export default LocationManagement