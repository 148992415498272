import _ from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import Form, { FormBody, FormError } from '../../../../../../../../components/Form'
import { useSelector } from 'react-redux'
import { getFormValues, } from 'redux-form'

import * as validators from '../../../../../../../../validators'
import { scheduleFormName } from '../../../../../constants'
import { dateFormat } from '../../../../../../../../config'

const ScheduleForm = Form(scheduleFormName)

const ScheduleAnnouncementForm = ({
  entity,
  error,
}) => {
  const values = useSelector(getFormValues(scheduleFormName))

  const initialValues = useMemo(() => {
    if (_.isEmpty(entity)) {
      return null
    }

    const publishFromDate = _.get(entity, 'publishFromDate')
    const publishToDate = _.get(entity, 'publishToDate')

    const [fromDate, fromTime] = parseDateAndTime(publishFromDate)
    const [toDate, toTime] = parseDateAndTime(publishToDate)

    return (
      {
        fromDate,
        fromTime,
        toDate,
        toTime
      }
    )
  }, [entity])

  if (!initialValues) {
    return <></>
  }

  return (
    <>
      <FormError>{error}</FormError>
      <ScheduleForm
        editing
        enableReinitialize={true}
        keepDirtyOnReinitialize={false}
        initialValues={initialValues}
      >
        <FormBody
          schema={getSchema({
            fromTimeEnabled: isDateValid(_.get(values, 'fromDate')),
            toTimeEnabled: isDateValid(_.get(values, 'toDate'))
          })}
          layout={[
            'fromDate:12',
            'fromTime:12',
            'toDate:12',
            'toTime:12',
          ]}
        />
      </ScheduleForm>
    </>
  )
}

const getSchema = ({ fromTimeEnabled, toTimeEnabled }) => {
  return [
    {
      id: 'fromDate',
      field: 'DatePicker',
      props: {
        label: 'From Date',
        shrink: true,
        validate: [validators.date]
      }
    },
    {
      id: 'fromTime',
      field: 'TimePicker',
      props: {
        label: 'From Time',
        shrink: true,
        disabled: !fromTimeEnabled,
        validate: [validators.time]
      }
    },
    {
      id: 'toDate',
      field: 'DatePicker',
      props: {
        label: 'To Date',
        shrink: true,
        validate: [validators.date]
      },
    },
    {
      id: 'toTime',
      field: 'TimePicker',
      props: {
        label: 'To Time',
        shrink: true,
        disabled: !toTimeEnabled,
        validate: [validators.time]
      }
    },
  ]
}

const isDateValid = (value) => {
  if (!value) {
    return false
  }
  const date = new Date(value)
  return moment(moment(date).format(dateFormat), dateFormat, true).isValid()
}

const parseDateAndTime = (input) => {
  if (!input) {
    return [null, null]
  }
  const dateValue = moment(input).valueOf()
  const formatted = moment(dateValue).format(`${dateFormat} HH:mm`)
  return _.split(formatted, ' ')
}
export default ScheduleAnnouncementForm
