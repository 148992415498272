export default theme => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: '22px'
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 15
  },
  chartContainer: {
    flex: 1,
    flexShrink: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  noDataText: {
    opacity: 0.5,
    fontWeight: 'normal'
  }
})
