import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormHelperText } from '@material-ui/core'
import { DateRange } from '@material-ui/icons'
import _ from 'lodash'

import Dropdown from '../Dropdown'
import InputLabel from '../../InputLabel'
import { FormFieldset } from '../../Form'
import styles from './style'

import { translations } from '../../../config'
import { useInputType } from '../../../hooks'

const Date = ({
  label,
  value,
  onChange,
  onBlur,
  meta: { error, touched } = {},
  classes,
  days,
  months,
  years,
  helperText = '',
  disabled,
  noNullOption,
  acceptedDateFormats,
  onDatePicked,
  min,
  max
}) => {
  const isBrowserCompatible = useInputType('date')
  const allowFullDate = !_.size(acceptedDateFormats) || acceptedDateFormats.includes('YYYYMMDD')
  return (
    <FormFieldset title={label}>
      <InputLabel
        staticLabel
        htmlFor={label}
        key='label'
        shrink
      >
        {label}
      </InputLabel>
      <div key='inputs' className={classes.dateWrapper}>
        <div className={`${classes.dateFieldWrapper}`}>
          <Dropdown
            nullOptionLabel={'-'}
            label={translations('Year')}
            noFloatingLabel
            givenClasses={{ overridingRootClasses: classes.dropdownRoot }}
            value={value.year}
            disabled={disabled}
            onChange={(e) => onChange(e, 'year')}
            onBlur={(e) => onBlur(e, 'year')}
            options={years}
            noNullOption={noNullOption}
            disableAriaLabel />
        </div>
        <div className={`${classes.dateFieldWrapper} ${classes.marginLeft}`}>
          { (allowFullDate || acceptedDateFormats.includes('YYYYMM')) &&
            <Dropdown
              nullOptionLabel={'-'}
              label={translations('Month')}
              noFloatingLabel
              givenClasses={{ overridingRootClasses: classes.dropdownRoot }}
              value={value.month}
              disabled={disabled || !value.year}
              onChange={e => onChange(e, 'month')}
              onBlur={e => onBlur(e, 'month')} options={months}
              noNullOption={noNullOption}
              disableAriaLabel />
          }
        </div>
        <div className={`${classes.dateFieldWrapper} ${classes.marginLeft}`}>
          { allowFullDate &&
            <Dropdown
              nullOptionLabel={'-'}
              label={translations('Day')}
              noFloatingLabel
              givenClasses={{ overridingRootClasses: classes.dropdownRoot }}
              value={value.day}
              disabled={disabled || !value.month || !value.year}
              onChange={e => onChange(e, 'day')}
              onBlur={e => onBlur(e, 'day')}
              options={days}
              noNullOption={noNullOption}
              disableAriaLabel />
          }
        </div>
        { allowFullDate && isBrowserCompatible &&
          <div className={`${classes.dateIconContainer} ${classes.marginLeft}`}>
            <label htmlFor={`dateselect-calendar-${label}`} className={disabled ? classes.disabledIcon : ''}>
              <DateRange/>
            </label>
            <input
              id={`dateselect-calendar-${label}`}
              type='date'
              aria-hidden
              className={classes.pickerInput}
              onChange={onDatePicked}
              min={min}
              max={max}
              disabled={disabled}
            />
          </div>
        }
      </div>
      <FormHelperText className={(touched && error) ? classes.errorStyle : ''} key='helper-text' role='alert'>
        {touched && error ? error : translations(helperText)}
      </FormHelperText>
    </FormFieldset>
  )
}

Date.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    number: PropTypes.string
  })
}

export default withStyles(styles)(Date)
