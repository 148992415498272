import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  results: [],
  permissions: {},
  viewJSON: [],
  translationsCSV: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_FORMVIEW_BY_ID:
      return reducers.fetchFormViewById(state, action)
    case constants.FETCH_ALL_FORMVIEWS:
      return reducers.fetchAllFormViews(state, action)
    case constants.UPDATE_FORMVIEW:
      return reducers.updateFormView(state, action)
    case constants.CREATE_FORM_VERSION:
      return reducers.createFormVersion(state, action)
    case constants.UPDATE_FORM_VERSION:
      return reducers.updateFormVersion(state, action)
    case constants.TOGGLE_VISIBILITY:
      return reducers.toggleVisibility(state, action)
    case constants.ADD_QUESTION:
      return reducers.addQuestion(state, action)
    case constants.CONFIGURE_FIELD:
      return reducers.configureField(state, action)
    case constants.ADD_SECTION:
      return reducers.addSection(state, action)
    case constants.DELETE_SECTION:
      return reducers.deleteSection(state, action)
    case constants.DELETE_QUESTION:
      return reducers.deleteQuestion(state, action)
    case constants.MOVE_QUESTION:
      return reducers.moveQuestion(state, action)
    case constants.MOVE_QUESTION_TO_SECTION:
      return reducers.moveQuestionToSection(state, action)
    case constants.EDIT_REPEATABLES:
      return reducers.editRepeatables(state, action)
    case constants.SET_VIEW_JSON:
      return reducers.setViewJSON(state, action)
    case constants.SET_NOT_EDITABLE_JSON:
      return reducers.setNotEditableJSON(state, action)
    case constants.CLEAR_NOT_EDITABLE_JSON:
      return reducers.clearNotEditableJSON(state, action)
    case constants.NEW_SCHEMA_BASE:
      return reducers.setNewSchemaBase(state, action)
    case constants.SELECT_REPORT_TYPE:
      return reducers.selectReportType(state, action)
    case constants.RESET_BASE_SCHEMA:
      return reducers.resetBaseSchema(state, action)
    case constants.SET_FORM_SCHEMA_UPDATED:
      return reducers.setFormViewSchemaUpdated(state, action)
    case constants.SET_FORM_SCHEMA_UPDATE_SAVED:
      return reducers.setFormViewSchemaUpdateSaved(state, action)
    case constants.GENERATE_REPORT_CONFIGURATION_TRANSLATIONS_CSV:
      return reducers.generateReportConfigurationTranslationsCSV(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
