import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import _ from 'lodash'
import moment from 'moment'
import draftToHtml from 'draftjs-to-html'
import cx from 'classnames'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Button from '../../../components/Button'
import Can from '../../../components/Can'
import { translations, newsDateFormat } from '../../../config'

import style from './style'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import A from '../../../components/A'

const renderTitleAndDescription = ({ title, description, classes }) => {
  return (
    <Fragment>
      <Heading component='h1' variant='h1'>{title}</Heading>
      <P value={description} className={classes.descriptionText} />
    </Fragment>
  )
}

const renderLine = ({ classes }) => <div className={classes.line} />

const renderDates = ({ updatedAt, publishedDate }) => {
  const formattedPublishedDate = translations('View News Published Date', { date: moment(publishedDate).local().format(newsDateFormat) })
  const formattedUpdatedAtDate = translations('View News Updated At Date', { date: moment(updatedAt).local().format(newsDateFormat) })
  return (
    <Fragment>
      <P value={formattedPublishedDate} />
      <P value={formattedUpdatedAtDate} />
    </Fragment>
  )
}

const renderTags = ({ tags = [] }) => {
  return <P value={`${translations('Tags')}: ${tags.map(tag => tag.name).join(', ')}`}/>
}

const getExtension = url => {
  if (url.includes('.')) {
    return url.split('.').pop()
  }
  return 'File'
}

const renderDetailsAndDocuments = ({ contentHTML, documents = [], classes }) => {
  return (
    <div className={classes.detailsAndDocsContainer}>
      <div className={cx(classes.detailsAndDocsInnerContainer, classes.detailsContainer)}>
        <div dangerouslySetInnerHTML={{ __html: contentHTML }} />
      </div>
      {
        documents.length
          ? (
            <div className={cx(classes.detailsAndDocsInnerContainer, classes.documentsContainer)}>
              <Heading component='h2' variant='h3'>{translations('News Details Documents Heading')}</Heading>
              <ul className={classes.documentList}>
                {documents.map(document => {
                  const extension = getExtension(document.url)
                  return (
                    <li className={classes.linkContainer}>
                      <A value={document.title} href={document.url} target='_blank' />
                      <P className={classes.fileExtensionText} value={_.toUpper(extension)} />
                    </li>
                  )
                })}
              </ul>
            </div>
          )
          : null
      }
    </div>
  )
}

const renderSubHeader = ({ isPublic, classes, onEditClick, width }) => {
  if (isPublic) {
    return null
  }

  const rightButton = (
    <Can actions='updateNewsArticle'>
      <Button
        raised
        buttonType='primary'
        className={classes.editButton}
        onClick={onEditClick}
        aria-label={translations('Edit Article Button Text')}
      >
        {width === 'xs' ? <EditIcon /> : translations('Edit Article Button Text')}
      </Button>
    </Can>
  )

  return (
    <SubHeader
      leftContent={<BackBar />}
      rightContent={rightButton}
      />
  )
}

const ViewNewsScreen = ({ article, onEditClick, classes, width, getPageTitle, isLoading, isPublic }) => {
  const title = _.get(article, 'title')
  const pageTitle = getPageTitle(title || 'News Title')
  const description = _.get(article, 'description')
  const updatedAt = _.get(article, 'updatedAt')
  const publishedDate = _.get(article, 'publishedDate')
  const content = _.get(article, 'content')
  const documents = _.get(article, 'documents')
  const contentHTML = draftToHtml(content)
  const tags = _.get(article, 'tags')

  return (
    <div>
      {renderSubHeader({ isPublic, classes, onEditClick, width })}
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <div className={classes.contentContainer}>
        {
          !isLoading && !article
          ? <Heading variant='h2' children={translations('View News Article Error')} />
          : <Fragment>
            {renderTitleAndDescription({ title: pageTitle.title, description, classes })}
            {renderLine({ classes })}
              {renderDates({ updatedAt, publishedDate })}
              {renderTags({ tags })}
            {renderDetailsAndDocuments({ contentHTML, documents, classes })}
          </Fragment>
        }
      </div>
    </div>
  )
}

ViewNewsScreen.propTypes = {
  article: PropTypes.object,
  onEditClick: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  width: PropTypes.string,
  getPageTitle: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isPublic: PropTypes.bool
}

ViewNewsScreen.defaultProps = {
  isPublic: false
}

export default withStyles(style)(ViewNewsScreen)
