import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import VisuallyHidden from '../../../../components/VisuallyHidden'
import Heading from '../../../../components/Heading'
import Checkbox from '../../../../components/Fields/Checkbox'

import { translations } from '../../../../config'
import styles from './style'

const SubmissionReportsToolbar = (props) => {
  const {
    classes,
    navId,
    navHeading,
    actions = [],
    mode,
    toggleSelectAll
  } = props

  const isSelectionMode = mode === 'SELECTION'

  const checkboxClass = classNames(classes.checkbox, {
    [classes.checkboxSelected]: isSelectionMode
  })

  return (
    <nav className={classes.toolbar} aria-labelledby={navId}>
      <VisuallyHidden>
        <Heading id={navId} component={'h3'}>{navHeading}</Heading>
      </VisuallyHidden>
      <div className={classes.selectToggle}>
        <Checkbox
          containerClass={classes.checkboxContainer}
          givenClass={checkboxClass}
          hideLabel
          value={isSelectionMode}
          label={isSelectionMode ? translations('Unselect all') : translations('Select all')}
          onClick={toggleSelectAll}
        />
      </div>
      {_.map(actions, (action) => {
        const { id, label, icon: IconComponent, onClick, disabled = false, disabledTooltip } = action
        const tooltipDisplay = (disabled && _.isString(disabledTooltip)) ? disabledTooltip : label

        return (
          <div className={classes.action} key={id}>
            <Tooltip title={tooltipDisplay} placement={'top'} classes={{ tooltip: classes.actionTooltip }}>
              <div>
                <IconButton
                  aria-label={label}
                  onClick={onClick}
                  disabled={disabled}
                  classes={{
                    root: classes.button,
                    disabled: classes.buttonDisabled
                  }}>
                  <IconComponent />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        )
      })}
    </nav>
  )
}

SubmissionReportsToolbar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isSelected: PropTypes.bool,
  navId: PropTypes.string.isRequired,
  navHeading: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
  })),
  mode: PropTypes.oneOf(['DEFAULT', 'SELECTION']),
  toggleSelectAll: PropTypes.func.isRequired
}

export default withStyles(styles)(SubmissionReportsToolbar)
