import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormHelperText } from '@material-ui/core'
import { Input, InputLabel } from '@material-ui/core'
import { ChromePicker as PickerModal } from 'react-color'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Color from 'color'

import style from './style'
import { translations } from '../../../config'

const _getButtonContrastColor = (color) => {
  if (!color) return null
  return Color(color).isLight() ? '#000000' : '#FFFFFF'
}

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {
    const {
      type,
      value,
      label,
      onChange,
      onBlur,
      passedProps,
      meta: { error, touched } = {},
      classes,
      shrink,
      autoFocus,
      getRef,
      placeholder
    } = this.props

    const buttonContrastColor = _getButtonContrastColor(value)

    return (
      <div>
        <InputLabel
          FormControlClasses={{
            focused: classes.floatingLabelFocusStyle
          }}
          htmlFor={label}
          key='label'
          shrink={shrink}
        >
          {label}
        </InputLabel>
        <div className={classes.colorInput} >
          <IconButton aria-label={translations('Edit')} size='small' className={classes.button} style={{ backgroundColor: value }} onClick={this.handleClick}>
            <EditIcon className={classes.buttonIcon} fontSize='inherit' htmlColor={buttonContrastColor} />
          </IconButton>
          <p onClick={this.handleClick} className={classes.colorInputLabel}>{value || translations('Please select color')}</p>
        </div>
        <FormHelperText className={classes.errorStyle} key='helper-text' role='alert'>{touched && error && error}</FormHelperText>
        { this.state.displayColorPicker ? <div className={classes.popover}>
          <div className={classes.cover} onClick={this.handleClose} />
          <PickerModal
            disableAlpha
            color={value}
            onChangeComplete={color => onChange(color.hex)}
          />
        </div> : null }
      </div>
    )
  }
}

export default withStyles(style)(ColorPicker)
