import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import Date from './Date'
import { getYears } from '../../../helpers'

const _getDays = (month, year, startDate, endDate) => {
  let day = month ? moment(month, 'MM').daysInMonth() : 31
  if (year && moment(year, 'YYYY').isLeapYear() && month == 2) {
    day++
  }
  let days = []
  while (day > 0) {
    days.unshift(day > 9 ? day.toString() : `0${day.toString()}`)
    day--
  }
  if (year === _.get(startDate, 'year') && month === _.get(startDate, 'month')) {
    days = days.slice(days.indexOf(startDate.day))
  }
  if (year === _.get(endDate, 'year') && month === _.get(endDate, 'month')) {
    days = days.slice(0, days.indexOf(endDate.day) + 1)
  }
  return days
}

const _getMonths = (selectedYear, startDate, endDate) => {
  let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  if (selectedYear === _.get(startDate, 'year')) {
    months = months.slice(months.indexOf(startDate.month))
  }
  if (selectedYear === _.get(endDate, 'year')) {
    months = months.slice(0, months.indexOf(endDate.month) + 1)
  }
  return months
}
class DateContainer extends React.Component {
  handleChange = (e, type) => {
    const { value } = e.target
    const currentDates = this.formatValue(this.props.value)
    const { day, month, year } = { ...currentDates, [type]: value || '' }

    const newDate = {
      day: month && year ? day : '',
      month: year ? month : '',
      year
    }

    const date = `${newDate.year}${newDate.month}${newDate.day}`
    return date || null
  }

  onBlur = (e, type) => {
    this.props.onBlur(this.handleChange(e, type))
  }

  onChange = (e, type) => {
    this.props.onChange(this.handleChange(e, type))
  }

  onDatePicked = event => this.props.onBlur(this.props.onChange(_.replace(event.target.value, /-/gi, '')))

  formatValue = (value) => {
    if (value) {
      return {
        year: value.substring(0, 4),
        month: value.substring(4, 6),
        day: value.substring(6, 8)
      }
    } else {
      return { day: '', month: '', year: '' }
    }
  }

  _generateProps = () => {
    const formattedValue = this.formatValue(this.props.value)
    const startDateParts = this.props.startDate && this.formatValue(this.props.startDate)
    const endDateParts = this.props.endDate && this.formatValue(this.props.endDate)
    return {
      ...this.props,
      value: formattedValue,
      onBlur: this.onBlur,
      onChange: this.onChange,
      onDatePicked: this.onDatePicked,
      years: getYears(this.props.startYear || _.get(startDateParts, 'year'), this.props.endYearOffset, _.get(endDateParts, 'year')),
      months: _getMonths(formattedValue.year, startDateParts, endDateParts),
      days: _getDays(formattedValue.month, formattedValue.year, startDateParts, endDateParts),
      min: startDateParts && `${startDateParts.year}-${startDateParts.month}-${startDateParts.day}`,
      max: endDateParts && `${endDateParts.year}-${endDateParts.month}-${endDateParts.day}`
    }
  }

  render() {
    const props = this._generateProps()
    return <Date {...props} />
  }
}

export default DateContainer
