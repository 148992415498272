import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import modalService from '../../../services/modalService'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import { MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ExportModal from '../ExportModal'

class ConfigurationMoreMenu extends React.Component {
  state = {
    anchorEl: null,
    options: [
      {
        label: 'Export Analytics',
        value: 'export',
        component: ExportModal
      }
    ]
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleSelection = (value) => {
    const { onSave, toggleEdit, editing = false } = this.props
    const { options } = this.state
    const selectedOption = _.find(options, { value })
    const modalComponent = _.get(selectedOption, 'component')

    if (modalComponent) {
      modalService.open({
        component: modalComponent,
        onSave,
        toggleEdit,
        editing,
        wideModal: true
      })
    }

    this.handleClose()
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render () {
    const { anchorEl, options } = this.state
    const open = Boolean(anchorEl)

    return (
      <div>
        <IconButton
          aria-label='More'
          aria-owns={open ? 'configuration-menu' : null}
          aria-haspopup='true'
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='configuration-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{}}
        >
          {options.map(option => (
            <MenuItem key={option.value} onClick={() => this.handleSelection(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

ConfigurationMoreMenu.propTypes = {
  onSave: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired
}

export default ConfigurationMoreMenu
