import { connect } from 'react-redux'
import { compose } from 'recompose'

import CreateNewsScreen from './CreateNewsScreen'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

const enhancer = compose(
  connect(state => ({
    getPageTitle: platformSelectors.getPageTitle(state)
  }))
)

export default enhancer(CreateNewsScreen)
