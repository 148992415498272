import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import Skeleton from '@material-ui/lab/Skeleton'
import P from '../../../../components/P'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'
import Heading from '../../../../components/Heading'
import RightButtonContent from '../../../../components/Form/RightButtonContent'
import Button from '../../../../components/Button'
import CopyToClipboardButton from '../../../../components/CopyToClipboardButton'
import APIKeyRow from './APIKeyRow'
import GenerateKeyModal from './GenerateKeyModal'

import { DataTable } from '../../../../components/DataTable'
import { hooks as authHooks } from '../../../../store/modules/auth'
import {
  actions as developerActions,
  hooks as developerHooks
} from '../../../../store/modules/developer'
import { PENDING } from '../../../../store/middleware/redux-promise'
import modalService from '../../../../services/modalService'

import { styled } from '@material-ui/core/styles'
import { translations } from '../../../../config'
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import RemoveIcon from '@material-ui/icons/Delete'
import GenerateApplicationModal from './GenerateApplicationModal/GenerateApplicationModal'
import ConfirmationModal from './ConfirmationModal'

const MultiContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `2px solid ${theme.palette.grey['200']}`
}))

const APIKeysContent = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

const TextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const Text = styled('p')({
  fontSize: '16px',
  fontWeight: 'bold',
  paddingLeft: 5,
  margin: 0
})

const LastUsedAt = styled('p')({
  fontSize: '12px',
  paddingLeft: 5,
  paddingTop: 5,
  margin: 0
})

const Content = styled('div')({
  width: '100%'
})

const TableContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2),
}))

const Intro = styled('div')(({ theme }) => ({
  ...theme.typography.body2
}))

const Alert = styled('div')(({ theme }) => ({
  background: theme.palette.grey['100'],
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1)
}))

const BlankState = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey['100'],
  textAlign: 'center',
  marginTop: theme.spacing(2)
}))

const Action = styled('div')(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  }
}))

const SkeletonContainer = styled('div')(({ theme }) => ({
  '& > span': {
    marginBottom: theme.spacing(1)
  }
}))

const LoadingState = () => {
  return (
    <SkeletonContainer>
      <Skeleton variant={'rect'} height={45} />
      <Skeleton variant={'text'} />
      <Skeleton variant={'text'} />
      <Skeleton variant={'text'} />
      <Skeleton variant={'text'} />
    </SkeletonContainer>
  )
}

const APIKeysTable = (props) => {
  const { isLoading, data, rowProps = {} } = props

  if (!isLoading && _.isEmpty(data)) {
    return (
      <BlankState>
        <P role='status' value={translations('No results found')} />
      </BlankState>
    )
  }

  return (
    <>
      <TableContainer>
        <DataTable
          data={data}
          rowComponent={APIKeyRow}
          isLoading={isLoading}
          tableId={'api-keys-table'}
          tableHeading={'API Keys'}
          primaryColumnWidth={'40%'}
          rowProps={rowProps}
          headers={[
            { id: 'name', label: translations('Name') },
            { id: 'version', label: translations('Created by'), options: { align: 'right' } },
            { id: 'createdAt', label: translations('Created at'), options: { align: 'right' } },
            { id: 'actions', options: { align: 'right' } }
          ]}
        />
      </TableContainer>
    </>
  )
}

const ApplicationAccessPanel = (props) => {
  const {
    application,
    multiple = false,
    isLoading
  } = props

  const {
    id: applicationId,
    name: applicationName,
    lastUsedAt: applicationLastUsedAt,
    canEdit = false,
    canDelete = false
  } = application

  const dispatch = useDispatch()

  const organisationId = authHooks.useOrganisation()
  const { status, result, error } = developerHooks.useApiKeys(applicationId)

  useEffect(() => {
    if (organisationId && applicationId) {
      dispatch(developerActions.getApiKeys({ organisationId, applicationId }))
    }
  }, [dispatch, organisationId, applicationId])

  const onCreate = () => {
    modalService.open({
      component: GenerateKeyModal,
      applicationId,
      organisationId,
      disableBackdropClick: true,
      onSubmit: () => {
        dispatch(developerActions.getApiKeys({ organisationId, applicationId }))
        dispatch(developerActions.clearTemporaryApiKey)
      }
    })
  }

  const onUpdate = () => {
    modalService.open({
      component: GenerateApplicationModal,
      disableBackdropClick: true,
      organisationId,
      applicationId,
      data: { name: applicationName },
      isUpdate: true
    })
  }

  const onDelete = () => {
    modalService.open({
      component: ConfirmationModal,
      disableBackdropClick: true,
      onConfirmation: (() => dispatch(developerActions.deleteApplication({ id: applicationId }))),
      title: translations('Developer - Delete Application'),
      message: translations('Developer - Delete Application Confirmation')
    })
  }

  const renderContentMultiple = () => {
    if (isLoading) {
      return <LoadingState />
    }
    return (
      <MultiContainer>
        <Intro>
          <APIKeysContent>
            <TextContainer>
              <Text>{applicationName}</Text>
              <LastUsedAt>{`Last used at: ${applicationLastUsedAt ? moment(applicationLastUsedAt).format('DD/MM/YYYY HH:mm') : 'N/A'}`}</LastUsedAt>
            </TextContainer>
            {applicationId && (canEdit || canDelete) &&
              <div>
                {canDelete && <IconButton onClick={onDelete} disabled={isLoading} aria-label='Delete'>
                  <RemoveIcon />
                </IconButton>}
                {canEdit && <IconButton onClick={onUpdate} disabled={isLoading} aria-label='Edit'>
                  <EditIcon />
                </IconButton>}
              </div>
            }
          </APIKeysContent>
          <Alert>
            <strong>X-App-Id:</strong> {applicationId}
            <CopyToClipboardButton text={applicationId} />
          </Alert>
        </Intro>
        <APIKeysContent>
          <Text>{translations(`API Keys`)}</Text>
          <Button onClick={onCreate} disabled={isLoading} raised color='primary'>{translations('Generate API Key')}</Button>
        </APIKeysContent>
        <APIKeysTable
          data={result}
          isLoading={status === PENDING}
          error={error}
          rowProps={{
            applicationId,
            organisationId
          }}
          multiple={true}
        />
      </MultiContainer>
    )
  }

  if (multiple) {
    return renderContentMultiple()
  }

  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading>{translations(`API Access Keys`)}</Heading>
        </TextContent>
        <RightButtonContent>
          <Action>
            <Button onClick={onCreate} disabled={isLoading} raised color='primary'>{translations('Generate API Key')}</Button>
          </Action>
        </RightButtonContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <Content>
          {
            isLoading
              ? <LoadingState />
              : (
                <>
                  <Intro>
                    <p>{translations('Developer - API access intro')}</p>
                    <Alert>
                      <strong>X-App-Id:</strong> {applicationId}
                      <CopyToClipboardButton text={applicationId} />
                    </Alert>
                  </Intro>
                  <APIKeysTable
                    data={result}
                    isLoading={status === PENDING}
                    error={error}
                    rowProps={{
                      applicationId,
                      organisationId
                    }}
                  />
                </>
              )
          }
        </Content>
      </ContentBoxBody>
    </ContentBox>
  )
}

export default ApplicationAccessPanel
