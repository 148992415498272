"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nonDeviceDistributionCountriesDropdownOptions = exports.getCountryCodeByCountryName = exports.eeaCountryCodes = exports.deviceDistributionCountriesDropdownOptions = exports.countryCodesDropdownOptions = exports.camelCaseCountryLookUp = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var countries = [{
  name: "Afghanistan",
  isoCode: "AF",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Åland Islands",
  isoCode: "AX",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Albania",
  isoCode: "AL",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Algeria",
  isoCode: "DZ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "American Samoa",
  isoCode: "AS",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Andorra",
  isoCode: "AD",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Angola",
  isoCode: "AO",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Anguilla",
  isoCode: "AI",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Antarctica",
  isoCode: "AQ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Antigua and Barbuda",
  isoCode: "AG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Argentina",
  isoCode: "AR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Armenia",
  isoCode: "AM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Aruba",
  isoCode: "AW",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Australia",
  isoCode: "AU",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Austria",
  isoCode: "AT",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Azerbaijan",
  isoCode: "AZ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Bahamas",
  isoCode: "BS",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Bahrain",
  isoCode: "BH",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Bangladesh",
  isoCode: "BD",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Barbados",
  isoCode: "BB",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Belarus",
  isoCode: "BY",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Belgium",
  isoCode: "BE",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Belize",
  isoCode: "BZ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Benin",
  isoCode: "BJ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Bermuda",
  isoCode: "BM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Bhutan",
  isoCode: "BT",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Bolivia, Plurinational State of",
  isoCode: "BO",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Bonaire, Sint Eustatius and Saba",
  isoCode: "BQ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Bosnia and Herzegovina",
  isoCode: "BA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Botswana",
  isoCode: "BW",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Bouvet Island",
  isoCode: "BV",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Brazil",
  isoCode: "BR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "British Indian Ocean Territory",
  isoCode: "IO",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Brunei Darussalam",
  isoCode: "BN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Bulgaria",
  isoCode: "BG",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Burkina Faso",
  isoCode: "BF",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Burundi",
  isoCode: "BI",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Cambodia",
  isoCode: "KH",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Cameroon",
  isoCode: "CM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Canada",
  isoCode: "CA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Cape Verde",
  isoCode: "CV",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Cayman Islands",
  isoCode: "KY",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Central African Republic",
  isoCode: "CF",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Chad",
  isoCode: "TD",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Chile",
  isoCode: "CL",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "China",
  isoCode: "CN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Christmas Island",
  isoCode: "CX",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Cocos (Keeling) Islands",
  isoCode: "CC",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Colombia",
  isoCode: "CO",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Comoros",
  isoCode: "KM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Congo",
  isoCode: "CG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Congo, the Democratic Republic of the",
  isoCode: "CD",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Cook Islands",
  isoCode: "CK",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Costa Rica",
  isoCode: "CR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Croatia",
  isoCode: "HR",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Cuba",
  isoCode: "CU",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Curaçao",
  isoCode: "CW",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Cyprus",
  isoCode: "CY",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Czech Republic",
  isoCode: "CZ",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Côte d'Ivoire",
  isoCode: "CI",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Denmark",
  isoCode: "DK",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Djibouti",
  isoCode: "DJ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Dominica",
  isoCode: "DM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Dominican Republic",
  isoCode: "DO",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Ecuador",
  isoCode: "EC",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Egypt",
  isoCode: "EG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "El Salvador",
  isoCode: "SV",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Equatorial Guinea",
  isoCode: "GQ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Eritrea",
  isoCode: "ER",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Estonia",
  isoCode: "EE",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Ethiopia",
  isoCode: "ET",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "European Union",
  isoCode: "EU",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Falkland Islands (Malvinas)",
  isoCode: "FK",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Faroe Islands",
  isoCode: "FO",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Fiji",
  isoCode: "FJ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Finland",
  isoCode: "FI",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "France",
  isoCode: "FR",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "French Guiana",
  isoCode: "GF",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "French Polynesia",
  isoCode: "PF",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "French Southern Territories",
  isoCode: "TF",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Gabon",
  isoCode: "GA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Gambia",
  isoCode: "GM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Georgia",
  isoCode: "GE",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Germany",
  isoCode: "DE",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Ghana",
  isoCode: "GH",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Gibraltar",
  isoCode: "GI",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Greece",
  isoCode: "GR",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Greenland",
  isoCode: "GL",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Grenada",
  isoCode: "GD",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Guadeloupe",
  isoCode: "GP",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Guam",
  isoCode: "GU",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Guatemala",
  isoCode: "GT",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Guernsey",
  isoCode: "GG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Guinea",
  isoCode: "GN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Guinea-Bissau",
  isoCode: "GW",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Guyana",
  isoCode: "GY",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Haiti",
  isoCode: "HT",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Heard Island and McDonald Islands",
  isoCode: "HM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Holy See (Vatican City State)",
  isoCode: "VA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Honduras",
  isoCode: "HN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Hong Kong",
  isoCode: "HK",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Hungary",
  isoCode: "HU",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Iceland",
  isoCode: "IS",
  memberOf: {
    eu: false,
    eea: true
  }
}, {
  name: "India",
  isoCode: "IN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Indonesia",
  isoCode: "ID",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Iran, Islamic Republic of",
  isoCode: "IR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Iraq",
  isoCode: "IQ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Ireland",
  isoCode: "IE",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Isle of Man",
  isoCode: "IM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Israel",
  isoCode: "IL",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Italy",
  isoCode: "IT",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Jamaica",
  isoCode: "JM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Japan",
  isoCode: "JP",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Jersey",
  isoCode: "JE",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Jordan",
  isoCode: "JO",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Kazakhstan",
  isoCode: "KZ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Kenya",
  isoCode: "KE",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Kiribati",
  isoCode: "KI",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Korea, Democratic People's Republic of",
  isoCode: "KP",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Korea, Republic of",
  isoCode: "KR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Kuwait",
  isoCode: "KW",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Kyrgyzstan",
  isoCode: "KG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Lao People's Democratic Republic",
  isoCode: "LA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Latvia",
  isoCode: "LV",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Lebanon",
  isoCode: "LB",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Lesotho",
  isoCode: "LS",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Liberia",
  isoCode: "LR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Libya",
  isoCode: "LY",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Liechtenstein",
  isoCode: "LI",
  memberOf: {
    eu: false,
    eea: true
  }
}, {
  name: "Lithuania",
  isoCode: "LT",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Luxembourg",
  isoCode: "LU",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Macao",
  isoCode: "MO",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Macedonia, the Former Yugoslav Republic of",
  isoCode: "MK",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Madagascar",
  isoCode: "MG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Malawi",
  isoCode: "MW",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Malaysia",
  isoCode: "MY",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Maldives",
  isoCode: "MV",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Mali",
  isoCode: "ML",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Malta",
  isoCode: "MT",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Marshall Islands",
  isoCode: "MH",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Martinique",
  isoCode: "MQ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Mauritania",
  isoCode: "MR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Mauritius",
  isoCode: "MU",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Mayotte",
  isoCode: "YT",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Mexico",
  isoCode: "MX",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Micronesia, Federated States of",
  isoCode: "FM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Moldova, Republic of",
  isoCode: "MD",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Monaco",
  isoCode: "MC",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Mongolia",
  isoCode: "MN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Montenegro",
  isoCode: "ME",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Montserrat",
  isoCode: "MS",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Morocco",
  isoCode: "MA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Mozambique",
  isoCode: "MZ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Myanmar",
  isoCode: "MM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Namibia",
  isoCode: "NA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Nauru",
  isoCode: "NR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Nepal",
  isoCode: "NP",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Netherlands",
  isoCode: "NL",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "New Caledonia",
  isoCode: "NC",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "New Zealand",
  isoCode: "NZ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Nicaragua",
  isoCode: "NI",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Niger",
  isoCode: "NE",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Nigeria",
  isoCode: "NG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Niue",
  isoCode: "NU",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Norfolk Island",
  isoCode: "NF",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Northern Ireland",
  isoCode: "XI",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Northern Mariana Islands",
  isoCode: "MP",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Norway",
  isoCode: "NO",
  memberOf: {
    eu: false,
    eea: true
  }
}, {
  name: "Oman",
  isoCode: "OM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Pakistan",
  isoCode: "PK",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Palau",
  isoCode: "PW",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Palestine, State of",
  isoCode: "PS",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Panama",
  isoCode: "PA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Papua New Guinea",
  isoCode: "PG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Paraguay",
  isoCode: "PY",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Peru",
  isoCode: "PE",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Philippines",
  isoCode: "PH",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Pitcairn",
  isoCode: "PN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Poland",
  isoCode: "PL",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Portugal",
  isoCode: "PT",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Puerto Rico",
  isoCode: "PR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Qatar",
  isoCode: "QA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Romania",
  isoCode: "RO",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Russian Federation",
  isoCode: "RU",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Rwanda",
  isoCode: "RW",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Réunion",
  isoCode: "RE",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Saint Barthélemy",
  isoCode: "BL",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Saint Helena, Ascension and Tristan da Cunha",
  isoCode: "SH",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Saint Kitts and Nevis",
  isoCode: "KN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Saint Lucia",
  isoCode: "LC",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Saint Martin (French part)",
  isoCode: "MF",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Saint Pierre and Miquelon",
  isoCode: "PM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Saint Vincent and the Grenadines",
  isoCode: "VC",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Samoa",
  isoCode: "WS",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "San Marino",
  isoCode: "SM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Sao Tome and Principe",
  isoCode: "ST",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Saudi Arabia",
  isoCode: "SA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Senegal",
  isoCode: "SN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Serbia",
  isoCode: "RS",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Seychelles",
  isoCode: "SC",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Sierra Leone",
  isoCode: "SL",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Singapore",
  isoCode: "SG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Sint Maarten (Dutch part)",
  isoCode: "SX",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Slovakia",
  isoCode: "SK",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Slovenia",
  isoCode: "SI",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Solomon Islands",
  isoCode: "SB",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Somalia",
  isoCode: "SO",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "South Africa",
  isoCode: "ZA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "South Georgia and the South Sandwich Islands",
  isoCode: "GS",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "South Sudan",
  isoCode: "SS",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Spain",
  isoCode: "ES",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Sri Lanka",
  isoCode: "LK",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Sudan",
  isoCode: "SD",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Suriname",
  isoCode: "SR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Svalbard and Jan Mayen",
  isoCode: "SJ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Swaziland",
  isoCode: "SZ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Sweden",
  isoCode: "SE",
  memberOf: {
    eu: true,
    eea: true
  }
}, {
  name: "Switzerland",
  isoCode: "CH",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Syrian Arab Republic",
  isoCode: "SY",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Taiwan, Province of China",
  isoCode: "TW",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Tajikistan",
  isoCode: "TJ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Tanzania, United Republic of",
  isoCode: "TZ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Thailand",
  isoCode: "TH",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Timor-Leste",
  isoCode: "TL",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Togo",
  isoCode: "TG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Tokelau",
  isoCode: "TK",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Tonga",
  isoCode: "TO",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Trinidad and Tobago",
  isoCode: "TT",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Tunisia",
  isoCode: "TN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Turkey",
  isoCode: "TR",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Turkmenistan",
  isoCode: "TM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Turks and Caicos Islands",
  isoCode: "TC",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Tuvalu",
  isoCode: "TV",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Uganda",
  isoCode: "UG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Ukraine",
  isoCode: "UA",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "United Arab Emirates",
  isoCode: "AE",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "United Kingdom",
  isoCode: "GB",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "United States",
  isoCode: "US",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "United States Minor Outlying Islands",
  isoCode: "UM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Uruguay",
  isoCode: "UY",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Uzbekistan",
  isoCode: "UZ",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Vanuatu",
  isoCode: "VU",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Venezuela, Bolivarian Republic of",
  isoCode: "VE",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Viet Nam",
  isoCode: "VN",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Virgin Islands, British",
  isoCode: "VG",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Virgin Islands, U.S.",
  isoCode: "VI",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Wallis and Futuna",
  isoCode: "WF",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Western Sahara",
  isoCode: "EH",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Yemen",
  isoCode: "YE",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Zambia",
  isoCode: "ZM",
  memberOf: {
    eu: false,
    eea: false
  }
}, {
  name: "Zimbabwe",
  isoCode: "ZW",
  memberOf: {
    eu: false,
    eea: false
  }
}];

var countriesWithIsoCodeValuesToDropDown = _fp["default"].map(function (country) {
  return {
    label: country.name,
    value: country.isoCode
  };
});

var camelCaseCountryLookUp = _fp["default"].transform(function (lookUp, country) {
  var key = _fp["default"].camelCase(country.name);

  lookUp[key] = country.isoCode;
}, {}, countries);

exports.camelCaseCountryLookUp = camelCaseCountryLookUp;

var isoCodesToDropDown = _fp["default"].map(function (country) {
  return {
    label: country.isoCode,
    value: country.isoCode
  };
});

var appendCountriesToListByCode = function appendCountriesToListByCode(isoCodes, countries) {
  return function (currentList) {
    return _fp["default"].compose(function (additionalCountries) {
      return _fp["default"].concat(currentList, additionalCountries);
    }, _fp["default"].filter(function (_ref) {
      var isoCode = _ref.isoCode;
      return _fp["default"].includes(isoCode, isoCodes);
    }))(countries);
  };
};

var removeCountriesFromListByCode = function removeCountriesFromListByCode(isoCodes) {
  return _fp["default"].reject(function (_ref2) {
    var isoCode = _ref2.isoCode;
    return _fp["default"].includes(isoCode, isoCodes);
  });
};

var countryCodesDropdownOptions = countriesWithIsoCodeValuesToDropDown(countries);
exports.countryCodesDropdownOptions = countryCodesDropdownOptions;
var MEMBER_OF_EEA = 'memberOf.eea';

var getEaaCountries = _fp["default"].filter(MEMBER_OF_EEA);

var removeEaaCountries = _fp["default"].reject(MEMBER_OF_EEA);

var sortCountriesByValueAscending = _fp["default"].orderBy(['value'], ['asc']);

var eeaCountryCodes = _fp["default"].compose(_fp["default"].map('isoCode'), getEaaCountries)(countries);

exports.eeaCountryCodes = eeaCountryCodes;

var deviceDistributionCountriesDropdownOptions = _fp["default"].compose(sortCountriesByValueAscending, isoCodesToDropDown, removeCountriesFromListByCode(['EU', 'XI']), appendCountriesToListByCode(['CH', 'GB', 'TR'], countries), getEaaCountries)(countries);

exports.deviceDistributionCountriesDropdownOptions = deviceDistributionCountriesDropdownOptions;

var nonDeviceDistributionCountriesDropdownOptions = _fp["default"].compose(sortCountriesByValueAscending, isoCodesToDropDown, removeCountriesFromListByCode(['CH', 'GB', 'TR']), removeEaaCountries)(countries);

exports.nonDeviceDistributionCountriesDropdownOptions = nonDeviceDistributionCountriesDropdownOptions;

var getCountryCodeByCountryName = function getCountryCodeByCountryName(_ref3) {
  var countryName = _ref3.countryName;

  var _ref4 = countries.find(function (country) {
    return country.name === countryName;
  }) || {},
      isoCode = _ref4.isoCode;

  return {
    countryCode: isoCode
  };
};

exports.getCountryCodeByCountryName = getCountryCodeByCountryName;