import React from 'react'
import PropTypes from 'prop-types'
import VisuallyHidden from '../VisuallyHidden'
import { translations } from '../../config'

const NumberOfResultsAnnouncer = ({ count }) => {
  return (
    <VisuallyHidden aria-live='assertive' aria-atomic>
      {translations('Number of results', { count })}
    </VisuallyHidden>
  )
}

NumberOfResultsAnnouncer.propTypes = {
  count: PropTypes.number.isRequired
}

export default NumberOfResultsAnnouncer
