import { styled } from '@material-ui/core/styles'

export default styled('div')(({ theme }) => ({
  marginTop: -theme.spacing(3),
  marginLeft: -theme.spacing(3),
  marginRight: -theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  background: theme.palette.grey[200]
}))
