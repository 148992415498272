import React, { useState, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { Form } from 'react-final-form'
import { FORM_ERROR, getIn } from 'final-form'
import createDecorator from 'final-form-focus'
import arrayMutators from 'final-form-arrays'

import { useSdk } from '../../services/sdk/hooks'
import { useReportConfigurationState } from '../../services/reportConfigurationContext'
import { useDiagnostics } from '../../services/diagnostics/hooks'
import { useFormStatusState } from '../../services/formStatusContext'
import FormSchemaLibrary, { utilities, constants as formSchemaConstants } from '@redant/mhra-form-schema-library'

import FormWizard from '../FormWizard'
import FormDisplay from '../FormDisplay'
import { mergeCombinedRepeatables } from './helpers'

const formSchemaLibrary = new FormSchemaLibrary()

const Content = styled.div``

/**
 * Creates a form from a schema
 */
const ReportForm = (props) => {
  const {
    onSuccess,
    onError,
    initialValues,
    userDetails,
    reportTarget,
    onSaveDraftSuccess,
    onSaveDraftError,
    reportId,
    extReportId,
    isLoggedIn,
    enableDrafts,
    keepDirtyOnReinitialize,
    onFormStateChange,
    singlePageDisplay: _singlePageDisplay,
    isWorkspace,
    readOnly,
    acknowledgementReport,
    isNewReport
  } = props

  const [index, setIndex] = useState(0)
  const [stepCount, setStepCount] = useState(0)
  const [freshStep, setFreshStep] = useState(false)
  const touchedInputs = useRef({})

  const findInput = (inputs, errors) => {
    const notTouched = !_.some(touchedInputs.current.value, Boolean)
    if (notTouched) return null
    return inputs.find(input => {
      const name = input.name || _.get(input, 'dataset.name')
      return name && getIn(errors, name)
    })
  }
  const focusOnErrors = useMemo(() => createDecorator(null, findInput), [])

  const setTouchedInputs = (updatedTouchedInputs) => {
    if (_.isEqual(touchedInputs, updatedTouchedInputs)) return
    touchedInputs.current.value = updatedTouchedInputs
  }

  const { postReport, postAcknowledgement, setAttachmentFields, setFormContext } = useSdk()
  const {
    clearRequiredRepeatable,
    requiredRepeatables,
    openRepeatables,
    clearOpenRepeatables,
    removeOpenRepeatableByName,
    hasSubmit,
    setHasSubmit,
    combinedRepeatables
  } = useFormStatusState()

  const {
    organisationDetails,
    productDetails,
    formView,
    followUp,
    professions,
    formSchemaId,
    schemaName,
    defaultViewJSON,
    formSchemaType
  } = useReportConfigurationState()
  const diagnostics = useDiagnostics()

  useEffect(() => {
    if (formSchemaType === formSchemaConstants.FORM_TYPE_CONSTANTS.ACKNOWLEDGEMENT && !reportId) {
      throw new Error('"reportId" is required for acknowledgements forms')
    }
  }, [reportId, formSchemaType])

  const singlePageDisplay = isSchemaAllowedSinglePageDisplay(schemaName) && _singlePageDisplay

  useEffect(() => {
    if (freshStep) {
      onNavigation()
      setIndex(index + 1)
      setFreshStep(false)
    }
  }, [freshStep])

  useEffect(() => {
    if(!readOnly) {
      clearRequiredRepeatable()
      clearOpenRepeatables()
    }
  }, [readOnly])

  diagnostics.trackReportMetadata({ formView })
  diagnostics.setFormSchemaName(schemaName)

  useEffect(() => {
    const attachmentFields = _.isString(schemaName) && formSchemaLibrary.getSchemaAttachmentFields(schemaName)
    if (attachmentFields) {
      setAttachmentFields(attachmentFields)
    }
  }, [schemaName])

  useEffect(() => {
    setFormContext({
      formSchema: schemaName,
      formSchemaId,
      formView,
      reportId,
      followUp
    })
  }, [formSchemaId, formView, followUp, reportId, schemaName])

  const onNavigation = () => {
    if (!_.isUndefined(window)) {
      window.scroll(0, 0)
    }
  }
  const onPrevious = () => {
    setFreshStep(false)
    clearRequiredRepeatable()
    clearOpenRepeatables()
    setIndex(index - 1)
    setHasSubmit(true)
  }

  const onStepCount = (count) => setStepCount(count)

  const validateRequiredRepeatables = (values, requiredRepeatables) => {
    const errors = {}
    _.forEach(requiredRepeatables, (repeatableId) => {
      if (_.get(values[repeatableId], '0._isOpen') || !_.size(values[repeatableId])) {
        errors[repeatableId] = "Field is required";
      }
    })
    return errors
  }

  const submitRepeatables = (values, requiredRepeatables) => {
    let errors = {}
    for (const repeatable of openRepeatables) {
      repeatable.submit()
      errors = { ...errors, ...validateRepeatable(repeatable, requiredRepeatables, values) }
    }
    return errors
  }

  const validateRepeatable = (repeatable, requiredRepeatables, values) => {
    const id = repeatable.fieldName
    const error = {}
    // Set error to null, so we can omit it later if it does not exist
    error[id] = null
    const isRequired = _.includes(requiredRepeatables, id) && !_.size(values[id])
    const isDirty = repeatable.isDirty
    const isPrepopulated = _.get(values[id], '0._isOpen')
    if (!repeatable.isValid && (isRequired || isDirty || isPrepopulated)) {
      error[id] = "Please make sure all required fields are complete"
    } else {
      removeOpenRepeatableByName(id)
    }
    return error
  }

  /**
   * Handles a submission to the API
   *
   * @param {Object} values The values submitted by the user.
   * @param {string} extReportId The extReportId the report to associate the submission with.
   * @param {string} formType The type of form schema used for the submission.
   * @returns {Object} The response object from the submission handler.
   */
  const handleSubmission = async ({ values, extReportId, formType }) => {
    try {
      let result
      let response

      switch (formType) {
        case formSchemaConstants.FORM_TYPE_CONSTANTS.REPORT:
          response = await postReport({ values })
          result = _.defaultsDeep(response, { report: { extReportId } })
          break;
        case formSchemaConstants.FORM_TYPE_CONSTANTS.ACKNOWLEDGEMENT:
          response = await postAcknowledgement({ values, reportId })
          result = response
          break;
        default:
          throw new Error(`Missing submission handler for ${formType}`)
      }

      return result
    } catch (error) {
      throw error
    }
  }

  const onSubmit = async (values) => {
    const requiredErrors = validateRequiredRepeatables(values, requiredRepeatables)
    const repeatableErrors = submitRepeatables(values, requiredRepeatables)

    // Omit any errors that are null - i.e. not actually an error
    const errors = _.omitBy({ ...requiredErrors, ...repeatableErrors }, _.isNil)
    if (!_.isEmpty(errors)) {
      return errors
    }

    /*
    * Due to state not changing inside this function until it's finished
    * Any open repeatables will have to be submit by returning nothing so the state can update
    * Then the user will have to re-submit to avoid any data going missing.
    */
    if (!_.isEmpty(openRepeatables) && !hasSubmit) {
      clearOpenRepeatables()
      setHasSubmit(true)
      return
    }

    const finalValues = mergeCombinedRepeatables(combinedRepeatables, values)

    if (index !== (stepCount - 1) && !singlePageDisplay) {
      // {submitting: true} hangs if we dont resolve the promise
      setFreshStep(true)
      setHasSubmit(false)
      return {}
    } else {
      return handleSubmission({ values, extReportId, formType: formSchemaType })
        .then((res) => {
          onSuccess(res, finalValues)
        })
        .catch((error) => {
          onError(error)
          let formError = 'Sorry, there has been a problem please try again.'
          const errorMessage = _.get(error, 'message')
          const errorCode = _.get(error, 'code')
          const invalidErrorCodes = ['RA-162-02']

          if (_.isString(errorMessage) && !_.includes(errorCode, invalidErrorCodes)) {
            formError = errorMessage
          }

          return { [FORM_ERROR]: formError }
        })
    }
  }

  const generatedInitialValues = useMemo(() => {
    switch (formSchemaType) {
      case formSchemaConstants.FORM_TYPE_CONSTANTS.REPORT:
        return utilities.generateInitialValues({ schemaName, initialValues, userDetails, organisationDetails, productDetails, formView, reportTarget, followUp, isWorkspace, options: { skipComputedValues: !isNewReport, skipDeviceValues: !isNewReport } })
      case formSchemaConstants.FORM_TYPE_CONSTANTS.ACKNOWLEDGEMENT:
        return utilities.generateAckInitialValues({ schemaName, initialValues, reportJSON: acknowledgementReport, options: { flattenMessages: true } })
      default:
        return {}
    }    
  }, [schemaName, initialValues, userDetails, organisationDetails, formView, reportTarget, followUp, isWorkspace, acknowledgementReport, formSchemaType])

  const getDisplayComponent = () => {
    if (singlePageDisplay || readOnly) {
      return FormDisplay
    }
    return FormWizard
  }

  return (
    <div>
      <Content>
        <Form
          initialValues={generatedInitialValues}
          keepDirtyOnReinitialize={keepDirtyOnReinitialize}
          initialValuesEqual={keepDirtyOnReinitialize ? () => true : undefined}
          component={getDisplayComponent()}
          decorators={[focusOnErrors]}
          mutators={{
            ...arrayMutators
          }}
          subscription={{
            values: true,
            submitting: true,
            submitError: true,
            submitErrors: true,
            submitFailed: true,
            submitSucceeded: true,
            touched: true,
          }}
          formSchemaId={formSchemaId}
          schemaName={schemaName}
          organisationDetails={organisationDetails}
          professions={professions}
          formViewDetails={formView}
          index={index}
          onSubmit={onSubmit}
          onPrevious={onPrevious}
          onStepCount={onStepCount}
          onSaveDraftSuccess={onSaveDraftSuccess}
          onSaveDraftError={onSaveDraftError}
          reportId={reportId}
          followUp={followUp}
          isLoggedIn={isLoggedIn}
          enableDrafts={enableDrafts}
          formSchemaLibrary={formSchemaLibrary}
          onFormStateChange={onFormStateChange}
          setTouched={setTouchedInputs}
          defaultViewJSON={defaultViewJSON}
          readOnly={readOnly}
        />
      </Content>
    </div>
  )
}

ReportForm.propTypes = {
  /**
   * Callback when form has been successfully submitted
  */
  onSuccess: PropTypes.func,
  /**
   * Initial values to pre-fill the form
  */
  initialValues: PropTypes.object,
  /**
   * User details to prepopulate the relevant form fields
  */
  userDetails: PropTypes.object,
  /**
   * The focus of the report i.e. drug or device name
   */
  reportTarget: PropTypes.object,
  /**
   * Report details for the report being acknowledged
   */
  acknowledgementReport: PropTypes.object  
}

ReportForm.defaultProps = {
  userDetails: {},
  initialValues: {},
  acknowledgementReport: {}
}


const isSchemaAllowedSinglePageDisplay = (schemaName) => {
  switch (schemaName) {
    case 'devicesReport':
      return false
    default:
      return true
  }
}

export default ReportForm
