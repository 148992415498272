import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledStep = styled.div``

/**
 * Individual step of the wizard
 */
const WizardStep = (props) => {
  const { children } = props
    return (
    <StyledStep>
      {children}
    </StyledStep>
  )
}

WizardStep.propTypes = {
  /** The step to render */
  children: PropTypes.object.isRequired
}

export default WizardStep
