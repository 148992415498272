import React, { useState } from 'react'
import _ from 'lodash'

const FormStatusContext = React.createContext()

const FormStatusProvider = (props) => {
  const { children } = props

  const [openRepeatables, setOpenRepeatables] = useState([])
  const [requiredRepeatables, setRequiredRepeatables] = useState([])
  const [combinedRepeatables, setCombinedRepeatables] = useState([])
  const [hasSubmit, setHasSubmit] = useState(false)

  const setOpenRepeatable = (error, formId, fieldName, submit, isValid, isDirty) => {
    const newErrors = _.filter(openRepeatables, (r) => r.formId !== formId)
    return setOpenRepeatables(_.concat(newErrors, [{ formId, error, fieldName, submit, isValid, isDirty}]))
  }

  const getOpenRepeatables = () => {
    return openRepeatables
  }

  const getOpenRepeatable = (formId) => {
    return _.filter(openRepeatables, r => r.formId === formId)[0]
  }
  
  const removeOpenRepeatable = (formId) => {
    const newOpenRepeatables = _.filter(openRepeatables, (r) => r.formId !== formId)
    return setOpenRepeatables([...newOpenRepeatables])
  }

  const removeOpenRepeatableByName = (name) => {
    const newOpenRepeatables = _.filter(openRepeatables, (r) => r.fieldName !== name)
    return setOpenRepeatables(newOpenRepeatables)
  }

  const clearOpenRepeatables = () => {
    return setOpenRepeatables([])
  }

  const setRequiredRepeatable = (repeatableId) => {
    return setRequiredRepeatables([...requiredRepeatables, repeatableId])
  }

  const clearRequiredRepeatableId = (repeatableId) => {
    const removeRequiredRepeatable = _.filter(requiredRepeatables, (repeatable) => repeatable !== repeatableId)
    return setRequiredRepeatables(removeRequiredRepeatable)
  }

  const clearRequiredRepeatable = () => {
    return setRequiredRepeatables([])
  }

  const setCombinedRepeatable = (id) => {
    return setCombinedRepeatables([...combinedRepeatables, id])
  }

  const openRepeatablesContext = {
    openRepeatables,
    setOpenRepeatable,
    getOpenRepeatable,
    removeOpenRepeatable,
    removeOpenRepeatableByName,
    clearOpenRepeatables,
    getOpenRepeatables
  }

  const requiredRepeatablesContext = {
    requiredRepeatables,
    setRequiredRepeatable,
    clearRequiredRepeatable,
    clearRequiredRepeatableId
  }

  const combinedRepeatablesContext = {
    combinedRepeatables,
    setCombinedRepeatable
  }

  return (
    <FormStatusContext.Provider value={{
      ...openRepeatablesContext,
      ...requiredRepeatablesContext,
      ...combinedRepeatablesContext,
      hasSubmit,
      setHasSubmit
    }}>
      {children}
    </FormStatusContext.Provider>
  )
}


const useFormStatusState = () => {
  const context = React.useContext(FormStatusContext)
  if (context === undefined) {
    throw new Error('useFormStatusState must be used within a FormStatusProvider')
  }
  return context
}

export { FormStatusProvider, useFormStatusState }
