import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { push } from 'connected-react-router'

import { actions as newsActions, selectors as newsSelectors } from '../../../../store/modules/news'
import { actions as newsArticleActions } from '../../../../store/modules/newsArticles'
import NewsSearchTable from './NewsSearchTable'
import modalService from '../../../../services/modalService'
import { translations } from '../../../../config'

const mapStateToProps = (state, ownProps) => {
  const type = ownProps.newsType
  const results = newsSelectors.getResults(state, type)
  const hasMore = newsSelectors.getHasMore(state, type)
  const isLoading = newsSelectors.getIsLoading(state, type)
  const error = newsSelectors.getError(state, type)

  const noResults = !isLoading && !(results || []).length

  return {
    isLoading,
    hasMore,
    results,
    noResults,
    error
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    loadMore: ({ dispatch, newsType, isLoading }) => () => {
      if (!isLoading) {
        dispatch(newsActions.fetchNewsNext({ newsType }))
      }
    },
    onDeleteClick: ({ dispatch }) => (e, articleId) => {
      e.stopPropagation()
      modalService.action({
        title: translations('Confirm article delete, this can not be undone.'),
        actions: [{
          text: translations('Cancel')
        }, {
          text: translations('Continue'),
          onClick: () => {
            dispatch(newsArticleActions.deleteArticle(articleId))
          }
        }]
      })
    },
    onArticleClick: ({ dispatch }) => ({ id, url, newsFeed }) => {
      // if (window.cordova) {
      //   dispatch(push({
      //     pathname: `/news/${id}`,
      //     search: `article=${url}`
      //   }))
      // } else {
      //   const win = window.open(url, '_blank')
      //   win.focus()
      // }
      //
      // Currently always opening in new tab
      // as some external sources have SAMEORIGIN iframe restrictions
      if (newsFeed.isInternalFeed) {
        // as it is internal - route to a viewing page
        dispatch(push(`/news/${id}/view`))
      } else {
        // external - open up a link
        const win = window.open(url, '_blank')
        win.focus()
      }
    },
    onEditClick: ({ dispatch }) => (e, articleId) => {
      e.stopPropagation()
      dispatch(push(`/news/${articleId}/edit`))
    }
  })
)(NewsSearchTable)
