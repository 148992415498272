import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectors as locationsSelectors, actions as locationsActions } from '../../store/modules/locations'

import LookupInputModal from '../LookupInputModal'

const SEARCH_PLACEHOLDER = 'Search Location'

const BasicLocationLookUpInputModal = ({ onChange, onClose, modalIndex }) => {
  const dispatch = useDispatch()

  const results = useSelector(locationsSelectors.getResults)
  const updatedResults = results? results.map((res) => { return {label: res.name, value: res.name, name: res.name }} ): []
  const noResults = !useSelector(locationsSelectors.getHasResults)
  const isLoading = useSelector(locationsSelectors.getIsLoading)
  const hasMore = useSelector(locationsSelectors.getHasMore) && !isLoading
  const error = useSelector(locationsSelectors.getError)

  const onSearch = useCallback(({query}) => {
    dispatch(locationsActions.searchLocations({query}))
  }, [dispatch])


  const onCloseLookup = useCallback(() => {
    onClose({ modalIndex })
    dispatch(locationsActions.resetStore())
  }, [dispatch])

  const onChangeLookup = useCallback((value) => {
    onChange(value)
  }, [])

  return (
    <LookupInputModal
      results={updatedResults}
      noResults={noResults}
      hasMore={hasMore}
      error={error}
      searchPlaceholder={SEARCH_PLACEHOLDER}
      onSearch={onSearch}
      onSearchNext={null}
      onChange={onChangeLookup}
      onClose={onCloseLookup}
    />
  )
}

export default BasicLocationLookUpInputModal

