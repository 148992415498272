"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stringDoesNotContain = exports.stringContains = exports.startsWith = exports.sizeLessThanOrEqual = exports.sizeLessThan = exports.sizeGreaterThanOrEqual = exports.sizeGreaterThan = exports.notInList = exports.notEqual = exports.isNotEmpty = exports.isNotBetween = exports.isEmpty = exports.isBetween = exports.inPast = exports.inList = exports.inFuture = exports.equal = exports.endsWith = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _dateMatrix = require("../utils/dateMatrix");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/**
 * Checks if the fact is an array which is greater than the rule's condition
 * @param {*} factValue - the value returned from the fact
 * @param {*} jsonValue - the "value" property stored in the condition itself
 * @returns {Boolean} - whether the values pass the operator test
 */
var sizeGreaterThan = function sizeGreaterThan(factValue, jsonValue) {
  if (!_lodash["default"].isArray(factValue)) {
    return false;
  }

  return _lodash["default"].size(factValue) > jsonValue;
};
/**
 * Checks if the fact is an array which is greater than or equal to the rule's condition
 * @param {*} factValue - the value returned from the fact
 * @param {*} jsonValue - the "value" property stored in the condition itself
 * @returns {Boolean} - whether the values pass the operator test
 */


exports.sizeGreaterThan = sizeGreaterThan;

var sizeGreaterThanOrEqual = function sizeGreaterThanOrEqual(factValue, jsonValue) {
  if (!_lodash["default"].isArray(factValue)) {
    return false;
  }

  return _lodash["default"].size(factValue) >= jsonValue;
};
/**
 * Checks if the fact is an array which is less than the rule's condition
 * @param {*} factValue - the value returned from the fact
 * @param {*} jsonValue - the "value" property stored in the condition itself
 * @returns {Boolean} - whether the values pass the operator test
 */


exports.sizeGreaterThanOrEqual = sizeGreaterThanOrEqual;

var sizeLessThan = function sizeLessThan(factValue, jsonValue) {
  if (!_lodash["default"].isArray(factValue)) {
    return false;
  }

  return _lodash["default"].size(factValue) < jsonValue;
};
/**
 * Checks if the fact is an array which is less than or equal to the rule's condition
 * @param {*} factValue - the value returned from the fact
 * @param {*} jsonValue - the "value" property stored in the condition itself
 * @returns {Boolean} - whether the values pass the operator test
 */


exports.sizeLessThan = sizeLessThan;

var sizeLessThanOrEqual = function sizeLessThanOrEqual(factValue, jsonValue) {
  if (!_lodash["default"].isArray(factValue)) {
    return false;
  }

  return _lodash["default"].size(factValue) <= jsonValue;
};

exports.sizeLessThanOrEqual = sizeLessThanOrEqual;

var anyContain = function anyContain(factValues, jsonValues) {
  var doesContain = false;

  _lodash["default"].forEach(factValues, function (fact) {
    _lodash["default"].forEach(jsonValues, function (jsonValue) {
      if (!doesContain) {
        doesContain = _lodash["default"].includes(fact, jsonValue);
      }
    });
  });

  return doesContain;
};

var getSafeValues = function getSafeValues(factValue, jsonValue) {
  var arrayFactValue = _lodash["default"].castArray(factValue);

  var arrayJsonValue = _lodash["default"].castArray(jsonValue);

  var lowerCaseJsonValue = _lodash["default"].map(arrayJsonValue, safeFact);

  var safeFactValue = _lodash["default"].map(arrayFactValue, safeFact);

  return [lowerCaseJsonValue, safeFactValue];
};

var stringContains = function stringContains(factValue, jsonValue) {
  if (!factValue) return false;

  var _getSafeValues = getSafeValues(factValue, jsonValue),
      _getSafeValues2 = _slicedToArray(_getSafeValues, 2),
      lowerCaseJsonValue = _getSafeValues2[0],
      safeFactValue = _getSafeValues2[1];

  return anyContain(safeFactValue, lowerCaseJsonValue);
};

exports.stringContains = stringContains;

var stringDoesNotContain = function stringDoesNotContain(factValue, jsonValue) {
  if (!factValue) return true;

  var _getSafeValues3 = getSafeValues(factValue, jsonValue),
      _getSafeValues4 = _slicedToArray(_getSafeValues3, 2),
      lowerCaseJsonValue = _getSafeValues4[0],
      safeFactValue = _getSafeValues4[1];

  return !anyContain(safeFactValue, lowerCaseJsonValue);
};

exports.stringDoesNotContain = stringDoesNotContain;

var isEmpty = function isEmpty(factValue) {
  return _lodash["default"].isEmpty(_lodash["default"].trim(factValue));
};

exports.isEmpty = isEmpty;

var isNotEmpty = function isNotEmpty(factValue) {
  return !_lodash["default"].isEmpty(_lodash["default"].trim(factValue));
};

exports.isNotEmpty = isNotEmpty;

var isBetween = function isBetween(factValue, jsonValue) {
  if (!factValue) return false;

  if (_lodash["default"].isNumber(factValue)) {
    return _lodash["default"].inRange(factValue, jsonValue[0], jsonValue[1]);
  }

  var formattedFact = (0, _dateMatrix.attemptToResolveDate)(factValue);

  var _$map = _lodash["default"].map(jsonValue, function (date) {
    return (0, _dateMatrix.attemptToResolveDate)(date);
  }),
      _$map2 = _slicedToArray(_$map, 2),
      startDate = _$map2[0],
      endDate = _$map2[1];

  return formattedFact.isAfter(startDate) && formattedFact.isBefore(endDate);
};

exports.isBetween = isBetween;

var isNotBetween = function isNotBetween(factValue, jsonValue) {
  if (!factValue) return false;

  if (_lodash["default"].isNumber(factValue)) {
    return !_lodash["default"].inRange(factValue, jsonValue[0], jsonValue[1]);
  }

  var formattedFact = (0, _dateMatrix.attemptToResolveDate)(factValue);

  var _$map3 = _lodash["default"].map(jsonValue, function (date) {
    return (0, _dateMatrix.attemptToResolveDate)(date);
  }),
      _$map4 = _slicedToArray(_$map3, 2),
      startDate = _$map4[0],
      endDate = _$map4[1];

  return formattedFact.isAfter(startDate) && !formattedFact.isBefore(endDate) || formattedFact.isBefore(endDate) && !formattedFact.isAfter(startDate);
};

exports.isNotBetween = isNotBetween;

var safeFact = function safeFact(fact) {
  var nextFact = _lodash["default"].clone(fact);

  if (_lodash["default"].get(fact, 'name')) {
    nextFact = _lodash["default"].get(fact, 'name');
  }

  nextFact = _lodash["default"].toLower(nextFact);
  return nextFact;
};

var inList = function inList(factValue, jsonValue) {
  if (!factValue) return false;

  var _getSafeValues5 = getSafeValues(factValue, jsonValue),
      _getSafeValues6 = _slicedToArray(_getSafeValues5, 2),
      lowerCaseJsonValue = _getSafeValues6[0],
      safeFactValue = _getSafeValues6[1];

  return _lodash["default"].size(_lodash["default"].intersection(lowerCaseJsonValue, safeFactValue)) > 0;
};

exports.inList = inList;

var notInList = function notInList(factValue, jsonValue) {
  if (!factValue) return true;

  var _getSafeValues7 = getSafeValues(factValue, jsonValue),
      _getSafeValues8 = _slicedToArray(_getSafeValues7, 2),
      lowerCaseJsonValue = _getSafeValues8[0],
      safeFactValue = _getSafeValues8[1];

  return _lodash["default"].size(_lodash["default"].intersection(lowerCaseJsonValue, safeFactValue)) === 0;
};

exports.notInList = notInList;

var equal = function equal(factValue, jsonValue) {
  var momentJSONVal = (0, _dateMatrix.attemptToResolveDate)(jsonValue);
  var momentFactValue = (0, _dateMatrix.attemptToResolveDate)(factValue);

  if (momentJSONVal.isValid() && momentFactValue.isValid()) {
    return momentJSONVal.isSame(momentFactValue);
  }

  return _lodash["default"].isEqual(safeFact(jsonValue), safeFact(factValue));
};

exports.equal = equal;

var notEqual = function notEqual(factValue, jsonValue) {
  var momentJSONVal = (0, _dateMatrix.attemptToResolveDate)(jsonValue);
  var momentFactValue = (0, _dateMatrix.attemptToResolveDate)(factValue);

  if (momentJSONVal.isValid() && momentFactValue.isValid()) {
    return !momentJSONVal.isSame(momentFactValue);
  }

  return !_lodash["default"].isEqual(safeFact(jsonValue), safeFact(factValue));
};

exports.notEqual = notEqual;

var startsWith = function startsWith(factValue, jsonValue) {
  return _lodash["default"].startsWith(factValue, jsonValue);
};

exports.startsWith = startsWith;

var endsWith = function endsWith(factValue, jsonValue) {
  return _lodash["default"].endsWith(factValue, jsonValue);
};

exports.endsWith = endsWith;

var inFuture = function inFuture(factValue) {
  var momentJSONVal = (0, _moment["default"])();
  var momentFactValue = (0, _dateMatrix.attemptToResolveDate)(factValue);

  if (momentJSONVal.isValid() && momentFactValue.isValid()) {
    return momentJSONVal.isBefore(momentFactValue);
  }

  return false;
};

exports.inFuture = inFuture;

var inPast = function inPast(factValue) {
  var momentJSONVal = (0, _moment["default"])();
  var momentFactValue = (0, _dateMatrix.attemptToResolveDate)(factValue);

  if (momentJSONVal.isValid() && momentFactValue.isValid()) {
    return momentJSONVal.isAfter(momentFactValue);
  }

  return false;
};

exports.inPast = inPast;