import React from 'react'
import Time from './Time'
import _ from 'lodash'

class TimeContainer extends React.Component {
  handleChange = (e, type) => {
    const { value } = e.target
    const currentTimes = this.formatValue(this.props.value)
    const { hour = '', minute = '' } = { ...currentTimes, [type]: value || '' }
    const time = `${hour}${hour ? `:${minute}` : ''}`
    return time || null
  }

  onBlur = (e, type) => {
    this.props.onBlur(this.handleChange(e, type))
  }

  onChange = (e, type) => {
    this.props.onChange(this.handleChange(e, type))
  }

  formatValue = (value) => {
    if (value) {
      const [hour, minute] = value.split(':')
      return { hour, minute: hour ? minute : '' }
    } else {
      return { hour: '', minute: '' }
    }
  }

  _generateProps = () => ({
    ...this.props,
    value: this.formatValue(this.props.value),
    onBlur: this.onBlur,
    onChange: this.onChange
  })

  render() {
    const props = this._generateProps()
    return <Time {...props} />
  }
}

export default TimeContainer
