"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var viewJSONtoValidations = function viewJSONtoValidations() {
  var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var validators = arguments.length > 1 ? arguments[1] : undefined;
  var maxLength = field.maxLength,
      pattern = field.pattern,
      patternCustom = field.patternCustom,
      patternFlags = field.patternFlags,
      patternCustomError = field.patternCustomError,
      minimum = field.minimum,
      maximum = field.maximum,
      exclusiveMaximum = field.exclusiveMaximum,
      exclusiveMinimum = field.exclusiveMinimum,
      decimal = field.decimal,
      acceptedDateFormats = field.acceptedDateFormats,
      startDate = field.startDate,
      endDate = field.endDate;
  var payload = {
    validate: []
  };

  if (_lodash["default"].toNumber(maxLength) > 0) {
    var maxLengthInteger = _lodash["default"].toNumber(maxLength);

    payload.maxLength = maxLengthInteger;
    payload.validate.push(validators.maxLength(maxLengthInteger, 'Field'));
  }

  if (_lodash["default"].isString(pattern)) {
    payload.validate.push(validators.pattern(pattern, patternCustom, patternFlags, patternCustomError));
  }

  if (minimum || maximum || exclusiveMinimum || exclusiveMaximum) {
    if (decimal) {
      payload.validate.push(validators.decimal);
    } else {
      payload.validate.push(validators.numeric);
    }
  }

  if (minimum && maximum) {
    var minLimit = _lodash["default"].toNumber(minimum || exclusiveMinimum);

    var maxLimit = _lodash["default"].toNumber(maximum || exclusiveMaximum);

    if (_lodash["default"].isNumber(minLimit) && _lodash["default"].isNumber(maxLimit) && maxLimit > minLimit) {
      payload.validate.push(validators.isBetween(minLimit, maxLimit));
    }
  } else {
    if (minimum) {
      var _minLimit = _lodash["default"].toNumber(minimum);

      if (_lodash["default"].isNumber(_minLimit)) {
        payload.validate.push(validators.isLessThanOrEqual(_minLimit, maxLength));
      }
    }

    if (maximum) {
      var _maxLimit = _lodash["default"].toNumber(maximum);

      if (_lodash["default"].isNumber(_maxLimit)) {
        payload.validate.push(validators.isGreaterThanOrEqual(_maxLimit, maxLength));
      }
    }
  }

  if (exclusiveMinimum) {
    var _minLimit2 = _lodash["default"].toNumber(exclusiveMinimum);

    if (_lodash["default"].isNumber(_minLimit2)) {
      payload.validate.push(validators.isLessThan(_minLimit2, maxLength));
    }
  }

  if (exclusiveMaximum) {
    var _minLimit3 = _lodash["default"].toNumber(exclusiveMaximum);

    if (_lodash["default"].isNumber(_minLimit3)) {
      payload.validate.push(validators.isGreaterThan(_minLimit3, maxLength));
    }
  }

  if (acceptedDateFormats && acceptedDateFormats.length) {
    payload.validate.push(validators.isAcceptedDateFormat(acceptedDateFormats));
  }

  if (startDate) {
    payload.validate.push(validators.isDateOnOrAfterStart(startDate));
  }

  if (endDate) {
    payload.validate.push(validators.isDateOnOrBeforeEnd(endDate));
  }

  if (payload.validate.length === 0) {
    delete payload.validate;
  }

  return payload;
};

var _default = viewJSONtoValidations;
exports["default"] = _default;