import React, { useState } from 'react'
import _ from 'lodash'
import { styled } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

import { translations } from '../../../../../config'

import IntegrationManagementEditReportScreenGeneralSidePanel from '../Panels/IntegrationManagementEditReportScreenGeneralSidePanel'

const TabBar = styled(AppBar)(({ theme }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.grey[200]}`
}))

const TabContainer = () => {
  const [value, setValue] = useState('panel-general')

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  const tabs = _.compact([
    { label: translations('General'), value: 'panel-general' }
  ])

  return (
    <TabContext value={value}>
      <TabBar position='static' color='transparent' variant='outlined'>
        <TabList onChange={handleChange} aria-label={translations('Report configuration panel tabs')} indicatorColor={'primary'}>
          {_.map(tabs, ({ label, value }) => {
            return (
              <Tab label={label} style={{ textTransform: 'none' }} value={value} />
            )
          })}
        </TabList>
      </TabBar>
      <TabPanel value='panel-general'>
        <IntegrationManagementEditReportScreenGeneralSidePanel />
      </TabPanel>
    </TabContext>
  )
}

export default TabContainer
