export const sortStates = {
    NONE: 'none',
    ASCENDING: 'asc',
    DESCENDING: 'desc'
}

export const tableActions = {
    CHANGE_SORT_ORDER: 'CHANGE_SORT_ORDER',
    CHANGE_SORT_FIELD: 'CHANGE_SORT_FIELD',
    CHANGE_PAGE: 'CHANGE_PAGE',
    CHANGE_PAGE_SIZE: 'CHANGE_PAGE_SIZE'
}