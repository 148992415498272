"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateAckInitialValues = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _constants = require("../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var generateComputedValues = function generateComputedValues(_ref) {
  var schemaName = _ref.schemaName,
      reportJSON = _ref.reportJSON;

  var messagenumb = _lodash["default"].get(reportJSON, 'messagenumb');

  var messagedate = _lodash["default"].get(reportJSON, 'messagedate');

  var safetyreportid = _lodash["default"].get(reportJSON, 'safetyreportid');

  var icsrmessagesenderidentifier = _lodash["default"].get(reportJSON, 'messagesenderidentifier');

  var icsrmessagereceiveridentifier = _lodash["default"].get(reportJSON, 'messagereceiveridentifier');

  switch (schemaName) {
    case _constants.FORM_SCHEMAS_CONSTANTS.MHRA_R3_ACK:
      return {
        batchnumber: messagenumb,
        icsrbatchnumber: messagenumb,
        batchsenderidentifier: icsrmessagesenderidentifier,
        batchtransmissiondate: messagedate,
        batchreceiveridentifier: icsrmessagereceiveridentifier,
        icsrbatchtransmissiondate: messagedate,
        icsrmessagenumber: safetyreportid,
        icsrmessagesenderidentifier: icsrmessagesenderidentifier,
        icsrmessagereceiveridentifier: icsrmessagereceiveridentifier,
        icsrcreationdate: messagedate
      };

    default:
      return {};
  }
};

var generateFlattenMessages = function generateFlattenMessages(_ref2) {
  var schemaName = _ref2.schemaName,
      initialValues = _ref2.initialValues,
      options = _ref2.options;

  if (options.flattenMessages !== true) {
    return {};
  }

  switch (schemaName) {
    case _constants.FORM_SCHEMAS_CONSTANTS.MHRA_R3_ACK:
      return _objectSpread({
        messageAcknowledgements: null
      }, _lodash["default"].head(_lodash["default"].get(initialValues, 'messageAcknowledgements', {})));

    default:
      return {};
  }
};

var generateAckInitialValues = function generateAckInitialValues(_ref3) {
  var schemaName = _ref3.schemaName,
      _ref3$initialValues = _ref3.initialValues,
      initialValues = _ref3$initialValues === void 0 ? {} : _ref3$initialValues,
      _ref3$reportJSON = _ref3.reportJSON,
      reportJSON = _ref3$reportJSON === void 0 ? {} : _ref3$reportJSON,
      _ref3$options = _ref3.options,
      options = _ref3$options === void 0 ? {} : _ref3$options;
  var computedValues = generateComputedValues({
    schemaName: schemaName,
    reportJSON: reportJSON
  });
  var flattenMessages = generateFlattenMessages({
    schemaName: schemaName,
    initialValues: initialValues,
    options: options
  });

  var parsedInitialValues = _lodash["default"].merge({}, computedValues, initialValues, flattenMessages);

  return parsedInitialValues;
};

exports.generateAckInitialValues = generateAckInitialValues;