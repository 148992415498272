export const murFormConsts = {
    MFR_ID: 'mfrAssociation',
    UKRP_ID: 'ukrpAssociation',
    AUTH_REP_ID: 'authRepAssociation',
    OTHER_ID: 'Other',

    MFR_GROUP_ID: '72bd8e00-113d-4709-8974-699f6366cd62',
    UKRP_GROUP_ID: '1cd18241-59bb-48ab-9017-090d4cdb394c',
    AUTH_REP_GROUP_ID: '8caf952b-71f2-4af2-8c04-3ead212c365a',
    OTHER_GROUP_ID: 'Other',
    
    CHANGE_SELECTED_ACCOUNT_TYPES: 'CHANGE_SELECTED_ACCOUNT_TYPES',
    CHANGE_SELECTED_ORGANISATIONS: 'CHANGE_SELECTED_ORGANISATIONS',
    CLEAR_SELECTED_ORGANISATIONS: 'CLEAR_SELECTED_ORGANISATIONS',
    CLEAR_SELECTED_ORGANISATION: 'CLEAR_SELECTED_ORGANISATION'
}