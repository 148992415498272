import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormHelperText } from '@material-ui/core'
import { Input, InputLabel } from '@material-ui/core'
import Button from '../../Button'

import style from './style'

const InputField = ({
  label,
  value,
  onChange,
  onBlur,
  passedProps,
  meta: { error, touched },
  classes
}) => {
  return <Fragment>
    <InputLabel
      FormControlClasses={{
        focused: classes.floatingLabelFocusStyle
      }}
      htmlFor={label}
      key='label'
    >{label}</InputLabel>
    <div className={classes.counterWrapper}>
      <div className={classes.value}>{value || 1}</div>
      <div>
        <Button type='button' className={classes.button} onClick={() => onChange('-')}>-</Button>
        <Button type='button' className={classes.button} onClick={() => onChange('+')}>+</Button>
      </div>
    </div>
    <FormHelperText className={classes.errorStyle} key='helper-text' role='alert'>{touched && error && error}</FormHelperText>
  </Fragment>
}

export default withStyles(style)(InputField)
