import React from 'react'
import Editable from '../Editable'
import Button from '../../Button'
import P from '../../P'
import { translations } from '../../../config'

export default Editable({
  input: (props) => (
    null
  ),
  display: ({ buttonValidationDisabled, label, value, onClick, buttonLabel, disabled, ...rest }) => {
    return (
      <div>
        <P value={label} type='textButton' />
        <Button
          onClick={() => onClick(value)}
          variant={'contained'}
          raised
          buttonType='primary'
          disabled={disabled || buttonValidationDisabled || value.includes('undefined')}
        >
          {buttonLabel}
        </Button>
        <P style={{ marginTop: 10 }} value={translations('Please ensure your Identity Provider has an "email" field as the unique user identifier. This is mandatory for successful integration.')} />
      </div>
    )
  }
})
