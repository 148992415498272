import _ from 'lodash'
import { translation } from '../translations'

export const composeValidators = (validators) => (value, allValues, state) => {
  return validators.reduce((error, validator) => error || validator(value, allValues, state), undefined)
}

export const requiredCheckbox = value => {
  return !value ? translation('Error - Field Is Required') : undefined
}

export const requiredValidator = (value) => {
  const errorMessage = translation('Error - Field Is Required')

  if (_.isArray(value)) {
    return _.size(value) ? undefined : errorMessage
  }

  return value ? undefined : errorMessage
}

export const requiredRepeatable = (value) => {
  if (_.some(value, { _isOpen: true })) {
    return translation('Please complete this section')
  }
  return undefined
}
