import { connect } from 'react-redux'
import UsersLoadingBar from './UsersLoadingBar'
import { selectors as usersSelectors } from '../../../../store/modules/users'
import { selectors as rolesSelectors } from '../../../../store/modules/roles'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const isLoadingRoles = rolesSelectors.getIsLoading(state)
  const isLoadingUsers = usersSelectors.getIsLoading(state)
  return {
    ...ownProps,
    isLoading: isLoadingRoles || isLoadingUsers
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UsersLoadingBar)
