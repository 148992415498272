"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeRequestCreator = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }

function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); Object.defineProperty(Constructor, "prototype", { writable: false }); return Constructor; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ChangeRequestCreator = _createClass(function ChangeRequestCreator(_value, _field, actionStore) {
  var _this = this;

  _classCallCheck(this, ChangeRequestCreator);

  _defineProperty(this, "chain", function (fn) {
    var newValue = fn(_this.value, _this.field);

    if (newValue instanceof ChangeRequestCreator) {
      return new ChangeRequestCreator(newValue.value, _this.field, _fp["default"].concat(_this.actionCreatorStore, newValue.actionCreatorStore));
    }

    return new ChangeRequestCreator(newValue, _this.field, _this.actionCreatorStore);
  });

  _defineProperty(this, "updateActionStore", function (field, value) {
    if (_fp["default"].isString(field)) {
      _this.actionCreatorStore = _fp["default"].concat(_this.actionCreatorStore, {
        field: field,
        value: value
      });
    }
  });

  _defineProperty(this, "handleComputedRepeatablesField", function (_ref) {
    var origin = _ref.origin,
        targetValueFromReduxStore = _ref.targetValueFromReduxStore,
        formValues = _ref.formValues,
        field = _ref.field;

    if (_fp["default"].isArray(_this.value)) {
      var allIdsInStore = _fp["default"].map('id', targetValueFromReduxStore);

      var previousComputedIds = _fp["default"].compose(_fp["default"].map('id'), _fp["default"].get(['computedRepeatables', field, 'repeatables']))(formValues);

      var userCreatedIds = _fp["default"].without(previousComputedIds, allIdsInStore);

      _this.updateActionStore('computedRepeatables', _defineProperty({}, _this.field, {
        repeatables: _fp["default"].compose(_fp["default"].map(function (repeatable) {
          return {
            origin: origin,
            id: _fp["default"].get('id', repeatable)
          };
        }), _fp["default"].reject(_fp["default"].compose(function (id) {
          return _fp["default"].includes(id, userCreatedIds);
        }, _fp["default"].get('id'))))(_this.value)
      }));
    }

    return new ChangeRequestCreator(_this.value, _this.field, _this.actionCreatorStore);
  });

  _defineProperty(this, "unwrap", function () {
    _this.updateActionStore(_this.field, _this.value);

    return _this.actionCreatorStore;
  });

  this.actionCreatorStore = _fp["default"].castArray(actionStore || []);
  this.value = _value;
  this.field = _field;
});

exports.ChangeRequestCreator = ChangeRequestCreator;