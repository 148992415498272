import React from 'react'
import Editable from '../Editable'
import Heading from '../../Heading'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../config'
import styles from './style'
import _ from 'lodash'

const EditableHeading = 
  Editable({
    input: props => {
      const newProps = _.omit(props, ['className', 'classes'])
      return (
        <Heading component='h2' className={props.classes.headingStyle} {...newProps}>
          {translations(props.value)}
        </Heading>
      )
    },
    display: ({ value, classes }) => (
        <Heading component='h2' className={classes.headingStyle} >
          {translations(value)}
        </Heading>
      )
  })


export default withStyles(styles)(EditableHeading)
