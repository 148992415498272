import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import { StyledLink } from '../Link'

const StyledButton = styled(StyledLink)`
  -webkit-appearance: none;
  border-radius: 0;
  background: transparent;
  opacity: ${props => props.disabled ? 0.5 : 1};
  display: inline-block;
  vertical-align: center;
  padding: 5px;
  font-size: ${props => `${props.theme.type.button.small}px`};
  line-height: 1;
  border: none;  
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  margin: 0;
  font-size: ${props => `${props.theme.type.heading3.small}px`};
  white-space: nowrap;
  ${media.greaterThan('medium')`
    font-size: ${props => `${props.theme.type.heading3.large}px`};
  `}
  &:focus {
    box-shadow: ${({ theme }) => `0 0 0 3px ${theme.colors.secondary}`};
    outline: none;
  }
  ${props => props.inheritFontSize && `
    & {
      font-size: inherit;
    }
  `}
`

/**
 * LinkButton 
 */
const LinkButton = (props) => {
  const { children, onClick, disabled, type, inheritFontSize } = props

  const handleClick = (e) => {
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }

  return (
    <StyledButton
      inheritFontSize={inheritFontSize}
      as={'button'}
      onClick={handleClick}
      disabled={disabled}
      type={type}

    >{children}</StyledButton>
  )
}

LinkButton.propTypes = {
  /** The text value of the button */
  children: PropTypes.string.isRequired,
  /** Function to call when clicking the button. Function is passed the `event` */
  onClick: PropTypes.func,
  /** Disable the button */
  disabled: PropTypes.bool,
  /** The default behavior of the button */
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  /** Should the button take the font-size from the theme or its parent */
  inheritFontSize: PropTypes.bool
}

export default LinkButton
