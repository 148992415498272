import React from 'react'

import Editable from '../Editable'
import { SelectUser } from '../../Fields'

import P from '../../P'

export default Editable({
  input: props => {
    return (
      <SelectUser {...props} />
    )
  },
  display: ({ label, value: user }) => {
    // editable component pre fills empty fields with '-'
    const newValue = user !== '-'
    ? `${user.firstName} ${user.lastName}`
    : user
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={newValue} />
      </div>
    )
  }
})
