import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import InfiniteScroll from 'react-infinite-scroller'
import { AutoTable } from '../../../../components/Table'
import KeyValueCard from '../../../../components/KeyValueCard'
import { translations } from '../../../../config'
import styles from './style'

class ReportAcknowledgementsTable extends Component {
  renderError = () => {
    const { classes } = this.props
    return <div className={classes.content}>{translations('Sorry, an error has occurred. Please try again.')}</div>
  }

  render () {
    const { error, noResults, searchReports, hasMore, results, rows, classes } = this.props
    if (error) {
      return this.renderError()
    } else {
      return (
        <div className={classes.gridContainer}>
          <InfiniteScroll
            pageStart={0}
            className={classes.container}
            loadMore={searchReports}
            hasMore={hasMore}
            initialLoad={false}
            useWindow={false}
          >
            {
              results &&
              <Fragment>
                <Hidden xsDown>
                  <AutoTable rows={rows} />
                </Hidden>
                <Hidden smUp>
                  {rows.map(row => {
                    return <div className={classes.cardContainer} key={row.key}>
                      <KeyValueCard {...row} />
                    </div>
                  })}
                </Hidden>
              </Fragment>
            }
            {noResults &&
              <div className={`${classes.content} ${classes.uppercase}`}>{translations('No results found')}</div>
            }
          </InfiniteScroll>
        </div>
      )
    }
  }
}

export default withStyles(styles)(ReportAcknowledgementsTable)
