import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import HomeScreen from './HomeScreen'
import { apps } from '../../../config'
import { actions as appActions } from '../../../store/modules/app'
import analyticsService from '../../../services/analyticsService'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

const mapStateToProps = state => {
  const user = _.get(state, 'auth.user')
  const widgets = authCombinedSelectors.getAvailableDashboardItemsForSelectedOrganisation(state)
  return {
    user,
    hasUserManagement: authSelectors.getHasUserManagement(state),
    isAdmin: authSelectors.getIsUserAdmin(state),
    widgets,
    pageTitle: platformSelectors.getPageTitle(state)('Dashboard Title'),
    bannerContent: platformSelectors.getDashboardBannerContent(state),
    displayAnnouncements: platformSelectors.isAnnouncementManagementEnabled(state)
  }
}

export default compose(
  connect(
    mapStateToProps
  ),
  withHandlers({
    onMenuItemClick: ({ dispatch }) => id => {
      if (id === 'USER_MANAGEMENT') {
        analyticsService.sendCustomEvent({ type: 'userAccountsVisit', route: 'hubTile' })
      }
      const item = apps[id]
      dispatch(item.action)
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    }
  })
)(HomeScreen)
