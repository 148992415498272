import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import FieldMeta from '../FieldMeta'
import Field from '../Field'
import _ from 'lodash'
import NullFlavourField from '../NullFlavourField'
import { getComputedRequiredFieldInvalid } from '../../helpers'

export const StyledInput = styled.input`
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.inputBorder};
  width: 100%;
  height: ${rem('35px')};
  padding: 8px;
  border-radius: 0;
  line-height: 1.4;
  margin: 0;
  color: ${props => props.theme.colors.inputText};
  font-size: ${props => `${props.theme.type.input.small}px`};
  font-weight: 400;
  transition: box-shadow .3s ease-in-out;
  ${media.greaterThan('small')`
    height: ${rem('41px')};
    padding: 8px 12px;
    font-size: ${props => `${props.theme.type.input.large}px`};
  `}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &:focus, &:active, &:focus {
    outline: 0;
    box-shadow: ${({ theme, error }) => error ? `0 0 0 3px ${theme.colors.error}` : `0 0 0 3px ${theme.colors.primary}`};
  }
`

/**
 * A basic text input
 */
const TextInput = (props) => {
  const {
    input: {
      name,
      value,
      onChange,
      onFocus,
      onBlur,
      type
    },
    meta: {
      error,
      touched
    },
    id,
    label,
    hint,
    required,
    disabled,
    nullFlavours
  } = props

  const fieldInvalid = getComputedRequiredFieldInvalid({required, error, touched})
  const errorMessage = fieldInvalid ? error : undefined

  const hasNullFlavours = nullFlavours && !_.isEmpty(nullFlavours)

  const renderTextInput = () => {
    return (
      <Field error={errorMessage}>
        <FieldMeta name={id} label={label} error={errorMessage} hint={hint} required={required} disabled={disabled} />
        <StyledInput
          id={id}
          name={name}
          type={type}
          value={value}
          error={errorMessage}
          aria-required={required}
          aria-invalid={fieldInvalid}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
      </Field>
    )
  }

  if (hasNullFlavours) {
    return (
      <NullFlavourField
        {...props}
        render={renderTextInput}
      />
    )
  }

  return (renderTextInput())
}

TextInput.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** Input props based from React Final Form
   * 
   * `name` - Used to associate label and input
   *
   * `value` - Value of the input
   * 
   * `type` - Native HTML input type
   * 
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   * 
   * `onFocus` - Function called when focus has been given to the field
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    type: PropTypes.oneOf(['color', 'email', 'hidden', 'number', 'search', 'tel', 'text', 'url']),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  /** Meta props based from React Final Form
   * 
   * `error` - Field validation message
   * 
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
}

TextInput.defaultProps = {
  required: false,
  input: {
    type: 'text'
  },
  meta: {}
}

export default TextInput
