import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import BasicModalOverlay from '../../components/BasicModalOverlay'
import ContentBoxBody from '../../components/ContentBox/ContentBoxBody'
import { translations } from '../../config'

import style from './style'


const AdminActionModal = (props) => {
  const {
    actions
  } = props
  return (
    <BasicModalOverlay
      title={translations('Admin options')}
      actions={actions}
    >
      These actions may occur in data loss.
    </BasicModalOverlay>
  )
}

AdminActionModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  schema: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object
}

export default withStyles(style)(AdminActionModal)
