import React from 'react'
import PropTypes from 'prop-types'

import BasicModalOverlay from '../../../../components/BasicModalOverlay'

import { translations } from '../../../../config'

const DisplayModal = ({
  dismiss,
  title,
  children
}) => {
  return (
    <BasicModalOverlay
      title={title}
      children={children}
      actions={[
        {
          text: translations('Cancel'),
          onClick: dismiss
        }
      ]}
    />
  )
}

DisplayModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any
}

export default DisplayModal
