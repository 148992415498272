import React, { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { compose } from 'recompose'
import { useDispatch } from 'react-redux'

import { FormControl } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import DownloadIcon from '@material-ui/icons/CloudDownload'

import { translations } from '../../../../config'
import Button from '../../../../components/Button'
import Dropdown from '../../../../components/Fields/Dropdown'
import DatePicker from '../../../../components/Fields/DatePicker'
import { withFiltersContainer } from '../../../../components/FiltersContainer'

import {
  hooks as reportConfigurationHooks,
  actions as reportConfigurationActions
} from '../../../../store/modules/reportConfiguration'
import { useParams } from 'react-router-dom'


import styles from './style'

const ReportsSearchFilters = function (props) {
  const {
    classes,
    statusOptions,
    sourceOptions,
    downloadReports
  } = props;
  const { id: formViewId } = useParams()
  const cohortOptions = reportConfigurationHooks.useFollowUpCohorts(formViewId)
  const filters = reportConfigurationHooks.useFilters(formViewId)
  const [cohorts, setCohorts] = useState(filters.cohorts)
  const [status, setStatus] = useState(filters.status)
  const [source, setSource] = useState(filters.source)
  const [dateFrom, setDateFrom] = useState(filters.dateFrom)
  const [dateTo, setDateTo] = useState(filters.dateTo)
  const dispatch = useDispatch()

  const debouncedUpdateFilters = useCallback(_.debounce((filter) => {
    const newFilters = { ...filters, [filter.name]: filter.value }
    dispatch(reportConfigurationActions.changeReportFilters({ formViewId, filters: newFilters }))
    dispatch(reportConfigurationActions.searchReportsFresh({ formViewId, filters: newFilters }))
  }, 500), [filters])

  const updateReportStatus = (e) => {
    setStatus(e.target.value)
    debouncedUpdateFilters({ name: 'status', value: e.target.value })
  }

  const updateReportSource = (e) => {
    setSource(e.target.value)
    debouncedUpdateFilters({ name: 'source', value: e.target.value })
  }

  const updateDateFrom = (e) => {
    setDateFrom(e)
    debouncedUpdateFilters({ name: 'dateFrom', value: e })
  }

  const updateDateTo = (e) => {
    setDateTo(e)
    debouncedUpdateFilters({ name: 'dateTo', value: e })
  }

  const updateCohorts = (e) => {
    setCohorts(e.target.value)
    debouncedUpdateFilters({ name: 'cohorts', value: e.target.value })
  }

  useEffect(() => {
    dispatch(
      reportConfigurationActions.searchFollowUpFresh({ formViewId })
    )
  }, [])

  return (
    <div className={classes.container} >
      <Grid container spacing={2}>
        {
          _.size(cohortOptions) > 0
          &&
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.storesFilter}>
              <Dropdown
                label={translations('Cohort(s)')}
                value={cohorts || null}
                options={cohortOptions}
                onChange={updateCohorts}
                noErrorTextLabel
                shrink
              />

            </FormControl>
          </Grid>
        }
        <Grid item xs={12} md={2} className={classes.leftContent}>
          <FormControl className={classes.storesFilter}>
            <Dropdown
              label={translations('Status')}
              value={status || null}
              options={statusOptions}
              onChange={updateReportStatus}
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} className={classes.leftContent}>
          <FormControl className={classes.storesFilter}>
            <Dropdown
              label={translations('Report Source')}
              value={source || null}
              options={sourceOptions}
              onChange={updateReportSource}
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} className={classes.leftContent}>
          <FormControl className={classes.storesFilter}>
            <DatePicker
              label={translations('Date From')}
              value={dateFrom}
              onChange={updateDateFrom}
              noErrorTextLabel
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} className={classes.leftContent}>
          <FormControl className={classes.storesFilter}>
            <DatePicker
              label={translations('Date To')}
              value={dateTo}
              onChange={updateDateTo}
              noErrorTextLabel
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1} className={classes.buttonCol}>
          <Button onClick={downloadReports} aria-label={translations('Download Reports Button')}>
            <DownloadIcon />
          </Button>
        </Grid>
      </Grid>
    </div >
  )
}

export default compose(
  withStyles(styles),
  withFiltersContainer
)(ReportsSearchFilters)
