import React from 'react'
import SearchInput from '../../../../../components/SearchInput'

import { translations } from '../../../../../config'

const SearchBar = ({ value, onSearchChanged }) => {
  const onChange = (event) => {
    onSearchChanged(event.target.value)
  }

  const onClear = () => {
    onSearchChanged(undefined)
  }

  return (
      <SearchInput
        onClear={onClear}
        onChange={onChange}
        value={value}
        autoFocus={false}
        placeholder={translations('Manage Translations - Search translations')}
      />    
  )
}

export default SearchBar
