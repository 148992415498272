import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../../config'

import Form, { FormSubmit } from '../../../../../components/Form'
import FormBody from '../../../../../components/Form/FormBody'
import FormError from '../../../../../components/Form/FormError'

import * as validators from '../../../../../validators'
import style from './style'

import UserGroupAndOrganisation from '../../../../Users/UserRegistrationScreen/MoreUserRegistrationForm/UserGroupAndOrganisation'
import { selectors as userGroupSelectors } from '../../../../../store/modules/userGroups'
import { useSelector } from 'react-redux'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import Button from '../../../../../components/Button'
import styled from 'styled-components'
import modalService from '../../../../../services/modalService'

const formId = 'OrganisationInviteForm'
const InviteForm = Form(formId)

const OrganisationInviteModal = (props) => {
  const {
    classes,
    onSubmit,
    actions
  } = props

  const userGroups = useSelector(userGroupSelectors.fetchAllUserGroupsForCurrentPlatform)
  const displayUserGroupSelection = !_.isEmpty(userGroups)

  return (
    <BasicModalOverlay
      title={translations('Organisation Invite - Header')}
      actions={actions}
    >
      <div className={classes.content} >
        <InviteForm onSubmit={onSubmit}>
          <FormBody>
            <div>
              <FormBody editing schema={schema} layout={['email:12']} />
              {displayUserGroupSelection &&
                <UserGroupAndOrganisation
                  singular={true}
                  excludeOther={true}
                  formId={formId}
                />
              }
            </div>
          </FormBody>
          <FormError />
          <ActionsContainer>
            <Button onClick={modalService.close}>{translations('Cancel')}</Button>
            <FormSubmit>{translations('Invite User')}</FormSubmit>
          </ActionsContainer>
        </InviteForm>
      </div>
    </BasicModalOverlay>
  )
}

const schema = [
  {
    id: 'email',
    field: 'Email',
    props: {
      label: translations('Email'),
      name: 'email',
      validate: [validators.email],
      required: true,
      shrink: false,
    }
  }
]

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`

export default withStyles(style)(OrganisationInviteModal)