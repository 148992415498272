import _ from 'lodash'

export const susarEditable = (values = {}) => values.susarEditable === 'true'

export const spontaneousEditable = (values = {}) => values.spontaneousEditable === 'true'

export const hasFatalReaction = (values) => {
  if (!values.reaction) return null
  const fatalReactionExists = (
    values.reaction &&
    _.find(values.reaction, reaction => reaction.reactionoutcome === '5')
  )

  return fatalReactionExists ? '1' : '2'
}

export const getFormatForDate = (value, formats) => {
  if (!value) return ''
  if (!formats) return ''

  const date = {
    year: value.substring(0, 4),
    month: value.substring(4, 6),
    day: value.substring(6, 8)
  }

  if (date.year && date.month && date.day) {
    return formats.yyyymmdd || ''
  } else if (date.year && date.month) {
    return formats.yyyymm || ''
  } else if (date.year) {
    return formats.yyyy || ''
  } else {
    return ''
  }
}

const _equals = (value, test) => {
  return value === test
}

const _notequals = (value, test) => {
  return value !== test
}

const _test = ({ operator, value, test }) => {
  if (!value) return false
  if (operator === 'equals') {
    return _equals(value, test)
  } else if (operator === 'notequals') {
    return _notequals(value, test)
  } else if (operator === 'truthy') {
    return !!value
  } else if (operator === 'falsey') {
    return !value
  } else {
    return false
  }
}

export const testField = ({ operator, value, test, isTrue, isFalse }) => {
  return _test({ operator, value, test }) ? isTrue : isFalse
}

export const equalsInRepeatable = ({ repeatable, field, operator, test, isTrue, isFalse }) => (values) => {
  if (!values[repeatable]) return null
  const predicate = _.find(values[repeatable], repeatableField => {
    if (!repeatableField[field] || !operator) return undefined
    return _test({
      operator,
      value: repeatableField[field],
      test
    })
  })

  return predicate ? isTrue : isFalse
}

/**
 * @typedef {Object} SchemaOption
 * @property {string} value - the hidden value
 * @property {string} label - string shown to the user
 */
/**
 * Cast a string in to a schema option compliant object, the input is used as both the value and label.
 * @param {string} option
 * @returns {SchemaOption} option
 */
export const stringToValueAndLabel = (option) => ({ label: option, value: option })