import _ from 'lodash'
import qs from 'qs'
import { matchPath } from 'react-router'

import { constants as authConstants, selectors as authSelectors } from '../auth'
import { constants as userDetailsConstants } from '../userDetails'
import { constants as usersConstants, selectors as usersSelectors } from '../users'
import analyticsService from '../../../services/analyticsService'
import { history } from '../../../store'
import { environment } from '../../../config'


class AnalyticsMiddleware {
  login = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === authConstants.LOGIN) {
      const user = authSelectors.getCurrentUser(getState())
      analyticsService.userLogin({ user })
    }
  }
  userSearch = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === usersConstants.SEARCH_USER_FRESH) {
      const query = usersSelectors.getQuery(getState()) || ''
      const responses = _.chain(usersSelectors.getResults(getState()))
        .map(user => user.id)
        .value()
      const roleIds = _.chain(usersSelectors.getRoleIds(getState()))
        .join(',')
        .value()
      const organisationIds = _.chain(usersSelectors.getOrganisationIds(getState()))
        .join(',')
        .value()
      const includeDeactivated = usersSelectors.getIncludeDeactivated(getState())
      const page = usersSelectors.getPage(getState()) || ''
      const size = usersSelectors.getSize(getState()) || ''
      const filters = qs.stringify({
        roleIds,
        organisationIds,
        includeDeactivated,
        page,
        size
      })
      analyticsService.userSearch({ query, filters, responses, sort: '' })
    }
  }
  viewMyAccount = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === userDetailsConstants.FETCH_CURRENT_USER_DETAILS) {
      const result = matchPath(history.location.pathname, { path: '/users/:id', exact: true })
      const isRoutingInUserDetailsView = result
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isRoutingInUserDetailsView && isLoggedIn) {
        analyticsService.sendViewEvent({ name: 'myAccount', url: history.location.pathname })
      }
    }
  }
  resetPassword = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === authConstants.RESET_PASSWORD) {
      analyticsService.sendCustomEvent({ type: 'resetPassword' })
    }
  }
}

export default new AnalyticsMiddleware()
