class Timer {
  constructor (callback, delay = 0) {
    this.delay = delay
    this.timeRemaining = delay
    this.callback = callback
    this._isRunning = this._isRunning.bind(this)
    this._timeoutFinished = this._timeoutFinished.bind(this)
    this.pause = this.pause.bind(this)
    this.resume = this.resume.bind(this)
    this.cancel = this.cancel.bind(this)
    this.start = this.start.bind(this)
    this.restart = this.restart.bind(this)
  }

  _timeoutFinished () {
    this.callback()
    this.cancel()
  }

  _isRunning () {
    return this.timeout && this.startTime !== undefined
  }

  restart () {
    this.cancel()
    this.start()
  }

  start () {
    if (!this._isRunning()) {
      this.startTime = new Date().getTime()
      this.timeout = setTimeout(this._timeoutFinished, this.timeRemaining)
    }
  }

  resume () {
    if (!this._isRunning()) {
      this.startTime = new Date().getTime()
      this.timeout = setTimeout(this._timeoutFinished, this.timeRemaining)
    }
  }

  cancel () {
    if (this._isRunning()) {
      clearTimeout(this.timeout)
      this.timeout = undefined
      this.timeRemaining = this.delay
      this.startTime = undefined
    }
  }

  pause () {
    if (this._isRunning()) {
      clearTimeout(this.timeout)
      this.timeout = undefined
      const timeNow = new Date().getTime()
      const timeElapsedSinceStart = timeNow - this.startTime
      this.timeRemaining = this.timeRemaining - timeElapsedSinceStart
      this.startTime = undefined
    }
  }
}

export default Timer
