import { push } from 'connected-react-router'

export const resetPasswordDeepLink = ({ dispatch, getState }) => {
  if (window.cordova && window.plugins && window.plugins.universalLinks) {
    // Subscribe to universal links
    window.plugins.universalLinks.subscribe(null, function (eventData) {
      const { reset_token: resetToken } = eventData.params
      if (!resetToken) return

      // Navigate to reset screen, attaching the token
      dispatch(push(`/reset-password?reset_token=${resetToken}`))
    })
  }

  return next => action => next(action)
}
