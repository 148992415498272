import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import c from 'classnames'

import style from './style'

const CustomCard = ({ classes, className, children, ...rest }) => (
  <section
    className={c(classes.container, className)} {...rest}
  >
    {children}
  </section>
)

CustomCard.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string
}

export default withStyles(style)(CustomCard)
