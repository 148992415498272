import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { translations } from '../../../config'
import style from './style'
import SubHeader from '../../SubHeader'
import Heading from '../../Heading'
import Table from '../Table'

const TableModal = (props) => {
  const { classes, onClose, title, tableProps } = props

  return (
    <div>
      <SubHeader
        centerContent={(
          <Heading component='h1'>{title}</Heading>
        )}
        rightContent={(
          <div>
            <IconButton aria-label={translations('Close')} onClick={() => onClose()}><CloseIcon /></IconButton>
          </div>
        )}
      />
      <div className={classes.root}>
        <Table {...tableProps} />
      </div>
    </div>
  )
}

TableModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tableProps: PropTypes.shape({
    data: PropTypes.array
  })
}

export default withStyles(style)(TableModal)
