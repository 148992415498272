import React, { Fragment } from 'react'

import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'

import PageForm from './components/PageForm'

const EditPageScreen = ({ classes, match, page = {}, organisationId, languageOptions }) => {
  let thisPage = page
  if(!thisPage.id){
    thisPage.new = true
    thisPage.id = match.params.pageId
    thisPage.title = 'New Page'
  }

  return (
    <div className={classes.container}>
      <SubHeader
        centerContent={(
          <Fragment>
            <Heading component='h1'>{thisPage.title}</Heading>
            <Helmet>
              <title>{thisPage.title}</title>
            </Helmet>
          </Fragment>
        )}
        leftContent={(
          <BackBar />
        )}
      />
      <div className={classes.form}>
        <PageForm page={thisPage} organisationId={organisationId} languageOptions={languageOptions}/>
      </div>
    </div>
  )
}

export default withStyles(style)(EditPageScreen)
