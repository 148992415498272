import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import P from '../../../../../components/P'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import Diff from './Diff'
import { assets, translations } from '../../../../../config'

import styles from './style'

const SchemaUpdatesModal = (props) => {
  const {
    classes,
    actions,
    hasUpdates = false,
    addedCount = 0,
    removedCount = 0,
    addedFields,
    removedFields,
    editing = false
  } = props

  return (
    <BasicModalOverlay
      title={translations('Schema Updates Check - Modal Heading')}
      actions={actions}
    >
      <ContentBoxBody>
        { editing && (
           <P type='alert' value={translations('Schema Updates Check - Warning')} />
        )}
        { (hasUpdates && !editing) && (
          <div className={classes.lists}>
            <Diff
              title={translations('Schema Updates Check - Added Fields', { count: addedCount })}
              count={addedCount}
              fields={addedFields}
              direction='ADD'
            />
            <Diff
              title={translations('Schema Updates Check - Removed Fields', { count: removedCount })}
              count={removedCount}
              fields={removedFields}
              direction='REMOVE'
            />
          </div>
        )}
        { (!hasUpdates && !editing) && (
          <div className={classes.checkContainer}>
            <img className={classes.checkIcon} src={assets.checkcircle} alt='' />
            <P className={classes.checkLabel} value={translations('Everything is up to date')} />
          </div>
        )}
      </ContentBoxBody>
    </BasicModalOverlay>
  )
}

SchemaUpdatesModal.propTypes = {
  classes: PropTypes.any,
  actions: PropTypes.array.isRequired,
  hasUpdates: PropTypes.bool,
  addedCount: PropTypes.number,
  removedCount: PropTypes.number,
  addedFields: PropTypes.array,
  removedFields: PropTypes.array,
  editing: PropTypes.bool
}

export default withStyles(styles)(SchemaUpdatesModal)
