
const styles = theme => {
  const { headerHeight, subheaderHeight, footerHeight, bottomBarHeight, tabHeight } = theme
  const filterHeight = 100

  return {
    container: {
      position: 'relative'
    },
    scollContainerInModal: {
      height: `calc(100vh - ${subheaderHeight + footerHeight}px)`,
      overflowY: 'auto'
    },
    scrollContainer: {
      height: `calc(100vh - ${headerHeight + subheaderHeight + footerHeight + tabHeight}px)`
    },
    scrollContainerWithBottomBar: {
      height: `calc(100vh - ${headerHeight + subheaderHeight + bottomBarHeight + footerHeight + tabHeight + filterHeight}px)`
    },
    configureFeedButtonWrapper: {
      marginRight: 10,

      [theme.breakpoints.up('sm')]: {
        marginRight: 20
      }
    },
    tabRoot: {
      fontWeight: 700
    }
  }
}

export default styles
