import _ from 'lodash'
import digitalStoreSdk from '../digitalStoreSdk'

class UploadService {
  uploadToUrl = ({ file, fileType, url, onProgress }) => {
    return new Promise((resolve, reject) => {
      const xhr = new window.XMLHttpRequest()
      xhr.open('PUT', url)
      xhr.addEventListener('load', () => {
        resolve(xhr)
      })
      xhr.addEventListener('error', () => {
        reject(new Error('upload error'))
      })
      xhr.addEventListener('abort', () => {
        reject(new Error('upload abort'))
      })
      if (xhr.upload && onProgress) {
        xhr.upload.onprogress = onProgress
      }
      xhr.setRequestHeader('Content-Type', fileType || file.type)
      xhr.send(file)
    })
  }

  upload = (file, fileName, folderName) => {
    return digitalStoreSdk.auth
      .fetchUploadUrl({
        fileName: fileName ? fileName : file.name,
        folderName,
        contentType: file.type
      })
      .then(({ url }) => {
        return this.uploadToUrl({ file, url })
          .then(() => {
            const remoteFileUrl = url.split('?')[0]
            return {
              file,
              name: fileName,
              folderName,
              url: remoteFileUrl,
              type: file.type
            }
          })
      })
  }

  downloadUrl = async ({ fileName, contentType, folderName, attachmentId }) => {
    try {
      const { url } = await digitalStoreSdk.auth.fetchDownloadUrl({ fileName, contentType, folderName, attachmentId })
      return url
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}

export default new UploadService()
