import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import * as authSelectors from '../auth/selectors'

class FilesActions {
  createFile = (payload) => ({
    type: constants.CREATE_FILE,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      const userId = authSelectors.getActiveUserId(state)
      const params = { ...payload, organisationId, userId }
      return digitalStoreSdk.files.createFile(params)
    }
  })

  updateFile = (payload) => ({
    type: constants.UPDATE_FILE,
    promise: () => {
      return digitalStoreSdk.files.updateFile(payload)
    }
  })

  deleteFile = (payload) => ({
    type: constants.DELETE_FILE,
    promise: () => {
      return digitalStoreSdk.files.deleteFile(payload)
    }
  })

  fetchFiles = (params) => ({
    type: constants.FETCH_FILES,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      return digitalStoreSdk.files.fetchFilesForOrganisation({ ...params, organisationId })
    }
  })
}

export default new FilesActions()
