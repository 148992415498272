import _ from 'lodash'
import { goBack, LOCATION_CHANGE } from 'connected-react-router'
import { destroy as formDestroy } from 'redux-form'
import { matchPath } from 'react-router'

import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { selectors as authSelectors } from '../auth'
import * as ackDetailsConstants from './constants'
import ackDetailsActions from './actions'

class AckDetailsMiddleware {
  loadAckMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/reports/:reportid/acknowledgements/:id', exact: true })
      const ackId = _.get(result, 'params.id')
      const isRoutingToAckDetailsView = result && ackId !== 'new'
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isRoutingToAckDetailsView && isLoggedIn) {
        dispatch(ackDetailsActions.fetchAckById({ id: ackId }))
      }
    }
  }

  showModalOnSubmitAckSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === ackDetailsConstants.SUBMIT_ACK && action.status === 'SUCCESS') {
      const clearStore = () => dispatch(ackDetailsActions.resetStore())

      dispatch(goBack())
      modalService.action({
        title: translations('Acknowledgement Submitted'),
        text: translations('Your acknowledgement has been successfully submitted.'),
        disableBackdropClick: true,
        actions: [
          {
            success: true,
            text: translations('Done'),
            onClick: () => {
              dispatch(formDestroy(ackDetailsConstants.NEW_ACK_FORM_NAME))
              clearStore()
            },
            primary: true
          }
        ]
      })
    }
  }

  showModalOnSubmitAckFail = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === ackDetailsConstants.SUBMIT_ACK && action.status === 'FAIL') {
      modalService.action({
        title: translations('Acknowledgement Submission Failed'),
        text: translations('Please try again'),
        actions: [
          {
            text: translations('Okay')
          }
        ]
      })
    }
  }
}

export default new AckDetailsMiddleware()
