import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

class Table extends PureComponent {
  render () {
    const { header, rows, classes } = this.props
    return (
      <div className={classes.grid} role='table'>
        <div role='rowgroup'>
          {header}
        </div>
        <div role='rowgroup'>
          {rows}
        </div>
      </div>
    )
  }
}

PureComponent.propTypes = {
  header: PropTypes.any,
  rows: PropTypes.any
}

export default withStyles(styles)(Table)
