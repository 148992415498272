import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import actions from './actions'
import * as authSelectors from '../auth/selectors'

class SelectedReceiverMiddleware {
  fetchSelectedReceiverMiddleware = ({ dispatch, getState }) => next => (action) => {
    // if (action.type === LOCATION_CHANGE) {
    //   const isRoutingToReports = matchPath(action.payload.location.pathname, { path: '/reports/:id', exact: true })
    //   const isRoutingToReportConfiguration = matchPath(action.payload.location.pathname, { path: '/report-configuration', exact: true })
    //   const isRoutingToSelectReport = matchPath(action.payload.location.pathname, { path: '/select-report', exact: true })
    //   const isRoutingToNewReports = matchPath(action.payload.location.pathname, { path: '/reports/new', exact: true })

    //   const isRoutingToAck = matchPath(action.payload.location.pathname, { path: '/reports/:id/acknowledgements/:id', exact: true })
    //   const isRoutingToOrgTranslations = matchPath(action.payload.location.pathname, { path: '/organisations/:id/translations', exact: true })
    //   const isLoggedIn = authSelectors.getIsLoggedIn(getState())
    //   if (
    //     isLoggedIn && (isRoutingToReports || isRoutingToAck || isRoutingToOrgTranslations || isRoutingToReportConfiguration || isRoutingToSelectReport || isRoutingToNewReports)
    //   ) {
    //     dispatch(actions.fetchSelectedReceiver())
    //   }
    // }
    next(action)
  }

  fetchSelectedReceiverForPublicFormMiddleware = ({ dispatch, getState }) => next => (action) => {
    // if (action.type === LOCATION_CHANGE) {
    //   const isRoutingToPublicForm = matchPath(action.payload.location.pathname, { path: '/:formType/new/public/:organisationId', exact: true })
    //   if (isRoutingToPublicForm) {
    //     dispatch(actions.fetchSelectedReceiverForPublicForm({
    //       organisationId: _.get(isRoutingToPublicForm, 'params.organisationId')
    //     }))
    //   }
    // }
    next(action)
  }
}

export default new SelectedReceiverMiddleware()
