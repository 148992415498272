import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'

import ViewPlatformScreen from './ViewPlatformScreen'
import { selectors as platformDetailsSelectors, actions as platformDetails } from '../../../store/modules/platformDetails'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as organisationsSelectors } from '../../../store/modules/organisations'
import { selectors as themesSelectors } from '../../../store/modules/themes'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { platformCombinedSelectors } from '../../../store/modules/combinedSelectors'
import FullScreenLoader from '../../../components/FullScreenLoader'
import { platformDetails as schema, formSchemaFieldExclusionsConfiguration as formSchemaFieldExclusionsConfigurationSchema } from '../formSchemas/platformDetailsSchema'

const mapStateToProps = state => {
  const isLoading =
    platformDetailsSelectors.getIsLoading(state) ||
    organisationsSelectors.getOrganisationsIsLoading(state) ||
    organisationsSelectors.getUnassignedOrganisationsIsLoading(state) ||
    themesSelectors.getIsLoadingAllThemes(state)

  const platformDetails = platformDetailsSelectors.getPlatformDetailsForForm(state)
  const platformName = platformDetailsSelectors.getCurrentPlatformName(state)

  const canEditPlatformDetails = authSelectors.getHasPlatformDetailsPermissions(state)

  const formSchemaFieldExclusions = _.get(platformDetails, 'formSchemaFieldExclusions.devicesReport.fields.ncaReportNo')

  const formSchemaConfigurationInitialValues = {
    formSchemaFieldExclusions: formSchemaFieldExclusions
  }

  const hasPlatformAppsEnabled = platformDetailsSelectors.getCurrentPlatformHasAppsEnabled(state)

  const res = {
    isLoading,
    platformName,
    initialValues: platformDetails,
    canEditPlatformDetails,
    schema,
    formSchemaConfigurationInitialValues,
    formSchemaFieldExclusionsConfigurationSchema,
    getPageTitle: platformSelectors.getPageTitle(state),
    hasPlatformAppsEnabled
  }

  return res
}

const enhancer = compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, match: { params: { id } } }) => (params) => {
      return dispatch(platformDetails.updatePlatform({ params, id }))
    },
    onSubmitFormSchemaConfiguration: ({ dispatch, match: { params: { id } } }) => (formValues) => {
      const value = _.get(formValues, 'formSchemaFieldExclusions')

      const formSchemaFieldExclusions = {
        devicesReport: {
          fields: {
            ncaReportNo: value
          }
        },
        fscaReport: {
          fields: {
            ncaReportNo: value
          }
        }
      }
      return dispatch(platformDetails.updatePlatform({ id, params: { formSchemaFieldExclusions: formSchemaFieldExclusions } }))
    },
  }),
  FullScreenLoader
)

export default enhancer(ViewPlatformScreen)
