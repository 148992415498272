"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.visibleIf = exports.show = exports.selectedInMultipleOption = exports.required = exports.optional = exports.isUpdate = exports.isRepeatableOrMultipleOptionIsNotEmpty = exports.isOfProfessionType = exports.isDrugSuspectedOrInteracting = exports.isClinicalTrial = exports.isAudience = exports.inAnyRepeatable = exports.hide = exports.fieldLte = exports.fieldIsTruthy = exports.fieldIsNot = exports.fieldIsFalsey = exports.fieldIsDefined = exports.fieldIs = exports.fieldGte = exports.disabled = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var show = _fp["default"].F;
exports.show = show;
var hide = _fp["default"].T;
exports.hide = hide;
var required = _fp["default"].T;
exports.required = required;
var disabled = _fp["default"].T;
exports.disabled = disabled;
var optional = _fp["default"].F;
exports.optional = optional;

var fieldIs = function fieldIs(field, oneOfArray) {
  return _fp["default"].compose(function (value) {
    return _fp["default"].includes(value, _fp["default"].castArray(oneOfArray));
  }, _fp["default"].get(field));
};

exports.fieldIs = fieldIs;

var fieldGte = function fieldGte(field, comparison) {
  return _fp["default"].compose(function (field) {
    return _fp["default"].gte(field, comparison);
  }, _fp["default"].toNumber, _fp["default"].get(field));
};

exports.fieldGte = fieldGte;

var fieldLte = function fieldLte(field, comparison) {
  return _fp["default"].compose(function (field) {
    return _fp["default"].lte(field, comparison);
  }, _fp["default"].toNumber, _fp["default"].get(field));
};

exports.fieldLte = fieldLte;

var fieldIsNot = function fieldIsNot() {
  return _fp["default"].negate(fieldIs.apply(void 0, arguments));
};

exports.fieldIsNot = fieldIsNot;

var fieldIsDefined = function fieldIsDefined(field) {
  return _fp["default"].compose(function (value) {
    return !_fp["default"].isNil(value);
  }, _fp["default"].get(field));
};

exports.fieldIsDefined = fieldIsDefined;

var fieldIsTruthy = function fieldIsTruthy(field) {
  return _fp["default"].compose(function (value) {
    return !!value;
  }, _fp["default"].get(field));
};

exports.fieldIsTruthy = fieldIsTruthy;

var fieldIsFalsey = function fieldIsFalsey(field) {
  return _fp["default"].compose(function (value) {
    return !value;
  }, _fp["default"].get(field));
};

exports.fieldIsFalsey = fieldIsFalsey;
var visibleIf = _fp["default"].negate;
exports.visibleIf = visibleIf;

var isAudience = function isAudience(details, audienceIds) {
  return _lodash["default"].get(audienceIds, _lodash["default"].get(details, 'audienceId'), false);
};

exports.isAudience = isAudience;

var isOfProfessionType = function isOfProfessionType(professionTypes) {
  return function (values, _parentFormValues, _org, _formview, professions) {
    var isATypeOfProfession = function isATypeOfProfession(targetType) {
      return _fp["default"].includes(targetType, _fp["default"].castArray(professionTypes));
    };

    var getUserProfession = _fp["default"].compose(_fp["default"].get('type'), function (id) {
      return _fp["default"].find({
        id: id
      }, professions);
    }, _fp["default"].get('professionofreporter'));

    return _fp["default"].compose(isATypeOfProfession, getUserProfession)(values);
  };
};

exports.isOfProfessionType = isOfProfessionType;
var isClinicalTrial = fieldIs('observestudytype', '1');
exports.isClinicalTrial = isClinicalTrial;
var isDrugSuspectedOrInteracting = fieldIs('drugcharacterization', ['1', '3']);
exports.isDrugSuspectedOrInteracting = isDrugSuspectedOrInteracting;

var inAnyRepeatable = function inAnyRepeatable(repeatable, fn) {
  return _fp["default"].compose(_fp["default"].any(fn), _fp["default"].get(repeatable));
};

exports.inAnyRepeatable = inAnyRepeatable;

var isRepeatableOrMultipleOptionIsNotEmpty = function isRepeatableOrMultipleOptionIsNotEmpty(fieldName) {
  return _fp["default"].compose(_fp["default"].negate(_fp["default"].isEmpty), _fp["default"].get(fieldName));
};

exports.isRepeatableOrMultipleOptionIsNotEmpty = isRepeatableOrMultipleOptionIsNotEmpty;

var selectedInMultipleOption = function selectedInMultipleOption(fieldName, value) {
  return _fp["default"].compose(_fp["default"].any(_fp["default"].equals(value)), _fp["default"].get(fieldName));
};

exports.selectedInMultipleOption = selectedInMultipleOption;
var isUpdate = fieldIs('isUpdate', true);
exports.isUpdate = isUpdate;