import fp from 'lodash/fp'

export const types = {
    SET_FILTER_ACTION_TYPE: 'SET_FILTER_ACTION_TYPE',
    SET_SEARCH_TERM: 'SET_SEARCH_TERM',
    SET_SORT: 'SET_SORT'
}

export const initialiseState = ({ actionType, searchTerm, sort }) => () => {
    return {
        actionType,
        searchTerm,
        sort
    }
}

export const reducer = (state, action) => {
    switch (action.type) {
        case types.SET_FILTER_CONTEXT_TYPE:
            return fp.set(
                'actionType',
                fp.castArray(action.payload.value),
                state
            )
        case types.SET_SEARCH_TERM:
            return fp.set(
                'searchTerm',
                action.payload.value,
                state
            )
        case types.SET_SORT:
            return fp.set(
                'sort',
                action.payload.value,
                state
            )
        default:
            return state
    }
}
