import React, { Component, Fragment } from 'react'
import { Hidden } from '@material-ui/core'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import _ from 'lodash'
import KeyValueCard from '../../../components/KeyValueCard'
import EditButtons from './Table/EditButtons'
import EditCheckbox from './Table/EditCheckBox'
import Header from './Table/Header'
import { mobileTable } from './Table/MobileTable'

import { translations } from '../../../config'
import { Table, TableRow, TableCell } from '../../../components/Table'
import getFormSchemaLabel from '../../../helpers/getFormSchemaLabel'

class ReportsSearchTable extends Component {
  renderRows = () => {
    const {
      formViews,
      onSubmit,
      isEditing,
      toggleEdit,
      togglePermission,
      reset,
      classes
    } = this.props

    let rows
    if (formViews) {
      rows = _.chain(formViews)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(report => {
          const { id, name, publish, public: publicVal } = report
          return (
            <TableRow key={id} className={classes.rowHeight}>
              <TableCell xs={2} sm={2} md={2} lg={2} xl={2}>
                {name}
              </TableCell>
              <TableCell xs={2} sm={2} md={2} lg={2} xl={2}>
                {getFormSchemaLabel(_.get(report, 'formSchema.name'))}
              </TableCell>
              <TableCell xs={2} sm={2} md={2} lg={2} xl={2} className={classes.tableCenter}>
                <EditCheckbox
                  isEditing={isEditing}
                  id={id}
                  togglePermission={togglePermission}
                  value={!!publish}
                  payload={{
                    publish: !publish
                  }}
                  classes={classes}
                />
              </TableCell>
              <TableCell xs={2} sm={2} md={2} lg={2} xl={2} className={classes.tableCenter}>
                <EditCheckbox
                  isEditing={isEditing}
                  id={id}
                  togglePermission={togglePermission}
                  value={!!publicVal}
                  payload={{
                    public: !publicVal
                  }}
                  classes={classes}
                />
              </TableCell>
              <TableCell xs={2} sm={2} md={2} lg={2} xl={2} className={classes.tableCenter}>
                <EditButtons
                  isEditing={isEditing}
                  id={id}
                  report={report}
                  toggleEdit={toggleEdit}
                  reset={reset}
                  onSubmit={onSubmit}
                />
              </TableCell>
            </TableRow>
          )
        }).value()
    } else {
      rows = <TableRow><TableCell>{translations('No Custom Form Views')}</TableCell></TableRow>
    }
    return rows
  }

  render() {
    const {
      classes,
      isEditing,
      toggleEdit,
      onSubmit,
      reset,
      togglePermission,
      formViews
    } = this.props
    return (
      <div className={classes.gridContainer}>
        <InfiniteScroll
          pageStart={0}
          className={classes.container}
          initialLoad={false}
          useWindow
          loadMore={true}
        >
          {formViews.length ? (
            <Fragment>
              <Hidden xsDown>
                <Table header={<Header classes={classes} />} rows={this.renderRows()} />
              </Hidden>
              <Hidden smUp>
                {_.chain(formViews)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((report, key) => {
                    const getRevision = _.get(report, 'formSchema.details.revision')
                    const { id, name, publish, public: publicVal } = report
                    const data = mobileTable({
                      id,
                      name,
                      publicVal,
                      publish,
                      report,
                      toggleEdit,
                      reset,
                      onSubmit,
                      togglePermission,
                      isEditing,
                      getRevision,
                      classes
                    })
                    return (
                      <KeyValueCard
                        key={key}
                        className={classes.card}
                        data={data}
                      />
                    )
                  }).value()}
              </Hidden>
            </Fragment>
          ) : (
            <div
              className={`${classes.content} ${classes.uppercase}`}
              role='status'
            >
              {translations('No Custom Form Views')}
            </div>
          )}
        </InfiniteScroll>
      </div>
    )
  }
}

ReportsSearchTable.propTypes = {
  classes: PropTypes.shape({}),
  isEditing: PropTypes.bool,
  toggleEdit: PropTypes.func,
  onSubmit: PropTypes.func,
  reset: PropTypes.func,
  togglePermission: PropTypes.func,
  formViews: PropTypes.array,
}

export default ReportsSearchTable
