import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, withPropsOnChange, lifecycle, withHandlers, withProps } from 'recompose'

import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { actions as submissionReportsActions, selectors as submissionReportsSelectors } from '../../../../store/modules/submissionReports'
import { actions as formSchemasActions } from '../../../../store/modules/formSchemas'
import { actions as receiverActions } from '../../../../store/modules/receivers'

import modalService from '../../../../services/modalService'
import ManuallyProcessModal from '../Modals/ManuallyProcessModal'
import ManuallyProcessedSummaryModal from '../Modals/ManuallyProcessedSummaryModal'

import CaseSearchScreen from './CaseSearchScreen'
import { translations } from '../../../../config'
import { showReceiverSummary as _showReceiverSummary, showValidationSummary as _showValidationSummary } from './utils'

export const statusMap = {
  SUCCESS: 'success',
  PENDING: 'warning',
  REJECTED: 'error',
  FAILED: 'error',
  TIMED_OUT: 'error',
  VALID: 'success',
  INVALID: 'error',
  MANUALLY_PROCESSED: 'manuallyProcessed'
}

const mapStateToProps = (state) => {
  const isLoading = submissionReportsSelectors.isLoading(state)
  return {
    pageTitle: platformSelectors.getPageTitle(state)('Case Management Title'),
    isLoading,
    submissionReports: submissionReportsSelectors.getResults(state),
    total: submissionReportsSelectors.getTotal(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubmissionReports: () => dispatch(submissionReportsActions.searchFresh()),
    fetchAllFormSchemas: () => dispatch(formSchemasActions.fetchAllFormSchemas()),
    fetchAllReceivers: () => dispatch(receiverActions.fetchReceivers()),
    fetchSavedSearches: () => dispatch(submissionReportsActions.fetchSavedSearches()),
    resetStore: () => dispatch(submissionReportsActions.resetStore())
  }
}

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount () {
      this.props.fetchSubmissionReports()
      this.props.fetchAllFormSchemas()
      this.props.fetchAllReceivers()
    },
    componentWillUnmount () {
      this.props.resetStore()
    }
  }),
  withHandlers({
    loadNext: ({ dispatch }) => () => {
      dispatch(submissionReportsActions.searchNext())
    },
    updateFilter: ({ dispatch }) => (filter) => {
      dispatch(submissionReportsActions.changeFilter({ filter }))
      dispatch(submissionReportsActions.searchFresh())
    },
    showReceiverSummary: ({ dispatch }) => ({ submissionReportId, receiverId, receiverStatus, receiverName, receiverStatusMessage, canResend = false, sentToReceiverSince = false, submission }) => {
      _showReceiverSummary({ dispatch })({ submissionReportId, receiverId, receiverStatus, receiverName, receiverStatusMessage, canResend, sentToReceiverSince, submission })
    },
    showValidationSummary: () => ({ validationStatus, validationErrors = [] }) => {
      _showValidationSummary()(({ validationStatus, validationErrors }))
    },
    showManuallyProcessed: ({ dispatch }) => ({ submissionReportId }) => {
      const handleSubmit = ({ manuallyProcessedReason }) => {
        dispatch(submissionReportsActions.setManuallyProcessedReport({ submissionReportId, manuallyProcessedReason }))
        modalService.close()
      }
      modalService.open({
        component: ManuallyProcessModal,
        handleSubmit,
        fullScreen: false
      })
    },
    showManuallyProcessedSummary: () => ({ manuallyProcessedByUser, manuallyProcessedReason, createdAt, updatedAt }) => {
      modalService.open({
        component: ManuallyProcessedSummaryModal,
        fullScreen: false,
        manuallyProcessedByUser,
        manuallyProcessedReason,
        createdAt,
        updatedAt
      })
    },
    setReceiverStatusToFailed: ({ dispatch }) => ({ submissionReportId }) => {
      dispatch(submissionReportsActions.setReceiverStatusToFailed({  submissionReportId }))
    }
  }),

  withProps((props) => {
    const { showReceiverSummary, showValidationSummary, showManuallyProcessed, showManuallyProcessedSummary, setReceiverStatusToFailed } = props
    const rowMoreOptions = {
      showReceiverSummary,
      showValidationSummary,
      showManuallyProcessed,
      showManuallyProcessedSummary,
      setReceiverStatusToFailed
    }
    return { rowMoreOptions }
  }),
  withPropsOnChange(['submissionReports'], (props) => {
    const { submissionReports } = props
    const data = submissionReports

    return {
      headers: [
        { id: 'extReportId', label: translations('Case Reference') },
        { id: 'receiver', label: translations('Receiver'), options: { align: 'center' } },
        { id: 'receiverStatus', label: translations('Receiver Status'), options: { align: 'center' } },
        { id: 'validationStatus', label: translations('Validation Status'), options: { align: 'center' } },
        { id: 'application', label: translations('Application'), options: { align: 'center' }},
        { id: 'source', label: translations('Source'), options: { align: 'center' }},
        { id: 'createdAt', label: translations('Created'), options: { align: 'center' } },
        { id: 'updatedAt', label: translations('Updated'), options: { align: 'center' } },
        { id: 'actions', label: translations('Actions'), options: { align: 'right', visuallyHidden: true } }
      ],
      data,
      count: _.size(data),
      hasResults: _.size(data) > 0
    }
  }),
  withPropsOnChange(['count', 'total'], (props) => {
    const { total, count } = props
    return {
      canLoadMore: (count < total)
    }
  })
)

export default enhancer(CaseSearchScreen)
