import React, { Fragment, useRef } from 'react'
import VisuallyHidden from '../../../components/VisuallyHidden'

const FileUploader = ({ onChange, renderContent, accept = [], multiple = false }) => {

  const hiddenFileInput = useRef(null)

  const onClick = event => {
    hiddenFileInput.current.click()
  }

  return (
    <Fragment>
      <input 
        id='fileuploader'
        type='file' 
        ref={hiddenFileInput}
        style={{display:'none'}}
        multiple={multiple} 
        accept={accept.join(',')}
        onChange={onChange}
      />
      <VisuallyHidden><label for="fileuploader">File Uploader</label></VisuallyHidden>
      {renderContent({ onClick })}
    </Fragment>
  )
}

export default FileUploader