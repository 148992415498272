import React from 'react'
import PropTypes from 'prop-types'

import BasicModalOverlay from '../../../../components/BasicModalOverlay'

import { translations } from '../../../../config'

const InfoModal = (props) => {
  const { dismiss, title, message } = props

  return (
    <BasicModalOverlay
      title={title}
      text={message}
      actions={[
        {
          text: translations('Ok'),
          onClick: dismiss
        }
      ]}
    />
  )
}

InfoModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export default InfoModal
