import _ from 'lodash'
import { SUCCESS, PENDING, FAIL } from '../../middleware/redux-promise'

class CommunicationTemplatesReducers {
  fetchCommunicationTemplates = (state, action, path) => {
    switch (action.status) {
      case PENDING:
        return this.fetchCommunicationTemplatesPending({ path, state, action })
      case SUCCESS:
        return this.fetchCommunicationTemplatesSuccess({ path, state, action })
      case FAIL:
        return this.fetchCommunicationTemplatesFail({ path, state, action })
      default:
        return state
    }
  }
  fetchCommunicationTemplatesPending = ({ path, state, action }) => {
    return {
      ...state,
      [path]: {
        ...state[path],
        error: undefined,
        status: PENDING
      }
    }
  }
  fetchCommunicationTemplatesSuccess = ({ path, state, action }) => {
    const { result, payload = {} } = action
    return {
      ...state,
      [path]: {
        ...state[path],
        entities: this._normalizeTemplates({ templates: result }),
        byType: this._groupTemplatesByType({ templates: result }),
        error: undefined,
        status: SUCCESS
      }
    }
  }
  fetchCommunicationTemplatesFail = ({ path, state, action }) => {
    const { error } = action
    return {
      ...state,
      [path]: {
        ...state[path],
        error: error.message,
        status: FAIL
      }
    }
  }
  fetchOrganisationCommunicationTemplate = (state, action) => {
    if (action.status === SUCCESS) {
      action = _.set(action, 'result', _.castArray(action.result))
    }
    return this.fetchCommunicationTemplates(state, action, 'organisationTemplates')
  }
  fetchSystemCommunicationTemplate = (state, action) => {
    if (action.status === SUCCESS) {
      action = _.set(action, 'result', _.castArray(action.result))
    }
    return this.fetchCommunicationTemplates(state, action, 'systemTemplates')
  }
  deleteOrganisationCommunicationTemplate = (state, action) => {
    const id = _.get(action, 'payload.id')
    const entities = _.get(state, 'organisationTemplates.entities')
    const type = _.get(entities, `${id}.emailTemplate.type`) || _.get(entities, `${id}.smsTemplate.type`) || _.get(entities, `${id}.pushTemplate.type`)
    const typeState = _.get(state, `organisationTemplates.byType.${type}`)

    if (action.status !== SUCCESS) {
      return state
    }

    return {
      ...state,
      organisationTemplates: {
        ...state.organisationTemplates,
        entities: _.omit(state.organisationTemplates.entities, id),
        byType: {
          ...state.organisationTemplates.byType,
          [type]: _.without(typeState, id)
        }
      }
    }
  }
  deleteSystemCommunicationTemplate = (state, action) => {
    const id = _.get(action, 'payload.id')
    const entities = _.get(state, 'systemTemplates.entities')
    const type = _.get(entities, `${id}.emailTemplate.type`) || _.get(entities, `${id}.smsTemplate.type`) || _.get(entities, `${id}.pushTemplate.type`)
    const typeState = _.get(state, `systemTemplates.byType.${type}`)

    if (action.status !== SUCCESS) {
      return state
    }

    return {
      ...state,
      systemTemplates: {
        ...state.systemTemplates,
        entities: _.omit(state.systemTemplates.entities, id),
        byType: {
          ...state.systemTemplates.byType,
          [type]: _.without(typeState, id)
        }
      }
    }
  }
  updateDefaultTemplates = (state, action) => {
    if (action.status !== SUCCESS) {
      return state
    }

    const ids = _.values(_.get(action, 'payload.body'))
    const orgEntities = _.get(state, 'organisationTemplates.entities')
    const sysEntities = _.get(state, 'systemTemplates.entities')
 
    const newOrgEntities = _.reduce(orgEntities, (memo, template) => {
      memo[template.id] = {
        ...template,
        isDefault: _.includes(ids, template.id)
      }
      return memo
    }, {})
    const newSysEntities = _.reduce(sysEntities, (memo, template) => {
      memo[template.id] = {
        ...template,
        isDefault: _.includes(ids, template.id)
      }
      return memo
    }, {})

    return {
      ...state,
      organisationTemplates: {
        ...state.organisationTemplates,
        entities: newOrgEntities
      },
      systemTemplates: {
        ...state.systemTemplates,
        entities: newSysEntities
      }
    }
  }
  _normalizeTemplates = ({ templates }) => {
    return _.reduce(templates, (memo, template) => {
      memo[template.id] = template
      return memo
    }, {})
  }
  _groupTemplatesByType = ({ templates }) => {
    return _.reduce((templates), (memo, template) => {
      const type = _.get(template, 'emailTemplate.type') || _.get(template, 'type') || _.get(template, 'smsTemplate.type') || _.get(template, 'pushTemplate.type') 
      if (type) {
        memo[type] = [..._.get(memo, type, []), _.get(template, 'id')]
      }
      return memo
    }, {})
  }
}

export default new CommunicationTemplatesReducers()
