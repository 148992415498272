import React from 'react'
import { pure } from 'recompose'

import ChartTile from '../../../components/Chart/ChartTile'
import ReportChart from '../ReportChart'
import { slugify } from '../../../helpers'
import userSchema from '../../../config/configFiles/organisationReportingSchemaUser.json'
import reportSchema from '../../../config/configFiles/organisationReportingSchemaReport.json'

import qs from 'qs'

const OrganisationCharts = ({
    widgetType,
    organisationAnalytics,
    ids,
    timePeriod,
    productName,
    reportType,
    sourceId,
    dateFrom,
    dateTo
}) => {
    const schema = widgetType === 'user'? userSchema: reportSchema
    return (
        schema
        .map((reportSchema) => {
            const link = `/monitoring-and-analytics/analytics/${reportSchema.id}-${slugify(reportSchema.title)}${qs.stringify({
                timePeriod, 
                organisationIds: ids, 
                productName, 
                reportType, 
                sourceId ,
                dateFrom,
                dateTo
            }, { addQueryPrefix: true })}`
            const to = reportSchema.fullList && !(reportSchema.type === 'LIST') && link
            const seeAllLink = reportSchema.fullList && (reportSchema.type === 'LIST' || reportSchema.type === 'COUNT') && link
            return (
                <ChartTile to={to || undefined} key={link}>
                    <ReportChart
                        items={[organisationAnalytics]}
                        reportSchema={reportSchema}
                        seeAllLink={seeAllLink}
                    />
                </ChartTile>
            )
        })
    )
}

export default pure(OrganisationCharts)
