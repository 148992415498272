import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import Button from '../../../../components/Button'
import Form, { FormBody } from '../../../../components/Form'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import Heading from '../../../../components/Heading'

import { translations } from '../../../../config'

import { hooks as reportConfigurationHooks } from '../../../../store/modules/reportConfiguration'

import getSchema from './schema'
import styles from './styles'

const FORM_NAME = 'FollowUpForm'
const FollowUpForm = Form(FORM_NAME)

const FollowUpModal = ({
  classes,
  closeModal,
  formViewId,
  onSubmit,
  otherFollowUpNames = [],
  initialValues,
  organisationLanguages
}) => {
  const timeframeOptions = reportConfigurationHooks.useTimeframeForDropdown(formViewId) || []
  const cohortOptions = reportConfigurationHooks.useFollowUpCohorts(formViewId) || []
  const triggerOptions = reportConfigurationHooks.useFollowUpTriggers(formViewId) || []
  const eventOptions = reportConfigurationHooks.useFollowUpEvents(formViewId) || []

  return (
    <div className={classes.listContainer}>
      <Heading>{translations('Communication Type - FOLLOWUP')}</Heading>
      <FollowUpForm onSubmit={onSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <FormBody
            formName={FORM_NAME}
            schema={
              getSchema({
                eventOptions,
                cohortOptions,
                triggerOptions,
                timeframeOptions,
                otherFollowUpNames,
                organisationLanguages
              })
            }
            editing
            fullWidthFields
          />
        </ContentBoxBody>
        <div className={classes.buttonContainer}>
          <Button raised buttonType='white' onClick={() => closeModal()}>
            {translations('Cancel')}
          </Button>
          <Button raised buttonType='primary' type='submit'>
            {translations('Confirm')}
          </Button>
        </div>
      </FollowUpForm>
    </div>
  )
}

FollowUpModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func,
  followUpNames: PropTypes.arrayOf(PropTypes.string),
  formViewId: PropTypes.string,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.shape({
    stageId: PropTypes.string.isRequired,
    cohortId: PropTypes.string.isRequired,
    triggerId: PropTypes.string.isRequired,
    timeframeId: PropTypes.string.isRequired,
    message: PropTypes.object
  })
}

export default withStyles(styles)(FollowUpModal)
