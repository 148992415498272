import React from 'react'
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/core/styles'
import Heading from '../../../../../components/Heading'
import { translations } from '../../../../../config'
import { useSelector } from 'react-redux'
import { selectors } from '../../../../../store/modules/terminology'
import fp from 'lodash/fp'
import AddEntryButton from '../../AddEntryButton'
import { DeleteArrangementButton } from '../../DeleteArrangementButton'
import moment from 'moment'
import { formatDate } from '../../utils'
import { EditDictionaryButtonSmall } from '../../AddDictionaryButton/AddDictionaryButton'
import { useAbilities } from '../../../../../ability/ability-context'

const Panel = styled('aside')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px'
}))

const Header = styled('div')(({ theme }) => ({
  paddingBottom: '5px'
}))

const Block = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(3)
}))

const Detail = styled(Typography)(() => ({
  padding: '5px 0 5px 0'
}))

const makeDetail = ({ context, information, seperator = ':' }) => {
  if (!information) {
    return null
  }
  let text = information
  const date = moment(text, moment.ISO_8601)
  if (date.isValid()) {
    text = formatDate(date)
  }
  if (context) {
    text = `${context}${seperator} ${text}`
  }
  return <Detail variant={'body2'} color={'textSecondary'}>{text}</Detail>
}

const ConfigurePanel = () => {
  const activeArrangement = useSelector(selectors.getActiveArrangement)
  const secondaryDictionaries = useSelector(selectors.getSecondaryDictionariesForArrangement)
  const doesArrangementHaveSecondaryDictionaries = useSelector(selectors.doesArrangementHaveSecondaryDictionaries)
  const isUsageTypeList = useSelector(selectors.isUsageTypeList)
  const {
    name,
    description,
    createdAt,
    updatedAt,
    action
  } = activeArrangement || {}

  const {
    useMeddraType
  } = action || {}

  const generalInformation = React.useMemo(() => {
    return [
      {
        information: description
      },
      {
        context: 'Created At',
        information: createdAt
      },
      {
        context: 'Updated At',
        information: updatedAt
      }
    ]

  }, [description, createdAt, updatedAt])


  const messageForMeddra = useMeddraType === 'YES'
  ? translations('Terminology Panel - use MedDRA enabled')
  : translations('Terminology Panel - use MedDRA disabled')

  const ability = useAbilities()
  const canDeleteDictionary = ability.can('deleteDictionary', 'terminology')
  return (
    <Panel>
      <Block>
        <Header>
          <Heading variant='h2' component='h2'>{name}</Heading>
        </Header>
      </Block>
      <Block>
        <Header>
          <Heading variant='h3' component='h3'>{translations('General')}</Heading>
          {fp.map(makeDetail, generalInformation)}
          {doesArrangementHaveSecondaryDictionaries && <Detail variant='body2' color='textSecondary'>{translations('Terminology Panel - Secondary dictionaries', { dictionaries: fp.map('name', secondaryDictionaries) })}</Detail>}
        </Header>

      </Block>
      {!isUsageTypeList && <Block>
        <Header>
          <Heading variant='h3' component='h3'>{translations('Incoming Report Behaviours')}</Heading>
        </Header>
        <Detail variant='body2' color='textSecondary'>{messageForMeddra}</Detail>
      </Block>}
      <Block>
        <Header>
          <Heading variant='h3' component='h3'>{translations('Actions')}</Heading>
        <AddEntryButton/>
        <EditDictionaryButtonSmall/>
        {canDeleteDictionary && <DeleteArrangementButton/>}
        </Header>
      </Block>
    </Panel>
  )
}

export default ConfigurePanel
