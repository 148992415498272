import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { actions as organisationDetailsActions } from '../../../../store/modules/organisationDetails'
import { actions as organisationActions } from '../../../../store/modules/organisations'
import { getCurrentUser } from '../../../../store/modules/auth/selectors'
import {
  getCurrentOrganisationAndChildOrganisations,
  getCurrentOrganisationAndChildOrganisationsCount
} from '../../../../store/modules/organisationDetails/selectors'

import { styled } from '@material-ui/core/styles'
import { translations } from '../../../../config'

import FormContentBox from '../../../../components/FormContentBox'
import Editable from '../../../../components/Editable'
import OrganisationPagination from '../../../OrganisationPagination'

import { getExternalOrganisationIdFieldName, getCurrentPlatformId, getExternalOrganisationIdFieldNamePath } from '../../../../store/modules/platforms/selectors'
import { getPaginatedOrganisations, getPaginatedOrganisationsCount} from '../../../../store/modules/organisations/selectors'

const Intro = styled('div')(({ theme }) => ({
  ...theme.typography.body2
}))

const formContainer = styled('div')(({ theme }) => ({
  overflow: 'auto',
  width: '100%',
  margin: '0 auto',
  boxSizing: 'border-box'
}))

const formId = 'ManufacturerOrganisationForm'
const ManufacturerOrganisationForm = Editable(FormContentBox(formId))

const ManufacturerOrganisationsPanel = () => {
  const dispatch = useDispatch()

  const userId = useSelector(getCurrentUser).id
  const platformId = useSelector(getCurrentPlatformId)
  const organisations = useSelector(getPaginatedOrganisations)
  const organisationCount = useSelector(getPaginatedOrganisationsCount)

  const externalOrganisationIdFieldNamePath = useSelector(getExternalOrganisationIdFieldNamePath)
  const externalOrganisationIdFieldName = useSelector(getExternalOrganisationIdFieldName)
  const headers = [
    { label: 'Organisation', id: 'organisation', type: 'label', width: '60%' },
    { label: 'Organisation ID', id: externalOrganisationIdFieldName, type: 'label', width: '30%' },
    { label: '', id: 'copyButton', type: 'copyButton', target: externalOrganisationIdFieldName, width: '10%', align: 'right' }
  ]

  const fetch = ({ query, limit, offset }) => {
    if(userId) {
      dispatch(organisationActions.fetchPaginatedOrganisations({
        query,
        limit: 100,
        offset,
        userId,
        platformId,
        attributes: ['id', 'name', 'friendlyName', 'displayName', 'details'],
        detailsAttributes: [externalOrganisationIdFieldNamePath]
      }))
    }
  }

  const parseOrganisations = (organisations) => {
    const assignedOrgs = organisations.assigned
    const mappedOrganisations = _.map(assignedOrgs, org => {
  
      const externalOrganisationIdField = _.get(org, `details.${externalOrganisationIdFieldNamePath}`)
      const mappedOrg = ({
        organisation: org.displayName,
      })
      if(!externalOrganisationIdField) {
        mappedOrg[externalOrganisationIdFieldName] = translations('Developer - Organisation Not Configured')
        mappedOrg['copyButton'] = 'HIDE'
        return mappedOrg
      }
      mappedOrg[externalOrganisationIdFieldName] = externalOrganisationIdField
      mappedOrg['copyButton'] = externalOrganisationIdField
      return mappedOrg
    })
    return _.compact(mappedOrganisations)
  }

  if(!externalOrganisationIdFieldNamePath) {
    return <></>
  }

  return (
    <>
      <Intro>
        <p>{translations('Developer - Manufacturer Organisations Intro')}</p>
        <PaginatedOrganisationTable
          organisations={organisations}
          organisationCount={organisationCount}
          fetch={fetch}
          headers={headers}
          parseOrganisations={parseOrganisations}
        />
      </Intro>
    </>
  )
}

const PaginatedOrganisationTable = ({ organisations, organisationCount, fetch, headers, parseOrganisations }) => {
  return (
    <OrganisationPagination
      id={'manufacturerOrganisations'}
      givenClass={formContainer}
      editable={false}
      label={'Organisation ID(s)'}
      Form={ManufacturerOrganisationForm}
      formId={formId}
      fetch={fetch}
      organisations={organisations}
      organisationCount={organisationCount}
      screen={'DEVELOPER'}
      hasUnassigned={false}
      headers={headers}
      parseOrganisations={parseOrganisations}
    />
  )
}

export default ManufacturerOrganisationsPanel
