const styles = theme => ({
  displayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '22px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  textStyle: {
    paddingRight: '9px'
  }
})

export default styles
