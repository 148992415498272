import digitalStoreSdk from '../../../../digitalStoreSdk'
import { asyncActions, actions } from '../../../../store/modules/terminology'
import toastService from '../../../../services/toastService'
import { translations } from '../../../../config'


export const onSubmit = ({ dismiss, dispatch }) => async (formValues) => {
    try {
        const editing = !!formValues.id
        let payload = {
            terminologyArrangement: {
                id: formValues.id,
                name: formValues.name,
                description: formValues.description || undefined,
                organisationId: formValues.organisationId,
                action: {
                    useMeddra: formValues.useMeddra
                }
            },
            options: {
                addSnomedTerms: formValues.addSnomedTerms
            }
        }

        const apiRequest = editing
            ? digitalStoreSdk
                .terminology
                .updateTerminologyArrangementById
            : digitalStoreSdk
                .terminology
                .createTerminologyArrangement

        const arrangement = await apiRequest(payload)
        dispatch(actions.resetMaster())
        dispatch(asyncActions.getTerminologyArrangements())
        if (arrangement.id) {
            dispatch(actions.setArrangementAsActive({ activeArrangementId: arrangement.id }))
        }
        const successMessage = editing
        ? 'Terminology Arrangement Configure - Successful Update'
        : 'Terminology Arrangement Configure - Successful Add'
        toastService.action({
            type: 'success',
            message: translations(successMessage, { arrangementName: formValues.name })
        })
        dismiss()

    } catch (error) {
        toastService.action({
            type: 'error',
            message: error.message
        })
        throw error
    }
}
