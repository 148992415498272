import { connect } from 'react-redux'
import _ from 'lodash'
import {
  compose,
  withHandlers,
  withProps
} from 'recompose'

import { selectors as formViewSelectors } from '../../../../../store/modules/formViews'
import modalService from '../../../../../services/modalService'
import { translations } from '../../../../../config'
import ExportForm from './ExportModal'

const enhancer = compose(
  connect((state) => {
    return {
      formViewId: formViewSelectors.getCurrentFormViewId(state),
      viewJSON: formViewSelectors.getCurrentFormViewVersionJSON(state)
    }
  }),
  withHandlers({
    closeModal: () => () => {
      modalService.close()
    }
  }),
  withProps((props) => {
    const { formViewId, viewJSON } = props
    const initialValues = {}

    if (formViewId) {
      initialValues.formViewId = formViewId
    }

    if (formViewId) {
      initialValues.viewJSON = JSON.stringify(viewJSON)
    }

    return {
      initialValues
    }
  }),
  withProps((props) => {
    const { closeModal } = props
    return {
      actions: [
        {
          text: translations('Close'),
          onClick: closeModal
        }
      ]
    }
  })
)

export default enhancer(ExportForm)
