import { translations } from '../../../../../config'

export const getSchema = (options) => {
  const {
    timeframeOptions
  } = options
  return ([
    {
      id: 'value',
      field: 'Dropdown',
      props: {
        label: translations('Reminder Time Frame'),
        name: 'value',
        options: timeframeOptions
      }
    }
  ])
}