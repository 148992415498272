import { connect } from 'react-redux'
import SideMenu from './SideMenu'
import {actions as appActions} from '../../store/modules/app'

import { selectors as authSelectors } from '../../store/modules/auth'

const mapStateToProps = state => ({
  user: authSelectors.getCurrentUser(state),
  hasUserManagement: authSelectors.getHasUserManagement(state),
  isUserAssessor: authSelectors.getIsUserAssessor(state)
})

const mapDispatchToProps = dispatch => ({
  onClose () {
    dispatch(appActions.toggleSideMenu({ isOpen: false }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu)
