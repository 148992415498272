import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton'
import * as terminologyModals from '../TerminologyModals'

export const DeleteEntryButton = ({ entryId }) => {
  const onClick = terminologyModals.useDeleteEntryModal({ entryId })
  return <IconButton
      aria-label="Delete"
      onClick={onClick}
  >
      <DeleteIcon />
  </IconButton>
}
