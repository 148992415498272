import { createStatefulThunk } from '../toolkit-utils'
import {
  CREATE_PLATFORM_APP,
  FETCH_PLATFORM_APP,
  UPDATE_PLATFORM_APP,
  DELETE_PLATFORM_APP,
  FETCH_PLATFORM_APPS,
  FETCH_PLATFORM_WEBISTES
} from './constants'

import digitalStoreSdk from '../../../digitalStoreSdk'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createPlatformApp = createAsyncThunk(CREATE_PLATFORM_APP,
  async ({ platformId, name, subdomain }, thunkApi) => {
    await digitalStoreSdk.pwa.createPlatformApp({ platformId, name, subdomain })
    thunkApi.dispatch(fetchPlatformApps({ platformId }))
  }
)

export const updatePlatformApp = createAsyncThunk(UPDATE_PLATFORM_APP,
  async ({ id, platformId, name, subdomain, websiteDifferences }, thunkApi) => {
    await digitalStoreSdk.pwa.updatePlatformApp({ id, name, subdomain, ...websiteDifferences })
    thunkApi.dispatch(fetchPlatformApp({ id }))
    if (name || subdomain) {
      thunkApi.dispatch(fetchPlatformApps({ platformId }))
    }
  }
)

export const deletePlatformApp = createAsyncThunk(DELETE_PLATFORM_APP,
  async ({ id, platformId }, thunkApi) => {
    await digitalStoreSdk.pwa.deletePlatformApp({ id })
    thunkApi.dispatch(fetchPlatformApps({ platformId }))
  }
)

export const fetchPlatformApp = createStatefulThunk({
  typePrefix: FETCH_PLATFORM_APP,
  path: 'selectedApp',
  options: {
    isPaginated: false
  },
  asyncFunction: async (args, thunkApi) => {
    const { id } = args
    const data = await digitalStoreSdk.pwa.fetchPlatformApp({ id })
    return data
  }
})

export const fetchPlatformApps = createStatefulThunk({
  typePrefix: FETCH_PLATFORM_APPS,
  path: 'platformApps',
  options: {
    isPaginated: false
  },
  asyncFunction: async (args, thunkApi) => {
    const { platformId } = args
    const data = await digitalStoreSdk.pwa.fetchPlatformApps({ platformId })
    return data
  }
})

export const fetchPlatformWebsites = createStatefulThunk({
  typePrefix: FETCH_PLATFORM_WEBISTES,
  path: 'platformWebsites',
  options: {
    isPaginated: false
  },
  asyncFunction: async (args, thunkApi) => {
    const { platformId } = args
    const data = await digitalStoreSdk.pwa.fetchPlatformWebsites({ platformId })
    return data
  }
})