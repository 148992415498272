import React, { useContext, useCallback } from 'react'
import Form, { FormBody } from '../../../../../components/Form'

import { formSchemaNameMatrix } from '../../../../../helpers'

import { useSidebar } from '../../../../../contexts'
import { hooks as reportConfigurationHooks } from '../../../../../store/modules/reportConfiguration'

const AdvancedForm = Form('ReportConfiguration/Advanced')

const AdvancedSection = ({ formViewId }) => {
  const isNewFormView = formViewId === 'new'
  const { type } = isNewFormView
    ? reportConfigurationHooks.useFormViewEditing()
    : reportConfigurationHooks.useFormViewMetaData(formViewId)
  const schema = formSchemaNameMatrix.getAdvancedOptions(type)
  const { dispatch, actions, state: { advanced } } = useSidebar()

  const handleUpdate = useCallback((updated) => {
    dispatch(actions.updateAdvancedDetails(updated))
  }, [advanced])
  return (
    <AdvancedForm
      editing
      onChange={handleUpdate}
      initialValues={advanced}
    >
      <FormBody
        schema={schema}
        editing
        fullWidthFields
      />
    </AdvancedForm>
  )
}

export default AdvancedSection