import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import { FETCH_ALL_ORGANISATIONS, FETCH_ORGANISATION_BY_ID, FETCH_UNASSIGNED_ORGANISATIONS } from './constants'
import { getCurrentPlatformId, getCurrentPlatformOrganisations } from '../platforms/selectors'

export const getAllOrganisations = state => (state.organisations || {}).results || []

export const getOrganisationByIdIsLoading = state => state.organisations.status === PENDING && state.organisations.action === FETCH_ORGANISATION_BY_ID
export const getCurrentOrganisationDetails = state => _.get(state, 'organisations.details')
export const getCurrentOrganisationName = state => _.get(state, 'organisations.fetchedOrganisation.name')
export const getUnassignedOrganisations = state => _.get(state, 'organisations.unassigned')

export const getAvailableOrganisationsForPlatformOptions = createSelector([
  getCurrentOrganisationDetails,
  getAllOrganisations,
  getCurrentPlatformOrganisations,
  getCurrentPlatformId
], (currentOrg, organisations, platformOrganisations, currentPlatformId) => {
  const platformOrgs = _.map(platformOrganisations, p => { return { platformId: currentPlatformId, ...p } })
  const allOrgs = _.map(organisations, p => { return { platformId: currentPlatformId, ...p } })
  let orgs = _.isEmpty(platformOrgs) ? allOrgs : platformOrgs
  return _.chain(orgs)
    .filter(org => org.platformId === currentPlatformId)
    .map(org => ({
      value: org.id,
      label: org.displayName || org.name,
      details: _.join(_.compact([_.get(org, 'country'), _.get(org, 'details.receivercity')]), ', '),
      reportDirectionType: _.get(org, 'reportDirectionType')
    }))
    .value()
})

export const getOrganisationById = id => createSelector([
  getAllOrganisations
], organisations => {
  return organisations.find(s => s.id === id)
})

export const getOrganisationsIsLoading = state => state.organisations.status === PENDING && state.organisations.action === FETCH_ALL_ORGANISATIONS

export const getUnassignedOrganisationsIsLoading = state => state.organisations.status === PENDING && state.organisations.action === FETCH_UNASSIGNED_ORGANISATIONS

export const getOrganisationNamesTruncatedDisplay = _.memoize((organisationIds) =>
  createSelector([
    getAllOrganisations
  ], (organisations) => {
    if (organisationIds.length > 2) {
      return organisations.length > 2
        ? `${organisations[0].name}, ${organisations[1].name} (+${organisationIds.length - 2} more)`
        : 'loading...'
    } else {
      return _.chain(organisations)
        .filter(organisation => organisationIds.includes(organisation.id))
        .map('name')
        .join(', ')
        .defaultTo('loading...')
        .value()
    }
  })
)

export const getPaginatedOrganisations = state => _.get(state, 'organisations.pagination.organisations')
export const getPaginatedOrganisationsCount = state => _.get(state, 'organisations.pagination.count')

export const getOrganisationSearchResults = state => _.get(state, 'organisations.search.results')
export const getOrganisationSearchCount = state => _.get(state, 'organisations.search.count')