import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'

import Button from '../../../../components/Button'
import { useQuery } from '../../../../hooks'
import { translations } from '../../../../config'

import { useConditions } from '../../../../contexts/ConditionsContext'
import ReportManagementConditionsPanel from '../../ReportManagementConditionsPanel'
import Content from '../Components/Content'
import Actions from '../Components/Actions'

const ManageConditionsContents = (props) => {
  const { onClose } = props
  const [selected, setSelected] = useState([])
  const { conditions: conditionFilters } = useQuery()
  const { getConditions, replaceResourceConditions } = useConditions()

  useEffect(() => {
    const filteredConditions = getConditions({ filters: conditionFilters })
    setSelected(_.map(filteredConditions, 'id'))
  }, [])

  const onSelect = ({ conditionId }) => {
    setSelected(_.xor(selected, [conditionId]))
  }

  const selectedLookup = ({ conditionId }) => {
    const isSelected = _.includes(selected, conditionId)
    return isSelected
  }

  const onSave = () => {
    const { resourceType, resourceId } = conditionFilters
    replaceResourceConditions({
      resourceType,
      resourceId,
      conditionIds: selected
    })
    onClose()
  }

  return (
    <>
      <Actions>
        <Grid container spacing={2} justify={'flex-end'}>
          <Grid item>
            <Button onClick={onClose}>
              {translations('Cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type='submit'
              raised
              buttonType='primary'
              onClick={onSave}
            >
              {translations('Save')}
            </Button>
          </Grid>
        </Grid>
      </Actions>
      <Content>
        <ReportManagementConditionsPanel
          onSelect={onSelect}
          selectedLookup={selectedLookup}
          disableDelete
        />
      </Content>
    </>
  )
}

ManageConditionsContents.props = {}

ManageConditionsContents.propTypes = {}

export default ManageConditionsContents
