
import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams } from 'react-router'

import { styled } from '@material-ui/core/styles'

import SubmittingButton from '../../../components/SubmittingButton'
import ReportersTable from './ReportersTable'
import ReportsLoadingBar from '../ReportConfigurationLoadingBar'
import ReportersSearch from './ReportersSearch'

import { translations } from '../../../config'
import { useInitialFetch } from '../../../hooks'
import {
  actions as reportConfigurationActions,
  hooks as reportConfigurationHooks
} from '../../../store/modules/reportConfiguration'
import ReportersSearchFilters from './ReportersSearchFilters'

const Content = styled('div')(() => ({
  width: '100%',
  textAlign: 'center'
}))

const ReportConfigurationReportersPanel = () => {
  const { id: formViewId } = useParams()
  const dispatch = useDispatch()
  useInitialFetch(() => reportConfigurationActions.searchReportersFresh({ formViewId }))
  const isLoading = reportConfigurationHooks.useFormViewReportersLoading()
  const canLoadMore = reportConfigurationHooks.useCanLoadMoreReporters({ formViewId })

  const loadMore = () => {
    dispatch(reportConfigurationActions.searchReportersNext({ formViewId }))
  }

  return (
    <>
      <ReportsLoadingBar isLoading={isLoading} />
      <Content>
        <ReportersSearch formViewId={formViewId} />
        <div>
          <ReportersSearchFilters />
          <ReportersTable
            isLoading={isLoading}
            formViewId={formViewId}
          />
        </div>
        {canLoadMore && (
          <SubmittingButton
            onClick={() => loadMore()}
            isLoading={isLoading}
            isSubmitting={isLoading}>
            {translations('Load more results')}
          </SubmittingButton>
        )}
      </Content>
    </>
  )
}

ReportConfigurationReportersPanel.propTypes = {
  formViewId: PropTypes.string
}

export default ReportConfigurationReportersPanel
