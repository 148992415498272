const styles = (theme) => {
    return {
      container: {},
      section: {
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        maxHeight: 320,
        overflow: 'auto',
        '& > h3': {
          marginBottom: theme.spacing(2)
        },
        '&:last-child': {
          marginBottom: 0
        }
      },
      list: {
        listStyle: 'none outside',
        margin: 0,
        padding: 0
      },
      listItem: {
        padding: '5px 10px',
        margin: '0 0 5px 0',
        borderLeft: `4px solid ${theme.palette.grey[300]}`,
        '&:last-child': {
          marginBottom: 0
        }
      },
      messageContainer: {
          margin: '0 0 10px 0',
          padding: '0 0 0 2px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
      }
    }
  }
  
  export default styles
  