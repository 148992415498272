"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showIfSpecificationOther = exports.showIfReporterReadyToSubmitConfirmation = exports.showIfReporterOther = exports.showIfReportedToBloodEstablishment = exports.showIfDeviationOther = exports.shouldUpdateConfirmationUser = exports.noIfNoImplicatedComponent = exports.isUserIdDifferentFromOriginalReporter = exports.isSpecificationOther = exports.isReporterReadyToSubmitConfirmation = exports.isReporterOther = exports.isReporterOriginal = exports.isReportedToBloodEstablishment = exports.isReportNotification = exports.isNotNotification = exports.isNotDraft = exports.isDraftUpdateReporter = exports.isDraft = exports.isDomainStatusSabreDraft = exports.isDomainStatusFootnoteRequired = exports.isDeviationOther = exports.isConfirmationSubmittedUpdateReporter = exports.isConfirmationSubmitted = exports.isConfirmationRequestedOrSubmitted = exports.isConfirmation = exports.hasFootnoteBeenAdded = exports.hasFileNameBeenSet = exports.hasFileBeenAttached = exports.getCurrentUserTitleAndFullName = exports.getCurrentUserOccupation = exports.formatNameWithTitle = exports.dobIsNotDefined = exports.disableComponentTransfused = exports.createCleanAndJoin = exports.computeOriginalReporterValue = exports.componentIsNoImplicatedComponent = exports.clearIfNotSpecificationOther = exports.clearIfNotReporterOther = exports.clearIfNotReportedToBloodEstablishment = exports.clearIfNotDeviationOther = exports.checkIfAdditionalFootnoteIsRequired = exports.ageIsNotDefined = exports.ageCompletion = exports.YES = exports.REPORT_STATUSES = exports.REPORTER_STATUSES = exports.REGULATOR_STATUSES = exports.OTHER = exports.NO = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var computedValue = _interopRequireWildcard(require("../../../formHelpers/computedValue"));

var computedHidden = _interopRequireWildcard(require("../../../formHelpers/computedHidden"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var YES = 'Yes';
exports.YES = YES;
var NO = 'No';
exports.NO = NO;
var OTHER = 'Other';
exports.OTHER = OTHER;
var ORIGINAL_REPORTER = 'Original Reporter';
var REPORT_STATUSES = {
  NOTIFICATION: 'Notification',
  CONFIRMATION: 'Confirmation'
};
exports.REPORT_STATUSES = REPORT_STATUSES;
var REPORTER_STATUSES = {
  SUBMIT_NOTIFICATION: 'Submit Notification',
  NO_ACTION: 'No Action',
  SUBMIT_CONFIRMATION: 'Submit Confirmation',
  FOOTNOTE_REQUIRED: 'Footnote required'
};
exports.REPORTER_STATUSES = REPORTER_STATUSES;
var REGULATOR_STATUSES = {
  NOT_RECEIVED: 'Not received',
  REVIEW_PENDING: 'Review pending',
  CONFIRMATION_REQUIRED: 'Confirmation required',
  PENDING_MHRA_REVIEW: 'Pending MHRA review',
  CLOSED: 'Closed',
  EXCLUDED: 'Excluded'
};
exports.REGULATOR_STATUSES = REGULATOR_STATUSES;
var isReportedToBloodEstablishment = computedHidden.fieldIs('beReported', YES);
exports.isReportedToBloodEstablishment = isReportedToBloodEstablishment;
var showIfReportedToBloodEstablishment = computedHidden.visibleIf(isReportedToBloodEstablishment);
exports.showIfReportedToBloodEstablishment = showIfReportedToBloodEstablishment;
var clearIfNotReportedToBloodEstablishment = computedValue.clearIfNot(isReportedToBloodEstablishment);
exports.clearIfNotReportedToBloodEstablishment = clearIfNotReportedToBloodEstablishment;
var isDeviationOther = computedHidden.fieldIs('deviation', OTHER);
exports.isDeviationOther = isDeviationOther;
var showIfDeviationOther = computedHidden.visibleIf(isDeviationOther);
exports.showIfDeviationOther = showIfDeviationOther;
var clearIfNotDeviationOther = computedValue.clearIfNot(isDeviationOther);
exports.clearIfNotDeviationOther = clearIfNotDeviationOther;
var isSpecificationOther = computedHidden.fieldIs('specification', OTHER);
exports.isSpecificationOther = isSpecificationOther;
var showIfSpecificationOther = computedHidden.visibleIf(isSpecificationOther);
exports.showIfSpecificationOther = showIfSpecificationOther;
var clearIfNotSpecificationOther = computedValue.clearIfNot(isSpecificationOther);
exports.clearIfNotSpecificationOther = clearIfNotSpecificationOther;
var isReporterOther = computedHidden.fieldIs('originalReporter', OTHER);
exports.isReporterOther = isReporterOther;
var isReporterOriginal = computedHidden.fieldIs('originalReporter', ORIGINAL_REPORTER);
exports.isReporterOriginal = isReporterOriginal;
var showIfReporterOther = computedHidden.visibleIf(isReporterOther);
exports.showIfReporterOther = showIfReporterOther;
var clearIfNotReporterOther = computedValue.clearIfNot(isReporterOther);
exports.clearIfNotReporterOther = clearIfNotReporterOther;
var reportStatusIsConfirmation = computedHidden.fieldIs('reportStatus', REPORT_STATUSES.CONFIRMATION);

var isConfirmation = _fp["default"].anyPass([reportStatusIsConfirmation]);

exports.isConfirmation = isConfirmation;
var isReporterReadyToSubmitConfirmation = computedHidden.fieldIs('enableConfirmation', YES);
exports.isReporterReadyToSubmitConfirmation = isReporterReadyToSubmitConfirmation;
var showIfReporterReadyToSubmitConfirmation = computedValue.not(isReporterReadyToSubmitConfirmation);
exports.showIfReporterReadyToSubmitConfirmation = showIfReporterReadyToSubmitConfirmation;
var isConfirmationSubmitted = computedHidden.fieldIs('reportStatus', REPORT_STATUSES.CONFIRMATION);
exports.isConfirmationSubmitted = isConfirmationSubmitted;
var isReportNotification = computedHidden.fieldIs('reportStatus', REPORT_STATUSES.NOTIFICATION);
exports.isReportNotification = isReportNotification;

var isConfirmationRequestedOrSubmitted = _fp["default"].anyPass([isReporterReadyToSubmitConfirmation]);

exports.isConfirmationRequestedOrSubmitted = isConfirmationRequestedOrSubmitted;

var createCleanAndJoin = function createCleanAndJoin() {
  var separator = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ' ';
  return _fp["default"].compose(_fp["default"].join(separator), _fp["default"].compact, _fp["default"].map(_fp["default"].trim));
};

exports.createCleanAndJoin = createCleanAndJoin;

var formatNameWithTitle = _fp["default"].compose(createCleanAndJoin(' '), _fp["default"].at(['title', 'firstName', 'lastName']));

exports.formatNameWithTitle = formatNameWithTitle;

var getCurrentUserTitleAndFullName = _fp["default"].compose(formatNameWithTitle, computedValue.getCurrentUser);

exports.getCurrentUserTitleAndFullName = getCurrentUserTitleAndFullName;

var getCurrentUserOccupation = _fp["default"].compose(_fp["default"].get('details.occupation'), computedValue.getCurrentUser);

exports.getCurrentUserOccupation = getCurrentUserOccupation;
var isDraft = computedHidden.fieldIs('regulatorStatus', REGULATOR_STATUSES.NOT_RECEIVED);
exports.isDraft = isDraft;
var isNotDraft = computedHidden.fieldIsNot('regulatorStatus', REGULATOR_STATUSES.NOT_RECEIVED);
exports.isNotDraft = isNotDraft;
var isNotNotification = computedHidden.fieldIsNot('reporterStatus', REPORTER_STATUSES.SUBMIT_NOTIFICATION);
exports.isNotNotification = isNotNotification;

var isDomainStatusFootnoteRequired = _fp["default"].allPass([computedHidden.fieldIs('reporterStatus', REPORTER_STATUSES.FOOTNOTE_REQUIRED), computedHidden.fieldIs('regulatorStatus', REGULATOR_STATUSES.PENDING_MHRA_REVIEW), _fp["default"].anyPass([computedHidden.fieldIs('reportStatus', REPORT_STATUSES.CONFIRMATION), computedHidden.fieldIs('enableConfirmation', NO)])]);

exports.isDomainStatusFootnoteRequired = isDomainStatusFootnoteRequired;

var isDomainStatusSabreDraft = _fp["default"].allPass(computedHidden.fieldIs('reporterStatus', REPORTER_STATUSES.SUBMIT_NOTIFICATION), computedHidden.fieldIs('regulatorStatus', REGULATOR_STATUSES.NOT_RECEIVED));

exports.isDomainStatusSabreDraft = isDomainStatusSabreDraft;
var hasFootnoteBeenAdded = computedValue.hasRepeatableBeenAdded('footnotes');
exports.hasFootnoteBeenAdded = hasFootnoteBeenAdded;

var checkIfAdditionalFootnoteIsRequired = function checkIfAdditionalFootnoteIsRequired(_value, values) {
  switch (true) {
    case !isDomainStatusFootnoteRequired(values):
      return;

    case hasFootnoteBeenAdded(values):
      return;

    default:
      return ['Error - Additional Footnote Required'];
  }
};

exports.checkIfAdditionalFootnoteIsRequired = checkIfAdditionalFootnoteIsRequired;
var hasFileBeenAttached = computedHidden.fieldIsDefined('attachmenturl');
exports.hasFileBeenAttached = hasFileBeenAttached;
var hasFileNameBeenSet = computedHidden.fieldIsDefined('attachmentfile');
exports.hasFileNameBeenSet = hasFileNameBeenSet;

var isUserIdDifferentFromOriginalReporter = function isUserIdDifferentFromOriginalReporter() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  var values = args[0];
  var currentUserId = computedValue.getCurrentUserId.apply(computedValue, args);

  var notificationUserId = _fp["default"].get('reporterIdNtf', values);

  if (notificationUserId && currentUserId) {
    return notificationUserId !== currentUserId;
  }
};

exports.isUserIdDifferentFromOriginalReporter = isUserIdDifferentFromOriginalReporter;

var shouldUpdateConfirmationUser = function shouldUpdateConfirmationUser() {
  var isInConfirmationEntryState = isConfirmationRequestedOrSubmitted.apply(void 0, arguments);

  if (!isInConfirmationEntryState) {
    return false;
  }

  var isUserIdDifferent = isUserIdDifferentFromOriginalReporter.apply(void 0, arguments);
  return isUserIdDifferent;
};

exports.shouldUpdateConfirmationUser = shouldUpdateConfirmationUser;
var computeOriginalReporterValue = computedValue.clearOrCallback(isConfirmationRequestedOrSubmitted, computedValue.branch(isUserIdDifferentFromOriginalReporter)(function () {
  return OTHER;
}, function () {
  return ORIGINAL_REPORTER;
}));
exports.computeOriginalReporterValue = computeOriginalReporterValue;

var isConfirmationSubmittedUpdateReporter = function isConfirmationSubmittedUpdateReporter(fn) {
  return computedValue.noopOrCallback(isConfirmationRequestedOrSubmitted, fn);
};

exports.isConfirmationSubmittedUpdateReporter = isConfirmationSubmittedUpdateReporter;

var isDraftUpdateReporter = function isDraftUpdateReporter(fn) {
  return computedValue.noopOrCallback(isDraft, fn);
};

exports.isDraftUpdateReporter = isDraftUpdateReporter;
var ageIsNotDefined = computedValue.not(computedHidden.fieldIsDefined('age'));
exports.ageIsNotDefined = ageIsNotDefined;
var dobIsNotDefined = computedValue.not(computedHidden.fieldIsDefined('dob'));
exports.dobIsNotDefined = dobIsNotDefined;
var ageCompletion = {
  fields: ['dob', 'age'],
  error: 'Error - Either age or date of birth must be completed'
};
exports.ageCompletion = ageCompletion;

var componentIsNoImplicatedComponent = _fp["default"].compose(_fp["default"].includes('No implicated component'), computedValue.get('component'));

exports.componentIsNoImplicatedComponent = componentIsNoImplicatedComponent;
var noIfNoImplicatedComponent = computedValue.noopOrCallback(componentIsNoImplicatedComponent, function () {
  return NO;
});
exports.noIfNoImplicatedComponent = noIfNoImplicatedComponent;

var disableComponentTransfused = _fp["default"].anyPass([componentIsNoImplicatedComponent, isNotNotification]);

exports.disableComponentTransfused = disableComponentTransfused;