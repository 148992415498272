import React from 'react'
import Header from '../AnalyticsHeader'
import Filters from '../AnalyticsFilters'
import Content from '../AnalyticsContent'

const AnalyticsView = () => {
  return (
    <>
      <Header/>
      <Filters />
      <Content />
    </>
  )
}

export default AnalyticsView
