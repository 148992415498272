export const CREATE_OR_FETCH_APPLICATION = 'digitalStore/developer/CREATE_OR_FETCH_APPLICATION'
export const FETCH_API_KEYS = 'digitalStore/developer/FETCH_API_KEYS'
export const CREATE_API_KEY = 'digitalStore/developer/CREATE_API_KEY'
export const REMOVE_API_KEY = 'digitalStore/developer/REMOVE_API_KEY'
export const CLEAR_TEMPORARY_KEY = 'digitalStore/developer/CLEAR_TEMPORARY_KEY'
export const GENERATE_KEY_FORM = 'digitalStore/developer/GENERATE_KEY_FORM'

export const CREATE_APPLICATION = 'digitalStore/developer/CREATE_APPLICATION'
export const DELETE_APPLICATION = 'digitalStore/developer/DELETE_APPLICATION'
export const UPDATE_APPLICATION = 'digitalStore/developer/UPDATE_APPLICATION'
export const FETCH_APPLICATIONS = 'digitalStore/developer/FETCH_APPLICATIONS'

export const GENERATE_APPLICATION = 'digitalStore/developer/GENERATE_APPLICATION_USER_FORM'
export const GENERATE_SERVICE_SUBMISSION_USER_FORM = 'digitalStore/developer/GENERATE_SERVICE_SUBMISSION_USER_FORM'
