import React from 'react'
import { InputAdornment } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/HighlightOff'
import _ from 'lodash'

const ClearButtonAdornment = ({ onClearButtonPress, value, clearable = false }) => {
  if (!clearable || _.isEmpty(value)) {
    return null
  }
  return (
    <InputAdornment position='end'>
      <IconButton onClick={onClearButtonPress}>
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  )
}

export default ClearButtonAdornment
