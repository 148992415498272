import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../../components/SubHeader'
import SearchInput from '../../../../components/SearchInput'
import BackBar from '../../../../components/BackBar'
import { translations } from '../../../../config'

import style from './style'

const ProductsSearchBar = (props) => {
  const { query, handleSearch, isLoading, watchListOnly } = props
  return <SubHeader
    leftContent={<BackBar />}
    centerContent={<SearchInput
      value={query}
      placeholder={translations(
        watchListOnly
        ? 'Watch List Search Input'
        : 'Products Search Input'
      )}
      onChange={handleSearch}
      autoFocus={false}
    />}
    isLoading={isLoading}
  />
}

export default withStyles(style)(ProductsSearchBar)
