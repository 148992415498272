import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import * as validators from '../../validators'
import style from './style'

import { Checkbox } from '../Fields'
import { translations } from '../../config'
import { Link } from '@material-ui/core'

const Terms = props => {
  const { classes, privacyPolicyLink, label = 'User Registration - Terms Agreement' } = props


  const getTextComponent = () => {
    if (privacyPolicyLink) {
      return (
        <Link
          href={privacyPolicyLink}
          target='_blank'
          children={translations(label)}
          className={classes.termsText}
          rel='nooopener'
        />
      )
    }
    return (
      <a
        children={translations(label)}
        className={classes.termsText}
      />
    )
  }

  const text = getTextComponent()

  return <div className={classes.formControl}>
    <Checkbox
      name='terms'
      validate={validators.requiredCheckbox}
      label={text}
    />
  </div>
}

Terms.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  privacyPolicyLink: PropTypes.string
}

export default withStyles(style)(Terms)
