import { combineReducers } from 'redux'
import * as constants from './constants'
import { constants as newsArticleConstants } from '../newsArticles'
import reducers from './reducers'

const defaultState = {
  size: 30,
  page: 1,
  language: undefined,
  error: undefined,
  total: undefined,
  status: undefined,
  results: []
}

// Reusable reducers
// https://redux.js.org/recipes/structuringreducers/reusingreducerlogic
// This reuses the news logic for default news and watchlist news
// Actions have the flag "watchlist" to indentify
const newsReducer = (newsType) => (state = defaultState, action) => {
  // delete should effect both stores, so is able to get past condition
  const isDeleteAction = action.type === newsArticleConstants.DELETE_ARTICLE
  if (!isDeleteAction && action.newsType !== newsType) return state

  switch (action.type) {
    case constants.FETCH_NEWS:
      return reducers.fetchNews(state, action)
    case constants.FETCH_NEWS_NEXT:
      return reducers.fetchNewsNext(state, action)
    case newsArticleConstants.DELETE_ARTICLE:
      return reducers.deleteArticle(state, action)
    case constants.CHANGE_NEWS_FILTER:
      return reducers.changeFilter(state, action)
    case constants.CHANGE_NEWS_FILTERS:
      return reducers.changeFilters(state, action)
    default:
      return state
  }
}

export default combineReducers({
  default: newsReducer('default'),
  watchlist: newsReducer('watchlist')
})
