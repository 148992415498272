import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'

import { selectors as newsSelectors } from '../../../store/modules/news'
import NewsWebviewScreen from './NewsWebviewScreen'

const mapStateToProps = state => {
  const news = newsSelectors.getResults(state)
  return {
    news
  }
}

const getArticleUrl = (match, location, news) => {
  const url = news.length > 0
    ? (news.find((item) => item.id === match.params.id) || {}).url
    : location.search.replace('?article=', '')
  return url
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(['match', 'location', 'news'], ({ match, location, news }) => ({
    url: getArticleUrl(match, location, news)
  }))
)(NewsWebviewScreen)
