import React, { Fragment } from 'react'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { InputLabel } from '@material-ui/core'

import style from './style'

const CustomInputLabel = (props) => {
  const { width, classes, staticLabel, className, ...rest } = props
  const { shrink } = rest

  const labelClass = cx(className, classes.label)

  if ((shrink && width === 'xs') || staticLabel) {
    return <Fragment>
      <InputLabel
        {...rest}
        className={labelClass}
      />
      {/* this span is here because material-ui has a style with an
      adjacent combinator (label + .MuiInput-formControl-440),
      which we want to avoid */}
      <span />
    </Fragment>
  } else {
    return (
      <InputLabel
        {...rest}
        className={labelClass}
      />
    )
  }
}

export default withStyles(style)(CustomInputLabel)
