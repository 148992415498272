import React, { useReducer } from 'react'
import _ from 'lodash'
import AccountType from '../AccountType'
import OtherUserGroups from '../OtherUserGroups'
import OrganisationAssociation from '../OrganisationAssociation'
import { murFormReducer } from './reducers'
import { murFormConsts } from './constants'
import { changeSelectedAccountTypes, changeSelectedOrganisations } from './actions'
import { useDispatch, useSelector } from 'react-redux'
import { translations } from '../../../../../config'
import { selectors as userGroupSelectors } from '../../../../../store/modules/userGroups'

const UserGroupAndOrganisation = (props) => {
  const {
    formId,
    singular,
    excludeOther
  } = props

  const accountTypeOptions = useSelector(userGroupSelectors.fetchAllUserGroupsForCurrentPlatform)
  const reduxDispatch = useDispatch()

  const [state, dispatch] = useReducer(murFormReducer, {
    formId: formId,
    selectedAccountTypes: [],
    selectedOrganisations: {
      mfrAssociation: null,
      ukrpAssociation: null,
      authRepAssociation: null
    },
    otherUserGroup: null
  })

  const { filteredAccountTypeOptions, otherOptions } = mapAccountTypeOptions(accountTypeOptions, excludeOther)
  const showAccountTypes = !_.isEmpty(filteredAccountTypeOptions)

  const showMfrAssociation = _.includes(state.selectedAccountTypes, murFormConsts.MFR_GROUP_ID)
  const showUKRPAssociation = _.includes(state.selectedAccountTypes, murFormConsts.UKRP_GROUP_ID)
  const showAuthRepAssociation = _.includes(state.selectedAccountTypes, murFormConsts.AUTH_REP_GROUP_ID)

  const showOtherUserGroups = _.includes(state.selectedAccountTypes, murFormConsts.OTHER_GROUP_ID) && !_.isEmpty(otherOptions)

  const wrappedChangeSelectedAccountTypes = (selectedAccountTypes) => {
    changeSelectedAccountTypes(dispatch)(state.selectedAccountTypes, selectedAccountTypes, singular, formId, reduxDispatch)
  }

  const wrappedChangeSelectedOrganisations = (organisationId, userGroup) => {
    changeSelectedOrganisations(dispatch)(organisationId, userGroup)
  }

  return (
    <>
      {showAccountTypes &&
        <AccountType
          formId={formId}
          options={filteredAccountTypeOptions}
          value={state.selectedAccountTypes}
          singular={singular}
          handleChange={wrappedChangeSelectedAccountTypes}
        />
      }
      {showOtherUserGroups &&
        <OtherUserGroups
          formId={formId}
          options={otherOptions}
          value={state.otherUserGroup}
        />
      }
      {showMfrAssociation &&
        <OrganisationAssociation
          id={'mfrAssociation'}
          label={translations('Manufacturer Association')}
          formId={formId}
          required={showMfrAssociation}
          selected={state.selectedOrganisations[murFormConsts.MFR_ID]}
          value={state.selectedOrganisations[murFormConsts.MFR_ID]}
          handleChange={(organisationId) => wrappedChangeSelectedOrganisations(organisationId, murFormConsts.MFR_ID)}
        />
      }
      {showUKRPAssociation &&
        <OrganisationAssociation
          id={'ukrpAssociation'}
          label={translations('UKRP Association')}
          formId={formId}
          required={showUKRPAssociation}
          selected={_.get(state.selectedOrganisations, murFormConsts.UKRP_ID)}
          value={_.get(state.selectedOrganisations, murFormConsts.UKRP_ID)}
          handleChange={(organisationId) => wrappedChangeSelectedOrganisations(organisationId, murFormConsts.UKRP_ID)}
        />
      }
      {showAuthRepAssociation &&
        <OrganisationAssociation
          id={'authRepAssociation'}
          label={translations('Authorised Representative Association')}
          formId={formId}
          required={showAuthRepAssociation}
          selected={_.get(state.selectedOrganisations, murFormConsts.AUTH_REP_ID)}
          value={_.get(state.selectedOrganisations, murFormConsts.AUTH_REP_ID)}
          handleChange={(organisationId) => wrappedChangeSelectedOrganisations(organisationId, murFormConsts.AUTH_REP_ID)}
        />
      }
    </>
  )
}

const mapAccountTypeOptions = (options, excludeOther) => {
  const mappedOptions = _.map(options, o => ({
    label: o.name,
    description: o.description,
    value: o.id
  }))
  const filteredAccountTypeOptions = _.filter(mappedOptions, o => !_.includes(o.label, 'Other'))
  if (!excludeOther) { 
    filteredAccountTypeOptions.push({ label: 'Other', description: 'Other', value: 'Other' })
  }
  const otherOptions = _.filter(mappedOptions, o => _.includes(o.label, 'Other'))
  return { filteredAccountTypeOptions, otherOptions }
}

export default UserGroupAndOrganisation