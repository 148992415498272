import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'

class AccountDeletionRequestActions {
  searchAccountDeletionRequestFresh = () => ({
    type: constants.SEARCH_ACCOUNT_DELETION_REQUEST_FRESH,
    promise: (dispatch, getState) => {
      const state = getState()
      const size = selectors.getSize(state)
      return digitalStoreSdk.users
        .searchUsers({
          includeOnlyDeletionRequests: true,
          page: 1,
          size
        })
    }
  })
  searchAccountDeletionRequestNext = () => ({
    type: constants.SEARCH_ACCOUNT_DELETION_REQUEST_NEXT,
    promise: (dispatch, getState) => {
      const state = getState()
      const size = selectors.getSize(state)
      const page = selectors.getPage(state)
      return digitalStoreSdk.users
        .searchUsers({ includeOnlyDeletionRequests: true, page: page + 1, size })
    }
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new AccountDeletionRequestActions()
