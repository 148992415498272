import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'
import Warning from '@material-ui/icons/Warning'
import { Grid, Hidden } from '@material-ui/core'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import Button from '../../../components/Button'
import ButtonsContainer from '../../../components/ButtonsContainer'
import FileBrowserEnhancer from '../../../components/FileBrowserEnhancer'
import IconText from '../../../components/IconText'
import ErrorScreen from '../../../components/ErrorScreen'
import Editable from '../../../components/Editable'
import ReportSummaryModal from '../../ReportManagement/ReportsSearchScreen/NewReportSummaryModal'
import { translations } from '../../../config'

import style from './style'
import NewReportForm from './components/NewReportForm'
import Notification from './components/Notification'
import { useExistingReportErrors } from '../hooks'

const _getTitle = ({ editing, isNewFormMode, reportStatus, formTitle }) => {
  const formType = reportStatus === 'draft' ? 'Draft' : 'Report'
  const isEditing = !!editing ? 'Edit' : 'View'
  if (isNewFormMode) {
    return formTitle || `Standard`
  }
  return translations(`${isEditing} ${formType}`)
}

const _getUploadButton = ({ editing, onLoadExisting, classes, loadReport }) => {
  if (loadReport && editing) {
    return (
      <FileBrowserEnhancer
        accept={['.xml']}
        onChange={onLoadExisting}
        renderContent={({ onClick }) => (
          <Button onClick={onClick} className={classes.headerButton} raised variant='contained'>
            {translations('Load Existing Report')}
          </Button>
        )}
      />
    )
  }
  return null
}

const _getDraftButton = ({
  classes,
  editing,
  hasNewerVersion,
  isNewFormMode,
  reportStatus = 'draft',
  toggleEdit,
  onSaveDraft,
  onUpdateDraft,
  formSchemaId,
  formViewVersionId,
  canSaveDraft,
  isPublicNewFormMode,
  canUpdateReports,
  editable,
  isReadOnlyReport,
  reportId,
  setIsReportSummaryModalOpen,
  isStandalonePage
}) => {
  const isDraft = reportStatus === 'draft'
  let buttonMeta = null
  if (isPublicNewFormMode || isStandalonePage) {
    return null
  }
  if (editing && isDraft) {
    if (isNewFormMode) {
      buttonMeta = {
        action: () => onSaveDraft({ formViewVersionId }),
        text: translations('Save New Draft'),
        disabled: !canSaveDraft
      }
    } else {
      buttonMeta = {
        action: () => onUpdateDraft({ formViewVersionId, formSchemaId }),
        text: translations('Update Draft'),
        disabled: !canSaveDraft
      }
    }
  } else if (editing) {
    buttonMeta = {
      action: () => onSaveDraft({ formViewVersionId, formSchemaId }),
      text: translations('Save Draft'),
      disabled: !canSaveDraft
    }
  }
  if (!editing && !hasNewerVersion && canUpdateReports) {
    buttonMeta = {
      action: toggleEdit,
      text: translations('Update'),
      disabled: !editable
    }
  }

  if (isReadOnlyReport) {
    buttonMeta = {
      action: () => {
        setIsReportSummaryModalOpen(true)
      },
      text: translations('Options')
    }
  }

  if (buttonMeta === null) {
    return null
  }
  return (
    <Button
      onClick={buttonMeta.action}
      className={classes.headerButton}
      disabled={buttonMeta.disabled}
      buttonType='primary'>
      {buttonMeta.text}
    </Button>
  )
}

const _renderWarning = ({ classes }) => {
  return (
    <div className={classes.warningContainer}>
      <IconText iconSize={26} fontSize={16} icon={Warning} text={translations('A newer version of this report is available')} />
    </div>
  )
}

const NewReport = Editable(({
  classes,
  onBackClick,
  reportStatus,
  onLoadExisting,
  onSaveDraft,
  onUpdateDraft,
  editing,
  isNewFormMode,
  isPublicNewFormMode,
  toggleEdit,
  canEdit,
  canSaveDraft,
  isSchemaAvailable,
  organisationId,
  isGuestSubmissionAllowed,
  loadReport,
  getPageTitle,
  hasNewerVersion,
  formTitle,
  formSchema,
  formViewVersionId,
  onSubmit,
  formSchemaId,
  defaultValues,
  formSchemaLibrary,
  formViewDetails,
  initialFieldMap,
  drug,
  product,
  selectedOrganisation,
  canUpdateReports,
  showUpdates,
  formVersion,
  followUpNotificationId,
  attachmentFields,
  editable,
  isReadOnlyReport,
  reportId,
  existingReport,
  isStandalonePage,
  standalonePageTitle
}) => {
  const [isReportSummaryModalOpen, setIsReportSummaryModalOpen] = useState(false)

  useExistingReportErrors(existingReport)

  const buttons = (
    <Fragment>
      {_getUploadButton({ editing, onLoadExisting, classes, loadReport })}
      {_getDraftButton({
        classes, editing, canEdit, isNewFormMode,
        reportStatus, toggleEdit, onSaveDraft,
        onUpdateDraft, formViewVersionId, formSchemaId,
        canSaveDraft, isPublicNewFormMode, hasNewerVersion,
        canUpdateReports, editable, isReadOnlyReport, 
        reportId, setIsReportSummaryModalOpen, isStandalonePage
      })}
    </Fragment>
  )
  const showVersionWarning = hasNewerVersion && showUpdates

  if (!isSchemaAvailable || (isPublicNewFormMode && !isGuestSubmissionAllowed) || _.isNull(formSchema)) {
    return <ErrorScreen title={translations('Report not available')} onClick={onBackClick} buttonText={translations('Go back')} />
  }

  const ReportForm = (
    <NewReportForm
      formSchema={formSchema}
      editing={editing}
      isPublic={isPublicNewFormMode}
      organisationId={_.get(selectedOrganisation, 'id') || organisationId}
      onSubmit={onSubmit}
      formSchemaId={formSchemaId}
      formViewVersionId={formViewVersionId}
      defaultValues={defaultValues}
      initialFieldMap={initialFieldMap}
      formSchemaLibrary={formSchemaLibrary}
      formViewDetails={formViewDetails}
      drug={drug}
      formVersion={formVersion}
      followUpNotificationId={followUpNotificationId}
      attachmentFields={attachmentFields}
    />
  )

  let pageTitle = getPageTitle(_getTitle({ editing, isNewFormMode, reportStatus, formTitle }))
  if (isStandalonePage && standalonePageTitle) {
    pageTitle = standalonePageTitle
  }
  return (
    <div>
      <SubHeader
        leftContent={(
          isPublicNewFormMode || isStandalonePage
            ? null
            : <BackBar onClick={() => onBackClick({ editing })} />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
        rightContent={(
          <Hidden xsDown>
            <ButtonsContainer className={classes.headerRightContent}>
              {buttons}
            </ButtonsContainer>
          </Hidden>
        )}
      />
      <Hidden smUp>
        <ButtonsContainer className={classes.buttonsMobile} align='center'>
          {buttons}
        </ButtonsContainer>
      </Hidden>
      {showVersionWarning && !followUpNotificationId && _renderWarning({ classes })}
      {followUpNotificationId ?
        <Grid container>
          <Grid item xs={12} sm={4} lg={3} className={classes.scrollableGridItem}>
            <Notification notificationId={followUpNotificationId} />
          </Grid>
          <Grid item xs={12} sm={8} lg={9} className={classes.scrollableGridItem}>
            {showVersionWarning && _renderWarning({ classes })}
            {ReportForm}
          </Grid>
        </Grid>
        :
        ReportForm
      }
      <ReportSummaryModal
        isOpen={isReportSummaryModalOpen}
        handleClose={() => setIsReportSummaryModalOpen(false)}
        reportId={reportId}
      />
    </div>
  )
})

NewReport.propTypes = {
  getPageTitle: PropTypes.func.isRequired
}

export default withStyles(style)(NewReport)
