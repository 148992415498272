const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 30
  },
  decisionCard: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    paddingLeft: 20,
    paddingRight: 10
  },
  decisionText: {
    fontSize: 16
  },
  decisionButton: {
    width: '30%',
    height: '40px',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  circleButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 35,
    width: 35,
    borderRadius: '50%',
    border: '1px solid #fff',
    userSelect: 'none',
    transition: 'border-color 0.2s',
    margin: '0 5px 0 5px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      height: 40,
      width: 40
    }
  },
  approve: {
    backgroundColor: 'rgba(82, 182, 64, 1)',
    '&:hover': {
      backgroundColor: 'rgba(82, 182, 64, 0.7)'
    }
  },
  reject: {
    backgroundColor: 'rgba(255, 0, 0, 1)',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.6)'
    }
  },
  expandable: {
    marginTop: '-30px',
    width: '100%',
    overflow: 'hidden',
  },
  textArea: {
    width: '100%',
    resize: 'none',
    border: '0px',
    padding: 5,
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    '&:focus': {
      outline: 'none',
      border: '0px'
    },
  },
  buttonContainer: {
    marginTop: '-10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    width: '60%'
  },
  approveButtonContainer: {
    marginTop: 20,
  }
})

export default theme => styles(theme)