const breakWordStyles = {
  wordBreak: 'break-word',
  hyphens: 'auto'
}

const getTableStyle = (theme) => (cols, xsCols) => {
  return {
    // IE only styles
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    '& > div': {
      [theme.breakpoints.up('sm')]: { width: `${100 / cols}%` },
      [theme.breakpoints.down('xs')]: { width: `${100 / xsCols}%` },
      padding: '4px 8px',
      ...breakWordStyles
    },
    // non-terrible browser styles
    '@supports (display: grid)': {
      display: 'grid',
      gridGap: '8px 16px',
      maxWidth: '100%',
      overflow: 'hidden',
      justifyContent: 'stretch',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: `repeat(${xsCols}, calc(${100 / xsCols}% - 16px))`
      },
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: `repeat(${cols}, calc(${100 / cols}% - 16px))`
      },
      '& > div': {
        width: 'initial !important',
        padding: 0,
        ...breakWordStyles
      }
    }
  }
}

export default theme => ({
  // yes this is bad, but JSS in material-ui doesn't
  // support using props in styles >:(
  'table-6-3': getTableStyle(theme)(6, 3),
  'table-5-3': getTableStyle(theme)(5, 3),
  'table-4-3': getTableStyle(theme)(4, 3),
  header: {
    fontWeight: 'bold'
  },
  row: {
    marginTop: '4px',
    marginBottom: '4px',
    paddingBottom: 4,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    alignItems: 'center'
  }
})
