import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  page: 1,
  size: 30,
  error: undefined,
  canLoadMore: true,
  status: undefined,
  link: undefined,
  results: [],
  filters: {},
  selectedRecordDetails: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.FETCH_EXPORTS_FRESH:
      return reducers.fetchExportFresh(state, action)
    case constants.FETCH_EXPORTS_NEXT:
      return reducers.fetchExportNext(state, action)
    case constants.FETCH_EXPORT:
      return reducers.fetchExportById(state, action)
    case constants.EXPORTS_FILTER:
      return reducers.changeFilter(state, action)
    case constants.EXPORTS_FILTERS:
      return reducers.changeFilters(state, action)
    case constants.FETCH_FILE:
     return reducers.fetchExportFile(state, action)
    case constants.CLEAR_LINK:
      return reducers.clearLink(state, action)
    default:
      return state
  }
}
