import { murFormConsts } from './constants'

export const murFormReducer = (state, action) => {
  switch (action.type) {
    case murFormConsts.CHANGE_SELECTED_ACCOUNT_TYPES:
      const selectedAccountTypes = action.payload.selectedAccountTypes
      return {
        ...state,
        selectedAccountTypes
      }
    case murFormConsts.CHANGE_SELECTED_ORGANISATIONS:
      const updatedSelectedOrganisations = state.selectedOrganisations
      updatedSelectedOrganisations[action.payload.userGroup] = action.payload.organisationId
      return {
        ...state,
        selectedOrganisations: updatedSelectedOrganisations
      }
    case murFormConsts.CLEAR_SELECTED_ORGANISATIONS:
      const clearedSelectedOrganisations = { mfr: null, ukrp: null, authRep: null }
      return {
        ...state,
        selectedOrganisations: clearedSelectedOrganisations
      }
    case murFormConsts.CLEAR_SELECTED_ORGANISATION:
      const currentSelectedOrganisations = state.selectedOrganisations
      currentSelectedOrganisations[action.payload.id] = null
      return {
        ...state,
        selectedOrganisations: currentSelectedOrganisations
      }
  }
}