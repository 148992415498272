import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, FormHelperText, FormLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Checkbox from '../Checkbox'
import styles from './style'

const Checklist = ({
  label,
  onChange,
  options,
  classes,
  meta: { error, touched } = {},
}) => ([
  <FormLabel
    key='label'
    classes={{
      focused: classes.floatingLabelFocusStyle
    }}
  >{label}</FormLabel>,
  <FormGroup key='group'>
    {options.map(option => (
      <Checkbox 
        key={option.label} 
        {...option}
        onChange={onChange} 
      />
    ))}
  </FormGroup>,
  <FormHelperText className={classes.errorStyle} key='helper-text' role='alert'>{touched && error && error}</FormHelperText>
])

Checklist.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({}))
}

export default withStyles(styles)(Checklist)
