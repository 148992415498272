export default theme => ({
  hideXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  actionsColumn: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
})
