import { colors } from '../../../../config/theme'

const style = ({
  container: {
    position: 'relative',
    minHeight: '100%',
    height: '100%'
  },
  tabsContainer: {
    width: 300,
    margin: '20px auto'
  },
  meta: {
    textAlign: 'center'
  }
})

export default theme => style
