import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import {
  selectors as organisationDetailsSelectors,
  actions as organisationDetailsActions
} from '../../../../store/modules/organisationDetails'
import modalService from '../../../../services/modalService'
import FormModal from '../../../../components/FormModal'
import { translations, longDateFormat } from '../../../../config'

import style from './style'

import DrugMetaPanel from './DrugMetaPanel'

const mapStateToProps = (state) => {
  const drugMetaImports = organisationDetailsSelectors.getCurrentOrganisationDrugMetaImports(state)
  const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(state)
  const languageOptions = organisationDetailsSelectors.getCurrentOrganisationLanguagesAsOptions(state)

  return {
    drugMetaImports,
    organisationId,
    languageOptions
  }
}

const enhance = compose(
  withStyles(style),
  connect(mapStateToProps),
  withPropsOnChange(
    ['drugMetaImports'],
    props => {
      const { drugMetaImports, classes } = props
      const data = _.chain(drugMetaImports)
        .orderBy('createdAt', 'desc')
        .map(drugsList => {
          const { name, createdAt, drugCount, details } = drugsList

          const status = _.get(details, 'importStatus')
          const importDate = createdAt ? moment(createdAt).format(longDateFormat) : ''

          let detailsString = ''
          if (status === 'SUCCESS') {
            detailsString = `Product count: ${drugCount}`
          } else if (status === 'FAIL') {
            detailsString = (
              _.get(details, 'error.importerErrorData.message') ||
              _.get(details, 'error.message')
            )
          }

          return [
            ['File Name', name],
            ['Import Date', importDate, { className: classes.hideXs }],
            ['Status', status],
            ['Details', detailsString, { className: classes.hideXs }]
          ].map(datum => {
            const [label, ...rest] = datum
            return [translations(`Drug Meta Panel - ${label}`), ...rest]
          })
        })
        .value()
      return { data, cols: 4, xsCols: 3 }
    }
  ),
  withHandlers({
    showUploadModal: ({ organisationId, dispatch, languageOptions }) => () => {
      const schema = [
        {
          id: 'file',
          field: 'FilePicker',
          props: {
            label: translations('Drug Meta Panel - Upload Modal File Field'),
            name: 'file',
            required: true,
            accept: ['.csv']
          }
        }
      ]
      const actions = [
        {
          text: translations('Upload'),
          success: true,
          primary: true
        }
      ]
      const onSubmit = (data) => {
        const { file } = data || {}
        dispatch(
          organisationDetailsActions.importDrugMeta(
            { file, organisationId, name: _.get(file, 'name') }
          )
        )
        modalService.close()
      }
      modalService.open({
        component: FormModal,
        title: translations('Drug Meta Panel - Upload Modal Title'),
        text: translations('Drug Meta Panel - Upload Modal Text'),
        formId: 'import-drug-meta',
        schema,
        actions,
        onSubmit
      })
    }
  })
)

export default enhance(DrugMetaPanel)
