import { sortStates, tableActions } from './enums'
import * as enums from '../../../store/modules/fileManager/enums'
import fp from 'lodash/fp'
import _ from 'lodash'
import { deriveTranslationFromError } from '../../../config'

export const tableManagementReducer = (state, action) => {
  switch (action.type) {
    case tableActions.CHANGE_SORT_ORDER:
      let nextOrdering = null
      switch (state.sort.order) {
        case sortStates.NONE:
          nextOrdering = sortStates.DESCENDING
          break
        case sortStates.DESCENDING:
          nextOrdering = sortStates.ASCENDING
          break
        case sortStates.ASCENDING:
          nextOrdering = sortStates.NONE
          break
      }
      return {
        ...state,
        sort: {
          ...state.sort,
          order: nextOrdering
        }
      }
    case tableActions.CHANGE_SORT_FIELD:
      const { field } = action.payload
      return {
        ...state,
        sort: {
          ...state.sort,
          order: sortStates.NONE,
          field
        }
      }
    case tableActions.CHANGE_PAGE:
      const { page } = action.payload
      return {
        ...state,
        pages: {
          ...state.pages,
          currentPage: page
        }
      }
    case tableActions.CHANGE_PAGE_SIZE:
      const { size } = action.payload
      return {
        ...state,
        pages: {
          ...state.pages,
          size
        }
      }
    default:
      return { ...state }
  }
}

export const statusReducer = (file) => {
  const statusMessageProps = {
    label: '',
    variant: 'secondary',
    details: '',
    errors: [],
    performingAsync: false,
  }

  const fileStatus = statusSetter(file, file.status, statusMessageProps)
  const attachmentStatus = statusSetter(file, file.attachmentStatus, statusMessageProps)

  return {
    ...fileStatus,
    attachment: attachmentStatus
  }
}

const statusSetter = (file, status, statusMessageProps) => {
  const statusMessage = _.clone(statusMessageProps)
  switch (status) {
    case enums.individualFileStatus.QUEUED:
      statusMessage.variant = 'secondary'
      statusMessage.label = 'Queued'
      statusMessage.performingAsync = false
      return statusMessage
    case enums.individualFileStatus.PENDING:
      statusMessage.variant = 'primary'
      statusMessage.label = 'In progress'
      statusMessage.performingAsync = true
      return statusMessage
    case enums.individualFileStatus.SUCCESS:
      statusMessage.variant = 'success'
      statusMessage.label = 'Success'
      statusMessage.performingAsync = false
      return statusMessage
    case enums.individualFileStatus.FAILURE:
      const error = fp.getOr({}, 'error', file)
      const errors = fp.getOr([], 'error.meta.properties.errors', file)
      statusMessage.variant = 'error'
      statusMessage.label = 'Error'
      statusMessage.performingAsync = false
      statusMessage.details = deriveTranslationFromError(error)
      statusMessage.errors = errors
      return statusMessage
  }
}