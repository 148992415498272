import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import ContentBox from '../../../../../components/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import TextContent from '../../../../../components/Form/TextContent'
import Heading from '../../../../../components/Heading'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { FormControl, Grid } from '@material-ui/core'
import { translations } from '../../../../../config'
import { OrganisationAssociationLookup, Dropdown } from '../../../../../components/Fields'
import * as validators from '../../../../../validators'
import { useSelector } from 'react-redux'
import { getIsUserAdmin, getUserOrganisationsOptions, getIsLoggedIn } from '../../../../../store/modules/auth/selectors'
import { getCurrentPlatformOrganisationsOptions } from '../../../../../store/modules/platforms/selectors'
import { getRoleOptionsForPlatform } from '../../../../../store/modules/roles/selectors'
import RoleSelector from '../RoleSelector'
import { useAbilities } from '../../../../../ability/ability-context'
import { getAvailableRoleNameOptionsForUser } from '../../../../../store/modules/combinedSelectors/authCombinedSelectors'

const OrganisationSelector = ({
  classes,
  multiple,
  isLookup
}) => {
  const isUserAdmin = useSelector(getIsUserAdmin)
  const allOrganisations = useSelector(getCurrentPlatformOrganisationsOptions)
  const userOrganisations = useSelector(getUserOrganisationsOptions)
  const isLoggedIn = useSelector(getIsLoggedIn)
  const organisations = (isUserAdmin || !isLoggedIn) ? allOrganisations : userOrganisations

  const ability = useAbilities()
  const canUpdateRole = ability.can('changeUserRoles', 'userManagement')
  const renderRoles = isUserAdmin || canUpdateRole
  
  const rolesForPlatform = useSelector(getRoleOptionsForPlatform)
  const rolesForUser = useSelector(getAvailableRoleNameOptionsForUser)
  const roles = _.intersectionBy(rolesForPlatform, rolesForUser, 'value')

  const headerText = renderRoles ? 'Organisation & Role' : 'Organisation'

  const getOrganisationComponent = () => {
    if (isLookup) {
      return (
        <OrganisationAssociationLookup
          id='organisationId'
          name='organisationId'
          validate={[validators.required]}
          label={translations('Organisation')}
          getCustomValue={(organisation) => organisation.id}
          multiple={multiple}
        />
      )
    }
    return (
      <Dropdown
        id='organisationId'
        name='organisationId'
        validate={[validators.required]}
        label={translations('Organisation')}
        options={organisations}
        fullWidth
      />
    )
  }
  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading className={classes.contentBoxHeader}>
            {translations(headerText)}
          </Heading>
        </TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              {getOrganisationComponent()}
            </FormControl>
          </Grid>
            {
          renderRoles && (
          <Grid item xs={12} sm={6}>
            <RoleSelector roles={roles} />
          </Grid>
              )
            }
        </Grid>
      </ContentBoxBody>
    </ContentBox>
  )
}

export default OrganisationSelector