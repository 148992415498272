import _ from 'lodash'
import { promiseReducer } from '../../util'
import { SUCCESS } from '../../middleware/redux-promise'

class SourcesReducers {
  fetchSources = promiseReducer(
    (state, action) => ({
      ...state,
      sources: this._normaliseSources(state, action)
    })
  )

  _normaliseSources = (state, action) => {
    let sources = _.cloneDeep(state.sources)
    const status = _.get(action, 'status')
    const organisationId = _.get(action, 'meta.organisationId')

    if (status === SUCCESS) {
      _.set(sources, organisationId, _.get(action, 'result', []))
    }

    return sources
  }
}

export default new SourcesReducers()
