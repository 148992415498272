import _ from 'lodash'
import { translations } from '../../../../config'
import { useDispatch } from 'react-redux'

import MoreMenu from '../../../../components/MoreMenu/MoreMenu'
import AccountDeletionRequestModal from './AccountDeletionRequestModal'
import modalService from '../../../../services/modalService'
import toastService from '../../../../services/toastService'
import { submit } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import { actions as usersActions } from '../../../../store/modules/users'
import { actions as authActions } from '../../../../store/modules/auth'

const MyProfileMoreMenu = (props) => {

  const {
    classes
  } = props

  const dispatch = useDispatch()

  const openAccountDeletionRequestModal = () => {
    const formId = 'AccountDeletionRequestForm'
    return modalService.open({
      component: AccountDeletionRequestModal,
      onSubmit: onSubmitAccountDeletionRequest,
      actions: [
        {
          text: translations('Cancel'),
          onClick: modalService.close
        },
        {
          text: translations('Account Deletion Request - Delete Account'),
          primary: true,
          success: true,
          className: classes.deleteButton,
          onClick: () => {
            dispatch(submit(formId))
          }
        }
      ]
    })
  }

  const onSubmitAccountDeletionRequest = async (data) => {
    const fields = {
      password: data.password
    }
    try {
      const result = await dispatch(usersActions.accountDeletionRequest(fields))
      
      if (result.success) {
        toastService.action({
          type: 'success',
          message: translations('Account Deletion - Successfully sent account deletion request')
        })

        dispatch(authActions.logout({ skipApi: true })) 
      } else {
        toastService.action({
          type: 'error',
          message: translations('Account Deletion - Failed to sent account deletion request')
        })
      }
    } catch (error) {
      toastService.action({
        type: 'error',
        message: error.message
      })
    }
    modalService.close()
  }

  const options = [
    { label: translations('Account Deletion Request - Delete Account'), onClick: openAccountDeletionRequestModal }
  ]

  return (
    <MoreMenu options={options} />
  )
}
export default withStyles(style)(MyProfileMoreMenu)