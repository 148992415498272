import { createSelector } from '@reduxjs/toolkit'
import fp from 'lodash/fp'
import { getEntityWithStatus } from './getEntityWithStatus'

export class GenericSelectors {
    constructor(name) {
        this.name = name
    }
    getById = ({ id }) => (data) => {
        return fp.get([this.name, 'detail', id])(data)
    }
    getDetailById = ({ id }) => data => {
        return createSelector([this.getById({ id })], getEntityWithStatus({ canBeEmpty: false }))(data)
    }
    getMasterFilters = (data) => {
        return fp.get([this.name, 'master', 'filters'])(data)
    }
    getMasterPagination = (data) => {
        return fp.get([this.name, 'master', 'pagination'])(data)
    }
    getMaster = state => {
        return createSelector([fp.get([this.name, 'master'])], getEntityWithStatus({ canBeEmpty: true }))(state)
    }
    getMasterErrorMessage = state => {
        return fp.get([this.name, 'master', 'error'])(state)
    }
    getMasterPageNumber = state => {
        return fp.get([this.name, 'master', 'pagination', 'page'])(state)
    }
    getMasterPageCount = state => {
        return fp.get([this.name, 'master', 'pagination', 'count'])(state)
    }
    getMasterPageTotal = state => {
        return fp.get([this.name, 'master', 'pagination', 'total'])(state)
    }
    getCanLoadMore = createSelector([this.getMasterPageCount, this.getMasterPageTotal], (count, total) => {
        return count < total
    })
}
