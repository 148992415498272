import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '../../Button'

import { translations } from '../../../config'

import styles from './style'

const SelectUserField = ({
  value: userValue,
  label,
  onChange,
  onBlur,
  classes,
  onSearchClick,
  onRemoveUserClick
}) => {
  return (
    <div className={classes.container}>
      <div>
        <div className={classes.label}>{label}</div>
      </div>
      <div className={classes.searchContainer}>
        <div className={classes.userValue}>
          {userValue
            ? `${userValue.firstName} ${userValue.lastName}`
            : 'Please select a user'}
        </div>
        <div className={classes.buttonContainer}>
          {userValue && <DeleteIcon className={classes.deleteIcon} onClick={onRemoveUserClick} />}
          <Button
            raised
            type='button'
            color='primary'
            className={classes.searchButton}
            onClick={onSearchClick}
          >
            {translations('Search')}
          </Button>
        </div>
      </div>
    </div>
  )
}

SelectUserField.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }),
    // can be an empty string if it is removed
    PropTypes.string
  ]),
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onRemoveUserClick: PropTypes.func.isRequired
}

export default withStyles(styles)(SelectUserField)
