import _ from 'lodash'
import { useState } from 'react'
import { Collapse, Divider, IconButton, styled } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import Heading from '../../../../components/Heading'
import Announcement from '../../../../components/Announcement'

import { getCurrentPlatformAnnouncements } from '../../../../store/modules/platforms/selectors'
import { translations } from '../../../../config'

const Announcements = () => {
  const [isOpen, setIsOpen] = useState(true)
  const announcements = useSelector(getCurrentPlatformAnnouncements)

  if (_.isEmpty(announcements)) {
    return null
  }

  return (
    <Container isOpen={isOpen}>
      <Header>
        <Heading variant={'h1'}>{translations('Announcements')}</Heading>
        <IconButton
          onClick={() => setIsOpen(!isOpen)}
          size='medium'
        >
          {isOpen
            ? <ExpandLess style={{ color: 'black' }} fontSize='large' />
            : <ExpandMore style={{ color: 'black' }} fontSize='large' />
          }
        </IconButton>
      </Header>
      <Collapse in={isOpen}>
        <Content>
          {announcements.map(announcement => (
            <Announcement
              key={announcement.id}
              title={announcement.title}
              body={announcement.body}
              createdAt={announcement.createdAt}
            />
          ))}
        </Content>
      </Collapse>
      <Divider />
    </Container>
  )
}

const Container = styled('div')(({ theme, isOpen }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: isOpen ? 20 : 5,
  marginBottom: '-50px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '-30px'
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0px'
  }
}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: 20,
  padding: '0px 69px 0px 69px',
  [theme.breakpoints.down('md')]: {
    padding: '0px 50px 0 50px'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0px 20px 0 20px'
  },
  flexWrap: 'wrap'
}))

const Header = styled(Content)(({ theme }) => ({
  marginTop: 20,
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between'
  },
}))


export default Announcements
