import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import actions from './actions'
import * as authSelectors from '../auth/selectors'

class FormSchemaMiddleware {
  fetchFormSchemaMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToReportConfiguration = matchPath(action.payload.location.pathname, { path: '/report-configuration', exact: true })
      const isRoutingToReportManagement = matchPath(action.payload.location.pathname, { path: '/report-management', exact: true })
      const isRoutingToReportConfigurationQuestions = matchPath(action.payload.location.pathname, { path: '/report-configuration/:id', exact: true })
      const isRoutingToOrganisationDetails = matchPath(action.payload.location.pathname, { path: '/organisations/:id', exact: true })
      const isRoutingToMyOrganisationDetails = matchPath(action.payload.location.pathname, { path: '/my-organisation', exact: true })
      const isRoutingToSelectReport = matchPath(action.payload.location.pathname, { path: '/select-report', exact: true })
      const isRoutingToNewReport = matchPath(action.payload.location.pathname, { path: '/reports/new', exact: true })

      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isLoggedIn && isRoutingToSelectReport) {
        dispatch(actions.resetStore())
      }
      if (
        isLoggedIn &&
        (
          isRoutingToReportConfiguration ||
          isRoutingToReportConfigurationQuestions ||
          isRoutingToReportManagement ||
          isRoutingToOrganisationDetails ||
          isRoutingToNewReport ||
          isRoutingToMyOrganisationDetails
        )
      ) {
        dispatch(actions.fetchAllFormSchemas())
      }
    }
  }
}

export default new FormSchemaMiddleware()