import _ from 'lodash'
import { createSelector } from 'reselect'

import { selectors as organisationDetailsSelectors } from '../organisationDetails'
import { selectors as sourcesSelectors } from '../sources'
import { getPlatformSourceOptions } from '../platforms/selectors'

export const getSourcesForSelectedOrganisation = createSelector([
  organisationDetailsSelectors.getCurrentOrganisationId,
  sourcesSelectors.getAllSources
], (organisationId, sources = {}) => {
  return _.get(sources, organisationId, [])
})

export const getSourcesForSelectedOrganisationAsOptions = createSelector([
  getSourcesForSelectedOrganisation,
  getPlatformSourceOptions
], (sources, platformSourceOptions = []) => {
  const options = _.map(sources, (source) => {
    return {
      label: source.name,
      value: source.id
    }
  })
  const combinedOptions = _.uniqBy([...options, ...platformSourceOptions], 'value')
  return combinedOptions
})
