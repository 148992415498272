import _ from 'lodash'

import React, { useEffect, useState } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../config'

import Form from '../../../../components/Form'
import FormBody from '../../../../components/Form/FormBody'
import FormError from '../../../../components/Form/FormError'

import style from './style'

import store from '../../../../store'
import { selectors as authSelectors } from '../../../../store/modules/auth'

import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import P from '../../../../components/P'

import digitalStoreSdk from '../../../../digitalStoreSdk'

import contactTypes from '../NotificationsMoreMenu/contactTypes'

const formId = 'VigilanceContactsForm'
const ContactForm = Form(formId)

const VigilanceContactsModal = (props) => {

  const [data, setData] = useState(null)

  const {
    classes,
    onSubmit,
    actions
  } = props

  const schema = contactTypes.map((contactType) => {
    return {
        id: contactType.name,
        field: 'Input',
        props: {
          label: translations(`Vigilance Contacts - Type ${contactType.type}`),
          name: contactType.name,
          required: false,
          multiline: true,
        }
      }
  })

  useEffect(async () => {
    const state = store.getState()
    const organisationId = authSelectors.getUserSelectedOrganisationId(state)
    const organisationContacts = await digitalStoreSdk.organisations.fetchOrganisationContacts({organisationId})

    let dt = {}
    organisationContacts.map((contact) => {
      const name = contactTypes.find((type) => { return type.type == contact.type }).name
      dt[name] = contact.emails.toString()
    })

    setData(() => (dt))

  }, [])

  const getLayout = (schema) => {
    const keys = _.map(schema, 'id')
    const layout = _.map(keys, key => `${_.camelCase(key)}:12`)
    return layout
  }

  if (data) {
  return (    
    <BasicModalOverlay
      title={translations('Vigilance Contacts - Header')}
      actions={actions}
    >
      <P value={translations('Vigilance Contacts - Notes')}/>
      <div className={classes.content} >
        <ContactForm onSubmit={onSubmit} initialValues={data}>
          <FormBody>
            <div>
              <FormBody editing schema={schema} layout={getLayout(schema)} />
            </div>
          </FormBody>
          <FormError />
        </ContactForm>
      </div>
    </BasicModalOverlay>
  )
  } else {
    return null
  }
}

export default withStyles(style)(VigilanceContactsModal)