const styles = theme => {
  return ({
    searchInput: {
      width: '50%',
      margin: '10px',
      marginLeft: '15px'
    },
    clickableRow: {
      cursor: 'pointer',
      "&:hover": {
        background: "#efefef"
      },
    }
  })
}

export default styles