import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './style'

const DataTableCell = (props) => {
  const { classes, children, align, nowrap = false, heading, compact, ...rest } = props

  const cellClass = classNames({
    [classes.nowrap]: nowrap,
    [classes.center]: align === 'center',
    [classes.right]: align === 'right',
    [classes.heading]: heading,
    [classes.compact]: compact
  })

  return (
    <TableCell className={cellClass} {...rest}>
      {children}
    </TableCell>
  )
}

DataTableCell.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.any,
  nowrap: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  heading: PropTypes.bool,
  compact: PropTypes.bool
}

export default withStyles(styles)(DataTableCell)
