import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as organisationSelectors } from '../organisationDetails'
import _ from 'lodash'
import moment from 'moment'

class FormViewActions {
  createFormVersion = ({ params }) => ({
    type: constants.CREATE_FORM_VERSION,
    promise: () => {
      return digitalStoreSdk.formView.createFormVersion({ params })
    }
  })

  updateFormVersion = ({ id, viewJSON, schemaLibraryVersion, schemaVersionJsonHash }) => {
    return (
      ({
        type: constants.UPDATE_FORM_VERSION,
        promise: () => {
          return digitalStoreSdk.formView.updateFormVersion({ id, params: { viewJSON, schemaLibraryVersion, schemaVersionJsonHash } })
        }
      }))
  }

  fetchFormViewById = ({ id }) => ({
    type: constants.FETCH_FORMVIEW_BY_ID,
    promise: () => digitalStoreSdk.formView.fetchFormViewById({ id })
  })

  fetchAllFormViews = ({ organisationId, params }) => {
    return ({
      type: constants.FETCH_ALL_FORMVIEWS,
      promise: () => digitalStoreSdk.formView.fetchAllFormViews({ organisationId, params })
    })
  }

  updateFormView = ({ id, params }) => ({
    type: constants.UPDATE_FORMVIEW,
    payload: { id, ...params },
    promise: () => {
      return digitalStoreSdk.formView.updateFormView({ id, params })
    }
  })

  setNewSchemaBase = (values) => ({
    type: constants.NEW_SCHEMA_BASE,
    values
  })

  selectReport = (values) => ({
    type: constants.SELECT_REPORT_TYPE,
    values
  })

  setViewJSON = (values) => ({
    type: constants.SET_VIEW_JSON,
    values
  })

  setNotEditableJSON = (values) => ({
    type: constants.SET_NOT_EDITABLE_JSON,
    values
  })

  clearNotEditableJSON = () => ({
    type: constants.CLEAR_NOT_EDITABLE_JSON
  })

  toggleVisibility = (values) => ({
    type: constants.TOGGLE_VISIBILITY,
    values
  })

  configureField = (values) => ({
    type: constants.CONFIGURE_FIELD,
    values
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })

  resetBaseSchema = () => ({
    type: constants.RESET_BASE_SCHEMA
  })

  moveQuestion = (values) => ({
    type: constants.MOVE_QUESTION,
    values
  })

  addQuestion = (values) => ({
    type: constants.ADD_QUESTION,
    values
  })

  addSection = (values) => ({
    type: constants.ADD_SECTION,
    values
  })

  deleteQuestion = (values) => ({
    type: constants.DELETE_QUESTION,
    values
  })

  deleteSection = (values) => ({
    type: constants.DELETE_SECTION,
    values
  })

  moveQuestionToSection = (values) => ({
    type: constants.MOVE_QUESTION_TO_SECTION,
    values
  })

  editRepeatables = (values) => ({
    type: constants.EDIT_REPEATABLES,
    values
  })
  setFormViewSchemaUpdated = () => ({
    type: constants.SET_FORM_SCHEMA_UPDATED
  })
  setFormViewSchemaUpdateSaved = () => ({
    type: constants.SET_FORM_SCHEMA_UPDATE_SAVED
  })

  generateReportConfigurationTranslationsCSV = ({ formViewId }) => ({
    type: constants.GENERATE_REPORT_CONFIGURATION_TRANSLATIONS_CSV,
    promise: async (dispatch, getState) => {
      const organisationId = organisationSelectors.getCurrentOrganisationId(getState())
      const formView = await digitalStoreSdk.formView.fetchFormViewById({ id: formViewId })
      const reportConfigurationTranslations = await digitalStoreSdk.translations.fetchTranslationsForFormViewById({ formViewId })
      const formViewName = _.get(formView, 'name')
      const today = moment().format('DDMMYY')
      const fileName = `${formViewName}-${today}`
      
      const result = await digitalStoreSdk.translations
        .generateTranslationsCSV({
          organisationId,
          appTranslations: reportConfigurationTranslations,
          fileName
        })

        window.location = _.get(result, 'downloadURL')
        return Promise.resolve()
    }
  })
}

export default new FormViewActions()