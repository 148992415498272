const style = (theme) => ({
  root: {
    padding: 50,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: 15
    }
  }
})

export default style
