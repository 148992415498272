import { history } from '../store'
import qs from 'qs'
import _ from 'lodash'

export const addQuery = (query = {}, method = 'replace') => {
  const currentQuery = qs.parse(_.get(history, 'location.search', {}), { ignoreQueryPrefix: true })

  history[method]({
    search: qs.stringify({ ...currentQuery, ...query }, { encode: false, arrayFormat: 'comma' })
  })
}

export const removeQuery = (queryNames = [], method = 'replace') => {
  const currentQuery = qs.parse(_.get(history, 'location.search', {}), { ignoreQueryPrefix: true })

  history[method]({
    search: qs.stringify(_.omit(currentQuery, queryNames), { encode: false })
  })
}
