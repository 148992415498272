import _ from 'lodash'
import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import LegacyAcknowledgementForm from './LegacyAcknowledgementForm'
import { utilities } from '@redant/mhra-form-schema-library'
import { actions as ackDetailsActions, constants as ackDetailsConstants, selectors as ackDetailsSelectors } from '../../../../../store/modules/ackDetails'
import { selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import { actions as json2xmlActions, selectors as json2xmlSelectors } from '../../../../../store/modules/json2xml'
import { selectors as selectedReceiverSelectors } from '../../../../../store/modules/selectedReceiver'
import { authCombinedSelectors } from '../../../../../store/modules/combinedSelectors'
import modalService from '../../../../../services/modalService'
import { translations } from '../../../../../config'

let previousFormValues = {}

const enhancer = compose(
  connect(state => ({
    ackStatus: ackDetailsSelectors.getAckStatus(state),
    reportDetails: reportDetailsSelectors.getReportFieldsForAck(state),
    ackDetails: ackDetailsSelectors.getDetails(state),
    json2xmlLoading: json2xmlSelectors.getIsLoading(state),
    formValues: getFormValues(ackDetailsConstants.NEW_ACK_FORM_NAME)(state) || {},
    formSchema: selectedReceiverSelectors.getAckSchema(state),
    organisationDetails: authCombinedSelectors.getDetailsForSelectedOrganisation(state)
  }), dispatch => ({
    changeField: (...props) => dispatch(change(...props)),
    submitAck: ({ fields, reportId }) => dispatch(ackDetailsActions.submitAck({ reportId, fields })),
    downloadXml: ({ reportId }) => {
      return dispatch(json2xmlActions.ackJson2xml({ reportId }))
        .catch(() => {
          modalService.continue({
            title: translations('Acknowledgement Json2Xml Fail Modal Title'),
            text: translations('Acknowledgement Json2Xml Fail Modal Text')
          })
        })
    }
  })),
  withHandlers({
    onSubmit: ({ submitAck, reportId }) => (fields) => submitAck({ reportId, fields }),
    onDownloadXml: ({ downloadXml, reportId }) => () => downloadXml({ reportId })
  }),
  withPropsOnChange(['formValues', 'reportDetails', 'ackDetails', 'formSchema', 'organisationDetails'], ({ formValues, changeField, isNewFormMode, reportDetails, ackDetails, formSchema, organisationDetails }) => {
    const parser = utilities.parseDynamicSchema(formValues, previousFormValues, changeField, ackDetailsConstants.NEW_ACK_FORM_NAME, organisationDetails)
    previousFormValues = formValues
    return {
      formSections: _.mapValues(formSchema, (formSection) => ({
        ...formSection,
        schema: parser(formSection.schema)
      })),
      initialValues: isNewFormMode ? reportDetails : ackDetails
    }
  })
)

export default enhancer(LegacyAcknowledgementForm)
