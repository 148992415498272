
const styles = {
  wrapper: {
    display: 'flex'
  },
  fieldContainer: {
    padding: '0px 15px 0 0'
  },
  inputContainer: {
    width: '100%',
    display: 'grid'
  }
}

export default styles
