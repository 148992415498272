import React from 'react'
import { Route } from 'react-router'

import { FollowUpScreen } from './FollowUpScreen'

const routes = [
  <Route key='/ota/follow-up' path='/ota/follow-up' exact component={FollowUpScreen} />
]

export default routes
