import React from 'react'
import { Diagnostics } from '../diagnostics'
import * as Sentry from '@sentry/react'

const diagnosticsSingleton = new Diagnostics(Sentry)
export const DiagnosticsContext = React.createContext(diagnosticsSingleton)



export const DiagnosticsContextProvider = ({ children }) => {
    return <DiagnosticsContext.Provider value={diagnosticsSingleton}>{children}</DiagnosticsContext.Provider>
}