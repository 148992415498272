import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'

import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../../../config'
import { constants as submissionConstants } from '../../../../../store/modules/submissionReports'

import style from './style'
import schema from './schema'

const formId = submissionConstants.MANUALLY_PROCESSED_MODAL
const ManuallyProcessModalForm = Form(formId)

const ManuallyProcessModal = (props) => {
  const {
    handleSubmit,
    dismiss,
    initialValues,
    isPristine,
    classes
  } = props
  const dispatch = useDispatch()
  return (
    <BasicModalOverlay
      title={translations('Case Management - Manually Processed Title')}
      actions={[
        {
          text: translations('Save'),
          primary: true,
          success: true,
          disabled: isPristine,
          onClick: () => {
            dispatch(submit(formId))
          }
        },
        {
          text: translations('Cancel'),
          onClick: dismiss
        }
      ]}
    >
      <ManuallyProcessModalForm onSubmit={handleSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <div className={classes.container}>
            <FormBody
              schema={schema}
              editing
              fullWidthFields
            />
          </div>
        </ContentBoxBody>
      </ManuallyProcessModalForm>
    </BasicModalOverlay>
  )
}

ManuallyProcessModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  schema: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object
}

export default withStyles(style)(ManuallyProcessModal)
