import React, { Children } from 'react'
import { withPropsOnChange, compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import VirtualizedMenuList from './VirtualizedMenuList'

export default compose(
  withStyles(style),
  withPropsOnChange(
    ['children'],
    props => {
      const { children, classes } = props
      const childrenArr = Children.toArray(children)

      const rowRenderer = params => {
        const { index, style } = params
        return (
          <div style={style} className={classes.listItem}>
            {childrenArr[index]}
          </div>
        )
      }

      return { rowRenderer }
    }
  )
)(VirtualizedMenuList)
