import React from 'react'
import { styled } from '@material-ui/core/styles'

export const OptionLabel = styled('p')({
  fontSize: 14
})

export const OptionMeta = styled('p')(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.grey[800],
  marginTop: 3
}))

export const VerticalOption = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))