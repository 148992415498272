import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../../../../components/Button'
import Form, { FormBody } from '../../../../../components/Form'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import styles from '../style'
import { translations } from '../../../../../config'
import { uniqueNameValidator } from '../../helpers'

const AddSectionToForm = Form('AddSectionToForm')

const AddSectionModal = ({
  classes,
  closeModal,
  sectionNames,
  handleSubmit
}) => {
  return (
    <div className={classes.listContainer}>
      <h3>{translations('Add Section Title')}</h3>
      <AddSectionToForm onSubmit={handleSubmit}>
        <ContentBoxBody>
          <FormBody
            schema={[
              {
                id: 'newsectionname',
                field: 'Input',
                props: {
                  name: 'newsectionname',
                  shrink: true,
                  required: true,
                  label: 'Section',
                  validate: [uniqueNameValidator(sectionNames)]
                }
              }
            ]}
            editing
            fullWidthFields
          />
        </ContentBoxBody>
        <div className={classes.buttonContainer}>
          <Button raised buttonType='white' onClick={() => closeModal()}>
            {translations('Cancel')}
          </Button>
          <Button raised buttonType='primary' type='submit'>
            {translations('Confirm')}
          </Button>
        </div>
      </AddSectionToForm>
    </div>
  )
}

AddSectionModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func,
  sectionNames: PropTypes.func,
  handleSubmit: PropTypes.func
}

export default withStyles(styles)(AddSectionModal)
