import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import FullWidthFormControl from '../FullWidthFormControl'

import { actionEnum } from '../../../constants'
import FieldHelp from '../FieldHelp'
import { translations } from '../../../../../config'

const ValueField = (props) => {
  const { actionValue, value, onChange } = props

  return (
    <FullWidthFormControl size='small' title={translations('Value')}>
      <TextField
        placeholder={actionValue === actionEnum.APPEND_TO_FIELD ? translations('Integration Management - Append Value', { interpolation: { prefix: "__", suffix: "__" } }) : translations('Value')}
        value={value}
        onChange={onChange}
        label={translations('Value')}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {actionValue === actionEnum.APPEND_TO_FIELD && (
        <FieldHelp>{translations('Integration Management - Template Helper', { interpolation: { prefix: "__", suffix: "__" } })}</FieldHelp>
      )}
    </FullWidthFormControl>
  )
}

ValueField.propTypes = {
  actionValue: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ValueField