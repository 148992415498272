import React, { useEffect } from 'react'
import SearchInput from '../../../../components/SearchInput'
import { translations } from '../../../../config'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import { useQuery } from '../../../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { actions as reportsActions, selectors as reportsSelectors } from '../../../../store/modules/reports'
import { getHasOnlyLatestReportVersionEnabled } from '../../../../store/modules/platforms/selectors'
import { manufacturerReferenceNumbersReportForm } from '../../helpers'


const ReportsSearchInput = () => {
  const { query: queryQuery } = useQuery()
  const dispatch = useDispatch()

  const selectedFilters = useSelector(reportsSelectors.getFilters)
  const latestVersion = useSelector(getHasOnlyLatestReportVersionEnabled)

  const filters = {
    ...selectedFilters,
    latestVersion: selectedFilters.latestVersion || latestVersion
  }

  useEffect(() => {
    updateFilter(query)
  }, [latestVersion])

  const queryFilter = _.get(filters, 'query')
  const query = queryQuery || queryFilter

  const canSearchManufacturerReferenceNumbers = _.includes(manufacturerReferenceNumbersReportForm, filters.reportForm) 

  const updateFilter = (query) => {
    const newFilters = {
      ...filters,
      query
    }
    dispatch(reportsActions.changeFilters({ filters: newFilters }))
    dispatch(reportsActions.searchReportsFresh({ filters: newFilters }))
  }

  const updateQuery = (e) => {
    const query = e.target.value
    updateFilter(query)
  }
  
  return (
    <SearchInput
      value={query}
      placeholder={canSearchManufacturerReferenceNumbers ? translations('Report Management - Search Field Placeholder with Manufacturer Reference Number') : translations('Report Management - Search Field Placeholder')}
      onChange={updateQuery}
      autoFocus={false}
    />
  )
}

export default withStyles(styles)(ReportsSearchInput)
