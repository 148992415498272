import React from 'react'
import _ from 'lodash'
import style from '../../style'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { FormControl } from '@material-ui/core'

import { Dropdown, Input as EditableInput } from '../../../../../components/EditableFields'
import Input from '../../../../../components/Fields/Input'
import * as validators from '../../../../../validators'
import { translations } from '../../../../../config'
import { useAbilities } from '../../../../../ability/ability-context'
import { userAccountTypeEnum } from '../../../../../containers/Users/constants'

const Role = (props) => {
  const {
    classes,
    editing,
    formattedRoleName,
    handleChange,
    roleOptions,
    isPublic,
    isPublicRole,
    selectedRole,
    isRolesAndOrganisationsEditable,
    isRolesAndOrganisationsViewable,
    selectedAccountType
  } = props
  const ability = useAbilities()
  const canUpdateRole = ability.can('changeUserRoles', 'userManagement')

  if (isPublic) return null
  if (!isRolesAndOrganisationsViewable) return null

  const label = translations('Mandatory Role')
  const isNotEditable = isPublicRole || !isRolesAndOrganisationsEditable || !canUpdateRole || selectedAccountType === userAccountTypeEnum.ASSESSOR

  return (
    <Grid item xs={12} sm={6}>
      <FormControl className={classes.dropDownStandard}>
        {
          isNotEditable ? (
            // have to do this because cant pass in value to
            // EditableInput because value from redux-form
            // overrides it
            editing
              ? <Input
                label={label}
                value={formattedRoleName}
                disabled
              />
              : <EditableInput
                label={label}
                value={formattedRoleName}
              />
          )
            : <Dropdown
              label={label}
              editing={editing}
              name='roleId'
              options={roleOptions}
              displayValue={formattedRoleName}
              validate={[validators.required]}
              classes={{ root: classes.dropDownStandard }}
            />
        }
      </FormControl>
    </Grid>
  )
}

export default withStyles(style)(Role)
