import * as constants from './constants'
import reducers from './reducers'
import { PENDING, FAIL } from '../../middleware/redux-promise'
import { SET_SEARCH_VALUE } from "./constants"

const defaultState = {
  status: undefined,
  createStatus: undefined,
  setStatus: PENDING,
  error: undefined,
  offset: 0,
  searchValue: undefined,
  languages: [],
  auditTrail: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.GENERATE_CSV:
      return reducers.generateTranslationsCSV(state, action)
    case constants.CREATE_TRANSLATION:
      return reducers.createTranslation(state, action)
    case constants.FETCH_TRANSLATIONS_FOR_ORG:
      return reducers.fetchTranslations(state, action)
    case constants.SET_TRANSLATIONS:
      return reducers.setTranslations(state, action)
    case constants.SEARCH_TRANSLATIONS_FOR_ORG:
      return reducers.searchTranslations(state, action)
    case constants.GET_TRANSLATION_DIFFERENCES:
      return reducers.getTranslationDifferences(state, action)
    case constants.SET_OFFSET:
      return reducers.setOffset(state, action)
    case constants.SET_SEARCH_VALUE:
      return reducers.setSearchValue(state, action)
    case constants.FETCH_TRANSLATION_TAGS:
      return reducers.fetchTranslationTags(state, action)
    case constants.FETCH_TRANSLATION_AUDIT_TRAIL_FOR_ORG:
      return reducers.fetchTranslationAuditTrailForOrganisation(state, action)
    case constants.FIND_ORGANISATION_LANGUAGES:
      return reducers.findOrganisationLanguages(state, action)  
    default:
      return state
  }
}
