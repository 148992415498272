import React, { Component, Fragment } from 'react'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { LinearProgress } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { colors } from '../../config/theme'
import Heading from '../../components/Heading'
import Spacing from '../../components/Spacing'
import P from '../../components/P'
import bytesFormatter from '../../formatters/bytesFormatter'

import style from './style'

class Content extends Component {
  shouldComponentUpdate (nextProps) {
    // avoid content disappearing during
    // animation of box hiding
    return !!nextProps.visible
  }

  render () {
    const {
      fileName,
      fileSize,
      bytesLoaded,
      percentageLoaded,
      classes
    } = this.props
    const pc = percentageLoaded.toFixed(1)
    return <Fragment>
      <div className={classes.top}>
        <Heading variant='h3'>{fileName || '?'}</Heading>
      </div>
      <Spacing height={10} />
      <div className={classes.progressContainer}>
        <div
          role='progressbar'
          aria-valuemin='0'
          aria-valuemax='100'
          aria-valuenow={pc}
          aria-valuetext={fileName}
          className={classes.progressBar}
          style={{ width: `${pc}%` }}
        />
      </div>
      <Spacing height={5} />
      <div className={classes.progressText}>
        <P value={`${pc}%`} />
        <P value={bytesFormatter.format(bytesLoaded, fileSize)} />
      </div>
    </Fragment>
  }
}

const MultipartUploadBox = props => {
  const {
    visible,
    classes
  } = props
  return <Paper
    square
    elevation={10}
    className={cx(classes.box, { [classes.hidden]: !visible })}
  >
    <Content {...props} />
  </Paper>
}

export default withStyles(style)(MultipartUploadBox)
