import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import ReportConfigurationScreen from './ReportConfigurationScreen'
import ReportQuestionsScreen from './ReportQuestionsScreen'
import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/report-configuration' path='/report-configuration' exact component={TimedLogoutComponent(ReportConfigurationScreen)} />,
  <PrivateRoute key='/report-configuration/:id' path='/report-configuration/:id' exact component={TimedLogoutComponent(ReportQuestionsScreen)} />,
  <PrivateRoute key='/report-configuration/new/:name' path='/report-configuration/new/:name' exact component={TimedLogoutComponent(ReportQuestionsScreen)} />
]
