import UserGroupLocationPagination from '../../UserGroupsScreen/components/UserGroupLocationPagination'

const PermissionsTab = () => {
  return (
    <UserGroupLocationPagination
      includeUnassigned={true}
      collapsible={true}
    />
  )
}

export default PermissionsTab