import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as organisationSelectors } from '../organisationDetails'
import { defaultTranslations } from '../../../config'

class LanguagesActions {
  fetchLanguages = () => ({
    type: constants.FETCH_LANGUAGES,
    promise: () => {
      return digitalStoreSdk.languages
      .fetchLanguages()
    }
  })
}

export default new LanguagesActions()
