import { createAsyncThunk } from '@reduxjs/toolkit'
import { createStatefulThunk } from '../toolkit-utils'
import fp from 'lodash/fp'
import digitalStoreSdk from '../../../digitalStoreSdk'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import { genericSelectors } from './selectors'
import { MASTER_PAGE_SIZE } from './constants'
import { actions } from '.'

export const getTerminologyArrangements = createStatefulThunk({
    typePrefix: 'terminology/getTerminologyArrangements',
    path: 'arrangements',
    asyncFunction: async (_args, thunkApi) => {
        const state = thunkApi.getState()
        const organisationId = authSelectors.getUserSelectedOrganisationId(state)
        const arrangements = await digitalStoreSdk
            .terminology
            .fetchTerminologyArrangementsForOrganisation({ organisationId })
        return arrangements
    }
})


export const createTerminologyArrangement = createStatefulThunk({
    typePrefix: 'terminology/createArrangement',
    path: 'arrangements',
    asyncFunction: async (args, thunkApi) => {
        const state = thunkApi.getState()
        const organisationId = authSelectors.getUserSelectedOrganisationId(state)
        const payload = fp.set('terminologyArrangement.organisationId', organisationId, args)

        await digitalStoreSdk
            .terminology
            .createNewTerminologyArrangement(payload)

        return digitalStoreSdk
            .terminology
            .fetchTerminologyArrangementsForOrganisation({ organisationId })
    }
})

export const deleteTerminologyArrangement = createStatefulThunk({
    typePrefix: 'terminology/deleteArrangement',
    path: 'arrangements',
    asyncFunction: async args => {
        await digitalStoreSdk
            .terminology
            .deleteTerminologyArrangementById(args)
    }
})

export const getTerminologyArrangementEntries = createStatefulThunk({
    typePrefix: 'terminology/getTerminologyArrangementEntries',
    path: 'master',
    options: {
        isPaginated: true
    },
    asyncFunction: async (_args, thunkApi) => {
        const state = thunkApi.getState()
        const filters = genericSelectors.getMasterFilters(state)
        const page = genericSelectors.getMasterPageNumber(state)
        const terminologyArrangementId = selectors.getActiveArrangementId(state)
        if (!terminologyArrangementId) {
            return []
        }
        return digitalStoreSdk.terminology.fetchTerminologyArrangementEntries({
            terminologyArrangementId,
            filters,
            pagination: {
                page,
                size: MASTER_PAGE_SIZE
            }
        })
    }
})

export const fetchArrangementTerminologyEntryById = createAsyncThunk(
    'terminology/fetchById',
    async ({ entryId }, thunkApi) => {
        try {
            const state = thunkApi.getState()
            const activeArrangementId = selectors.getActiveArrangementId(state)
            const data = await digitalStoreSdk.terminology.fetchArrangementTerminologyEntryById({
                terminologyEntryId: entryId,
                terminologyArrangementId: activeArrangementId
            })
            return thunkApi.fulfillWithValue({ data }, { path: ['detail', entryId] })
        } catch (error) {
            return thunkApi.rejectWithValue({ error }, { path: ['detail', entryId] })
        }
    },
    {
        getPendingMeta: ({ arg }) => {
            return { path: ['detail', arg.entryId] }
        }
    }
)

export const deleteTerminologyEntry = createStatefulThunk({
    typePrefix: 'terminology/deleteTerminologyEntry',
    path: 'master',
    asyncFunction: async (args, thunkApi) => {
        await digitalStoreSdk
            .terminology
            .deleteTerminologyEntryById(args)
        thunkApi.dispatch(actions.resetMasterExcludingFilters())
        thunkApi.dispatch(getTerminologyArrangementEntries())
    }
})
