import React, { Fragment, useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import Option from '../Option'
// import VirtualizedMenuList from '../VirtualizedMenuList'
import /* Paper, */ { paperHeight } from '../Paper'
import Label from '../Label'
import ErrorText from '../ErrorText'
import SubHeader from '../SubHeader'

const SelectDropdown = (props) => {
  const {
    name,
    value,
    label,
    options,
    passedProps,
    multiple,
    classes,
    givenClasses = {},
    renderLabel,
    wrappedOnChange,
    listWidth,
    noErrorTextLabel,
    noFloatingLabel,
    meta,
    shrink,
    required,
    disableAriaLabel,
    disabled,
    staticLabel,
    disableUnderline = false
  } = props

  const { error, touched } = meta || {}
  // Adding className here breaks ie11, so it was removed
  // if you plan on re-adding test ie11!
  const selectClass = classNames(givenClasses.field)
  // const useVirtualized = _.flatten(options).length > 10

  const PaperProps = {}
  PaperProps.style = { maxHeight: paperHeight }

  const MenuProps = { PaperProps }

  const selectInput = <Input
    id={name}
    classes={{
      root: givenClasses.overridingRootClasses
    }}
  />

  const mappedOptions = useMemo(() => {
    const mapGroupOptions = (groupOptions) => {
      return _.map(groupOptions, option => {
        if (!option.isSubheader && !(option.label === 'Please select' && options.length > 1)) {
          return (<Option
            key={option.value}
            {...option}
            dropdownProps={props}
          />)
        }
      })
    }
    let ungroupedIndex
    // Map grouped options first
    const mappedOptions = _.map(options, (groupOptions, i) => {
      const groupHeader = _.find(groupOptions, 'isSubheader')
      if (!groupHeader) {
        ungroupedIndex = i
      }
      if (groupHeader) {
        return [
          <SubHeader
            id={groupHeader.id}
            label={groupHeader.label}
            isSticky={false}
          />,
          ...mapGroupOptions(groupOptions),
          <Option
            divider
            key={`divider-${groupHeader.name}`}
          />
        ]
      }
    })
    // Map those with no group at the end
    mappedOptions.push(...mapGroupOptions(options[ungroupedIndex]))
    return mappedOptions
  }, [options])

  return <Fragment>
    <Label
      label={label}
      noFloatingLabel={noFloatingLabel}
      shrink={shrink || (value && value.length)}
      required={required}
      staticLabel={staticLabel}
    />
    <Select
      key='input-select'
      MenuProps={MenuProps}
      className={selectClass}
      displayEmpty
      error={touched && error}
      input={selectInput}
      disabled={disabled}
      onChange={wrappedOnChange}
      renderValue={renderLabel}
      value={_.isNil(value) ? '' : value}
      multiple={multiple}
      disableUnderline={disableUnderline}
      aria-label={!disableAriaLabel ? label : ''}
      // this should be _.pick instead but idk all the values we need
      {..._.omit(passedProps, ['options', 'givenClasses', 'noFloatingLabel', 'noErrorTextLabel'])}
    >
      {mappedOptions}
    </Select>
    <ErrorText
      meta={meta}
      noErrorTextLabel={noErrorTextLabel}
    />
  </Fragment>
}

SelectDropdown.defaultProps = {
  disableAriaLabel: false,
  required: false
}

SelectDropdown.propTypes = {
  givenClasses: PropTypes.shape({
    overridingRootClasses: PropTypes.string
  }),
  disableAriaLabel: PropTypes.bool,
  required: PropTypes.bool
}

export default withStyles(style)(SelectDropdown)
