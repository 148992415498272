"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.weightConversion = exports.metricWeight = exports.imperialWeight = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var imperialWeight = function imperialWeight(_ref) {
  var values = _ref.values;
  var imperialValue = values.patientweightimperial;
  var metricValue = values.patientweightmetric;
  if (!imperialValue && !metricValue) return;
  if (imperialValue) return imperialValue;
  var stones = parseFloat(metricValue) / 6.35029318;
  var pounds = stones % 1 * 14;
  return {
    Stone: (stones - stones % 1).toFixed(0),
    Pounds: pounds.toFixed(0)
  };
};

exports.imperialWeight = imperialWeight;

var metricWeight = function metricWeight(_ref2) {
  var values = _ref2.values;
  var metricWeight = values.patientweightmetric;
  var imperialWeight = values.patientweightimperial;
  if (!metricWeight && !imperialWeight) return;
  if (metricWeight) return metricWeight;
  var conversion = weightConversion({
    stones: _lodash["default"].get(imperialWeight, 'Stone'),
    pounds: _lodash["default"].get(imperialWeight, 'Pounds')
  });
  return conversion;
};

exports.metricWeight = metricWeight;

var weightConversion = function weightConversion(_ref3) {
  var stones = _ref3.stones,
      pounds = _ref3.pounds;
  var stone = !_lodash["default"].isEmpty(stones) ? parseFloat(stones) : 0;
  var pound = !_lodash["default"].isEmpty(pounds) ? parseFloat(pounds) : 0;
  var convertPounds = pound ? pound / 14 : 0;
  var conversion = ((stone + convertPounds) * 6.35029318).toFixed(0);
  return conversion;
};

exports.weightConversion = weightConversion;