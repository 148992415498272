import _ from 'lodash'
import { createSelector } from 'reselect'

import { PENDING, FAIL } from '../../middleware/redux-promise'

export const getHasFailed = state => _.get(state, 'sources.status') === FAIL
export const getReceivers = state => _.get(state, 'sources.receivers')
export const getIsLoading = state => _.get(state, 'sources.status') === PENDING
export const getSources = state => _.get(state, 'sources.sources')

export const getAllSources = createSelector([
  getSources
], (sources) => {
  return sources
})
