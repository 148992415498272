import _ from 'lodash'
import { connect } from 'react-redux'
import { change, getFormValues, registerField, unregisterField } from 'redux-form'
import { compose, withHandlers, withPropsOnChange, lifecycle } from 'recompose'
import Repeatable from './Repeatable'
import RepeatableForm from '../../RepeatableForm'
import modalService from '../../../services/modalService'
import uuid from 'uuid/v4'

const mapStateToProps = (state, { formName }) => ({
  formValues: getFormValues(formName)(state) || {}
})

const enhancer = compose(
  connect(mapStateToProps),
  withPropsOnChange(['formValues'], props => {
    const { id, formValues, repeatableLabel, repeatableSeparator, label, schema, groupId, sourceInitialValues, maxLength, disableAdd = false } = props
    const repeatableValue = formValues[id]
    const displayValues = groupId ? _.filter(repeatableValue, (value) => value.groupId === groupId) : repeatableValue
    const repeatableSize = _.size(repeatableValue)
    const hitMaxLength = repeatableSize >= maxLength

    return {
      displayValues: _.sortBy(displayValues, ['prepopulated']),
      repeatableLabel,
      repeatableSeparator,
      label,
      schema,
      sourceInitialValues,
      hitMaxLength,
      maxLength,
      disableAdd: hitMaxLength || disableAdd
    }
  }),
  withHandlers({
    onAdd: ({ onSubmit, dispatch, schema, id, formValues, label, formName, modalIndex = 0, formId, initialValues = {}, sourceInitialValues = {}, groupId, repeatableValidate, ...props }) => (e) => {
      const currentFields = formValues[id] ? formValues[id] : []
      modalService.open({
        component: RepeatableForm,
        hideBottomBar: true,
        fullScreen: true,
        inModal: true,
        modalIndex,
        parentFormValues: formValues,
        schema,
        label,
        formId,
        fieldId: id,
        repeatableValidate,
        initialValues: { ...initialValues, ...sourceInitialValues, _parentFormValues: formValues },
        onComplete: ({ values }) => {
          const newValues = {
            id: uuid(),
            groupId,
            ..._.omit(values, ['_parentFormValues'])
          }
          const newFields = [...currentFields, newValues]

          const checkOnSubmit = _.isFunction(onSubmit)
          if (checkOnSubmit) {
            onSubmit(values, dispatch, change, formName, id)
          }

          dispatch(change(formName, id, newFields))

          modalService.close({ modalIndex })
        },
        onClose: () => modalService.close({ modalIndex }),
        onBackClick: () => modalService.close({ modalIndex })
      })
    },

    onEdit: ({ dispatch, schema, id, label, formValues, formName, modalIndex = 0, formId, sourceInitialValues = {}, onSubmit, repeatableDefaultValues, repeatableValidate, ...props }) => (value, index) => {
      const currentFields = formValues[id] ? formValues[id] : []
      const initialValues = _.find(currentFields, field => value.id === field.id)
      const updatedSchema = _.chain(schema)
        .map((field) => {
          if (_.get(field.props, 'onEditReadOnly')) {
            return {
              ...field,
              props: {
                ...field.props,
                passedProps: {
                  ...(field.props.passedProps || {}),
                  readOnly: true,
                  disabled: true
                }
              }
            }
          } else {
            return field
          }
        })
        .map((field) => {
          return {
            ...field,
            props: {
              ...field.props,
              disabled: field.props.disabled || (value.prepopulated && field.props.disabledWhenPrepopulate)
            }
          }
        })
        .value()
      if (repeatableDefaultValues) {
        const labelIndex = _.findIndex(currentFields, (item) => item.id === value.id)
        label = repeatableDefaultValues[labelIndex].title
      }

      modalService.open({
        component: RepeatableForm,
        hideBottomBar: true,
        fullScreen: true,
        inModal: true,
        modalIndex,
        initialValues: { ...initialValues, ...sourceInitialValues, _parentFormValues: formValues },
        parentFormValues: formValues,
        schema: updatedSchema,
        label,
        formId,
        fieldId: id,
        editMode: true,
        repeatableValidate,
        onComplete: ({ values }) => {
          const currentFields = formValues[id] ? formValues[id] : []
          const filteredFields = _.filter(currentFields, (field) => field.id !== value.id)
          const newValues = {
            id: uuid(),
            ..._.omit(values, '_parentFormValues')
          }
          const newFields = [...filteredFields]
          newFields.splice(index, 0, newValues)

          const checkOnSubmit = _.isFunction(onSubmit)
          if (checkOnSubmit) {
            onSubmit(values)
          }

          dispatch(change(formName, id, newFields))
          modalService.close({ modalIndex })
        },
        onClose: () => modalService.close({ modalIndex }),
        onBackClick: () => modalService.close({ modalIndex })
      })
    },

    onDelete: ({ dispatch, schema, id, formValues, formName }) => (value) => {
      const currentFields = formValues[id] ? formValues[id] : []
      const newFields = _.filter(currentFields, (field) => field.id !== value.id)
      dispatch(change(formName, id, newFields))
    },

    onView: ({ dispatch, schema, id, formValues, label, formName, modalIndex = 0, formId, sourceInitialValues }) => (value) => {
      const currentFields = formValues[id] ? formValues[id] : []
      const initialValues = _.find(currentFields, field => value.id === field.id)

      modalService.open({
        component: RepeatableForm,
        hideBottomBar: true,
        fullScreen: true,
        inModal: true,
        initialValues: { ...initialValues, ...sourceInitialValues },
        modalIndex,
        parentFormValues: formValues,
        schema,
        label,
        formId,
        editing: false,
        onClose: () => modalService.close({ modalIndex }),
        onBackClick: () => modalService.close({ modalIndex })
      })
    },
    registerField: ({ formName, id, dispatch, editable }) => () => {
      if (editable) {
        dispatch(registerField(formName, id, 'Field'))
      }
    },
    unregisterField: ({ formName, id, dispatch }) => () => {
      dispatch(unregisterField(formName, id))
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.registerField()
      const getDefaultValues = _.get(this.props, 'repeatableDefaultValues')
      const currentValues = _.get(this.props, `formValues.${this.props.id}`, [])
      if (getDefaultValues) {
        const buildArray = _.map(getDefaultValues, (value, index) => {
          const currentIndexValues = currentValues[index] || []
          return ({
            id: uuid(),
            groupId: this.props.groupId,
            disableDelete: true,
            ...currentIndexValues
          })
        })
        this.props.dispatch(change(this.props.formName, this.props.id, buildArray))
      }
    },
    componentWillUnmount() {
      this.props.unregisterField()
    }
  }),
  withPropsOnChange(['editable'], props => {
    if (props.editable) {
      props.registerField()
    } else {
      props.unregisterField()
    }
  })
)

export default enhancer(Repeatable)
