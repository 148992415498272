import React, { Fragment, useCallback, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Form, { FormSubmit } from '../../../components/Form'
import _ from 'lodash'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'

import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import SelectProductDropdown from './SelectProductDropdown/SelectProductDropdown'
import SelectReportDropdown from './SelectReportDropdown'
import SelectOrganisationDropdown from './SelectOrganisationDropdown'
import { selectReportReducer } from './reducers'
import * as reportSelectActions from './actions'
import { getCurrentPlatformOrganisations } from '../../../store/modules/platforms/selectors'
import { actions as formViewActions } from '../../../store/modules/formViews'
import { push } from 'connected-react-router'
import { useSelector, useDispatch } from 'react-redux'
import { translations } from '../../../config'
import { getAvailableOrganisationsForPlatformOptions } from '../../../store/modules/organisations/selectors'
import { isLoggedIntoWorkspace as isLoggedIntoWorkspaceSelector } from '../../../store/modules/auth/selectors'
import { actions as organisationActions } from '../../../store/modules/organisationDetails'
import { getCurrentOrganisation } from '../../../store/modules/organisationDetails/selectors'

import digitalStoreSdk from '../../../digitalStoreSdk'

const ReportForm = Form('SelectReport')

const SelectReport = ({
  onBackClick,
  pageTitle,
  classes,
  onChange,
  onSubmit,
  forms,
  formOptions
}) => {
  const [state, dispatch] = useReducer(selectReportReducer,
    {
      selectedReport: undefined,
      selectedOrganisation: undefined,
      selectedProduct: undefined,
      productOptions: undefined,
      products: [],
      hasSelectedMirOrFsca: false,
      organisations: useSelector(getCurrentPlatformOrganisations),
      organisationOptions: useSelector(getAvailableOrganisationsForPlatformOptions)
    })

  const reduxDispatch = useDispatch()

  const selectedOrganisation = useSelector(getCurrentOrganisation)
  const isLoggedIntoWorkspace = useSelector(isLoggedIntoWorkspaceSelector)
  const showOrganisationDropdown = isLoggedIntoWorkspace && state.hasSelectedMirOrFsca
  const showProductDropdown = !_.isNil(_.get(state.selectedOrganisation, 'id')) && showOrganisationDropdown

  const selectReport = (selectedReportId) => {
    const selectedReport = _.find(forms, { id: selectedReportId })
    reportSelectActions.selectReport(dispatch)(selectedReport)
  }

  const selectOrganisation = async (selectedOrganisationId) => {
    if (!selectedOrganisationId) {
      return reportSelectActions.selectOrganisation(dispatch)({})
    }
    reduxDispatch(organisationActions.fetchOrganisationById({ id: selectedOrganisationId, allowForWorkspace: true }))
  }

  useEffect(() => {
    reportSelectActions.selectOrganisation(dispatch)(selectedOrganisation)
  }, [selectedOrganisation])

  const selectProduct = (selectedProductId) => {
    const selectedProduct = _.find(state.products, { id: selectedProductId })
    reportSelectActions.selectProduct(dispatch)(selectedProduct)
  }

  const onMenuItemClick = useCallback(async (path = '/reports/new') => {
    if (!state.selectedReport) {
      return
    }

    const selectedReport = _.find(forms, { id: state.selectedReport.id })
    reduxDispatch(formViewActions.selectReport(selectedReport))

    const options = {}

    if (isLoggedIntoWorkspace) {
      let appianProduct = {}
      const product = state.selectedProduct

      if (product) {
        appianProduct = await digitalStoreSdk.appianProducts.fetchProduct({ productId: _.get(product, 'id') })
      }

      options.selectedOrganisation = state.selectedOrganisation
      options.product = appianProduct
    }

    return reduxDispatch(push(path, options))
  }, [forms, isLoggedIntoWorkspace, reduxDispatch, state.selectedReport, state.selectedOrganisation, state.selectedProduct])

  return (
    <div>
      <SubHeader
        leftContent={<BackBar onClick={onBackClick} />}
        centerContent={
          <Fragment>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        }
      />
      <div className={classes.container}>
        {_.isEmpty(formOptions)
          ? <div className={classes.bodyContainer}>No report types found, make sure receiving organisations have been selected in your organisation configuration</div>
          : <ReportForm
            initialValues={{}}
            onChange={onChange}
            onSubmit={onSubmit}
          >
            <SelectReportDropdown
              classes={classes}
              onMenuItemClick={onMenuItemClick}
              selectReport={selectReport}
              selectedReport={state.selectedReport}
              formOptions={formOptions}
              forms={forms}
            />

            {/* {showOrganisationDropdown && */}
            {false &&
              <SelectOrganisationDropdown
                classes={classes}
                organisations={state.organisations}
                options={state.organisationOptions}
                selectedOrganisation={state.selectedOrganisation}
                selectOrganisation={selectOrganisation}
              />
            }

            {showProductDropdown &&
              <SelectProductDropdown
                classes={classes}
                products={state.products}
                options={state.productOptions}
                selectProduct={selectProduct}
                selectedProduct={state.selectedProduct}
                dispatch={dispatch}
              />
            }

            <FormSubmit
              className={classes.footerButton}
              big
              fullWidth
              disabled={!state.selectedReport}
              onClick={() => onMenuItemClick('/reports/new')}
            >
              {translations('Select')}
            </FormSubmit>

          </ReportForm>}
      </div>
    </div>
  )
}

SelectReport.propTypes = {
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(SelectReport)
