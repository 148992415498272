import _ from "lodash"
import React, { useState, Fragment } from "react"
import styled from "styled-components"
import PropTypes from 'prop-types'
import { StyledInput } from "../TextInput"
import Tabs from "../Tab"
import Field from "../Field"
import FieldMeta from '../FieldMeta'

const InputContainer = styled.div`
  display: block;
`

const FieldContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
  position: relative;
  color: ${(props) => props.theme.colors.secondaryText};
  font-size: ${(props) => `${props.theme.type.label.small}px`};
  line-height: 1.25;
  justify-content: space-between;
`
const Input = styled(StyledInput)``

const StyledField = styled(Field)`
width: 100%;
&:focus, &:active, &:focus {
  outline: 0;
  box-shadow: ${({ theme, error }) => error ? `0 0 0 3px ${theme.colors.error}` : `0 0 0 3px ${theme.colors.primary}`};
}
`
const ImperialOptionsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 80%;
`
const switchFormat = (label, setselectedFormat, formatFieldName, change) => {
  setselectedFormat({
    selectedFormat: label,
  })
  change(formatFieldName, label)
}

const updateMetric = ({
  e,
  metricFieldName,
  imperialFieldName,
  setMetricValue,
  setImperialValue,
  change,
  id
}) => {
  const value = e.target.value
  setMetricValue(value)
  setImperialValue(null)
  change(id, value)
  change(metricFieldName, value)
  change(imperialFieldName, null)
}

const updateImperial = ({
  e,
  label,
  imperialFieldName,
  metricFieldName,
  setImperialValue,
  setMetricValue,
  change,
  formValues
}) => {
  const value = e.target.value
  const imperialValue = _.get(formValues, imperialFieldName)
  setImperialValue({ ...imperialValue, [label]: value })
  setMetricValue(null)
  change(imperialFieldName, { ...imperialValue, [label]: value })
  change(metricFieldName, null)
}

const imperialFormat = (format, selectedFormat, initialFormat) => {
  if (format) {
    return format !== _.get(initialFormat, "value") ? true : false
  }
  if (selectedFormat) {
    return selectedFormat !== _.get(initialFormat, "value") ? true : false
  }
}

/**
 * Provide a helpful hint for an input
 */
const ImperialToggle = (props) => {
  const {
    change,
    getState,
    imperialToggle,
    id,
    label,
    required,
    hint,
    meta: { error }
  } = props
  const [selectedFormat, setselectedFormat] = useState(
    _.get(imperialToggle, "initial.value")
  )
  const [imperialValue, setImperialValue] = useState({})
  const [metricValue, setMetricValue] = useState(null)

  const formValues = _.get(getState(), "values")
  const formatFieldName = _.get(imperialToggle, "formatFieldName")
  const metricFieldName = _.get(imperialToggle, "metricFieldName")
  const imperialFieldName = _.get(imperialToggle, "imperialFieldName")
  const format = _.get(formValues, [formatFieldName])
  const metricField = _.get(formValues, [metricFieldName])
  const imperialField = _.get(formValues, [imperialFieldName])
  const tabLabels = _.get(imperialToggle, "tabLabels")
  const imperialOptions = _.get(imperialToggle, "imperialOptions")
  const initialFormat = _.get(imperialToggle, "initial")

  return (
    <StyledField error={error}>
      <FieldMeta name={id} label={label} error={error} hint={hint} required={required} />
      <FieldContainer>
        {imperialFormat(format, selectedFormat, initialFormat) ? (
          <ImperialOptionsContainer>
            {_.map(imperialOptions, (option) => (
              <InputContainer>
                  <Input
                    value={
                      _.get(imperialField, [option]) ||
                      _.get(imperialValue, [option])
                    }
                    onChange={(e) =>
                      updateImperial({
                        e,
                        label: option,
                        imperialFieldName,
                        metricFieldName,
                        setImperialValue,
                        setMetricValue,
                        change,
                        formValues
                      })
                    }
                    placeholder={option}
                  />
              </InputContainer>
            ))}
          </ImperialOptionsContainer>
        ) : (
          <InputContainer>
              <Input
                value={metricField || metricValue}
                onChange={(e) =>
                  updateMetric({
                    e,
                    metricFieldName,
                    imperialFieldName,
                    setMetricValue,
                    setImperialValue,
                    change,
                    id
                  })
                }
                placeholder={_.get(initialFormat, "label")}
              />
          </InputContainer>
        )}
          <Tabs
            tabs={tabLabels}
            customOnChange={(val) =>
              switchFormat(val, setselectedFormat, formatFieldName, change)
            }
            initialTab={format && tabLabels.indexOf(format)}
          />
      </FieldContainer>
    </StyledField>
  )
}

ImperialToggle.propTypes = {
  /** The ImperialToggle */
  imperialToggle: PropTypes.shape({
    tabLabels: PropTypes.array,
    initial: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    formatFieldName: PropTypes.string,
    metricFieldName: PropTypes.string,
    imperialFieldName: PropTypes.string,
    imperialOptions: PropTypes.array,
  }),
  change: PropTypes.func,
  getState: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  hint: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string
  })
}

export default ImperialToggle
