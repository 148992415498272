const escape = (text) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")

const replaceMultiple = (replacements) => {
  const regExpParts = []
  for (const prop in replacements) {
    if (replacements.hasOwnProperty(prop)) {
      regExpParts.push(escape(prop))
    }
  }
  const regExp = new RegExp(regExpParts.join("|"), 'g')
  const replacer = (match) => replacements[match]
  return (text) => text.replace(regExp, replacer)
}

export default replaceMultiple
