import React, { useCallback, useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { translations } from '../../../../../config'
import { useSidebar } from '../../../../../contexts'
import styles from './style'
import { useCurrentOrganisationAndPlatformSourcesOptions } from '../../../../../store/modules/combinedHooks/rcCombinedHooks'
import Dropdown from '../../../../../components/Fields/Dropdown'
import _ from 'lodash'

const VisibilitySection = ({ classes }) => {
  const { dispatch, actions, state } = useSidebar()
  const { visibility, sourcesDisabled } = state
  const { public: publicState, publish: publishedState, sources } = visibility

  const sourceOptions = useCurrentOrganisationAndPlatformSourcesOptions({ rcSupport: true })

  const togglePublic = useCallback((ev, val) => {
    dispatch(actions.togglePublic(val))
  }, [actions])
  const togglePublished = useCallback((ev, val) => {
    dispatch(actions.togglePublished(val))
  }, [actions])
  const updateSources = (event) => {
    dispatch(actions.updateSources(event.target.value))
  }
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={publishedState}
              onChange={togglePublished}
              name='publish'
              color='primary'
              size='small'
            />
          }
          label={translations('Publish')}
          className={classes.checkbox}
        />
        {publishedState &&
          <FormControlLabel
            control={
              <Switch
                checked={publicState}
                onChange={togglePublic}
                name='public'
                color='primary'
                size='small'
              />
            }
            label={translations('Visible to all sources')}
            className={classes.checkbox}
          />}
      </FormGroup>
      {publishedState &&
        <div className={classes.container}>
          <Dropdown
            id={'sources'}
            multiple
            options={sourceOptions}
            label={translations('Sources')}
            value={sources}
            onChange={updateSources}
            disabled={sourcesDisabled || _.isEmpty(sourceOptions)}
            shrink={true}
            givenClasses={classes}
          />
        </div>}
    </>
  )
}

export default withStyles(styles)(VisibilitySection)