import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { translations } from '../../../../config'
import { actions as userGroupActions } from '../../../../store/modules/userGroups'
import { getUserGroupLocations } from '../../../../store/modules/userGroups/selectors'
import { getUserSelectedOrganisationId } from '../../../../store/modules/auth/selectors'
import { useAbilities } from '../../../../ability/ability-context'

import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import AbstractPagination from '../../../../components/AbstractPagination/AbstractPagination'
import toastService from '../../../../services/toastService'

const formId = 'user-group-locations'
const Form = Editable(FormContentBox(formId))

const UserGroupLocationPagination = ({
  includeUnassigned = true,
  collapsible = false,
}) => {
  const dispatch = useDispatch()
  const organisationId = useSelector(getUserSelectedOrganisationId)
  const locations = useSelector(getUserGroupLocations)

  const ability = useAbilities()
  const canEditPermissions = ability.can('editPermissions', 'permissionsEditor')

  const {
    results = {},
    status,
    error
  } = locations

  const {
    assigned,
    unassigned,
    rows,
    count = 0
  } = results

  const { id } = useParams()

  const headers = [
    { label: 'Location', id: 'location', type: 'label', width: '80%' },
    { label: 'Assigned', id: 'assigned', type: 'checkbox', width: '20%' }
  ]

  const fetch = ({ query, limit, offset }) => {
    dispatch(userGroupActions.fetchUserGroupLocations({
      id,
      organisationId,
      includeUnassigned,
      query,
      limit,
      offset
    }))
  }

  const data = useMemo(() => {
    if (assigned && unassigned) {
      return { assigned, unassigned }
    }
    if (rows) {
      return rows
    }
    return null
  }, [assigned, unassigned, rows])

  const update = (params) => {
    const data = params.data || {}
    const {
      assigned = [],
      unassigned = []
    } = data

    dispatch(userGroupActions.updateUserGroupLocations({ id, organisationId, assigned, unassigned }))
      .then(() => {
        toastService.action({
          type: 'success',
          message: translations('User Groups - Update Locations Success'),
          autoHideDuration: 6000
        })
      })
      .catch(error => {
        toastService.action({
          type: 'error',
          message: error,
          autoHideDuration: 6000
        })
      })
  }

  return (
    <AbstractPagination
      id={id}
      editable={canEditPermissions}
      label={translations('User Groups - Location Pagination Header')}
      Form={Form}
      formId={formId}
      fetch={fetch}
      update={update}
      submitDetails={{}}
      data={data}
      dataCount={count}
      headers={headers}
      parseData={parseLocations}
      path={''}
      includeUnassigned={includeUnassigned}
      clickable={false}
      limit={200}
      enablePagination={false}
      enableSearch={false}
      groupOptionsBy={'parentId'}
      collapsible={collapsible}
    />
  )
}

const parseLocation = ({ location, assigned }) => ({
  id: location.id,
  location: location.name,
  assigned,
  parentId: location.parentId
})

const parseLocations = (locations, includeUnassigned) => {
  if (!includeUnassigned) {
    return _.map(locations, location => parseLocation({ location, assigned: true }))
  }
  const assigned = locations.assigned
  const unassigned = locations.unassigned
  const parsedAssigned = _.map(assigned, location => parseLocation({ location, assigned: true }))
  const parsedUnassigned = _.map(unassigned, location => parseLocation({ location, assigned: false }))
  const parsedLocations = [...parsedUnassigned, ...parsedAssigned]
  return parsedLocations
}
export default UserGroupLocationPagination