import { connect } from 'react-redux'
import _ from 'lodash'
import { isSubmitting, isInvalid, submit, reset } from 'redux-form'
import {
  compose,
  withHandlers,
  withPropsOnChange,
  lifecycle
} from 'recompose'

import {
  constants as receiverConstants,
  selectors as receiverSelectors,
  actions as receiverActions
} from '../../../../../store/modules/receivers'

import modalService from '../../../../../services/modalService'
import toastService from '../../../../../services/toastService'
import { translations } from '../../../../../config'
import ReceiverModal from './ReceiverModal'

const mapStateToProps = (state) => {
  return {
    isSubmitting: isSubmitting(receiverConstants.RECEIVER_SELECTOR_FORM)(state),
    isInvalid: isInvalid(receiverConstants.RECEIVER_SELECTOR_FORM)(state),
    receivers: receiverSelectors.getReceiversForDropdown(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllReceivers: () => dispatch(receiverActions.fetchReceivers()),
    reset: () => dispatch(reset(receiverConstants.RECEIVER_SELECTOR_FORM)),
    dispatch
  }
}

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount () {
      const { receivers } = this.props
      if (_.isEmpty(receivers)) {
        this.props.fetchAllReceivers()
      }
    }
  }),
  withHandlers({
    closeModal: () => () => {
      modalService.close({ modalIndex: 1 })
    },
    onSubmit: ({ dispatch }) => () => {
      dispatch(submit(receiverConstants.RECEIVER_SELECTOR_FORM))
    }
  }),
  withHandlers({
    handleSubmit: (props) => async (formData) => {
      try {
        const { onSelect, closeModal } = props
        const { receiverId } = formData

        if (_.isFunction(onSelect)) {
          await onSelect({
            receiverId,
            onClose: closeModal
          })
        }
      } catch (error) {
        console.log(error)
        toastService.action({
          type: 'error',
          message: translations('Generic error')
        })
      }
    }
  }),
  withPropsOnChange(['isSubmitting'], (props) => {
    const { onSubmit, closeModal, isSubmitting, reset } = props
    return {
      actions: [
        {
          success: true,
          text: translations('Confirm'),
          submitting: isSubmitting,
          onClick: onSubmit,
          primary: true
        },
        {
          text: translations('Reset'),
          onClick: reset
        },
        {
          text: translations('Cancel'),
          onClick: closeModal
        }
      ]
    }
  }),
  withPropsOnChange(['receiverId'], (props) => {
    const { receiverId } = props
    return {
      initialValues: {
        receiverId
      }
    }
  }),
  withPropsOnChange(['receivers'], ({ receivers = [] }) => {
    let schema = []

    schema.push({
      id: 'receiverId',
      field: 'Dropdown',
      props: {
        label: translations('Receivers'),
        name: 'receiverId',
        options: receivers,
        shrink: true,
        required: true
      }
    })

    return { schema }
  })
)

export default enhancer(ReceiverModal)
