import moment from 'moment'
import _ from 'lodash'

export const insertIf = (condition, field) => {
  const value = _.isArray(field) ? field : [field]
  return condition ? value : []
}

export const removeEmptySections = (sections) => {
  return _.reduce((sections), (memo, section, sectionKey) => {
    if (!_.isEmpty(section.schema)) {
      memo[sectionKey] = section
    }
    return memo
  }, {})
}

export const isLimitedToYesNoUnkOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '2' },
  { label: 'Unknown', value: '3' }
]

/**
 * Apply default options if required
 * @param {object} payload - Data for the field
 * @returns {object} Payload with defaults
 */
export const withDefaults = (editing) => (payload) => {
  return {
    ...(!editing && {
      visible: true
    }),
    ...payload
  }
}

/**
 * Apply default options if required for a specific field type
 * @param {object} payload - Data for the field
 * @returns {object} Payload with defaults
 */
export const withFieldDefaults = ({ fieldType, editing }) => (payload) => {
  return {
    ...((fieldType === 'QUESTION' && !editing) && {
      isCustom: true
    }),
    ...(fieldType === 'HEADING' && {
      isHeading: true
    }),
    ...(fieldType === 'BLOCK' && {
      isBlock: true
    }),
    ...payload
  }
}

/**
 * Apply changes to field based on the value to isLimitedToYesNoUnk
 * @param {object} payload - Data for the section
 * @returns {object} Payload with conditions
 */
export const isLimitedToYesNoUnk = (payload) => {
  const nextPayload = _.clone(payload)
  const isLimitedToYesNoUnk = _.get(payload, 'isLimitedToYesNoUnk')

  if (isLimitedToYesNoUnk === true) {
    nextPayload.field = 'Dropdown'
    nextPayload.dropdownOptions = isLimitedToYesNoUnkOptions
  } else if (isLimitedToYesNoUnk === false) {
    nextPayload.field = 'Input'
    nextPayload.dropdownOptions = []
    nextPayload.disableDefaultMapping = []
  }

  return nextPayload
}

/**
 * Convert `answerMap` from form data to viewJSON
 * @param {object} payload - Data for the field
 * @returns {object} Payload with `answerMap` modified
 */
export const answerMapOutgoing = (payload) => {
  const nextPayload = _.clone(payload)
  const answerMap = _.get(nextPayload, 'answerMap', {})
  const isLimitedToYesNoUnk = _.get(payload, 'isLimitedToYesNoUnk')

  if (isLimitedToYesNoUnk === true) {
    nextPayload.answerMap = _.reduce(answerMap, (memo, value, key) => {
      const nextKey = _.last(_.split(key, 'value__'))
      if (nextKey) {
        memo[nextKey] = value
      }
      return memo
    }, {})
  } else if (isLimitedToYesNoUnk === false) {
    nextPayload.answerMap = {}
  }

  return nextPayload
}

/**
 * Convert `answerMap` from viewJSON to form values
 * @param {object} payload - Data for the field
 * @returns {object} Payload with `answerMap` in form values format
 */
export const answerMapIncoming = (payload = {}) => {
  const nextPayload = _.clone(payload)
  const answerMap = _.get(nextPayload, 'answerMap', {})

  nextPayload.answerMap = _.reduce(answerMap, (memo, value, key) => {
    const nextKey = `value__${key}`
    memo[nextKey] = value
    return memo
  }, {})

  return nextPayload
}

/**
 * Omit specific props if required
 * @param {object} payload - Data for the field
 * @returns {object} Payload with or without props omitted
 */
export const omitByType = fieldType => payload => {
  switch (fieldType) {
    case 'REPEATABLE':
      return _.omit(payload, 'fields')
    default:
      return payload
  }
}

/**
 * Omit 'dropdownOptions' if all custom ones have been removed
 * so original options can take their place instead of being
 * overwritten by an empty array.
 * @param {object} payload - Data for the field
 * @returns  {object} Payload with or without dropdownOptions omitted
 */
export const omitEmptyDropdownOptions = (payload = {}) => {
  const nextPayload = _.clone(payload)
  const dropdownOptions = _.get(nextPayload, 'dropdownOptions')
  if(dropdownOptions && _.isEmpty(dropdownOptions)) {
    delete nextPayload.dropdownOptions
  }
  return nextPayload
}