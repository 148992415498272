import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange} from 'recompose'
import uuid from 'uuid/v4'
import { translations } from '../../../../config'

import { push } from 'connected-react-router'

import ManagePagesScreen from './ManagePagesScreen'
import withWidth from '@material-ui/core/withWidth'

import { selectors as pagesSelectors } from '../../../../store/modules/pages'
import { selectors as organisationDetailsSelectors } from '../../../../store/modules/organisationDetails'
import { selectors as languagesSelectors } from '../../../../store/modules/languages'

export default compose(
  connect(state => ({
    pages: pagesSelectors.getPages(state),
    organisationId: organisationDetailsSelectors.getCurrentOrganisationId(state),
    languageOptions: languagesSelectors.getLanguagesAsOptions(state),
    loading: pagesSelectors.getIsLoading(state)
  })),
  withHandlers({
    onPageEdit: ({ dispatch, organisationId }) => ({page}) => {
      if(page){
        dispatch(push(`/organisations/${organisationId}/page-management/${page.id}`))
      }
      else {
        dispatch(push(`/organisations/${organisationId}/page-management/${uuid()}`))
      }
    },
    onPageNavigate : ({dispatch}) => ({path}) => {
      dispatch(push(`/pages/${path}`))
    }
  }),
  withPropsOnChange(
    ['pages', 'organisationId'],
    props => {
      const { pages, onPageEdit, languageOptions } = props
      let rows = []
      if (pages) {
        rows = _.map(_.sortBy(pages, 'title'), page => {
          const { id, title, languageId, path } = page
          const onClick = () => onPageEdit({page})
          const data = [
            ['Title', title, { xs: 9, cardDatumWidth: '50%', onClick }],
            ['Language', languageOptions.find(language => language.value === languageId).label, { xs: 3, cardDatumWidth: '50%' }],
          ].map(datum => {
            const [label, value, props] = datum
            return [translations(label), value, props]
          })
          return {
            key: id,
            onClick,
            data
          }
        })
      }
      return { rows }
    }
  ),
  withWidth()
)(ManagePagesScreen)
