import { translations } from '../config'
import * as constants from '../contexts/ReportConfigurationSidebarContext/constants'
export const getLabel = (type) => ({
  devicesReport: translations('REPORT DEVICES'),
  mhraR2Report: translations('REPORT R2'),
  mhraR3Report: translations('REPORT R3'),
  halmedAppReport: translations('REPORT Halmed'),
  larebAppReport: translations('REPORT Lareb'),
  redantReport: translations('REPORT RedAnt'),
  trilogyReport: translations('REPORT Trilogy'),
  yellowcardReport: translations('REPORT Yellowcard'),
  yellowcardR2Report: translations('REPORT R2Yellowcard'),
  webradrAppReport: translations('REPORT Webradr')
})[type] || type

const _R2R3Combined = [
  {
    id: 'useEuRules',
    field: 'Checkbox',
    props: {
      label: 'Use EU validation',
      name: 'useEuRules'
    }
  },
  {
    id: 'mandatoryMedDRA',
    field: 'Checkbox',
    props: {
      label: 'Allow Mandatory MedDRA',
      name: 'mandatoryMedDRA'
    }
  },
  {
    id: 'showNullFlavours',
    field: 'Dropdown',
    props: {
      label: `${translations('New Report Configuration - Null flavours label')}`,
      name: 'showNullFlavours',
      options: [
        {value: true, label: 'Show Null Flavour selector on questions where supported'},
        {value: false, label: 'Hide Null Flavour selector and use default values'  }
      ]
    }
  }
]

export const getAdvancedOptions = (type) => ({
  devicesReport: [
    {
      id: 'isSyringeReport',
      field: 'Checkbox',
      props: {
        label: 'Is a Syringe Report',
        name: 'isSyringeReport'
      }
    },
    {
      id: 'isLateralFlowReport',
      field: 'Checkbox',
      props: {
        label: 'Is a Lateral Flow Report',
        name: 'isLateralFlowReport'
      }
    }
  ],
  mhraR2Report: [
    ..._R2R3Combined
  ],
  mhraR3Report: [
    {
      id: 'aefilogic',
      field: 'Checkbox',
      props: {
        label: 'Is report for Adverse Events Following Immunisation (AEFI)',
        name: 'aefilogic'
      }
    },
    {
      id: 'waiveArtefactValidation',
      field: 'Checkbox',
      props: {
        label: 'Skip internal XML validation',
        name: 'waiveArtefactValidation'
      }
    },
    {
      id: 'artefactFormat',
      field: 'Dropdown',
      props: {
        label: 'Choose delivery format for Reciever',
        name: 'artefactFormat',
        options: [
          { label: 'Send XML Only', value: constants.ARTEFACT_TYPES.XML_ONLY },
          { label: 'Send XML and JSON keeping narrative', value: constants.ARTEFACT_TYPES.XML_JSON_FREE_TEXT_DUPLICATION }
        ]
      }
    },
    ..._R2R3Combined
  ]
})[type] || []
