import P from '../P'
import { translations } from '../../config'
import styles from './style'
import { withStyles } from '@material-ui/core/styles'

const EmptyResultBanner = ({ classes }) => {
    return (<div className={classes.message}>
                <P role='status' value={translations('No results found')} />
            </div>)
}

export default withStyles(styles)(EmptyResultBanner)
