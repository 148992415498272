import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import ButtonLink from '../../ButtonLink'
import styles from './style'

const TableCell = (props) => {
  const { xs, sm, md, lg, children, classes, className, onClick, heading, align } = props
  
  const tableCellClass = classNames({
    [classes.hideForExtraSmall]: xs === 0,
    [classes.hideForSmall]: sm === 0,
    [classes.cell]: true,
    [classes.truncate]: true
  })

  const tableCellContentClass = classNames({
    [classes.truncatePadding]: true,
    [classes.truncate]: _.size(children) > 15,
    [classes.alignCenter]: align === 'center'
  })

  const contentComponent = () => {
    const handleClick = (e) => {
      e.stopPropagation()
      onClick()
    }

    if (!heading && _.isFunction(onClick)) {
      return <ButtonLink onClick={handleClick}>{children}</ButtonLink>
    } else {
      return children
    }
  }

  return (
    <Grid
      className={`${tableCellClass} ${className}`}
      item
      xs={xs} sm={sm} md={md} lg={lg}
      role={(heading) ? 'columnheader' : 'cell'}
      >
      <div className={tableCellContentClass}>
        {contentComponent()}
      </div>
    </Grid>
  )
}

TableCell.defaultProps = {
  children: '',
  heading: false
}

TableCell.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  heading: PropTypes.bool
}

export default withStyles(styles)(TableCell)
