import React from "react"
import modalService from '../../../services/modalService'
import ChangeRequestSummaryModal from "../ChangeRequestSummaryModal"

export const useChangeManagementModal = ({ changeRequestId }) => React.useCallback(() => {
    modalService.open({
        component: ChangeRequestSummaryModal,
        fullScreen: false,
        changeRequestId
    })
}, [changeRequestId])
