import _ from 'lodash'
import React from 'react'
import { useWebsites } from '../contexts'
import Drawer from '../../../components/Drawer'
import { CLOSE } from '../contexts/constants'
import Form, { FormBody, FormSubmit } from '../../../components/Form'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import { translations } from '../../../config'
import toastService from '../../../services/toastService'
import { PAGE_FORM_NAME } from '../../../store/modules/websiteManagement/constants'
import { styled } from '@material-ui/core'
import * as validators from '../../../validators'

const toastError = ({ error }) => toastService.action({
  type: 'error',
  message: `${translations(`Failure - Website Management Page Update`)} - ${error}`
})

const toastSuccess = ({ }) => toastService.action({
  type: 'success',
  message: translations(`Success - Website Management Page Update`)
})

const SubmitContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
}))

const formName = PAGE_FORM_NAME
const PageForm = Form(formName)

const PageOverlay = (props) => {
  const {
    state,
    toggleOverlay,
    updatePage
  } = useWebsites()

  const dispatch = useDispatch()

  const page = state.page || {}
  const isOpen = state.overlayOpen && page && !_.isEmpty(page)
  const isSavingPage = state.isSavingPage

  const {
    id = '',
    title = '',
    content = {},
    schema = []
  } = page

  const handleClose = () => {
    toggleOverlay({ status: CLOSE })
  }

  const handleSubmit = async (values) => {
    const fields = {
      content: values
    }
    try {
      await updatePage({ id, values: fields })
      toastSuccess({})
    } catch (error) {
      toastError({ error })
    }
  }

  return (
    <Drawer
      heading={title}
      open={isOpen}
      anchor={'right'}
      onClose={handleClose}
      width={'60vw'}
    >
      <>
        <PageForm editing initialValues={content} onSubmit={handleSubmit}>
          <FormBody
            schema={parseSchema(schema)}
            layout={getLayout(schema)}
          />
          <SubmitContainer>
            <FormSubmit
              onClick={() => dispatch(submit(formName))}
              disabled={isSavingPage}
            >{translations('Update')}</FormSubmit>
          </SubmitContainer>
        </PageForm>
      </>
    </Drawer>
  )
}

const parseSchema = (schema) => {
  const parsedSchema = []
  for (const field of schema) {
    const parsedField = { ...field }
    const maxLength = _.get(field, 'props.maxLength')
    if(maxLength) {
      parsedField.props.validate = [validators.maxLength(maxLength, 'Field')]
    }
    parsedSchema.push(parsedField)
  }
  return parsedSchema
}

const getLayout = (schema) => {
  const keys = _.map(schema, 'id')
  const layout = _.map(keys, key => `${_.camelCase(key)}:12`)
  return layout
}

export default PageOverlay