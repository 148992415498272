import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Form, { FormBody, FormSubmit, FormError } from '../../../../../components/Form'
import { translations } from '../../../../../config'
import Loader from '../../../../../components/Loader'
import Button from '../../../../../components/Button'
import BottomBar from '../../../../../components/BottomBar'
import Can from '../../../../../components/Can'
import * as validators from '../../../../../validators'
import style from './style'
import _ from 'lodash'
import fp from 'lodash/fp'

const NewsForm = Form('news')
const _getSchema = ({ languageOptions = [], tagsOptions = [], isInternalFeed = false }) => {
  const schemaItems = [
    {
      id: 'title',
      field: 'Input',
      props: {
        label: 'Title',
        required: true,
        shrink: true,
        disabled: !isInternalFeed
      }
    },
    {
      id: 'description',
      field: 'Input',
      props: {
        label: 'Summary',
        required: true,
        multiline: true,
        shrink: true
      }
    },
    {
      id: 'content',
      field: 'Wysiwyg',
      props: {
        label: 'Details',
        required: true,
        shrink: true
      }
    },
    {
      id: 'tags',
      field: 'Dropdown',
      props: {
        label: 'Tags',
        multiple: true,
        disabled: _.isEmpty(tagsOptions) || !tagsOptions,
        options: tagsOptions,
        shrink: true
      }
    },
    {
      id: 'newsFeedId',
      field: 'Dropdown',
      props: {
        label: 'Language',
        required: true,
        options: languageOptions,
        shrink: true
      }
    },
    {
      id: 'documents',
      field: 'Repeatable',
      props: {
        label: 'Attach Documents',
        shrink: true,
        repeatableLabel: ['title'],
        schema: [
          {
            id: 'title',
            field: 'Input',
            props: {
              label: 'Document Name',
              required: true,
              shrink: true
            }
          },
          {
            id: 'url',
            field: 'FilePicker',
            props: {
              label: 'Select File',
              required: true,
              shrink: true
            }
          }
        ]
      }
    },
    {
      id: 'publishedDate',
      field: 'DateTime',
      props: {
        dateLabel: 'Publish Date',
        timeLabel: 'Publish Time',
        endYearOffset: 1,
        shrink: true,
        required: true,
        validate: [validators.dateTimeNotInPast]
      }
    }
  ]

  if (!isInternalFeed) {

    return fp.compose(
      fp.at(['title', 'tags']),
      fp.keyBy('id')
    )(schemaItems)
  }

  return schemaItems
}



const NewsFormWrapper = ({ classes, error, handleSubmit, initialValues, isLoading, isPristine, handleDelete, isSubmitting, isInvalid, languageOptions, tagsOptions, isEditing }) => {
  if (isLoading && isPristine) {
    return (
      <Loader />
    )
  }
  const isInternalFeed = _.get(initialValues, 'newsFeed.isInternalFeed', true)

  let layout = []

  if (isInternalFeed) {
    layout = [
      'title:12',
      'description:12',
      'content:12',
      'tags:6',
      'documents:12',
      'newsFeedId:6',
      'publishedDate:12'
    ]
  } else {
    layout = [
      'title:6',
      'tags:6'
    ]
  }


  return (
    <div>
      <FormError>{error}</FormError>
      <NewsForm editing onSubmit={handleSubmit} initialValues={initialValues} noValidate>
        <FormBody schema={_getSchema({ languageOptions, tagsOptions, isInternalFeed })} layout={layout} />
        <BottomBar>
          <FormSubmit
            containerClass={classes.submitWrapper}
            submitting={isSubmitting}
            invalid={isInvalid}
            noContainer
          >{translations('Submit')}</FormSubmit>
          {
            isEditing
              ? (
                <Can actions='deleteNews'>
                  <Button
                    onClick={handleDelete}
                    disabled={isSubmitting}>{translations('Delete')}</Button>
                </Can>
              )
              : null
          }
        </BottomBar>
      </NewsForm>
    </div>
  )
}

NewsFormWrapper.defaultProps = {
  isLoading: false,
  isSubmitting: false
}

NewsFormWrapper.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isLoading: PropTypes.bool,
  isPristine: PropTypes.bool,
  handleDelete: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isInvalid: PropTypes.bool,
  languageOptions: PropTypes.array,
  tagsOptions: PropTypes.array
}

export default withStyles(style)(NewsFormWrapper)
