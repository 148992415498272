import React from 'react'
import { push } from 'connected-react-router'
import ConfirmationModal from '../../Developer/DeveloperScreen/ApplicationAccessPanel/ConfirmationModal'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { useWebsites } from '../contexts'
import { useDispatch } from 'react-redux'
import BackBar from '../../../components/BackBar'

const WebsiteManagementBackBar = () => {
  const dispatch = useDispatch()
  const {
    state
  } = useWebsites()
  const isEdited = state.isEdited

  // Currently will have to go back to dashboard due to redirect by WebsiteSelectorScreen causing a loop
  const onBackClick = () => {
    if (isEdited) {
      modalService.open({
        component: ConfirmationModal,
        disableBackdropClick: true,
        onConfirmation: (() => dispatch(push('/'))),
        message: translations('Website Management - Unsaved Changes')
      })
    } else {
      dispatch(push('/'))
    }
  }
  return (
    <BackBar onClick={onBackClick} />
  )
}

export default WebsiteManagementBackBar
