import React from 'react'

import Editable from '../Editable'
import { Hidden } from '../../../components/Fields'

export default Editable({
  input: (props) => {
    return (
      <Hidden {...props} />
    )
  },
  display: ({ label, value, format, hideIfEmpty, isEmpty }) => {
    return null
  }
})
