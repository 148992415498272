import * as constants from './constants'
import * as selectors from './selectors'
import * as enums from './enums'
import { selectors as authSelectors } from '../auth'
import digitalStoreSdk from '../../../digitalStoreSdk'
import fp from 'lodash/fp'
import _ from 'lodash'

class FileManagerActions {
  setUploadFile = ({ files }) => async (dispatch, getState) => {

    const state = getState()
    const fileManagerStatus = selectors.getFileManagerStatus(state)
    dispatch({
      type: constants.SET_FILE,
      payload: { files }
    })
    if (fileManagerStatus === enums.fileManagerStatus.UNINTIALISED) {
      await dispatch(this.initialiseFileManager())
    } else {
      await dispatch(this.uploadFiles)
    }

  }
  initialiseFileManager = () => async (dispatch, getState) => {
    try {
      dispatch({ type: constants.INITALISE_FILE_MANAGER_BEGIN })
      await this.uploadFiles(dispatch, getState)
      dispatch({ type: constants.INITALISE_FILE_MANAGER_FINISH })
    } catch (error) {
      dispatch({ type: constants.INITALISE_FILE_MANAGER_ERROR, payload: { error } })
    }
  }
  setUploadFileStatusPending = ({ id }) => {
    return {
      type: constants.FILE_UPLOAD_PENDING,
      payload: { id }
    }
  }
  setUploadFileStatusSettledProgress = ({ id }) => {
    return {
      type: constants.FILE_UPLOAD_SUCCESS,
      payload: { id }
    }
  }
  setUploadFileStatusSettledFailure = ({ id, error }) => {
    return {
      type: constants.FILE_UPLOAD_FAILURE,
      payload: { id, error }
    }
  }
  setFileManagerStatusInProgress = () => {
    return {
      type: constants.FILE_MANAGER_STATUS_IN_PROGRESS
    }
  }
  setFileManagerStatusIdle = () => {
    return {
      type: constants.FILE_MANAGER_STATUS_IDLE
    }
  }
  setFileManagerStatusCancelled = () => async (dispatch, getState) => {
    const status = selectors.getFileManagerStatus(getState())
    const isInProgress = status === enums.fileManagerStatus.IN_PROGRESS
    if (isInProgress) {
      await dispatch({ type: constants.FILE_MANAGER_STATUS_CANCELLED })
    } else {
      await dispatch(this.resetStore())
    }
  }
  setFileManagerStatusCompleted = () => {
    return {
      type: constants.FILE_MANAGER_STATUS_COMPLETED
    }
  }
  setUploadAttachmentsStatusPending = ({ id }) => {
    return {
      type: constants.ATTACHMENTS_UPLOAD_PENDING,
      payload: { id }
    }
  }
  setUploadAttachmentsStatusSuccess = ({ id }) => {
    return {
      type: constants.ATTACHMENTS_UPLOAD_SUCCESS,
      payload: { id }
    }
  }
  setUploadAttachmentsStatusFailure = ({ id }) => {
    return {
      type: constants.ATTACHMENTS_UPLOAD_FAILURE,
      payload: { id }
    }
  }
  resetStore = () => ({
    type: constants.RESET_STORE
  })
  uploadFiles = async (dispatch, getState) => {
    const state = getState()
    const files = selectors.getFiles(state)
    const userId = authSelectors.getActiveUserId(state)
    const organisationId = authSelectors.getUserSelectedOrganisationId(state)
    await dispatch(this.setFileManagerStatusInProgress())
    for (const fileInstance of fp.toArray(files)) {
      const isCancelled = selectors.getIsCancelled(getState())
      if (isCancelled) {
        await dispatch(this.resetStore())
        return
      }
      const { id, file, status, attachments } = fileInstance || {}
      switch (status) {
        case enums.individualFileStatus.QUEUED:
          try {
            await dispatch(this.setUploadFileStatusPending({ id }))
            let uploadResults
            if(!_.isEmpty(attachments)) {
              uploadResults = await digitalStoreSdk.reports.uploadReportAttachments({
                reportJSON: {
                  attachment: attachments
                },
                attachmentFields: [
                  'attachment'
                ]
              })
              await dispatch(this.setUploadAttachmentsStatusPending({ id }))
            }
            await digitalStoreSdk.reports.postReport({ file, userId, attachments: uploadResults, organisationId })
            await dispatch(this.setUploadFileStatusSettledProgress({ id }))
            await dispatch(this.setUploadAttachmentsStatusSuccess({ id }))

          } catch (error) {
            await dispatch(this.setUploadFileStatusSettledFailure({ id, error }))
            await dispatch(this.setUploadAttachmentsStatusFailure({ id }))
          }
          break
        case enums.individualFileStatus.PENDING:
          break
        case enums.individualFileStatus.SUCCESS:
          break
        case enums.individualFileStatus.FAILURE:
          break
        default:
          break
      }
    }
    await dispatch(this.setFileManagerStatusCompleted())
  }
}



export default new FileManagerActions()
