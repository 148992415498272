const styles = theme => ({
    widgetContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexGrow: 1 
    },
    gridWrapper: {
      [theme.breakpoints.down('lg')]: {
        padding: 50
      },
      [theme.breakpoints.up('lg')]: {
        padding: 69
      },
      [theme.breakpoints.down('sm')]: {
        padding: 20
      },
    },
    gridItemContainer: {
      display: 'flex',
      justifyContent: 'center'
    }
  })
  
  export default styles
  