import { colors } from '../../../config/theme'

const style = {
  input: {
    marginBottom: 8
  },
  noMargin: {
    margin: 0
  },
  errorText: { color: colors.error }
}

export default style
