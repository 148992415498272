import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { authCombinedSelectors } from '../../../../../../store/modules/combinedSelectors'
import { getIsReportEditable } from '../../../../../../store/modules/reportDetails/selectors'
import { selectors as reportDetailsSelectors } from '../../../../../../store/modules/reportDetails'

import { actions as reportsActions } from '../../../../../../store/modules/reports'
import modalService from '../../../../../../services/modalService'
import { translations } from '../../../../../../config'

import Section, { SectionTypes } from '../../Section'
import { ActionButton } from '../../ReportSummaryModal'

import ConfirmationModal from "../../../../../../components/ConfirmationModal";

const UpdateActions = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection,
    canDeleteDraft,
    handleClose
  } = props

  const dispatch = useDispatch()
  
  const reportId = useSelector(reportDetailsSelectors.getReportId)
  const notificationId = useSelector(reportDetailsSelectors.getNotificationIdForReport)
  const isReportEditable = useSelector(getIsReportEditable)
  const hasUpdateReportPermissions = useSelector(authCombinedSelectors.getHasUpdateReportsPermissions)
  const canUpdateReports = isReportEditable && hasUpdateReportPermissions
  const isReadOnlyReportWithNotification = useSelector(reportDetailsSelectors.isReadOnlyReportWithNotification)
  const title = canUpdateReports ? translations('Report Summary Modal - view or update report') : translations('Report Summary Modal - view report')

  const updateReport = () => {
    const path = isReadOnlyReportWithNotification 
      ? `/reports/${reportId}/notification/${notificationId}`
      : `/reports/${reportId}` 
    dispatch(push(path))
  }

  const deleteDraftReport = useCallback(() => {
    modalService.open({
      component: ConfirmationModal,
      disableBackdropClick: true,
      title: translations('Delete Draft Confirmation modal title'),
      message: translations('Delete Draft Confirmation modal message'),
      onConfirmation: async () => {
        await dispatch(reportsActions.deleteDraftReport({ reportId }))
        await dispatch(reportsActions.searchReportsRefresh())
        handleClose()
      }
    })
  }, [reportId, handleClose, dispatch])

  const updateReportButton = 
    <ActionButton onClick={updateReport}>
      {canUpdateReports && translations('View/Update Report')}
      {!canUpdateReports && translations('View Report')}
    </ActionButton>

  const deleteDraftReportButton = (
    <ActionButton onClick={deleteDraftReport}>
      {translations('Delete Draft Report Button')}
    </ActionButton>
  )

  const rows = [
    { type: SectionTypes.ACTION, value: updateReportButton },
    ...(canDeleteDraft ? [{ type: SectionTypes.ACTION, value: deleteDraftReportButton }] : [])
  ]

  return (
    <Section
      name={title}
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
    />
  )
}

export default UpdateActions
