import _ from 'lodash'
import SabreUserRegistrationForm from './SabreUserRegistrationForm'

import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { actions as userDetailsActions } from '../../../../store/modules/userDetails'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { selectors as organisationInviteSelectors } from '../../../../store/modules/organisationInvites'

const mapStateToProps = (state, ownProps) => {
  const currentPlatformId = platformSelectors.getCurrentPlatformId(state)
  const organisationInvite = organisationInviteSelectors.getOrganisationInvite(state)
  return {
    currentPlatformId,
    organisationInvite
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ 
      dispatch, 
      isPublic, 
      currentPlatformId, 
      organisationInvite, 
      isOrganisationInvite 
    }) => (data) => {
      const organisationId = _.get(data, 'organisationId')
      const occupation = _.get(data, 'occupation')
      const modifiedData = _.omit(data, ['confirmPassword', 'organisationId', 'occupation'])
      modifiedData.platformId = currentPlatformId
      modifiedData.organisationIds = [organisationId]
      modifiedData.organisationIds = _.compact(modifiedData.organisationIds)
      modifiedData.details = { occupation }
      if (isPublic) {
        modifiedData.isPublic = true
      }
      if (isOrganisationInvite && organisationInvite) {
        modifiedData.organisationInviteId = organisationInvite.id
        modifiedData.organisationIds = [organisationInvite.organisationId]
      }
      if(isPublic) {
        return dispatch(userDetailsActions.registerUser(modifiedData))
      }
      return dispatch(userDetailsActions.createUser(modifiedData))
    }
  })
)(SabreUserRegistrationForm)
