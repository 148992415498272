import React from 'react'
import { useParams } from 'react-router-dom'
import { VISIBILITY, GENERAL, ADVANCED, TIMEFRAMES, REPEATABLE_INSTANCES } from './constants'

import { styled } from '@material-ui/core/styles'

import VisibilitySection from './VisibilitySection'
import GeneralSection from './GeneralSection'
import AdvancedSection from './AdvancedSection'
import CollapsableSection from './CollapsableSection'
import TimeframeSection from './TimeframeSection'

import { useAbilities } from '../../../../ability/ability-context'
import { FormViewContext, useFormViews, useRCEditor } from '../../../../contexts'
import RepeatableInstancesSection from './RepeatableInstancesSection'
import _ from 'lodash'

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))

const ConfigurePanel = () => {
  const { id: formViewId } = useParams()
  const { state: rcEditorState } = useRCEditor()
  const { type: formViewType } = rcEditorState

  const ability = useAbilities()

  const canUpdate = ability.can('update', 'reportConfigurationNext')
  const canConfigure = canUpdate && formViewType === 'REPORT'

  const { getRepeatableFieldOptions: repeatableOptions } = useFormViews()

  return canConfigure ? (
    <Wrapper>
      <CollapsableSection title='Visibility' sectionName={VISIBILITY}>
        <VisibilitySection formViewId={formViewId} />
      </CollapsableSection>
      <CollapsableSection title='General' sectionName={GENERAL}>
        <GeneralSection formViewId={formViewId} />
      </CollapsableSection>
      <CollapsableSection title='Advanced' sectionName={ADVANCED}>
        <AdvancedSection formViewId={formViewId} />
      </CollapsableSection>
      <CollapsableSection title='Follow Ups' sectionName={TIMEFRAMES}>
        <TimeframeSection formViewId={formViewId} />
      </CollapsableSection>
      {
        !_.isEmpty(repeatableOptions) && (
          <CollapsableSection title='Repeatable Instances' sectionName={REPEATABLE_INSTANCES}>
            <RepeatableInstancesSection formViewId={formViewId} repeatableOptions={repeatableOptions} />
          </CollapsableSection>
        )
      }
    </Wrapper>
  ) : (
    <Wrapper>
      <CollapsableSection title='General' sectionName={GENERAL}>
        <GeneralSection formViewId={formViewId} isFollowUp={true} />
      </CollapsableSection>
    </Wrapper>
  )
}

export default ConfigurePanel
