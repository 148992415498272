import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import NotificationsScreen from './NotificationsScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

const routes = []

routes.push(
  <PrivateRoute key='notifications' path='/notifications' exact component={TimedLogoutComponent(NotificationsScreen)} />
)

export default routes
