import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { getReportManagementColumns } from '../../ReportManagement/helpers'
import { useDispatch } from 'react-redux'
import FormContentBox from '../../../components/FormContentBox'
import Editable from '../../../components/Editable'
import { FormBody } from '../../../components/Form'
import { translations } from '../../../config'
import { change } from 'redux-form'
import { actions as platformDetailsActions } from '../../../store/modules/platformDetails'

const formId = 'platformReportManagement'
const PlatformReportManagementForm = Editable(FormContentBox(formId))

const PlatformReportManagement = (props) => {
  const {
    classes,
    editable,
    platformDetails
  } = props

  const id = 'platformReportManagement'
  const dispatch = useDispatch()
  const platformId = _.get(platformDetails, 'id')
  const columnMappings = _.get(platformDetails, 'config.reportManagementColumnMappings')
  const data = useMemo(() => {
    return getReportManagementColumns(columnMappings)
  }, [columnMappings])
  
  const headers = [
    { label: 'Column', id: 'columnName', type: 'label', width: '50%' },
    { label: 'Visible', id: 'isVisible', type: 'checkbox', width: '20%' },
    { label: 'Custom Name', id: 'mappedName', type: 'input', width: '30%' }
  ]
  
  const schema = [
    {
      id: id,
      field: 'TableList',
      props: {
        name: id,
        required: false,
        headers,
        count: _.size(data),
        enablePagination: false,
        defaultRowsPerPage: 25
      }
    }
  ]
  
  useEffect(() => {
    dispatch(change(formId, id, data))
  }, [data])


  const handleSubmit = (params) => {
    const data = params[id]
    const reportManagementColumnMappings = {}
    for (const column of data) {
      const name = column.columnName
      const visible = column.isVisible
      const label = column.mappedName
      reportManagementColumnMappings[name] = {
        visible,
        label
      }
    }
    const updatedPlatformDetails = {
      config: platformDetails.config
    }
    updatedPlatformDetails.config.reportManagementColumnMappings = reportManagementColumnMappings
    return dispatch(platformDetailsActions.updatePlatform({ id: platformId, params: updatedPlatformDetails }))
  }

  const handleCancel = () => {
    dispatch(change(formId, id, data))
  }

  return (
    <PlatformReportManagementForm
      editable={editable}
      boxName={translations('Platform Report Management')}
      formId={formId}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    >
      <FormBody
        schema={schema}
        layout={[`${id}:12`]}
      />
    </PlatformReportManagementForm>
  )

}

export default PlatformReportManagement