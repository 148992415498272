import i18next from 'i18next'

import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import digitalStoreSdk from '../../../digitalStoreSdk'

class DrugDictionaryActions {
  _searchDrugDictionary = (next = false) => ({ query = '', watchListOnly = false, lookupOptions }) => ({
    type: next ? constants.SEARCH_DRUG_DICTIONARY_NEXT : constants.SEARCH_DRUG_DICTIONARY,
    query,
    lookupOptions,
    watchListOnly,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      const size = selectors.getSize(state)
      const languageCode = i18next.language
      const searchParams = {
        query,
        size,
        organisationId,
        ...lookupOptions,
        languageCode,
        // API doesn't like watchListOnly = false
        // because it turns false into a string
        watchListOnly: watchListOnly || null
      }

      if (next) searchParams.page = selectors.getPage(state) + 1

      return digitalStoreSdk.drugs.searchDrugs(searchParams)
    }
  })

  searchDrugDictionary = this._searchDrugDictionary(false)

  searchDrugDictionaryNext = this._searchDrugDictionary(true)

  updateWatching = (payload) => ({
    type: constants.UPDATE_WATCHING,
    payload
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new DrugDictionaryActions()
