import React, { useState } from 'react'
import { Grid, TextareaAutosize } from '@material-ui/core'
import Card from '../../../../../components/Card/Card'
import Button from '../../../../../components/Button'
import { translations } from '../../../../../config'

const RejectionBox = (props) => {
  const {
    classes,
    onReject,
    rejectionMessage,
    setRejectionMessage,
    visible,
    disabled
  } = props

  if(!visible) {
    return(<></>)
  }

  return(
    <Grid container justify='center' alignItems='center' direction='column'>
      <Card className={classes.decisionCard}>
        <TextareaAutosize
          value={rejectionMessage}
          onChange={e => setRejectionMessage(e.target.value)}
          className={classes.textArea}
          rows={6}
          placeholder={translations('Change Request - Rejection Message Placeholder')}
        />
      </Card>
      <Grid container className={classes.buttonContainer} justify='flex-end'>
        <Button className={[classes.decisionButton, classes.reject]} disabled={disabled} onClick={() => onReject(rejectionMessage)}>Reject</Button>
      </Grid>
    </Grid>
  )
}

export default RejectionBox