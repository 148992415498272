import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ConditionsItem from '../ConditionsItem'

import { styled } from '@material-ui/core/styles'
import OperatorDisplay from '../OperatorDisplay'
import { Droppable } from 'react-beautiful-dnd'

const List = styled('ul')(() => ({
  margin: 0,
  padding: 0,
  listStyle: 'none inside'
}))

const Item = styled('li')(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(1),
  padding: 0
}))

/**
 * List of conditions
 */
export const ConditionsList = (props) => {
  const {
    conditions,
    readOnly,
    onEdit,
    onDelete,
    fieldLookup,
    onSelect,
    selectedLookup,
    showCombinator = false,
    useDragAndDrop,
    id
  } = props
  const conditionNodes = _.map(conditions, (entry, index) => {
    const { id } = entry
    const isSelected = _.isFunction(selectedLookup) ? selectedLookup({ conditionId: id }) : false

    return (
      <Item key={id}>
        <ConditionsItem
          entry={entry}
          readOnly={readOnly}
          onEdit={onEdit}
          onDelete={onDelete}
          onSelect={onSelect}
          isSelected={isSelected}
          fieldLookup={fieldLookup}
          useDragAndDrop={useDragAndDrop}
          index={index}
        />
      </Item>
    )
  })

  const nodes = _.map(conditionNodes, (node, index) => {
    if (showCombinator && index < _.size(conditionNodes) - 1) {
      return (
        <>
          {node}
          <OperatorDisplay label='AND' />
        </>
      )
    }
    return node
  })


  if (useDragAndDrop) {
    return (
      <Droppable droppableId={id}>
        {provided =>
          <List
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {nodes}
            {provided.placeholder}
          </List>
        }
      </Droppable>
    )
  }

  return (
    <List>
      {nodes}
    </List>
  )
}

ConditionsList.defaultProps = {
  readOnly: false
}

ConditionsList.propTypes = {
  conditions: PropTypes.array.isRequired,
  readOnly: PropTypes.bool
}

export default ConditionsList
