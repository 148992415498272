export default theme => ({
  button: {
    width: 'auto'
  },
  container: {
    minHeight: `calc(100vh - ${theme.headerHeight}px)`,
    display: 'flex',
    flexDirection: 'column'
  },
  bottomBar: {
    marginTop: 'auto'
  }
})
