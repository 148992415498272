import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Label from '../Label'
import ErrorMessage from '../ErrorMessage'
import Hint from '../Hint'

const StyledFieldMeta = styled.div`
  padding-bottom: 8px;
`

/**
 * Displays common information about an input such as label, error messages and hints
 */
const FieldMeta = (props) => {
  const { name, label, error, hint, required, link, target, disabled } = props

  const linkLabel = link
    ? ( <a target={target} href={link}>{label}</a> )
    : label

  return (
    <StyledFieldMeta>
      <Label name={name} required={required} disabled={disabled}>{linkLabel}</Label>
      { error && <ErrorMessage error={error} /> }
      { hint && <Hint message={hint} /> }
    </StyledFieldMeta>
  )
}

FieldMeta.propTypes = {
  /** Used to associate label and input */
  name: PropTypes.string.isRequired,
  /** User friendly name for the field */
  label: PropTypes.string.isRequired,
  /** Optional URL link for the label */
  link: PropTypes.string,
  /** Field validation message  */
  error: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string
}

FieldMeta.defaultProps = {
  required: false
}

export default FieldMeta
