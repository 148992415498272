import React from 'react'
import { styled, withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import _ from 'lodash'

import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import H2 from '../../../../components/H2'

import { translations } from '../../../../config'

import style from './style'
import Heading from '../../../../components/Heading'

import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

const HealthCheckHeader = props => {
  const {
    classes,
  } = props

  const dispatch = useDispatch()

  const back = () => {
    dispatch(push('/monitoring-and-analytics'))
  }

  return (
    <>
      {/* desktop */}
      <Hidden xsDown>
        <SubHeader
          className={classes.headerWithDropdown}
          leftContent={<BackBar onClick={back} />}
          centerContent={<Heading component={'h1'}>{translations('Health Check')}</Heading>}
        />
      </Hidden>
      {/* mobile */}
      <Hidden smUp>
        <>
          <SubHeader
            leftContent={<BackBar />}
            centerContent={<H2 value={_.toUpper(translations('Health Check'))} />}
          />
        </>
      </Hidden>
    </>
  )
}

export default withStyles(style)(HealthCheckHeader)
