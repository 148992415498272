import React from 'react'
import _ from 'lodash'

import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

import { styled } from '@material-ui/core/styles'

const DragHandleWrapper = styled('div')(({ theme, isBlock }) => ({
  position: 'absolute',
  top: 0,
  left: isBlock ? theme.spacing(1) : 0,
  height: '100%',
  width: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey['200'],
  borderRight: `1px solid ${theme.palette.grey['300']}`,
  cursor: 'grab',
  color: theme.palette.grey['600'],
  transition: 'color .2s ease-in-out, background-color .2s ease-in-out',
  '&:hover, &:active, &:focus': {
    backgroundColor: theme.palette.grey['300'],
    color: theme.palette.grey['900']
  },
  '& svg': {
    pointerEvents: 'none'
  }
}))

const DragHandle = ({ dragHandleProps, isBlock }) => {
  return (
    <DragHandleWrapper isBlock={isBlock} {...dragHandleProps}>
      <DragIndicatorIcon />
    </DragHandleWrapper>
  )
}

export default DragHandle