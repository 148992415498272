import { NETWORK_CONNECTION_ON, NETWORK_CONNECTION_OFF } from './constants'

class NetworkActions {
  networkConnectionOn = (state, action) => {
    return {
      type: NETWORK_CONNECTION_ON
    }
  }
  networkConnectionOff = (state, action) => {
    return {
      type: NETWORK_CONNECTION_OFF
    }
  }
}

export default new NetworkActions()
