import DataTable from '../../../components/DataTable'
import { DataTableRow, DataTableCell } from '../../../components/DataTable'
import { translations } from '../../../config'

const rows = (props) => {
    const { entry } = props
    const { firstName = '', lastName = '', email = '' } = entry
  
    return (
      <DataTableRow>
        <DataTableCell align={'left'}>{`${firstName} ${lastName}`}</DataTableCell>
        <DataTableCell align={'left'}>{email}</DataTableCell>
      </DataTableRow>
    )
  }
  
  const ExportSharedWithTable = ({data}) => {
    return (
        <DataTable
          data={data}
          rowComponent={rows}
          tableId={'shared-with-table'}
          tableHeading={'Shared With'}
          primaryColumnWidth={'40%'}
          headers={[
            { id: 'name', label: translations('Export Management - Name') },
            { id: 'email', label: translations('Export Management - Email') }
          ]}
        />
    )
  
  }

  export default ExportSharedWithTable