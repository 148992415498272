// the point of this is to componentize some common styles
// for limiting the width of screen content and adding vertical margins

// this Container component should probably NOT be inside any
// other component which is not the entire width of the screen

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import style from './style'

const Container = props => {
  const {
    children,
    className,
    classes,

    fullWidth,
    followingList,
    inList,
    withMarginBottom,
    withMarginTop
  } = props
  const containerClass = classNames(
    className,
    {
      [classes.screen]: !fullWidth,
      [classes.followingList]: followingList,
      [classes.inList]: inList,
      [classes.withMarginBottom]: withMarginBottom,
      [classes.withMarginTop]: withMarginTop
    }
  )

  return <div className={containerClass}>{children}</div>
}

export default withStyles(style)(Container)
