import { colors } from '../../../config/theme'

const styles = {
  fieldWrap: {
    marginTop: 16
  },
  fieldEntry: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.mediumGrey}`
  },
  fieldEntryLabel: {
    padding: 15,
    flex: 1
  },
  emptyLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    color: colors.mediumGrey,
    borderBottom: `1px solid ${colors.mediumGrey}`
  },
  emptyField: {
    marginTop: 16,
    display: 'flex',
    padding: `6px 0`
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15
  },
  repeatableInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '25px',
    paddingLeft: '2px'
  },
  repeatableInfo: {
    fontSize: 12,
    margin: '0px 0px 7px 0px',
  }
}

export default theme => styles
