import React from 'react'

import { translations } from '../../../../../config'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import AddLanguageForm from '../AddLanguageForm'

const AddLanguageModal = ({ languages, onSuccess, onSubmit }) => {
  return (
    <BasicModalOverlay title={translations('Manage Translations - Add Language Modal Title')}>
      <AddLanguageForm fullWidth languages={languages} onSuccess={onSuccess} onSubmit={onSubmit} />
    </BasicModalOverlay>
  )
}

export default AddLanguageModal
