import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import fp from 'lodash/fp'
import SelectOrganisationForm from './SelectOrganisationForm'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'

const mapStateToProps = state => ({
  organisations: authSelectors.getUserStandardOrganisationRegionOptions(state),
  organisationId: authSelectors.getUserSelectedOrganisationId(state),
  workspaceId: authSelectors.getWorkspaceId(state),
  canUserSeeWorkspaceOption: authCombinedSelectors.canUserSeeWorkspaceOption(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch }) => ({ organisationRegionId }) => {
      const [ organisationId, regionId, receiverId ] = organisationRegionId.split('_')
      return dispatch(authActions.selectOrganisation({ organisationId, regionId, receiverId }))
    },
    signInToWorkspace: ({ dispatch, workspaceId }) => async () => {
      if (workspaceId) {
        return dispatch(authActions.selectOrganisation({ organisationId: workspaceId }))
      }
      const response = await dispatch(authActions.createWorkspace())
      const organisationId = fp.get(['organisation', 'id'], response)
      return dispatch(authActions.selectOrganisation({ organisationId }))
    }
  })
)(SelectOrganisationForm)
