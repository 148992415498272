import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const IconText = (props) => {
  const {
    classes,
    icon: Icon,
    text,
    fontSize,
    iconSize
  } = props

  return (
    <div className={classes.container}>
      {Icon && <div className={classes.iconContainer} style={{ fontSize: iconSize }}>
        <Icon fontSize={'inherit'} className={classes.icon} />
      </div>}
      <p className={classes.text} style={{ fontSize: fontSize }}>{text}</p>
    </div>
  )
}

IconText.defaultProps = {
  fontSize: 13,
  iconSize: 18
}

IconText.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  icon: PropTypes.oneOfType([ PropTypes.string, PropTypes.func, PropTypes.object ]),
  fontSize: PropTypes.number
}

export default withStyles(style)(IconText)
