import { connect } from 'react-redux'
import { compose } from 'recompose'

import ConfigProvider from './ConfigProvider'
import { selectors as themeSelectors } from '../../store/modules/themes'
import { selectors as translationsSelectors } from '../../store/modules/translations'
import { selectors as platformSelectors } from '../../store/modules/platforms'

const mapStateToProps = state => {
  // To fix translations
  // const isLoading = themeSelectors.getIsLoadingCurrentTheme(state) || translationsSelectors.getIsLoading(state) || !platformSelectors.getCurrentPlatform(state)
  const isLoading = themeSelectors.getIsLoadingCurrentTheme(state) || !platformSelectors.getCurrentPlatform(state)
  const theme = themeSelectors.getCurrentTheme(state)
  const title = platformSelectors.getPlatformName(state)
  const favicon = themeSelectors.getThemeFavicon(state)

  return {
    theme,
    title,
    isLoading,
    favicon
  }
}

export default compose(
  connect(mapStateToProps)
)(ConfigProvider)
