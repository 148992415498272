const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  gridContainer: {
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      padding: 7.5,
      paddingTop: 0
    }
  },
  content: {
    width: '100%',
    marginTop: 30,
    textAlign: 'center',
    fontSize: 19,
    fontWeight: 'bold'
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  lockContainer: {
    width: '50px',
    padding: 0
  },
  cardContainer: {
    width: '100%',
    maxWidth: 400,
    padding: 7.5
  }
})

export default styles
