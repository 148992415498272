import _ from 'lodash'
import { createSelector } from 'reselect'

import { PENDING, SUCCESS } from '../../middleware/redux-promise'


export const invitedUsersState = state => _.get(state, 'invitedUsers')

export const getInvitedUsers = createSelector(
  invitedUsersState,
  (invitedUsers) => _.get(invitedUsers, 'users')
)

export const getIsLoading = createSelector(
  invitedUsersState,
  (invitedUsers) => _.get(invitedUsers, 'status') === PENDING
)

export const getHasBeenSearched = createSelector(
  invitedUsersState,
  (invitedUsers) => _.get(invitedUsers, 'status') === SUCCESS
)

export const getFilters = createSelector(
  invitedUsersState,
  (invitedUsers) => _.get(invitedUsers, 'filters')
)

export const getStatusFilters = createSelector(
  invitedUsersState,
  (invitedUsers) => _.get(invitedUsers, 'filters.statuses', [])
)

export const getQueryFilter = createSelector(
  invitedUsersState,
  (invitedUsers) => _.get(invitedUsers, 'filters.query', '')
)

export const getTotalResults = createSelector(
  invitedUsersState,
  (invitedUsers) => _.get(invitedUsers, 'total', 0)
)

export const getParams = createSelector(
  invitedUsersState,
  (invitedUsers) => {
    const { size, page, filters = {} } = invitedUsers
    return {
      size,
      page,
      ...filters
    }
  }
)

export const getQuery = createSelector(
  invitedUsersState,
  (invitedUsers) => {
    return _.get(invitedUsers, 'filters.query')
  }
)

export const getError = createSelector(
  invitedUsersState,
  (invitedUsers) => _.get(invitedUsers, 'error')
)

export const getHasMore = createSelector(
  invitedUsersState,
  (invitedUsers) => {
    const total = _.get(invitedUsers, 'total', 0)
    const totalResults = _.get(invitedUsers, 'users', [])
    return total > totalResults.length
  }
)
