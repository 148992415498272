import * as constants from './constants'
import reducers from './reducers'

const defaultTemplateState = {
  status: undefined,
  error: undefined,
  entities: {},
  byType: {}
}

const defaultState = {
  templates: defaultTemplateState,
  organisationTemplates: defaultTemplateState,
  systemTemplates: defaultTemplateState
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.GET_EMAIL_TEMPLATES:
    case constants.GET_SMS_TEMPLATES:
    case constants.GET_PUSH_TEMPLATES:
      return reducers.fetchCommunicationTemplates(state, action, 'templates')
    case constants.GET_ORGANISATION_EMAIL_TEMPLATES:
    case constants.GET_ORGANISATION_SMS_TEMPLATES:
    case constants.GET_ORGANISATION_PUSH_TEMPLATES:
      return reducers.fetchCommunicationTemplates(state, action, 'organisationTemplates')
    case constants.GET_SYSTEM_EMAIL_TEMPLATES:
      return reducers.fetchCommunicationTemplates(state, action, 'systemTemplates')
    case constants.GET_SYSTEM_EMAIL_TEMPLATE_BY_ID:
      return reducers.fetchSystemCommunicationTemplate(state, action)
    case constants.GET_ORGANISATION_EMAIL_TEMPLATE_BY_ID:
    case constants.GET_ORGANISATION_SMS_TEMPLATE_BY_ID:
    case constants.GET_ORGANISATION_PUSH_TEMPLATE_BY_ID:
      return reducers.fetchOrganisationCommunicationTemplate(state, action)
    case constants.DELETE_SYSTEM_EMAIL_TEMPLATE:
      return reducers.deleteSystemCommunicationTemplate(state, action)
    case constants.DELETE_ORGANISATION_EMAIL_TEMPLATE:
    case constants.DELETE_ORGANISATION_SMS_TEMPLATE:
    case constants.DELETE_ORGANISATION_PUSH_TEMPLATE:
      return reducers.deleteOrganisationCommunicationTemplate(state, action)
    case constants.UPDATE_DEFAULT_ORGANISATION_EMAIL_TEMPLATES:
    case constants.UPDATE_DEFAULT_ORGANISATION_SMS_TEMPLATES:
    case constants.UPDATE_DEFAULT_ORGANISATION_PUSH_TEMPLATES:
      return reducers.updateDefaultTemplates(state, action)
    default:
      return state
  }
}
