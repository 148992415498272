import _ from 'lodash'
import React, { useState, Fragment, useEffect } from 'react'
import Hidden from '@material-ui/core/Hidden'
import InfiniteScroll from 'react-infinite-scroller'
import { AutoTable } from '../../../../components/Table'
import KeyValueCard from '../../../../components/KeyValueCard'
import NumberOfResultsAnnouncer from '../../../../components/NumberOfResultsAnnouncer'
import { translations } from '../../../../config'
import ReportSummaryModal from '../NewReportSummaryModal'
import { addQuery, removeQuery } from '../../../../helpers/query'
import { useQuery } from '../../../../hooks'

const ReportsSearchTable = (props) => {
  const {
    classes,
    error,
    noResults,
    hasSearched,
    loadMore,
    hasMore,
    results,
    rows: passedRows
  } = props

  const [rows, setRows] = useState([])
  const { reportId } = useQuery()
  const openModal = ({ reportId }) => {
    addQuery({ reportId })
  }
  const handleClose = () => {
    removeQuery(['reportId'])
  }

  useEffect(() => {
    const rows = _.map(passedRows, row => {
      return ({
        ...row,
        onClick: () => openModal({ reportId: row.key })
      })
    })
    setRows(rows)
  }, [passedRows])

  const renderError = () => {
    return <div className={classes.content}>{translations('Sorry, an error has occurred. Please try again.')}</div>
  }

  if (error) {
    return renderError()
  }

  return (
    <Fragment>
      <NumberOfResultsAnnouncer count={rows.length} />
      <InfiniteScroll
        pageStart={0}
        className={classes.container}
        loadMore={loadMore}
        hasMore={hasMore}
        initialLoad={false}
        useWindow
      >
        {
          results &&
          <div className={classes.contentContainer}>
            <Hidden smDown>
              <AutoTable rows={rows} />
            </Hidden>
            <Hidden mdUp>
              {rows.map(row => {
                return <div className={classes.cardContainer} key={row.key}>
                  <KeyValueCard {..._.pick(row, ['onClick', 'data', 'deactivated'])} />
                </div>
              })}
            </Hidden>
          </div>
        }
        {noResults && hasSearched &&
          <div
            className={`${classes.content} ${classes.uppercase}`}
            role='status'>{translations('No results found')}
          </div>
        }
      </InfiniteScroll>
      <ReportSummaryModal
        isOpen={!!reportId}
        handleClose={handleClose}
        reportId={reportId}
      />
    </Fragment>
  )
}

export default (ReportsSearchTable)
