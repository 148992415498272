import _ from 'lodash'
import Form, { FormBody } from '../../../../components/Form'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchPlatformApp, updatePlatformApp } from '../../../../store/modules/pwa/asyncActions'
import { actions, getPlatformWebsites, getSelectedApp } from '../../../../store/modules/pwa'
import { getCurrentPlatformId } from '../../../../store/modules/platformDetails/selectors'
import { subdomain } from '../../../../validators'

export const PLATFORM_APP_FORM_NAME = 'platform-app-form'
const AppForm = Form(PLATFORM_APP_FORM_NAME)

const PlatformAppTable = ({
  id
}) => {
  const dispatch = useDispatch()
  const platformId = useSelector(getCurrentPlatformId)
  const platformApp = useSelector(getSelectedApp)
  const platformWebsites = useSelector(getPlatformWebsites)

  const assignedWebsites = useMemo(() => new Set(_.map(platformApp.websites, 'id')), [platformApp.websites])
  const websites = useMemo(() => _.map(platformWebsites, website => {
    const isAssigned = assignedWebsites.has(website.id)
    const appWebsites = platformApp.websites
    const displayName = isAssigned
      ? _.get(_.find(appWebsites, appWebsite => appWebsite.id === website.id), 'displayName', '')
      : ''
    return ({
      id: website.id,
      name: website.name,
      subdomain: website.subdomain,
      displayName,
      assigned: isAssigned
    })
  }), [assignedWebsites, platformWebsites, platformApp.websites])

  const initialValues = useMemo(() => ({
    ...platformApp,
    websites
  }), [platformApp, websites])

  useEffect(() => {
    dispatch(fetchPlatformApp({ id }))
    return (() => {
      dispatch(actions.resetSelectedApp())
    })
  }, [id])

  const onSubmit = useCallback((values) => {
    const valuesWithoutWebsites = _.omit(values, 'websites')
    const initialValuesWithoutWebsites = _.omit(initialValues, 'websites')

    const appDifferences = getAppValuesDiff(valuesWithoutWebsites, initialValuesWithoutWebsites)

    const valuesWebsites = values.websites
    const websiteDifferences = _.differenceBy(valuesWebsites, websites)

    const assigned = _.map(
      _.filter(websiteDifferences, website => website.assigned && !assignedWebsites.has(website.id)),
      website => ({ id: website.id, displayName: website.displayName })
    )

    const unassigned = _.map(
      _.filter(websiteDifferences, website => !website.assigned && assignedWebsites.has(website.id)),
      website => (website.id)
    )

    const updated = _.map(
      _.filter(websiteDifferences, website => website.assigned && assignedWebsites.has(website.id)),
      website => ({ id: website.id, displayName: website.displayName })
    )

    dispatch(updatePlatformApp({
      id: platformApp.id,
      platformId,
      ...appDifferences,
      websiteDifferences: {
        assigned,
        unassigned,
        updated
      }
    }))
  }, [initialValues])

  return (
    <AppForm
      enableReinitialize={true}
      keepDirtyOnReinitialize={false}
      initialValues={initialValues}
      editing={true}
      onSubmit={onSubmit}
    >
      <FormBody
        editing
        layout={['name:6', 'subdomain:6', 'websites:12']}
        schema={schema} />
    </AppForm>
  )
}

const getAppValuesDiff = (values, initialValues) => {
  const difference = {}
  _.forEach(values, (value, key) => {
    if (initialValues[key] !== value) {
      difference[key] = value
    }
  })
  return difference
}

const schema = [
  {
    id: 'name',
    field: 'Input',
    props: {
      label: 'Name',
      required: true
    }
  },
  {
    id: 'subdomain',
    field: 'Input',
    props: {
      validate: [subdomain],
      label: 'Subdomain',
      required: true
    }
  },
  {
    id: 'websites',
    field: 'TableList',
    props: {
      shrink: true,
      required: false,
      headers: [
        { label: 'App', id: 'name', type: 'label', width: '50%' },
        { label: 'Subdomain', id: 'subdomain', type: 'label', width: '20%' },
        { label: 'Display Name', id: 'displayName', type: 'input', width: '20%', align: 'center', enableOn: 'assigned' },
        { label: 'Assigned', id: 'assigned', type: 'checkbox', width: '10%', align: 'center' },
      ],
      enableSearch: false,
      enablePagination: false
    }
  },
]

export default PlatformAppTable