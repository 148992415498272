import { useDispatch } from 'react-redux'
import { actions as fileManagerActions } from '../../store/modules/fileManager'

export const useInitialiseFileManager = () => {
    const dispatch = useDispatch()
    dispatch(fileManagerActions.initialiseFileManager())
}

export const useClearFileManager = () => {
    const dispatch = useDispatch()
    return () => {
        dispatch(fileManagerActions.setFileManagerStatusCancelled())
    }
}