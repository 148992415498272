import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import { assets, translations } from '../../../config'
import MainLogo from '../../../components/MainLogo'
import Heading from '../../../components/Heading'
import VisuallyHidden from '../../../components/VisuallyHidden'
import ResetPasswordForm from '../ResetPasswordFrom'

class ResetPasswordScreen extends Component {
  state = {
    submitted: false
  }

  onSubmissionComplete = () => {
    this.setState({ submitted: true })
  }

  render = () => {
    const { classes, pageTitle } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <MainLogo />
          <VisuallyHidden>
            <h1>{pageTitle.title}</h1>
          </VisuallyHidden>
          <Helmet>
            <title>{pageTitle.titleWithName}</title>
          </Helmet>
          {this.state.submitted
          ? <div>
            <div className={classes.checkContainer}>
              <img className={classes.logo} src={assets.checkcircle} alt={'Success'} />
            </div>
            <div className={classes.header}>
              <Heading>{translations('Password Reset')}</Heading>
            </div>
          </div>
          : <div>
            <ResetPasswordForm onSubmissionComplete={this.onSubmissionComplete} />
          </div>
        }
        </div>
      </div>
    )
  }
}

ResetPasswordScreen.propTypes = {
  onSubmissionComplete: PropTypes.func,
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(style)(ResetPasswordScreen)
