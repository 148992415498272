import _ from 'lodash'
import fp from 'lodash/fp'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class InvitedUsersReducer {
  searchInvitedUsersFresh = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchInvitedUsersFreshPending(state, action)
      case SUCCESS:
        return this.searchInvitedUsersFreshSuccess(state, action)
      case FAIL:
        return this.searchInvitedUsersFreshFail(state, action)
      default:
        return state
    }
  }

  searchInvitedUsersNext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchInvitedUsersNextPending(state, action)
      case SUCCESS:
        return this.searchInvitedUsersNextSuccess(state, action)
      case FAIL:
        return this.searchInvitedUsersNextFail(state, action)
      default:
        return state
    }
  }

  searchInvitedUsersFreshPending = (state, action) => {
    const changes = fp.compose(
      fp.omitBy(fp.isUndefined),
      fp.pick([
        'filters',
        'query'
      ])
    )(action)
    return {
      ...state,
      ...changes,
      status: PENDING,
      action: action.type
    }
  }

  searchInvitedUsersFreshSuccess = (state, action) => {
    const { count: total, rows = [] } = action.result
    return {
      ...state,
      total,
      page: 1,
      users: rows,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  searchInvitedUsersFreshFail = (state, action) => {
    return {
      ...state,
      error: action.error.message,
      status: FAIL,
      action: undefined
    }
  }

  searchInvitedUsersNextPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  searchInvitedUsersNextSuccess = (state, action) => {
    const { total, results, statuses } = action.result
    const { statuses: currentStatuses, users: currentUsers, page } = state
    if (
      statuses === currentStatuses
    ) {
      return {
        ...state,
        total,
        page: page + 1,
        users: [...currentUsers, ...results],
        status: SUCCESS,
        action: undefined,
        error: undefined
      }
    } else {
      return state
    }
  }

  searchUserNextFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  updateStatusFilters = (state, action) => {
    const { payload: { statuses = [] } = {} } = action
    return {
      ...state,
      filters: {
        ...state.filters,
        statuses
      }
    }
  }

  updateQueryFilter = (state, action) => {
    const { payload: { query = [] } = {} } = action
    return {
      ...state,
      filters: {
        ...state.filters,
        query
      }
    }
  }
}

export default new InvitedUsersReducer()
