import _ from 'lodash'

class LinkService {
  open = ({ link }) => {
    if (window.cordova) {
      const open = _.get(window, 'cordova.InAppBrowser.open', _.noop)
      open(link, '_system')
    } else {
      window.open(link, '_blank')
    }
  }
}

export default new LinkService()
