import React from 'react'

import { translations } from '../../../../../config'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import CreateNewKeyForm from '../CreateNewKeyForm'

const CreateKeyModal = ({ onCreate }) => {
  return (
    <BasicModalOverlay title={translations('Manage Translations - Create Key Modal Title')}>
      <CreateNewKeyForm onSubmit={({ key }) => onCreate(key)} />
    </BasicModalOverlay>
  )
}

export default CreateKeyModal
