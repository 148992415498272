const style = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
    zIndex: '1',
    flex: 1
  },
  text: {
    marginRight: 10
  }
}

export default style
