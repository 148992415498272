import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  sources: {},
  status: undefined,
  error: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_SOURCES:
      return reducers.fetchSources(state, action)
    default:
      return state
  }
}
