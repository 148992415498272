import * as constants from './constants'
import reducers from './reducers'
import moment from 'moment'

const defaultState = {
  size: 30,
  page: 1,
  error: undefined,
  query: undefined,
  total: undefined,
  status: undefined,
  results: [],
  filters: {
    dateFrom: moment().subtract(1, 'week').format('MM/DD/YYYY'),
    noSummary: true
  }
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SEARCH_REPORTS_FRESH:
      return reducers.searchReportsFresh(state, action)
    case constants.SEARCH_REPORTS_NEXT:
      return reducers.searchReportsNext(state, action)
    case constants.CHANGE_REPORTS_FILTER:
      return reducers.changeFilter(state, action)
    case constants.CHANGE_REPORTS_FILTERS:
      return reducers.changeFilters(state, action)
    case constants.FETCH_REPORTS_BY_EXT_ID:
      return reducers.fetchReportsByExtId(state, action)
    case constants.FETCH_REPORT_FOR_ID:
      return state
    default:
      return state
  }
}

export default reducer
