"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.conditionsMatchEvent = void 0;
var conditionsMatchEvent = {
  type: 'matchedConditions',
  params: {
    data: 'Report satisfies conditions'
  }
};
exports.conditionsMatchEvent = conditionsMatchEvent;