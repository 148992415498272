const style = theme => ({
  bar: {
    height: theme.headerHeight,
    backgroundColor: theme.header.backgroundColor,
    display: 'flex',
    flexDirection: 'row'
  },
  right: {
    justifyContent: 'flex-end'
  },
  left: {
    justifyContent: 'flex-start'
  }
})

export default style
