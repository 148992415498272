import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

const FollowUpTimeframe = ({ followUpTimeframe: { value, type } = {}, classes, children }) => {
  const _mapType = (type) => {
    switch (type) {
      case 'DAYS':
        return 'days'
      case 'WEEKS':
        return 'weeks'
      case 'MONTHS':
        return 'months'
      case 'YEARS':
        return 'years'
    }
  }
  return (
    <div className={classes.timeframe}>
      <div className={classes.timeframeHeader}>
        <Typography variant='subtitle2' component='h2'>
          {`After ${value} ${_mapType(type)}`}
        </Typography>
      </div>
      <div className={classes.list}>
        {children}
      </div>
    </div>
  )
}

export default withStyles(styles)(FollowUpTimeframe)