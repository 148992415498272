import { useEffect, useState, useLayoutEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

/**
 * Use the shallowEqual function from React-Redux as the default equalityFn argument
 * https://react-redux.js.org/api/hooks#recipe-useshallowequalselector
 * @param {function} selector - Redux state selector
 */
export const useShallowEqualSelector = (selector) => {
  return useSelector(selector, shallowEqual)
}

/**
 * Dispatch an action to fetch data when component has mounted
 * @param {function} action - Redux action
 */
export const useInitialFetch = (action) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(action())
  }, [])
}

/**
 * Get and parse the query string parameters
 */
export const useQuery = () => {
  const { search } = useLocation()
  return qs.parse(search, { ignoreQueryPrefix: true, comma: true })
}


/**
 * Check if input type is compatible with the current browser
 */
export const useInputType = (type) => {
  const [isBrowserCompatible, setIsBrowserCompatible] = useState(false)

  useEffect(() => {
    let input = document.createElement("input")
    input.setAttribute("type", type)
    setIsBrowserCompatible(input.type == type)
    return () => input = null
  })

  return isBrowserCompatible
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
