const styles = theme => {
  return {
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    selectToggle: {
      borderRight: `1px solid ${theme.palette.grey['300']}`,
      marginLeft: 1
    },
    checkboxContainer: {
      marginRight: 5
    },
    checkbox: {
      color: theme.palette.grey['900'],
      height: 38,
      width: 38
    },
    checkboxSelected: {
      color: theme.palette.grey['900']
    },
    action: {
      display: 'flex'
    },
    actionTooltip: {
      marginBottom: -5
    },
    button: {
      color: theme.palette.grey['900']
    },
    buttonDisabled: {
      color: theme.palette.grey['400']
    }
  }
}

export default styles
