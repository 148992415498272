export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 20,
    maxWidth: 1000,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      padding: 30
    }
  }
})
