import { createStatefulThunk } from '../toolkit-utils'
import { CREATE_WEBSITE, FETCH_WEBSITE, FETCH_WEBSITES } from './constants'
import { selectors as authSelectors } from '../auth'

import digitalStoreSdk from '../../../digitalStoreSdk'

export const fetchWebsites = createStatefulThunk({
  typePrefix: FETCH_WEBSITES,
  path: 'websites',
  options: {
    isPaginated: false
  },
  asyncFunction: async (args, thunkApi) => {
    const state = thunkApi.getState()
    const organisationId = authSelectors.getUserSelectedOrganisationId(state)
    const data = await digitalStoreSdk.website.fetchWebsites({ organisationId })
    return data
  }
})

export const fetchWebsite = createStatefulThunk({
  typePrefix: FETCH_WEBSITE,
  path: 'selectedWebsite',
  options: {
    isPaginated: false
  },
  asyncFunction: async (args, thunkApi) => {
    const state = thunkApi.getState()
    const { id } = args
    try {
      const selectedWebsite = await digitalStoreSdk.website.fetchWebsiteById({ id })
      return selectedWebsite
    } catch (error) {
      console.log(error)
    }
  }
})

export const createWebsite = createStatefulThunk({
  typePrefix: CREATE_WEBSITE,
  path: 'selectedWebsite',
  options: {
    isPaginated: false
  },
  asyncFunction: async (data, thunkApi) => {
    const state = thunkApi.getState()
    const organisationId = authSelectors.getUserSelectedOrganisationId(state)
    data.organisationId = organisationId
    try {
      const selectedWebsite = await digitalStoreSdk.website.createWebsite({ data })
      return selectedWebsite
    } catch (error) {
      console.log(error)
    }
  }
})