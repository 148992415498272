import React from 'react'
import { useDiagnostics } from './useDiagnostics'
import { v4 as uuid } from 'uuid'
import fp from 'lodash/fp'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

export const useTrackRepeatables = ({ constants = {}, useFormState = _.noop }) => {
    /**
     * @type {Diagnostics}
     */
    const diagnostics = useDiagnostics()
    const formId = fp.get('id', constants)
    const { values: formValues } = useFormState()
    const repeatableCollection = fp.get(formId, formValues)
    const reducer = (_state, action) => {
        const { mode } = action.payload
        const nextState = { mode, id: uuid() }
        switch (action.type) {
            case 'START_INSTANCE':
                nextState.message = `New Instance was started.`
                break
            case 'EDIT_INSTANCE':
                nextState.message = `Instance was opened by the user for editing.`
                break
            case 'SUBMIT_INSTANCE': {
                const { instance } = action.payload
                nextState.message = `Instance ${instance.id} was submitted.`
                break
            }
            case 'CANCEL_INSTANCE':
                nextState.message = `Instance was canceled.`
                break
            case 'REMOVE_INSTANCE': {
                const { index, repeatables } = action.payload
                const instance = _.nth(repeatables, index)
                nextState.message = `Instance ${instance.id} was removed from the repeatable.`
                break
            }
            default:
                nextState.message = 'unsupported action on a repeatable.'
                break
        }
        return nextState
    }
    const [state, dispatch] = React.useReducer(reducer, {})

    React.useEffect(() => {

        if (fp.get('id', state)) {
            const repeatablesJSON = fp.compose(
                (repeatables) => {
                    try {
                        return JSON.stringify(repeatables, null, 2)
                    } catch (error) {
                        return repeatables
                    }
                },
                fp.map(fp.pick([
                    'id',
                    'drugcharacterization',
                    'medicinalproduct',
                    'primarysourcereaction',
                    'reactionmeddrallt'
                ])),
            )(repeatableCollection)
            diagnostics.addBreadcrumb({
                category: 'form',
                message: state.message,
                level: Sentry.Severity.Info,
                data: {
                    field: 'repeatable',
                    id: formId,
                    label: _.truncate(constants.label, { length: 100 }),
                    mode: state.mode,
                    value: repeatablesJSON
                }
            });
        }
    }, [state.id])

    return [state, dispatch]
}