
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import ImageControlPointDuplicateIcon from '@material-ui/icons/FileCopy'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import ContentBox from '../../../components/ContentBox'
import ContentBoxHeader from '../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody/'
import TextContent from '../../../components/Form/TextContent'
import Form, { FormBody, FormSubmit, FormSection } from '../../../components/Form'
import { translations } from '../../../config'
import styles from './style'
import * as orgSchema from '../formSchemas/organisationDetailsSchema'
import BottomBar from '../../../components/BottomBar'
import { constants as organisationDetailsConstants } from '../../../store/modules/organisationDetails'

import { Can as AbilityCan } from '../../../ability/ability-context'
import * as schema from '../formSchemas/organisationDetailsSchema'

const OrganisationDetailsForm = Form(organisationDetailsConstants.NEW_ORGANISATION_FORM_NAME)
const ReportingOrganisationContactDetailsForm = Form(organisationDetailsConstants.REPORTING_ORGANISATION_CONTACT_DETAILS_FORM_NAME)

const NewOrganisationFormWrapper = ({
  classes,
  onSubmit,
  organisationConfigurationSchema,
  pageTitle,
  sourceOrganisationDetails,
  orgReceivers,
  hasSourceOrganisationDetails,
  initialValues,
  orgContactDetailsFormValues
}) => {
  const renderDuplicateOrganisationMessage = () => {
    if (!hasSourceOrganisationDetails) return null
    const fields = _.split(translations('Duplicate organisation Info - List'), ',')

    return (
      <div className={classes.duplicateContainer}>
        <ContentBox givenContentClass={classes.duplicateBoxBody}>
          <ContentBoxHeader>
            <TextContent>
              <Heading>
                <ImageControlPointDuplicateIcon className={classes.duplicateIcon} />
                {translations('Duplicate organisation Info - Title', { name: sourceOrganisationDetails.name })}
              </Heading>
            </TextContent>
          </ContentBoxHeader>
          <ContentBoxBody>
            <P value={translations('Duplicate organisation Info - Message')} />
            <ul>
              {_.map(fields, (item) => <li key={item}><P value={_.trim(item)} /></li>)}
            </ul>
          </ContentBoxBody>
        </ContentBox>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1' uppercase>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
      />

      <div className={classes.contentContainer}>
        <AbilityCan I="viewReportingOrganisationContactDetails" a="organisationManagement">
          <ReportingOrganisationContactDetailsForm
            editing
            fullWidthFields
            initialValues={{
              ...initialValues,
              templateRoleType: 'DIRECT_REPORTING'
            }}
            onSubmit={onSubmit}
            formId={organisationDetailsConstants.REPORTING_ORGANISATION_CONTACT_DETAILS_FORM_NAME}>
            <FormBody schema={schema.reportingOrganisationContactDetailsFormSchema(orgContactDetailsFormValues).schema}>
            </FormBody>
            <FormSubmit
              ContainerComponent={BottomBar}
              label={translations('Create Organisation')}
              disabled={false}
            />
          </ReportingOrganisationContactDetailsForm>
        </AbilityCan>

        <AbilityCan I='viewOrganisation' a='organisationManagement'>
          {renderDuplicateOrganisationMessage()}
          <div className={classes.requiredDeactivateButtonContainer}>
            <P value={translations('required fields')} className={classes.requiredText} />
          </div>
          <OrganisationDetailsForm initialValues={initialValues} onSubmit={onSubmit}>
            <FormBody>
              {FormSection({ title: translations('Organisation'), schema: orgSchema.organisationDetails.schema })}
              {FormSection({ title: translations('Organisation Contact Details'), schema: orgSchema.organisationContactDetails.schema })}
              {FormSection({ title: translations('Organisation Configuration'), schema: organisationConfigurationSchema })}
              {FormSection({ title: translations('News Configuration'), schema: orgSchema.newsConfiguration.schema })}
            </FormBody>
            <FormSubmit
              ContainerComponent={BottomBar}
              label={translations('Create Organisation')}
              disabled={false}
            />
          </OrganisationDetailsForm>
        </AbilityCan>
      </div>
    </div >
  )
}

NewOrganisationFormWrapper.defaultProps = {
  hasSourceOrganisationDetails: false
}

NewOrganisationFormWrapper.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  organisationConfigurationSchema: PropTypes.array.isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired,
  initialValues: PropTypes.object,
  sourceOrganisationDetails: PropTypes.object,
  hasSourceOrganisationDetails: PropTypes.bool
}

export default withStyles(styles)(NewOrganisationFormWrapper)
