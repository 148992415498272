import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

const StyledParagraph = styled.p`
  color: ${props => props.theme.colors.primaryText};
  font-size: ${props => `${props.theme.type.body.small}px`};
  line-height: 1.25;
  ${media.greaterThan('small')`
    font-size: ${props => `${props.theme.type.body.large}px`};
    line-height: 1.4;
  `}
`

/**
 * A simple paragraph
 */
const P = (props) => {
  const { children, style } = props

  return (
    <StyledParagraph style={style}>{children}</StyledParagraph>
  )
}

P.propTypes = {
  /** The text value of the paragraph */
  children: PropTypes.string.isRequired
}

export default P
