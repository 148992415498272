import React, { useEffect, useState, useCallback } from 'react'
import { usePrevious } from 'react-use'
import _ from 'lodash'
import styled from 'styled-components'
import media from 'styled-media-query'
import { FormSpy } from 'react-final-form'

import { useFormStatusState } from '../../services/formStatusContext'

import ReportFormSection from '../ReportFormSection'
import LinkButton from '../LinkButton'
import Button from '../Button'
import Spinner from '../Spinner'

import { utilities } from '@redant/mhra-form-schema-library'
import { useDeepPrevious } from '../../hooks'

import { translation } from '../../services/translations'

const Container = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  background: ${props => props.nested ? props.theme.colors.lightGray : 'none'};
`

const FormActions = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: ${props => `${props.theme.form.spacing.small}px`};
  padding-bottom: ${props => `${props.theme.form.spacing.small}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${media.greaterThan('small')`
    padding-top: ${props => `${props.theme.form.spacing.large}px`};
    padding-bottom: ${props => `${props.theme.form.spacing.large}px`};
  `}
  > button {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const RepeatableForm = (props) => {
  const {
    schema,
    form: {
      change,
      submit
    },
    values = {},
    parentValues,
    onClose,
    onCancel,
    formName,
    nested,
    fieldName,
    required,
    valid,
    disabled,
    actionLabels
  } = props

  const formId = `repeatable_${formName}`
  const previousValues = useDeepPrevious(values) || values
  const [parsedSchema, setParsedSchema] = useState([])
  const {
    setOpenRepeatable,
    getOpenRepeatable,
    removeOpenRepeatable,
    setHasSubmit
  } = useFormStatusState()

  const handleAdd = () => {
    submit()
    const isOpen = getOpenRepeatable(formId)
    if (valid && isOpen) {
      removeOpenRepeatable(formId)
      setHasSubmit(true)
    }
  }

  useEffect(() => {
    change('_parentFormValues', parentValues)
    return () => { }
  }, [parentValues, formId])
  const parseSchema = useCallback(() => {
    const changeField = (_form, field, value) => change(field, value)
    const parser = utilities.parseDynamicSchema(values, previousValues, changeField, formId, false, parentValues)
    const parsedSchema = parser(schema)
    return parsedSchema
  }, [change, values, previousValues, parentValues, formName])

  useEffect(() => {
    const parsedSchema = parseSchema()
    if (!_.isUndefined(disabled)) {
      for (const item of parsedSchema) {
        item.props.disabled = disabled
      }
    }
    setParsedSchema(parsedSchema)
  }, [change, values, previousValues, parentValues])

  const buttonLabel = _.isFunction(onClose) ? actionLabels.update : actionLabels.add

  const onFormChange = (spyProps) => {
    setOpenRepeatable('Validity changed or dirty', formId, fieldName, submit, spyProps.valid, !spyProps.pristine)
  }

  const determineCancel = () => {
    if (required && _.size(parentValues[fieldName]) === 0) return false
    if (_.isFunction(onCancel)) return true
  }

  const checkDisabled = () => {
    const valuesWithoutParentValues = _.omit(values, ['_parentFormValues'])
    return _.isEmpty(valuesWithoutParentValues) || _.every(valuesWithoutParentValues, _.isEmpty)
  }

  const isDisabled = checkDisabled()

  const renderForm = () => {
    return (
      <>
        <FormSpy
          subscription={{ valid: true, pristine: true }}
          onChange={(spyProps) => onFormChange(spyProps)}
        />
        {!_.isEmpty(parsedSchema) && <ReportFormSection schema={parsedSchema} />}
        <FormActions>
          {_.isFunction(onClose) && <LinkButton type='button' onClick={onClose}>Close</LinkButton>}
          {determineCancel() && <LinkButton type='button' onClick={() => onCancel(formId)}>{translation(actionLabels.cancel)}</LinkButton>}
          {!disabled && <Button type='button' onClick={handleAdd} disabled={isDisabled}>{translation(buttonLabel)}</Button>}
        </FormActions>
      </>)
  }

  const renderSpinner = () => (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  )

  return (
    <Container nested={nested}>
      {values._parentFormValues ? renderForm() : renderSpinner()}
    </Container>
  )
}

export default RepeatableForm
