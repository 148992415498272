import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../../../config'
import { constants as receiverConstants } from '../../../../../store/modules/receivers'

import style from './style'

const ReceiverModalForm = Form(receiverConstants.RECEIVER_SELECTOR_FORM)

const ReceiverModal = (props) => {
  const {
    handleSubmit,
    actions,
    schema,
    initialValues,
    classes
  } = props
  return (
    <BasicModalOverlay
      title={translations('Select a receiver - Modal heading')}
      actions={actions}
    >
      <ReceiverModalForm onSubmit={handleSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <div className={classes.container}>
            <FormBody
              schema={schema}
              editing
              fullWidthFields
            />
          </div>
        </ContentBoxBody>
      </ReceiverModalForm>
    </BasicModalOverlay>
  )
}

ReceiverModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  schema: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object
}

export default withStyles(style)(ReceiverModal)
