import React, { Fragment } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { FormControlLabel, FormHelperText } from '@material-ui/core'
import classNames from 'classnames'
import style from './style'
import _ from 'lodash'
import { translations } from '../../../config'

const CheckboxComponent = (props) => {
  const {
    label,
    value,
    checked,
    onChange,
    onFocus,
    onBlur,
    onClick,
    givenClass,
    containerClass,
    hideLabel,
    classes,
    disabled,
    required = false,
    help,
    meta: {
      pristine,
      touched,
      error
    }
  } = props

  /**
   * @type {(string | object)} Label for display, either a string or a html element, like an `<a/>`.
   */
  const formattedLabel = _.isObject(label) ? label : `${label}${required ? ' *' : ''}`

  const labelClass = classNames(
    {
      [classes.hideLabel]: hideLabel
    }
  )

  return (
    <Fragment>
      <FormControlLabel
        classes={{
          root: containerClass,
          label: labelClass
        }}
        control={
          <Checkbox
            checked={checked || value === true} // Value should be the checked value - adding it as an "or" to ensure the old API doesnt break for other components
            value={String(value)}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            className={givenClass}
            onClick={onClick}
            disabled={disabled}
            color='default'
          />
        }
        label={formattedLabel}
      />
      {help && <FormHelperText margin={'dense'}>{translations(help)}</FormHelperText>}
      <FormHelperText className={classes.errorText} key='helper-text' role='alert'>{(!pristine || touched) && error && error}</FormHelperText>
    </Fragment>
  )
}

CheckboxComponent.defaultProps = {
  meta: {
    pristine: true,
    touched: false,
    error: undefined
  }
}

export default withStyles(style)(CheckboxComponent)
