import _ from 'lodash'
import { isPrice } from '../../helpers'
import cf from '../currencyFormatter'

// needed this for fixing count chart values to 2 decimal places

class ValueFormatter {
  _formatValue = (value) => {
    if (typeof value === 'number' && value % 1 !== 0) {
      return value.toFixed(2)
    } else {
      return value
    }
  }
  format = (someEntity) => {
    if (someEntity) {
      if (typeof someEntity === 'object') {
        if (isPrice(someEntity)) {
          return cf.format(someEntity)
        } else if (_.has(someEntity, 'value')) {
          return this._formatValue(someEntity.value)
        } else {
          return someEntity
        }
      } else {
        return this._formatValue(someEntity)
      }
    } else {
      return ''
    }
  }
}

export default new ValueFormatter()
