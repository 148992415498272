import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  status: undefined,
  error: undefined
}
export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SUBMIT_ACK:
      return reducers.submitAck(state, action)
    case constants.FETCH_ACK:
      return reducers.fetchAck(state, action)
    default:
      return state
  }
}
