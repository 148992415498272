export default {
  imagePicker: {
    display: 'none'
  },
  submitButtonLabelContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  submitButtonLabelIcon: {
    height: 15,
    width: 20,
    margin: '0 auto'
  },
  submitButtonLabelText: {
    marginTop: 5,
    fontSize: 12
  },
  header: {
    marginBottom: 5
  },
  buttonContainer: {
    display: 'flex',
    marginTop: 16,
    alignItems: 'center'
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    width: '100%'
  },
  submitButtonHalf: {
    fontWeight: 'bold',
    flex: 1,
    fontSize: 14,
    minHeight: 30,
    height: 30,
    textTransform: 'none'
  },
  buttonSpace: {
    margin: '0 10px'
  },
  fileName: {
    margin: '0 10px 0 0'
  },
  imagePreview: {
    display: 'block',
    marginRight: 10,
    height: 30
  },
  buttonIcon: {
    fontSize: 10
  }
}
