import { connect } from 'react-redux'
import { compose } from 'recompose'

import NewsFeedScreen from './NewsFeedScreen'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

const enhancer = compose(
  connect(state => ({
    pageTitle: platformSelectors.getPageTitle(state)('Configure news feeds')
  }))
)

export default enhancer(NewsFeedScreen)
