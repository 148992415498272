import { push, LOCATION_CHANGE } from 'connected-react-router'
import { REHYDRATE } from 'redux-persist'
import { constants as authConstants, actions as authActions, selectors as authSelectors } from '../auth'
import { selectors as platformSelectors } from '../platforms'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import _ from 'lodash'
import { userAccountTypeEnum } from '../../../containers/Users/constants'

class RoutingMiddleware {
  loginSuccessRouteRedirectMiddleware = ({ dispatch, getState }) => next => async (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === authConstants.LOGIN) {
      const fromLocation = _.get(getState(), 'auth.fromLocation', '')
      const organisations = _.get(action, 'result.organisations', [])
      const length = _.get(organisations, 'length', 0)
      const isAssessor = _.get(action, 'result.accountType') === userAccountTypeEnum.ASSESSOR
      if (length === 1) {
        const organisation = action.result.organisations[0]
        const organisationId = organisation.id
        const platformId = platformSelectors.getCurrentPlatformId(getState())
        dispatch(authActions.setAuthContext({ organisationId, platformId }))
        dispatch(authActions.selectOrganisation({ organisationId, regionId: organisation.regionId, receiverId: organisation.receiverId }))
        dispatch(push(fromLocation))
      } else {
        if (isAssessor) {
          return dispatch(push(fromLocation))
        }
        dispatch(push(`/select-organisation`))
      }
    }
  }

  showMigrationScreenMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === REHYDRATE) {
      const isMigrationRequired = authSelectors.isMigrationRequired(getState())
      if (isMigrationRequired) {
        dispatch(push(`/migration`))
      }
    }
  }

  selectOrganisationRouteRedirectMiddleware = ({ dispatch, getState }) => next => async (action) => {
    next(action)
    const isMigrationRequired = authSelectors.isMigrationRequired(getState())
    if (action.type === authConstants.SELECT_ORGANISATION && !action.silent) {
      const fromLocation = _.get(getState(), 'auth.fromLocation', '')
      const platformId = platformSelectors.getCurrentPlatformId(getState())
      await dispatch(authActions.setAuthContext({ organisationId: action.organisationId, platformId }))
      if (isMigrationRequired) {
        dispatch(push(`/migration`))
      } else {
        dispatch(push(fromLocation))
      }
    }
  }

  getSSOAuthContextSuccessMiddleware = ({ dispatch, getState }) => next => async (action) => {
    next(action)
    if (action.status === 'SUCCESS' && action.type === authConstants.GET_SSO_AUTH_CONTEXT) {
      if (action.result.organisations.length === 1) {
        const organisation = action.result.organisations[0]
        const organisationId = organisation.id
        dispatch(authActions.setAuthContext({ organisationId }))
        dispatch(authActions.selectOrganisation({ organisationId, regionId: organisation.regionId, receiverId: organisation.receiverId }))
      } else {
        dispatch(push(`/select-organisation`))
      }
    }
  }

  finishProfileMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    const loggedIn = authSelectors.getIsLoggedIn(getState())
    if (action.type === LOCATION_CHANGE && action.payload.location.pathname === '/my-profile') {
      const hasCompleteProfile = authSelectors.hasCompleteProfile(getState())
      if (!hasCompleteProfile) {
        modalService.action({
          title: translations('Please complete your profile'),
          text: 'Please enter the following personal details before continuing:\n - First Name\n - Last Name',
          disableBackdropClick: true,
          actions: [
            {
              success: true,
              text: translations('OK'),
              onClick: modalService.close,
              primary: true
            }
          ]
        })
      }
    } else if (action.type === LOCATION_CHANGE && action.payload.location.pathname !== '/my-profile' && loggedIn) {
      const hasCompleteProfile = authSelectors.hasCompleteProfile(getState())
      if (!hasCompleteProfile) {
        dispatch(push(`/my-profile`))
      }
    }
  }

  // causing a bug on login
  // showLoginWithNoSelectedOrganisation = ({ dispatch, getState }) => next => (action) => {
  //   next(action)
  //   if (action.type === LOCATION_CHANGE && action.payload.location.pathname === '/select-organisation') {
  //     if (!authSelectors.getSelectedOrganisation(getState())) {
  //       dispatch(replace('/login'))
  //     }
  //   }
  // }
}

export default new RoutingMiddleware()
