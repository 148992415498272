import React from 'react'

import { FormControl, Grid, styled } from '@material-ui/core'

import Dropdown from '../../../../components/Fields/Dropdown'

const FullWidthFormControl = styled(FormControl)(() => ({
  width: '100%'
}))

const Filter = ({ label, options, value, onChange }) => {
  return (
    <Grid xs={12} md={2}>
      <FullWidthFormControl>
        <Dropdown
          noNullOption
          label={label}
          value={value}
          onChange={onChange}
          options={options}
        />
      </FullWidthFormControl>
    </Grid>
  )

}

export default Filter
