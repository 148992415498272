import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import _ from 'lodash'

import { translations } from '../../../../../config'
import { withStyles } from '@material-ui/core/styles'

import Scopes from './scopes'
import ColumnMenu from './ColumnMenu'
import Toolbar from './Toolbar'
import modalService from '../../../../../services/modalService'
import SaveChangesModal from '../SaveTranslationChangesModal'
import DeleteLanguageModal from '../DeleteLanguageModal'
import CreateKeyModal from '../CreateKeyModal'
import Types from './types'
import DeleteTranslationModal from '../DeleteTranslationModal'
import GridCellExpand from './GridCellExpand'
import styles from './style'
import TooltipIcon from '../../../../../components/TooltipIcon'
import LockIcon from '@material-ui/icons/Lock'

const KEY_COLUMN_NAME = 'key'
const IS_SYSTEM_KEY_COLUMN_NAME = 'isSystemKey'
const DEFAULT_TRANSLATION_ISO_CODE = 'en'

const Table = ({
  organisationLanguages,
  searchedTranslations,
  onTablePageChange,
  totalTranslationCount,
  rowsPerPage,
  onCreateKey,
  languages,
  onSubmitTranslationChanges,
  onSubmitTranslationDelete,
  onDeleteLanguage,
  setCanSave,
  canSave,
  classes,
  showSource,
  showAddLanguageModal
}) => {
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([])
  const [modifiedCells, setModifiedCells] = useState({})
  const [selectionModel, setSelectionModel] = useState([])
  const [canDelete, setCanDelete] = useState(false)
  const [canUpdate, setCanUpdate] = useState(false)

  const initialRows = useMemo(() => {
    return searchedTranslations.map(({ keyId, key, keyScope, translations, tags, updateType }) => {
      const translationCells = translations.reduce((prev, curr) => {
        const { id, value, languageIsoCode, updateType } = curr

        const { name: languageName } = languages.find(({ isoCode }) => isoCode === languageIsoCode)

        return {
          ...prev,
          [languageIsoCode]: { value, id, initial: value, key, keyId, languageIsoCode, type: Types.TRANSLATION, languageName, updateType }
        }
      }, {})

      return { 
        id: keyId, 
        [IS_SYSTEM_KEY_COLUMN_NAME]: keyScope === Scopes.GLOBAL,
        [KEY_COLUMN_NAME]: { 
          value: key, 
          initial: key, 
          scope: keyScope, 
          id: keyId, 
          type: Types.KEY, 
          tags, 
          updateType 
        }, 
        ...translationCells 
      }
    })
  }, [languages, searchedTranslations])

  const deletedRows = useMemo(() => {
    return initialRows.filter((row) => { return selectionModel.includes(row.id) })
  }, [selectionModel])

  function renderCellExpand(params) {
    const { id, field, colDef, value, api, row } = params
    const isKeyField = field === KEY_COLUMN_NAME
    const tags = row[KEY_COLUMN_NAME]?.tags
    const updateType = row[field]?.updateType
    return (
      <GridCellExpand
        id={id}
        field={field}
        colDef={colDef}
        value={value}
        api={api}
        canUpdate={canUpdate}
        tags={tags}
        showKeyTag={isKeyField && !_.isEmpty(tags)}
        isCellGlobal={isKeyField && row.key.scope === Scopes.GLOBAL}
        updateType={updateType}
        showSource={showSource}
      />
    )
  }

  function renderPadLockCell(params) {
    if (!params.value) {
      return ''
    }
    return (
      <TooltipIcon title={translations('Manage Translations - System key tooltip message')} icon={<LockIcon />} />
    )
  }

  const columns = useMemo(() => {
    const languageHeaders = organisationLanguages.map(({ label, isoCode, value }) => ({
      field: isoCode,
      headerName: label,
      width: 220,
      editable: true,
      sortable: false,
      languageId: value,
      disableColumnMenu: isoCode === DEFAULT_TRANSLATION_ISO_CODE,
      valueGetter: (params) => params.row[isoCode]?.value,
      renderCell: renderCellExpand
    })).sort((a, b) => a.field === DEFAULT_TRANSLATION_ISO_CODE ? -1 : b.field === DEFAULT_TRANSLATION_ISO_CODE ? 1 : 0)

    return [
      {
        field: IS_SYSTEM_KEY_COLUMN_NAME,
        headerName: ' ',
        width: 53,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderPadLockCell
      },
      {
        field: KEY_COLUMN_NAME,
        headerName: translations('Manage Translations - Table Key Column Header'),
        width: 320,
        editable: true,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row[KEY_COLUMN_NAME]?.value,
        renderCell: renderCellExpand
      },
      ...languageHeaders
    ]
  }, [organisationLanguages])

  const showDeletionModal = useCallback(() => {
    modalService.open({
      component: DeleteTranslationModal,
      largeModal: true,
      changes: deletedRows,
      onSubmitTranslationDelete: (changes) => {
        onSubmitTranslationDelete(changes).then(() => {
          setModifiedCells({})
        })
        modalService.close()
      },
      onClose: () => modalService.close()
    })
  }, [initialRows, deletedRows, onSubmitTranslationDelete])

  const showChangesModal = useCallback(() => {
    modalService.open({
      component: SaveChangesModal,
      largeModal: true,
      changes: Object.values(modifiedCells),
      onSubmitTranslationChanges: (changes) => {
        onSubmitTranslationChanges(changes).then(() => {
          setModifiedCells({})
        })
        modalService.close()
        setCanUpdate(false)
      },
      onClose: () => modalService.close()
    })
  }, [modifiedCells, onSubmitTranslationChanges])

  const showDeleteModal = useCallback(({ id, languageName }) => {
    modalService.open({
      component: DeleteLanguageModal,
      languageName,
      languageId: id,
      onDelete: (languageId) => {
        onDeleteLanguage(languageId)
        modalService.close()
      },
      onCancel: () => modalService.close()
    })
  }, [onDeleteLanguage])

  const showCreateKeyModal = useCallback(() => {
    modalService.open({
      component: CreateKeyModal,
      onCreate: (key) => {
        onCreateKey(key)
        modalService.close()
      }
    })
  }, [onCreateKey])

  useEffect(() => {
    setRows(initialRows)
  }, [initialRows])

  useEffect(() => {
    setModifiedCells((mc) => {
      const modifiedCellKeys = Object.entries(mc)
      if (modifiedCellKeys.length) {
        const updatedRows = initialRows.map((row) => {
          for (const [key, cell] of modifiedCellKeys) {
            const [keyId, fieldName] = key.split('.')

            if (row.id === keyId) {
              return {
                ...row,
                [fieldName]: {
                  ...row[fieldName],
                  value: cell.value
                }
              }
            }
          }

          return row
        })

        setRows(updatedRows)
      }

      return mc
    })
  }, [initialRows])

  useEffect(() => {
    setCanSave(!_.isEmpty(modifiedCells))
  }, [setCanSave, modifiedCells])

  useEffect(() => {
    setCanDelete(!_.isEmpty(deletedRows))
  }, [setCanDelete, deletedRows])

  const isCellEditable = useCallback((params) => {
    if (!canUpdate) {
      return false
    }
    switch (params.field) {
      case KEY_COLUMN_NAME: {
        return !(params.row.key.scope === Scopes.GLOBAL)
      }
      default:
        return true
    }
  }, [canUpdate])

  const isRowSelectable = useCallback((params) => {
    if (canUpdate) {
      return false
    }
    return _.get(params, 'row.key.scope') === Scopes.ORGANISATION
  }, [canUpdate])

  const onCellEditCommit = useCallback(({ id, field, value = '', colDef }) => {
    let updatedCell = {}
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        if (field === KEY_COLUMN_NAME) {
          const newKey = value
          const isNewKeyValid = newKey.trim().length > 0
          if (!isNewKeyValid) {
            updatedCell = row[KEY_COLUMN_NAME]
            return row
          }
        }
        if (!row[field]) {
          updatedCell = {
            type: Types.NEW_TRANSLATION,
            keyId: id,
            key: row[KEY_COLUMN_NAME].value,
            languageIsoCode: field,
            languageName: colDef.headerName,
            languageId: colDef.languageId,
            value
          }
        } else {
          updatedCell = { ...row[field], value }
        }
        return {
          ...row,
          [field]: updatedCell
        }
      }

      return row
    })

    const targetRow = initialRows.find((row) => row.id === id)
    const targetCell = targetRow[field]

    const isExistingCell = !!targetCell

    const cellId = `${targetRow[KEY_COLUMN_NAME].id}.${field}`
    if (isExistingCell) {
      const hasChanged = !_.isEqual(targetCell, updatedCell)
      if (hasChanged) {
        setModifiedCells((prevModifiedCells) => ({
          ...prevModifiedCells,
          [cellId]: updatedCell
        }))
      }

      if (!hasChanged) {
        setModifiedCells((prevModifiedCells) => {
          delete prevModifiedCells[cellId]

          return { ...prevModifiedCells }
        })
      }
    } else {
      if (!updatedCell.value) updatedCell = undefined

      const hasChanged = !_.isEqual(targetCell, updatedCell)

      if (hasChanged) {
        setModifiedCells((prevModifiedCells) => ({
          ...prevModifiedCells,
          [cellId]: updatedCell
        }))
      }

      if (!hasChanged) {
        setModifiedCells((prevModifiedCells) => {
          delete prevModifiedCells[cellId]

          return { ...prevModifiedCells }
        })
      }
    }

    setRows(updatedRows)
  }, [initialRows, rows])

  useEffect(() => {
    setModifiedCells({})
  }, [canUpdate])

  const onPageChange = useCallback((pageNumber) => {
    setPage(pageNumber)
    onTablePageChange(pageNumber)
  }, [])

  return (
    <DataGrid className={canUpdate ? classes.root : null}
      checkboxSelection
      page={page}
      onSelectionModelChange={setSelectionModel}
      selectionModel={selectionModel}
      onPageChange={onPageChange}
      rowCount={totalTranslationCount}
      onCellEditCommit={onCellEditCommit}
      isCellEditable={isCellEditable}
      isRowSelectable={isRowSelectable}
      rows={rows}
      columns={columns}
      pageSize={rowsPerPage}
      rowsPerPageOptions={[rowsPerPage]}
      paginationMode="server"
      components={{ ColumnMenu, Toolbar }}
      componentsProps={{
        columnMenu: { onDeleteLanguage: showDeleteModal },
        toolbar: {
          canSave,
          onSaveChanges: showChangesModal,
          onCreateKey: showCreateKeyModal,
          onAddLanguage: showAddLanguageModal,
          canDelete,
          onDelete: showDeletionModal,
          canUpdate,
          setCanUpdate
        }
      }}
      autoHeight
    />
  )
}

export default withStyles(styles)(Table)
