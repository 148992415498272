import { getPageTitle } from './selectors'
import { useShallowEqualSelector } from '../../../hooks'

/**
 * @description Get page title
 * @returns {Object} Page title and Page title with platform name
 */
export const usePageTitle = (label) => {
  return useShallowEqualSelector((state) => getPageTitle(state)(label))
}
