import { translations } from '../../../../config'

export const sortOptions = [
    { label: translations('Terminology Sort Filter - Sort By Term Ascending'), value: 'term_ASC' },
    { label: translations('Terminology Sort Filter - Sort By Term Descending'), value: 'term_DESC' },
    { label: translations('Terminology Sort Filter - Sort By Created At Ascending'), value: 'createdAt_ASC' },
    { label: translations('Terminology Sort Filter - Sort By Created At Descending'), value: 'createdAt_DESC' },
    { label: translations('Terminology Sort Filter - Sort By updated At Ascending'), value: 'updatedAt_ASC' },
    { label: translations('Terminology Sort Filter - Sort By updated At Descending'), value: 'updatedAt_DESC' },
    { label: translations('Terminology Sort Filter - Sort By Last used by action Ascending'), value: 'action_lastUsedAt_ASC'},
    { label: translations('Terminology Sort Filter - Sort By Last used by action Descending'), value: 'action_lastUsedAt_DESC'},
]
