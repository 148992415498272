import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import VisuallyHidden from '@reach/visually-hidden'

const StyledIconButton = styled.button`
  -webkit-appearance: none;
  border-radius: 0;
  border: 0;
  background: none;
  width: 26px;
  height: 26px;
  background-size: 16px 16px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.theme.icons[props.icon]})`};
  opacity: ${props => props.disabled && '0.3'};
  margin: 0;
  padding: 0;
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  ${media.greaterThan('small')`
    width: 26px;
    height: 26px;
    background-size: 20px 20px;
  `}
  &:not(:disabled):hover, &:not(:disabled):active, &:not(:disabled):focus {
    outline: none;
    box-shadow: ${({ theme }) => `0 0 0 3px ${theme.colors.primary}`};
  }
`

const IconButton = (props) => {
  const { disabled, icon, label, onClick  } = props

  const handleClick = (e) => {
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }

  return (
    <StyledIconButton disabled={disabled} type={'button'} onClick={handleClick} icon={icon}>
      <VisuallyHidden>{label}</VisuallyHidden>
    </StyledIconButton>
  )
}

IconButton.propTypes = {
  /** The icon of the button */
  icon: PropTypes.string,
  /** The hidden label of the button */
  label: PropTypes.string,
  /** Function to call when clicking the button. Function is passed the `event` */
  onClick: PropTypes.func,
  /** Disable the button */
  disabled: PropTypes.bool
}

IconButton.defaultProps = {
  disabled: false,
  icon: 'edit'
}

export default IconButton
