import { colors } from '../../config/theme'

export default theme => ({
  container: {
    display: 'flex',
    border: `1px solid ${colors.border}`
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 5px',
    flexWrap: 'wrap',
    margin: 0,
    width: '100%'
  },
  keyValuePairContainer: {
    padding: '5px 10px',
    [theme.breakpoints.up('sm')]: {
      padding: '5px 12px'
    }
  },
  key: {
    opacity: 0.5,
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    ...theme.p.p,
    color: colors.text
  },
  value: {
    fontWeight: 'bold',
    ...theme.p.p,
    color: colors.text
  },
  faded: {
    opacity: 0.5
  },
  containerWithActions: {
    flex: 1
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 50,
    borderLeft: `1px solid ${colors.border}`,
    justifyContent: 'space-around',
    '&:empty': {
      display: 'none'
    }
  }
})
