import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import ToolTip from '@material-ui/core/Tooltip'
import { IconButton } from '@material-ui/core'
import { ThumbsUpDown, Check } from '@material-ui/icons'
import ButtonLink from '../../../../components/ButtonLink'
import Chip from '../../../../components/Chip'
import Checkbox from '../../../../components/Fields/Checkbox'
import { DataTableRow, DataTableCell } from '../../../../components/DataTable'
import SubmissionReportsMoreMenu from '../SubmissionReportsMoreMenu'
import SubmissionStatus from '../SubmissionStatus'
import { statusMap } from '../CaseSearchScreen'
import { translations } from '../../../../config'
import styles from './style'
import { getImage } from '../../../../components/Images'

const SubmissionReportsRow = (props) => {
  const {
    submissionReportId,
    classes,
    rowHeading,
    submission,
    reportId,
    validationStatus,
    receiverId,
    receiverName,
    receiverStatus,
    application,
    source,
    createdAt,
    updatedAt,
    updateQuery,
    onReceiver,
    onValidationErrors,
    sentToReceiverSince,
    menuOptions,
    toggleSelected,
    isSelected,
    manuallyProcessedByUserId,
    readOnly,
    hideSubmissionStatus,
    onOpenReportReview,
    report
  } = props
  const {anonymisedReport} = report

  const ManuallyProcessedIcon = () => (
    <ToolTip
      title={translations('Case Management - is manually processed')}
      aria-label={translations('Case Management - is manually processed')}
    >
      <Check />
    </ToolTip>
  )
  const AnonymiseIcon = () => {
    const anonymiseIcon = getImage('anonymiseIcon')
    return(
      <ToolTip title={translations('Case Management - Review Anonymised Report')}>
      <IconButton onClick={() => {onOpenReportReview({anonymisedReport: true})}} aria-label={translations('Case Management - Review Anonymised Report')}>
      <img src={anonymiseIcon} className={classes.anonymiseIcon} alt={translations('Case Management - Review Anonymised Report - Img Alt')}/>
      </IconButton>
    </ToolTip>
    )
  }

  const ReviewIcon = () => {
    return(
      <ToolTip title={translations('Case Management - Review Report')}>
      <IconButton onClick={onOpenReportReview} aria-label={translations('Case Management - Review Report')}>
        <ThumbsUpDown />
      </IconButton>
    </ToolTip>
    )
  }

  const excludedMenuOptions = []
  const hasBeenManuallyProcessed = !_.isNull(manuallyProcessedByUserId)
  const rowClass = classNames(classes.row, {
    [classes.selected]: isSelected
  })

  const checkboxClass = classNames(classes.checkbox, {
    [classes.checkboxSelected]: isSelected
  })
  if (hasBeenManuallyProcessed) {
    excludedMenuOptions.push(translations('Case Management - Mark as manually processed'))
  }
  if (!hasBeenManuallyProcessed) {
    excludedMenuOptions.push(translations('Case Management - View manually processed reason'))
  }
  const filterByExcluded = (val) => !_.includes(excludedMenuOptions, val.label)

  return (
    <DataTableRow className={rowClass}>
      {!readOnly && (
        <DataTableCell compact component='th' scope='row' nowrap>
          <Checkbox
            containerClass={classes.checkboxContainer}
            givenClass={checkboxClass}
            hideLabel
            value={isSelected}
            label={isSelected ? translations('Unselect case') : translations('Select case')}
            onClick={toggleSelected}
          />
          <ButtonLink
            tooltip={translations('Case Management - Search via case')}
            onClick={updateQuery}
            className={classes.rowHeader}
          >
            {rowHeading}
          </ButtonLink>
        </DataTableCell>
      )}
      <DataTableCell compact align={'center'}>{receiverName}</DataTableCell>
      <DataTableCell compact align={'center'}>
        <SubmissionStatus
          submission={submission}
          hidden={hideSubmissionStatus}
          disabled={readOnly}
          isSent={sentToReceiverSince}
          receiverStatus={receiverStatus}
          receiverId={receiverId}
          receiverName={receiverName}
        >
          <Chip disabled={readOnly} onClick={onReceiver} variant={_.get(statusMap, receiverStatus)} label={translations(`Case Management Status - ${receiverStatus}`)} />
        </SubmissionStatus>
      </DataTableCell>
      <DataTableCell compact align={'center'}>
        <Chip disabled={readOnly} onClick={onValidationErrors} variant={_.get(statusMap, validationStatus)} label={translations(`Case Management Status - ${validationStatus}`)} />
      </DataTableCell>
      <DataTableCell compact align={'center'} nowrap>{application}</DataTableCell>
      <DataTableCell compact align={'center'} nowrap>{source}</DataTableCell>
      <DataTableCell compact align={'center'} nowrap>{createdAt}</DataTableCell>
      <DataTableCell compact align={'center'} nowrap>{updatedAt}</DataTableCell>
      {!readOnly && (
  <>
      <DataTableCell compact align={'right'}>
        <div className={classes.moreWrap}>
          {hasBeenManuallyProcessed && <ManuallyProcessedIcon />}
          {anonymisedReport && <AnonymiseIcon />}
          {!anonymisedReport && ['IN_REVIEW', 'REJECTED'].includes(receiverStatus) && <ReviewIcon />}
          <SubmissionReportsMoreMenu menuId={`menu-${reportId}`} options={_.filter(menuOptions, filterByExcluded)} />
        </div>
      </DataTableCell>
    </>
  )}
    </DataTableRow>
  )
}

SubmissionReportsRow.defaultProps = {
  readOnly: false,
  hideSubmissionStatus: false
}

SubmissionReportsRow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  rowHeading: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
  validationStatus: PropTypes.oneOf(['VALID', 'INVALID', 'NOT_VALIDATED']),
  receiverStatus: PropTypes.oneOf([
    'NOT_SENT', 'SUCCESS', 'PENDING', 'FAILED', 'TIMED_OUT', 'IN_REVIEW', 'REJECTED'
  ]),
  receiverId: PropTypes.string.isRequired,
  receiverName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updateQuery: PropTypes.func.isRequired,
  onReceiver: PropTypes.func.isRequired,
  onValidationErrors: PropTypes.func.isRequired,
  sentToReceiverSince: PropTypes.bool,
  menuOptions: PropTypes.array.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  manuallyProcessedReason: PropTypes.string,
  readOnly: PropTypes.bool,
  hideSubmissionStatus: PropTypes.bool,
  onOpenReportReview: PropTypes.func.isRequired,
  report: PropTypes.shape({
    anonymisedReport: PropTypes.shape({})
  }).isRequired
}

export default withStyles(styles)(SubmissionReportsRow)
