import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import digitalStoreSdk from '../../../../../digitalStoreSdk'

import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

import P from '../../../../../components/P'
import Button from '../../../../../components/Button'
import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../../../config'
import { constants as reportConfigurationConstants } from '../../../../../store/modules/reportConfiguration'
import { actions as formViewsActions } from '../../../../../store/modules/formViews'

import { useDispatch } from 'react-redux'
import toastService from '../../../../../services/toastService'

import styles from './style'

const toastError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Downloaded Report Config Translations')
})
const toastSuccess = () => toastService.action({
  type: 'success',
  message: translations('Successfully - Downloaded Report Config Translations')
})

const ExportTranslationsForm = Form(reportConfigurationConstants.EXPORT_CONFIGURATION_FORM)

const ExportTranslationsModal = (props) => {
  const {
    classes,
    formViewId,
    dismiss
  } = props

  const dispatch = useDispatch()

  const handleDownload = (dismiss) => async () => {
    try {
      await dispatch(formViewsActions.generateReportConfigurationTranslationsCSV({ formViewId }))
      toastSuccess()
      dismiss()
    } catch (error) {
      toastError()
    }
  }

  return (
    <BasicModalOverlay
      title={translations('Export Report Configuration Translations - Modal Heading')}
    >
      {
        <ExportTranslationsForm initialValues={{ formViewId }}>
          <ContentBoxBody>
            <div className={classes.container}>
              <div className={classes.intro}>
                <ol>
                  <li>{translations('Export Report Configuration Translations - Statement 1')}</li>
                  <li>{translations('Export Report Configuration Translations - Statement 2')}</li>
                </ol>
              </div>
              <Grid container spacing={2} justify='flex-end'>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleDownload(dismiss)}
                  >
                    {translations('Export Report Configuration Translations - Download Translations Button')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color='secondary'
                    onClick={dismiss}
                    variant='outlined'
                  >
                    {translations('Close')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </ContentBoxBody>
        </ExportTranslationsForm>
      }
    </BasicModalOverlay>
  )
}

ExportTranslationsModal.propTypes = {
  classes: PropTypes.any,
  actions: PropTypes.array.isRequired,
  initialValues: PropTypes.shape({
    formViewId: PropTypes.string.isRequired
  })
}

export default withStyles(styles)(ExportTranslationsModal)
