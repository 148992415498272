import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { styled } from '@material-ui/core/styles'
import _ from 'lodash'
import { Chip } from '@material-ui/core'
import { actions as translationActions } from '../../../../store/modules/translations'
import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import Heading from '../../../../components/Heading'
import { translations } from '../../../../config'
import Container from '../../../../components/Container'
import Loader from '../../../../components/Loader/Loader'
import AuditRow from '../../../../components/AuditRow'
import { Modification, NewKey, ChangeList, Changes } from '../components/SaveTranslationChangesModal/SaveChangesModal'
import Types from '../components/Table/types'
import AuditTypes from './auditTypes'
import * as utils from './utils'
import { selectors as languagesSelectors } from '../../../../store/modules/languages'
import { selectors as organisationDetailsSelectors } from '../../../../store/modules/organisationDetails'
import { selectors as translationSelectors } from '../../../../store/modules/translations'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'

const HistoryWrapper = styled('div')({
  padding: '16px 16px 16px 48px'
})

const StyledContainer = styled(Container)({
  paddingBottom: 20
})

const renderUpdatedTranslations = ({json = {}}) => {
  const additions = _.groupBy(json.filter(({ type }) => type === Types.NEW_TRANSLATION), 'key')
  const modifications = json.filter(({ type }) => [Types.TRANSLATION, Types.KEY].includes(type))
  const newKeys = json.filter(({ type }) => type === Types.NEW_KEY)

  return <Changes additions={additions} modifications={modifications} newKeys={newKeys} changes={json} />
}

const renderAddedLanguage = ({json = {}, languages = []}) => {
  const { languageId } = json
  const langName = utils.getLanguageNameByID(languages, languageId)

  return <ChangeList>{translations('Manage Translations - language is added', { langName })}</ChangeList>
}

const renderDeletedLanguage = ({json = {}, languages = []}) => {
  const { languageId } = json
  const langName = utils.getLanguageNameByID(languages, languageId)
  
  return <ChangeList>{translations('Manage Translations - language is deleted', { langName })}</ChangeList>
}

const renderAddedKey = ({json = {}}) => {
  const { key } = json
  return (
    <ChangeList>
      <h3>{translations('Manage Translations - Save Changes New Keys Header')}</h3>
      <NewKey>{key}</NewKey>
    </ChangeList>
  )
}

const renderDeletedKeys = ({json = {}}) => {
  return (
    <ChangeList>
      <h3>{translations('Manage Translations - Save Changes Deletion Header')}</h3>
      {_.map(json, (item, index) => 
        <Modification key={index}>{item.value}</Modification>
      )}
    </ChangeList>
  )
  
}

const CollapseContent = ({row, languages}) => {
  const { auditType, json } = row

  const renderBlocks = () => {    
    switch (auditType) {
      case AuditTypes.UPDATE_TRANSLATIONS:
      case AuditTypes.UPLOAD_TRANSLATIONS:
        return renderUpdatedTranslations({json})
      case AuditTypes.DELETE_LANGUAGE:
        return renderDeletedLanguage({json, languages})
      case AuditTypes.NEW_LANGUAGE:
        return renderAddedLanguage({json, languages})
      case AuditTypes.NEW_KEY:
        return renderAddedKey({json})
      case AuditTypes.DELETE_KEYS:
        return renderDeletedKeys({json})
      default:
        return null
    }
  }

  return <HistoryWrapper>{renderBlocks()}</HistoryWrapper>
}


const TranslationsHistory = () => {
  const dispatch = useDispatch()
  const languages = useSelector(languagesSelectors.getLanguages)
  const organisationId = useSelector(organisationDetailsSelectors.getCurrentOrganisationId)
  const translationsAuditTrail = useSelector(translationSelectors.getTranslationsAuditTrail)
  const pageTitle = useSelector(platformSelectors.getPageTitle)('Manage Translations')

  useEffect(() => {
    if (organisationId) {
      dispatch(translationActions.fetchTranslationAuditTrailForOrganisation({ organisationId }))
    }
  }, [dispatch, organisationId])


  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1' uppercase>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
      />
      
      <Container withMarginTop withMarginBottom>
        <Heading component='h2' uppercase alignCenter>{translations('Manage Translations - Amend History')}</Heading>
      </Container>

      <StyledContainer withMarginTop>
        {!translationsAuditTrail 
          ? <Loader /> 
          : (_.size(translationsAuditTrail) 
            ? _.map(translationsAuditTrail, (row, index) => {
                const {
                  id,
                  createdAt,
                  auditType,
                  user
                } = row
    
                const rowDescription = `${translations('Manage Translations - Updated by',  { displayName: _.get(user, 'displayName', 'Unknown') })} (${user.email})`
                const changesCount = _.size(row.json)
                const rightGridItems = [
                  {
                    component: <Chip label={translations('Manage Translations - Changes Count', { changesCount })} variant="outlined" />,
                    visible: true
                  }
                ]
      
                return (
                  <AuditRow
                    key={id}
                    id={id}
                    createdAt={createdAt}
                    startOpen={index === 0}
                    collapseContent={<CollapseContent row={row} languages={languages} />}
                    rightGridItems={rightGridItems}
                    rowDescription={rowDescription}
                    rowTitle={translations(`Translation Audit - ${auditType}`)}
                  />
                )
              })
              : <div>{translations('Manage Translations - No History')}</div>
          )
        }
      </StyledContainer>
    </div>
  )
}

export default TranslationsHistory
