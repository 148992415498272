import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { translations } from '../../config'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import style from './style'

const ToggleIconButton = (props) => {
  const {
    onClick,
    active,
    inactiveIcon,
    activeIcon,
    targetId
  } = props

  const IconComponent = active ? activeIcon : inactiveIcon
  const label = active ? translations('Toggle Close') : translations('Toggle Open')

  return (
    <IconButton
      focusRipple
      onClick={onClick}
      aria-label={label}
      aria-expanded={active}
      aria-controls={targetId}
    >
      <IconComponent />
    </IconButton>
  )
}

ToggleIconButton.defaultProps = {
  active: false,
  inactiveIcon: AddIcon,
  activeIcon: RemoveIcon
}

ToggleIconButton.propTypes = {
  active: PropTypes.bool,
  inactiveIcon: PropTypes.func.isRequired,
  activeIcon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  targetId: PropTypes.string.isRequired
}

export default withStyles(style)(ToggleIconButton)
