import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import TabPanel from '@material-ui/lab/TabPanel'

import { translations } from '../../../config'
import { Content } from '../UserGroupsScreen/UserGroupsScreen'
import { getUserGroup } from '../../../store/modules/userGroups/selectors'
import { actions as userGroupActions } from '../../../store/modules/userGroups'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import ScreenHeading from '../../../components/ScreenHeading'
import UserGroupUserPagination from '../UserGroupsScreen/components/UserGroupUserPagination'
import PermissionsTab from './components/PermissionsTab'
import ReusableTabs from '../../../components/ReusableTabs'

const UserGroupScreen = () => {
  const dispatch = useDispatch()

  const { id } = useParams()
  const {
    data = {}
  } = useSelector(getUserGroup)

  const { state = {} } = useLocation()
  const {
    user: passedUser
  } = state

  // Need user as useLocation state is being lost
  const [user, setUser] = useState({})
  const {
    displayName = ''
  } = user

  const tabs = [
    { label: translations('Users'), value: 'users', ...a11yProps(0) },
    { label: translations('Permissions'), value: 'permissions', ...a11yProps(1) }
  ]

  useEffect(() => {
    if (id) {
      dispatch(userGroupActions.fetchUserGroup({ id }))
    }
  }, [id])

  useEffect(() => {
    if(passedUser) {
      setUser(passedUser)
    }
  }, [passedUser])

  return (
    <div>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={<ScreenHeading label={_.get(data, 'name', 'User Group')} />}
      />
      <Content>
        <ReusableTabs tabs={tabs} unmountOnExit={true} ariaLabel={'User Groups - Tabs'}>
          <TabPanel value='users'>
            <UserGroupUserPagination
              includeUnassigned={true}
              initialQuery={displayName}
            />
          </TabPanel>
          <TabPanel value='permissions'>
            <PermissionsTab />
          </TabPanel>
        </ReusableTabs>
      </Content>
    </div>
  )
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default UserGroupScreen