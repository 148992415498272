import { FormControl } from '@material-ui/core'
import { Dropdown } from '../../../../../components/Fields'
import { translations } from '../../../../../config'
import * as validators from '../../../../../validators'

const RoleSelector = ({ roles }) => {
  return (
    <FormControl fullWidth>
      <Dropdown
        id='roleId'
        name='roleId'
        validate={[validators.required]}
        label={translations('Role')}
        options={roles}
        fullWidth
      />
    </FormControl>
  )
}

export default RoleSelector