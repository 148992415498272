import React from 'react'
import PropTypes from 'prop-types'
import fp from 'lodash/fp'
import Chip from '../../../../components/Chip'
import { DataTableRow, DataTableCell } from '../../../../components/DataTable'
import { translations } from '../../../../config'
import { handlePublicationStatus } from '../../handlers'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import moment from 'moment'
import { styled } from '@material-ui/core'

const getUser = fp.compose(
  fp.get(['createdByUser', 'displayName'])
)

const getModifiedByUser = fp.compose(
  fp.get(['modifiedByUser', 'displayName'])
)

const getModifiedAtDate = (entry) => {
  if (!entry.modifiedByUserAt) {
    return ''
  }
  return moment(entry.modifiedByUserAt).format('DD/MM/YYYY HH:mm')
}

const ClickableRow = styled(DataTableRow)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[100]
  }
}))

const AnnouncementsRow = (props) => {
  const {
    entry
  } = props

  const dispatch = useDispatch()
  const publicationStatus = handlePublicationStatus({ publicationStatus: entry.publishType })
  return (
    <ClickableRow onClick={() => {
      dispatch(push(`/communications/announcement-management/edit/${entry.id}`))
    }}>
      <DataTableCell>{fp.get(['announcementLanguages', 0, 'title'], entry)}</DataTableCell>
      <DataTableCell align='center'>
        <Chip variant={publicationStatus.chipVariant} label={translations(publicationStatus.chipLabel)} />
      </DataTableCell>
      <DataTableCell compact nowrap align='center'>{getUser(entry)}</DataTableCell>
      <DataTableCell compact nowrap align='center'>{moment(entry.createdAt).format('DD/MM/YYYY HH:mm')}</DataTableCell>
      <DataTableCell compact nowrap align='center'>{getModifiedByUser(entry)}</DataTableCell>
      <DataTableCell compact nowrap align='right'>{getModifiedAtDate(entry)}</DataTableCell>
    </ClickableRow>
  )
}

AnnouncementsRow.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  entry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    methodType: PropTypes.oneOf([
      'CREATE',
      'UPDATE',
      'DELETE'
    ]),
    validationStatus: PropTypes.oneOf([
      'APPROVED',
      'REJECTED',
      'PENDING'
    ]),
    origin: PropTypes.oneOf([
      'orgUser'
    ])
  }).isRequired,
}

export default AnnouncementsRow
