import { colors, theme } from '../../config/theme'

const fixedThingsHeight = theme.headerHeight

const itemInner = {
  margin: '0 23px',
  borderTop: `1px solid ${colors.border}`
}

const style = theme => ({
  containerContainer: {
    position: 'relative',
    zIndex: 0
  },
  container: {
    position: 'relative',
    zIndex: -1
  },
  boxContainer: {
    '&:last-child': {
      borderBottom: `1px solid ${colors.contentBoxGrey}`
    }
  },
  groupTitleContainer: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: 0,
    '&:before': {
      content: '" "',
      display: 'block',
      marginTop: -fixedThingsHeight,
      height: fixedThingsHeight,
      visibility: 'hidden'
    }
  },
  groupTitle: {
    textAlign: 'center',
    backgroundColor: colors.alphabetListGrey,
    textTransform: 'uppercase',
    margin: 0,
    lineHeight: '30px'
  },
  itemsContainer: {
    position: 'relative',
    zIndex: 1,
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  itemContainer: {
    marginBottom: '-1px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:first-child > div': {
      borderTop: 0
    },
    '&:hover': {
      backgroundColor: colors.tableHoverGrey
    }
  },
  itemInner: {
    ...itemInner
  },
  itemInnerNarrow: {
    ...itemInner,
    margin: '0 6px'
  },
  itemInnerNoMargin: {
    ...itemInner,
    margin: 0
  },
  boxHeader: {
    lineHeight: '55px',
    border: `1px solid ${colors.contentBoxGrey}`,
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: 0,
    fontSize: 13,
    [theme.breakpoints.up('sm')]: {
      fontSize: 19
    }
  }
})

export default style
