import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { translations } from '../../../config'
import Heading from '../../../components/Heading'

const ReportConfigurationPageUnavailable = () => {
  return (
    <div>
      <Heading component='h3' variant='h3'>{translations('This page is not available with new report configurations')}</Heading>
    </div>
  )
}

export default ReportConfigurationPageUnavailable