import React, { useState } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import SelectBox from '../SelectBox/SelectBox'
import Tooltip from '@reach/tooltip'
import { translation } from '../../services/translations'
import Button from '../Button'
import _ from 'lodash'

const ComponentRow = styled.div`
  display: flex;
  flex-direction: row;
`

const ComponentColumn = styled.div`
  flex: 1 1 auto;
  & + & {
    margin-left: 10px;
  }
  ${media.greaterThan('small')`
    & + & {
      margin-left: 15px;
    }
  `}
`

const NullFlavourColumn = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: flex-end;
  & + & {
    margin-left: 10px;
  }  ${media.greaterThan('small')`
    margin-left: 15px;
  `}
`

const NullFlavourButton = styled.div`
  flex: 0 1 auto;
  padding-top: ${props => `${props.theme.form.spacing.small}px`};
  padding-bottom: ${props => `${props.theme.form.spacing.small}px`};
  ${media.greaterThan('small')`    
    padding-top: ${props => `${props.theme.form.spacing.large}px`};
    padding-bottom: ${props => `${props.theme.form.spacing.large}px`};
  `}
`

const StyledTooltipButton = styled.div`
  line-height: 13px;
  ${media.greaterThan('small')`
    line-height: 19px;
  `}
`

const NullFlavourField = (props) => {
  const {
    nullFlavours,
    render
  } = props

  const [selectedNullFlavours, setSelectedNullFlavours] = useState(false)
  const options = _.concat({ value: '', label: translation('Please select null flavour') }, (_.get(nullFlavours, 'options') || nullFlavours))

  const renderNullFlavourOptions = () => {
    return (
      <SelectBox
        {...props}
        nullFlavours={null}
        options={options}
      />
    )
  }

  return (
    <ComponentRow>
      <ComponentColumn>
        {selectedNullFlavours ? renderNullFlavourOptions() : render()}
      </ComponentColumn>
      <NullFlavourColumn>
        <NullFlavourButton>
          <Button
            onClick={() => setSelectedNullFlavours(!selectedNullFlavours)}
            buttonType={'primary'}
            light={!selectedNullFlavours}
          >
            <Tooltip
              label={translation('Null Flavour')}
              aria-label={translation('Null Flavour')}
            >
              <StyledTooltipButton>
                NF
              </StyledTooltipButton>
            </Tooltip>
          </Button>
        </NullFlavourButton>
      </NullFlavourColumn>
    </ComponentRow>
  )
}

export default NullFlavourField