import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import Heading from '../../../../components/Heading'
import ReportAcknowledgementsTable from '../ReportAcknowledgementsTable'
import ReportAcknowledgementsFilters from '../ReportAcknowledgementsFilters'

import styles from './style'

const ReportAcknowledgementsScreen = ({ classes, pageTitle }) => {
  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
      />
      <div className={classes.scrollContainer}>
        <ReportAcknowledgementsFilters />
        <ReportAcknowledgementsTable />
      </div>
    </div>
  )
}

ReportAcknowledgementsScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(ReportAcknowledgementsScreen)
