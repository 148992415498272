import { colors } from '../../../../config/theme'

  const styles = {
    wrapperContainer: {
        display: 'flex',
        marginTop: '16px'
    },
    tabRoot: {
        fontWeight: 700,
        minWidth: 50,
        width: 50,
        fontSize: '12px'
    },
    tabsContainer: {
        position: 'absolute',
        right: '0',
        top: '-18px'
    },
    inputContainer: {
        display: 'block',
        marginRight: '5px'
    },
    errorStyle: { color: colors.error },
  }
  
  export default styles