import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'

import Skeleton from '@material-ui/lab/Skeleton'

const Container = styled('div')(() => ({}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  top: (theme.headerHeight + theme.subheaderHeight),
  left: 0,
  right: 0,
  bottom: 0
}))

const Main = styled('section')(({ theme }) => ({
  flex: 1,
  background: '#fff',
  height: '100%',
  overflow: 'auto'
}))

const Side = styled('aside')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: 340,
    background: theme.palette.grey[50],
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    height: '100%',
    overflow: 'auto'
  }
}))

const SkeletonContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  '& > span': {
    marginBottom: theme.spacing(2)
  }
}))

const EditorSkeleton = ({ variant }) => {
  if (variant === 'side') {
    return (
      <SkeletonContainer>
        <Skeleton variant={'rect'} height={50} />
        <Skeleton variant={'rect'} height={50} />
        <Skeleton variant={'rect'} height={50} width={'60%'} />
        <Skeleton variant={'rect'} height={50} />
        <Skeleton variant={'rect'} height={50} width={'80%'} />
        <Skeleton variant={'rect'} height={50} />
      </SkeletonContainer>
    )
  }

  return (
    <SkeletonContainer>
      <Skeleton variant={'rect'} height={150} />
      <Skeleton variant={'rect'} height={150} />
      <Skeleton variant={'rect'} height={100} />
      <Skeleton variant={'rect'} height={100} />
      <Skeleton variant={'rect'} height={200} />
    </SkeletonContainer>
  )
}

const EditorLayout = (props) => {
  const { isLoading } = props

  return (
    <Container>
      { props.header}
      <Content>
        <Main >
          {isLoading ? <EditorSkeleton variant='main' /> : props.main}
        </Main>
        <Side>
          {isLoading ? <EditorSkeleton variant='side' /> : props.side}
        </Side>
      </Content>
    </Container>
  )
}

EditorLayout.defaultProps = {
  isLoading: false
}

EditorLayout.propTypes = {
  main: PropTypes.element.isRequired,
  side: PropTypes.element.isRequired,
  isLoading: PropTypes.bool
}

export default EditorLayout
