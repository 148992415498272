import FieldModal from './FieldModal'
import {
  getFormSyncErrors
} from 'redux-form'
import { connect } from 'react-redux'
import { constants as reportConfigurationConstants } from '../../../../../store/modules/reportConfiguration'

export default connect(state => ({
  formErrors: getFormSyncErrors(reportConfigurationConstants.FIELD_FORM)(state)
}))(FieldModal)
