import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import ImportDataPanel from '../../../../components/ImportDataPanel'
import { translations } from '../../../../config'

import style from './style'

const DrugMetaPanel = (props) => {
  const { data, cols, xsCols, showUploadModal, editable } = props

  const panelProps = {
    name: translations(`Drug Meta Panel - Name`),
    noDataMessage: translations('Drug Meta Panel - No Results'),
    onClickUpload: showUploadModal,
    data,
    cols,
    xsCols,
    editable
  }

  return <ImportDataPanel {...panelProps} />
}

export default withStyles(style)(DrugMetaPanel)
