import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, mapProps, withHandlers } from 'recompose'
import { push } from 'connected-react-router'

import { actions as communicationTemplateActions, selectors as communicationTemplateSelectors } from '../../../store/modules/communicationTemplates'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

import TemplateItem from './TemplateItem'

const mapStateToProps = (state, ownProps) => {
  const { id, channel } = ownProps
  const allTemplates = communicationTemplateSelectors.getAllEntities(state)
  return {
    template: _.get(allTemplates, id),
    channel
  }
}

const enhance = compose(
  connect(mapStateToProps),
  mapProps((props) => {
    const { template, defaultTemplateId, dispatch, channel, isSystemEmailTemplate, fallbackDefaultEmailTemplateId } = props

    let disabled
    if (channel === 'email') {
      disabled = (template.id === fallbackDefaultEmailTemplateId)
    } else {
      disabled = !_.has(template, 'organisationId')
    }
    return {
      ..._.pick(template, ['id', 'name', 'updatedAt', 'organisationId']),
      isDefault: template.id === defaultTemplateId,
      disabled: disabled,
      dispatch,
      channel,
      isSystemEmailTemplate
    }
  }),
  withHandlers({
    onEdit: ({ dispatch, id, channel, isSystemEmailTemplate }) => () => {
      dispatch(push(`/communications/edit/${channel}/${id}?isSystemEmailTemplate=${isSystemEmailTemplate}`))
    },
    onDelete: ({ dispatch, id, name, organisationId, channel, isSystemEmailTemplate}) => () => {
      modalService.action({
        title: translations('Communications - Delete Template Modal Heading'),
        text: translations('Communications - Delete Template Modal Description', { name }),
        actions: [
          {
            text: translations('Cancel')
          },
          {
            text: translations('Delete'),
            onClick: () => {
              switch(channel){
                case 'sms':
                  return dispatch(communicationTemplateActions.deleteOrganisationSmsTemplate({ organisationId, id }))
                case 'push':
                  return dispatch(communicationTemplateActions.deleteOrganisationPushTemplate({ organisationId, id }))
                default:
                  if (isSystemEmailTemplate) {
                    return dispatch(communicationTemplateActions.deleteSystemEmailTemplate({ id }))
                  }
                  return dispatch(communicationTemplateActions.deleteOrganisationEmailTemplate({ organisationId, id }))
              }
            },
            primary: true
          }
        ]
      })
    }
  })
)

export default enhance(TemplateItem)
