import { createSelector } from 'reselect'
import _ from 'lodash'

import { selectors as notificationsSelectors } from '../notifications'
import { selectors as authSelectors } from '../auth'

export const getNotificationsWithActionedValues = createSelector([
  authSelectors.getActiveUserId,
  notificationsSelectors.getNotifications
], (userId, notifications) => {
  return notifications
    .map(notification => {
      if (notification.actionedByUserId) {
        return {
          ...notification,
          isActionedByCurrentUser: _.get(notification, 'actionedByUserId') === userId
        }
      }
      return notification
    })
})
