import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import _ from 'lodash'

export const getIsLoading = state => _.get(state, 'devices.status') === PENDING
export const getError = state => _.get(state, 'devices.error')
export const getIsInitial = state => _.get(!state, 'devices.status')
export const getStatus = state => _.get(state, 'devices.status')
export const getPage = state => _.get(state, 'devices.page')
export const getSize = state => _.get(state, 'devices.size')
export const getTotal = state => _.get(state, 'devices.total')
export const getHasResults = state => _.get(state, 'devices.results', []).length > 0
export const getDevices = state => _.get(state, 'devices.results', [])
export const getDevicesForDropdown = state => _.map(_.get(state, 'devices.results', []), (result) => result)
export const getDevicesForSearchResults = state => _.map(_.get(state, 'devices.results', []), (result) => {
  return {
    ...result
  }
})

export const getHasMore = createSelector([
  getTotal,
  getDevices
], (total, results) => {
  return total && results.length < total
})

export const getdevicesAsOptions = createSelector([
  getDevices
], (devices) => {
  return (_.map(devices, device => {
    return ({ label: _.get(device, 'label'), value: _.get(device, 'name') }
   )}))
})
