import Button from '../../../../components/Button'
import * as terminologyModals from '../TerminologyModals'
import { translations } from '../../../../config'


export const DeleteArrangementButton = () => {
  const launchModal = terminologyModals.useDeleteArrangementModal({ translations })
    return <Button
        fullWidth
        onClick={launchModal}
    >{translations('Terminology - Delete Arrangement')}</Button>
}
