import React from 'react'
import TextInput from '../TextInput'
import { translation } from '../../services/translations'

const LocationFreeText = (props) => {
  const {
    input: {
      onChange,
      value
    }
  } = props

  const updateInput = (index) => (e) => {
    const updatedInput = e.target.value
    const newValue = [...value]
    newValue[index] = updatedInput
    onChange(newValue)
  }

  return (
    <>
      <TextInput
        label={translation('Location - Free Text City')}
        input={{
          ...props.input,
          value: value[0],
          onChange: updateInput(0)
        }}
      />
      <TextInput
        label={translation('Location - Free Text State')}
        input={{
          ...props.input,
          value: value[1],
          onChange: updateInput(1)
        }}
      />
    </>
  )
}

export default LocationFreeText