import _ from "lodash"

export const getStandaloneReportTitle = (report, type) => {
  const reportType = type || report?.formSchema?.name
  switch (reportType) {
    case 'devicesReport':
      return report?.extReportId + ' ' + report?.mfrRef
    case 'fscaReport':
      return report?.mfrRef + ' ' + report?.extReportId
    case 'mhraR3Report':
      return report?.extReportId
    case 'psrReport':
      return report?.reportJSON?.psrId
    case 'saeReport':
      return report?.reportJSON?.cipNumber
    case 'trendReport':
      return report?.extReportId + ' ' + report?.mfrRef
    default:
      return '-'
  }

} 

export const noPermissionRoles = [2,6]