import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  userGroups: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.FETCH_ALL_USER_GROUPS_FOR_CURRENT_PLATFORM:
      return reducers.fetchAllUserGroupsForCurrentPlatform(state, action)
    case constants.FETCH_ALL_USER_GROUPS_FOR_ORGANISATION:
      return reducers.fetchAllUserGroupsForOrganisation(state, action)
    case constants.FETCH_USER_GROUP:
      return reducers.fetchUserGroup(state, action)
    case constants.CREATE_USER_GROUP_FOR_ORGANISATION:
      return reducers.createUserGroupForOrganisation(state, action)
    case constants.UPDATE_USER_GROUP:
      return reducers.updateUserGroup(state, action)
    case constants.DELETE_USER_GROUP:
      return reducers.deleteUserGroup(state, action)
    case constants.FETCH_USER_GROUP_USERS:
      return reducers.fetchUserGroupUsers(state, action)
    case constants.UPDATE_USER_GROUP_USERS:
      return reducers.updateUserGroupUsers(state, action)
      case constants.FETCH_USER_GROUP_LOCATIONS:
        return reducers.fetchUserGroupLocations(state, action)
      case constants.UPDATE_USER_GROUP_LOCATIONS:
        return reducers.updateUserGroupLocations(state, action)
    default:
      return state
  }
}