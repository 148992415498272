import { FAIL, PENDING, SUCCESS } from '../../middleware/redux-promise'
import * as constants from './constants'

class UserGroupReducers {
  fetchAllUserGroupsForCurrentPlatform = (state, action) => {
    const userGroups = action.result
    return {
      ...state,
      userGroups,
    }
  }

  fetchAllUserGroupsForOrganisation = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllUserGroupsForOrganisationPending(state, action)
      case SUCCESS:
        return this.fetchAllUserGroupsForOrganisationSuccess(state, action)
      case FAIL:
        return this.fetchAllUserGroupsForOrganisationFail(state, action)
      default:
        return state
    }
  }
  fetchAllUserGroupsForOrganisationPending = (state, action) => {
    return {
      ...state,
      orgUserGroups: {
        status: PENDING,
        error: undefined
      }
    }
  }
  fetchAllUserGroupsForOrganisationSuccess = (state, action) => {
    return {
      ...state,
      orgUserGroups: {
        status: SUCCESS,
        data: action.result,
        error: undefined
      }
    }
  }
  fetchAllUserGroupsForOrganisationFail = (state, action) => {
    return {
      ...state,
      orgUserGroups: {
        status: FAIL,
        error: action.error
      }
    }
  }

  fetchUserGroup = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchUserGroupPending(state, action)
      case SUCCESS:
        return this.fetchUserGroupSuccess(state, action)
      case FAIL:
        return this.fetchUserGroupFail(state, action)
      default:
        return state
    }
  }
  fetchUserGroupPending = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: PENDING,
        error: undefined
      }
    }
  }
  fetchUserGroupSuccess = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: SUCCESS,
        data: action.result,
        error: undefined
      }
    }
  }
  fetchUserGroupFail = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: FAIL,
        error: action.error
      }
    }
  }

  createUserGroupForOrganisation = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.createUserGroupForOrganisationPending(state, action)
      case SUCCESS:
        return this.createUserGroupForOrganisationSuccess(state, action)
      case FAIL:
        return this.createUserGroupForOrganisationFail(state, action)
      default:
        return state
    }
  }

  createUserGroupForOrganisationPending = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: PENDING,
        error: undefined,
      }
    }
  }

  createUserGroupForOrganisationSuccess = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: SUCCESS,
        error: undefined
      }
    }
  }

  createUserGroupForOrganisationFail = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: FAIL,
        error: action.error
      }
    }
  }

  updateUserGroup = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.updateUserGroupPending(state, action)
      case SUCCESS:
        return this.updateUserGroupSuccess(state, action)
      case FAIL:
        return this.updateUserGroupFail(state, action)
      default:
        return state
    }
  }

  updateUserGroupPending = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: PENDING,
        error: undefined,
      }
    }
  }

  updateUserGroupSuccess = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: SUCCESS,
        error: undefined
      }
    }
  }

  updateUserGroupFail = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: FAIL,
        error: action.error
      }
    }
  }

  deleteUserGroup = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.deleteUserGroupPending(state, action)
      case SUCCESS:
        return this.deleteUserGroupSuccess(state, action)
      case FAIL:
        return this.deleteUserGroupFail(state, action)
      default:
        return state
    }
  }

  deleteUserGroupPending = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: PENDING,
        error: undefined,
      }
    }
  }

  deleteUserGroupSuccess = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: SUCCESS,
        error: undefined
      }
    }
  }

  deleteUserGroupFail = (state, action) => {
    return {
      ...state,
      userGroup: {
        status: FAIL,
        error: action.error
      }
    }
  }

  fetchUserGroupUsers = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchUserGroupUsersPending(state, action)
      case SUCCESS:
        return this.fetchUserGroupUsersSuccess(state, action)
      case FAIL:
        return this.fetchUserGroupUsersFail(state, action)
      default:
        return state
    }
  }

  fetchUserGroupUsersPending = (state, action) => {
    return {
      ...state,
      users: {
        status: PENDING,
        error: undefined,
      }
    }
  }

  fetchUserGroupUsersSuccess = (state, action) => {
    return {
      ...state,
      users: {
        status: SUCCESS,
        results: action.result,
        error: undefined
      }
    }
  }

  fetchUserGroupUsersFail = (state, action) => {
    return {
      ...state,
      users: {
        status: FAIL,
        error: action.error
      }
    }
  }

  updateUserGroupUsers = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.updateUserGroupUsersPending(state, action)
      case SUCCESS:
        return this.updateUserGroupUsersSuccess(state, action)
      case FAIL:
        return this.updateUserGroupUsersFail(state, action)
      default:
        return state
    }
  }

  updateUserGroupUsersPending = (state, action) => {
    return {
      ...state,
      users: {
        ...state.users,
        update: {
          status: PENDING,
          error: undefined,
        }
      }
    }
  }

  updateUserGroupUsersSuccess = (state, action) => {
    return {
      ...state,
      users: {
        ...state.users,
        update: {
          status: SUCCESS,
          error: undefined
        }
      }
    }
  }

  updateUserGroupUsersFail = (state, action) => {
    return {
      ...state,
      users: {
        ...state.users,
        update: {
          status: FAIL,
          error: action.error
        }
      }
    }
  }

  fetchUserGroupLocations = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchUserGroupLocationsPending(state, action)
      case SUCCESS:
        return this.fetchUserGroupLocationsSuccess(state, action)
      case FAIL:
        return this.fetchUserGroupLocationsFail(state, action)
      default:
        return state
    }
  }

  fetchUserGroupLocationsPending = (state, action) => {
    return {
      ...state,
      locations: {
        status: PENDING,
        error: undefined,
      }
    }
  }

  fetchUserGroupLocationsSuccess = (state, action) => {
    return {
      ...state,
      locations: {
        status: SUCCESS,
        results: action.result,
        error: undefined
      }
    }
  }

  fetchUserGroupLocationsFail = (state, action) => {
    return {
      ...state,
      locations: {
        status: FAIL,
        error: action.error
      }
    }
  }

  updateUserGroupLocations = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.updateUserGroupLocationsPending(state, action)
      case SUCCESS:
        return this.updateUserGroupLocationsSuccess(state, action)
      case FAIL:
        return this.updateUserGroupLocationsFail(state, action)
      default:
        return state
    }
  }

  updateUserGroupLocationsPending = (state, action) => {
    return {
      ...state,
      locations: {
        ...state.locations,
        update: {
          status: PENDING,
          error: undefined,
        }
      }
    }
  }

  updateUserGroupLocationsSuccess = (state, action) => {
    return {
      ...state,
      locations: {
        ...state.locations,
        update: {
          status: SUCCESS,
          error: undefined
        }
      }
    }
  }

  updateUserGroupLocationsFail = (state, action) => {
    return {
      ...state,
      locations: {
        ...state.locations,
        update: {
          status: FAIL,
          error: action.error
        }
      }
    }
  }

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new UserGroupReducers()