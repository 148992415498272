import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import { LinearProgress } from '@material-ui/core'

class ReportsLoadingBar extends Component {
  render () {
    const { isLoading, classes } = this.props
    return (
      <div className={classes.container}>
        {isLoading && <LinearProgress mode='indeterminate' style={{ height: 2 }} />}
      </div>
    )
  }
}

ReportsLoadingBar.propTypes = {
  isLoading: PropTypes.bool
}

export default withStyles(style)(ReportsLoadingBar)
