import _ from 'lodash'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { getFormError } from 'redux-form'
import SelectOrganisationForm from './MigrationForm'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as themeSelectors } from '../../../store/modules/themes'
import { RESET_PASSWORD_FORM } from '../../ResetPassword/ResetPasswordFrom/ResetPasswordForm'

const mapStateToProps = state => {
  return {
    themeId: themeSelectors.getCurrentThemeId(state),
    user: authSelectors.getCurrentUser(state),
    formError: getFormError(RESET_PASSWORD_FORM)(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, themeId, user }) => (values) => {
      const { password } = values
      const resetToken = _.get(user, 'passwordPolicy.resetToken')
      const email = _.get(user, 'email')

      return dispatch(authActions.changePassword({
        resetToken,
        password,
        themeId
      }))
      .then(() => {
        return dispatch(authActions.login({ email, password }))
      })
    }
  })
)(SelectOrganisationForm)
