import React from 'react'
import fp from 'lodash/fp'
import * as hooks from './hooks'
import Autocomplete from '../Autocomplete'
import { withStyles } from '@material-ui/styles'
import * as utils from './utils'
import style from './style'
import { translations } from '../../../config'

const AsyncLookUpInput = (props) => {
    const {
        value: selectedOption,
        onChange
    } = props

    const {
        minimumSearchLength,
        asyncFunction,
        renderOption,
        multiple
    } = hooks.useImplementation({ props })

    const {
        useApiRequest,
        data
    } = hooks.useApiRequestHandler({
        minimumSearchLength,
        asyncFunction
    })

    const [inputValue, setInputValue] = React.useState(selectedOption?.label || '')

    useApiRequest({ value: inputValue })

    const {
        entity,
        isLoading,
        isEmpty,
        isInitial
    } = data

    const { loadingText, loading } = utils.handleAsyncStatusMessagesToUser({ isLoading, isEmpty, isInitial, minimumSearchLength })
    const noOptionsText = translations('Organisation Lookup - No Options Text')
    return (
        <Autocomplete
            {...props}
            getOptionLabel={utils.getOptionLabel}
            getOptionSelected={utils.getOptionSelected}
            renderOption={renderOption}
            value={selectedOption}
            inputValue={inputValue}
            options={entity}
            freeSolo={false}
            multiple={multiple}
            handleInputChange={utils.handleInputChange({
                onInputValueChange: setInputValue
            })}
            handleChange={utils.handleOptionChange({
                onChange,
                // With multiple we don't want the box to keep disappearing on each selection
                onInputValueChange: multiple ? null : setInputValue
            })}
            onBlur={utils.handleOnBlur({
                // With multiple we don't want the input value to be set to an array
                onInputValueChange: multiple ? () => setInputValue('') : setInputValue,
                selectedOption
            })}
            loading={loading}
            loadingText={loadingText}
            filterOptions={fp.identity}
            noOptionsText={noOptionsText}
        />
    )

}

export default withStyles(style)(AsyncLookUpInput)
