import i18next from 'i18next'

import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import digitalStoreSdk from '../../../digitalStoreSdk'

class HospitalsUKactions {
  _searchHospitals = (next = false) => ({ query }) => ({
    type: next ? constants.SEARCH_HOSPITALS_NEXT : constants.SEARCH_HOSPITALS,
    query,
    promise: (dispatch, getState) => {
      const state = getState()
      const size = selectors.getSize(state)

      const searchParams = {
        query,
        size,
      }

      if (next) searchParams.page = selectors.getPage(state) + 1

      return digitalStoreSdk.hospitals.searchHospitals(searchParams)
    }
  })

  searchHospitals = this._searchHospitals(false)

  searchHospitalsNext = this._searchHospitals(true)

  fetchHospitals = () => ({
    type: constants.FETCH_HOSPITALS,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.hospitals.fetchAllHospitals()
    }
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new HospitalsUKactions()
