import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Autocomplete from '../Autocomplete'

const MultiSelectAutocompleteDropdown = (props) => {
  const {
    onChange: defaultOnChange,
    handleChange,
    multiple,
    options,
    handleChangeOtherFields,
    value: initialValue
  } = props

  const [value, setValue] = useState(initialValue || undefined)

  useEffect(() => {
    if (multiple) { setValue([]) }
  }, [multiple])

  /**
   * handleChange is passed from the component for extra functionality
   * onChange is passed from redux forms usually
   * onChange will always be called
   * If we do not want onChange from the redux forms to run for whatever reason (usually bugs)
   * Then pass your 'handleChange' as 'onChange'
   */
  const onChange = (event, option) => {
    if (_.isFunction(handleChangeOtherFields)) {
      const dataRow = _.find(options, { value: option.value })
      if (!_.isNil(dataRow)) {
        handleChangeOtherFields(_.get(dataRow, 'meta'))
      }
    }
    const setNewValue = () => {
      if (!multiple) {
        setValue(option.value)
        return option.value
      }
      const values = _.map(option, o => o.value)
      setValue(values)
      return values
    }
    const newValue = option ? setNewValue() : null
    if (_.isFunction(handleChange)) {
      handleChange(newValue)
    }
    if (_.isFunction(defaultOnChange)) {
      return defaultOnChange(newValue)
    }
  }

  // onBlur having side effects
  const modifiedProps = _.omit(props, ['value', 'onBlur', 'handleChange', 'onChange'])

  return (
    <Autocomplete
      {...modifiedProps}
      onChange={onChange}
      value={value}
      getOptionLabel={(option) => option.label}
      options={options}
    />
  )
}
export default MultiSelectAutocompleteDropdown