const style = theme => ({
  dropdown: {
    width: '15em',
    margin: '0 0.5em',
    textAlign: 'left',
    height: '80px'
  },
  field: {
    width: '100%'
  },
  mobileDropdownsContainer: {
    display: 'grid',
    gridGap: '18px',
    margin: 18
  },
  lastUpdated: {
    padding: '5px'
  },
  headerWithDropdown: {
    height: '100px'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 20px 0',
    '@media (max-width: 814px)': {
      flexWrap: 'wrap',
        '& > div': {
          width: '47%',
        }
    },
    '@media (max-width: 599px)': {
        '& > div': {
          width: '100%',
        }
    }
  }
})

export default style
