import _ from 'lodash'
import mergeby from 'mergeby'

import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import { promiseReducer } from '../../util'

class PlatformsReducers {
  fetchAllPlatforms = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllPlatformsPending(state, action)
      case SUCCESS:
        return this.fetchAllPlatformsSuccess(state, action)
      case FAIL:
        return this.fetchAllPlatformsFail(state, action)
      default:
        return state
    }
  }
  fetchAllPlatformsPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchAllPlatformsSuccess = (state, action) => {
    return {
      ...state,
      results: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchAllPlatformsFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }
  fetchPlatformForDomain = (state, action) => {
    return {
      ...state,
      currentPlatform: action.result
    }
  }
  fetchPlatformAnnouncents = promiseReducer(
    (state, action) => {
      const announcements = action.result
      return {
        ...state,
        currentPlatform: {
          ...state.currentPlatform,
          announcements
        }
      }
    }
  )
  fetchPlatformOrOrganisationsSources = promiseReducer(
    (state, action) => {
      const sources = action.result
      return {
        ...state,
        currentPlatform: {
          ...state.currentPlatform,
          organisationSources: sources
        }
      }
    }
  )
}

export default new PlatformsReducers()
