export const ACTION_ENUM = [
    'SET_FIELD',
    'CLEAR_FIELD',
    "APPEND_TO_FIELD",
    'SEND_EMAIL',
    'AWAIT_REVIEW',
    'REMOVE_REPEATABLE',
    'USE_DICTIONARY'
]
export const REQUEST_STATUS_TYPE = {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    FULFILLED: 'FULFILLED',
    IS_EMPTY: 'IS_EMPTY',
    REJECTED: 'REJECTED'
}
export const NAME = 'integrationManagement'
