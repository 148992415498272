import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { translations } from '../../../../config'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import { actions as reportsActions, selectors as reportsSelectors } from '../../../../store/modules/reports'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import ReportsSearchTable from './ReportsSearchTable'
import { getColumnName, isColumnVisible } from '../../helpers'
import {
  getStatusLabel,
  displaySafetyReportId,
  getLocationString,
  hasLatestVersion,
  getTypeOfReportValue,
  getApplication,
  getSource,
  getReportFormStatus,
  getShotStatus,
  getReactionTypeWithEventCategoryAndStorageSubcategory,
  getReactionOrEventRelatedTo
} from './utils'

export const REPORTER_STATUSES = {
  SUBMIT_NOTIFICATION: 'Submit Notification',
  NO_ACTION: 'No Action',
  SUBMIT_CONFIRMATION: 'Submit Confirmation',
  FOOTNOTE_REQUIRED: 'Footnote required'
}

export const REGULATOR_STATUSES = {
  NOT_RECEIVED: 'Not received',
  REVIEW_PENDING: 'Review pending',
  CONFIRMATION_REQUIRED: 'Confirmation required',
  PENDING_MHRA_REVIEW: 'Pending MHRA review',
  CLOSED: 'Closed',
  EXCLUDED: 'Excluded'
}

const mapStateToProps = state => {
  const results = reportsSelectors.getResults(state)
  const hasMore = reportsSelectors.getHasMore(state)
  const isLoading = reportsSelectors.getIsLoading(state)
  const hasSearched = reportsSelectors.getReportsHasBeenSearched(state)
  const error = reportsSelectors.getError(state)
  const currentUserId = authSelectors.getActiveUserId(state)
  const hasCreateAckPermissions = platformSelectors.getHasCreateAckPermissions(state)
  const columnMappings = platformSelectors.getReportManagementColumnMappings(state)
  const isShowReportStatusBadge = platformSelectors.getIsShowReportStatusBadge(state)
  const isSabreReportType = platformSelectors.getIsSabreReportType(state)
  const n3URL = platformSelectors.getN3URL(state)
  const nonN3URL = platformSelectors.getNonN3URL(state)

  const noResults = !isLoading && !(results || []).length

  return {
    hasCreateAckPermissions,
    columnMappings,
    isLoading,
    hasMore,
    results,
    noResults,
    hasSearched,
    currentUserId,
    error,
    isShowReportStatusBadge,
    isSabreReportType,
    n3URL,
    nonN3URL
  }
}

export default compose(
  withStyles(style),
  connect(mapStateToProps),
  withHandlers({
    loadMore: ({ dispatch, isLoading }) => () => {
      if (!isLoading) {
        dispatch(reportsActions.searchReportsNext())
      }
    }
  }),
  withPropsOnChange(
    ['results', 'columnMappings'],
    props => {
      const {
        results,
        columnMappings,
        isShowReportStatusBadge,
        isSabreReportType,
        n3URL,
        nonN3URL
      } = props
      let rows = []
      if (results && results.length) {
        rows = results.map(report => {
          const {
            id,
            updatedAt,
            messageNumber,
            status,
            formSchema,
            archivedAt,
            user,
            senderId,
            typeOfReport,
            overrideFormSchemaName,
            mfrRef,
            reportJSON,
            submissionMeta,
            location
          } = report

          const {
            eventDateNtf,
            reactionStartDate,
            localReferenceNumber,
            regulatorStatus,
            reporterStatus,
            reportStatus
          } = reportJSON || {}
          const reportType = _.get(formSchema, 'name')

          const extReportId = displaySafetyReportId({ report })
          const userName = user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ''
          const reportForm = overrideFormSchemaName || _.get(formSchema, 'name')

          const data = [
            [
              getColumnName('Date', columnMappings),
              moment(updatedAt).format('DD/MM/YYYY HH:mm'),
              { xs: 1, cardDatumWidth: '50%' },
              isColumnVisible('Date', columnMappings)
            ],
            [
              getColumnName('Sender', columnMappings),
              senderId,
              { xs: 1, cardDatumWidth: '50%' },
              isColumnVisible('Sender', columnMappings)
            ],
            [
              getColumnName('User', columnMappings),
              userName,
              { xs: 1, cardDatumWidth: '50%' },
              isColumnVisible('User', columnMappings)
            ],
            [
              getColumnName('Report Form', columnMappings),
              getReportFormStatus(isShowReportStatusBadge, reportForm, reportStatus),
              { xs: 2, cardDatumWidth: '50%' },
              isColumnVisible('Report Form', columnMappings)
            ],
            [
              getColumnName('Location'),
              location ? getLocationString(location) : translations('Location - Unspecified Regions'),
              { xs: 2, cardDatumWidth: '100%' },
              isColumnVisible('Location', columnMappings)
            ],
            [
              getColumnName('Type of Report', columnMappings),
              getTypeOfReportValue(typeOfReport),
              { xs: 1, cardDatumWidth: '50%' },
              isColumnVisible('Type of Report', columnMappings)
            ],
            [
              getColumnName('Message Number', columnMappings),
              messageNumber,
              { xs: 2, cardDatumWidth: '100%', useRowOnClick: true },
              isColumnVisible('Message Number', columnMappings)
            ],
            [
              getColumnName('Safety Report Id', columnMappings),
              (isSabreReportType && regulatorStatus === REGULATOR_STATUSES.NOT_RECEIVED && reporterStatus == REPORTER_STATUSES.SUBMIT_NOTIFICATION) ? translations('Yet to be assigned') : extReportId,
              { xs: 2, cardDatumWidth: '100%' },
              isColumnVisible('Safety Report Id', columnMappings)
            ],
            [
              getColumnName('Manufacturer Reference Number', columnMappings),
              mfrRef,
              { xs: 2, cardDatumWidth: '100%' },
              isColumnVisible('Manufacturer Reference Number', columnMappings)
            ],
            [
              getColumnName('Status', columnMappings),
              getStatusLabel(status),
              { xs: 1, cardDatumWidth: '100%' },
              isColumnVisible('Status', columnMappings)
            ],
            [
              getColumnName('Application', columnMappings),
              getApplication({ report }),
              { xs: 1, cardDatumWidth: '50%' },
              isColumnVisible('Application', columnMappings)
            ],
            [
              getColumnName('Source', columnMappings),
              getSource({ report }),
              { xs: 1, cardDatumWidth: '50%' },
              isColumnVisible('Source', columnMappings)
            ],
            [
              getColumnName('Latest Version', columnMappings),
              hasLatestVersion({ report }),
              { xs: 1, cardDatumWidth: '50%', align: 'center' },
              isColumnVisible('Latest Version', columnMappings)
            ],
            [
              getColumnName('Reaction / Event related to', columnMappings),
              getReactionOrEventRelatedTo({ reportType, reportJSON }),
              { xs: 2, cardDatumWidth: '100%' },
              isColumnVisible('Reaction / Event related to', columnMappings)
            ],
            [
              getColumnName('Reaction type / Event category + Storage Subcategory', columnMappings),
              getReactionTypeWithEventCategoryAndStorageSubcategory({ reportType, reportJSON }),
              { xs: 3, cardDatumWidth: '100%' },
              isColumnVisible('Reaction type / Event category + Storage Subcategory', columnMappings)
            ],
            [
              getColumnName('Incident Date', columnMappings),
              reportType === 'sabreSAE' ? moment(eventDateNtf).format('DD/MM/YYYY') : moment(reactionStartDate).format('DD/MM/YYYY'),
              { xs: 1, cardDatumWidth: '100%' },
              isColumnVisible('Incident Date', columnMappings)
            ],
            [
              getColumnName('Local Ref No', columnMappings),
              localReferenceNumber,
              { xs: 2, cardDatumWidth: '100%' },
              isColumnVisible('Local Ref No', columnMappings)
            ],
            [
              getColumnName('Reporter action', columnMappings),
              reporterStatus ? translations(`SABRE Reporter Status - ${reporterStatus}`) : '',
              { xs: 1, cardDatumWidth: '100%' },
              isColumnVisible('Reporter action', columnMappings)
            ],
            [
              getColumnName('MHRA Status', columnMappings),
              regulatorStatus ? translations(`SABRE Regulator Status - ${regulatorStatus}`) : '',
              { xs: 1, cardDatumWidth: '100%' },
              isColumnVisible('MHRA Status', columnMappings)
            ],
            [
              getColumnName('SHOT Status', columnMappings),
              getShotStatus(submissionMeta, n3URL, nonN3URL),
              { xs: 1, cardDatumWidth: '100%' },
              isColumnVisible('SHOT Status', columnMappings)
            ]
          ].map(datum => {
            const [label, value, props, visible] = datum
            if (!visible) { return }
            return [translations(label), value, props]
          }).filter(Boolean)

          return {
            key: id,
            deactivated: archivedAt,
            data
          }
        })
      }
      return { rows }
    }
  )
)(ReportsSearchTable)
