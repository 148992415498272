import React from 'react'
import { styled } from '@material-ui/core'
import { FormControl } from '@material-ui/core'

const Control = styled(FormControl)(({ theme }) => ({
  width: '100%',
  paddingRight: theme.spacing(2),
  paddingBottom: 0,
  marginTop: theme.spacing(2),
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    height: 0,
    width: 0
  },
  '& .MuiFormLabel-root': {
    position: 'static'
  }
}))

const FullWidthFormControl = (props) => {
  const { children, ...rest } = props
  
  return (
    <Control {...rest}>
      {children}
    </Control>
  )
}

export default FullWidthFormControl