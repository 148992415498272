import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Input } from '@material-ui/core'
import InputLabel from '../../InputLabel'
import Dropdown from '../Dropdown'

import style from './style'

const NullFlavourInput = ({
  classes,
  nullFlavours,
  setNullFlavour,
  nullFlavourValue,
  label,
  shrink,
  checkProps,
  props,
}) => {

  return (
    <div className={classes.wrapper}>
      <div className={classes.fieldContainer}>
        <Dropdown
          meta={props.meta}
          options={nullFlavours}
          value={nullFlavourValue}
          onChange={e => setNullFlavour(e.target.value)}
          style={{ width: '100px' }}
          noNullOption={true}
          disableUnderline={true}
        />
      </div>
      <div className={classes.inputContainer}>
        <InputLabel htmlFor={label} key='label' shrink={shrink}>
          {label}
        </InputLabel>
        <Input
          {...props}
          disabled={true}
          value={checkProps ? checkProps.label : nullFlavourValue}
        />
      </div>
    </div>
  )
}
NullFlavourInput.propTypes = {
  classes: PropTypes.shape({}),
  nullFlavours: PropTypes.array,
  setNullFlavour: PropTypes.func,
  nullFlavourValue: PropTypes.string,
  checkProps: PropTypes.object,
  props: PropTypes.object
}

export default withStyles(style)(NullFlavourInput)
