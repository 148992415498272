import { colors } from '../../config/theme'

const styles = theme => {
  return {
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.lightGrey,
      [theme.breakpoints.down('xs')]: {
        paddingTop: '100%',
        width: '100%'
      }
    },
    inner: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        top: 0,
        left: 0
      }
    },
    ratioContainer: {
      width: '100%'
    },
    shadowOverlay: {
      position: 'absolute',
      width: '100%',
      height: '98%',
      top: 0,
      left: 0,
      background: 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%)'
    },
    title: {
      position: 'absolute',
      bottom: '8%',
      width: '90%',
      textAlign: 'center',
      left: '5%'
    },
    titleText: {
      fontSize: 14,
      lineHeight: '1.1',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ['@media (min-width: 600px) and (max-width: 700px)']: {
        fontSize: 12
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 16
      }
    },
    image: {
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)'
      }
    },
    parentCorner: {
      position: 'absolute',
      right: '5px',
      top: '5px',
      width: 'auto',
      '& .MuiBadge-badge': {
        position: 'relative'
      }
    },
    smallIconImg: {
      maxWidth: '40%',
      float: 'right'
    }
  }
}

export default theme => styles(theme)
