import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormHelperText } from '@material-ui/core'
import InputLabel from '../../InputLabel'

import Dropdown from '../Dropdown'
import styles from './style'
import _ from 'lodash'

const hours = _.range(0, 24).map(digit => _.padStart(digit, 2, 0))
const mins = _.range(0, 60).map(digit => _.padStart(digit, 2, 0))

const Time = ({
  label,
  value,
  onChange,
  onBlur,
  options,
  passedProps,
  meta: { error, touched } = {},
  validate,
  classes,
  noNullOption = false
}) => {
  return ([
    <InputLabel
      FormControlClasses={{
        focused: classes.floatingLabelFocusStyle
      }}
      htmlFor={label}
      key='label'
      shrink
      staticLabel
    >
      {label}
    </InputLabel>,
    <div key='inputs' className={classes.timeWrapper}>
      <div>
        <Dropdown
          givenClasses={{ overridingRootClasses: classes.dropdownRoot }}
          value={value.hour}
          onChange={(e) => onChange(e, 'hour')}
          onBlur={(e) => onBlur(e, 'hour')}
          options={hours}
          label='Hours'
          noFloatingLabel
          noNullOption={noNullOption}
        />
      </div>
      <div className={classes.marginLeft}>
        <Dropdown
          givenClasses={{ overridingRootClasses: classes.dropdownRoot }}
          value={value.minute} onChange={(e) => onChange(e, 'minute')}
          onBlur={(e) => onBlur(e, 'minute')}
          options={mins}
          disabled={!value.hour}
          label='Minutes'
          noFloatingLabel
          noNullOption={noNullOption}
        />
      </div>
    </div>,
    <FormHelperText className={classes.errorStyle} key='helper-text' role='alert'>{error}</FormHelperText>
  ])
}

Time.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    number: PropTypes.string
  })
}

export default withStyles(styles)(Time)
