import React, { Component } from 'react'

const Editable = WrappedComponent => class EditableWrapper extends Component {
  state = {
    editing: !!this.props.editing
  }
  _isEvent = (value) => {
    return value && value.target
  }
  UNSAFE_componentWillMount () {
    this._toggleEdit = editing => {
      this.setState({ editing })
    }
  }
  componentWillUnmount () {
    this._toggleEdit = undefined
  }
  toggleEdit = (editing = !this.state.editing) => {
    this._toggleEdit && this._toggleEdit(this._isEvent(editing) ? !this.state.editing : editing)
  }
  render () {
    const { editing } = this.state
    return <WrappedComponent {...this.props} editing={editing} toggleEdit={this.toggleEdit} />
  }
}

export default Editable
