import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'

import Routes from './Routes'

const startApp = () => {
  ReactDOM.render((
   <Routes />
  ), document.getElementById('root'))
}

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false)
} else {
  startApp()
}
