import { connect } from 'react-redux'

import { selectors as organisationDetailsSelectors } from '../../../../../store/modules/organisationDetails'
import { selectors as platformSelectors } from '../../../../../store/modules/platforms'

import Terms from '../../../../../components/Terms'

const mapStateToProps = (state) => {
  const organisationDetails = organisationDetailsSelectors
    .getCurrentOrganisationDetails(state)
  const platformPrivacyPolicyLink = platformSelectors.getPrivacyPolicyLink(state)
  const { privacyPolicyLink } = organisationDetails || { privacyPolicyLink: platformPrivacyPolicyLink }
  return { privacyPolicyLink }
}

export default connect(mapStateToProps)(Terms)
