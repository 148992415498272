import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'

import modalService from '../../services/modalService'
import { translations } from '../../config'
import BasicModalOverlay from '../BasicModalOverlay'
import Button from '../Button'

import style from './style'

class FiltersContainer extends Component {
  openFilterModal = () => {
    return modalService.open({
      component: (props) => <BasicModalOverlay {...props}>
        {this.props.children}
      </BasicModalOverlay>,
      actions: [{
        text: translations('Apply filters')
      }]
    })
  }

  render () {
    const { classes, numberOfActiveFilters, children } = this.props

    let buttonText = translations('Filter Results Button Text')
    if (numberOfActiveFilters) {
      buttonText += ` (${translations(
        'Filter Results Button Number Of Active Filters',
        { numberOfActiveFilters }
      )})`
    }

    return <Fragment>
      <Hidden smDown>
        {children}
      </Hidden>
      <Hidden mdUp>
        <Button
          onClick={this.openFilterModal}
          children={buttonText}
          buttonType='primary'
          className={classes.filterModalButton}
        />
      </Hidden>
    </Fragment>
  }
}
export default withStyles(style)(FiltersContainer)
