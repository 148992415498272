import React, { useEffect } from 'react'
import StandardForm from '../StandardForm'
import LinkButton from '../../LinkButton'
import { addResources } from '../../../services/translations'
import { SdkProvider, useSdk } from '../../../services/sdk'
import { FORM_ERROR } from 'final-form'
import { Trans } from 'react-i18next'
import { translation } from '../../../services/translations'

const AUTH_ERROR_CODES= {
  LOGIN_FAILED: 'RA-02-01',
  LOGIN_FAILED_DEACTIVATED: 'RA-02-03'
}

const LoginForm = ({
  onForgottenPassword,
  onRegister,
  onSuccess,
  onError,
  handleSubmit,
  translations
}) => {

  useEffect(() => {
    if (translations) addResources(translations, 'auth')
  }, [])

  const handleRegister = () => {
    onRegister && onRegister()
  }

  const handleForgottenPassword = () => {
    onForgottenPassword && onForgottenPassword()
  }

  const actions = [
    { label: translation('Forgotten Password?'), onClick: handleForgottenPassword, light: true }
  ]

  const onSubmit = async (values) => {
    try {
      const user = await handleSubmit(values)
      onSuccess && onSuccess(user)
      return user
    } catch (error) {
      const [message, code] = error.message.split('|')

      const messageMapping= {
        [AUTH_ERROR_CODES.LOGIN_FAILED]: (
          <Trans ns="auth" i18nKey={code}>
            {message}. <LinkButton inheritFontSize onClick={handleRegister}>Register here</LinkButton> or <LinkButton inheritFontSize onClick={handleForgottenPassword}>reset your password</LinkButton>
          </Trans>
        ),
        [AUTH_ERROR_CODES.LOGIN_FAILED_DEACTIVATED]: <Trans ns="auth" i18nKey={code}>Account Deactivated</Trans>
      }

      const errorMessage = messageMapping[code] || message

      onError && onError(error)
      return { [FORM_ERROR]: errorMessage }
    }
  }

  return (
    <StandardForm
      schemaName={'login'}
      onSubmit={onSubmit}
      actions={actions}
      submitText={translation('Sign In')}
      fullWidthSubmit={false}
    />
  )
}

const WrappedLoginForm = (props) => {
  const {
    baseUrl,
    organisationId,
    platformId,
    ...rest
  } = props

  return (
    <SdkProvider
      baseUrl={baseUrl}
      organisationId={organisationId}
      platformId={platformId}
    >
      <LoginForm {...rest} />
    </SdkProvider>
  )
}

export default WrappedLoginForm