import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import { MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

class SubmissionReportsMoreMenu extends React.Component {
  state = {
    anchorEl: null
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleSelection = (value) => {
    const { options } = this.props
    const selectedOption = _.find(options, { value })

    if (_.isFunction(selectedOption.action)) {
      selectedOption.action()
    }

    this.handleClose()
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render () {
    const { options, menuId } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? menuId : null}
          aria-haspopup='true'
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={menuId}
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{}}
        >
          {options.map(option => (
            <MenuItem key={option.value} onClick={() => this.handleSelection(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

SubmissionReportsMoreMenu.defaultProps = {
  options: [],
  menuId: 'more-menu'
}

SubmissionReportsMoreMenu.propTypes = {
  options: PropTypes.array.isRequired,
  menuId: PropTypes.string.isRequired
}

export default SubmissionReportsMoreMenu
