import _ from 'lodash'
import React from 'react'
import VigilanceHubForm from '@redant/mhra-form-ui-library'
import { useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import { baseUrl } from '../../../../../digitalStoreSdk'

import { translations } from '../../../../../config'
import toastService from '../../../../../services/toastService'

import Button from '../../../../../components/Button'
import ReportLoader from '../../../../../components/ReportLoader'

import downloadXml from '../../../../../helpers/downloadXml'

// Actions
import { actions as ackDetailsActions } from '../../../../../store/modules/ackDetails'

// Selectors
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { selectors as themeSelectors } from '../../../../../store/modules/themes'
import { selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import { selectors as ackDetailsSelectors } from '../../../../../store/modules/ackDetails'

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
}))

const ReportContainer = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  width: '100%',
  maxWidth: 720
}))

const AckActions = styled('div')(({ theme }) => ({
  marginTop: -theme.spacing(2),
  paddingBottom: theme.spacing(2)
}))

const AcknowledgementForm = (props) => {
  const { isReadOnly, isNewFormMode = false } = props
  const dispatch = useDispatch()

  const report = useSelector(reportDetailsSelectors.getReport)
  const reportId = _.get(report, 'id')
  const acknowledgementReport = _.get(report, 'reportJSON')

  const acknowledgementJSON = useSelector(ackDetailsSelectors.getDetails)
  const acknowledgementXML = useSelector(ackDetailsSelectors.getXML)
  const acknowledgementFileName = useSelector(ackDetailsSelectors.getFileName)

  const theme = useSelector(themeSelectors.getThemeForNewReportBetaForms)
  const auth = useSelector(authSelectors.getFormAuth)
  const organisationId = _.get(useSelector(authSelectors.getSelectedOrganisation), 'id')

  const isLoadingReport = useSelector(reportDetailsSelectors.getIsLoading)
  const isLoadingAcknowledgement = useSelector(ackDetailsSelectors.getIsLoading)

  const onSuccess = () => {
    dispatch(ackDetailsActions.resetStore())
    dispatch(push('/report-management'))
    toastService.action({
      type: 'success',
      message: translations('Your acknowledgement has been successfully submitted.'),
      autoHideDuration: 6000
    })
  }

  const onError = () => {
    toastService.action({
      type: 'error',
      message: translations('Acknowledgement Submission Failed'),
      autoHideDuration: 6000
    })
  }

  if (isLoadingReport || isLoadingAcknowledgement || !reportId) {
    return <ReportLoader />
  }

  return (
    <Container>
      <ReportContainer>
        {reportId && (
          <VigilanceHubForm
            theme={theme}
            auth={auth}
            baseUrl={baseUrl}
            organisationId={organisationId}
            reportId={reportId}
            formSchemaName={'mhraR3Ack'}
            initialValues={acknowledgementJSON}
            onSuccess={onSuccess}
            onError={onError}
            readOnly={isReadOnly}
            acknowledgementReport={isNewFormMode ? acknowledgementReport : {}}
            singlePageDisplay
          />)}
        {acknowledgementXML && (
          <AckActions>
            <Button onClick={downloadXml({ xml: acknowledgementXML, fileName: acknowledgementFileName })} big fullWidth raised>{translations('Export XML')}</Button>
          </AckActions>
        )}
      </ReportContainer>
    </Container>
  )
}

export default AcknowledgementForm