import _ from 'lodash'
import qs from 'qs'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import RootScreen from './RootScreen'
import { actions as appActions, selectors as appSelectors } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as themeSelectors } from '../../../store/modules/themes'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { apps } from '../../../config'

const mapStateToProps = (state, ownProps) => {
  const { displayMode } = qs.parse(_.get(ownProps, 'location.search'), { ignoreQueryPrefix: true })
  return {
    isLoggedIn: authSelectors.getIsLoggedIn(state),
    footerLogo: themeSelectors.getThemeFooterLogo(state),
    footerLogoAlt: themeSelectors.getThemeFooterLogoAlt(state),
    footerLink: themeSelectors.getThemeFooterLogoLink(state),
    footerMobileLogo: themeSelectors.getThemeFooterMobileLogo(state),
    appsAllowed: authCombinedSelectors.getAllowedAppsForSelectedOrganisation(state),
    isUnsecuredPage: appSelectors.getIsUnsecuredPage(state),
    isPublicPage: appSelectors.getIsPublicPage(state),
    isUserAssessor: authSelectors.getIsUserAssessor(state),
    isAssessorsPage: appSelectors.getIsAssessorsPage(state),
    displayMode
  }
}

const mapDispatchToProps = dispatch => ({
  openMenu: () => dispatch(appActions.toggleSideMenu({ isOpen: true })),
  goHome: () => dispatch(push('/'))
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withPropsOnChange(['location', 'appsAllowed'], ({ location, appsAllowed }) => {
    // Find the app id we are on based on the current location
    const basePath = '/' + location.pathname.split('/')[1]
    const currentApp = Object.entries(apps).reduce((acc, [appId, appContent]) => {
      const path = _.get(appContent, 'action.payload.args.0')
      if (path === basePath) {
        return appId
      }
      return acc
    }, null)

    // If route does not match an app, then allow - as a fail-safe to not block all apps
    const appNotSpecified = typeof appsAllowed[currentApp] === 'undefined'

    return {
      isAppAllowed: appNotSpecified || appsAllowed[currentApp]
    }
  })
)(RootScreen)
