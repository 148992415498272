import moment from 'moment'
import _ from 'lodash'
import * as constants from './constants'
import * as selectors from './selectors'
import * as authSelectors from '../auth/selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { dateFormat, translations } from '../../../config'
import toastService from '../../../services/toastService'
import { reviewStatuses } from './constants'
const _formatFilterDate = (date) => {
  if (!date) return undefined
  const dateMoment = moment(date, dateFormat)
  if (dateMoment.isValid() && dateMoment.isAfter('1000-01-01')) {
    return dateMoment.format('YYYY/MM/DD')
  }
  return undefined
}

const _mapFilters = (filters) => {
  return _.omitBy({
    ...filters,
    dateFrom: _formatFilterDate(filters.dateFrom),
    dateTo: _formatFilterDate(filters.dateTo),
    excludeFailedHistory: _.eq(filters.display, translations('Exclude Failed History')),
    latestVersionsOnly: _.eq(filters.display, translations('Display Latest Versions Only'))
  }, _.isNil)
}

class SubmissionReportsActions {
  fetchSubmissionReports = ({ organisationId }) => ({
    type: constants.GET_SUBMISSION_REPORTS,
    payload: { organisationId },
    promise: (_dispatch, getState) => {
      const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
      return digitalStoreSdk.submissions.fetchSubmissionReports({ organisationId })
    }
  })

  fetchSubmissionReportById = ({ submissionReportId }) => ({
    type: constants.GET_SUBMISSION_REPORT,
    payload: { submissionReportId },
    promise: (_dispatch, getState) => {
      const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
      return digitalStoreSdk.submissions.fetchSubmissionReportById({ organisationId, submissionReportId })
    }
  })

  searchFresh = () => ({
    type: constants.SEARCH_SUBMISSION_REPORTS_FRESH,
    promise: (_dispatch, getState) => {
      const state = getState()
      const size = selectors.getSearchSize(state)
      const filters = selectors.getFilters(state)
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)

      return digitalStoreSdk.submissions
        .fetchSubmissionReports({
          page: 1,
          size,
          organisationId,
          ..._mapFilters(filters)
        })
    }
  })

  searchNext = () => ({
    type: constants.SEARCH_SUBMISSION_REPORTS_NEXT,
    promise: (_dispatch, getState) => {
      const state = getState()
      const size = selectors.getSearchSize(state)
      const page = selectors.getSearchPage(state)
      const filters = selectors.getFilters(state)
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)

      return digitalStoreSdk.submissions
        .fetchSubmissionReports({
          page: page + 1,
          size,
          organisationId,
          ..._mapFilters(filters)
        })
    }
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })

  changeFilters = ({ filters }) => {
    return {
      type: constants.CHANGE_SUBMISSION_REPORTS_FILTERS,
      page: 1,
      filters
    }
  }

  changeFilter = ({ filter }) => ({
    type: constants.CHANGE_SUBMISSION_REPORTS_FILTER,
    filter
  })

  toggleSelected = ({ submissionReportId }) => {
    return {
      type: constants.TOGGLE_SELECTED_SUBMISSION_REPORTS,
      submissionReportId
    }
  }

  toggleSelectAll = () => {
    return {
      type: constants.TOGGLE_SELECT_ALL
    }
  }

  clearSelected = () => {
    return {
      type: constants.CLEAR_SELECTED
    }
  }

  distributeSubmissions = ({ resources, refreshResults = false }) => ({
    type: constants.DISTRIBUTE_SUBMISSIONS,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.submissions
        .distributeSubmissions({ resources })
        .then((res) => {
          toastService.action({
            type: 'success',
            message: translations('Receiver scheduled', { count: _.size(res) })
          })
          if (refreshResults) {
            dispatch(this.clearSelected())
            dispatch(this.searchFresh())
          }
        })
    }
  })

  fetchSavedSearches = () => ({
    type: constants.FETCH_SAVED_SEARCHES,
    promise: (dispatch, getState) => {
      const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
      return digitalStoreSdk.savedSearches
        .fetchSavedSearches({ organisationId, resource: 'caseManagement' })
    }
  })

  setSavedSearch = ({ id }) => ({
    type: constants.SET_SAVED_SEARCH,
    id
  })

  createNewSavedSearch = ({ name, description }) => ({
    type: constants.CREATE_SAVED_SEARCH,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      const { dateFrom, dateTo, ...filters } = selectors.getFilters(state)
      return digitalStoreSdk.savedSearches
        .createSavedSearch({
          organisationId,
          name,
          description,
          filters,
          resource: 'caseManagement'
        })
        .then((res) => {
          const setId = _.get(res, 'id')
          toastService.action({
            type: 'success',
            message: translations('Saved search created')
          })
          dispatch(this.fetchSavedSearches())
          dispatch(this.setSavedSearch({ id: setId }))
        })
        .catch(() => {
          toastService.action({
            type: 'error',
            message: translations('Unable to save search')
          })
        })
    }
  })

  updateSavedSearch = ({ savedSearchId, name, description }) => ({
    type: constants.UPDATE_SAVED_SEARCH,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      const { dateFrom, dateTo, ...filters } = selectors.getFilters(state)
      return digitalStoreSdk.savedSearches
        .updateSavedSearch({
          organisationId,
          savedSearchId,
          name,
          description,
          filters
        })
        .then((res) => {
          toastService.action({
            type: 'success',
            message: translations('Saved search updated')
          })
          dispatch(this.fetchSavedSearches())
        })
        .catch(() => {
          toastService.action({
            type: 'error',
            message: translations('Unable to update saved search')
          })
        })
    }
  })

  deleteSavedSearch = (savedSearchId) => ({
    type: constants.DELETE_SAVED_SEARCH,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      return digitalStoreSdk.savedSearches
        .deleteSavedSearch({ organisationId, savedSearchId })
        .then((res) => {
          toastService.action({
            type: 'success',
            message: translations('Saved search deleted')
          })
          dispatch(this.fetchSavedSearches())
        })
        .catch(() => {
          toastService.action({
            type: 'error',
            message: translations('Unable to delete search')
          })
        })
    }
  })

  discardFilters = () => ({
    type: constants.DISCARD_SAVED_FILTERS
  })

  setManuallyProcessedReport = ({ submissionReportId, manuallyProcessedReason }) => ({
    type: constants.SET_MANUALLY_PROCESSED,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      return digitalStoreSdk.submissions
        .updateManuallyProcessedReport({ submissionReportId, organisationId, manuallyProcessedReason })
        .then((res) => {
          toastService.action({
            type: 'success',
            message: translations('Case Management - Toast Success Updated Manually Processed')
          })
          dispatch(this.searchFresh())
          return res
        })
        .catch(() => {
          toastService.action({
            type: 'error',
            message: translations('Case Management - Toast Error Updated Manually Processed')
          })
        })
    }
  })
  setReceiverStatusToFailed = ({ submissionReportId }) => ({
    type: constants.SET_RECEIVER_STATUS_TO_FAILED,
    promise: async (dispatch) => {
      try {
        const result = await digitalStoreSdk.submissions.setReceiverStatusAsFailed({ submissionReportId })
        toastService.action({
          type: 'success',
          message: translations('Case Management - Toast Success Manually Marked As Failure')
        })
        dispatch(this.searchFresh())
        return result
      } catch (error) {
        toastService.action({
          type: 'error',
          message: translations('Case Management - Toast Error Manually Marked As Failure')
        })
      }
    }
  })
  setReceiverStatusToInReview = ({ submissionReportId }) => ({
    type: constants.SET_RECEIVER_STATUS_TO_IN_REVIEW,
    promise: async (dispatch) => {
      try {
        const result = await digitalStoreSdk.submissions.setReceiverStatusAsInReview({ submissionReportId })
        toastService.action({
          type: 'success',
          message: translations('Case Management - Toast Success Marked As In Review')
        })
        dispatch(this.searchFresh())
        return result
      } catch (error) {
        toastService.action({
          type: 'error',
          message: translations('Case Management - Toast Error Marked As In Review')
        })
      }
    }
  })

  setReceiverStatusToRejected = ({ submissionReportId }) => ({
    type: constants.SET_RECEIVER_STATUS_TO_REJECTED,
    promise: async (dispatch) => {
      try {
        const result = await digitalStoreSdk.submissions.setReceiverStatusAsRejected({ submissionReportId })
        toastService.action({
          type: 'success',
          message: translations('Case Management - Toast Success Marked As Rejected')
        })
        dispatch(this.searchFresh())
        return result
      } catch (error) {
        toastService.action({
          type: 'error',
          message: translations('Case Management - Toast Error Marked As Rejected')
        })
      }
    }
  })

  submitApproval = ({ comment, submissionReportId }) => ({
    type: constants.SUBMIT_APPROVAL,
    promise: async (dispatch, getState) => {
      try {
        const res = await digitalStoreSdk.submissions.reviewSubmissionReport({
          submissionReportId,
          approvalComment: comment,
          reviewStatus: reviewStatuses.APPROVE
        })
        toastService.action({
          type: 'success',
          message: translations('Case Management - Toast Success Submit Approval')
        })
  
        return res
      } catch (error) {
        toastService.action({
          type: 'error',
          message: translations('Case Management - Toast Error Submit Approval')
        })
      }
    }
  })
  
  submitRejection = ({ comment, submissionReportId }) => ({
    type: constants.SUBMIT_REJECTION,
    promise: async (dispatch) => {
      try {
        const res = await digitalStoreSdk.submissions.reviewSubmissionReport({
          submissionReportId,
          rejectionComment: comment,
          reviewStatus: 'REJECT'
        })
  
        toastService.action({
          type: 'success',
          message: translations('Case Management - Toast Success Submit Rejection')
        })
  
        return res
      } catch (error) {
        toastService.action({
          type: 'error',
          message: translations('Case Management - Toast Error Submit Rejection')
        })
      }
    }
  })

  fetchReportReviewById = ({ submissionReportId }) => ({
    type: constants.FETCH_REPORT_REVIEWS_BY_ID,
    payload: { submissionReportId },
    promise: async (dispatch) => {
      try {
        return digitalStoreSdk.submissions.fetchSubmissionReportReviewById({ submissionReportId })
      } catch (error) {
        toastService.action({
          type: 'error',
          message: translations('Case Management - Toast Error Load Report Review')
        })
      }
    }
  })
  
}

export default new SubmissionReportsActions()
