import React from 'react'
import { styled, Typography } from '@material-ui/core'

const Message = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: 0
}))

const FieldHelp = (props) => {
  const { children} = props
  
  return (
    <Message variant='caption'>
      {children}
    </Message>
  )
}

export default FieldHelp