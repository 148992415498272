"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wasBabyNotBornAlive = exports.wasBabyBornAlive = exports.symptomOptions = exports.msvmHasRecoveredForDailyActivity = exports.isNotCurrentlyPregnant = exports.isCurrentlyPregnant = exports.hospitalOptions = exports.hasSkinDisorder = exports.hasRecoveredForDailyActivity = exports.hasReactogenicityHospitalVisit = exports.hasOtherPregnancySymptoms = exports.hasOtherBirthEvents = exports.hasNeurologicDisorder = exports.hasHeartBloodVesselInjury = exports.hasFollowupHospitalVisit = exports.hasEventHospitalVisit = exports.hasCovidHospitalVisit = exports.hasCausedMissedWork = exports.hasBloodFlowDisorder = exports.hasAnyInjectionSymptomCausedIssue = exports.hasAllergicReaction = exports.hadReactogenicityCareForSymptoms = exports.hadImmunizationCausedFever = exports.hadFollowUpCareForSymptoms = exports.hadEventCareForSymptoms = exports.hadCovidCareForSymptoms = exports.babyHasOtherCondition = exports.babyHasBirthDefect = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var computedHidden = _interopRequireWildcard(require("../../../../../../../formHelpers/computedHidden"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var responses = {
  yes: '1',
  no: '2'
};
var symptomOptions = [{
  value: 'no',
  label: 'No I did not have this symptom'
}, {
  value: 'yesWithNoIssue',
  label: 'Yes, but it was not a problem'
}, {
  value: 'yesWithMinorIssue',
  label: 'Yes, and it impacted normal activities'
}, {
  value: 'yesWithMajorIssue',
  label: 'Yes; normal activities were impossible'
}];
exports.symptomOptions = symptomOptions;
var hospitalOptions = [{
  value: 'intensiveCareUnit',
  label: 'Intensive care unit'
}, {
  value: 'nasalTubeOxygen',
  label: 'Oxygen by nasal tube'
}, {
  value: 'highFlowOxygen',
  label: 'Oxygen by CPAP, BiPAP, high flow nasal canula'
}, {
  value: 'ventilatorOxygen',
  label: 'Oxygen by intubation and ventilator'
}];
exports.hospitalOptions = hospitalOptions;
var hasAllergicReaction = computedHidden.fieldIs('suddenallergicreaction', 'Yes');
exports.hasAllergicReaction = hasAllergicReaction;

var definedAndNotEmpty = function definedAndNotEmpty(values, field) {
  return _fp["default"].allPass([computedHidden.fieldIsDefined(field), _fp["default"].pipe(_fp["default"].get(field), _fp["default"].isEmpty, _fp["default"].isEqual(false))])(values);
};

var hasHeartBloodVesselInjury = computedHidden.fieldIs('heartbloodvesselinjury', 'Yes');
exports.hasHeartBloodVesselInjury = hasHeartBloodVesselInjury;
var hasBloodFlowDisorder = computedHidden.fieldIs('bloodflowdisorder', 'Yes');
exports.hasBloodFlowDisorder = hasBloodFlowDisorder;
var hasNeurologicDisorder = computedHidden.fieldIs('neurologicdisorder', 'Yes');
exports.hasNeurologicDisorder = hasNeurologicDisorder;
var hasSkinDisorder = computedHidden.fieldIs('skindisorder', 'Yes');
exports.hasSkinDisorder = hasSkinDisorder;

var hasReactogenicityHospitalVisit = _fp["default"].pipe(_fp["default"].get('reactogenicitycarevisittype'), _fp["default"].some(function (value) {
  return value === 'hospitalStay';
}));

exports.hasReactogenicityHospitalVisit = hasReactogenicityHospitalVisit;

var hasCovidHospitalVisit = _fp["default"].pipe(_fp["default"].get('covidcarevisittype'), _fp["default"].some(function (value) {
  return value === 'hospitalStay';
}));

exports.hasCovidHospitalVisit = hasCovidHospitalVisit;

var hasEventHospitalVisit = _fp["default"].pipe(_fp["default"].get('eventscarevisittype'), _fp["default"].some(function (value) {
  return value === 'hospitalStay';
}));

exports.hasEventHospitalVisit = hasEventHospitalVisit;

var hasFollowupHospitalVisit = _fp["default"].pipe(_fp["default"].get('followupcarevisittype'), _fp["default"].some(function (value) {
  return value === 'hospitalStay';
}));

exports.hasFollowupHospitalVisit = hasFollowupHospitalVisit;
var isCurrentlyPregnant = computedHidden.fieldIs('currentlypregnant', 'Yes');
exports.isCurrentlyPregnant = isCurrentlyPregnant;
var isNotCurrentlyPregnant = computedHidden.fieldIs('currentlypregnant', 'No');
exports.isNotCurrentlyPregnant = isNotCurrentlyPregnant;
var hasOtherPregnancySymptoms = computedHidden.selectedInMultipleOption('pregnancysymptoms', 'other');
exports.hasOtherPregnancySymptoms = hasOtherPregnancySymptoms;
var wasBabyBornAlive = computedHidden.fieldIs('babybornalive', 'Yes');
exports.wasBabyBornAlive = wasBabyBornAlive;
var wasBabyNotBornAlive = computedHidden.fieldIs('babybornalive', 'No');
exports.wasBabyNotBornAlive = wasBabyNotBornAlive;
var babyHasBirthDefect = computedHidden.fieldIs('birthdefect', 'Yes');
exports.babyHasBirthDefect = babyHasBirthDefect;
var babyHasOtherCondition = computedHidden.fieldIs('otherbirthconditions', 'Yes');
exports.babyHasOtherCondition = babyHasOtherCondition;
var hasOtherBirthEvents = computedHidden.fieldIs('otherbirthevents', 'Yes');
exports.hasOtherBirthEvents = hasOtherBirthEvents;
var hasRecoveredForDailyActivity = computedHidden.fieldIs('recoveredfordailyactivity', 'Yes');
exports.hasRecoveredForDailyActivity = hasRecoveredForDailyActivity;
var msvmHasRecoveredForDailyActivity = computedHidden.fieldIs('msvmrecoveredfordailyactivity', 'Yes');
exports.msvmHasRecoveredForDailyActivity = msvmHasRecoveredForDailyActivity;
var hasCausedMissedWork = computedHidden.fieldIs('causedworkloss', 'Yes');
exports.hasCausedMissedWork = hasCausedMissedWork;
var injectionQuestions = ['injectionpain', 'injectionredness', 'injectionswelling', 'immunizationchills', 'immunizationheadache', 'immunizationjointpain', 'immunizationbodyache', 'immunizationfatigue', 'immunizationnausea', 'immunizationvomiting', 'immunizationdiarrhea', 'immunizationabdominalpain'];

var hasAnyInjectionSymptomCausedIssue = function hasAnyInjectionSymptomCausedIssue(values) {
  return _lodash["default"].some(injectionQuestions, function (question) {
    var fieldValue = _lodash["default"].get(values, question);

    var issueIds = ['yesWithMinorIssue', 'yesWithMajorIssue'];
    return _lodash["default"].some(issueIds, function (id) {
      return _lodash["default"].includes(fieldValue, id);
    });
  });
};

exports.hasAnyInjectionSymptomCausedIssue = hasAnyInjectionSymptomCausedIssue;
var hadReactogenicityCareForSymptoms = computedHidden.fieldIs('reactogenicitycareforsymptoms', responses.yes);
exports.hadReactogenicityCareForSymptoms = hadReactogenicityCareForSymptoms;
var hadCovidCareForSymptoms = computedHidden.fieldIs('covidcareforsymptoms', responses.yes);
exports.hadCovidCareForSymptoms = hadCovidCareForSymptoms;
var hadEventCareForSymptoms = computedHidden.fieldIs('eventscareforsymptoms', responses.yes);
exports.hadEventCareForSymptoms = hadEventCareForSymptoms;
var hadFollowUpCareForSymptoms = computedHidden.fieldIs('followupcareforsymptoms', responses.yes);
exports.hadFollowUpCareForSymptoms = hadFollowUpCareForSymptoms;
var hadImmunizationCausedFever = computedHidden.fieldIs('immunizationfever', responses.yes);
exports.hadImmunizationCausedFever = hadImmunizationCausedFever;