"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schema = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _schema = require("../../devicesReport/7.2.0/schema");

var _PSR = require("../../devicesReport/7.2.0/collections/schemas/PSR");

var _view = _interopRequireDefault(require("./view"));

var _constants = require("../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var schema = {
  type: _constants.FORM_TYPE_CONSTANTS.REPORT,
  getFormSchema: function getFormSchema(validators) {
    var pickedDevicesSchema = _fp["default"].compose(_fp["default"].values, _fp["default"].pick(['adversefromdateformat', 'adversetodateformat', 'reportDate', 'reporterOrgEmailAddress', 'audienceid']), _fp["default"].keyBy('id'))((0, _schema.getFormSchema)(validators));

    return [].concat(_toConsumableArray((0, _PSR.getFormSchema)(validators)), _toConsumableArray(pickedDevicesSchema));
  },
  getFormView: function getFormView() {
    return JSON.parse(JSON.stringify(_view["default"]));
  },
  getLocationField: function getLocationField(viewJSON) {
    return undefined;
  },
  attachmentFields: ['applicationAttachment', 'incidentsAttachment', 'reportAttachment'],
  attachmentTypeGroups: {
    'application': 'applicationAttachment',
    'incidents': 'incidentsAttachment',
    'default': 'reportAttachment'
  }
};
exports.schema = schema;