import React from 'react'

import LookupInput from '../LookupInput'
import BasicLocationLookUpInputModal from '../../BasicLocationLookUpInputModal'

const BasicLocationLookUpInput = ({ restrictFreeformEntry, ...props }) => (
  <LookupInput
    component={BasicLocationLookUpInputModal}
    lookUpOnly={restrictFreeformEntry}
    clearable={restrictFreeformEntry}
    {...props}
  />
)

export default BasicLocationLookUpInput
