import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormHelperText } from '@material-ui/core'

import Date from '../Date'
import Time from '../Time'
import styles from './style'
import moment from 'moment'

const DateTime = ({
  dateLabel,
  timeLabel,
  value,
  onChange,
  onBlur,
  options,
  endYearOffset,
  passedProps,
  meta: { error, touched },
  validate,
  classes
}) => {
  return ([
    <div key='inputs' className={classes.wrapper}>
      <div className={classes.fieldWrapperDate}>
        <Date
          label={dateLabel}
          value={moment(value).format('YYYYMMDD')}
          onChange={(e) => onChange(e, 'date')}
          onBlur={(e) => onBlur(e, 'date')}
          noNullOption
          endYearOffset={endYearOffset}
        />
      </div>
      <div className={`${classes.fieldWrapperTime}`}>
        <Time
          label={timeLabel}
          value={moment(value).format('HH:mm')}
          onChange={(e) => onChange(e, 'time')}
          onBlur={(e) => onBlur(e, 'time')}
          noNullOption
        />
      </div>
    </div>,
    <FormHelperText className={classes.errorStyle} key='helper-text' role='alert'>{error}</FormHelperText>
  ])
}

DateTime.propTypes = {
  value: PropTypes.shape({
    code: PropTypes.string,
    number: PropTypes.string
  })
}

export default withStyles(styles)(DateTime)
