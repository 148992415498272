import React, { Component, useMemo, useState } from 'react'
import _ from 'lodash'
import { withStyles, styled } from '@material-ui/core/styles'
import { compose } from 'redux'


import withReportData from '../withReportData'
import style from './style'

import Collapse from '@material-ui/core/Collapse'
import Container from '@material-ui/core/Container'

import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Heading from '../../../components/Heading'
import { translations } from '../../../config'

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  cursor: 'pointer',
  padding: theme.spacing(1)
}))

const RowContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1)
}))

const ContentCollapse = styled(Collapse)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))

const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.grey[300]}`
}))

const ExpandAndTotal = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  flexDirection: 'row'
}))

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1)
}))



const ReportFullListCollapsable = (props) => {
  const {
    classes,
    items: { expandedErrorMessages, count, receiverStatusCounts } = {},
    reportSchema: {
      errorType,
      type,
      titleBy,
    } = {},
  } = props

  const [openRows, setIsOpen] = useState({})

  const isRowOpen = (rowIndex) => {
    return openRows[rowIndex]
  }

  const toggleRowOpen = (rowIndex) => {
    const nextOpen = _.clone(openRows)
    nextOpen[rowIndex] = !nextOpen[rowIndex]
    setIsOpen(nextOpen)
  }

  const ExpandIcon = (isIn) => {
    return isIn ? <ExpandLessIcon /> : <ExpandMoreIcon />
  }

  const getAndCountTitles = (rows) => {
    const preMappedRows = _.chain(rows)
      .map((row) => {
        const title = _.compact(_.map(titleBy, (title) => _.get(row, title)))
        return title.length && { ...row, title: title.join(' - ') }
      })
      .compact()
      .value()
    return _.chain(preMappedRows)
      .countBy('title')
      .map(
        (val, key) => ({
          title: key, total: val, rows: _.find(preMappedRows, { title: key })
        }))
      .orderBy(['total', 'title'], ['desc', 'asc'])
      .value()
  }
  const activeErrorsFromMessages = _.get(expandedErrorMessages, errorType, [])
  const errorTitles = _.keys(activeErrorsFromMessages)

  const renderCountRow = (status, index) => (
    <Row>
      <Header onClick={() => toggleRowOpen(index)}>
        <Heading variant={'h2'} component={'h2'}>
          {`${status.title}`}
        </Heading>
        <ExpandAndTotal>
          <Heading variant={'h2'} component={'h2'}>
          </Heading>
          {ExpandIcon(isRowOpen(index))}
        </ExpandAndTotal>
      </Header>
      <ContentCollapse in={isRowOpen(index)}>
        <Content>{
          _.map(errorTitles, (title) => {
            const total = _.get(activeErrorsFromMessages, `${title}.receiver.${status.key}`, 0)
            return (
              <RowContent>
                <span>{title === 'null' ? 'Unknown Source' : title}</span>
                <span>{total}</span>
              </RowContent>
            )
          })
        }</Content>
      </ContentCollapse>
    </Row>
  )

  if (type === 'LIST') {
    const activeErrors = _.chain(activeErrorsFromMessages)
      .map((errors) => {
        const errorRows = _.get(errors, 'rows', [])
        const titledRows = getAndCountTitles(errorRows)
        const allTotals = _.sumBy(titledRows, 'total')
        return { titledRows, allTotals, error: _.get(errors, 'error') }
      })
      .orderBy(['allTotals', 'titledRows'], ['desc', 'asc'])
      .value()
    return (
      <Container>
        {
          _.map(activeErrors, ({ titledRows, allTotals, error }, index) => {
            if (allTotals === 0) {
              return null
            }
            return (
              <Row>
                <Header onClick={() => toggleRowOpen(index)}>
                  <Heading variant={'h2'} component={'h2'}>
                    {`${error}`}
                  </Heading>
                  <ExpandAndTotal>
                    <Heading variant={'h2'} component={'h2'}>
                      {`${allTotals}`}
                    </Heading>
                    {ExpandIcon(isRowOpen(index))}
                  </ExpandAndTotal>
                </Header>
                <ContentCollapse in={isRowOpen(index)}>
                  <Content>{
                    _.map(titledRows, ({ rows, title, total }) => {
                      return (
                        <RowContent>
                          <span>{title}</span>
                          <span>{total}</span>
                        </RowContent>
                      )
                    })
                  }
                  </Content>
                </ContentCollapse>
              </Row>
            )
          })
        }
      </Container >
    )
  }
  if (type === 'COUNT') {
    const statuses = [
      { key: 'SUCCESS', title: translations('Successful reports by source') },
      { key: 'NOT_SENT', title: translations('Invalid reports by source') },
      { key: 'FAILED', title: translations('Failed reports by source') }
    ]
    const totalsSubtitles = [
      { key: 'SUCCESS', title: translations('Successful reports') },
      { key: 'NOT_SENT', title: translations('Invalid reports') },
      { key: 'FAILED', title: translations('Failed reports') }
    ]

    return (
      <Container>
        <Row>
          <Header onClick={() => toggleRowOpen('totals')}>
            <Heading variant={'h2'} component={'h2'}>
              {`Totals`}
            </Heading>
            <ExpandAndTotal>
              <Heading variant={'h2'} component={'h2'}>
              </Heading>
              {ExpandIcon(isRowOpen('totals'))}
            </ExpandAndTotal>
          </Header>
          <ContentCollapse in={isRowOpen('totals')}>
            <Content>
              <RowContent>
                <span>{'Total'}</span>
                <span>{count}</span>
              </RowContent>
              {
                _.map(receiverStatusCounts, (value, key) => {
                  return (
                    <RowContent>
                      <span>{_.get(_.find(totalsSubtitles, { key }), 'title')}</span>
                      <span>{value}</span>
                    </RowContent>
                  )
                })
              }
            </Content>
          </ContentCollapse>
        </Row>
        {
          _.map(statuses, renderCountRow)
        }
      </Container>
    )
  }
  return null
}

export default compose(
  withReportData,
  withStyles(style)
)(ReportFullListCollapsable)
