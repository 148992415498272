import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import AnnouncementManagementSearchScreen from './AnnouncementManagementSearchScreen'
import AnnouncementEditScreen from './AnnouncementEditScreen/AnnouncementEditScreen'

import TimedLogoutComponent from '../../components/TimedLogout'
const PREFIX = `/communications/announcement-management`
export default [
  <PrivateRoute key={PREFIX} path={PREFIX} exact component={TimedLogoutComponent(AnnouncementManagementSearchScreen)} />,
  <PrivateRoute key={`${PREFIX}/new`} path={`${PREFIX}/new`} exact component={TimedLogoutComponent(AnnouncementEditScreen)} />,
  <PrivateRoute key={`${PREFIX}/edit/:id`} path={`${PREFIX}/edit/:id`} exact component={TimedLogoutComponent(AnnouncementEditScreen)} />
]
