import React, { createContext, useMemo, useContext, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { asyncActions, selectors as reportAuditSelectors } from '../../../../../store/modules/reportAudit'
import { useParams } from 'react-router'

const initialState = []

const fieldsStore = createContext(initialState)
const { Provider } = fieldsStore

const AuditProvider = ({ children }) => {
  const { id: reportId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(asyncActions.fetchReportAuditHistory({ reportId }))
  }, [reportId, dispatch])

  const { status, data: reportCommits } = useSelector(reportAuditSelectors.getByReportId({ id: reportId }))

  const auditData = useMemo(() => {
    const result = []
    _.forEach(reportCommits, (commit, index) => {
      const baseCommit = _.get(commit, 'commit.patch')
      const previousCommit = _.get(reportCommits, `${index - 1}.commit.patch`)
      const reportXML = _.get(_.find(baseCommit, { path: '/reportXML' }), 'value', '')
      const reportJSON = _.get(_.find(baseCommit, { path: '/reportJSON' }), 'value', {})
      const details = _.get(_.find(baseCommit, { path: '/details' }), 'value', {})
      const attachments = _.get(_.find(baseCommit, { path: '/attachments' }), 'value', [])
      const previousReportXML = _.get(_.find(previousCommit, { path: '/reportXML' }), 'value', '')
      const previousReportJSON = _.get(_.find(previousCommit, { path: '/reportJSON' }), 'value', {})
      const previousDetails = _.get(_.find(previousCommit, { path: '/details' }), 'value', {})
      const previousAttachments = _.get(_.find(previousCommit, { path: '/attachments' }), 'value', [])
      const conditionName = _.get(commit, 'conditionVersion.condition.name')
      const description = _.isString(conditionName)
      ? `Automatic update performed by rule "${conditionName}"`
      : _.get(commit, 'reportAmendAction.description')
      const amendDetails = _.get(commit, 'details')

      const commitObject = {
        action: _.get(commit, 'reportAmendAction.action'),
        description,
        previousXml: previousReportXML,
        currentXml: reportXML,
        createdAt: _.get(commit, 'createdAt'),
        id: _.get(commit, 'id'),
        details,
        reportJSON,
        attachments,
        previousDetails,
        previousReportJSON,
        previousAttachments,
        amendDetails
      }
      _.set(commitObject, 'currentXml', reportXML)
      if (index !== 0) {
        _.set(commitObject, 'previousXml', previousReportXML)
      }

      result.push(commitObject)
    })
    return _.reverse(result)
  }, [reportCommits])

  return (
    <Provider
      value={{ status, data: auditData }}>
      {children}
    </Provider>
  )
}

export const useAudit = () => useContext(fieldsStore)

// eslint-disable-next-line import/no-anonymous-default-export
export default { AuditProvider, useAudit }