import _ from 'lodash'
import fp from 'lodash/fp'
import { PENDING, SUCCESS } from '../../middleware/redux-promise'
import { createSelector } from 'reselect'

export const getIsLoading = (state, type = 'default') => state.news[type].status === PENDING
export const getResults = (state, type = 'default') => state.news[type].results
export const getHasMore = (state, type = 'default') => state.news[type].total != null && state.news[type].results.length < state.news[type].total
export const getError = (state, type = 'default') => state.news[type].error
export const getSize = (state, type = 'default') => state.news[type].size
export const getPage = (state, type = 'default') => state.news[type].page
export const getLanguage = (state, type = 'default') => state.news[type].language
export const getFilters = (state, type = 'default') => _.get(state, ['news', type, 'filters'], {})
