import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

const _formatSchemaForDB = (fields) => {
  return {
    name: fields.name,
    type: fields.type,
    details: fields.details
  }
}
class FormSchemaActions {
  fetchAllFormSchemas = () => ({
    type: constants.FETCH_ALL_FORMSCHEMAS,
    promise: () => digitalStoreSdk.formSchema.fetchFormSchemas()
  })
  updateFormSchema = ({ id, params }) => ({
    type: constants.UPDATE_FORMSCHEMA,
    payload: { id, ...params },
    promise: () => {
      let updatedSchema = _formatSchemaForDB(params)
      return digitalStoreSdk.formSchema.updateFormSchema({ id, params: updatedSchema })
    }
  })
  togglePermission = (values) => ({
    type: constants.TOGGLE_PERMISSION,
    values
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })

}

export default new FormSchemaActions()