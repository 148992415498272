import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'
import Button from '../../../../components/Button'

import { translations } from '../../../../config'

import ReportManagementConditionsPanel from '../../ReportManagementConditionsPanel'
import Content from '../Components/Content'
import Actions from '../Components/Actions'

const ManageCohortConditionsContent = (props) => {
    const { conditions = [], onClose, onSave } = props
    const [activeConditions, setActiveConditions] = useState(conditions)

    const selectedLookup = ({ conditionId }) => {
        const isSelected = _.includes(activeConditions, conditionId)
        return isSelected
    }

    const onSelect = ({ conditionId }) => {
        setActiveConditions(_.xor(activeConditions, [conditionId]))
    }

    const handleSave = () => {
        onSave(activeConditions)
    }

    return (
        <>
            <Actions>
                <Grid container spacing={2} justify={'flex-end'}>
                    <Grid item>
                        <Button onClick={onClose}>
                            {translations('Cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type='submit'
                            raised
                            buttonType='primary'
                            onClick={handleSave}
                        >
                            {translations('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </Actions>
            <Content>
                <ReportManagementConditionsPanel
                    onSelect={onSelect}
                    selectedLookup={selectedLookup}
                />
            </Content>
        </>
    )
}

export default ManageCohortConditionsContent
