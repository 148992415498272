import React from 'react'
import fp from 'lodash/fp'

const actionTypes = {
    INITIAL: 'INITIAL',
    SEARCH_START: 'START_SEARCH',
    SEARCH_SUCCESSFUL: 'SEARCH_SUCCESFUL',
    SEARCH_ERROR: 'SEARCH_ERROR'
}

export const actions = {
    setAsInitial() {
        return {
            type: actionTypes.INITIAL
        }
    },
    setAsIsSearching() {
        return {
            type: actionTypes.SEARCH_START,
            payload: {}
        }
    },
    setAsSearchIsSuccessful({ results }) {
        return {
            type: actionTypes.SEARCH_SUCCESSFUL,
            payload: {
                results
            }
        }
    },
    setAsSearchHasFailed({ error }) {
        return {
            type: actionTypes.SEARCH_ERROR,
            payload: {
                error
            }
        }
    }
}

const initialState = {
        isInitial: true,
        isLoading: false,
        results: [],
        error: null
    }

const apiRequestStateReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.INITIAL:
            return initialState
        case actionTypes.SEARCH_START:
            return {
                isInitial: false,
                isLoading: true,
                results: state.results,
                error: null
            }
        case actionTypes.SEARCH_SUCCESSFUL:
            return {
                isInitial: false,
                isLoading: false,
                results: action.payload.results,
                error: null
            }
        case actionTypes.SEARCH_ERROR:
            return {
                isInitial: false,
                isLoading: false,
                results: [],
                error: action.payload.error
            }
        default:
            return {}

    }
}


export const useApiRequestHandler = ({
    minimumSearchLength,
    asyncFunction
}) => {
    const [state, dispatch] = React.useReducer(apiRequestStateReducer, initialState)
    const getApiResults = async (value) => {
        if (minimumSearchLength > fp.size(value)) {
            dispatch(actions.setAsInitial())
            return
        }
        try {
            dispatch(actions.setAsIsSearching())
            const results = await asyncFunction({ value })
            dispatch(actions.setAsSearchIsSuccessful({ results }))
        } catch (error) {
            dispatch(actions.setAsSearchHasFailed({ error }))
        }
    }

    const useApiRequest = ({ value }) => {
        return React.useEffect(() => {
            getApiResults(value)
        }, [value])
    }
    return {
        data: {
            entity: state.results,
            isLoading: state.isLoading,
            isInitial: state.isInitial,
            isEmpty: fp.isEmpty(state.results),
        },
        useApiRequest
    }
}
