import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { compose } from 'recompose'
import moment from 'moment'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { translations } from '../../../../config'
import Dropdown from '../../../../components/Fields/Dropdown'
import DatePicker from '../../../../components/Fields/DatePicker'
import { withFiltersContainer } from '../../../../components/FiltersContainer'
import { approvalStatuses, changeTypes } from './options'
import { withStyles } from '@material-ui/core/styles'
import { actions } from '../../../../store/modules/changeManagement'
import * as filtersLogic from './reducer'
import styles from './style'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { useDispatch, useSelector } from 'react-redux'
import OrganisationAssociationLookup from '../../../../components/Fields/OrganisationAssociationLookup'

export const handleFilter = ({ type, dispatch }) => event => {
  return dispatch({
    type,
    payload: {
      value: _.has(event, 'target') ? event.target.value : event
    }
  })
}
const ChangeManagementSearchFilters = (props) => {
  const {
    classes,
  } = props
  const loggedInUserOrganisationsOptions = useSelector(authSelectors.getUserOrganisationsOptions)
  /* 
  * If hasOrganistionsOptions is false, it means the max organisation limit was exceeded and it must resort to using
  * the organisation lookup component as none of the organisations have been loaded.
  */
  const hasOrganisationOptions = loggedInUserOrganisationsOptions && !_.isEmpty(loggedInUserOrganisationsOptions) 
  const currentOrganisation = useSelector(authSelectors.getSelectedOrganisation)
  const [stateFilters, dispatch] = React.useReducer(filtersLogic.reducer, {}, filtersLogic.initialiseState({
    approvalStatus: ['PENDING'],
    dateFrom: moment().subtract(1, 'week').format('MM/DD/YYYY'),
    dateTo: moment().format('MM/DD/YYYY'),
    organisationIds: hasOrganisationOptions ? [currentOrganisation.id] : [],
    type: 'ORG_USER'
  }) )
  const dispatchRedux = useDispatch()
  // Callback needs to take debounce, regardless of exhaustive-deps.
  // https://stackoverflow.com/a/61786423
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFilterChange = React.useCallback(_.debounce(filters => {
      dispatchRedux(actions.updateFilters({ filters }))
    }, 100)
  ,
    [dispatchRedux]
  )
  React.useEffect(() => {
    onFilterChange(stateFilters)
  }, [stateFilters, onFilterChange]
  )
  const organisationsComponent = hasOrganisationOptions ? 
    <FormControl className={classes.formControl}>
      <Dropdown
        label={translations('Organisation(s)')}
        value={stateFilters.organisationIds}
        options={loggedInUserOrganisationsOptions}
        onChange={handleFilter({ type: filtersLogic.types.SET_FILTER_ORGANISATIONS, dispatch })}
        passedProps={{ multiple: true }}
        multiple
        noErrorTextLabel
      />
    </FormControl>
    :
    <FormControl className={classes.lookupInputFormControl}>
      <OrganisationAssociationLookup
        label={translations('Organisation(s)')}
        onChange={handleFilter({ type: filtersLogic.types.SET_FILTER_ORGANISATIONS, dispatch })}
        getCustomValue={(organisation) => organisation.id}
        multiple
      />
    </FormControl>

  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={2} justify={'center'} className={classes.filterBlock}>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            { organisationsComponent }
          </Grid>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <Dropdown
                label={translations("Change Request - Type Filter")}
                value={stateFilters.type}
                options={changeTypes}
                onChange={handleFilter({ type: filtersLogic.types.SET_FILTER_TYPE, dispatch })}
                noErrorTextLabel
                shrink
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <Dropdown
                label={translations('Change Request - Approval Status')}
                multiple
                value={stateFilters.approvalStatus}
                options={approvalStatuses}
                onChange={handleFilter({ type: filtersLogic.types.SET_FILTER_APPROVAL_STATUS, dispatch })}
                passedProps={{ multiple: true }}
                noErrorTextLabel
                shrink
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <DatePicker
                label={translations('Date From')}
                value={stateFilters.dateFrom}
                onChange={handleFilter({ type: filtersLogic.types.SET_FILTER_DATE_FROM, dispatch })}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <DatePicker
                label={translations('Date To')}
                value={stateFilters.dateTo}
                onChange={handleFilter({ type: filtersLogic.types.SET_FILTER_DATE_TO, dispatch })}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

ChangeManagementSearchFilters.propTypes = {
  classes: PropTypes.shape({}).isRequired,
}

export default compose(
  withStyles(styles),
  withFiltersContainer
)(ChangeManagementSearchFilters)
