"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withHiddenOptions = exports.mapValuesAt1 = exports.mapValueAndLabel = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = _interopRequireDefault(require("lodash/fp"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var mapValueAndLabel = _lodash["default"].partial(_lodash["default"].map, _lodash["default"], function (option) {
  return {
    label: _lodash["default"].toString(option),
    value: _lodash["default"].toString(option)
  };
});

exports.mapValueAndLabel = mapValueAndLabel;

var mapValuesAt1 = _lodash["default"].partial(_lodash["default"].map, _lodash["default"], function (option, index) {
  return {
    label: _lodash["default"].toString(option),
    value: _lodash["default"].toString(index + 1)
  };
});

exports.mapValuesAt1 = mapValuesAt1;

var withHiddenOptions = function withHiddenOptions(hiddenOptions) {
  return function (visibleOptions) {
    var options = _fp["default"].compose(_fp["default"].concat(visibleOptions), _fp["default"].map(_fp["default"].set('hidden', true)))(hiddenOptions);

    return options;
  };
};

exports.withHiddenOptions = withHiddenOptions;