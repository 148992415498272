import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'

import CommunicationsMenuScreen from './CommunicationsMenuScreen'
import CommunicationsEmailScreen from './Email/CommunicationsEmailScreen'
import CommunicationsSmsScreen from './Sms/CommunicationsSmsScreen'
import CommunicationsPushScreen from './Push/CommunicationsPushScreen'

import CommunicationsAddScreen from './CommunicationsAddScreen'
import CommunicationsEditScreen from './CommunicationsEditScreen'

const communicationsTypes = [
  'RESET_PASSWORD',
  'REQUEST_ACCOUNT',
  'REPORT_ANALYTICS',
  'FILE_UPLOAD_NOTIFICATION',
  'ACKNOWLEDGEMENT',
  'FOLLOWUP',
  'REGISTRATION',
  'ORGANISATION_REPORT_NOTIFICATION',
  'REMINDER',
  'NOTIFICATION',
  'ORGANISATION_ASSOCIATION_REQUEST',
  'ORGANISATION_ASSOCIATION_REQUEST_APPROVE',
  'ORGANISATION_ASSOCIATION_REQUEST_REJECT',
  'PLATFORM_REGISTRATION',
  'ORGANISATION_INVITE',
  'INVITE_ORGANISATION_TO_USE_PLATFORM',
  'PLATFORM_REGISTRATION_OTHER',
  'REPORT_PROMPT_CREATED',
  'VIGILANCE_CONTACT',
  'DELETE_USER',
  'ACCOUNT_DELETION_REQUEST',
  'VERIFY_EMAIL'
]

export default [
  <PrivateRoute key='/communications' path='/communications' exact component={TimedLogoutComponent(CommunicationsMenuScreen)} />,
  <PrivateRoute key='/communications/email' path='/communications/email' exact component={TimedLogoutComponent(CommunicationsEmailScreen)} />,
  <PrivateRoute key='/communications/sms' path='/communications/sms' exact component={TimedLogoutComponent(CommunicationsSmsScreen)} />,
  <PrivateRoute key='/communications/push' path='/communications/push' exact component={TimedLogoutComponent(CommunicationsPushScreen)} />,
  <PrivateRoute key='/communications/add/:channel/:type' path={`/communications/add/:channel/:type(${communicationsTypes.join('|')})`} exact component={TimedLogoutComponent(CommunicationsAddScreen)} />,
  <PrivateRoute key='/communications/edit/:channel/:id' path='/communications/edit/:channel/:id' exact component={TimedLogoutComponent(CommunicationsEditScreen)} />
]
