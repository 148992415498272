import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import { styled } from '@material-ui/core/styles'

import { translations } from '../../../config'

import ReportConfigurationEditorPanel from '../ReportConfigurationEditorPanel'
import ReportManagementConditionsPanel from '../ReportManagementConditionsPanel'
import ReportConfigurationReportersPanel from '../ReportConfigurationReportersPanel'
import ReportManagementPanel from '../ReportManagementPanel/ReportManagementPanel'
import ReportConfigurationFollowUpPanel from '../ReportConfigurationFollowUpPanel'
import ReportConfigurationCohortsPanel from '../ReportConfigurationCohortsPanel'
import ReportConfigurationPageUnavailable from '../ReportConfigurationPageUnavailable'
import ManageConditionsDrawer from '../drawers/ManageConditionsDrawer'
import ManageFollowUpDrawer from '../drawers/ManageFollowUpDrawer'
import ManageCohortConditionsDrawer from '../drawers/ManageCohortConditionsDrawer'
import ReporterFollowUpDrawer from '../drawers/ReporterFollowUpDrawer'

import { Can, useAbilities } from '../../../ability/ability-context'

import { hooks as reportConfigurationHooks } from '../../../store/modules/reportConfiguration'
import { useConditions, useFormViews, useRCEditor } from '../../../contexts'
import { constants as reportConfigurationEditorConstants } from '../../../contexts/ReportConfigurationEditorContext'
import FormViewContext from '../../../contexts/FormViewContext'
import { useQuery } from '../../../hooks'
import { addQuery } from '../../../helpers/query'
import UnsavedChangesDialog from './UnsavedChangesDialog'
import * as conditionsActions from '../../../contexts/ConditionsContext/actions'

const TabBar = styled(AppBar)(({ theme }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.grey[200]}`
}))

const ReportConfigurationTabs = (props) => {
  const { handleSave } = props
  const ability = useAbilities()
  const { state: rcEditorState } = useRCEditor()
  const { state: conditionsState, dispatch: conditionsDispatch, setReportFields } = useConditions()
  const { state: formViewState, actions: formViewActions, dispatch: formViewDispatch } = useFormViews()
  const { fields: reportFields, isEdited: isFormViewEdited } = formViewState
  const { isEdited: isConditionsEdited } = conditionsState

  const { isNewFormView, formViewId, type: formViewType } = rcEditorState
  const currentQuery = useQuery()
  const capabilities = reportConfigurationHooks.useFormViewVersionCapabilities(formViewId)

  const isEdited = (isFormViewEdited || isConditionsEdited)

  const [isUnsavedDialogOpen, setIsUnsavedDialogOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState(null)

  useEffect(() => {
    setReportFields(reportFields)
  }, [reportFields])

  useEffect(() => {
    if (formViewId) {
      addQuery({
        currentTab
      })
    }
  }, [])

  const handleChange = (e, selectedTab) => {
    if (
      isEdited &&
      currentQuery.currentTab === 'editor' &&
      currentQuery.currentTab !== selectedTab
    ) {
      setIsUnsavedDialogOpen(true)
      setSelectedTab(selectedTab)
    } else {
      addQuery({ currentTab: selectedTab })
    }
  }

  const handleDialogChoice = (choice) => {
    setIsUnsavedDialogOpen(false)
    switch (choice) {
      case 'DISCARD':
        addQuery({ currentTab: selectedTab })
        conditionsDispatch(conditionsActions.setUnedited())
        formViewDispatch(formViewActions.setUnedited())
        break
      case 'SAVE':
        handleSave()
        addQuery({ currentTab: selectedTab })
        break
      case 'CANCEL':
      default:
        break

    }
  }

  const tabs = useMemo(() => {
    const canHaveEditorTab = ability.can('viewEditorTab', 'reportConfigurationNext') && formViewType === reportConfigurationEditorConstants.REPORT
    const canHaveConditionsTab = ability.can('viewConditionsTab', 'reportConfigurationNext') && formViewType === reportConfigurationEditorConstants.REPORT && _.get(capabilities, 'conditions', false)
    const canHaveReportsTab = ability.can('viewReportsTab', 'reportConfigurationNext')
    const canHaveReportersTab = ability.can('viewReportersTab', 'reportConfigurationNext')
    const canHaveFollowUpsTab = ability.can('viewFollowUpsTab', 'reportConfigurationNext') && formViewType === reportConfigurationEditorConstants.REPORT
    const canHaveCohortsTab = ability.can('viewCohortsTab', 'reportConfigurationNext') && formViewType === reportConfigurationEditorConstants.REPORT

    return _.compact([
      canHaveEditorTab && { label: translations('Editor'), value: 'editor' },
      canHaveConditionsTab && { label: translations('Conditions'), value: 'conditions' },
      canHaveReportsTab && { label: translations('Reports'), value: 'reports' },
      canHaveReportersTab && { label: translations('Reporters'), value: 'reporters' },
      canHaveFollowUpsTab && { label: translations('Follow-Ups'), value: 'followups' },
      canHaveCohortsTab && { label: translations('Cohorts'), value: 'cohorts' }
    ])
  }, [ability, capabilities, formViewType])

  const getDefaultTab = () => {
    const defaultTab = _.get(tabs, '[0].value')
    if (defaultTab) {
      return defaultTab
    } else {
      return 'editor'
    }
  }

  const currentTab = currentQuery.currentTab || getDefaultTab()

  return (
    <>
      <UnsavedChangesDialog open={isUnsavedDialogOpen} handleClose={handleDialogChoice}
        headerText={translations('Report Configuration - Unsaved Changes Dialog Header')}
        contentText={translations('Report Configuration - Unsaved Changes Dialog Content')}
      />
      <TabContext value={currentTab}>
        <TabBar position='static' color='transparent' variant='outlined'>
          <TabList variant={'scrollable'} scrollButtons={'auto'} onChange={handleChange} aria-label={translations('Report configuration editor tabs')} indicatorColor={'primary'}>
            {_.map(tabs, ({ label, value }) => {
              return (
                <Tab label={label} style={{ textTransform: 'none' }} value={value} />
              )
            })}
          </TabList>
        </TabBar>
        <Can I='viewEditorTab' a='reportConfigurationNext'>
          <TabPanel value='editor'>
            <ReportConfigurationEditorPanel formViewStore={FormViewContext.formViewStore} formViewId={formViewId} isNewFormView={isNewFormView} />
          </TabPanel>
        </Can>
        <Can I='viewConditionsTab' a='reportConfigurationNext'>
          <TabPanel value='conditions'>
            <ReportManagementConditionsPanel formViewId={formViewId} handleSave={handleSave} />
          </TabPanel>
        </Can>
        <Can I='viewReportsTab' a='reportConfigurationNext'>
          <TabPanel value='reports'>
            {isNewFormView
              ? <ReportConfigurationPageUnavailable />
              : <ReportManagementPanel formViewId={formViewId} />
            }
          </TabPanel>
        </Can>
        <Can I='viewReportersTab' a='reportConfigurationNext'>
          <TabPanel value='reporters'>
            {isNewFormView
              ? <ReportConfigurationPageUnavailable />
              : <ReportConfigurationReportersPanel formViewId={formViewId} />
            }
          </TabPanel>
        </Can>
        <Can I='viewFollowUpsTab' a='reportConfigurationNext'>
          <TabPanel value='followups'>
            {isNewFormView
              ? <ReportConfigurationPageUnavailable />
              : <ReportConfigurationFollowUpPanel formViewId={formViewId} />
            }
          </TabPanel>
        </Can>
        <Can I='viewCohortsTab' a='reportConfigurationNext'>
          <TabPanel value='cohorts'>
            {isNewFormView
              ? <ReportConfigurationPageUnavailable />
              : <ReportConfigurationCohortsPanel formViewId={formViewId} />
            }
          </TabPanel>
        </Can>
      </TabContext >
      <ManageCohortConditionsDrawer />
      <ManageConditionsDrawer />
      <ManageFollowUpDrawer />
      <ReporterFollowUpDrawer />
    </>
  )
}

export default ReportConfigurationTabs
