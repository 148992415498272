import { colors } from '../../../config/theme'
import formStyle from '../../../components/Form/style'

const styles = {
  field: {
    width: '100%',
    maxWidth: '700px'
  },
  generalError: {
    height: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.error
  },
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background
  },
  formContainer: {
    position: 'relative',
    padding: '20px'
  },
  checkContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0 0 0'
  },
  textContainer: {
    maxWidth: '300px',
    width: '100%',
    paddingTop: '20px'
  },
  forgotLinkWrapper: {
    textAlign: 'center',
    padding: '30px 0 0 0'
  },
  forgotLink: {
    fontSize: 14,
    color: '#000'
  },
  header: {
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '20px 0 0 0'
  }
}

export default theme => ({
  ...formStyle,
  ...styles
})
