import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'

import { withStyles } from '@material-ui/core/styles'
import style from './style'

const TooltipDetails = (props) => {
  const { classes, label, title } = props

  const tooltipClasses = {
    root: classes.tooltipIcon,
    tooltip: classes.tooltip
  }

  const content = <p>
    <strong>{label}: </strong>
    <span>{title}</span>
  </p>

  return (
    <Tooltip title={content} classes={tooltipClasses}>
      <InfoIcon />   
    </Tooltip>
  )
}

TooltipDetails.propTypes = {
  classes: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default withStyles(style)(TooltipDetails)
