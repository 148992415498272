const styles = theme => ({
  formContainer: {
    overflow: 'auto',
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 50,
  },
  content: {
    width: '100%'
  }
})

export default styles
