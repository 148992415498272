import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const omitedOperatorsEnum = {
  EQUAL: 'equal',
  NOT_EQUAL: 'notEqual',
  IN_FUTURE: 'inFuture',
  IN_PAST: 'inPast'
}

const repeatableOmittedValues = [
  omitedOperatorsEnum.EQUAL,
  omitedOperatorsEnum.NOT_EQUAL,
  omitedOperatorsEnum.IN_FUTURE,
  omitedOperatorsEnum.IN_PAST
]

const notDateOmittedOperators = [
  omitedOperatorsEnum.IN_FUTURE,
  omitedOperatorsEnum.IN_PAST
]

const ValueSelector = (props) => {
  const {
    className,
    handleOnChange,
    options,
    value,
    title,
    field,
    fieldData,
    forceEquals
  } = props

  const [computedOptions, setComputedOptions] = useState(options)

  const splitField = _.split(field, '.')
  useEffect(() => {
    const isRepeatable = _.size(splitField) > 1 && !_.includes(_.get(splitField, '0'), 'block-')
    const isDate = _.get(fieldData, 'inputType') === 'date'
    let nextComputedOptions = _.cloneDeep(options)
    if (isRepeatable && !forceEquals) {
      nextComputedOptions = _.filter(nextComputedOptions, ({ name }) => !_.includes(repeatableOmittedValues, name))
      const hasItem = _.find(nextComputedOptions, { name: value })
      if (_.isUndefined(hasItem)) {
        handleOnChange(_.get(nextComputedOptions, '0.name'))
      }
    }
    if (!isDate) {
      nextComputedOptions = _.filter(nextComputedOptions, ({ name }) => !_.includes(notDateOmittedOperators, name))
    }
    setComputedOptions(nextComputedOptions)
  }, [field, fieldData, options])

  return (
    <FormControl className={className} title={title} size={'small'}>
      <Select value={value} onChange={(e) => handleOnChange(e.target.value)}>
        {_.map(computedOptions, (option) => {
          const key = option.id ? `key-${option.id}` : `key-${option.name}`
          return (
            <MenuItem key={key} value={option.name}>
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default ValueSelector
