import { colors } from '../../../config/theme'

const style = theme => ({
  listContainerTest: {
    background: colors.lightGrey,
    padding: '20px 30px',
    borderTop: `1px solid ${colors.border}`,
    height: `calc(100vh - ${theme.headerHeight + theme.subheaderHeight}px)`,
    overflow: 'auto'
  },
  resourceListMessage: {
    display: 'flex',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  uploadButton: {
    paddingRight: '20px'
  }
})

export default style
