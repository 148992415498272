
import Button from '../../../../components/Button'
import * as hooks from '../ConfigureArrangementModal/hooks'
import { translations } from '../../../../config'
import InsertButton from '../../../../components/FormviewEditor/InsertButton'

export const EditDictionaryButtonSmall = () => {
  const launchModal = hooks.useEntryConfigureModal({ editing: true })
    return <Button
        fullWidth
        onClick={launchModal}
    >{translations('Terminology - Edit Arrangement')}</Button>
}

export const AddDictionaryButtonSmall = () => {
  const launchModal = hooks.useEntryConfigureModal({ editing: false })
    return <Button
        variant='contained'
        color='primary'
        onClick={launchModal}
    >{translations('Terminology - Add Arrangement')}</Button>
}

export const AddDictionaryButtonLarge = () => {
   const launchModal = hooks.useEntryConfigureModal({ editing: false })
    return <InsertButton
        fullWidth
        size='large'
        onClick={launchModal}
    >
    {translations('Terminology - Add Arrangement')}
    </InsertButton>
}
