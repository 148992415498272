const style = (theme) => ({
  container: {
    padding: 50,
    [theme.breakpoints.down('xs')]: {
      padding: 15
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 40px',
    backgroundColor: '#f4f4f4'
  },
  footerButton: {
    margin: '10px 0'
  }
})

export default style
