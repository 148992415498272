import React, { useState, useEffect } from 'react'
import { push } from 'connected-react-router'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import Button from '../../../components/Button'
import BottomBar from '../../../components/BottomBar'
import { translations } from '../../../config'
import styles from './style'
import _ from 'lodash'
import fp from 'lodash/fp'
import FileUploader from './FileUploader'
import { actions as fileManagerActions } from '../../../store/modules/fileManager'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import * as schema from './schema'
import Form, { FormBody } from '../../../components/Form'
import { getFormValues } from 'redux-form'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { Typography } from '@material-ui/core'
import Hidden from '@material-ui/core/Hidden';
import InsertButton from '../../../components/FormviewEditor/InsertButton'

const AdvancedPostReportForm = Form('APRForm')

const useStyles = makeStyles(styles)

const AdvancedPostReport = () => {

  const dispatch = useDispatch()
  const classes = useStyles()
  const userId = useSelector(state => authSelectors.getActiveUserId(state))
  const organisationId = useSelector(state => authSelectors.getSelectedOrganisation(state).id)
  const [reports, setReports] = useState([])
  const [idCounter, setIdCounter] = useState(0)
  const formValues = useSelector(state => getFormValues('APRForm')(state))
  const parser = new DOMParser()

  const addReports = async (e) => {
    let startId = idCounter
    const files = e.target.files
    const newReports = []
    for(const file of files) {
      const isR3 = await checkIfReportIsR3(file)
      const report = {
        id: startId++,
        file,
        attachments: [{}],
        isR3: isR3
      }
      newReports.push(report)
    }
    const sortedReports = _.sortBy([...reports, ...newReports], (r) => { return r.id })
    setReports(sortedReports)
    setIdCounter(startId)
  }

  const deleteReport = (id) => {
    const newReports = _.reject(reports, {id})
    setReports(newReports)
  }

  const submitReports = () => {
    if (fp.size(reports) === 0) {
      return
    }
    const reportWithAttachments = _.map(reports, (r) => { 
      return {
        file: r.file,
        attachments: fp.getOr([], `${r.id}_attachment`, formValues)
      }
    })
    dispatch(fileManagerActions.setUploadFile({ files: reportWithAttachments, userId, organisationId }))
    dispatch(push('/'))
  }

  const checkIfReportIsR3 = async (file) => {
    const text = await file.text()
    const doc = parser.parseFromString(text, "application/xml")
    const queryResult = doc.querySelector('MCCI_IN200100UV01')
    return queryResult
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Advanced Post Report</title>
      </Helmet>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={
          <Heading component='h1' uppercase>
            Advanced Post Reports
          </Heading>
        }
      />
      <AdvancedPostReportForm>
        <div className={classes.contentContainer}>
          <div className={classes.reportsContainer}>
            {reports.map((report) => (
              <div className={classes.reportContainer}>
                <Grid
                  container
                  alignItems='center'
                  spacing={1}
                >
                  <Grid item wrap="nowrap" xs={12} sm={11} md={11}>
                    <Typography noWrap>{report.file.name}</Typography>
                  </Grid>
                  <Hidden xsDown>
                    <Grid item xs={12} sm={1} md={1} container justify='flex-end'>
                      <IconButton onClick={() => deleteReport(report.id)} color='secondary'>
                        <DeleteIcon titleAccess={'Remove Report'} />
                      </IconButton>
                    </Grid>
                  </Hidden>
                  {!report.isR3 && 
                    <Grid item xs={12}>
                      <ContentBoxBody>
                        <FormBody
                          formName={'APRForm'}
                          schema={schema.advancedReportFormSchema.schema(report.id)}
                          editing
                          fullWidthFields
                        />
                      </ContentBoxBody>
                  </Grid>}
                  <Hidden smUp>
                    <Grid item xs={12} md={1}>
                      <Button 
                        className={classes.deleteReportButton} 
                        onClick={() => deleteReport(report.id)} 
                        raised
                        color='primary'>Delete report</Button>
                    </Grid>
                  </Hidden>
                </Grid>
              </div>
            ))}
            <FileUploader
              accept={['.xml']}
              multiple={true}
              onChange={addReports}
              renderContent={({ onClick }) => (
                <InsertButton fullWidth size={'large'} onClick={onClick}>Add Reports</InsertButton>
              )}
            />
          </div>
        </div>
        </AdvancedPostReportForm >
        <BottomBar>
          <Button raised color='primary' disabled={_.isEmpty(reports)} onClick={submitReports}>Post Reports</Button>
        </BottomBar>
    </div>

	)
}

export default AdvancedPostReport