import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { styled } from '@material-ui/core/styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'

import Conditions from '../../../components/Conditions'
import Heading from '../../Heading'
import DragHandle from '../DragHandle'
import InsertButton from '../InsertButton'
import Field from '../Field'
import { useMoreOptions } from '../../MoreMenu'
import { translations } from '../../../config'

import DividerHeading from '../DividerHeading'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useConditions, useFormViews } from '../../../contexts'
import { actions as formViewActions } from '../../../contexts/FormViewContext'

const Container = styled('div')(({ theme, fieldContainer = false }) => ({
  position: 'relative',
  border: !fieldContainer && `1px solid ${theme.palette.grey[300]}`,
  marginBottom: theme.spacing(1),
  '&:last-child': {
    marginBottom: 0
  }
}))

const Header = styled('header')(({ theme, collapsible, disabled }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  cursor: collapsible ? 'pointer' : 'default',
  backgroundImage: disabled ? `linear-gradient(45deg, ${theme.palette.grey[100]} 25%, #eaeaea 25%, #eaeaea 50%, ${theme.palette.grey[100]} 50%, ${theme.palette.grey[100]} 75%, #eaeaea 75%, #eaeaea 100%)` : 'none',
  backgroundColor: disabled ? theme.palette.grey[100] : '#fff',
  backgroundSize: disabled ? '8px 8px' : 'initial'
}))

const BlockDelimiter = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  height: '100%',
  display: 'flex',
  background: theme.palette.primary.main,
  position: 'absolute',
}))

const HandleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row'
}))

const Icon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 34,
  height: 34,
  color: theme.palette.grey[800],
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 32,
  background: '#fff'
}))

const Content = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  background: theme.palette.grey[100]
}))

const Menu = styled('div')(({ theme }) => ({
  color: theme.palette.grey[800]
}))

const Actions = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2)
}))

const Block = (props) => {
  const {
    id,
    index,
    name,
    isOpen: initialOpen,
    visible,
    onMove,
    onEdit,
    onDelete,
    onToggleVisibility,
    onChangeBlock,
    onRemoveFromBlock,
    isFirst,
    isLast,
    fields,
    saveDraggedResult,
    moveAnyResource,
    sectionId,
    initialValues,
    conditions,
    onDeleteCondition,
    onManageConditions
  } = props
  const { state: { sections }, dispatch } = useFormViews()
  const openBlocks = _.get(sections, `${sectionId}.openBlocks`, [])
  const [disabled, setDisabled] = useState(true)
  const isOpen = _.includes(openBlocks, id)
  const [hasBeenDragged, setDragged] = useState(false)
  const [fieldSnapshot, setSnapshot] = useState(undefined)
  const { fieldLookup } = useConditions()
  const draggableId = `${id}`
  const orderedFields = _.sortBy(fields, ['index'])
  const editMeta = { id }
  const editProps = { subField: true }

  const onDragEnd = useCallback((payload) => {
    const movedResources = moveAnyResource(payload)
    if (movedResources) {
      const movedFields = _.get(movedResources, 'fields')
      const filteredFields = _.find(movedFields, { id })
      const currentFields = _.get(filteredFields, 'fields', [])
      const sortedFields = _.orderBy(currentFields, ['index'])
      const moveProps = _.get(movedResources, 'moveProps')
      setSnapshot(sortedFields)
      setDragged(true)
      saveDraggedResult(moveProps)
    }
  }, [fieldSnapshot])

  useEffect(() => {
    if (hasBeenDragged) {
      setDragged(false)
    } else if (!_.isEqual(orderedFields, fieldSnapshot)) {
      setSnapshot(orderedFields)
    }
  }, [orderedFields, fieldSnapshot])

  useEffect(() => {
    setDisabled(!visible)
  }, [visible])

  const onOpenToggle = (event, value) => {
    event.stopPropagation()
    dispatch(isOpen ? formViewActions.closeBlock({ sectionId, blocks: id }) : formViewActions.openBlock({ sectionId, blocks: id }))
  }

  const getCollapseButton = ({ isOpen, collapsible }) => {
    if (!collapsible) {
      return null
    }
    return (
      <Grid item onClick={(event) => onOpenToggle(event, isOpen)}>
        <IconButton size='small' aria-label={translations(isOpen ? 'Close' : 'Open')}>
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Grid>
    )
  }

  const getHeaderAction = (event, { isOpen, collapsible }) => {
    if (!collapsible) {
      return null
    }
    onOpenToggle(event, isOpen)
  }
  const handleEdit = useCallback(() => onEdit({ fieldType: 'BLOCK', meta: { id }, props: { initialValues } }), [props])
  const handleToggleVisibility = useCallback(() => onToggleVisibility({ resourceType: 'BLOCK', id }), [props])
  const handleMove = useCallback((props) => onMove({ resourceType: 'BLOCK', id, ...props }), [props])
  const handleDelete = useCallback(() => onDelete({ resourceType: 'BLOCK', id }), [props])

  const { MoreMenu, moreMenuProps, hasMoreMenu } = useMoreOptions({
    onEdit: handleEdit,
    onToggleVisibility: handleToggleVisibility,
    onMove: handleMove,
    onDelete: handleDelete,
    visible,
    isFirst,
    isLast
  })

  const conditionsActions = () => {
    const actions = []

    actions.push({
      label: translations('Add condition'),
      onClick: () => onManageConditions(),
      iconComponent: AddIcon
    })

    return actions
  }

  return (
    <Draggable draggableId={draggableId} index={index}>
      {provided => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <HandleWrapper>
            <BlockDelimiter />
            <DragHandle isBlock={true} dragHandleProps={provided.dragHandleProps} />
          </HandleWrapper>
          <Header collapsible={true} disabled={disabled} onClick={(event) => getHeaderAction(event, { isOpen, collapsible: true })}>
            <Grid container spacing={2} alignItems='center'>
              {getCollapseButton({ isOpen, collapsible: true })}
              {!visible && (
                <Grid item>
                  <Icon>
                    <VisibilityOffIcon fontSize={'small'} />
                  </Icon>
                </Grid>
              )}
              <Grid item xs>
                <Grid container spacing={0} alignItems='center'>
                  <Grid item xs>
                    <Grid item xs={12}>
                      <Heading component='h3' variant='h5'>{name}</Heading>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {hasMoreMenu &&
                <Grid item>
                  <Menu>
                    <MoreMenu id={id} {...moreMenuProps} />
                  </Menu>
                </Grid>
              }
            </Grid>
          </Header>
          <Collapse in={isOpen} timeout='auto' unmountOnExit>
            <Content>
              <DividerHeading heading={translations('Conditions')} actions={conditionsActions()} />
              <Conditions
                conditions={conditions}
                onDelete={onDeleteCondition}
                fieldLookup={fieldLookup}
                showCombinator={true}
              />
              <DividerHeading heading={translations('Questions')} />
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={id} type={'BLOCK'}>
                  {provided => (
                    <Container
                      fieldContainer={true}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {
                        _.map(fieldSnapshot, (field, index) => {
                          const { id: nestedId, isCustom, isHeading, name, fields = [] } = field
                          const isRepeatable = !isHeading && _.size(fields)
                          const fieldType = isHeading ? 'HEADING' : isRepeatable ? 'REPEATABLE' : 'QUESTION'
                          const fieldPayload = {
                            ...field,
                            key: nestedId,
                            type: fieldType,
                            blockId: id,
                            onToggleVisibility: () => onToggleVisibility({ resourceType: 'FIELD', nestedId, id }),
                            onChangeBlock: () => onChangeBlock({ source: id, name, id: nestedId }),
                            onRemoveFromBlock: () => onRemoveFromBlock({ source: id, name, id: nestedId, sectionId }),
                            onMove: (props) => onMove({ resourceType: 'FIELD', id, nestedId, ...props }),
                            onEdit: () => onEdit({ fieldType, meta: editMeta, props: { initialValues: field, ...editProps } }),
                            isFirst: index === 0,
                            isLast: index === (fields.length - 1),
                            ...((isCustom === true || isHeading === true && !isRepeatable) && {
                              onDelete: () => onDelete({ resourceType: 'FIELD', nestedId, id })
                            }),
                            index
                          }
                          return <Field {...fieldPayload} />
                        })
                      }
                      {provided.placeholder}
                      {
                        onEdit &&
                        (
                          <Actions >
                            <Grid container spacing={2}>
                              <Grid item xs>
                                <InsertButton
                                  onClick={() => onEdit({
                                    fieldType: 'QUESTION',
                                    meta: editMeta,
                                    props: { ...editProps, editing: false }
                                  })}
                                  fullWidth
                                  size='medium'>
                                  {translations('Add Question')}
                                </InsertButton>
                              </Grid>
                              <Grid item xs>
                                <InsertButton
                                  onClick={() => onEdit({
                                    fieldType: 'HEADING',
                                    meta: editMeta,
                                    props: { ...editProps, editing: false }
                                  })}
                                  fullWidth
                                  size='medium'>
                                  {translations('Add Heading')}
                                </InsertButton>
                              </Grid>
                            </Grid>
                          </Actions>
                        )
                      }
                    </Container>
                  )}
                </Droppable>
              </DragDropContext>
            </Content>
          </Collapse>
        </Container>
      )}
    </Draggable>
  )
}

Block.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onMove: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onToggleVisibility: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  conditions: PropTypes.array,
  onDeleteCondition: PropTypes.func
}

export default Block
