import React from 'react'

import AnalyticsMenu from './AnalyticsMenu'

const widgets = [
  { id: 'analytics', title: 'Analytics', image: 'analyticsImg', icon: 'analyticsIcon' },
  { id: 'healthcheck', title: 'Health Check', image: 'caseManagementImg', icon: 'caseManagementIcon' },
]

const AnalyticsScreen = () => {
  return (
    <>
      <AnalyticsMenu widgets={widgets} isDetail={false}/>
    </>
  )
}

export default AnalyticsScreen
