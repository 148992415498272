import React, { useState }from 'react'
import _ from 'lodash'

import modalService from '../../../../services/modalService'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import { MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ExportModal from '../ExportModal'
import { translations } from '../../../../config'

const ReportsMoreMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const options = [
    {
      label: translations('Export Reports'),
      value: 'export',
      component: ExportModal
    }
  ]

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelection = (value) => {
    const selectedOption = _.find(options, { value })
    const modalComponent = _.get(selectedOption, 'component')

    if (modalComponent) {
      modalService.open({
        component: modalComponent,
        wideModal: true
      })
    }

    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null )
  }

  const open = !!(anchorEl)

  return (
    <div>
      <IconButton
        aria-label='More'
        aria-owns={open ? 'configuration-menu' : null}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='configuration-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{}}
      >
        {options.map(option => (
          <MenuItem key={option.value} onClick={() => handleSelection(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default ReportsMoreMenu
