import { pure, compose, withHandlers } from 'recompose'
import _ from 'lodash'

import ChartTile from './ChartTile'
import analyticsService from '../../../services/analyticsService'

export default compose(
  pure,
  withHandlers({
    onClick: ({ chartCategory, chartOptions }) => () => {
      const dataType = _.get(chartOptions, 'dataType')
      if (dataType === 'CUSTOMER') {
        if (chartCategory === 'customerCountByDate') {
          analyticsService.sendCustomEvent({ type: 'customerCountByDateFullListView' })
        } else if (chartCategory === 'customerCountBySegment') {
          analyticsService.sendCustomEvent({ type: 'customerCountBySegmentFullListView' })
        } else if (chartCategory === 'customerSpendBySegment') {
          analyticsService.sendCustomEvent({ type: 'customerSpendBySegmentFullListView' })
        }
      }
    }
  })
)(ChartTile)
