import React, { Fragment, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

import { actions as translationActions } from '../../../../store/modules/translations'
import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import Heading from '../../../../components/Heading'
import Button from '../../../../components/Button'
import ButtonsContainer from '../../../../components/ButtonsContainer'
import { translations } from '../../../../config'
import styles from './style'
import BottomBar from '../../../../components/BottomBar'
import Container from '../../../../components/Container'
import BlockList, { BlockListItem } from '../../../../components/BlockList'
import ManageContentBox from '../../../../components/ManageContentBox'
import AddLanguageForm from '../components/AddLanguageForm'
import FileBrowserEnhancer from '../../../../components/FileBrowserEnhancer'

const renderLanguageList = ({ classes, languages, onClickExport, translationsCount = {}, refetchTranslations }) => {
  return (
    <div className={classes.languageList}>
      <AddLanguageForm
        onSuccess={refetchTranslations}
        languages={languages}
      />
      <div className={classes.blockList}>
        {translationsCount}
      </div>
      <Button
        children={translations('Manage Translations - Export Button')}
        onClick={onClickExport}
        raised
        color={'primary'}
      />
    </div>
  )
}

const renderTranslations = (translationList) => {
  return (
    <BlockList>
      {_.map(translationList, (item, i) => {
        const { isoCode, total } = item
        const language = translations(`Language - ${isoCode}`)
        const label = `${translations('Manage Translations - List Item', { language, total })}`
        return (
          <BlockListItem
            label={label}
            key={isoCode}
          />
        )
      })}
    </BlockList>
  )
}

const renderBottomBar = ({ width, onClickImport }) => {
  const isXs = width === 'xs'
  return (
    <BottomBar relative={isXs}>
      <ButtonsContainer align='center' column={isXs}>
        <FileBrowserEnhancer
          accept={['.csv']}
          onChange={onClickImport}
          renderContent={({ onClick }) => (
            <Button
              children={translations('Manage Translations - Import Button')}
              onClick={onClick}
              raised
              color={'primary'}
            />
          )}
        />
      </ButtonsContainer>
    </BottomBar>
  )
}

const renderIntro = () => {
  return (
    <div>
      <p>To ease editing multiple translations across different locales you can upload a spreadsheet to replace translations.</p>
      <p>To be valid the CSV needs to meet the following criteria:</p>
      <ul>
        <li>First column header needs to be "key"</li>
        <li>Keys should not be edited or removed. New keys will be ignored.</li>
        <li>All other column headers are required to be valid ISO 639-1 code e.g. "en", "fr", "de"</li>
        <li>File type of .csv with UTF-8 encoding</li>
      </ul>

      <p>To add new language(s):</p>
      <ul>
        <li>Choose from the list of available languages</li>
        <li>{`Select '${translations('Manage Translations - Add Language Button')}'`}</li>
        <li>{`Select the '${translations('Manage Translations - Export Button')}' button`}</li>
        <li>Add your new translations to the exported file</li>
        <li>
          Save the file ensuring it's encoding type is 'UTF-8', otherwise special characters will not show correctly.
          <ul>
            <li>
              <strong>Excel (Windows): </strong>
              <ul>
                <li>File > Save As > Save as type > CSV (Comma delimited) (*.csv)</li>
                <li>Still within the 'Save As' menu. Tools > Web Options... > Unicode (UTF-8)</li>
              </ul>
            </li>
            <li>
              <strong>Excel (Mac): </strong>
              <ul>
                <li>File > Save As > File Format > CSV UTF-8 (Comma delimited) (.csv)</li>                
              </ul>
            </li>
          </ul>
        </li>
        <li>Import your updated translations file</li>
      </ul>

      <p><b>Warning:</b>Uploading a new spreadsheet will override the existings translations for all matching keys and languages.</p>
    </div>
  )
}

const UploadTranslations = ({ classes, onClickExport, onClickImport, width, languages, pageTitle, organisationId, existingTranslations, organisationTranslations }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (organisationId) {
      dispatch(translationActions.setOffset({ offset: -1 }))
      dispatch(translationActions.searchTranslationsForOrganisation({ organisationId }))
    }
  }, [dispatch, organisationId])

  const refetchTranslations = useCallback(() => {
    if (organisationId) {
      dispatch(translationActions.setOffset({ offset: -1 }))
      dispatch(translationActions.searchTranslationsForOrganisation({ organisationId }))
    }
  }, [organisationId])

  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1' uppercase>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
      />
      <Container withMarginTop>
        <ManageContentBox
          name={translations('Manage Translations - Upload Title')}
          content={renderIntro()}
        />
        <ManageContentBox
          name={translations('Manage Translations - Languages Title')}
          content={renderLanguageList({ classes, translationsCount: renderTranslations(organisationTranslations), onClickExport, languages, refetchTranslations })}
        />
        {renderBottomBar({ width, onClickImport })}
      </Container>
    </div>
  )
}

UploadTranslations.propTypes = {
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(UploadTranslations)
