import fp from 'lodash/fp'
import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { REQUEST_STATUS_TYPE } from './constants'

export const createStatefulThunk = ({
    asyncFunction,
    path,
    typePrefix,
    options = {}
}) => createAsyncThunk(
    typePrefix,
    async (arg, thunkApi) => {
        const metadata = {
            path,
            isPaginated: options.isPaginated
        }
        try {
            const data = await asyncFunction(arg, thunkApi)
            return thunkApi.fulfillWithValue({ data }, metadata)
        } catch (error) {
            return thunkApi.rejectWithValue(error, metadata)
        }
    },
    {
        getPendingMeta: () => ({ path })
    }
)


export const statusAndNameMatcher = (NAME, status) => (data) => {
  const requestStatusEq = fp.get('meta.requestStatus', data) === status
  const namespace = fp.get('type', data)
  const namespaceEq = fp.startsWith(NAME)(namespace)
  return requestStatusEq && namespaceEq
}


const set = ({ state, action, path, value }) => {
    const location = _.concat(_.castArray(action.meta.path), path)
    _.set(state, location, value)
}
const isEmptyArray = fp.allPass([fp.isArray, fp.isEmpty])

const setPaginated = ({ state, action }) => {
    const results = fp.getOr([], 'payload.data.results', action)
    if (state.master.data) {
        state.master.data.push(...results)
    } else {
        state.master.data = results
    }
    set({
        state,
        action,
        path: 'status',
        value: isEmptyArray(state.master.data) ? REQUEST_STATUS_TYPE.IS_EMPTY : REQUEST_STATUS_TYPE.FULFILLED
    })
    _.set(state, 'master.pagination.total', action.payload.data.total)
    _.set(state, 'master.pagination.count', state.master.data.length)
}

export const createExtraReducers = (sliceName) => (builder) => {
        return builder
            .addMatcher(
                statusAndNameMatcher(sliceName, 'pending'),
                (state, action) => {
                    set({ state, action, path: 'status', value: REQUEST_STATUS_TYPE.PENDING })
                }
            )
            .addMatcher(
                statusAndNameMatcher(sliceName, 'fulfilled'),
                (state, action) => {
                    if (action.meta.isPaginated === true) {
                        setPaginated({ state, action })
                        return
                    }
                    const { data } = fp.getOr({}, 'payload', action)
                    set({
                        state,
                        action,
                        path: 'status',
                        value: isEmptyArray(data) ? REQUEST_STATUS_TYPE.IS_EMPTY : REQUEST_STATUS_TYPE.FULFILLED
                    })
                    set({ state, action, path: 'data', value: data })

                }
            )
            .addMatcher(
                statusAndNameMatcher(sliceName, 'rejected'),
                (state, action) => {
                    set({ state, action, path: 'status', value: REQUEST_STATUS_TYPE.REJECTED })
                    set({ state, action, path: 'error', value: fp.get('payload.message', action) })
                    set({ state, action, path: 'errorCode', value: fp.get('payload.code', action) })
                    set({ state, action, path: 'errorMeta', value: fp.get('payload.meta', action) })
                }
            )
    }
