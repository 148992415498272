import { createSelector } from 'reselect'
import _ from 'lodash'
import fp from 'lodash/fp'
import { LOGIN, EDIT_WATCH_LIST } from './constants'
import { PENDING } from '../../middleware/redux-promise'
import { unpackRules } from '@casl/ability/extra'
import { userAccountTypeEnum } from '../../../containers/Users/constants'

export const getErrorMessage = state => (state.auth.error || {}).message
export const getStatus = state => state.auth.status
export const getAction = state => state.auth.action
export const getMigrationStatus = state => _.get(state, 'auth.migrationRequired')
export const getCurrentUser = state => (state.auth || {}).user
export const getIsLoggedIn = state => !!_.get(state, 'auth.user')
export const getIsUserAssessor = state => _.get(state, 'auth.user.accountType') === userAccountTypeEnum.ASSESSOR
export const getAccountIsLocked = state => _.chain(state)
  .get('auth.error.code')
  .eq('RA-02-02')
  .value()
export const getUserSelectedOrganisationId = state => {
  return _.get(state, 'auth.user.selectedOrganisationId')
}

export const getUserSelectedOrganisationIdOrTemplateId = state => {
  const selectedOrganisation = _.get(state, 'auth.user.selectedOrganisation')
  const selectedId = _.get(selectedOrganisation, 'templateId')
    ? _.get(selectedOrganisation, 'templateId')
    : _.get(selectedOrganisation, 'id')
  return selectedId
}
export const getUserSelectedRegionId = state => (state.auth.user || {}).selectedRegionId
export const getUserSelectedReceiverId = state => (state.auth.user || {}).selectedReceiverId
export const getLoggedInUserRole = state => (state.auth.user || {}).role
export const getAuth = state => _.get(state, 'auth')

export const getCurrentOrganisationApplications = state => _.get(state, 'auth.user.selectedOrganisation.applications', [])

export const getIsUserAdmin = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'isAdmin'))

export const getRoleValue = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'value'))
/**
 * Check whether the current signed in user's role is __"Public"__ (`6`) or a falsey value.
 */
export const isPublicOrUnknownRole = createSelector([
  getRoleValue
], roleValue => {
  return roleValue === 6 || _.isNil(roleValue)
})

export const getLoggedInUsersPermissions = createSelector([
  getLoggedInUserRole,
  getIsUserAdmin
], (role, isAdmin) => {
  return {
    isAdmin,
    ..._.get(role, 'permissions', {})
  }
})

export const getHasUserManagement = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.userManagement', false))

export const getHasHigherUserAccess = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.higherUserAccess', true))

export const getHasCreateAck = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.createAck', false))

export const getHasConfigureNews = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.configureNews', false))

export const getHasConfigureOrgPermission = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.configureOrg', false))

export const getHasConfigureOrgFundamentalsPermission = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.configureOrgFundamentals', false))

export const getCanViewOrganisation = getHasConfigureOrgPermission

export const getCanViewAllReports = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.viewAllReports', false))

export const getCanEditReportConfig = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.editReportConfig', false))

export const getHasCommunicationPermissions = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.editCommunications', false))

export const getCanViewOrganisationReports = createSelector([
  getLoggedInUserRole,
  getCanViewAllReports
], (role, canViewAllReports) => (canViewAllReports || _.get(role, 'permissions.viewOrganisationReports', false)))

export const getCanViewOwnReports = createSelector([
  getLoggedInUserRole,
  getCanViewOrganisationReports
], (role, canViewOrganisationReports) => (canViewOrganisationReports || _.get(role, 'permissions.viewOwnReports', false)))

export const getCanEditFiles = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.editFiles', false))

export const getCanViewFiles = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.viewFiles', false))

export const getCanViewAnalytics = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.viewAnalytics', false))

export const getHasPlatformDetailsPermissions = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.platformDetails', false))

export const getHasCreateNewsPermissions = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.createNews', false))

export const getHasEditNewsPermissions = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.updateNewsArticle', false))

export const getHasDeleteNewsPermissions = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.deleteNews', false))

export const getHasConfigureCasesPermission = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.configureCases', false))

export const getHasMultipleOrganisations = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'multipleOrganisations', false))

export const getIsLoggingIn = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === LOGIN)
))

export const getHasViewOtherUsersNotifications = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.viewOtherUsersNotifications', false))

export const getUserOrganisationsOptions = createSelector([
  getCurrentUser
], user => (
  user ? user.organisations.map(organisation => ({
    label: organisation.displayName || organisation.name,
    value: organisation.id
  })) : []
))
export const getUserStandardOrganisationRegionOptions = createSelector([
  getCurrentUser
], user => {
  return fp.compose(
    fp.map(organisation => ({
      label: organisation.displayName || organisation.name,
      value: `${organisation.id}_${organisation.regionId}_${organisation.receiverId}`
    })),
    fp.filter({ systemType: 'STANDARD' }),
    fp.get('organisations')
  )(user)
})

export const getUserHasPendingChanges = createSelector([
  getCurrentUser
], user => {
  return fp.compose(
    fp.size,
    fp.get('changeRequests')
  )(user) > 0
})

export const getUserPendingChanges = createSelector([
  getCurrentUser
], user => {
  return fp.compose(
    fp.map(({ organisationName }) => organisationName),
    fp.get('changeRequests')
  )(user)
})

export const getUserPendingChangesString = createSelector([
  getUserPendingChanges
], changes => {
  return fp.join(',')(changes)
})

export const getUserOrganisations = createSelector([
  getCurrentUser
], user => (
  user ? user.organisations : []
))

export const getUserOrganisationIds = createSelector([
  getUserOrganisations
], (userOrganisations) => userOrganisations.map(organisation => organisation.id))

export const getUserOrganisationOptions = createSelector([
  getUserOrganisations
], (userOrganisations) => _.map(userOrganisations, organisation => ({ value: organisation.id, label: organisation.name })))

export const getUserStandardOrganisationOptions = createSelector([
  getUserOrganisations
], (userOrganisations) => _.chain(userOrganisations)
  .filter(org => org.systemType === 'STANDARD')
  .map(organisation => ({ value: organisation.id, label: organisation.name }))
  .value()
)

export const getSelectedOrganisation = createSelector([
  getCurrentUser
], (user) => {
  return _.get(user, 'selectedOrganisation', {})
})

export const getSelectedOrganisationInternalNewsFeedId = createSelector(
  [getSelectedOrganisation], (organisation) => {
    const internalFeed = _.find(organisation.newsFeeds, (feed) => feed.isInternalFeed)
    return _.get(internalFeed, 'id')
  })

export const getSelectedOrganisationThemeId = createSelector(
  [getSelectedOrganisation], (org) => {
    return _.get(org, 'themeId')
  })

export const getSelectedOrganisationApplications = createSelector(
  [getSelectedOrganisation], (org) => {
    return _.get(org, 'applications', [])
  })

export const getSelectedOrganisationProfessionGroups = createSelector(
  [getSelectedOrganisation], (org) => {
    return _.get(org, 'professionGroups', [])
  })

const getOrganisationsHashmap = createSelector(
  [getUserOrganisations],
  organisations => {
    return (organisations || []).reduce(
      (hashmap, organisation) => {
        return {
          ...hashmap,
          [organisation.id]: organisation
        }
      }, {})
  }
)

export const getUserOrganisationFromId = (state, id) => {
  const organisationsHashMap = getOrganisationsHashmap(state)
  const organisation = organisationsHashMap[id] || {}
  return organisation
}

export const getActiveUsername = createSelector([
  getCurrentUser
], user => {
  return user && user.username
})

export const getActiveEmail = createSelector([
  getCurrentUser
], user => {
  return user && user.email
})

export const getActiveTelephone = createSelector([
  getCurrentUser
], user => {
  return user && user.telephone
})

export const getActiveUserValue = createSelector([
  getCurrentUser
], currentUser => {
  return currentUser && currentUser.role.value
})

export const getActiveUserId = createSelector([
  getCurrentUser
], user => {
  return user && user.id
})

export const getActiveUserFullName = createSelector([
  getCurrentUser
], user => {
  return user && `${user.firstName} ${user.lastName}`
})

export const hasCompleteProfile = createSelector([
  getCurrentUser
], user => {
  return user && user.firstName && user.lastName
})

export const isMigrationRequired = createSelector([
  getMigrationStatus
], (migrationRequired) => {
  return migrationRequired
})

export const getIsLoadingEditWatchList = state => {
  return state.auth.status === PENDING && state.auth.action === EDIT_WATCH_LIST
}

export const getPermissions = createSelector([getCurrentUser],
  fp.compose(
    unpackRules,
    fp.getOr([], 'policy.rules')
  )
)

export const getIsCustomPermissionsPolicy = createSelector([getCurrentUser], user => {
  return _.get(user, 'policy.isCustom')
})

export const getWorkspaceId = createSelector([getCurrentUser], fp.get('workspaceId'))

export const getHasSinglePlatformRole = createSelector([getRoleValue], value => {
  return value > 3
})

export const isLoggedIntoWorkspace = createSelector([getCurrentUser, getUserSelectedOrganisationId],
  (user, selectedOrganisationId) => {
    if (user.workspaceId && user.workspaceId === selectedOrganisationId) {
      return true
    }
    return false
  })

export const getFormAuth = createSelector([
  getCurrentUser
], user => {
  return {
    id: user.id,
    token: user.token,
    refreshToken: user.refreshToken,
    platformId: user.platformId
  }
})

export const isSuperAdmin = createSelector([getActiveUserValue], fp.eq(0))
