import {
  ADD_CONDITION,
  UPDATE_CONDITION,
  REMOVE_CONDITION,
  SET_UNEDITED,
  UPDATE_FIELDS,
  SET_CONDITIONS,
  ATTACH_TO_RESOURCE,
  REMOVE_FROM_RESOURCE,
  REPLACE_RESOURCE_CONDITIONS,
  SET_RESOURCE_CONDITIONS
} from './constants'

export const addCondition = (payload) => ({ type: ADD_CONDITION, payload })
export const updateCondition = (payload) => ({ type: UPDATE_CONDITION, payload })
export const removeCondition = (payload) => ({ type: REMOVE_CONDITION, payload })
export const updateFields = (payload) => ({ type: UPDATE_FIELDS, payload })
export const setUnedited = () => ({ type: SET_UNEDITED })
export const setConditions = (payload) => ({ type: SET_CONDITIONS, payload })
export const attachToResource = (payload) => ({ type: ATTACH_TO_RESOURCE, payload })
export const removeFromResource = (payload) => ({ type: REMOVE_FROM_RESOURCE, payload })
export const replaceResourceConditions = (payload) => ({ type: REPLACE_RESOURCE_CONDITIONS, payload })
export const setResourceConditions = (payload) => ({ type: SET_RESOURCE_CONDITIONS, payload })

