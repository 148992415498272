"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validatePassword = exports.matchingPassword = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var passwordPolicy = {
  REQUIRED: {
    rule: 8,
    message: 'Field is required'
  },
  MIN_LENGTH: {
    rule: 9,
    message: 'Password must be at least 9 characters long'
  },
  UPPERCASE_MIN_COUNT: {
    rule: 1,
    message: 'Password must include at least 1 uppercase letter'
  },
  LOWERCASE_MIN_COUNT: {
    rule: 1,
    message: 'Password must include at least 1 lowercase letter'
  },
  NUMBER_MIN_COUNT: {
    rule: 1,
    message: 'Password must include at least 1 number'
  }
};

var applyRequireRule = function applyRequireRule(password, rule) {
  return password;
};

var applyMinLengthRule = function applyMinLengthRule(password, rule) {
  return password && password.length >= rule;
};

var applyMaxLengthRule = function applyMaxLengthRule(password, rule) {
  return password && password.length <= rule;
};

var applyUppercaseMinCountRule = function applyUppercaseMinCountRule(password, rule) {
  var uppercaseCount = password.match(/([A-Z])/g);
  return uppercaseCount && uppercaseCount.length >= rule;
};

var applyLowercaseMinCountRule = function applyLowercaseMinCountRule(password, rule) {
  var lowercaseCount = password.match(/([a-z])/g);
  return lowercaseCount && lowercaseCount.length >= rule;
};

var applyNumberMinCountRule = function applyNumberMinCountRule(password, rule) {
  var numberCount = password.match(/([\d])/g);
  return numberCount && numberCount.length >= rule;
};

var applySpecialCharMinCountRule = function applySpecialCharMinCountRule(password, rule) {
  var specialCharCount = password.match(/([\!\?\-])/g);
  return specialCharCount && specialCharCount.length >= rule;
};

var applyNonRepeatingRule = function applyNonRepeatingRule(password, rule) {
  var repeatingCharacters = password.match(/(.)\1+/g);
  return !repeatingCharacters;
};

var applyPolicyRule = function applyPolicyRule(options) {
  switch (options.policyRuleName) {
    case 'REQUIRED':
      return applyRequireRule(options.password, options.policyRule.rule);

    case 'MIN_LENGTH':
      return applyMinLengthRule(options.password, options.policyRule.rule);

    case 'MAX_LENGTH':
      return applyMaxLengthRule(options.password, options.policyRule.rule);

    case 'UPPERCASE_MIN_COUNT':
      return applyUppercaseMinCountRule(options.password, options.policyRule.rule);

    case 'LOWERCASE_MIN_COUNT':
      return applyLowercaseMinCountRule(options.password, options.policyRule.rule);

    case 'NUMBER_MIN_COUNT':
      return applyNumberMinCountRule(options.password, options.policyRule.rule);

    case 'SPECIAL_CHAR_MIN_COUNT':
      return applySpecialCharMinCountRule(options.password, options.policyRule.rule);

    case 'NON_REPEATING':
      return applyNonRepeatingRule(options.password, options.policyRule.rule);

    default:
      return new Error('Unknow policy rule');
  }
};

var validatePassword = function validatePassword(password) {
  var errors = _lodash["default"].chain(passwordPolicy).map(function (policyRule, policyRuleName) {
    return {
      policyRule: policyRule,
      policyRuleName: policyRuleName
    };
  }).filter(function (next) {
    var policyRule = next.policyRule;
    var policyRuleName = next.policyRuleName;
    var ruleMatches = applyPolicyRule({
      policyRuleName: policyRuleName,
      policyRule: policyRule,
      password: password
    });
    return !ruleMatches;
  }).map(function (next) {
    var policyRule = next.policyRule;
    return policyRule.message;
  }).value();

  return errors.length === 0 ? undefined : errors[0];
};

exports.validatePassword = validatePassword;

var matchingPassword = function matchingPassword(value, data) {
  var password = _lodash["default"].get(data, 'password');

  var matching = _lodash["default"].eq(password, value);

  if (matching) {
    return undefined;
  }

  return 'Password does not match';
};

exports.matchingPassword = matchingPassword;