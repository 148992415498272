import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import { selectors as reportsSelectors } from '../auth'
import actions from './actions'

class MedDRAActionsMiddleware {
  fetchVersions = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const matchesOrgDetails = matchPath(path, { path: '/organisations/:id', exact: true })
      const matchesMyOrgDetails = matchPath(path, { path: '/my-organisation', exact: true })
      if (matchesOrgDetails || matchesMyOrgDetails) {
        dispatch(actions.fetchMeddraVersions())
      }
    }
  }
}

export default new MedDRAActionsMiddleware()
