import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import style from '../style'
import P from '../../P'
import _ from 'lodash'

const BlockList = ({ key, label, renderRight, classes, renderLabelAsLink = false, error }) => {
  return (
    <li key={key} className={classes.fieldEntry}>
      <div className={classes.fieldEntryLabel}>
        {renderLabel(label, renderLabelAsLink)}
        { error && <P value={error} type='error' /> }
      </div>
      <div className={classes.fieldEntryActions}>
        {renderRight && renderRight()}
      </div>
    </li>
  )
}

BlockList.propTypes = {
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  renderRight: PropTypes.func,
  classes: PropTypes.object.isRequired,
  renderLabelAsLink: PropTypes.bool,
  error: PropTypes.string
}

const renderLabel = (label, renderLabelAsLink) => {
  const splitLabel = _.split(label, '\n')
  if(splitLabel.length > 1) {
    return (_.map(splitLabel, (item, index) => {
      const text = (index > 0) ? ` - ${item}` : item
      return (
        <P
          value={
          renderLabelAsLink
          ? <a href={item} target='_blank'>{text}</a>
          : text
        }
        dontBreakOut
      />
    )}))
  }
  return (
    <P
      value={
        renderLabelAsLink
        ? <a href={label} target='_blank'>{label}</a>
        : label
      }
      dontBreakOut
    />
  )
}

export default withStyles(style)(BlockList)
