import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

import P from '../P'
import style from './style'

const FullScreenLoader = ({
  classes,
  loadingMessage
}) => (
  <div className={classes.root}>
    <CircularProgress size={50} />
    {
      loadingMessage
      ? <P value={loadingMessage} className={classes.message} />
      : null
    }
  </div>
)

export default withStyles(style)(FullScreenLoader)
