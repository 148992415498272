import _ from 'lodash'
import { push } from 'connected-react-router'

import appsConfig from './configFiles/apps'
import { translations } from './translations'

const appActions = {
  REPORTS: push('/reports'),
  REPORT_MANAGEMENT: push('/report-management'),
  REPORT_CONFIGURATION: push('/report-configuration'),
  REPORT_CONFIGURATION_NEXT: push('/report-configuration-beta'),
  RESOURCES: push('/resources'),
  USER_MANAGEMENT: push('/users'),
  ORGANISATION_MANAGEMENT: push('/organisations'),
  OWN_ORGANISATION: push('/my-organisation'),
  ACKNOWLEDGEMENTS: push('/acknowledgements'),
  NEWS: push('/news'),
  PRODUCTS: push('/products'),
  PLATFORMS: push('/platforms'),
  WATCH_LIST: push('/watch-list'),
  NOTIFICATIONS: push('/notifications'),
  PHARMACEUTICAL_REFERRALS: push('/major-safety-review-upload'),
  COMMUNICATIONS: push('/communications'),
  ANALYTICS: push('/monitoring-and-analytics'),
  CASE_MANAGEMENT: push('/transmission-management'),
  CHANGE_MANAGEMENT: push('change-management'),
  DEVELOPER: push('/developer'),
  INTEGRATION_MANAGEMENT: push('/integration-management'),
  TERMINOLOGY: push('/terminology'),
  WEBSITE_MANAGEMENT: push('/website-management'),
  PERMISSIONS_EDITOR: push('/permissions'),
  EXPORT_MANAGEMENT: push('/export-management')
}

const apps = (
  appsConfig.apps
    ? _.keys(appActions).reduce(
      (acc, key) => {
        const appConfig = appsConfig.apps[key]
        const val = (
          appConfig
            ? {
              id: key,
              action: appActions[key],
              ..._.pick(appConfig, ['title', 'image', 'icon', 'badgeType'])
            }
            : null
        )
        acc[key] = val
        return acc
      }, {}
    )
    : {}
)

const dashboard = _.chain(appsConfig.dashboard || [])
  .map(key => apps[key])
  .compact()
  .value()

const menu = [
  { id: 'DASHBOARD', action: push('/'), title: translations('Dashboard') },
  ...(
    _.chain(appsConfig.menu || [])
      .map(key => apps[key])
      .compact()
      .value()
  )
]

export { apps, dashboard, menu }
