import * as constants from './constants'
import reducers from './reducers'

const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.GET_ORGANISATION_HEALTH_CHECK:
      return reducers.getOrganisationHealthCheck(state, action)
    case constants.CHANGE_FILTERS:
      return reducers.changeFilters(state, action)
    default:
      return state
  }
}
