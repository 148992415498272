import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'
import classNames from 'classnames'
import SettingsIcon from '@material-ui/icons/Settings'
import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import NewsSearchTable from '../NewsSearchTable'
import NewsLoadingBar from '../NewsLoadingBar'
import Button from '../../../../components/Button'
import Heading from '../../../../components/Heading'

import NewsSearchFilters from '../NewsSearchFilters'
import AddNewsArticleButton from '../../../../components/RegistrationButton'
import { translations } from '../../../../config'

import styles from './style'

const NewsSearchScreen = ({
  classes,
  onBackClick,
  hideBottomBar,
  inModal,
  isConfigureVisible,
  newsType,
  setNewsType,
  width,
  hasWatchListPermissions,
  pageTitle
}) => {
  const scrollContainerClass = classNames({
    [classes.scrollContainerInModal]: inModal,
    [classes.scrollContainer]: !inModal && hideBottomBar,
    [classes.scrollContainerWithBottomBar]: !inModal && !hideBottomBar
  })
  return (
    <div className={classes.container}>
      <NewsLoadingBar />
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1' uppercase>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
        rightContent={(
          <div className={classes.configureFeedButtonWrapper}>
            {isConfigureVisible ? (
              <Button
                buttonType='primary'
                raised
                to='/news/feeds'
                aria-label={translations('Configure Feeds')}
              >
                {width === 'xs' ? <SettingsIcon /> : translations('Configure Feeds')}
              </Button>
            ) : null}
          </div>
        )}
      />
      {
        hasWatchListPermissions
        ? <Tabs value={newsType} onChange={(e, val) => setNewsType(val)} centered fullWidth indicatorColor='primary'>
          <Tab classes={{ root: classes.tabRoot }} label='News' value='default' />
          <Tab classes={{ root: classes.tabRoot }} label='Watchlist' value='watchlist' />
        </Tabs>
        : null
      }
      <NewsSearchFilters newsType={newsType} />
      <div className={scrollContainerClass}>
        <NewsSearchTable newsType={newsType} fullHeight />
      </div>
      {/* Lets re-consider this design, we are taking up way to much screen space for one button,
        it should be added to the header next to "configure feeds" */}
      {!hideBottomBar && (
        <AddNewsArticleButton to='/news/create'>
          {translations('Add New Article')}
        </AddNewsArticleButton>
      )}
    </div>
  )
}

NewsSearchScreen.defaultProps = {
  hideBottomBar: true
}

NewsSearchScreen.propTypes = {
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}


export default withStyles(styles)(NewsSearchScreen)
