import { promiseReducer } from '../../util'
class ReceiversReducers {
  fetchReceivers = promiseReducer(
    (state, action) => ({
      ...state,
      receivers: action.result
    })
  )
}

export default new ReceiversReducers()
