import React from 'react'
import { Paper, Typography, Button } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Loader from '../Loader'
import { translations } from '../../config'
import Alert from '@material-ui/lab/Alert'
import { styled } from '@material-ui/core/styles'

const ReviewInfo = ({ reportReview , className, isLoading, onApprove }) => {
  const { approvedByUser, rejectedByUser, approvedAt, rejectedAt, approvalComment, rejectionComment } = reportReview
  const theme = useTheme()
  const approvedAtDate = new Date(approvedAt).toLocaleString()
  const rejectedAtDate = new Date(rejectedAt).toLocaleString()

  const CommentsContainer = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '10px 10px 10px 10px',
    margin: '8px 0 8px 0',
    backgroundColor: theme.palette.grey[100],
    fontSize: 14,
    lineHeight: 1,
    borderRadius: '4px'
}))

  return (
      <Paper elevation={0} style={{ padding: '16px', marginBottom: '16px', border: `1px solid ${theme.palette.grey[200]}`}} className={className}>
       <Typography variant="h6" style={{ color: theme.palette.primary, fontWeight: 'bold', paddingBottom: '12px' }}>
        {translations('Review Info - Title')}
        </Typography>
         {isLoading && <Loader/>}
          {rejectedAt && (
            <Alert severity='error' style={{ fontWeight: 'bold'}}> {translations('Review Info - Rejected By', {rejectedByUser, rejectedAt: rejectedAtDate})}</Alert>
          )}
          {rejectionComment && (     
            <CommentsContainer>
              <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              {translations('Review Info - Rejection Comments')}
              </Typography>
              <Typography variant="body2">
              {rejectionComment}
              </Typography>
            </CommentsContainer>
          )}
          {approvedAt && (
          <Alert severity='success' style={{ fontWeight: 'bold'}}>{translations('Review Info - Approved By', {approvedByUser, approvedAt: approvedAtDate})}</Alert>
         )}
          {approvalComment && (
            <CommentsContainer>
              <Typography variant="body2" style={{fontWeight: 'bold'}}>
              {translations('Review Info - Approval Comments')}
              </Typography>
              <Typography variant="body2">
              {approvalComment}
              </Typography>
            </CommentsContainer>
          )}     
      {!approvedAt && rejectedAt && (
        <Button onClick={onApprove} variant="contained" color="secondary" style={{ marginTop: '16px' }}>
          {translations('Review Info - Revert Rejection')}
        </Button>
      )}
    </Paper>
  )
}

export default ReviewInfo
