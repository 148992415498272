import { withPropsOnChange } from 'recompose'
import _ from 'lodash'

const formatOptions = options => {
  return _.map(options, (option) => {
    if (typeof option === 'object') return option
    return { label: option, value: option }
  })
}

const OptionsFormatter = withPropsOnChange(
  ['options'],
  ({ options }) => ({
    options: formatOptions(options)
  })
)

export default OptionsFormatter
