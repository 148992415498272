import _ from 'lodash'
import { useSelector } from 'react-redux'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { selectors as userDetailsSelectors } from '../../../../../store/modules/userDetails'
import EditUserProfessions from './Components/EditUserProfessions'
import CreateUserProfessions from './Components/CreateUserProfessions'
import AddUserProfessions from './Components/AddUserProfessions'

const ProfessionsWrapper = (props) => {
  const {
    isPublic,
    editing
  } = props

  const currentUserOrganisationId = useSelector(authSelectors.getUserSelectedOrganisationId)
  const currentUserRoleId = useSelector(userDetailsSelectors.getSelectedUserValue)
  const isEditingUser = !_.isNil(currentUserOrganisationId) && !_.isNil(currentUserRoleId)

  if (isPublic) {
    return <CreateUserProfessions editing={editing} />
  }
  if (isEditingUser) {
    return <EditUserProfessions editing={editing} />
  }
  return <AddUserProfessions editing={editing} />
}

export default ProfessionsWrapper