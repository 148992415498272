import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

export const StyledH3 = styled.h3`
  color: ${props => props.theme.colors.secondary};
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
  font-size: ${props => props.theme.type.heading3.small}px;
  ${media.greaterThan('medium')`
    margin-bottom: 15px;
    font-size: ${props => props.theme.type.heading3.large}px;
  `}
`

/**
 * The main heading
 */
const H3 = (props) => {
  const { children } = props
  return (
    <StyledH3>{children}</StyledH3>
  )
}

H3.propTypes = {
  /** The value of the heading tag */
  children: PropTypes.node.isRequired
}

export default H3
