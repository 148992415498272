import _ from 'lodash'
import { useShallowEqualSelector } from '../../../hooks'
import { getSelectedOrganisationApplications, getUserSelectedOrganisationId } from '../auth/selectors'
import { getCurrentPlatformApplications } from '../platforms/selectors'

export const useApplicationOptions = () => {
  const organisationId = useShallowEqualSelector(getUserSelectedOrganisationId)
  const organisationApplications = useShallowEqualSelector(getSelectedOrganisationApplications)
  const platformApplications = useShallowEqualSelector(getCurrentPlatformApplications)

  const combinedApplications = [...organisationApplications, ...platformApplications]
  const filteredApplications = _.uniqBy(
    _.filter(combinedApplications, application => [organisationId, null].includes(application.organisationId)), 'id'
  )

  const options = _.map(filteredApplications, application => {
    return (
      {
        label: application.name,
        value: application.id
      }
    )
  })

  return options
}