import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledLink = styled.a`
  text-decoration: underline;
  color: ${props => props.theme.colors.linkText};
  display: inline-block;
  &:hover, &:active, &:focus {
    color: ${props => props.theme.colors.primaryText};
  }
  &:visited {
    color: ${props => props.theme.colors.secondaryText};
  }
`

/**
 * A simple link 
 */
const Link = (props) => {
  const { href, children } = props
  return (
    <StyledLink href={href}>{children}</StyledLink>
  )
}

Link.propTypes = {
  /** The href of the link */
  href: PropTypes.string
}

export default Link
