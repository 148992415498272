import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  activePageId: undefined,
  pages: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.GET_PAGES_FOR_ORGANISATION:
      return reducers.getPagesForOrganisation(state, action)
    case constants.SET_ACTIVE_PAGE:
      return reducers.setActivePageId(state, action)
    case constants.GET_PAGE:
      return reducers.getPage(state, action)
    case constants.UPDATE_PAGE: //FOR UPDATE AND CREATE
      return reducers.updatePage(state, action)
    case constants.DELETE_PAGE:
      return reducers.deletePage(state, action)
    default:
      return state
  }
}
