import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import modalService from '../../../../services/modalService'
import { translations } from '../../../../config'
import _ from 'lodash'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'


import ReportsSearchTable from './ReportsSearchTable'

export default ReportsSearchTable
