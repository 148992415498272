export const ADD_SECTION = 'reportConfigurationEditor/ADD_SECTION'
export const REMOVE_SECTION = 'reportConfigurationEditor/REMOVE_SECTION'
export const UPDATE_SECTION = 'reportConfigurationEditor/UPDATE_SECTION'
export const ADD_FIELD = 'reportConfigurationEditor/ADD_FIELD'
export const REMOVE_FIELD = 'reportConfigurationEditor/REMOVE_FIELD'
export const UPDATE_FIELD = 'reportConfigurationEditor/UPDATE_FIELD'
export const LOAD_FORM_VIEW = 'reportConfigurationEditor/LOAD_FORM_VIEW'
export const TOGGLE_VISIBILITY = 'reportConfigurationEditor/TOGGLE_VISIBILITY'
export const REMOVE_RESOURCE = 'reportConfigurationEditor/REMOVE_RESOURCE'
export const MOVE_RESOURCE = 'reportConfigurationEditor/MOVE_RESOURCE'
export const CHANGE_SECTION = 'reportConfigurationEditor/CHANGE_SECTION'
export const SET_UNEDITED = 'reportConfigurationEditor/SET_UNEDITED'
export const SET_EDITED = 'reportConfigurationEditor/SET_EDITED'
export const ADD_BLOCK = 'reportConfigurationEditor/ADD_BLOCK'
export const CHANGE_BLOCK = 'reportConfigurationEditor/CHANGE_BLOCK'
export const REMOVE_FROM_BLOCK = 'reportConfigurationEditor/REMOVE_FROM_BLOCK'
export const ADD_TO_BLOCK = 'reportConfigurationEditor/ADD_TO_BLOCK'
export const OPEN_BLOCK = 'reportConfigurationEditor/OPEN_BLOCK'
export const CLOSE_BLOCK = 'reportConfigurationEditor/CLOSE_BLOCK'
export const SET_FORM_SCHEMA_UPDATED = 'reportConfigurationEditor/SET_FORM_SCHEMA_UPDATED'
export const SET_FORM_SCHEMA_UPDATE_SAVED = 'reportConfigurationEditor/SET_FORM_SCHEMA_UPDATE_SAVED'
