import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'

import * as validators from '../../../validators'
import style from './style'
import Form, { FormSubmit } from '../../../components/Form'
import { Input } from '../../../components/Fields'
import { translations } from '../../../config'

export const RESET_PASSWORD_FORM = 'reset-password'

const ResetPasswordForm = Form(RESET_PASSWORD_FORM)

const ResetPasswordFormWrapper = ({
  onSubmit,
  errorMessage,
  classes
}) => {
  return (
    <div>
      <div className={classes.generalError}>{errorMessage}</div>
      <ResetPasswordForm onSubmit={onSubmit} submitLabel={translations('Submit')}>
        <div className={classes.formBody}>
          <FormControl className={classes.field}>
            <Input
              label={translations('New password')}
              name='password'
              type='password'
              validate={[validators.password]}
              autoCapitalize='none'
              autoComplete='off'
              autoCorrect='off'
              spellCheck='off'
            />
          </FormControl>
          <FormControl className={classes.field}>
            <Input
              label={translations('Confirm new password')}
              name='passwordConfirmation'
              type='password'
              validate={[validators.passwordConfirmation]}
              autoCapitalize='none'
              autoComplete='off'
              autoCorrect='off'
              spellCheck='off'
            />
          </FormControl>
        </div>
        <FormSubmit containerClass={classes.centerButtonContainer} label={translations('Submit')} buttonClass={classes.fullWidthButton} />
      </ResetPasswordForm>
    </div>
  )
}

ResetPasswordFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default withStyles(style)(ResetPasswordFormWrapper)
