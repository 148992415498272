import _ from 'lodash'
import { useMemo } from 'react'

import { InfoOutlined } from '@material-ui/icons'
import { translations } from '../../../../../config'
import TooltipIcon from '../../../../../components/TooltipIcon'

const KeyTags = ({
  tags = []
}) => {
  const tagNames = useMemo(() => {
    return tags.map(tag => tag.name).join(', ')
  }, [tags])

  return (
    <TooltipIcon title={translations('Manage Translations - Tags Tooltip Text', { tagNames })} icon={<InfoOutlined />}/>
  )
}

export default KeyTags