const styles = theme => {
  return {
    root: {
      width: '100%',
      padding: theme.spacing(2)
    },
    table: {
      maxWidth: '100%'
    },
    tableWrapper: {
      overflowX: 'auto',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderBottom: 'none'
    },
    meta: {
      fontWeight: '700',
      textAlign: 'right'
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      '&:empty': {
        display: 'none'
      }
    },
    toolbarContent: {

    }
  }
}

export default styles
