import _ from 'lodash'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import MDEditor from '@uiw/react-md-editor'
import Button from '../../../../components/Button'
import xmlFormat from 'xml-formatter'
import { IconButton, TextareaAutosize, styled } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { getCurrentPlatformId } from '../../../../store/modules/platforms/selectors'
import { translations } from '../../../../config'

import { actions as reportDetailsActions } from '../../../../store/modules/reportDetails'
import { getXMLEditor } from '../../../../store/modules/reportDetails/selectors'
import { PENDING, SUCCESS } from '../../../../store/middleware/redux-promise'
import { showValidationSummary } from '../../../CaseManagement/CaseSearchScreen/CaseSearchScreen/utils'
import { Info } from '@material-ui/icons'
import toastService from '../../../../services/toastService'

const Container = styled('div')(({ theme }) => ({
  width: '70%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2)
}))

const ButtonsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginTop: 5
}))

const FormatButtonContainer = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  gap: 5
}))

const SubmitButtonsContainer = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 5
}))

const StyledButton = styled(Button)(({ theme }) => ({
  width: '30%',
  padding: theme.spacing(1)
}))

const SuccessMessage = styled('p')(({ theme }) => ({
  color: 'green',
  marginLeft: 15
}))

const ErrorMessage = styled('p')(({ theme }) => ({
  color: 'red',
  marginLeft: 15
}))

const FallbackTextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  resize: 'none'
}))

const XMLEditor = (props) => {
  const {
    reportId,
    reportXML,
    reportType
  } = props

  const dispatch = useDispatch()
  const platformId = useSelector(getCurrentPlatformId)
  const { validate, updateXML } = useSelector(getXMLEditor)

  const {
    message: validationMessage,
    error: validationError,
    status: validationStatus,
    validationErrors
  } = validate

  const {
    error: updateError,
    status: updateStatus,
    message: updateMessage
  } = updateXML
  const message = validationMessage || updateMessage
  const error = validationError || updateError

  const isValid = validationStatus === SUCCESS
  const isSubmitting = updateStatus === PENDING
  const hasSubmitted = updateStatus === SUCCESS

  const [value, setValue] = useState()

  const handleChange = (value) => {
    dispatch(reportDetailsActions.resetValidation())
    setValue(value)
  }

  const handleSubmit = async () => {
    const minifiedXML = xmlFormat.minify(value)
    dispatch(reportDetailsActions.updateReportXML({ reportId, reportXML: minifiedXML }))
    dispatch(reportDetailsActions.resetValidation())
  }

  const handleValidate = async () => {
    const validationValue = xmlFormat.minify(value)
    dispatch(reportDetailsActions.resetUpdateXML())
    dispatch(reportDetailsActions.validateReport({ reportType, report: validationValue, platformId }))
  }

  useEffect(() => {
    if (hasSubmitted) {
      dispatch(push('/report-management'))
      toastService.action({
        type: 'success',
        message: updateMessage,
        autoHideDuration: 6000
      })
    }
  }, [hasSubmitted, updateMessage, dispatch])

  useEffect(() => {
    if (reportXML) {
      const beautifulXML = beautifyXML(reportXML)
      setValue(beautifulXML)
    }
  }, [reportXML])

  const handleFormat = () => {
    const beautifulXML = beautifyXML(value)
    setValue(beautifulXML)
  }

  useEffect(() => {
    return () => {
      dispatch(reportDetailsActions.resetValidation())
      dispatch(reportDetailsActions.resetUpdateXML())
    }
  }, [dispatch])

  return (
    <Container>
      <WrappedEditor value={value} handleChange={handleChange} />
      <ButtonsContainer>
        <FormatButtonContainer>
          <StyledButton buttonType={'primary'} disabled={isSubmitting} onClick={handleFormat}>
            {translations('Format')}
          </StyledButton>
          {message && <SuccessMessage>{message}</SuccessMessage>}
          {error &&
            <>
              <ErrorMessage>{error}</ErrorMessage>
              {!_.isEmpty(validationErrors) &&
                <IconButton onClick={() => showValidationSummary()({ validationStatus: 'INVALID', validationErrors })}>
                  <Info />
                </IconButton>
              }
            </>
          }
        </FormatButtonContainer>
        <SubmitButtonsContainer>
          <StyledButton buttonType={'primary'} disabled={isSubmitting} onClick={handleValidate}>
            {translations('Validate')}
          </StyledButton>
          <StyledButton buttonType={'primary'} disabled={!isValid || isSubmitting || hasSubmitted} onClick={handleSubmit}>
            {translations('Submit')}
          </StyledButton>
        </SubmitButtonsContainer>
      </ButtonsContainer>
    </Container>
  )
}

const WrappedEditor = React.memo(({ value = '', handleChange }) => {
  const characterCount = value.length
  if (characterCount > 18000) {
    const textAreaChange = (event) => {
      handleChange(event.target.value)
    }
    return (
      <FallbackTextArea
        onChange={textAreaChange}
        value={value}
      />
    )
  }
  return (
    <div data-color-mode="light" style={{ width: '100%' }}>
      <MDEditor
        value={value}
        onChange={handleChange}
        height={800}
        hideToolbar={true}
        overflow={false}
        preview={'edit'}
        visibleDragbar={false}
      />
    </div>
  )
})

const beautifyXML = (reportXML) => {
  return xmlFormat(reportXML, {
    collapseContent: true
  })
}

export default XMLEditor
