import _ from 'lodash'
import { SUCCESS, FAIL } from '../../middleware/redux-promise'

// Helper to set the loading status of an article
const setStatus = (id, state, action) => {
  return {
    ...state,
    statuses: {
      ...state.status,
      [id]: action.status
    },
    errors: {
      ...state.errors,
      [id]: action.status === FAIL && _.get(action, 'error.message')
    }
  }
}

// Helper to set the success result of an entry
const setSuccessResult = (id, state, result) => {
  return {
    ...state,
    entries: {
      ...state.entries,
      [id]: result
    }
  }
}

class NewsArticleReducers {
  setActiveArticle = (state, action) => {
    return {
      ...state,
      activeArticleId: action.payload.id
    }
  }

  getArticle = (state, action) => {
    const articleId = action.payload.id
    const newState = setStatus(articleId, state, action)
    if (action.status === SUCCESS) {
      return {
        ...newState,
        entries: {
          ...newState.entries,
          [articleId]: action.result
        }
      }
    } else {
      return newState
    }
  }

  createArticle = (state, action) => {
    const localId = action.payload.localId
    const newState = setStatus(localId, state, action)
    if (action.status === SUCCESS) {
      const stateWithSuccessResult = setSuccessResult(localId, newState, action.result)

      // Replace the key of the new article from localId to serverId
      const serverId = action.result.id
      return {
        ...stateWithSuccessResult,
        entries: _.omit({
          ...stateWithSuccessResult.entries,
          [serverId]: stateWithSuccessResult.entries[localId]
        }, localId),
        // Update the activeArticleId if it is still set to the localId
        activeArticleId: state.activeArticleId === localId ? serverId : state.activeArticleId
      }
    } else {
      return newState
    }
  }

  updateArticle = (state, action) => {
    const articleId = action.payload.id
    const newState = setStatus(articleId, state, action)
    if (action.status === SUCCESS) {
      return setSuccessResult(articleId, newState, action.result)
    } else if (action.status === FAIL) {
      return setStatus(articleId, newState, action)
    } else {
      return newState
    }
  }

  deleteArticle = (state, action) => {
    const articleId = action.payload.id
    const newState = setStatus(articleId, state, action)
    if (action.status === SUCCESS) {
      return {
        ...newState,
        entries: _.omit(newState.entries, articleId)
      }
    } else {
      return newState
    }
  }
}

export default new NewsArticleReducers()
