const styles = theme => ({
  content: {
    marginTop: 30,
  },
  warningSign: {
    padding: '5px'
  },
  warningBar: {
    backgroundColor: '#f1c40f',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px'
  },
  warningMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default styles
