import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import modalService from '../../../../services/modalService'
import { translations } from '../../../../config'
import moment from 'moment'

import { selectors as authSelectors } from '../../../../store/modules/auth'
import { actions as reportAcknowledgementsActions, selectors as reportAcknowledgementsSelectors } from '../../../../store/modules/reportAcknowledgements'
import ReportAcknowledgementsTable from './ReportAcknowledgementsTable'

const mapStateToProps = state => {
  const results = reportAcknowledgementsSelectors.getResults(state)
  const hasMore = reportAcknowledgementsSelectors.getHasMore(state)
  const isLoading = reportAcknowledgementsSelectors.getIsLoading(state)
  const error = reportAcknowledgementsSelectors.getError(state)
  const hasCreateAckPermissions = authSelectors.getHasCreateAck(state)
  const noResults = !isLoading && !results.length

  return {
    isLoading,
    hasMore,
    results,
    noResults,
    error,
    hasCreateAckPermissions
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    searchReports: ({ dispatch, isLoading }) => () => {
      if (!isLoading) {
        dispatch(reportAcknowledgementsActions.searchReportsNext())
      }
    },
    onAckClick: ({ dispatch, onAckClick, disabledUserId, hasCreateAckPermissions }) => ack => {
      modalService.action({
        title: ack.id,
        text: moment(ack.createdAt).format('DD/MM/YYYY HH:mm'),
        actions: [
          {
            text: translations('Open'),
            onClick: () => dispatch(push(`/reports/${ack.reportId}/acknowledgements/${ack.id}`)),
            primary: true
          },
          {
            text: translations('Export XML'),
            onClick: () => {
              dispatch(reportAcknowledgementsActions.fetchAckXMLForId({ id: ack.id }))
              modalService.close()
            }
          }
        ]
      })
    }
  }),
  withPropsOnChange(
    ['results'],
    props => {
      const { results, onAckClick } = props
      let rows = []

      if (results && results.length) {
        rows = results
          .map(acknowledgement => {
            const { id, createdAt, messageNumber, acknowledgementJSON: ackJSON, formSchema = {} } = acknowledgement
            const { details = {} } = formSchema
            const sender = _.get(ackJSON, _.get(details, 'senderIdentifierFieldName'), '-')
            const safetyReportId = _.get(ackJSON, _.get(details, 'extReportIdFieldName'), '-')
            const onClick = () => onAckClick(acknowledgement)

            const data = [
              ['Date', moment(createdAt).format('DD/MM/YYYY HH:mm'), { xs: 3, cardDatumWidth: '50%', onClick }],
              ['Sender', sender, { xs: 3, cardDatumWidth: '50%' }],
              ['Message Number', messageNumber, { xs: 3, cardDatumWidth: '100%' }],
              ['Safety Report ID', safetyReportId, { xs: 3, cardDatumWidth: '100%' }]
            ].map(datum => {
              const [label, value, props] = datum
              return [translations(label), value, props]
            })

            return {
              key: id,
              onClick,
              data
            }
          })
      }
      return { rows }
    }
  )
)(ReportAcknowledgementsTable)
