import { downloadFile } from '../../../../../helpers/zipHelper'
import _ from 'lodash'

export const downloadXml = ({ xml, fileName }) => () => {
  const xmlBlob = new Blob([xml], { type: 'application/xml' })
  downloadFile({ blob: xmlBlob, filename: fileName })
}

export const mapAmendDetails = ({ amendDetails }) => {
  const keys = _.keys(amendDetails)
  const children = _.map(keys, key => {
    const val = _.get(amendDetails, key)
    return `${_.startCase(key)}: ${val}`
  })
  return (
    <div>
      <br />
      {children}
    </div>
  )
}