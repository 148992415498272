
import { actions as networkActions } from '../modules/network'

let monitorNetworkConnection
if (window.cordova) {
  monitorNetworkConnection = ({ dispatch }) => {
    const handleOffline = () => {
      dispatch(networkActions.networkConnectionOff())
    }
    const handleOnline = () => {
      dispatch(networkActions.networkConnectionOn())
    }
    document.addEventListener('offline', handleOffline, false)
    document.addEventListener('online', handleOnline, false)
    return () => {
      document.removeEventListener('offline', handleOffline)
      document.removeEventListener('online', handleOnline)
    }
  }
} else {
  require('offline-js')
  monitorNetworkConnection = ({ dispatch }) => {
    const offlineHandler = window.Offline.on('down', () => {
      dispatch(networkActions.networkConnectionOff())
    })
    const onlineHandler = window.Offline.on('up', () => {
      dispatch(networkActions.networkConnectionOn())
    })
    return () => {
      window.Offline.off('down', offlineHandler)
      window.Offline.off('up', onlineHandler)
    }
  }
}

export default monitorNetworkConnection
