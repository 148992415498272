import React, { useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from '@reach/combobox'

import FieldMeta from '../FieldMeta'
import Field from '../Field'

import { useDebounce } from '../../services/utilities'
import { StyledInput } from '../TextInput'

const StyledComboboxOption = styled(ComboboxOption)`
  padding: 8px;
`

const P = styled.p`
  margin: 8px;
`

const Input = styled(StyledInput)`
  ${props => props.disabled && `> div, label {
    background-color: #FAFAFA;
    user-select: none;
    pointer-events: none;
  }`}
`

const StyledCombobox = styled(Combobox)`
  position: relative;
  display: flex;
  gap: 20px;
`

/**
 * AutoComplete
 */

const AutoComplete = (props) => {
  const {
    input: {
      name,
      value,
      onChange,
      onFocus,
      onBlur
    } = {},
    meta: {
      error,
      touched,
      active
    } = {},
    id,
    label,
    hint,
    required,
    options,
    freeSolo = true,
    disabled,
    handleChangeOtherFields,
    hideBrowserAutocomplete = false,
    actionComponent = null,
    overrideMapOptions,
    displayValue
  } = props

  const [searchTerm, setSearchTerm] = useState('')
  const fieldInvalid = error && touched
  const errorMessage = fieldInvalid ? error : undefined
  const valueAsOption = _.find(options, option => option.value === value) || {}

  // Tracks whether the current value is an option or user input
  const [isUserInput, setIsUserInput] = useState(false)

  const useMatch = (term) => {
    const debouncedTerm = useDebounce(term, 100)
    return useMemo(
      () =>
        term.trim() === ""
          ? options
          : options.filter(option => {
            const searchVal = option.label || option.value || option
            return _.toLower(searchVal).indexOf(_.toLower(term)) > -1
          }),
      [options, debouncedTerm]
    )
  }

  const results = useMatch(searchTerm)

  const handleSearchTermChange = useCallback(event => {
    setSearchTerm(event.target.value)
    setIsUserInput(true)
    if (onChange) {
      onChange(event.target.value)
    }
    if (_.isFunction(handleChangeOtherFields)) {
      handleChangeOtherFields(event.target.value)
    }
  })

  /**
   * Sets user input to false as has selected an option
   */
  const handleOnSelect = (value) => {
    setIsUserInput(false)
    if (_.isFunction(handleChangeOtherFields)) {
      handleChangeOtherFields(value)
    }
    onChange(value)
  }

  /**
   * If freeSolo is not allowed then clear current value and search term if the current value
   * is the users input and not a selected option
   */
  const handleOnBlur = (event) => {
    if (!freeSolo && isUserInput && (event.relatedTarget === null || event.relatedTarget.tagName !== 'LI')) {
      setSearchTerm('')
      onChange('')
      onBlur(event)
    }
  }

  const mapOptions = (results) => {
    if (overrideMapOptions && _.isFunction(overrideMapOptions)) {
      return overrideMapOptions(results)
    }
    return results.map((item, index) => {
      const value = _.isObject(item) && item.value ? item.value : item
      const label = _.isObject(item) && item.label ? item.label : value
      return <StyledComboboxOption key={`${index}-${value}`} value={label} />
    })
  }

  return (
    <Field error={errorMessage}>
      <FieldMeta name={name} label={label} error={errorMessage} hint={disabled ? '' : hint} required={required} disabled={disabled} />
      <StyledCombobox
        aria-label={'autoselect'}
        openOnFocus={true}
        onSelect={handleOnSelect}
        onBlur={handleOnBlur}
      >
        <Input
          as={ComboboxInput}
          id={id}
          name={name}
          value={displayValue || valueAsOption.label || value}
          onChange={handleSearchTermChange}
          onFocus={onFocus}
          disabled={disabled}
          autoComplete={hideBrowserAutocomplete}
        />
        {actionComponent}
        {results && (
          <ComboboxPopover style={{ maxHeight: '200px', overflow: 'auto' }} className={'mhraUI'}>
            {results.length > 0 ? (
              <ComboboxList>
                {mapOptions(results)}
              </ComboboxList>
            ) : null}
            {searchTerm && !results.length && <P>No results found</P>}
          </ComboboxPopover>
        )}
      </StyledCombobox>
    </Field>
  )
}

AutoComplete.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** Input props based from React Final Form
   *
   * `name` - Used to associate label and input
   *
   * `value` - Value of the input
   *
   * `type` - Native HTML input type
   *
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   *
   * `onFocus` - Function called when focus has been given to the field
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  /** Meta props based from React Final Form
   *
   * `error` - Field validation message
   *
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
}

export default AutoComplete
