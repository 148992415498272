"use strict";

var _computedHidden = require("../../../../../../formHelpers/computedHidden");

var _computedValue = require("../../../../../../formHelpers/computedValue");

var _options = require("../../../../../../formHelpers/options");

var _index = require("./formHelpers/index");

var _validators = require("../../../../../../formHelpers/validators");

var schema = function schema(validators) {
  return [{
    id: 'severecovidcondition',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'severecovidcondition',
      label: 'Do you have any of the following conditions that may be associated with increased risk for severe COVID disease?',
      multiple: true,
      options: [{
        value: 'cancer',
        label: 'Cancer'
      }, {
        value: 'kidneyDisease',
        label: 'Chronic kidney disease'
      }, {
        value: 'pulmonaryDisease',
        label: 'Chronic obstructive pulmonary disease (COPD)'
      }, {
        value: 'downSyndrome',
        label: 'Down syndrome'
      }, {
        value: 'heartCondition',
        label: 'Heart conditions (such as heart failure, coronary artery disease, or cardiomyopathies)'
      }, {
        value: 'weakenedImmune',
        label: 'Weakened immune system'
      }, {
        value: 'obesity',
        label: 'Obesity'
      }, {
        value: 'sickleCell',
        label: 'Sickle cell disease'
      }, {
        value: 'diabetes',
        label: 'Type 2 diabetes'
      }]
    }
  }, {
    id: 'allergicreactionsymptoms',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasAllergicReaction),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasAllergicReaction),
    props: {
      shrink: true,
      required: false,
      name: 'allergicreactionsymptoms',
      label: 'Please identify all symptoms of your allergic reaction',
      multiple: true,
      options: [{
        value: 'itchyRedRash',
        label: 'Raised, itchy red rash'
      }, {
        value: 'otherRash',
        label: 'Other rash that may or may not have been itchy'
      }, {
        value: 'faceSwelling',
        label: 'Swelling on the face around the mouth or eyes'
      }, {
        value: 'mouthSwelling',
        label: ' Swelling inside the mouth or throat'
      }, {
        value: 'difficultyBreathing',
        label: 'Difficulty or wheezy or noisy breathing'
      }, {
        value: 'dryCough',
        label: 'Persistent dry cough'
      }, {
        value: 'fastBreathing',
        label: 'Fast breathing'
      }, {
        value: 'fastHeartBeat',
        label: 'Fast heart beats'
      }, {
        value: 'lowBloodPressure',
        label: 'Very low blood pressure'
      }, {
        value: 'alteredConsciousness',
        label: 'Altered level of consciousness'
      }, {
        value: 'collapseByHeartLung',
        label: 'Sudden collapse caused by heart or lung problems'
      }, {
        value: 'abdominalPain',
        label: 'Abdominal/belly pain'
      }, {
        value: 'nausea',
        label: 'Nausea'
      }, {
        value: 'vomiting',
        label: 'Vomiting'
      }, {
        value: 'diarrhoea',
        label: 'Diarrhoea'
      }]
    }
  }, {
    id: 'reactogenicitycareforsymptoms',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'reactogenicitycareforsymptoms',
      label: 'Did any of the symptoms cause you to get care from a doctor or other healthcare professional?',
      options: (0, _options.mapValuesAt1)(['Yes', 'No'])
    }
  }, {
    id: 'reactogenicitycarevisittype',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hadReactogenicityCareForSymptoms),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hadReactogenicityCareForSymptoms),
    props: {
      shrink: true,
      required: false,
      name: 'reactogenicitycarevisittype',
      label: 'Reactogenicity - What type of care visit did you have?',
      multiple: true,
      options: [{
        value: 'virtual',
        label: 'Virtual health / email / phone consultation'
      }, {
        value: 'clinicVisit',
        label: 'Clinic or urgent care visit'
      }, {
        value: 'emergencyRoom',
        label: 'Emergency room / department visit'
      }, {
        value: 'hospitalStay',
        label: 'Hospital Stay'
      }]
    }
  }, {
    id: 'covidcareforsymptoms',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'covidcareforsymptoms',
      label: 'Did you get care from a doctor or other healthcare professional for your symptoms or health conditions?',
      options: (0, _options.mapValuesAt1)(['Yes', 'No'])
    }
  }, {
    id: 'covidcarevisittype',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hadCovidCareForSymptoms),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hadCovidCareForSymptoms),
    props: {
      shrink: true,
      required: false,
      name: 'covidcarevisittype',
      label: 'COVID - What type of care visit did you have?',
      multiple: true,
      options: [{
        value: 'virtual',
        label: 'Virtual health / email / phone consultation'
      }, {
        value: 'clinicVisit',
        label: 'Clinic or urgent care visit'
      }, {
        value: 'emergencyRoom',
        label: 'Emergency room / department visit'
      }, {
        value: 'hospitalStay',
        label: 'Hospital Stay'
      }]
    }
  }, {
    id: 'eventscareforsymptoms',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'eventscareforsymptoms',
      label: 'Did any of the symptoms or events you reported cause you to get care from a doctor or other healthcare professional?',
      options: (0, _options.mapValuesAt1)(['Yes', 'No'])
    }
  }, {
    id: 'eventscarevisittype',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hadEventCareForSymptoms),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hadEventCareForSymptoms),
    props: {
      shrink: true,
      required: false,
      name: 'eventscarevisittype',
      label: 'Events - What type of care visit did you have?',
      multiple: true,
      options: [{
        value: 'virtual',
        label: 'Virtual health / email / phone consultation'
      }, {
        value: 'clinicVisit',
        label: 'Clinic or urgent care visit'
      }, {
        value: 'emergencyRoom',
        label: 'Emergency room / department visit'
      }, {
        value: 'hospitalStay',
        label: 'Hospital Stay'
      }]
    }
  }, {
    id: 'followupcareforsymptoms',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'followupcareforsymptoms',
      label: 'The last time we checked, you reported symptoms after getting vaccinated that caused you to get care from a doctor or other healthcare professional. Have you gotten more care from a doctor or other healthcare professional for those symptoms or health conditions?',
      options: (0, _options.mapValuesAt1)(['Yes', 'No'])
    }
  }, {
    id: 'followupcarevisittype',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hadFollowUpCareForSymptoms),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hadFollowUpCareForSymptoms),
    props: {
      shrink: true,
      required: false,
      name: 'followupcarevisittype',
      label: 'Followup - What type of care visit did you have?',
      multiple: true,
      options: [{
        value: 'virtual',
        label: 'Virtual health / email / phone consultation'
      }, {
        value: 'clinicVisit',
        label: 'Clinic or urgent care visit'
      }, {
        value: 'emergencyRoom',
        label: 'Emergency room / department visit'
      }, {
        value: 'hospitalStay',
        label: 'Hospital Stay'
      }]
    }
  }, {
    id: 'heartbloodvesselinjuryoptions',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasHeartBloodVesselInjury),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasHeartBloodVesselInjury),
    props: {
      shrink: true,
      required: false,
      name: 'heartbloodvesselinjuryoptions',
      label: 'Acute heart or blood vessel injury, please check all that apply:',
      multiple: true,
      options: [{
        value: 'microangiopathy',
        label: 'Microangiopathy (capillary disease)'
      }, {
        value: 'heartFailure',
        label: 'Heart failure'
      }, {
        value: 'cardiomyopathy',
        label: 'Cardiomyopathy (disease of the heart muscle)'
      }, {
        value: 'arteryDisease',
        label: 'Coronary artery disease'
      }, {
        value: 'arrhythmia',
        label: 'Arrhythmia (abnormal heart rhythm)'
      }, {
        value: 'myocarditis',
        label: 'Myocarditis (inflammation of the heart muscle)'
      }, {
        value: 'pericarditis',
        label: 'Pericarditis (inflammation of the membrane around the heart)'
      }]
    }
  }, {
    id: 'bloodflowdisorderoptions',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasBloodFlowDisorder),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasBloodFlowDisorder),
    props: {
      shrink: true,
      required: false,
      name: 'bloodflowdisorderoptions',
      label: 'Blood flow or clotting disorder, please check all that apply:',
      multiple: true,
      options: [{
        value: 'deepVeinThrombosis',
        label: 'Deep vein thrombosis (DVT, a blood clot deep inside your body)'
      }, {
        value: 'pulmonaryEmbolus',
        label: 'Pulmonary embolus (blockage of one of the arteries in your lungs)'
      }, {
        value: 'cerebrovascularStroke',
        label: 'Cerebrovascular stroke (stopped blood flow to your brain)'
      }, {
        value: 'limbIschemia',
        label: 'Limb ischemia (reduced blood flow to your limb(s))'
      }, {
        value: 'hemorrhage',
        label: 'Hemorrhage (significant bleeding)'
      }]
    }
  }, {
    id: 'neurologicdisorderoptions',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasNeurologicDisorder),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasNeurologicDisorder),
    props: {
      shrink: true,
      required: false,
      name: 'neurologicdisorderoptions',
      label: 'Neurologic disorder, please check all that apply:',
      multiple: true,
      options: [{
        value: 'generalizedSeizure',
        label: 'Generalized seizure'
      }, {
        value: 'guillainBarreSyndrome',
        label: 'Guillain Barre Syndrome (a form of paralysis)'
      }, {
        value: 'meningoencephalitis',
        label: 'Meningoencephalitis (inflammation of the brain and its covering membrane)'
      }, {
        value: 'encephalomyelitis',
        label: 'Acute disseminated encephalomyelitis (ADEM, inflammation of the brain and spinal cord)'
      }]
    }
  }, {
    id: 'skindisorderoptions',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasSkinDisorder),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasSkinDisorder),
    props: {
      shrink: true,
      required: false,
      name: 'skindisorderoptions',
      label: 'Skin disorder, please check all that apply:',
      multiple: true,
      options: [{
        value: 'cancer',
        label: 'Chilblain-like lesions (lesions on the toes)'
      }, {
        value: 'kidneyDisease',
        label: 'Single organ cutaneous vasculitis (inflammation of skin blood vessels)'
      }, {
        value: 'pulmonaryDisease',
        label: 'Erythema multiforme (skin rash with target lesions)'
      }]
    }
  }, {
    id: 'reactogenicityhospitalrequirement',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasReactogenicityHospitalVisit),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasReactogenicityHospitalVisit),
    props: {
      shrink: true,
      required: false,
      name: 'reactogenicityhospitalrequirement',
      label: 'Reactogenicity - If you stayed in a hospital, did you require any of the following?',
      multiple: true,
      options: _index.general.hospitalOptions
    }
  }, {
    id: 'covidhospitalrequirement',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasCovidHospitalVisit),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasCovidHospitalVisit),
    props: {
      shrink: true,
      required: false,
      name: 'covidhospitalrequirement',
      label: 'COVID - If you stayed in a hospital, did you require any of the following?',
      multiple: true,
      options: _index.general.hospitalOptions
    }
  }, {
    id: 'eventshospitalrequirement',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasEventHospitalVisit),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasEventHospitalVisit),
    props: {
      shrink: true,
      required: false,
      name: 'eventshospitalrequirement',
      label: 'Events - If you stayed in a hospital, did you require any of the following?',
      multiple: true,
      options: _index.general.hospitalOptions
    }
  }, {
    id: 'followuphospitalrequirement',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasFollowupHospitalVisit),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasFollowupHospitalVisit),
    props: {
      shrink: true,
      required: false,
      name: 'followuphospitalrequirement',
      label: 'Followup - If you stayed in a hospital, did you require any of the following?',
      multiple: true,
      options: _index.general.hospitalOptions
    }
  }, {
    id: 'currentlypregnant',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'currentlypregnant',
      label: 'Are you currently still pregnant',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'pregnancyknowndate',
    field: 'Date',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.isCurrentlyPregnant),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.isCurrentlyPregnant),
    props: {
      shrink: true,
      required: false,
      name: 'pregnancyknowndate',
      label: 'When did you find out you were pregnant?',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat)],
      helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
      startYear: 2020
    }
  }, {
    id: 'pregnancysymptoms',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.isCurrentlyPregnant),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.isCurrentlyPregnant),
    props: {
      shrink: true,
      required: false,
      name: 'pregnancysymptoms',
      label: 'Pregnancy symptoms, have you experienced any of the following?',
      multiple: true,
      options: [{
        value: 'vaginalBleeding',
        label: 'Vaginal bleeding'
      }, {
        value: 'highBloodPressure',
        label: 'High blood pressure (hypertension diagnosed during pregnancy), pre-eclampsia or eclampsia'
      }, {
        value: 'gestationalDiabetes',
        label: 'Gestational diabetes'
      }, {
        value: 'threatenedPreterm',
        label: 'Threatened preterm labor'
      }, {
        value: 'other',
        label: 'Other'
      }]
    }
  }, {
    id: 'pregnancysymptomsother',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasOtherPregnancySymptoms),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasOtherPregnancySymptoms),
    props: {
      shrink: true,
      required: false,
      name: 'pregnancysymptomsother',
      label: 'Other pregnancy symptoms, please describe:'
    }
  }, {
    id: 'babybornalive',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.isNotCurrentlyPregnant),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.isNotCurrentlyPregnant),
    props: {
      shrink: true,
      required: false,
      name: 'babybornalive',
      label: 'Was your baby born alive?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'birthweek',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.wasBabyBornAlive),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.wasBabyBornAlive),
    props: {
      shrink: true,
      required: false,
      name: 'birthweek',
      label: 'How many weeks pregnant were you when your baby was born?',
      validate: [validators.maxNumericLength(2, 'Field'), validators.isBelow(55)]
    }
  }, {
    id: 'birthweight',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.wasBabyBornAlive),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.wasBabyBornAlive),
    props: {
      shrink: true,
      required: false,
      name: 'birthweight',
      label: 'What was your baby`s birth weight in grams?',
      validate: [validators.maxNumericLength(4, 'Field'), validators.isBelow(9999)]
    }
  }, {
    id: 'babybornsmall',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.wasBabyBornAlive),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.wasBabyBornAlive),
    props: {
      shrink: true,
      required: false,
      name: 'babybornsmall',
      label: 'Was your baby born small for gestational age?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'babystillalive',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.wasBabyBornAlive),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.wasBabyBornAlive),
    props: {
      shrink: true,
      required: false,
      name: 'babystillalive',
      label: 'Is your baby still alive?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'babydeathmonth',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.wasBabyNotBornAlive),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.wasBabyNotBornAlive),
    props: {
      shrink: true,
      required: false,
      name: 'babydeathmonth',
      label: 'How many months old was your baby when they died?',
      validate: [validators.maxNumericLength(2, 'Field'), validators.isBelow(12)]
    }
  }, {
    id: 'babydeathreason',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.wasBabyNotBornAlive),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.wasBabyNotBornAlive),
    props: {
      shrink: true,
      required: false,
      name: 'babydeathreason',
      label: 'If no to "baby born alive," How did your pregnancy end?',
      multiple: true,
      options: [{
        value: 'miscarriage',
        label: 'Miscarriage'
      }, {
        value: 'medicalTermination',
        label: 'Medical Termination'
      }, {
        value: 'stillbirth',
        label: 'Stillbirth'
      }]
    }
  }, {
    id: 'birthdefect',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.isNotCurrentlyPregnant),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.isNotCurrentlyPregnant),
    props: {
      shrink: true,
      required: false,
      name: 'birthdefect',
      label: 'Was your baby diagnosed with a birth defect?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'birthdefectdetails',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.babyHasBirthDefect),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.babyHasBirthDefect),
    props: {
      shrink: true,
      required: false,
      name: 'birthdefectdetails',
      label: 'Birth defect details, please describe:'
    }
  }, {
    id: 'otherbirthconditions',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.isNotCurrentlyPregnant),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.isNotCurrentlyPregnant),
    props: {
      shrink: true,
      required: false,
      name: 'otherbirthconditions',
      label: 'Was your baby diagnosed with any other medical conditions?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'otherbirthconditiondetails',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.babyHasOtherCondition),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.babyHasOtherCondition),
    props: {
      shrink: true,
      required: false,
      name: 'otherbirthconditiondetails',
      label: 'Other medical birth conditions, please describe:'
    }
  }, {
    id: 'otherbirthevents',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.isNotCurrentlyPregnant),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.isNotCurrentlyPregnant),
    props: {
      shrink: true,
      required: false,
      name: 'otherbirthevents',
      label: 'Were there other medical events experienced by you or your baby?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'otherbirtheventsdetails',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasOtherBirthEvents),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasOtherBirthEvents),
    props: {
      shrink: true,
      required: false,
      name: 'otherbirtheventsdetails',
      label: 'Other birth medical events, please describe:'
    }
  }, {
    id: 'birtheventrecoverydate',
    field: 'Date',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasRecoveredForDailyActivity),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasRecoveredForDailyActivity),
    props: {
      shrink: true,
      required: false,
      name: 'birtheventrecoverydate',
      label: 'Approximately when were you able to go back to doing your normal daily activities?',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat)],
      helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
      startYear: 2020
    }
  }, {
    id: 'recoveredfordailyactivity',
    field: 'Dropdown',
    computedHidden: function computedHidden() {},
    props: {
      shrink: true,
      required: false,
      name: 'recoveredfordailyactivity',
      label: 'The last time we checked, you reported symptoms after getting vaccinated that caused you to be unable to do your normal daily activities. Are you feeling better and able to do your normal daily activities?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'recoveredforwork',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasCausedMissedWork),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasCausedMissedWork),
    props: {
      shrink: true,
      required: false,
      name: 'recoveredforwork',
      label: 'The last time we checked, you reported symptoms after getting vaccinated that caused you to miss work. Are you feeling better and able to go back to work?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'causedworkloss',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'causedworkloss',
      label: 'Did any of the symptoms or events you reported cause you to miss work?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'skindisorder',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'skindisorder',
      label: 'Since our last contact have you been told that you have a skin disorder?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'neurologicdisorder',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'neurologicdisorder',
      label: 'Since our last contact have you experienced any neurologic disorder?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'suddenallergicreaction',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'suddenallergicreaction',
      label: 'Have you had the sudden occurrence of an allergic reaction?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'heartbloodvesselinjury',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'heartbloodvesselinjury',
      label: 'Since our last contact have you been told that you have acute heart or blood vessel injury?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'bloodflowdisorder',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'bloodflowdisorder',
      label: 'Since our last contact have you been told that you have a blood flow or clotting (coagulation)  disorder?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'injectionpain',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'injectionpain',
      label: 'Have you had pain or tenderness at or near the injection site?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'injectionredness',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'injectionredness',
      label: 'Have you had redness at or near the injection site?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'injectionswelling',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'injectionswelling',
      label: 'Have you had swelling at or near the injection site?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'immunizationchills',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'immunizationchills',
      label: 'Have you had chills since your immunization?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'immunizationheadache',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'immunizationheadache',
      label: 'Have you had headache since your immunization?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'immunizationjointpain',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'immunizationjointpain',
      label: 'Have you had joint pains since your immunization?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'immunizationbodyache',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'immunizationbodyache',
      label: 'Have you had general muscle or body aches beyond the injection site since your immunization?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'immunizationfatigue',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'immunizationfatigue',
      label: 'Have you had fatigue since your immunization?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'immunizationnausea',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'immunizationnausea',
      label: 'Have you had nausea since your immunization?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'immunizationvomiting',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'immunizationvomiting',
      label: 'Have you had vomiting since your immunization?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'immunizationdiarrhea',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'immunizationdiarrhea',
      label: 'Have you had diarrhea since your immunization?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'immunizationabdominalpain',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'immunizationabdominalpain',
      label: 'Have you had abdominal pain since your immunization?',
      options: _index.general.symptomOptions
    }
  }, {
    id: 'immunizationfever',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'immunizationfever',
      label: 'Have you had a fever since your immunization?',
      options: (0, _options.mapValuesAt1)(['Yes', 'No'])
    }
  }, {
    id: 'immunizationfeverdegrees',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hadImmunizationCausedFever),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hadImmunizationCausedFever),
    props: {
      shrink: true,
      required: true,
      name: 'immunizationfeverdegrees',
      label: 'Enter the highest measurement in degrees Celsius'
    }
  }, {
    id: 'msvmrecoveredfordailyactivity',
    field: 'Dropdown',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.hasAnyInjectionSymptomCausedIssue),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.hasAnyInjectionSymptomCausedIssue),
    props: {
      shrink: true,
      required: true,
      name: 'msvmrecoveredfordailyactivity',
      label: 'Are you feeling better and able to do your normal daily activities?',
      options: (0, _options.mapValueAndLabel)(['Yes', 'No'])
    }
  }, {
    id: 'dailyactivityreturndate',
    field: 'Date',
    computedHidden: (0, _computedHidden.visibleIf)(_index.general.msvmHasRecoveredForDailyActivity),
    computedValue: (0, _computedValue.clearIfNot)(_index.general.msvmHasRecoveredForDailyActivity),
    props: {
      shrink: true,
      required: false,
      name: 'dailyactivityreturndate',
      label: 'Approximately when were you able to go back to doing your normal daily activities?',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat)],
      helperText: 'YYYY, YYYY/MM, YYYY/MM/DD',
      startYear: 2020
    }
  }];
};

module.exports = schema;