import { LOCATION_CHANGE } from 'connected-react-router'
import _ from 'lodash'
import { matchPath } from 'react-router'
import themeActions from './actions'
import * as themeSelectors from './selectors'
import { selectors as authSelectors, constants as authConstants } from '../auth'
import { constants as themeDetailsConstants } from '../themeDetails'
import { constants as organisationDetailsConstants } from '../organisationDetails'
import { constants as platformsConstants, selectors as platformSelectors } from '../platforms'
import { constants as platformDetailsConstants } from '../platformDetails'

class ThemeMiddleware {
  fetchAllThemesOnDetails = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToOrgDetailsView = matchPath(action.payload.location.pathname, { path: '/organisations/:id', exact: true })
      const isRoutingToPlatformDetailsView = matchPath(action.payload.location.pathname, { path: '/platforms/:id', exact: true })
      const isRoutingToPlatformDetailsNew = matchPath(action.payload.location.pathname, { path: '/platforms/new', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if ((
        isRoutingToOrgDetailsView ||
        isRoutingToPlatformDetailsView ||
        isRoutingToPlatformDetailsNew) &&
        isLoggedIn) {
        dispatch(themeActions.fetchAllThemes())
      }
    }
  }
  fetchCurrentThemeOnFetchPlatformForDomain = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === platformsConstants.FETCH_PLATFORM_FOR_DOMAIN && action.status === 'SUCCESS') {
      const platformThemeId = _.get(action, 'result.themeId')
      const organisationThemeId = authSelectors.getSelectedOrganisationThemeId(getState())
      dispatch(themeActions.fetchCurrentTheme({ id: organisationThemeId || platformThemeId }))
    }
  }
  fetchCurrentThemeOnSelectOrganisation = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === authConstants.SELECT_ORGANISATION) {
      const { organisationId } = action
      const orgs = authSelectors.getUserOrganisations(getState())
      const selectedOrg = orgs.find(org => org.id === organisationId)
      const organisationThemeId = _.get(selectedOrg, 'themeId')
      if (organisationThemeId) {
        dispatch(themeActions.fetchCurrentTheme({ id: organisationThemeId }))
      }
    }
  }
  fetchCurrentThemeOnThemeUpdate = ({ dispatch, getState }) => next => action => {
    next(action)
    if (
      (action.type === themeDetailsConstants.UPDATE_THEME && action.status === 'SUCCESS') ||
      (action.type === themeDetailsConstants.CREATE_THEME && action.status === 'SUCCESS')
    ) {
      const updatedThemeId = _.get(action, 'result.id')
      const currentThemeId = themeSelectors.getCurrentThemeId(getState())
      if (updatedThemeId === currentThemeId) {
        dispatch(themeActions.fetchCurrentTheme({ id: currentThemeId }))
      }
    }
  }
  fetchCurrentThemeOnOrganisationUpdate = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === organisationDetailsConstants.UPDATE_ORGANISATION && action.status === 'SUCCESS') {
      const state = getState()
      const updateOrganisationId = _.get(action, 'result.id')
      const selectedOrganisationId = authSelectors.getUserSelectedOrganisationId(state)
      if (updateOrganisationId === selectedOrganisationId) {
        const organisationThemeId = _.get(action, 'result.themeId')
        const currentPlatformThemeId = platformSelectors.getCurrentPlatformThemeId(state)
        dispatch(themeActions.fetchCurrentTheme({ id: organisationThemeId || currentPlatformThemeId }))
      }
    }
  }
  fetchCurrentThemeOnPlatformUpdate = ({ dispatch, getState }) => next => action => {
    // you may think this middleware needs to exist. BUT! when a platform is updated,
    // some platform middleware fires off a fetchPlatformForDomain action, which in turn
    // triggers the fetchCurrentThemeOnFetchPlatformForDomain middleware above.
    // the magic of middleware! oodles of untraceable spaghetti code. :)
    next(action)
  }
}

export default new ThemeMiddleware()
