import * as constants from './constants'
import DevicesList from '../../../config/devicesList.json'
import _ from 'lodash'

class DevicesListactions {
  _searchDevices = () => ({query}) => {
    return ({
    type: constants.SEARCH_DEVICES,
    results: query ? _.filter(DevicesList, device => {
      const getName = _.get(device, 'name')
      if (getName && getName.toLowerCase().includes(query.toLowerCase())){
        return getName
      }
      }) : DevicesList
    })
  }

  searchDevices = this._searchDevices()

  fetchDevices = () => ({
    type: constants.FETCH_DEVICES,
    results: DevicesList
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new DevicesListactions()
