import { connect } from 'react-redux'
import UsersSearchInput from './UsersSearchInput'
import { actions as usersActions, selectors as usersSelectors } from '../../../../store/modules/users'

const mapStateToProps = state => ({
  query: usersSelectors.getQuery(state)
})

const mapDispatchToProps = dispatch => ({
  searchUsers: ({ query }) => dispatch(usersActions.searchUsersFresh({ query }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersSearchInput)
