import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import PermissionsEditorScreen from './PermissionsEditorScreen'

import TimedLogoutComponent from '../../components/TimedLogout'
import UserGroupsScreen from './UserGroupsScreen'
import UserGroupScreen from './UserGroupScreen'

const routes = [
  <PrivateRoute key='permissions' path='/permissions' exact component={TimedLogoutComponent(PermissionsEditorScreen)} />,
  <PrivateRoute key='user-groups' path='/permissions/user-groups' exact component={TimedLogoutComponent(UserGroupsScreen)}/>,
  <PrivateRoute key='user-group' path='/permissions/user-group/:id' exact component={TimedLogoutComponent(UserGroupScreen)}/>
]

export default routes