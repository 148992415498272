export const handleInviteStatus = ({ inviteStatus }) => {
    switch (inviteStatus) {
        case 'ACCEPTED':
            return {
                chipVariant: 'success',
                chipLabel: 'Invite - Accepted',
                alertText: 'Invite was Accepted',
                alertSeverity: 'success',
                showReviewRequest: false,
                reviewDescriptionTitle: 'Invite - Accepted reason title'
            }
        case 'REJECTED':
            return {
                chipVariant: 'errorOutlined',
                chipLabel: 'Invite - Rejected',
                alertText: 'Invite was declined',
                alertSeverity: 'error',
                showReviewRequest: false,
                reviewDescriptionTitle: 'Invite - Rejection reason title'
            }
        case 'PENDING':
            return {
                chipVariant: 'default',
                chipLabel: 'Invite - Pending',
                alertText: 'Requires user to accept invite',
                alertSeverity: 'info',
                showReviewRequest: true
            }
        default:
            return {
                variant: 'default'
            }
    }
}
