import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import Field from '../Field'
import FieldMeta from '../FieldMeta'
import ErrorMessage from '../ErrorMessage'
import Hint from '../Hint'
import { translation } from '../../services/translations'

const Box = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  width: ${35}px;
  height: ${35}px;
  border: 1px solid
    ${(p) => (p['aria-invalid'] ? p.theme.colors.errorText : p.theme.colors.inputText)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  & > div {
    opacity: 0;
  }
  input:checked ~ & {
    background-color: ${'black'};
  }
  input:checked ~ & > div {
    opacity: 1;
  }
  ${media.greaterThan('small')`
    width: ${40}px;
    height: ${40}px;
  `}
`
const CheckboxInput = styled.input`
  opacity: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  &:focus ~ ${Box}, &:active ~ ${Box}, &:focus ~ ${Box} {
    outline: 0;
    box-shadow: ${({ theme, error }) => error ? `0 0 0 3px ${theme.colors.error}` : `0 0 0 3px ${theme.colors.primary}`};
  }
  &:disabled ~ ${Box} {
    background-color: #FAFAFA;
    pointer-events: none;
    user-select: none;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  gap: 12px;
`

const CheckboxLabel = styled.div`
  > div, label {
    padding: 0;
    margin: 0;
  }
  ${props => props.disabled && `> div, label {
    user-select: none;
    pointer-events: none;
  }`}
`

const CheckboxWrapper = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  user-select: none;
`

const TickIcon = styled.div`
  background-image: ${props => `url(${props.theme.icons.tick})`};
  background-position: center center;
  background-size: 20px;
  background-repeat: no-repeat;
  width: inherit;
  height: inherit;
`

/**
 * Checkbox 
 */
const Checkbox = (props) => {
  const {
    input: {
      name,
      checked,
      onChange,
      onFocus,
      onBlur,
      value,
      type = 'checkbox'
    },
    meta: {
      error,
      touched
    },
    id,
    label,
    hint,
    link,
    linkTarget,
    required,
    disabled,
    disabledMessage,
    checkboxInterfaceActive,
    checkboxInterfaceInactive, 
    isGroup
  } = props

  const fieldInvalid = error && touched
  const errorMessage = fieldInvalid ? error : undefined

  const hintMessage = (disabled && disabledMessage) ? disabledMessage : hint

  const change = ({ checked }) => {
    const status = !checked ? checkboxInterfaceActive : checkboxInterfaceInactive
    onChange(status)
  }

  const isChecked = () => {
    const currentValue = !_.isNil(value) ? value : checked
    return currentValue === checkboxInterfaceActive
  }

  return (
    <Field error={errorMessage}>
      <Container>
        <CheckboxWrapper>
          <CheckboxInput
            id={id}
            type={type}
            name={name}
            checked={isChecked()}
            error={errorMessage}
            aria-required={required}
            aria-invalid={fieldInvalid}
            disabled={disabled}
            onChange={() => change({ checked: isChecked() })}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <Box aria-invalid={fieldInvalid}>
            <TickIcon width={24} height={19} />
          </Box>
        </CheckboxWrapper>
        <CheckboxLabel disabled={disabled}>
          <FieldMeta
            name={id}
            label={label}
            link={link}
            target={linkTarget}
            required={isGroup || required}
            disabled={disabled}
          />
        </CheckboxLabel>
      </Container>
      {errorMessage && <ErrorMessage error={translation(errorMessage)} />}
      {(hintMessage && <Hint message={translation(hintMessage)} />)}
    </Field>
  )
}

Checkbox.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** If the field is disabled */
  disabled: PropTypes.bool,
  /** Message displayed when field is disabled */
  disabledMessage: PropTypes.string,
  /** Input props based from React Final Form
   * 
   * `name` - Used to associate label and input
   *
   * `checked` - Value of the input
   * 
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   * 
   * `onFocus` - Function called when focus has been given to the field
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  /** Meta props based from React Final Form
   * 
   * `error` - Field validation message
   * 
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  }),
  checkboxInterfaceActive: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  checkboxInterfaceInactive: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

Checkbox.defaultProps = {
  required: false,
  disabled: false,
  input: {},
  meta: {},
  checkboxInterfaceActive: true,
  checkboxInterfaceInactive: false,
}

export default Checkbox
