import React, { Component } from 'react'
import currencies from 'currency-formatter/currencies.json'
import _ from 'lodash'

import Input from './Input'
import { currencyCode as defaultCurrencyCode } from '../../../config'

const DECIMAL_REGEX = /[^\d\.]/g

class InputContainer extends Component {
  // Fixes a bug which caused fields "labels" to not float when the value is changed programmatically.
  // By focusing and then immediately bluring it.
  componentWillReceiveProps(nextProps) {
    if (nextProps.value && !this.props.value) {
      let activeElement = document.activeElement
      if (activeElement !== this.ref) {
        setTimeout(() => {
          this.ref.focus()
          this.ref.blur()
          activeElement.focus()
        }, 0)
      }
    }
  }

  handleChange = (e) => {
    const { type, decimal = false } = this.props
    const value = e.target ? e.target.value : e
    let processedValue

    switch (type) {
      case 'number':
        if (decimal) {
          processedValue = value.replace(DECIMAL_REGEX, '')
        } else {
          processedValue = value.replace(/\D/g, '')
        }
        break
      case 'price':
        processedValue = value.replace(DECIMAL_REGEX, '')
        break
      default:
        processedValue = value
        break
    }

    return processedValue
  }

  getValue = () => {
    const { value, type, currencyCode = defaultCurrencyCode } = this.props
    if (type === 'price') {
      const currencySymbol = _.get(currencies, [currencyCode, 'symbol'])
      return currencySymbol + ' ' + value
    } else {
      return value
    }
  }

  getRef = (ref) => {
    if (ref) {
      this.ref = ref
    }
  }

  onChange = (e) => {
    this.props.onChange(this.handleChange(e))
  }

  onClearButtonPress = () => {
    this.onChange('')
  }

  onBlur = (e) => {
    this.props.onBlur(this.handleChange(e))
  }

  _generateProps = () => ({
    ...this.props,
    onBlur: this.onBlur,
    onChange: this.onChange,
    getRef: this.getRef,
    value: this.getValue(),
    onClearButtonPress: this.onClearButtonPress
  })

  render() {
    const props = this._generateProps()
    return <Input {...props} />
  }
}

InputContainer.defaultProps = {
  onBlur: () => { }
}

export default InputContainer
