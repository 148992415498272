export const handleApprovalStatus = ({ approvalStatus }) => {
    switch (approvalStatus) {
        case 'APPROVED':
            return {
                chipVariant: 'success',
                chipLabel: 'Change Request - Approved',
                alertText: 'Request was approved',
                alertSeverity: 'success',
                showReviewRequest: false,
                reviewDescriptionTitle: 'Change Request - Approval reason title'
            }
        case 'REJECTED':
            return {
                chipVariant: 'errorOutlined',
                chipLabel: 'Change Request - Rejected',
                alertText: 'Request was declined',
                alertSeverity: 'error',
                showReviewRequest: false,
                reviewDescriptionTitle: 'Change Request - Rejection reason title'
            }
        case 'PENDING':
            return {
                chipVariant: 'primary',
                chipLabel: 'Change Request - Pending',
                alertText: 'Requires an approval',
                alertSeverity: 'info',
                showReviewRequest: true
            }
        default:
            return {
                variant: 'default'
            }
    }
  }
  