import React from 'react'
import { Route } from 'react-router'

import { EmailVerifiedScreen } from './EmailVerifiedScreen'

const routes = [
  <Route key='/ota/email-verified' path='/ota/email-verified' exact component={EmailVerifiedScreen} />,
]

export default routes
