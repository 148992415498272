import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange } from 'recompose'

import { withStyles } from '@material-ui/core/styles'

import style from './style'
import { selectors as receiverSelectors } from '../../../store/modules/selectedReceiver'
import {
  selectors as formViewSelectors,
  actions as formViewActions
} from '../../../store/modules/formViews'
import { selectors as authSelectors } from '../../../store/modules/auth'

import ReportsSearchTable from './ReportConfigurationTable'

const mapStateToProps = state => {
  const updatedSchema = _.get(state, 'formViews.results')
  const formViews = formViewSelectors.getAllFormViews(state)
  const organisationId = authSelectors.getUserSelectedOrganisationId(state)

  return {
    formViews,
    updatedSchema,
    organisationId
  }
}

export default compose(
  withStyles(style),
  connect(mapStateToProps),
  withHandlers({
    togglePermission: ({ dispatch, organisationId }) => values => {
      let current
      const checkPublic = _.get(values, 'public', undefined)
      const checkPublish = _.get(values, 'publish', undefined)
      if (checkPublic !== undefined) {
        current = {
          public: values.public
        }
      } else if (checkPublish !== undefined) {
        current = {
          publish: values.publish
        }
      }
      return dispatch(
        formViewActions.updateFormView({ id: values.id, params: current })
      ).then(() => {
        return dispatch(formViewActions.fetchAllFormViews({
          organisationId,
          params: {
            version: 'ALL',
            source: 'ALL'
          }
        }))
      })
    }
  }),
  withPropsOnChange(['formViews', 'updatedSchema', 'isEditing'], props => {
    const { updatedSchema, isEditing } = props
    let { formViews } = props
    if (updatedSchema && formViews) {
      formViews = formViews.map(x =>
        x.id === updatedSchema.id ? updatedSchema : x
      )
    }
    return { formViews, isEditing }
  })
)(ReportsSearchTable)
