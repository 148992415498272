/**
 * @file Provide hooks and utility functions to be used by other services.
 */
import React, { useState, useEffect } from 'react'
/**
 * @typedef {Object} TaskData
 * @property {('PENDING' | 'SUCCESS' | 'ERROR')} status Current status of the request.
 * @property {object} response The obtained JSON from the API.
 * @property {(Error | null)} error The reported error JSON from the API.
 */

/**
 * Initial state of SDK reducers
 */
export const reducerInitialState = { status: 'PENDING', response: null, error: null }

/**
* Reducer function used to format data over asynchronous code.
* @param {Object} _state not used.
* @param {Object} action
*/
export const reducer = (_state, action) => {
  switch (action.status) {
    case 'SUCCESS':
      return { status: 'SUCCESS', response: action.response, error: null }
    case 'ERROR':
      return { status: 'ERROR', response: null, error: action.error };
    case 'CANCELLED':
      return { status: 'CANCELLED', response: null, error: null };
    default:
      return reducerInitialState
  }
}

/**
 * Resolves a pending promise and formats it as taskData.
 * @param {Promise} promise
 * @returns {TaskData}
 */
export const useResolvePromise = promise => {

  const [state, dispatch] = React.useReducer(reducer)

  React.useEffect(() => {
    (async () => {
      dispatch({ status: 'PENDING' })
      try {
        const response = await promise;
        dispatch({ status: 'SUCCESS', response })
      } catch (error) {
        dispatch({ status: 'ERROR', error })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return state;
}

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}