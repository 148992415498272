import { getRolesForPlatform } from '../roles/selectors'
import * as selectors from './selectors'
import _ from 'lodash'

export const getSearchUsersRoleIds = (state, roleIds) => {
  const rolesForPlatform = getRolesForPlatform(state)
  let searchUsersRoleIds = roleIds || selectors.getRoleIds(state)
  if (_.size(searchUsersRoleIds) === 0 && _.size(rolesForPlatform) > 0) {
    searchUsersRoleIds = _.map(rolesForPlatform, 'id')
  }
  return searchUsersRoleIds
}