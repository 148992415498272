const styles = theme => ({
  field: {
    marginBottom: '20px',
    width: '100%'
  },
  columnStyle: {
    padding: '0 20px',
    alignSelf: 'flex-start'
  },
  rowStyle: {
    marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0px'
    }
  },
  formContainer: {
    width: '100%'
  },
  fieldWrapper: {
    margin: '10px'
  },
  contentContainer: {
    margin: '20px 7.5%',
    display: 'flex',
    flexDirection: 'column'
  },
  submitError: {
    textAlign: 'right'
  },
  dropDownStandard: {
    width: '100%'
  },
  flexGridContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  requiredDeactivateButtonContainer: {
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'space-between'
  },
  requiredText: {
    paddingRight: 10
  },
  requiredTextDeactivate: {
    margin: '20px 0 0 7.5%'
  },
  warningSign: {
    padding: '5px'
  },
  warningBar: {
    backgroundColor: '#f1c40f',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row'
  },
  warningMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '10px',
  },
  input: {
    paddingTop: '5px',
    width: '100%',
    paddingleft: '10px'
  },
  permanentDeleteButton: {
    backgroundColor: 'red',
    '&:hover, &:active, &:focus': {
      backgroundColor: 'red'
    },
  }
})

export default theme => styles(theme)
