import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import _ from 'lodash'

export const getIsLoading = state => state.drugDictionary.status === PENDING
export const getError = state => state.drugDictionary.error
export const getIsInitial = state => !state.drugDictionary.status
export const getStatus = state => state.drugDictionary.status
export const getPage = state => state.drugDictionary.page
export const getSize = state => state.drugDictionary.size
export const getTotal = state => state.drugDictionary.total
export const getHasResults = state => _.get(state, 'drugDictionary.results') && _.get(state, 'drugDictionary.results').length
export const getDrugs = state => state.drugDictionary.results
export const getDrugsForDropdown = state => _.map(state.drugDictionary.results, (result) => result.name)
export const getDrugsForSearchResults = state => _.map(state.drugDictionary.results, (result) => {
  return {
    ...result,
    label: result.name
  }
})
export const getIsWatchListOnly = state => state.drugDictionary.watchListOnly

export const getHasMore = createSelector([
  getTotal,
  getDrugs
], (total, results) => {
  return total && results.length < total
})
