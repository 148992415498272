import _ from 'lodash'
import * as constants from './constants'
import { selectors as authSelectors } from '../auth'
import * as drugDetailsSelectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'

class DrugDetailsActions {
  fetchDrug = ({ id }) => ({
    type: constants.FETCH_DRUG,
    id,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      return digitalStoreSdk.drugs.fetchDrug({ id, organisationId })
    }
  })

  changeMetaDistribution = ({ metaDistribution }) => ({
    type: constants.CHANGE_META_DISTRIBUTION,
    metaDistribution,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      const drug = drugDetailsSelectors.getDrug(state)
      const id = _.get(drug, 'id')
      const orderedMeta = true

      // only refetch drug if there is not already data in the store
      // for the meta distribution being selected
      if (!_.get(drug, ['meta', metaDistribution])) {
        return digitalStoreSdk.drugs.fetchDrug({
          id,
          organisationId,
          metaDistribution,
          orderedMeta
        })
      } else {
        return Promise.resolve(drug)
      }
    }
  })
}

export default new DrugDetailsActions()
