import React from 'react'
import PropTypes from 'prop-types'
import style from './style'
import { withStyles } from '@material-ui/core/styles'

const MainLogo = ({ classes, src, name }) => (
  <div className={classes.logoContainer}>
    <img className={classes.logo} src={src} alt={name} />
  </div>
)

MainLogo.propTypes = {
  classes: PropTypes.any,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
}

export default withStyles(style)(MainLogo)
