import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

const Expandable = styled.div`
  width: '100%',
  overflow: 'hidden',
  transition: 'display 0.3s linear',
`

const Collapsible = (props) => {
  const {
    children,
    isOpen
  } = props

  const ref = useRef(null)
  const [height, setHeight] = useState(0)
  const [display, setDisplay] = useState('block')

  useEffect(() => {
    if (isOpen) {
      setHeight(ref.current?.getBoundingClientRect().height) 
      setDisplay('block')
    } else {
      setHeight(0)
      setDisplay('none')
    }
  }, [isOpen])

  return(
    <Expandable style={{ display }}>
      <div ref={ref}>
        {children}
      </div>
    </Expandable>
  )
}

export default Collapsible