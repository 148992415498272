import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ImportModal from '../Modals/ImportModal'
import ExportModal from '../Modals/ExportModal'
import UpdateModal from '../Modals/UpdateModal'
import ExportTranslationstModal from '../Modals/ExportTranslationsModal'


import modalService from '../../../../services/modalService'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import { MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { styled } from '@material-ui/core/styles'

import { useFormViews, useRCDetails, useRCEditor, useConditions } from '../../../../contexts'
import { hooks } from '../../../../store/modules/reportConfiguration'

const Container = styled('div')(({ theme }) => ({
  marginLeft: -theme.spacing(1),
  marginRight: theme.spacing(1)
}))
function ConfigurationMoreMenu(props) {
  const RCEditorData = useRCEditor()
  const FormViewData = useFormViews()
  const RCDetails = useRCDetails()
  const ConditionStore = useConditions()
  const { type: schemaName } = hooks.useCurrentFormViewMeta()

  const [anchorEl, setAnchorEl] = useState(null)
  const options = [
    {
      label: 'Import Configuration',
      value: 'import',
      component: ImportModal,
      props: {
        formViewId: RCEditorData.state.formViewId,
        loadFormView: FormViewData.actions.loadFormView,
        setEdited: FormViewData.actions.setEdited,
        formViewActions: FormViewData.actions,
        formViewDispatch: FormViewData.dispatch,
        conditionsDispatch: ConditionStore.dispatch,
        loadConditions: ConditionStore.addCondition,
        migrateConditions: ConditionStore.migrateConditions,
        formSchemaName: schemaName
      }
    },
    {
      label: 'Export Configuration',
      value: 'export',
      component: ExportModal,
      props: {
        formViewId: RCEditorData.state.formViewId,
        viewJson: FormViewData.viewJson
      }
    },
    {
      label: 'Check for Updates',
      value: 'update',
      component: UpdateModal,
      props: {
        formViewActions: FormViewData.actions,
        formViewDispatch: FormViewData.dispatch,
        schemaName,
        viewJSON: FormViewData.viewJson,
        formViewVersionMeta: FormViewData.formViewVersionMeta,
        edited: RCDetails.edited
      }
    },
    {
      label: 'Export Translations',
      value: 'exportTranslations',
      component: ExportTranslationstModal,
      props: {
        formViewId: RCEditorData.state.formViewId,
        viewJson: FormViewData.viewJson
      }
    },
  ]

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelection = (value) => {
    const { editing = false } = props
    const selectedOption = _.find(options, { value })
    const modalComponent = _.get(selectedOption, 'component')

    if (modalComponent) {
      modalService.open({
        component: modalComponent,
        editing,
        wideModal: true,
        ...selectedOption.props
      })
    }

    handleClose()
  }

  const open = Boolean(anchorEl)

  return (
    <Container>
      <IconButton
        aria-label='More'
        aria-owns={open ? 'configuration-menu' : null}
        aria-haspopup='true'
        onClick={(e) => handleClick(e)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='configuration-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        PaperProps={{}}
      >
        {options.map(option => (
          <MenuItem
            key={option.value}
            onClick={() => handleSelection(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  )
}

ConfigurationMoreMenu.propTypes = {
  editing: PropTypes.bool.isRequired
}

export default ConfigurationMoreMenu
