import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class OrganisationsActions {
  fetchAllOrganisations = ({ platformId }) => ({
    type: constants.FETCH_ALL_ORGANISATIONS,
    promise: () => digitalStoreSdk.platforms.fetchPlatformOrganisations({ id: platformId })
  })
  fetchUnassignedOrganisations = () => ({
    type: constants.FETCH_UNASSIGNED_ORGANISATIONS,
    promise: () => digitalStoreSdk.organisations.fetchUnassignedOrganisations()
  })
  fetchOrganisationById = ({ id }) => ({
    type: constants.FETCH_ORGANISATION_BY_ID,
    promise: () => digitalStoreSdk.organisations.fetchOrganisation({ id })
  })
  updateOrganisation = ({ id, params }) => ({
    type: constants.UPDATE_ORGANISATION,
    promise: () => digitalStoreSdk.organisations.updateOrganisation({ id, params })
  })
  fetchPaginatedOrganisations = ({ 
    query, 
    userId, 
    platformId, 
    limit, 
    offset, 
    includeUnassigned = true,
    attributes,
    detailsAttributes
  }) => ({
    type: constants.FETCH_PAGINATED_ORGANISATIONS,
    promise: () => digitalStoreSdk.organisations.fetchPaginatedOrganisations({ 
      query, 
      userId, 
      platformId, 
      limit, 
      offset, 
      includeUnassigned,
      attributes,
      detailsAttributes
    })
  })
  clearPaginatedOrganisations = () => ({
    type: constants.CLEAR_PAGINATED_ORGANISATIONS
  })
  searchOrganisations = ({ query, platformId, limit = 20, offset = 0 }) => ({
    type: constants.SEARCH_ORGANISATIONS,
    promise: () => digitalStoreSdk.organisations.searchOrganisations({ query, platformId, limit, offset })
  })
}

export default new OrganisationsActions()
