import React from 'react'

import Editable from '../Editable'
import { DateTimePicker } from '../../../components/Fields'
import P from '../../P'

const DateTimePickerComponent = Editable({
  input: props => {
    return (
      <DateTimePicker {...props} />
    )
  },
  display: ({ label, value }) => (
    <div>
      <P value={label} type='textButton' />
      <P value={value} />
    </div>
  )
})

export default DateTimePickerComponent
