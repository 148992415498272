import digitalStoreSdk from '../../../../digitalStoreSdk'
import fp from 'lodash/fp'

export const createImplementation = ({ props }) => ({
    minimumSearchLength: 3,
    asyncFunction: async ({
        value,
    }) => {

        const searchParams = {
            query: value,
            terminologyDictionaryId: props.dictionaryId
        }

        const data = await digitalStoreSdk
            .terminology
            .searchTerminologyEntries(searchParams)

        return fp.map(({ term, id, definition }) => ({
            label: term,
            value: id,
            meta: {
                definition
            }
        }), data.results)
    },
    renderOption: (option) => {
        return <>
            <span>{option.label}</span>
            <span>{option.meta.definition}</span>
        </>

    }
})
