import React from 'react'
import { Helmet } from 'react-helmet'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'

import MenuGrid from '../../../components/MenuGrid'
import MenuWidget from '../../../components/MenuWidget'
import { useDispatch } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import { translations } from '../../../config'
import { useLocation, useParams } from 'react-router-dom'

const PermissionsEditorScreen = () => {
  const dispatch = useDispatch()
  const { state = {} } = useLocation()

  const widgets = [
    {
      id: 'userGroups',
      title: 'User Groups',
      image: 'userGroupsImg',
      onClick: () => dispatch(push('/permissions/user-groups', state))
    }
  ]

  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar onClick={() => dispatch(goBack())} />
        )}
        centerContent={(
          <>
            <Heading component='h1'>{translations('App Name - Permissions')}</Heading>
            <Helmet>
              <title>{translations('App Name - Permissions')}</title>
            </Helmet>
          </>
        )}
      />
      <MenuGrid widgets={widgets} renderWidget={(widget) => {
        return (
          <MenuWidget
            id={widget.id}
            {...widget}
          />
        )
      }} />
    </div>
  )
}

export default PermissionsEditorScreen