import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { SLICE_NAME } from './constants'
import * as asyncActions from './asyncActions'
import * as selectors from './selectors'
import { createExtraReducers } from '../toolkit-utils'

const initialState = {
  master: {
    filters: {},
    pagination: {
      page: 1,
      total: 0,
      count: 0
    },
    data: []
  },
  detail: {}
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetMaster(state) {
      state.master = initialState.master
    },
    updateFilters(state, action) {
      _.set(state, 'master.filters', action.payload.filters)
      _.set(state, 'master.pagination.page', initialState.master.pagination.page)
      _.set(state, 'master.data', initialState.master.data)
    },
    incrementPage(state) {
      state.master.pagination.page++
    },
    resetStore() {
      return initialState
    }
  },
  extraReducers: createExtraReducers(SLICE_NAME)
})

export { selectors, asyncActions }
export const actions = slice.actions
export const store = slice.reducer
