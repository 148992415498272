import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import fp from 'lodash/fp'
import _ from 'lodash'
import digitalStoreSdk from '../../../digitalStoreSdk'
import {
    NAME,
    REQUEST_STATUS_TYPE
} from './constants'
import * as selectors from './selectors'
import { statusAndNameMatcher } from '../toolkit-utils'

const generatePath = ({ formViewId, formSchemaId }) => {
    const basePath = formViewId ? `master.byFormViewId` : `master.byFormSchemaId`
    return `${basePath}.${formViewId || formSchemaId || 'undefined'}`
}

const fetchIntegrationManagementConfigs = createAsyncThunk(
    'integrationManagement/fetchConfiguration',
    async ({ organisationId, formSchemaId, formViewId }, thunkApi) => {
        const fullPath = generatePath({ formSchemaId, formViewId })
        try {
            const data = await digitalStoreSdk.integrationManagement.fetchIntegrationManagementConfigurations({ organisationId, params: { formSchemaId, formViewId } })
            return thunkApi.fulfillWithValue({ data, meta: { formSchemaId, formViewId } }, { path: fullPath })
        } catch (error) {
            return thunkApi.rejectWithValue(_.get(error, 'error', error), { path: fullPath })
        }
    },
    {
        getPendingMeta: () => ({ path: 'master' })
    }
)

const createIntegrationManagementConfig = createAsyncThunk(
    'integrationManagement/createConfiguration',
    async ({ organisationId, formSchemaId, formViewId, conditions }, thunkApi) => {
        const fullPath = generatePath({ formSchemaId, formViewId })
        try {
            const data = await digitalStoreSdk.integrationManagement.createIntegrationManagementConfiguration({ params: { organisationId, formSchemaId, formViewId, conditions } })
            return thunkApi.fulfillWithValue({ data, meta: { formSchemaId, formViewId } }, { path: fullPath })
        } catch (error) {

            return thunkApi.rejectWithValue(_.get(error, 'error', error), { path: fullPath })
        }
    },
    {
        getPendingMeta: () => ({ path: 'master' })
    }
)

const updateIntegrationManagementConfig = createAsyncThunk(
    'integrationManagement/updateConfiguration',
    async ({ formSchemaId, formViewId, conditions, integrationManagementConfigurationId, organisationId }, thunkApi) => {
        const fullPath = generatePath({ formSchemaId, formViewId })
        try {
            const data = await digitalStoreSdk.integrationManagement.updateIntegrationManagementConfiguration({ integrationManagementConfigurationId, params: { formSchemaId, formViewId, conditions, organisationId } })
            return thunkApi.fulfillWithValue({ data, meta: { integrationManagementConfigurationId, formSchemaId, formViewId, conditions, } }, { path: fullPath })
        } catch (error) {
            return thunkApi.rejectWithValue(_.get(error, 'error', error), { path: fullPath })
        }
    },
    {
        getPendingMeta: () => ({ path: 'master' })
    }
)

const deleteIntegrationManagementConfigCondition = createAsyncThunk(
    'integrationManagement/deleteConfiguration',
    async ({ formSchemaId, formViewId, conditionId }, thunkApi) => {
        const fullPath = generatePath({ formSchemaId, formViewId })
        try {
            const data = await digitalStoreSdk.integrationManagement.deleteIntegrationManagementConfigurationCondition({ conditionId })
            return thunkApi.fulfillWithValue({ data, meta: { conditionId, formSchemaId, formViewId } }, { path: fullPath })
        } catch (error) {
            return thunkApi.rejectWithValue(_.get(error, 'error', error), { path: fullPath })
        }
    },
    {
        getPendingMeta: () => ({ path: 'master' })
    }
)

const set = ({ state, action, path, value }) => {
    const location = _.concat(_.split(action.meta.path, '.'), path)
    _.set(state, location, value)
}
const initialState = {
    master: {
        byFormViewId: {},
        byFormSchemaId: {}
    }
}
const isEmptyArray = fp.allPass([fp.isArray, fp.isEmpty])
const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        fetchConfiguration(state, action) {
            const { formViewId, formSchemaId } = action.meta
            if (formViewId) {
                _.set(state, `master.byFormViewId.${formViewId}`, action.payload)
            }
            if (formSchemaId) {
                _.set(state, `master.byFormSchemaId.${formSchemaId}`, action.payload)
            }
        },
        createConfiguration(state, action) {
            const { formViewId, formSchemaId } = action.meta
            const { id: integrationManagementConfigurationId } = action.payload
            if (formViewId) {
                _.set(state, `master.byFormViewId.${formViewId}.${integrationManagementConfigurationId}`, action.payload)
            }
            if (formSchemaId) {
                _.set(state, `master.byFormSchemaId.${formSchemaId}.${integrationManagementConfigurationId}`, action.payload)
            }
        },
        updateConfiguration(state, action) {
            const { formViewId, formSchemaId, integrationManagementConfigurationId } = action.meta
            if (formViewId) {
                _.set(state, `master.byFormViewId.${formViewId}.${integrationManagementConfigurationId}`, action.payload)
            }
            if (formSchemaId) {
                _.set(state, `master.byFormSchemaId.${formSchemaId}.${integrationManagementConfigurationId}`, action.payload)
            }
        },
        deleteConfiguration(state, action) {
            const { formViewId, formSchemaId, integrationManagementConfigurationId } = action.meta
            if (formViewId) {
                _.set(state, `master.byFormViewId.${formViewId}.${integrationManagementConfigurationId}`, undefined)
            }
            if (formSchemaId) {
                _.set(state, `master.byFormSchemaId.${formSchemaId}.${integrationManagementConfigurationId}`, undefined)
            }
        },
        resetStore() {
            return initialState
        }
    },
    extraReducers: (builder) => {
        return builder
            .addMatcher(
                statusAndNameMatcher(NAME, 'pending'),
                (state, action) => {
                    set({ state, action, path: 'status', value: REQUEST_STATUS_TYPE.PENDING })
                }
            )
            .addMatcher(
                statusAndNameMatcher(NAME, 'fulfilled'),
                (state, action) => {
                    const { data } = action.payload
                    set({
                        state,
                        action,
                        path: 'status',
                        value: isEmptyArray(data) ? REQUEST_STATUS_TYPE.IS_EMPTY : REQUEST_STATUS_TYPE.FULFILLED
                    })
                    set({ state, action, path: 'data', value: data })
                }
            )
            .addMatcher(
                statusAndNameMatcher(NAME, 'rejected'),
                (state, action) => {
                    set({ state, action, path: 'status', value: REQUEST_STATUS_TYPE.REJECTED })
                    set({ state, action, path: 'error', value: action.payload })
                }
            )
    }
})
export const asyncActions = {
    fetchIntegrationManagementConfigs,
    createIntegrationManagementConfig,
    updateIntegrationManagementConfig,
    deleteIntegrationManagementConfigCondition
}

export { selectors }
export const actions = slice.actions
export const store = slice.reducer
