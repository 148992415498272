import { connect } from 'react-redux'
import { submit } from 'redux-form'
import _ from 'lodash'
import fp from 'lodash/fp'
import moment from 'moment'
import {
  compose,
  withHandlers,
  withPropsOnChange
} from 'recompose'

import modalService from '../../../services/modalService'
import toastService from '../../../services/toastService'
import { translations } from '../../../config'
import ExportModal from './ExportModal'
import * as analytics from '../../../store/modules/platformAnalytics'
import * as auth from '../../../store/modules/auth'
import * as validators from '../../../validators'
import { getHasBackgroundAnalyticsExportsEnabled } from '../../../store/modules/platforms/selectors'

const formId = analytics.constants.EXPORT_ANALYTICS_FORM

const dateValidator = validators.isDateAfterOrEqual('startDate', 'Start Date')
const minLengthValidator = validators.minLength(1, 'sections')

const enhancer = compose(
  connect((state) => {
    const organisationOptions = auth.selectors.getUserOrganisationOptions(state)
    const organisationId = analytics.selectors.getSelectedOrganisationId(state)
    const timePeriod = analytics.selectors.getTimePeriodDates(state)
    const isBackgroundAnalyticsExportEnabled = getHasBackgroundAnalyticsExportsEnabled(state)
    const areOrganisationOptionsHidden = fp.allPass([
      options => fp.size(options) === 1,
      fp.some({ value: organisationId })
    ])(organisationOptions)
    const schema = [
      {
        id: 'organisationId',
        field: 'Dropdown',
        props: {
          label: translations('Organisation Select'),
          name: 'organisationId',
          options: organisationOptions,
          type: areOrganisationOptionsHidden ? 'hidden' : undefined
        }
      },
      {
        id: 'startDate',
        field: 'Date',
        props: {
          label: translations('Start Date'),
          name: 'startDate',
        }
      },
      {
        id: 'endDate',
        field: 'Date',
        props: {
          label: translations('End Date'),
          name: 'endDate',
          required: true,
          validate: [dateValidator]
        }
      },
      {
        id: 'sections',
        field: 'Dropdown',
        props: {
          label: translations('Sections'),
          name: 'sections',
          multiple: true,
          required: true,
          validate: [minLengthValidator],
          options: [
            { label: 'Patient Information', value: 'patient' },
            { label: 'Pregnancy Details', value: 'pregnancy' },
            { label: 'Immunocompromised Details', value: 'immunocompromised' },
            { label: 'Medicine/Vaccine Breakdown', value: 'drug' },
            { label: 'Concomitant Medicines Breakdown', value: 'otherDrug' },
            { label: 'Adverse Reaction Breakdown', value: 'reaction' },
            { label: 'AEFI Adverse Reaction Breakdown', value: 'aefiReaction' },
            { label: 'Narrative Details', value: 'narrative' },
            { label: 'AI reporting', value: 'enhancement' }
          ]
        }
      }]
    return {
      organisationOptions,
      areOrganisationOptionsHidden,
      isBackgroundAnalyticsExportEnabled,
      isSubmitting: analytics.selectors.getIsfetchingOrganisationAnalyticsExport(state),
      initialValues: {
        organisationId,
        startDate: moment(timePeriod.startDate).format('YYYYMMDD'),
        endDate: moment(timePeriod.endDate).format('YYYYMMDD')
      },
      schema
    }
  }),
  withHandlers({
    closeModal: () => () => {
      modalService.close()
    },
    onSubmit: ({ dispatch }) => () => {
      dispatch(submit(formId))
    },
    handleSubmit: (props) => async (formData) => {
      try {
        const { dispatch, isBackgroundAnalyticsExportEnabled } = props
        const filters = {
          organisationId: formData.organisationId,
          timePeriod: {
            startDate: formData.startDate,
            endDate: formData.endDate
          },
          sections: formData.sections
        }
        await dispatch(analytics.actions.exportAnalytics(filters))
        if (isBackgroundAnalyticsExportEnabled) {
          toastService.action({
            type: 'success',
            message: translations('Analytics Export - Processing Message'),
            autoHideDuration: 6000
          })
        }
        modalService.close()
      } catch (error) {
        let errorTranslation = 'Failure - Export Analytics'

        if (_.startsWith(error.message, 'Failure -')) {
          errorTranslation = error.message
        }

        if (_.get(error, 'code')) {
          errorTranslation = error.code
        }

        toastService.action({
          type: 'error',
          message: translations(errorTranslation),
          autoHideDuration: 6000
        })
      }
    }
  }),
  withPropsOnChange(['isSubmitting'], (props) => {
    const { onSubmit, closeModal, isSubmitting } = props
    return {
      actions: [
        {
          success: true,
          text: translations('Export'),
          submitting: isSubmitting,
          onClick: onSubmit,
          primary: true
        },
        {
          text: translations('Cancel'),
          onClick: closeModal
        }
      ]
    }
  })
)

export default enhancer(ExportModal)
