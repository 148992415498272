import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import DeveloperScreen from './DeveloperScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

const routes = [
  <PrivateRoute key='/developer' path='/developer' exact component={TimedLogoutComponent(DeveloperScreen)} />
]

export default routes
