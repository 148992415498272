import { colors, theme } from '../../config/theme'

const verticalCentering = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
}

const styles = {
  container: {
    height: theme.subheaderHeight,
    backgroundColor: colors.lightGrey,
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 100
  },
  notFixed: {
    position: 'relative'
  },
  leftContent: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    ...verticalCentering
  },
  centerContent: {
    height: '100%',
    maxWidth: '50%',
    margin: '0 auto',
    ...verticalCentering
  },
  rightContent: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    ...verticalCentering
  },
  fake: {
    height: theme.subheaderHeight,
    minHeight: theme.subheaderHeight,
    width: '100%',
    flexShrink: 0
  },
  inModal: {
    marginTop: -20,
    marginLeft: -20,
    marginRight: -20
  }
}

export default theme => styles
