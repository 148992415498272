import _ from 'lodash'
import { selectors as authSelectors } from '../auth'
import accountDeletionRequestActions from './actions'

class AcccountDeletionRequestMiddleware {
  loadInitialAccountDeletionRequestMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const isLoggedIn = authSelectors.getIsLoggedIn(getState())
    if (isLoggedIn) {
      dispatch(accountDeletionRequestActions.searchAccountDeletionRequestFresh())
    }
  }
}

export default new AcccountDeletionRequestMiddleware()
