"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  showForIndustryOnly: true,
  optional: true,
  mandatoryForIndustry: true,
  showForMOPAndHCP: true,
  showForEveryone: true,
  showForMOPOnly: true,
  mandatoryForIndustryButOnlyStatusReporterOther: true,
  isStatusReporterOthers: true,
  visibleIfStatusReporterIsOthers: true,
  clearIfStatusReporterIsNotOthers: true,
  isStatusReporterAuthRep: true,
  isNomenclatureSystemOther: true,
  visibleIfNomenclatureSystemIsOther: true,
  clearIfNomenclatureSystemIsNotOther: true,
  isInitialReporterRoleOther: true,
  visibleIfInitialReporterRoleIsOther: true,
  clearIfInitialReporterRoleIsNotOther: true,
  isDeviceClassMDD: true,
  visibleIfDeviceClassIsMDD: true,
  clearIfDeviceClassIsNotMDD: true,
  isDeviceClassIVDD: true,
  visibleIfDeviceClassIsIVDD: true,
  clearIfDeviceClassIsNotIVDD: true,
  isDeviceClassMDR: true,
  visibleIfDeviceClassIsMDR: true,
  clearIfDeviceClassIsNotMDR: true,
  isDeviceClassIVDR: true,
  visibleIfDeviceClassIsIVDR: true,
  clearIfDeviceClassIsNotIVDR: true,
  isCurrentDeviceLocationOther: true,
  visibleIfCurrentDeviceLocationIsOther: true,
  clearIfCurrentDeviceLocationIsNotOther: true,
  isDeviceOperatorAtEventOther: true,
  visibleIfDeviceOperatorAtEventIsOther: true,
  clearIfDeviceOperatorAtEventIsNotOther: true,
  isDeviceUsageOther: true,
  visibleIfDeviceUsageIsOther: true,
  clearIfDeviceUsageIsNotOther: true,
  isNumberBasedOnOther: true,
  visibleIfNumberBasedOnIsOther: true,
  clearIfNumberBasedOnIsNotOther: true,
  isDistributionAllHidden: true,
  clearIfDistributionAllIsNotVisible: true,
  isDistributionEEAHidden: true,
  clearIfDistributionEEAIsNotVisible: true,
  getFormatForDate: true,
  isReportTypeNotAFollowUpOrFinal: true,
  isSimilarIncidentsIMDRFOther: true,
  castToCountryCode: true,
  isCombinedOrFinalReportable: true,
  returnEmptyStringValue: true,
  statusReporterConditionalFieldUpdate: true
};
exports.visibleIfStatusReporterIsOthers = exports.visibleIfNumberBasedOnIsOther = exports.visibleIfNomenclatureSystemIsOther = exports.visibleIfInitialReporterRoleIsOther = exports.visibleIfDeviceUsageIsOther = exports.visibleIfDeviceOperatorAtEventIsOther = exports.visibleIfDeviceClassIsMDR = exports.visibleIfDeviceClassIsMDD = exports.visibleIfDeviceClassIsIVDR = exports.visibleIfDeviceClassIsIVDD = exports.visibleIfCurrentDeviceLocationIsOther = exports.statusReporterConditionalFieldUpdate = exports.showForMOPOnly = exports.showForMOPAndHCP = exports.showForIndustryOnly = exports.showForEveryone = exports.returnEmptyStringValue = exports.optional = exports.mandatoryForIndustryButOnlyStatusReporterOther = exports.mandatoryForIndustry = exports.isStatusReporterOthers = exports.isStatusReporterAuthRep = exports.isSimilarIncidentsIMDRFOther = exports.isReportTypeNotAFollowUpOrFinal = exports.isNumberBasedOnOther = exports.isNomenclatureSystemOther = exports.isInitialReporterRoleOther = exports.isDistributionEEAHidden = exports.isDistributionAllHidden = exports.isDeviceUsageOther = exports.isDeviceOperatorAtEventOther = exports.isDeviceClassMDR = exports.isDeviceClassMDD = exports.isDeviceClassIVDR = exports.isDeviceClassIVDD = exports.isCurrentDeviceLocationOther = exports.isCombinedOrFinalReportable = exports.getFormatForDate = exports.clearIfStatusReporterIsNotOthers = exports.clearIfNumberBasedOnIsNotOther = exports.clearIfNomenclatureSystemIsNotOther = exports.clearIfInitialReporterRoleIsNotOther = exports.clearIfDistributionEEAIsNotVisible = exports.clearIfDistributionAllIsNotVisible = exports.clearIfDeviceUsageIsNotOther = exports.clearIfDeviceOperatorAtEventIsNotOther = exports.clearIfDeviceClassIsNotMDR = exports.clearIfDeviceClassIsNotMDD = exports.clearIfDeviceClassIsNotIVDR = exports.clearIfDeviceClassIsNotIVDD = exports.clearIfCurrentDeviceLocationIsNotOther = exports.castToCountryCode = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = _interopRequireDefault(require("lodash/fp"));

var computedHidden = _interopRequireWildcard(require("../../../formHelpers/computedHidden"));

Object.keys(computedHidden).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === computedHidden[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return computedHidden[key];
    }
  });
});

var computedValue = _interopRequireWildcard(require("../../../formHelpers/computedValue"));

var _options = require("../../../formHelpers/options");

Object.keys(_options).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _options[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _options[key];
    }
  });
});

var _countryCodes = require("../../../config/countryCodes");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var showForIndustryOnly = function showForIndustryOnly(formValues, parentFormValues, organisationDetails, formViewDetails) {
  return (0, computedHidden.isAudience)(formViewDetails, {
    1: false,
    2: true,
    3: true
  });
};

exports.showForIndustryOnly = showForIndustryOnly;

var optional = function optional(formValues, parentFormValues, organisationDetails, formViewDetails) {
  return (0, computedHidden.isAudience)(formViewDetails, {
    1: false,
    2: false,
    3: false
  });
};

exports.optional = optional;

var mandatoryForIndustry = function mandatoryForIndustry(formValues, parentFormValues, organisationDetails, formViewDetails) {
  return (0, computedHidden.isAudience)(formViewDetails, {
    1: true,
    2: false,
    3: false
  });
};

exports.mandatoryForIndustry = mandatoryForIndustry;

var showForMOPAndHCP = function showForMOPAndHCP(formValues, parentFormValues, organisationDetails, formViewDetails) {
  return (0, computedHidden.isAudience)(formViewDetails, {
    1: true,
    2: false,
    3: false
  });
};

exports.showForMOPAndHCP = showForMOPAndHCP;

var showForEveryone = function showForEveryone(formValues, parentFormValues, organisationDetails, formViewDetails) {
  return (0, computedHidden.isAudience)(formViewDetails, {
    1: false,
    2: false,
    3: false
  });
};

exports.showForEveryone = showForEveryone;

var showForMOPOnly = function showForMOPOnly(formValues, parentFormValues, organisationDetails, formViewDetails) {
  return (0, computedHidden.isAudience)(formViewDetails, {
    1: false,
    2: false,
    3: true
  });
};

exports.showForMOPOnly = showForMOPOnly;

var mandatoryForIndustryButOnlyStatusReporterOther = function mandatoryForIndustryButOnlyStatusReporterOther(formValues, parentFormValues, organisationDetails, formViewDetails) {
  return (0, computedHidden.isAudience)(formViewDetails, {
    1: formValues.statusReporter === 'Others',
    2: false,
    3: false
  });
};

exports.mandatoryForIndustryButOnlyStatusReporterOther = mandatoryForIndustryButOnlyStatusReporterOther;
var isStatusReporterOthers = computedHidden.fieldIs('statusReporter', 'Others');
exports.isStatusReporterOthers = isStatusReporterOthers;
var visibleIfStatusReporterIsOthers = computedHidden.visibleIf(isStatusReporterOthers);
exports.visibleIfStatusReporterIsOthers = visibleIfStatusReporterIsOthers;
var clearIfStatusReporterIsNotOthers = computedValue.clearIfNot(isStatusReporterOthers);
exports.clearIfStatusReporterIsNotOthers = clearIfStatusReporterIsNotOthers;
var isStatusReporterAuthRep = computedHidden.fieldIs('statusReporter', 'Authorised representative');
exports.isStatusReporterAuthRep = isStatusReporterAuthRep;
var isNomenclatureSystemOther = computedHidden.fieldIs('nomenclatureSystem', 'Other');
exports.isNomenclatureSystemOther = isNomenclatureSystemOther;
var visibleIfNomenclatureSystemIsOther = computedHidden.visibleIf(isNomenclatureSystemOther);
exports.visibleIfNomenclatureSystemIsOther = visibleIfNomenclatureSystemIsOther;
var clearIfNomenclatureSystemIsNotOther = computedValue.clearIfNot(isNomenclatureSystemOther);
exports.clearIfNomenclatureSystemIsNotOther = clearIfNomenclatureSystemIsNotOther;
var isInitialReporterRoleOther = computedHidden.fieldIs('initialReporterRole', 'Other');
exports.isInitialReporterRoleOther = isInitialReporterRoleOther;
var visibleIfInitialReporterRoleIsOther = computedHidden.visibleIf(isInitialReporterRoleOther);
exports.visibleIfInitialReporterRoleIsOther = visibleIfInitialReporterRoleIsOther;
var clearIfInitialReporterRoleIsNotOther = computedValue.clearIfNot(isInitialReporterRoleOther);
exports.clearIfInitialReporterRoleIsNotOther = clearIfInitialReporterRoleIsNotOther;
var isDeviceClassMDD = computedHidden.fieldIs('deviceClassChoice', 'MDD');
exports.isDeviceClassMDD = isDeviceClassMDD;
var visibleIfDeviceClassIsMDD = computedHidden.visibleIf(isDeviceClassMDD);
exports.visibleIfDeviceClassIsMDD = visibleIfDeviceClassIsMDD;
var clearIfDeviceClassIsNotMDD = computedValue.clearIfNot(isDeviceClassMDD);
exports.clearIfDeviceClassIsNotMDD = clearIfDeviceClassIsNotMDD;
var isDeviceClassIVDD = computedHidden.fieldIs('deviceClassChoice', 'IVDD');
exports.isDeviceClassIVDD = isDeviceClassIVDD;
var visibleIfDeviceClassIsIVDD = computedHidden.visibleIf(isDeviceClassIVDD);
exports.visibleIfDeviceClassIsIVDD = visibleIfDeviceClassIsIVDD;
var clearIfDeviceClassIsNotIVDD = computedValue.clearIfNot(isDeviceClassIVDD);
exports.clearIfDeviceClassIsNotIVDD = clearIfDeviceClassIsNotIVDD;
var isDeviceClassMDR = computedHidden.fieldIs('deviceClassChoice', 'MDR');
exports.isDeviceClassMDR = isDeviceClassMDR;
var visibleIfDeviceClassIsMDR = computedHidden.visibleIf(isDeviceClassMDR);
exports.visibleIfDeviceClassIsMDR = visibleIfDeviceClassIsMDR;
var clearIfDeviceClassIsNotMDR = computedValue.clearIfNot(isDeviceClassMDR);
exports.clearIfDeviceClassIsNotMDR = clearIfDeviceClassIsNotMDR;
var isDeviceClassIVDR = computedHidden.fieldIs('deviceClassChoice', 'IVDR');
exports.isDeviceClassIVDR = isDeviceClassIVDR;
var visibleIfDeviceClassIsIVDR = computedHidden.visibleIf(isDeviceClassIVDR);
exports.visibleIfDeviceClassIsIVDR = visibleIfDeviceClassIsIVDR;
var clearIfDeviceClassIsNotIVDR = computedValue.clearIfNot(isDeviceClassIVDR);
exports.clearIfDeviceClassIsNotIVDR = clearIfDeviceClassIsNotIVDR;
var isCurrentDeviceLocationOther = computedHidden.fieldIs('currentDeviceLocation', 'Other');
exports.isCurrentDeviceLocationOther = isCurrentDeviceLocationOther;
var visibleIfCurrentDeviceLocationIsOther = computedHidden.visibleIf(isCurrentDeviceLocationOther);
exports.visibleIfCurrentDeviceLocationIsOther = visibleIfCurrentDeviceLocationIsOther;
var clearIfCurrentDeviceLocationIsNotOther = computedValue.clearIfNot(isCurrentDeviceLocationOther);
exports.clearIfCurrentDeviceLocationIsNotOther = clearIfCurrentDeviceLocationIsNotOther;
var isDeviceOperatorAtEventOther = computedHidden.fieldIs('deviceOperatorAtEvent', 'Other');
exports.isDeviceOperatorAtEventOther = isDeviceOperatorAtEventOther;
var visibleIfDeviceOperatorAtEventIsOther = computedHidden.visibleIf(isDeviceOperatorAtEventOther);
exports.visibleIfDeviceOperatorAtEventIsOther = visibleIfDeviceOperatorAtEventIsOther;
var clearIfDeviceOperatorAtEventIsNotOther = computedValue.clearIfNot(isDeviceOperatorAtEventOther);
exports.clearIfDeviceOperatorAtEventIsNotOther = clearIfDeviceOperatorAtEventIsNotOther;
var isDeviceUsageOther = computedHidden.fieldIs('deviceUsage', 'Other');
exports.isDeviceUsageOther = isDeviceUsageOther;
var visibleIfDeviceUsageIsOther = computedHidden.visibleIf(isDeviceUsageOther);
exports.visibleIfDeviceUsageIsOther = visibleIfDeviceUsageIsOther;
var clearIfDeviceUsageIsNotOther = computedValue.clearIfNot(isDeviceUsageOther);
exports.clearIfDeviceUsageIsNotOther = clearIfDeviceUsageIsNotOther;
var isNumberBasedOnOther = computedHidden.fieldIs('numberBasedOn', 'Other');
exports.isNumberBasedOnOther = isNumberBasedOnOther;
var visibleIfNumberBasedOnIsOther = computedHidden.visibleIf(isNumberBasedOnOther);
exports.visibleIfNumberBasedOnIsOther = visibleIfNumberBasedOnIsOther;
var clearIfNumberBasedOnIsNotOther = computedValue.clearIfNot(isNumberBasedOnOther);
exports.clearIfNumberBasedOnIsNotOther = clearIfNumberBasedOnIsNotOther;

var isDistributionAllHidden = function isDistributionAllHidden(values, parentFormValues, organisationDetails, formViewDetails) {
  if ((0, computedHidden.isAudience)(formViewDetails, {
    1: false,
    2: true,
    3: true
  })) return true;

  var checkMulti = _lodash["default"].get(values, 'distribution_multi');

  if (checkMulti && checkMulti.length) {
    return true;
  }

  return false;
};

exports.isDistributionAllHidden = isDistributionAllHidden;
var clearIfDistributionAllIsNotVisible = computedValue.clearIf(isDistributionAllHidden);
exports.clearIfDistributionAllIsNotVisible = clearIfDistributionAllIsNotVisible;

var isDistributionEEAHidden = function isDistributionEEAHidden(values, parentFormValues, organisationDetails, formViewDetails) {
  return (0, computedHidden.isAudience)(formViewDetails, {
    1: false,
    2: true,
    3: true
  }) ? true : !!values.distribution_all;
};

exports.isDistributionEEAHidden = isDistributionEEAHidden;
var clearIfDistributionEEAIsNotVisible = computedValue.clearIf(isDistributionEEAHidden);
exports.clearIfDistributionEEAIsNotVisible = clearIfDistributionEEAIsNotVisible;

var getFormatForDate = function getFormatForDate(value, formats) {
  if (!value) return '';
  if (!formats) return '';
  var date = {
    year: value.substring(0, 4),
    month: value.substring(4, 6),
    day: value.substring(6, 8)
  };

  if (date.year && date.month && date.day) {
    return formats.yyyymmdd || '';
  } else if (date.year && date.month) {
    return formats.yyyymm || '';
  } else if (date.year) {
    return formats.yyyy || '';
  } else {
    return '';
  }
};

exports.getFormatForDate = getFormatForDate;

var isReportTypeNotAFollowUpOrFinal = function isReportTypeNotAFollowUpOrFinal(values) {
  return _fp["default"].compose(function (reportType) {
    return !['Follow up', 'Final (Reportable incident)', 'Final (Non-reportable incident)'].includes(reportType);
  }, _fp["default"].get('reportType'))(values);
};

exports.isReportTypeNotAFollowUpOrFinal = isReportTypeNotAFollowUpOrFinal;

var isSimilarIncidentsIMDRFOther = function isSimilarIncidentsIMDRFOther(values) {
  return _fp["default"].compose(function (similarIncidentsIMDRF) {
    return similarIncidentsIMDRF && similarIncidentsIMDRF.includes('other');
  }, _fp["default"].get('similarIncidentsIMDRF'))(values);
};

exports.isSimilarIncidentsIMDRFOther = isSimilarIncidentsIMDRFOther;

var matchCountryToUKAndIreland = function matchCountryToUKAndIreland(dataValue) {
  var searchTerm = _lodash["default"].toLower(dataValue);

  switch (true) {
    case _lodash["default"].includes(searchTerm, 'northern ireland'):
      return _countryCodes.camelCaseCountryLookUp.northernIreland;

    case _lodash["default"].includes(searchTerm, 'united kingdom'):
      return _countryCodes.camelCaseCountryLookUp.unitedKingdom;

    default:
      return;
  }
};

var matchToCountryCode = function matchToCountryCode(dataValue) {
  var key = _lodash["default"].camelCase(dataValue);

  return _countryCodes.camelCaseCountryLookUp[key];
};

var castToCountryCode = function castToCountryCode(dataValue) {
  var value = _lodash["default"].find([matchCountryToUKAndIreland(dataValue), matchToCountryCode(dataValue)], _lodash["default"].identity);

  return value || undefined;
};

exports.castToCountryCode = castToCountryCode;

var isCombinedOrFinalReportable = function isCombinedOrFinalReportable(formValues) {
  return ['Combined initial and final', 'Final (Reportable incident)'].includes(formValues.reportType);
};

exports.isCombinedOrFinalReportable = isCombinedOrFinalReportable;

var returnEmptyStringValue = function returnEmptyStringValue(dataValue) {
  return '';
};

exports.returnEmptyStringValue = returnEmptyStringValue;

var statusReporterConditionalFieldUpdate = function statusReporterConditionalFieldUpdate(formValuePaths, fieldValue) {
  var result;

  switch (fieldValue) {
    case 'Manufacturer':
      result = _lodash["default"].filter(formValuePaths, function (value) {
        return /^mfr/.test(value);
      });
      break;

    case 'Authorised representative':
      result = _lodash["default"].filter(formValuePaths, function (value) {
        return /^AR/.test(value);
      });
      break;

    case 'Others':
    default:
      break;
  }

  if (_lodash["default"].isUndefined(result) || _lodash["default"].size(result) > 1 || _lodash["default"].isEmpty(result)) {
    return false;
  }

  return result[0];
};

exports.statusReporterConditionalFieldUpdate = statusReporterConditionalFieldUpdate;