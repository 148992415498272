import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Form, { FormBody, FormSubmit, FormError } from '../../../../../components/Form'
import { translations } from '../../../../../config'
import Button from '../../../../../components/Button'
import A from '../../../../../components/A'
import BottomBar from '../../../../../components/BottomBar'
import style from './style'
import _ from 'lodash'

const PageForm = Form('page')
const _getSchema = ({languageOptions = []}) => {
  const schemaItems = [
    {
      id: 'title',
      field: 'Input',
      props: {
        label: 'Title',
        required: true,
        shrink: true
      }
    },
    {
      id: 'content',
      field: 'Wysiwyg',
      props: {
        label: 'Details',
        required: true,
        shrink: true
      }
    },
    {
      id: 'languageId',
      field: 'Dropdown',
      props: {
        required: true,
        label: 'Language',
        options: languageOptions,
        shrink: true
      }
    }
  ]

  return schemaItems
}

const PageFormWrapper = ({ languageOptions, classes, error, handleSubmit, handleDelete, getPublicLink, initialValues, isSubmitting, isInvalid }) => {

  const layout = [
      'title:12',
      'content:12',
      'languageId:9'
  ]
  
  return (
    <div>
      <FormError>{error}</FormError>
      <PageForm editing onSubmit={handleSubmit} initialValues={initialValues} noValidate>
        <FormBody schema={_getSchema({languageOptions})} layout={layout} />
        <BottomBar>
          <FormSubmit
            containerClass={classes.submitWrapper}
            submitting={isSubmitting}
            invalid={isInvalid}
            noContainer
          >{translations('Submit')}</FormSubmit>
          {!initialValues.new && (
            <>
            <Button
            onClick={handleDelete}
            disabled={isSubmitting}>{translations('Delete')}</Button>
            <A target='_blank' href={getPublicLink()} value={translations('Public Link')} />
            </>
          )}
        </BottomBar>
      </PageForm>
    </div>
  )
}

PageFormWrapper.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
}

export default withStyles(style)(PageFormWrapper)
