"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setValidators = exports.setTypeToDropDown = exports.setRequired = exports.setOtherFieldsDataMap = exports.setLabel = exports.setDropDownOptions = exports.setComputedValue = exports.setComputedRequired = exports.setComputedHidden = exports.setComputedDisabled = exports.removePropTypeHidden = exports.removeComputedValue = exports.modifiers = exports.getCollection = exports.convertToFormView = exports.combineGetSchemas = exports.addPropRequiredTrue = exports.KeepAndAlterFormSchemaItems = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = _interopRequireDefault(require("lodash/fp"));

var collectionSchemas = _interopRequireWildcard(require("../collections/schemas"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var collectionsLookUp = {
  BCG: collectionSchemas.bcg,
  YellowCard: collectionSchemas.yellowcard,
  PAHO: collectionSchemas.paho
};

var getCollection = function getCollection(key) {
  var validators = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var questions;

  try {
    questions = _lodash["default"].get(collectionsLookUp, key);
    return questions(validators);
  } catch (error) {
    questions = [];
  }
};

exports.getCollection = getCollection;

var convertToFormView = function convertToFormView(schema) {
  return _lodash["default"].map(schema, function (field) {
    return {
      id: field.id,
      name: field.props.label,
      visible: false
    };
  });
};

exports.convertToFormView = convertToFormView;

var updateFormItem = function updateFormItem(_ref) {
  var validators = _ref.validators,
      formSchema = _ref.formSchema;
  return function (fieldId) {
    var formSchemaItem = _fp["default"].find({
      id: fieldId.id
    }, formSchema);

    if (_fp["default"].isNil(formSchemaItem)) {
      return;
    }

    var changes = fieldId.changes;

    if (_fp["default"].isArray(changes)) {
      var modifiedFormSchemaItem = _fp["default"].compose.apply(_fp["default"], _toConsumableArray(_fp["default"].over(changes)({
        validators: validators
      })))(formSchemaItem);

      return modifiedFormSchemaItem;
    }

    return formSchemaItem;
  };
};

var removeComputedValue = function removeComputedValue() {
  return _fp["default"].unset('computedValue');
};

exports.removeComputedValue = removeComputedValue;

var removePropTypeHidden = function removePropTypeHidden() {
  return _fp["default"].unset('props.type');
};

exports.removePropTypeHidden = removePropTypeHidden;

var addPropRequiredTrue = function addPropRequiredTrue() {
  return _fp["default"].set('props.required', true);
};

exports.addPropRequiredTrue = addPropRequiredTrue;

var setTypeToDropDown = function setTypeToDropDown() {
  return _fp["default"].set('type', 'Dropdown');
};

exports.setTypeToDropDown = setTypeToDropDown;

var setDropDownOptions = function setDropDownOptions(options) {
  return function () {
    return _fp["default"].set('props.options', options);
  };
};

exports.setDropDownOptions = setDropDownOptions;

var setLabel = function setLabel(label) {
  return function () {
    return _fp["default"].set('props.label', label);
  };
};

exports.setLabel = setLabel;

var setRequired = function setRequired(required) {
  return function () {
    return _fp["default"].set('props.required', required);
  };
};

exports.setRequired = setRequired;

var setValidators = function setValidators(validateFn) {
  return function (_ref2) {
    var validators = _ref2.validators;
    return _fp["default"].set('props.validate', validateFn(validators));
  };
};

exports.setValidators = setValidators;

var setOtherFieldsDataMap = function setOtherFieldsDataMap(dataMap) {
  return function () {
    return _fp["default"].set('otherFieldsDataMap', dataMap);
  };
};

exports.setOtherFieldsDataMap = setOtherFieldsDataMap;

var setComputedValue = function setComputedValue(fn) {
  return function () {
    return _fp["default"].set('computedValue', fn);
  };
};

exports.setComputedValue = setComputedValue;

var setComputedHidden = function setComputedHidden(fn) {
  return function () {
    return _fp["default"].set('computedHidden', fn);
  };
};

exports.setComputedHidden = setComputedHidden;

var setComputedRequired = function setComputedRequired(fn) {
  return function () {
    return _fp["default"].set('computedRequired', fn);
  };
};

exports.setComputedRequired = setComputedRequired;

var setComputedDisabled = function setComputedDisabled(fn) {
  return function () {
    return _fp["default"].set('computedDisabled', fn);
  };
};

exports.setComputedDisabled = setComputedDisabled;
var modifiers = {
  removePropTypeHidden: removePropTypeHidden,
  addPropRequiredTrue: addPropRequiredTrue,
  setTypeToDropDown: setTypeToDropDown,
  setDropDownOptions: setDropDownOptions,
  setValidators: setValidators,
  setComputedValue: setComputedValue,
  setComputedHidden: setComputedHidden,
  setComputedRequired: setComputedRequired,
  setComputedDisabled: setComputedDisabled,
  setLabel: setLabel,
  setRequired: setRequired,
  setOtherFieldsDataMap: setOtherFieldsDataMap,
  removeComputedValue: removeComputedValue
};
exports.modifiers = modifiers;

var KeepAndAlterFormSchemaItems = function KeepAndAlterFormSchemaItems(_ref3) {
  var fields = _ref3.fields,
      getFormSchema = _ref3.getFormSchema;
  return function (validators) {
    var formSchema = getFormSchema(validators);

    var alteredFormSchema = _fp["default"].map(updateFormItem({
      validators: validators,
      formSchema: formSchema
    }), fields);

    return alteredFormSchema;
  };
};

exports.KeepAndAlterFormSchemaItems = KeepAndAlterFormSchemaItems;

var combineGetSchemas = function combineGetSchemas(getSchemas) {
  return _fp["default"].compose(_fp["default"].flatten, _fp["default"].over(getSchemas));
};

exports.combineGetSchemas = combineGetSchemas;