import _ from 'lodash'

import { Grid } from '@material-ui/core'
import P from '../../../../components/P'

const titleStyle = {
  textTransform: 'uppercase',
  fontWeight: 900,
  whiteSpace: 'nowrap'
}
  
const ReceiverMeta = ({ initialValues, receiverOptions }) => {
  const currentReceivers = _.get(initialValues, 'receiverSchemas', [])
  const allFormSchemas = _.get(receiverOptions, 'formSchemas')
  const allSources = _.get(receiverOptions, 'sources')
  const missingReceivers = {}
  _.forEach(allSources, (source) => {
    const sourceId = _.get(source, 'value')
    const sourceReceiver = _.filter(currentReceivers, ({ source, condition_formViewCategory }) => (source === sourceId || source === null) && condition_formViewCategory === undefined)
    const currentlyCoveredFormSchemas = _.map(sourceReceiver, 'schema')
    const missingSchemas = _.filter(allFormSchemas, ({ value: id }) => {
      return !_.includes(currentlyCoveredFormSchemas, id)
    })
    _.set(missingReceivers, sourceId, missingSchemas)
  })
  return _.size(missingReceivers) !== 0 && (
    <>
      <P style={titleStyle} value={'Sources & FormSchemas with no receivers configured:'} />
      <Grid container direction='row' justify='space-evenly' wrap={'wrap'} spacing={4}>
        {
          _.map(missingReceivers, (formSchemasMissing, sourceId) => {
            const matchedSource = _.find(allSources, { value: sourceId })
            return (
              <Grid item direction='column'>
                <P style={titleStyle} value={matchedSource.label} />
                <Grid direction='column'>
                  {
                    _.map(formSchemasMissing, ({ label }) => {
                      return (
                        <P value={label} />
                      )
                    })
                  }
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
    </>
  )
}

export default ReceiverMeta
