import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import ReportConfigurationFollowUpDetails from '../ReportConfigurationFollowUpDetails'

const ReportConfigurationFollowUpScreen = (props) => {

  return (
    <div>
      <ReportConfigurationFollowUpDetails />
    </div>
  )
}

export default ReportConfigurationFollowUpScreen