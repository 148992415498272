import _ from 'lodash'
import { push } from 'connected-react-router'
import { translations } from '../../../../config'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getUserSelectedOrganisationId } from '../../../../store/modules/auth/selectors'

import MoreMenu from '../../../../components/MoreMenu/MoreMenu'
import VigilanceContactsModal from '../VigilanceContactsModal'
import modalService from '../../../../services/modalService'
import toastService from '../../../../services/toastService'
import { submit } from 'redux-form'
import store from '../../../../store'
import { selectors as authSelectors } from '../../../../store/modules/auth'

import digitalStoreSdk from '../../../../digitalStoreSdk'
import contactTypes from './contactTypes'

const NotificationsMoreMenu = (props) => {

  const dispatch = useDispatch()
  const state = store.getState()
  const organisationId = authSelectors.getUserSelectedOrganisationId(state)

  const openVigilanceContactsModal = () => {
    const formId = 'VigilanceContactsForm'
    return modalService.open({
      component: VigilanceContactsModal,
      onSubmit: onSubmitVigilanceContacts,
      modalIndex: 1,
      actions: [
        {
          text: translations('Cancel'),
          onClick: modalService.close
        },
        {
          text: translations('Save'),
          primary: true,
          success: true,
          onClick: () => {
            dispatch(submit(formId))
          }
        }
      ]
    })
  }

  const emailsStringToArray = (emailsString) => {
    return emailsString.split(',').map((email) => { return email.trim() })
  }

  const onSubmitVigilanceContacts = async (data) => {

    const contacts = contactTypes.map((type) => {
      return (
        { 
          type: type.type,
          emails: emailsStringToArray(data[type.name])
        }
      )
    })

    try{
      const result = await digitalStoreSdk.organisations.updateOrganisationContacts({ organisationId, contacts })

      if (result) {
        toastService.action({
          type: 'success',
          message: translations('Vigilance Contacts - Successfully saved'),
          autoHideDuration: 6000
        })
      } else {
        toastService.action({
          type: 'error',
          message: translations('Vigilance Contacts - Failed saved'),
          autoHideDuration: 6000
        })
      }

      modalService.close()
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations('Vigilance Contacts - Failed saved'),
        autoHideDuration: 6000
      })  
    }
  }

  const options = [
    { label: translations('Notifications More Menu - Vigilance Contacts'), onClick: openVigilanceContactsModal }
  ]

  return (
    <MoreMenu options={options} />
  )
}
export default NotificationsMoreMenu