const styles = theme => ({
  languageList: {
    marginBottom: 20
  },
  contentContainer: {
    margin: '20px 7.5%',
    display: 'flex',
    flexDirection: 'column'
  },
  blockList: {
    marginBottom: 20
  }
})

export default theme => styles(theme)
