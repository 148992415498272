import _ from 'lodash'

import colors from './configFiles/theme/colors'
import base from './configFiles/theme/base'
import text from './configFiles/theme/text'
import _theme from './configFiles/theme/theme'

const visibility = theme => ({
  hideForExtraSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  hideForSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
})

const recursivelySubstitute = (input, references) => {
  const referenceNames = Object.keys(references)
  return _.mapValues(input, value => {
    if (typeof value === 'object') {
      return recursivelySubstitute(value, references)
    } else if (typeof value === 'string') {
      let matchingReference
      referenceNames.forEach(referenceName => {
        if (value.includes(`${referenceName}.`)) {
          matchingReference = referenceName
        }
      })
      if (matchingReference) {
        return _.get(
          references[matchingReference],
          value.split('.').slice(1)
        )
      } else {
        return value
      }
    } else {
      return value
    }
  })
}

const theme = recursivelySubstitute(
  { base, ...text, ..._theme },
  { colors, base }
)

const addColorToMUITheme = colorKey => {
  const color = colors[colorKey]
  theme.palette[colorKey] = {
    main: color,
    light: color,
    dark: color
  }
}

theme.palette = {}
addColorToMUITheme('primary')
addColorToMUITheme('secondary')
addColorToMUITheme('accent')
addColorToMUITheme('warning')
theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: '2px'
    },
    contained: {
      '&:hover': {
        boxShadow: 'none'
      }
    }
  },
  MuiIconButton: {
    root: {
      '&:hover': {
        backgroundColor: 'none'
      }
    }
  },
  MuiInputLabel: {
    root: {
      '&.Mui-focused': {
        color: colors.accent
      }
    }
  },
  MuiInput: {
    underline: {
      '&:after': {
        borderBottom: colors.accent
      },
      '&.Mui-disabled:before': {
        background: 'transparent',
        backgroundSize: '6px 1px',
        backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.42) 33%, transparent 0%)',
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'bottom',
        borderBottomStyle: 'none'
      }
    }
  },
  MuiFormHelperText: {
    root: {
      minHeight: '1em',
      marginTop: '8px'
    }
  }
}

export { theme, colors, visibility }
export default theme
