import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getFormValues, submit, getFormSyncErrors } from 'redux-form'
import _ from 'lodash'
import fp from 'lodash/fp'
import { useDispatch } from 'react-redux'
import { useShallowEqualSelector } from '../../../../../hooks'

import Form from '../../../../../components/Form'
import TabbedFormModal from '../../../../../components/TabbedFormModal'
import {
  constants as reportConfigurationConstants,
  hooks as reportConfigurationHooks
} from '../../../../../store/modules/reportConfiguration'
import toastService from '../../../../../services/toastService'

import { translations } from '../../../../../config'

import { getSchema } from './schema'
import * as fieldUtils from './utils'

const FORM_NAME = reportConfigurationConstants.FIELD_FORM
const FieldForm = Form(FORM_NAME, { destroyOnUnmount: false })

const toastError = () => toastService.action({
  type: 'error',
  message: translations('Form - errors')
})

const FieldModal = (props) => {
  const { onSubmit, dismiss, fieldType, subField, editing, initialValues: propInitialValues = {}, formSchemaName, allFields, useFormBuilder = false, formErrors } = props
  const [initialValues, setInitialValues] = useState({
    ...(fieldType === 'QUESTION') && {
      field: 'Input'
    },
    ...propInitialValues
  })
  const [schemaSections, setSchemaSections] = useState({})
  const dispatch = useDispatch()
  const schemaField = reportConfigurationHooks.useSchemaField({
    fieldId: _.get(initialValues, 'id'),
    parentId: _.get(initialValues, 'fieldId'),
    formSchema: formSchemaName
  })
  const formValues = useShallowEqualSelector(getFormValues(FORM_NAME)) || {}

  const updateInitialValues = (newValues) => {
    setInitialValues(newValues)
  }

  const parsedInitialValues = useMemo(() => {
    return fp.compose(
      fieldUtils.answerMapIncoming
    )(initialValues)
  }, [initialValues])

  const handleSubmit = useCallback((formData) => {
    const optsNames = ['options', 'dropdownOptions']
    let errors = {}
    let ommittedFormErrors = {}

    const handleErrors = (name) => {
      const formErrorOptions = _.get(formErrors, name, [])
      const formDataOptions = _.get(formData, name, [])
      const nextErrorOptions = _.slice(formErrorOptions, 0, _.size(formDataOptions))
      const errors = _.every(nextErrorOptions, _.isUndefined) ? undefined : nextErrorOptions
      const ommittedFormErrors = _.omit(formErrors, optsNames)
      return {errors, ommittedFormErrors}
    }  

    _.forEach(optsNames, name => {
      const allErrors = handleErrors(name)
      errors = {...errors, ...allErrors.errors}
      ommittedFormErrors = {...ommittedFormErrors, ...allErrors.ommittedFormErrors}
    })

    const nextFormErrors = {
      ...ommittedFormErrors,
      ...(errors && errors)
    }

    if (_.size(nextFormErrors)) {
      toastError()
      return
    }

    const payload = fp.compose(
      fieldUtils.omitEmptyDropdownOptions,
      fieldUtils.answerMapOutgoing,
      fieldUtils.isLimitedToYesNoUnk,
      fieldUtils.omitByType(fieldType),
      fieldUtils.withFieldDefaults({ fieldType, editing }),
      fieldUtils.withDefaults(editing)
    )(formData)
    onSubmit({
      payload: subField ? { fields: payload } : payload
    })
    dismiss()
  }, [onSubmit, dismiss, subField, formErrors])

  const actions = useMemo(() => {
    return [
      {
        success: true,
        text: translations('Confirm'),
        primary: true,
        onClick: () => {
          dispatch(submit(reportConfigurationConstants.FIELD_FORM))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [onSubmit, dismiss])

  useEffect(() => {
    const schemaSections = getSchema({ fieldType, editing, schemaField, initialValues, formValues, allFields, updateInitialValues, useFormBuilder })
    setSchemaSections(schemaSections)
  }, [fieldType, editing, schemaField, useFormBuilder, initialValues, formValues.isLimitedToYesNoUnk, formValues.field, formValues.decimal, formValues.options])

  const title = useMemo(() => {
    let translation
    if (editing === true) {
      translation = _.get(initialValues, 'name', 'Unknown')
    } else {
      translation = translations(`Report Configuration - Modal - Add ${fieldType}`)
    }
    return translation
  }, [fieldType, editing, initialValues])

  return (
    <TabbedFormModal
      formComponent={FieldForm}
      formProps={{ enableReinitialize: true, formName: FORM_NAME }}
      handleSubmit={handleSubmit}
      actions={actions}
      schemaSections={schemaSections}
      title={title}
      initialValues={parsedInitialValues}
      tabsLabel={editing ? translations('Edit field tabs') : translations('Create field tabs')}
    />
  )
}

FieldModal.props = {
  subField: false,
  editing: false,
  fieldType: 'QUESTION',
  initialValues: {}
}

FieldModal.propTypes = {
  /** Modal actions */
  actions: PropTypes.array.isRequired,
  /** Callback when form is submitted */
  onSubmit: PropTypes.func,
  /** Closes the modal */
  dismiss: PropTypes.func,
  /** High level type of field  */
  fieldType: PropTypes.oneOf(['QUESTION', 'HEADING', 'BLOCK', 'REPEATABLE']),
  /** Indicates field is within a nested schema (repeatable) */
  subField: PropTypes.bool,
  /** Modal is editing not creating a field */
  editing: PropTypes.bool,
  /** Data to pre-populate form */
  initialValues: PropTypes.object,
  /** Name of schema the field is associated to, used to lookup field in schema  */
  formSchemaName: PropTypes.string
}

export default FieldModal
