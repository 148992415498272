import { colors } from '../../../config/theme'

export default theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4
  },
  fileName: {
    marginRight: 10,
    flexShrink: 1,
    minWidth: 0,
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  errorText: { color: colors.error }
})
