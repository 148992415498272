import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/core/styles'

import FieldBase from '../FieldBase'
import InsertButton from '../InsertButton'

import { translations } from '../../../config'
import { Droppable } from 'react-beautiful-dnd'

const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(6),
  '&:last-child': {
    marginBottom: 0
  }
}))

const Actions = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(4)
}))

const HiddenState = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  fontSize: 13,
  color: theme.palette.grey[800],
  '& > p': {
    margin: 0
  }
}))

export const FieldGroup = (props) => {
  const { fieldId, fields, visible, onEdit, onToggleVisibility, onDelete, onMove } = props
  const editMeta = { id: fieldId }
  const editProps = { subField: true }
  const droppableId = `${fieldId}`

  if (!visible) {
    return (
      <Container>
        <HiddenState variant='outlined'>
          <p>{translations('Report Configuration - Hidden Repeatable')}</p>
        </HiddenState>
      </Container>
    )
  }
  
  return (
    <Droppable droppableId={droppableId} type={'REPEATABLE'}>
      {provided => (
        <Container
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {
            _.size(fields) > 0 && _.map(fields, (field, index) => {
              const { id, isCustom, isHeading } = field
              const fieldType = isHeading ? 'HEADING' : 'QUESTION'

              const fieldPayload = {
                ...field,
                key: id,
                type: fieldType,
                onToggleVisibility: () => onToggleVisibility({ resourceType: 'FIELD', nestedId: id, id: fieldId }),
                onMove: (props) => onMove({ resourceType: 'FIELD', id: fieldId, nestedId: id, ...props }),
                onEdit: () => onEdit({ fieldType, meta: editMeta, props: { initialValues: field, ...editProps } }),
                isFirst: index === 0,
                isLast: index === (fields.length - 1),
                ...((isCustom === true || isHeading === true) && {
                  onDelete: () => onDelete({ resourceType: 'FIELD', nestedId: id, id: fieldId })
                }),
                index
              }

              return <FieldBase {...fieldPayload} />
            })
          }
          {provided.placeholder}
          < Actions >
            <Grid container spacing={2}>
              <Grid item xs>
                <InsertButton
                  onClick={() => onEdit({
                    fieldType: 'QUESTION',
                    meta: editMeta,
                    props: { ...editProps, editing: false }
                  })}
                  fullWidth
                  size='medium'>
                  {translations('Add Question')}
                </InsertButton>
              </Grid>
              <Grid item xs>
                <InsertButton
                  onClick={() => onEdit({
                    fieldType: 'HEADING',
                    meta: editMeta,
                    props: { ...editProps, editing: false }
                  })}
                  fullWidth
                  size='medium'>
                  {translations('Add Heading')}
                </InsertButton>
              </Grid>
            </Grid>
          </Actions>
        </Container >
      )}
    </Droppable>
  )
}

FieldGroup.defaultProps = {
  fields: [],
  visible: true
}

FieldGroup.propTypes = {
  /** List of fields associated to the parent field */
  fields: PropTypes.array,
  /** Is the parent field visible in the form */
  visible: PropTypes.bool,
  /** The parent field's id */
  fieldId: PropTypes.string,
  /** Method to update existing field */
  onEdit: PropTypes.func
}

export default FieldGroup
