import React from 'react'
import { Grid, } from '@material-ui/core'
import Button from '../../../../../components/Button'

const ApproveBox = (props) => {
  const {
    classes,
    onApprove,
    visible,
    disabled
  } = props

  if(!visible) {
    return(<></>)
  }

  return (
    <Grid container justify='center' alignItems='center' direction='column'>
      <Grid container className={[classes.buttonContainer, classes.approveButtonContainer]} justify='flex-end'>
        <Button className={[classes.decisionButton, classes.approve]}  disabled={disabled} onClick={onApprove}>Approve</Button>
      </Grid>
    </Grid>
  )
}

export default ApproveBox