import React, { useState } from "react"
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs"
import PropTypes from "prop-types"
import styled from "styled-components"
import media from "styled-media-query"
import _ from 'lodash'

const StyledTabs = styled(Tabs)``

/**
 * Tabs Component
 */
const TabComponent = (props) => {
  const { tabs, tabPanels, customOnChange, initialTab } = props
  const [tabIndex, setTabIndex] = useState(initialTab || 0);
  const [tabValue, setTabValue] = useState(null);
  const handleTabsChange = (index) => {
    setTabIndex(index);
    customOnChange(tabs[index])
  }

  return (
    <StyledTabs index={tabIndex} onChange={handleTabsChange}
    >
      <TabList>
        {_.map(tabs, (tab) => (
          <Tab>{tab}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {_.map(tabPanels, (panel) => (
          <TabPanel>
          <div>{panel}</div>
          </TabPanel>
        ))}
      </TabPanels>
    </StyledTabs>
  )
}

TabComponent.propTypes = {
  /** Tab Panel and content */
  tabs: PropTypes.array.isRequired,
  tabPanels: PropTypes.array,
  onChange: PropTypes.func
}

export default TabComponent
