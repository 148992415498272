import { connect } from 'react-redux'
import { compose, withHandlers, withProps } from 'recompose'
import DevicesListLookupInputModal from './DevicesListLookupInputModal'
import { selectors as deviceListSelectors, actions as devicesActions } from '../../store/modules/devicesList'

const mapStateToProps = state => ({
  results: deviceListSelectors.getDevicesForSearchResults(state),
  noResults: !deviceListSelectors.getHasResults(state),
  hasMore: deviceListSelectors.getHasMore(state) && !deviceListSelectors.getIsLoading(state),
  error: deviceListSelectors.getError(state)
})

const connectedComponent = compose(
  connect(mapStateToProps),
  withProps({
    searchPlaceholder: 'Search Devices'
  }),
  withHandlers({
    onSearch: ({ dispatch, onComplete }) => ({ query }) => {
      dispatch(devicesActions.searchDevices({ query }))
    },
    onSearchNext: ({ dispatch, onComplete }) => ({ query }) => {
      dispatch(devicesActions.searchDevicesNext({ query }))
    },
    onChange: ({ dispatch, onChange, ...props }) => (value) => {
      onChange(value)
    },
    closeModal: ({ dispatch, onClose, modalIndex }) => () => {
      onClose({ modalIndex })
      dispatch(devicesActions.resetStore())
    }
  })
)(DevicesListLookupInputModal)

export default connectedComponent
