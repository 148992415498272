import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import styles from './style'
import FullScreenProgress from '../../../components/FullScreenLoader'

const ReportDetailsScreen = ({ classes, ...props }) => {
  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Heading component='h1'>{'Details'}</Heading>
        )}
      />
      <div className={classes.contentContainer}></div>
    </div>
  )
}

export default FullScreenProgress(
  withStyles(styles)(ReportDetailsScreen)
)
