import _ from 'lodash'
import { promiseReducer } from '../../util'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class FormViewReducers {
  createFormView = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.createFormViewPending(state, action)
      case SUCCESS:
        return this.createFormViewSuccess(state, action)
      case FAIL:
        return this.createFormViewFailure(state, action)
      default:
        return state
    }
  }

  createFormViewPending = (state, action) => ({
    ...state,
    createFormViewStatus: PENDING,
    action: action.type
  })

  createFormViewSuccess = (state, action) => ({
    ...state,
    createFormViewStatus: SUCCESS,
    action: undefined,
    formView: action.result,
    error: {}
  })

  createFormViewFailure = (state, action) => ({
    ...state,
    createFormViewStatus: FAIL,
    error: action.error
  })

  createFormVersion = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.createFormVersionPending(state, action)
      case SUCCESS:
        return this.createFormVersionSuccess(state, action)
      case FAIL:
        return this.createFormVersionFailure(state, action)
      default:
        return state
    }
  }

  createFormVersionPending = (state, action) => ({
    ...state,
    createFormViewStatus: PENDING,
    action: action.type
  })

  createFormVersionSuccess = (state, action) => ({
    ...state,
    createFormVersionStatus: SUCCESS,
    action: undefined,
    formVersion: action.result,
    error: {}
  })

  createFormVersionFailure = (state, action) => ({
    ...state,
    createFormVersionStatus: FAIL,
    error: action.error
  })

  updateFormVersion = promiseReducer(
    (state, action) => {
      const updateFormView = () => {
        const { formViewId } = action.result
        const currentFormView = _.get(state, 'formViewById')
        const currentFormViewId = _.get(currentFormView, 'id')
        const newFormViewById = _.cloneDeep(currentFormView)
        if (formViewId === currentFormViewId) {
          _.set(newFormViewById, 'formViewVersions', [{ ...action.result }])
        }
        return newFormViewById
      }

      return {
        ...state,
        viewJSON: action.result.viewJSON,
        formViewById: updateFormView()
      }
    }
  )

  fetchFormViewById = promiseReducer(
    (state, action) => ({
      ...state,
      formViewById: action.result,
      viewJSON: action.result.formViewVersions[0].viewJSON
    })
  )


  fetchAllFormViews = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllFormViewsPending(state, action)
      case SUCCESS:
        return this.fetchAllFormViewsSuccess(state, action)
      case FAIL:
        return this.fetchAllFormViewsFail(state, action)
      default:
        return state
    }
  }
  fetchAllFormViewsPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchAllFormViewsSuccess = (state, action) => {
    return {
      ...state,
      results: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchAllFormViewsFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }
  updateFormView = promiseReducer(
    (state, action) => {
      return {
        ...state,
        formViewById: { ...state.formViewById, ...action.result }
      }
    }
  )

  setViewJSON = (state, action) => {
    const { values } = action
    return {
      ...state,
      viewJSON: values
    }
  }

  setNotEditableJSON = (state, action) => {
    const { values } = action
    return {
      ...state,
      notEditable: values
    }
  }

  clearNotEditableJSON = (state) => {
    return {
      ...state,
      notEditable: null
    }
  }

  setNewSchemaBase = (state, action) => {
    const { values } = action
    return {
      ...state,
      formViewById: undefined,
      newSchemaBase: values
    }
  }
  selectReportType = (state, action) => {
    const { values } = action
    return {
      ...state,
      selectedReportType: values
    }
  }
  resetBaseSchema = (state, action) => {
    return {
      ...state,
      newSchemaBase: null
    }
  }

  generateReportConfigurationTranslationsCSV = (state, action) => {
    return {
      ...state,
      translationsCSV: action.status
    }
  }

  configureField = (state, action) => {
    const { viewJSON, nestedSections, details } = action.values
    const clonedViewJSON = _.cloneDeep(viewJSON)
    const isForThromboticEvents = _.get(details, 'isForThromboticEvents')
    const isForMyocarditis = _.get(details, 'isForMyocarditis')
    const disableDefaultMapping = _.get(details, 'disableDefaultMapping', [])
    const dropdownOptions = _.get(details, 'dropdownOptions')

    const sectionIndexs = this.recurseSection({ viewJSON: clonedViewJSON, nestedSections })
    const sectionPath = sectionIndexs.join('.')

    const field = _.get(clonedViewJSON, sectionPath)
    const mergedDetails = _.merge(field, details)
    _.set(mergedDetails, 'disableDefaultMapping', disableDefaultMapping)

    /**
     * `merge` is incorrect for `dropdownOptions` therefore it should be replaced
     */
    if (_.size(dropdownOptions) > 0) {
      _.set(mergedDetails, 'dropdownOptions', dropdownOptions)
    } else {
      _.unset(mergedDetails, 'dropdownOptions')
    }

    if (_.get(mergedDetails, 'isLimitedToYesNoUnk') === false) {
      _.set(mergedDetails, 'answerMap', {})
      _.set(mergedDetails, 'disableDefaultMapping', [])
    }

    _.set(clonedViewJSON, sectionPath, mergedDetails)

    if (_.isBoolean(isForThromboticEvents)) {
      const conditions = isForThromboticEvents ? {
        any: [
          {
            fact: 'relateToThromboticEvents',
            value: '1',
            operator: 'equal'
          }
        ]
      } : {}
      _.set(clonedViewJSON, `${sectionPath}.conditions`, conditions)
    }

    if (_.isBoolean(isForMyocarditis)) {
      const conditions = isForMyocarditis ? {
        any: [
          {
            fact: 'relateToMyocarditis',
            value: '1',
            operator: 'equal'
          }
        ]
      } : {}
      _.set(clonedViewJSON, `${sectionPath}.conditions`, conditions)
    }

    return {
      ...state,
      viewJSON: clonedViewJSON
    }
  }

  toggleVisibility = (state, action) => {
    const { viewJSON, visible, nestedSections } = action.values
    const sectionIndexs = this.recurseSection({ viewJSON, nestedSections })
    _.set(viewJSON, `${sectionIndexs.join('.')}.visible`, !visible)
    return {
      ...state,
      viewJSON: viewJSON
    }
  }

  moveQuestion = (state, action) => {
    const { viewJSON, nestedSections, questionKey, direction } = action.values
    const sectionIndexs = this.recurseSection({ viewJSON, nestedSections })
    const section = _.get(viewJSON, `${sectionIndexs.join('.')}.fields`)
    const moveTo = direction === 'up' ? questionKey - 1 : questionKey + 1
    const current = section.splice(questionKey, 1)[0]
    section.splice(moveTo, 0, current);
    return {
      ...state,
      viewJSON
    }
  }

  moveQuestionToSection = (state, action) => {
    const { viewJSON, questionKey, nestedSections, moveToSections, isHeading } = action.values
    const sectionIndexs = this.recurseSection({ viewJSON, nestedSections })
    const moveToSectionIndexs = this.recurseSection({ viewJSON, nestedSections: moveToSections })
    const section = _.get(viewJSON, `${sectionIndexs.join('.')}.fields`)
    const moveToSection = _.get(viewJSON, `${moveToSectionIndexs.join('.')}.fields`)
    const current = section.splice(questionKey, 1)[0]
    if (isHeading) {
      moveToSection.unshift(current)
    } else {
      moveToSection.push(current)
    }
    return {
      ...state,
      viewJSON
    }
  }

  addQuestion = (state, action) => {
    const { viewJSON, name, visible, section, heading } = action.values
    const currentState = viewJSON
    const newField = {
      id: generateSchemaItemIdForCustomItems({ prefix: heading ? 'heading' : '', name }),
      name,
      visible,
      ...heading ? { isHeading: true } : { isCustom: true }
    }
    _.forEach(currentState, field => {
      if (field.id === section) {
        const fields = _.get(field, 'fields')
        if (heading) {
          fields.unshift(newField)
        } else {
          fields.push(newField)
        }
      }
    })
    return {
      ...state,
      viewJSON: currentState
    }
  }

  deleteQuestion = (state, action) => {
    const { viewJSON, sectionKey, questionKey } = action.values
    const currentState = viewJSON
    const getSectionKey = currentState[sectionKey]
    const getFields = _.get(getSectionKey, 'fields')
    getFields.splice(questionKey, 1)
    return {
      ...state,
      viewJSON: currentState
    }
  }

  editRepeatables = (state, action) => {
    const { viewJSON, fields, nestedSections } = action.values
    const sectionIndexs = this.recurseSection({ viewJSON, nestedSections })
    _.set(viewJSON, `${sectionIndexs.join('.')}.fields`, fields)
    return {
      ...state,
      viewJSON
    }
  }

  addSection = (state, action) => {
    const { viewJSON, sectionName } = action.values
    const currentState = viewJSON
    const newSection = {
      'id': generateSchemaItemIdForCustomItems({ name: sectionName }),
      'name': sectionName,
      'isCustom': true,
      'visible': true,
      'fields': []
    }
    currentState.push(newSection)
    return {
      ...state,
      viewJSON: currentState
    }
  }

  deleteSection = (state, action) => {
    const { viewJSON, sectionKey } = action.values
    const currentState = viewJSON
    currentState.splice(sectionKey, 1)
    return {
      ...state,
      viewJSON: currentState
    }
  }


  recurseSection = ({ viewJSON, nestedSections }) => {
    let sectionIndexs = []
    _.forEach(nestedSections, (section, index) => {
      const currentSection = _.size(sectionIndexs) ? _.get(viewJSON, _.join(sectionIndexs, '.')) : viewJSON
      const sectionIndex = _.findIndex(currentSection, item => item.id === section)
      const determineSuffix = nestedSections.length - 1 === index ? '' : '.fields'
      sectionIndexs.push(`${sectionIndex}${determineSuffix}`)
    })
    return sectionIndexs
  }
  setFormViewSchemaUpdated = (state) => {
    return {
      ...state,
      formSchemaUpdated: true
    }
  }
  setFormViewSchemaUpdateSaved = (state) => {
    return {
      ...state,
      formSchemaUpdated: false
    }
  }
}

/**
 * Create a random ID to append to the end of IDs.
 *
 * Defaults to 3 characters as thats around 7,140 unique combinations.
 *
 * @param {object} options
 * @param {number} [options.length = 3]
 */
const generateRandomIdSuffix = ({ length = 3 } = {}) => _.sampleSize('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789', length).join('')
/**
 * @description Generate a ID with some basic collision protection.
 * @example
 * ```javascript
 * generateRandomIdSuffix({ name: 'First Name?' }) // 'firstName--X5D'
 * generateRandomIdSuffix({ prefix: 'heading' name: 'Or' }) // 'heading-or--JN2'
 * ```
 * @param {object} params
 * @param {string?} params.prefix A given prefix like `'heading'`.
 * @param {string} params.name Given name of the field to use for camelcasing the body of the ID.
 */
const generateSchemaItemIdForCustomItems = ({ prefix = '', name = '' }) => {
  /**
   * typical form schema ID
   */
  const id = _.camelCase(name)
  /**
   * No prefixing if not provided by arguments.
   */
  const idWithMaybePrefix = _.chain([prefix, id])
    .compact()
    .join('-')
    .value()
  const suffix = generateRandomIdSuffix()
  return `${idWithMaybePrefix}--${suffix}`
}
export default new FormViewReducers()
