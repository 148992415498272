import React, { createContext, useState, useMemo, useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import fp from 'lodash/fp'

import { useQuery } from '../../../../../../../hooks'
import { selectors as formSchemaSelectors } from '../../../../../../../store/modules/formSchemas'

const initialState = {
  fields: [],
  fieldLookup: _.noop
}

const fieldsStore = createContext(initialState)
const { Provider } = fieldsStore

const FieldProvider = ({ children }) => {
  const { formSchemaId } = useQuery()
  const formSchema = useSelector(formSchemaSelectors.getSchemaById(formSchemaId))
  const formSchemaFields = _.get(formSchema, 'fields', [])
  const formSchemaDefaultViewSections = _.get(formSchema, 'defaultView.sections', [])
  const sectionLookup = fp.compose(
    fp.mergeAll,
    fp.map((section) => {
      const { id: sectionId, fields } = section
      let acc = {}
      for (const field of fields) {
        const fieldId = _.get(field, 'id')
        const internalFields = _.get(field, 'fields', [])
        acc[fieldId] = sectionId
        for (const internalField of internalFields) {
          const internalFieldId = _.get(internalField, 'id')
          acc[internalFieldId] = fieldId
        }
      }
      return acc
    }
    )
  )(formSchemaDefaultViewSections)

  const objectifiedFields = useMemo(() => {
    const fieldsObject = {}
    for (const schemaField of formSchemaFields) {
      const fieldId = _.get(schemaField, 'id')
      // prevent processing further if no id
      if (!fieldId) {
        break
      }
      _.set(fieldsObject, fieldId, {
        ...schemaField,
        id: fieldId
      })
      // can either be 'schema' if from formSchema or 'fields' if from formView
      // NOTE: only 'schema' is currently being used
      const internalFields = _.get(schemaField, 'props.schema') || _.get(schemaField, 'fields', [])
      for (const internalField of internalFields) {
        const internalFieldId = _.get(internalField, 'id')
        // prevent processing further if no id
        if (!internalFieldId) {
          break
        }
        const combinedPath = `${fieldId}.${internalFieldId}`
        fieldsObject[combinedPath] = {
          ...internalField,
          fieldId
        }
      }
    }
    return fieldsObject
  }, [formSchemaFields])

  const fields = useMemo(() => {
    const getFieldMeta = (field) => {
      const schemaType = _.get(field, 'field')
      const isOptionsEmpty = fp.compose(
        fp.isEmpty,
        fp.compact,
        fp.get('props.options')
      )(field)
      return [schemaType, isOptionsEmpty]
    }
    const getEditorType = (field) => {
      const [schemaType, isOptionsEmpty] = getFieldMeta(field)
      switch (schemaType) {
        case 'Dropdown':
        case 'Checklist':
        case 'Radio':
          if (!isOptionsEmpty) {
            return 'select'
          }
          return 'text'
        default:
          return 'text'
      }
    }
    const getInputType = (field) => {
      const [schemaType, isOptionsEmpty] = getFieldMeta(field)
      switch (schemaType) {
        case 'Date':
          return 'date'
        case 'Dropdown':
        case 'Checklist':
        case 'Radio':
        case 'Radiolist':
          if (!isOptionsEmpty) {
            return 'select'
          }
          return 'text'
        default:
          return 'text'
      }
    }
    const getOptions = (field) => {
      const options = _.get(field, 'props.options', [])
      // just return undefined if no options
      if (_.isEmpty(options)) {
        return undefined
      }
      const mappedOptions = _.map(options, (option) => {
        if (_.isPlainObject(option)) {
          const { value, label } = option
          return {
            name: value,
            label
          }
        }
        return {
          name: option,
          label: option
        }
      })
      return mappedOptions
    }
    const createEditorField = (field) => {
      const currentFieldId = _.get(field, 'id')
      const parentId = _.get(field, 'fieldId')
      const sectionId = sectionLookup[currentFieldId]
      const parentDisplayName = _.get(objectifiedFields, `${parentId}.props.name`) || sectionId
      const options = getOptions(field)
      const fieldId = _.compact([parentId, currentFieldId])
      const nextField = _.pickBy({
        ...field,
        id: _.join(fieldId, '.'),
        name: _.join(fieldId, '.'),
        label: _.get(field, 'props.label'),
        valueEditorType: getEditorType(field),
        inputType: getInputType(field),
        parentId,
        parentDisplayName,
        options,
        values: options
      }, _.identity)
      return nextField
    }

    const excludedFields = ['susarEditable', 'spontaneousEditable']
    const mappedFields = fp.compose(
      fp.map(createEditorField),
      fp.pickBy((value) => value !== 'false'),
      fp.pickBy(fp.identity),
      fp.filter((field) => !fp.includes(fp.get('id', field), excludedFields))
    )(objectifiedFields)
    return mappedFields
  }, [objectifiedFields, sectionLookup])

  const fieldLookup = useCallback(({ field, value, operator }) => {
    const matchedField = _.get(objectifiedFields, { id: field }) || {}

    const getValue = () => {
      let mappedValue = value
      const options = _.get(matchedField, 'props.options') || _.get(matchedField, 'values')

      if (options) {
        const matchedOption = _.find(options, { value }) || _.find(options, { name: value })
        mappedValue = _.get(matchedOption, 'label', value)
      }
      if (_.isArray(value)) {
        const isDate = operator === 'isBetween' || operator === 'isNotBetween'
        if (isDate) {
          mappedValue = _.join(value, ' - ')
        } else if (_.every(value, (value) => typeof value == 'string')) {
          mappedValue = _.join(value, ', ')
        } else {
          mappedValue = _.join(_.map(value, ({ label }) => label), ', ')
        }
      }
      return mappedValue
    }

    return {
      friendlyName: _.get(matchedField, 'displayName', _.get(matchedField, 'label', field)),
      friendlyValue: getValue()
    }
  }, [objectifiedFields])

  return (
    <Provider
      value={{
        fields,
        fieldLookup
      }}>
      {children}
    </Provider>
  )
}

export const useFields = () => useContext(fieldsStore)

// eslint-disable-next-line import/no-anonymous-default-export
export default { FieldProvider, useFields }
