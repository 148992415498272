import _ from 'lodash'
import { Base64 } from 'js-base64'
import fileDownload from 'js-file-download'

class FSService {
  downloadFile = ({ data, fileName, mimeType }) => {
    if (window.cordova) {
      return this.writeFileToDevice({ data, fileName, mimeType })
        .then(filePath => this.openFile({ filePath, mimeType }))
    } else {
      fileDownload(data, fileName)
      return Promise.resolve()
    }
  }

  downloadFileFromURL = url => {
    if (window.cordova) {
      
    } else {
      return window.open(url)
    }
  }

  writeFileToDevice = ({ data, fileName, mimeType }) => {
    // https://stackoverflow.com/questions/43575581/cordova-download-a-file-in-download-folder
    // refactored into promises to escape callback hell

    return new Promise((resolve, reject) => {
      const writeOpts = { create: true, exclusive: false }

      const getStorageLocation = () => {
        switch (window.device.platform) {
          case 'Android':
            return 'file:///storage/emulated/0/'
          case 'iOS':
            return window.cordova.file.documentsDirectory
        }
      }

      const writeFile = (fileEntry) => {
        return new Promise((resolve, reject) => {
          fileEntry.createWriter(
            (writer) => {
              const blob = new Blob([data], { type: mimeType })
              writer.onwriteend = () => {
                resolve(fileEntry.nativeURL)
              }
              writer.seek(0)
              writer.write(blob)
            },
            reject
          )
        })
      }

      const getFile = (directory) => {
        return new Promise((resolve, reject) => {
          directory.getFile(fileName, writeOpts, resolve, reject)
        })
      }

      const getDirectory = (fileSystem) => {
        return new Promise((resolve, reject) => {
          fileSystem.getDirectory('Download', writeOpts, resolve, reject)
        })
      }

      window.resolveLocalFileSystemURL(
        getStorageLocation(),
        (fileSystem) => {
          getDirectory(fileSystem)
            .then(getFile)
            .then(writeFile)
            .then(resolve)
            .catch(reject)
        }
      )
    })
  }

  shareFile = ({ filePath }) => {
    return new Promise((resolve, reject) => {
      const shareFn = _.get(window, 'plugins.socialsharing.shareWithOptions')
      if (!shareFn) reject(new Error('socialsharing plugin not found'))

      const options = { files: [filePath] }
      shareFn(options, resolve, reject)
    })
  }

  openFile = ({ filePath, mimeType }) => {
    return new Promise((resolve, reject) => {
      const fnName = window.device.platform === 'Android' ? 'showOpenWithDialog' : 'open'
      const openFn = _.get(window, `cordova.plugins.fileOpener2.${fnName}`)
      if (!openFn) reject(new Error('fileOpener2 plugin not found'))

      openFn(filePath, mimeType, { success: resolve, error: reject })
    })
  }

  loadBlob = (localUrl) => {
    if (window.cordova) {
      return this._loadBlobCordova(localUrl)
    } else {
      return this._loadBlobWeb(localUrl)
    }
  }

  _loadBlobWeb = (localUrl) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', localUrl, true)
      xhr.responseType = 'blob'
      xhr.onload = () => resolve(xhr.response)
      xhr.onerror = () => reject(xhr.statusText)
      xhr.send()
    })
  }

  _loadBlobCordova = (localUrl) => {
    return new Promise((resolve, reject) => {
      window.resolveLocalFileSystemURL(localUrl, (fileEntry) => {
        fileEntry.file((fileObj) => {
          var reader = new FileReader()
          reader.onloadend = (e) => {
            const blob = new Blob([new Uint8Array(e.target.result)], { type: fileObj.type })
            resolve(blob)
          }
          reader.readAsArrayBuffer(fileObj)
        })
      })
    })
  }
}

export default new FSService()
