import { withHandlers } from 'recompose'

import PhoneIcons from './PhoneIcons'

export default withHandlers({
  onClickCall: ({ value }) => () => {
    const href = `tel:${value}`
    window.location.href = href
  }
})(PhoneIcons)
