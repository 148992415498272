import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Form, { FormBody, FormSubmit, FormError } from '../../../../../components/Form'
import { translations } from '../../../../../config'
import * as validators from '../../../../../validators'
import style from './style'

export const newFeedFormName = 'feed'
const FeedForm = Form(newFeedFormName)

const NewFeedForm = ({ classes, error, handleSubmit, languageOptions }) => {
  const schema = [
    {
      id: 'url',
      field: 'Input',
      props: {
        label: 'RSS Feed Url',
        required: true,
        validate: [validators.url]
      }
    },
    {
      id: 'languageId',
      field: 'Dropdown',
      props: {
        label: 'RSS Feed Language',
        options: languageOptions,
        autocomplete: true,
        required: true
      }
    },
    {
      id: 'source',
      field: 'Input',
      props: {
        label: 'Source',
        required: true
      }
    }
  ]

  return (
    <div>
      <FormError>{error}</FormError>
      <FeedForm editing onSubmit={handleSubmit}>
        <FormBody schema={schema} />
        <FormSubmit
          containerClass={classes.submitWrapper}
          className={classes.submit}
        >{translations('Submit')}</FormSubmit>
      </FeedForm>
    </div>
  )
}

export default withStyles(style)(NewFeedForm)
