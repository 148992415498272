import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../config'

import style from './style'

const UsersRowIcon = ({ accountDeactivated, deactivatedIcon, classes }) => {
  if (accountDeactivated) {
    return (
      <div className={classes.iconContainer}>
        <IconButton aria-label={translations('Account Deactivated')}><img src={deactivatedIcon} alt='' /></IconButton>
      </div>
    )
  } else {
    return null
  }
}

export default withStyles(style)(UsersRowIcon)
