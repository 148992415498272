import _ from 'lodash'
import { createSelector } from 'reselect'
import { reportShareUrls } from '../../../containers/Login/constants'

export const getIsMenuOpen = state => state.app.isMenuOpen
export const getIsBasketOpen = state => state.app.isBasketOpen
export const appReady = state => state.app.ready
export const getApiFailStatus = state => state.app.apiFail
export const getClientBookTab = state => state.app.clientBookTab
export const getSnapshotMetricTab = state => state.app.snapshotMetricTab
export const getSnapshotCarouselTab = state => state.app.snapshotCarouselTab

export const getLocationPathname = state => _.get(state.router, 'location.pathname', '')

export const getIsUnsecuredPage = createSelector(
  getLocationPathname,
  pathname => {
    const unsecuredUrls = [
      '/login',
      '/forgot-password',
      '/reset-password',
      '/select-store',
      '/select-department',
      '/select-organisation',
      '/migration',
      '/account/request'
    ]

    return !!unsecuredUrls.includes(pathname) || !pathname
  }
)

export const getIsPublicPage = createSelector(
  getLocationPathname,
  pathname => {
    const publicUrls = ['/reports/new/public', '/users/new/public']
    return !!publicUrls.find(url => pathname.startsWith(url))
  }
)

export const getIsAssessorsPage = createSelector(
  getLocationPathname,
  pathname => {
    const selectOrgnisationUrl = '/select-organisation'
    const assessorUrls = reportShareUrls.concat(selectOrgnisationUrl)
    return _.some(assessorUrls, (url) => pathname.startsWith(url))
  }
)
