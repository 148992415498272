import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles'

import AddIcon from '@material-ui/icons/AddCircleOutline'

const StyledButton = styled(Button)(({ theme, size }) => ({
  border: `2px dashed ${theme.palette.grey['300']}`,
  background: theme.palette.grey['100'],
  transition: '.3s ease-in-out',
  color: theme.palette.grey['800'],
  borderRadius: 5,
  '&:hover, &:active, &:focus': {
    borderColor: theme.palette.grey['600'],
    color: theme.palette.grey['900']
  },
  ...(size === 'medium' && {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }),
  ...(size === 'large' && {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  })
}))

/**
 * Subtle button for inserting an element within the context the button has been placed
 */
export const InsertButton = (props) => {
  const { children, size, onClick, disabled, fullWidth } = props

  return (
    <StyledButton
      variant='outlined'
      color='default'
      startIcon={<AddIcon />}
      size={size}
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {children}
    </StyledButton>
  )
}

InsertButton.defaultProps = {
  size: 'medium',
  disabled: false,
  fullWidth: false
}

InsertButton.propTypes = {
  /** Content of the button */
  children: PropTypes.any.isRequired,
  /** Function to call when button is clicked */
  onClick: PropTypes.func.isRequired,
  /** Size of the button */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Prevent any input from the user */
  disabled: PropTypes.bool,
  /** If the button should fill the container */
  fullWidth: PropTypes.bool
}

export default InsertButton
