import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledWizard = styled.div``

/**
 * Manage visible steps within a form
 */
const FormWizard = (props) => {
  const { children, index } = props
  const currentPage = React.Children.toArray(children)[index]

  return (
    <StyledWizard>
      {currentPage}
    </StyledWizard>
  )
}

FormWizard.propTypes = {
  /** Each child is a new step */
  children: PropTypes.array.isRequired,
  /** The current step */
  index: PropTypes.number 
}

FormWizard.defaultProps = {
  index: 0
}

export default FormWizard
