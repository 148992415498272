export const mergeDynamicTheme = ({ theme, dynamicTheme }) => {
  let newTheme = { ...theme }

  const {
    footerLogo,
    primaryColor,
    primaryContrastColor,
    headerBackgroundColor,
    headerContrastColor
  } = dynamicTheme || {}

  newTheme.footerHeight = footerLogo ? newTheme.footerHeight : 0

  if (dynamicTheme) {
    newTheme.palette.primary.main = primaryColor
    newTheme.palette.primary.light = primaryColor
    newTheme.palette.primary.dark = primaryColor
    newTheme.palette.primary.contrastText = primaryContrastColor
    newTheme.header.backgroundColor = headerBackgroundColor
    newTheme.header.textColor = headerContrastColor
  }

  return newTheme
}
