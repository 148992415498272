import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import styled from 'styled-components'
import media from 'styled-media-query'

import VisuallyHidden from '@reach/visually-hidden'

import Label from '../Label'
import Field from '../Field'
import Hint from '../Hint'
import ErrorMessage from '../ErrorMessage'
import NullFlavourField from '../NullFlavourField'

import {
  StyledSelect,
  SelectBoxWrapper,
  SelectArrowIconWrapper,
  SelectArrowIcon
} from '../SelectBox/SelectBox'

import { translation } from '../../services/translations'
import { getComputedRequiredFieldInvalid } from '../../helpers'

import { useInputType } from '../../hooks'
import { getYears } from '../../helpers'
import { getDays, getMonths, parseDate, stringifyDate } from './functions'

const SelectBoxRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 8px;

    gap: 10px;
    ${media.greaterThan('small')`
    gap: 15px;
  `}
`

const SelectBoxColumn = styled.div``

const DateSelect = styled(StyledSelect)`
  min-width: ${props => props.big ? '120px' : '80px'};
  &:disabled {
    cursor: not-allowed;
  }
  background: transparent;
`

const PickerInput = styled.input`
  border: none;
  box-sizing: border-box;
  outline: 0;
  width: 30px;
  height: 30px;
  color: transparent;
  position: relative;
  background: transparent;
  &:disabled {
    cursor: not-allowed;
  }
  
  &::-webkit-calendar-picker-indicator {
    background: transparent;
    cursor: pointer;
    bottom: 0;
    height: 30px;
    left: 0;
    right: 0;
    top: 0;
    width: 30px;
    position: absolute;
  }
`

const IconWrapper = styled.div`
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  ${media.greaterThan('small')`
    margin-left: 15px;
    margin-right: 15px;
  `}
`

const CalendarIcon = styled.div`
    background-image: ${(props) => `url(${props.theme.icons.calendarRange})`};
    background-position: center center;
    background-size: 30px;
    background-repeat: no-repeat;
    width: inherit;
    height: inherit;
`

const RowCenter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const IconLabel = styled.label`
  height: 30px;
  width: 30px;

  display: flex;
  position: absolute;
`

const DateSelectBox = ({
  id,
  required,
  fieldInvalid,
  label,
  disabled,
  value,
  big,
  errorMessage,
  options,
  onDateChange,
  handleFocus,
  handleBlur
}) => (
  <SelectBoxWrapper>
    <DateSelect
      id={id}
      aria-required={required}
      aria-invalid={fieldInvalid}
      label={label}
      disabled={disabled}
      as={'select'}
      value={value}
      big={big}
      error={errorMessage}
      onChange={onDateChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {options}
    </DateSelect>
    <SelectArrowIconWrapper>
      <SelectArrowIcon />
    </SelectArrowIconWrapper>
  </SelectBoxWrapper>
)

const DateInput = (props) => {
  const {
    input: { name, value, onChange, onFocus, onBlur },
    meta: { error, touched, active },
    id,
    label,
    hint,
    required,
    disabled,
    startYear,
    startDate,
    endDate,
    acceptedDateFormats,
    endYearOffset,
    nullFlavours
  } = props

  const isBrowserCompatible = useInputType('date')
  const fieldInvalid = getComputedRequiredFieldInvalid({required, error, touched})
  const errorMessage = fieldInvalid && error
  const allowFullDate = !acceptedDateFormats?.length || acceptedDateFormats.includes('YYYYMMDD')
  const valueParts = parseDate(value)
  const startDateParts = parseDate(startDate)
  const endDateParts = parseDate(endDate)

  const onDateChange = useCallback(
    (dateLabel) => (event) => {
      const nextDate = stringifyDate(event, value, dateLabel)

      onChange && onChange(nextDate)
      onBlur && onBlur()
    },
    [value, onChange, onBlur]
  )

  const onDatePicked = useCallback(
    (event) => onChange && onChange(_.replace(event.target.value, /-/gi, '')),
    [onChange]
  )

  const handleFocus = useCallback(
    (params) => {
      !active && onFocus(params)
    },
    [active, onFocus]
  )

  // Prevents onBlur from being called when the new target is an inner/child div
  const handleBlur = useCallback(
    (event) => {
      if (!event.currentTarget.parentNode.parentNode.contains(event.relatedTarget)) {
        onBlur()
      }
    },
    [onBlur]
  )

  const hasNullFlavours = nullFlavours && !_.isEmpty(nullFlavours)

  const renderDateComponent = () => {
    return (
      <Field as={'fieldset'} error={errorMessage} tabIndex={error ? '-1' : ''} name={name}>
        <VisuallyHidden>
          <legend>{label}</legend>
        </VisuallyHidden>

        <Label as='h3' required={required}>
          {label}
        </Label>
        {errorMessage && <ErrorMessage error={errorMessage} />}
        {hint && <Hint message={hint} />}
        <SelectBoxRow>
          <SelectBoxColumn>
            <Label name={`${id}_year`} nested required>
              {translation('Year')}
            </Label>
            <DateSelectBox
              id={`${id}_year`}
              required={required}
              fieldInvalid={fieldInvalid}
              label='year'
              big
              disabled={disabled}
              value={valueParts.year}
              errorMessage={errorMessage}
              options={[
                'YYYY',
                ...getYears(startYear || startDateParts.year, endYearOffset, endDateParts.year)
              ].map((opt) => {
                return (
                  <option key={`${opt}`} value={opt !== 'YYYY' ? opt : ''}>
                    {opt}
                  </option>
                )
              })}
              onDateChange={onDateChange('year')}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
            />
          </SelectBoxColumn>
          {(allowFullDate || acceptedDateFormats.includes('YYYYMM')) && (
            <SelectBoxColumn>
              <Label name={`${id}_month`} nested required>
                {translation('Month')}
              </Label>
              <DateSelectBox
                id={`${id}_month`}
                required={required}
                fieldInvalid={fieldInvalid}
                label='month'
                disabled={disabled}
                value={valueParts.month}
                errorMessage={errorMessage}
                options={['MM', ...getMonths(valueParts.year, startDateParts, endDateParts)].map(
                  (opt) => {
                    return (
                      <option key={`${opt}`} value={opt !== 'MM' ? opt : ''}>
                        {opt}
                      </option>
                    )
                  }
                )}
                onDateChange={onDateChange('month')}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
              />
            </SelectBoxColumn>
          )}
          {allowFullDate && (
            <SelectBoxColumn>
              <Label name={`${id}_day`} nested required>
                {translation('Day')}
              </Label>
              <RowCenter>
                <DateSelectBox
                  id={`${id}_day`}
                  required={required}
                  fieldInvalid={fieldInvalid}
                  label='day'
                  disabled={disabled}
                  value={valueParts.day}
                  errorMessage={errorMessage}
                  options={[
                    'DD',
                    ...getDays(valueParts.month, valueParts.year, startDateParts, endDateParts)
                  ].map((opt) => {
                    return (
                      <option key={`${opt}`} value={opt !== 'DD' ? opt : ''}>
                        {opt}
                      </option>
                    )
                  })}
                  onDateChange={onDateChange('day')}
                  handleFocus={handleFocus}
                  handleBlur={handleBlur}
                />
                {isBrowserCompatible && !disabled && (
                  <IconWrapper>
                    <IconLabel htmlFor='dateselect-calendar'>
                      <CalendarIcon />
                    </IconLabel>
                    <PickerInput
                      id='dateselect-calendar'
                      type='date'
                      onChange={onDatePicked}
                      disabled={disabled}
                      min={
                        startDate &&
                        `${startDateParts.year}-${startDateParts.month}-${startDateParts.day}`
                      }
                      max={
                        endDate && `${endDateParts.year}-${endDateParts.month}-${endDateParts.day}`
                      }
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      tabIndex='-1'
                    />
                  </IconWrapper>
                )}
              </RowCenter>
            </SelectBoxColumn>
          )}
        </SelectBoxRow>
      </Field>
    )
  }

  if (hasNullFlavours && !disabled) {
    return <NullFlavourField {...props} render={renderDateComponent} />
  }

  return renderDateComponent()
}

DateInput.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** If the field is disabled */
  disabled: PropTypes.bool,
  /** Year to start the year dropdown from */
  startYear: PropTypes.number,
  /** Number of years to offset the maximum year dropdown selection by */
  endYearOffset: PropTypes.number,
  /** Input props based from React Final Form
   *
   * `name` - Used to associate label and input
   *
   * `value` - Value of the input
   *
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   *
   * `onFocus` - Function called when focus has been given to the field
   */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  /** Meta props based from React Final Form
   *
   * `error` - Field validation message
   *
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
   */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
}

DateInput.defaultProps = {
  required: false,
  input: {},
  meta: {}
}

export default DateInput
