import { connect } from 'react-redux'
import { compose, withHandlers, withProps } from 'recompose'
import DrugLookupInputModal from './DrugLookupInputModal'
import { selectors as drugDictionarySelectors, actions as drugDictionaryActions } from '../../store/modules/drugDictionary'

const mapStateToProps = state => ({
  results: drugDictionarySelectors.getDrugsForSearchResults(state),
  noResults: !drugDictionarySelectors.getHasResults(state),
  hasMore: drugDictionarySelectors.getHasMore(state) && !drugDictionarySelectors.getIsLoading(state),
  error: drugDictionarySelectors.getError(state)
})

const mapDispatchToProps = dispatch => ({
  searchDrugDictionary: ({ query, lookupOptions }) => dispatch(drugDictionaryActions.searchDrugDictionary({ query, lookupOptions })),
  searchDrugDictionaryNext: ({ query, lookupOptions }) => dispatch(drugDictionaryActions.searchDrugDictionaryNext({ query, lookupOptions })),
  resetDrugDictionary: () => dispatch(drugDictionaryActions.resetStore())
})

const connectedComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps({
    searchPlaceholder: 'Search Drugs Dictionary'
  }),
  withHandlers({
    onSearch: ({ onComplete, searchDrugDictionary, lookupOptions }) => ({ query }) => {
      searchDrugDictionary({ query, lookupOptions})
    },
    onSearchNext: ({ onComplete, searchDrugDictionaryNext, lookupOptions }) => ({ query }) => {
      searchDrugDictionaryNext({ query, lookupOptions })
    },
    onChange: ({ onChange, ...props }) => (value) => {
      onChange(value)
    },
    closeModal: ({ onClose, resetDrugDictionary, modalIndex }) => () => {
      onClose({ modalIndex })
      resetDrugDictionary()
    }
  })
)(DrugLookupInputModal)

export default connectedComponent
