import { _ } from 'core-js'
import { SUCCESS, PENDING, FAIL } from '../../middleware/redux-promise'

class DeveloperReducers {
  updateDefaultApplication = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.updateDefaultApplicationPending(state, action)
      case SUCCESS:
        return this.updateDefaultApplicationSuccess(state, action)
      case FAIL:
        return this.updateDefaultApplicationFail(state, action)
      default:
        return state
    }
  }
  updateDefaultApplicationPending = (state) => {
    return {
      ...state,
      applications: {
        ...state.applications.results,
        ...state.application,
        default: {
          error: undefined,
          status: PENDING
        }
      }
    }
  }
  updateDefaultApplicationSuccess = (state, action) => {
    return {
      ...state,
      applications: {
        ...state.applications.results,
        ...state.applications,
        default: {
          application: action.result,
          error: undefined,
          status: SUCCESS
        }
      }
    }
  }
  updateDefaultApplicationFail = (state, action) => {
    const { error } = action
    return {
      ...state,
      applications: {
        ...state.applications.results,
        ...state.applications,
        default: {
          error: error.message,
          status: FAIL
        }
      }
    }
  }
  updateApiKeys = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.updateApiKeysPending(state, action)
      case SUCCESS:
        return this.updateApiKeysSuccess(state, action)
      case FAIL:
        return this.updateApiKeysFail(state, action)
      default:
        return state
    }
  }
  updateApiKeysPending = (state, action) => {
    const { payload } = action
    const { applicationId } = payload
    return {
      ...state,
      apiKeys: {
        ...state.apiKeys,
        [applicationId]: {
          result: [],
          error: undefined,
          status: PENDING
        }  
      }
    }
  }
  updateApiKeysSuccess = (state, action) => {
    const { payload, result } = action
    const { applicationId } = payload
    return {
      ...state,
      apiKeys: {
        ...state.apiKeys,
        [applicationId]: {
          result,
          error: undefined,
          status: SUCCESS
        }  
      }
    }
  }
  updateApiKeysFail = (state, action) => {
    const { payload, error } = action
    const { applicationId } = payload
    return {
      ...state,
      apiKeys: {
        ...state.apiKeys,
        [applicationId]: {
          result: [],
          error: error.message,
          status: FAIL
        }  
      }
    }
  }
  createApiKey = (state, action) => {
    const { status, error, result } = action
    return {
      ...state,
      temporaryApiKey: {
        status,
        error,
        result
      }
    }
  }
  clearTemporaryKey = (state) => {
    return {
      ...state,
      temporaryApiKey: {}
    }
  }
  removeApiKey = (state, action) => {
    const { status, payload } = action
    const { applicationId, apiKeyId } = payload

    if (status === SUCCESS) {
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          [applicationId]: {
            ...state.apiKeys[applicationId],
            result: _.reject(state.apiKeys[applicationId].result, (key) => {
              return _.get(key, 'apiKey.id') === apiKeyId
            })
          }
        }
      }
    }

    return state
  }

  fetchApplications = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchApplicationsPending(state, action)
      case SUCCESS:
        return this.fetchApplicationsSuccess(state, action)
      case FAIL:
        return this.fetchApplicationsFail(state, action)
      default:
        return state
    }
  }
  fetchApplicationsPending = (state, action) => {
    return {
      ...state,
      applications: {
        results: {
          status: PENDING,
          error: undefined
        }
      }
    }
  }
  fetchApplicationsSuccess = (state, action) => {
    return {
      ...state,
      applications: {
        results: {
          status: SUCCESS,
          result: action.result,
          error: undefined
        }
      }
    }
  }
  fetchApplicationsFail = (state, action) => {
    return {
      ...state,
      applications: {
        results: {
          status: FAIL,
          error: action.error
        }
      }
    }
  }

  createApplication = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.createApplicationPending(state, action)
      case SUCCESS:
        return this.createApplicationSuccess(state, action)
      case FAIL:
        return this.createApplicationFail(state, action)
      default:
        return state
    }
  }

  createApplicationPending = (state, action) => {
    return {
      ...state,
      application: {
        status: PENDING,
        error: undefined,
      }
    }
  }

  createApplicationSuccess = (state, action) => {
    return {
      ...state,
      application: {
        status: SUCCESS,
        result: action.result,
        error: undefined
      }
    }
  }

  createApplicationFail = (state, action) => {
    return {
      ...state,
      application: {
        status: FAIL,
        error: action.error
      }
    }
  }

  updateApplication = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.updateApplicationPending(state, action)
      case SUCCESS:
        return this.updateApplicationSuccess(state, action)
      case FAIL:
        return this.updateApplicationFail(state, action)
      default:
        return state
    }
  }

  updateApplicationPending = (state, action) => {
    return {
      ...state,
      application: {
        status: PENDING,
        error: undefined,
      }
    }
  }

  updateApplicationSuccess = (state, action) => {
    return {
      ...state,
      application: {
        status: SUCCESS,
        result: action.result,
        error: undefined
      }
    }
  }

  updateApplicationFail = (state, action) => {
    return {
      ...state,
      application: {
        status: FAIL,
        error: action.error
      }
    }
  }

  deleteApplication = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.deleteApplicationPending(state, action)
      case SUCCESS:
        return this.deleteApplicationSuccess(state, action)
      case FAIL:
        return this.deleteApplicationFail(state, action)
      default:
        return state
    }
  }

  deleteApplicationPending = (state, action) => {
    return {
      ...state,
      application: {
        status: PENDING,
        error: undefined,
      }
    }
  }

  deleteApplicationSuccess = (state, action) => {
    return {
      ...state,
      application: {
        status: SUCCESS,
        result: action.result,
        error: undefined
      }
    }
  }

  deleteApplicationFail = (state, action) => {
    return {
      ...state,
      application: {
        status: FAIL,
        error: action.error
      }
    }
  }
}

export default new DeveloperReducers()
