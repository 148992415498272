import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'

import FormContentBox from '../../../../components/FormContentBox'
import { authCombinedSelectors } from '../../../../store/modules/combinedSelectors'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'

import RolesOrganisationsProfessions from './RolesOrganisationsProfessions'

const enhance = compose(
  connect(
    state => {
      const isRolesAndOrganisationsEditable = authCombinedSelectors.isCurrentUserAbleToEditRoleAndOrganisations(state)
      const isRolesAndOrganisationsViewable = authCombinedSelectors.isCurrentUserAbleToViewRoleAndOrganisations(state)
      const isProfessionEditable = authCombinedSelectors.isCurrentUserAbleToEditProfession(state)
      const isAccountTypeEditable = authCombinedSelectors.getCanEditAccountType(state)
      const allowReportShareAsAssessor = platformSelectors.getAllowReportShareAsAssessor(state)

      return {
        isRolesAndOrganisationsEditable,
        isRolesAndOrganisationsViewable,
        isProfessionEditable,
        isAccountTypeEditable: isAccountTypeEditable && allowReportShareAsAssessor
      }
    }
  ),
  withPropsOnChange(
    ['formId'],
    (props) => {
      const { formId } = props
      const FormComponent = FormContentBox(formId)
      return { FormComponent }
    }
  )
)

export default enhance(RolesOrganisationsProfessions)
