import { colors } from '../../../config/theme'

const styles = {
  floatingLabelFocusStyle: { color: colors.accent },
  underlineFocusStyle: { borderColor: colors.accent },
  errorStyle: { color: colors.error },
  errorText: { color: colors.error },
  borderFocussed: { borderColor: colors.lightGrey },
  inputInkbar: {
    '&:after': {
      backgroundColor: colors.text
    }
  },
  colorPickerWrap: {
    marginTop: 16,
    position: 'relative'
  },
  popover: {
    position: 'absolute',
    zIndex: '2'
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  },
  colorPreview: {
    width: 30,
    height: 30,
    marginRight: 10,
    borderRadius: 5
  },
  colorInput: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    width: 30,
    height: 30,
    marginRight: 10,
    fontSize: 16,
    borderRadius: 2
  },
  colorInputLabel: {
    margin: 0
  }
}

export default theme => styles
