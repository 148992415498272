import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { selectors as platformSelectors } from '../../../store/modules/platforms'
import CommunicationsAddScreen from './CommunicationsAddScreen'

const mapStateToProps = (state, ownProps) => {
  const channel = _.get(ownProps, 'match.params.channel')
  return {
    pageTitle: platformSelectors.getPageTitle(state)(`Communications Add - Page Title ${_.capitalize(channel)}`),
    type: _.toUpper(_.get(ownProps, 'match.params.type')),
    channel
  }
}
const enhance = compose(
  connect(mapStateToProps)
)

export default enhance(CommunicationsAddScreen)
