"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withNullFlavours = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _formHelpers = require("./formHelpers");

var _fp = _interopRequireDefault(require("lodash/fp"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var withNullFlavours = function withNullFlavours() {
  var nullFlavours = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return function () {
    var validatorFunctions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _fp["default"].noop;
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var value = _fp["default"].first(args);

      if (_fp["default"].includes(value, nullFlavours)) {
        return;
      }

      return _fp["default"].compose(_fp["default"].find(_fp["default"].identity), function (validators) {
        return _fp["default"].over(validators).apply(void 0, args);
      }, _fp["default"].castArray)(validatorFunctions);
    };
  };
};

exports.withNullFlavours = withNullFlavours;