
const styles = {
  offlineScreenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    textAlign: 'center'
  },
  offlineButton: {
    display: 'block',
    margin: '20px auto 0'
  }
}

export default theme => styles
