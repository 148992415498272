import React, { useEffect, useState } from 'react'
import qs from 'qs'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { translations } from '../../../../config'

import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as formSchemaSelectors, actions as formSchemaActions } from '../../../../store/modules/formSchemas'

import PanelContainer from '../../components/PanelContainer'
import RowItem from '../../components/RowItem'
import Filter from '../../components/Filter'
import { useInitialFetch, useQuery } from '../../../../hooks'
import { addQuery, removeQuery } from '../../../../helpers/query'
import OrganisationSearchFilter from '../../components/OrganisationSearchFilter'
import { push } from 'connected-react-router'

const IntegrationManagementReportingFormsPanel = () => {
  const dispatch = useDispatch()
  const currentQuery = useQuery()
  const organisations = useSelector(authSelectors.getUserOrganisationsOptions)
  const currentOrganisation = useSelector(authSelectors.getSelectedOrganisation)
  const currentOrganisationId = _.get(currentOrganisation, 'id')
  const hasOrganisationOptions = organisations && !_.isEmpty(organisations)
  const [filter, setFilter] = useState(currentOrganisationId)
  const formSchemas = useSelector(formSchemaSelectors.getSchemasForDropdown)

  useInitialFetch(formSchemaActions.fetchAllFormSchemas)

  const editFormSchema = (formSchemaId) => (e) => {
    const organisationId = _.get(currentQuery, 'organisationId')
    dispatch(push(`/integration-management/edit?${qs.stringify({ organisationId, formSchemaId })}`))
  }

  const handleDropdownChange = (event) => {
    const organisationId = _.get(event, 'target.value')
    handleSetOrganisation(organisationId)
  }

  const handleSetOrganisation = (organisationId) => {
    if (!_.isNil(organisationId) || !hasOrganisationOptions) {
      addQuery({ organisationId })
      setFilter(organisationId)
    } else {
      removeQuery(['organisationId'])
    }
  }

  useEffect(() => {
    if (_.isUndefined(currentQuery.organisationId) && hasOrganisationOptions) {
      addQuery({ organisationId: currentOrganisationId })
    }
  }, [currentQuery, currentOrganisationId, organisations, hasOrganisationOptions])

  return (
    <PanelContainer panelTitle={'Reporting Forms'}>
      {hasOrganisationOptions ? (
        <Filter
          label={translations('Organisation')}
          options={organisations}
          onChange={handleDropdownChange}
          value={filter}
        />
      ) : (
        <OrganisationSearchFilter
          label={translations('Organisation')}
          onChange={handleSetOrganisation}
        />
      )}
      {
        _.map(formSchemas, ({ value, label }) => {
          return <RowItem text={label} onEdit={editFormSchema(value)} />
        })
      }
    </PanelContainer>
  )
}

export default IntegrationManagementReportingFormsPanel
