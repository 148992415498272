import _ from 'lodash'
import {
  getUserSelectedOrganisationId,
  getPermissions,
  getCurrentUser,
  getSelectedOrganisation
} from './selectors'
import { useShallowEqualSelector } from '../../../hooks'
import { useSelector } from 'react-redux'
import { getProfessionOptions } from '../organisations/helpers'

export const useOrganisation = () => {
  return useShallowEqualSelector(getUserSelectedOrganisationId)
}

export const useLoggedInUserOrganisationName = () => {
  const currentOrg = useSelector(getSelectedOrganisation)
  const name = _.get(currentOrg, 'name')
  return name
}

export const useLoggedInUserOrganisationProfessions = () => {
  const currentOrg = useSelector(getSelectedOrganisation)
  const professions = _.get(currentOrg, 'professions')
  return professions
}

export const useLoggedInUserOrganisationProfessionsOptions = () => {
  const professions = useLoggedInUserOrganisationProfessions()
  const options = getProfessionOptions(professions)
  return options
}

export const usePermissionRules = () => {
  return useShallowEqualSelector(getPermissions)
}

export const usePermissionBySubject = ({ subject }) => {
  const rules = useShallowEqualSelector(getPermissions)
  return _.filter(rules, { subject })
}

export const usePermissionByAction = ({ action }) => {
  const rules = useShallowEqualSelector(getPermissions)
  return _.filter(rules, { action })
}
