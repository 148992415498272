import React from 'react'
import StandardForm from '../StandardForm'
import { SdkProvider, useSdk } from '../../../services/sdk'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

const ResetPasswordForm = ({
  onSuccess,
  onError,
  resetToken,
  themeId
}) => {

  const forgottenPassword = _.isNil(resetToken)
  const schemaName = forgottenPassword ? 'forgotPassword' : 'resetPassword'

  const { resetPassword, changePassword } = useSdk()

  const onSubmit = async (data) => {
    try {
      if(forgottenPassword) {
        await resetPassword({ ...data, themeId })
      } else {
        await changePassword({ resetToken, password: data.password })
      }
      onSuccess && onSuccess()
    } catch (error) {
      onError && onError(error)
      return { [FORM_ERROR]: error.message }
    }
  }

  return (
    <StandardForm
      schemaName={schemaName}
      onSubmit={onSubmit}
      fullWidthSubmit
    />
  )
}

const WrappedResetPasswordForm = (props) => {
  const {
    baseUrl,
    organisationId,
    platformId,
    websiteId,
    themeId,
    resetToken,
    ...rest
  } = props

  return (
    <SdkProvider
      baseUrl={baseUrl}
      organisationId={organisationId}
      platformId={platformId}
      websiteId={websiteId}
    >
      <ResetPasswordForm 
        {...rest} 
        resetToken={resetToken} 
        themeId={themeId} 
      />
    </SdkProvider>
  )
}

export default WrappedResetPasswordForm