import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import Field from '../Field'
import { StyledH3 } from '../H3'

const Heading = styled(StyledH3)`
  margin-bottom: 0;
  margin-top: 10px;
  ${media.greaterThan('small')`
    margin-top: 15px;
    margin-bottom: 0; 
  `}
`

/**
 * A heading within a form schema 
 */
const HeadingField = (props) => {
  const { children } = props
  return (
    <Field>
      <Heading>{children}</Heading>
    </Field>
  )
}

HeadingField.propTypes = {
  /** The value of the heading tag */
  children: PropTypes.node.isRequired
}

export default HeadingField
