import { LOGIN, RESET_PASSWORD, CHANGE_PASSWORD, LOGOUT, GET_SSO_AUTH_CONTEXT, SELECT_ORGANISATION, LOAD_SELECTED_ORGANISATION, UPDATE_AUTH_USER, REQUEST_NEW_COMPANY_ACCOUNT, EDIT_WATCH_LIST, SET_AUTH_CONTEXT, CREATE_WORKSPACE} from './constants'
import * as platformSelectors from '../platforms/selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'

class AuthActions {
  login = ({ email, username, password, from, isAssessorLogin = false }) => ({
    type: LOGIN,
    promise: (dispatch, getState) => {
      const platformId = platformSelectors.getCurrentPlatformId(getState())
      if (isAssessorLogin) {
        return digitalStoreSdk.auth
          .loginForAssessor({ email, username, password, platformId })
      }
      return digitalStoreSdk.auth
        .login({ email, username, password, platformId })
    },
    from
  })
  resetPassword = ({ email, username, themeId, platformId }) => ({
    type: RESET_PASSWORD,
    promise: () => digitalStoreSdk.auth
      .resetPassword({ email, username, themeId, platformId })
  })
  changePassword = ({ resetToken, password, themeId }) => ({
    type: CHANGE_PASSWORD,
    promise: () => digitalStoreSdk.auth
      .changePassword({ resetToken, password, themeId })
  })
  logout = ({ skipApi } = {}) => ({
    type: LOGOUT,
    promise: () => digitalStoreSdk.auth.logout({ skipApi })
  })
  selectOrganisation = ({ organisationId, regionId, receiverId, silent = false }) => ({
    type: SELECT_ORGANISATION,
    organisationId,
    regionId,
    receiverId,
    silent
  })
  loadSelectedOrganisation = ({ organisationId }) => ({
    type: LOAD_SELECTED_ORGANISATION,
    promise: () => digitalStoreSdk.organisations.fetchOrganisation({ id: organisationId })
  })
  setAuthContext = (authContext) => ({
    type: SET_AUTH_CONTEXT,
    ...authContext,
    promise: () => digitalStoreSdk.auth
      .setAuthContext(authContext)
  })
  updateUser = ({ user }) => ({
    type: UPDATE_AUTH_USER,
    user
  })
  requestNewCompanyAccount = (params) => ({
    type: REQUEST_NEW_COMPANY_ACCOUNT,
    promise: () => digitalStoreSdk.auth.requestNewAccount(params)
  })

  editWatchList = (payload) => ({
    type: EDIT_WATCH_LIST,
    promise: () => digitalStoreSdk.users.editWatchList(payload),
    payload
  })
  getSSOAuthContext = () => ({
    type: GET_SSO_AUTH_CONTEXT,
    promise: () => digitalStoreSdk.auth.getAuthContext()
  })
  createWorkspace = () => ({
    type: CREATE_WORKSPACE,
    promise: () => digitalStoreSdk.auth.createWorkspace()
  })
}

export default new AuthActions()
