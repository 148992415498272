import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'

import { selectors as authSelectors } from '../auth'
import ReportAcknowledgementsActions from './actions'

class ReportAcknowledgementsMiddleware {
  loadReportAcknowledgementsMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/reports/:reportid/acknowledgements', exact: true })
      const reportId = _.get(result, 'params.reportid')
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (result && isLoggedIn && reportId) {
        dispatch(ReportAcknowledgementsActions.fetchReportAcknowledgements({ reportId }))
      }
    }
  }
}

export default new ReportAcknowledgementsMiddleware()
