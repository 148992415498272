import { LOCATION_CHANGE, push } from 'connected-react-router'
import { matchPath } from 'react-router'
import qs from 'qs'
import _ from 'lodash'
import { translations } from '../../../config'
import modalService from '../../../services/modalService'
import { selectors as authSelectors } from '../auth'
import {
  actions as organisationDetailsActions,
  constants as organisationDetailsConstants
} from '../organisationDetails'
import {
  actions as locationActions,
} from '../locations'
import {
  actions as ssoProviderActions
} from '../ssoProvider'
import { getHasLocationProvidersEnabled } from '../platforms/selectors'

class OrganisationDetailsMiddleware {
  fetchInitialOrganisationDetails = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const matchesOrgDetails = matchPath(path, { path: '/organisations/:id', exact: true })
      const matchesMyOrgDetails = matchPath(path, { path: '/my-organisation', exact: true })
      const matchesOrgTranslations = matchPath(path, { path: ['/organisations/:id/translations', '/organisations/:id/translations/*'], exact: true })
      const matchesOrgPages = matchPath(path, { path: '/organisations/:id/page-management', exact: true })
      const matchesMyOrgPages = matchPath(path, { path: '/my-organisation/page-management', exact: true })
      const matchesOrgPagesEdit = matchPath(path, { path: '/organisations/:id/page-management/*', exact: true })
      const matchesMyOrgPagesEdit = matchPath(path, { path: '/my-organisation/page-management/*', exact: true })
      const matchesSelectReport = matchPath(path, { path: '/select-report', exact: true })
      const matchesReportConfig = matchPath(path, { path: '/report-configuration', exact: true })
      const matchesAckDetails = matchPath(path, { path: '/reports/:reportid/acknowledgements/:id', exact: true })
      const matchesReportConfigNext = matchPath(path, { path: '/report-configuration-beta/:id', exact: true })
      const matchesReport = matchPath(path, { path: '/reports/:reportId', exact: true })

      const hasLocationProvidersEnabled = getHasLocationProvidersEnabled(getState())

      const result = authSelectors.getIsLoggedIn(getState()) && (matchesOrgDetails || matchesOrgTranslations || matchesOrgPages || matchesOrgPagesEdit || matchesReport)
      const orgId = _.get(result, 'params.id') || authSelectors.getUserSelectedOrganisationId(getState())
      if (result && orgId !== 'new') {
        dispatch(organisationDetailsActions.fetchOrganisationById({ id: orgId }))
        dispatch(organisationDetailsActions.fetchOrganisationReceivers({ organisationId: orgId }))
        if (hasLocationProvidersEnabled) {
          dispatch(locationActions.fetchLocationProviders())
        }
      }
      if (authSelectors.getIsLoggedIn(getState()) && (matchesSelectReport || matchesReportConfig || matchesAckDetails)) {
        const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
        dispatch(organisationDetailsActions.fetchOrganisationReceivers({ organisationId }))
      }
      if (matchesReportConfigNext) {
        const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
        dispatch(organisationDetailsActions.fetchOrganisationById({ id: organisationId }))
      }
      if (matchesMyOrgDetails || matchesMyOrgPages || matchesMyOrgPagesEdit) {
        const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
        dispatch(organisationDetailsActions.fetchOrganisationReceivers({ organisationId }))
        dispatch(organisationDetailsActions.fetchOrganisationById({ id: organisationId }))
        if (hasLocationProvidersEnabled) {
          dispatch(locationActions.fetchLocationProviders())
        }
      }
    }
  }

  fetchOrganisationDetailsForPublicForm = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToPublicForm = matchPath(action.payload.location.pathname, { path: '/:formType/new/public/:organisationId', exact: true })
      if (isRoutingToPublicForm) {
        dispatch(organisationDetailsActions.fetchOrganisationById({
          id: _.get(isRoutingToPublicForm, 'params.organisationId')
        }))
      }
    }
  }

  fetchOrganisationDetailsForDuplicateForm = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToCreateForm = matchPath(action.payload.location.pathname, { path: '/organisations/new', exact: true })
      const { sourceOrganisationId } = qs.parse(_.get(action.payload, 'search'), { ignoreQueryPrefix: true })
      if (sourceOrganisationId && isRoutingToCreateForm) {
        dispatch(organisationDetailsActions.fetchOrganisationById({ id: sourceOrganisationId }))
      }
    }
  }

  clearOrganisationDetailsFormOnNavigateAwayMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const matchesOrgDetails = matchPath(path, { path: '/organisations/:id', exact: true })
      const matchesMyOrgDetails = matchPath(path, { path: '/my-organisation', exact: true })
      const matchesOrgTranslations = matchPath(path, { path: '/organisations/:id/translations', exact: true })
      const matchesReportManagement = matchPath(path, { path: '/report-management', exact: true })
      const matchResources = matchPath(path, { path: '/resources', exact: true })

      const result = matchesOrgDetails || matchesOrgTranslations || matchesMyOrgDetails || matchesReportManagement || matchResources
      if (!result) {
        dispatch(organisationDetailsActions.resetStore())
      }
    }
  }

  organisationCreateSuccessMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === organisationDetailsConstants.CREATE_ORGANISATION && action.status === 'SUCCESS') {
      modalService.action({
        title: translations('Success'),
        text: translations('Organisation successfully created'),
        actions: [
          {
            primary: true,
            text: translations('Okay'),
            onClick: () => {
              dispatch(push(`/organisations/${action.result.id}`))
            }
          }
        ]
      })
    }
  }

  organisationCreateFailMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === organisationDetailsConstants.CREATE_ORGANISATION && action.status === 'FAIL') {
      modalService.action({
        title: translations(translations('Create Organisation Fail Title')),
        text: translations(translations(action.error.code)),
        actions: [
          {
            primary: true,
            text: translations('Okay')
          }
        ]
      })
    }
  }

  fetchOrganisationSuccessMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === organisationDetailsConstants.FETCH_ORGANISATION_BY_ID && action.status === 'SUCCESS') {
      const ssoProviderId = _.find(_.get(action, 'result.loginMethods'), loginMethod => loginMethod !== 'DIGITAL_STORE_LOGIN')
      if (ssoProviderId) {
        dispatch(ssoProviderActions.fetchSSOProvider({ id: ssoProviderId }))
      } else {
        dispatch(ssoProviderActions.resetStore())
      }
    }
  }
}

export default new OrganisationDetailsMiddleware()
