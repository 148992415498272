import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  applications: {
    default: {}
  },
  apiKeys: {},
  temporaryApiKey: {}
}

const store = (state = defaultState, action) => {
  switch (action.type) {
    case constants.CREATE_OR_FETCH_APPLICATION:
      return reducers.updateDefaultApplication(state, action)
    case constants.FETCH_API_KEYS:
      return reducers.updateApiKeys(state, action)
    case constants.CREATE_API_KEY:
      return reducers.createApiKey(state, action)
    case constants.REMOVE_API_KEY:
      return reducers.removeApiKey(state, action)
    case constants.CLEAR_TEMPORARY_KEY:
      return reducers.clearTemporaryKey(state, action)
    case constants.CREATE_APPLICATION:
      return reducers.createApplication(state, action)
    case constants.UPDATE_APPLICATION:
      return reducers.updateApplication(state, action)
    case constants.DELETE_APPLICATION:
      return reducers.deleteApplication(state, action)
    case constants.FETCH_APPLICATIONS:
      return reducers.fetchApplications(state, action)
    default:
      return state
  }
}

export default store
