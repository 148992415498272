import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { FormControl } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getImage } from '../../../components/Images'
import { useDispatch, useSelector } from 'react-redux'
import { getFormValues, change } from 'redux-form'
import qs from 'qs'

import Form, { FormBody, FormSubmit } from '../../../components/Form'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import Button from '../../../components/Button'
import InsertButton from '../../../components/FormviewEditor/InsertButton'
import BottomBar from '../../../components/BottomBar'
import ContentFormSection from '../ContentFormSection'
import Dropdown from '../../../components/Fields/Dropdown'
import modalService from '../../../services/modalService'
import toastService from '../../../services/toastService'
import AddLanguageModal from '../../../containers/Organisations/ManageTranslationsScreen/components/AddLanguageModal/AddLanguageModal'

import { actions as languagesActions, selectors as languagesSelectors } from '../../../store/modules/languages'
import { constants as communicationTemplateConstants,
  selectors as communicationTemplateSelectors,
  actions as communicationTemplateActions
} from '../../../store/modules/communicationTemplates'
import { selectors as authSelectors } from '../../../store/modules/auth'

import { translations } from '../../../config'
import style from './style'

const ConfigureReportForm = Form(communicationTemplateConstants.COMMUNICATIONS_TEMPLATE_FORM)

const copyIcon = getImage('copyIcon')


const generateContentSchema = ({ language, channel }) => {
  let contentSchema = [{
    id: `content.${language}.body`,
    field: 'Input',
    props: {
      label: translations('Communications - Body Field'),
      name: `content.${language}.body`,
      multiline: true,
      rows: 10,
      shrink: true,
      required: true
    }
  }]
  if(channel === 'email') {
    const body = _.get(contentSchema, '0')
    contentSchema = _.concat([
      {
        id: `content.${language}.subject`,
        field: 'Input',
        props: {
          label: translations('Communications - Subject Field'),
          name: `content.${language}.subject`,
          shrink: true,
          required: true
        }
      }
    ], body)
  }
  return contentSchema
}

const CommunicationsTemplateForm = (props) => {
  const {
    type,
    variables,
    initialValues,
    isSubmitting,
    isInvalid,
    classes,
    onSendTest,
    onCopy,
    testDestination,
    testLanguage,
    setTestLanguage,
    channel,
    languages,
    createOrganisationTemplate,
    updateTemplate,
    onCreateSuccess,
    onUpdateSuccess,
    editing,
    id,
    systemEntities,
  } = props

  let translationsChannelKey = _.capitalize(channel)

  const query = _.get(window, 'location.search')
  const { isSystemEmailTemplate = false } = qs.parse(query, { ignoreQueryPrefix: true }) || {}  
  const isSystemEmailTemplateBool = isSystemEmailTemplate === 'true'
  
  const dispatch = useDispatch()
  const [ contentSections, setContentSections ] = useState([])
  const allLanguages = useSelector(languagesSelectors.getLanguages)
  const languagesOptions = _.map(_.keys(contentSections), (language) => ({ label: translations(`Language - ${language}`), value: language }))
  const templateTypes = useSelector(communicationTemplateSelectors.getDefaultPlatformTemplatesByType)
  const organisationId = useSelector(authSelectors.getUserSelectedOrganisationId)
  const currentFormData = useSelector(getFormValues(communicationTemplateConstants.COMMUNICATIONS_TEMPLATE_FORM))

  const schema = [
    {
      id: 'Name',
      field: 'Input',
      props: {
        label: translations('Communications - Name Field'),
        name: 'name',
        shrink: true,
        required: true
      }
    }
  ]

  useEffect(() => {
    if (!_.size(allLanguages)) {
      dispatch(languagesActions.fetchLanguages())
    }
  }, [])
  
  useEffect(() => {
    let contentLanguages = languages
    if (isSystemEmailTemplateBool && initialValues) {
      if (initialValues) {
        let existingLanguages = Object.keys(_.get(initialValues, 'content'))
        existingLanguages = _.pull(existingLanguages, 'en')
        existingLanguages.unshift('en')

        contentLanguages = existingLanguages
      }
    }
    const contentSections =  _.reduce(contentLanguages, (memo, language) => {
      memo[language] = {
        id: language,
        title: `Language - ${language}`,  
        schema: generateContentSchema({language, channel})
      }
      return memo
    }, {})

    setContentSections(contentSections)
  }, [channel])

  const removeContentSection = async({ id: languageCode }) => {
    const newContentSections = _.omit(contentSections, [languageCode])
    const newFormContent = _.omit(_.get(currentFormData, 'content'), [languageCode])
    try {
      if (id) {
        await dispatch(communicationTemplateActions.deleteSystemTemplateContentForLanguage({ id, languageCode }))
      }
      await setContentSections(newContentSections)
      await dispatch(change(communicationTemplateConstants.COMMUNICATIONS_TEMPLATE_FORM, 'content', newFormContent ))
      await toastService.action({
        type: 'success',
        message: translations('Communications - Delete Template Language Modal Success', { language: translations(`Language - ${languageCode}`) })
      })
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations('Communications - Delete Template Language Modal Failed')
      })
    }
  }

  const showAddLanguageModal = useCallback(() => {
    const availableLanguages = _.filter(allLanguages, language => !_.includes(_.map(languagesOptions, 'value'), language.isoCode))
    modalService.open({
      overflowContent: true,
      component: AddLanguageModal,
      languages: availableLanguages,
      onSubmit: (languageId) => {
        const selectedLang = _.find(allLanguages, lang => lang.id === languageId)
        
        const newContentSections = {
          ...contentSections,
          [selectedLang.isoCode]: {
            id: selectedLang.isoCode,
            title: `Language - ${selectedLang.isoCode}`,  
            schema: generateContentSchema({language: selectedLang.isoCode, channel})
          }
        }

        setContentSections(newContentSections)
      },
      onSuccess: () => {
        modalService.close()
      }
    })
  }, [allLanguages, contentSections])

  const handleSubmit = useCallback(async () => {
    const template = _.get(templateTypes, type)
    const name = _.get(currentFormData, 'name')
    const isSystemTemplate = _.includes(_.keys(systemEntities), id)
    try {
      if (editing) {
        await updateTemplate({ organisationId, id, body: currentFormData, channel, isSystemTemplate })
        onUpdateSuccess({ name })
      } else {
        await createOrganisationTemplate({
          organisationId,
          body: {
            templateId: _.get(template, 'id'),
            ...currentFormData
          },
          channel,
          isSystemEmailTemplate: isSystemEmailTemplateBool
        })
        onCreateSuccess({ name })
      }
    } catch (e) {
      const translationsKey = id ? 'Communications - Update Failure' : 'Communications - Create Failure'
      toastService.action({
        type: 'error',
        message: translations(translationsKey, { name })
      })
    }
  }, [currentFormData])

  const AddLanguageTemplate = () => {
    return (
      <div className={classes.addButtonWrapper}>
        <InsertButton
            color="primary"
            onClick={showAddLanguageModal}
            fullWidth
          >
            {translations('Manage Translations - Table Add Language Button')}
        </InsertButton>       
      </div>
    )
  }

  return (
    <ConfigureReportForm onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize keepDirtyOnReinitialize>
      <div className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <FormBody schema={schema} editing />
            {isSystemEmailTemplateBool && <AddLanguageTemplate />}
            {_.map(contentSections, (section) => {
              const { id, title, schema: contentSchema } = section
              return (
                <ContentFormSection
                  key={id}
                  id={id}
                  title={title}
                  schema={contentSchema}
                  initialOpen
                  isSystemEmailTemplateBool={isSystemEmailTemplateBool}
                  removeContentSection={removeContentSection}
                />
              )
            })}
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.metaSection}>
              <div className={classes.meta}>
                <Heading variant='h4' component='h2' uppercase>{translations('Communications - Template Type')}</Heading>
                <P value={translations(`Communication Type - ${_.toUpper(type)}`)} />
              </div>
              {_.size(variables) && (
                <div className={classes.meta}>
                  <Heading variant='h4' component='h2' uppercase>{translations('Communications - Variables')}</Heading>
                  <dl className={classes.variables}>
                    {_.map(variables, (variable) => {
                      return (
                        <React.Fragment key={variable}>
                          <div className={classes.variableItem}>
                            <dt>{variable}</dt>
                            <dd>{translations(`Variables - ${variable}`)}</dd>
                            <CopyToClipboard onCopy={() => onCopy(variable)} text={`{{${variable}}}`}>
                              <button
                                type='button'
                                className={classes.copyButton}
                              >
                                <img src={copyIcon} alt={translations('Copy')} />
                              </button>
                            </CopyToClipboard>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </dl>
                  <P value={translations('Communications - Variables Help', { interpolation: { prefix: '[[', suffix: ']]' } })} />
                </div>
              )}
              {!_.isNull(testDestination) && (
                  <div className={classes.meta}>
                  <Heading variant='h4' component='h2' uppercase>{translations(`Communications - Test Title ${translationsChannelKey}`)}</Heading>
                  <P value={translations(`Communications - Test ${translationsChannelKey}`, { destination: testDestination })} />
                  <div className={classes.testActions}>
                    {(_.size(languagesOptions) > 1) && <FormControl className={classes.storesFilter}>
                      <Dropdown
                        label={translations('Communications - Select language')}
                        value={testLanguage}
                        options={languagesOptions}
                        onChange={(e) => setTestLanguage(e.target.value)}
                        noErrorTextLabel
                        noFloatingLabel
                        noNullOption
                        givenClasses={{ field: classes.languageDropdown }}
                        shrink
                      />
                    </FormControl>}
                    <Button
                      className={classes.testButton}
                      onClick={onSendTest}
                      raised
                      children={translations('Communications - Send Test')}
                    />
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <BottomBar>
          <FormSubmit
            containerClass={classes.submitWrapper}
            submitting={isSubmitting}
            invalid={isInvalid}
            noContainer
          >{translations('Save')}</FormSubmit>
        </BottomBar>
      </div>
    </ConfigureReportForm>
  )
}

CommunicationsTemplateForm.defaultProps = {
  isSubmitting: false,
  isInvalid: false
}

CommunicationsTemplateForm.propTypes = {
  contentSections: PropTypes.object,
  initialValues: PropTypes.object,
  classes: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isInvalid: PropTypes.bool,
  type: PropTypes.string,
  variables: PropTypes.array,
  testDestination: PropTypes.string,
  onSendTest: PropTypes.func,
  testLanguage: PropTypes.string,
  setTestLanguage: PropTypes.func,
  onCopy: PropTypes.func
}

export default withStyles(style)(CommunicationsTemplateForm)
