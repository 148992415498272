export const ADD_CONDITION = 'conditions/ADD_CONDITION'
export const UPDATE_CONDITION = 'conditions/UPDATE_CONDITION'
export const REMOVE_CONDITION = 'conditions/REMOVE_CONDITION'
export const UPDATE_FIELDS = 'conditions/UPDATE_FIELDS'
export const SET_UNEDITED = 'conditions/SET_UNEDITED'
export const SET_CONDITIONS = 'conditions/SET_CONDITIONS'
export const ATTACH_TO_RESOURCE = 'conditions/ATTACH_TO_RESOURCE'
export const REMOVE_FROM_RESOURCE = 'conditions/REMOVE_FROM_RESOURCE'
export const REPLACE_RESOURCE_CONDITIONS = 'conditions/REPLACE_RESOURCE_CONDITIONS'
export const SET_RESOURCE_CONDITIONS = 'conditions/SET_RESOURCE_CONDITIONS'
