import React from 'react'

import withProvider from '../../../../hocs/withProvider'
import reportAuditContext from './contexts/ReportAuditContext'
import ReportAuditTable from './ReportAuditTable/ReportAuditTable'

const ReportAuditTrailScreen = () => {

  return (
    <>
      <ReportAuditTable />
    </>
  )
}

export default withProvider(reportAuditContext.AuditProvider)(ReportAuditTrailScreen)
