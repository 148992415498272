import _ from 'lodash'

import { Grid, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../../config'

import Form from '../../../../../components/Form'
import FormBody from '../../../../../components/Form/FormBody'
import FormError from '../../../../../components/Form/FormError'
import FormSubmit from '../../../../../components/Form/FormSubmit'
import Heading from '../../../../../components/Heading'

import * as validators from '../../../../../validators'
import style from './style'

import UserGroupAndOrganisation from '../../../UserRegistrationScreen/MoreUserRegistrationForm/UserGroupAndOrganisation'
import { selectors as userGroupSelectors } from '../../../../../store/modules/userGroups'
import { useSelector } from 'react-redux'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import WarningIcon from '@material-ui/icons/Warning'

const formId = 'AccountDeletionRequestForm'
const InviteForm = Form(formId)

const AccountDeletionRequestModal = (props) => {

  const {
    classes,
    onSubmit,
    actions
  } = props

  const schema = [
    {
      id: 'password',
      field: 'Input',
      props: {
        label: translations('Password'),
        name: 'password',
        required: true,
        shrink: false,
        type: 'password'
      }
    }
  ]

  return (
    <BasicModalOverlay
      title={translations('Account Deletion Request - Delete Account')}
      actions={actions}
    >
      <div className={classes.content} >
        <InviteForm onSubmit={onSubmit}>
          <div className={classes.warningBar}>
            <div className={classes.warningSign}>
              <WarningIcon/>
            </div>
            <div className={classes.warningMessage}>
              {translations('Account Deletion Request - Statement')}
            </div>
          </div>
          <FormBody>
            <div>
              <FormBody editing schema={schema} layout={['password:12']} />
            </div>
          </FormBody>
          <FormError />
        </InviteForm>
      </div>
    </BasicModalOverlay>
  )
}

export default withStyles(style)(AccountDeletionRequestModal)