import * as selectors from './selectors'
import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class ReportAcknowledgementsActions {
  fetchReportAcknowledgements = ({ reportId }) => ({
    type: constants.FETCH_REPORT_ACKNOWLEDGEMENTS,
    promise: (dispatch, getState) => digitalStoreSdk.acks.fetchAcksForReportId({ reportId })
  })

  fetchReportAcknowledgementsNext = ({ reportId }) => ({
    type: constants.FETCH_REPORT_ACKNOWLEDGEMENTS_NEXT,
    promise: (dispatch, getState) => {
      const size = selectors.getSize(getState())
      digitalStoreSdk.acks.fetchAcksForReportId({ reportId, size })
    }
  })

  fetchAckXMLForId = ({ id }) => ({
    type: constants.FETCH_ACK_XML_FOR_ID,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.bulkDownloads
      .requestBulkDownloadURL({
        payload: [ id ],
        type: 'ack'
      })
      .then(({ url }) => {
        window.location = url
        return Promise.resolve()
      })
    }
  })

  fetchAckXMLForIds = ({ ids }) => ({
    type: constants.FETCH_ACK_XML_FOR_ID,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.bulkDownloads
      .requestBulkDownloadURL({
        payload: ids,
        type: 'ack'
      })
      .then(({ url }) => {
        window.location = url
        return Promise.resolve()
      })
    }
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new ReportAcknowledgementsActions()
