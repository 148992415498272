import React from 'react'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import P from '../../P'

import style from './style'

const Legend = props => {
  const { data = [], toggleDatum, classes } = props

  return <div className={classes.legendContainer}>
    {data.map(datum => {
      const { key, value, color, hidden } = datum
      const text = `${key} (${value})`
      return <div
        key={key}
        onClick={() => toggleDatum(key)}
        className={classes.entry}
      >
        <div
          className={classes.colorCircle}
          style={{ backgroundColor: color }}
        />
        <P
          value={text}
          className={cx(classes.text, {
            [classes.hidden]: hidden
          })}
        />
      </div>
    })}
  </div>
}

export default withStyles(style)(Legend)
