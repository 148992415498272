import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../config'
import _ from 'lodash'

import cx from 'classnames'
import Button from '../../../components/Button'
import SideMenu from '../../../components/SideMenu'
import AppDetails from '../AppDetails'
import style from './style'

import { permissionsLookup, useAbilities } from '../../../ability/ability-context'

const RootMenu = ({
  handleLogout,
  handleMyAccountClick,
  handleMyOrganisationClick,
  classes,
  canViewOrg,
  isUserAssessor,
  ...props
}) => {
  const ability = useAbilities()

  const renderMyOrganisationButton = ({ canViewOrg, handleMyOrganisationClick, classes }) => {
    if (canViewOrg || ability.can('viewOwnOrganisation', _.get(permissionsLookup, 'ORGANISATION_MANAGEMENT'))) {
      return <Button raised onClick={handleMyOrganisationClick} variant='contained' className={cx(classes.organisationButton, classes.organisationButton)}>{translations('My Organisation')}</Button>
    }
    else {
      return null
    }
  }
  return (
    <SideMenu
      {...props}
      footerElement={
        <div className={classes.footer}>
          <AppDetails />
          <div className={classes.footerMenuButtonWrapper}>
            {renderMyOrganisationButton({ canViewOrg, handleMyOrganisationClick, classes })}
            {!isUserAssessor && <Button raised onClick={handleMyAccountClick} className={classes.accountButton}>{translations('My Profile')}</Button>}
            <Button raised onClick={handleLogout} className={classes.logoutButton} color='primary' >{translations('Sign Out')}</Button>
          </div>
        </div>
      }
    />
  )
}

export default withStyles(style)(RootMenu)
