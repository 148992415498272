import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import SettingsIcon from '@material-ui/icons/Settings'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import Button from '../../../components/Button'
import Form from '../../../components/Form'
import BottomBar from '../../../components/BottomBar'
import FormSection from './FormSection'
import FullScreenProgress from '../../../components/FullScreenLoader'
import { constants as formViewConstants } from '../../../store/modules/formViews'

import { translations } from '../../../config'
import styles from './style'
import EditButtons from './EditButtons'
import ConfigurationMoreMenu from './ConfigurationMoreMenu'
import ConfigureModal from './Modals/ConfigureModal'

const ReportForm = Form(formViewConstants.REPORT_CONFIGURATION_FORM)

const ReportQuestionsScreen = (props) => {
  const {
    classes,
    pageTitle,
    titleName,
    viewJSON,
    toggleVisibility,
    moveQuestion,
    moveQuestionToSection,
    sectionNames,
    onSave,
    openModal,
    editing,
    closeModal,
    addQuestion,
    addSection,
    configureField,
    deleteQuestion,
    deleteSection,
    questionNames,
    editRepeatables,
    width,
    formSections,
    schemaFields,
    getFormViewById,
    formViewDetails,
    audienceChanged,
    setAudienceChanged,
    toggleEdit,
    initialValues = {}
  } = props

  return (
    <div>
      <Helmet>
        <title>{`${pageTitle.title} - ${titleName}`}</title>
      </Helmet>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={
          <Heading component='h1' uppercase>{titleName}</Heading>
        }
        rightContent={(
          <div className={classes.configureButtonWrapper}>
            <div className={classes.configureItem}>
              <Button
                buttonType='primary'
                raised
                onClick={() => openModal(() => <ConfigureModal onSave={onSave} setAudienceChanged={setAudienceChanged}/>)}
                aria-label={translations('Configure Report')}
              >
                {width === 'xs' ? <SettingsIcon /> : translations('Configure Report')}
              </Button>
            </div>
            <div className={classes.configureItem}>
              <ConfigurationMoreMenu editing={editing} toggleEdit={toggleEdit} onSave={onSave} />
            </div>
          </div>
        )}
      />
      <div className={classes.container}>
          <React.Fragment>
            <div className={classes.scrollContainer}>
             <ReportForm
                // initialValues={_.size(initialValues) ? initialValues : {}}
                // enableReinitialize={true}
              >
                <div className={classes.formContentWrapper}>
                  {_.size(viewJSON) && _.map(viewJSON, (section, key) => {
                    return (
                      <FormSection
                        id={key}
                        sectionKey={key}
                        key={key}
                        sectionId={section.id}
                        initialOpen={false || section.initialOpen}
                        editing={false}
                        toggleVisibility={toggleVisibility}
                        moveQuestion={moveQuestion}
                        moveQuestionToSection={moveQuestionToSection}
                        openModal={openModal}
                        closeModal={closeModal}
                        sectionNames={sectionNames}
                        questionNames={questionNames}
                        deleteQuestion={deleteQuestion}
                        title={section.name}
                        editRepeatables={editRepeatables}
                        deleteSection={deleteSection}
                        sectionVisible={section.visible}
                        configureField={configureField}
                        getFormViewById={getFormViewById}
                        schemaFields={schemaFields}
                        formViewDetails={formViewDetails}
                        audienceChanged={audienceChanged}
                        setAudienceChanged={setAudienceChanged}
                        nestedSections={[section.id]}
                        {...section}
                      />
                    )
                  })}
                </div>
              </ReportForm>
            </div>
          </React.Fragment>
      </div>
      <BottomBar>
        <EditButtons
          classes={classes}
          viewJSON={viewJSON}
          sectionNames={sectionNames}
          closeModal={closeModal}
          openModal={openModal}
          addQuestion={addQuestion}
          addSection={addSection}
          questionNames={questionNames}
          editing={editing}
          onSave={onSave}
        />
      </BottomBar>
    </div>
  )
}

ReportQuestionsScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
  titleName: PropTypes.string,
  viewJSON: PropTypes.array,
  toggleVisibility: PropTypes.func,
  moveQuestion: PropTypes.func,
  moveQuestionToSection: PropTypes.func,
  sectionNames: PropTypes.func,
  onSave: PropTypes.func,
  openModal: PropTypes.func,
  editing: PropTypes.bool,
  closeModal: PropTypes.func,
  addQuestion: PropTypes.func,
  addSection: PropTypes.func,
  deleteQuestion: PropTypes.func,
  questionNames: PropTypes.func,
  editRepeatables: PropTypes.func,
  width: PropTypes.string
}

export default FullScreenProgress(withStyles(styles)(ReportQuestionsScreen))
