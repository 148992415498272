import _ from 'lodash'
import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

const _formatFormFieldsForDB = (fields) => {
  let newTheme = {
    name: fields.name,
    domain: fields.domain,
    details: _.omit(fields, ['id', 'name', 'domain']),
    type: fields.type
  }

  if (fields.id) newTheme.id = fields.id
  return newTheme
}

class ThemeDetailsActions {
  createTheme = (fields) => ({
    type: constants.CREATE_THEME,
    promise: () => digitalStoreSdk.themes
      .createTheme(_formatFormFieldsForDB(fields))
  })

  createOrganisationTheme = (fields) => ({
    type: constants.CREATE_ORGANISATION_THEME,
    promise: () => digitalStoreSdk.themes
      .createTheme(_formatFormFieldsForDB(fields))
  })

  createPlatformTheme = (fields) => ({
    type: constants.CREATE_PLATFORM_THEME,
    promise: () => digitalStoreSdk.themes
      .createTheme(_formatFormFieldsForDB(fields))
  })

  fetchThemeDetails = (id) => {
    return {
      type: constants.FETCH_THEME_DETAILS,
      promise: () => digitalStoreSdk.themes
        .fetchTheme({id})
    }
  }

  fetchThemeDetailsForForm = (id) => {
    return {
      type: constants.FETCH_THEME_DETAILS_FOR_FORM,
      promise: () => digitalStoreSdk.themes
        .fetchTheme({id})
    }
  }

  updateTheme = (fields) => {
    return {
      type: constants.UPDATE_THEME,
      promise: () => digitalStoreSdk.themes
        .updateTheme(_formatFormFieldsForDB(fields))
    }
  }
}

export default new ThemeDetailsActions()
