import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/core/styles'

import InsertButton from '../../../components/FormviewEditor/InsertButton'
import Conditions from '../../../components/Conditions'
import ConfigureConditionsModal from '../modals/ConfigureConditionsModal'
import ConfirmationModal from '../modals/ConfirmationModal'

import { removeCondition } from '../../../contexts/ConditionsContext/actions'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { useConditions, useFormViews } from '../../../contexts'
import { actions as RCActions, hooks as RCHooks } from '../../../store/modules/reportConfiguration'
import { useParams } from 'react-router'
import InfoModal from '../modals/InfoModal'

const Content = styled('div')(() => ({
  maxWidth: 1200,
  margin: '0 auto'
}))

const Actions = styled('div')(() => ({}))

const ReportManagementConditionsPanel = (props) => {
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const { getFields: editiorFields, getSections: editiorSections } = useFormViews()

  const { filters, disableEdit, disableDelete, disableCreate, onSelect, selectedLookup, handleSave } = props
  const { state: conditionsState, fieldLookup, dispatch, addCondition, updateCondition, getConditions, getConditionsForSave, getConditionsForResource } = useConditions()
  const { fields } = conditionsState
  const reduxDispatch = useDispatch()
  const { id: formViewId } = useParams()
  const { formViewVersionId } = RCHooks.useFormViewMetaData(formViewId)
  const { conditions: conditionsInUse } = RCHooks.useFollowUpDependencies({ formViewId })

  const editorConditions = useMemo(() => {
    const activeConditions = []
    _.forOwn(editiorSections, ({ id }) => {
      const sectionConditions = getConditionsForResource({ resourceType: 'SECTION', resourceId: id })
      activeConditions.push(..._.map(sectionConditions, 'id'))
    })
    _.forOwn(editiorFields, ({ isBlock, fields = [], id }) => {
      if (isBlock) {
        const fieldConditions = getConditionsForResource({ resourceType: 'BLOCK', resourceId: id })
        activeConditions.push(..._.map(fieldConditions, 'id'))
      }
    })
    return _.union(activeConditions)
  })

  const conditions = useMemo(() => {
    return getConditions({ filters })
  }, [getConditions])

  useEffect(() => {
    if (shouldUpdate) {
      reduxDispatch(RCActions.updateReportVersionConditions({ id: formViewVersionId, conditions: getConditionsForSave() }))
      setShouldUpdate(false)
      handleSave(false)
    }
  }, [shouldUpdate])

  useEffect(() => {
    dispatch(RCActions.searchFollowUpFresh({ formViewId }))
    dispatch(RCActions.searchCohortsFresh({ formViewId }))
  }, [])


  const onAdd = useCallback(() => {
    modalService.open({
      component: ConfigureConditionsModal,
      disableBackdropClick: true,
      zoomModal: true,
      fields,
      onSubmit: async (payload) => {
        await addCondition(payload)
        setShouldUpdate(true)
      }
    })
  }, [fields, conditions])

  const onEdit = useCallback(({ initialValues }) => {
    const openEditModal = () => {
      modalService.open({
        component: ConfigureConditionsModal,
        disableBackdropClick: true,
        zoomModal: true,
        fields,
        editing: true,
        initialValues,
        onSubmit: async (payload) => {
          await updateCondition({
            id: _.get(initialValues, 'id'),
            ...payload
          })
          setShouldUpdate(true)
        }
      })
    }
    if (
      _.includes(conditionsInUse, _.get(initialValues, 'id')) ||
      _.includes(editorConditions, _.get(initialValues, 'id'))
    ) {
      modalService.open({
        component: ConfirmationModal,
        title: translations('Confirmation'),
        message: translations(`Condition - Currently in use`),
        onConfirmation: _.debounce(openEditModal, 100)
      })
    } else {
      openEditModal()
    }

  }, [fields, conditions, conditionsInUse, editorConditions])

  const onDelete = useCallback(({ id }) => {
    if (
      _.includes(conditionsInUse, id) ||
      _.includes(editorConditions, id)
    ) {
      modalService.open({
        component: InfoModal,
        title: translations('Confirmation'),
        message: translations(`Condition - Cannot delete modal message`),
        onConfirmation: () => { }
      })
    } else {
      modalService.open({
        component: ConfirmationModal,
        title: translations('Confirmation'),
        message: translations(`Condition - Delete modal message`),
        onConfirmation: async () => {
          await dispatch(removeCondition({ id }))
          setShouldUpdate(true)
        }
      })
    }
  }, [conditionsInUse])

  return (
    <Content>
      <Conditions
        conditions={conditions}
        onEdit={!disableEdit ? onEdit : undefined}
        onDelete={!disableDelete ? onDelete : undefined}
        fieldLookup={fieldLookup}
        onSelect={onSelect}
        selectedLookup={selectedLookup}
      />
      {
        !disableCreate &&
        <Actions>
          <Grid container spacing={2}>
            <Grid item xs>
              <InsertButton onClick={() => onAdd()} fullWidth size='large'>{translations('Add Condition')}</InsertButton>
            </Grid>
          </Grid>
        </Actions>
      }
    </Content>
  )
}

ReportManagementConditionsPanel.defaultProps = {
  disableEdit: false,
  disableDelete: false,
  isSelectable: false
}

ReportManagementConditionsPanel.propTypes = {
  isSelectable: PropTypes.bool,
  filters: PropTypes.object,
  disableEdit: PropTypes.bool,
  disableDelete: PropTypes.bool
}

export default ReportManagementConditionsPanel
