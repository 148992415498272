
import Button from '../../../../../components/Button'
import { translations } from '../../../../../config'


export const AddAnnouncementButtonSmall = ({ onClick }) => {
    return <Button
        variant='contained'
        color='primary'
        onClick={onClick}
    >{translations('Announcement Management - Add Announcement')}</Button>
}
