import React from 'react'
import { connect } from 'react-redux'
import {
  compose,
  withHandlers,
} from 'recompose'

import DeleteConfirmModal from './DeleteConfirmModal'
import modalService from '../../../../../services/modalService'

const enhancer = compose(
  withHandlers({
    openModal: () => component => {
      modalService.open({
        component
      })
    },
    closeModal: () => () => {
      modalService.close()
    }
  })
)

export default enhancer(DeleteConfirmModal)
