import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  IconButton,
  Menu,
  FormControlLabel,
  Switch
} from '@material-ui/core'

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  DataUsage as DataUsageIcon,
  EventAvailable as EventAvailableIcon,
} from '@material-ui/icons'

import { actions as reportConfigurationActions } from '../../../../store/modules/reportConfiguration'

import Button from '../../../../components/Button'
import modalService from "../../../../services/modalService"
import toastService from "../../../../services/toastService"
import ConfirmationModal from '../../modals/ConfirmationModal'
import FollowUpModal from '../FollowUpModal'

import { translations } from '../../../../config'
import { addQuery } from '../../../../helpers/query'

import style from './style'
import { getMessageObject } from '../utils'

const toastError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Save Report Config')
})
const toastSuccess = () => toastService.action({
  type: 'success',
  message: translations('Successfully - Save Report Config')
})
const toastDeleteError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Delete Report Config')
})
const toastDeleteSuccess = () => toastService.action({
  type: 'success',
  message: translations('Successfully - Delete Report Config')
})

const FollowUpRow = ({ followUp, formViewId, classes, organisationLanguages }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    id,
    formView = {},
    message = '',
    cohort = {},
    followUpTrigger = {},
    followUpFormViewId = '',
    force,
    followUpEventId: stageId,
    followUpTriggerId: triggerId,
    followUpTimeframeId: timeframeId,
    cohortId,
    followUpMessages,
    scheduled
  } = followUp
  const {
    name
  } = formView

  const dispatch = useDispatch()

  const editFollowUpReportConfiguration = ({ followUpReportFormViewId, followUpId }) => {
    addQuery({
      followUp: {
        followUpReportFormViewId,
        followUpId
      }
    })
  }

  const handleEditFollowUp = ({ ...params }) => {
    dispatch(reportConfigurationActions.editFollowUp({ params, followUpId: id }))
      .then(res => {
        modalService.close()
        toastSuccess()
        dispatch(reportConfigurationActions.searchFollowUpFresh({ formViewId }))
        return res
      })
      .catch(err => {
        toastError()
      })
  }

  const editFollowUp = () => {
    modalService.open({
      component: FollowUpModal,
      disableBackdropClick: true,
      wideModal: true,
      initialValues: {
        name,
        stageId,
        cohortId,
        triggerId,
        timeframeId,
        message: getMessageObject(message, followUpMessages)
      },
      onSubmit: handleEditFollowUp,
      closeModal: () => modalService.close(),
      formViewId,
      organisationLanguages
    })
  }

  const handleDelete = () => {
    modalService.open({
      component: ConfirmationModal,
      disableBackdropClick: true,
      wideModal: true,
      message: translations('FollowUp - Confirm Delete Message'),
      title: translations('FollowUp - Confirm Delete Title'),
      onConfirmation: () => {
        dispatch(reportConfigurationActions.deleteFollowUp({ followUpId: id }))
          .then(res => {
            toastDeleteSuccess()
            setAnchorEl(null)
            dispatch(reportConfigurationActions.searchFollowUpFresh({ formViewId }))
            return res
          })
          .catch(err => {
            toastDeleteError()
          })
      }
    })
  }

  const handleEdit = () => {
    editFollowUp({ followUpId: id })
  }

  const handleMoreClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMoreClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleEditFollowUpReportConfiguration = () => {
    editFollowUpReportConfiguration({ followUpReportFormViewId: followUpFormViewId, followUpId: id })
  }

  const __changeVisibility = () => {
    dispatch(reportConfigurationActions.updateFollowUpPublishState({ scheduled: !scheduled, id }))
    setTimeout(() => {
      dispatch(reportConfigurationActions.searchFollowUpFresh({ formViewId }))
    }, 100)
  }

  const handlePublish = () => {
    scheduled
      ? modalService.open({
        component: ConfirmationModal,
        disableBackdropClick: true,
        wideModal: true,
        message: translations('FollowUp - Confirm publish change message'),
        title: translations('Confirm'),
        onConfirmation: __changeVisibility
      })
      : __changeVisibility()
  }

  return (
    <div className={classes.followupContainer} >
      <Paper className={classes.followup} variant='outlined'>
        {
          name &&
          <div className={classes.followUpHeader}>
            <Typography>
              {name}
            </Typography>
            <div>
              <FormControlLabel
                style={{ marginRight: 'unset' }}
                control={
                  <Switch
                    checked={scheduled}
                    onChange={handlePublish}
                    name='publish'
                    color='primary'
                    size='small'
                  />
                }
                labelPlacement='start'
                label={translations(scheduled ? 'Unschedule' : 'Schedule')}
              />
              <IconButton aria-label='More options' size='small' aria-controls={`question-more-menu-${id}`} aria-haspopup='true' onClick={handleMoreClick} color='inherit'>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`question-more-menu-${id}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMoreClose}
              >
                <ListItem button divider onClick={handleEdit}>
                  <ListItemIcon>
                    <EditIcon fontSize='small' />
                  </ListItemIcon>
                  Edit
                </ListItem>
                {/* <ListItem button divider>
                  <ListItemIcon>
                    <RateReviewIcon fontSize='small' />
                  </ListItemIcon>
                  Manual review
                </ListItem> */}
                <ListItem button divider onClick={handleDelete}>
                  <ListItemIcon>
                    <DeleteIcon fontSize='small' />
                  </ListItemIcon>
                  Delete
                </ListItem>
              </Menu>
            </div>
          </div>
        }
        <div className={classes.followupToolbar}>
          <div className={classes.followupStats}>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <DataUsageIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`Cohort: ${cohort.name}`}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`Event: ${followUpTrigger.name}`}
                />
              </ListItem>
              {/* <ListItem>
                <ListItemIcon>
                  <FeedbackIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    force
                      ? 'Follow-up asked: Always'
                      : 'Follow-up asked: Only if previous have been completed'
                  }
                />
              </ListItem> */}
            </List>
          </div>
          <div className={classes.followupToolbarActions}>

          </div>
        </div>
        {message && (
          <div className={classes.followupBlocks}>
            <Typography variant={'caption'} className={classes.blockTitle}>Message:</Typography>
            <div className={classes.followupItems}>
              <Typography variant={'body2'}>{message}</Typography>
            </div>
          </div>
        )}
        <div className={classes.followupBlocks}>
          <Typography variant={'caption'} className={classes.blockTitle}>Follow-Up Report:</Typography>
          <div className={classes.followupItems}>
            <Button
              buttonType='primary'
              raised
              onClick={handleEditFollowUpReportConfiguration}
            >
              {translations('Edit Configuration')}
            </Button>
          </div>
        </div>
      </Paper>
    </div >
  )
}

export default withStyles(style)(FollowUpRow)
