import { connect } from 'react-redux'
import _ from 'lodash'
import { push } from 'connected-react-router'
import { compose, withHandlers } from 'recompose'

import RootMenu from './RootMenu'
import { actions as appActions, selectors as appSelectors } from '../../../store/modules/app'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { menu } from '../../../config'
import analyticsService from '../../../services/analyticsService'

const mapStateToProps = state => {
  const isOpen = appSelectors.getIsMenuOpen(state)
  const items = authCombinedSelectors.getAvailableMenuItemsForSelectedOrganisation(state)
  const currentUser = authSelectors.getCurrentUser(state)
  const currentOrganisation = authSelectors.getSelectedOrganisation(state)
  const canViewOrg = _.some(authCombinedSelectors.getAvailableDashboardItemsForSelectedOrganisation(state), { id: 'ORGANISATION_MANAGEMENT' })
  const isUserAssessor = authSelectors.getIsUserAssessor(state)
  return {
    isOpen,
    items,
    currentUser,
    currentOrganisation,
    canViewOrg,
    isUserAssessor
  }
}

export default compose(
  connect(
    mapStateToProps
  ),
  withHandlers({
    onRequestClose: ({ dispatch }) => () => {
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    },
    onMenuItemClick: ({ dispatch }) => id => {
      const item = menu.find(menuItem => menuItem.id === id)
      if (id === 'USER_MANAGEMENT') {
        analyticsService.sendCustomEvent({ type: 'userAccountsVisit', route: 'burgerMenu' })
      }
      dispatch(item.action)
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    },
    handleLogout: ({ dispatch }) => () => {
      dispatch(push('/'))
      dispatch(authActions.logout())
    },
    handleMyAccountClick: ({ dispatch, currentUser }) => () => {
      dispatch(push(`/my-profile`))
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    },
    handleMyOrganisationClick: ({ dispatch }) => () => {
      dispatch(push(`/my-organisation`))
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    }
  })
)(RootMenu)
