import { matchPath } from 'react-router'
import { LOCATION_CHANGE } from 'connected-react-router'

import modalService from '../../../services/modalService'

export const onNavigateAway = ({ dispatch, ...rest }) => (next) => (action) => {
  if (action.type === LOCATION_CHANGE) {
    const previousPage = rest.getState().router.location.pathname
    const previousPageWasChangeManagement = matchPath(previousPage, { path: '/change-management' })

    if (previousPageWasChangeManagement) {
      modalService.close()
    }
  }

  next(action)
}
