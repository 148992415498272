export default [
  { label: 'Anticoagulant and preservative solution for blood', value: 'C108' },
  { label: 'Bath additive', value: '52' },
  { label: 'Bladder irrigation', value: '53' },
  { label: 'Blood fraction modifier', value: '325' },
  { label: 'Buccal Tablet', value: '54' },
  { label: 'Capsule', value: '56' },
  { label: 'Capsule, hard', value: '57' },
  { label: 'Capsule, soft', value: '58' },
  { label: 'Chewable capsule, soft', value: '338' },
  { label: 'Chewable tablet', value: '40' },
  { label: 'Chewable/dispersible tablet', value: '360' },
  { label: 'Coated tablet', value: '60' },
  { label: 'Collodion', value: '61' },
  { label: 'Compressed lozenge', value: '326' },
  { label: 'Concentrate and diluent for solution for infusion', value: 'C106' },
  { label: 'Concentrate and diluent for solution for injection', value: '319' },
  { label: 'Concentrate and solvent for concentrate for solution for infusion', value: '454' },
  { label: 'Concentrate and solvent for cutaneous solution', value: 'C104' },
  { label: 'Concentrate and solvent for cutaneous use', value: 'C125' },
  { label: 'Concentrate and solvent for injection', value: '365' },
  { label: 'Concentrate and solvent for solution for infusion', value: 'C122' },
  { label: 'Concentrate and solvent for solution for injection', value: 'C118' },
  { label: 'Concentrate and solvent for solution for injection/infusion', value: '455' },
  { label: 'Concentrate and solvent for suspension for injection', value: '368' },
  { label: 'Concentrate for concentrate for solution for infusion', value: '456' },
  { label: 'Concentrate for cutaneous solution', value: '62' },
  { label: 'Concentrate for cutaneous spray, emulsion', value: 'C123' },
  { label: 'Concentrate for gargle', value: '63' },
  { label: 'Concentrate for haemodialysis solution', value: '64' },
  { label: 'Concentrate for oral solution', value: '370' },
  { label: 'Concentrate for oral/rectal solution', value: '371' },
  { label: 'Concentrate for peritoneal dialysis solution', value: 'C112' },
  { label: 'Concentrate for rectal solution', value: '65' },
  { label: 'Concentrate for solution for heamodialysis', value: '463' },
  { label: 'Concentrate for solution for infusion', value: '67' },
  { label: 'Concentrate for solution for injection', value: '66' },
  { label: 'Concentrate for solution for injection/infusion', value: 'C113' },
  { label: 'Concentrate for solution for intraocular irrigation', value: '464' },
  { label: 'Concentrate for solution for intravesical use', value: 'C103' },
  { label: 'Concentrate for solution for peritoneal dialysis', value: '465' },
  { label: 'Concentrate for suspension for infusion', value: '374' },
  { label: 'Concentrate for suspension for injection', value: '466' },
  { label: 'Cream', value: '14' },
  { label: 'Cutaneous and nasal ointment', value: '375' },
  { label: 'Cutaneous emulsion', value: '70' },
  { label: 'Cutaneous foam', value: '71' },
  { label: 'Cutaneous liquid', value: '72' },
  { label: 'Cutaneous paste', value: '73' },
  { label: 'Cutaneous patch', value: '438' },
  { label: 'Cutaneous powder', value: '74' },
  { label: 'Cutaneous solution', value: '75' },
  { label: 'Cutaneous solution/concentrate for oromucosal solution', value: '467' },
  { label: 'Cutaneous sponge', value: '76' },
  { label: 'Cutaneous spray', value: '77' },
  { label: 'Cutaneous spray, ointment', value: '376' },
  { label: 'Cutaneous spray, powder', value: '78' },
  { label: 'Cutaneous spray, solution', value: '79' },
  { label: 'Cutaneous spray, suspension', value: '80' },
  { label: 'Cutaneous stick', value: '69' },
  { label: 'Cutaneous suspension', value: '81' },
  { label: 'Dental emulsion', value: '82' },
  { label: 'Dental gel', value: '83' },
  { label: 'Dental insert', value: 'C114' },
  { label: 'Dental liquid', value: '85' },
  { label: 'Dental paste', value: '377' },
  { label: 'Dental powder', value: '86' },
  { label: 'Dental solution', value: '87' },
  { label: 'Dental stick', value: '88' },
  { label: 'Dental suspension', value: '89' },
  { label: 'Denture lacquer', value: '327' },
  { label: 'Dispersible tablet', value: '90' },
  { label: 'Dispersion', value: '328' },
  { label: 'Dispersion for concentrate for dispersion for infusion', value: '471' },
  { label: 'Dispersion for injection', value: '432' },
  { label: 'Ear cream', value: '91' },
  { label: 'Ear drops, emulsion', value: '93' },
  { label: 'Ear drops, powder and solvent for suspension', value: '339' },
  { label: 'Ear drops, solution', value: '94' },
  { label: 'Ear drops, suspension', value: '95' },
  { label: 'Ear drops, suspension in single-dose container', value: '475' },
  { label: 'Ear gel', value: '96' },
  { label: 'Ear ointment', value: '97' },
  { label: 'Ear powder', value: '99' },
  { label: 'Ear spray', value: '100' },
  { label: 'Ear spray, emulsion', value: '101' },
  { label: 'Ear spray, solution', value: '102' },
  { label: 'Ear spray, suspension', value: '103' },
  { label: 'Ear stick', value: '104' },
  { label: 'Ear tampon', value: '105' },
  { label: 'Ear wash', value: '106' },
  { label: 'Ear wash, emulsion', value: '107' },
  { label: 'Ear wash, solution', value: '108' },
  { label: 'Ear/Eye ointment', value: '379' },
  { label: 'Ear/eye drops, solution', value: '378' },
  { label: 'Ear/eye/nose drops, solution', value: '380' },
  { label: 'Effervescent granules', value: '109' },
  { label: 'Effervescent granules for oral suspension', value: '478' },
  { label: 'Effervescent powder', value: '110' },
  { label: 'Effervescent tablet', value: '111' },
  { label: 'Effervescent vaginal tablet', value: '112' },
  { label: 'Emulsion and suspension for emulsion for injection', value: '479' },
  { label: 'Emulsion for infusion', value: '113' },
  { label: 'Emulsion for injection', value: '114' },
  { label: 'Emulsion for injection/infusion', value: '381' },
  { label: 'Emulsion for injection/infusion in pre-filled syringe', value: '481' },
  { label: 'Endocervical gel', value: '115' },
  { label: 'Endosinusal wash, suspension', value: '382' },
  { label: 'Endotracheopulmonary instillation', value: '116' },
  { label: 'Endotracheopulmonary instillation, powder and solvent for suspension', value: '483' },
  { label: 'Endotracheopulmonary instillation, powder for solution', value: '118' },
  { label: 'Endotracheopulmonary instillation, powder for suspension', value: '484' },
  { label: 'Endotracheopulmonary instillation, solution', value: 'C102' },
  { label: 'Endotracheopulmonary instillation, suspension', value: '120' },
  { label: 'Enema', value: '20' },
  { label: 'Eye cream', value: '122' },
  { label: 'Eye drops, emulsion', value: '340' },
  { label: 'Eye drops, powder and solvent for solution', value: '124' },
  { label: 'Eye drops, powder and solvent for suspension', value: '125' },
  { label: 'Eye drops, prolonged-release', value: '126' },
  { label: 'Eye drops, prolonged-release solution in single-dose container', value: '488' },
  { label: 'Eye drops, solution', value: '127' },
  { label: 'Eye drops, solution in single-dose container', value: 'C115' },
  { label: 'Eye drops, solvent for reconstitution', value: '128' },
  { label: 'Eye drops, suspension', value: '129' },
  { label: 'Eye drops, suspension in single-dose container', value: '489' },
  { label: 'Eye gel', value: '130' },
  { label: 'Eye lotion', value: '131' },
  { label: 'Eye lotion, solvent for reconstitution', value: '132' },
  { label: 'Eye ointment', value: '133' },
  { label: 'Film-coated tablet', value: '134' },
  { label: 'Film-coated tablet and gastro-resistant granules in sachet', value: '492' },
  { label: 'Gargle', value: '29' },
  { label: 'Gargle, powder for solution', value: '136' },
  { label: 'Gargle, tablet for solution', value: '137' },
  { label: 'Gargle/mouth wash', value: '384' },
  { label: 'Gas and solvent for dispersion for injection/infusion', value: '433' },
  { label: 'Gas for dispersion for injection/infusion', value: '496' },
  { label: 'Gastro-resistant capsule', value: '142' },
  { label: 'Gastro-resistant capsule, hard', value: '143' },
  { label: 'Gastro-resistant capsule, soft', value: '144' },
  { label: 'Gastro-resistant coated tablet', value: '385' },
  { label: 'Gastro-resistant granules', value: '145' },
  { label: 'Gastro-resistant granules for oral suspension', value: '386' },
  { label: 'Gastro-resistant granules for oral suspension in sachet', value: '497' },
  { label: 'Gastro-resistant tablet', value: '146' },
  { label: 'Gastroenteral emulsion', value: '138' },
  { label: 'Gastroenteral liquid', value: '139' },
  { label: 'Gastroenteral solution', value: '140' },
  { label: 'Gastroenteral suspension', value: '141' },
  { label: 'Gel', value: '147' },
  { label: 'Gel for injection', value: '329' },
  { label: 'Gingival gel', value: '148' },
  { label: 'Gingival paste', value: '149' },
  { label: 'Gingival solution', value: '150' },
  { label: 'Granules', value: '151' },
  { label: 'Granules and solvent for oral suspension', value: '500' },
  { label: 'Granules and solvent for suspension for injection', value: '387' },
  { label: 'Granules for oral and rectal suspension', value: '388' },
  { label: 'Granules for oral drops, solution', value: '389' },
  { label: 'Granules for oral solution', value: '152' },
  { label: 'Granules for oral suspension', value: '153' },
  { label: 'Granules for syrup', value: '154' },
  { label: 'Granules for vaginal solution', value: '441' },
  { label: 'Herbal tea', value: '155' },
  { label: 'Implant', value: '156' },
  { label: 'Implantation  chain', value: 'C120' },
  { label: 'Implantation tablet', value: '158' },
  { label: 'Impregnated dressing', value: '159' },
  { label: 'Impregnated pad', value: '160' },
  { label: 'Infusion', value: '341' },
  { label: 'Inhalation gas', value: '161' },
  { label: 'Inhalation powder', value: '162' },
  { label: 'Inhalation powder, hard capsule', value: '163' },
  { label: 'Inhalation powder, pre-dispensed', value: '164' },
  { label: 'Inhalation powder, tablet', value: '390' },
  { label: 'Inhalation solution', value: '436' },
  { label: 'Inhalation vapour', value: '165' },
  { label: 'Inhalation vapour, capsule', value: '166' },
  { label: 'Inhalation vapour, effervescent tablet', value: '391' },
  { label: 'Inhalation vapour, emulsion', value: '392' },
  { label: 'Inhalation vapour, impregnated pad', value: '393' },
  { label: 'Inhalation vapour, liquid', value: '167' },
  { label: 'Inhalation vapour, ointment', value: '168' },
  { label: 'Inhalation vapour, powder', value: '169' },
  { label: 'Inhalation vapour, solution', value: '170' },
  { label: 'Inhalation vapour, tablet', value: '171' },
  { label: 'Injection', value: '01' },
  { label: 'Instant herbal tea', value: '173' },
  { label: 'Intestinal gel', value: '342' },
  { label: 'Intrauterine delivery system', value: '330' },
  { label: 'Intrauterine device', value: '174' },
  { label: 'Intrauterine liquid', value: '175' },
  { label: 'Intrauterine tablet', value: '320' },
  { label: 'Intravenous infusion', value: '176' },
  { label: 'Intravesical solution/solution for injection', value: '519' },
  { label: 'Irrigation solution', value: '177' },
  { label: 'Kit for radiopharmaceutical preparation', value: '178' },
  { label: 'Liquefied gas for dental use', value: '394' },
  { label: 'Living tissue equivalent', value: '331' },
  { label: 'Lyophilisate for suspension', value: '343' },
  { label: 'Medicated chewing-gum', value: '180' },
  { label: 'Medicated nail lacquer', value: '181' },
  { label: 'Medicated plaster', value: '332' },
  { label: 'Medicated sponge', value: '344' },
  { label: 'Medicated thread', value: '345' },
  { label: 'Medicated vaginal tampon', value: '182' },
  { label: 'Medicinal gas, compressed', value: '346' },
  { label: 'Medicinal gas, cryogenic', value: '347' },
  { label: 'Medicinal gas, liquefied', value: '348' },
  { label: 'Modified-release capsule, hard', value: '183' },
  { label: 'Modified-release capsule, soft', value: '184' },
  { label: 'Modified-release film-coated tablet', value: '395' },
  { label: 'Modified-release granules', value: '185' },
  { label: 'Modified-release granules for oral suspension', value: '396' },
  { label: 'Modified-release tablet', value: '186' },
  { label: 'Mouth wash', value: '187' },
  { label: 'Mouth wash, tablet for solution', value: '188' },
  { label: 'Muco-adhesive buccal tablet', value: '189' },
  { label: 'Nasal cream', value: '190' },
  { label: 'Nasal drops', value: '191' },
  { label: 'Nasal drops, emulsion', value: '192' },
  { label: 'Nasal drops, solution', value: '193' },
  { label: 'Nasal drops, solution in single-dose container', value: '527' },
  { label: 'Nasal drops, suspension', value: '194' },
  { label: 'Nasal gel', value: '195' },
  { label: 'Nasal ointment', value: '196' },
  { label: 'Nasal powder', value: '197' },
  { label: 'Nasal spray', value: '198' },
  { label: 'Nasal spray and oromucosal solution', value: '397' },
  { label: 'Nasal spray, emulsion', value: '199' },
  { label: 'Nasal spray, solution', value: '200' },
  { label: 'Nasal spray, solution in single-dose container', value: '529' },
  { label: 'Nasal spray, solution/oromucosal solution', value: '530' },
  { label: 'Nasal spray, suspension', value: '201' },
  { label: 'Nasal stick', value: '202' },
  { label: 'Nasal wash', value: '203' },
  { label: 'Nebuliser emulsion', value: '204' },
  { label: 'Nebuliser liquid', value: '205' },
  { label: 'Nebuliser solution', value: '206' },
  { label: 'Nebuliser suspension', value: '207' },
  { label: 'Ointment', value: '13' },
  { label: 'Ophthalmic insert', value: '209' },
  { label: 'Ophthalmic strip', value: '439' },
  { label: 'Oral drops', value: '210' },
  { label: 'Oral drops, emulsion', value: '211' },
  { label: 'Oral drops, powder for suspension', value: '437' },
  { label: 'Oral drops, solution', value: '212' },
  { label: 'Oral drops, suspension', value: '213' },
  { label: 'Oral emulsion', value: '214' },
  { label: 'Oral gel', value: '215' },
  { label: 'Oral gum', value: '216' },
  { label: 'Oral liquid', value: '217' },
  { label: 'Oral lyophilisate', value: '218' },
  { label: 'Oral paste', value: '219' },
  { label: 'Oral powder', value: '220' },
  { label: 'Oral solution', value: '221' },
  { label: 'Oral solution/concentrate for nebuliser solution', value: 'C128' },
  { label: 'Oral suspension', value: '222' },
  { label: 'Oral/rectal suspension', value: '398' },
  { label: 'Orodispersible tablet', value: '223' },
  { label: 'Oromucosal capsule', value: '224' },
  { label: 'Oromucosal cream', value: '349' },
  { label: 'Oromucosal drops', value: '225' },
  { label: 'Oromucosal gel', value: '226' },
  { label: 'Oromucosal liquid', value: '227' },
  { label: 'Oromucosal paste', value: '228' },
  { label: 'Oromucosal patch', value: '399' },
  { label: 'Oromucosal powder in pouch', value: '443' },
  { label: 'Oromucosal solution', value: '229' },
  { label: 'Oromucosal spray', value: '230' },
  { label: 'Oromucosal suspension', value: '231' },
  { label: 'Oromucosal/Laryngopharyngeal solution/spray, solution', value: '547' },
  { label: 'Oromucosal/laryngopharyngeal solution', value: '400' },
  { label: 'Oromucosal/laryngopharyngeal solution/spray', value: 'C126' },
  { label: 'Pastille', value: '333' },
  { label: 'Periodontal gel', value: '350' },
  { label: 'Periodontal insert', value: '351' },
  { label: 'Periodontal powder', value: '352' },
  { label: 'Pessary', value: '232' },
  { label: 'Pillules', value: '233' },
  { label: 'Pillules, single-dose container', value: '402' },
  { label: 'Plaster for provocation test', value: '353' },
  { label: 'Poultice', value: '234' },
  { label: 'Pour-on', value: '235' },
  { label: 'Powder and solution for solution for injection', value: '551' },
  { label: 'Powder and solvent for concentrate for solution for infusion', value: 'C105' },
  { label: 'Powder and solvent for cutaneous solution', value: 'C117' },
  { label: 'Powder and solvent for dental gel', value: '321' },
  { label: 'Powder and solvent for dispersion for injection', value: '552' },
  { label: 'Powder and solvent for emulsion for injection', value: '553' },
  { label: 'Powder and solvent for endocervical gel', value: '237' },
  { label: 'Powder and solvent for endosinusial solution', value: '406' },
  { label: 'Powder and solvent for epilesional solution', value: '554' },
  { label: 'Powder and solvent for gingival gel', value: '407' },
  { label: 'Powder and solvent for implantation paste', value: 'C119' },
  { label: 'Powder and solvent for instillation solution for intraocular use', value: '555' },
  { label: 'Powder and solvent for intravesical solution', value: '556' },
  { label: 'Powder and solvent for intravesical suspension', value: '557' },
  { label: 'Powder and solvent for nasal drops, solution', value: '558' },
  { label: 'Powder and solvent for nebuliser solution', value: '559' },
  { label: 'Powder and solvent for oral solution', value: '238' },
  { label: 'Powder and solvent for oral suspension', value: '239' },
  { label: 'Powder and solvent for prolonged-release suspension for injection in pre-filled pen', value: '560' },
  { label: 'Powder and solvent for prolonged-release suspension for injection in pre-filled syringe', value: '561' },
  { label: 'Powder and solvent for sealant', value: '240' },
  { label: 'Powder and solvent for solution for infusion', value: 'C110' },
  { label: 'Powder and solvent for solution for injection', value: 'C124' },
  { label: 'Powder and solvent for solution for injection in cartridge', value: '562' },
  { label: 'Powder and solvent for solution for injection in pre-filled pen', value: '563' },
  { label: 'Powder and solvent for solution for injection in pre-filled syringe', value: '564' },
  { label: 'Powder and solvent for solution for injection/ skin-prick test', value: '565' },
  { label: 'Powder and solvent for solution for injection/infusion', value: '435' },
  { label: 'Powder and solvent for suspension for injection', value: '243' },
  { label: 'Powder and solvent for suspension for injection in pre-filled syringe', value: '566' },
  { label: 'Powder and suspension for suspension for injection', value: '444' },
  { label: 'Powder for bladder irrigation', value: '244' },
  { label: 'Powder for concentrate for dispersion for infusion', value: '569' },
  { label: 'Powder for concentrate for haemodialysis solution', value: '410' },
  { label: 'Powder for concentrate for intravesical suspension', value: '570' },
  { label: 'Powder for concentrate for solution for haemodialysis', value: '571' },
  { label: 'Powder for concentrate for solution for infusion', value: 'C116' },
  { label: 'Powder for concentrate for solution for injection/infusion', value: '572' },
  { label: 'Powder for dip solution', value: '355' },
  { label: 'Powder for infusion', value: '245' },
  { label: 'Powder for injection', value: '246' },
  { label: 'Powder for intraocular instillation solution', value: '588' },
  { label: 'Powder for intravesical solution', value: '411' },
  { label: 'Powder for intravesical solution/solution for injection', value: '589' },
  { label: 'Powder for intravesical suspension', value: '412' },
  { label: 'Powder for mouth wash', value: '413' },
  { label: 'Powder for nebuliser solution', value: '247' },
  { label: 'Powder for nebuliser suspension', value: '248' },
  { label: 'Powder for oral solution', value: '249' },
  { label: 'Powder for oral suspension', value: '250' },
  { label: 'Powder for oral/rectal suspension', value: '414' },
  { label: 'Powder for oral/rectal suspension in sachet', value: '591' },
  { label: 'Powder for prolonged-release suspension for injection', value: '594' },
  { label: 'Powder for rectal solution', value: '236' },
  { label: 'Powder for rectal suspension', value: '251' },
  { label: 'Powder for solution for infusion', value: '252' },
  { label: 'Powder for solution for injection', value: '253' },
  { label: 'Powder for solution for injection or infusion', value: 'C111' },
  { label: 'Powder for solution for injection/skin-prick test', value: '596' },
  { label: 'Powder for solution for intraocular irrigation', value: '429' },
  { label: 'Powder for solution for intravesical use', value: 'C121' },
  { label: 'Powder for solution for nasal spray', value: '417' },
  { label: 'Powder for suspension for injection', value: '254' },
  { label: 'Powder for syrup', value: '255' },
  { label: 'Powder, dispersion and solvent for concentrate for dispersion for infusion', value: '599' },
  { label: 'Powder, solvent and matrix for implantation matrix', value: '600' },
  { label: 'Premix for medicated feeding stuff', value: '322' },
  { label: 'Pressurised inhalation', value: '256' },
  { label: 'Pressurised inhalation, emulsion', value: '257' },
  { label: 'Pressurised inhalation, solution', value: '258' },
  { label: 'Pressurised inhalation, suspension', value: '259' },
  { label: 'Prolonged-release capsule', value: '260' },
  { label: 'Prolonged-release capsule, hard', value: '261' },
  { label: 'Prolonged-release capsule, soft', value: '262' },
  { label: 'Prolonged-release granules', value: '263' },
  { label: 'Prolonged-release granules for oral suspension', value: '418' },
  { label: 'Prolonged-release granules for oral suspension in sachet', value: '601' },
  { label: 'Prolonged-release suspension for injection', value: '603' },
  { label: 'Prolonged-release suspension for injection in pre-filled syringe', value: '604' },
  { label: 'Prolonged-release tablet', value: '264' },
  { label: 'Radionuclide generator', value: '265' },
  { label: 'Radiopharmaceutical precursor', value: '266' },
  { label: 'Radiopharmaceutical precursor, solution', value: '419' },
  { label: 'Rectal capsule', value: '267' },
  { label: 'Rectal cream', value: '268' },
  { label: 'Rectal emulsion', value: '269' },
  { label: 'Rectal foam', value: '270' },
  { label: 'Rectal gel', value: '271' },
  { label: 'Rectal ointment', value: '272' },
  { label: 'Rectal solution', value: '273' },
  { label: 'Rectal suspension', value: '274' },
  { label: 'Rectal tampon', value: '275' },
  { label: 'Sealant', value: '276' },
  { label: 'Shampoo', value: '44' },
  { label: 'Soft capsule', value: '335' },
  { label: 'Soluble tablet', value: '278' },
  { label: 'Solution and suspension for suspension for injection in pre-filled syringe', value: '607' },
  { label: 'Solution for blood fraction modification', value: '336' },
  { label: 'Solution for haemodiafiltration', value: '279' },
  { label: 'Solution for haemodialysis', value: '280' },
  { label: 'Solution for haemodialysis/haemofiltration', value: 'C127' },
  { label: 'Solution for haemofiltration', value: '281' },
  { label: 'Solution for infusion', value: '282' },
  { label: 'Solution for infusion and oral solution', value: '421' },
  { label: 'Solution for infusion in administration system', value: '610' },
  { label: 'Solution for infusion in pre-filled syringe', value: '611' },
  { label: 'Solution for injection', value: '283' },
  { label: 'Solution for injection in administration system', value: '612' },
  { label: 'Solution for injection in cartridge', value: '445' },
  { label: 'Solution for injection in needle-free injector', value: '613' },
  { label: 'Solution for injection in pre-filled injector', value: '633' },
  { label: 'Solution for injection in pre-filled pen', value: '446' },
  { label: 'Solution for injection in pre-filled syringe', value: 'C101' },
  { label: 'Solution for injection/infusion', value: '422' },
  { label: 'Solution for injection/infusion in pre-filled syringe', value: '615' },
  { label: 'Solution for intraperitoneal use', value: '423' },
  { label: 'Solution for intravesical use', value: '284' },
  { label: 'Solution for iontophoresis', value: '285' },
  { label: 'Solution for organ preservation', value: '286' },
  { label: 'Solution for peritoneal dialysis', value: '287' },
  { label: 'Solution for provocation test', value: '356' },
  { label: 'Solution for skin-prick test', value: '357' },
  { label: 'Solution for skin-scratch test', value: '358' },
  { label: 'Solvent for parenteral use', value: '288' },
  { label: 'Solvent for solution for infusion', value: '431' },
  { label: 'Solvent for solution for intraocular irrigation', value: '430' },
  { label: 'Spot-on', value: '289' },
  { label: 'Spot-on solution', value: '323' },
  { label: 'Sterile concentrate', value: '290' },
  { label: 'Stomach irrigation', value: '291' },
  { label: 'Sublingual spray', value: '292' },
  { label: 'Sublingual tablet', value: '293' },
  { label: 'Suppository', value: '08' },
  { label: 'Suspension and effervescent granules for oral suspension', value: '424' },
  { label: 'Suspension and solution for spray', value: '448' },
  { label: 'Suspension and solvent for suspension for injection', value: '625' },
  { label: 'Suspension for infusion', value: '425' },
  { label: 'Suspension for injection', value: '295' },
  { label: 'Suspension for injection in cartridge', value: '449' },
  { label: 'Suspension for injection in pre-filled injector', value: '627' },
  { label: 'Suspension for injection in pre-filled pen', value: 'C107' },
  { label: 'Suspension for injection in pre-filled syringe', value: 'C109' },
  { label: 'Syrup', value: '10' },
  { label: 'Tablet', value: '297' },
  { label: 'Tablet and powder for oral solution', value: '427' },
  { label: 'Tablet and solvent for rectal suspension', value: '426' },
  { label: 'Tablet for oral suspension', value: '428' },
  { label: 'Tablet for rectal solution', value: '298' },
  { label: 'Tablet for rectal suspension', value: '299' },
  { label: 'Tablet for vaginal solution', value: '300' },
  { label: 'Toothpaste', value: '301' },
  { label: 'Transdermal patch', value: '302' },
  { label: 'Transdermal system', value: '359' },
  { label: 'Urethral gel', value: '303' },
  { label: 'Urethral stick', value: '304' },
  { label: 'Vaginal capsule', value: '305' },
  { label: 'Vaginal capsule, hard', value: '306' },
  { label: 'Vaginal capsule, soft', value: '307' },
  { label: 'Vaginal cream', value: '308' },
  { label: 'Vaginal delivery system', value: '337' },
  { label: 'Vaginal device', value: '309' },
  { label: 'Vaginal emulsion', value: '310' },
  { label: 'Vaginal foam', value: '311' },
  { label: 'Vaginal gel', value: '312' },
  { label: 'Vaginal liquid', value: '313' },
  { label: 'Vaginal ointment', value: '314' },
  { label: 'Vaginal solution', value: '315' },
  { label: 'Vaginal suspension', value: '316' },
  { label: 'Vaginal tablet', value: '317' },
  { label: 'Wound stick', value: '318' }
]
