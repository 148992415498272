import { connect } from 'react-redux'
import { compose, withHandlers, withProps } from 'recompose'
import MedDRALookupInputModal from './MedDRALookupInputModal'
import { selectors as medDRASelectors, actions as medDRAActions } from '../../store/modules/meddra'

const mapStateToProps = state => ({
  results: medDRASelectors.getDrugsForSearchResults(state),
  noResults: !medDRASelectors.getHasResults(state),
  hasMore: medDRASelectors.getHasMore(state) && !medDRASelectors.getIsLoading(state),
  error: medDRASelectors.getError(state)
})

const connectedComponent = compose(
  connect(mapStateToProps),
  withProps({
    searchPlaceholder: 'Search MedDRA'
  }),
  withHandlers({
    onSearch: ({ dispatch, onComplete }) => ({ query }) => {
      dispatch(medDRAActions.searchMedDRA({ query }))
    },
    onSearchNext: ({ dispatch, onComplete }) => ({ query }) => {
      dispatch(medDRAActions.searchMedDRANext({ query }))
    },
    onChange: ({ dispatch, onChange, ...props }) => (value) => {
      onChange(value)
    },
    closeModal: ({ dispatch, onClose, modalIndex }) => () => {
      onClose({ modalIndex })
      dispatch(medDRAActions.resetStore())
    }
  })
)(MedDRALookupInputModal)

export default connectedComponent
