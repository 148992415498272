export const LOGIN = 'digitialStore/auth/LOGIN'
export const RESET_PASSWORD = 'digitialStore/auth/RESET_PASSWORD'
export const CHANGE_PASSWORD = 'digitialStore/auth/CHANGE_PASSWORD'
export const LOGOUT = 'digitialStore/auth/LOGOUT'
export const SELECT_ORGANISATION = 'digitialStore/auth/SELECT_ORGANISATION'
export const UPDATE_AUTH_USER = 'digitialStore/auth/UPDATE_AUTH_USER'
export const REQUEST_NEW_COMPANY_ACCOUNT = 'digitialStore/auth/REQUEST_NEW_COMPANY_ACCOUNT'
export const EDIT_WATCH_LIST = 'digitialStore/auth/EDIT_WATCH_LIST'
export const GET_SSO_AUTH_CONTEXT = 'digitialStore/auth/GET_SSO_AUTH_CONTEXT'
export const SET_AUTH_CONTEXT = 'digitialStore/auth/SET_AUTH_CONTEXT'
export const CREATE_WORKSPACE = 'digitalStore/auth/CREATE_WORKSPACE'
export const LOAD_SELECTED_ORGANISATION = 'digitalStore/auth/LOAD_SELECTED_ORGANISATION'
