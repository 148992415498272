import { colors } from '../../../../config/theme'

const styles = {
  underlineFocusStyle: { borderColor: colors.accent },
  errorStyle: { color: colors.error },
  errorText: { color: colors.error },
  inputInkbar: {
    '&:after': {
      backgroundColor: colors.text
    }
  },
  container: {
    position: 'relative'
  }
}

export default theme => styles
