import React from 'react'
import { styled } from '@material-ui/core'
import P from '../../../../components/P'
import { translations } from '../../../../config'

const BlankState = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  borderLeft: `4px solid ${theme.palette.grey[300]}`
}))

const NoConditions = () => {
  return (
    <BlankState>
      <P value={translations('No conditions')} />
    </BlankState>
  )
}

export default NoConditions
