import React from 'react'

import { FormHelperText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const ErrorText = props => {
  const { meta: { touched, error } = {}, noErrorTextLabel, classes } = props

  return (
    noErrorTextLabel
    ? null
    : <FormHelperText className={classes.errorText} role='alert'>
      {touched && error}
    </FormHelperText>
  )
}

export default withStyles(style)(ErrorText)
