import { REQUEST_STATUS_TYPE } from "./constants"
export const getEntityWithStatus = ({ canBeEmpty = false }) => entityWithStatus => {
    if (!entityWithStatus) {
        return {
            isLoading: true
        }
    }
    const data = {
        entity: entityWithStatus.data,
        isLoading: entityWithStatus.status === REQUEST_STATUS_TYPE.PENDING,
        isError: entityWithStatus.status === REQUEST_STATUS_TYPE.REJECTED
    }
    if (canBeEmpty) {
        data.isEmpty = entityWithStatus.status === REQUEST_STATUS_TYPE.IS_EMPTY
    }
    return data
}
