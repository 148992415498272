import React from 'react'

import BarGraph from '../../../components/Chart/BarGraph'
import CountChart from '../../../components/Chart/CountChart'
import ListChart from '../../../components/Chart/ListChart'
import withReportData from '../withReportData'

const ReportChart = props => {
  const { reportSchema, chartData, listData, currency, target, seeAllLink, listItem } = props
  const { title, type: chartType, chartOptions } = reportSchema

  switch (chartType) {
    case 'BAR':
      return <BarGraph
        data={chartData}
        chartTitle={title}
        layout='vertical'
        chartAspect={1.2}
        currency={currency}
        {...chartOptions}
      />
    // case 'PIE':
    //   return <DonutChart
    //     data={chartData}
    //     chartTitle={title}
    //     currency={currency}
    //     pie
    //     {...chartOptions}
    //   />
    case 'LIST':
      return <ListChart
        data={listData}
        chartTitle={title}
        currency={currency}
        seeAllLink={seeAllLink}
        listItem={listItem}
        {...chartOptions}
      />
    case 'COUNT':
      return <CountChart
        data={chartData}
        chartTitle={title}
        currency={currency}
        seeAllLink={seeAllLink}
        {...chartOptions}
      />
    default:
      return null
  }
}

export default withReportData(ReportChart)
