import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Button from './Button'

const mergeProps = (stateProps, { dispatch }, { onClick, to, ...ownPropsRest }) => {
  if (to) {
    onClick = () => dispatch(push(to))
  }
  return {
    ...ownPropsRest,
    onClick
  }
}

export default connect(null, null, mergeProps)(Button)
