import _ from 'lodash'
import { createSelector } from 'reselect'

export const defaultApplicationState = state => _.get(state, 'developer.applications.default')
export const apiKeyState = state => _.get(state, 'developer.apiKeys')
export const temporaryApiKeyState = state => _.get(state, 'developer.temporaryApiKey')

export const getApplications = state => _.get(state, 'developer.applications.results')
export const getApplication = state => _.get(state, 'developer.application')

export const getDefaultApplication = createSelector(
  [defaultApplicationState],
  (application) => application
)

export const getApplicationApiKeys = (applicationId) => createSelector([
  apiKeyState
], (keys) => {
  return _.get(keys, applicationId, [])
})

export const getTemporaryApiKey = createSelector([
  temporaryApiKeyState
], (temporaryKey) => {
  return temporaryKey
})