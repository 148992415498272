import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import Heading from '../../../components/Heading'

import { translations, emailDateFormat } from '../../../config'
import style from './style'

// eslint-disable-next-line react/prop-types
const renderActions = ({ classes, onEdit, onDelete }) => {
  return (
    <div className={classes.actionsContainer}>
      <ul className={classes.actions}>
        <li className={classes.action}>
          <IconButton
            aria-label={translations('Edit')}
            onClick={onEdit}
            children={<EditIcon />}
          />
        </li>
        <li className={classes.action}>
          <IconButton
            aria-label={translations('Delete')}
            onClick={onDelete}
            children={<DeleteIcon />}
          />
        </li>
      </ul>
    </div>
  )
}

const TemplateItem = (props) => {
  const { name, updatedAt, isDefault, onEdit, onDelete, disabled, classes } = props

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Heading variant='h3'>{name}</Heading>
        <div className={classes.meta}>
          {isDefault && <Chip label={translations('Default')} className={classNames(classes.chip, classes.primaryChip)} />}
          <Chip label={translations('Updated At Date', { date: moment(updatedAt).local().format(emailDateFormat) })} className={classNames(classes.chip, classes.secondaryChip)} />
        </div>
      </div>
      {!disabled && renderActions({ classes, onEdit, onDelete })}
    </div>
  )
}

TemplateItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isDefault: PropTypes.bool
}

TemplateItem.defaultProps = {
  isDefault: false,
  disabled: false
}

export default withStyles(style)(TemplateItem)
