import { v4 } from 'uuid'
import {
  LOAD_FORM_VIEW,
  UPDATE_PUBLIC,
  UPDATE_PUBLISHED,
  UPDATE_SOURCES,
  UPDATE_GENERAL_DETAILS,
  UPDATE_ADVANCED_DETAILS,
  UPDATE_TIMEFRAME_DETAILS,
  TOGGLE_SECTION,
  SET_UNEDITED,
  ARTEFACT_TYPES,
  ADD_REPEATABLE_INSTANCE,
  DELETE_REPEATABLE_INSTANCE,
  UPDATE_REPEATABLE_INSTANCE
} from './constants'
import { initialState as contextInitialState } from './ReportConfigurationSidebarContext'
import _ from 'lodash'

const PUBLIC = 'public'
const PUBLISHED = 'publish'
const SOURCES = 'sources'

const updateVisibility = (state, payload, type) => {
  const visibility = _.get(state, 'visibility', {})
  const initialState = _.get(state, 'initialState', {})
  const sourcesDisabled = type !== PUBLIC ? state.sourcesDisabled : payload
  const newState = {
    ...state,
    visibility: {
      ...visibility,
      [type]: payload
    }
  }
  return {
    ...newState,
    isEdited: !_.isEqual(initialState, newState),
    sourcesDisabled
  }
}

const loadFormView = (state, payload = {}) => {
  const {
    public: formViewPublic,
    publish: formViewPublished,
    category,
    subCategory,
    details = {},
    reminderTimeframe = {},
    formSchemaId,
    sources,
    formViewVersions = []
  } = payload
  const isNew = _.isUndefined(formSchemaId)
  const {
    audienceId = isNew ? 1 : undefined,
    senderevid,
    reporttype,
    observestudytype,
    emailTemplateId,
    isLateralFlowReport,
    isSyringeReport,
    showNullFlavours = isNew ? true : undefined,
    waiveArtefactValidation,
    artefactFormat = isNew ? ARTEFACT_TYPES.XML_ONLY : undefined,
    aefilogic,
    useEuRules,
    mandatoryMedDRA
  } = details

  const newInitialState = {
    ...contextInitialState,
    general: {
      category,
      subCategory,
      audienceId,
      senderevid,
      reporttype,
      observestudytype,
      emailTemplateId
    },
    advanced: {
      isLateralFlowReport,
      isSyringeReport,
      aefilogic,
      showNullFlavours,
      waiveArtefactValidation,
      artefactFormat,
      useEuRules,
      mandatoryMedDRA
    },
    visibility: {
      public: !!formViewPublic,
      publish: !!formViewPublished,
      sources: _.map(sources, 'id'),
    },
    timeframe: {
      value: _.toString(_.get(reminderTimeframe, 'value'))
    },
    repeatableInstances: _.get(formViewVersions, '0.repeatableInstances', {}),
    sourcesDisabled: formViewPublic
  }
  const openSections = _.get(state, 'openSections')
  return {
    ...newInitialState,
    isEdited: false,
    initialState: newInitialState,
    openSections
  }
}

const updateDetails = (state, payload, detailType) => {
  const newState = {
    ...state,
    [detailType]: {
      ...state[detailType],
      ...payload
    }
  }
  return {
    ...newState,
    isEdited: !_.isEqual(state, newState)
  }
}

const updateRepeatableInstances = (state, payload, action) => {
  let newState
  const currentState = { ...state.repeatableInstances }
  const data = currentState.data || []
  switch (action) {
    case 'ADD':
      newState = {
        ...state,
        repeatableInstances: {
          ...currentState,
          data: [
            ...data,
            {
              id: v4(),
              ...payload
            }
          ]
        }
      }
      break
    case 'UPDATE':
      const index = _.findIndex(data, instance => instance.id === payload.id)
      const updatedInstances = [...data]
      updatedInstances[index] = payload
      newState = {
        ...state,
        repeatableInstances: {
          ...currentState,
          data: updatedInstances
        }
      }
      break
    case 'DELETE':
      newState = {
        ...state,
        repeatableInstances: {
          ...state.repeatableInstances,
          data: _.filter(data, instance => instance.id !== payload)
        }
      }
      break
  }
  return {
    ...newState,
    repeatableInstancesEdited: !_.isEqual(state, newState)
  }
}

const toggleSection = (state, payload) => {
  const sectionToToggle = _.get(state, `openSections.${payload}`)
  return {
    ...state,
    openSections: {
      ...state.openSections,
      [payload]: !sectionToToggle
    }
  }
}

const setUnedited = (state) => {
  return {
    ...state,
    isEdited: false
  }
}

export default (state, action) => {
  const { type, payload } = action
  switch (type) {
    case LOAD_FORM_VIEW:
      return loadFormView(state, payload)
    case UPDATE_PUBLIC:
      return updateVisibility(state, payload, PUBLIC)
    case UPDATE_PUBLISHED:
      return updateVisibility(state, payload, PUBLISHED)
    case UPDATE_SOURCES:
      return updateVisibility(state, payload, SOURCES)
    case UPDATE_GENERAL_DETAILS:
      return updateDetails(state, payload, 'general')
    case UPDATE_ADVANCED_DETAILS:
      return updateDetails(state, payload, 'advanced')
    case UPDATE_TIMEFRAME_DETAILS:
      return updateDetails(state, payload, 'timeframe')
    case ADD_REPEATABLE_INSTANCE:
      return updateRepeatableInstances(state, payload, 'ADD')
    case UPDATE_REPEATABLE_INSTANCE:
      return updateRepeatableInstances(state, payload, 'UPDATE')
    case DELETE_REPEATABLE_INSTANCE:
      return updateRepeatableInstances(state, payload, 'DELETE')
    case TOGGLE_SECTION:
      return toggleSection(state, payload)
    case SET_UNEDITED:
      return setUnedited(state)
    default: return state
  }
}