import { connect } from 'react-redux'
import _ from 'lodash'
import { goBack, push } from 'connected-react-router'
import qs from 'qs'
import fp from 'lodash/fp'
import { compose, withHandlers, withStateHandlers, withPropsOnChange } from 'recompose'

import SelectReport from './SelectReport'
import { actions as formViewActions, selectors as formViewSelectors } from '../../../store/modules/formViews'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { authFormViewsSelectedReceiverSelectors } from '../../../store/modules/combinedSelectors'

import { translations } from '../../../config'

const enhancer = compose(
  connect(state => ({
    pageTitle: platformSelectors.getPageTitle(state)('App Name - Select Report'),
    forms: authFormViewsSelectedReceiverSelectors.getPublishedForms(state),
    viewJSON: formViewSelectors.getViewJSON(state)
  })),
  withPropsOnChange(['location', 'forms'], ({ location, forms }) => {
    let formType
    let formOptions = []
    const typeQuery = _.get(location, 'search')
    const { type } = qs.parse(typeQuery, { ignoreQueryPrefix: true })

    if (type){
      formType = fp.filter(
        fp.overSome([
          fp.matchesProperty('formType', type),
          fp.matchesProperty('formSchema.formType', type)
        ])
      )(forms)
    }

    if (forms) {
      const mapToOption = (el) => ({ value: el.id, label: el.publicName || el.name })
      const groupedForms = _.groupBy(forms, (form) => {
        return form.versionId || 'standard'
      })
      const groupKeys = ['standard', '2', '1']

      _.forEach(groupKeys, (key) => {
        const groupForms = _.get(groupedForms, key)
        if (groupForms) {
          formOptions = [
            ...formOptions,
            {
              isSubheader: true,
              label: translations(`Report Selection - ${key}`)
            },
            ..._.map(groupForms, mapToOption)
          ]
        }
      })

    }
    return {
      forms: formType || forms,
      formOptions
    }
  }),
  withHandlers({
    onBackClick: ({ dispatch }) => () => {
      dispatch(goBack())
    }
  })
)

export default enhancer(SelectReport)
