import { LOGIN, LOGOUT, GET_SSO_AUTH_CONTEXT, SELECT_ORGANISATION, LOAD_SELECTED_ORGANISATION, UPDATE_AUTH_USER, EDIT_WATCH_LIST, SET_AUTH_CONTEXT } from './constants'
import { CHANGE_CURRENT_USER_PASSWORD } from '../userDetails/constants'
import reducers from './reducers'

const defaultState = {
  user: undefined,
  status: undefined,
  error: undefined,
  action: undefined,
  migrationRequired: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN:
      return reducers.login(state, action)
    case LOGOUT:
    case CHANGE_CURRENT_USER_PASSWORD:
      return reducers.logout(state, action)
    case SELECT_ORGANISATION:
      return reducers.selectOrganisation(state, action)
    case UPDATE_AUTH_USER:
      return reducers.updateUser(state, action)
    case EDIT_WATCH_LIST:
      return reducers.editWatchList(state, action)
    case SET_AUTH_CONTEXT:
      return reducers.getAuthContext(state, action)
    case GET_SSO_AUTH_CONTEXT:
      return reducers.getSSOAuthContext(state, action)
    case LOAD_SELECTED_ORGANISATION:
      return reducers.loadSelectedOrganisation(state, action)
    default:
      return state
  }
}
