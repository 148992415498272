import _ from 'lodash'
import { createSelector } from 'reselect'

const getNumber = n => Number(n) || 0

export const getMultipartUpload = state => _.get(state, 'multipartUpload')

export const getFile = createSelector(getMultipartUpload, x => _.get(x, 'file'))

export const getIsUploadHappening = createSelector(getFile, file => !!file)

export const getFileName = createSelector(getMultipartUpload, x => _.get(x, 'fileName'))

export const getFileSize = createSelector(getFile, file => getNumber(_.get(file, 'size')))

export const getProgress = createSelector(getMultipartUpload, x => _.get(x, 'progress'))

export const getBytesLoaded = createSelector(
  getProgress,
  progress => _.reduce(progress, (acc, partProgress) => getNumber(acc) + getNumber(partProgress), 0)
)

export const getPercentageLoaded = createSelector(
  [getBytesLoaded, getFileSize],
  (bytesLoaded, fileSize) => getNumber((bytesLoaded / fileSize) * 100)
)
