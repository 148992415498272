export default theme => {
  return {
    tableHead: {
      backgroundColor: theme.palette.primary.main,
      textTransform: 'uppercase',
      color: theme.palette.primary.contrastText,
      height: 40,
      fontSize: '13px',
      fontWeight: 'bold',
      alignItems: 'center',
      flexWrap: 'nowrap'
    }
  }
}
