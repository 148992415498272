import { useEffect, useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'
import VigilanceHubForm from '@redant/mhra-form-ui-library'

import digitalStoreSdk, { baseUrl } from '../../../digitalStoreSdk'
import { translations } from '../../../config'

import Container from '../../../components/Container'
import ErrorScreen from '../../../components/ErrorScreen'
import SuccessScreen from '../../../components/SuccessScreen'

const FollowUpScreenContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center'
})

const FormContainer = styled('div')({
  width: '100%',
  maxWidth: '720px',
  paddingLeft: '16px',
  paddingRight: '16px'
})

const FollowUpScreen = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [error, setError] = useState(null)
  const [extReportId, setExtReportId] = useState(null)

  const onSuccess = useCallback(({ response }) => {
    setExtReportId(response.report.extReportId)
  }, [setExtReportId])

  const onError = useCallback(({ code, message }) => {
    setError({ code, message })
  }, [setError])

  const fetchFollowUp = async ({ auth, followUpUserId }) => {
    const { token } = auth

    digitalStoreSdk.setConfig({ baseUrl, authToken: token })

    return await digitalStoreSdk.followUp.fetchFollowUpForCurrentUserById({
      followUpUserId
    })
  }

  useEffect(() => {
    if (location.state) {
      const { auth, followUpUserId } = location.state

      fetchFollowUp({ auth, followUpUserId })
        .then(followup => {
          if (followup.type === 'SENT') {
            return
          }

          setError({
            message: 'Followup has already been completed or is invalid.'
          })
        })
        .catch(onError)
    }
  }, [])

  useEffect(() => {
    if (!location.state) {
      dispatch(push('/'))
    }
  }, [location, dispatch])

  if (!location.state) return null

  const {
    auth,
    organisationId,
    followUpUserId,
    user: userDetails,
    sourceId
  } = location.state

  return (
    <FollowUpScreenContainer withMarginTop>
      {!error && !extReportId ? (
        <FormContainer>
          <VigilanceHubForm
            auth={auth}
            followUpUserId={followUpUserId}
            baseUrl={baseUrl}
            organisationId={organisationId}
            onSuccess={onSuccess}
            onError={onError}
            userDetails={userDetails}
            sourceId={sourceId}
          />
        </FormContainer>
      ) : (
        <>
          {error && <ErrorScreen title={translations(error.message) || translations(error.code)} />}
          {extReportId && <SuccessScreen message={translations('Followup success message', { extReportId })} />}
        </>
      )}
    </FollowUpScreenContainer>
  )
}

export default FollowUpScreen
