"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schema = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _schema = require("./schema");

var _view = _interopRequireDefault(require("./view"));

var _collections = require("./collections");

var _constants = require("../../../constants");

var _utils = require("../../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var schema = {
  type: _constants.FORM_TYPE_CONSTANTS.REPORT,
  getFormSchema: _schema.getFormSchema,
  getFormView: function getFormView(validators) {
    var viewJSON = JSON.parse(JSON.stringify(_view["default"]));

    try {
      var _viewJSON$sections$se;

      var BCG = _collections.collectionUtils.convertToFormView(_collections.collectionUtils.getCollection('BCG', validators));

      var YellowCard = _collections.collectionUtils.convertToFormView(_collections.collectionUtils.getCollection('YellowCard', validators));

      var PAHO = _collections.collectionUtils.convertToFormView(_collections.collectionUtils.getCollection('PAHO', validators));

      var sectionIndex = viewJSON.sections.findIndex(function (section) {
        return section.id === 'uncategorised';
      });

      (_viewJSON$sections$se = viewJSON.sections[sectionIndex].fields).push.apply(_viewJSON$sections$se, _toConsumableArray(BCG).concat(_toConsumableArray(YellowCard), _toConsumableArray(PAHO)));

      return viewJSON;
    } catch (error) {
      console.log(error);
      return viewJSON;
    }
  },
  getLocationField: function getLocationField(viewJSON) {
    var locationFieldName = 'location';
    var result = (0, _utils.findObjectByKeyAndValue)(viewJSON, 'id', locationFieldName);
    return result;
  },
  initialFieldMap: _schema.initialFieldMap
};
exports.schema = schema;