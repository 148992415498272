import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../../config'
import Form, { FormBody } from '../../../../../components/Form'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { categoryOptions } from '../../../../../store/modules/formViews/selectors'
import Button from '../../../../../components/Button'
import styles from './style'

const CategoryForm = Form('CategoryForm')
const Category = ({
  classes,
  closeModal,
  handleSubmit
}) => {
  return (
    <div className={classes.listContainer}>
      <h3>{translations('Report Config Category')}</h3>
      <CategoryForm onSubmit={handleSubmit}>
        <ContentBoxBody>
          <FormBody
            schema={[
              {
                id: 'newreportcategory',
                field: 'Dropdown',
                props: {
                  name: 'newreportcategory',
                  options: categoryOptions,
                  shrink: true,
                  required: true
                }
              }
            ]}
            editing
            fullWidthFields
          />
        </ContentBoxBody>
        <div className={classes.buttonContainer}>
          <Button
            raised
            buttonType='white'
            style={{ marginRight: 10 }}
            onClick={() => closeModal()}
          >
            {translations('Cancel')}
          </Button>
          <Button
            type='submit'
            raised
            buttonType='primary'>
            {translations('Confirm')}
          </Button>
        </div>
      </CategoryForm>
    </div>
  )
}

Category.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func,
  handleSubmit: PropTypes.func
}

export default withStyles(styles)(Category)
