import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import { styled } from '@material-ui/core/styles'

import Heading from '../Heading'
import { FormBody } from '../Form'
import BasicModalOverlay from '../BasicModalOverlay'
import ContentBoxBody from '../ContentBox/ContentBoxBody'

const TabBar = styled(AppBar)(({ theme }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  background: theme.palette.grey[100]
}))

const ModalHeading = styled('div')(({ theme }) => ({
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 10
}))

const Container = styled('div')(({ theme }) => ({
  marginTop: -20,
  marginLeft: -20,
  marginRight: -20
}))

const Content = styled('div')(({ theme }) => ({
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 30
}))

const ModalTab = styled(Tab)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minWidth: 120
  }
}))

const TabbedFormModal = (props) => {
  const {
    formComponent: FormComponent,
    handleSubmit,
    actions,
    schemaSections,
    title,
    children,
    initialValues,
    onTabChange,
    tabsLabel,
    formProps = {}
  } = props
  const [activeTab, setActiveTab] = useState('general')
  const [tabs, setTabs] = useState([])
  const [schema, setSchema] = useState({})

  useEffect(() => {
    setTabs(_.map(schemaSections, (section, sectionKey) => ({
      label: section.title,
      id: sectionKey
    })))
  }, [schemaSections])

  useEffect(() => {
    setSchema(schemaSections[activeTab])
  }, [schemaSections, activeTab])

  useEffect(() => {
    if (_.isFunction(onTabChange)) {
      onTabChange(activeTab)
    }
  }, [activeTab])

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <BasicModalOverlay actions={actions}>
      <Container>
        <TabContext value={activeTab}>
          <TabBar position='static' color='transparent' variant='outlined'>
            <ModalHeading>
              <Heading variant={'h3'}>{title}</Heading>
            </ModalHeading>
            <TabList onChange={handleChange} aria-label={tabsLabel} scrollButtons={'auto'}>
              {_.map(tabs, ({ label, id }) => {
                return (
                  <ModalTab label={label} style={{ textTransform: 'none' }} key={id} value={id} />
                )
              })}
            </TabList>
          </TabBar>
          <Content>
            <FormComponent onSubmit={handleSubmit} initialValues={initialValues} {...formProps}>
              <ContentBoxBody>
                {children}
                {_.size(_.get(schema, 'schema')) > 0 && (
                  <FormBody
                    formName={formProps.formName}
                    layout={schema.layout}
                    schema={schema.schema}
                    editing
                    fullWidthFields
                  />
                )}
              </ContentBoxBody>
            </FormComponent>
          </Content>
        </TabContext>
      </Container>
    </BasicModalOverlay>
  )
}

TabbedFormModal.props = {
  actions: [],
  schemaSections: {},
  initialValues: {}
}

TabbedFormModal.propTypes = {
  /** Wrapped Redux Form component */
  formComponent: PropTypes.object.isRequired,
  /** Wrapped Redux submit function */
  handleSubmit: PropTypes.func.isRequired,
  /** Actions for modal */
  actions: PropTypes.arrayOf(PropTypes.shape({
    success: PropTypes.bool,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    primary: PropTypes.bool
  })).isRequired,
  /** Schemas grouped within sections */
  schemaSections: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      layout: PropTypes.array.isRequired,
      schema: PropTypes.array.isRequired
    })
  ),
  /** Content to show above form fields */
  children: PropTypes.any,
  /** Data to pre-populate form */
  initialValues: PropTypes.object,
  /** Callback when tab changes */
  onTabChange: PropTypes.func,
  /** Title of modal */
  title: PropTypes.string.isRequired,
  /** ARIA label of tabs listing */
  tabsLabel: PropTypes.string
}

export default TabbedFormModal
