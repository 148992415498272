import { createSelector } from 'reselect'
import { selectors as roleSelectors } from '../roles'
import { selectors as platformSelectors } from '../platforms'
import { selectors as userDetailsSelectors } from '../userDetails'
import { selectors as authSelectors } from '../auth'
import _ from 'lodash'

export const getUserDetailsOrRolesIsLoading = createSelector([
  userDetailsSelectors.getInitialUserDetailIsLoading, roleSelectors.getIsLoading
], (userLoading, roleLoading) => {
  return userLoading || roleLoading
})

export const getSelectedUserOrganisationsOnCurrentPlatform = createSelector([
  userDetailsSelectors.getSelectedUserOrganisations,
  platformSelectors.getCurrentPlatformOrganisations],
  (userOrgs, platformOrgs) => {
    return _.intersectionBy(userOrgs, platformOrgs, 'id')
  })

export const getSelectedUserOrganisationsIdsOnCurrentPlatform = createSelector([
  getSelectedUserOrganisationsOnCurrentPlatform
],
  (organisations) => {
    return _.map(organisations, 'id')
  })

export const getUserDetailsProfessionId = createSelector([
  userDetailsSelectors.getSelectedUser, authSelectors.getUserSelectedOrganisationId
],
  (user, loggedInOrganisationId) => {
    const theOrg = _.get(user,'organisations',[]).find((org) => { return org.id === loggedInOrganisationId })
    return _.get(theOrg,'organisationUser.professionId')
  })

export const getSelectedUserOrganisationsOnCurrentPlatformFormatted = createSelector([
    getSelectedUserOrganisationsOnCurrentPlatform,
    authSelectors.getUserOrganisations
], (organisations, currentUserOrganisation) => {
  const userOrgsOnPlatforms = _.intersectionBy(organisations, currentUserOrganisation, 'id')
  if (_.isEmpty(userOrgsOnPlatforms)) {
    return '-'
  }
  if (_.size(userOrgsOnPlatforms) > 2) {
    return `${userOrgsOnPlatforms[0].name}, ${userOrgsOnPlatforms[1].name} (+${userOrgsOnPlatforms.length - 2} more)`
  }
  return userOrgsOnPlatforms.map(p => p.name).join(', ')
})
