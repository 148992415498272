import React from 'react'
import { Route } from 'react-router'
import LoginScreen from './LoginScreen'
import CompanyAccountRequestScreen from './CompanyAccountRequestScreen'
import FullScreenLoader from '../../components/FullScreenLoader'

export default [
  <Route key='/login' path='/login' exact component={LoginScreen} />,
  <Route key='/account/request' path='/account/request' exact component={CompanyAccountRequestScreen} />,
  <Route path='/sso/success/:token' exact component={FullScreenLoader} />
]
