import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import modalService from '../../../../services/modalService'
import SearchInput from '../../../../components/SearchInput'
import { translations } from '../../../../config'
import ConfirmDeleteFiltersModal from './Modals/ConfirmDeleteFiltersModal'
import FilterEditorModal from './Modals/FilterEditorModal'

import styles from './style'
import { useQuery } from '../../../../hooks'
const SubmissionReportsSearchInput = (props) => {
  const {
    filters,
    changeQuery,
    search,
    setSavedSearch,
    updateSavedSearch,
    deleteSavedSearch,
    createNewSavedSearch,
    discardFilters,
    savedSearches,
    activeSavedSearchId
  } = props

  const queryFilters = useQuery()
  const combinedFilters = useMemo(() => {
    return { ...queryFilters, ...filters }
  }, [filters, queryFilters])

  const { query } = combinedFilters

  const debouncedSearch = _.debounce(() => {
    search()
  }, 300)

  const updateQuery = (e) => {
    const query = e.target.value
    changeQuery({ query })
    debouncedSearch()
  }

  const clearQuery = () => {
    changeQuery({ query: '' })
    search()
  }

  const loadSavedFilters = (params) => {
    setSavedSearch(params)
  }

  const handleSaveFilter = (params) => {
    modalService.open({
      component: FilterEditorModal,
      initialValues: params,
      onSubmit: (params) => {
        updateSavedSearch({ ..._.omitBy(params, _.isNil), savedSearchId: params.id })
        modalService.close()
      }
    })
  }

  const handleDeleteSavedFilters = ({ id }) => {
    modalService.open({
      component: ConfirmDeleteFiltersModal,
      title: translations('Confirmation'),
      message: translations(`Submission Reports - Delete Filter`),
      onConfirmation: () => {
        deleteSavedSearch(id)
        modalService.close()
      }
    })
  }
  const handleCreateSavedFilter = () => {
    modalService.open({
      component: FilterEditorModal,
      onSubmit: (params) => {
        createNewSavedSearch(params)
        modalService.close()
      },
      isNew: true
    })
  }

  const handleDiscardFilters = () => {
    modalService.open({
      component: ConfirmDeleteFiltersModal,
      title: translations('Confirmation'),
      message: translations(`Submission Reports - Discard Filters`),
      onConfirmation: () => {
        discardFilters()
        modalService.close()
      }
    })
  }

  return (
    <SearchInput
      value={query}
      placeholder={translations('Case Management - Search Field Placeholder')}
      onChange={updateQuery}
      onClear={clearQuery}
      autoFocus={false}
      menuItems={savedSearches}
      onMenuItemSelect={loadSavedFilters}
      activeMenuItemId={activeSavedSearchId}
      onMenuSave={handleSaveFilter}
      onMenuDelete={handleDeleteSavedFilters}
      onMenuCreate={handleCreateSavedFilter}
      onMenuDiscard={activeSavedSearchId && handleDiscardFilters}
    />
  )

}

SubmissionReportsSearchInput.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  query: PropTypes.string,
  search: PropTypes.func.isRequired,
  changeQuery: PropTypes.func.isRequired
}

export default withStyles(styles)(SubmissionReportsSearchInput)
