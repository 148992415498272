import React, { Component } from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../../config'
import Heading from '../../../../../components/Heading'
import ButtonsContainer from '../../../../../components/ButtonsContainer'
import Button from '../../../../../components/Button'

import styles from './style'

class ManuallyProcessedSummaryModal extends Component {
  state = {
    query: null
  }

  render() {
    const {
      classes,
      manuallyProcessedByUser,
      manuallyProcessedReason,
      createdAt,
      updatedAt,
      dismiss
    } = this.props

    const getManuallyProcessedUserString = () => {
      const { firstName, lastName } = manuallyProcessedByUser
      return (<span>{`${translations('Manually Processed By')}: ${firstName} ${lastName}`}</span>)
    }
    const getManuallyProcessedReasonString = () => {
      return (<span>{`${translations('Manually Processed Reason')}: ${manuallyProcessedReason}`}</span>)
    }

    return (
      <>
        <Heading>{translations('Case Management - Manually Processed Summary Title')}</Heading>
        <div className={classes.textWrap}>
          <span>{`${translations('Created At')}: ${createdAt}`}</span>
          <span>{`${translations('Updated At')}: ${updatedAt}`}</span>
          {!_.isEmpty(manuallyProcessedByUser) && (getManuallyProcessedUserString())}
          {!_.isEmpty(manuallyProcessedReason) && (getManuallyProcessedReasonString())}
        </div>
        <ButtonsContainer align='right' wrap isModal>
          <Button buttonType={'white'} onClick={() => dismiss()}>{translations('Ok')}</Button>
        </ButtonsContainer>
      </>
    )
  }
}
ManuallyProcessedSummaryModal.propTypes = {
}

export default withStyles(styles)(ManuallyProcessedSummaryModal)
