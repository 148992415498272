import { createSelector } from 'reselect'
import _ from 'lodash'
import { PENDING } from '../../middleware/redux-promise'

export const getIsLoading = state => _.get(state, 'pages.status') === PENDING

export const getActivePageId = state => state.pages.activePageId
export const getPages = state => state.pages.pages

export const getActivePage = createSelector([
  getActivePageId,
  getPages
], (activeId, pages) => {
  return pages[activeId]
})
