import React from 'react'
import Editable from '../Editable'
import { TableList } from '../../Fields'

export default Editable({
  input: (props) => (
    <TableList {...props} />
  ),
  display: ({ ...props }) => (
      <TableList {...props} disabled={true} />
  )
})
