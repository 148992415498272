"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.yesWithConsequence = exports.yesDuringVaccinationTrial = exports.yesCurrently = exports.yesAndNoLongerPregnant = exports.yesAndIsOfNote = exports.yesAndCurrentlyPregnant = exports.medicineHadAdverseEffect = exports.isReportingForChildOrPregnancy = exports.isReportingForChild = exports.isParentSubmittingReport = exports.isOfProfessionTypeParent = exports.generatePregnancyAtTimeOfReactionOptions = exports.computePregnancyExposureToMedicineOptions = exports.canPatientMenstruate = exports.canBearChildren = exports.appendFolicAcidInConcomitantDrugs = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var computedHidden = _interopRequireWildcard(require("../computedHidden"));

var computedValue = _interopRequireWildcard(require("../computedValue"));

var _2 = require("./");

var _computeOtherValues = require("../../computeOtherValues");

var _options = require("../../formHelpers/options");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var isOfProfessionTypeParent = computedHidden.isOfProfessionType('Parent');
exports.isOfProfessionTypeParent = isOfProfessionTypeParent;

var isParentSubmittingVaccineTrial = _fp["default"].allPass([isOfProfessionTypeParent, _2.covid19.isCovid19VaccineLogicActive]);

var isParentSubmittingReport = _fp["default"].allPass([isOfProfessionTypeParent, _2.covid19.isCovid19VaccineLogicNotActive]);

exports.isParentSubmittingReport = isParentSubmittingReport;

var canPatientMenstruate = function canPatientMenstruate(_ref) {
  var getDurationInYears = _ref.getDurationInYears,
      timeMeasurements = _ref.timeMeasurements;

  var isNumberBetween13and55 = _fp["default"].allPass([_fp["default"].gte(55), _fp["default"].lte(13)]);

  var isWithinPatientAgeBoundaries = _fp["default"].compose(isNumberBetween13and55, getDurationInYears('patientonsetage', 'patientonsetageunit'));

  var isOnDecadeBoundaries = _fp["default"].allPass([computedHidden.fieldIs('patientonsetageunit', _fp["default"].get('decade', timeMeasurements)), computedHidden.fieldIs('patientonsetage', ['2', '6'])]);

  var isAdolescentOrAdultAgeGroup = computedHidden.fieldIs('patientagegroup', ['4', '5']);

  var isWithinBoundariesUsingDOB = _fp["default"].compose(isNumberBetween13and55, _2.general.convertPatientDobToYears);

  var isFemale = computedHidden.fieldIs('patientsex', '2');
  return _fp["default"].allPass([isFemale, _fp["default"].anyPass([isWithinPatientAgeBoundaries, isOnDecadeBoundaries, isAdolescentOrAdultAgeGroup, isWithinBoundariesUsingDOB])]);
};

exports.canPatientMenstruate = canPatientMenstruate;

var canBearChildren = function canBearChildren(_ref2) {
  var getDurationInYears = _ref2.getDurationInYears,
      timeMeasurements = _ref2.timeMeasurements;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _fp["default"].anyPass([_fp["default"].allPass([canPatientMenstruate({
      getDurationInYears: getDurationInYears,
      timeMeasurements: timeMeasurements
    }), function () {
      return !isOfProfessionTypeParent.apply(void 0, args);
    }]), isParentSubmittingVaccineTrial]).apply(void 0, args);
  };
};

exports.canBearChildren = canBearChildren;

var yesAndCurrentlyPregnant = _fp["default"].allPass([computedHidden.fieldIs('pregnancyAtTimeOfReaction', '1'), _2.covid19.isCovid19VaccineLogicNotActive]);

exports.yesAndCurrentlyPregnant = yesAndCurrentlyPregnant;

var yesDuringVaccinationTrial = _fp["default"].allPass([computedHidden.fieldIs('pregnancyAtTimeOfReaction', '1'), _2.covid19.isCovid19VaccineLogicActive]);

exports.yesDuringVaccinationTrial = yesDuringVaccinationTrial;

var yesAndNoLongerPregnant = _fp["default"].allPass([computedHidden.fieldIs('pregnancyAtTimeOfReaction', '2'), _2.covid19.isCovid19VaccineLogicNotActive]);

exports.yesAndNoLongerPregnant = yesAndNoLongerPregnant;

var yesAndIsOfNote = _fp["default"].anyPass([yesAndNoLongerPregnant, yesDuringVaccinationTrial]);

exports.yesAndIsOfNote = yesAndIsOfNote;

var yesCurrently = _fp["default"].anyPass([yesAndCurrentlyPregnant, yesDuringVaccinationTrial]);

exports.yesCurrently = yesCurrently;
var hasExperiencedReaction = computedHidden.fieldIsNot('hasexperiencedreaction', '2');

var yesWithConsequence = _fp["default"].allPass([hasExperiencedReaction, yesAndIsOfNote]);

exports.yesWithConsequence = yesWithConsequence;
var isReportingForChild = computedHidden.fieldIs('reportInvolvesAdverseReactionDuringPregnancy', '1');
exports.isReportingForChild = isReportingForChild;

var isReportingForChildOrPregnancy = _fp["default"].anyPass([yesAndCurrentlyPregnant, yesAndNoLongerPregnant, yesDuringVaccinationTrial, isReportingForChild]);

exports.isReportingForChildOrPregnancy = isReportingForChildOrPregnancy;
var medicineHadAdverseEffect = computedHidden.fieldIs('didMedicineHaveAdverseEffectOnPregnancy', 'Yes');
exports.medicineHadAdverseEffect = medicineHadAdverseEffect;

var generatePregnancyAtTimeOfReactionOptions = _2.covid19.isStudyBranch(function () {
  return (0, _options.mapValuesAt1)(['Yes', 'No']);
}, function () {
  return (0, _options.mapValuesAt1)(['Yes (currently pregnant)', 'Yes (no longer pregnant)', 'No']);
});

exports.generatePregnancyAtTimeOfReactionOptions = generatePregnancyAtTimeOfReactionOptions;

var computePregnancyExposureToMedicineOptions = _2.covid19.isStudyBranch(function () {
  return (0, _options.mapValueAndLabel)(['first-trimester (1-12 weeks)', 'Second-trimester (13-28 weeks)', 'Third-trimester (29-40 weeks)', 'Unknown']);
}, function () {
  return (0, _options.mapValueAndLabel)(['Before pregnancy', 'Throughout pregnancy', 'first-trimester (1-12 weeks)', 'Second-trimester (13-28 weeks)', 'Third-trimester (29-40 weeks)', 'Breastfeeding', 'Unknown']);
});

exports.computePregnancyExposureToMedicineOptions = computePregnancyExposureToMedicineOptions;
var appendFolicAcidInConcomitantDrugs = (0, _computeOtherValues.computeOtherValuesHelper)(function (value, allValues) {
  var wasTransplancental = isReportingForChild(allValues);

  switch (value) {
    case '1':
      return {
        type: _computeOtherValues.action.ADDITIVE,
        payload: {
          field: 'otherdrug_drug',
          value: [{
            drugcharacterization: '2',
            medicinalproduct: 'FOLIC ACID',
            activesubstancename: 'FOLIC ACID',
            drugstructuredosagenumb: '5',
            drugstructuredosageunit: '003',
            drugsource: "1",
            drugadministrationroute: wasTransplancental ? '760' : undefined,
            drugindication: 'Folic acid supplementation'
          }]
        }
      };

    case '2':
      return {
        type: _computeOtherValues.action.ADDITIVE,
        payload: {
          field: 'otherdrug_drug',
          value: [{
            drugcharacterization: '2',
            medicinalproduct: 'FOLIC ACID',
            activesubstancename: 'FOLIC ACID',
            drugstructuredosagenumb: '400',
            drugstructuredosageunit: '004',
            drugsource: '2',
            drugadministrationroute: wasTransplancental ? '760' : undefined,
            drugindication: 'Folic acid supplementation'
          }]
        }
      };

    case '3':
      return {
        type: _computeOtherValues.action.ADDITIVE,
        payload: {
          field: 'otherdrug_drug',
          value: [{
            drugcharacterization: '2',
            medicinalproduct: 'FOLIC ACID',
            activesubstancename: 'FOLIC ACID',
            drugadministrationroute: wasTransplancental ? '760' : undefined,
            drugindication: 'Folic acid supplementation'
          }]
        }
      };

    case '4':
      return {
        type: _computeOtherValues.action.DESTRUCTIVE,
        payload: {
          field: 'otherdrug_drug'
        }
      };

    case null:
      return {
        type: _computeOtherValues.action.DESTRUCTIVE,
        payload: {
          field: 'otherdrug_drug'
        }
      };

    default:
      return {
        type: _computeOtherValues.action.NO_OPERATION
      };
  }
});
exports.appendFolicAcidInConcomitantDrugs = appendFolicAcidInConcomitantDrugs;