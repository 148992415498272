import { colors } from '../../config/theme'

const styles = theme => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 1299,
    boxShadow: 'none',
    bottom: 0,
    borderTop: `1px solid ${colors.mediumGrey}`,
    width: '100%',
    height: theme.footerHeight,
    padding: 10,
    backgroundColor: colors.lightGrey
  },

  footerLogo: {
    display: 'block',
    maxHeight: 55,
    maxWidth: '100%'
  },

  footerMobileLogo: {
    display: 'block',
    maxHeight: 55,
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },

  footerDesktopLogo: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      maxHeight: 55,
      maxWidth: '100%'
    }
  }
})

export default styles
