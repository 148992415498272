import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import _ from 'lodash'
import { withStyles, styled} from '@material-ui/core/styles'

import SubHeader from '../../../components/SubHeader'
import Loader from '../../../components/Loader'
import Heading from '../../../components/Heading'
import DataTable, { DataTableRow, DataTableCell }  from '../../../components/DataTable'
import P from '../../../components/P'
import ButtonLink from '../../../components/ButtonLink'

import { translations } from '../../../config'
import styles from './style'
import { getStandaloneReportTitle } from '../helpers'

const ErrorMessage = styled('div')(({ theme }) => ({
  padding: theme.spacing(2)
}))

const ReportsShareScreen = ({
  pageTitle,
  headers,
  data,
  count,
  total,
  onClick,
  classes,
  isLoading,
  allSchemas,
  noPermission
}) => {

  const reportRows = ({ entry }) => {
    const { createdAt, id } = entry
    const reportType = _.get(_.find(allSchemas, {id: entry.formSchemaId}), 'name', '-')
    return (
      <DataTableRow>
        <DataTableCell compact component='th' scope='row' nowrap>
          <ButtonLink
            onClick={() => onClick(id)}
            className={classes.buttonLink}
          >
            {getStandaloneReportTitle(entry, reportType)}
          </ButtonLink>
        </DataTableCell>
        <DataTableCell compact component='th' scope='row' nowrap>
          {moment(createdAt).format('DD/MM/YYYY HH:mm')}
        </DataTableCell>
      </DataTableRow>
    )
  }

  return (
    <div>
      <SubHeader
        centerContent={(
          <Fragment>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName} - </title>
            </Helmet>
          </Fragment>
        )}
      />

      {isLoading && <Loader />}

      {!isLoading && count === 0 &&
        <ErrorMessage>
          <P type={'error'} value={noPermission ? translations('Shared Reports - No Permission') : translations('Shared Reports - No Reports')} />
        </ErrorMessage>
      }

      {
        <DataTable
          headers={headers}
          data={data}
          tableId={'shared-report-table'}
          tableHeading={'Shared Reports'}
          count={count}
          total={total}
          rowComponent={reportRows}
        />
      }
    </div>
  )
}

export default withStyles(styles)(ReportsShareScreen)
