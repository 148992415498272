import _ from 'lodash'
import React, { useMemo } from 'react'
import { submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

import Form, { FormBody } from '../../../../components/Form'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import * as validators from '../../../../validators'

import {
  constants as developerConstants,
  actions as developerActions
} from '../../../../store/modules/developer'

import { translations } from '../../../../config'
import { getApplication } from '../../../../store/modules/developer/selectors'
import { PENDING } from '../../../../store/middleware/redux-promise'
import modalService from '../../../../services/modalService'
import toastService from '../../../../services/toastService'

const formName = developerConstants.GENERATE_SERVICE_SUBMISSION_USER_FORM
const GenerateForm = Form(formName)

const SubmissionServiceUserModal = (props) => {
  const {
    applicationId,
    organisationId,
    dismiss,
    isUpdate
  } = props

  const dispatch = useDispatch()
  const submissionServiceUser = useSelector(getApplication)
  const status = _.get(submissionServiceUser, 'status')
  const mode = isUpdate ? 'Update' : 'Create'

  const handleSubmit = (data) => {
    const {
      id,
      username,
      password,
      description
    } = data

    if (isUpdate) {
      handleUpdate({ id, username, password, description })
    } else {
      handleCreate({ username, password, description })
    }
  }

  const handleCreate = ({ username, password, description }) => {
    try {
      dispatch(developerActions.createApplication({
        organisationId,
        submissionServiceUser: {
          username,
          password,
          description
        }
      }))
      modalService.close()
      toastService.action({
        type: 'success',
        message: translations('Developer - Create Submission Service User Success'),
        autoHideDuration: 6000
      })
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations('Developer - Create Submission Service User Failure'),
        autoHideDuration: 6000
      })
    }
  }

  const handleUpdate = ({ id, username, password, description }) => {
    try {
      dispatch(developerActions.updateApplication({
        id: applicationId,
        submissionServiceUser: {
          id,
          username,
          password,
          description
        }
      }))
      modalService.close()
      toastService.action({
        type: 'success',
        message: translations('Developer - Update Submission Service User Success'),
        autoHideDuration: 6000
      })
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations('Developer - Update Submission Service User Failure'),
        autoHideDuration: 6000
      })
    }
  }

  const actions = useMemo(() => {
    return [
      {
        success: true,
        text: translations(mode),
        primary: true,
        onClick: () => {
          dispatch(submit(formName))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [])

  const schema = useMemo(() => {
    const passwordValidator = isUpdate ? validators.passwordIfLengthGreaterThanZero : validators.password
    return [
      {
        id: 'description',
        field: 'Input',
        props: {
          label: translations('Description'),
          name: 'description',
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250,
          required: true
        }
      },
      {
        id: 'username',
        field: 'Input',
        props: {
          label: translations('Username'),
          name: 'username',
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250,
          required: true
        }
      },
      {
        id: 'password',
        field: 'Input',
        props: {
          label: translations('Password'),
          name: 'password',
          type: 'password',
          validate: [validators.maxLength(250, 'Field'), passwordValidator],
          maxLength: 250,
          required: !isUpdate,
          help: isUpdate ? translations('Submission Service User - Leave Password Blank') : ''
        }
      },
      {
        id: 'confirmPassword',
        field: 'Input',
        props: {
          label: translations('Confirm Password'),
          name: 'confirmPassword',
          type: 'password',
          validate: [validators.maxLength(250, 'Field'), validators.passwordConfirmation],
          maxLength: 250,
          required: !isUpdate
        }
      }
    ]
  }, [])

  return (
    <BasicModalOverlay
      submitting={status === PENDING}
      actions={actions}
      title={translations(`Developer - ${mode} Submission Service User`)}
    >
      <GenerateForm onSubmit={handleSubmit} initialValues={props.data || {}}>
        <ContentBoxBody>
          <FormBody
            schema={schema}
            editing
            fullWidthFields
          />
        </ContentBoxBody>
      </GenerateForm>
    </BasicModalOverlay>
  )
}

export default SubmissionServiceUserModal