import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class SourcesActions {
  fetchOrganisationSources = ({ organisationId }) => ({
    type: constants.FETCH_SOURCES,
    promise: () => {
      if (!organisationId) {
        return Promise.resolve()
      }
      return digitalStoreSdk.organisations.fetchOrganisationSources({ organisationId })
    },
    meta: {
      organisationId
    }
  })
}

export default new SourcesActions()
