import * as constants from './constants'
import reducers from './reducers'
import * as enums from './enums'

const defaultState = {
  status: enums.fileManagerStatus.UNINTIALISED,
  error: undefined
}
export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.INITALISE_FILE_MANAGER_BEGIN:
      return reducers.setManagerStatusToIdle(state, action)
    case constants.FILE_MANAGER_STATUS_IDLE:
      return reducers.setManagerStatusToIdle(state, action)
    case constants.FILE_MANAGER_STATUS_IN_PROGRESS:
      return reducers.setManagerStatusToInProgress(state, action)
    case constants.FILE_MANAGER_STATUS_CANCELLED:
      return reducers.setManagerStatusToCancelled(state, action)
    case constants.FILE_MANAGER_STATUS_COMPLETED:
      return reducers.setManagerStatusToCompleted(state, action)
    case constants.SET_FILE:
      return reducers.setUploadFile(state, action)
    case constants.FILE_UPLOAD_PENDING:
      return reducers.setIndividualFileStatusPending(state, action)
    case constants.FILE_UPLOAD_SUCCESS:
      return reducers.setIndividualFileStatusSuccess(state, action)
    case constants.FILE_UPLOAD_FAILURE:
      return reducers.setIndividualFileStatusFailure(state, action)
    case constants.FILE_UPDATE_PROGRESS:
      return reducers.setUploadFileStatus(state, action)
    case constants.ATTACHMENTS_UPLOAD_PENDING:
      return reducers.setAttachmentFileStatusPending(state, action)
    case constants.ATTACHMENTS_UPLOAD_SUCCESS:
      return reducers.setAttachmentFileStatusSuccess(state, action)
    case constants.ATTACHMENTS_UPLOAD_FAILURE:
      return reducers.setAttachmentFileStatusFailure(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
