import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { getSearchUsersRoleIds } from './helpers'

class UserActions {
  searchUsersFresh = ({ query, roleIds, organisationIds, changeRequestStatuses, includeDeactivated, accountTypes, ...rest }) => ({
    type: constants.SEARCH_USER_FRESH,
    query,
    roleIds,
    organisationIds,
    changeRequestStatuses,
    includeDeactivated,
    promise: (dispatch, getState) => {
      const state = getState()
      const searchUsersOrganisationIds = organisationIds === undefined ? selectors.getOrganisationIds(state) : organisationIds
      const searchUsersRoleIds = getSearchUsersRoleIds(state, roleIds)
      const searchChangeRequestStatuses = changeRequestStatuses === undefined ? selectors.getChangeRequestStatuses(state) : changeRequestStatuses
      const searchUsersQuery = query === undefined ? selectors.getQuery(state) : query
      const searchUsersIncludeDeactivated = includeDeactivated === undefined ? selectors.getIncludeDeactivated(state) : includeDeactivated
      const searchAccountTypes = accountTypes || selectors.getAccountTypes(state)

      const size = selectors.getSize(state)
      return digitalStoreSdk.users
        .searchUsers({
          query: searchUsersQuery,
          roleIds: searchUsersRoleIds,
          organisationIds: searchUsersOrganisationIds,
          changeRequestStatuses: searchChangeRequestStatuses,
          includeDeactivated: searchUsersIncludeDeactivated,
          accountTypes: searchAccountTypes,
          page: 1,
          size
        })
    }
  })
  searchUsersNext = () => ({
    type: constants.SEARCH_USER_NEXT,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationIds = selectors.getOrganisationIds(getState())
      const roleIds = selectors.getRoleIds(state)
      const searchUsersRoleIds = getSearchUsersRoleIds(state, roleIds)
      const changeRequestStatuses =  selectors.getChangeRequestStatuses(state)
      const query = selectors.getQuery(getState())
      const includeDeactivated = selectors.getIncludeDeactivated(state)
      const size = selectors.getSize(state)
      const page = selectors.getPage(state)
      return digitalStoreSdk.users
        .searchUsers({ query, organisationIds, roleIds: searchUsersRoleIds, changeRequestStatuses, includeDeactivated, page: page + 1, size })
        .then(({ total, results }) => ({ total, results, query, organisationIds, roleIds, includeDeactivated }))
    }
  })
  updateUserInList = ({ user }) => ({
    type: constants.UPDATE_USER_IN_LIST,
    user
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
  deleteUserPermanentlyById = ({ userId, reason, requestedAt }) => ({
    type: constants.PERMANENT_DELETE_USER,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.users
      .deleteUserPermanentlyById({ userId, reason, requestedAt })
    }  
  })
  accountDeletionRequest = ({ password }) => ({
    type: constants.ACCOUNT_DELETION_REQUEST,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.users
      .requestAccountDeletion({ password })
    }  
  })
}

export default new UserActions()
