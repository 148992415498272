import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import { Badge } from '@material-ui/core'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: 'white',
    backgroundColor: theme.palette.error.main,
  }
}))

const AlertBadge = ({
  onClick,
  getBadgeCount,
  children,
  variant
}) => {
  const badgeCount = getBadgeCount()
  const [invisible, setInvisible] = useState(true)
  const [badgeContent, setBadgeContent] = useState(badgeCount)

  useEffect(() => {
    if (badgeCount > 0) {
      setInvisible(false)
    } else {
      setInvisible(true)
    }
    setBadgeContent(badgeCount > 99 ? '99+' : badgeCount)
  }, [badgeCount])

  return (
    <StyledBadge
      onClick={onClick}
      badgeContent={badgeContent}
      children={children}
      invisible={invisible}
      variant={variant}
    />
  )
}

AlertBadge.defaultProps = {
  fullWidth: false,
  children: '' // Removes PropTypes error from MUI for Badge
}

AlertBadge.propTypes = {
  getBadgeCount: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.any,
  variant: PropTypes.string
}

export default AlertBadge
