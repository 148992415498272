export default theme => ({
  barChartContainer: {
    width: '100%',
    height: 300,
    position: 'relative'
  },
  centerAbsolutely: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    // account for assymmetry of bar chart
    // horizontal lines
    marginTop: -3
  }
})
