import _ from 'lodash'

const getFields = (schema) => {
  return _.reduce(schema, (memo, field) => {
    const type = field.field
    if (type === 'Repeatable') {
      const requried = _.get(field, 'props.required', false)
      if (requried) {
        memo.push(field.id)
      }
    }
    if (type === 'CombinedRepeatable') {
      memo.push(field)
    }
    return memo
  }, [])
}

const getFieldsFromSections = (schema) => {
  const fields = _.reduce(schema, (memo, section) => {
    memo.push(...getFields(section.schema))
    return memo
  }, [])
  return fields
}

const requiredRepeatables = schema => values => {
  const errors = {}
  const requiredRepeatables = _.isArray(schema) ? getFields(schema) : getFieldsFromSections(schema)

  _.forEach(requiredRepeatables, (field) => {
    if (field.field === 'CombinedRepeatable') {
      const repeatableRequired = _.get(values, _.get(field, 'props.requiredFieldValue')) === 'true'
      const repeatableFieldId = _.get(field, 'props.repeatableGroupId')
      const requiredFields = _.chain(field.props.schema)
        .find({ id: repeatableFieldId })
        .get('props.schema')
        .filter('props.required')
        .map('id')
        .value()

      const repeatableValues = _.get(values, repeatableFieldId)
      _.forEach(repeatableValues, (value) => {
        const hasMissingFields = _.reduce(requiredFields, (memo, requiredField) => {
          const missingValue = _.isEmpty(_.get(value, requiredField))
          return memo || missingValue
        }, false)

        if (repeatableRequired && hasMissingFields) {
          _.set(errors, [field.id, value.id], 'Error - Missing Required Fields')
        }
      })
    } else {
      if (_.isEmpty(values[field])) {
        errors[field] = 'Field is required'
      }
    }
  })

  return errors
}

export default requiredRepeatables
