const style = theme => ({
  container: {
    display: 'flex'
  },
  buttonContainer: {
    marginLeft: 10,
    marginTop: 16
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0
  }
})

export default style
