"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitterAddressFieldsValidation = exports.ishomeTestingKitLogicNotActive = exports.ishomeTestingKitLogicActive = exports.isSyringeReport = exports.isNotSyringeReport = exports.isNotLateralFlowReport = exports.isLateralOrSyringeReport = exports.isLateralFlowReport = exports.isLateralFlowBranch = exports.isLateralFlowAndHomeTestingKitReport = exports.hasNotGotMultipleSimilarEvents = exports.hasMultipleSimilarEvents = exports.hasAdverseEventDate = exports.distributionFieldsValidation = exports.computedAdverseEventDate = exports.computeProductNameOptions = exports.computeImdrfCodeChoice1Options = exports.computeDeviceDescOptions = exports.computeCurrentDeviceLocationOptions = exports.clearIfNotMultipleSimilarEvents = exports.canSaveDraft = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var computedHidden = _interopRequireWildcard(require("../computedHidden"));

var computedValue = _interopRequireWildcard(require("../computedValue"));

var _options = require("../options");

var _config = require("../../config");

var _2 = require(".");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var isLateralFlowReport = function isLateralFlowReport(formValues) {
  var locations = _fp["default"].at(['isLateralFlowReport', '_parentFormValues.isLateralFlowReport'], formValues);

  return _fp["default"].any(_fp["default"].isEqual(true), locations);
};

exports.isLateralFlowReport = isLateralFlowReport;
var isNotLateralFlowReport = computedValue.not(isLateralFlowReport);
exports.isNotLateralFlowReport = isNotLateralFlowReport;

var isSyringeReport = function isSyringeReport(formValues) {
  var locations = _fp["default"].at(['isSyringeReport', '_parentFormValues.isSyringeReport'], formValues);

  return _fp["default"].any(_fp["default"].isEqual(true), locations);
};

exports.isSyringeReport = isSyringeReport;
var isNotSyringeReport = computedValue.not(isSyringeReport);
exports.isNotSyringeReport = isNotSyringeReport;

var isLateralOrSyringeReport = _fp["default"].anyPass([isLateralFlowReport, isSyringeReport]);

exports.isLateralOrSyringeReport = isLateralOrSyringeReport;

var ishomeTestingKitLogicActive = function ishomeTestingKitLogicActive(formValues) {
  var locations = _fp["default"].at(['homeTestingKitLogic', '_parentFormValues.homeTestingKitLogic'], formValues);

  return _fp["default"].any(_fp["default"].isEqual(true), locations);
};

exports.ishomeTestingKitLogicActive = ishomeTestingKitLogicActive;
var ishomeTestingKitLogicNotActive = computedValue.not(ishomeTestingKitLogicActive);
exports.ishomeTestingKitLogicNotActive = ishomeTestingKitLogicNotActive;

var isLateralFlowAndHomeTestingKitReport = _fp["default"].allPass([isLateralFlowReport, ishomeTestingKitLogicActive]);

exports.isLateralFlowAndHomeTestingKitReport = isLateralFlowAndHomeTestingKitReport;

var computeProductNameOptions = _fp["default"].cond([[isLateralFlowAndHomeTestingKitReport, function () {
  return (0, _options.mapValueAndLabel)(['Sampling Device', 'Self-Testing']);
}], [isLateralFlowReport, function () {
  return (0, _options.mapValueAndLabel)(['Laboratory (instrumentation/platform)', 'Point of Care Testing', 'Reagent', 'Sampling Device', 'Self-Testing']);
}], [isSyringeReport, function () {
  return (0, _options.mapValueAndLabel)(['Syringe', 'Needle']);
}], [_fp["default"].identity, function () {
  return (0, _options.mapValueAndLabel)(['Reagent', 'PCR test', 'Test-kit Immunoassay', 'Test-kit accessories', 'Calibrators', 'Instrumentation/Software', 'QC Materials', 'Reagent strip', 'Test-kit Colorimetric']);
}]]);

exports.computeProductNameOptions = computeProductNameOptions;
var isLateralFlowBranch = computedValue.branch(isLateralFlowReport);
exports.isLateralFlowBranch = isLateralFlowBranch;
var computeDeviceDescOptions = isLateralFlowBranch(function () {
  return (0, _options.mapValueAndLabel)(['Antibody Immunoassay', 'Lateral flow antigen test', 'Other antigen test', 'PCR Test']);
}, function () {
  return (0, _options.mapValueAndLabel)(['Laboratory (Instrumentation/platform)', 'Point of Care testing', 'Home sampling kit', 'Self-Test', 'Clinical Chemistry', 'Cytopathology/Histopathology', 'Extra-Lab Testing', 'Genetic Testing', 'Haematology', 'Immunology', 'Microbiology', 'Specimen Receptacle']);
});
exports.computeDeviceDescOptions = computeDeviceDescOptions;

var computeImdrfCodeChoice1Options = _fp["default"].cond([[isLateralFlowReport, function () {
  return [{
    value: 'A0401',
    label: 'Material break-A0401'
  }, {
    value: 'A0501',
    label: 'Detachment of device component-A0501'
  }, {
    value: 'A020602',
    label: 'Component missing-A020602'
  }, {
    value: 'A0205',
    label: 'Packaging problem-A0205'
  }, {
    value: 'A0504',
    label: 'Leak/splash-A0504'
  }, {
    value: 'A090803',
    label: 'False negative result-A090803'
  }, {
    value: 'A090804',
    label: 'False positive result-A090804'
  }, {
    value: 'A090811',
    label: 'Unable to obtain readings-A090811'
  }, {
    value: 'A090802',
    label: 'Failure to obtain sample-A090802'
  }, {
    value: 'A2301',
    label: 'Device handling problem-A2301'
  }, {
    value: 'A21',
    label: 'Labelling, Instructions for Use or Training Problem-A21'
  }];
}], [isSyringeReport, function () {
  return [{
    value: 'A02',
    label: 'Manufacturing, Packaging or Shipping Problem-A02'
  }, {
    value: 'A04',
    label: 'Material Integrity Problem-A04'
  }, {
    value: 'A05',
    label: 'Mechanical Problem-A05'
  }, {
    value: 'A14',
    label: 'Infusion or Flow Problem-A14'
  }, {
    value: 'A15',
    label: 'Activation, Positioning or Separation Problem-A15'
  }, {
    value: 'A18',
    label: 'Contamination / decontamination Problem-A18'
  }, {
    value: 'A21',
    label: 'Labelling, Instructions for Use or Training Problem-A21'
  }, {
    value: 'A0201',
    label: 'Product Quality Problem-A0201'
  }, {
    value: 'A0204',
    label: 'Device Damaged Prior to Use-A0204'
  }, {
    value: 'A0205',
    label: 'Packaging Problem-A0205'
  }, {
    value: 'A0206',
    label: 'Device Misassembled During Manufacturing / Shipping-A0206'
  }, {
    value: 'A0401',
    label: 'Break-A0401'
  }, {
    value: 'A0404',
    label: 'Crack-A0404'
  }, {
    value: 'A0406',
    label: 'Material Deformation-A0406'
  }, {
    value: 'A0407',
    label: 'Material Discolored-A0407'
  }, {
    value: 'A0501',
    label: 'Detachment of Device or device Component-A0501'
  }, {
    value: 'A0504',
    label: 'Leak / Splash-A0504'
  }, {
    value: 'A0509',
    label: 'Physical Resistance / Sticking-A0509'
  }, {
    value: 'A0510',
    label: 'Retraction Problem-A0510'
  }, {
    value: 'A1402',
    label: 'Excess Flow or Over-Infusion-A1402'
  }, {
    value: 'A1403',
    label: 'Filling Problem-A1403'
  }, {
    value: 'A1409',
    label: 'Obstruction of Flow-A1409'
  }, {
    value: 'A1502',
    label: 'Positioning Problem-A1502'
  }, {
    value: 'A1802',
    label: 'Device Contaminated during manufacture or shipping-A1802'
  }, {
    value: 'A2101',
    label: 'Device Markings / Labelling Problem-A2101'
  }, {
    value: 'A020101',
    label: 'Dull, Blunt-A020101'
  }, {
    value: 'A020503',
    label: 'Unsealed Device Packaging-A020503'
  }, {
    value: 'A020504',
    label: 'Tear, Rip or Hole in Device Packaging-A020504'
  }, {
    value: 'A020602',
    label: 'Component Missing-A020602'
  }, {
    value: 'A040602',
    label: 'Dent in Material-A040602'
  }, {
    value: 'A050401',
    label: 'Fluid Leak-A050401'
  }, {
    value: 'A150206',
    label: 'Difficult to Insert-A150206'
  }, {
    value: 'A210101',
    label: 'Expiration Date Error-A210101'
  }, {
    value: 'A210103',
    label: 'Inaccurate Information-A210103'
  }, {
    value: 'A210104',
    label: 'Unclear Information-A210104'
  }, {
    value: 'A210105',
    label: 'Missing Information-A210105'
  }];
}], [_fp["default"].identity, function () {
  return _config.annexA;
}]]);

exports.computeImdrfCodeChoice1Options = computeImdrfCodeChoice1Options;
var hasMultipleSimilarEvents = computedHidden.fieldIs('multipleSimilarEvents', 'Yes');
exports.hasMultipleSimilarEvents = hasMultipleSimilarEvents;
var hasNotGotMultipleSimilarEvents = computedHidden.fieldIsNot('multipleSimilarEvents', 'Yes');
exports.hasNotGotMultipleSimilarEvents = hasNotGotMultipleSimilarEvents;
var clearIfNotMultipleSimilarEvents = computedValue.clearIf(hasNotGotMultipleSimilarEvents);
exports.clearIfNotMultipleSimilarEvents = clearIfNotMultipleSimilarEvents;
var hasAdverseEventDate = computedHidden.fieldIsDefined('adverseEventDate');
exports.hasAdverseEventDate = hasAdverseEventDate;

var adverseEventDateLogic = _fp["default"].cond([[hasAdverseEventDate, computedValue.get('adverseEventDate')], [_fp["default"].T, computedValue.noop]]);

var computedAdverseEventDate = _2.audience.match({
  HCP: adverseEventDateLogic,
  MOP: adverseEventDateLogic,
  DEFAULT: _fp["default"].noop
});

exports.computedAdverseEventDate = computedAdverseEventDate;

var currentDeviceLocationMOPorHCPOptions = function currentDeviceLocationMOPorHCPOptions(_ref) {
  var _ref$without = _ref.without,
      without = _ref$without === void 0 ? [] : _ref$without;
  return _fp["default"].reject(function (option) {
    return _fp["default"].includes(option.value, without);
  })([{
    value: 'Healthcare facility/carer',
    label: 'Healthcare facility/carer'
  }, {
    value: 'Patient/user',
    label: 'Patient/user'
  }, {
    value: 'In transit to manufacturer',
    label: 'In transit to manufacturer'
  }, {
    value: 'Manufacturer',
    label: 'Manufacturer'
  }, {
    value: 'Distributor',
    label: 'Distributor'
  }, {
    value: 'Discarded',
    label: 'Discarded'
  }, {
    value: 'Remains implanted',
    label: 'Remains implanted'
  }, {
    value: 'Healthcare facility network',
    label: 'Healthcare facility network'
  }, {
    value: 'Cloud',
    label: 'Cloud'
  }, {
    value: 'Unknown',
    label: 'Unknown'
  }, {
    value: 'Other',
    label: 'Other, please specify'
  }]);
};

var computeCurrentDeviceLocationOptions = _2.audience.match({
  HCP: currentDeviceLocationMOPorHCPOptions,
  MOP: currentDeviceLocationMOPorHCPOptions,
  DEFAULT: function DEFAULT() {
    return currentDeviceLocationMOPorHCPOptions({
      without: ['Healthcare facility network', 'Cloud']
    });
  }
});

exports.computeCurrentDeviceLocationOptions = computeCurrentDeviceLocationOptions;
var distributionFieldsValidation = {
  fields: ['distribution_all', 'distributionEEA', 'distributionOutsideEU'],
  error: 'Error - Minimum of 1 distribution needed'
};
exports.distributionFieldsValidation = distributionFieldsValidation;
var submitterAddressFieldsValidation = {
  fields: ['reporterOrgStreet', 'reporterOrgStreetNum', 'reporterOrgAddress', 'reporterOrgPOBox'],
  error: 'Error - Minimum of 1 submitter address field needed'
};
exports.submitterAddressFieldsValidation = submitterAddressFieldsValidation;

var canSaveDraft = function canSaveDraft(values) {
  return !_lodash["default"].isNil(values.reportType) && !_lodash["default"].isNil(values.ncaName);
};

exports.canSaveDraft = canSaveDraft;