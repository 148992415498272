import _ from 'lodash'
import React from 'react'
import Section from '../Section'
import digitalStoreSdk from '../../../../../digitalStoreSdk'
import uploadService from '../../../../../services/uploadService'
import toastService from '../../../../../services/toastService'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { Tooltip, Chip, IconButton } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import { translations } from '../../../../../config'
import { SectionTypes } from '../Section'

const AttachmentDetails = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection,
    report
  } = props

  const attachments = useSelector(reportDetailsSelectors.getAttachments)
  const hasAttachments = useSelector(reportDetailsSelectors.getHasAttachments)

  if (!hasAttachments) {
    return <></>
  }

  const renderDownloadButton = (attachment) => {
    const { statusMessage, status } = attachment
    const isComplete = status === 'COMPLETE'
    return (
      <Tooltip title={isComplete ? translations('download') : (statusMessage || status)}>
        {isComplete ?
          <IconButton
            aria-label="download"
            size="small"
            onClick={() => downloadAttachment(attachment)}
          >
            <DownloadIcon />
          </IconButton>
          :
          <Chip label={status} variant={status === 'FAILED' ? 'error' : undefined} />
        }
      </Tooltip>
    )
  }

  const rows = _.map(attachments, (attachment) => {
    return ({
      type: SectionTypes.HYBRID,
      name: _.get(attachment, 'title'),
      value: _.get(attachment, 'ext'),
      attachmentType: _.get(attachment, 'attachmentType'),
      action: renderDownloadButton(attachment),
    })
  })

  return (
    <Section
      name='Attachments'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
      collapsible={false}
      columns={['attachmentType']}
    />
  )
}

const downloadAttachment = async (attachment) => {
  const { attachmentId, attachmenturl } = attachment
  const { name, type } = attachmenturl || {}
  let url

  try {
    switch (true) {
      case !_.isNil(attachmenturl):
        url = await uploadService.downloadUrl({ fileName: name, contentType: type })
        break;
      case !_.isNil(attachmentId):
        const presignedUrl = await digitalStoreSdk.attachments.requestPresignedUrl({ attachmentId })
        url = presignedUrl.url
        break;
      default:
        return;
    }
  } catch (error) {
    toastService.action({
      type: 'error',
      message: translations('Attachment - download failed'),
      autoHideDuration: 3000
    })
  }

  if (url) {
    window.open(url)
  }
}

export default AttachmentDetails
