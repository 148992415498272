import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import Button from '../../../components/Button'
import BottomBar from '../../../components/BottomBar'
import { translations } from '../../../config'
import FullScreenProgress from '../../../components/FullScreenLoader'
import ReportConfigurationTable from '../ReportConfigurationTable'
import NewReportModal from './Modals/NewReport'
import ReportNameModal from './Modals/ReportName'
import CategoryModal from './Modals/Category'

import styles from './style'

const ReportConfigurationScreen = ({
  classes,
  pageTitle,
  isEditing,
  toggleEdit,
  openModal,
  formSchemas,
  setNewReportType,
  setName,
  setCategory,
  submitNewReport,
  closeModal,
  formViews
}) => {
  return (
    <div className={classes.container}>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={
          <Heading component='h1' uppercase>
            {pageTitle.title}
          </Heading>
        }
      />
      <div className={classes.scrollContainer}>
        <ReportConfigurationTable
          isEditing={isEditing}
          toggleEdit={toggleEdit}
        />
      </div>
      <BottomBar>
        <Button
          raised
          color='primary'
          onClick={() => openModal(() => (
            <NewReportModal
              formViews={formViews}
              formSchemas={formSchemas}
              setNewReportType={setNewReportType}
              openModal={openModal}
              categoryModal={
                <CategoryModal
                  classes={classes}
                  closeModal={closeModal}
                  setCategory={setCategory}
                  reportNameModal={
                    <ReportNameModal
                      classes={classes}
                      formViews={formViews}
                      closeModal={closeModal}
                      submitNewReport={submitNewReport}
                      setName={setName}
                    />
                  }
                />
              }
            />
          ))}
        >
          {translations('Configure New Report')}
        </Button>
      </BottomBar>
    </div>
  )
}

ReportConfigurationScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired,
  isEditing: PropTypes.bool,
  toggleEdit: PropTypes.func,
  newReportTypeModal: PropTypes.func,
  nameReportModal: PropTypes.func,
  formSchemas: PropTypes.array,
  setNewReportType: PropTypes.func,
  setName: PropTypes.func,
  submitNewReport: PropTypes.func,
  closeModal: PropTypes.func,
  formViews: PropTypes.array
}

export default FullScreenProgress(withStyles(styles)(ReportConfigurationScreen))
