import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'
import RightButtonContent from '../../../../components/Form/RightButtonContent'
import ToggleIconButton from '../../../../components/ToggleIconButton'
import Heading from '../../../../components/Heading'
import FullScreenProgress from '../../../../components/FullScreenLoader'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ViewIcon from '@material-ui/icons/RemoveRedEye'
import SettingsIcon from '@material-ui/icons/Settings'
import EditableQuestion from '../EditableQuestion'
import DeleteConfirmModal from '../Modals/DeleteConfirmModal'
import modalService from '../../../../services/modalService'
import MoveSectionModal from '../Modals/MoveSectionModal'
import EditSectionModal from '../Modals/EditSectionModal'
import TooltipDetails from '../TooltipDetails'
import { FormBody } from '../../../../components/Form'
import _ from 'lodash'

import style from './style'
import { translations } from '../../../../config'

class FormSection extends Component {
  state = {
    isOpen: this.props.initialOpen,
    editedValues: [],
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  closeModal = () => {
    modalService.close()
  }

  moveFieldSection = ({ questionKey, isHeading }) => {
    const { sectionKey, sectionNames, moveQuestionToSection, nestedSections } = this.props
    modalService.open({
      component: () => (
        <MoveSectionModal
          sectionKey={sectionKey}
          questionKey={questionKey}
          isHeading={isHeading}
          sectionNames={sectionNames}
          moveQuestionToSection={moveQuestionToSection}
          closeModal={this.closeModal}
          nestedSections={nestedSections}
        />
      ),
    })
  }

  renderFormBody() {
    const {
      fields,
      sectionId,
      sectionKey,
      toggleVisibility,
      moveQuestion,
      deleteQuestion,
      openModal,
      editRepeatables,
      configureField,
      schemaFields,
      getFormViewById,
      questionNames,
      audienceChanged,
      formViewDetails,
      setAudienceChanged,
      closeModal,
      nestedSections
    } = this.props
    const sectionLength = _.size(fields)
    const checkCustom = _.find(fields, 'isCustom')
    const checkHeading = _.find(fields, 'isHeading')
    return _.map(fields, (field, key) => {
      const editable = _.get(field, 'editable', true)
      const getSchemaField = _.find(
        schemaFields,
        (schemaField) => schemaField.id === field.id
      )
      return (
        <EditableQuestion
          {...field}
          editable={editable}
          sectionKey={sectionKey}
          questionKey={key}
          key={key}
          sectionId={sectionId}
          sectionLength={sectionLength}
          questionNames={questionNames}
          openModal={openModal}
          closeModal={closeModal}
          editFieldName={this.editFieldName}
          toggleVisibility={toggleVisibility}
          moveQuestion={moveQuestion}
          moveFieldSection={this.moveFieldSection}
          deleteQuestion={deleteQuestion}
          editRepeatables={editRepeatables}
          checkCustom={checkCustom}
          checkHeading={checkHeading}
          configureField={configureField}
          getFormViewById={getFormViewById}
          schemaField={getSchemaField}
          audienceChanged={audienceChanged}
          formViewDetails={formViewDetails}
          setAudienceChanged={setAudienceChanged}
          nestedSections={nestedSections}
        />
      )
    })
  }

  renderHeading() {
    const { sectionId, title, displayName } = this.props

    return (
      <TextContent>
        <Heading id={sectionId}>
          {displayName && (
            <TooltipDetails
              label={translations('Report Configuration - Display name')}
              title={displayName}
            />
          )}
          {translations(title)}
        </Heading>
      </TextContent>
    )
  }

  render() {
    const {
      sectionId,
      sectionKey,
      title,
      classes,
      fields,
      isCustom,
      openModal,
      deleteSection,
      configureField,
      toggleVisibility,
      sectionVisible,
      displayName,
      isForAssessment,
      isForThromboticEvents,
      isForMyocarditis,
      hideFromForm,
      nestedSections
    } = this.props
    return (
      <ContentBox
        id={sectionId}
        contentId={sectionId}
        hidden={!this.state.isOpen}
      >
        <ContentBoxHeader xsLeft={2} xsText={8} xsRight={2}>
          {this.renderHeading()}
          <RightButtonContent>
            <div className={classes.rightButtonContent}>
              <ToggleIconButton
                id={sectionId}
                targetId={sectionId}
                onClick={this.toggleOpen}
                active={this.state.isOpen}
              />
              <IconButton
                size='small'
                className={classes.button}
                style={{ color: 'black' }}
                onClick={() => toggleVisibility({ visible: sectionVisible, nestedSections })}
              >
                <ViewIcon fontSize='inherit' />
                {!sectionVisible && <span className={classes.notVisible} />}
              </IconButton>
              <IconButton
                size='small'
                className={classes.button}
                style={{ color: 'black' }}
                onClick={() => {
                  openModal(() => (
                    <EditSectionModal
                      title={title}
                      initialValues={{
                        displayName,
                        isForAssessment,
                        isForThromboticEvents,
                        isForMyocarditis,
                        hideFromForm
                      }}
                      onCallback={(values) =>
                        configureField({
                          nestedSections,
                          details: values
                        })
                      }
                    />
                  ))
                }}
                aria-label={translations(
                  'Report Configuration - Configure Section'
                )}
              >
                <SettingsIcon fontSize='inherit' />
              </IconButton>
              {isCustom && !fields.length && (
                <IconButton
                  size='small'
                  className={classes.button}
                  style={{ color: 'black' }}
                  onClick={() => {
                    openModal(() => (
                      <DeleteConfirmModal
                        callback={() => deleteSection({ sectionKey })}
                        translation={'Section'}
                      />
                    ))
                  }}
                >
                  <DeleteIcon fontSize='inherit' />
                </IconButton>
              )}
            </div>
          </RightButtonContent>
        </ContentBoxHeader>
        <ContentBoxBody>{this.renderFormBody()}</ContentBoxBody>
      </ContentBox>
    )
  }
}

FormSection.defaultProps = {
  initialOpen: false,
  editing: true,
}

FormSection.propTypes = {
  sectionId: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.shape({}),
  initialOpen: PropTypes.bool,
  sectionKey: PropTypes.number,
  sectionNames: PropTypes.func,
  moveQuestionToSection: PropTypes.func,
  fields: PropTypes.array,
  toggleVisibility: PropTypes.func,
  moveQuestion: PropTypes.func,
  deleteQuestion: PropTypes.func,
  openModal: PropTypes.func,
  editRepeatables: PropTypes.func,
}

export default FullScreenProgress(withStyles(style)(FormSection))
