import { colors } from '../../../config/theme'
import logoStyles from '../../../components/MainLogo/style'

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background
  },
  innerContainer: {
    position: 'relative',
    padding: '20px'
  },
  linksContainer: {
    marginTop: 30
  },
  linkWrapper: {
    textAlign: 'center',
    marginBottom: 10,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  loginWrapper: {
    flexDirection: 'row',
    '@media (max-width: 620px)': {
      flexDirection: 'column-reverse'
    }
  }
}

export default theme => ({
  ...styles,
  ...logoStyles
})
