import fp from 'lodash/fp'
import { createSelector } from 'reselect'
import * as enums from './enums'
const ROOT_KEY = 'fileManager'
export const getFileManager = fp.getOr({}, [ROOT_KEY])
export const getFileManagerStatus = fp.getOr('UNKNOWN', [ROOT_KEY, 'status'])
export const getFiles = fp.getOr([], [ROOT_KEY, 'files'])

export const getIsCancelled = createSelector([
    getFileManagerStatus
], fp.isEqual(enums.fileManagerStatus.CANCELLED))

export const getTotalFiles = createSelector([
    getFiles
], fp.size)

export const getCurrentOperationIndex = createSelector([
    getFiles
], fp.compose(
    fp.defaultTo(''),
    fp.findKey({ status: enums.individualFileStatus.PENDING })
))

export const getFile = id => createSelector([
    getFiles
], fp.get(id))

export const getTotalFileSizeFactory = (filterFn = null) => createSelector(
    getFiles,
    fp.compose(
        fp.defaultTo(0),
        fp.sum,
        fp.map('size'),
        (files) => filterFn ? fp.filter(filterFn, files) : files
    )
)

export const getTotalFileSize = getTotalFileSizeFactory()

export const getCompletedFileSize = getTotalFileSizeFactory(file => {
    const status = file.status
    return status === enums.individualFileStatus.FAILURE || status === enums.individualFileStatus.SUCCESS
})

export const getPendingFileSize = getTotalFileSizeFactory(file => {
    const status = file.status
    return status === enums.individualFileStatus.PENDING
})

export const getErrorCount = createSelector(
    getFiles,
    fp.compose(
        fp.size,
        fp.filter({ status: enums.individualFileStatus.FAILURE })
    )
)

const toPercentage = (value, total) => {
    return ((value / total) * 100) || 0
}

export const getProgressPercentage = createSelector(
    [
        getCompletedFileSize,
        getTotalFileSize
    ],
    toPercentage
)

export const getBufferPercentage = createSelector(
    [
        getPendingFileSize,
        getTotalFileSize
    ],
    toPercentage
)

