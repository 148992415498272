import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class ReceiversActions {
  fetchReceivers = () => ({
    type: constants.FETCH_RECEIVERS,
    promise: () => {
      return digitalStoreSdk.receivers
        .fetchAllReceivers()
    }
  })
}

export default new ReceiversActions()
