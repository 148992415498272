import React, { useState } from 'react'
import _ from 'lodash'
import { Collapse, Grid, IconButton, styled, CircularProgress, Chip } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { translations } from '../../config'
import Heading from '../../components/Heading'
import moment from 'moment'
import MoreMenu from '../../components/MoreMenu/MoreMenu'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`
}))

const Header = styled('header')(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(6),
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: '#fff'
}))

const ToggleButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: theme.spacing(1),
  transform: 'translateY(-50%)'
}))

const Meta = styled('p')(({ theme }) => ({
  fontSize: 14,
  margin: 0,
  marginTop: 4,
  opacity: 0.7
}))

const AuditRow = props => {
  const {
    createdAt,
    id,
    startOpen,
    collapseContent,
    moreMenuOptions,
    rightGridItems,
    rowDescription,
    rowTitle,
    disabled = false
  } = props
  const [isOpen, setIsOpen] = useState(startOpen)
  const [hasFinishedOpening, setHasFinishedOpening] = useState(true)
  const [hasFinishedClosing, setHasFinishedClosing] = useState(true)

  const onToggle = () => {
    if (isOpen) {
      setHasFinishedClosing(false)
    }

    if (!isOpen) {
      setHasFinishedOpening(false)
    }
    setIsOpen(!isOpen)
  }

  const getCollapseButton = () => {
    const shouldShowClose = !isOpen && hasFinishedClosing
    const shouldShowOpen = isOpen && hasFinishedOpening
    const shouldShowPending = !shouldShowClose && !shouldShowOpen
    const toggleState = () => onToggle()
    let ariaLabel = 'Open'
    let ToggleIcon = () => <ExpandLessIcon />
    if (shouldShowClose) {
      ariaLabel = 'Close'
      ToggleIcon = () => <ExpandMoreIcon />
    }
    if (shouldShowPending) {
      ariaLabel = 'Loading'
      ToggleIcon = () => <CircularProgress size={25} />
    }
    return (
      <ToggleButton>
        <IconButton
          onClick={shouldShowPending ? _.noop : toggleState}
          size='small'
          aria-label={
            translations(ariaLabel)
          }
        >
          {<ToggleIcon />}
        </IconButton>
      </ToggleButton>
    )
  }

  return (
    <Container>
      <Header onClick={() => onToggle()}>
        {!disabled && getCollapseButton()}
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs>
                <Heading component='h2' variant={'h3'}>{rowTitle}</Heading>
                {rowDescription && <Meta>{rowDescription}</Meta>}
              </Grid>

              {rightGridItems && _.filter(rightGridItems, 'visible').map((rightGridItem, index) => 
                <Grid item xs={12} sm={'auto'} key={index}>{rightGridItem.component}</Grid>
              )}

              {createdAt && <Chip label={moment(createdAt).format('DD/MM/YYYY HH:mm:ss')} variant="outlined" />}

              {moreMenuOptions &&
                <Grid item>
                  <MoreMenu id={id} options={moreMenuOptions} />
                </Grid>
              } 
            </Grid>
          </Grid>
        </Grid>
      </Header>
      <Collapse
        in={isOpen}
        timeout={400}
        onEntered={() => setHasFinishedOpening(true)}
        onExited={() => setHasFinishedClosing(true)}
      >
        {collapseContent}
      </Collapse>
    </Container>
  )
}

export default AuditRow
