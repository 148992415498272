import {
  getDefaultApplication,
  getApplicationApiKeys,
  getTemporaryApiKey
} from './selectors'
import { useShallowEqualSelector } from '../../../hooks'

export const useDefaultApplication = () => {
  return useShallowEqualSelector(getDefaultApplication)
}

export const useApiKeys = (applicationId) => {
  return useShallowEqualSelector(getApplicationApiKeys(applicationId))
}

export const useTemporaryApiKey = () => {
  return useShallowEqualSelector(getTemporaryApiKey)
}
