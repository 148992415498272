import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import actions from './actions'

class HospitalsUKMiddleware {
  fetchHospitals = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const matchesOrgDetails = matchPath(path, { path: '/reports/new', exact: true })
      if (matchesOrgDetails) {
        dispatch(actions.fetchHospitals())
      }
    }
  }
}

export default new HospitalsUKMiddleware()
