"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateEuBranch = exports.patientCompletion = exports.checkReactionOutcomeAndDeath = exports.checkPrimaryReporterIsWithinTheEea = exports.checkPatientWeight = exports.checkPatientHeight = exports.checkPatientAge = exports.checkParentWeight = exports.checkParentHeight = exports.checkForSuspectOrInteractingPresent = exports.checkFieldInRepeatables = exports.checkEudraCtNumber = exports.branchValidation = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var computedHidden = _interopRequireWildcard(require("../../../../formHelpers/computedHidden"));

var computedValue = _interopRequireWildcard(require("../../../../formHelpers/computedValue"));

var sharedValidators = _interopRequireWildcard(require("../../../../formHelpers/validators"));

var _config = require("../../../../config");

var _utils = require("./utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var EUROPEAN_UNION = 'EU';

var checkPatientAge = function checkPatientAge(params) {
  var initliasedFunction = sharedValidators.checkPatientAge(params);
  return (0, _utils.euValidator)(initliasedFunction);
};

exports.checkPatientAge = checkPatientAge;

var validationFactory = function validationFactory(_ref) {
  var subject = _ref.subject,
      measurement = _ref.measurement,
      upperBoundary = _ref.upperBoundary;
  return function (value) {
    var characteristic = _lodash["default"].get({
      cm: 'height',
      kg: 'weight'
    }, measurement, 'characteristic');

    if (value > upperBoundary) {
      return ["Error - ".concat(subject, " ").concat(characteristic, " exceeds {{upperBoundary}} ").concat(measurement), {
        upperBoundary: upperBoundary
      }];
    }
  };
};

var checkPatientWeight = (0, _utils.euValidator)(validationFactory({
  subject: 'Patient',
  measurement: 'kg',
  upperBoundary: 650
}));
exports.checkPatientWeight = checkPatientWeight;
var checkPatientHeight = (0, _utils.euValidator)(validationFactory({
  subject: 'Patient',
  measurement: 'cm',
  upperBoundary: 250
}));
exports.checkPatientHeight = checkPatientHeight;
var checkParentWeight = (0, _utils.euValidator)(validationFactory({
  subject: 'Parent',
  measurement: 'kg',
  upperBoundary: 650
}));
exports.checkParentWeight = checkParentWeight;
var checkParentHeight = (0, _utils.euValidator)(validationFactory({
  subject: 'Parent',
  measurement: 'cm',
  upperBoundary: 250
}));
exports.checkParentHeight = checkParentHeight;
var patientCompletion = {
  fields: ['patientinitial', 'patientgpmedicalrecordnumb', 'patientonsetage', 'patientagegroup', 'patientweight', 'patientheight', 'patientsex'],
  error: 'Error - R3 1 Patient characteristic is mandatory'
};
exports.patientCompletion = patientCompletion;

var checkFieldInRepeatables = function checkFieldInRepeatables(_ref2) {
  var field = _ref2.field,
      target = _ref2.target,
      repeatable = _ref2.repeatable,
      validationError = _ref2.validationError;
  return function (value, allValues) {
    var valueConforms = _fp["default"].includes(value, target);

    if (valueConforms) {
      return;
    }

    var repeatableInstances = _fp["default"].get(['_parentFormValues', repeatable], allValues);

    var noRepeatables = !repeatableInstances || _fp["default"].isEmpty(repeatableInstances);

    var isNotPresentInAnotherRepeatable = !_fp["default"].compose(_fp["default"].any(computedHidden.fieldIs(field, target)), _fp["default"].reject({
      id: allValues.id
    }))(repeatableInstances);

    if (noRepeatables || isNotPresentInAnotherRepeatable) {
      return validationError;
    }
  };
};

exports.checkFieldInRepeatables = checkFieldInRepeatables;
var checkForSuspectOrInteractingPresent = (0, _utils.euValidator)(checkFieldInRepeatables({
  field: 'drugcharacterization',
  repeatable: 'drug',
  target: ['1', '3'],
  validationError: ['Error - No suspect/interacting drug']
}));
exports.checkForSuspectOrInteractingPresent = checkForSuspectOrInteractingPresent;
var checkReactionOutcomeAndDeath = (0, _utils.euValidator)(function (_value, formValues) {
  var _fp$over = _fp["default"].over([computedHidden.fieldIs('reactionoutcome', '5'), computedHidden.fieldIs('reactionseriousnessdeath', true)])(formValues),
      _fp$over2 = _slicedToArray(_fp$over, 2),
      isFatalOutcome = _fp$over2[0],
      isSeriousnessDeath = _fp$over2[1];

  if (isFatalOutcome && !isSeriousnessDeath) {
    return ["Error - Reaction's results in death must be \"Yes\" if the outcome is fatal"];
  }
});
exports.checkReactionOutcomeAndDeath = checkReactionOutcomeAndDeath;
var checkPrimaryReporterIsWithinTheEea = (0, _utils.euValidator)(function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  var values = _fp["default"].nth(1, args);

  var regulatoryPurposePrimarySourceId = _fp["default"].get('_parentFormValues.primarysourceid', values);

  var isPrimarySourceReporterWithinTheEea = _fp["default"].compose(computedHidden.fieldIs('reportercountry', _config.eeaCountryCodes), _fp["default"].find({
    id: regulatoryPurposePrimarySourceId
  }), _fp["default"].get('_parentFormValues.primarysource'))(values);

  if (isPrimarySourceReporterWithinTheEea) {
    return checkFieldInRepeatables({
      field: 'studyregistrationcountry',
      repeatable: 'studyregistration',
      target: EUROPEAN_UNION,
      validationError: ['Error - 1 Study Registration Country must be European Union']
    }).apply(void 0, args);
  }
});
exports.checkPrimaryReporterIsWithinTheEea = checkPrimaryReporterIsWithinTheEea;
var checkEudraCtNumber = (0, _utils.euValidator)(function (value, allValues) {
  var isNotEuTrial = !computedHidden.fieldIs('studyregistrationcountry', EUROPEAN_UNION)(allValues);
  var eudraCtFormat = /\d{4}-\d{6}-\d{2}/;
  var isValidEudraCtNumber = eudraCtFormat.test(value);

  switch (true) {
    case isNotEuTrial:
      return;

    case isValidEudraCtNumber:
      return;

    default:
      return ["Error - Invalid EudraCT number"];
  }
});
exports.checkEudraCtNumber = checkEudraCtNumber;
var validateEuBranch = computedValue.branch(function (_value, formValues) {
  return (0, _utils.isEu)(formValues);
});
exports.validateEuBranch = validateEuBranch;

var branchValidation = function branchValidation(validators, euValidator, defaultValidator) {
  return validators.handle(validateEuBranch(euValidator, defaultValidator));
};

exports.branchValidation = branchValidation;