import React, { Component } from 'react'

import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { FormBody, FormError } from '../../../../components/Form'
import { PasswordConfirm } from '../../../../components/EditableFields'

import { translations } from '../../../../config'

const PasswordForm = FormContentBox('password')

class WrappedPasswordForm extends Component {
  componentDidUpdate (prevProps, prevState) {
    const { editing, initializeForm } = this.props
    if (editing !== prevProps.editing && editing) {
      // redux form was not initialising the form when it is mounted again
      initializeForm()
    }
  }

  render () {
    const { classes, initialValues, editing, toggleEdit, destroyPasswordForm,
      boxName, editable, isSubmitting,
      onSubmit, formId, isSelectedUserLocked } = this.props
    const editButtonText = isSelectedUserLocked ? translations('Unlock') : translations('Set Now')
    return (
      <PasswordForm
        initialValues={initialValues}
        editable={editable}
        editing={editing}
        toggleEdit={toggleEdit}
        isSubmitting={isSubmitting}
        givenClass={classes.formContainer}
        boxName={boxName}
        onSubmit={onSubmit}
        formId={formId}
        editButtonText={editButtonText}
        saveButtonText={translations('Set')}
        destroyForm={destroyPasswordForm}
      >
        <FormError givenClass={classes.submitError} />
        <FormBody>
          <PasswordConfirm
            label={translations('Mandatory Password')}
            name='password'
            editing={editing}
          />
        </FormBody>
      </PasswordForm>
    )
  }
}

export default Editable(WrappedPasswordForm)
