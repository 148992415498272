import _ from 'lodash'
import { categoryOptions, versionOptions } from '../store/modules/formViews/selectors'
import { translations } from '../config'
import { formSchemaNameMatrix } from './'

export const getNewFormViewSchema = ({ formViews, formSchemas }) => {
  const schema = [
    {
      id: 'name',
      field: 'Input',
      props: {
        label: translations('Name'),
        required: true,
        shrink: true
      }
    },
    {
      id: 'category',
      field: 'Dropdown',
      props: {
        label: translations('Category'),
        required: true,
        options: categoryOptions,
        shrink: true
      }
    },
    {
      id: 'template',
      field: 'Dropdown',
      props: {
        label: translations('Template'),
        shrink: true,
        required: true,
        options: []
      }
    },
    {
      id: 'versionId',
      field: 'Dropdown',
      props: {
        label: translations('Version'),
        required: true,
        options: versionOptions,
        shrink: true
      }
    }
  ]
  const template = schema[_.findIndex(schema, { id: 'template' })]
  if (formSchemas.length) {
    template.props.options = [
      ...template.props.options,
      {
        isSubheader: true,
        label: translations('Standard Reports Title')
      },
      ..._.map(formSchemas, formSchema => {
        return {
          label: formSchemaNameMatrix.getLabel(formSchema.name),
          value: `FORM_SCHEMA.${formSchema.name}`
        }
      })
    ]
  }
  if (formViews.length) {
    template.props.options = [
      ...template.props.options,
      {
        isSubheader: true,
        label: translations('Custom Reports Title')
      },
      ..._.map(formViews, formView => {
        return {
          label: formView.name,
          value: `FORM_VIEW.${formView.id}`
        }
      })
    ]
  }
  return schema
}

export const getDiscardModal = ({ handleBack }) => {
  const schema = {
    title: translations('Warning'),
    text: translations('Report Configuration - Modal - Discard Changes'),
    actions: [
      { text: translations('Cancel') },
      {
        text: translations('Discard changes and go back'),
        onClick: handleBack
      }
    ]
  }
  return schema
}