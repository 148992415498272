/* eslint-disable quote-props */
import _ from 'lodash'
import { createSelector } from 'reselect'
// eslint-disable-next-line no-unused-vars
import { PENDING, SUCCESS } from '../../middleware/redux-promise'

const getStatus = (data) => _.get(data, 'status')
export const formSchemaState = state => _.get(state, 'formSchemas.results', [])
export const organisationReceiversState = state => _.get(state, 'organisationDetails.organisation.receivers', [])
export const formViewState = state => _.get(state, 'reportConfiguration.formViews')
export const reportsState = state => _.get(state, 'reportConfiguration.reports')
export const reportConfigurationState = state => _.get(state, 'reportConfiguration')
export const formViewReportersState = state => _.get(state, 'reportConfiguration.reporters')
export const followUpState = state => _.get(state, 'reportConfiguration.followUps')
export const editorState = state => _.get(state, 'reportConfiguration.editor')
export const formViewFormSchemasState = state => _.get(state, 'reportConfiguration.formSchemas')
export const formViewCohortState = state => _.get(state, 'reportConfiguration.cohorts')

export const getCohorts = (formViewId) => createSelector(
  [getFormViewByIds],
  (formViews) => {
    const activeFormView = _.get(formViews, formViewId)
    return _.get(activeFormView, 'cohorts')
  }
)

export const getFormViews = createSelector(
  [formViewState],
  (formViews) => {
    return _.map(formViews.entities, (id) => {
      return formViews.byId[id]
    })
  }
)

export const getFormViewByIds = createSelector(
  [formViewState],
  (formViewById) => {
    return formViewById.byId
  }
)

export const getActiveFormViewFilters = createSelector(
  [formViewState],
  (formViewState) => {
    return _.get(formViewState, 'activeFormViewFilters')
  }
)

export const isLoadingFormViews = createSelector(
  [formViewState],
  (formViews) => getStatus(formViews) === PENDING
)

export const getFormViewById = (formViewId) => createSelector(
  [getFormViewByIds],
  (formViews) => {
    return _.get(formViews, formViewId)
  }
)

export const getViewJsonFromFormViewId = (formViewId) => createSelector(
  [getFormViewById],
  (formView) => {
    return _.get(formView, 'formView.viewJson')
  }
)

export const getFormViewMeta = (formViewId) => createSelector(
  [getFormViewByIds],
  (formViews) => {
    const activeFormView = _.get(formViews, formViewId)
    const formSchemaName = _.get(activeFormView, 'formSchema.name')
    const formViewVersion = _.get(activeFormView, 'formViewVersions.0', {})
    const createdAt = _.get(activeFormView, 'createdAt')
    const versionId = _.get(activeFormView, 'versionId')
    const formViewType = _.get(activeFormView, 'type')
    const { id, updatedAt, archivedAt } = formViewVersion
    return { type: formSchemaName, formViewVersionId: id, updatedAt, createdAt, archivedAt, versionId, formViewType }
  }
)

export const defaultUsage = {
  'last7Days': 0,
  'last30Days': 0,
  'last90Days': 0
}

export const getFormViewUsage = (formViewId) => createSelector(
  [getFormViewByIds],
  (formViews) => {
    const activeFormView = _.get(formViews, formViewId)
    const usageStats = { ...defaultUsage, ..._.get(activeFormView, 'usage', {}) }
    return usageStats
  }
)

export const getVersionCapabilities = (formViewId) => createSelector(
  [getFormViewByIds],
  (formViews) => {
    const activeFormView = _.get(formViews, formViewId)
    const versionDetails = _.get(activeFormView, 'version.details', {})
    return versionDetails
  }
)

export const getFormViewAudiencesForDropdown = (formViewId) => createSelector(
  [getFormViewByIds],
  (formViews) => {
    const activeFormView = _.get(formViews, formViewId)
    const audiences = _.get(activeFormView, 'formSchema.audience', [])
    return _.map(audiences, (audience) => ({
      value: audience.id,
      label: audience.name
    }))
  }
)

export const getEditorAudiencesForDropdown = (template) => createSelector(
  [getFormViewByIds, formSchemaState],
  (formViews, formSchemas) => {
    const [templateType, key] = _.split(template, '.')
    let audiences = []
    if (templateType == 'FORM_VIEW') {
      audiences = _.get(formViews, `${key}.formSchema.audience`, [])
    }
    if (templateType == 'FORM_SCHEMA') {
      audiences = _.get(_.find(formSchemas, ['name', key]), 'audience')
    }
    return _.map(audiences, (audience) => ({
      value: audience.id,
      label: audience.name
    }))
  }
)

export const getReportsError = createSelector([reportsState], (reports) => _.get(reports, 'error'))
export const getDefaultFilters = createSelector([reportConfigurationState], (state) => _.get(state, 'defaultFilters'))

export const getReportsSize = (formViewId) => createSelector([reportsState], (reports) => _.get(reports, `filtersById.${formViewId}.size`))
export const getReportsPage = (formViewId) => createSelector([reportsState], (reports) => _.get(reports, `filtersById.${formViewId}.page`))

export const getReportResultsById = (formViewId) => createSelector(
  [reportsState],
  (reports) => {
    return _.get(reports, `resultsById.${formViewId}.results`)
  }
)

export const getReportsQuery = (formViewId) => createSelector(
  [reportsState],
  (reports) => _.get(reports, `filtersById.${formViewId}.query`)
)

export const getReportFilters = (formViewId) => createSelector(
  [reportsState],
  (reports) => _.get(reports, `filtersById.${formViewId}`)
)

export const isLoadingReports = createSelector(
  [reportsState],
  (reports) => getStatus(reports) === PENDING
)

export const getReportsHasBeenSearched = (formViewId) => createSelector(
  [reportsState],
  (reports) => {
    const hasQuery = !!_.get(reports, `filtersById.${formViewId}.query`)
    const hasFilters = !_.isEmpty(_.get(reports, `filtersById.${formViewId}`))
    return hasQuery || hasFilters
  })

export const getHasMore = (formViewId) => createSelector([reportsState], (reports) => {
  const base = _.get(reports, `resultsById.${formViewId}`)
  const total = _.get(base, 'total')
  const results = _.get(base, 'results')
  return total !== null && results && results.length < total
})

export const getNumberOfActiveFilters = formViewId => createSelector(
  getReportFilters(formViewId),
  (filters) => {
    return _.reduce(filters, (total, value) => {
      return value ? total + 1 : total
    }, 0)
  }
)

export const getReportersSearchSize = formViewId => createSelector(
  [getFormViewReportersFiltersByIds],
  (filters) => {
    const filter = filters[formViewId]
    return _.get(filter, 'size')
  }
)

export const getReportersSearchPage = formViewId => createSelector(
  [getFormViewReportersFiltersByIds],
  (filters) => {
    const filter = filters[formViewId]
    return _.get(filter, 'page')
  }
)

export const isLoadingFormViewReporters = createSelector(
  [formViewReportersState],
  (reporters) => getStatus(reporters) === PENDING
)

export const canLoadMoreReporters = formViewId => createSelector(
  [formViewReportersState],
  (reporters) => {
    const base = _.get(reporters, `resultsById.${formViewId}`)
    const total = _.get(base, 'total')
    const results = _.get(base, 'results')
    return total !== null && results && results.length < total
  }
)

export const getFormViewReportersByIds = createSelector(
  [formViewReportersState],
  (reporters) => {
    return reporters.byId || {}
  }
)

export const getFormViewReportersFiltersByIds = createSelector(
  [formViewReportersState],
  (reporters) => {
    return reporters.filtersById || {}
  }
)

export const getFormViewReportersById = formViewId => createSelector(
  [getFormViewReportersByIds],
  (byId) => {
    return byId[formViewId]
  }
)

export const getFormViewReportersQuery = formViewId => createSelector(
  [getFormViewReportersFiltersByIds],
  (byId) => {
    return _.get(byId[formViewId], 'query', '')
  }
)
export const getFormViewReportersFilters = formViewId => createSelector(
  [getFormViewReportersFiltersByIds],
  (byId) => {
    return _.omit(byId[formViewId], 'query')
  }
)

export const getFormViewReporter = (formViewId, userId) => createSelector(
  [getFormViewReportersByIds],
  (byId) => {
    const reporters = byId[formViewId]
    return _.find(reporters, { id: userId })
  }
)

export const getFollowUpByIds = createSelector(
  [followUpState],
  (followUpById) => {
    return followUpById.byId
  }
)

export const getFollowUpFiltersByIds = createSelector(
  [followUpState],
  (followUpById) => {
    return followUpById.filtersById || {}
  }
)

export const getFollowUpById = (formViewId) => createSelector(
  [getFollowUpByIds],
  (followUps) => {
    const activeFollowUp = _.get(followUps, formViewId)
    return _.get(activeFollowUp, 'followUps', [])
  }
)

export const getFormViewFollowUpsByReporter = createSelector(
  [followUpState],
  followUps => followUps.byReporter || {}
)

export const getFormViewFollowUpsByReporterId = userId => createSelector(
  [getFormViewFollowUpsByReporter],
  byReporter => byReporter[userId]
)

export const getTimeframeSortedFollowUps = (formViewId) => createSelector(
  [getFollowUpByIds],
  (followUps) => {
    const activeFollowUpDetails = _.get(followUps, formViewId)
    const activeFollowUps = _.get(activeFollowUpDetails, 'followUps')
    return _.sortBy(activeFollowUps, 'followUpTimeframe.value')
  }
)

export const getTimeframesById = (formViewId) => createSelector(
  [getFollowUpByIds],
  (followUps) => {
    const activeFollowUp = _.get(followUps, formViewId)
    return _.get(activeFollowUp, 'timeframes', [])
  }
)

export const getFormViewCohortsByIds = createSelector(
  [formViewCohortState],
  cohorts => cohorts.byId || {}
)

export const getFormViewCohortsById = formViewId => createSelector(
  [getFormViewCohortsByIds],
  byId => byId[formViewId]
)


const _getMultiplier = (type) => {
  switch (type) {
    case 'DAYS':
      return 1
    case 'WEEKS':
      return 7
    case 'MONTHS':
      return 31
    case 'YEAR':
      return 365
  }
}

export const getTimeFramesForDropdown = (formViewId) => createSelector(
  [getFollowUpByIds],
  (followUps) => {
    const activeFollowUp = _.get(followUps, formViewId)
    const timeframes = _.get(activeFollowUp, 'timeframes', [])
    const sortedTimeframes = _.chain(timeframes)
      .map((props) => {
        const { value, type } = props
        const absVal = _getMultiplier(type) * value
        return { ...props, absVal }
      })
      .sortBy('absVal')
      .value()
    return _.map(sortedTimeframes, ({ id, value, type }) => ({ value: id, label: `${value} ${_.camelCase(type)}`, type, count: value }))
  }
)

export const getFollowUpEvents = (formViewId) => createSelector(
  [getFollowUpByIds],
  (followUps) => {
    const activeFollowUp = _.get(followUps, formViewId)
    const events = _.get(activeFollowUp, 'events', [])
    return _.map(events, ({ id, name }) => ({ label: name, value: id }))
  }
)

export const getFollowUpFilterById = (formViewId) => createSelector(
  [getFollowUpFiltersByIds],
  (filters) => {
    return _.get(filters, formViewId)
  }
)

export const isLoadingFollowups = createSelector(
  [followUpState],
  (followUps) => getStatus(followUps) === PENDING
)

export const getFollowUpDependencies = (formViewId) => createSelector(
  [getFollowUpByIds, getFormViewCohortsByIds],
  (followUps, cohorts) => {
    const activeFollowUp = _.get(followUps, `${formViewId}.followUps`)
    const activeCohorts = _.get(cohorts, `${formViewId}`)
    const cohortConditions = _.flattenDeep(_.map(activeCohorts, ({ conditions }) => conditions))
    const conditions = _.get(activeFollowUp, 'reportFormView.formViewVersions.0.dependencies.conditions', [])
    _.forEach(activeFollowUp, (followUp) => {
      const followUpConditions = _.get(followUp, 'formView.formViewVersions.0.dependencies.conditions', [])
      conditions.push(...followUpConditions)
    })
    conditions.push(...cohortConditions)
    return {
      conditions: _.union(conditions)
    }
  }
)

export const getFollowUpFilters = (formViewId) => createSelector(
  [getFollowUpFiltersByIds],
  (filters) => {
    const activeFilters = _.get(filters, formViewId, {})
    return activeFilters
  }
)

export const getFollowUpCohorts = (formViewId) => createSelector(
  [getFollowUpByIds],
  (followUps) => {
    const activeFormView = _.get(followUps, formViewId)
    const rawCohorts = _.get(activeFormView, 'cohorts')
    return _.map(rawCohorts, ({ id, name }) => ({ value: id, label: name }))
  }
)

export const getFollowUpTriggers = (formViewId) => createSelector(
  [getFollowUpByIds],
  (followUps) => {
    const activeFormView = _.get(followUps, formViewId)
    const rawTriggers = _.get(activeFormView, 'triggers')
    return _.map(rawTriggers, ({ id, name }) => ({ value: id, label: name }))
  }
)

export const getEditor = createSelector(
  [editorState],
  (editor) => {
    return editor
  }
)

export const getFormsAndSchemasForDropdown = createSelector(
  [
    getFormViewByIds,
    formSchemaState,
    organisationReceiversState
  ],
  (formViews, formSchemas, receivers) => {
    const lookupSchemas = _.keyBy(receivers, (r) => _.get(r, 'formSchemaId'))
    const filteredFormSchemas = _.filter(formSchemas, (formSchema) => lookupSchemas[formSchema.id])
    const formViewValues = _.values(formViews)
    const notArchivedFormViews = _.filter(formViewValues, ({ archivedAt }) => !archivedAt)
    return { formViews: notArchivedFormViews, formSchemas: filteredFormSchemas }
  }
)

const __applyFormViewFilters = (formViews, filters) => {
  const versionId = _.get(filters, 'versionId')
  const category = _.get(filters, 'category')
  const includeArchived = _.get(filters, 'includeArchived')
  const query = _.get(filters, 'query')
  let filteredFormViews = _.assign({}, formViews)
  if (versionId) {
    filteredFormViews = _.filter(filteredFormViews, { versionId })
  }
  if (category) {
    filteredFormViews = _.filter(filteredFormViews, { category })
  }
  if (!includeArchived) {
    filteredFormViews = _.filter(filteredFormViews, ({ archivedAt }) => !archivedAt)
  }
  if (query) {
    const searches = _.map(_.split(_.escapeRegExp(query), ' '), (word) => new RegExp(`\\b${word}`, 'i'))
    filteredFormViews = _.filter(filteredFormViews, ({ name }) => {
      const testedWords = _.map(searches, (word) => {
        return word.test(name)
      })
      return _.every(testedWords)
    })
  }
  return filteredFormViews
}

export const getFilteredFormViews = createSelector(
  [
    getFormViewByIds,
    formSchemaState,
    organisationReceiversState,
    getActiveFormViewFilters
  ],
  (formViews, formSchemas, receivers, activeFilters) => {
    const lookupSchemas = _.keyBy(receivers, (r) => _.get(r, 'formSchemaId'))
    const formViewValues = _.values(formViews)
    const filteredFormSchemas = _.filter(formSchemas, (formSchema) => lookupSchemas[formSchema.id])
    return { formViews: __applyFormViewFilters(formViewValues, activeFilters), formSchemas: filteredFormSchemas }
  }
)

export const getFormViewFilters = createSelector(
  [
    getActiveFormViewFilters
  ], (filters) => {
    return filters
  }
)

export const getSchemaField = ({ formSchema, fieldId, parentId }) => createSelector(
  [formViewFormSchemasState],
  (formSchemas) => {
    const fields = _.get(formSchemas, `${formSchema}.fields`)
    let matchedField

    if (parentId) {
      const parentField = _.find(fields, { id: parentId })
      matchedField = _.find(_.get(parentField, 'props.schema'), { id: fieldId })
    } else {
      matchedField = _.find(fields, { id: fieldId })
    }

    return matchedField
  }
)

export const getSchemaFields = ({ formSchema }) => createSelector(
  [formViewFormSchemasState],
  (formSchemas) => {
    const fields = _.get(formSchemas, `${formSchema}.fields`)
    return fields
  })

export const getFollowUpReportConfiguration = ({ reportConfigurationId, followUpId }) => createSelector(
  [getFollowUpByIds],
  (followUps) => {
    const basePath = `${reportConfigurationId}.followUps`
    const followUpState = _.get(followUps, basePath)
    const followUpIndex = _.indexOf(_.map(followUpState, ({ id }) => id), followUpId)
    const followUpPath = `${followUpIndex}`
    const currentFollowUpMeta = _.get(followUpState, followUpPath, {})
    return currentFollowUpMeta
  }
)

export const getReportsByReporterLatest = createSelector(
  [reportsState],
  reports => reports.byReporterLatest || {}
)

export const getReporterLatestReportByExtReportId = (userId, extReportId) => createSelector(
  [getReportsByReporterLatest],
  byReporterLatest => _.get(byReporterLatest, [userId, extReportId])
)
