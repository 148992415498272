import React, { Fragment } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import LegacyAcknowledgementForm from './components/LegacyAcknowledgementForm'
import AcknowledgementForm from './components/AcknowledgementForm'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import Editable from '../../../components/Editable'
import ErrorScreen from '../../../components/ErrorScreen'
import { translations } from '../../../config'
import { getAcknowledgmentSchemaName } from '../../../store/modules/platforms/selectors'
import style from './style'

const NewAcknowledgement = Editable((props) => {
  const { onBackClick, editing, reportId, isNewFormMode, isSchemaAvailable, getPageTitle, ackFormSchemaName} = props

  const platformAckFormat = useSelector(getAcknowledgmentSchemaName)

  if (!isSchemaAvailable) {
    return <ErrorScreen title={translations('Acknowledgements not available')} onClick={onBackClick} buttonText={translations('Go back')} />
  }
  const pageTitle = getPageTitle(isNewFormMode ? `Create Ack` : `View Ack`)

  const renderAckForm = () => {
    if (!ackFormSchemaName && !isNewFormMode) {
      return null
    }

    let ackSchema = ackFormSchemaName

    if (isNewFormMode && platformAckFormat) {
      ackSchema = platformAckFormat
    }

    const isPlatformOverride = !_.isNil(platformAckFormat)

    if (ackSchema === 'mhraR2Ack' && (!isNewFormMode || isPlatformOverride)) {
      return <LegacyAcknowledgementForm isNewFormMode={isNewFormMode} reportId={reportId} editing={editing} />
    } else {
      return <AcknowledgementForm isNewFormMode={isNewFormMode} reportId={reportId} isReadOnly={!editing} />
    }
  }


  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
      />
      {renderAckForm()}      
    </div>
  )
})

NewAcknowledgement.propTypes = {
  getPageTitle: PropTypes.func.isRequired
}

export default withStyles(style)(NewAcknowledgement)
