
const styles = theme => {
  const { headerHeight, subheaderHeight, bottomBarHeight } = theme
  return {
    container: {
      position: 'relative'
    },
    subHeader: {
      backgroundColor: 'transparent'
    },
    scrollContainer: {
      height: `400px`,
      overflowY: 'auto'
    }
  }
}

export default styles
