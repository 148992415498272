import { translations } from '../../../../config'
import * as validators from '../../../../validators'

const getSchema = ({
  otherFollowUpNames,
  eventOptions,
  cohortOptions,
  triggerOptions,
  timeframeOptions,
  organisationLanguages
} = {}) => (
  [
    {
      id: 'name',
      field: 'Input',
      props: {
        label: translations('Name'),
        required: true,
        shrink: true,
        validate: [validators.valueBlocklist(otherFollowUpNames, translations('Name must be unique'), true /* ignore case */)]
      }
    },
    {
      id: 'stageId',
      field: 'Dropdown',
      props: {
        label: translations('FollowUp - Form Select Event'),
        name: 'stageId',
        options: eventOptions,
        required: true
      }
    },
    {
      id: 'cohortId',
      field: 'Dropdown',
      props: {
        label: translations('FollowUp - Form Select Cohort'),
        name: 'cohortId',
        options: cohortOptions,
        required: true
      }
    },
    {
      id: 'triggerId',
      field: 'Dropdown',
      props: {
        label: translations('FollowUp - Form Select Trigger'),
        name: 'triggerId',
        options: triggerOptions,
        required: true
      }
    },
    {
      id: 'timeframeId',
      field: 'Dropdown',
      props: {
        label: translations('FollowUp - Form Select Frequency'),
        name: 'timeframeId',
        options: timeframeOptions,
        required: true
      }
    },
    {
      id: 'message',
      field: 'MultiLanguageInput',
      props: {
        shrink: true,
        name: 'message',
        label: translations('FollowUp - Form Input Message'),
        validate: [validators.maxLength(1024, 'Field')],
        languages: organisationLanguages
      }
    }
    // {
    //   id: 'force',
    //   field: 'Checkbox',
    //   props: {
    //     name: 'force',
    //     label: translations('FollowUp - Form Force Send')
    //   }
    // }
  ]
)

export default getSchema
