import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  page: 1,
  size: 50,
  error: undefined,
  query: undefined,
  total: undefined,
  status: undefined,
  results: [],
  filters: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SEARCH_SUBMISSIONS_FRESH:
      return reducers.searchFresh(state, action)
    case constants.SEARCH_SUBMISSIONS_NEXT:
      return reducers.searchNext(state, action)
    case constants.CHANGE_SUBMISSION_FILTER:
      return reducers.changeFilter(state, action)
    case constants.CHANGE_SUBMISSION_FILTERS:
      return reducers.changeFilters(state, action)
    default:
      return state
  }
}
