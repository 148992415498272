"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateUserValues = exports.generateComputedValues = void 0;

var _moment = _interopRequireDefault(require("moment"));

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = _interopRequireDefault(require("lodash/fp"));

var _index = require("../../index");

var _constants = require("../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var generateComputedValues = function generateComputedValues(_ref) {
  var schemaName = _ref.schemaName,
      organisationDetails = _ref.organisationDetails,
      _ref$formViewDetails = _ref.formViewDetails,
      formViewDetails = _ref$formViewDetails === void 0 ? {} : _ref$formViewDetails,
      timestamp = _ref.timestamp;
  var _organisationDetails$ = organisationDetails.sendercountrycode,
      sendercountrycode = _organisationDetails$ === void 0 ? '' : _organisationDetails$;
  var _formViewDetails$send = formViewDetails.senderevid,
      senderevid = _formViewDetails$send === void 0 ? organisationDetails.senderevid : _formViewDetails$send;

  switch (schemaName) {
    case _constants.FORM_SCHEMAS_CONSTANTS.MIR_REPORT:
      return {
        primarysourcecountry: sendercountrycode
      };

    case _constants.FORM_SCHEMAS_CONSTANTS.MHRA_R2_REPORT:
      {
        var safetyreportId = (0, _index.generateSafetyReportId)({
          sendercountrycode: sendercountrycode,
          senderevid: senderevid,
          timestamp: timestamp
        });
        return {
          messagetype: 'ICHICSR',
          reporttype: _lodash["default"].get(formViewDetails, 'reporttype', '1'),
          observestudytype_primarysource: _lodash["default"].get(formViewDetails, 'observestudytype'),
          companynumb: '',
          authoritynumb: safetyreportId,
          safetyreportid: safetyreportId,
          messagenumb: safetyreportId,
          primarysourcecountry: sendercountrycode
        };
      }

    case _constants.FORM_SCHEMAS_CONSTANTS.MHRA_R3_REPORT:
      {
        var safetyReportId = (0, _index.generateSafetyReportId)({
          sendercountrycode: sendercountrycode,
          senderevid: senderevid,
          timestamp: timestamp
        });
        var dateNow = (0, _moment["default"])().format('YYYYMMDD');
        return {
          messagetype: 'ICHICSR',
          reporttype: _lodash["default"].get(formViewDetails, 'reporttype', '1'),
          observestudytype: _lodash["default"].get(formViewDetails, 'observestudytype'),
          safetyreportid: safetyReportId,
          messagenumb: safetyReportId,
          authoritynumb: safetyReportId,
          primarysourcecountry: sendercountrycode,
          receiptdate: dateNow,
          firstsenderofcase: '1',
          othercases: false,
          primarysourceforreg_primarysource: '1',
          fulfillexpeditecriteria: true
        };
      }

    default:
      return {};
  }
};

exports.generateComputedValues = generateComputedValues;

var generateUserValues = function generateUserValues(_ref2) {
  var schemaName = _ref2.schemaName,
      userDetails = _ref2.userDetails,
      isWorkspace = _ref2.isWorkspace;
  var email = userDetails.email,
      professionId = userDetails.professionId,
      title = userDetails.title,
      firstName = userDetails.firstName,
      lastName = userDetails.lastName,
      company = userDetails.company,
      houseNumberOrName = userDetails.houseNumberOrName,
      address = userDetails.address,
      addressLineTwo = userDetails.addressLineTwo,
      city = userDetails.city,
      county = userDetails.county,
      country = userDetails.country,
      postalCode = userDetails.postalCode,
      telephone = userDetails.telephone;

  switch (schemaName) {
    case _constants.FORM_SCHEMAS_CONSTANTS.MIR_REPORT:
      var professionType = _lodash["default"].get(userDetails, 'professionType');

      var workspaceFields = {
        reporterOrgName: company,
        reporterContactPersonFirstName: firstName,
        reporterContactPersonSecondName: lastName,
        reporterOrgEmailAddress: email,
        reporterOrgPhone: telephone,
        reporterOrgCountry: country,
        reporterOrgStreet: _fp["default"].trim(_fp["default"].join(' ')([address, addressLineTwo])),
        reporterOrgStreetNum: houseNumberOrName,
        reporterOrgCity: city,
        reporterOrgPostcode: postalCode
      };
      var mappedProfession;

      if (professionType) {
        switch (professionType) {
          case 'Health Care Professional':
            mappedProfession = 'Healthcare professional';
            break;

          case 'Public':
            mappedProfession = 'Patient';
            break;

          case 'Parent':
            mappedProfession = 'Lay user';
            break;

          default:
            mappedProfession = 'Other';
        }
      }

      return _objectSpread({
        professionofreporter: professionId,
        healthcareFacilityContactPersonFirstName: firstName,
        healthcareFacilityContactPersonSecondName: lastName,
        healthcareFacilityEmail: email,
        healthcareFacilityPhone: telephone,
        reporteraddresshousenameornumber: houseNumberOrName || company,
        reporteraddressline1: address,
        reporteraddressline2: addressLineTwo,
        healthcareFacilityCity: city,
        healthcareFacilityPostcode: postalCode,
        initialReporterRole: mappedProfession
      }, isWorkspace && workspaceFields);

    case _constants.FORM_SCHEMAS_CONSTANTS.MHRA_R2_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.MHRA_R3_REPORT:
      return {
        professionofreporter: professionId,
        personaldetailstitle: title,
        personaldetailsgivename: firstName,
        personaldetailsfamilyname: lastName,
        reporterstreet_primarysource: address,
        reporteraddresshousenameornumber: houseNumberOrName || company,
        reporteraddressline1: address,
        reporteraddressline2: addressLineTwo,
        reportercity_primarysource: city,
        reporterstate_primarysource: county,
        reporterpostcode_primarysource: postalCode,
        reportertel_primarysource: telephone,
        personaldetailsemailaddress: email
      };

    default:
      return {};
  }
};

exports.generateUserValues = generateUserValues;