import React from 'react'
import StandardForm from '../StandardForm'
import { SdkProvider, useSdk } from '../../../services/sdk'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

const RegistrationForm = ({
  onSuccess,
  onError,
  organisationId,
  organisationDetails,
  professions,
  privacyPolicyLink
}) => {

  const { register } = useSdk()

  const onSubmit = async (data) => {
    try {
      const modifiedData = _.omit(data, 'confirmPassword')
      modifiedData.isPublic = true
      modifiedData.organisationIds = [organisationId]
      modifiedData.emailCommunicationsPermitted = _.includes(modifiedData.communicationPreferences, 'email')
      modifiedData.smsCommunicationsPermitted = _.includes(modifiedData.communicationPreferences, 'sms')
      if(modifiedData.hospitalOrPracticeOrOrganisation) {
        modifiedData.houseNumberOrName = modifiedData.hospitalOrPracticeOrOrganisation
      }
      await register(modifiedData)
      onSuccess && await onSuccess({
        email: modifiedData.email, 
        password: modifiedData.password, 
        platformId: modifiedData.platformId, 
        stayLoggedIn: modifiedData.stayLoggedIn 
      })
    } catch (error) {
      onError && onError(error)
      return { [FORM_ERROR]: error.message }
    }
  }

  return (
    <StandardForm
      schemaName={'registration'}
      onSubmit={onSubmit}
      professions={professions}
      organisationDetails={organisationDetails}
      privacyPolicyLink={privacyPolicyLink}
    />
  )
}

const WrappedRegistrationForm = (props) => {
  const {
    baseUrl,
    organisationId,
    platformId,
    ...rest
  } = props

  return (
    <SdkProvider
      baseUrl={baseUrl}
      organisationId={organisationId}
      platformId={platformId}
    >
      <RegistrationForm organisationId={organisationId} {...rest} />
    </SdkProvider>
  )
}

export default WrappedRegistrationForm