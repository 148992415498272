import _ from 'lodash'

const getNumber = n => Number(n) || 0

const units = [
  { amount: 1, code: 'Bytes' },
  { amount: 1000, code: 'KB' },
  { amount: 1000 * 1000, code: 'MB' },
  { amount: 1000 * 1000 * 1000, code: 'GB' },
  { amount: 1000 * 1000 * 1000 * 1000, code: 'TB' }
]

class BytesFormatter {
  getUnit = (bytes) => {
    for (let index = 0; index < units.length; index++) {
      const unit = units[index]
      const { amount } = unit
      if ((amount * 1000) > bytes) {
        return unit
      }
    }
    return units.slice(-1)[0]
  }
  format = (_bytes, _totalBytes) => {
    const bytes = getNumber(_bytes)
    const totalBytes = getNumber(_totalBytes)
    const biggestValue = Math.max(bytes, totalBytes)
    const unit = this.getUnit(biggestValue)
    const { amount, code } = unit
    const getFigure = (num) => amount >= 1000 ? (num / amount).toFixed(2) : (num / amount)
    if (!_totalBytes && !_totalBytes === 0) {
      return `${getFigure(bytes)} ${code}`
    } else {
      return `${getFigure(bytes)} / ${getFigure(totalBytes)} ${code}`
    }
  }
}

export default new BytesFormatter()
