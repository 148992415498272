import React from 'react'
import PrivateRoute from '../../../../components/PrivateRoute'
import { slugify } from '../../../../helpers'
import schema from '../../../../config/configFiles/organisationHealthCheckSchema.json'

import OrganisationReportingFullList from './OrganisationFullList'

// this only needs to be done once so it can be outside a render function
// because schema is static
const OrganisationReportingScreens = (
  schema
    .map((report, i) => {
      const path = `/monitoring-and-analytics/healthCheck/organisation/${i}-${slugify(report.title || 'report')}`
      return (
        report.fullList
          ? <PrivateRoute
            key={path}
            path={path}
            component={() => <OrganisationReportingFullList reportSchema={report} state />}
            exact
          />
          : null
      )
    })
    .filter(Boolean)
)

export default OrganisationReportingScreens
