import { connect } from 'react-redux'
import {
  compose,
  withHandlers
} from 'recompose'

import modalService from "../../../services/modalService"
import { selectors as organisationDetailsSelectors } from '../../../store/modules/organisationDetails'

import ReportConfigurationFollowUpDetails from './ReportConfigurationFollowUpDetails'

const mapStateToProps = state => {
  const organisationLanguages = organisationDetailsSelectors.getCurrentOrganisationLanguagesAsOptions(state)

  return {
    organisationLanguages
  }
}

const enhancer = compose(
  connect(mapStateToProps),
  withHandlers({
    openModal: () => component => {
      modalService.open({
        component
      })
    },
    closeModal: () => () => {
      modalService.close()
    },
  })
)

export default enhancer(ReportConfigurationFollowUpDetails)
