import { connect } from 'react-redux'
import NetworkNotification from './NetworkNotification'
import { selectors as networkSelectors } from '../../store/modules/network'

const mapStateToProps = state => {
  return {
    isOffline: !networkSelectors.isConnected(state)
  }
}

export default connect(mapStateToProps)(NetworkNotification)
