import digitalStoreSdk from '../../../../digitalStoreSdk'
import _ from 'lodash'

export const createImplementation = ({ props }) => ({
  minimumSearchLength: 3,
  multiple: true,
  asyncFunction: async ({
    value,
  }) => {
    const searchParams = {
      query: { search: value },
      organisationId: props.organisationId
    }

    const data = await digitalStoreSdk.location.fetchOrganisationLocations(searchParams)
    const { regions, districts } = data

    const groupedDistricts = _.groupBy(districts, 'parentId')
    const mappedOptions = []

    for (const region of regions) {
      mappedOptions.push({
        value: region.locationId,
        label: region.name
      })
      const districts = groupedDistricts[region.locationId]
      const mappedDistricts = _.map(districts, district => ({
        value: district.locationId,
        label: district.name,
        isChild: true
      }))
      mappedOptions.push(...mappedDistricts)
    }
    return mappedOptions
  },
  renderOption: (option) => {
    return (
      <span style={{ paddingLeft: option.isChild ? 10 : 0 }}>{option.label}</span>
    )
  }
})
