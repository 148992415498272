import React from 'react'
import Typography from '@material-ui/core/Typography'
import fp from 'lodash/fp'
import { translations } from '../../../../config'
import { styled } from '@material-ui/core/styles'
const Text = styled(Typography)(({ theme }) => {
    const style = {
        ...theme.typography.body2
    }
    return style
})

const ActionTypeCell = (props) => {
    const actionType = fp.getOr('NONE', 'entry.action.actionType', props)
    switch (actionType) {
        case 'IGNORE':
            return <Text>{translations('Terminology Entry Action - Ignore')}</Text>
        case 'NONE':
            return <Text>{translations('Terminology Entry Action - None')}</Text>
        case 'APPLY_ENTRY_MAPPING':
            return <Text>{translations(
                'Terminology Entry Action - Map to Term', {
                dictionary: props.entry.action.mappedEntry.dictionary.name,
                term: props.entry.action.mappedEntry.term,
                code: props.entry.action.mappedEntry.definition || ''
            }
            )}</Text>
        case 'APPLY_MEDDRA_MAPPING':
            return <Text>{translations(
                'Terminology Entry Action - Map to Term', {
                dictionary: 'MedDRA',
                term: props.entry.action.meddra.name,
                code: props.entry.action.meddra.code || ''
            }
            )}</Text>
            case 'APPLY_FREE_TEXT_MAPPING':
                return <Text>{translations(
                    'Terminology Entry Action - Map to Free Text', {
                        mapping: props.entry.action.mapToFreeText
                }
                )}</Text>
            case 'REMOVE_REPEATABLE':
                return <Text>{translations('Terminology Entry Action - Remove Repeatable')}</Text>
        default:
            return null
    }

}

export default ActionTypeCell
