import moment from 'moment'
import _ from 'lodash'
import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { dateFormat } from '../../../config'

const _formatFilterDate = (date) => {
  if (!date) return undefined
  const dateMoment = moment(date, dateFormat)
  if (dateMoment.isValid() && dateMoment.isAfter('1000-01-01')) {
    return dateMoment.format('YYYY/MM/DD')
  }
  return undefined
}

const _mapFilters = (filters) => {
  return _.omitBy({
    ...filters,
    dateFrom: _formatFilterDate(filters.dateFrom),
    dateTo: _formatFilterDate(filters.dateTo)
  }, _.isNil)
}

class SubmissionsActions {
  fetchSubmissions = ({ organisationId }) => ({
    type: constants.GET_SUBMISSIONS,
    payload: { organisationId },
    promise: () => digitalStoreSdk.submissions.fetchSubmissions({ organisationId })
  })
  fetchSubmissionById = ({ organisationId, submissionId }) => ({
    type: constants.GET_SUBMISSION,
    payload: { organisationId, submissionId },
    promise: () => digitalStoreSdk.submissions.fetchSubmissionById({ organisationId, submissionId })
  })
  searchFresh = () => ({
    type: constants.SEARCH_SUBMISSIONS_FRESH,
    promise: (_dispatch, getState) => {
      const state = getState()
      const size = selectors.getSearchSize(state)
      const filters = selectors.getFilters(state)
      return digitalStoreSdk.submissions
        .fetchSubmissions({
          page: 1,
          size,
          ..._mapFilters(filters)
        })
    }
  })
  searchNext = () => ({
    type: constants.SEARCH_SUBMISSIONS_NEXT,
    promise: (_dispatch, getState) => {
      const state = getState()
      const size = selectors.getSearchSize(state)
      const page = selectors.getSearchPage(state)
      const filters = selectors.getFilters(state)
      return digitalStoreSdk.submissions
        .fetchSubmissions({
          page: page + 1,
          size,
          ..._mapFilters(filters)
        })
    }
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
  changeFilters = ({ filters }) => {
    return {
      type: constants.CHANGE_SUBMISSION_FILTERS,
      page: 1,
      filters
    }
  }
  changeFilter = ({ filter }) => ({
    type: constants.CHANGE_SUBMISSION_FILTER,
    filter
  })
}

export default new SubmissionsActions()
