import _ from 'lodash'
import mergeby from 'mergeby'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import { promiseReducer } from '../../util'

class CustomerReducers {
  fetchPersonalNotificationsFresh = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchPersonalNotificationsFreshPending(state, action)
      case SUCCESS:
        return this.fetchPersonalNotificationsFreshSuccess(state, action)
      case FAIL:
        return this.fetchPersonalNotificationsFreshFail(state, action)
      default:
        return state
    }
  }
  fetchPersonalNotificationsFreshPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchPersonalNotificationsFreshSuccess = (state, action) => {
    const { total, results } = action.result
    return {
      ...state,
      total,
      results,
      page: 1,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchPersonalNotificationsFreshFail = (state, action) => {
    return {
      ...state,
      error: action.error.message,
      status: FAIL,
      action: undefined
    }
  }

  fetchPersonalNotificationsNext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchPersonalNotificationsNextPending(state, action)
      case SUCCESS:
        return this.fetchPersonalNotificationsNextSuccess(state, action)
      case FAIL:
        return this.fetchPersonalNotificationsNextFail(state, action)
      default:
        return state
    }
  }
  fetchPersonalNotificationsNextPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchPersonalNotificationsNextSuccess = (state, action) => {
    const { total, results } = action.result
    const { page, results: currentResults } = state
    const finalResults = _.uniqBy([...currentResults, ...results], 'id')
    return {
      ...state,
      total,
      page: page + 1,
      results: finalResults,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchPersonalNotificationsNextFail = (state, action) => {
    return {
      ...state,
      error: action.error.message,
      status: FAIL,
      action: undefined
    }
  }

  fetchUserNotificationsFresh = promiseReducer(
    (state, action) => {
      const { total, results } = action.result
      const { selectedUserId } = action
      return {
        ...state,
        total,
        results,
        page: 1,
        status: SUCCESS,
        action: undefined,
        error: undefined,
        selectedUserId
      }
    }
  )
  fetchUserNotificationsNext = promiseReducer(
    (state, action) => {
      const { total, results } = action.result
      const { page, results: currentResults } = state
      const finalResults = _.uniqBy([...currentResults, ...results], 'id')
      return {
        ...state,
        total,
        page: page + 1,
        results: finalResults,
        status: SUCCESS,
        action: undefined,
        error: undefined
      }
    }
  )

  // SET NOTIFICATION USER STATUS
  setNotificationUserStatus = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.setNotificationUserStatusPending(state, action)
      case SUCCESS:
        return this.setNotificationUserStatusSuccess(state, action)
      case FAIL:
        return this.setNotificationUserStatusFail(state, action)
      default:
        return state
    }
  }
  setNotificationUserStatusPending = (state, action) => {
    const { id, status } = action.payload
    return {
      ...state,
      status: PENDING,
      action: action.type,
      results: mergeby(state.results, {
        notificationId: id,
        ...status
      }, 'notificationId')
    }
  }
  setNotificationUserStatusSuccess = (state, action) => {
    const { notificationId, isFlagged, isRead } = action.result
    return {
      ...state,
      status: SUCCESS,
      results: mergeby(state.results, {
        notificationId,
        isFlagged,
        isRead
      }, 'notificationId'),
      totalNotificationsNotViewed: (state.totalNotificationsNotViewed - 1)
    }
  }
  setNotificationUserStatusFail = (state, action) => {
    const { id, status } = action.payload
    const revertedStatus = _.mapObject(status, (val) => !val)

    return {
      ...state,
      status: FAIL,
      results: mergeby(state.results, {
        notificationId: id,
        ...revertedStatus
      }, 'notificationId')
    }
  }

  // SET NOTIFICATIONS NOT VIEWED
  setTotalNotificationsNotViewed = (state, action) => {
    return {
      ...state,
      totalNotificationsNotViewed: action.payload.total
    }
  }

  // GET NOTIFICATIONS NOT VIEWED
  getTotalNotificationsNotViewed = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.getTotalNotificationsNotViewedPending(state, action)
      case SUCCESS:
        return this.getTotalNotificationsNotViewedSuccess(state, action)
      case FAIL:
        return this.getTotalNotificationsNotViewedFail(state, action)
      default:
        return state
    }
  }
  getTotalNotificationsNotViewedPending = (state, action) => {
    return {
      ...state,
      status: action.status,
      action: action.type
    }
  }
  getTotalNotificationsNotViewedSuccess = (state, action) => {
    return {
      ...state,
      totalNotificationsNotViewed: action.result.total
    }
  }
  getTotalNotificationsNotViewedFail = (state, action) => {
    return {
      ...state,
      status: action.status,
      action: action.type,
      error: action.error.message
    }
  }

  // SET NOTIFICATION ACTIONED STATUS
  setNotificationActionedStatus = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.setNotificationActionedStatusPending(state, action)
      case SUCCESS:
        return this.setNotificationActionedStatusSuccess(state, action)
      case FAIL:
        return this.setNotificationActionedStatusFail(state, action)
      default:
        return state
    }
  }
  setNotificationActionedStatusPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  setNotificationActionedStatusSuccess = (state, action) => {
    // returns a notification NOT a notificationUser
    // so it is id not notificationId
    const { id, isActioned, meta } = action.result
    return {
      ...state,
      status: SUCCESS,
      results: mergeby(state.results, {
        notificationId: id,
        isActioned,
        meta
      }, 'notificationId')
    }
  }
  setNotificationActionedStatusFail = (state, action) => {
    return {
      ...state,
      error: action.error.message,
      status: FAIL,
      action: undefined
    }
  }
  updateNotificationFilters = (state, action) => {
    const { filters } = action
    return {
      ...state,
      filters
    }
  }
  removeNotificationFromList = (state, action) => {
    const { id } = action.payload
    const newResults = state.results.filter(notification => notification.notificationId !== id)
    return {
      ...state,
      results: newResults
    }
  }
}

export default new CustomerReducers()
