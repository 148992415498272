import { transparentize, parseToHsl, setLightness } from 'polished'
import _ from 'lodash'

export default theme => {
  const mainColor = _.get(theme, 'palette.primary.main')
  const tooLight = !!(parseToHsl(mainColor).lightness > 0.8)
  const progressColor = tooLight ? setLightness(0.7, mainColor) : mainColor

  return {
    box: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      width: '100%',
      maxWidth: 400,
      padding: 20,
      transition: `0.25s ease-out all`,
      zIndex: 2001 // remove this before PR
    },
    hidden: {
      opacity: 0,
      transform: `translateY(100%)`
    },
    progressContainer: {
      height: 8,
      width: '100%',
      backgroundColor: transparentize(0.8, progressColor)
    },
    progressBar: {
      height: '100%',
      width: 0,
      backgroundColor: progressColor,
      transition: '0.05s ease-out width'
    },
    progressText: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    top: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    closeIconContainer: {
      margin: -16
    }
  }
}
