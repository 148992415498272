const style = theme => ({
  editButton: {
    marginRight: 20
  },
  contentContainer: {
    padding: 30,
    maxWidth: 850,
    margin: '0 auto'
  },
  line: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    margin: '20px 0'
  },
  descriptionText: {
    marginTop: 10,
    fontSize: 16,
    lineHeight: '1.4'
  },
  detailsAndDocsContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  detailsAndDocsInnerContainer: {},
  detailsContainer: {
    lineHeight: '1.35',    
    '&:last-child': {
      paddingRight: 0
    },
    '& img': {
      maxWidth: '100%'
    }
  },
  documentsContainer: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: 20,
    marginTop: 30    
  },
  fileExtensionText: {
    fontSize: 11
  },
  linkContainer: {
    marginTop: 10
  },
  documentList: {
    listStyle: 'none',
    padding: 0
  }
})

export default style
