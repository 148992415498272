import React from 'react'
import LookupInput from '../LookupInput'
import HospitalsUKLookupInputModal from '../../HospitalsUKLookupInputModal'
import { Input } from '../../../components/Fields'

export default ({ isUsingHospitalsUKList, ...props }) => {
  if (isUsingHospitalsUKList) {
    return <LookupInput component={HospitalsUKLookupInputModal} {...props} lookUpOnly={false} />
  } else {
    return <Input {...props} />
  }
}
