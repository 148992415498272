import { SUCCESS, FAIL } from '../../middleware/redux-promise'

class NewsFeedReducers {
  fetchNewsFeeds = (state, action) => {
    return {
      ...state,
      status: action.status,
      results: action.result || state.results
    }
  }
  fetchDistinctNewsFeedSources = (state, action) => {
    return {
      ...state,
      status: action.status,
      distinctNewsSources: action.result || state.results
    }
  }
  createNewsFeed = (state, action) => {
    if (action.status === SUCCESS) {
      return {
        ...state,
        results: [action.result].concat(state.results),
        createError: undefined
      }
    }

    return {
      ...state,
      createStatus: action.status,
      createError: action.error ? action.error.message : undefined
    }
  }
  deleteNewsFeed = (state, action) => {
    if (action.status === SUCCESS) {
      return {
        ...state,
        results: state.results.filter(res => res.id !== action.payload.id)
      }
    }

    return {
      ...state,
      deleteStatus: action.status,
      deleteError: action.error ? action.error.message : undefined
    }
  }
  clearNewsFeedErrors = (state) => {
    return {
      ...state,
      deleteError: undefined,
      deleteStatus: undefined,
      createError: undefined,
      createStatus: undefined,
      status: undefined
    }
  }
}

export default new NewsFeedReducers()
