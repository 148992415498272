import React from 'react'

import Editable from '../Editable'
import { FilePicker } from '../../../components/Fields'
import P from '../../P'
import { getFieldDisplayValue } from '../../../helpers'

export default Editable({
  input: (props) => {
    return (
      <FilePicker {...props} />
    )
  },
  display: ({ label, value, type, format, hideIfEmpty, isEmpty }) => {
    if (isEmpty && hideIfEmpty) return null
    const { displayValue, isUrl, onClick } = getFieldDisplayValue({ value, label, type })
    return (
      <div>
        {!isUrl && <P value={label} type='textButton' />}
        <P value={format ? format(value) : displayValue} dontBreakOut={isUrl} onClick={onClick} />
      </div>
    )
  }
})
