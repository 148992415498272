import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'

import Tooltip from '@material-ui/core/Tooltip'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'

import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

import { DataTableRow, DataTableCell } from '../../../components/DataTable'
import ButtonLink from '../../../components/ButtonLink'

import { translations } from '../../../config'

const Row = styled(DataTableRow)({
  cursor: 'pointer'
})

const SourceTitle = styled('div')(({ theme }) => ({
  whiteSpace: 'pre-line',
  fontSize: '12px'
}))

const FormViewRow = (props) => {
  const { entry } = props
  const { id, name, publish, public: isPublic, sources, archivedAt, formSchema, details = {}, formViewVersions, category, versionId, usage = {} } = entry
  const { last30Days = 0 } = usage
  const { audienceId } = details
  const updatedAt = _.get(formViewVersions, '0.updatedAt')
  const usageCount = Math.abs(last30Days) > 999 ? `${(last30Days / 1000).toFixed(0)}k` : last30Days
  const isArchived = !_.isNil(archivedAt)
  const sourceCount = _.size(sources)

  const renderSourcesTooltip = () => {
    const sourceNames = _.map(sources, 'name')
    const sourceTooltip = _.size(sourceNames) > 0 ? _.join(sourceNames, '\n') : undefined
    const sourceContent = isPublic ? 'All' : sourceCount

    if (sourceTooltip) {
      return (
        <Tooltip title={<SourceTitle>{sourceTooltip}</SourceTitle>}>
          <p>{sourceContent}</p>
        </Tooltip>
      )
    }

    return (
      <p>{sourceContent}</p>
    )
  }


  const booleanToIcon = (value) => value
    ? <CheckIcon titleAccess={translations('Yes')} />
    : <CloseIcon color={'disabled'} titleAccess={translations('No')} />

  const dispatch = useDispatch()
  const onClick = () => dispatch(push(`/report-configuration-beta/${id}`))

  return (
    <Row hover onClick={onClick}>
      <DataTableCell component='th' scope='row' heading>
        <ButtonLink onClick={onClick}>{name}</ButtonLink>
      </DataTableCell>
      <DataTableCell align={'center'}>{versionId || '-'}</DataTableCell>
      <DataTableCell align={'center'}>{translations(`Form Schema - ${formSchema.name}`)}</DataTableCell>
      <DataTableCell align={'left'}>{category || '-'}</DataTableCell>
      <DataTableCell align={'left'}>{_.isNumber(audienceId) ? translations(`Filter audience - ${audienceId}`) : '-'}</DataTableCell>
      <DataTableCell align={'center'}>{booleanToIcon(publish)}</DataTableCell>
      <DataTableCell align={'center'}>{renderSourcesTooltip()}</DataTableCell>
      <DataTableCell align={'center'}>{booleanToIcon(isArchived)}</DataTableCell>
      <DataTableCell align={'center'}>
        <Tooltip title={translations('Usage tooltip - Days', { count: 30 })} placement={'top'}>
          <span>{usageCount}</span>
        </Tooltip>
      </DataTableCell>
      <DataTableCell align={'right'} nowrap>{moment(updatedAt).format('DD/MM/YYYY HH:mm')}</DataTableCell>
    </Row>
  )
}

FormViewRow.propTypes = {
  entry: PropTypes.object.isRequired
}

export default FormViewRow
