import React from 'react'
import { Grid, Tooltip, Typography } from '@material-ui/core'
import Button from '../../../../../components/Button'
import { CloseOutlined, Done } from '@material-ui/icons'
import Card from '../../../../../components/Card/Card'
import { translations } from '../../../../../config'

const DecisionCard = (props) => {

  const {
    classes,
    message,
    onApprove,
    onReject
  } = props

  return(
    <Card className={classes.decisionCard}>
      <Grid container>
        <Grid item xs={10}>
          <Typography className={classes.decisionText}>
            {message}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={2} justify='flex-end'>
            <Tooltip title={translations('Change Request - Approve')}>
              <Button className={[classes.circleButton, classes.approve]} onClick={onApprove}>
                <Done aria-label={translations('Change Request - Approve')} style={{color: 'white'}}/>
              </Button>
            </Tooltip>
            <Tooltip title={translations('Change Request - Reject')}>
              <Button className={[classes.circleButton, classes.reject]} onClick={onReject}>
                <CloseOutlined aria-label={translations('Change Request - Reject')} style={{color: 'white'}}/>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
  </Card>
  )
}
export default DecisionCard