import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import digitalStoreSdk from '../../../../../digitalStoreSdk'

import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

import P from '../../../../../components/P'
import Button from '../../../../../components/Button'
import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../../../config'
import { constants as reportConfigurationConstants } from '../../../../../store/modules/reportConfiguration'
import { downloadFile, b64toBlob } from '../../../../../helpers/zipHelper'
import toastService from '../../../../../services/toastService'

import styles from './style'

const toastError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Downloaded Report Config')
})
const toastSuccess = () => toastService.action({
  type: 'success',
  message: translations('Successfully - Downloaded Report Config')
})

const ExportForm = Form(reportConfigurationConstants.EXPORT_CONFIGURATION_FORM)

const ExportModal = (props) => {
  const {
    classes,
    formViewId,
    dismiss
  } = props

  const [loadingZip, setLoadingZip] = useState(false)

  const isSaved = !!formViewId

  const handleDownload = async () => {
    setLoadingZip(true)
    try {
      await digitalStoreSdk
        .formView
        .downloadZip({ formViewId })
        .then((res) => {
          const { zip, filename } = res
          const blob = b64toBlob({ b64Data: zip })
          downloadFile({ blob, filename })
          toastSuccess()
          dismiss()
        })
    } catch (ex) {
      toastError()
    } finally {
      setLoadingZip(false)
    }
  }

  return (
    <BasicModalOverlay
      title={translations('Export Report Configuration - Modal Heading')}
    >
      {!isSaved && <P value={translations('Export Report Configuration - New Configuration')} />
      }
      {
        isSaved && <ExportForm initialValues={{ formViewId }}>
          <ContentBoxBody>
            <div className={classes.container}>
              <div className={classes.intro}>
                <P value={translations('Export Report Configuration - Intro')} />
                <P value={translations('Export Report Configuration - Intro ID')} />
                <P value={translations('Export Report Configuration - Intro viewJSON')} />
              </div>
              <FormBody
                schema={[
                  {
                    id: 'formViewId',
                    field: 'Input',
                    props: {
                      label: translations('Export Report Configuration - Report configuration ID'),
                      name: 'formViewId',
                      disabled: true
                    }
                  }
                ]}
                editing
                fullWidthFields
              />
              <Grid container spacing={2} justify='flex-end'>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleDownload}
                  >
                    {loadingZip && <CircularProgress size={23} />}
                    {translations('Download Configuration .zip')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color='secondary'
                    onClick={dismiss}
                    variant='outlined'
                  >
                    {translations('Close')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </ContentBoxBody>
        </ExportForm>
      }
    </BasicModalOverlay>
  )
}

ExportModal.propTypes = {
  classes: PropTypes.any,
  actions: PropTypes.array.isRequired,
  initialValues: PropTypes.shape({
    formViewId: PropTypes.string.isRequired
  })
}

export default withStyles(styles)(ExportModal)
