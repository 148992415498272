import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton'

import DataTableRow from './DataTableRow'
import DataTableCell from './DataTableCell'

const DataTableSkeleton = (props) => {
  const {
    colSpan,
    count
  } = props

  return (
    <Fragment>
      {_.times(count, (index) => {
        return (
          <DataTableRow key={index}>
            <DataTableCell colSpan={colSpan} align={'center'}>
              <Skeleton />
            </DataTableCell>
          </DataTableRow>
        )
      })}
    </Fragment>
  )
}

DataTableSkeleton.defaultProps = {
  colSpan: 1,
  count: 5
}

DataTableSkeleton.propTypes = {
  colSpan: PropTypes.number.isRequired,
  count: PropTypes.number
}

export default DataTableSkeleton
