import _ from 'lodash'

const getRulesEngineCombinator = (combinator) => combinator === 'and' ? 'all' : 'any'
const rulesEngineBuilder = ({ id, field, value, combinator, operator, rules = [], not }) => {
  const stagedCombinator = getRulesEngineCombinator(combinator)
  const splitField = _.split(field, '.')
  const nestedRule = {
    fact: field,
    operator,
    value
  }
  const blockRegex = /block\-/
  if (splitField.length > 1 && !blockRegex.test(splitField[0])) {
    nestedRule.fact = splitField[0]
    nestedRule.path = `$[*].${splitField[1]}`
  } else if (blockRegex.test(splitField[0])) {
    nestedRule.fact = splitField[1]
  }
  if (rules.length && combinator) {
    nestedRule[stagedCombinator] = _.map(rules, rulesEngineBuilder)
  }
  return _.omitBy(nestedRule, _.isNil)
}

/**
   * Format query builder output to rules engine payload
   * @param {object} conditions - Query builder output
   * @returns Conditions in rules engine format for internal use
   */
export const formatRulesEngine = ({ combinator, rules = [], id, not } = {}) => {
  const base = {}
  const resolvedCombinator = getRulesEngineCombinator(combinator)
  for (const rule of rules) {
    const currentBaseCombinator = _.get(base, resolvedCombinator, [])
    _.set(
      base,
      resolvedCombinator,
      [
        ...currentBaseCombinator,
        rulesEngineBuilder(rule)
      ]
    )
  }
  return base
}

const generateID = (prefix = 'r') => `${prefix}-${Math.random().toString()}`

const queryBuilder = ({ any, all, fact, path, value, operator } = {}) => {
  if (any || all) {
    const ruleArray = any || all
    return {
      id: generateID('g'),
      combinator: any ? 'or' : 'and',
      not: false,
      rules: _.map(ruleArray, rule => queryBuilder(rule))
    }
  } else {
    let cleanPath = _.replace(path, /\$\[\*\]\./g, '')
    if (cleanPath) {
      return {
        field: `${fact}.${cleanPath}`,
        value,
        operator
      }
    }
    return {
      field: fact,
      value,
      operator
    }
  }
}
/**
 * Format rules engine payload to query builder format
 * @param {object} conditions - Rules engine payload (from database)
 * @returns Conditions in query builder format
 */
export const formatQueryBuilder = (conditions = {}) => {
  const [baseOr, baseAnd] = [_.get(conditions, 'any'), _.get(conditions, 'all')]
  const baseRules = baseOr || baseAnd
  let formattedConditions = {
    not: false,
    id: generateID('g'),
    rules: _.map(baseRules, rule => queryBuilder(rule))
  }
  if (baseOr) {
    formattedConditions.combinator = 'or'
  }
  if (baseAnd) {
    formattedConditions.combinator = 'and'
  }

  return formattedConditions
}
