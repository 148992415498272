import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors } from '.'
/**
 * @typedef {object} Filters
 * @property {string} organisationId
 * @property {'24hr' | '7d' | '30d'} timePeriod
 */
/**
 * Handle HealthCheck from the Vigilance Hub API.
 */
class PlatformHealthcheck {
  /**
   * Get analytical data for an organisation with the filters from redux applied.
   */
  getOrganisationHealthCheckUsingFilters = () => (dispatch, getState) => {
    const state = getState()
    /**
     * @type {Filters}
     */
    const filters = selectors.getFilters(state)
    
    return dispatch({
      type: constants.GET_ORGANISATION_HEALTH_CHECK,
      promise: () => digitalStoreSdk.healthCheck.getOrganisationHealthCheck(filters)
    })
  }
  /**
   * @param {Filters['organisationId']} organisationIds
   */
  setOrganisationIdsFilter = (organisationIds) => {
    return this.changeFilters({ organisationIds })
  }
  /**
   * @param {Filters['timePeriod']} timePeriod
   */
  setTimePeriodFilter = (timePeriod = '24hr') => {
    return this.changeFilters({ timePeriod })
  }
  /**
   * @param {Filters['sourceId']} sourceId
   */
  setSourceIdFilter = (sourceId = 'AllSources') => {
    const filter = sourceId === 'AllSources'? null: sourceId
    return this.changeFilters({ sourceId: filter })
  }
  /**
   * @private
   * @param {Filters} filters
   * Modify the filters within the Store.
   */
  changeFilters = (filters) => {
    return {
      type: constants.CHANGE_FILTERS,
      filters
    }
  }
}

export default new PlatformHealthcheck()
