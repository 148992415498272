import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import { translations } from '../../../config'
import { getHasSabreRegistrationEnabled, getUserManagementConfig } from '../platforms/selectors'

export const getIsInitial = state => !state.roles.status
export const getError = state => state.roles.error
export const getHasError = state => !!state.roles.error
export const getAllRoles = state => (state.roles || {}).roles
export const getIsLoading = state => ((state.roles || {}).status === PENDING)

export const convertRoleToOptions = (roles) => {
  return _.chain(roles)
    .map(role => ({ value: role.id, label: translations(`Role - ${role.name}`) }))
    .value()
}

export const getAllRolesOptions = createSelector([
  getAllRoles
], (roles) => {
  return convertRoleToOptions(roles)
})

export const getRolesForPlatform = createSelector([
  getAllRoles,
  getUserManagementConfig
], (roles, userManagementConfig) => {
  // Org lead and platform manager
  const allowedRoles = _.get(userManagementConfig, 'allowedRoles')
  if(_.isEmpty(allowedRoles)) {
    return roles
  }
  const filteredRoles = _.filter(roles, role => _.includes(allowedRoles, role.value))
  return filteredRoles
})

export const getRoleOptionsForPlatform = createSelector([
  getRolesForPlatform
], (roles) => convertRoleToOptions(roles))

export const getRoleById = id => createSelector([
  getAllRoles
], roles => {
  if (roles) {
    return roles.find(role => id === role.id)
  }
})
