import React, { Component, Fragment } from 'react'
import Hidden from '@material-ui/core/Hidden'
import InfiniteScroll from 'react-infinite-scroller'
import { AutoTable } from '../../../../components/Table'
import KeyValueCard from '../../../../components/KeyValueCard'
import NumberOfResultsAnnouncer from '../../../../components/NumberOfResultsAnnouncer'
import { translations } from '../../../../config'

class UsersSearchTable extends Component {
  renderError = () => {
    const { classes } = this.props
    return <div className={classes.content}>Sorry, an error has occurred. Please try again.</div>
  }


  render () {
    const { error, noResults, hasSearched, searchUsers, hasMore, results, classes, rows } = this.props
    if (error) {
      return this.renderError()
    } else {
      return (
        <Fragment>
          <NumberOfResultsAnnouncer count={rows.length} />
          <div className={classes.gridContainer}>
            <InfiniteScroll
              pageStart={0}
              className={classes.container}
              loadMore={searchUsers}
              hasMore={hasMore}
              initialLoad={false}
              useWindow
            >
              {
                results &&
                <Fragment>
                  <Hidden xsDown>
                    <AutoTable rows={rows} />
                  </Hidden>
                  <Hidden smUp>
                    {rows.map(row => {
                      return <div className={classes.cardContainer} key={row.key}>
                        <KeyValueCard {...row} />
                      </div>
                    })}
                  </Hidden>
                </Fragment>
              }
              {noResults && hasSearched &&
                <div className={`${classes.content} ${classes.uppercase}`} role='status'>{translations('No results found')}</div>
              }
            </InfiniteScroll>
          </div>
        </Fragment>
      )
    }
  }
}

export default UsersSearchTable
