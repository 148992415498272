import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange } from 'recompose'

import ViewUserScreen from './ViewUserScreen'
import { selectors as userDetailsSelectors, actions as userDetailsActions } from '../../../store/modules/userDetails'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { authCombinedSelectors, userDetailCombinedSelector } from '../../../store/modules/combinedSelectors'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

import { push as routerPush } from 'connected-react-router'

import modalService from '../../../services/modalService'

import { translations } from '../../../config'


const mapStateToProps = state => {
  const isLoading = userDetailCombinedSelector.getUserDetailsOrRolesIsLoading(state)
  const initialValues = userDetailsSelectors.getUserWithModifiedRoleAndOrganisations(state)

  const selectedUser = userDetailsSelectors.getSelectedUser(state)
  const selectedUserFail = userDetailsSelectors.getFailedFetchCurrentUserDetailsStatus(state)

  const currentUser = authSelectors.getCurrentUser(state)
  const isCurrentUserSelectedUser = selectedUser && currentUser && selectedUser.id === currentUser.id
  const organisationDetails = authCombinedSelectors.getDetailsForSelectedOrganisation(state)

  const isPersonalDetailsEditable = authCombinedSelectors.isCurrentUserAbleToEditPersonalDetails(state)
  const isRolesAndOrganisationsEditable = authCombinedSelectors.isCurrentUserAbleToEditRoleAndOrganisations(state)
  const isProfessionEditable = authCombinedSelectors.isCurrentUserAbleToEditProfession(state)
  const isPasswordEditable = authCombinedSelectors.isCurrentUserAbleToEditPassword(state)
  const isPublic = authCombinedSelectors.isCurrentPublicUsersProfile(state)
  const canUserModifyPermissions = authCombinedSelectors.canUserModifyPermissions(state)

  const isCurrentUserAbleToDeactivateUser = authCombinedSelectors.isCurrentUserAbleToDeactivateUser(state)
  const canManageUsers = authSelectors.getHasUserManagement(state)
  const isCurrentUserOrgLead = authSelectors.getRoleValue(state) === 4
  const isSelectedUserOrgLead = userDetailsSelectors.getSelectedUserValue(state) === 4
  const isCurrentUserAndSelectedUserOrgLead = isCurrentUserOrgLead && isSelectedUserOrgLead
  const isSelectedUserDeactivated = userDetailsSelectors.getSelectedUserIsDeactivated(state)
  const isMarkedForDeletion = userDetailsSelectors.getMarkForDeletion(state)

  const isSelectedUserLocked = userDetailsSelectors.getSelectedUserIsLocked(state)
  const pendingChangeRequests = userDetailsSelectors.getSelectedUserPendingChangeRequests(state)

  const isMoreRegistrationEnabled = platformSelectors.getHasMoreRegistrationEnabled(state)
  const isSabreRegistrationEnabled = platformSelectors.getHasSabreRegistrationEnabled(state)
  const hasReportAuditEnabled = platformSelectors.getHasReportAuditingEnabled(state)

  const isPermissionsEditorEnabled = platformSelectors.getHasPermissionsEditorEnabled(state)
  const canViewPermissions = authCombinedSelectors.canViewPermissions(state)
  const canEditPermissions = authCombinedSelectors.canEditPermissions(state)


  const {
    hideProfessions = false,
    hidePermissions = false,
    hideReportSummary = false,
    hideOrganisations = false,
    hideOrganisationPreferences = false,
    hideCommunicationPreferences = false
  } = platformSelectors.getUserManagementConfig(state)

  return {
    isLoading,
    selectedUserFail,
    initialValues,
    selectedUser,
    isCurrentUserSelectedUser,
    isPersonalDetailsEditable,
    isRolesAndOrganisationsEditable,
    isProfessionEditable,
    isPasswordEditable,
    isCurrentUserAbleToDeactivateUser,
    canManageUsers,
    isSelectedUserDeactivated,
    isMarkedForDeletion,
    isSelectedUserLocked,
    isPublic,
    canUserModifyPermissions,
    isCurrentUserAndSelectedUserOrgLead,
    getPageTitle: platformSelectors.getPageTitle(state),
    currentUser,
    organisationDetails,
    pendingChangeRequests,
    isMoreRegistrationEnabled,
    isSabreRegistrationEnabled,
    hasReportAuditEnabled,
    hideProfessions,
    hidePermissions,
    hideReportSummary,
    hideOrganisations,
    hideOrganisationPreferences,
    hideCommunicationPreferences,
    isPermissionsEditorEnabled,
    canViewPermissions,
    canEditPermissions
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, selectedUser, isCurrentUserSelectedUser, initialValues }) => (params) => {
      if (params.password) {
        const newParams = _.omit(params, 'confirmPassword')
        newParams.id = isCurrentUserSelectedUser
          ? '@me'
          : selectedUser.id
        // different actions to make middleware easier
        // different modals show depending on whether it is the current users password which is being changed
        if (isCurrentUserSelectedUser) {
          return dispatch(userDetailsActions.changeCurrentUserPassword(newParams))
        } else {
          return dispatch(userDetailsActions.changeUserPassword(newParams))
        }
      } else {
        if (params.organisationIds) {
          params.organisationIds = _.flatten([params.organisationIds])
        }

        // User has saved on the policy section.
        if (params.isPolicyForm === true) {
          const userId = params.id
          const policy = _.chain(params)
            .omit(['id', 'isPolicyForm'])
            .omitBy(value => value === 'DEFAULT' || _.isUndefined(value) || (_.isArray(value) && _.isEmpty(value)))
            .map((value, key) => {
              const [resource, action] = _.split(key, '-')
              if (action === 'filterReports') {
                return {
                  resource,
                  action,
                  effect: 'ALLOW',
                  conditions: { category: value }
                }
              }

              return {
                resource,
                action,
                effect: value
              }
            })
            .value()
          return dispatch(userDetailsActions.updateUser({ id: userId, policy }))
        }
        // If policies are untouched remove them
        // As it's a no-op and not worth sending.
        _.unset(params, 'policies')

        if (params.isOrgPreferences) {
          const userId = _.get(selectedUser, 'id')
          const initialPreferences = _.get(initialValues, 'preferences')
          const currentPreferences = params.preferences
          const difference = _.difference(currentPreferences, initialPreferences)
          if (_.isEmpty(difference)) { return }
          const preferences = _.map(difference, p => (
            {
              id: p.id,
              userId,
              organisationId: p.organisationId,
              disallowEmailComms: p.disallowEmailComms
            }
          ))
          return dispatch(userDetailsActions.updateUserOrganisationPreferences({ userId, preferences }))
        }

        let newDetails = _.get(params, 'details')
        const occupation = params.occupation
        const isWeeklyTransmissionsReportsEnabled = params.isWeeklyTransmissionsReportsEnabled
        newDetails = {
          ...newDetails,
          occupation,
          isWeeklyTransmissionsReportsEnabled
        }
        params.details = newDetails

        return dispatch(userDetailsActions.updateUser(params))
      }
    },
    showDeactivateModal: ({ dispatch, isSelectedUserDeactivated, selectedUser }) => () => {
      const toggleDeactivateAccount = () => dispatch(userDetailsActions.userAccountStatusToggle(selectedUser.id))
      const title = isSelectedUserDeactivated
        ? translations('Confirm Re-Activation')
        : translations('Confirm De-Activation')
      const text = isSelectedUserDeactivated
        ? translations('Re-Activation Copy')
        : translations('De-Activation Copy')

      modalService.action({
        title,
        text,
        actions: [
          {
            success: true,
            text: translations('Confirm'),
            onClick: () => toggleDeactivateAccount(),
            primary: true
          },
          {
            text: translations('Cancel')
          }
        ]
      })
    },
    openModal: () => component => {
      modalService.open({
        component
      })
    },
  }),
  withPropsOnChange(
    ['selectedUserFail'],
    ({ dispatch, selectedUserFail }) => {
      if (selectedUserFail) {
        dispatch(routerPush('/users'))
      }
    }
  )
)(ViewUserScreen)
