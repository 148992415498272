import { asyncActions, selectors } from '../../../../../store/modules/terminology'
import store from '../../../../../store'
export const config = ({ entryId }) => ({
    formName: 'deleteEntry',
    title: 'Terminology - Delete Entry',
    schema: [],
    onSubmit(_response, dispatch) {
        return dispatch(asyncActions.deleteTerminologyEntry({ terminologyEntryId: entryId }))
    }
})
