import * as validators from '../../../validators'

export default {
  header: {
    title: 'Message Acknowledgement',
    initialOpen: true,
    schema: [
      {
        id: 'messagenumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'messagenumb',
          label: `Acknowledgement message number`,
          required: true
        }
      },
      {
        id: 'messagesenderidentifier',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'messagesenderidentifier',
          label: `Message Sender ID`,
          required: false
        }
      },
      {
        id: 'messagereceiveridentifier',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'messagereceiveridentifier',
          label: `Message Receiver ID`,
          required: false
        }
      },
      {
        id: 'filename',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'filename',
          label: `File Name`,
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'filereceiveddate',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'filereceiveddate',
          label: 'File Received Date',
          required: false
        }
      },
      {
        id: 'ackgenerationdate',
        field: 'Input',
        props: {
          shrink: true,
          name: 'ackgenerationdate',
          label: 'Acknowledgement Generation Date',
          required: true
        }
      },
      {
        id: 'transmissionacknowledgmentcode',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'transmissionacknowledgmentcode',
          label: 'Transmission Acknowledgement Code',
          options: [
            { value: '01', label: 'Successful' },
            { value: '02', label: 'Unsuccessful' },
            { value: '03', label: 'Unsuccessful' }
          ],
          required: true
        }
      }
    ]
  },
  safetyReportAck: {
    title: 'Safety Report Acknowledgement',
    initialOpen: true,
    schema: [
      {
        id: 'reportacknowledgmentcode',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'reportacknowledgmentcode',
          label: 'Report Acknowledgement Code',
          options: [
            { value: '01', label: 'Successful' },
            { value: '02', label: 'Unsuccessful' }
          ],
          required: true
        }
      },
      {
        id: 'message',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'message',
          label: `Message`,
          required: false
        }
      },
      {
        id: 'localreportnumber',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'localreportnumber',
          label: `Local Report Number`,
          required: false
        }
      }
    ]
  }
}
