import React from 'react'
import _ from 'lodash'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import { styled } from '@material-ui/core/styles'

import { translations } from '../../../../config'
import * as hooks from './hooks'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from '../../../../store/modules/terminology'


const TabBar = styled(AppBar)(({ theme }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.grey[200]}`
}))

const TerminologyTabs = (props) => {
  const dispatch = useDispatch()
  const terminologyArrangements = useSelector(selectors.getTerminologyArrangements)
  const activeArrangementId = useSelector(selectors.getActiveArrangementId)
  hooks.useGetTerminologyArrangement({ dispatch })
  const setActiveTerminologyArrangement =  hooks.useSetActiveTerminologyArrangement({ dispatch })
  if (terminologyArrangements.isLoading) {
    return null
  }

  if (terminologyArrangements.isEmpty) {
    return null
  }

  if (terminologyArrangements.isError) {
    return null
  }

  return (
    <>
      <TabContext value={activeArrangementId}>
        <TabBar position='static' color='transparent' variant='outlined'>
          <TabList
            variant={'scrollable'}
            scrollButtons={'auto'}
            onChange={setActiveTerminologyArrangement}
            aria-label={translations('Terminology tabs')}
            indicatorColor={'primary'}
          >
            {_.map(terminologyArrangements.entity, ({ id, name }) => {
              return (
                <Tab label={name} id={id} style={{ textTransform: 'none' }} value={id} />
              )
            })}
          </TabList>
        </TabBar>
      </TabContext >
</>
  )
}

export default TerminologyTabs
