import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class NewsReducers {
  fetchNews = (state, action) => {
    const { results: currentResults } = state
    const results = _.get(action, 'result.results') || []
    const total = _.get(action, 'result.total') || 0
    const language = _.get(action, 'result.language')
    switch (action.status) {
      case PENDING:
        return this.fetchNewsPending(state, action)
      case SUCCESS:
        return this.fetchNewsSuccess(state, action, results, currentResults, total, language)
      case FAIL:
        return this.fetchNewsFail(state, action)
      default:
        return state
    }
  }
  fetchNewsPending = (state, action) => {
    return {
      ...state,
      page: 1,
      status: PENDING,
      action: action.type
    }
  }
  fetchNewsSuccess = (state, action, results, currentResults, total, language) => {
    return {
      ...state,
      total,
      page: 1,
      language,
      results,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchNewsFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      page: 1,
      action: undefined,
      error: action.error.message
    }
  }
  fetchNewsNext = (state, action) => {
    const { page, results: currentResults } = state
    const results = _.get(action, 'result.results') || []
    const total = _.get(action, 'result.total') || 0
    const language = _.get(action, 'result.language')
    switch (action.status) {
      case PENDING:
        return this.fetchNewsNextPending(state, action, results, currentResults, total, language, page)
      case SUCCESS:
        return this.fetchNewsNextSuccess(state, action, results, currentResults, total, language, page)
      case FAIL:
        return this.fetchNewsNextFail(state, action, results, currentResults, total, language, page)
      default:
        return state
    }
  }
  fetchNewsNextPending = (state, action, results, currentResults, total, language, page) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchNewsNextSuccess = (state, action, results, currentResults, total, language, page) => {
    return {
      ...state,
      total,
      page: page + 1,
      language,
      results: [...currentResults, ...results],
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchNewsNextFail = (state, action, results, currentResults, total, language, page) => {
    return {
      ...state,
      status: FAIL,
      action: undefined
    }
  }
  deleteArticle = (state, action) => {
    if (action.status !== SUCCESS) {
      return state
    }

    return {
      ...state,
      results: state.results.filter(article => article.id !== action.payload.id)
    }
  }
  changeFilter = (state, action) => {
    const { filter } = action
    const { filters } = state
    return {
      ...state,
      filters: {
        ...filters,
        [filter.name]: filter.value
      }
    }
  }

  changeFilters = (state, action) => {
    const { filters } = action
    return {
      ...state,
      filters
    }
  }
}

export default new NewsReducers()
