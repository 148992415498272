import { connect } from 'react-redux'
import _ from 'lodash'
import { isSubmitting, isInvalid, submit } from 'redux-form'
import {
  compose,
  withHandlers,
  withPropsOnChange
} from 'recompose'

import {
  actions as communicationTemplateActions,
  selectors as communicationTemplateSelectors,
  constants as communicationTemplateConstants
} from '../../../store/modules/communicationTemplates'

import {
  selectors as authSelectors
} from '../../../store/modules/auth'

import modalService from '../../../services/modalService'
import toastService from '../../../services/toastService'
import { translations } from '../../../config'
import ConfigureModal from './ConfigureModal'

const enhancer = compose(
  connect((state, ownProps) => {
    return {
      isSubmitting: isSubmitting(communicationTemplateConstants.CONFIGURE_COMMUNICATIONS_FORM)(state),
      isInvalid: isInvalid(communicationTemplateConstants.CONFIGURE_COMMUNICATIONS_FORM)(state),
      templateTypes: communicationTemplateSelectors.getTemplatesIDsByType(state),
      templateEntities: communicationTemplateSelectors.getAllEntities(state),
      defaultTemplates: communicationTemplateSelectors.getDefaultTemplatesByType(state),
      organisationId: authSelectors.getUserSelectedOrganisationId(state),
      channel: _.get(ownProps, 'channel')
    }
  }),
  withHandlers({
    closeModal: () => () => {
      modalService.close()
    },
    onSubmit: ({ dispatch }) => () => {
      dispatch(submit(communicationTemplateConstants.CONFIGURE_COMMUNICATIONS_FORM))
    },
    handleSubmit: (props) => async (formData) => {
      try {
        const { dispatch, organisationId, channel} = props
        switch(channel) {
          case 'sms':
            await dispatch(communicationTemplateActions.updateDefaultSmsTemplates({
              organisationId,
              body: _.pickBy(formData, _.identity)
            }))
            break
          case 'push':
            await dispatch(communicationTemplateActions.updateDefaultPushTemplates({
              organisationId,
              body: _.pickBy(formData, _.identity)
            }))
            break
          default:
            await dispatch(communicationTemplateActions.updateDefaultEmailTemplates({
              organisationId,
              body: _.pickBy(formData, _.identity)
            }))
        }

        modalService.close()
        toastService.action({
          type: 'success',
          message: translations('Communications - Configure Success')
        })
      } catch (e) {
        toastService.action({
          type: 'error',
          message: translations('Communications - Configure Failure')
        })
      }
    }
  }),
  withPropsOnChange(['isSubmitting'], (props) => {
    const { onSubmit, closeModal, isSubmitting } = props
    return {
      actions: [
        {
          success: true,
          text: translations('Save'),
          submitting: isSubmitting,
          onClick: onSubmit,
          primary: true
        },
        {
          text: translations('Cancel'),
          onClick: closeModal
        }
      ]
    }
  }),
  withPropsOnChange(['templateTypes', 'templateEntities'], (props) => {
    const { templateTypes, templateEntities } = props
    let schema = []

    _.forEach(templateTypes, (templateIds, type) => {
      const schemaItem = {
        id: type,
        field: 'Dropdown',
        props: {
          label: `${translations(`Communication Type - ${type}`)}`,
          name: type,
          required: true,
          options: _.map(templateIds, (id) => {
            return {
              value: id,
              label: _.get(templateEntities, `${id}.name`)
            }
          }),
          shrink: true
        }
      }
      schema.push(schemaItem)
    })

    return { schema }
  }),
  withPropsOnChange(['defaultTemplates'], (props) => {
    const { defaultTemplates = {} } = props
    return {
      initialValues: { ...defaultTemplates }
    }
  })
)

export default enhancer(ConfigureModal)
