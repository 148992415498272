import { promiseReducer } from '../../util'

class EdqmReducers {
  searchDrugDictionary = promiseReducer(
    (state, action) => {
      const { result, status } = action
      const { rows, count } = result

      return {
        ...state,
        status,
        rows,
        count,
        page: 1
      }
    }
  )

  searchDrugDictionaryNext = promiseReducer(
    (state, action) => {
      const { result, status } = action
      const { rows, count } = result
      const { page: currentPage, rows: existingRows } = state

      return {
        ...state,
        status,
        rows: [...existingRows, ...rows],
        count,
        page: currentPage + 1
      }
    }
  )
}

export default new EdqmReducers()
