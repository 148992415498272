import _ from 'lodash'
import Professions from '../Professions'
import { useSelector } from 'react-redux'
import { getFormValues } from 'redux-form'
import { selectors as rolesSelectors } from '../../../../../../store/modules/roles'
import { useOrganisationName, useOrganisationProfessionsOptions } from '../../../../../../store/modules/organisations/hooks'

const AddUserProfessions = ({ editing }) => {
  const formState = useSelector(getFormValues('user-registration'))
  const allRoles = useSelector(rolesSelectors.getAllRoles)
  const selectedRoleId = _.get(formState, 'roleId')
  const selectedRoleValue = _.chain(allRoles)
    .find(_.matchesProperty('id', selectedRoleId))
    .get('value', 6)
    .value()
  const selectedOrganisationIds = _.get(formState, 'organisationIds') || []
  const selectedOrganisationId = selectedOrganisationIds[0]
  const organisationName = useOrganisationName(selectedOrganisationId)
  const professionOptions = useOrganisationProfessionsOptions(selectedOrganisationId)

  const shouldShowProfessionDropdown = (
    selectedRoleValue >= 4
    && selectedOrganisationIds
    && selectedOrganisationIds.length === 1
    && !_.isEmpty(professionOptions)
  )

  if (!shouldShowProfessionDropdown) {
    return <></>
  }

  return (
    <Professions
      professionOptions={professionOptions}
      editing={editing}
      organisationName={organisationName}
    />
  )
}

export default AddUserProfessions