
const styles = {
    container: {
      display: 'flex'
    },
    dropdown: {
      padding: '0px 15px 0 0'
    },
    inputContainer: {
      width: '100%'
    },
    wrapped: {
      width: '100%',
      display: 'grid',
      paddingTop: '8px'
    }
  }
  
  export default styles