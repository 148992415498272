import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import AlertBadge from './AlertBadge'

import { selectors as notificationSelectors } from '../../store/modules/notifications'

const mapStateToProps = state => ({
  notifications: notificationSelectors.getTotalNotificationsNotViewed(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    getBadgeCount: ({ type, ...badgeTypes }) => () => {
      return badgeTypes[type]
    }
  })
)(AlertBadge)
