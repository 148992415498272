import { colors } from '../../../config/theme'

export default theme => {
  return {
    lockContainer: {
      width: '50px',
      padding: 0
    },
    tableRow: {
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'nowrap',
      minHeight: 50,
      overflow: 'hidden',
      alignItems: 'center',
      fontSize: '13px',
      justifyContent: 'left',
      transition: 'background .3s',
      '&:nth-child(even)': {
        backgroundColor: colors.lightGrey
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: -3,
        width: 3,
        height: '100%',
        opacity: 0,
        transition: 'opacity .3s'
      },
      '&:hover:before': {
        opacity: 1
      },
      '&:hover': {
        backgroundColor: colors.tableHoverGrey
      }
    },
    deactivatedTableRow: {
      color: '#888',
      cursor: 'not-allowed'
    }
  }
}
