const styles = {
  root: {
    padding: 0,
    margin: 0,
    border: 0
  },
  legend: {
    border: '0',
    clip: 'rect(1px, 1px, 1px, 1px)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px'
  }
}

export default theme => styles
