import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'

class WebviewScreen extends Component {
  state = {
    src: this.props.src,
    editable: this.props.editable
  }

  onSearch = src => {
    this.setState({ src, editable: false })
  }

  refresh = () => {
    this.iframe.src += ''
  }

  render = () => {
    const { src, editable } = this.state
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <SubHeader
          leftContent={(
            <BackBar />
          )}
        />
        <iframe
          ref={iframe => { this.iframe = iframe }}
          className={classes.iframe}
          src={src}
        />
      </div>
    )
  }
}

WebviewScreen.propTypes = {
  src: PropTypes.string,
  editable: PropTypes.bool.isRequired
}

export default withStyles(style)(WebviewScreen)
