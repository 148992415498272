export const GET_SUBMISSION_REPORTS = 'digitalStore/submissionReports/GET_SUBMISSION_REPORTS'
export const GET_SUBMISSION_REPORT = 'digitalStore/submissionReports/GET_SUBMISSION_REPORT'
export const SEARCH_SUBMISSION_REPORTS_FRESH = 'digitalStore/submissionReports/SEARCH_SUBMISSION_REPORTS_FRESH'
export const SEARCH_SUBMISSION_REPORTS_NEXT = 'digitalStore/submissionReports/SEARCH_SUBMISSION_REPORTS_NEXT'
export const CHANGE_SUBMISSION_REPORTS_FILTERS = 'digitalStore/submissionReports/CHANGE_SUBMISSION_REPORTS_FILTERS'
export const CHANGE_SUBMISSION_REPORTS_FILTER = 'digitalStore/submissionReports/CHANGE_SUBMISSION_REPORTS_FILTER'
export const TOGGLE_SELECTED_SUBMISSION_REPORTS = 'digitalStore/submissionReports/TOGGLE_SELECTED_SUBMISSION_REPORTS'
export const CLEAR_SELECTED = 'digitalStore/submissionReports/CLEAR_SELECTED'
export const TOGGLE_SELECT_ALL = 'digitalStore/submissionReports/TOGGLE_SELECT_ALL'
export const DISTRIBUTE_SUBMISSIONS = 'digitalStore/submissionReports/DISTRIBUTE_SUBMISSIONS'
export const RESET_STORE = 'digitalStore/submissionReports/RESET_STORE'
export const FETCH_SAVED_SEARCHES = 'digitalStore/submissionReports/FETCH_SAVED_SEARCHES'
export const CREATE_SAVED_SEARCH = 'digitalStore/submissionReports/CREATE_SAVED_SEARCH'
export const UPDATE_SAVED_SEARCH = 'digitalStore/submissionReports/UPDATE_SAVED_SEARCH'
export const DELETE_SAVED_SEARCH = 'digitalStore/submissionReports/DELETE_SAVED_SEARCH'
export const SET_SAVED_SEARCH = 'digitalStore/submissionReports/SET_SAVED_SEARCH'
export const EDITOR_MODAL = 'digitalStore/submissionReports/EDITOR_MODAL'
export const DISCARD_SAVED_FILTERS = 'digitalStore/submissionReports/DISCARD_SAVED_FILTERS'
export const SET_MANUALLY_PROCESSED = 'digitalStore/submissionReports/SET_MANUALLY_PROCESSED'
export const MANUALLY_PROCESSED_MODAL = 'digitalStore/submissionsReports/MANUALLY_PROCESSED_MODAL'
export const SET_RECEIVER_STATUS_TO_FAILED = 'digitalStore/submissionsReports/SET_RECEIVER_STATUS_TO_FAILED'
export const SET_RECEIVER_STATUS_TO_IN_REVIEW = 'digitalStore/submissionsReports/SET_RECEIVER_STATUS_TO_IN_REVIEW'
export const SET_RECEIVER_STATUS_TO_REJECTED = 'digitalStore/submissionsReports/SET_RECEIVER_STATUS_TO_REJECTED'
export const SUBMIT_APPROVAL = 'digitalStore/submissionsReports/SUBMIT_APPROVAL'
export const SUBMIT_REJECTION = 'digitalStore/submissionsReports/SUBMIT_REJECTION'
export const FETCH_REPORT_REVIEWS_BY_ID = 'digitalStore/submissionsReports/FETCH_REPORT_REVIEWS_BY_ID'
export const reviewStatuses = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT'
}


