import _ from 'lodash'

export const mergeCombinedRepeatables = (combinedRepeatables, values) => {
  let valuesClone = _.cloneDeep(values)
  for(const combinedRepeatable of combinedRepeatables) {
    const allRepeatables = _.filter(values, (value, index) => _.includes(index, `${combinedRepeatable}_`))
    const combinedRepeatables = _.flatMap(allRepeatables)

    _.set(valuesClone, combinedRepeatable, combinedRepeatables)
    valuesClone = _.omitBy(valuesClone, (value, key) =>  _.includes(key, `${combinedRepeatable}_`))
  }
  return valuesClone
}