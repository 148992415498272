import React from 'react'
import ContentBox from '../ContentBox'
import ContentBoxBody from '../ContentBox/ContentBoxBody'
import ContentBoxHeader from '../ContentBox/ContentBoxHeader'
import TextContent from '../Form/TextContent'
import RightButtonContent from '../Form/RightButtonContent'
import LeftButtonContent from '../Form/LeftButtonContent'
import Grid from '@material-ui/core/Grid'
import Heading from '../Heading'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const ManageContentBox = props => {
  const { className, classes, leftButtonContent, rightButtonContent, name, content } = props

  return (
    <ContentBox givenContentClass={classes.contentContainer}>
      <ContentBoxHeader>
        <LeftButtonContent>
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.editLeftButtonGridItem} >
              {leftButtonContent || null}
            </Grid>
          </Grid>
        </LeftButtonContent>
        <TextContent>
          <Heading className={classes.formContentBoxHeader}>
            {name}
          </Heading>
        </TextContent>
        <RightButtonContent>
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.editRightButtonGridItem} >
              {rightButtonContent || null}
            </Grid>
          </Grid>
        </RightButtonContent>
      </ContentBoxHeader>
      <ContentBoxBody>{content || null}</ContentBoxBody>
    </ContentBox>
  )
}

export default withStyles(style)(ManageContentBox)
