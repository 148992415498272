import _ from 'lodash'
import React, { useEffect } from 'react'
import { styled, useMediaQuery, useTheme } from "@material-ui/core"
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useLocation } from 'react-router-dom'

import { translations } from '../../../config'
import { actions as userGroupActions } from '../../../store/modules/userGroups'
import { getUserGroups } from '../../../store/modules/userGroups/selectors'
import { getUserSelectedOrganisationId } from '../../../store/modules/auth/selectors'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { DataTable } from '../../../components/DataTable'

import Button from '../../../components/Button'
import modalService from '../../../services/modalService'
import UserGroupRow from './components/UserGroupRow'
import UserGroupModal from './components/UserGroupModal'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import ConfirmationModal from '../../Developer/DeveloperScreen/ApplicationAccessPanel/ConfirmationModal'
import toastService from '../../../services/toastService'
import { PENDING } from '../../../store/middleware/redux-promise'

export const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  maxWidth: 1200,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

const UserGroupsScreen = () => {
  const dispatch = useDispatch()
  const organisationId = useSelector(getUserSelectedOrganisationId)
  const canEditPermissions = useSelector(authCombinedSelectors.canEditPermissions)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const results = useSelector(getUserGroups)
  const {
    data = [],
    status
  } = results
  const isLoading = status === PENDING

  const { state = {} } = useLocation()
  const {
    user
  } = state

  useEffect(() => {
    dispatch(userGroupActions.fetchAllUserGroupsForOrganisation({ organisationId }))
  }, [organisationId])

  const handleCreate = ({
    name,
    description
  }) => {
    dispatch(userGroupActions.createUserGroupForOrganisation({ organisationId, name, description }))
      .then((res) => {
        modalService.close()
        toastService.action({
          type: 'success',
          message: translations('User Groups - Create Success'),
          autoHideDuration: 6000
        })
        if (!_.isEmpty(user) && !_.isEmpty(res)) {
          onOptionClick({ id: res.id })()
          return
        }
        dispatch(userGroupActions.fetchAllUserGroupsForOrganisation({ organisationId }))
      })
      .catch(error => {
        toastService.action({
          type: 'error',
          message: error.message,
          autoHideDuration: 6000
        })
      })
  }

  const handleUpdate = ({
    id,
    name,
    description
  }) => {
    dispatch(userGroupActions.updateUserGroup({ id, organisationId, name, description }))
      .then(() => {
        dispatch(userGroupActions.fetchAllUserGroupsForOrganisation({ organisationId }))
        modalService.close()
        toastService.action({
          type: 'success',
          message: translations('User Groups - Update Success'),
          autoHideDuration: 6000
        })
      })
      .catch(error => {
        toastService.action({
          type: 'error',
          message: error.message,
          autoHideDuration: 6000
        })
      })
  }

  const handleDelete = (id) => {
    modalService.open({
      component: ConfirmationModal,
      title: translations('Confirmation'),
      message: translations('User Groups - Delete User Group'),
      onConfirmation: async () => {
        dispatch(userGroupActions.deleteUserGroup({ id }))
          .then(() => {
            dispatch(userGroupActions.fetchAllUserGroupsForOrganisation({ organisationId }))
            modalService.close()
            toastService.action({
              type: 'success',
              message: translations('User Groups - Delete Success'),
              autoHideDuration: 6000
            })
          }).catch(error => {
            toastService.action({
              type: 'error',
              message: error.message,
              autoHideDuration: 6000
            })
          })
      }
    })
  }

  const onOptionClick = ({ id }) => (e) => {
    dispatch(push(`/permissions/user-group/${id}`, state))
  }

  const openUserGroupModal = (props) => {
    modalService.open({
      component: UserGroupModal,
      organisationId,
      handleCreate,
      handleUpdate,
      ...props
    })
  }

  return (
    <>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={<Heading>{translations(`App Name - User Groups`)}</Heading>}
        rightContent={canEditPermissions &&
          <Button
            onClick={openUserGroupModal}
            raised
            color='primary'
            style={{ marginRight: 20 }}
          >
            {isSmallScreen ? '+' : translations('User Groups - Create User Group')}
          </Button>
        }
      />
      <Content>
        <DataTable
          headers={[
            { label: 'Name', id: 'name', options: { align: 'left' } },
            { label: 'Description', id: 'senderId', options: { align: 'left' } },
            ...(canEditPermissions ? [{ label: 'Actions', id: 'delete', options: { align: 'right' } }] : [])
          ]}
          data={data}
          tableId={'user-group-management-table'}
          tableHeading={translations('User Groups - Table Header')}
          rowComponent={UserGroupRow}
          rowProps={{ onOptionClick, openUserGroupModal, handleDelete, canEdit: canEditPermissions }}
          isLoading={isLoading}
        />
      </Content>
    </>
  )
}

export default UserGroupsScreen
