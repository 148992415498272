"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var _countries = require("../../../../config/countries");

var _computedValue = require("../../../../formHelpers/computedValue");

var _utils = require("./utils");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var getFormSchema = function getFormSchema(_ref) {
  var _ref$isAboutYou = _ref.isAboutYou,
      isAboutYou = _ref$isAboutYou === void 0 ? false : _ref$isAboutYou,
      _ref$isUpdate = _ref.isUpdate,
      isUpdate = _ref$isUpdate === void 0 ? false : _ref$isUpdate;
  return function (validators) {
    return [{
      id: 'occupation',
      field: 'Dropdown',
      props: {
        shrink: true,
        name: 'occupation',
        label: 'Are you a member of the public or a healthcare professional?',
        required: true,
        options: [{
          label: 'Member of the public',
          value: 'public'
        }, {
          label: 'Healthcare professional',
          value: 'hcp'
        }]
      }
    }, {
      id: 'professionId',
      field: 'Dropdown',
      computedHidden: function computedHidden(values) {
        return !values.occupation;
      },
      computedOptions: function computedOptions(formValues, parentFormValues, organisationDetails, formViewDetails, professions) {
        var occupation = formValues.occupation;
        var type = occupation === 'public' ? 'Public' : 'Health Care Professional';

        var options = _.filter(professions, function (profession) {
          return profession.type === type;
        });

        return _.map(options, function (option) {
          return {
            label: option.name,
            value: option.id
          };
        });
      },
      props: {
        shrink: true,
        name: 'professionId',
        label: 'What best describes you?',
        required: true
      }
    }, {
      id: 'title',
      field: 'Dropdown',
      props: {
        shrink: true,
        name: 'title',
        label: 'Title',
        required: true,
        options: [{
          label: 'Mr',
          value: 'Mr'
        }, {
          label: 'Mrs',
          value: 'Mrs'
        }, {
          label: 'Miss',
          value: 'Miss'
        }, {
          label: 'Ms',
          value: 'Ms'
        }, {
          label: 'Dr',
          value: 'Dr'
        }, {
          label: 'Sir',
          value: 'Sir'
        }, {
          label: 'Prof',
          value: 'Prof'
        }, {
          label: 'Mx',
          value: 'Mx'
        }, {
          label: 'Prefer not to say',
          value: 'Prefer not to say'
        }]
      }
    }, {
      id: 'firstName',
      field: 'Input',
      props: {
        shrink: true,
        name: 'firstName',
        label: 'First Name',
        required: true
      }
    }, {
      id: 'lastName',
      field: 'Input',
      props: {
        shrink: true,
        name: 'lastName',
        label: 'Last Name',
        required: true
      }
    }, {
      id: 'email',
      field: 'Input',
      props: {
        shrink: true,
        name: 'email',
        label: 'Email Address',
        required: true,
        validate: [validators.isValidEmail]
      }
    }, {
      id: 'communicationPreferences',
      field: 'Checklist',
      props: {
        shrink: true,
        name: 'communicationPreferences',
        label: 'Communication Preferences',
        checkboxInterfaceActive: true,
        checkboxInterfaceInactive: false,
        options: [{
          label: 'SMS',
          value: 'sms'
        }, {
          label: 'Email',
          value: 'email'
        }],
        singleRow: true
      }
    }, {
      id: 'privacyPolicy',
      field: 'TermsCheckbox',
      props: {
        shrink: true,
        name: 'privacyPolicy',
        label: 'I accept the Privacy Policy',
        required: true
      }
    }].concat(_toConsumableArray(getAddressSchema(validators)), _toConsumableArray(getPasswordSchema(validators, isAboutYou, isUpdate)));
  };
};

exports.getFormSchema = getFormSchema;

var getPasswordSchema = function getPasswordSchema(validators, isAboutYou, isUpdate) {
  var isPasswordHidden = function isPasswordHidden(values) {
    var isHidden = isAboutYou && !values.enableRegister || isUpdate;
    return isHidden;
  };

  return [{
    id: 'enableRegister',
    field: 'Checkbox',
    props: {
      shrink: true,
      name: 'enableRegister',
      label: 'Would you like to register?',
      required: false
    },
    computedHidden: function computedHidden() {
      return !isAboutYou;
    }
  }, {
    id: 'password',
    field: 'Input',
    props: {
      shrink: true,
      name: 'password',
      label: 'Password',
      type: 'password',
      required: true,
      validate: [_utils.validatePassword]
    },
    computedHidden: function computedHidden(values) {
      return isPasswordHidden(values);
    },
    computedValue: function computedValue(values) {
      return isPasswordHidden(values) ? '' : values.password;
    }
  }, {
    id: 'confirmPassword',
    field: 'Input',
    props: {
      shrink: true,
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: 'password',
      required: true,
      validate: [_utils.matchingPassword]
    },
    computedHidden: function computedHidden(values) {
      return isPasswordHidden(values);
    },
    computedValue: function computedValue(values) {
      return isPasswordHidden(values) ? '' : values.confirmPassword;
    }
  }, {
    id: 'passwordInfo',
    field: 'InfoList',
    props: {
      shrink: true,
      name: 'passwordInfo',
      label: 'Your password must:',
      helpText: 'Have at least one capital letter,Have at least one lower case letter,Have at least one numeric character,Be at least 9 characters or more long'
    },
    computedHidden: function computedHidden(values) {
      return isPasswordHidden(values);
    }
  }, {
    id: 'stayLoggedIn',
    field: 'Checkbox',
    props: {
      shrink: true,
      name: 'stayLoggedIn',
      label: 'Keep me signed in',
      required: false
    },
    computedHidden: function computedHidden(values) {
      return isPasswordHidden(values);
    }
  }];
};

var getAddressSchema = function getAddressSchema(validators) {
  return [{
    id: 'houseNumberOrName',
    field: 'Input',
    props: {
      shrink: true,
      name: 'houseNumberOrName',
      label: 'House Name/Number',
      required: false
    },
    computedHidden: function computedHidden(values) {
      return (values === null || values === void 0 ? void 0 : values.occupation) !== 'public';
    }
  }, {
    id: 'hospitalOrPracticeOrOrganisation',
    field: 'Input',
    props: {
      shrink: true,
      name: 'hospitalOrPracticeOrOrganisation',
      label: 'Hospital / Practice Name / Organisation',
      required: false
    },
    computedHidden: function computedHidden(values) {
      return (values === null || values === void 0 ? void 0 : values.occupation) !== 'hcp';
    }
  }, {
    id: 'address',
    field: 'Input',
    props: {
      shrink: true,
      name: 'address',
      label: 'Address line 1',
      required: false
    }
  }, {
    id: 'addressLineTwo',
    field: 'Input',
    props: {
      shrink: true,
      name: 'addressLineTwo',
      label: 'Address line 2',
      required: false
    }
  }, {
    id: 'city',
    field: 'Input',
    props: {
      shrink: true,
      name: 'city',
      label: 'City',
      required: false
    }
  }, {
    id: 'country',
    field: 'Input',
    props: {
      shrink: true,
      name: 'country',
      label: 'Country',
      required: false
    }
  }, {
    id: 'postalCode',
    field: 'Input',
    props: {
      shrink: true,
      name: 'postalCode',
      label: 'Postal Code',
      required: false,
      validate: [validators.maxLength(15, 'Field')]
    }
  }, {
    id: 'telephoneCountryCode',
    field: 'Dropdown',
    props: {
      label: 'Telephone Country Code',
      name: 'telephoneCountryCode',
      validate: [validators.countryIsoCode, validators.requiredWith({
        fieldWith: 'telephone'
      })],
      options: _countries.countriesDirectory,
      required: false,
      shrink: true
    }
  }, {
    id: 'telephone',
    field: 'Input',
    props: {
      label: 'Telephone',
      name: 'telephone',
      validate: [validators.simplePhoneNumber, validators.requiredWith({
        fieldWith: 'telephoneCountryCode',
        withName: 'Telephone Country Code',
        fieldName: 'telephone'
      })],
      required: false,
      shrink: true
    }
  }];
};