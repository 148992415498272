import React from 'react'
import LookupInput from '../LookupInput'
import MedDRALookupInputModal from '../../MedDRALookupInputModal'
import { Input } from '../../../components/Fields'

export default ({ isUsingMedDRATerms, isMandatoryMedDRA, restrictFreeformEntry, ...props }) => {
  const lookUpOnly = isMandatoryMedDRA || restrictFreeformEntry
  if (isUsingMedDRATerms) {
    return <LookupInput component={MedDRALookupInputModal} {...props} lookUpOnly={lookUpOnly} clearable={lookUpOnly} />
  } else {
    return <Input {...props} />
  }
}
