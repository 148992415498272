
const styles = theme => {
  const { headerHeight, subheaderHeight, bottomBarHeight } = theme
  return {
    container: {
      position: 'relative'
    }
  }
}

export default styles
