import { colors } from '../../../config/theme'

export default theme => {
  return {
    contentBox: {
      padding: 0
    },
    content: {
      width: '100%'
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0
    },
    listItem: {
      borderBottom: `1px solid ${colors.mediumGrey}`
    },
    actions: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      background: colors.lightGrey,
      padding: 20
    },
    emptyMessage: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      color: colors.disabledGrey,
      padding: 20
    }
  }
}
