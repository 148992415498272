import { push, LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'

import modalService from '../../../services/modalService'
import { translations } from '../../../config'

import * as pageConstants from './constants'
import pageActions from './actions'
import * as pageSelectors from './selectors'

import * as authSelectors from '../auth/selectors'

class PagesMiddleware {
  loadPagesMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const pathname = action.payload.location.pathname
      const pageManagementPaths = ['/organisations/:id', '/organisations/:id/page-management']
      const editPagePaths = ['/organisations/:id/page-management/:pageId', '/my-organisation/page-management/:id']
      const isRoutingToMyOrgPageManagement = matchPath(pathname, { path: '/my-organisation', exact: true })

      const resultPageManagement = pageManagementPaths.reduce((memo, path) => (memo || matchPath(pathname, { path, exact: true })), false)
      const resultEditPages = editPagePaths.reduce((memo, path) => (memo || matchPath(pathname, { path, exact: true })), false)

      const isRoutingToPageManagement = resultPageManagement
      const isRoutingToEditPage = resultEditPages

      if (isRoutingToPageManagement) {
        const { id } = resultPageManagement.params
        dispatch(pageActions.getPagesForOrganisation({ organisationId: id }))
      }
      if (isRoutingToEditPage) {
        const { pageId } = resultEditPages.params
        dispatch(pageActions.setActivePageId(pageId))
      }
      if (isRoutingToMyOrgPageManagement) {
        const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
        dispatch(pageActions.getPagesForOrganisation({ organisationId }))
      }
    }
  }
}

export default new PagesMiddleware()
