import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '@material-ui/core'
import InputLabel from '../../InputLabel'
import Checkbox from '../Checkbox'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const SwitchInput = ({
  classes,
  checked,
  label,
  shrink,
  otherValue,
  setOtherValue,
  resetOther,
  setChecked,
  children
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.wrapped}>
       {children}
      </div>
      <div className={classes.inputContainer}>
        <Checkbox
          label={'Other'}
          onClick={() => {
            setChecked(!checked)
            resetOther()
          }}
          value={checked}
        />
        <InputLabel htmlFor={label} key='label' shrink={shrink}>
          {label}
        </InputLabel>
        <Input
          disabled={!checked}
          onChange={e => setOtherValue(e.target.value, false)}
          value={!checked ? '' : otherValue}
        />
      </div>
    </div>
  )
}
SwitchInput.propTypes = {
  classes: PropTypes.shape({}),
  setOtherValue: PropTypes.func,
  props: PropTypes.object,
  checked: PropTypes.bool,
  label: PropTypes.string,
  shrink: PropTypes.bool,
  options: PropTypes.array,
  otherValue: PropTypes.string,
  setOtherValue: PropTypes.func,
  resetOther: PropTypes.func,
  setChecked: PropTypes.func,
  newprops: PropTypes.object
}

export default withStyles(style)(SwitchInput)
