import _ from 'lodash'
import { createSelector } from 'reselect'

import { PENDING, SUCCESS } from '../../middleware/redux-promise'
import * as constants from './constants'

export const getIsLoading = state => state.reports.status === PENDING
export const getResults = state => state.reports.results
export const getHasMore = state => state.reports.total != null &&
state.reports.results.length < state.reports.total
export const getReportsHasBeenSearched = state => {
  return (
    state.reports.query ||
    !_.isEmpty(state.reports.storeIds) ||
    state.reports.status === SUCCESS
  )
}
export const getError = state => state.reports.error
export const getIsInitial = state => !state.reports.status
export const getQuery = state => _.get(state, 'reports.filters.query')
export const getSize = state => state.reports.size
export const getPage = state => state.reports.page
export const getStoreIds = state => state.reports.storeIds
export const getStatus = state => state.reports.status

export const getFilters = state => _.get(state.reports, 'filters', {})
export const getNumberOfActiveFilters = createSelector(
  getFilters,
  (filters) => {
    return _.reduce(filters, (total, value) => {
      return value ? total + 1 : total
    }, 0)
  }
)

export const getIsfetchingReportsExport = state => {
  return state.reports.status === PENDING && state.reports.action === constants.EXPORT_FILTERED_REPORTS
}