import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class RolesActions {
  fetchAllRoles = () => ({
    type: constants.FETCH_ALL_ROLES,
    promise: () => {
      return digitalStoreSdk.roles
        .fetchRoles()
    }
  })
}

export default new RolesActions()
