import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { DataTableRow, DataTableCell } from '../../../../components/DataTable'
import styles from './style'
import ActionTypeCell from './ActionTypeCell'
import ConfigureButton from './ConfigureButton'
import { formatDate } from '../utils'
import { DeleteEntryButton } from '../DeleteEntryButton/DeleteEntryButton'
import { useSelector } from 'react-redux'
import { selectors } from '../../../../store/modules/terminology'

const DictionaryEntryRow = (props) => {
  const {
    classes,
    entry
  } = props
  const showDictionaryColumn = useSelector(selectors.doesArrangementHaveSecondaryDictionaries)
  const showDefinitionColumn = useSelector(selectors.doesArrangementUseDefinitions)
  const isUsageTypeAction = useSelector(selectors.isUsageTypeAction)
  return (
    <DataTableRow className={classes.rowClass} >
      <DataTableCell>{entry.term}</DataTableCell>
      {showDefinitionColumn ? <DataTableCell>{entry.definition}</DataTableCell> : null}
      {showDictionaryColumn ? <DataTableCell>{!entry.isPrimary ? entry.dictionaryName : null}</DataTableCell> : null}
      {isUsageTypeAction && <DataTableCell>
        <ActionTypeCell entry={entry}/>
      </DataTableCell>}
      <DataTableCell compact nowrap>{formatDate(entry.createdAt)}</DataTableCell>
      <DataTableCell compact nowrap><ConfigureButton entryId={entry.id} /></DataTableCell>
      <DataTableCell compact nowrap align='right'>{entry.isPrimary ? <DeleteEntryButton entryId={entry.id} /> : null}</DataTableCell>
    </DataTableRow>
  )
}


export default withStyles(styles)(DictionaryEntryRow)
