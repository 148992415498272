import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import VisuallyHidden from '../../../../components/VisuallyHidden'
import ReportsSearchInput from '../ReportsSearchInput'
import ReportsSearchTable from '../ReportsSearchTable'
import ReportsSearchFilters from '../ReportsSearchFilters'
import ReportsLoadingBar from '../ReportsLoadingBar'
import styles from './style'

import { addQuery } from '../../../../helpers/query'

import { useInitialFetch } from '../../../../hooks'
import { selectors as reportsSelectors } from '../../../../store/modules/reports'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { actions as sourceActions } from '../../../../store/modules/sources'
import { actions as organisationDetailsActions, selectors as organisationDetailsSelectors } from '../../../../store/modules/organisationDetails'
import ReportsMoreMenu from '../ReportsMoreMenu'

const ReportsSearchScreen = ({ classes, pageTitle }) => {
  const filters = useSelector(reportsSelectors.getFilters)
  const organisationId = useSelector(authSelectors.getUserSelectedOrganisationId)
  const hasPlatformReportExportEnabled = useSelector(platformSelectors.getHasPlatformReportsExportEnabled)
  useInitialFetch(() => sourceActions.fetchOrganisationSources({ organisationId }))
  useInitialFetch(() => organisationDetailsActions.fetchOrganisationById({ id: organisationId }))

  useEffect(() => {
    addQuery(filters)
  }, [filters])

  return (
    <div className={classes.container}>
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <ReportsLoadingBar />
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <ReportsSearchInput />
        )}
        rightContent={( hasPlatformReportExportEnabled && <ReportsMoreMenu /> )}
      />
      <div className={classes.scrollContainer}>
        <ReportsSearchFilters />
        <ReportsSearchTable />
      </div>
    </div>
  )
}

ReportsSearchScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(ReportsSearchScreen)
