import React from 'react'

import Editable from '../Editable'
import { Input } from '../../../components/Fields'
import A from '../../A'
import P from '../../P'

export default Editable({
  input: (props) => (
    <Input {...props} />
  ),
  display: ({ label, value }) => {
    const addHttps = (url) => {
      if (!url.includes('://')) {
        url = 'https://' + url
      }
      return url
    }
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={value === '-' ? value : <A href={addHttps(value)} target='_blank' value={value} />} />
      </div>
    )
  }
})
