import _ from 'lodash'
import { insertIf, removeEmptySections, isLimitedToYesNoUnkOptions } from './utils'
import * as validators from '../../../../../validators'
import { translations } from '../../../../../config'
import drugTypes from '../../../../../config/configFiles/drugTypes.json'

import {
  getOptionLabel,
  renderOption,
  filterCustom,
  filterHeadings,
  filterBlocks
} from './helpers'

const commonFields = ({ editing, autoCompleteOptions = [], updateInitialValues }) => {
  return {
    field: {
      id: 'field',
      field: 'Dropdown',
      props: {
        label: translations('Field type'),
        name: 'field',
        required: true,
        disabled: editing,
        options: [
          {
            value: 'Input',
            label: translations('Field type - Input')
          },
          {
            value: 'Dropdown',
            label: translations('Field type - Dropdown')
          },
          {
            value: 'Date',
            label: translations('Field type - Date')
          },
          {
            value: 'Time',
            label: translations('Field type - Time')
          },
          {
            value: 'Numeric',
            label: translations('Field type - Numeric')
          },
          {
            value: 'Checklist',
            label: translations('Field type - Checklist')
          },
          {
            value: 'Radiolist',
            label: translations('Field type - Radiolist')
          }
        ]
      }
    },
    name: {
      id: 'name',
      field: 'Autocomplete',
      props: {
        label: translations('Name'),
        name: 'name',
        required: true,
        disabled: editing,
        options: autoCompleteOptions,
        freeSolo: true,
        getOptionLabel,
        renderOption,
        handleChange: (ev, value) => {
          if (_.isObject(value)) {
            updateInitialValues(_.omit(value, ['id', 'sectionId']))
          }
        }
      }
    },
    displayName: {
      id: 'displayName',
      field: 'Input',
      props: {
        label: translations('Display name'),
        name: 'displayName'
      }
    }
  }
}

const questionSchema = ({ fields, editing, schemaField, isCustom, formValues, useFormBuilder = false }) => {
  const inputType = _.get(schemaField, 'field') || (useFormBuilder === true && _.get(formValues, 'field'))
  const isLimitedToYesNoUnk = _.get(formValues, 'isLimitedToYesNoUnk')
  const hasOptions = _.size(formValues.options) > 0
  const showLimitedToYesNoUnk = (isLimitedToYesNoUnk === true) && !hasOptions
  const allowDecimal = _.get(formValues, 'decimal', false)
  const allowOptions = ['Checklist', 'Radiolist', 'Dropdown'].includes(inputType)

  const createSchema = {
    general: {
      title: 'General',
      layout: [],
      schema: [
        ...insertIf(useFormBuilder === true, fields.field),
        fields.name,
        fields.displayName
      ]
    },
    validation: {
      title: 'Validation',
      layout: [
        'mandatory:12'
      ],
      schema: [
        {
          id: 'mandatory',
          field: 'Checkbox',
          props: {
            label: translations('Mark as required'),
            name: 'mandatory'
          }
        }
      ]
    }
  }

  const updateSchema = {
    general: {
      ...createSchema.general,
      schema: [
        ...insertIf(isCustom, fields.field),
        fields.name,
        fields.displayName
      ]
    },
    validation: {
      ...createSchema.validation,
      layout: [
        ...createSchema.validation.layout,
        ...insertIf(isCustom && inputType === 'Dropdown', 'multiple:12'),
        ...insertIf(inputType === 'Numeric', 'decimal:12'),
        ...insertIf(inputType === 'Numeric', 'minimum:6'),
        ...insertIf(inputType === 'Numeric', 'maximum:6')
      ],
      schema: [
        ...createSchema.validation.schema,
        ...insertIf((isCustom && inputType === 'Input'), {
          id: 'maxLength',
          field: 'Input',
          props: {
            shrink: true,
            label: translations('Maximum character count'),
            name: 'maxLength',
            help: translations('Report Configuration - maxLength Help'),
            validate: [validators.numeric]
          }
        }),
        ...insertIf((isCustom && inputType === 'Input'), {
          id: 'pattern',
          field: 'OptionSelector',
          props: {
            label: 'Pattern',
            name: 'pattern',
            options: [
              {
                value: 'email',
                label: 'Email'
              }
            ],
            translationKeys: {
              selectorField: 'Pattern - Option',
              customField: 'Pattern - Custom option',
              customErrorField: 'Pattern - Custom error message'
            },
            allowCustomError: true,
            customFieldValidators: [validators.isRegExp]
          }
        }),
        ...insertIf(isCustom && inputType === 'Dropdown', {
          id: 'multiple',
          field: 'Checkbox',
          props: {
            label: translations('Report Configuration - Multi Select'),
            name: 'multiple'
          }
        }),
        ...insertIf(isCustom && inputType === 'Date', {
          id: 'startDate',
          field: 'Date',
          props: {
            label: translations('Start Date'),
            name: 'startDate',
            validate: [
              validators.isDateBeforeOrEqual('endDate', 'End Date')
            ]
          }
        }),
        ...insertIf(isCustom && inputType === 'Date', {
          id: 'endDate',
          field: 'Date',
          props: {
            label: translations('End Date'),
            name: 'endDate',
            validate: [
              validators.isDateAfterOrEqual('startDate', 'Start Date')
            ]
          }
        }),
        ...insertIf(isCustom && inputType === 'Date', {
          id: 'acceptedDateFormats',
          field: 'Checklist',
          props: {
            label: translations('Accepted date formats'),
            name: 'acceptedDateFormats',
            options: [
              { value: 'YYYY', label: 'YYYY' },
              { value: 'YYYYMM', label: 'YYYY/MM' },
              { value: 'YYYYMMDD', label: 'YYYY/MM/DD' }
            ]
          }
        }),
        ...insertIf((isCustom && inputType === 'Numeric'), {
          id: 'decimal',
          field: 'Checkbox',
          props: {
            label: translations('Allow decimal numbers'),
            name: 'decimal'
          }
        }),
        ...insertIf((isCustom && inputType === 'Numeric'), {
          id: 'minimum',
          field: 'Input',
          props: {
            label: translations('Minimum value'),
            name: 'minimum',
            validate: [
              (allowDecimal && inputType === 'Numeric') ? validators.decimalNumeric : validators.numeric,
              validators.isLessThanField({
                field: 'maximum',
                fieldName: translations('Maximum value')
              })
            ]
          }
        }),
        ...insertIf((isCustom && inputType === 'Numeric'), {
          id: 'maximum',
          field: 'Input',
          props: {
            label: translations('Maximum value'),
            name: 'maximum',
            validate: [
              (allowDecimal && inputType === 'Numeric') ? validators.decimalNumeric : validators.numeric,
              validators.isGreaterThanField({
                field: 'minimum',
                fieldName: translations('Minimum value')
              })
            ]
          }
        })
      ]
    },
    appearance: {
      title: 'Appearance',
      layout: [],
      schema: [
        {
          id: 'helperText',
          field: 'Input',
          props: {
            label: translations('Field hint text'),
            name: 'helperText',
            help: translations('Report Configuration - HelperText Help')
          }
        },
        {
          id: 'hideFromForm',
          field: 'Checkbox',
          props: {
            label: translations('Read only'),
            name: 'hideFromForm',
            help: translations('Report Configuration - hideFromForm Help')
          }
        },
        {
          id: 'isForAssessment',
          field: 'Checkbox',
          props: {
            label: translations('Is for Assessment'),
            name: 'isForAssessment',
            help: translations('Report Configuration - isForAssessment Help')
          }
        }
      ]
    },
    options: {
      title: 'Options',
      layout: [],
      schema: [
        ...insertIf((isCustom && (inputType === 'Input' || inputType === 'Dropdown') && !hasOptions), {
          id: 'isLimitedToYesNoUnk',
          field: 'Checkbox',
          props: {
            label: translations('Accept only Yes, No or Unknown values'),
            name: 'isLimitedToYesNoUnk',
            help: translations('Report Configuration - isLimitedToYesNoUnk Help')
          }
        }),
        /**
         * By using a prefix of `value__` redux form will keep `answerMap` as an object
         * If the key was just a number it will convert to an array.
         */
        ...insertIf(showLimitedToYesNoUnk, _.map(isLimitedToYesNoUnkOptions, (option) => {
          return {
            id: `answerMap.value__${option.value}`,
            field: 'Input',
            props: {
              label: translations(`Report Configuration - AnswerMap - ${option.label}`),
              name: `answerMap.value__${option.value}`,
              help: translations('Report Configuration - AnswerMap Help'),
              multiline: true,
              rows: 5,
              validate: [validators.isJSON]
            }
          }
        })),
        ...insertIf(showLimitedToYesNoUnk, {
          id: 'disableDefaultMapping',
          field: 'Dropdown',
          props: {
            label: translations('Report Configuration - Disable default mapping'),
            name: 'disableDefaultMapping',
            options: _.map(isLimitedToYesNoUnkOptions, (option) => ({
              label: translations(`Report Configuration - AnswerMap - ${option.label}`),
              value: option.value
            })),
            multiple: true
          }
        }),
        ...insertIf((isCustom && allowOptions && !showLimitedToYesNoUnk), {
          id: 'options',
          field: 'OptionArray',
          props: {
            name: 'options',
            label: translations('Report Configuration - Custom options', { inputType }),
            divider: !hasOptions
          }
        }),
        ...insertIf((!isCustom && allowOptions && !showLimitedToYesNoUnk && inputType === 'Dropdown'), {
          id: 'dropdownOptions',
          field: 'OptionArray',
          props: {
            name: 'dropdownOptions',
            label: translations('Report Configuration - Custom options', { inputType }),
            divider: !hasOptions
          }
        })
      ]
    },
    advanced: {
      title: 'Advanced',
      layout: [
        ...insertIf(inputType === 'Date', 'startYear:6'),
        ...insertIf(inputType === 'DrugLookupInput', 'type:6')
      ],
      schema: [
        ...insertIf(inputType === 'DrugLookupInput', {
          id: 'type',
          field: 'Dropdown',
          props: {
            multiple: true,
            shrink: true,
            label: translations('Report Configuration - DrugType'),
            name: 'lookupOptions.type',
            options: drugTypes,
            noNullOption: true
          }
        }),
        ...insertIf((inputType === 'DrugLookupInput' || inputType === 'MedDRALookupInput'), {
          id: 'restrictFreeformEntry',
          field: 'Checkbox',
          props: {
            shrink: true,
            label: translations('Restrict Freeform Entry'),
            name: 'restrictFreeformEntry',
            help: translations('Report Configuration - restrictFreeformEntry Help')
          }
        })
      ]
    }
  }

  return removeEmptySections(editing ? updateSchema : createSchema)
}

const headingSchema = ({ fields }) => {
  return {
    general: {
      title: 'General',
      layout: [],
      schema: [
        fields.name,
        fields.displayName
      ]
    }
  }
}

const blockSchema = ({ fields }) => {
  return {
    general: {
      title: 'General',
      layout: [],
      schema: [
        fields.name
      ]
    }
  }
}

export const getSchema = ({ fieldType, schemaField, editing = false, initialValues = {}, formValues = {}, allFields = [], updateInitialValues, useFormBuilder = false }) => {
  const isCustom = _.get(initialValues, 'isCustom', false) === true
  switch (fieldType) {
    case 'QUESTION':
    case 'REPEATABLE':
      const currentFields = filterCustom(allFields)
      return questionSchema({
        fields: commonFields({
          editing,
          autoCompleteOptions: currentFields,
          updateInitialValues
        }),
        editing,
        schemaField,
        isCustom,
        formValues,
        currentFields,
        useFormBuilder
      })
    case 'HEADING':
      const currentHeaders = filterHeadings(allFields)
      return headingSchema({
        fields: commonFields({
          editing,
          autoCompleteOptions: currentHeaders,
          updateInitialValues
        }),
        editing,
        schemaField,
        currentHeaders
      })
    case 'BLOCK':
      const currentBlocks = filterBlocks(allFields)
      return blockSchema({
        fields: commonFields({
          editing,
          autoCompleteOptions: currentBlocks,
          updateInitialValues
        }),
        editing,
        schemaField,
        currentBlocks
      })
    default:
      return {
        general: {
          title: 'General',
          schema: [],
          layout: []
        }
      }
  }
}
