"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pharmFormOptions = exports.drugAdministrationRoutesR3Options = exports.drugAdministrationRoutesR2Options = void 0;

var _drugAdministrationRoutes = _interopRequireDefault(require("../config/EDQM/drugAdministrationRoutes.json"));

var _pharmForms = _interopRequireDefault(require("../config/EDQM/pharmForms.json"));

var _drugAdministrationRoutes2 = _interopRequireDefault(require("../config/drugAdministrationRoutes.json"));

var _drugAdministrationRoutesR = _interopRequireDefault(require("../config/drugAdministrationRoutesR3.json"));

var _drugDosageFormOptions = _interopRequireDefault(require("../config/drugDosageFormOptions.json"));

var _options = require("./options");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var drugAdministrationRoutesR2Options = (0, _options.withHiddenOptions)(_drugAdministrationRoutes2["default"])(_drugAdministrationRoutes["default"]);
exports.drugAdministrationRoutesR2Options = drugAdministrationRoutesR2Options;
var drugAdministrationRoutesR3Options = (0, _options.withHiddenOptions)(_drugAdministrationRoutesR["default"])(_drugAdministrationRoutes["default"]);
exports.drugAdministrationRoutesR3Options = drugAdministrationRoutesR3Options;
var pharmFormOptions = (0, _options.withHiddenOptions)(_drugDosageFormOptions["default"])(_pharmForms["default"]);
exports.pharmFormOptions = pharmFormOptions;