import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import { translations } from '../../../config'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import KeyValueCard from '../../../components/KeyValueCard'
import { AutoTable } from '../../../components/Table'
import styles from './style'
import FullScreenProgress from '../../../components/FullScreenLoader'
import RegistrationButton from '../../../components/RegistrationButton'

const PlatformsManagementScreen = ({ classes, results, rows, pageTitle, ...props }) => {
  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1' uppercase>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
      />
      <div className={classes.contentContainer}>
        {
          results &&
          <Fragment>
            <Hidden xsDown>
              <AutoTable rows={rows} />
            </Hidden>
            <Hidden smUp>
              {rows.map(row => {
                return <div className={classes.cardContainer} key={row.key}>
                  <KeyValueCard {...row} />
                </div>
              })}
            </Hidden>
          </Fragment>
        }
        {!results &&
          <div className={`${classes.content} ${classes.uppercase}`}>{translations('No results found')}</div>
        }
      </div>
      <RegistrationButton to='/platforms/new'>
        {translations('Add New Platform')}
      </RegistrationButton>
    </div>
  )
}

PlatformsManagementScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  results: PropTypes.array,
  rows: PropTypes.array,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default FullScreenProgress(
  withStyles(styles)(PlatformsManagementScreen)
)
