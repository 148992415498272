import digitalStoreSdk from '../../../digitalStoreSdk'
import * as constants from './constants'

class UserGroupActions {
  fetchAllUserGroupsForCurrentPlatform = ({ platformId }) => ({
    type: constants.FETCH_ALL_USER_GROUPS_FOR_CURRENT_PLATFORM,
    promise: (dispatch, getState) => digitalStoreSdk.userGroups.fetchAllUserGroupsByPlatformId({ platformId })
  })
  fetchAllUserGroupsForOrganisation = ({ organisationId }) => ({
    type: constants.FETCH_ALL_USER_GROUPS_FOR_ORGANISATION,
    promise: (dispatch, getState) => digitalStoreSdk.userGroups.fetchAllUserGroupsForOrganisation({ organisationId })
  })
  fetchUserGroup = ({ id }) => ({
    type: constants.FETCH_USER_GROUP,
    promise: (dispatch, getState) => digitalStoreSdk.userGroups.fetchUserGroup({ id })
  })
  createUserGroupForOrganisation = ({ organisationId, name, description }) => ({
    type: constants.CREATE_USER_GROUP_FOR_ORGANISATION,
    promise: (dispatch, getState) => digitalStoreSdk.userGroups.createUserGroupForOrganisation({ organisationId, name, description })
  })
  updateUserGroup = ({ id, organisationId, name, description }) => ({
    type: constants.UPDATE_USER_GROUP,
    promise: (dispatch, getState) => digitalStoreSdk.userGroups.updateUserGroup({ id, organisationId, name, description })
  })
  deleteUserGroup = ({ id }) => ({
    type: constants.DELETE_USER_GROUP,
    promise: (dispatch, getState) => digitalStoreSdk.userGroups.deleteUserGroup({ id })
  })
  fetchUserGroupUsers = ({ id, organisationId, query, offset, limit, includeUnassigned }) => ({
    type: constants.FETCH_USER_GROUP_USERS,
    promise: (dispatch, getState) => digitalStoreSdk.userGroups.fetchUserGroupUsers({ id, organisationId, query, offset, limit, includeUnassigned })
  })
  updateUserGroupUsers = ({ id, organisationId, assigned, unassigned }) => ({
    type: constants.UPDATE_USER_GROUP_USERS,
    promise: (dispatch, getState) => digitalStoreSdk.userGroups.updateUserGroupUsers({ id, organisationId, assigned, unassigned })
  })
  fetchUserGroupLocations = ({ id, organisationId, query, offset, limit, includeUnassigned }) => ({
    type: constants.FETCH_USER_GROUP_LOCATIONS,
    promise: (dispatch, getState) => digitalStoreSdk.userGroups.fetchUserGroupLocations({ id, organisationId, query, offset, limit, includeUnassigned })
  })
  updateUserGroupLocations = ({ id, organisationId, assigned, unassigned }) => ({
    type: constants.UPDATE_USER_GROUP_LOCATIONS,
    promise: (dispatch, getState) => digitalStoreSdk.userGroups.updateUserGroupLocations({ id, organisationId, assigned, unassigned })
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new UserGroupActions()