import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '../../Button'
import { CircularProgress } from '@material-ui/core'

const FormSubmit = props => {
  const {
    big,
    buttonType,
    ContainerComponent,
    disabled,
    buttonClass,
    containerClass,
    invalid,
    label,
    name,
    submitting,
    value,
    onClick,
    noContainer,
    fullWidth,
    children,
    className
  } = props

  const buttonContent = (
    submitting
    ? <CircularProgress size={23} thickness={2} />
    : label || children
  )

  const Container = ContainerComponent || 'div'

  const button = (
    <Button
      big={big}
      fullWidth={fullWidth}
      buttonType={buttonType}
      className={className || buttonClass}
      color='primary'
      disabled={invalid || submitting || disabled}
      name={name}
      raised
      type='submit'
      value={value}
      onClick={onClick}
    >
      {buttonContent}
    </Button>
  )

  return (
    noContainer
    ? button
    : <Container className={containerClass}>
      {button}
    </Container>
  )
}

FormSubmit.propTypes = {
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  label: PropTypes.string
}

export default FormSubmit
