import { createSlice } from '@reduxjs/toolkit'
import { createExtraReducers } from '../toolkit-utils'
import { SLICE_NAME } from './constants'
import middleware from './middleware'

const initialState = {
  websites: {
    data: [],
  },
  selectedWebsite: {
    data: []
  }
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetStore() {
      return initialState
    },
    resetWebsites(state) {
      state.websites = initialState.websites
    }
  },
  extraReducers: createExtraReducers(SLICE_NAME)
})

export const actions = slice.actions
export const store = slice.reducer
export { middleware }