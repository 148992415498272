import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import style from './styles'

const StyledDropZone = (props) => {
  const {
    accept,
    classes,
    onDrop,
    text,
    multiple = true,
    maxSize,
    maxFiles,
    onDragText,
    onSuccessText,
    onRejectText,
    minHeight
  } = props

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ accept, onDrop, multiple, maxSize, maxFiles })

  const activeClass = useMemo(() => {
    let className = classes.base
    if (isDragActive) {
      className = classes.active
    }
    if (isDragAccept) {
      className = classes.success
    }
    if (isDragReject) {
      className = classes.reject
    }
    return className
  }, [
    isDragActive,
    isDragReject,
    isDragAccept
  ])

  const activeText = useMemo(() => {
    let activeText = text
    if (isDragActive && onDragText) {
      activeText = onDragText
    }
    if (isDragAccept && onSuccessText) {
      activeText = onSuccessText
    }
    if (isDragReject && onRejectText) {
      activeText = onRejectText
    }
    return activeText
  }, [
    text,
    onDragText,
    onSuccessText,
    onRejectText,
    isDragActive,
    isDragReject,
    isDragAccept
  ])

  return (
    <Grid container className="container">
      <div {...getRootProps({ className: `${classes.base} ${activeClass}`, style: { minHeight } })}>
        <input {...getInputProps()} />
        <p>{activeText}</p>
      </div>
    </Grid>
  )
}

StyledDropZone.propTypes = {
  accept: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  onDragText: PropTypes.string,
  onSuccessText: PropTypes.string,
  onRejectText: PropTypes.string,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  minHeight: PropTypes.string
}

StyledDropZone.defaultValues = {
  accept: '',
  onDragText: '',
  onSuccessText: '',
  onRejectText: '',
  multiple: true
}

export default withStyles(style)(StyledDropZone)
