import React, { useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { submit } from 'redux-form'
import { useDispatch } from 'react-redux'

import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import {
  constants as developerConstants,
  actions as developerActions,
} from '../../../../../store/modules/developer'

import { PENDING } from '../../../../../store/middleware/redux-promise'
import * as validators from '../../../../../validators'

import { translations } from '../../../../../config'
import modalService from '../../../../../services/modalService'
import toastService from '../../../../../services/toastService'

const formName = developerConstants.GENERATE_APPLICATION
const GenerateForm = Form(formName)

const GenerateApplicationModal = (props) => {
  const {
    applicationId,
    organisationId,
    dismiss,
    isUpdate
  } = props
  const dispatch = useDispatch()

  const type = isUpdate ? 'Update' : 'Generate'

  const handleSubmit = (data) => {
    const {
      name,
      createdReason
    } = data

    if (isUpdate) {
      handleUpdate({ name })
    } else {
      handleCreate({ name, createdReason })
    }
  }

  const handleCreate = ({ name, createdReason }) => {
    try {
      dispatch(developerActions.createApplication({
        organisationId,
        application: {
          name,
          createdReason
        }
      }))
      modalService.close()
      toastService.action({
        type: 'success',
        message: translations('Developer - Create Application Success'),
        autoHideDuration: 6000
      })
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations('Developer - Create Application Failure'),
        autoHideDuration: 6000
      })
    }
  }

  const handleUpdate = ({ name }) => {
    try {
      dispatch(developerActions.updateApplication({
        id: applicationId,
        application: {
          name
        }
      }))
      modalService.close()
      toastService.action({
        type: 'success',
        message: translations('Developer - Update Application Success'),
        autoHideDuration: 6000
      })
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations('Developer - Update Application Success'),
        autoHideDuration: 6000
      })
    }
  }

  const actions = useMemo(() => {
    return [
      {
        success: true,
        text: translations(type),
        primary: true,
        onClick: () => {
          dispatch(submit(formName))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [dispatch, dismiss])

  const schema = useMemo(() => {
    const currentSchema = [
      {
        id: 'name',
        field: 'Input',
        props: {
          label: translations('Developer - Key name'),
          name: 'name',
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250,
          required: true
        }
      }
    ]
    if (!isUpdate) {
      currentSchema.push(
        {
          id: 'createdReason',
          field: 'Input',
          props: {
            label: translations('Developer - Created Reason'),
            name: 'createdReason',
            validate: [validators.maxLength(250, 'Field')],
            maxLength: 250,
            required: true
          }
        }
      )
    }
    return currentSchema
  }, [isUpdate])

  return (
    <BasicModalOverlay actions={actions} title={translations(`Developer - ${type} Application`)}>
      <GenerateForm onSubmit={handleSubmit} initialValues={props.data || {}}>
        <ContentBoxBody>
          <FormBody
            schema={schema}
            editing
            fullWidthFields
          />
        </ContentBoxBody>
      </GenerateForm>
    </BasicModalOverlay>
  )
}

GenerateApplicationModal.propTypes = {
  /** Modal actions */
  actions: PropTypes.array.isRequired,
  /** Callback when form is submitted */
  onSubmit: PropTypes.func,
  /** Closes the modal */
  dismiss: PropTypes.func,
  /** Title of the modal */
  title: PropTypes.string.isRequired
}

export default GenerateApplicationModal
