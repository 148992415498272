import React, { Component } from 'react'
import _ from 'lodash'
import { compose } from 'recompose'

import { FormControl } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../../config'
import Dropdown from '../../../../components/Fields/Dropdown'
import Checkbox from '../../../../components/Fields/Checkbox'
import { withFiltersContainer } from '../../../../components/FiltersContainer'
import { approvalStatuses } from '../../../ChangeManagement/ChangeManagementSearchScreen/ChangeManagementSearchFilters/options'
import styles from './style'
import OrganisationAssociationLookup from '../../../../components/Fields/OrganisationAssociationLookup'

export const UserAccountTypes = [
  { label: translations('User Account Type - STANDARD'), value: 'STANDARD' },
  { label: translations('User Account Type - GUEST'), value: 'GUEST' },
  { label: translations('User Account Type - ASSESSOR'), value: 'ASSESSOR' },
]

class UsersSearchFilters extends Component {
  state = {
    roleIds: this.props.roleIds,
    organisationIds: this.props.organisationIds,
    changeRequestStatuses: this.props.changeRequestStatuses,
    includeDeactivated: this.props.includeDeactivated,
    accountTypes: this.props.accountTypes
  }

  searchUsers = _.debounce(({ roleIds, organisationIds, changeRequestStatuses, includeDeactivated, accountTypes }) => {
    const { searchUsers } = this.props
    searchUsers({ roleIds, organisationIds, changeRequestStatuses, includeDeactivated, accountTypes })
  }, 300)

  updateIncludeDeactivated = (e) => {
    const includeDeactivated = e.target.checked
    this.searchUsers({ includeDeactivated })
    this.setState({ includeDeactivated })
  }

  updateRoles = (e) => {
    const roleIds = e.target.value
    this.searchUsers({ roleIds })
    this.setState({ roleIds })
  }

  updateOrganisations = (event) => {
    const organisationIds = _.has(event, 'target') ? event.target.value : event
    this.searchUsers({ organisationIds })
    this.setState({ organisationIds })
  }
  updateChangeRequestStatus = ({ target: { value: changeRequestStatuses } }) => {
    this.searchUsers({ changeRequestStatuses })
    this.setState({ changeRequestStatuses })
  }

  updateAccountType = ({ target: { value: accountTypes } }) => {
    this.searchUsers({ accountTypes })
    this.setState({ accountTypes })
  }

  render() {
    const { classes, loggedInUserOrganisationsOptions, allRolesOptions } = this.props
    const organisationComponent = loggedInUserOrganisationsOptions && !_.isEmpty(loggedInUserOrganisationsOptions) 
    ? 
    <FormControl className={classes.organisationsFilter}>
      <Dropdown
          label={translations('Organisation(s)')}
          value={this.state.organisationIds}
          options={loggedInUserOrganisationsOptions}
          onChange={this.updateOrganisations}
          passedProps={{ multiple: true }}
          multiple
          noErrorTextLabel
        />
    </FormControl>
    : 
    <FormControl className={classes.organisationsLookupFilter}>
      <OrganisationAssociationLookup
        label={translations('Organisation(s)')}
        onChange={this.updateOrganisations}
        getCustomValue={(organisation) => organisation.id}
        multiple
      />
    </FormControl>
    return (
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className={classes.leftContent}>
              {organisationComponent}
          </Grid>
          <Grid item xs={12} md={4} className={classes.middleContent}>
            <FormControl className={classes.roleFilter}>
              <Dropdown
                label={translations('Role(s)')}
                value={this.state.roleIds}
                options={allRolesOptions}
                onChange={this.updateRoles}
                passedProps={{ multiple: true }}
                multiple
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
          {this.props.hasApprovalRequirementForJoiningOrganisations
            && <Grid item xs={12} md={4} className={classes.middleContent}>
            <FormControl className={classes.roleFilter}>
              <Dropdown
                label={translations('Change Request - Approval Status')}
                value={this.state.changeRequestStatuses}
                options={approvalStatuses}
                onChange={this.updateChangeRequestStatus}
                passedProps={{ multiple: true }}
                multiple
                noErrorTextLabel
              />
            </FormControl>
          </Grid>}
          <Grid item xs={12} md={4} className={classes.leftContent}>
            <FormControl className={classes.roleFilter}>
              <Dropdown
                label={translations('User Search - Account Type')}
                value={this.state.accountTypes}
                options={UserAccountTypes}
                onChange={this.updateAccountType}
                passedProps={{ multiple: true }}
                multiple
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} className={classes.rightContent}>
            <FormControl className={classes.checkboxContainer}>
              <Checkbox
                label={translations('Include Deactivated')}
                value={this.state.includeDeactivated}
                checked={this.state.includeDeactivated}
                onChange={this.updateIncludeDeactivated}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(
  withStyles(styles),
  withFiltersContainer
)(UsersSearchFilters)
