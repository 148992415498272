import {
  SET_FORMVIEW_ID,
  SET_SIDEBAR_STATE,
  LOAD_INITIAL_VALUES
} from './constants'

const setFormViewId = (state, formViewId) => {
  return {
    ...state,
    formViewId,
    isNewFormView: formViewId === 'new'
  }
}

const setSidebarState = (state, isOpen) => {
  return {
    ...state,
    sidebarOpen: !!isOpen
  }
}

const loadInitialValues = (state, { formSchemaId, name, type }) => {
  return {
    ...state,
    formSchemaId,
    name,
    type
  }
}


export default (state, action) => {
  state.error = undefined
  const { type, payload } = action
  switch (type) {
    case SET_FORMVIEW_ID:
      return setFormViewId(state, payload)
    case SET_SIDEBAR_STATE:
      return setSidebarState(state, payload)
    case LOAD_INITIAL_VALUES:
      return loadInitialValues(state, payload)
    default: return state
  }
}