import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin: -20px 0px 20px 2px
`

const RepeatableInformation = (props) => {
  const {
    content
  } = props

  if(!content) {
    return <></>
  }

  return (
    <Container>
      {content.map((line) => {
        return (<p>{line}</p>)
      })}
    </Container>
  )
}

export default RepeatableInformation