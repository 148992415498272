import _ from 'lodash'
import mergeby from 'mergeby'
import { promiseReducer } from '../../util'

import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class OrganisationsReducers {
  fetchAllOrganisations = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllOrganisationsPending(state, action)
      case SUCCESS:
        return this.fetchAllOrganisationsSuccess(state, action)
      case FAIL:
        return this.fetchAllOrganisationsFail(state, action)
      default:
        return state
    }
  }
  fetchAllOrganisationsPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchAllOrganisationsSuccess = (state, action) => {
    return {
      ...state,
      results: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchAllOrganisationsFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  fetchUnassignedOrganisations = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchUnassignedOrganisationsPending(state, action)
      case SUCCESS:
        return this.fetchUnassignedOrganisationsSuccess(state, action)
      case FAIL:
        return this.fetchUnassignedOrganisationsFail(state, action)
      default:
        return state
    }
  }
  fetchUnassignedOrganisationsPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchUnassignedOrganisationsSuccess = (state, action) => {
    return {
      ...state,
      unassigned: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchUnassignedOrganisationsFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

  updateOrganisation = (state, action) => {
    switch (action.status) {
      case SUCCESS:
        return {
          ...state,
          // Merge the details updates
          // into the correct item in list
          results: mergeby(state.results, {
            id: action.payload.id,
            details: action.payload
          }, 'id')
        }
      default:
        return state
    }
  }

  fetchPaginatedOrganisations = promiseReducer(
    (state, action) => {
      return {
        ...state,
        pagination: {
          organisations: action.result,
          count: action.result.count
        }
      }
    }
  )

  clearPaginatedOrganisations = (state, action) => {
    return {
      ...state,
      pagination: {}
    }
  }

  searchOrganisations = promiseReducer(
    (state, action) => {
      return {
        ...state,
        search: {
          results: action.result.organisations,
          count: action.result.count
        }
      }
    }
  )

  clearSearchOrganisations = (state, action) => {
    return {
      ...state,
      search: {}
    }
  }
}

export default new OrganisationsReducers()
