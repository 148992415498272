import React, { useState } from 'react'
import _ from 'lodash'
import { styled } from '@material-ui/core/styles'
import { useAbilities } from '../../../../ability/ability-context'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

import { translations } from '../../../../config'

import GeneralPanel from '../GeneralPanel'
import ConfigurePanel from '../ConfigurePanel'
import { useRCEditor } from '../../../../contexts'

const TabBar = styled(AppBar)(({ theme }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.grey[200]}`
}))

const DrawerContent = () => {
  const [value, setValue] = useState('panel-general')
  const ability = useAbilities()
  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }
  const canUpdate = ability.can('update', 'reportConfigurationNext')

  const tabs = _.compact([
    { label: translations('General'), value: 'panel-general' },
    canUpdate && { label: translations('Configure'), value: 'panel-configure' }
  ])

  return (
    <TabContext value={value}>
      <TabBar position='static' color='transparent' variant='outlined'>
        <TabList onChange={handleChange} aria-label={translations('Report configuration panel tabs')} indicatorColor={'primary'}>
          {_.map(tabs, ({ label, value }) => {
            return (
              <Tab label={label} style={{ textTransform: 'none' }} value={value} />
            )
          })}
        </TabList>
      </TabBar>
      <TabPanel value='panel-general'>
        <GeneralPanel />
      </TabPanel>
      {
        canUpdate && <TabPanel value='panel-configure'>
          <ConfigurePanel />
        </TabPanel>
      }
    </TabContext>
  )
}

export default DrawerContent
