import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import { actions as authActions, selectors as authSelectors } from '../../store/modules/auth'
import Timer from './Timer'
import { logoutTime, translations, disableLogoutTimer } from '../../config'
import modalService from '../../services/modalService'

const TimedLogoutHOC = (WrappedComponent) => {
  class TimedLogoutContainer extends Component {
    constructor (props) {
      super(props)
      const { logoutTime } = props
      this.logoutTimer = new Timer(this.onTimeout, logoutTime)
    }
    componentDidMount () {
      this.logoutTimer.start()
    }

    componentWillUnmount () {
      this.logoutTimer.cancel()
    }

    onTimeout = () => {
      const { logout } = this.props

      modalService.action({
        title: translations('Session timeout - Title'),
        text: translations('Session timeout - Message'),
        disableBackdropClick: true,
        automaticAction: logout,
        actions: [
          {
            text: translations('No'),
            onClick: () => {
              logout()
            }
          },
          {
            text: translations('Yes'),
            onClick: () => {
              this.logoutTimer.restart()
            },
            primary: true
          }
        ]
      })
    }

    _onClick = () => {
      this.logoutTimer.restart()
    }

    render () {
      return (
        <div onClick={this._onClick} >
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }

  TimedLogoutContainer.propTypes = {
    logout: PropTypes.func,
    logoutTime: PropTypes.number
  }

  TimedLogoutContainer.defaultProps = {
    logoutTime
  }

  const mapStateToProps = state => {
    return {
      userIsLoggedIn: authSelectors.getIsLoggedIn(state)
    }
  }

  return compose(
    connect(mapStateToProps),
    withHandlers({
      logout: ({ dispatch, userIsLoggedIn }) => () => {
        if (userIsLoggedIn) {
          dispatch(authActions.logout())
        }
      }
    })
  )(TimedLogoutContainer)
}

const IdentityHOC = Comp => props => (
  <Comp {...props} />
)

const ExportedComponent = (
  disableLogoutTimer
  ? IdentityHOC
  : TimedLogoutHOC
)

export default ExportedComponent
