"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFieldUniqueInRepeatable = exports.dateTimeNotInFutureWithoutFormat = exports.checkPatientAge = exports.checkMinimumOf1FieldIs = exports.checkIf = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = _interopRequireDefault(require("lodash/fp"));

var _moment = _interopRequireDefault(require("moment"));

var _formHelpers = require("../formSchemas/devicesReport/7.2.0/formHelpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var checkPatientAge = function checkPatientAge(_ref) {
  var getDurationInYears = _ref.getDurationInYears,
      upperBoundary = _ref.upperBoundary;
  return function (_value, formValues) {
    var age = getDurationInYears('patientonsetage', 'patientonsetageunit')(formValues);

    if (age > upperBoundary) {
      return ["Error - Patient age exceeds {{upperBoundary}} years", {
        upperBoundary: upperBoundary
      }];
    }
  };
};

exports.checkPatientAge = checkPatientAge;

var isFieldUniqueInRepeatable = function isFieldUniqueInRepeatable(_ref2) {
  var fieldName = _ref2.fieldName,
      inRepeatable = _ref2.inRepeatable,
      unitName = _ref2.unitName,
      translationCallback = _ref2.translationCallback;
  return function (value, formValues) {
    var repeatables = _fp["default"].get(['_parentFormValues', inRepeatable], formValues);

    if (!repeatables || !value) {
      return;
    }

    var virtualRepeatable = _fp["default"].compose(_fp["default"].concat(_defineProperty({}, fieldName, value)), _fp["default"].reject(function (repeatable) {
      return repeatable.id === formValues.id || !repeatable[fieldName];
    }))(repeatables);

    var isFieldUnique = _fp["default"].compose(_fp["default"].eq(_fp["default"].size(virtualRepeatable)), _fp["default"].size, _fp["default"].uniqBy(_fp["default"].prop(fieldName)))(virtualRepeatable);

    if (!isFieldUnique) {
      return _lodash["default"].isFunction(translationCallback) ? translationCallback(value) : ["Error - fieldName not unique across repeatables", {
        value: value,
        field: unitName
      }];
    }
  };
};

exports.isFieldUniqueInRepeatable = isFieldUniqueInRepeatable;

var checkMinimumOf1FieldIs = function checkMinimumOf1FieldIs(_ref3) {
  var fields = _ref3.fields,
      predicate = _ref3.predicate,
      translationKey = _ref3.translationKey;
  return function (_value, formValues) {
    var formValuesSubset = _fp["default"].at(fields, formValues);

    var predicateIsTrue = _fp["default"].compose(function (predicates) {
      return _fp["default"].allPass(predicates)(formValuesSubset);
    }, _fp["default"].map(_fp["default"].any), _fp["default"].castArray)(predicate);

    if (!predicateIsTrue) {
      return translationKey;
    }
  };
};

exports.checkMinimumOf1FieldIs = checkMinimumOf1FieldIs;

var checkIf = function checkIf(predicate, validator) {
  return function () {
    if (predicate.apply(void 0, arguments)) {
      return validator.apply(void 0, arguments);
    }
  };
};

exports.checkIf = checkIf;

var dateTimeNotInFutureWithoutFormat = function dateTimeNotInFutureWithoutFormat(value) {
  var format = (0, _formHelpers.getFormatForDate)(value, {
    yyyymmdd: 'YYYYMMDD',
    yyyymm: 'YYYYMM',
    yyyy: 'YYYY'
  });

  if (!format || !value) {
    return;
  }

  var date = (0, _moment["default"])(value, _lodash["default"].toUpper(format));

  if (date.isAfter((0, _moment["default"])())) {
    return ['Error - Date In Future'];
  }
};

exports.dateTimeNotInFutureWithoutFormat = dateTimeNotInFutureWithoutFormat;