import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import ContentBox from '../../../../../components/ContentBox/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../../components/Form/TextContent'
import Heading from '../../../../../components/Heading'
import { translations } from '../../../../../config'
import FormBody from '../../../../../components/Form/FormBody'
import style from './style'
import _ from 'lodash'

const OtherUserGroups = (props) => {
  const {
    classes,
    options,
    value
  } = props

  const schema = [
    {
      id: 'otherUserGroup',
      field: 'Dropdown',
      props: {
        label: translations('Select other user group'),
        name: 'otherUserGroup',
        shrink: true,
        options,
        required: true
      }
    }
  ]

  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading className={classes.contentBoxHeader}>
            {translations('Select Other User Group')}
          </Heading>
        </TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <FormBody
          layout={['otherUserGroup:12']}
          schema={schema}
          editing
        />
      </ContentBoxBody>
    </ContentBox>
  )
}

export default withStyles(style)(OtherUserGroups)