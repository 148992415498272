// https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/12900504#12900504
export const getExtension = path => {
  const basename = (path || '').split(/[\\/]/).pop() // extract file name from full path (supports `\\` and `/` separators)
  const pos = basename.lastIndexOf('.')      // get last position of `.`
  if (basename === '' || pos < 1) {
    // if file name is empty or `.` not found (-1) or comes first (0)
    return ''
  }
  // extract extension ignoring `.`
  return basename.slice(pos + 1)
}
