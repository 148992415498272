import React from 'react'
import { styled } from '@material-ui/core/styles'

import Form, { FormBody, FormSubmit } from '../../../../../components/Form'
import { translations } from '../../../../../config'
import * as validators from '../../../../../validators'

const formName = 'newKey'
const CreateNewKeyForm = Form(formName)

const FormWrapper = styled('div')({
  width: '100%'
})

const NewCreateNewKeyForm = ({ onSubmit }) => {
  const schema = [
    {
      id: 'key',
      field: 'Input',
      props: {
        label: translations('Manage Translations - Create Key Input Label'),
        required: true
      }
    }
  ]

  return (
    <CreateNewKeyForm editing onSubmit={onSubmit}>
        <FormBody fullWidthFields schema={schema} />
        <FormSubmit>{translations('Manage Translations - Create Key Button')}</FormSubmit>
    </CreateNewKeyForm>
  )
}

export default NewCreateNewKeyForm
