import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { dateFormat } from '../../../config'
import moment from 'moment'
import _ from 'lodash'

const _formatFilterDate = (date) => {
  if (!date) return undefined
  const dateMoment = moment(date, dateFormat)
  if (dateMoment.isValid() && dateMoment.isAfter('1000-01-01')) return dateMoment.format('YYYY/MM/DD')
  return undefined
}

const _mapFilters = (filters) => {
  return filters ? {
    ...filters,
    dateFrom: _formatFilterDate(filters.dateFrom),
    dateTo: _formatFilterDate(filters.dateTo)
  } : {}
}
class ReportConfigurationActions {
  fetchFormViews = () => ({
    type: constants.FETCH_FORM_VIEWS,
    promise: (_dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      return digitalStoreSdk.formView.fetchAllFormViews({
        organisationId,
        params: {
          includeUsage: true,
          version: 'ALL',
          source: 'ALL_CLIENT'
        }
      })
    }
  })

  fetchFormViewById = ({ formViewId, silent }) => ({
    type: constants.FETCH_FORMVIEW_BY_ID,
    payload: { isSilent: !!silent },
    promise: () => digitalStoreSdk.formView.fetchFormViewById({
      id: formViewId,
      params: { includeCohorts: true, includeUsage: true }
    })
  })

  changeReportFilters = ({ formViewId, filters }) => {
    return {
      type: constants.CHANGE_REPORTS_FILTERS,
      payload: { formViewId },
      page: 1,
      filters
    }
  }

  changeDefaultFilters = ({ filter }) => ({
    type: constants.CHANGE_DEFAULT_FILTERS,
    filter
  })

  searchReportsFresh = ({ formViewId, filters }) => {
    return {
      type: constants.SEARCH_REPORTS_FRESH,
      payload: { formViewId },
      promise: (dispatch, getState) => {
        const size = selectors.getReportsSize(getState())
        const currentFilters = selectors.getReportFilters(formViewId)(getState())
        return digitalStoreSdk.formView
          .fetchFormViewReportsById({
            params: {
              size,
              formViewId,
              ..._mapFilters({ ...currentFilters, ...filters }),
              page: 1
            }
          })
      }
    }
  }

  fetchReportXMLForIds = ({ ids }) => ({
    type: constants.FETCH_REPORT_FOR_ID,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.bulkDownloads
        .requestBulkDownloadURL({
          payload: ids,
          type: 'report'
        })
        .then(({ url }) => {
          window.location = url
          return Promise.resolve()
        })
    }
  })

  searchReportsNext = ({ formViewId, page }) => ({
    type: constants.SEARCH_REPORTS_NEXT,
    payload: { formViewId, next: true },
    promise: (dispatch, getState) => {
      const filters = selectors.getReportFilters(formViewId)(getState())
      const size = selectors.getReportsSize(formViewId)(getState())
      return digitalStoreSdk.formView
        .fetchFormViewReportsById({
          params: {
            ..._mapFilters(filters),
            page: (page + 1),
            size,
            formViewId
          }
        })
    }
  })

  searchReportersFresh = ({ formViewId }) => ({
    type: constants.SEARCH_FORMVIEW_REPORTERS_FRESH,
    payload: { formViewId },
    promise: (_dispatch, getState) => {
      const state = getState()
      const size = selectors.getReportersSearchSize(formViewId)(state)
      const query = selectors.getFormViewReportersQuery(formViewId)(state)
      const filters = selectors.getFormViewReportersFilters(formViewId)(state)
      return digitalStoreSdk.formView
        .fetchFormViewReportersById({
          id: formViewId,
          page: 1,
          size,
          query,
          ...filters
        })
    }
  })
  searchReportersNext = ({ formViewId }) => ({
    type: constants.SEARCH_FORMVIEW_REPORTERS_NEXT,
    payload: { formViewId },
    promise: (_dispatch, getState) => {
      const state = getState()
      const size = selectors.getReportersSearchSize(formViewId)(state)
      const page = selectors.getReportersSearchPage(formViewId)(state)
      const query = selectors.getFormViewReportersQuery(formViewId)(state)

      return digitalStoreSdk.formView
        .fetchFormViewReportersById({
          id: formViewId,
          page: page + 1,
          size,
          query
        })
    }
  })

  changeReportersQuery = ({ formViewId, query }) => ({
    type: constants.CHANGE_FORMVIEW_REPORTERS_QUERY,
    payload: { formViewId },
    query
  })

  updateReportersFilters = ({ type, value, formViewId }) => ({
    type: constants.UPDATE_FORMVIEW_REPORTERS_FILTERS,
    payload: { formViewId, type, value }
  })

  searchFollowUpFresh = ({ formViewId }) => {
    return ({
      type: constants.SEARCH_FOLLOWUP_FRESH,
      payload: { formViewId },
      promise: (_dispatch, getState) => {
        const state = getState()
        const filters = selectors.getFollowUpFilters(formViewId)(state)
        return digitalStoreSdk.formView
          .fetchFormViewFollowUpsById({
            formViewId,
            ...filters
          })
      }
    })
  }

  changeFollowUpFilters = ({ formViewId, filters }) => {
    return {
      type: constants.UPDATE_FOLLOWUP_FILTERS,
      payload: { formViewId },
      filters
    }
  }

  setInitialEditorValues = (payload) => {
    return {
      type: constants.SET_INITIAL_EDITOR_VALUES,
      payload
    }
  }
  createReportConfiguration = ({ params }) => {
    return {
      type: constants.CREATE_REPORT_CONFIGURATION,
      promise: () => {
        return digitalStoreSdk.formView.createFormVersion({ params })
      }
    }
  }
  updateReportConfiguration = ({ id, params }) => {
    return {
      type: constants.UPDATE_REPORT_CONFIGURATION,
      promise: () => {
        return digitalStoreSdk.formView.updateFormView({ id, params })
      }
    }
  }
  updateReportConfigurationVersion = ({ id, viewJSON, conditions, schemaLibraryVersion, schemaVersionJsonHash, repeatableInstances }) => {
    return {
      type: constants.UPDATE_REPORT_CONFIGURATION_VERSION,
      promise: () => {
        return digitalStoreSdk.formView.updateFormVersion({ id, params: { viewJSON, conditions, schemaLibraryVersion, schemaVersionJsonHash, repeatableInstances } })
      }
    }
  }
  updateReportVersionConditions = ({ id, conditions }) => {
    return {
      type: constants.UPDATE_FORMVIEW_CONDITIONS,
      promise: () => {
        return digitalStoreSdk.formView.updateFormVersionConditions({ id, conditions })
      }
    }
  }
  updateFormViewFilters = ({ filters }) => {
    return {
      type: constants.UPDATE_FORM_VIEW_FILTERS,
      payload: filters
    }
  }
  createFollowUp = ({ params = {}, formViewId }) => {
    return {
      type: constants.CREATE_FOLLOW_UP,
      promise: () => {
        return digitalStoreSdk.formView.createFollowUp({ ...params, formViewId })
      }
    }
  }

  createIndividualFollowUp = ({ params = {}, formViewId, userId }) => {
    return {
      type: constants.CREATE_INDIVIDUAL_FOLLOW_UP,
      promise: () => {
        return digitalStoreSdk.formView.createIndividualFollowUp({ ...params, formViewId, userId })
      }
    }
  }

  editFollowUp = ({ params = {}, followUpId }) => {
    return {
      type: constants.UPDATE_FOLLOW_UP,
      promise: () => {
        return digitalStoreSdk.formView.updateFollowUp({ ...params, followUpId })
      }
    }
  }
  deleteFollowUp = ({ followUpId }) => {
    return {
      type: constants.DELETE_FOLLOW_UP,
      promise: () => {
        return digitalStoreSdk.formView.deleteFollowUp({ followUpId })
      }
    }
  }
  fetchFollowUpViewJSON = ({ followUpReportFormViewId, followUpId, reportConfigurationId }) => {
    return {
      type: constants.FETCH_FOLLOW_UP_REPORT_CONFIGURATION,
      promise: () => {
        return digitalStoreSdk.formView.fetchFormViewById({ id: followUpReportFormViewId })
      },
      payload: { followUpId, reportConfigurationId }
    }
  }

  searchCohortsFresh = ({ formViewId }) => {
    return ({
      type: constants.SEARCH_COHORTS_FRESH,
      payload: { formViewId },
      promise: () => {
        return digitalStoreSdk.formView.fetchFormViewCohortsById({ formViewId })
      }
    })
  }

  createCohort = ({ params = {}, formViewId }) => {
    return {
      type: constants.CREATE_COHORT,
      promise: () => {
        return digitalStoreSdk.formView.createCohort({ ...params, formViewId })
      }
    }
  }

  updateCohort = ({ params = {}, formViewId, cohortId }) => {
    return {
      type: constants.UPDATE_COHORT,
      promise: () => {
        return digitalStoreSdk.formView.updateCohort(_.omitBy({ ...params, formViewId, cohortId }, _.isNil))
      }
    }
  }

  deleteCohort = ({ cohortId }) => {
    return {
      type: constants.DELETE_COHORT,
      promise: () => {
        return digitalStoreSdk.formView.deleteCohort({ cohortId })
      }
    }
  }

  fetchFormViewReporterFollowUps = ({ userId, reportFormViewId }) => {
    return {
      type: constants.FETCH_FORMVIEW_REPORTER_FOLLOWUPS,
      payload: { userId, reportFormViewId },
      promise: () => {
        return digitalStoreSdk.formView.fetchFormViewReporterFollowUps({ userId, reportFormViewId })
      }
    }
  }

  updateFollowUpUser = ({ params = {}, followUpUserId }) => {
    return {
      type: constants.UPDATE_FOLLOWUP_USER,
      promise: () => {
        return digitalStoreSdk.formView.updateFollowUpUser({ ...params, followUpUserId })
      }
    }
  }

  fetchLatestReportVersionForReporter = ({ params = {}, reportId, userId }) => {
    return {
      type: constants.FETCH_LATEST_REPORT_VERSION_FOR_REPORTER,
      payload: { userId },
      promise: () => {
        return digitalStoreSdk.formView.fetchLatestReportVersionForReporter({ ...params, reportId, userId })
      }
    }
  }

  updateFollowUpPublishState = ({ id, scheduled }) => {
    return {
      type: constants.UPDATE_FOLLOWUP_PUBLISH_STATE,
      promise: () => {
        return digitalStoreSdk.formView.updateFollowUpPublishState({ id, scheduled })
      }
    }
  }
}

export default new ReportConfigurationActions()
