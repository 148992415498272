import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
`

const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.errorText};
  padding: 0;
  margin: 0;
`

const RetryButton = styled.button`
  color: #0000FF;
  background-color: inherit;
  border: 0;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  padding-left: 5px;
  margin: 0;
  &:hover, &:active, &:focus {
    color: #000099;
  }
`

const LocationError = ({
  message,
  action,
  actionLabel
}) => {
  return (
    <ErrorContainer>
      <ErrorMessage>{message}</ErrorMessage>
      {
        action && actionLabel && (
          <RetryButton onClick={action}>{actionLabel}</RetryButton>
        )
      }
    </ErrorContainer>
  )
}

export default LocationError