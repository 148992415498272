import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import { selectors as authSelectors } from '../../store/modules/auth'

import Can from './Can'

const enhance = compose(
  connect(state => ({
    permissions: authSelectors.getLoggedInUsersPermissions(state)
  })),
  withPropsOnChange(['permissions'], props => {
    const { permissions, actions } = props
    const actionsToArray = _.isString(actions) ? [actions] : actions
    const allowed = _.reduce(actionsToArray, (memo, action) => {
      return memo || _.get(permissions, action, false)
    }, false)

    return { allowed }
  })
)

export default enhance(Can)
