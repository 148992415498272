import { styled, Button, Tooltip, Typography } from '@material-ui/core'
import CopyToClipboardButton from '../../../../../components/CopyToClipboardButton'

export const DetailButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  padding: 0,
  margin: 0,
  textTransform: 'none'
}))

export const InlineCode = styled('code')(({ theme }) => ({
  fontFamily: 'Monospace !important',
  backgroundColor: '#f4f4f4',
  padding: '0 5px'
}))

export const getTooltipDetailButton = ({ text, onClick, hoverText }) => {
  return (
    <Tooltip title={hoverText} placement="top">
      <DetailButton onClick={onClick}>
        {text}
      </DetailButton>
    </Tooltip>
  )
}

export const getCopyDetailButton = ({ text, code }) => {
  return (
    <CopyToClipboardButton
      component={
        <DetailButton>
          {code && <InlineCode>{text}</InlineCode>}
          {!code && <code>{text}</code>}
        </DetailButton>
      }
      text={text}
    />
  )
}