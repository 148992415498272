import { REHYDRATE } from 'redux-persist'

import ReportConfigurationActions from './actions'
import { constants as authConstants } from '../auth'

import _ from 'lodash'

class ReportConfigurationMiddleware {
  setOrganisationFilterOnOrgSelect = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === authConstants.SELECT_ORGANISATION) {
      const orgId = _.get(action, 'organisationId')
      if (orgId) {
        dispatch(ReportConfigurationActions.changeDefaultFilters({ filter: { name: 'organisationId', value: orgId } }))
      }
    }
  }

  setOrganisationFilterOnRehydrate = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === REHYDRATE) {
      const orgId = _.get(action, 'payload.auth.user.selectedOrganisationId')
      if (orgId) {
        dispatch(ReportConfigurationActions.changeDefaultFilters({ filter: { name: 'organisationId', value: orgId } }))
      }
    }
  }
}

export default new ReportConfigurationMiddleware()
