const styles = theme => {
  return {
    rowHeader: {
      fontWeight: 700,
      position: 'relative',
      top: 1
    },
    row: {
      transition: 'background-color .2s ease-in-out'
    },
    selected: {
      backgroundColor: theme.palette.grey['100']
    },
    checkboxContainer: {
      marginRight: 5
    },
    checkbox: {
      color: theme.palette.grey['600'],
      height: 38,
      width: 38
    },
    checkboxSelected: {
      color: theme.palette.grey['900']
    },
    moreWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    anonymiseIcon: {
      height: 20,
      width: 20,
      marginLeft: 5
    },
    loadingSpinner: {
      height: 20,
      width: 20,
      marginRight: 12
    }
  }
}

export default styles
