import React from 'react'
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton'
import * as hooks from '../ConfigureEntryModal/hooks'


const ConfigureButton = ({ entryId }) => {
    const onClick = hooks.useEntryConfigureModal({ entryId })
    return <IconButton
        aria-label="Configure"
        onClick={onClick}
    >
        <EditIcon />
    </IconButton>
}

export default ConfigureButton
