import React from 'react'

import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'

import OrganisationRequests from './index'

export default [
  <PrivateRoute key='/organisation-requests/:changeRequestId' path='/organisation-requests/:changeRequestId' exact component={TimedLogoutComponent(OrganisationRequests)} />
]

