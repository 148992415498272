import React from 'react'
import { TextField } from '@material-ui/core'

import FullWidthFormControl from '../FullWidthFormControl'

const InputField = ({ value, label, placeholder, onChange }) => (
  <FullWidthFormControl size='small'>
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      label={label}
      InputLabelProps={{
        shrink: true
      }}
    />
  </FullWidthFormControl>
)

export default InputField
