import React, { useState } from 'react'
import _ from 'lodash'

import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core'

import SearchInput from '../../../../components/SearchInput'

import { translations } from '../../../../config'
import {
    hooks as reportConfigurationHooks,
    actions as reportConfigurationActions
} from '../../../../store/modules/reportConfiguration'

const InputContainer = styled('div')(({ theme }) => ({
    width: '100%',
    margin: '0 auto 10px auto',
    [theme.breakpoints.up('md')]: {
        width: '50%'
    }
}))

const ReportersSearch = ({ formViewId }) => {
    const dispatch = useDispatch()
    const searchQuery = reportConfigurationHooks.useReportersQuery({ formViewId })
    const [searchValue, setSearchValue] = useState(searchQuery)

    const search = _.debounce(() => {
        dispatch(reportConfigurationActions.searchReportersFresh({ formViewId }))
    }, 300)

    const updateQuery = (e) => {
        const query = e.target.value
        setSearchValue(query)
        dispatch(reportConfigurationActions.changeReportersQuery({ formViewId, query }))
        search()
    }

    const clearQuery = () => {
        setSearchValue('')
        dispatch(reportConfigurationActions.changeReportersQuery({ formViewId, query: '' }))
        search()
    }

    return (
        <InputContainer>
            <SearchInput
                value={searchValue}
                placeholder={translations('Reporters - Search Field Placeholder with WWRN')}
                onChange={(e) => updateQuery(e)}
                onClear={() => clearQuery()}
                autoFocus={false}
            />
        </InputContainer>
    )
}

export default ReportersSearch
