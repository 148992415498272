const styles = {
  container: {
    minHeight: 2,
    height: 2,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  }
}

export default theme => styles
