import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import digitalStoreSdk from '../../../digitalStoreSdk'
import Dropdown from '../Dropdown'

const TerminologyLookupDropdown = (props) => {
  const {
    lookupOptions,
    label,
    value = '',
    onChange,
    onBlur,
    name,
    id,
    required = false,
    meta,
  } = props

  const [options, setOptions] = useState([])
  
  useEffect(async () => {
    if (lookupOptions?.listName) {
      try {
        const results = await digitalStoreSdk.terminology.searchTerminologyEntriesByReference({reference: lookupOptions.listName})
        const entries = results?.rows
        const mappedOptions = _.map(entries, entry => ({value: entry?.term, label: entry?.term}))
        setOptions(mappedOptions)
      } catch (error) {
        console.error(error)
      }
    }
  }, [lookupOptions])
  
  return (
    <Dropdown
      autocomplete={true}
      options={options}
      value={value}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      id={id}
      name={name}
      required={required}
      meta={meta}
    />
  )
}
export default TerminologyLookupDropdown
