
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import Heading from '../../../../components/Heading'
import { translations } from '../../../../config'
import styles from './style'
import BottomBar from '../../../../components/BottomBar'
import ButtonsContainer from '../../../../components/ButtonsContainer'
import Button from '../../../../components/Button'
import Loader from '../../../../components/Loader'
import Container from '../../../../components/Container'
import Hidden from '@material-ui/core/Hidden'
import InfiniteScroll from 'react-infinite-scroller'
import { AutoTable } from '../../../../components/Table'
import KeyValueCard from '../../../../components/KeyValueCard'

const renderBottomBar = ({ width, onPageEdit }) => {
  const isXs = width === 'xs'
  return (
    <BottomBar relative={isXs}>
      <ButtonsContainer align='center' column={isXs}>
        <Button onClick={onPageEdit} raised color='primary'>
          {translations('Pages - New Page')}
        </Button>
      </ButtonsContainer>
    </BottomBar>
  )
}

const ManagePagesScreen = ({rows, loading, classes, width, onPageEdit}) => {
  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1' uppercase>{translations('Pages - Page Management')}</Heading>
            <Helmet>
              <title>{translations('Pages - Page Management')}</title>
            </Helmet>
          </Fragment>
        )}
      />
      <Container withMarginTop>
        <Fragment>
          <div className={classes.gridContainer}>
            <InfiniteScroll
              pageStart={0}
              className={classes.container}
              useWindow
            >
            {
              loading ? 
                <Loader /> : 
              rows.length ?
                <Fragment>
                  <Hidden xsDown>
                    <AutoTable rows={rows} />
                  </Hidden>
                  <Hidden smUp>
                    {rows.map(row => {
                      return <div className={classes.cardContainer} key={row.key}>
                        <KeyValueCard {...row} />
                      </div>
                    })}
                  </Hidden>
                </Fragment> :
                <Heading component='h2' className={classes.noDataMessage} uppercase>{translations('Pages - No Pages Found')}</Heading>
            }
            </InfiniteScroll>
          </div>
        </Fragment>
        {renderBottomBar({ width, onPageEdit})} 
      </Container>
    </div>
  )
}

export default withStyles(styles)(ManagePagesScreen)
