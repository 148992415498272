import { selectors as authSelectors } from '../auth'
import actions from './actions'

export const getTotalNotificationsNotViewed = ({ getState, dispatch }) => {
  setInterval(() => {
    const state = getState()
    const isLoggedIn = authSelectors.getIsLoggedIn(state)

    if (isLoggedIn) {
        dispatch(actions.getTotalNotificationsNotViewed())
    }
  }, 60 * 1000 * 5)
}
