/**
 * Use an async fetching component (either ADRSearch or ADROptions).
 * The function mutates in place.
 * @param {object} schemaItem
 * @param {object} options
 * @param {string} options.searchType The type of search, depending on what is chosen is how ADR is queried.
 * @param {string} options.field The field to match to, if the field doesn't match then no operation is performed.
 */
export const generateADRSearchComponent = (schemaItem, { searchType, field, showInitially, lookupOptions, detailsList, formatResults, restrictInput, displayResults, lookupOptionsRequired } = {}) => {
  if (schemaItem.field !== field) {
    return schemaItem
  }
  schemaItem.field = schemaItem.props.restrictFreeformEntry ? 'ADROptions' : 'ADRSearch'
  schemaItem.props.searchType = searchType
  if (detailsList) {
    schemaItem.props.detailsList = detailsList
  }
  if (lookupOptions) {
    schemaItem.props.lookupOptions = lookupOptions
  }
  if (showInitially) {
    schemaItem.props.showInitially = showInitially
  }

  if (formatResults) {
    schemaItem.props.formatResults = formatResults
  }

  if (displayResults) {
    schemaItem.props.displayResults = displayResults
  }

  if (lookupOptionsRequired) {
    schemaItem.props.lookupOptionsRequired = lookupOptionsRequired
  }

  if (restrictInput) {
    schemaItem.props.restrictInput = restrictInput
  }

  return schemaItem
}
