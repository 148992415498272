import * as validators from '../../../validators'

import { SETTINGS_FORM_NAME } from '../../../store/modules/websiteManagement/constants'
import { EDIT_PLATFORM_FORM_NAME } from '../../../store/modules/platformDetails/constants'

const FormsWithPartnershipLogo = [
  SETTINGS_FORM_NAME,
  EDIT_PLATFORM_FORM_NAME
]

const shouldHidePartnershipLogoFields = (parentFormName) => !FormsWithPartnershipLogo.includes(parentFormName)

export const themeDetails = {
  theme: {
    title: 'Theme',
    initialOpen: true,
    schema: [
      {
        id: 'name',
        field: 'Input',
        props: {
          label: 'Name',
          name: 'name',
          shrink: true,
          required: true
        }
      },
      {
        id: 'favicon',
        field: 'ImagePicker',
        props: {
          label: 'Favicon',
          name: 'favicon',
          shrink: true,
          required: false
        }
      },
      {
        id: 'primaryColor',
        field: 'ColorPicker',
        props: {
          label: 'Primary Color',
          name: 'primaryColor',
          shrink: true,
          required: true
        }
      },
      {
        id: 'headerBackgroundColor',
        field: 'ColorPicker',
        props: {
          label: 'Header Background Color',
          name: 'headerBackgroundColor',
          shrink: true,
          required: true
        }
      },
      {
        id: 'secondaryColor',
        field: 'ColorPicker',
        props: {
          label: 'Secondary Color',
          name: 'secondaryColor',
          shrink: true,
          required: true
        }
      },
      {
        id: 'headerTitle',
        field: 'Input',
        props: {
          label: 'Header Title',
          name: 'headerTitle',
          shrink: true,
          required: false,
          validate: [validators.maxLength(27, 'Text')]
        }
      },
      {
        id: 'headerLogo',
        field: 'ImagePicker',
        props: {
          label: 'Header Logo',
          name: 'headerLogo',
          shrink: true,
          required: true
        }
      },
      {
        id: 'partnershipLogo',
        field: 'ImagePicker',
        computedHidden: (_formValues, parentFormName) => shouldHidePartnershipLogoFields(parentFormName),
        props: {
          label: 'Partnership Logo',
          name: 'partnershipLogo',
          shrink: true,
          required: false
        }
      },
      {
        id: 'partnershipLogoAlt',
        field: 'Input',
        computedHidden: (_formValues, parentFormName) => shouldHidePartnershipLogoFields(parentFormName),
        props: {
          label: 'Partnership Logo Alt Text',
          name: 'partnershipLogoAlt',
          shrink: true,
          required: false
        }
      },
      {
        id: 'headerLogoAlt',
        field: 'Input',
        props: {
          label: 'Header Logo Alt Text',
          name: 'headerLogoAlt',
          shrink: true,
          required: false
        }
      },
      {
        id: 'headerLogoLink',
        field: 'Input',
        props: {
          label: 'Header Logo Link',
          name: 'headerLogoLink',
          shrink: true,
          required: false,
          validate: [validators.url]
        }
      },
      {
        id: 'footerLogo',
        field: 'ImagePicker',
        props: {
          label: 'Footer/Platform Logo',
          name: 'footerLogo',
          shrink: true,
          required: false
        }
      },
      {
        id: 'footerMobileLogo',
        field: 'ImagePicker',
        props: {
          label: 'Footer/Platform Mobile Logo',
          name: 'footerMobileLogo',
          shrink: true,
          required: false
        }
      },
      {
        id: 'footerLogoAlt',
        field: 'Input',
        props: {
          label: 'Footer/Platform Logo Alt Text',
          name: 'footerLogoAlt',
          shrink: true,
          required: false
        }
      },
      {
        id: 'footerLogoLink',
        field: 'Input',
        props: {
          label: 'Footer/Platform Logo Link',
          name: 'footerLogoLink',
          shrink: true,
          required: false,
          validate: [validators.url]
        }
      },
      {
        id: 'enableLegacyTheming',
        field: 'Checkbox',
        props: {
          label: 'Enable Legacy Theming',
          name: 'enableLegacyTheming',
          shrink: true,
          required: false
        }
      }
    ]
  },
  legacy: {
    title: 'Legacy',
    initialOpen: true,
    schema: [
      {
        id: 'logo',
        field: 'ImagePicker',
        props: {
          label: 'Logo',
          name: 'logo',
          shrink: true,
          required: true
        }
      },
      {
        id: 'legacyAppColorPrimary',
        field: 'ColorPicker',
        props: {
          label: 'Primary Colour',
          name: 'legacyAppColorPrimary',
          shrink: true,
          required: true
        }
      },
      {
        id: 'legacyAppColorAppHeader',
        field: 'ColorPicker',
        props: {
          label: 'Header Bar Colour',
          name: 'legacyAppColorAppHeader',
          shrink: true,
          required: true
        }
      },
      {
        id: 'legacyAppColorAppHeaderText',
        field: 'ColorPicker',
        props: {
          label: 'Header Bar Text Colour',
          name: 'legacyAppColorAppHeaderText',
          shrink: true,
          required: true
        }
      },
      {
        id: 'legacyAppColorAppBackground',
        field: 'ColorPicker',
        props: {
          label: 'App Background Colour',
          name: 'legacyAppColorAppBackground',
          shrink: true,
          required: true
        }
      },
      {
        id: 'legacyAppColorStatusBar',
        field: 'ColorPicker',
        props: {
          label: 'Status Bar Color',
          name: 'legacyAppColorStatusBar',
          shrink: true,
          required: true
        }
      },
      {
        id: 'legacyAppColorPrimaryText',
        field: 'ColorPicker',
        props: {
          label: 'Primary Text Color',
          name: 'legacyAppColorPrimaryText',
          shrink: true,
          required: true
        }
      },
      {
        id: 'legacyAppColorSecondaryText',
        field: 'ColorPicker',
        props: {
          label: 'Secondary Text Colour',
          name: 'legacyAppColorSecondaryText',
          shrink: true,
          required: true
        }
      },
      {
        id: 'legacyAppColorInverseText',
        field: 'ColorPicker',
        props: {
          label: 'Inverse Text Color',
          name: 'legacyAppColorInverseText',
          shrink: true,
          required: true
        }
      }
    ]
  }
}
