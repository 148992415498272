import { FormControlLabel, FormGroup, Switch } from '@material-ui/core'
import { translations } from '../../../../../../../../config'

const Actions = ({
  isPublished,
  handlePublishChange
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={isPublished}
            onChange={handlePublishChange}
            name='publish'
            color='primary'
            size='medium'
          />
        }
        label={translations('Publish')}
      />
    </FormGroup>
  )
}

export default Actions