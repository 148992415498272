import { actions as userGroupActions } from '../userGroups'
import * as platformConstants from '../platforms/constants'
class UserGroupMiddleware {
  fetchAllUserGroupsForCurrentPlatform = ({ dispatch, setState }) => next => action => {
    next(action)
    if(action.type === platformConstants.FETCH_PLATFORM_FOR_DOMAIN && action.status === 'SUCCESS') {
      const platformId = action.result.id
      dispatch(userGroupActions.fetchAllUserGroupsForCurrentPlatform({ platformId }))
    }
  }
}

export default new UserGroupMiddleware()