import React from 'react'
import Checklist from './Checklist'
import OptionsFormatter from '../Wrappers/OptionsFormatter'
class ChecklistContainer extends React.Component {
  onChange = (e) => {
    const checkedValue = e.target.value
    const checkedItems = this.props.value || []
    const newValue = checkedItems.includes(checkedValue)
      ? checkedItems.filter(v => v !== checkedValue)
      : checkedItems.concat(checkedValue)
    this.props.onChange(newValue)
  }

  markCheckedOptions = () => {
    const { options, value } = this.props
    return options.map(option => ({
      ...option,
      checked: value.includes(option.value)
    }))
  }

  _generateProps = () => ({
    ...this.props,
    onChange: this.onChange,
    options: this.markCheckedOptions()
  })

  render () {
    const props = this._generateProps()
    return <Checklist {...props} />
  }
}

export default OptionsFormatter(ChecklistContainer)
