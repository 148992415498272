"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _sideEffects = require("../../../../../../formHelpers/sideEffects");

var _schema = require("../../../schema");

var _common = require("../common");

var psrHelpers = _interopRequireWildcard(require("./formHelpers"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'canSaveDraft',
    field: 'Input',
    computedValue: function computedValue(values) {
      return true;
    },
    props: {
      type: 'hidden',
      name: 'canSaveDraft',
      label: 'Can save draft',
      required: false
    }
  }, {
    id: 'psrId',
    field: 'Input',
    props: {
      shrink: true,
      disabled: true,
      name: 'psrId',
      label: "Periodic Summary Report (PSR) ID",
      validate: [validators.maxLength(100, 'Field')]
    }
  }, {
    id: 'status',
    field: 'Input',
    props: {
      shrink: true,
      type: 'hidden',
      name: 'status',
      label: 'Application Status'
    }
  }, {
    id: 'psrType',
    field: 'Dropdown',
    computedDisabled: psrHelpers.psrStatusIsNotSet,
    computedOptions: psrHelpers.psrTypeOptions,
    computedValue: psrHelpers.ifApprovedThenApplication,
    props: {
      shrink: true,
      name: 'psrType',
      label: 'Type of PSR (periodic summary report)',
      required: true
    }
  }, {
    id: 'adverseEventDateFrom',
    field: 'Date',
    computedRequired: psrHelpers.isAnalysisOrClosure,
    computedHidden: _sideEffects.audience.match({
      HCP: psrHelpers.adverseDatesHidden,
      MOP: psrHelpers.adverseDatesHidden,
      DEFAULT: _fp["default"].F
    }),
    props: {
      shrink: true,
      name: 'adverseEventDateFrom',
      label: 'Date of incident from',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('adversefromdateformat', _schema.dateFormats), validators.isDateBeforeOrEqual('adverseEventDateTo', 'Date of incident to')],
      helperText: 'Date range the report covers'
    }
  }, {
    id: 'adverseEventDateTo',
    field: 'Date',
    computedRequired: psrHelpers.isAnalysisOrClosure,
    computedHidden: _sideEffects.audience.match({
      HCP: psrHelpers.adverseDatesHidden,
      MOP: psrHelpers.adverseDatesHidden,
      DEFAULT: _fp["default"].F
    }),
    props: {
      shrink: true,
      name: 'adverseEventDateTo',
      label: 'Date of incident to',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('adversetodateformat', _schema.dateFormats), validators.isDateAfterOrEqual('adverseEventDateFrom', 'Date of incident from')],
      helperText: 'Date range the report covers'
    }
  }, {
    id: 'mfrName',
    field: 'Input',
    props: {
      required: true,
      shrink: true,
      name: 'mfrName',
      label: "Manufacturer Organisation name",
      validate: [validators.maxLength(200, 'Field')]
    }
  }].concat(_toConsumableArray((0, _common.miscDeviceSchema)(validators)), _toConsumableArray((0, _common.reporterSchema)(validators)), [{
    id: 'applicationAttachment',
    field: 'Repeatable',
    props: {
      label: 'Application Attachment',
      name: 'applicationAttachment',
      repeatableLabel: ['attachmentfile'],
      shrink: true,
      required: true,
      disableEdit: true,
      maxLength: 1,
      schema: [{
        id: 'attachmentfile',
        field: 'Input',
        props: {
          label: 'File Name',
          name: 'attachmentfile',
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmenttype',
        field: 'Input',
        props: {
          label: 'Attachment Type',
          name: 'attachmenttype',
          type: 'hidden',
          defaultValue: 'application',
          required: false,
          shrink: true
        }
      }, {
        id: 'attachmenturl',
        field: 'FilePicker',
        props: {
          label: 'Select File',
          name: 'attachmenturl',
          accept: ['.docx', '.doc'],
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmentcomments',
        field: 'Input',
        props: {
          label: 'File Comments',
          name: 'attachmentcomments',
          required: false,
          shrink: true,
          multiline: true,
          maxLength: 1000,
          validate: [validators.maxLength(1000, 'Field')]
        }
      }]
    }
  }, {
    id: 'incidentsAttachment',
    field: 'Repeatable',
    computedHidden: psrHelpers.isNotApplication,
    computedValue: psrHelpers.clearIfNotApplication,
    props: {
      label: 'Incidents Attachment',
      name: 'incidentsAttachment',
      repeatableLabel: ['attachmentfile'],
      shrink: true,
      required: true,
      disableEdit: true,
      maxLength: 1,
      schema: [{
        id: 'attachmentfile',
        field: 'Input',
        props: {
          label: 'File Name',
          name: 'attachmentfile',
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmenttype',
        field: 'Input',
        props: {
          label: 'Attachment Type',
          name: 'attachmenttype',
          type: 'hidden',
          defaultValue: 'incidents',
          required: false,
          shrink: true
        }
      }, {
        id: 'attachmenturl',
        field: 'FilePicker',
        props: {
          label: 'Select File',
          name: 'attachmenturl',
          accept: ['.xlsx', '.xls'],
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmentcomments',
        field: 'Input',
        props: {
          label: 'File Comments',
          name: 'attachmentcomments',
          required: false,
          shrink: true,
          multiline: true,
          maxLength: 1000,
          validate: [validators.maxLength(1000, 'Field')]
        }
      }]
    }
  }, {
    id: 'reportAttachment',
    field: 'Repeatable',
    props: {
      label: 'Supplementary Attachments',
      name: 'reportAttachment',
      repeatableLabel: ['attachmentfile'],
      shrink: true,
      disableEdit: true,
      schema: (0, _common.attachmentSchema)(validators)
    }
  }]);
};

exports.getFormSchema = getFormSchema;