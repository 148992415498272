const styles = theme => {
  return {
    container: {
      marginTop: '4px',
      marginBottom: '4px',
      paddingLeft: '120px',
      paddingRight: '120px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '20px',
        paddingRight: '20px'
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '10px',
        paddingRight: '10px'
      }
    },
    leftContent: {
      paddingLeft: 20
    },
    middleContent: {
    },
    rightContent: {
      paddingRight: 20
    },
    storesFilter: {
      width: '100%'
    },
    checkboxContainer: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 11,
        paddingLeft: 10
      }
    },
    roleFilter: {
      width: '100%'
    },
    buttonCol: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto'
    }
  }
}

export default styles
