// https://github.com/paypal/downshift/issues/512#issuecomment-406870254

import BrokenDownshift from 'downshift'

const stateKeys = [
  'highlightedIndex',
  'inputValue',
  'isOpen',
  'selectedItem',
  'type'
]

const pickState = (state = {}) => {
  const result = {}
  stateKeys.forEach(k => {
    if (state.hasOwnProperty(k)) {
      result[k] = state[k]
    }
  })
  return result
}

class Downshift extends BrokenDownshift {
  selectItem = (item, otherStateToSet, cb) => {
    otherStateToSet = pickState(otherStateToSet)
    this.internalSetState(
      {
        isOpen: false,
        highlightedIndex: this.props.defaultHighlightedIndex,
        selectedItem: item,
        inputValue: this.props.itemToString(item),
        ...otherStateToSet
      },
      cb
    )
  }
}

export default Downshift
