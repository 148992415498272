import React, { useState } from 'react'
import { styled, withStyles } from '@material-ui/core/styles'

import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import { translations } from '../../../config'
import ReusableTabs from '../../../components/ReusableTabs'
import GeneralTab from './GeneralTab'

const Content = styled('div')(({ theme }) => ({}))
const DrawerActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  background: theme.palette.primary.main,
  height: '65px'
}))
const MobileDrawer = styled(Drawer)(({ theme }) => ({}))

const WebsiteManagementSidebar = (props) => {
  const {
    generalFormRef
  } = props

  const [sidebarOpen, setSidebarOpen] = useState(true)

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  const tabs = [
    { label: translations('General'), value: 'general' }
  ]

  const renderGeneralTab = () => {
    return (
      <ReusableTabs tabs={tabs} shouldUseQuery={false} ariaLabel={'Website Management - Sidebar Tabs'}>
        <GeneralTab generalFormRef={generalFormRef} />
      </ReusableTabs>
    )
  }

  return (
    <Content>
      <Hidden mdUp>
        <IconButton
          color='secondary'
          onClick={toggleSidebar}
          aria-label='Open'
        >
          <ChevronLeftIcon />
        </IconButton>
        <MobileDrawer
          open={sidebarOpen}
          onClose={toggleSidebar}
          anchor='right'
          variant='temporary'
        >
          <DrawerActions>
            <IconButton
              color='secondary'
              onClick={toggleSidebar}
              aria-label='Close'
            >
              <CloseIcon />
            </IconButton>
          </DrawerActions>
          {renderGeneralTab()}
        </MobileDrawer>
      </Hidden>
      <Hidden smDown>
        {renderGeneralTab()}
      </Hidden>
    </Content>
  )
}

export default WebsiteManagementSidebar
