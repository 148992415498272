import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import fp from 'lodash/fp'
import _ from 'lodash'
import digitalStoreSdk from '../../../digitalStoreSdk'
import {
  NAME,
  REQUEST_STATUS_TYPE
} from './constants'
import * as selectors from './selectors'
import { statusAndNameMatcher } from '../toolkit-utils'

const generatePath = ({ reportId, formSchemaId }) => {
  const basePath = `master.byReportId`
  return `${basePath}.${reportId || 'undefined'}`
}

const fetchReportAuditHistory = createAsyncThunk(
  'reportAudit/fetchReportAuditHistory',
  async ({ reportId }, thunkApi) => {
    const fullPath = generatePath({ reportId })
    try {
      const data = await digitalStoreSdk.reportAudit.fetchReportAudit({ reportId })
      return thunkApi.fulfillWithValue({ data, meta: { reportId } }, { path: fullPath })
    } catch (error) {
      return thunkApi.rejectWithValue(error, { path: fullPath })
    }
  },
  {
    getPendingMeta: ({ arg: { reportId } }) => ({ path: generatePath({ reportId }) })
  }
)

const set = ({ state, action, path, value }) => {
  const location = _.concat(_.split(action.meta.path, '.'), path)
  _.set(state, location, value)
}
const initialState = {
  master: {
    byReportId: {}
  }
}
const isEmptyArray = fp.allPass([fp.isArray, fp.isEmpty])
const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    fetchReportAuditHistory(state, action) {
      const { reportId } = action.meta
      _.set(state, `master.byReportId.${reportId}`, action.payload)
    },
    resetStore() {
      return initialState
    }
  },
  extraReducers: (builder) => {
    return builder
      .addMatcher(
        statusAndNameMatcher(NAME, 'pending'),
        (state, action) => {
          set({ state, action, path: 'status', value: REQUEST_STATUS_TYPE.PENDING })
        }
      )
      .addMatcher(
        statusAndNameMatcher(NAME, 'fulfilled'),
        (state, action) => {
          const { data } = action.payload
          set({
            state,
            action,
            path: 'status',
            value: isEmptyArray(data) ? REQUEST_STATUS_TYPE.IS_EMPTY : REQUEST_STATUS_TYPE.FULFILLED
          })
          set({ state, action, path: 'data', value: data })
        }
      )
      .addMatcher(
        statusAndNameMatcher(NAME, 'rejected'),
        (state, action) => {
          set({ state, action, path: 'status', value: REQUEST_STATUS_TYPE.REJECTED })
          set({ state, action, path: 'error', value: action.payload.message })
        }
      )
  }
})
export const asyncActions = {
  fetchReportAuditHistory
}

export { selectors }
export const actions = slice.actions
export const store = slice.reducer
