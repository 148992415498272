const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '10px'
  },
  gridContainer: {
    margin: '10px auto 0 auto',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 15,
      paddingRight: 15,
      margin: 0
    }
  },
  content: {
    width: '100%',
    marginTop: 30,
    textAlign: 'center',
    fontSize: 19,
    fontWeight: 'bold'
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  lockContainer: {
    width: '50px',
    padding: 0
  },
  hideXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  cardContainer: {
    width: '100%',
    maxWidth: 400,
    padding: '7.5px 0'
  }
})

export default styles
