import { murFormConsts } from './constants'
import { change, untouch } from 'redux-form'
import _ from 'lodash'

export const changeSelectedAccountTypes = dispatch => (currentState, newState, singular, formId, reduxDispatch) => {

  const hasAccountTypeBeenRemoved = () => {
    const accountTypeRemoved = _.size(currentState) > _.size(newState)
    if(accountTypeRemoved) {
      const accountType = _.difference(currentState, newState)[0]
      deselectAccountType(dispatch, reduxDispatch)(accountType, formId)
    }
    return accountTypeRemoved
  }

  if(singular) {
    if(hasAccountTypeBeenRemoved()) { 
      selectAccountTypes(dispatch, reduxDispatch)([], formId)
      return 
    }
    const accountType = _.last(newState)
    return selectOne(dispatch, reduxDispatch)(accountType, formId)
  }

  const hasSelectedOther = _.includes(newState, murFormConsts.OTHER_GROUP_ID) && _.indexOf(newState, murFormConsts.OTHER_GROUP_ID) === _.size(newState) - 1
  if(hasSelectedOther) {
    return selectOne(dispatch, reduxDispatch)(murFormConsts.OTHER_GROUP_ID, formId)
  }
  deselectOther(reduxDispatch)(formId)
  hasAccountTypeBeenRemoved()
  const selectedAccountTypes = _.filter(newState, t => !_.includes(t, murFormConsts.OTHER_GROUP_ID))
  selectAccountTypes(dispatch, reduxDispatch)(selectedAccountTypes, formId)
}

const selectAccountTypes = (dispatch, reduxDispatch) => (selectedAccountTypes, formId) => {
  dispatch({ type: murFormConsts.CHANGE_SELECTED_ACCOUNT_TYPES, payload: { selectedAccountTypes } })
  reduxDispatch(change(formId, 'accountTypes', selectedAccountTypes))
}

const deselectAccountType = (dispatch, reduxDispatch) => (accountType, formId) => {
  const id = getFieldId(accountType)
  dispatch({ type: murFormConsts.CLEAR_SELECTED_ORGANISATION, payload: { id } })
  resetField(reduxDispatch)(formId, id)
}

const deselectOther = (reduxDispatch) => (formId) => {
  resetField(reduxDispatch)(formId, 'otherUserGroup')
}

const selectOne = (dispatch, reduxDispatch) => (accountType, formId) => {
  const allAccountTypes = [murFormConsts.MFR_GROUP_ID, murFormConsts.UKRP_GROUP_ID, murFormConsts.AUTH_REP_GROUP_ID, murFormConsts.OTHER_GROUP_ID]
  const selectedAccountTypes = [accountType]
  reduxDispatch(change(formId, 'accountTypes', selectedAccountTypes))
  dispatch({ type: murFormConsts.CHANGE_SELECTED_ACCOUNT_TYPES, payload: { selectedAccountTypes } })

  if(_.eq(accountType, murFormConsts.OTHER_GROUP_ID)) {
    dispatch({ type: murFormConsts.CLEAR_SELECTED_ORGANISATIONS })
  }

  const deslectingAccountTypes = _.difference(allAccountTypes, selectedAccountTypes)
  for(const type of deslectingAccountTypes) {
    deselectAccountType(dispatch, reduxDispatch)(type, formId)
  }
}

const resetField = (reduxDispatch) => (formId, id) => {
  reduxDispatch(change(formId, id, null))
  reduxDispatch(untouch(formId, id, null))
}

const getFieldId = (accountType) => {
  switch(accountType) {
    case murFormConsts.MFR_GROUP_ID:
      return murFormConsts.MFR_ID
    case murFormConsts.UKRP_GROUP_ID:
      return murFormConsts.UKRP_ID
    case murFormConsts.AUTH_REP_GROUP_ID:
      return murFormConsts.AUTH_REP_ID
    case murFormConsts.OTHER_GROUP_ID:
      return murFormConsts.OTHER_ID
  }
}

export const changeSelectedOrganisations = dispatch => (organisationId, userGroup) => {
  dispatch({ type: murFormConsts.CHANGE_SELECTED_ORGANISATIONS, payload: { organisationId, userGroup } })
}
