import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { actions as communicationTemplateActions, selectors as communicationTemplateSelectors } from '../../../../store/modules/communicationTemplates'

import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { selectors as authSelectors } from '../../../../store/modules/auth'

import FullScreenLoader from '../../../../components/FullScreenLoader'
import CommunicationsSmsScreen from './CommunicationsSmsScreen'

const mapStateToProps = (state) => {
  return {
    pageTitle: platformSelectors.getPageTitle(state)('Communications - Page Title Sms'),
    organisationId: authSelectors.getUserSelectedOrganisationId(state),
    isLoading: communicationTemplateSelectors.isLoadingAllTemplates(state),
    smsTemplateTypes: communicationTemplateSelectors.getCommunicationTemplatesTypes(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSmsTemplates: () => dispatch(communicationTemplateActions.fetchSmsTemplates()),
    fetchOrganisationSmsTemplates: ({ organisationId }) => dispatch(communicationTemplateActions.fetchOrganisationSmsTemplates({ organisationId }))
  }
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount () {
      this.props.fetchSmsTemplates()
      this.props.fetchOrganisationSmsTemplates({ organisationId: this.props.organisationId })
    }
  }),
  FullScreenLoader
)

export default enhance(CommunicationsSmsScreen)
