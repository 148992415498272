import React from 'react'
import _ from 'lodash'

import { Button, ButtonGroup } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

const CustomButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1)
}))

const CustomGroupButton = styled(Button)(({ theme }) => ({
  border: '1px solid',
  padding: '0px'
}))


export const CombinatorSelector = (props) => {
  const { options, value, handleOnChange } = props
  const onToggle = (nextVal) => { handleOnChange(nextVal) }
  return (
    <CustomButtonGroup
      variant='outlined'
    >
      {
        _.map(options, ({ name, label }) => (
          <CustomGroupButton
            size='small'
            color={name === value ? 'primary' : 'secondary'}
            variant={name === value ? 'contained' : 'outlined'}
            disableElevation
            onClick={() => onToggle(name)}
          >
            {label}
          </CustomGroupButton>
        ))
      }
    </CustomButtonGroup>
  )
}

export default CombinatorSelector
