import React from 'react'

import AutocompleteDropdown from './AutocompleteDropdown'
import SelectDropdown from './SelectDropdown'

const Dropdown = props => {
  const { autocomplete } = props

  return (
    autocomplete
    ? <AutocompleteDropdown {...props} />
    : <SelectDropdown {...props} />
  )
}

export default Dropdown
