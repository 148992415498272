import fp from 'lodash/fp'
import { connect } from 'react-redux'
import { goBack, push } from 'connected-react-router'
import { compose, withHandlers, withPropsOnChange } from 'recompose'

import CommunicationsMenuScreen from './CommunicationsMenuScreen'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'

const widgets = [
  { id: 'email', title: 'Communications Email', image: 'communicationsImg', icon: 'communicationsIcon' },
  { id: 'sms', title: 'Communications SMS', image: 'smsCommunicationImg' },
  { id: 'push', title: 'Communications Push', image: 'pushNotificationImg' },
]

const enhancer = compose(
  connect(state => ({
    organisationId: authSelectors.getSelectedOrganisation(state).id,
    userId: authSelectors.getActiveUserId(state),
    userRole: authSelectors.getRoleValue(state),
    widgets: widgets,
    pageTitle: platformSelectors.getPageTitle(state)('App Name - Communications'),
    canAccessAnnouncementManagement: authCombinedSelectors.canAccessAnnouncementManagement(state)
  })),
  withPropsOnChange(['canAccessAnnouncementManagement'], props => {
    if (props.canAccessAnnouncementManagement) {
      const announcementManagementTile = {
        id: 'announcement-management',
        title: 'Communications Announcement Management',
        image: 'announcementManagementImg',
      }
      return {
        widgets: fp.concat(props.widgets, announcementManagementTile)
      }
    }
  }),
  withHandlers({
    onBackClick: ({ dispatch }) => () => {
      dispatch(goBack())
    },
    onMenuItemClick: ({ dispatch }) => id => {
      dispatch(push(`/communications/${id}`))
    }
  })
)

export default enhancer(CommunicationsMenuScreen)
