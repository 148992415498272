import React from 'react'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { translations } from '../../config'

import style from './style'

const DrawerBar = props => {
  const { left, right, closeDrawer = () => {}, classes, className, primaryContrastColor } = props

  const barClass = classNames(
    classes.bar,
    className,
    {
      [classes.left]: left,
      [classes.right]: right
    }
  )

  return (
    <div className={barClass}>
      <IconButton
        onClick={closeDrawer}
      >
        <CloseIcon
          htmlColor={primaryContrastColor}
          titleAccess={translations('Close')} />
      </IconButton>
    </div>
  )
}

export default withStyles(style)(DrawerBar)
