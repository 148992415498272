import _ from 'lodash'

export const getFullName = (...args) => {
  let obj
  if (typeof args[0] === 'object') {
    obj = args[0]
  } else {
    let [ firstName, lastName ] = args
    obj = { firstName, lastName }
  }
  const { firstName = '', lastName = '' } = obj
  return _.startCase(`${firstName} ${lastName}`)
}

export default getFullName
