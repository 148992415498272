import _ from 'lodash'
import * as constants from './constants'
import { promiseReducer } from '../../util'
class MultipartUploadReducers {
  upload = promiseReducer()

  startUpload = (state, action) => {
    return {
      ...state,
      ..._.pick(action, ['fileType', 'fileName', 'file', 'uploadType']),
      uploadId: _.get(action, 'result.uploadId')
    }
  }

  completeUpload = (state, action) => {
    return {
      ...state,
      url: _.get(action, 'result.Location')
    }
  }

  hide = (state, action) => {
    if (true) {
      return {
        ...state,
        foo: 'bar'
      }
    } else {
      return state
    }
  }

  setProgress = (state, action) => {
    const { progress } = state
    const { partNumber, loaded } = action
    const newProgress = [...(progress || [])]
    newProgress[partNumber] = loaded
    return {
      ...state,
      progress: newProgress
    }
  }
}

export default new MultipartUploadReducers()
