"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.metricHeight = exports.imperialHeight = exports.heightConversion = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var imperialHeight = function imperialHeight(_ref) {
  var values = _ref.values;
  var imperialValue = values.patientheightimperial;
  var metricValue = values.patientheightmetric;
  if (!imperialValue && !metricValue) return;
  if (imperialValue) return imperialValue;
  var feet = parseFloat(metricValue) / 30.48;
  var inches = feet % 1 * 12;
  return {
    Feet: (feet - feet % 1).toFixed(0),
    Inches: inches.toFixed(0)
  };
};

exports.imperialHeight = imperialHeight;

var metricHeight = function metricHeight(_ref2) {
  var values = _ref2.values;
  var metricheight = values.patientheightmetric;
  var imperialheight = values.patientheightimperial;
  if (!metricheight && !imperialheight) return;
  if (metricheight) return metricheight;
  var conversion = heightConversion({
    feet: _lodash["default"].get(imperialheight, 'Feet'),
    inch: _lodash["default"].get(imperialheight, 'Inches')
  });
  return conversion;
};

exports.metricHeight = metricHeight;

var heightConversion = function heightConversion(_ref3) {
  var feet = _ref3.feet,
      inch = _ref3.inch;
  var ft = !_lodash["default"].isEmpty(feet) ? parseFloat(feet) : 0;
  var inches = !_lodash["default"].isEmpty(inch) ? parseFloat(inch) : 0;
  var convertInches = inches ? inches / 12 : 0;
  return _lodash["default"].floor((ft + convertInches) * 30.48).toFixed(0);
};

exports.heightConversion = heightConversion;