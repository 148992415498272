export default theme => ({
  center: {
    textAlign: 'center'
  },
  list: {
    margin: '0 auto',
    padding: 20,
    listStyle: 'none',
    maxWidth: 540
  },
  listItem: {
    margin: 0
  }
})
