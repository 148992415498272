import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { getFormError, SubmissionError, submit } from 'redux-form'

import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import Form, { FormBody } from '../../../../components/Form'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import { translations } from '../../../../config'
import { constants as reportConfigurationConstants } from '../../../../store/modules/reportConfiguration'
import getSchema from './schema'

const FORM_NAME = reportConfigurationConstants.COHORTS_FORM
const CohortForm = Form(FORM_NAME)

const CohortModal = ({
  otherCohortNames = [],
  onSubmit,
  initialValues = {},
  dismiss,
  title
}) => {
  const dispatch = useDispatch()

  const actions = useMemo(() => {
    return [
      {
        success: true,
        text: translations('Confirm'),
        primary: true,
        onClick: () => {
          dispatch(submit(FORM_NAME))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [onSubmit, dismiss])

  return (
    <BasicModalOverlay title={title} actions={actions}>
      <CohortForm onSubmit={onSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <FormBody
            schema={getSchema({
              otherCohortNames
            })}
            editing
            fullWidthFields
          />
        </ContentBoxBody>
      </CohortForm>
    </BasicModalOverlay>
  )
}

export default CohortModal
