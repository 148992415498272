import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { translations } from '../../../../config'

import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { FormError, FormBody } from '../../../../components/Form'

import { actions as userDetailsActions } from '../../../../store/modules/userDetails'
import { getSelectedUserOrganisationPreferences, getSelectedUserOrganisationPreferencesCount, getSelectedUserId } from '../../../../store/modules/userDetails/selectors'
import { getCurrentPlatformId } from '../../../../store/modules/platforms/selectors'
import { getCurrentUser } from '../../../../store/modules/auth/selectors'
import { change } from 'redux-form'

const FORM_ID = 'organisation-preferences'
const OrganisationPreferencesForm = Editable(FormContentBox(FORM_ID))

const OrganisationPreferences = (props) => {
  const {
    classes,
    editable,
    onSubmit
  } = props

  const [loadedPreferences, setLoadedPreferences] = useState([])
  const [loading, setLoading] = useState(true)

  const preferences = useSelector(getSelectedUserOrganisationPreferences)
  const preferencesCount = useSelector(getSelectedUserOrganisationPreferencesCount)
  const selectedUserId = useSelector(getSelectedUserId)
  const currentUser = useSelector(getCurrentUser)
  const platformId = useSelector(getCurrentPlatformId)

  useEffect(() => {
    if(loading && !_.isEmpty(preferences)) {
      setLoadedPreferences([...loadedPreferences, ...preferences])
      setLoading(false)
    }
  }, [preferences])

  useEffect(() => {
    dispatch(change(FORM_ID, 'preferences', loadedPreferences))
  }, [loadedPreferences])
 
  const dispatch = useDispatch()
  const changeOrgPreferences = ({ limit, offset }) => {
    const count = limit + offset
    const size = _.size(loadedPreferences)
    if(count > size && size < preferencesCount) {
      setLoading(true)
      dispatch(userDetailsActions.fetchUserOrganisationPreferences({ 
        userId: selectedUserId, 
        platformId, 
        limit: 100, 
        offset 
      }))
    }
  }

  const schema = [
    {
      id: 'preferences',
      field: 'TableList',
      props: {
        label: 'Organisation Preferences',
        name: 'preferences',
        required: false,
        headers: [
          { label: 'Organisation', id: 'organisation', type: 'label', width: '80%' },
          { label: 'Opt-out of email communications', id: 'disallowEmailComms', type: 'checkbox', width: '20%' }
        ],
        count: preferencesCount,
        onPageChange: changeOrgPreferences,
        onRowsPerPageChange: changeOrgPreferences,
        enableSearch: false
      }
    }
  ]

  return (
    <OrganisationPreferencesForm
      editable={editable}
      givenClass={classes.formContainer}
      onSubmit={onSubmit}
      boxName={translations('Organisation Preferences')}
      formId='organisation-preferences'
      initialValues={{ isOrgPreferences: true }}
      >
      <FormError givenClass={classes.submitError} />
      <FormBody
        schema={schema}
        layout={[
          'preferences:12'
        ]}
        globalClasses={{
          col: classes.columnStyle,
          row: classes.rowStyle
        }}
      />
    </OrganisationPreferencesForm>
  )
}

export default OrganisationPreferences