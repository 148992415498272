import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import VisuallyHidden from '@reach/visually-hidden'
import { Field as FormField } from 'react-final-form'

import Field from '../Field'
import FieldMeta from '../FieldMeta'
import Hint from '../Hint'
import Radio from './Radio'
import ErrorMessage from '../ErrorMessage'


/**
 * Radiolist 
 */
const Radiolist = (props) => {
  const {
    id,
    hint,
    link,
    label,
    options,
    disabled,
    disabledMessage,
    input: {
      name
    },
    emptyMessage
  } = props

  const isEmpty = _.isEmpty(options)
  const hintMessage = (disabled && disabledMessage) ? disabledMessage : hint

  return (
    <Field as='fieldset' data-name={name}>
      <VisuallyHidden><legend>{label}</legend></VisuallyHidden>
      <FieldMeta
        name={id}
        label={label}
        link={link}
        required={true}
        disabled={disabled}
      />
      {options.map(option => {

        const value = option.value || option.label

        return (
          <FormField
            id={`${id}.${value}`}
            name={name}
            type="radio"
            component={Radio}
            value={value}
            label={option.label}
            disabled={disabled}
            // Set the expectation of radio behaviour by always starting with a value selected
            defaultValue={options[0].value || options[0].label}
          />
        )
      })}
      {(isEmpty && emptyMessage && <ErrorMessage error={emptyMessage}/>)}
      {(hintMessage && <Hint message={hintMessage} />)}
    </Field>
  )
}

Radiolist.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** Array of Checkbox props  */
  options: PropTypes.array,
  /** If the field is disabled */
  disabled: PropTypes.bool,
  /** Message to display when the field is disabled */
  disabledMessage: PropTypes.string,
  /** Input props based from React Final Form
   * `name` - Used to associate label and input
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
}

Radiolist.defaultProps = {
  options: [],
  disabled: false
}

export default Radiolist