import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers } from 'recompose'
import { push } from 'connected-react-router'

import LoginScreen from './LoginScreen'
import CreateAccountSelectOrganisationModal from '../CreateAccountSelectOrganisationModal'
import { selectors as themeSelectors } from '../../../store/modules/themes'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import modalService from '../../../services/modalService'
import { environment, translations } from '../../../config'

const mapStateToProps = (state) => {
  const isLoading = themeSelectors.getIsLoadingCurrentTheme(state)
  const logoSrc = themeSelectors.getThemeLogo(state)
  const currentPlatform = platformSelectors.getCurrentPlatform(state)
  const organisations = _.get(currentPlatform, 'organisations', [])
  const {
    publicUserRegistration: isPublicUserRegistrationAllowed,
    companyAccountRequest
  } = _.get(currentPlatform, 'permissions') || {}

  const moreRegistrationEnabled = platformSelectors.getHasMoreRegistrationEnabled(state)
  const sabreRegistrationEnabled = platformSelectors.getHasSabreRegistrationEnabled(state)
  const allowReportShareAsAssessor = platformSelectors.getAllowReportShareAsAssessor(state)

  const loginMethods = _.reduce(organisations, (acc, org) => {
    const newAcc = [...acc]
    _.forEach(_.get(org, 'loginMethods', []), (loginMethod) => {
      if (!acc.includes(loginMethod)) {
        newAcc.push(loginMethod)
      }
    })
    return newAcc
  }, [])

  return {
    isLoading,
    logoSrc,
    isPublicUserRegistrationAllowed,
    isRequestCompanyAccountAllowed: companyAccountRequest,
    moreRegistrationEnabled,
    sabreRegistrationEnabled,
    organisations,
    loginMethods,
    pageTitle: platformSelectors.getPageTitle(state)('Sign in Title'),
    allowReportShareAsAssessor
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    goToCreateAccountForm: ({ dispatch }) => (org) => {
      dispatch(push(`/users/new/public/${_.get(org, 'id')}`))
      modalService.close()
    },
    goToRegister: ({ dispatch }) => () => {
      dispatch(push(`/register`))
      modalService.close()
    },
    openSSO: ({ dispatch }) => (ssoProviderId) => {
      const shouldClearLoginCache = environment.SSO_CLEAR_LOGIN_CACHE || false
      const src = `${environment.API_URL}/v1/saml/login/${ssoProviderId}`
      const successRoute = '/sso/success/'
      if (window.cordova) {
        const browserOpts = `${shouldClearLoginCache && 'clearcache=yes,'}location=no,footer=no,enableViewportScale=yes,hidenavigationbuttons=yes`
        const inAppBrowser = window.cordova.InAppBrowser.open(src, '_blank', browserOpts)
        inAppBrowser.addEventListener('loadstart', (params) => {
          if (params.url.includes(successRoute)) {
            const token = params.url.split(successRoute)[1]
            inAppBrowser.close()
            dispatch(push(`${successRoute}${token}`))
          }
        })
      } else {
        window.location = src
      }
    }
  }),
  withHandlers({
    showCreateAccountSelectOrganisationModal: ({
      organisations,
      goToCreateAccountForm,
      goToRegister,
      moreRegistrationEnabled,
      sabreRegistrationEnabled
    }) => () => {
      const len = organisations.length
      if (moreRegistrationEnabled || sabreRegistrationEnabled) {
        goToRegister()
        return
      }
      if (len < 1) {
        modalService.continue({
          title: translations('Login - Create Account No Organisations Error Modal Title')
        })
      } else if (len === 1) {
        goToCreateAccountForm(organisations[0])
      } else {
        modalService.open({
          component: CreateAccountSelectOrganisationModal,
          organisations,
          goToCreateAccountForm
        })
      }
    }
  })
)(LoginScreen)
