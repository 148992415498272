import React , { useCallback } from 'react'
import PropTypes from 'prop-types'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ReviewForm from '../../ReviewForm'
import P from '../../../../../components/P'
import { useDispatch } from 'react-redux'

import { translations } from '../../../../../config'
import { actions as submissionReportActions } from '../../../../../store/modules/submissionReports'
import { submit } from 'redux-form'
import { reviewStatuses } from '../../../../../store/modules/submissionReports/constants'


const FORM_NAME = 'integrationManagement/incomingReports/conditionsForm'

const ReviewModal = ({
  dismiss,
  title,
  message,
  receiverStatus,
  reviewStatus,
  submissionReportId,
  onReviewSuccess,
  isSubmitting
}) => {
  const dispatch = useDispatch()

  const onApprove = useCallback(({ comment, submissionReportId, dismiss, onReviewSuccess }) => {
    dispatch(submissionReportActions.submitApproval({ comment, submissionReportId, reviewStatus: reviewStatuses.APPROVE }))
      .then(() => onReviewSuccess())
    dismiss()
  }, [dispatch, submissionReportActions, reviewStatuses])
  
  const onReject = useCallback(({ comment, submissionReportId, dismiss, onReviewSuccess }) => {
    dispatch(submissionReportActions.submitRejection({ comment, submissionReportId, reviewStatus: reviewStatuses.REJECT }))
      .then(() => onReviewSuccess())
    dismiss()
  }, [dispatch, submissionReportActions, reviewStatuses])
  
  const onSubmitForm = useCallback(() => {
    dispatch(submit(FORM_NAME))
  }, [dispatch])

  const actions = [
        {
          text: translations('Cancel'),
          onClick: dismiss
        },
        {
          success: true,
          text: reviewStatus === reviewStatuses.APPROVE 
            ? translations('Report Review - Submit - APPROVE') 
            : translations('Report Review - Submit - REJECT'),
          submitting: isSubmitting,
          onClick: onSubmitForm, 
          primary: true
        }
      ]
  
  return (
    <BasicModalOverlay
      title={title}
      text={message}
      actions={actions}
    >
      <P style={{ marginBottom: 10 }} value={message} />
      <ReviewForm
        receiverStatus={receiverStatus}
        reviewStatus={reviewStatus}
        submissionReportId={submissionReportId}
        dismiss={dismiss}
        onApprove={onApprove}
        onReject={onReject}
        onReviewSuccess={onReviewSuccess}
        formName={FORM_NAME}
      />
    </BasicModalOverlay>
  )
}

ReviewModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  receiverStatus: PropTypes.string.isRequired,
  reviewStatus: PropTypes.string.isRequired,
  submissionReportId: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  actions: PropTypes.array.isRequired,
  formName: PropTypes.string.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onReviewSuccess: PropTypes.func.isRequired
}

export default ReviewModal
