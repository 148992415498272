import React from 'react'
import { Helmet } from 'react-helmet'

import ContentContainer from '../../../components/ContentContainer'
import SubHeader from '../../../components/SubHeader'
import VisuallyHidden from '../../../components/VisuallyHidden'
import ScreenHeading from '../../../components/ScreenHeading'
import BackBar from '../../../components/BackBar'
import ReportsLoadingBar from '../ReportsSearchScreen/ReportsLoadingBar'
import { hooks as platformHooks } from '../../../store/modules/platforms'

import ReportAuditTrailScreen from './ReportAuditTrailScreen/ReportAuditTrailScreen'

const ReportAuditTrail = () => {
  const pageTitle = platformHooks.usePageTitle('Report Audit Trail Title')

  return (
    <div>
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <ReportsLoadingBar />
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <ScreenHeading label={pageTitle.title} />
        )}
      />
      <ContentContainer>
        <ReportAuditTrailScreen />
      </ContentContainer>
    </div>
  )
}

export default ReportAuditTrail
