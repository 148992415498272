import _ from 'lodash'
import fp from 'lodash/fp'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class AccountDeletionRequestReducers {
  searchAccountDeletionRequestFresh = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchAccountDeletionRequestFreshPending(state, action)
      case SUCCESS:
        return this.searchAccountDeletionRequestFreshSuccess(state, action)
      case FAIL:
        return this.searchAccountDeletionRequestFreshFail(state, action)
      default:
        return state
    }
  }

  searchAccountDeletionRequestFreshPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  searchAccountDeletionRequestFreshSuccess = (state, action) => {
    const { total, results } = action.result
    return {
      ...state,
      total,
      page: 1,
      results,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  searchAccountDeletionRequestFreshFail = (state, action) => ({
    ...state,
    error: action.error.message,
    status: FAIL,
    action: undefined
  })

  searchAccountDeletionRequestNext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchAccountDeletionRequestNextPending(state, action)
      case SUCCESS:
        return this.searchAccountDeletionRequestNextSuccess(state, action)
      case FAIL:
        return this.searchUserNextFail(state, action)
      default:
        return state
    }
  }

  searchAccountDeletionRequestNextPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  searchAccountDeletionRequestNextSuccess = (state, action) => {
    const { total, results } = action.result
    const { page, results: currentResults } = state

    if (currentResults.length + results.length <= total) {
      return {
        ...state,
        total,
        page: page + 1,
        results: [...currentResults, ...results],
        status: SUCCESS,
        action: undefined,
        error: undefined
      }
    } else {
      return state
    }
  }

  searchAccountDeletionRequestNextFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

}

export default new AccountDeletionRequestReducers()
