import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, FormHelperText } from '@material-ui/core'

import { translations } from '../../../config'
import FileBrowserEnhancer from '../../FileBrowserEnhancer'
import InputLabel from '../../InputLabel'
import Button from '../../Button'
import P from '../../P'
import style from './style'

class FilePicker extends Component {
  renderFileBrowserContent = ({ onClick }) => {
    const { value, label, required, meta, disabled, disabledText, classes } = this.props
    const { touched, error } = meta || {}

    const formattedLabel = `${label}${required ? ' *': ''}`
    
    const fileName = ((
      value && (value.toString() === '[object File]' || value.type)
      ? value.name
      : value 
    ) || '').toString()

    const handleClick = (
      disabled
      ? undefined
      : (
        value
        ? this.handleDelete
        : onClick
      )
    )

    const buttonText = (
      (disabled && disabledText) ||
      translations(value ? 'FilePicker Delete' : 'FilePicker Upload')
    )

    return <FormControl>
      <InputLabel label={formattedLabel} children={formattedLabel} staticLabel />
      <div className={classes.container}>
        {value ? <P value={fileName} className={classes.fileName} /> : null}
        <Button
          children={buttonText}
          onClick={handleClick}
          color={'white'}
          raised
          variant='contained'
          disabled={disabled}
        />
      </div>
      <FormHelperText className={classes.errorText} role='alert'>
        {touched && error}
      </FormHelperText>
    </FormControl>
  }

  handleDelete = (e) => {
    const { onChange } = this.props
    onChange(null)
  }

  handleUpload = (e) => {
    const { onChange } = this.props
    onChange(e.target.files[0])
  }

  render () {
    const { accept } = this.props
    return <FileBrowserEnhancer
      onChange={this.handleUpload}
      renderContent={this.renderFileBrowserContent}
      accept={accept}
    />
  }
}

export default withStyles(style)(FilePicker)
