import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import media from "styled-media-query"

const StyledH2 = styled.h2`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;
  font-size: ${(props) => props.theme.type.heading2.small}px;
  ${media.greaterThan("medium")`
     margin-bottom: 20px;
     font-size: ${(props) => props.theme.type.heading2.medium}px;
  `}
  ${media.greaterThan("large")`
  font-size: ${(props) => props.theme.type.heading2.large}px;
`}
`
/**
 * A secondary heading
 */
const H2 = ({ children }) => {
  return <StyledH2>{children}</StyledH2>
}

H2.propTypes = {
  /** The value of the heading tag */
  children: PropTypes.node.isRequired,
}

export default H2
