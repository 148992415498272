const style = (theme) => ({
  formContainer: {
    display: 'flex',
    width: '100%',
    border: '1px solid black',
    padding: 10,
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    }
  },
  inputStyle: {
    color: 'black',
    width: '100%',
    paddingLeft: '1em',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '3em',
    }
  },
  button: {
    width: 30,
    height: 30,
    marginRight: 10,
    fontSize: 26,
    borderRadius: 2
  },
  editWrapper: {
    display: 'flex',
  },
  editWrapperHeading: {
    display: 'flex',
    marginRight: '2.5em'
  },
    arrowWrapper: {
    display: 'flex',
    minWidth: '80px',
    justifyContent: 'space-between'
  },
  arrowDown: {
    transform: 'translateY(100%)',
    width: 0,
    height: 10,
    borderTop: '8px solid black',
    borderRight: '8px solid transparent',
    borderLeft: '8px solid transparent'
  },
  arrowUp: {
    transform: 'translateY(100%)',
    width: 0,
    height: 10,
    borderRight: '8px solid transparent',
    borderLeft: '8px solid transparent',
    borderBottom: '8px solid black'
  },
  notVisible: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 13,
    width: '30px',
    height: '3px',
    backgroundColor: '#000',
    transform: 'rotate(45deg)'
  }
})

export default style
