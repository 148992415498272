import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import { getHasAllowRegisterEmailConfirmationEnabled } from '../platforms/selectors'

const getStatus = (data) => _.get(data, 'status')
export const communicationsTemplatesState = state => _.get(state, 'communicationTemplates.templates')
export const getOrganisationCommunicationTemplatesState = state => _.get(state, 'communicationTemplates.organisationTemplates')
export const getSystemCommunicationTemplatesState = state => _.get(state, 'communicationTemplates.systemTemplates')

export const getCommunicationTemplates = createSelector(
  [communicationsTemplatesState],
  (templates) => templates
)

export const getOrganisationCommunicationTemplates = createSelector(
  [getOrganisationCommunicationTemplatesState],
  (templates) => templates
)

export const isLoadingCommunicationTemplates = createSelector(
  [getCommunicationTemplates],
  (templates) => getStatus(templates) === PENDING
)

export const isLoadingOrganisationCommunicationTemplates = createSelector(
  [getOrganisationCommunicationTemplates],
  (templates) => getStatus(templates) === PENDING
)

export const isLoadingSystemCommunicationTemplates = createSelector(
  [getSystemCommunicationTemplatesState],
  (templates) => getStatus(templates) === PENDING
)

export const isLoadingAllTemplates = createSelector([
  isLoadingCommunicationTemplates,
  isLoadingOrganisationCommunicationTemplates,
  isLoadingSystemCommunicationTemplates
], (isLoadingCommunicationTemplates, isLoadingOrganisationTemplates, isLoadingSystemTemplates) => {
  return isLoadingCommunicationTemplates || isLoadingOrganisationTemplates || isLoadingSystemTemplates
})

export const getAllEntities = createSelector([
  getCommunicationTemplates,
  getOrganisationCommunicationTemplates,
  getSystemCommunicationTemplatesState
], ({ entities }, { entities: organisationEntitles }, { entities: systemEntitles }) => {
  return { ...entities, ...organisationEntitles, ...systemEntitles }
})

export const getSystemEntities = createSelector([
  getSystemCommunicationTemplatesState,
], ({ entities }) => {
  return entities
})

export const getCommunicationTemplatesTypes = createSelector(
  [
    communicationsTemplatesState,
    getHasAllowRegisterEmailConfirmationEnabled
  ],
  (templates, registerEmailEnabled) => {
    let templateTypes = _.keys(templates.byType)
    if (!registerEmailEnabled) {
      templateTypes = _.filter(templateTypes, (template) => template !== 'REGISTRATION')
    }
    return templateTypes
  }
)

export const getTemplatesIDsByType = createSelector(
  [
    communicationsTemplatesState,
    getSystemCommunicationTemplatesState,
    getOrganisationCommunicationTemplatesState,
    getHasAllowRegisterEmailConfirmationEnabled
  ],
  (communicationsTemplates, organisationTemplates, systemTemplates, registerEmailEnabled) => {
    let mergedTypes = {}
    const templateGroups = [communicationsTemplates, organisationTemplates, systemTemplates]
    _.forEach(templateGroups, (templates) => {
      _.forEach(_.get(templates, 'byType'), (templateIds, key) => {
        if (!registerEmailEnabled && key === 'REGISTRATION') return
        mergedTypes = _.set(mergedTypes, key, _.concat(_.get(mergedTypes, key, []), templateIds))
      })
    })
    return mergedTypes
  }
)

export const getDefaultAndSystemTemplatesIDsByType = createSelector(
  [
    communicationsTemplatesState,
    getSystemCommunicationTemplatesState,
    getHasAllowRegisterEmailConfirmationEnabled
  ],
  (communicationsTemplates, systemTemplates, registerEmailEnabled) => {
    let mergedTypes = {}
    const templateGroups = [communicationsTemplates, systemTemplates]
    _.forEach(templateGroups, (templates) => {
      _.forEach(_.get(templates, 'byType'), (templateIds, key) => {
        if (!registerEmailEnabled && key === 'REGISTRATION') return
        mergedTypes = _.set(mergedTypes, key, _.concat(_.get(mergedTypes, key, []), templateIds))
      })
    })
    return mergedTypes
  }
)

export const getOrganisationTemplatesIDsByType = createSelector(
  [
    getOrganisationCommunicationTemplatesState,
    getHasAllowRegisterEmailConfirmationEnabled
  ],
  (organisationTemplates, registerEmailEnabled) => {
    let organisationTemplatesIDs = {}

    _.forEach(_.get(organisationTemplates, 'byType'), (templateIds, key) => {
      if (!registerEmailEnabled && key === 'REGISTRATION') return
      organisationTemplatesIDs = _.set(organisationTemplatesIDs, key, _.concat(_.get(organisationTemplatesIDs, key, []), templateIds))
    })
    return organisationTemplatesIDs
  }
)

export const getOrganisationAcknowledgementTemplates = createSelector(
  [
    getOrganisationCommunicationTemplatesState
  ],
  (communicationsTemplates) => {
    const acknowledgementIds = _.get(communicationsTemplates, 'byType.ACKNOWLEDGEMENT')
    const templates = _.map(acknowledgementIds, (id) => {
      return _.get(communicationsTemplates, `entities.${id}`)
    })
    return _.compact(templates)
  }
)

export const getDefaultTemplatesByType = createSelector(
  [
    getCommunicationTemplates,
    getOrganisationCommunicationTemplates,
    getSystemCommunicationTemplatesState,
    getCommunicationTemplatesTypes
  ],
  (communicationsTemplates, organisationTemplates, systemTemplates, types) => {
    const defaultTemplates = _.reduce(types, (memo, type) => {
      const fallbackTemplate = _.get(communicationsTemplates, `byType.${type}.0`)

      const systemDefaultTemplate = _.find(systemTemplates.entities, (template) => {
        const isDefault = _.get(template, 'isDefault') === true
        const templateType = _.get(template, 'emailTemplate.type') || _.get(template, 'smsTemplate.type') || _.get(template, 'pushTemplate.type')
        const isTemplateType = templateType === type
        return isDefault && isTemplateType
      })

      const organisationDefaultTemplate = _.find(organisationTemplates.entities, (template) => {
        const isDefault = _.get(template, 'isDefault') === true
        const templateType = _.get(template, 'emailTemplate.type') || _.get(template, 'smsTemplate.type') || _.get(template, 'pushTemplate.type')
        const isTemplateType = templateType === type
        return isDefault && isTemplateType
      })

      memo[type] = _.get(organisationDefaultTemplate, 'id') ||  _.get(systemDefaultTemplate, 'id') || fallbackTemplate
      return memo
    }, {})
    return defaultTemplates
  }
)

export const getDefaultPlatformTemplatesByType = createSelector(
  [getCommunicationTemplates],
  (communicationsTemplates) => {
    const entities = _.get(communicationsTemplates, 'entities')
    const templates = _.reduce(_.get(communicationsTemplates, 'byType'), (memo, type, key) => {
      memo[key] = _.get(entities, `${_.head(type)}`)
      return memo
    }, {})
    return templates
  }
)

export const getOrganisationEmailTemplatesForSchema = createSelector(
  [
    getOrganisationAcknowledgementTemplates
  ],
  (acknowledgementTemplates) => {
    return _.map(acknowledgementTemplates, (template) => {
      return {
        value: _.get(template, 'id'),
        label: _.get(template, 'name')
      }
    })
  }
)
