import { connect } from 'react-redux'
import { compose, withHandlers, withProps } from 'recompose'
import HospitalsUKLookupInputModal from './HospitalsUKLookupInputModal'
import { selectors as hospitalsSelectors, actions as hospitalsActions } from '../../store/modules/hospitals'

const mapStateToProps = state => ({
  results: hospitalsSelectors.getHospitalsForSearchResults(state),
  noResults: !hospitalsSelectors.getHasResults(state),
  hasMore: hospitalsSelectors.getHasMore(state) && !hospitalsSelectors.getIsLoading(state),
  error: hospitalsSelectors.getError(state)
})

const connectedComponent = compose(
  connect(mapStateToProps),
  withProps({
    searchPlaceholder: 'Search Hospitals'
  }),
  withHandlers({
    onSearch: ({ dispatch, onComplete }) => ({ query }) => {
      dispatch(hospitalsActions.searchHospitals({ query }))
    },
    onSearchNext: ({ dispatch, onComplete }) => ({ query }) => {
      dispatch(hospitalsActions.searchHospitalsNext({ query }))
    },
    onChange: ({ dispatch, onChange, ...props }) => (value) => {
      onChange(value)
    },
    closeModal: ({ dispatch, onClose, modalIndex }) => () => {
      onClose({ modalIndex })
      dispatch(hospitalsActions.resetStore())
    }
  })
)(HospitalsUKLookupInputModal)

export default connectedComponent
