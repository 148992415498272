import { ellipsis } from 'polished'

export default theme => ({
  legendContainer: {
  },
  entry: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
    padding: '2px 0'
  },
  colorCircle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    marginRight: 10
  },
  text: {
    ...ellipsis('100%'),
    minWidth: 0,
    flexShrink: 1,
    fontSize: 14
  },
  hidden: {
    opacity: 0.2
  }
})
