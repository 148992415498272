"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var computedValue = _interopRequireWildcard(require("../../../formHelpers/computedValue"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'batchnumber',
    field: 'Input',
    props: {
      shrink: true,
      required: true,
      name: 'batchnumber',
      label: 'Acknowledgement batch number',
      validate: [validators.maxLength(100, 'Field')],
      maxLength: 100
    }
  }, {
    id: 'batchsenderidentifier',
    field: 'Input',
    props: {
      shrink: true,
      required: true,
      name: 'batchsenderidentifier',
      label: 'Acknowledgement batch sender identifier',
      validate: [validators.maxLength(60, 'Field')],
      maxLength: 60
    }
  }, {
    id: 'batchreceiveridentifier',
    field: 'Input',
    props: {
      shrink: true,
      required: true,
      name: 'batchreceiveridentifier',
      label: 'Acknowledgement batch receiver identifier',
      validate: [validators.maxLength(60, 'Field')],
      maxLength: 60
    }
  }, {
    id: 'batchtransmissiondate',
    field: 'Date',
    computedValue: computedValue.setDateNow('batchtransmissiondate'),
    props: {
      shrink: true,
      disabled: true,
      name: 'batchtransmissiondate',
      label: 'Acknowledgement date of batch transmission',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'icsrbatchnumber',
    field: 'Input',
    props: {
      shrink: true,
      disabled: true,
      name: 'icsrbatchnumber',
      label: 'ICSR batch number',
      required: false,
      validate: [validators.maxLength(100, 'Field')],
      maxLength: 100
    }
  }, {
    id: 'localmessagenumber',
    field: 'Input',
    props: {
      shrink: true,
      disabled: true,
      name: 'localmessagenumber',
      label: 'Acknowledgement local message number',
      required: false,
      validate: [validators.maxLength(100, 'Field')],
      maxLength: 100
    }
  }, {
    id: 'icsrbatchtransmissiondate',
    field: 'Date',
    computedValue: computedValue.setDateNow('icsrbatchtransmissiondate'),
    props: {
      shrink: true,
      disabled: true,
      name: 'icsrbatchtransmissiondate',
      label: 'Date of ICSR batch transmission',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'transmissionacknowledgmentcode',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'transmissionacknowledgmentcode',
      label: 'Transmission acknowledgement code',
      options: [{
        value: 'AA',
        label: 'Application Acknowledgement Accept'
      }, {
        value: 'AE',
        label: 'Application Acknowledgment Error'
      }, {
        value: 'AR',
        label: 'Application Acknowledgment Reject'
      }],
      required: true
    }
  }, {
    id: 'batchvalidationerror',
    computedDisabled: function computedDisabled(_ref) {
      var transmissionacknowledgmentcode = _ref.transmissionacknowledgmentcode;
      return transmissionacknowledgmentcode !== 'AR';
    },
    field: 'Input',
    props: {
      shrink: true,
      name: 'batchvalidationerror',
      label: 'Batch validation error',
      required: false,
      validate: [validators.maxLength(250, 'Field')],
      maxLength: 250
    }
  }, {
    id: 'icsrmessagenumber',
    field: 'Input',
    props: {
      shrink: true,
      required: true,
      name: 'icsrmessagenumber',
      label: 'ICSR message number',
      validate: [validators.maxLength(100, 'Field')],
      maxLength: 100
    }
  }, {
    id: 'localreportnumber',
    field: 'Input',
    props: {
      shrink: true,
      required: false,
      name: 'localreportnumber',
      label: 'Local report number',
      validate: [validators.maxLength(100, 'Field')],
      maxLength: 100
    }
  }, {
    id: 'icsrmessagesenderidentifier',
    field: 'Input',
    props: {
      shrink: true,
      required: false,
      name: 'icsrmessagesenderidentifier',
      label: 'ICSR message ACK receiver',
      validate: [validators.maxLength(60, 'Field')],
      maxLength: 60
    }
  }, {
    id: 'icsrmessagereceiveridentifier',
    field: 'Input',
    props: {
      shrink: true,
      required: false,
      name: 'icsrmessagereceiveridentifier',
      label: 'ICSR message ACK sender',
      validate: [validators.maxLength(60, 'Field')],
      maxLength: 60
    }
  }, {
    id: 'icsrcreationdate',
    field: 'Date',
    props: {
      shrink: true,
      disabled: true,
      name: 'icsrcreationdate',
      label: 'Date of ICSR message creation',
      required: false,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'icsracknowledgementcode',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'icsracknowledgementcode',
      label: 'Acknowledgement code for a ICSR message',
      options: [{
        value: 'CA',
        label: 'Commit Accept'
      }, {
        value: 'CR',
        label: 'Commit Reject'
      }],
      required: true
    }
  }, {
    id: 'errormessage',
    field: 'Input',
    computedDisabled: function computedDisabled(_ref2) {
      var icsracknowledgementcode = _ref2.icsracknowledgementcode;
      return icsracknowledgementcode !== 'CR';
    },
    props: {
      shrink: true,
      name: 'errormessage',
      label: 'Error / Warning message or comment',
      required: false,
      validate: [validators.maxLength(250, 'Field')],
      maxLength: 250
    }
  }];
};

exports.getFormSchema = getFormSchema;