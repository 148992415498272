import * as validators from '../../../validators'
import { countriesFullNamesValues } from '../../../config'

const smallTextLengthValidator = validators.maxLength(200, 'Text')

const schema = ({ allFieldsMandatory = false }) => {
  return [{
    id: 'address',
    field: 'Input',
    props: {
      label: 'Address Line 1',
      name: 'address',
      validate: [smallTextLengthValidator],
      required: allFieldsMandatory,
      shrink: true
    }
  },
  {
    id: 'addressLineTwo',
    field: 'Input',
    props: {
      label: 'Address Line 2',
      name: 'addressLineTwo',
      validate: [smallTextLengthValidator],
      required: allFieldsMandatory,
      shrink: true
    }
  },
  {
    id: 'city',
    field: 'Input',
    props: {
      label: 'City',
      name: 'city',
      validate: [smallTextLengthValidator],
      required: allFieldsMandatory,
      shrink: true
    }
  },
  {
    id: 'county',
    field: 'Input',
    props: {
      label: 'County',
      name: 'county',
      validate: [smallTextLengthValidator],
      required: allFieldsMandatory,
      shrink: true
    }
  },
  {
    id: 'postalCode',
    field: 'Input',
    props: {
      label: 'Postcode',
      name: 'postalCode',
      validate: [validators.maxLength(15, 'Text')],
      required: allFieldsMandatory,
      shrink: true
    }
  },
  {
    id: 'country',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'country',
      label: 'Country',
      autocomplete: true,
      options: countriesFullNamesValues,
      required: allFieldsMandatory
    }
  }]
}

export default schema
