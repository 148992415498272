import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { actions as appActions } from '../../store/modules/app'
import Header from './Header'
import { selectors as themeSelectors } from '../../store/modules/themes'
import { selectors as authSelectors } from '../../store/modules/auth'
import { translations } from '../../config'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const openMenu = () => dispatch(appActions.toggleSideMenu({ isOpen: true }))
  const logoSrc = themeSelectors.getThemeLogo(state)
  const logoAlt = themeSelectors.getThemeLogoAlt(state)
  const logoLink = themeSelectors.getThemeLogoLink(state)
  const headerContrastColor = themeSelectors.getHeaderContrastColor(state)
  const headerTitle = themeSelectors.getThemeHeaderTitle(state)
  const isLoggedIn = authSelectors.getIsLoggedIn(state)
  const hasPendingChanges = authSelectors.getUserHasPendingChanges(state)
  const pendingChangesOrganisations = authSelectors.getUserPendingChangesString(state)

  return {
    ...ownProps,
    logoSrc,
    logoAlt,
    logoLink,
    openMenu,
    headerContrastColor,
    headerTitle,
    isLoggedIn,
    hasPendingChanges,
    pendingChangesOrganisations
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Header)
