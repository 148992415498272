import React from 'react'
import fp from 'lodash/fp'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import { useShallowEqualSelector } from '../../../hooks'
import { selectors as fileManagerSelectors } from '../../../store/modules/fileManager'
import LinearProgress from '@material-ui/core/LinearProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { translations } from '../../../config'
import { tableManagementReducer, statusReducer } from './reducers'
import * as tableActions from './actions'
import { sortStates } from './enums'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { styled, makeStyles } from '@material-ui/core/styles'
import Heading from '../../../components/Heading'
import FileManagerItem from './fileManagerItem'

const DrawerBar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  height: '5%'
}))

const HeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey['200'],
  textTransform: 'uppercase',
  color: theme.palette.grey['900'],
  fontSize: '13px',
  fontWeight: 'bold',
  alignItems: 'center',
  borderBottom: 'none'
}))

const ParentCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none'
}))

const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%'
}))
const useTableStyles = makeStyles((_props) => ({
  container: {
    height: '85%'
  },
  pagination: {
    height: '6%',
  }
})
);

const StyledContainer = styled('div')(({theme}) => ({
  width: 500,
  height: '100%',
  [theme.breakpoints.down('xs')]: {
    width: '100vw'
  }
}))

const ProgressManager = ({ onClose }) => {
  const files = useShallowEqualSelector(fileManagerSelectors.getFiles)
  const total = useShallowEqualSelector(fileManagerSelectors.getTotalFiles)
  const tableStyles = useTableStyles()

  const [tableState, dispatchTableAction] = React.useReducer(tableManagementReducer, {
    sort: {
      order: 'none',
      field: 'fileName'
    },
    pages: {
      currentPage: 0,
      size: 15
    }
  })


  const EnhancedTableHead = (props) => {
    const { onRequestSort } = props;
    const createSortHandler = property => () => {
      onRequestSort(property);
    };
    const headCells = [
      {
        id: 'fileName',
        label: 'File'
      },
      {
        id: 'details',
        label: 'Details'
      },
      {
        id: 'status',
        label: 'Status'
      },
      {
        id: 'attachments',
        label: ''
      }
    ];
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => {
            return (<HeadCell
              key={headCell.id}
              sortDirection={tableState.sort.field === headCell.id ? tableState.sort.order : false}
            >
              <TableSortLabel
                active={tableState.sort.field === headCell.id && tableState.sort.order !== sortStates.NONE}
                direction={tableState.sort.field === headCell.id ? tableState.sort.order : undefined}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </HeadCell>)
          })
          }
        </TableRow>
      </TableHead>
    );
  }

  const sortedFiles = React.useMemo(() => {
    return fp.compose(
      fp.slice(tableState.pages.currentPage * tableState.pages.size, tableState.pages.currentPage * tableState.pages.size + tableState.pages.size),
      files => tableState.sort.order === sortStates.NONE ? fp.toArray(files) : fp.orderBy([tableState.sort.field], [tableState.sort.order], files)
    )(files)
  }, [files, tableState.sort.order, tableState.pages.currentPage, tableState.pages.size, tableState.sort.field])

  return (
    <StyledContainer>
      <DrawerBar>
        <Heading variant='h2' component='h2'>{translations('File Manager')}</Heading>
        <IconButton
          onClick={onClose}
        >
          <CloseIcon
            titleAccess={translations('Close')} />
        </IconButton>
      </DrawerBar>
      <TableContainer component={Paper} classes={{ root: tableStyles.container }}>
        <StyledTable stickyHeader aria-label='Download Table' >
          <EnhancedTableHead
            order={tableState.sort.order}
            orderBy={tableState.sort.field}
            onRequestSort={tableActions.handleRequestSort(dispatchTableAction, tableState)}
            rowCount={total}
          >
            <TableRow>
              <TableCell>{translations('File')}</TableCell>
              <TableCell align='right'>{translations('Status')}</TableCell>
            </TableRow>
          </EnhancedTableHead>
          <TableBody>
            {fp.map((file) => {
              const statusData = statusReducer(file)
              return ( <FileManagerItem file={file} statusData={statusData} /> )
            }, sortedFiles)
            }
          </TableBody>
        </StyledTable>
      </TableContainer>
      <TablePagination
        classes={{ root: tableStyles.pagination }}
        rowsPerPageOptions={[15, 30, 45]}
        component='div'
        count={total}
        rowsPerPage={tableState.pages.size}
        page={tableState.pages.currentPage}
        onChangePage={tableActions.handleChangePage(dispatchTableAction)}
        onChangeRowsPerPage={tableActions.handleChangeRowsPerPage(dispatchTableAction)}
      />
    </StyledContainer>

  )
}


export default ProgressManager

