import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import { Collapse, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { translations } from '../../config'

const Banner = ({ title, description, withClose, onClose, closed, classes }) => {
  const [isClosed, stateSetClosed] = useState(false)
  const setClosed = onClose || stateSetClosed

  return (
    <Collapse in={!isClosed && !closed}>
      <div className={classes.banner} aria>
        <div className={classes.bannerContent}>
          {title && <Typography variant='h6' component='h1'>{title}</Typography>}
          {description && <Typography variant='body2' component='h3'>{description}</Typography>}
        </div>
        {withClose && (
          <IconButton onClick={() => setClosed(true)} className={classes.closeButton}>
            <CloseIcon titleAccess={translations('Dismiss')} />
          </IconButton>
        )}
      </div>
    </Collapse>
  )
}

Banner.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string
}

export default withStyles(style)(Banner)
