import React from 'react'

import { styled } from '@material-ui/core/styles'
import { Grid, Button, Typography } from '@material-ui/core'
import Heading from '../../../../components/Heading'
import { translations } from '../../../../config'

const Container = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  minHeight: theme.spacing(6)
}))

const ContentWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexGrow: 0
}))

const RowItem = ({ text, onEdit }) => {
  return (
    <Container container>
      <ContentWrapper
        item xs={11}
        justify='flex-start'
      >
        <Typography variant='body2' component='span'>
          {text}
        </Typography>
      </ContentWrapper>
      <ContentWrapper item xs={1} justify='center' alignContent='center'>
        {onEdit && (
          <Button onClick={onEdit}>
            {translations('Edit')}
          </Button>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default RowItem
