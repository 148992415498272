const styles = theme => ({
  text: {
    whiteSpace: 'pre-line',
    overflowY: 'auto'
  },
  title: {
    marginBottom: 10
  },
  container: {
    width: '48%',
    padding: '20px 20px 10px 20px'
  },
  buttonsContainer: {
    marginTop: 20
  },
  automaticActionTimer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  }
})

export default styles
