import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  error: undefined,
  query: undefined,
  status: undefined,
  results: [],
  page: 1,
  size: 10
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SEARCH_EDQM:
      return reducers.searchDrugDictionary(state, action)
    case constants.SEARCH_EDQM_NEXT:
      return reducers.searchDrugDictionaryNext(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
