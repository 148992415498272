"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validatorForMOP = exports.validatorForIndustry = exports.validatorForHPRAndMOP = exports.validatorForHPR = exports.validatorFor = exports.match = exports.joinStreetNameAndAddress = exports.isAudiencePublic = exports.isAudienceIndustry = exports.isAudienceHealthCare = exports.getProfessionOptions = exports.computeReporterCountry = exports.allAddressFieldsAreRequiredForPublicDetails = exports.allAddressFieldsAreRequiredForPrimarySource = exports.addressValidationForPrimarySource = exports.addressValidationForPersonalDetails = exports.addressFieldsIncompleteForPrimarySource = exports.addressFieldsIncompleteForPersonalDetails = exports.MOPpatientValidation = exports.HPRpatientValidation = exports.HPRandMOPAddressValidation = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var computedHidden = _interopRequireWildcard(require("./computedHidden"));

var computedValue = _interopRequireWildcard(require("./computedValue"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var AUDIENCE_CONSTANTS = {
  INDUSTRY: 1,
  HEALTHCARE_PROFESSIONAL: 2,
  MEMBER_OF_PUBLIC: 3
};

var audienceIs = function audienceIs(audienceId) {
  return function (values) {
    var audienceIdFromDetails = _fp["default"].compose(_fp["default"].find(_fp["default"].identity), _fp["default"].at(['audienceid', '_parentFormValues.audienceid']))(values);

    return audienceIdFromDetails === audienceId;
  };
};

var isAudienceIndustry = audienceIs(AUDIENCE_CONSTANTS.INDUSTRY);
exports.isAudienceIndustry = isAudienceIndustry;
var isAudienceHealthCare = audienceIs(AUDIENCE_CONSTANTS.HEALTHCARE_PROFESSIONAL);
exports.isAudienceHealthCare = isAudienceHealthCare;
var isAudiencePublic = audienceIs(AUDIENCE_CONSTANTS.MEMBER_OF_PUBLIC);
exports.isAudiencePublic = isAudiencePublic;
var predicates = {
  MOP: isAudiencePublic,
  HCP: isAudienceHealthCare,
  INDUSTRY: isAudienceIndustry,
  DEFAULT: _fp["default"].identity
};
var match = computedValue.match(predicates);
exports.match = match;

var validatorFor = function validatorFor(audienceId) {
  return function (validator) {
    return function (value, formValues) {
      var selectedAudiences = _fp["default"].castArray(audienceId);

      var isCorrectAudience = _fp["default"].compose(function (audienceId) {
        return _lodash["default"].includes(selectedAudiences, audienceId);
      }, _fp["default"].find(_fp["default"].identity), _fp["default"].at(['audienceid', '_parentFormValues.audienceid']), computedValue.convertImmutable)(formValues);

      if (isCorrectAudience) {
        return validator(value, computedValue.convertImmutable(formValues));
      }
    };
  };
};

exports.validatorFor = validatorFor;
var validatorForIndustry = validatorFor(AUDIENCE_CONSTANTS.INDUSTRY);
exports.validatorForIndustry = validatorForIndustry;
var validatorForMOP = validatorFor(AUDIENCE_CONSTANTS.MEMBER_OF_PUBLIC);
exports.validatorForMOP = validatorForMOP;
var validatorForHPR = validatorFor(AUDIENCE_CONSTANTS.HEALTHCARE_PROFESSIONAL);
exports.validatorForHPR = validatorForHPR;
var validatorForHPRAndMOP = validatorFor([AUDIENCE_CONSTANTS.HEALTHCARE_PROFESSIONAL, AUDIENCE_CONSTANTS.MEMBER_OF_PUBLIC]);
exports.validatorForHPRAndMOP = validatorForHPRAndMOP;
var MOPpatientValidation = {
  fields: ['patientinitial', 'patientonsetage', 'patientsex', 'patientbirthdate'],
  error: 'Error - Minimum of 1 patient identifier needed - Patient initials, Patient sex, Patient age at time of the side effect'
};
exports.MOPpatientValidation = MOPpatientValidation;
var HPRpatientValidation = {
  fields: ['patientinitial', 'patientgpmedicalrecordnumb', 'patientonsetage', 'patientsex', 'patientbirthdate'],
  error: 'Minimum of 1 patient identifier needed - Patient initials, Patient sex, Patient age at time of the side effect or Local Patient Identification Number'
};
exports.HPRpatientValidation = HPRpatientValidation;
var personalDetailsAddressfieldIds = [['personaldetailsemailaddress'], ['reporteraddresshousenameornumber', 'reporteraddressline1', 'reporteraddressline2', 'reportercity_primarysource', 'reporterstate_primarysource', 'reporterpostcode_primarysource']];
var primarySourceAddressfieldIds = [['reporteremailaddress'], ['reporteraddresshousenameornumber', 'reporteraddressline1', 'reporteraddressline2', 'reportercity', 'reporterstate', 'reporterpostcode']];

var HPRandMOPAddressValidation = function HPRandMOPAddressValidation(fields) {
  return {
    fields: fields,
    error: "One of either Email or Address is required"
  };
};

exports.HPRandMOPAddressValidation = HPRandMOPAddressValidation;
var addressValidationForPersonalDetails = HPRandMOPAddressValidation(personalDetailsAddressfieldIds);
exports.addressValidationForPersonalDetails = addressValidationForPersonalDetails;
var addressValidationForPrimarySource = HPRandMOPAddressValidation(primarySourceAddressfieldIds);
exports.addressValidationForPrimarySource = addressValidationForPrimarySource;

var joinStreetNameAndAddress = _fp["default"].compose(_fp["default"].join(' '), _fp["default"].compact, _fp["default"].at(['reporteraddresshousenameornumber', 'reporteraddressline1', 'reporteraddressline2']));

exports.joinStreetNameAndAddress = joinStreetNameAndAddress;

var isAllNil = _fp["default"].all(_fp["default"].isNil);

var isSomeNil = _fp["default"].some(_fp["default"].isNil);

var allAddressFieldsAreRequired = function allAddressFieldsAreRequired(fields) {
  return function (values) {
    var _fields = _slicedToArray(fields, 2),
        firstArray = _fields[0],
        addressFields = _fields[1];

    var email = _fp["default"].first(firstArray);

    var getAddressValues = _fp["default"].at(addressFields);

    if (!_fp["default"].compose(_fp["default"].isUndefined, _fp["default"].prop(email))(values)) {
      return false;
    }

    if (!_fp["default"].compose(isAllNil, getAddressValues)(values)) {
      return true;
    }

    if (_fp["default"].compose(isAllNil, getAddressValues)(values)) {
      return false;
    }

    if (_fp["default"].compose(isSomeNil, getAddressValues)(values)) {
      return true;
    }

    return false;
  };
};

var allAddressFieldsAreRequiredForPublicDetails = allAddressFieldsAreRequired(personalDetailsAddressfieldIds);
exports.allAddressFieldsAreRequiredForPublicDetails = allAddressFieldsAreRequiredForPublicDetails;
var allAddressFieldsAreRequiredForPrimarySource = allAddressFieldsAreRequired(primarySourceAddressfieldIds);
exports.allAddressFieldsAreRequiredForPrimarySource = allAddressFieldsAreRequiredForPrimarySource;

var addressFieldsIncompleted = function addressFieldsIncompleted(fields) {
  return function (values) {
    var _fields2 = _slicedToArray(fields, 2),
        addressFields = _fields2[1];

    var getAddressValues = _fp["default"].at(addressFields);

    if (_fp["default"].compose(isSomeNil, getAddressValues)(values)) {
      return true;
    }

    return false;
  };
};

var addressFieldsIncompleteForPersonalDetails = addressFieldsIncompleted(personalDetailsAddressfieldIds);
exports.addressFieldsIncompleteForPersonalDetails = addressFieldsIncompleteForPersonalDetails;
var addressFieldsIncompleteForPrimarySource = addressFieldsIncompleted(primarySourceAddressfieldIds);
exports.addressFieldsIncompleteForPrimarySource = addressFieldsIncompleteForPrimarySource;

var computeReporterCountry = _fp["default"].compose(_fp["default"].find(_fp["default"].identity), _fp["default"].at(['primarysourcecountry', '_parentFormValues.primarysourcecountry']));

exports.computeReporterCountry = computeReporterCountry;

var getProfessionOptions = function getProfessionOptions(values, _parentFormValues, _org, _formview, professions) {
  var professionIsTypeOf = _lodash["default"].partial(computedHidden.fieldIs, 'type');

  var byAudienceProfession = _fp["default"].cond([[function () {
    return isAudienceHealthCare(values);
  }, professionIsTypeOf(['Health Care Professional', 'Company'])], [function () {
    return isAudiencePublic(values);
  }, professionIsTypeOf(['Public', 'Parent'])], [_fp["default"].identity, _fp["default"].T]]);

  return _fp["default"].compose(_fp["default"].map(function (profession) {
    return {
      value: profession.id,
      label: profession.name,
      professionGroupId: profession.professionGroupId,
      professionGroup: profession.professionGroup
    };
  }), _fp["default"].filter(byAudienceProfession))(professions);
};

exports.getProfessionOptions = getProfessionOptions;