const styles = theme => {
  const { headerHeight, subheaderHeight, bottomBarHeight, footerHeight } = theme
  return {
    container: {
      height: `calc(100vh - ${headerHeight + footerHeight}px)`,
      position: 'relative',
      overflow: 'hidden'
    },
    scollContainerInModal: {
      height: `100%`,
      overflowY: 'auto'
    },
    scrollContainer: {
      height: `100%`,
      overflowY: 'auto'
    },
    iframe: {
      width: '100%',
      height: '100vh',
      flex: 1,
      border: 0
    }
  }
}

export default styles
