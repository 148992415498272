// Connects field to redux-forms
import FieldWrapper from './Wrappers/FieldWrapper'
import NullableWrapper from './Wrappers/NullableWrapper'
import ImperialWrapper from './Wrappers/ImperialWrapper'

// Each form input
import InputComponent from './Input'
import DropdownComponent from './Dropdown'
import CheckboxComponent from './Checkbox'
import IconCheckboxComponent from './IconCheckbox'
import ChecklistComponent from './Checklist'
import RadiolistComponent from './Radiolist'
import CounterComponent from './Counter'
import PhoneNumberComponent from './PhoneNumber'
import DateComponent from './Date'
import TimeComponent from './Time'
import DateTimeComponent from './DateTime'
import PasswordConfirmComponent from './PasswordConfirm'
import SelectUserComponent from './SelectUser'
import TimePickerComponent from './TimePicker'
import DatePickerComponent from './DatePicker'
import DateTimePickerComponent from './DateTimePicker'
import HiddenComponent from './Hidden'
import EditableDropdownComponent from './EditableDropdown'
import ImagePickerComponent from './ImagePicker'
import ColorPickerComponent from './ColorPicker'
import FilePickerComponent from './FilePicker'
import WysiwygComponent from './Wysiwyg'
import AutocompleteComponent from './Autocomplete'
import OptionArrayComponent from './OptionArray'
import OptionSelectorComponent from './OptionSelector'
import MultiLanguageInputComponent from './MultiLanguageInput'
import TableListComponent from './TableList'
import OrganisationLookupInputComponent from './OrganisationLookupInput'
import OrganisationAssociationLookupComponent from './OrganisationAssociationLookup'
import TerminologyLookupDropdownComponent from './TerminologyLookupDropdown'
import {
    MeddraAsyncLookUp as MeddraAsyncLookUpComponent,
    DictionaryEntriesAsyncLookUp as DictionaryEntriesAsyncLookupComponent
} from './asyncLookup'

// Wrap and export fields
export const Input = FieldWrapper(NullableWrapper(ImperialWrapper(InputComponent)))
export const Dropdown = FieldWrapper(NullableWrapper(DropdownComponent))
export const Checkbox = FieldWrapper(CheckboxComponent)
export const IconCheckbox = FieldWrapper(IconCheckboxComponent)
export const Checklist = FieldWrapper(ChecklistComponent)
export const Radiolist = FieldWrapper(RadiolistComponent)
export const Counter = FieldWrapper(CounterComponent)
export const PhoneNumber = FieldWrapper(PhoneNumberComponent)
export const Date = FieldWrapper(NullableWrapper(DateComponent))
export const Time = FieldWrapper(TimeComponent)
export const DateTime = FieldWrapper(DateTimeComponent)
export const PasswordConfirm = FieldWrapper(PasswordConfirmComponent)
export const SelectUser = FieldWrapper(SelectUserComponent)
export const TimePicker = FieldWrapper(TimePickerComponent)
export const DatePicker = FieldWrapper(DatePickerComponent)
export const DateTimePicker = FieldWrapper(DateTimePickerComponent)
export const Hidden = HiddenComponent
export const EditableDropdown = FieldWrapper(EditableDropdownComponent)
export const ImagePicker = FieldWrapper(ImagePickerComponent)
export const ColorPicker = FieldWrapper(ColorPickerComponent)
export const FilePicker = FieldWrapper(FilePickerComponent)
export const Wysiwyg = FieldWrapper(WysiwygComponent)
export const Autocomplete = FieldWrapper(AutocompleteComponent)
export const OptionArray = FieldWrapper(OptionArrayComponent)
export const OptionSelector = FieldWrapper(OptionSelectorComponent)
export const MultiLanguageInput = FieldWrapper(MultiLanguageInputComponent)
export const TableList = FieldWrapper(TableListComponent)
export const OrganisationLookupInput = FieldWrapper(OrganisationLookupInputComponent)
export const OrganisationAssociationLookup = FieldWrapper(OrganisationAssociationLookupComponent)
export const MeddraAsyncLookUp = FieldWrapper(MeddraAsyncLookUpComponent)
export const DictionaryEntriesAsyncLookup = FieldWrapper(DictionaryEntriesAsyncLookupComponent)
export const TerminologyLookupDropdown = FieldWrapper(TerminologyLookupDropdownComponent)
