import _ from 'lodash'
import {
  compose,
  withHandlers,
  withProps
} from 'recompose'

import modalService from '../../../../../services/modalService'
import { translations } from '../../../../../config'

import ManuallyProcessModal from './ManuallyProcessModal'

const enhancer = compose(
  withHandlers({
    closeModal: () => () => {
      modalService.close()
    }
  }),
  withProps((props) => {
    const { closeModal } = props
    return {
      actions: [
        {
          text: translations('Close'),
          onClick: closeModal
        }
      ]
    }
  }),
  withProps((props) => {
    const {
      name,
      status,
      message,
      details,
      errors = [],
      modalTitle
    } = props

    const parseDetails = () => {
      let nextDetails
      if (_.isString(details)) {
        nextDetails = details
      }
      if (!_.isEmpty(details)) {
        nextDetails = JSON.stringify(details)
      }
      return nextDetails
    }

    return {
      modalTitle,
      name,
      status,
      message,
      details: parseDetails(),
      errors: _.castArray(errors)
    }
  })
)

export default enhancer(ManuallyProcessModal)
