import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import Button from '../../../../../components/Button'
import Form, { FormBody } from '../../../../../components/Form'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import Heading from '../../../../../components/Heading'

import { translations } from '../../../../../config'

import { actions as reportConfigurationActions, hooks as reportConfigurationHooks } from '../../../../../store/modules/reportConfiguration'

import getSchema from './schema'
import styles from './styles'

const FORM_NAME = 'AdHocFollowUpForm'
const FollowUpForm = Form(FORM_NAME)

const AdHocFollowUpModal = ({
  classes,
  closeModal,
  onSubmit,
  otherFollowUpNames = [],
  initialValues,
  organisationLanguages,
  reporterUser,
  reportId,
  extReportId
}) => {
  const userId = _.get(reporterUser, 'id')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(reportConfigurationActions.fetchLatestReportVersionForReporter({ reportId, userId }))
  }, [reportId, reporterUser])

  const latestReport = reportConfigurationHooks.useLatestReporterReport({ userId, extReportId })
  const reporterName = _.startCase(_.get(reporterUser, 'fullName'))

  return (
    <>
      <Heading className={classes.heading}>{translations('FollowUp - Add Individual Followup', { name: reporterName })}</Heading>
      <FollowUpForm
        onSubmit={params => onSubmit({ ...params, reportId: _.get(latestReport, 'id'), extReportId })}
        initialValues={initialValues}
      >
        <ContentBoxBody>
          <FormBody
            formName={FORM_NAME}
            schema={
              getSchema({
                otherFollowUpNames,
                organisationLanguages
              })
            }
            editing
            fullWidthFields
          />
        </ContentBoxBody>
        <div className={classes.buttonContainer}>
          <Button raised buttonType='white' onClick={() => closeModal()}>
            {translations('Cancel')}
          </Button>
          <Button raised buttonType='primary' type='submit'>
            {translations('Confirm')}
          </Button>
        </div>
      </FollowUpForm>
    </>
  )
}

AdHocFollowUpModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func,
  otherFollowUpNames: PropTypes.arrayOf(PropTypes.string),
  formViewId: PropTypes.string,
  handleSubmit: PropTypes.func
}

export default withStyles(styles)(AdHocFollowUpModal)
