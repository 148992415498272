import React from 'react'
import _ from 'lodash'
import {
  compose,
  withHandlers,
  withProps,
} from 'recompose'

import AddQuestionModal from './AddQuestionModal'
import modalService from '../../../../../services/modalService'

const enhancer = compose(
  withProps(props => {
    const { questionNames } = props
    return { questionNames: _.compact(questionNames()) }
  }),
  withHandlers({
    handleSubmit: ({ heading, addQuestion }) => async (formData) => {
      try {
        const { newfieldname, newfieldvisible, newquestionmultisection } = formData
        await addQuestion({
          name: newfieldname,
          visible: newfieldvisible,
          section: newquestionmultisection,
          heading
        })
      } catch (error) {
        console.log(error)
      }
    },
    openModal: () => component => {
      modalService.open({
        component
      })
    },
    closeModal: () => () => {
      modalService.close()
    }
  })
)

export default enhancer(AddQuestionModal)
