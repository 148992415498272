import { NETWORK_CONNECTION_ON, NETWORK_CONNECTION_OFF } from './constants'
import reducers from './reducers'

const defaultState = {
  connected: window.cordova 
    ? true
    : window.navigator.onLine
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case NETWORK_CONNECTION_ON:
      return reducers.networkConnectionOn(state, action)
    case NETWORK_CONNECTION_OFF:
      return reducers.networkConnectionOff(state, action)
    default:
      return state
  }
}
