export default theme => ({
  form: {
    padding: 20,
    paddingBottom: 0
  },
  listContainer: {
    overflow: 'auto',
    width: '100%',
    margin: '0 auto',
    padding: '20px 10px 0 10px',
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      paddingLeft: 20,
      paddingRight: 20
    }
  }
})
