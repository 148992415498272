"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sabreSAEFields = void 0;

var formHelpers = _interopRequireWildcard(require("../../sabreSAR/1.0.0/formHelpers"));

var _utils = require("../../mhraR3Report/1.0.0/collections/utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fp = require('lodash/fp');

var sabreSAEFields = [{
  id: 'reportStatus'
}, {
  id: 'reportExcluded'
}, {
  id: 'regulatorStatus'
}, {
  id: 'reporterStatus'
}, {
  id: 'orgCode'
}, {
  id: 'orgName'
}, {
  id: 'orgAddress'
}, {
  id: 'submittedNtf'
}, {
  id: 'reporterIdNtf'
}, {
  id: 'reporterNameNtf'
}, {
  id: 'reporterEmailNtf'
}, {
  id: 'reporterPhoneNtf'
}, {
  id: 'reporterPositionNtf'
}, {
  id: 'hvrIdHeading'
}, {
  id: 'hvrId'
}, {
  id: 'localReferenceNumber'
}, {
  id: 'hospitalConsultant'
}, {
  id: 'incidentLocation'
}, {
  id: 'linkedEstablishment'
}, {
  id: 'reportedLocally'
}, {
  id: 'beReported'
}, {
  id: 'beName'
}, {
  id: 'beConsultant'
}, {
  id: 'furtherDetails'
}, {
  id: 'notificationAttachments'
}, {
  id: 'sex',
  changes: [_utils.modifiers.setRequired(true)]
}, {
  id: 'reporterIdCnf',
  changes: [_utils.modifiers.setComputedHidden(formHelpers.showIfIsConfirmation), _utils.modifiers.setComputedDisabled(formHelpers.lockIfIsConfirmation), _utils.modifiers.setComputedValue(true)]
}, {
  id: 'reporterNameCnf',
  changes: [_utils.modifiers.setComputedHidden(formHelpers.showIfIsConfirmation), _utils.modifiers.setComputedDisabled(formHelpers.lockIfIsConfirmation), _utils.modifiers.setComputedValue(true)]
}, {
  id: 'reporterEmailCnf',
  changes: [_utils.modifiers.setComputedHidden(formHelpers.showIfIsConfirmation), _utils.modifiers.setComputedDisabled(formHelpers.lockIfIsConfirmation), _utils.modifiers.setComputedValue(true)]
}, {
  id: 'reporterPhoneCnf',
  changes: [_utils.modifiers.setComputedHidden(formHelpers.showIfIsConfirmation), _utils.modifiers.setComputedDisabled(formHelpers.lockIfIsConfirmation), _utils.modifiers.setComputedValue(true)]
}, {
  id: 'reporterPositionCnf',
  changes: [_utils.modifiers.setComputedHidden(formHelpers.showIfIsConfirmation), _utils.modifiers.setComputedDisabled(formHelpers.lockIfIsConfirmation), _utils.modifiers.setComputedValue(true)]
}, {
  id: 'confirmationAttachments'
}, {
  id: 'footnotes'
}];
exports.sabreSAEFields = sabreSAEFields;