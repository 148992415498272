"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _config = require("../../../../../../config");

var _formHelpers = require("../../../formHelpers");

var _formHelpers2 = require("./formHelpers");

var _common = require("../common");

var _validators = require("../../../../../../formHelpers/validators");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'canSaveDraft',
    field: 'Input',
    computedValue: function computedValue(values) {
      return !_lodash["default"].isNil(values.reportType);
    },
    props: {
      type: 'hidden',
      name: 'canSaveDraft',
      label: 'Can save draft',
      required: false
    }
  }, {
    id: 'clinicalInvestigationType',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'clinicalInvestigationType',
      label: 'GB or NI clinical investigation',
      options: (0, _formHelpers.mapValuesAt1)(['GB only', 'NI only', 'GB and NI'])
    }
  }, {
    id: 'eudamedId',
    field: 'Input',
    computedRequired: _formHelpers2.isNIReport,
    computedDisabled: _formHelpers.isUpdate,
    props: {
      shrink: true,
      name: 'eudamedId',
      label: 'EUDAMED - ID',
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'clinicalTitle',
    field: 'Input',
    computedDisabled: _formHelpers.isUpdate,
    props: {
      shrink: true,
      required: true,
      name: 'clinicalTitle',
      label: 'Title of Clinical Investigation',
      validate: [validators.maxLength(4000, 'Field')]
    }
  }, {
    id: 'cipNumber',
    field: 'Input',
    props: {
      shrink: true,
      required: false,
      name: 'cipNumber',
      label: 'Reference number assigned by NCA',
      disabled: true,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'cipNumberNew',
    field: 'Input',
    props: {
      shrink: true,
      required: false,
      name: 'cipNumberNew',
      label: 'CIP Number',
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'reportType',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'reportType',
      label: 'Report Type',
      options: (0, _formHelpers.mapValuesAt1)(['Quarterly report', 'Annual report', 'End of study report', 'Deviation report', 'Early termination report', 'Other'])
    }
  }, {
    id: 'reportingPeriod',
    field: 'Dropdown',
    computedRequired: _formHelpers2.isQuarterlyReport,
    props: {
      shrink: true,
      name: 'reportingPeriod',
      label: 'Reporting Period - Quarterly',
      options: [{
        value: 'Q1',
        label: 'Q1 (Jan-Mar)'
      }, {
        value: 'Q2',
        label: 'Q2 (Apr-Jun)'
      }, {
        value: 'Q3',
        label: 'Q3 (Jul-Sep)'
      }, {
        value: 'Q4',
        label: 'Q4 (Oct-Dec)'
      }]
    }
  }, {
    id: 'reportingPeriodAnnual',
    field: 'Date',
    computedRequired: _formHelpers2.isAnnualReport,
    props: {
      shrink: true,
      name: 'reportingPeriodAnnual',
      label: 'Reporting Period - Annual',
      startYear: 2021,
      acceptedDateFormats: ['YYYY'],
      validate: [validators.isAcceptedDateFormat(['YYYY'])]
    }
  }, {
    id: 'irasId',
    field: 'Input',
    computedDisabled: _formHelpers.isUpdate,
    props: {
      shrink: true,
      required: true,
      name: 'irasId',
      label: 'IRAS Project Id No',
      validate: [validators.numeric, validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'mhraClinicalNo',
    field: 'Input',
    computedDisabled: _formHelpers.isUpdate,
    props: {
      shrink: true,
      required: true,
      name: 'mhraClinicalNo',
      label: 'MHRA Clinical investigation number',
      validate: [validators.pattern('custom', 'CI\/[0-9]{4}\/[0-9]{4}', 'g', 'Field must match pattern ie: CI/2022/0001')]
    }
  }, {
    id: 'msNcaRef',
    field: 'Input',
    computedDisabled: _formHelpers.isUpdate,
    props: {
      shrink: true,
      required: true,
      name: 'msNcaRef',
      label: 'MS+NCA Reference Numbers for all participating Countries',
      validate: [validators.maxLength(4000, 'Field')]
    }
  }, {
    id: 'refMemberState',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'refMemberState',
      label: 'Reference Member State',
      options: _config.deviceDistributionCountriesDropdownOptions
    }
  }, {
    id: 'numPatientsEnrolledTotal',
    field: 'Input',
    props: {
      shrink: true,
      required: true,
      name: 'numPatientsEnrolledTotal',
      label: 'No. of Patients enrolled to date total',
      validate: [validators.numeric, validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'numPatientsEnrolledPerCountry',
    field: 'Input',
    props: {
      shrink: true,
      required: true,
      name: 'numPatientsEnrolledPerCountry',
      label: 'No. of Patients enrolled to date per country',
      validate: [validators.maxLength(4000, 'Field')]
    }
  }, {
    id: 'numDevicesUsedTotal',
    field: 'Input',
    props: {
      shrink: true,
      required: true,
      name: 'numDevicesUsedTotal',
      label: 'No. of Invest. Devices used to date total',
      validate: [validators.numeric, validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'numDevicesUsedPerCountry',
    field: 'Input',
    props: {
      shrink: true,
      required: true,
      name: 'numDevicesUsedPerCountry',
      label: 'No. of Invest. Devices used to date per country',
      validate: [validators.maxLength(4000, 'Field')]
    }
  }, {
    id: 'dateOfReport',
    field: 'Date',
    props: {
      shrink: true,
      required: true,
      name: 'dateOfReport',
      label: 'Date of report',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat)],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'deviceType',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'deviceType',
      label: 'Device type',
      options: _config.saeDevicesList
    }
  }, {
    id: 'deviceModel',
    field: 'Input',
    props: {
      shrink: true,
      required: true,
      name: 'deviceModel',
      label: 'Device model',
      validate: [validators.maxLength(300, 'Field')]
    }
  }, {
    id: 'reporterOrgName',
    field: 'Input',
    computedValue: function computedValue(_formValues, _parentFormValues, organisationDetails) {
      return _lodash["default"].get(organisationDetails, 'sendervid');
    },
    props: {
      shrink: true,
      name: 'reporterOrgName',
      label: 'Submitting Organisation Name',
      validate: [validators.maxLength(200, 'Field')]
    }
  }].concat(_toConsumableArray((0, _common.reporterSchema)(validators)), [{
    id: 'attachment',
    field: 'Repeatable',
    props: {
      label: 'Primary Attachment',
      name: 'attachment',
      repeatableLabel: ['attachmentfile'],
      shrink: true,
      required: true,
      disableEdit: true,
      maxLength: 1,
      schema: [{
        id: 'attachmentfile',
        field: 'Input',
        props: {
          label: 'File Name',
          name: 'attachmentfile',
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmenttype',
        field: 'Input',
        props: {
          label: 'Attachment Type',
          name: 'attachmenttype',
          type: 'hidden',
          defaultValue: 'primary',
          required: false,
          shrink: true
        }
      }, {
        id: 'attachmenturl',
        field: 'FilePicker',
        props: {
          label: 'Select File',
          name: 'attachmenturl',
          accept: ['.xlsx', '.xls'],
          required: true,
          shrink: true
        }
      }, {
        id: 'attachmentcomments',
        field: 'Input',
        props: {
          label: 'File Comments',
          name: 'attachmentcomments',
          required: false,
          shrink: true,
          multiline: true,
          maxLength: 1000,
          validate: [validators.maxLength(1000, 'Field')]
        }
      }]
    }
  }, {
    id: 'reportAttachment',
    field: 'Repeatable',
    props: {
      label: 'Supplementary Attachments',
      name: 'reportAttachment',
      repeatableLabel: ['attachmentfile'],
      shrink: true,
      disableEdit: true,
      schema: (0, _common.attachmentSchema)(validators)
    }
  }]);
};

exports.getFormSchema = getFormSchema;