import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Form, { FormBody, FormSubmit, FormError } from '../../../../../components/Form'
import { translations } from '../../../../../config'
import languages from '../../../../../config/configFiles/languages'
import * as validators from '../../../../../validators'
import style from './style'

export const formName = 'newLanguages'
const AddLanguageForm = Form(formName)

const NewAddLanguageForm = ({ classes, error, handleSubmit, languages = [], fullWidth = false, onSuccess }) => {
  const schema = [
    {
      id: 'language',
      field: 'Dropdown',
      props: {
        label: 'Language',
        options: Object.values(languages).map(e => ({ label: e.name, value: e.id })),
        required: true,
        autocomplete: true
      }
    }
  ]
  return (
    <div className={classes.formWrapper}>
      <AddLanguageForm editing onSubmit={(...args) => handleSubmit(...args).then(onSuccess)}>
        <FormError>{error}</FormError>
        <FormBody fullWidthFields={fullWidth} schema={schema} />
        <FormSubmit
          containerClass={classes.submitWrapper}
          className={classes.submit}
        >{translations('Manage Translations - Add Language Button')}</FormSubmit>
      </AddLanguageForm>
    </div>
  )
}

export default withStyles(style)(NewAddLanguageForm)
