import _ from 'lodash'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { submit } from 'redux-form'

import { translations } from '../../../../config'
import { FormBody } from '../../../../components/Form'
import { getUserGroup } from '../../../../store/modules/userGroups/selectors'
import { PENDING } from '../../../../store/middleware/redux-promise'

import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'

const formId = 'create-user-group'
const UserGroupForm = Editable(FormContentBox(formId))

const UserGroupModal = ({
  organisationId,
  handleCreate,
  handleUpdate,
  dismiss,
  isUpdate,
  data
}) => {
  const dispatch = useDispatch()
  const mode = isUpdate ? 'Update' : 'Create'
  const status = _.get(useSelector(getUserGroup), 'status')

  const schema = [
    {
      id: 'name',
      field: 'Input',
      props: {
        label: translations('Name'),
        name: 'name',
        required: true
      }
    },
    {
      id: 'description',
      field: 'Input',
      props: {
        label: translations('Description'),
        name: 'description',
        required: true
      }
    }
  ]

  const actions = useMemo(() => {
    return [
      {
        success: true,
        text: translations('Submit'),
        primary: true,
        onClick: () => {
          dispatch(submit(formId))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [dispatch])

  const handleSubmit = (data) => {
    const {
      id,
      name,
      description
    } = data

    if (isUpdate) {
      handleUpdate({ id, name, description })
    } else {
      handleCreate({ name, description })
    }
  }

  return (
    <BasicModalOverlay
      submitting={status === PENDING}
      actions={actions}
    >
      <UserGroupForm
        boxName={translations(`User Groups - ${mode} User Group`)}
        editing={true}
        onSubmit={handleSubmit}
        initialValues={data || {}}
      >
        <FormBody
          editing={true}
          schema={schema}
          layout={['name:12', 'description:12']}
        />
      </UserGroupForm>
    </BasicModalOverlay>
  )
}

export default UserGroupModal