import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/styles'

import digitalStoreSdk from '../../../digitalStoreSdk'
import MultiSelectAutocompleteDropdown from '../MultiSelectAutocompleteDropdown/MultiSelectAutocompleteDropdown'
import { getCurrentPlatformId } from '../../../store/modules/platforms/selectors'
import { getCurrentUser } from '../../../store/modules/auth/selectors'

import style from './style'
import { translations } from '../../../config'

const OrganisationAssociationLookup = (props) => {
  const {
    classes,
    reportDirectionType,
    userGroupId,
    optionsCallback,
    getCustomValue,
    multiple,
    fetchInitial,
    minimumSearchLength,
    freeSolo
  } = props

  const platformId = useSelector(getCurrentPlatformId)
  const userId = _.get(useSelector(getCurrentUser), 'id')

  const [options, setOptions] = useState([])
  const [initialOrganisations, setInitialOrganisations] = useState([])
  const [organisations, setOrganisations] = useState([])

  const noOptionsText = translations('Organisation Lookup - No Options Text')

  useEffect(() => {
    const fetchInitialOrganisations = async () => {
      const organisations = await fetchOrganisations()
      setInitialOrganisations(organisations)
    }
    if (fetchInitial) {
      fetchInitialOrganisations()
    }
  }, [])

  useEffect(() => {
    if (organisations) {
      const options = mapOrganisationsToOptions(organisations)
      setOptions(options)
    }
  }, [organisations])

  useEffect(() => { optionsCallback && optionsCallback(options) }, [options])

  const validateName = (query) => {
    const name = _.trim(query)
    const MIN_SEARCH_LENGTH = minimumSearchLength || 3
    const validName = name.length >= MIN_SEARCH_LENGTH
    return validName ? name : null
  }

  const fetch = (e) => {
    const query = e.target.value
    const name = validateName(query)
    if (!name) { return setOrganisations(initialOrganisations) }
    fetchOrganisations(name)
  }

  const fetchOrganisations = async (name) => {
    const options = { query: name, platformId, userId, getCustomValue }
    if (reportDirectionType) {
      options.reportDirectionType = reportDirectionType
    }
    if (userGroupId) {
      options.userGroupId = userGroupId
    }
    const result = await digitalStoreSdk.organisations.searchOrganisations(options)
    const organisations = result.organisations
    setOrganisations(organisations)
    return organisations
  }

  const mapOrganisationsToOptions = (organisations) => {
    const detailsList = [
      'details.senderstreetaddress',
      'details.senderstreetaddressLine2',
      'details.sendercity'
    ]
    const options = _.map(organisations, organisation => {
      const details = _.join(_.compact(_.map(detailsList, detail => _.get(organisation, detail))), ', ')
      return {
        label: organisation.displayName || organisation.name,
        value: getCustomValue ? getCustomValue(organisation) : organisation.displayName || organisation.name,
        details,
        ...(!getCustomValue && { meta: organisation })
      }
    })
    return options
  }
  
  return (
    <MultiSelectAutocompleteDropdown
      {...props}
      renderOption={(props, option) => {
        return (
          <>
            <span>{props.label}</span>
            {props.details && <span className={classes.subtext}>{props.details}</span>}
          </>
        )
      }}
      multiple={multiple}
      freeSolo={freeSolo || multiple}
      disableCloseOnSelect={multiple}
      noOptionsText={noOptionsText}
      options={options}
      handleInputChange={fetch}
    />
  )
}
export default withStyles(style)(OrganisationAssociationLookup)
