export const GENERATE_CSV = 'digitalStore/translations/GENERATE_CSV'
export const CREATE_TRANSLATION = 'digitalStore/translations/CREATE_TRANSLATION'
export const UPLOAD_TRANSLATIONS = 'digitalStore/translations/UPLOAD_TRANSLATIONS'
export const SET_TRANSLATIONS = 'digitalStore/translations/SET_TRANSLATIONS'
export const FETCH_TRANSLATIONS_FOR_ORG = 'digitalStore/translations/FETCH_TRANSLATIONS_FOR_ORG'
export const SEARCH_TRANSLATIONS_FOR_ORG = 'digitalStore/translations/SEARCH_TRANSLATIONS_FOR_ORG'
export const CREATE_NEW_TRANSLATION_KEY_FOR_ORG = 'digitalStore/translations/CREATE_NEW_TRANSLATION_KEY'
export const DELETE_LANGUAGES_TRANSLATIONS_FOR_ORG = 'digitalStore/translations/DELETE_LANGUAGES_TRANSLATIONS_FOR_ORG'
export const SAVE_TRANSLATION_CHANGES_FOR_ORG = 'digitalStore/translations/SAVE_TRANSLATION_CHANGES_FOR_ORG'
export const SAVE_TRANSLATION_DELETE_FOR_ORG = 'digitalStore/translations/SAVE_TRANSLATION_DELETE_FOR_ORG'
export const GET_TRANSLATION_DIFFERENCES = 'digitalStore/translations/GET_TRANSLATION_DIFFERENCES'
export const SET_OFFSET = 'digitalStore/translations/SET_OFFSET'
export const SET_SEARCH_VALUE = 'digitalStore/translations/SET_SEARCH_VALUE'
export const FETCH_TRANSLATION_TAGS = 'digitalStore/translations/FETCH_TRANSLATION_TAGS'
export const FETCH_TRANSLATION_AUDIT_TRAIL_FOR_ORG = 'digitalStore/translations/FETCH_TRANSLATION_AUDIT_TRAIL_FOR_ORG'
export const FIND_ORGANISATION_LANGUAGES = 'digitalStore/translations/FIND_ORGANISATION_LANGUAGES'
