import { LOCATION_CHANGE, push } from 'connected-react-router'
import { matchPath } from 'react-router'
import qs from 'qs'
import _ from 'lodash'
import { translations } from '../../../config'
import modalService from '../../../services/modalService'
import { selectors as authSelectors } from '../auth'
import {
  actions as ssoProviderActions,
  constants as ssoProviderConstants
} from '../ssoProvider'

class SSOProviderMiddleware {

}

export default new SSOProviderMiddleware()
