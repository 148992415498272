import _ from 'lodash'
import fp from 'lodash/fp'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'

export const exportManagementState = state => _.get(state, 'export')

export const getResults = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'results')
)

export const isLoading = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'status') === PENDING
)

export const getFilters = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'filters', {})
)

export const getQuery = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'filters.query')
)

export const getSearchSize = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'size')
)

export const getSearchPage = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'page')
)

export const getSelectedRecord = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'selectedRecord')
)

export const getSelectedRecordDetails = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'selectedRecordDetails')
)

export const getCanLoadMore = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'canLoadMore')
)

export const getLink = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'link')
)

export const getStatus = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'status')
)

export const getError = createSelector(
  [exportManagementState],
  (exports) => _.get(exports, 'error')
)

export const getNumberOfActiveFilters = createSelector(
  getFilters,
  (filters) => {
    return _.reduce(filters, (total, value) => {
      return value ? total + 1 : total
    }, 0)
  }
)