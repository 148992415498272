import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import ImportDataPanel from '../../../../components/ImportDataPanel'
import { translations } from '../../../../config'

import style from './style'

const DrugsListsPanel = (props) => {
  const { data, showUploadModal, cols, xsCols, editable } = props

  const panelProps = {
    name: translations(`Drugs Lists Panel - Name`),
    noDataMessage: translations('Drugs Lists Panel - No Results'),
    onClickUpload: showUploadModal,
    data,
    cols,
    xsCols,
    editable
  }

  return <ImportDataPanel {...panelProps} />
}

export default withStyles(style)(DrugsListsPanel)
