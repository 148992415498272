import fp from 'lodash/fp'
import { translations } from '../config'

/**
 * Checks if the form schema already exists in the repeatable and enforces that only 1 can exist.
 * @param {object} formValues the formValues of the repeatable.
 * @returns {object|undefined} Object with _error property or empty object if no errors
 */
const isUniqueReceiver = (formValues) => {
  const values = fp.defaults({ source: null }, formValues)

  /**
   * Remove the current repeatable
   */
  const receiverFormSchemas = fp.reject(
    { id: fp.get('id', formValues) },
    fp.get('_parentFormValues.receiverSchemas', values)
  )

  /**
   * Ensure required fields for determining a unique receiver are complete
   */
  const hasCompletedRequiredFields = fp.compose(
    fp.every(fp.identity),
    fp.paths(['schema', 'receiver'])
  )(values)

  if (!hasCompletedRequiredFields) {
    return
  }

  /**
   * Find another instance with the same values
   */
  const matchingReceiver = fp.find((receiver) => {
    const { schema, source, condition_formViewCategory: formViewCategory } = receiver
    const matchesSchema = schema === fp.get('schema', values)
    const matchesSource = source === fp.get('source', values)
    const matchesCategory = fp.isEqual(formViewCategory, fp.get('condition_formViewCategory', values))
    let matchesFormViewCategory = true

    if (fp.get('condition_formViewCategory', values)) {
      matchesFormViewCategory = formViewCategory === fp.get('condition_formViewCategory', values)
    }
    return fp.every((value) => value === true, [matchesSchema, matchesSource, matchesCategory, matchesFormViewCategory])
  }, receiverFormSchemas)

  if (fp.isPlainObject(matchingReceiver)) {
    return {
      _error: translations('Error - Receiver already exists')
    }
  }

  /**
   * Only allow one instance of schema and receiver without a source
   */
  const hasFallbackReceiver = fp.compose(
    (size) => fp.gte(size, 1),
    fp.size,
    fp.filter(
      fp.allPass([
        fp.matchesProperty('schema', fp.get('schema', values)),
        fp.matchesProperty('receiver', fp.get('receiver', values)),
        (receiver) => !fp.has('source', receiver)
      ])
    )
  )(receiverFormSchemas)
  const withoutSource = fp.isNull(fp.get('source', values))
  const withoutCategory = fp.isNull(fp.get('condition_formViewCategory', values))
  
  if (withoutSource && hasFallbackReceiver && withoutCategory) {
    return {
      _error: translations('Error - Receiver default exists')
    }
  }
}

export default isUniqueReceiver
