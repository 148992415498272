import _ from 'lodash'
import { useShallowEqualSelector } from '../../../hooks'
import { getCurrentOrganisationSources } from '../combinedSelectors/authCombinedSelectors'

export const useCurrentOrganisationSourcesOptions = () => {
  const organisationSources = useShallowEqualSelector(getCurrentOrganisationSources)
  return _.map(organisationSources, (source) => {
    return {
      value: _.get(source, 'id'),
      label: _.get(source, 'name'),
      rcSupport: _.get(source, 'rcSupport'),
    }
  })
}
