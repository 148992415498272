import draftToHtml from 'draftjs-to-html'
import { Card, Typography, styled } from '@material-ui/core'
import { Info } from '@material-ui/icons'

import Heading from '../Heading'
import modalService from '../../services/modalService'

const Announcement = ({
  title,
  body,
  createdAt,
  isModal = false,
  fullWidth = false,
}) => {
  const contentHTML = draftToHtml(body)

  return (
    <Container
      onClick={() => modalService.open({
        component: Announcement,
        title,
        body,
        createdAt,
        isModal: true
      })}
      isModal={isModal}
      fullWidth={fullWidth}
    >
      <Info />
      <Content>
        <Header variant='h3' isModal={isModal}>
          {title}
        </Header>
        <Body
          isModal={isModal}
          dangerouslySetInnerHTML={{ __html: `<div>${contentHTML}</div>` }}
        />
        <Footer>
          <Typography variant='caption'>
            {createdAt}
          </Typography>
        </Footer>
      </Content>
    </Container >
  )
}
export default Announcement

const Container = styled(Card)(({ theme, isModal, fullWidth }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(2),
  },
  width: (fullWidth || isModal) ? '100%' : 'calc(50% - 12px)',
  cursor: isModal ? 'auto' : 'pointer',
  gap: 20,
  overflow: 'hidden'
}))

const Content = styled('div')(() => ({
  display: 'flex',
  width: '90%',
  flexDirection: 'column',
  backgroundColor: 'inherit',
  gap: 15
}))

const Header = styled(Heading)(({ isModal }) => ({
  display: 'block',
  width: '100%',
  backgroundColor: 'inherit',
  lineHeight: '1.4',
  maxHeight: isModal ? 'none' : '1.4',
  textOverflow: 'ellipsis',
  whiteSpace: isModal ? 'normal' : 'nowrap',
  overflow: 'hidden'
}))

const Body = styled('div')(({ isModal }) => ({
  display: 'flex',
  backgroundColor: 'inherit',

  '& > div': {
    display: 'inline-block',
    maxWidth: '100%',
    lineHeight: '1.4',
    overflowWrap: 'break-word',
    ...(!isModal && {
      display: '-webkit-box',
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxHeight: '4.2'
    }),

    '& > p': {
      ...(!isModal && {
        padding: 0,
        margin: 0
      })
    }
  },
}))

const Footer = styled('div')(() => ({
  display: 'flex',
  lineHeight: '1.4'
}))