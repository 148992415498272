import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

import digitalStoreSdk from '../../digitalStoreSdk'
import Button from '../../components/Button'
import modalService from '../../services/modalService'
import toastService from '../../services/toastService'
import { translations } from '../../config'

import CompanyAccountRequestForm from '../Login/CompanyAccountRequestForm'

import RejectModal from './rejectModal'

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 150px;
  flex-direction: column;
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
`

export default function OrganisationRequests(props) {
  const [preview, setPreview] = useState()
  const [changeRequest, setChangeRequest] = useState()
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const { changeRequestId } = props.match.params

  useEffect(() => {
    void async function fetch(){
      const changeRequest = await digitalStoreSdk.changeRequests.fetchById({ changeRequestId })
      const preview = await digitalStoreSdk.changeRequests.previewChangeRequestCommit({ changeRequestId })

      setChangeRequest(changeRequest)
      setPreview(preview)
    }()
  }, [changeRequestId])

  useEffect(() => {
    if (changeRequest && changeRequest.approvalStatus !== 'PENDING') {
      dispatch(push(`/`))
    }
  }, [changeRequest])

  const initialValues = {
    ...preview?.organisation.details,
    ...preview?.requesterDetails,
    name: preview?.organisation.name,
    country: preview?.organisation.country
  }

  const onApprove = useCallback(async () => {
    try {
      await digitalStoreSdk.changeRequests.approveChangeRequest({ changeRequestId })
      dispatch(push(`/`))
    } catch (e) {
      setError(e.message)
    }
  }, [changeRequestId])

  const onReject = useCallback(async () => {
    modalService.open({
      component: RejectModal,
      onDismiss: () => modalService.close(),
      onConfirm: async ({ rejectionMessage }) => {
        try {
          await digitalStoreSdk.changeRequests.declineChangeRequest({ changeRequestId, rejectionMessage })
          dispatch(push(`/`))
          modalService.close()
        } catch (e) {
          setError(e.message)
        }
      },
      title: translations('Organisation Requests - Reject new organisation')
    })
  }, [changeRequestId])

  useEffect(() => {
    if (error) {
      toastService.action({
        type: 'error',
        message: error,
        autoHideDuration: 6000
      })

      setError(undefined)
    }
  }, [error])

  if (changeRequest && changeRequest.approvalStatus !== 'PENDING') {
    return null
  }

  return (
    <>
      {preview ? (
        <FormContainer>
          <CompanyAccountRequestForm initialValues={initialValues} readOnly />
          <ActionsContainer>
            <Button buttonType="secondary" onClick={onReject}>{translations('Change Request - Reject')}</Button>
            <Button buttonType="primary" onClick={onApprove}>{translations('Change Request - Approve')}</Button>
          </ActionsContainer>
        </FormContainer>
      ) : (
        <div>Loading...</div>
      )}
    </>
  )
}
