import _ from 'lodash'
import { change, reduxForm, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { compose, withProps, withHandlers, lifecycle } from 'recompose'
import ThemeDetailsModal from './ThemeDetailsModal'
import { actions as themeDetailsActions, selectors as themeDetailsSelectors, constants as themeDetailsConstants } from '../../../store/modules/themeDetails'
import imageUploadService from '../../../services/imageUploadService'

const saveTheme = ({ values, editMode, dispatch }) => {
  if (editMode) {
    return dispatch(themeDetailsActions.updateTheme(values))
  } else {
    return dispatch(themeDetailsActions.createOrganisationTheme(values))
  }
}

const _getRemoteImage = (image) => {
  if (!image || !_.startsWith(image, 'blob:')) return null
  return imageUploadService.upload(image)
    .then((image) => _.get(image, 'url'))
}

const connectedComponent = compose(
  connect(state => ({
    isDetailsLoading: themeDetailsSelectors.getIsFormDetailsLoading(state),
    formValues: getFormValues(themeDetailsConstants.THEME_FORM_NAME)(state) || {}
  })),
  withHandlers({
    onSubmit: ({ dispatch, onComplete, editMode, themeId, themeType }) => (values) => {
      const headerLogoPromise = _getRemoteImage(values.headerLogo)
      const footerLogoPromise = _getRemoteImage(values.footerLogo)
      const logoPromise = _getRemoteImage(values.logo)
      const footerMobileLogoPromise = _getRemoteImage(values.footerMobileLogo)
      const faviconPromise = _getRemoteImage(values.favicon)
      const partnershipLogo = _getRemoteImage(values.partnershipLogo)

      return Promise.all([
        headerLogoPromise,
        footerLogoPromise,
        faviconPromise,
        footerMobileLogoPromise,
        logoPromise,
        partnershipLogo
      ])
      .then(([ headerLogo, footerLogo, favicon, footerMobileLogo, logo, partnershipLogo ]) => {
        const newValues = {
          ...values
        }

        if (headerLogo) {
          newValues.headerLogo = headerLogo
        }

        if (footerLogo) {
          newValues.footerLogo = footerLogo
        }

        if (favicon) {
          newValues.favicon = favicon
        }

        if (footerMobileLogo) {
          newValues.footerMobileLogo = footerMobileLogo
        }
        
        if (logo) {
          newValues.logo = logo
        }

        if (partnershipLogo) {
          newValues.partnershipLogo = partnershipLogo
        }

        newValues.type = themeType || 'Standard'
        
        return saveTheme({ values: newValues, editMode: !!themeId, dispatch })
        .then(created => {
          return onComplete(created)
        })
      })
    },
    closeModal: ({ onClose }) => () => {
      onClose()
    },
    changeField: ({ dispatch }) => (...values) => dispatch(change(...values)),
    fetchThemeDetails: ({ dispatch, themeId }) => () => {
      if (themeId) {
        dispatch(themeDetailsActions.fetchThemeDetailsForForm(themeId))
      }
    }
  }),
  withProps(({ changeField, themeId, isDetailsLoading }) => {
    return {
      isLoading: themeId && isDetailsLoading,
      editMode: !!themeId,
      changeField
    }
  }),
  lifecycle({
    componentDidMount () {
      this.props.fetchThemeDetails()
    }
  })
)(ThemeDetailsModal)

export default reduxForm({
  form: themeDetailsConstants.THEME_FORM_NAME
})(connectedComponent)
