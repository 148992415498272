import React from 'react'
import { connect } from 'react-redux'
import {
  compose,
  withHandlers,
  withStateHandlers,
  withPropsOnChange,
} from 'recompose'

import Category from './Category'
import modalService from '../../../../../services/modalService'

const enhancer = compose(
  withPropsOnChange(['fields'], (props) => {
    const {fields} = props
    return fields
  }),
  withStateHandlers(),
  withHandlers({
    handleSubmit: ({ reportNameModal, setCategory }) => async (formData) => {
      try {
        const { newreportcategory } = formData
        await setCategory(newreportcategory)
        modalService.open({
          component: () => reportNameModal
        })
      } catch (err) {
        console.log(err)
      }
    },
    closeModal: () => () => {
      modalService.close()
    }
  })
)

export default enhancer(Category)
