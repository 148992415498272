const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  contentContainer: {
    overflow: 'auto',
    width: '100%',
    margin: '0 auto',
    padding: '20px 10px 0 10px',
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      paddingLeft: 20,
      paddingRight: 20
    }
  },
  gridContainer: {
    margin: '20px auto 0 auto',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  },
  content: {
    width: '100%',
    marginTop: 30,
    textAlign: 'center',
    fontSize: 19,
    fontWeight: 'bold'
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  lockContainer: {
    width: '50px',
    padding: 0
  },
  cardContainer: {
    width: '100%',
    maxWidth: 400,
    padding: '7.5px 0'
  }
})

export default styles
