class AppReducers {
  toggleSideMenu = (state, action) => {
    return {
      ...state,
      isMenuOpen: action.isOpen
    }
  }

  toggleBasket = (state, action) => {
    return {
      ...state,
      isBasketOpen: action.isOpen
    }
  }

  appReady = (state, action) => {
    return {
      ...state,
      ready: action.ready
    }
  }
  onApiFail = (state, action) => {
    return {
      ...state,
      apiFail: true
    }
  }
  onApiDismiss = (state, action) => {
    return {
      ...state,
      apiFail: false
    }
  }

  changeClientBookTab = (state, action) => {
    return {
      ...state,
      clientBookTab: action.clientBookTab
    }
  }

  changeSnapshotMetricTab = (state, action) => {
    return {
      ...state,
      snapshotMetricTab: action.snapshotMetricTab
    }
  }
  changeSnapshotCarouselTab = (state, action) => {
    return {
      ...state,
      snapshotCarouselTab: action.snapshotCarouselTab
    }
  }

  editOrder = (state, action) => {
    return {
      ...state,
      isBasketOpen: true
    }
  }

  stopEditingOrder = (state, action) => {
    return {
      ...state,
      isBasketOpen: false
    }
  }
}

export default new AppReducers()
