import React from 'react'
import { useSelector } from 'react-redux'
import { selectors as analyticsSelectors } from '../../../../../store/modules/platformAnalytics'
import ReportFullList from '../../../ReportFullList'

const OrganisationReportingFullList = props => {
  const { reportSchema } = props
  const organisationAnalytics = useSelector(analyticsSelectors.getSelectedOrganisationAnalytics)
  const timePeriodDates = useSelector(analyticsSelectors.getTimePeriodDates)

  return (
    <ReportFullList
      items={[organisationAnalytics]}
      reportSchema={reportSchema}
      itemProps={{ chartCategory: reportSchema.chartCategory }}
      timePeriodDates={timePeriodDates}
    />
  )
}

export default OrganisationReportingFullList