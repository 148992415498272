import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { getFormError, SubmissionError, submit } from 'redux-form'
import { useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import Form, { FormBody } from '../../../../components/Form'
import P from '../../../../components/P'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import ConditionsBuilder from '../../../../components/Conditions/ConditionsBuilder'
import { constants as reportConfigurationConstants } from '../../../../store/modules/reportConfiguration'

import { styled } from '@material-ui/core/styles'
import toastService from '../../../../services/toastService'
import { translations } from '../../../../config'
import { useShallowEqualSelector } from '../../../../hooks'

const FORM_NAME = reportConfigurationConstants.CONFIGURE_CONDITIONS_FORM
const ConditionsForm = Form(FORM_NAME)

const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2)
}))

const Spacing = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1)
}))

const ConfigureConditionsModal = (props) => {
  const { onSubmit, dismiss, fields, initialValues, editing } = props
  const dispatch = useDispatch()
  const [conditions, setConditions] = useState({})
  const formError = useShallowEqualSelector(getFormError(FORM_NAME))

  const handleSubmit = useCallback(async ({ name, description }) => {
    if (_.size(conditions.rules) < 1) {
      throw new SubmissionError({ _error: translations('Conditions - Empty rules error') })
    }
    try {
      await onSubmit({ name, description, conditions })
      dismiss()
    } catch (error) {
      const errorCode = _.get(error, 'code', 'unknown')
      const errorMessage = translations('Conditions - Failed to save', { code: errorCode })

      toastService.action({
        type: 'error',
        message: errorMessage
      })
      throw new SubmissionError({ error: errorMessage })
    }
  }, [onSubmit, dismiss, conditions])

  const actions = useMemo(() => {
    return [
      {
        success: true,
        text: translations('Confirm'),
        primary: true,
        onClick: () => {
          dispatch(submit(FORM_NAME))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [onSubmit, dismiss])

  const schema = useMemo(() => {
    return [
      {
        id: 'name',
        field: 'Input',
        props: {
          label: translations('Name'),
          name: 'name',
          required: true
        }
      },
      {
        id: 'description',
        field: 'Input',
        props: {
          label: translations('Description'),
          name: 'description',
          multiline: true
        }
      }
    ]
  }, [])

  const title = editing ? translations('Edit conditions') : translations('Add conditions')

  return (
    <BasicModalOverlay actions={actions} title={title}>
      <ConditionsForm onSubmit={handleSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <Content>
            <Grid container spacing={2}>
              {_.isString(formError) && (
                <Grid item xs={12}>
                  <Spacing>
                    <P value={formError} type={'error'} />
                  </Spacing>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormBody
                  schema={schema}
                  layout={['name:6', 'description:12']}
                  editing
                  fullWidthFields
                />
              </Grid>
              <Grid item xs={12}>
                <Spacing>
                  <ConditionsBuilder
                    query={_.get(initialValues, 'conditions')}
                    fields={fields}
                    onQueryChange={(conditions) => setConditions(conditions)}
                  />
                </Spacing>
              </Grid>
            </Grid>
          </Content>
        </ContentBoxBody>
      </ConditionsForm>
    </BasicModalOverlay>
  )
}

ConfigureConditionsModal.props = {}

ConfigureConditionsModal.propTypes = {
  dismiss: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired
}

export default ConfigureConditionsModal
