import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'

import ContentBox from '../../../../../components/ContentBox/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../../components/Form/TextContent'
import Heading from '../../../../../components/Heading'
import { translations } from '../../../../../config'
import FormBody from '../../../../../components/Form/FormBody'
import style from './style'
import _ from 'lodash'
import { murFormConsts } from '../UserGroupAndOrganisation/constants'

const OrganisationAssociation = (props) => {
  const {
    id,
    classes,
    label,
    required,
    handleChange,
    selected,
    value
  } = props

  const [options, setOptions] = useState()

  const organisationAssociations = [
    { id: 'mfrAssociation', name: 'mfrAssociation', label: translations('Select manufacturer association'), userGroupId: murFormConsts.MFR_GROUP_ID },
    { id: 'authRepAssociation', name: 'authRepAssociation', label: translations('Select authorised representative association'), userGroupId: murFormConsts.AUTH_REP_GROUP_ID },
    { id: 'ukrpAssociation', name: 'ukrpAssociation', label: translations('Select UKRP association'), userGroupId: murFormConsts.UKRP_GROUP_ID }
  ]

  const getSchema = ({ id, name, label, userGroupId }) => (
    [
      {
        id,
        field: 'OrganisationAssociationLookup',
        props: {
          label: translations(label),
          name,
          shrink: true,
          required,
          onChange: handleChange,
          userGroupId,
          value,
          optionsCallback: setOptions,
          getCustomValue: ({ id }) => { return id }
        }
      }
    ]
  )

  const getSelectedInfo = () => {
    const info = _.find(options, ['value', selected])
    const label = _.get(info, 'label')
    const details = _.get(info, 'details')
    return details ? `${label} - ${details}` : label
  }

  const association = _.find(organisationAssociations, o => _.eq(o.id, id))
  const schema = getSchema({ ...association })

  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading className={classes.contentBoxHeader}>
            {label}
          </Heading>
        </TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <FormBody
          layout={[`${id}:12`]}
          schema={schema}
          editing
        />
        {selected &&
          <TextContent>
            {translations("New Account - Organisation Selection", { details: getSelectedInfo() })}
          </TextContent>
        }
      </ContentBoxBody>
    </ContentBox>
  )
}

export default withStyles(style)(OrganisationAssociation)
