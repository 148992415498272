import textContentStyle from '../../../../components/Form/TextContent/style'
import formStyles from '../../../../components/Form/style'

const styles = {}

export default styles

export const formStyle = theme => ({
  ...formStyles,
  ...textContentStyle
})
