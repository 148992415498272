import _ from 'lodash'
import React from 'react'
import { useWebsites } from '../../contexts/WebsiteManagementContext'
import { styled } from '@material-ui/core'

import { SETTINGS_FORM_NAME } from '../../../../store/modules/websiteManagement/constants'
import Form, { FormBody } from '../../../../components/Form'
import { getSchema } from './schema'

const Container = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: 900
}))

const SettingsForm = Form(SETTINGS_FORM_NAME)

const SettingsTab = ({}) => {
  const {
    state,
    updateSettings,
    fetchOrganisationThemes
  } = useWebsites()

  const initialValues = {
    name: state.settings.name,
    description: state.settings.description,
    domain: state.settings.domain,
    themeId: state.settings.themeId,
    showPartnershipLogo: state.settings.showPartnershipLogo
  }
  
  const themeOptions = state.themeOptions
  const schema = getSchema({ updateSettings, themeOptions, fetchOrganisationThemes })

  return (
    <SettingsForm
      initialValues={initialValues}
      destroyOnUnmount={false}
      forceUnregisterOnUnmount={false}
      enableReinitialize={true}
      keepDirtyOnReinitialize={true}
    >
      <Container>
        <FormBody
          schema={schema}
          editing
          fullWidthFields
        />
      </Container>
    </SettingsForm>
  )
}

export default SettingsTab