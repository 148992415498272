import { compose, withState, withHandlers, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import { connect } from 'react-redux'

import {
  actions as drugDetailsActions,
  selectors as drugDetailsSelectors
} from '../../../../store/modules/drugDetails'

import ProductCharts from './ProductCharts'

const mapStateToProps = state => {
  const retval = {
    metaDistribution: drugDetailsSelectors.getMetaDistribution(state),
    isLoadingMeta: drugDetailsSelectors.getIsLoadingMeta(state)
  }
  return retval
}

const { changeMetaDistribution } = drugDetailsActions
const mapDispatchToProps = { changeMetaDistribution }

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withPropsOnChange(['drug'], ({ drug }) => {
    const { meta, metaDistributions } = drug || {}
    let initialChart = null
    let chartOptions = []

    if (metaDistributions && metaDistributions.length) {
      initialChart = _.get(metaDistributions, '0.value')
      chartOptions = metaDistributions
    } else {
      const metaKeys = _.keys(meta).sort((l, r) => {
        const lo = meta[l].__order
        const ro = meta[r].__order
        if (!lo && lo !== 0) return 1
        if (!ro && ro !== 0) return -1
        return lo > ro ? 1 : -1
      })
      initialChart = metaKeys[0]
      chartOptions = metaKeys.map(key => {
        return {
          value: key,
          label: _.startCase(key)
        }
      })
    }
    return {
      initialChart,
      chartOptions,
      noData: !meta
    }
  }),
  withHandlers({
    handleChangeChart: ({
      setSelectedChart,
      changeMetaDistribution,
      drug
    }) => e => {
      const metaDistribution = e.target.value
      changeMetaDistribution({ metaDistribution })
    }
  }),
  withPropsOnChange(['drug', 'metaDistribution'], props => {
    const { drug, metaDistribution } = props
    const { meta } = drug || {}
    let chartData = []
    if (meta) {
      chartData = _.chain(meta[metaDistribution])
        .thru(data =>
          _.isArray(meta[metaDistribution]) ? _.fromPairs(data) : data
        )
        .omit('__order')
        .map((value, key) => ({ key, value: Number(value) }))
        .thru(data => {
          if (_.isArray(meta[metaDistribution])) {
            return data
          }
          return data.sort((a, b) =>
            a.key.localeCompare(b.key, undefined, { numeric: true })
          )
        })
        .value()
    }
    return { chartData }
  })
)

export default enhance(ProductCharts)
