import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../../config'
import Form, { FormBody } from '../../../../../components/Form'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import Button from '../../../../../components/Button'
import styles from './style'
import _ from 'lodash'

const NewReportForm = Form('NewReportForm')
const standardNames = [
  'Standard',
  'Devices',
  'MIR',
  'Standard E2B ICH R3 medicines form',
  'Standard E2B ICH R2 medicines form',
  'FSCA',
  'Halmed',
  'Lareb',
  'RedAnt',
  'Trilogy',
  'Yellowcard',
  'Webradr'
]

const uniqueName = (formViews) => (value) => {
  const checkExists =
    (value && _.find(formViews, (report) => report.name === value)) ||
    (value &&
      _.find(
        standardNames,
        (name) => name.toLowerCase() === value.toLowerCase()
      ))
  const errorMessage = translations('Error - Must Be A Unique Name')
  return value && checkExists ? errorMessage : undefined
}
const uniqueNameValidator = (formViews) => uniqueName(formViews)

const ReportNameModal = ({
  classes,
  formViews,
  closeModal,
  handleSubmit
}) => {
  return (
    <div className={classes.listContainer}>
      <h3>{translations('Report Config Name')}</h3>
      <NewReportForm onSubmit={handleSubmit}>
        <ContentBoxBody>
          <FormBody
            schema={[
              {
                id: 'newreportname',
                field: 'Input',
                props: {
                  name: 'newreportname',
                  validate: [uniqueNameValidator(formViews)],
                  shrink: true,
                  required: true
                }
              }
            ]}
            editing
            fullWidthFields
          />
        </ContentBoxBody>
        <div className={classes.buttonContainer}>
          <Button
            raised
            buttonType='white'
            style={{ marginRight: 10 }}
            onClick={() => closeModal()}
          >
            {translations('Cancel')}
          </Button>
          <Button raised buttonType='primary' type='submit'>
            {translations('Confirm')}
          </Button>
        </div>
      </NewReportForm>
    </div>
  )
}

ReportNameModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  formViews: PropTypes.array,
  closeModal: PropTypes.func,
  handleSubmit: PropTypes.func
}

export default withStyles(styles)(ReportNameModal)
