import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { selectors as multipartUploadSelectors } from '../../store/modules/multipartUpload'

import MultipartUploadBox from './MultipartUploadBox'

const enhance = compose(
  connect(state => {
    const retval = {
      fileName: multipartUploadSelectors.getFileName(state),
      fileSize: multipartUploadSelectors.getFileSize(state),
      bytesLoaded: multipartUploadSelectors.getBytesLoaded(state),
      percentageLoaded: multipartUploadSelectors.getPercentageLoaded(state),
      visible: multipartUploadSelectors.getIsUploadHappening(state)
    }
    return retval
  })
)

export default enhance(MultipartUploadBox)
