"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.constants = void 0;
Object.defineProperty(exports, "getCountryCodeByCountryName", {
  enumerable: true,
  get: function get() {
    return _config.getCountryCodeByCountryName;
  }
});
exports.utilities = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _formSchemas = _interopRequireDefault(require("./formSchemas"));

var _validators = require("./validators");

var externalUtils = _interopRequireWildcard(require("./utilities"));

exports.utilities = externalUtils;

var _schemaDiff = require("./services/schemaDiff");

var _meta = _interopRequireDefault(require("./meta.json"));

var constants = _interopRequireWildcard(require("./constants"));

exports.constants = constants;

var _config = require("./config");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }

function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); Object.defineProperty(Constructor, "prototype", { writable: false }); return Constructor; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var parseSectionFields = externalUtils.parseSectionFields;
var viewType = {
  FORM: 'form',
  DISPLAY: 'display'
};

var FormSchemaLibrary = _createClass(function FormSchemaLibrary() {
  var _this = this;

  _classCallCheck(this, FormSchemaLibrary);

  _defineProperty(this, "options", {
    showNullFlavours: true,
    showAllSections: false,
    reportConfig: false,
    audienceId: null,
    view: viewType.FORM,
    showAssessmentFields: false
  });

  _defineProperty(this, "AUDIENCE_CONSTANTS", {
    INDUSTRY: 1,
    HEALTHCARE_PROFESSIONAL: 2,
    MEMBER_OF_PUBLIC: 3
  });

  _defineProperty(this, "mergeObject", function (property, values) {
    var hasProperty = _lodash["default"].has(_this, property);

    if (!hasProperty) {
      throw new Error("Library does not support updating property ".concat(property));
    }

    if (_lodash["default"].isObject(values) && _lodash["default"].size(values)) {
      _this[property] = _objectSpread(_objectSpread({}, _this[property]), values);
    }

    return _this[property];
  });

  _defineProperty(this, "setOptions", function (options) {
    return _this.mergeObject('options', options);
  });

  _defineProperty(this, "getOptions", function () {
    return _this.options;
  });

  _defineProperty(this, "mapFormSchema", function () {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        getFormSchema = _ref.getFormSchema,
        getFormView = _ref.getFormView;

    var viewJSON = arguments.length > 1 ? arguments[1] : undefined;
    var validators = arguments.length > 2 ? arguments[2] : undefined;
    var options = arguments.length > 3 ? arguments[3] : undefined;
    getFormSchema = getFormSchema || _lodash["default"].get(_this.formSchema, 'getFormSchema');
    getFormView = getFormView || _lodash["default"].get(_this.formSchema, 'getFormView');
    viewJSON = viewJSON || _this.viewJSON;
    validators = validators || _this.schemaValidators;
    options = options || _this.options;

    var reportConfig = _lodash["default"].get(options, 'reportConfig') || _this.options.reportConfig;

    var composedSchema = {};
    var formSchema = getFormSchema(validators);
    var formView = _lodash["default"].has(viewJSON, 'sections') && _lodash["default"].isArray(viewJSON.sections) ? viewJSON : getFormView(validators);

    var shouldAssessmentItemBeHidden = function shouldAssessmentItemBeHidden(schemaItem) {
      var itemIsForAssessment = _lodash["default"].get(schemaItem, 'isForAssessment', false);

      var assessmentFieldsCanNotBeShown = options.showAssessmentFields === false;
      return assessmentFieldsCanNotBeShown && itemIsForAssessment;
    };

    var shouldSectionBeHidden = function shouldSectionBeHidden(section) {
      var isHidden = true;

      if (options.showAllSections) {
        isHidden = false;
      } else if (section.visible) {
        isHidden = false;
      }

      return isHidden;
    };

    var mapSections = function mapSections(sections) {
      return _lodash["default"].forEach(sections, function (section, i) {
        if (shouldAssessmentItemBeHidden(section)) {
          _lodash["default"].set(section, 'visible', false);
        }

        if (shouldSectionBeHidden(section)) {
          return undefined;
        }

        if (section.hideFromForm && _this.options.view === viewType.FORM) {
          _lodash["default"].set(section, 'hideFromForm', true);
        }

        var mapSectionSchema = function mapSectionSchema() {
          var fields = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
          var schema = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : formSchema;
          var parentField = arguments.length > 2 ? arguments[2] : undefined;
          return parseSectionFields(fields).map(function (field) {
            var hideFromForm = _lodash["default"].get(field, 'hideFromForm') && _this.options.view === viewType.FORM;

            if (!field.visible) {
              return undefined;
            }

            if (field.isHeading) {
              var headingName = !_lodash["default"].isEmpty(field.displayName) ? field.displayName : field.name;
              return {
                id: field.id,
                field: 'Heading',
                computedValue: function computedValue() {
                  return headingName;
                },
                props: {
                  shrink: true,
                  name: "heading.".concat(field.id),
                  disabled: true,
                  value: headingName,
                  isHeading: true,
                  conditions: _lodash["default"].get(field, 'conditions')
                }
              };
            }

            if (field.isCustom) {
              var component = _lodash["default"].get(field, 'field', 'Input');

              var getComponent = function getComponent(component) {
                switch (component) {
                  case 'Numeric':
                    return 'Input';

                  default:
                    return component;
                }
              };

              var getType = function getType(component) {
                switch (component) {
                  case 'Numeric':
                    return 'number';

                  default:
                    return;
                }
              };

              var customFieldName = function customFieldName() {
                var sections = _lodash["default"].get(field, 'nestedSections');

                var parentId = _lodash["default"].get(parentField, 'id');

                var customPath = ['custom'];
                var includeSection = !_lodash["default"].isString(sections) && !_lodash["default"].isString(parentId);

                if (includeSection) {
                  customPath.push(section.id);
                }

                customPath.push(field.id);
                return _lodash["default"].join(customPath, '.');
              };

              var customField = {
                id: field.id,
                field: getComponent(component),
                props: _objectSpread({
                  shrink: true,
                  name: customFieldName(),
                  label: !_lodash["default"].isEmpty(field.displayName) ? field.displayName : field.name,
                  type: getType(component),
                  helperText: _lodash["default"].get(field, 'helperText', null),
                  required: _lodash["default"].get(field, 'mandatory', false),
                  options: _lodash["default"].get(field, 'options', []),
                  conditions: _lodash["default"].get(field, 'conditions'),
                  decimal: _lodash["default"].get(field, 'decimal', false),
                  multiple: _lodash["default"].get(field, 'multiple', false),
                  startDate: _lodash["default"].get(field, 'startDate', null),
                  endDate: _lodash["default"].get(field, 'endDate', null),
                  acceptedDateFormats: _lodash["default"].get(field, 'acceptedDateFormats', [])
                }, (0, _validators.viewJSONtoValidations)(field, validators))
              };

              if (component === 'Dropdown') {
                var dropDownOptions = _lodash["default"].get(field, 'dropdownOptions');

                if (_lodash["default"].isArray(dropDownOptions)) {
                  _lodash["default"].set(customField, 'props.options', dropDownOptions);
                }
              }

              if (shouldAssessmentItemBeHidden(field)) {
                _lodash["default"].set(customField, 'props.type', 'hidden');
              }

              return customField;
            }

            var matchedField = _lodash["default"].find(schema, function (schemaField) {
              return schemaField.id === field.id;
            });

            if (matchedField) {
              if (matchedField.field === 'Repeatable') {
                var repeatableSchema = _lodash["default"].clone(matchedField.props.schema);

                _lodash["default"].set(matchedField, 'props.schema', _lodash["default"].compact(mapSectionSchema(field.fields, repeatableSchema, matchedField)));
              }

              if (!_lodash["default"].isEmpty(field.displayName)) {
                _lodash["default"].set(matchedField, 'props.label', field.displayName);
              }

              if (!options.showNullFlavours && _lodash["default"].has(matchedField, 'props.nullFlavours.defaultVisible') && _lodash["default"].has(matchedField, 'props.nullFlavours.defaultHidden') && _lodash["default"].get(matchedField, 'props.required') === true) {
                _lodash["default"].set(matchedField, 'props.required', false);
              }

              if (!options.showNullFlavours) {
                matchedField = _lodash["default"].omit(matchedField, 'props.nullFlavours');
              }

              if (field.mandatory) {
                _lodash["default"].set(matchedField, 'props.required', true);
              }

              if (_lodash["default"].isNumber(field.startYear)) {
                _lodash["default"].set(matchedField, 'props.startYear', field.startYear);
              }

              if (!_lodash["default"].isEmpty(field.helperText)) {
                _lodash["default"].set(matchedField, 'props.helperText', field.helperText);
              }

              if (_lodash["default"].isBoolean(field.restrictFreeformEntry)) {
                _lodash["default"].set(matchedField, 'props.restrictFreeformEntry', field.restrictFreeformEntry);
              }

              if (_lodash["default"].isObject(field.lookupOptions) && !_lodash["default"].isEmpty(field.lookupOptions)) {
                _lodash["default"].set(matchedField, 'props.lookupOptions', field.lookupOptions);
              }

              if (_lodash["default"].isBoolean(field.restrictInput)) {
                _lodash["default"].set(matchedField, 'props.restrictInput', field.restrictInput);
              }

              if (_lodash["default"].isBoolean(field.showInitially)) {
                _lodash["default"].set(matchedField, 'props.showInitially', field.showInitially);
              }

              if (hideFromForm === true) {
                _lodash["default"].set(matchedField, 'props.type', 'hidden');
              }

              if (matchedField.field === 'Dropdown') {
                var _dropDownOptions = _lodash["default"].get(field, 'dropdownOptions');

                if (_lodash["default"].isArray(_dropDownOptions)) {
                  _lodash["default"].set(matchedField, 'props.options', _dropDownOptions);
                }
              }

              if (shouldAssessmentItemBeHidden(field)) {
                _lodash["default"].set(matchedField, 'props.type', 'hidden');
              }

              var fieldConditions = _lodash["default"].get(field, 'conditions');

              if (!_lodash["default"].isUndefined(fieldConditions)) {
                _lodash["default"].set(matchedField, 'props.conditions', fieldConditions);
              }

              return matchedField;
            }
          });
        };

        var sectionDisplayName = _lodash["default"].get(section, 'displayName');

        var sectionTitle = !_lodash["default"].isEmpty(sectionDisplayName) ? sectionDisplayName : section.name;

        var sectionSchema = _lodash["default"].compact(mapSectionSchema(section.fields));

        composedSchema[section.id] = {
          initialOpen: i === 0,
          title: sectionTitle,
          schema: sectionSchema,
          visible: section.visible,
          conditions: _lodash["default"].get(section, 'conditions', {}),
          hideFromForm: _lodash["default"].get(section, 'hideFromForm', false)
        };
      });
    };

    mapSections(formView.sections);
    return composedSchema;
  });

  _defineProperty(this, "fallbackTranslations", function (string) {
    return string;
  });

  _defineProperty(this, "processSchema", function (name, viewJSON, translations, options, reportConfig) {
    if (!_formSchemas["default"][name]) {
      throw new Error("No schema found for ".concat(name));
    }

    _this.schemaVersion = _lodash["default"].get(viewJSON, 'schemaVersion');
    _this.viewJSON = viewJSON;
    _this.schemaValidators = (0, _validators.getValidators)(translations || _this.fallbackTranslations);
    _this.formSchema = _this.getSchema(name, _this.schemaVersion);

    _this.setOptions(_objectSpread(_objectSpread({}, options), reportConfig));

    var schema = _this.mapFormSchema();

    return schema;
  });

  _defineProperty(this, "getSchema", function (name) {
    var version = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return _lodash["default"].get(_formSchemas["default"][name](version), 'schema');
  });

  _defineProperty(this, "getAllFields", function (name) {
    var version = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

    var schema = _this.getSchema(name, version);

    var fields = [];

    if (schema && _lodash["default"].isFunction(schema.getFormSchema)) {
      var validators = (0, _validators.getValidators)(_this.fallbackTranslations);
      return schema.getFormSchema(validators);
    }

    return fields;
  });

  _defineProperty(this, "getDefaultView", function (name, schemaVersion) {
    if (!_formSchemas["default"][name]) {
      throw new Error("No schema found for ".concat(name));
    }

    var _formSchemas$name = _formSchemas["default"][name](schemaVersion),
        schema = _formSchemas$name.schema;

    var validators = (0, _validators.getValidators)(_this.fallbackTranslations);
    return schema.getFormView(validators);
  });

  _defineProperty(this, "getSchemaVersions", function (name) {
    var _ref2 = _formSchemas["default"][name]() || [],
        versions = _ref2.versions;

    return versions;
  });

  _defineProperty(this, "getSchemaInitialFieldMap", function (name, schemaVersion) {
    if (!_formSchemas["default"][name]) {
      throw new Error("No schema found for ".concat(name));
    }

    var _formSchemas$name2 = _formSchemas["default"][name](schemaVersion),
        schema = _formSchemas$name2.schema;

    return _lodash["default"].get(schema, 'initialFieldMap');
  });

  _defineProperty(this, "getSchemaFormType", function (name, schemaVersion) {
    if (!_formSchemas["default"][name]) {
      throw new Error("No schema found for ".concat(name));
    }

    var _formSchemas$name3 = _formSchemas["default"][name](schemaVersion),
        schema = _formSchemas$name3.schema;

    return _lodash["default"].get(schema, 'type');
  });

  _defineProperty(this, "checkSchemaExists", function (name) {
    return !!_lodash["default"].get(_formSchemas["default"], name);
  });

  _defineProperty(this, "getFieldsFromViewJSON", function (viewJSON) {
    viewJSON = _lodash["default"].get(viewJSON, 'sections') || _lodash["default"].get(_this.viewJSON, 'sections');
    var allFields = [];

    var mapFields = function mapFields(_ref3) {
      var fields = _ref3.fields,
          visibility = _ref3.visibility;
      return _lodash["default"].map(fields, function (field) {
        var fieldVisibility = !!visibility && !!field.visible;
        allFields.push(_objectSpread(_objectSpread({}, field), {}, {
          visible: fieldVisibility
        }));

        if (field.fields) {
          mapFields({
            fields: field.fields,
            visibility: fieldVisibility
          });
        }
      });
    };

    _lodash["default"].forEach(viewJSON, function (section) {
      mapFields({
        fields: section.fields,
        visibility: section.visible
      });
    });

    return allFields;
  });

  _defineProperty(this, "getSchemaChanges", function () {
    var viewJSON = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var formSchemaName = arguments.length > 1 ? arguments[1] : undefined;
    var diff = (0, _schemaDiff.getSchemaDiff)({
      source: viewJSON,
      remote: _lodash["default"].get(_this.getDefaultView(formSchemaName), 'sections')
    });
    return diff;
  });

  _defineProperty(this, "getDefaultViewJsonHash", function (formSchemaName) {
    return _lodash["default"].get(_meta["default"], ['formSchemas', formSchemaName, 'hash'], '');
  });

  _defineProperty(this, "getSchemaAttachmentFields", function (name, schemaVersion) {
    if (!_formSchemas["default"][name]) {
      return ['attachment'];
    }

    var _formSchemas$name4 = _formSchemas["default"][name](schemaVersion),
        schema = _formSchemas$name4.schema;

    return _lodash["default"].get(schema, 'attachmentFields', ['attachment']);
  });

  _defineProperty(this, "getLocationField", function (_ref4) {
    var viewJSON = _ref4.viewJSON,
        name = _ref4.name,
        schemaVersion = _ref4.schemaVersion;

    var defaultViewJSON = _this.getDefaultView(name);

    var JSON = viewJSON ? viewJSON : _lodash["default"].get(defaultViewJSON, 'sections');
    var formSchema = _formSchemas["default"][name];

    if (formSchema) {
      var _formSchema = formSchema(schemaVersion),
          schema = _formSchema.schema;

      return schema.getLocationField(JSON);
    } else {
      return undefined;
    }
  });

  _defineProperty(this, "getFieldForAttachmentType", function () {
    var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        attachmentType = _ref5.attachmentType,
        name = _ref5.name,
        schemaVersion = _ref5.schemaVersion;

    var formSchema = _formSchemas["default"][name];
    var attachmentField = 'attachment';

    if (formSchema) {
      var _formSchema2 = formSchema(schemaVersion),
          schema = _formSchema2.schema;

      var attachmentTypeGroups = _lodash["default"].get(schema, 'attachmentTypeGroups');

      if (_lodash["default"].isPlainObject(attachmentTypeGroups)) {
        var typeLookup = _lodash["default"].get(attachmentTypeGroups, attachmentType, _lodash["default"].get(attachmentTypeGroups, 'default'));

        if (typeLookup) {
          attachmentField = typeLookup;
        }
      }
    }

    return attachmentField;
  });

  _defineProperty(this, "VERSION", _lodash["default"].get(_meta["default"], 'version', ''));
});

var _default = FormSchemaLibrary;
exports["default"] = _default;