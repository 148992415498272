"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.populateSenderType = exports.populateReporterTitle = exports.populateReporterProfessionXml = exports.populateReporterLastName = exports.populateReporterFirstName = exports.orClear = exports.noopOrCallback = exports.isOfProfessionType = exports.isNotProfessionType = exports.getProfessionOfReporterXml = exports.getProfessionOfPrimarySourceXml = exports.getProfessionOfPersonalDetailsXml = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var computedHidden = _interopRequireWildcard(require("../computedHidden"));

var computedValue = _interopRequireWildcard(require("../computedValue"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var isOfProfessionType = computedHidden.isOfProfessionType('Company');
exports.isOfProfessionType = isOfProfessionType;

var isNotProfessionType = _fp["default"].negate(isOfProfessionType);

exports.isNotProfessionType = isNotProfessionType;
var getProfessionOfPrimarySourceXml = computedValue.computeProfessionXmlFrom('professionofprimarysource');
exports.getProfessionOfPrimarySourceXml = getProfessionOfPrimarySourceXml;
var getProfessionOfReporterXml = computedValue.computeProfessionXmlFrom('professionofreporter');
exports.getProfessionOfReporterXml = getProfessionOfReporterXml;

var populateSenderType = _fp["default"].cond([[isOfProfessionType, computedValue.always('1')], [_fp["default"].identity, computedValue.get('_defaultValues.sendertype')]]);

exports.populateSenderType = populateSenderType;

var orClear = function orClear(fn) {
  return _fp["default"].cond([[isOfProfessionType, fn], [_fp["default"].identity, computedValue.clearField]]);
};

exports.orClear = orClear;

var noopOrCallback = function noopOrCallback(fn) {
  return _fp["default"].cond([[isOfProfessionType, computedValue.noop], [_fp["default"].identity, fn]]);
};

exports.noopOrCallback = noopOrCallback;
var populateReporterTitle = noopOrCallback(computedValue.get('personaldetailstitle'));
exports.populateReporterTitle = populateReporterTitle;
var populateReporterFirstName = noopOrCallback(computedValue.get('personaldetailsgivename'));
exports.populateReporterFirstName = populateReporterFirstName;
var populateReporterLastName = noopOrCallback(computedValue.get('personaldetailsfamilyname'));
exports.populateReporterLastName = populateReporterLastName;

var populateReporterProfessionXml = _fp["default"].cond([[computedHidden.fieldIsTruthy('professionofprimarysource'), getProfessionOfPrimarySourceXml], [_fp["default"].identity, getProfessionOfReporterXml]]);

exports.populateReporterProfessionXml = populateReporterProfessionXml;
var getProfessionOfPersonalDetailsXml = computedValue.computeProfessionXmlFrom('personaldetailsprofession');
exports.getProfessionOfPersonalDetailsXml = getProfessionOfPersonalDetailsXml;