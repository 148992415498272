import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import ButtonBase from '@material-ui/core/ButtonBase'
import { withStyles } from '@material-ui/core/styles'
import AlertBadge from '../../containers/AlertBadge'

import DrawerBar from '../DrawerBar'

import style from './style'
import { translations } from '../../config'

const SideMenu = ({
  items,
  isOpen,
  onClose,
  onMenuItemClick,
  footerElement,
  classes,
  isUserAssessor
}) => (
  <Drawer
    open={isOpen}
    onClose={onClose}
    anchor='left'
    classes={{ paper: classes.rootMenu }}
    role='navigation'
  >
    <DrawerBar closeDrawer={onClose} left />
    <nav
      aria-label={translations('Menu Title')}
      className={classes.container}
    >
      <ul className={classes.list}>
        {!isUserAssessor && items.map(({ id, title, badgeType }) => {
          return (
              <li key={id}>
                  <ButtonBase focusRipple onClick={() => onMenuItemClick(id)} classes={{ root: classes.menuItem }}>
                    <AlertBadge type={badgeType} variant="dot">
                      {translations(`App Name - ${title}`)}
                    </AlertBadge>
                  </ButtonBase>
              </li>
          )
        })}
      </ul>
    </nav>

    <div className={classes.footer}>
      {footerElement}
    </div>
  </Drawer>
)

SideMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    title: PropTypes.string
  })),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func,
  isUserAssessor: PropTypes.bool
}

export default withStyles(style)(SideMenu)
