import { connect } from 'react-redux'
import NewsLoadingBar from './NewsLoadingBar'
import { selectors as newsSelectors } from '../../../../store/modules/news'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const isLoadingNews = newsSelectors.getIsLoading(state)
  return {
    ...ownProps,
    isLoading: isLoadingNews
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NewsLoadingBar)
