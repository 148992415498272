import { colors } from '../../../config/theme'

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '100%',
    paddingTop: 17
  },
  inputInkbar: {
    color: colors.materialUIFormGrey
  }
}

export default theme => styles
