import { connect } from 'react-redux'
import { compose } from 'recompose'

import SelectOrganisationScreen from './SelectOrganisationScreen'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

const enhancer = compose(
  connect(state => ({
    pageTitle: platformSelectors.getPageTitle(state)('Select Organisation Title')
  }))
)

export default enhancer(SelectOrganisationScreen)
