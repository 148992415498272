export default theme => ({
  buttonContainer: {
    border: '1px solid',
    width: '35px',
    height: '35px',
    marginLeft: '11px'
  },
  phoneIconPosition: {
    width: '19px'
  },
  smsIconPosition: {
    width: '22px'
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
})
