const styles = theme => ({
  skipLink: {
    color: theme.header.textColor,
    backgroundColor: theme.palette.common.black,
    padding: '1rem',
    position: 'absolute',
    left: '-9999em',
    '&:focus': {
      top: '10px',
      left: '10px',
      zIndex: 1300
    }
  }
})

export default theme => styles(theme)
