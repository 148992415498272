const borderColor = 'rgba(0,0,0,0.42)'

export default () => ({
  wrapper: {
    border: `1px solid ${borderColor}`
  },
  toolbar: {
    border: 0,
    borderRadius: 0,
    borderBottom: `1px solid ${borderColor}`
  },
  editor: {
    padding: '0 15px',
    minHeight: 150
  },
  label: {
    position: 'relative',
    marginTop: -15,
    marginBottom: 20
  }
})
