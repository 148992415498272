import { asyncActions, selectors, actions } from '../../../../../store/modules/terminology'
import store from '../../../../../store'
export const config = () => ({
    formName: 'deleteArrangement',
    title: 'Terminology - Delete Arrangement',
    schema: [],
    async onSubmit(_response, dispatch) {
        const state = store.getState()
        const terminologyArrangementId = selectors.getActiveArrangementId(state)
        await dispatch(asyncActions.deleteTerminologyArrangement({ terminologyArrangementId }))
        await dispatch(actions.resetStore())
        await dispatch(asyncActions.getTerminologyArrangements())
        await dispatch(actions.setFirstArrangementAsActive())
    }
})
