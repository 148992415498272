import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class PagesActions {
  getPagesForOrganisation = ({organisationId}) => ({
    type: constants.GET_PAGES_FOR_ORGANISATION,
    promise: () => digitalStoreSdk.pages.fetchPagesForOrganisation({ organisationId })
  })

  setActivePageId = (pageId) => ({
    type: constants.SET_ACTIVE_PAGE,
    payload: { pageId }
  })

  getPage = (id) => ({
    type: constants.GET_PAGE,
    payload: { id },
    promise: () => digitalStoreSdk.pages.fetchPageById({ id })
  })

  updatePage = (id, pageData) => ({
    type: constants.UPDATE_PAGE,
    payload: { id },
    promise: () => digitalStoreSdk.pages.updatePageById({ id, ...pageData })
  })

  createPage = (id, pageData) => ({
    type: constants.UPDATE_PAGE,
    payload: { id },
    promise: () => digitalStoreSdk.pages.createPage({ ...pageData })
  })

  deletePage = (id) => ({
    type: constants.DELETE_PAGE,
    payload: { id },
    promise: () => digitalStoreSdk.pages.deletePage({ id })
  })
}

export default new PagesActions()