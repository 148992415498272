"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "action", {
  enumerable: true,
  get: function get() {
    return _actionHandlers.action;
  }
});
exports.computeOtherValuesHelper = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _actionHandlers = require("./actionHandlers");

var _changeRequestCreator = require("./changeRequestCreator");

var _utilities = require("./utilities");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var computeOtherValuesHelper = function computeOtherValuesHelper(computeOtherValuesFn) {
  return function (value, formValues, parentFormValues, origin) {
    var computedOtherValuesActions = _fp["default"].castArray((computeOtherValuesFn || _fp["default"].noop)(value, formValues, parentFormValues) || []);

    return _fp["default"].flatMap(function (computedOtherValuesAction) {
      var _ref = computedOtherValuesAction || {},
          _ref$type = _ref.type,
          type = _ref$type === void 0 ? _actionHandlers.action.NO_OPERATION : _ref$type,
          _ref$payload = _ref.payload,
          payload = _ref$payload === void 0 ? {} : _ref$payload;

      var value = payload.value,
          field = payload.field;

      var targetValueFromReduxStore = _fp["default"].get(field, formValues);

      if (type === _actionHandlers.action.NO_OPERATION) {
        return [];
      }

      return new _changeRequestCreator.ChangeRequestCreator(value, field).chain(_utilities.liftSingleRepeatables).chain(_utilities.attachIdsToRepeatables).chain((0, _utilities.manageExistingValues)({
        payload: payload,
        formValues: formValues,
        origin: origin
      })).chain((0, _actionHandlers.handleAdditive)({
        type: type,
        formValues: formValues,
        targetValueFromReduxStore: targetValueFromReduxStore
      })).chain((0, _actionHandlers.handleDestructive)({
        type: type,
        formValues: formValues,
        targetValueFromReduxStore: targetValueFromReduxStore
      })).handleComputedRepeatablesField({
        origin: origin,
        targetValueFromReduxStore: targetValueFromReduxStore,
        formValues: formValues,
        field: field
      }).unwrap();
    }, computedOtherValuesActions);
  };
};

exports.computeOtherValuesHelper = computeOtherValuesHelper;