const gutter = 8

export default theme => ({
  container: {
    width: '100%'
  },
  inner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: -0.5 * gutter,
    flex: 1,
    '& > button': {
      margin: 0.5 * gutter
    }
  },
  alignLeft: {
    justifyContent: 'flex-start'
  },
  alignCenter: {
    justifyContent: 'center'
  },
  alignRight: {
    justifyContent: 'flex-end'
  },
  modalColumn: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  wrap: {
    'flex-wrap': 'wrap'
  },
  column: {
    flexDirection: 'column'
  }
})
