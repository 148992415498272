import { connect } from 'react-redux'
import { compose, withProps, withHandlers, lifecycle, withPropsOnChange } from 'recompose'
import { push } from 'connected-react-router'
import ReportsShareScreen from './ReportsShareScreen'
import { actions as reportsActions } from '../../../store/modules/reports'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as reportsSelectors } from '../../../store/modules/reports'
import _ from 'lodash'
import { translations } from '../../../config'
import { actions as formSchemasActions, selectors as formSchemaSelectors } from '../../../store/modules/formSchemas'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { noPermissionRoles } from '../helpers'

const mapStateToProps = (state) => {
  return {
    pageTitle: platformSelectors.getPageTitle(state)('Shared Reports - Page Title'),
    results: reportsSelectors.getResults(state),
    isLoading: reportsSelectors.getIsLoading(state),
    allSchemas: formSchemaSelectors.getAllFormSchemas(state),
    userRole: authSelectors.getRoleValue(state)
  }
}

const enhancer = compose(
  connect(mapStateToProps),
  lifecycle({
    componentDidMount () {
      if (!noPermissionRoles.includes(this.props.userRole)) {
        const urlString = _.get(this.props, 'location.pathname')
        const extReportId = urlString.split('/reports/share/')[1]

        this.props.dispatch(reportsActions.fetchReportsShare({ extReportId }))
        this.props.dispatch(formSchemasActions.fetchAllFormSchemas())
      }
    }
  }),
  withProps((props) => {
    const { results, userRole } = props
    return {
      headers: [
        { id: 'extReportId', label: translations('Title') },
        { id: 'createdAt', label: translations('Created') }
      ],
      data: results,
      count: _.size(results),
      noPermission: noPermissionRoles.includes(userRole)
    }
  }),
  withHandlers({
    onClick: ({ dispatch }) => (id) => {
      const link =  `/reports/permalink/${id}`
      dispatch(push(link))
    }
  })
)

export default enhancer(ReportsShareScreen)
