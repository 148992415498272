import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { InputLabel } from '@material-ui/core'
import { FormHelperText } from '@material-ui/core'
import cx from 'classnames'

import { getImage } from '../../Images'
import { translations } from '../../../config'
import Button from '../../Button'
import style from './style'
import imageUploadService from '../../../services/imageUploadService/imageUploadService'

class ImagePicker extends Component {
  state = {}
  fileSelectedWeb = (e) => {
    const { onChange } = this.props
    const file = e.target.files[0]
    const blobUrl = window.URL.createObjectURL(file)
    this.setState && this.setState({ fileName: file.name })
    onChange(blobUrl)
  }
  fileSelectedCordova = (file) => {
    const { onChange } = this.props
    this.setState && this.setState({ fileName: 'File attached' })
    onChange(file)
  }

  openImagePickerWeb = () => {
    const { filepicker } = this.refs
    filepicker.click()
  }
  _onDeleteClick = () => {
    const { onChange } = this.props
    const { filepicker } = this.refs
    onChange(null)
    filepicker.value = null
  }
  _renderDeleteFileButton = () => {
    return (
      <Button
        color={'white'}
        raised
        onClick={this._onDeleteClick}
      >
        {translations('Delete')}
      </Button>
    )
  }
  _renderButtonCordova = () => {
    const { classes, buttonSpacing, value } = this.props
    if (value) {
      return this._renderDeleteFileButton()
    } else {
      const buttonClasses = cx(classes.submitButtonHalf, {
        [classes.buttonSpace]: buttonSpacing
      })
      return (
        <div>
          <Button
            className={buttonClasses}
            color={'white'}
            raised
            size='small'
            onClick={this._openImagePickerCamera}
          >
            <div className={classes.submitButtonLabelContainer}>
              <img className={classes.submitButtonLabelIcon} src={getImage('camera')} alt='' />
              <div className={classes.submitButtonLabelText}>
                {translations('Take Photo')}
              </div>
            </div>
          </Button>
          <Button
            className={classes.submitButtonHalf}
            color={'white'}
            raised
            size='small'
            onClick={this._openImagePickerLibrary}
          >
            <div className={classes.submitButtonLabelContainer}>
              <img className={classes.submitButtonLabelIcon} src={getImage('addFromGallery')} alt='' />
              <div className={classes.submitButtonLabelText}>
                {translations('Add from Gallery')}
              </div>
            </div>
          </Button>
        </div>
      )
    }
  }
  _openImagePickerLibrary = () => imageUploadService.openImagePickerLibrary(this.fileSelectedCordova)
  _openImagePickerCamera = () => imageUploadService.openImagePickerCamera(this.fileSelectedCordova)
  _renderButtonWeb = () => {
    if (this.props.value) {
      return this._renderDeleteFileButton()
    } else {
      return (
        <Button
          color={'white'}
          raised
          size='small'
          onClick={this.openImagePickerWeb}
        >
          {this.props.value ? translations('Change') : translations('Add Image')}
        </Button>
      )
    }
  }
  renderButton = () => {
    if (window.cordova) {
      return this._renderButtonCordova()
    } else {
      return this._renderButtonWeb()
    }
  }
  renderPreview = () => {
    const { classes, value } = this.props
    const { fileName } = this.state
    if (_.startsWith(value, 'blob:')) {
      return <p className={classes.fileName}>{fileName}</p>
    }
    if (value) {
      return <img src={value} className={classes.imagePreview} alt='' />
    }
  }

  renderHelp = () => {
    const { help } = this.props

    if (!help) {
      return false
    }

    return <FormHelperText>{translations(help)}</FormHelperText>
  }

  render () {
    const {
      classes,
      className,
      label,
      shrink
    } = this.props

    return (
      <div className={className}>
        <InputLabel
          htmlFor={label}
          key='label'
          shrink={shrink}
        >
          {label}
        </InputLabel>
        <div className={classes.buttonContainer}>
          {this.renderPreview()}
          {this.renderButton()}
          {!window.cordova
            ? <input onChange={this.fileSelectedWeb} className={classes.imagePicker} ref={'filepicker'} type='file' accept='image/png, image/gif, image/jpeg, image/jpg, image/svg' />
            : null
          }
        </div>
        {this.renderHelp()}
      </div>
    )
  }
}

ImagePicker.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  shrink: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  value: PropTypes.string,
  help: PropTypes.string,
  buttonSpacing: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

export default withStyles(style)(ImagePicker)
