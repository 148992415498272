const style = theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  status: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5)
    }
  },
  column: {
    flexDirection: 'column'
  }
})

export default style
