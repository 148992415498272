import { useEffect } from 'react'
import fp from 'lodash/fp'

import { asyncActions } from '.'

export const useFetchMaster = ({ dispatch, page, filters }) => {
  useEffect(() => {
    if (!fp.isEmpty(filters)) {
      dispatch(asyncActions.getMaster())
    }
  }, [dispatch, filters, page])
}