import React, { useEffect, useState } from 'react'
import { updateSyncErrors, getFormValues } from 'redux-form'

import { FormControl } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Input, Dropdown } from '..'
import * as validators from '../../../validators'
import { translations } from '../../../config'

import { useShallowEqualSelector } from '../../../hooks'

import style from './style'

import _ from 'lodash'
import { useDispatch } from 'react-redux'

/**
 * Dropdown of options with custom option
 * Custom reveals a field to allow the user to enter their custom value.
 * If required a custom error message can be enabled to allow further customisations.
 */
const OptionSelector = (props) => {
  const { classes, options, id, formName, allowCustomError, translationKeys, customFieldValidators } = props
  const formValues = useShallowEqualSelector(getFormValues(formName)) || {}
  const fieldValue = formValues[id]
  const isCustom = fieldValue === 'custom'
  const dispatch = useDispatch()

  useEffect(() => {
    // Call update sync errors on fieldValue change
    // as it is not called when a field is unregistered
    // which in turns keeps old errors
    dispatch(updateSyncErrors(formName));
  }, [fieldValue])

  return (
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <FormControl className={classes.field} fullWidth>
          <Dropdown
            label={translations(translationKeys.selectorField)}
            name={id}
            options={[
              ...options,
              {
                value: 'custom',
                label: 'Custom'
              }
            ]}
          />
        </FormControl>
      </Grid>
      {isCustom && <Grid item sm={8}>
        <FormControl className={classes.field} fullWidth>
          <Input
            label={`${translations(translationKeys.customField)} *`}
            name={`${id}Custom`}
            validate={[validators.required, ...customFieldValidators]}
            help={translations(translationKeys.customHintField)}
            shrink
          />
        </FormControl>
      </Grid>}
      {(allowCustomError && isCustom) && <Grid item sm={12}>
        <FormControl className={classes.field} fullWidth>
          <Input
            label={translations(translationKeys.customErrorField)}
            name={`${id}CustomError`}
            help={translations(translationKeys.customErrorFieldHelp)}
            shrink
          />
        </FormControl>
      </Grid>}
    </Grid>
  )
}

OptionSelector.defaultProps = {
  options: [],
  allowCustomError: false,
  translationKeys: {
    selectorField: 'OptionSelector - Option',
    customField: 'OptionSelector - Custom option',
    customErrorField: 'OptionSelector - Custom error message',
    customErrorFieldHelp: 'OptionSelector - Custom error message help',
    customFieldHelp: 'OptionSelector - Custom option help'
  },
  customFieldValidators: []
}

export default withStyles(style)(OptionSelector)
