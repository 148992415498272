import _ from 'lodash'
import React, { useCallback, useState, useEffect } from 'react'
import { utilities } from '@redant/mhra-form-schema-library'
import ReportFormSection from '../ReportFormSection'
import styled from 'styled-components'
import Button from '../Button'

const Content = styled.div`
  width: 100%;
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 16px;
  margin-left: 15px;
`

const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 15px;
  flex-direction: column;
  gap: 10px;
`

const StandardFormDisplay = (props) => {
  const {
    schemaName,
    schemaArgs,
    form: {
      batch,
      change,
      submit,
      getFieldState,
      getState
    },
    values,
    formSchemaLibrary,
    actions = [],
    handleSubmit,
    organisationDetails,
    professions,
    submitText = 'Submit',
    fullWidthSubmit,
    privacyPolicyLink
  } = props

  const [formSections, setFormSections] = useState()

  const { submitError, submitting } = getState()

  const schema = formSchemaLibrary.processSchema(schemaName, {})
  const changeField = useCallback((_form, field, value) => change(field, value), [change])
  const parseSchema = useCallback(() => {
    return utilities.parseDynamicSchemaSections({ schema, formValues: values, changeField, denyList: [], batchChanges: batch })
      .then((schemaSections) => {
        const parser = utilities.parseDynamicSchema(values, {}, changeField, schemaName, false, {}, organisationDetails, {}, professions, { batchChanges: batch })
        const formSections = {}
        for (const sectionKey in schemaSections) {
          const formSection = schemaSections[sectionKey]
          let formSectionSchema = parser(formSection.schema)
          if (!formSection.hideFromForm && utilities.sectionShouldRender({ fields: formSectionSchema })) {
            formSections[sectionKey] = _.assign({}, formSection, { schema: formSectionSchema })
          }
        }

        return formSections
      })
  }, [schemaName, values, organisationDetails, professions])

  useEffect(() => {
    parseSchema()
      .then(formSections => setFormSections(formSections))
  }, [values])

  const renderFormSections = () => {
    return _.map(formSections, (section, index) => {
      const { schema } = section
      return (
        <ReportFormSection
          schema={schema}
          change={change}
          getFieldState={getFieldState}
          getState={getState}
          schemaName={schemaName}
          useSupportedFieldTypes={false}
          privacyPolicyLink={privacyPolicyLink}
        />
      )
    })
  }

  const renderActions = () => {
    return (
      <ActionContainer>
        <Button disabled={submitting} key={submitText} onClick={handleSubmit} fullWidth={fullWidthSubmit} type={'submit'}>{submitText}</Button>
        {_.map(actions, action => {
          return (
            <Button
              key={action.label}
              onClick={action.onClick}
              secondary={true}
            >
              {action.label}
            </Button>
          )
        })}
      </ActionContainer>
    )
  }

  return (
    <Content>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        {renderFormSections()}
        {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
        {renderActions()}
      </form>
    </Content>
  )
}

export default StandardFormDisplay