const style = (theme) => ({
    container: {
      padding: 50,
      [theme.breakpoints.down('xs')]: {
        padding: 15
      }
    },
    bodyContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    dropdownRoot: {
      width: '100%'
    }
  })
  
  export default style
  