import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

const TableHeader = ({ children, classes }) => {
  return (
    <Grid container spacing={0} hover='true' className={classes.tableHead} role='row'>
      {children}
    </Grid>
  )
}

export default withStyles(styles)(TableHeader)
