import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../../../../components/Button'
import { translations } from '../../../../../config'
import styles from '../style'

const DeleteConfirmModal = ({classes, closeModal, callback, translation}) => {
  return (
    <div className={classes.listContainer}>
      <h3>{translations(`Delete ${translation} Title`)}</h3>
      <p style={{marginBottom:'3rem'}}>{translations(`Delete ${translation} Text`)}</p>
      <div className={classes.buttonContainer}>
        <Button
          raised
          buttonType='white'
          onClick={() => closeModal()}
        >{translations('Cancel')}</Button>
        <Button
          raised
          buttonType='primary'
          onClick={()=> callback()}
        >{translations('Confirm')}</Button>
      </div>
    </div>
    )
  }

  DeleteConfirmModal.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    closeModal: PropTypes.func,
    deleteQuestion: PropTypes.func,
    sectionKey: PropTypes.number,
    questionKey: PropTypes.number,
  }

  export default withStyles(styles)(DeleteConfirmModal)