import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import VisuallyHidden from '../../../../components/VisuallyHidden'
import LoadingBar from '../../../../components/LoadingBar'
import P from '../../../../components/P'
import DataTable from '../../../../components/DataTable'

import ExportManagementSearchFilters from '../ExportManagementSearchFilters'
import ExportManagementRow from '../ExportManagementRow'
import ListViewLoadMore from '../../../../components/ListViewLoadMore'

import { translations } from '../../../../config'
import Heading from '../../../../components/Heading'
import Loader from '../../../../components/Loader'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { selectors as exportManagementSelectors, actions as exportManagementActions } from '../../../../store/modules/exportManagement'
import _ from 'lodash'
import { styled } from '@material-ui/core'

export const STATUS_TYPES = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  EXPIRED: 'EXPIRED'
}

// eslint-disable-next-line react/prop-types
const renderResults = ({ classes, headers, data, isLoading, hasResults = false, isEmpty = false, canLoadMore = false, loadNext, count, updateFilter, rowMoreOptions }) => {
  const NoResultsContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'center',
    textTransform: 'uppercase'
  }))

  if (isLoading && !canLoadMore) {
    return <Loader />
  }

  if (!hasResults) {
    return (
      <NoResultsContainer>
        <P role='status' value={translations('No results found')} />
      </NoResultsContainer>
    )
  }
  return (
    <Fragment>
      <DataTable
        headers={headers}
        data={data}
        tableId={'export-management-table'}
        tableHeading={'Exports'}
        count={count}
        rowComponent={ExportManagementRow}
        rowProps={{ updateFilter, rowMoreOptions }}
      />
      {canLoadMore && <ListViewLoadMore onClick={() => loadNext()} isLoading={isLoading} />}
    </Fragment>
  )
}

const ExportManagementScreen = (props) => {
  const {
    classes
  } = props

  const isLoading = useSelector(exportManagementSelectors.isLoading)
  const pageTitle = useSelector(platformSelectors.getPageTitle)('Export Management Title')
  const exports = useSelector(exportManagementSelectors.getResults)
  const canLoadMore = useSelector(exportManagementSelectors.getCanLoadMore)

  const data = exports

  const headers = [
    { id: 'name', label: translations('Export Management - Name'), options: { align: 'left' } },
    { id: 'status', label: translations('Export Management - Status'), options: { align: 'center' } },
    { id: 'authorId', label: translations('Export Management - Author'), options: { align: 'center' } },
    { id: 'sharedWith', label: translations('Export Management - Shared With'), options: { align: 'center' } },
    { id: 'createdAt', label: translations('Export Management - Created Date'), options: { align: 'center' } },
    { id: 'file', label: translations('Export Management - File'), options: { align: 'center' } }
  ]

  const count = _.size(data)
  const hasResults = _.size(data) > 0

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(exportManagementActions.fetchExportFresh({ dateFrom: null, dateTo: null, exportType: null}))
  },[])

  const loadNext = () => {
    dispatch(exportManagementActions.fetchExportNext())
  }

  const updateFilter = (filter) => {
    dispatch(exportManagementActions.changeFilter({ filter }))
    dispatch(exportManagementActions.fetchExportFresh({ dateFrom: null, dateTo: null, exportType: null}))
  }

  return (
    <div className={classes.container}>
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </>
        )}
      />
      <div className={classes.content}>
        <LoadingBar isLoading={isLoading} />
        <ExportManagementSearchFilters />
        {renderResults({
          classes,
          headers,
          data,
          hasResults,
          isLoading,
          canLoadMore,
          loadNext,
          count,
          updateFilter
        })}
      </div>
    </div>
  )
}

ExportManagementScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasResults: PropTypes.bool,
  canLoadMore: PropTypes.bool,
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loadNext: PropTypes.func.isRequired,
  count: PropTypes.number,
  updateFilter: PropTypes.func.isRequired,
}

export default withStyles()(ExportManagementScreen)
