const style = () => ({
  rightButtonContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%'
  },
  buttonContainer: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    width: 30,
    height: 30,
    marginRight: 10,
    fontSize: 26,
    borderRadius: 2
  },
  visibleFormBody: {
    width: 'auto'
  },
  notVisible: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 13,
    width: '30px',
    height: '3px',
    backgroundColor: '#000',
    transform: 'rotate(45deg)'
  }
})

export default style
