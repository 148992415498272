import { theme as appTheme, colors } from '../../config/theme'

const style = theme => ({
  container: {
    background: colors.lightGrey,
    padding: '10px 10px',
    paddingLeft: theme.spacing(4),
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '10px 20px',
      paddingLeft: theme.spacing(4)
    },
    '&:before': {
      content: '""',
      display: 'block',
      width: 6,
      height: 6,
      backgroundColor: '#000000',
      borderRadius: '50%',
      position: 'absolute',
      left: theme.spacing(2),
      top: '50%',
      marginTop: -3,
      marginLeft: -3
    }
  },
  imageContainer: {
    minWidth: 35,
    height: 35,
    background: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      minWidth: 45,
      height: 45
    }
  },
  image: {
    width: 15,
    [theme.breakpoints.up('sm')]: {
      width: 20
    }
  },
  receipt: {
    position: 'absolute',
    top: 3,
    right: 3,
    width: 10,
    height: 10
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between'
    }
  },
  notificationText: {
    ...appTheme.p.p,
    color: colors.text,
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  textLastItem: {
    paddingBottom: 10
  },
  togglesContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px 0 5px auto',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      margin: '0 15px 0 auto',
      flexDirection: 'row'
    }
  },
  isReadState: {
    background: 'white',
    '&:before': {
      display: 'none'      
    }
  },
  userTextContainer: {
    order: 2,
    marginTop: 5,
    textAlign: 'right',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
      order: 1,
      marginRight: 5
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      display: 'flex'
    }
  },
  toggles: {
    display: 'flex',
    order: 1,
    marginLeft: '-5px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      order: 2,
      marginLeft: 0
    }
  },
  notificationTextStyle: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 5
    }
  },
  circleButtonStyle: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 11
    }
  },
  hideSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  hideMediumUp: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
})

export default style
