import _ from 'lodash'
import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'

class SelectedReceiverActions {
  fetchSelectedReceiver = ({ id } = {}) => ({
    type: constants.FETCH_SELECTED_RECEIVER,
    promise: (dispatch, getState) => {
      const receiverId = id || authSelectors.getUserSelectedReceiverId(getState())
      return digitalStoreSdk.receivers
        .fetchReceiver({ id: receiverId })
    }
  })

  fetchSelectedReceiverForPublicForm = ({ organisationId }) => ({
    type: constants.FETCH_SELECTED_RECEIVER_FOR_PUBLIC_REPORT_FORM,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.organisations.fetchOrganisation({ id: organisationId })
        .then(org => {
          return digitalStoreSdk.receivers.fetchReceiver({ id: _.get(org, 'receiverId') })
        })
    }
  })
}

export default new SelectedReceiverActions()
