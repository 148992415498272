import { colors } from '../../../config/theme'

const styles = {
  floatingLabelFocusStyle: { color: colors.accent },
  underlineFocusStyle: { borderColor: colors.accent },
  errorStyle: { color: colors.error },
  errorText: { color: colors.error },
  borderFocussed: { borderColor: colors.lightGrey },
  inputInkbar: {
    '&:after': {
      backgroundColor: colors.text
    }
  },
  endAdornmentTextStyle: { 
    ['@media (min-width: 600px)']: {
      width: '300px', textAlign: 'right' }
    },
}

export default theme => styles
