import _ from 'lodash'
import {
  compose,
  withHandlers,
  withPropsOnChange
} from 'recompose'
import { connect } from 'react-redux'

import SendIcon from '@material-ui/icons/Send'
import RefreshIcon from '@material-ui/icons/Refresh'
import ReceiverModal from '../Modals/ReceiverModal'

import { actions as submissionReportsActions, selectors as submissionReportsSelectors } from '../../../../store/modules/submissionReports'

import modalService from '../../../../services/modalService'
import toastService from '../../../../services/toastService'
import { translations } from '../../../../config'

import SubmissionReportsToolbar from './SubmissionReportsToolbar'

const mapStateToProps = (state) => {
  return {
    count: submissionReportsSelectors.getSelectedCount(state),
    selected: submissionReportsSelectors.getSelected(state),
    receiverId: submissionReportsSelectors.getSelectedReceiverId(state),
    canRetry: submissionReportsSelectors.getCanRetrySelected(state)
  }
}

const enhancer = compose(
  connect(mapStateToProps),
  withPropsOnChange(['count'], (props) => {
    const { count } = props
    return {
      mode: count > 0 ? 'SELECTION' : 'DEFAULT'
    }
  }),
  withHandlers({
    toggleSelectAll: ({ dispatch }) => () => {
      dispatch(submissionReportsActions.toggleSelectAll())
    },
    refreshResults: ({ dispatch }) => async () => {
      try {
        await dispatch(submissionReportsActions.searchFresh())
        toastService.action({
          type: 'success',
          message: translations('Results refreshed - Success')
        })
      } catch (error) {
        console.error(error)
        toastService.action({
          type: 'error',
          message: translations('Results refreshed - Error')
        })
      }
    },
    sendToReceiver: ({ dispatch, selected, receiverId }) => async () => {
      modalService.open({
        component: ReceiverModal,
        smallModal: true,
        modalIndex: 1,
        receiverId,
        onSelect: async ({ receiverId: nextReceiverId }) => {
          try {
            const mappedResources = _.map(selected, (submissionReportId) => {
              return {
                receiverId: nextReceiverId,
                submissionReportId
              }
            })
            await dispatch(submissionReportsActions.distributeSubmissions({
              resources: mappedResources,
              refreshResults: true
            }))
            modalService.close({ modalIndex: 1 })
          } catch (error) {
            console.error(error)
            throw error
          }
        }
      })
    }
  }),
  withPropsOnChange(['mode', 'canRetry'], (props) => {
    const { mode, canRetry, refreshResults, sendToReceiver } = props
    const actions = []

    if (mode === 'DEFAULT') {
      actions.push({
        id: 'refresh',
        label: translations('Results refreshed - Prompt'),
        icon: RefreshIcon,
        onClick: refreshResults
      })
    }

    if (mode === 'SELECTION') {
      actions.push({
        id: 'sendReceiver',
        label: translations('Send to receiver'),
        icon: SendIcon,
        onClick: sendToReceiver,
        disabledTooltip: translations('Send to receiver toolbar disabled'),
        disabled: !canRetry
      })
    }

    return {
      actions
    }
  })
)

export default enhancer(SubmissionReportsToolbar)
