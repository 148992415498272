import _ from 'lodash'
import { translations } from '../../../../../config'

export const getSchema = (options) => {
  const {
    emailTemplateOptions,
    audiences,
    categoryOptions,
    subCategoryOptions,
    reportTypeOptions,
    observeStudyTypeOptions,
    isFollowUp
  } = options
  return isFollowUp ? ([
    {
      id: 'emailTemplateId',
      field: 'Dropdown',
      props: {
        label: `${translations('Acknowledgement email template')}`,
        name: 'emailTemplateId',
        options: emailTemplateOptions,
        disabled: _.size(emailTemplateOptions) < 1,
        shrink: true
      }
    }
  ]) : ([
    {
      id: 'category',
      field: 'Dropdown',
      props: {
        label: 'Category',
        name: 'category',
        options: categoryOptions,
        nullOptionLabel: 'None'
      }
    },
    {
      id: 'subCategory',
      field: 'Dropdown',
      props: {
        label: 'Sub Category',
        name: 'subCategory',
        options: subCategoryOptions,
        nullOptionLabel: 'None'
      }
    },
    {
      id: 'reporttype',
      field: 'Dropdown',
      props: {
        label: 'Report Type',
        name: 'reporttype',
        options: reportTypeOptions
      }
    },
    {
      id: 'observestudytype',
      field: 'Dropdown',
      props: {
        label: 'Study type',
        name: 'observestudytype',
        options: observeStudyTypeOptions
      }
    },
    {
      id: 'audienceId',
      field: 'Dropdown',
      props: {
        label: 'Audience',
        name: 'audienceId',
        options: audiences,
        noNullOption: true
      }
    },
    {
      id: 'senderevid',
      field: 'Input',
      props: {
        label: 'Report Identifier',
        name: 'senderevid'
      }
    },
    {
      id: 'emailTemplateId',
      field: 'Dropdown',
      props: {
        label: `${translations('Acknowledgement email template')}`,
        name: 'emailTemplateId',
        options: emailTemplateOptions,
        disabled: _.size(emailTemplateOptions) < 1,
        shrink: true
      }
    }
  ])
}
