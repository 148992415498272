import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tooltip from '@material-ui/core/Tooltip'

import { getImage } from '../Images'
import { styled } from '@material-ui/core/styles'
import { translations } from '../../config'

import toastService from '../../services/toastService'
const copyIcon = getImage('copyIcon')

const Button = styled('button')(({ theme }) => ({
  display: 'inline-block',
  width: 40,
  height: 40,
  padding: 0,
  margin: 0,
  border: `1px solid ${theme.palette.grey['300']}`,
  background: 'transparent',
  cursor: 'pointer',
  borderRadius: '50%',
  transition: '.3s background',
  outline: 'none',
  '&:hover, &:active, &:focus': {
    background: theme.palette.grey['200']
  },
  '& img': {
    width: 16,
    height: 16
  }
}))

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  verticalAlign: 'middle',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1)
}))

const CopyToClipboardButton = (props) => {
  const {
    text,
    component
  } = props

  const onCopy = () => {
    toastService.action({
      type: 'success',
      message: translations('Copied to clipboard', { name: text }),
      autoHideDuration: 6000
    })
  }

  return (
    <CopyToClipboard onCopy={onCopy} text={text}>
      <Tooltip title={translations('Copy to clipboard')} placement={'top'}>
        { component ||
          <ButtonContainer>
            <Button type='button'>
              <img src={copyIcon} alt={translations('Copy')} />
            </Button>
          </ButtonContainer>
        }
      </Tooltip>
    </CopyToClipboard>
  )
}

export default CopyToClipboardButton
