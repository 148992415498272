import _ from 'lodash'

export const advancedReportFormSchema = {
  schema: (id) => {
    return [
      {
        id: `${id}_attachment`,
        field: 'Repeatable',
        props: {
          label: 'Attachments',
          name: `${id}_attachment`,
          repeatableLabel: ['attachmentfile'],
          shrink: true,
          disableEdit: false,
          schema: [
            {
              id: 'attachmentfile',
              field: 'Input',
              props: {
                label: 'File Name',
                name: 'attachmentfile',
                required: true,
                shrink: true
              }
            },
            {
              id: 'attachmenturl',
              field: 'FilePicker',
              props: {
                label: 'Select File',
                name: 'attachmenturl',
                accept: ['.docx', '.xlsx', '.pptx', '.jpg', '.png', '.pdf'],
                required: true,
                shrink: true
              }
            },
            {
              id: 'attachmentcomments',
              field: 'Input',
              props: {
                label: 'File Comments',
                name: 'attachmentcomments',
                required: false,
                shrink: true,
                multiline: true,
                maxLength: 1000
              }
            }
          ]
        }
      }
    ]
  }
}