import React from 'react'

import P from '../../../../../components/P'
import BottomBar from '../../../../../components/BottomBar'
import HealthCheckFilters from '../../../AnalyticsScreen/HealthCheckFilters/HealthCheckFilters'
import SubHeader from '../../../../../components/SubHeader'
import BackBar from '../../../../../components/BackBar'
import H2 from '../../../../../components/H2'

import ReportFullListCollapsable from '../../../ReportFullListCollapsable'
import { translations } from '../../../../../config'
import _ from 'lodash'

const OrganisationReportingFullList = props => {
  const { organisationHealthCheck, reportSchema, lastUpdated } = props
  return <>
    <SubHeader
      leftContent={(
        <BackBar />
      )}
      centerContent={(
        <div>
          <H2 value={_.toUpper(reportSchema.title)} />
        </div>
      )}
    />
    <HealthCheckFilters preventDropdown />
    <ReportFullListCollapsable
      items={organisationHealthCheck}
      reportSchema={reportSchema}
      itemProps={{ chartCategory: reportSchema.chartCategory }}
    />
    <BottomBar relative>
      <div style={{ textAlign: 'center' }}>
        <P value={`${translations('Last Updated At:')} ${lastUpdated}`} />
      </div>
    </BottomBar>
  </>
}

export default OrganisationReportingFullList
