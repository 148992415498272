import {
  LOAD_INITIAL_VALUES,
  SET_FORMVIEW_ID,
  SET_SIDEBAR_STATE
} from './constants'


export const setFormViewId = (payload) => {
  return { type: SET_FORMVIEW_ID, payload }
}

export const setSidebarState = (payload) => {
  return { type: SET_SIDEBAR_STATE, payload }
}

export const loadInitialData = (payload) => {
  return { type: LOAD_INITIAL_VALUES, payload }
}