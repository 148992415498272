import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { translations } from '../../../../config'
import { phoneNumber } from '../../../../validators'

import { getImage } from '../../../Images'
import { mobileCheck } from '../../../../helpers'
import style from './style'

const phoneIcon = getImage('phoneIcon')

const PhoneIcons = props => {
  const { value, classes, onClickCall } = props

  // note: phoneNumber(value) returns true if NOT a valid phone number
  if (phoneNumber(value)) {
    return null
  }
  return (
    <div className={classes.iconContainer}>
      {
        mobileCheck
        ? <IconButton
          aria-label={translations('Start Call')}
          className={classes.buttonContainer}
          onClick={onClickCall}
        >
          <img src={phoneIcon} className={classes.phoneIconPosition} alt='' />
        </IconButton>
        : null
      }
    </div>
  )
}

export default withStyles(style)(PhoneIcons)
