import React, { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'
import PropTypes from 'prop-types'

import { Tabs, Tab } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import DropZone from '../../../../../components/DropZone'
import modalService from '../../../../../services/modalService'
import toastService from '../../../../../services/toastService'
import { translations } from '../../../../../config'
import digitalStoreSdk from '../../../../../digitalStoreSdk'

import { constants } from '../../../../../store/modules/reportConfiguration'
import { zipHelper, conditionsFormatter, replaceMultiple } from '../../../../../helpers'
import _ from 'lodash'

const { blobTob64 } = zipHelper
const { formatQueryBuilder } = conditionsFormatter

const replaceDataURI = replaceMultiple({
  'data:application/zip;base64': '',
  'data:application/x-zip-compressed;base64': ''
})


const toastError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Uploaded Report Config')
})
const toastSuccess = () => toastService.action({
  type: 'success',
  message: translations('Successfully - Uploaded Report Config')
})

const formId = constants.IMPORT_CONFIGURATION_FORM
const byIDTabId = 'byID'
const byUploadTabId = 'byUpload'

const ImportByIdForm = Form(formId)

const ContentContainer = styled('div')(({ theme }) => ({
  paddingTop: 25
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  root: {
    fontWeight: 700
  }
}))

const ImportModal = (props) => {
  const {
    formViewDispatch,
    loadFormView,
    setEdited,
    formSchemaName,
    loadConditions,
    formViewId
  } = props
  const [file, setFile] = useState([])
  const [fileData, setFileData] = useState(undefined)
  const [isLoadingFile, setIsLoadingFile] = useState(false)
  const [tabIndex, setTabIndex] = useState(byUploadTabId)
  const dismiss = () => modalService.close()
  const dispatch = useDispatch()

  const handleSubmit = () => {
    if (tabIndex === byIDTabId) {
      dispatch(submit(formId))
    } else if (tabIndex === byUploadTabId) {
      handleFileUpload()
    }
  }
  const handleFileUpload = () => {
    const zipData = replaceDataURI(fileData)
    setIsLoadingFile(true)
    digitalStoreSdk.formView.uploadZip({ zip: zipData, formViewId })
      .then(onUploadSuccess)
      .catch(toastError)
      .finally(() => { setIsLoadingFile(false) })
  }
  const onUploadSuccess = (result) => {
    const { conditions, conditionVersions, reportConfiguration } = result
    const viewJSON = _.get(reportConfiguration, 'formViewVersions.0.viewJSON', [])
    const previousConditionFields = []
    const previousConditionSections = []
    _.forEach(viewJSON, ({ fields: nextFields, ...sectionProps }) => {
      const sectionHasConditions = _.get(sectionProps, 'conditions', false)
      const fieldsWithConditions = _.filter(nextFields, ({ conditions }) => conditions && _.size(conditions))
      if (_.size(fieldsWithConditions) > 0) {
        previousConditionFields.push(...fieldsWithConditions)
      }
      if (_.size(sectionHasConditions) > 0) {
        previousConditionSections.push({ ...sectionProps, type: 'SECTION' })
      }
    })
    const nextConditions = _.map(conditions, (condition) => {
      const matchedCondition = _.findLast(conditionVersions, ({ conditionId }) => conditionId === condition.id)
      const matchedConditionVersionId = _.get(matchedCondition, 'id')
      const mappedMatchedConditions = _.get(matchedCondition, 'conditions')
      return {
        ...condition,
        conditions: formatQueryBuilder(mappedMatchedConditions),
        oldVersionId: matchedConditionVersionId
      }
    })
    formViewDispatch(loadFormView(viewJSON))
    loadConditions(nextConditions, { previousConditionFields, previousConditionSections })
    formViewDispatch(setEdited())
    dismiss()
    toastSuccess()
  }
  const handleFormSubmit = async (formData) => {
    let { sourceFormViewId } = formData
    try {
      const sourceFormView = await digitalStoreSdk.formView.fetchFormViewById({ id: sourceFormViewId })
      if (_.get(sourceFormView, 'formSchema.name') !== formSchemaName) {
        throw new Error('Failure - Report Configuration Schema Mismatch')
      }
      const viewJSON = _.get(sourceFormView, 'formViewVersions.0.viewJSON', [])
      formViewDispatch(loadFormView(viewJSON))
      formViewDispatch(setEdited())
      toastService.action({
        type: 'success',
        message: translations('Successfully - Upload Report Config')
      })
      dismiss()
    } catch (error) {
      let errorTranslation = 'Failure - Upload Report Config'
      if (_.startsWith(error.message, 'Failure -')) {
        errorTranslation = error.message
      }
      toastService.action({
        type: 'error',
        message: translations(errorTranslation)
      })
    }
  }

  const actions = useMemo(() => {
    return (
      [
        {
          success: true,
          text: translations('Import'),
          primary: true,
          onClick: handleSubmit,
          disabled: isLoadingFile
        },
        {
          text: translations('Cancel'),
          onClick: dismiss
        }
      ]
    )
  }, [tabIndex, isLoadingFile])


  const handleTabChange = (event, value) => {
    setTabIndex(value)
  }

  const getText = (state) => {
    return translations(`DropZone - ${state}`)
  }

  const handleDrop = (files, rejections) => {
    if (_.size(files) && !_.size(rejections)) {
      setFile(files)
      setIsLoadingFile(true)
      blobTob64({ blob: _.get(files, '0') })
        .then((fileData) => {
          setFileData(fileData)
        })
        .finally(() => {
          setIsLoadingFile(false)
        })
    }
  }

  const getFileStats = () => {
    const calcKb = (bytes) => _.round(bytes / 1000, 2)
    const calcMb = (bytes) => _.round(bytes / 10000000, 2)
    const fileName = _.get(file, '0.path')
    const fileBytes = _.get(file, '0.size')
    const size = calcMb(fileBytes) > 1 ? `${calcMb(fileBytes)}MB` : `${calcKb(fileBytes)}KB`
    return `${fileName} (${size})`
  }

  return (
    <BasicModalOverlay
      title={translations('Import Report Configuration - Modal Heading')}
      actions={actions}
    >
      <ImportByIdForm onSubmit={handleFormSubmit}>
        <ContentBoxBody>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            centered
            fullWidth
            indicatorColor='primary'
          >
            <StyledTab label={translations('Import Report Configuration - By Upload')} value={byUploadTabId} />
          </Tabs>
          <ContentContainer>
            {tabIndex === 'byUpload' &&
              <>
                <DropZone
                  accept='application/zip,application/x-zip-compressed,.zip'
                  minHeight='50vh'
                  onDrop={handleDrop}
                  text={getText('default')}
                  onDragText={getText('drag')}
                  onSuccessText={getText('success')}
                  onRejectText={getText('reject')}
                  multiple={false}
                />
                {!!_.size(file) && <p>{getFileStats()}</p>}
              </>
            }
          </ContentContainer>
        </ContentBoxBody>
      </ImportByIdForm>
    </BasicModalOverlay >
  )
}

ImportModal.propTypes = {
  formViewDispatch: PropTypes.func.isRequired,
  loadFormView: PropTypes.func.isRequired,
  setEdited: PropTypes.func.isRequired,
  formViewId: PropTypes.string.isRequired
}
export default ImportModal