import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class CommincationTemplatesActions {
  //Email
  fetchEmailTemplates = ({ type } = {}) => ({
    type: constants.GET_EMAIL_TEMPLATES,
    promise: () => digitalStoreSdk.communicationTemplates.fetchEmailTemplates({ type })
  })
  fetchOrganisationEmailTemplates = ({ organisationId }) => ({
    type: constants.GET_ORGANISATION_EMAIL_TEMPLATES,
    payload: { organisationId },
    promise: () => digitalStoreSdk.communicationTemplates.fetchOrganisationEmailTemplates({ organisationId })
  })
  fetchSystemEmailTemplates = () => ({
    type: constants.GET_SYSTEM_EMAIL_TEMPLATES,
    promise: () => digitalStoreSdk.communicationTemplates.fetchSystemEmailTemplates()
  })
  fetchOrganisationEmailTemplate = ({ organisationId, id }) => ({
    type: constants.GET_ORGANISATION_EMAIL_TEMPLATE_BY_ID,
    payload: { organisationId, id },
    promise: () => digitalStoreSdk.communicationTemplates.fetchOrganisationEmailTemplate({ organisationId, id })
  })
  fetchSystemEmailTemplate = ({ id }) => ({
    type: constants.GET_SYSTEM_EMAIL_TEMPLATE_BY_ID,
    payload: { id },
    promise: () => digitalStoreSdk.communicationTemplates.fetchSystemEmailTemplate({ id })
  })
  createOrganisationEmailTemplate = ({ organisationId, body }) => ({
    type: constants.CREATE_ORGANISATION_EMAIL_TEMPLATE,
    payload: { organisationId, body },
    promise: () => digitalStoreSdk.communicationTemplates.createOrganisationEmailTemplate({ organisationId, body })
  })
  createSystemEmailTemplate = ({ body }) => ({
    type: constants.CREATE_SYSTEM_EMAIL_TEMPLATE,
    payload: { body },
    promise: () => digitalStoreSdk.communicationTemplates.createSystemEmailTemplate({ body })
  })
  updateOrganisationEmailTemplate = ({ organisationId, id, body }) => ({
    type: constants.UPDATE_ORGANISATION_EMAIL_TEMPLATE,
    payload: { organisationId, id, body },
    promise: () => digitalStoreSdk.communicationTemplates.updateOrganisationEmailTemplate({ organisationId, id, body })
  })
  updateSystemEmailTemplate = ({ id, body }) => ({
    type: constants.UPDATE_SYSTEM_EMAIL_TEMPLATE,
    payload: { id, body },
    promise: () => digitalStoreSdk.communicationTemplates.updateSystemEmailTemplate({ id, body })
  })
  deleteOrganisationEmailTemplate = ({ organisationId, id }) => ({
    type: constants.DELETE_ORGANISATION_EMAIL_TEMPLATE,
    payload: { organisationId, id },
    promise: () => digitalStoreSdk.communicationTemplates.deleteOrganisationEmailTemplate({ organisationId, id })
  })
  deleteSystemEmailTemplate = ({ id }) => ({
    type: constants.DELETE_SYSTEM_EMAIL_TEMPLATE,
    payload: { id },
    promise: () => digitalStoreSdk.communicationTemplates.deleteSystemEmailTemplate({ id })
  })
  deleteSystemTemplateContentForLanguage = ({ id, languageCode }) => ({
    type: constants.DELETE_SYSTEM_EMAIL_TEMPLATE_CONTENT_FOR_LANGUAGE,
    payload: { id, languageCode },
    promise: () => digitalStoreSdk.communicationTemplates.deleteSystemTemplateContentForLanguage({ id, languageCode })
  })
  updateDefaultEmailTemplates = ({ organisationId, body }) => ({
    type: constants.UPDATE_DEFAULT_ORGANISATION_EMAIL_TEMPLATES,
    payload: { organisationId, body },
    promise: () => digitalStoreSdk.communicationTemplates.updateDefaultEmailTemplates({ organisationId, body })
  })
  //SMS
  fetchSmsTemplates = ({ type } = {}) => ({
    type: constants.GET_SMS_TEMPLATES,
    promise: () => digitalStoreSdk.communicationTemplates.fetchSmsTemplates({ type })
  })
  fetchOrganisationSmsTemplates = ({ organisationId }) => ({
    type: constants.GET_ORGANISATION_SMS_TEMPLATES,
    payload: { organisationId },
    promise: () => digitalStoreSdk.communicationTemplates.fetchOrganisationSmsTemplates({ organisationId })
  })
  fetchOrganisationSmsTemplate = ({ organisationId, id }) => ({
    type: constants.GET_ORGANISATION_SMS_TEMPLATE_BY_ID,
    payload: { organisationId, id },
    promise: () => digitalStoreSdk.communicationTemplates.fetchOrganisationSmsTemplate({ organisationId, id })
  })
  createOrganisationSmsTemplate = ({ organisationId, body }) => ({
    type: constants.CREATE_ORGANISATION_SMS_TEMPLATE,
    payload: { organisationId, body },
    promise: () => digitalStoreSdk.communicationTemplates.createOrganisationSmsTemplate({ organisationId, body })
  })
  updateOrganisationSmsTemplate = ({ organisationId, id, body }) => ({
    type: constants.UPDATE_ORGANISATION_SMS_TEMPLATE,
    payload: { organisationId, id, body },
    promise: () => digitalStoreSdk.communicationTemplates.updateOrganisationSmsTemplate({ organisationId, id, body })
  })
  deleteOrganisationSmsTemplate = ({ organisationId, id }) => ({
    type: constants.DELETE_ORGANISATION_SMS_TEMPLATE,
    payload: { organisationId, id },
    promise: () => digitalStoreSdk.communicationTemplates.deleteOrganisationSmsTemplate({ organisationId, id })
  })
  updateDefaultSmsTemplates = ({ organisationId, body }) => ({
    type: constants.UPDATE_DEFAULT_ORGANISATION_SMS_TEMPLATES,
    payload: { organisationId, body },
    promise: () => digitalStoreSdk.communicationTemplates.updateDefaultSmsTemplates({ organisationId, body })
  })
  //Push
  fetchPushTemplates = ({ type } = {}) => ({
    type: constants.GET_PUSH_TEMPLATES,
    promise: () => digitalStoreSdk.communicationTemplates.fetchPushTemplates({ type })
  })
  fetchOrganisationPushTemplates = ({ organisationId }) => ({
    type: constants.GET_ORGANISATION_PUSH_TEMPLATES,
    payload: { organisationId },
    promise: () => digitalStoreSdk.communicationTemplates.fetchOrganisationPushTemplates({ organisationId })
  })
  fetchOrganisationPushTemplate = ({ organisationId, id }) => ({
    type: constants.GET_ORGANISATION_PUSH_TEMPLATE_BY_ID,
    payload: { organisationId, id },
    promise: () => digitalStoreSdk.communicationTemplates.fetchOrganisationPushTemplate({ organisationId, id })
  })
  createOrganisationPushTemplate = ({ organisationId, body }) => ({
    type: constants.CREATE_ORGANISATION_PUSH_TEMPLATE,
    payload: { organisationId, body },
    promise: () => digitalStoreSdk.communicationTemplates.createOrganisationPushTemplate({ organisationId, body })
  })
  updateOrganisationPushTemplate = ({ organisationId, id, body }) => ({
    type: constants.UPDATE_ORGANISATION_PUSH_TEMPLATE,
    payload: { organisationId, id, body },
    promise: () => digitalStoreSdk.communicationTemplates.updateOrganisationPushTemplate({ organisationId, id, body })
  })
  deleteOrganisationPushTemplate = ({ organisationId, id }) => ({
    type: constants.DELETE_ORGANISATION_PUSH_TEMPLATE,
    payload: { organisationId, id },
    promise: () => digitalStoreSdk.communicationTemplates.deleteOrganisationPushTemplate({ organisationId, id })
  })
  updateDefaultPushTemplates = ({ organisationId, body }) => ({
    type: constants.UPDATE_DEFAULT_ORGANISATION_PUSH_TEMPLATES,
    payload: { organisationId, body },
    promise: () => digitalStoreSdk.communicationTemplates.updateDefaultPushTemplates({ organisationId, body })
  })
}

export default new CommincationTemplatesActions()
