import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from "react-i18next"

i18next
.use(LanguageDetector)
.use(initReactI18next)
.init({
  debug: false,
  fallbackLng: 'en',
  detection: {
    order: [ 'navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain' ]
  },
  ns: ['auth', 'translation'],
  defaultNS: 'translation',
  nsSeparator: '__'
})

export const translation = (key, options) => i18next.t(key, options)
export const addResources = (translations, ns = 'translation') => {
  for (let lang in translations) {
    i18next.addResourceBundle(lang, ns, translations[lang], false, true)
  }
}

export default i18next
