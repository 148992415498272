import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import SubmissionReportsSearchFilters from './SubmissionReportsSearchFilters'
import { actions as submissionReportsActions, selectors as submissionReportsSelectors } from '../../../../store/modules/submissionReports'
import { selectors as receiverSelectors } from '../../../../store/modules/receivers'
import { translations } from '../../../../config'

const mapStateToProps = state => {
  const filters = submissionReportsSelectors.getFilters(state)
  const numberOfActiveFilters = submissionReportsSelectors.getNumberOfActiveFilters(state)
  const results = submissionReportsSelectors.getResults(state)
  const receiverOptions = receiverSelectors.getReceiversForDropdown(state)

  const validationStatusOptions = [
    {
      label: translations('Case Management Status - VALID'),
      value: 'VALID'
    },
    {
      label: translations('Case Management Status - INVALID'),
      value: 'INVALID'
    },
    {
      label: translations('Case Management Status - NOT_VALIDATED'),
      value: 'NOT_VALIDATED'
    }
  ]

  const receiverStatusOptions = [
    {
      label: translations('Case Management Status - SUCCESS'),
      value: 'SUCCESS'
    },
    {
      label: translations('Case Management Status - PENDING'),
      value: 'PENDING'
    },
    {
      label: translations('Case Management Status - FAILED'),
      value: 'FAILED'
    },
    {
      label: translations('Case Management Status - TIMED_OUT'),
      value: 'TIMED_OUT'
    },
    {
      label: translations('Case Management Status - NOT_SENT'),
      value: 'NOT_SENT'
    },
    {
      label: translations('Case Management Status - IN_REVIEW'),
      value: 'IN_REVIEW'
    },
    {
      label: translations('Case Management Status - REJECTED'),
      value: 'REJECTED'
    }
  ]

  return {
    results,
    filters,
    numberOfActiveFilters,
    validationStatusOptions,
    receiverStatusOptions,
    receiverOptions
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    showReceiverStatusFilters: () => (state) => {
      const { receiverStatus, validationStatus } = state
      const isValidated = _.indexOf(['INVALID'], validationStatus) !== -1
      const isFailedOrTimedOut = _.indexOf(['FAILED', 'TIMED_OUT'], receiverStatus) !== -1
      return isValidated || isFailedOrTimedOut
    }
  }),
  withHandlers({
    updateFilters: ({ dispatch, filters, showReceiverStatusFilters }) => (filter) => {
      const newFilters = { ...filters, ...filter }

      if (!showReceiverStatusFilters(newFilters)) {
        newFilters.receiverErrorQuery = ''
      }

      dispatch(submissionReportsActions.changeFilters({ filters: newFilters }))
      dispatch(submissionReportsActions.searchFresh({ filters: newFilters }))
    },
    searchFresh: ({ dispatch, filters }) => () => {
      dispatch(submissionReportsActions.searchFresh({ filters }))
    }
  })
)(SubmissionReportsSearchFilters)
