import _ from 'lodash'

export const actions = {
  SET_ATTACHMENT_FIELDS: 'SET_ATTACHMENT_FIELDS',
  SET_ATTACHMENT_UPLOAD_TYPE: 'SET_ATTACHMENT_UPLOAD_TYPE',
  SET_FORM_CONTEXT: 'SET_FORM_CONTEXT'
}

export const initialState = {
  attachmentFields: [],
  attachmentUploadType: 'public',
  formContext: {
    formSchemaId: undefined,
    formView: undefined,
    reportId: undefined,
    followUp: undefined
  }
}

const reducers = {
  setAttachmentFields: (state, action) => {
    return {
      ...state,
      attachmentFields: _.get(action, 'payload.attachmentFields', [])
    }
  },
  setAttachmentUploadType: (state, action) => {
    return {
      ...state,
      attachmentUploadType: _.get(action, 'payload.isAuthenticated', false) ? 'auth' : 'public'
    }
  },
  setFormContext: (state, action) => {
    return {
      ...state,
      formContext: Object.assign({}, state.formContext, action.payload)
    }
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_ATTACHMENT_FIELDS:
      return reducers.setAttachmentFields(state, action)
    case actions.SET_ATTACHMENT_UPLOAD_TYPE:
      return reducers.setAttachmentUploadType(state, action)
    case actions.SET_FORM_CONTEXT:
      return reducers.setFormContext(state, action)
    default:
      return state
  }
}
