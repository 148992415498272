"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.topLevelFields = exports.reactionWasSeriousOrNotAsked = exports.reactionWasSerious = exports.reactionWasNotSeriousOrNotAnswered = exports.reactionWasExperiencedAndSerious = exports.reactionFields = exports.isSeriousDeath = exports.isOneSeriousnessYes = exports.isFatal = exports.clearIfReactionWasNotSerious = exports.clearIfReactionWasNotFatal = exports.checkOneSeriousnessIsYes = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var validators = _interopRequireWildcard(require("../validators"));

var computedHidden = _interopRequireWildcard(require("../computedHidden"));

var computedValue = _interopRequireWildcard(require("../computedValue"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var topLevelFields = ['seriousnessdeath', 'seriousnessother', 'seriousnessdisabling', 'seriousnesshospitalization', 'seriousnesscongenitalanomali', 'seriousnesslifethreatening', 'seriousnessaffectlifestyle', 'seriousnessuncomfortable', 'seriousnessmild', 'seriousnessnotserious'];
exports.topLevelFields = topLevelFields;
var reactionFields = ['reactionseriousnessdeath', 'reactionseriousnesslifethreatening', 'reactionseriousnesshospitalization', 'reactionseriousnessdisabling', 'reactionseriousnesscongenitalanomali', 'reactionseriousnessother'];
exports.reactionFields = reactionFields;

var reactionWasSerious = _fp["default"].anyPass([computedHidden.fieldIs('serious', '1'), computedHidden.fieldIs('seriousnessnotserious', ['2', null])]);

exports.reactionWasSerious = reactionWasSerious;
var reactionWasNotSeriousOrNotAnswered = computedHidden.fieldIs('serious', ['2', null]);
exports.reactionWasNotSeriousOrNotAnswered = reactionWasNotSeriousOrNotAnswered;
var reactionWasSeriousOrNotAsked = computedHidden.fieldIs('serious', ['1', undefined]);
exports.reactionWasSeriousOrNotAsked = reactionWasSeriousOrNotAsked;

var reactionWasExperiencedAndSerious = function reactionWasExperiencedAndSerious() {
  var hasExperiencedReactionIsNotUsed = computedHidden.fieldIs('hasexperiencedreaction', undefined).apply(void 0, arguments);
  var hasExperiencedReaction = computedHidden.fieldIs('hasexperiencedreaction', '1').apply(void 0, arguments);
  var hasNotExperiencedReaction = computedHidden.fieldIs('hasexperiencedreaction', '2').apply(void 0, arguments);
  var wasNotSeriousOrNotAnswered = reactionWasNotSeriousOrNotAnswered.apply(void 0, arguments);
  var isSerious = reactionWasSerious.apply(void 0, arguments);

  switch (true) {
    case isSerious && hasExperiencedReaction:
      return true;

    case wasNotSeriousOrNotAnswered:
      return false;

    case hasExperiencedReaction:
      return true;

    case hasNotExperiencedReaction:
      return false;

    case hasExperiencedReactionIsNotUsed:
      return true;

    default:
      return false;
  }
};

exports.reactionWasExperiencedAndSerious = reactionWasExperiencedAndSerious;
var clearIfReactionWasNotSerious = computedValue.clearIf(computedValue.not(reactionWasSeriousOrNotAsked));
exports.clearIfReactionWasNotSerious = clearIfReactionWasNotSerious;

var isOneSeriousnessYes = function isOneSeriousnessYes(field) {
  return field === '1' || field === true;
};

exports.isOneSeriousnessYes = isOneSeriousnessYes;
var checkOneSeriousnessIsYes = validators.checkIf(function (_value, formvalues) {
  return reactionWasSerious(formvalues);
}, validators.checkMinimumOf1FieldIs({
  fields: topLevelFields,
  predicate: isOneSeriousnessYes,
  translationKey: 'Error - One seriousness must be yes'
}));
exports.checkOneSeriousnessIsYes = checkOneSeriousnessIsYes;
var FATAL = '5';

var isFatal = function isFatal(values) {
  var reactionFields = _fp["default"].pick(['reaction', 'adverseEventsOutcome', 'covid19postvaccinepositiveoutcome'], values);

  switch (true) {
    case _fp["default"].any({
      reactionoutcome: FATAL
    }, reactionFields.reaction):
      return true;

    case reactionFields.adverseEventsOutcome === FATAL:
      return true;

    case reactionFields.covid19postvaccinepositiveoutcome === FATAL:
      return true;

    default:
      return false;
  }
};

exports.isFatal = isFatal;
var clearIfReactionWasNotFatal = computedValue.clearIf(computedValue.not(isFatal));
exports.clearIfReactionWasNotFatal = clearIfReactionWasNotFatal;

var isSeriousDeath = function isSeriousDeath(values) {
  if (isFatal(values)) {
    return '1';
  }

  return '2';
};

exports.isSeriousDeath = isSeriousDeath;