import React from 'react'

import Editable from '../Editable'
import { Wysiwyg } from '../../../components/Fields'
import P from '../../P'

export default Editable({
  input: (props) => (
    <Wysiwyg {...props} />
  ),
  display: ({ label, value, format, hideIfEmpty, isEmpty }) => {
    if (isEmpty && hideIfEmpty) return null
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={format ? format(value) : value} />
      </div>
    )
  }
})
