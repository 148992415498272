import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import style from './style'

// this is the DEFAULT tile for charts but this is not included in
// ChartWrapper because it is not an intrinsic part of a chart.
// but it is used in multiple containers (customer & sales reporting)

const ChartTile = props => {
  const { children, to, classes, onClick } = props
  const Comp = to ? Link : 'div'
  return (
    <div className={classes.reportTileContainer} onClick={onClick}>
      <Comp className={classes.reportTile} to={to}>
        <div className={classes.reportTileInner}>
          <div className={classes.reportTileEvenFurtherInner}>
            {children}
          </div>
        </div>
      </Comp>
    </div>
  )
}

export default withStyles(style)(ChartTile)
