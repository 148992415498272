import React, { useState } from 'react'
import Collapsible from '../../../Collapsible'
import styled from 'styled-components'
import media from 'styled-media-query'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { useEffect } from 'react'

const ContentBox = styled.div`
  width: 100%;
  margin-bottom: 30px;
`

const ContentHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${media.lessThan('medium')`
  flex-direction: column;
`}
`

const HeaderTextContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  ${media.lessThan('medium')`
    width: 100%;
  `}
`

const SectionText = styled.div`
  font-size: 14px;
  justify-content: center;
  align-items: center;
  color: grey;
  margin-bottom: 2px;
`

const HeaderText = styled.div`
  font-size: 20px;  
  font-weight: bold;
  justify-content: center;
  align-items: center;
`

const ToggleButton = styled.button`
  width: 10%;
  display: flex;
  font-weight: bold;
  justify-content: end;
  align-items: center;
  border: 0px;
  background-color: white;
  cursor: pointer;
  ${media.lessThan('medium')`
    width: 100%;
    margin-top: 5px
  `}
`

const ContentBody = styled.div`
  width: 100%;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: black;
`

const InnerContent = styled.div`
  width: 100%;
  margin-left: -10px;
`

const SectionDisplay = (props) => {
  const {
    children,
    title,
    isOpen,
    toggle,
    index,
    numberOfSections
  } = props

  return (
    <ContentBox>
      <ContentHeader>
        <HeaderTextContainer>
          <SectionText>
            {`Section `}<strong>{index}</strong>{`/${numberOfSections}`}
          </SectionText>
          <HeaderText>
            {title}
          </HeaderText>
        </HeaderTextContainer>
        <ToggleButton type='button' onClick={toggle}>
          {isOpen ? <MdExpandLess size={40} /> : <MdExpandMore size={40} />}
        </ToggleButton>
      </ContentHeader>
      <ContentBody>
        <InnerContent>
          <Collapsible isOpen={isOpen}>
            {children}
          </Collapsible>
        </InnerContent>
      </ContentBody>
    </ContentBox>
  )
}

export default SectionDisplay