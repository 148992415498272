import _ from 'lodash'
import { getPlatformSourceOptions } from '../platforms/selectors' 
import { useCurrentOrganisationSourcesOptions } from './authCombinedHooks'
import { useShallowEqualSelector } from '../../../hooks'

export const useCurrentOrganisationAndPlatformSourcesOptions = ({ rcSupport }) => {
  const organisationSources = useCurrentOrganisationSourcesOptions()
  const platformSources = useShallowEqualSelector(getPlatformSourceOptions)
  const allSources = _.uniqBy([...organisationSources, ...platformSources], 'value')
  if(rcSupport) {
    return _.filter(allSources, (source) => (source.rcSupport === 'YES')) 
  }
  return allSources
}