import React from 'react'

import { styled } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Heading from '../../../../components/Heading'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(3)
}))

const Wrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1)
}))

const HeadingContainer = styled(Grid)(({ theme }) => ({
  borderWidth: `1px 1px 0px 1px`,
  borderStyle: 'solid',
  borderColor: theme.palette.grey[300],
  display: 'flex',
  padding: theme.spacing(2)
}))

const PanelContainer = ({ panelTitle, contentLeft, children }) => {
  return (
    <>
      <HeadingContainer container>
        <Grid xs={2} />
        <Grid xs={8}>
          <Heading component='h1' uppercase alignCenter>{panelTitle}</Heading>
        </Grid>
        <Grid xs={2}>
          {contentLeft}
        </Grid>
      </HeadingContainer>
      <Container>
        <Wrapper>
          {children}
        </Wrapper>
      </Container>
    </>
  )
}

export default PanelContainer
