import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import Tooltip from '@material-ui/core/Tooltip'

const ButtonLink = ({ onClick, classes, children, className, tooltip, tooltipPlacement = 'top' }) => {
  const ButtonComponent = <button onClick={onClick} className={`${classes.button} ${className}`}>{children}</button>

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement={tooltipPlacement}>
        {ButtonComponent}
      </Tooltip>
    )
  }

  return ButtonComponent
}

ButtonLink.defaultProps = {
  className: ''
}

ButtonLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top'
  ])
}

export default withStyles(style)(ButtonLink)
