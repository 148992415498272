import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useInitialFetch, useQuery } from '../../../../hooks'
import { useSelector } from 'react-redux'

import { selectors as formSchemaSelectors, actions as formSchemaActions } from '../../../../store/modules/formSchemas'

import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import ScreenHeading from '../../../../components/ScreenHeading'
import EditorLayout from '../../../../components/EditorLayout'
import Sidebar from '../../components/Sidebar'
import IntegrationManagementEditReportFormTabs from '../IntegrationManagementEditReportFormTabs'
import styles from '../../components/Sidebar/style'

const IntegrationManagementEditReportFormScreen = () => {
  const { formSchemaId } = useQuery()
  const { friendlyName } = useSelector(formSchemaSelectors.getSchemaById(formSchemaId))
  useInitialFetch(formSchemaActions.fetchAllFormSchemas)

  return (
    <EditorLayout
      header={<SubHeader
        leftContent={<BackBar />}
        centerContent={<ScreenHeading label={friendlyName} />}
      />}
      main={<IntegrationManagementEditReportFormTabs />}
      side={<Sidebar />}
    />
  )
}

export default withStyles(styles)(IntegrationManagementEditReportFormScreen)
