import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import TabbedContent from '../../../../components/TabbedContent'
import { translations } from '../../../../config'

import CommunicationsHeader from '../../CommunicationsHeader'
import TemplateSection from '../../TemplateSection'
import { actions as communicationTemplateActions, selectors as communicationTemplateSelectors } from '../../../../store/modules/communicationTemplates'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { selectors as authSelectors } from '../../../../store/modules/auth'

import style from './style'


const CommunicationsEmailScreen = (props) => {
  const { classes } = props
  const dispatch = useDispatch()

  const pageTitle = useSelector(platformSelectors.getPageTitle)('Communications - Page Title Email')
  const organisationId = useSelector(authSelectors.getUserSelectedOrganisationId)
  const emailTemplateTypes = useSelector(communicationTemplateSelectors.getCommunicationTemplatesTypes)
  const defaultAndSystemTemplatesTypes = useSelector(communicationTemplateSelectors.getDefaultAndSystemTemplatesIDsByType)
  const organisationTemplateTypes = useSelector(communicationTemplateSelectors.getOrganisationTemplatesIDsByType)
  const userRoleId = useSelector(authSelectors.getLoggedInUserRole)
  const canSeeSystemTemplates = _.get(userRoleId, 'value') <= 2
  const [activeTab, setActiveTab] = useState(canSeeSystemTemplates ? 'SYSTEM_TEMPLATES' : 'ORGANISATION_TEMPLATES')

  const tabs = [
    {
      label: translations('Communications Email Tab - Organisation Templates'),
      content: null,
      value: 'ORGANISATION_TEMPLATES'
    }
  ]

  if (canSeeSystemTemplates) {
    tabs.unshift({
      label: translations('Communications Email Tab - System Templates'),
      content: null,
      value: 'SYSTEM_TEMPLATES'
    })
  }

  useEffect(async () => {
    if (organisationId) {
      await dispatch(communicationTemplateActions.fetchEmailTemplates())
      await dispatch(communicationTemplateActions.fetchOrganisationEmailTemplates({ organisationId }))
      await dispatch(communicationTemplateActions.fetchSystemEmailTemplates())
    }
  }, [dispatch, organisationId])

  const handleTabChange = (e, value) => {
    setActiveTab(value)
  }

  const renderTemplateSections = (types) => {
    const isSystemEmailTemplate = activeTab === 'SYSTEM_TEMPLATES'
    return _.map(types, (type) => {
      return <TemplateSection channel='email' key={type} type={type}
        emailTemplates={(isSystemEmailTemplate ? defaultAndSystemTemplatesTypes : organisationTemplateTypes)}
        isSystemEmailTemplate={isSystemEmailTemplate}/>
    })
  }

  return <Fragment>
    <CommunicationsHeader channel= 'email' pageTitle={pageTitle} />
    <div className={classes.tabsContainer}>
      <TabbedContent
        tabs={tabs}
        tabValue={activeTab}
        handleTabChange={handleTabChange}
        noDivider
        centered
      />
    </div>
    
    <div className={classes.container}>
      {renderTemplateSections(emailTemplateTypes)}
    </div>
  </Fragment>
}

export default withStyles(style)(CommunicationsEmailScreen)
