import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Chip from '@material-ui/core/Chip'

import style from './style'

/**
 * Wrapper around Material UI Chip
 * Accepts all the same props.
 * https://v1.material-ui.com/api/chip/
*/
const CustomChip = ({ variant, size, classes, disabled, ...rest }) => {
  const chipClass = classNames(classes.chip, {
    [classes.primary]: variant === 'primary',
    [classes.secondary]: variant === 'secondary',
    [classes.success]: variant === 'success',
    [classes.error]: variant === 'error',
    [classes.warning]: variant === 'warning',
    [classes.small]: size === 'small',
    [classes.large]: size === 'large',
    [classes.errorOutlined]: variant === 'errorOutlined'
  })

  const clickableClass = classNames(classes.chip, {
    [classes.successClickable]: variant === 'success',
    [classes.errorClickable]: variant === 'error',
    [classes.warningClickable]: variant === 'warning',
    [classes.errorOutlinedClickable]: variant === 'errorOutlined'
  })

  const disabledClass = classNames({
    [classes.disabled]: disabled
  })

  return (
    <Chip
      className={chipClass}
      classes={{
        clickable: clickableClass,
        disabled: disabledClass
      }}
      disabled={disabled}
      {...rest}
    />
  )
}

CustomChip.defaultProps = {
  variant: 'default',
  size: 'default',
  disabled: false
}

CustomChip.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'success',
    'warning',
    'error',
    'errorOutlined'
  ]),
  size: PropTypes.oneOf([
    'default',
    'small',
    'large'
  ]),
  disabled: PropTypes.bool
}

export default withStyles(style)(CustomChip)
