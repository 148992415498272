import React from 'react'
import DateTime from './DateTime'
import moment from 'moment'
import _ from 'lodash'

class DateTimeContainer extends React.Component {
  handleChange = (value, type) => {
    let change = {}
    switch (type) {
      case 'date':
        const { year = '', month = '', date = '' } = this.formatValue(value)
        change = { year, month: +month - 1, date }
        break
      case 'time':
        const [hour = '', minute = ''] = (value || ':').split(':')
        change = { hour, minute }
        break
    }

    return moment(this.props.value).set(change).toISOString()
  }

  onBlur = (e, type) => {
    this.props.onBlur(this.handleChange(e, type))
  }

  onChange = (e, type) => {
    this.props.onChange(this.handleChange(e, type))
  }

  formatValue = (value) => {
    if (value) {
      const [, year, month, date, hour, minute] = value.split(/(\d{4})?(\d{2})?(\d{2})?(\d{2})?(\d{2})?/g)
      return { year, month, date, hour, minute }
    }

    return {}
  }

  _generateProps = () => ({
    ...this.props,
    onBlur: this.onBlur,
    onChange: this.onChange
  })

  render() {
    const props = this._generateProps()
    return <DateTime {...props} />
  }
}

export default DateTimeContainer
