export const selectReportConstants = {
  SELECT_REPORT_TYPE: 'SELECT_REPORT_TYPE',
  SELECT_ORGANISATION: 'SELECT_ORGANISATION',

  SELECT_PRODUCT: 'SELECT_PRODUCT',
  GET_PRODUCTS: 'GET_PRODUCTS',
  SET_PRODUCTS: 'SET_PRODUCTS',
  CLEAR_PRODUCTS: 'CLEAR_PRODUCTS',

  CLEAR_ORGANISATION: 'CLEAR_ORGANISATION',
  CLEAR_PRODUCT: 'CLEAR_PRODUCT',
  
  MIR_SHORT_CODE: 'MIR',
  MIR_REPORT_NAME: 'devicesReport',

  FSCA_SHORT_CODE: 'FSCA',
  FSCA_REPORT_NAME: 'fscaReport',

  MIN_SEARCH_LENGTH: 3
}