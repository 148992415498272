const styles = theme => {
  return {
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 25,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 25,
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
      '& + &': {
        background: theme.palette.grey['100'],
        marginTop: -1,
        borderTop: `1px solid ${theme.palette.grey['200']}`
      },
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 20,
        paddingRight: 20
      }
    },
    leftContent: {
      paddingLeft: 20
    },
    rightContent: {
      paddingRight: 20
    },
    formControl: {
      width: '100%',
    },
    filterBlock: {
      paddingBottom: theme.spacing(2)
    }
  }
}

export default styles
