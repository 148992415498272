import _, { create } from 'lodash'
import fp from 'lodash/fp'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import { FETCH_ALL_PLATFORMS } from './constants'
import { translations, translationsExists } from '../../../config'
import * as defaultFilters from '../../../config/configFiles/defaultReportManagementFilters.json'

export const getAllPlatforms = state => (state.platforms || {}).results || []
export const getCurrentPlatform = state => _.get(state, 'platforms.currentPlatform', null)
export const getCurrentPlatformId = state => _.get(state, 'platforms.currentPlatform.id', null)
export const getCurrentPlatformThemeId = state => _.get(state, 'platforms.currentPlatform.themeId')
export const getCurrentPlatformName = state => _.get(state, 'platforms.currentPlatform.name')
export const getCurrentPlatformOrganisations = state => _.get(state, 'platforms.currentPlatform.organisations', [])
export const getCurrentPlatformPermissions = state => _.get(state, 'platforms.currentPlatform.permissions', false)
export const getCurrentPlatformAnnouncements = state => _.get(state, 'platforms.currentPlatform.announcements', [])
export const getHasPostReportPermissions = state => _.get(state, 'platforms.currentPlatform.permissions.postReport', false)
export const getHasAdvancedPostReportPermissions = state => _.get(state, 'platforms.currentPlatform.permissions.advancedPostReport')
// export const getHasLoadReportsPermissions = state => {
//   return _.get(state, 'platforms.currentPlatform.permissions.loadReport', false)
// } // until R3 can read reports, the organisation's revision must be used in conjunction to determine this.
export const getHasWatchListPermissions = state => _.get(state, 'platforms.currentPlatform.permissions.watchList', false)
export const getHasProductsPermissions = state => _.get(state, 'platforms.currentPlatform.permissions.products', true)
export const getHasCreateAckPermissions = state => _.get(state, 'platforms.currentPlatform.permissions.createAck', false)
export const getHasPharmaceuticalReferralsPermissions = state => _.get(state, 'platforms.currentPlatform.permissions.pharmaceuticalReferrals', false)
export const getHasReportsPermissions = state => _.get(state, 'platforms.currentPlatform.permissions.reports', false)
export const getHasAnalyticsEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.analytics', false)
export const getHasAllowRegisterEmailConfirmationEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.registerEmailConfirmation', false)
export const getHasCaseManagementEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.caseManagement', true)
export const getHasChangeManagementEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.changeManagement', false)
export const getHasDenyPublicUpdatesPermissions = state => _.get(state, 'platforms.currentPlatform.permissions.denyPublicUpdates', false)
export const getHasDeveloperMenuEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.developer', false)
export const getHasRCBetaEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.reportConfigurationBeta', false)
export const getHasCommunicationsEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.communications', false)
export const getHasMoreRegistrationEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.moreRegistration', false)
export const getRequireApprovalForJoiningOrganisations = state => _.get(state, 'platforms.currentPlatform.permissions.requireApprovalForJoiningOrganisations', false)
export const getHasNewReportBetaEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableNewReportBeta', false)
export const getHasOrganisationInvitesEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.allowInvitingUsersToOrganisations', false)
export const getCurrentPlatformSources = state => _.get(state, 'platforms.currentPlatform.sources', [])
export const getHasIntegrationManagementEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.integrationManagement', false)
export const getHasSubmissionServiceUsersEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableSubmissionServiceUsers', false)
export const getHasMultipleApplicationsEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableMultipleApplications', false)
export const getHasTerminologyEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.terminology', false)
export const getHasReportAuditingEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.reportAuditing', false)
export const getHasWebsiteManagementEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.websiteManagement', false)
export const getEmailNotificationRecipientEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableEmailNotificationRecipient', false)
export const getHasReportReassignmentEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableReportReassignment', false)
export const getHasReportSendingMethodManagementEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.allowReportSendingMethodManagement', false)
export const getHasAnonymisationManagementEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.allowAnonymisationManagement', false)
export const getAcknowledgmentSchemaName = state => _.get(state, 'platforms.currentPlatform.permissions.acknowledgmentSchemaName')
export const getHasTranslationManagementInterfaceEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableNewTranslationsManagement', false)
export const getHasLocationsManagementEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableLocationsManagement', false)
export const getHasNotificationEmailsDisabled = state => _.get(state, 'platforms.currentPlatform.permissions.disableNotificationEmails', false)
export const getHasSabreRegistrationEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.sabreRegistration', false)
export const getAllowReportShareAsAssessor = state => _.get(state, 'platforms.currentPlatform.permissions.allowReportShareAsAssessor', false)
export const getHasSabreRequestCompanyAccountRegistrationEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.sabreRequestCompanyAccountRegistration', false)
export const getUserManagementConfig = state => _.get(state, 'platforms.currentPlatform.config.userManagement', {})
export const getHasPermissionsEditorEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enablePermissionsEditor', false)
export const getHasActionsModalAfterUserCreationEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableActionsModalAfterUserCreation', false)
export const getN3URL = state => _.get(state, 'platforms.currentPlatform.details.n3Url', '')
export const getNonN3URL = state => _.get(state, 'platforms.currentPlatform.details.nonN3Url', '')
export const getHasOnlyLatestReportVersionEnabled = state => _.get(state, 'platforms.currentPlatform.config.onlyDisplayLatestReportVersion', false)
export const getHasSkipReportReadOnlyViewEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.skipReportReadOnlyView', false)
export const getHasLocationProvidersEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableLocationProviders', false)
export const getHasBackgroundAnalyticsExportsEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableBackgroundAnalyticsExports', false)
export const getHasExportManagementEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enableExportManagement', false)
export const getHasPlatformAppsEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enablePlatformApps', false)
export const getHasPlatformReportsBulkDownloadDisabled = state => _.get(state, 'platforms.currentPlatform.permissions.disablePlatformReportsBulkDownload', false)
export const getHasPlatformReportsExportEnabled = state => _.get(state, 'platforms.currentPlatform.permissions.enablePlatformReportsExport', false)
export const getAllPlatformsAsOptions = createSelector([
  getAllPlatforms
], (platforms) => {
  return platforms.map(platform => ({ value: platform.id, label: platform.name }))
})

export const getIsPlatformLoading = state => state.platforms.status === PENDING && state.platforms.action === FETCH_ALL_PLATFORMS

export const getPlatformName = createSelector([
  getCurrentPlatformName
], (name) => name)

export const getPageTitle = (state) => (label) => {
  const title = translationsExists(label) ? translations(label) : label
  const name = getCurrentPlatformName(state)

  return {
    title,
    titleWithName: name ? `${title} | ${name}` : title
  }
}

export const getCurrentPlatformOrganisationsOptions = createSelector([
  getCurrentPlatformOrganisations
], (organisations) => {
  return organisations.map(organisation => ({
    value: organisation.id,
    label: organisation.displayName || organisation.name
  }))
})


export const canBulkUploadReports = createSelector(
  [
    getCurrentPlatform
  ],
  fp.getOr(false, 'permissions.postMultipleReports')
)

export const hasWorkspacesEnabled = createSelector(
  [
    getCurrentPlatform
  ],
  fp.getOr(false, 'permissions.enableWorkspaces')
)

export const getPrivacyPolicyLink = createSelector(
  [
    getCurrentPlatform
  ],
  fp.get('details.privacyPolicyLink')
)

export const getCurrentPlatformReportManagementFilters = createSelector(
  [
    getCurrentPlatform
  ],
  fp.getOr(defaultFilters, 'config.reportManagementFilters')
)

export const getHasEnabledReportStages = createSelector(
  [
    getCurrentPlatform
  ],
  fp.getOr(false, 'config.enableReportStages')
)

export const getReportManagementColumnMappings = createSelector(
  [
    getCurrentPlatform
  ],
  fp.getOr(false, 'config.reportManagementColumnMappings')
)

export const getIsShowReportStatusBadge = createSelector(
  [
    getCurrentPlatform
  ],
  fp.getOr(false, 'config.showReportStatusBadge')
)
export const getIsSabreReportType = createSelector(
  [
    getCurrentPlatform
  ],
  fp.getOr(false, 'config.isSabreReportType')
)

export const getPlatformSourceOptions = createSelector(
  [
    getCurrentPlatformSources
  ],
  (sources) => {
    return sources.map(source => ({
      value: _.get(source, 'id'),
      label: _.get(source, 'name'),
      rcSupport: _.get(source, 'rcSupport')
    }))
  }
)

export const getExternalOrganisationIdFieldNamePath = createSelector(
  [
    getCurrentPlatform
  ], (currentPlatform) => {
    const externalOrganisationIdFieldName = _.get(currentPlatform, 'config.jsonWrapperConfiguration.incoming.receiverOrganisationId.id.externalOrganisationIdFieldName')
    if (!externalOrganisationIdFieldName) {
      return null
    }
    /**
     * `externalOrganisationIdFieldName` is a Postgres JSONB path.
     * Converts into a JSON dot notation.
     */
    const sanitisedFieldNamePath = externalOrganisationIdFieldName
      .replaceAll('details->', '')
      .replaceAll('>', '')
      .replaceAll('\'', '')
      .replaceAll('\"', '')
      .replaceAll('-', '.')
    return sanitisedFieldNamePath
  }
)

export const getExternalOrganisationIdFieldName = createSelector(
  [
    getCurrentPlatform
  ], (currentPlatform) => {
    const externalOrganisationIdFieldName = _.get(currentPlatform, 'config.jsonWrapperConfiguration.incoming.receiverOrganisationId.id.externalOrganisationIdFieldName')
    if (!externalOrganisationIdFieldName) {
      return null
    }
    const actualFieldName = _.split(externalOrganisationIdFieldName, '->').pop()
    const sanitisedFieldName = actualFieldName.replaceAll('>', '').replaceAll('\'', '').replaceAll('\"', '')
    return sanitisedFieldName
  }
)

export const getCurrentPlatformApplications = createSelector(
  [
    getCurrentPlatform
  ], (currentPlatform) => {
    return _.get(currentPlatform, 'applications', [])
  }
)

export const getDashboardBannerContent = state => _.get(state, 'platforms.currentPlatform.details.dashboardBannerContent')

export const getNewReportBanner = state => _.get(state, 'platforms.currentPlatform.details.newReportBanner')

/**
 * @description Check whether Announcement Management is enabled for the platform.
 *
 * __NOTE__ This should not be used directly, as it must also take policies in to consideration.
 */
export const isAnnouncementManagementEnabled = createSelector(
  [
    getCurrentPlatform
  ],
  fp.getOr(false, 'permissions.announcementManagement')
)

export const getSourcesForPlatform = state => _.get(state, 'platforms.currentPlatform.organisationSources')


export const getSourcesForPlatformAsOptions = createSelector([
  getSourcesForPlatform,
  getPlatformSourceOptions
], (sources, platformSourceOptions = []) => {
  const options = _.map(sources, (source) => {
    return {
      label: source.name,
      value: source.id
    }
  })
  const combinedOptions = _.uniqBy([...options, ...platformSourceOptions], 'value')
  return combinedOptions
})