import React, { Fragment, PureComponent } from 'react'
import FlagIcon from '@material-ui/icons/Flag'
import TickIcon from '@material-ui/icons/Done'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import _ from 'lodash'

import { timeFormat, longDateFormat, translations } from '../../config'
import { colors } from '../../config/theme'

import P from '../P'
import CircleButton from '../CircleButton'
import ButtonLink from '../ButtonLink'
import VisuallyHidden from '../VisuallyHidden'

import style from './style'

const Notification = ({ 
  classes, 
  isRead, 
  iconSource, 
  onClick, 
  createdAt, 
  actionedAt, 
  actionedByUserId, 
  notificationText,
  isActionable,
  isFlagged,
  isActionedByCurrentUser,
  onToggleActioned,
  onToggleStatus,
  actions,
  currentUserSelected, 
  hideFlag,
  actionedByUser
}) => {

  const containerClass = classNames(
    classes.container,
    { [classes.isReadState]: isRead }
  )

  const statusText = isRead ? translations('Read') : translations('Unread')
  const formatCreatedAt = moment(createdAt).format(timeFormat)
  const isDisabled = !isActionable || (actionedByUserId && !isActionedByCurrentUser)
  const [firstName, lastName] = _.at(actionedByUser, 'firstName', 'lastName')

  const DefaultAction = () => (
    <CircleButton
      label={(!actionedByUserId) ? translations('Mark as done') : translations('Mark as not done')}
      className={classes.circleButtonStyle}
      Icon={TickIcon}
      color={
        actionedByUserId
          ? (
            isActionedByCurrentUser
              ? colors.actionedGreen
              : colors.text
          )
          : colors.mediumGrey
      }
      onClick={e => {
        e.stopPropagation()
        if (!isDisabled) {
          onToggleActioned({ isActioned: !actionedByUserId })
        }
      }}
      isDisabled={isDisabled}
    />
  )

  const Flag = () => (
    <CircleButton
    label={(!isFlagged) ? translations('Mark as flagged') : translations('Unmark as flagged')}
    Icon={FlagIcon}
    color={isFlagged ? colors.primary : colors.mediumGrey}
    onClick={(e) => {
      e.stopPropagation()
      onToggleStatus({ isFlagged: !isFlagged })
    }}
  />
  )

  const Actioned = ({ responsiveClass }) => {
    const actionedAtFormatted = moment(actionedAt).format(longDateFormat)

    return (
      <div className={`${classes.userTextContainer} ${responsiveClass}`}>
        <P type='greenText' value={`${firstName} ${lastName}`} className={classes.notificationTextStyle} />
        <P type='greenText' value={actionedAtFormatted} />
      </div>
    )
  }

  const Content = () => (
    <div className={classes.notificationContainer}>
      <ButtonLink onClick={onClick} className={classes.notificationText}>{notificationText}</ButtonLink>
      <P value={formatCreatedAt} className={classes.hideMediumUp} />
      {actionedByUserId && <Actioned responsiveClass={classes.hideMediumUp}/>}
    </div>
  )

  return (
    <div className={containerClass}>
      <VisuallyHidden>
        <P value={`${statusText}: `} />
      </VisuallyHidden>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={iconSource} alt='' />
      </div>
      <div className={classes.textContainer}>
        <Content/>
        <div className={classes.togglesContainer}>
          {actionedByUserId && <Actioned responsiveClass={classes.hideSmall}/>}
          <div className={classes.toggles}>
            {actions || <DefaultAction/>}
            {(currentUserSelected && !hideFlag) && <Flag/>}
          </div>
        </div>
        <P value={formatCreatedAt} className={classes.hideSmall} />
      </div>
    </div>
  )
}

Notification.defaultProps = {
  isReceipt: false
}

Notification.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  notificationText: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  iconSource: PropTypes.node.isRequired,
  onToggleStatus: PropTypes.func.isRequired,
  onToggleActioned: PropTypes.func.isRequired,
  isFlagged: PropTypes.bool.isRequired,
  isRead: PropTypes.bool.isRequired,
  actionedByUserId: PropTypes.string.isRequired,
  isActionedByCurrentUser: PropTypes.bool,
  isActionable: PropTypes.bool.isRequired,
  isReceipt: PropTypes.bool,
  meta: PropTypes.shape({}),
  hideFlag: PropTypes.bool,
  currentUserSelected: PropTypes.bool,
  renderText: PropTypes.func,
  onClick: PropTypes.func
}

export default withStyles(style)(Notification)
