import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { getFormError, submit, formValueSelector } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert';

import Form, { FormBody } from '../../../../components/Form'
import P from '../../../../components/P'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'

import { styled } from '@material-ui/core/styles'
import { translations } from '../../../../config'
import { useShallowEqualSelector } from '../../../../hooks'
import { selectors } from '../../../../store/modules/terminology'
import { CenterContainer } from './style'
import { ACTION_ENTRY_CONFIGURE_OPTIONS } from '../../../../store/modules/terminology/constants'
import fp from 'lodash/fp'
import { onSubmit } from './onSubmit'
import * as hooks from './hooks'

const FORM_NAME = 'TERMINOLOGY_CONFIGURE_ENTRY_MODAL'
const TerminologyEntryForm = Form(FORM_NAME)

const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2)
}))

const Spacing = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1)
}))
const ConfigureEntryModal = (props) => {
  const { entryId, dismiss } = props
  const editing = !!entryId
  const dispatch = useDispatch()
  hooks.useGetExistingEntry({ entryId })

  const { entity, isLoading, isError } = useSelector(selectors.genericSelectors.getDetailById({ id: entryId }))
  const isPrimary = fp.getOr(true, 'isPrimary', entity)
  const mappingOptions = useSelector(selectors.getMappingOptions)
  const arrangementUsesDefinitions = useSelector(selectors.doesArrangementUseDefinitions)
  const isUsageTypeAction = useSelector(selectors.isUsageTypeAction)
  const formError = useShallowEqualSelector(getFormError(FORM_NAME))
  const actionType = useSelector(state => formValueSelector(FORM_NAME)(state, 'actionType'))
  const mappingChoice = useSelector(state => formValueSelector(FORM_NAME)(state, 'mappingOption'))
  const initialValues = hooks.useGetInitialValues({ entity })
  const actions = useMemo(() => {
    return [
      {
        success: true,
        text: translations('Confirm'),
        primary: true,
        onClick: () => {
          dispatch(submit(FORM_NAME))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [dismiss, dispatch])

  const schema = useMemo(() => {
    const schema = [
      {
        id: 'term',
        field: 'Input',
        props: {
          label: translations('Terminology Entry Configure - Term'),
          name: 'term',
          required: false,
          multiline: true,
          disabled: !isPrimary
        }
      }
    ]

    if (!isPrimary || arrangementUsesDefinitions) {
      schema.push(
        {
          id: 'definition',
          field: 'Input',
          props: {
            label: translations('Terminology Entry Configure - Definition'),
            name: 'definition',
            required: false,
            multiline: true,
            disabled: !isPrimary
          }
        }
       )
      }

    if (isUsageTypeAction) {
      schema.push({
        id: 'actionType',
        field: 'Dropdown',
        props: {
          label: translations('Terminology Entry Configure - actionType'),
          name: 'actionType',
          required: true,
          options: fp.map((entry) => {
            return {
              value: entry.value,
              label: translations(entry.label)
            }
          }, ACTION_ENTRY_CONFIGURE_OPTIONS)
        }
      })
    }

    if (actionType === 'APPLY_MAPPING') {
      schema.push(
        {
          id: 'mappingOption',
          field: 'Dropdown',
          props: {
            label: translations('Terminology Entry Configure Action Options - Select a dictionary to map from'),
            name: 'mappingOption',
            required: true,
            options: mappingOptions
          }
        }
      )
    }
    switch (true) {
      case mappingChoice === 'meddra':
        schema.push({
          id: 'mapToMeddraId',
          field: 'MeddraAsyncLookUp',
          props: {
            label: translations('Terminology Entry Configure - meddraCode'),
            name: 'mapToMeddraId',
            required: true
          }
        })
        break
        case mappingChoice === 'freeText':
        schema.push({
          id: 'mapToFreeText',
          field: 'Input',
          props: {
            label: translations('Terminology Entry Configure - freeText'),
            name: 'mapToFreeText',
            required: true
          }
        })
        break
      case fp.isString(mappingChoice):
        schema.push({
          id: 'mapToEntryId',
          field: 'DictionaryEntriesAsyncLookUp',
          props: {
            label: translations('Terminology Entry Configure - Map to another dictionary code'),
            name: 'mapToEntryId',
            required: true,
            dictionaryId: mappingChoice
          }
        })
        break
      default:
        break

    }
      return schema
  }, [actionType, mappingOptions, mappingChoice, isPrimary])
  const isHandlingEditingAsyncLogic = editing && (isLoading || isError)
  if (isHandlingEditingAsyncLogic) {
    return (<CenterContainer>
      <CircularProgress />
    </CenterContainer>)
  }

  const title = editing ? translations('Terminology - Edit Entry') : translations('Terminology - Add Entry')
  return (
    <BasicModalOverlay actions={actions} title={title}>
      {!isPrimary && <Alert severity={'info'}>{translations('Terminology Entry Configure - Secondary Dictionary Notice', { dictionaryName: fp.get('dictionaryName', entity) })}</Alert>}
      <TerminologyEntryForm
        onSubmit={onSubmit({ dismiss, dispatch })}
        initialValues={initialValues}>
        <ContentBoxBody>
          <Content>
            <Grid container spacing={2}>
              {_.isString(formError) && (
                <Grid item xs={12}>
                  <Spacing>
                    <P value={formError} type={'error'} />
                  </Spacing>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormBody
                  schema={schema}
                  layout={[]}
                  editing
                  fullWidthFields
                />
              </Grid>
            </Grid>
          </Content>
        </ContentBoxBody>
      </TerminologyEntryForm>
    </BasicModalOverlay>
  )
}



export default ConfigureEntryModal
