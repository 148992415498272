import React, { Component } from 'react'
import SearchInput from '../../SearchInput'
import { translations } from '../../../config'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

class LookupInputModalSearch extends Component {
  state = {
    query: this.props.query
  }

  search = _.debounce(({ query }) => {
    const { onSearch } = this.props
    onSearch({ query })
  }, 300)

  updateQuery = (e) => {
    const query = e.target.value
    this.setState({ query })
    this.search({ query })
  }

  render () {
    const { classes, searchPlaceholder } = this.props
    return (
      <SearchInput
        absolute
        value={this.state.query}
        placeholder={searchPlaceholder}
        onChange={this.updateQuery}
        autoFocus={false}
      />
    )
  }
}

export default withStyles(styles)(LookupInputModalSearch)
