import _ from 'lodash'
import {
  CREATE_WEBSITE,
  FAIL,
  FETCH_PAGE,
  IS_SAVING,
  OPEN,
  PENDING,
  SET_THEME_OPTIONS,
  SUCCESS,
  TOGGLE_OVERLAY,
  UPDATE_PAGE,
  UPDATE_SETTINGS,
  UPDATE_WEBSITE,
  TOGGLE_PAGE_VISIBILITY,
  USE_EXISTING_WEBSITE
} from './constants'

export default (state, action) => {

  const { type } = action

  const updateEditedFields = ({ state, path, fieldName, value }) => {
    const editedFields = _.get(state, `editedFields`, {})
    if (!path) {
      editedFields[fieldName] = value
      return editedFields
    }
    if (!editedFields[path]) {
      editedFields[path] = {}
    }
    editedFields[path][fieldName] = value
    return editedFields
  }

  const updateSettings = (state, action) => {
    const nextState = _.clone(state)
    const { payload } = action
    const { fieldName, value } = payload
    nextState.settings[fieldName] = value
    nextState.editedFields = updateEditedFields({ state, path: 'settings', fieldName, value })
    return { ...nextState, isEdited: true }
  }

  const isSaving = (state, action) => {
    const nextState = _.clone(state)
    const { payload } = action
    const { status } = payload
    nextState.isSaving = status
    return { ...nextState, isEdited: status }
  }

  const useExistingWebsite = (state, action) => {
    const nextState = _.clone(state)
    const { payload } = action
    const { selectedWebsite } = payload
    nextState.settings = {
      name: selectedWebsite.name,
      domain: selectedWebsite.subdomain,
      description: selectedWebsite.description,
      published: selectedWebsite.published === 'PUBLISHED',
      themeId: selectedWebsite.themeId,
      showPartnershipLogo: selectedWebsite.showPartnershipLogo
    }
    const websitePages = selectedWebsite.pages || []
    const pages = []
    for(const page of websitePages) {
      const published = page.published === 'PUBLISHED'
      const parsedPage = { ...page, published }
      pages.push(parsedPage)
    }
    nextState.content = pages
    return { ...nextState, isEdited: false }
  }

  const createWebsite = (state, action) => {
    const nextState = _.clone(state)
    const { payload } = action
    const { status } = payload
    switch(status) {
      case PENDING:
        nextState.isSaving = true
        break
      case SUCCESS:
      case FAIL:
        nextState.isSaving = false
        nextState.isEdited = false
        break
    }
    return { ...nextState }
  }

  const updateWebsite = (state, action) => {
    const nextState = _.clone(state)
    const { payload } = action
    const { status } = payload
    switch(status) {
      case PENDING:
        nextState.isSaving = true
        break
      case SUCCESS:
      case FAIL:
        nextState.isSaving = false
        nextState.isEdited = false
        break
    }
    return { ...nextState }
  }

  const setThemeOptions = (state, action) => {
    const nextState = _.clone(state)
    const { payload } = action
    const { themes } = payload
    const options = _.map(themes, theme => {
      return ({
        label: theme.name,
        value: theme.id
      })
    })
    nextState.themeOptions = options
    return { ...nextState }
  }

  const fetchPage = (state, action) => {
    const nextState = _.clone(state)
    const { payload } = action
    const { page, status } = payload
    switch(status) {
      case SUCCESS:
        const clonePage = _.clone(page)
        clonePage.schema = _.get(clonePage, 'template.schema', {})
        nextState.page = clonePage
        break
    }
    return { ...nextState }
  }

  const updatePage = (state, action) => {
    const nextState = _.clone(state)
    const { payload } = action
    const { status } = payload
    switch(status) {
      case SUCCESS:
      case FAIL:
        nextState.isSavingPage = false
        break
      case PENDING:
        nextState.isSavingPage = true
        break
    }
    return { ...nextState }
  }

  const togglePageVisibility = (state, action) => {
    const { payload } = action
    const { id } = payload
    const nextState = _.clone(state)
    const content = _.clone(nextState.content)
    const index = _.findIndex(content, { id })
    const page = _.clone(content[index])
    const newStatus = !page.published
    page.published = newStatus
    content[index] = page
    nextState.content = content
    nextState.editedFields = updateEditedFields({ state, path: 'content', fieldName: id, value: { ...content[id],  published: newStatus } })
    return { ...nextState, isEdited: true }
  }

  const toggleOverlay = (state, action) => {
    const { payload } = action
    const { status } = payload
    const nextState = _.clone(state)
    if(status === OPEN) {
      nextState.overlayOpen = true
    } else {
      nextState.overlayOpen = false
      nextState.page = {}
    }
    return { ...nextState }
  }

  switch (type) {
    case CREATE_WEBSITE:
      return createWebsite(state, action)
    case UPDATE_WEBSITE:
      return updateWebsite(state, action)
    case UPDATE_SETTINGS:
      return updateSettings(state, action)
    case IS_SAVING:
      return isSaving(state, action)
    case USE_EXISTING_WEBSITE:
      return useExistingWebsite(state, action)
    case SET_THEME_OPTIONS:
      return setThemeOptions(state, action)
    case FETCH_PAGE:
      return fetchPage(state, action)
    case UPDATE_PAGE:
      return updatePage(state, action)
    case TOGGLE_PAGE_VISIBILITY:
      return togglePageVisibility(state, action) 
    case TOGGLE_OVERLAY:
      return toggleOverlay(state, action)
  }
}