import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/core/styles'

import { utilities } from '@redant/mhra-form-schema-library'

import EditorSection from './components/EditorSection'
import SectionModal from './modals/SectionModal'
import FieldModal from './modals/FieldModal'
import ConfirmationModal from '../modals/ConfirmationModal'
import ChangeSectionModal from './modals/ChangeSectionModal'
import ChangeBlockModal from './modals/ChangeBlockModal'
import RemoveFromBlockModal from './modals/RemoveFromBlockModal'
import AddToBlockModal from './modals/AddToBlockModal'
import InsertButton from '../../../components/FormviewEditor/InsertButton'

import modalService from '../../../services/modalService'
import { hooks as reportConfigurationHooks } from '../../../store/modules/reportConfiguration'
import { translations } from '../../../config'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { addQuery } from '../../../helpers/query'
import { useConditions } from '../../../contexts'

const Content = styled('div')(() => ({
  maxWidth: 1200,
  margin: '0 auto'
}))

const Actions = styled('div')(() => ({}))

const ReportConfigurationEditorPanel = ({ formViewStore, formViewId }) => {
  const formViewContext = useContext(formViewStore)
  const { dispatch: formViewDispatch, actions: formViewActions, getSections: sections, getFields: fields, moveResourceFunction, state: viewJSONState, getConditionsForResource } = formViewContext
  const { addSection, updateSection, addField, updateField, toggleVisibility, removeResource, moveResource, changeSection, changeBlock, removeFromBlock, addToBlock, closeBlock } = formViewActions
  const { type: formSchemaName } = reportConfigurationHooks.useCurrentFormViewMeta()
  const [sectionsSnapshot, setSnapshot] = useState(undefined)
  const [hasBeenDragged, setDragged] = useState(false)
  const useFormBuilder = reportConfigurationHooks.useFormViewVersionHasFormBuilder(formViewId)
  const { setInternalResourceConditions } = useConditions()

  const __getBlockConditions = (fields = []) => {
    const payload = {}
    const blocks = _.filter(fields, 'isBlock') || []
    _.forEach(blocks, ({ id, editorConditions: conditions, conditions: assignedConditions }) => {
      if (_.size(assignedConditions) > 0) {
        payload[id] = _.map(conditions, ({ id }) => id)
      }
    })
    return payload
  }
  const __getSectionConditions = (sections = []) => {
    const payload = {}
    _.forEach(sections, ({ id, editorConditions: conditions, conditions: assignedConditions }) => {
      if (_.size(assignedConditions) > 0) {
        payload[id] = _.map(conditions, ({ id }) => id)
      }
    })
    return payload
  }

  const resourceConditions = useMemo(() => {
    const allConditions = {}
    if (!_.isEmpty(viewJSONState.sections)) {
      const viewJson = utilities.unflattenViewJSON(viewJSONState, getConditionsForResource)
      let fields = [], sections = []
      _.forEach(viewJson, (section) => fields = [...fields, ..._.get(section, 'fields')])
      _.forEach(viewJson, (section) => {
        if (_.size(_.get(section, 'conditions', [])) > 0) {
          sections.push(section)
        }
      })
      if (_.size(fields)) {
        const clonedFields = _.cloneDeep(fields)
        const blockConditions = __getBlockConditions(clonedFields)
        allConditions.BLOCK = blockConditions
      }
      if (_.size(sections)) {
        const clonedSections = _.cloneDeep(sections)
        const sectionConditions = __getSectionConditions(clonedSections)
        allConditions.SECTION = sectionConditions
      }

    }
    return allConditions
  }, [viewJSONState])

  useEffect(() => {
    setInternalResourceConditions(resourceConditions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceConditions])


  const getMappedSections = (sections) => {
    return _.map(
      sections,
      (section, index) => {
        return <EditorSection
          key={section.id}
          section={section}
          isOpen={false}
          onEditSection={onEditSection}
          onAddField={onAddField}
          onEditField={onEditField}
          onToggleVisibility={onToggleVisibility}
          onDelete={onDelete}
          onMove={onMove}
          onChangeSection={onChangeSection}
          onChangeBlock={onChangeBlock}
          onRemoveFromBlock={onRemoveFromBlock}
          onAddToBlock={onAddToBlock}
          onCloseAllBlocks={onCloseAllBlocks}
          isFirst={index === 0}
          isLast={index === (sections.length - 1)}
          saveDraggedResult={saveDraggedResult}
          moveAnyResource={moveAnyResource}
          onManageConditions={onManageConditions}
          formViewStore={formViewStore}
        />
      })
  }

  useEffect(() => {
    if (hasBeenDragged) {
      setDragged(false)
    } else if (!_.isEqual(sections, sectionsSnapshot)) {
      setSnapshot(sections)
    }
  }, [sections, sectionsSnapshot])

  const onAddSection = useCallback(() => {
    modalService.open({
      component: SectionModal,
      disableBackdropClick: true,
      onSubmit: ({ payload }) => {
        formViewDispatch(addSection(payload))
      }
    })
  }, [formViewDispatch, addSection])

  const onEditSection = useCallback(({ props = {} }) => {
    modalService.open({
      component: SectionModal,
      disableBackdropClick: true,
      editing: true,
      wideModal: true,
      ...props,
      onSubmit: ({ payload }) => {
        formViewDispatch(updateSection(payload))
      }
    })
  }, [formViewDispatch, updateSection])

  const onAddField = useCallback(({ fieldType = 'QUESTION', meta, props = {} }) => {
    modalService.open({
      component: FieldModal,
      disableBackdropClick: true,
      fieldType,
      formSchemaName,
      allFields: fields,
      useFormBuilder,
      ...props,
      onSubmit: ({ payload }) => {
        formViewDispatch(addField({ ...meta, ...payload }))
      }
    })
  }, [fields, formViewDispatch, formSchemaName, addField])

  const onEditField = useCallback(({ fieldType = 'QUESTION', meta = {}, props = {} }) => {
    modalService.open({
      component: FieldModal,
      disableBackdropClick: true,
      editing: true,
      wideModal: true,
      fieldType,
      formSchemaName,
      allFields: fields,
      useFormBuilder,
      ...props,
      onSubmit: ({ payload }) => {
        formViewDispatch(updateField({ ...meta, ...payload }))
      }
    })
  }, [fields, formViewDispatch, formSchemaName, updateField])

  const onToggleVisibility = useCallback((payload) => {
    formViewDispatch(toggleVisibility({ ...payload }))
  }, [formViewDispatch, toggleVisibility])

  const onDelete = useCallback((payload) => {
    const { resourceType = 'FIELD' } = payload
    modalService.open({
      component: ConfirmationModal,
      title: translations('Confirmation'),
      message: translations(`Delete resource - ${resourceType}`),
      onConfirmation: () => {
        formViewDispatch(removeResource({ ...payload }))
      }
    })
  }, [formViewDispatch, removeResource])

  const onMove = useCallback((payload) => {
    formViewDispatch(moveResource({ ...payload }))
  }, [formViewDispatch, moveResource])

  const onChangeSection = useCallback(({ fieldType = 'QUESTION', id: fieldId, name, currentSectionId }) => {
    modalService.open({
      component: ChangeSectionModal,
      title: name,
      fieldType,
      sectionOptions: _.map(sections, (section) => {
        const { name: sectionName, displayName: sectionDisplayName, id: sectionId } = section
        return {
          label: sectionDisplayName || sectionName,
          value: sectionId,
          disabled: sectionId === currentSectionId
        }
      }),
      onSubmit: ({ sectionId }) => {
        formViewDispatch(changeSection({ sectionId, fieldId }))
      }
    })
  }, [formViewDispatch, changeSection, sections])

  const getBlockOptions = ({ fields: allFields, source }) => {
    return _.chain(allFields)
      .map((field) => {
        const { isBlock, name, displayName, id } = field
        return {
          label: displayName || name,
          value: id,
          disabled: id === source,
          isBlock,
          id
        }
      })
      .filter(({ isBlock, id }) => !!isBlock && id !== source)
      .value()
  }

  const onChangeBlock = useCallback(({ fieldType = 'QUESTION', id, name, source }) => {
    modalService.open({
      component: ChangeBlockModal,
      title: name,
      fieldType,
      blockOptions: getBlockOptions({ fields, source }),
      onSubmit: ({ destination }) => {
        formViewDispatch(changeBlock({ destination, source, id }))
      }
    })
  }, [formViewDispatch, changeBlock, fields])

  const onRemoveFromBlock = useCallback(({ id, name, source, sectionId }) => {
    modalService.open({
      component: RemoveFromBlockModal,
      title: name,
      onConfirmation: () => {
        formViewDispatch(removeFromBlock({ id, name, source, sectionId }))
        modalService.close()
      }
    })
  }, [formViewDispatch, removeFromBlock, fields])

  const onAddToBlock = useCallback(({ id, name }) => {
    modalService.open({
      component: AddToBlockModal,
      title: name,
      blockOptions: getBlockOptions({ fields }),
      onSubmit: ({ destination }) => {
        formViewDispatch(addToBlock({ destination, id }))
      }
    })
  }, [formViewDispatch, addToBlock, fields])

  const onCloseAllBlocks = useCallback(({ sectionId, blocks }) => {
    formViewDispatch(closeBlock({ sectionId, blocks }))
  }, [formViewDispatch, fields])

  const moveAnyResource = (payload) => {
    const { source = {}, destination = {}, type: resourceType, draggableId: id } = payload
    const { droppableId: sourceDropId, index: sourceIndex } = source
    const { droppableId: destinationDropId, index: destinationIndex } = destination
    if (sourceDropId === destinationDropId && destinationIndex !== sourceIndex) {
      const vector = destinationIndex > sourceIndex
        ? { places: destinationIndex - sourceIndex, direction: 'DOWN' }
        : { places: sourceIndex - destinationIndex, direction: 'UP' }
      const getId = () => {
        switch (resourceType) {
          case 'REPEATABLE':
          case 'BLOCK':
            return destinationDropId
          default:
            return id
        }
      }
      const getNestedId = () => {
        switch (resourceType) {
          case 'REPEATABLE':
          case 'BLOCK':
            return id
          default:
            return undefined
        }
      }
      const moveProps = {
        id: getId(),
        nestedId: getNestedId(),
        resourceType,
        ...vector
      }
      return { ...moveResourceFunction(moveProps), moveProps }
    }
  }

  const onDragEnd = (payload) => {
    const movedResult = moveAnyResource(payload)
    if (movedResult) {
      const moveProps = _.get(movedResult, 'moveProps')
      const movedSections = _.get(movedResult, 'sections')
      const orderedSections = _.orderBy(movedSections, ['index'])
      setSnapshot(orderedSections)
      setDragged(true)
      saveDraggedResult(moveProps)
    }
  }
  const saveDraggedResult = (moveProps) => {
    formViewDispatch(moveResource(moveProps))
  }

  const onManageConditions = ({ resourceType, resourceId }) => {
    addQuery({
      conditions: {
        resourceType,
        resourceId
      }
    })
  }

  return (
    <Content>
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId={'OUTER_SECTION_DROP_ZONE'} type='SECTION'>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {
                sectionsSnapshot
                  ? getMappedSections(sectionsSnapshot)
                  : getMappedSections(sections)
              }
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Actions>
        <Grid container spacing={2}>
          <Grid item xs>
            <InsertButton onClick={onAddSection} fullWidth size='large'>{translations('Add Section')}</InsertButton>
          </Grid>
        </Grid>
      </Actions>
    </Content>
  )
}

export default ReportConfigurationEditorPanel
