import React, { useState } from 'react'
import { translations } from '../../config'

import BasicModalOverlay from '../../components/BasicModalOverlay'
import Input from '../../components/Fields/Input'

const RejectModal = ({ onConfirm, onDismiss, title, message }) => {
  const [rejectionMessage, setRejectionMessage] = useState()

  return (
    <BasicModalOverlay
      title={title}
      text={message}
      actions={[
        {
          disabled: !rejectionMessage,
          buttonType: 'primary',
          text: translations('Change Request - Reject'),
          onClick: () => onConfirm({ rejectionMessage })
        },
        {
          buttonType: 'secondary',
          text: translations('Cancel'),
          onClick: onDismiss
        }
      ]}>
      <Input onChange={setRejectionMessage} placeholder={translations('Organisation Requests - Reject message input placeholder')} />
    </BasicModalOverlay>
  )
}

export default RejectModal
