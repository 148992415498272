export const FETCH_PERSONAL_NOTIFICATIONS_FRESH = 'digitalStore/notifications/FETCH_PERSONAL_NOTIFICATIONS_FRESH'
export const FETCH_PERSONAL_NOTIFICATIONS_NEXT = 'digitalStore/notifications/FETCH_PERSONAL_NOTIFICATIONS_NEXT'

export const FETCH_USER_NOTIFICATIONS_FRESH = 'digitalStore/notifications/FETCH_USER_NOTIFICATIONS_FRESH'
export const FETCH_USER_NOTIFICATIONS_NEXT = 'digitalStore/notifications/FETCH_USER_NOTIFICATIONS_NEXT'

export const SET_NOTIFICATION_USER_STATUS = 'digitalStore/notifications/SET_NOTIFICATION_USER_STATUS'
export const GET_TOTAL_NOTIFICATIONS_NOT_VIEWED = 'digitalStore/notifications/GET_TOTAL_NOTIFICATIONS_NOT_VIEWED'
export const SET_TOTAL_NOTIFICATIONS_NOT_VIEWED = 'digitalStore/notifications/SET_TOTAL_NOTIFICATIONS_NOT_VIEWED'
export const SET_NOTIFICATION_ACTIONED_STATUS = 'digitalStore/notifications/SET_NOTIFICATION_ACTIONED_STATUS'
export const UPDATE_NOTIFICATION_FILTERS = 'digitalStore/notifications/UPDATE_NOTIFICATION_FILTERS'
export const REMOVE_NOTIFICATION_FROM_LIST = 'digitalStore/notifications/REMOVE_NOTIFICATION_FROM_LIST'
export const RESET_STORE = 'digitalStore/notifications/RESET_STORE'
export const SEND_USER_TO_USER_NOTIFICATION = 'digitalStore/notifications/SEND_USER_TO_USER_NOTIFICATION'
