import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { translations } from '../../../config'
import TabPanel from '@material-ui/lab/TabPanel'
import SettingsTab from './SettingsTab'
import ContentTab from './ContentTab'
import ReusableTabs from '../../../components/ReusableTabs'

const tabs = [
  { label: translations('Content'), value: 'content' },
  { label: translations('Settings'), value: 'settings' },
]

const WebsiteManagementTabs = (props) => {
  const {
  } = props
  
  return (
    <>
      <ReusableTabs unmountOnExit={false} tabs={tabs} ariaLabel={'Website Management - Main Tabs'}>
        <TabPanel value='content'>
          <ContentTab />
        </TabPanel>
        <TabPanel value='settings'>
          <SettingsTab />
        </TabPanel>
      </ReusableTabs>
    </>
  )
}

export default WebsiteManagementTabs