import React from 'react'
import { connect } from 'react-redux'
import { compose, withPropsOnChange, withHandlers, lifecycle } from 'recompose'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import withWidth from '@material-ui/core/withWidth'
import FeedList from './FeedList'
import { actions as newsFeedsActions } from '../../../../../store/modules/newsFeeds'
import { actions as languagesActions } from '../../../../../store/modules/languages'
import { newsFeedCombinedSelectors } from '../../../../../store/modules/combinedSelectors'
import modalService from '../../../../../services/modalService'
import { translations } from '../../../../../config'

const removeModal = (cb) => {
  modalService.continue({
    title: translations('Would you like to remove this feed?'),
    confirmButtonText: translations('Confirm'),
    success: cb
  })
}

const enhancer = compose(
  connect(state => ({
    rows: newsFeedCombinedSelectors.getFeedsWithLanguage(state)
  })),
  withWidth(),
  withHandlers({
    onRowClick: ({ dispatch }) => (id) => {
      removeModal(() => {
        dispatch(newsFeedsActions.deleteFeed(id))
      })
    }
  }),
  lifecycle({
    componentDidMount () {
      this.props.dispatch(languagesActions.fetchLanguages())
      this.props.dispatch(newsFeedsActions.clearErrors())
      this.props.dispatch(newsFeedsActions.fetchFeeds())
    }
  }),
  // Convert a plain object array into the format needed
  // for the AutoTable component
  withPropsOnChange(['rows', 'width'], ({ rows, onRowClick, width }) => ({
    rows: rows.map(row => {
      // Construct headers, but don't show organisation on mobile
      const mobile = width === 'xs'
      const { id, url, language, source, organisation = {} } = row
      const columns = { url, language }

      // To display extra content on larger screens i.e. not 'xs'
      // Add addtional properties to the columns object
      // The content will appear as the last columns in the table
      if (!mobile) {
        columns.source = source
        columns.organisation = organisation.name
      }

      // Build table
      const entries = Object.entries(columns)
      const columnSize = Math.floor(10 / entries.length)
      return {
        key: id,
        data: entries
          .map(([key, value]) => {
            // Bump up url column on desktop
            return [key, value, { xs: columnSize }]
          })
          // Attach a "Delete" icon to the end of every row
          .concat([
            ['', (
              <IconButton aria-label={translations('Remove')} onClick={() => onRowClick(id)}>
                <DeleteIcon />
              </IconButton>
            ), { xs: 2 }]
          ])
      }
    })
  }))
)

export default enhancer(FeedList)
