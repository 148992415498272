import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as organisationSelectors } from '../organisationDetails'
import { selectors as selectedReceiverSelectors } from '../selectedReceiver'
import * as translationSelectors from './selectors'
import { defaultTranslations, addResources } from '../../../config'

class TranslationsActions {
  generateTranslationsCSV = () => ({
    type: constants.GENERATE_CSV,
    promise: (dispatch, getState) => {
      const organisationId = organisationSelectors.getCurrentOrganisationId(getState())
      const ackTranslations = selectedReceiverSelectors.getAckSchemaTranslations(getState())
      const reportTranslations = selectedReceiverSelectors.getReportSchemaTranslations(getState())

      const appTranslations = {
        ...reportTranslations,
        ...ackTranslations,
        ...defaultTranslations
      }

      return digitalStoreSdk.translations
        .generateTranslationsCSV({
          organisationId,
          appTranslations
        })
    }
  })

  createTranslations = languageId => ({
    type: constants.CREATE_TRANSLATION,
    promise: (dispatch, getState) => {
      const organisationId = organisationSelectors.getCurrentOrganisationId(getState())

      return digitalStoreSdk.translations
        .createTranslation({ languageId, organisationId })
    }
  })

  uploadTranslations = ({ organisationId, changes, ignoreDuplicateKeys }) => ({
    type: constants.UPLOAD_TRANSLATIONS,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.translations.uploadTranslations({ organisationId, changes, ignoreDuplicateKeys })
    }
  })

  fetchTranslationsForOrg = organisationId => ({
    type: constants.FETCH_TRANSLATIONS_FOR_ORG,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.translations
        .fetchTranslationsForOrg({ organisationId })
    }
  })

  setTranslations = translations => ({
    type: constants.SET_TRANSLATIONS,
    promise: (dispatch, getState) => {
      return addResources(translations)
    }
  })

  searchTranslationsForOrganisation = ({ organisationId, filters }) => ({
    type: constants.SEARCH_TRANSLATIONS_FOR_ORG,
    promise: (dispatch, getState) => {
      const offset = translationSelectors.getOffset(getState())
      const searchValue = translationSelectors.getSearchValue(getState())

      return digitalStoreSdk.translations.searchTranslationsForOrganisation({ organisationId, offset, searchValue, filters })
    }
  })

  createNewTranslationKeyForOrganisation = ({ organisationId, key }) => ({
    type: constants.CREATE_NEW_TRANSLATION_KEY_FOR_ORG,
    promise: () => digitalStoreSdk.translations.createNewTranslationKeyForOrganisation({ organisationId, key })
  })

  deleteLanguagesTranslationsForOrganisation = ({ organisationId, languageId }) => ({
    type: constants.DELETE_LANGUAGES_TRANSLATIONS_FOR_ORG,
    promise: () => digitalStoreSdk.translations.deleteLanguagesTranslationsForOrganisation({ organisationId, languageId })
  })

  saveTranslationChangesForOrganisation = ({ organisationId, changes }) => ({
    type: constants.SAVE_TRANSLATION_CHANGES_FOR_ORG,
    promise: () => digitalStoreSdk.translations.saveTranslationChangesForOrganisation({ organisationId, changes })
  })

  deleteTranslationsForOrganisation = ({ organisationId, ids }) => ({
    type: constants.SAVE_TRANSLATION_DELETE_FOR_ORG,
    promise: () => digitalStoreSdk.translations.deleteTranslationsForOrganisation({ organisationId, ids })
  })

  getTranslationDifferences = ({ organisationId, translations }) => ({
    type: constants.GET_TRANSLATION_DIFFERENCES,
    promise: () => digitalStoreSdk.translations.getTranslationDifferencesForOrganisation({
      organisationId,
      translations
    })
  })

  fetchTranslationTags = () => ({
    type: constants.FETCH_TRANSLATION_TAGS,
    promise: () => digitalStoreSdk.translations.fetchTranslationTags()
  })

  setOffset = ({ offset }) => ({
    type: constants.SET_OFFSET,
    payload: { offset }
  })

  setSearchValue = ({ searchValue }) => ({
    type: constants.SET_SEARCH_VALUE,
    payload: { searchValue }
  })

  fetchTranslationAuditTrailForOrganisation = ({ organisationId }) => ({
    type: constants.FETCH_TRANSLATION_AUDIT_TRAIL_FOR_ORG,
    promise: () => digitalStoreSdk.translations.fetchTranslationAuditTrailForOrganisation({ organisationId })
  })

  findOrganisationLanguages = ({ organisationId }) => ({
    type: constants.FIND_ORGANISATION_LANGUAGES,
    promise: () => digitalStoreSdk.translations.findOrganisationLanguages({
      organisationId
    })
  })
}

export default new TranslationsActions()
