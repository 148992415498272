import * as validators from '../../../../validators'
import { translations, environment } from '../../../../config'
import _ from 'lodash'

const normalizeInput = (value, updateSettings, fieldName) => {
  const trimmedValue = _.trim(value)
  const newValue = trimmedValue || null
  updateSettings({ fieldName, value: newValue })
  return newValue
}

export const getSchema = ({ updateSettings, themeOptions, fetchOrganisationThemes }) => {
  return ([
    {
      id: 'name',
      field: 'Input',
      props: {
        label: translations('Name'),
        name: 'name',
        required: true,
        validate: [validators.maxLength(250, 'Field'), validators.minLength(10, 'Field')],
        handleChange: (e) => updateSettings({ fieldName: 'name', value: e.target.value }),        
        normalize: (value) => normalizeInput(value, updateSettings, 'name')
      }
    },
    {
      id: 'description',
      field: 'Input',
      props: {
        label: translations('Description'),
        name: 'description',
        validate: [validators.maxLength(160, 'Field')],
        maxLength: 160,
        required: true,
        handleChange: (e) => updateSettings({ fieldName: 'description', value: e.target.value }),
        normalize: (value) => normalizeInput(value, updateSettings, 'description')
      }
    },
    {
      id: 'domain',
      field: 'Input',
      props: {
        label: translations('Domain'),
        name: 'domain',
        validate: [validators.minLength(1, 'Field'), validators.maxLength(60, 'Field'), validators.subdomain],
        minLength: 1,
        maxLength: 60,
        required: true,
        handleChange: (e) => updateSettings({ fieldName: 'domain', value: e.target.value }),
        endAdornmentText: `.${environment.REACT_APP_WEBAPP_DOMAIN}`
      }
    },
    {
      id: 'themeId',
      field: 'ThemeSelect',
      props: {
        label: 'Theme',
        name: 'themeId',
        shrink: true,
        required: true,
        handleChange: (id) => updateSettings({ fieldName: 'themeId', value: id }),
        fetchThemes: () => fetchOrganisationThemes(),
        onCreateCallback: (id) => updateSettings({ fieldName: 'themeId', value: id }),
        themeType: 'WEBSITE',
        options: themeOptions
      }
    },
    {
      id: 'showPartnershipLogo',
      field: 'Checkbox',
      props: {
        label: 'Show Partnership Logo',
        name: 'showPartnershipLogo',
        required: false,
        onChange: (e) => updateSettings({ fieldName: 'showPartnershipLogo', value: e.target.checked })
      }
    }
  ])
}