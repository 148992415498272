import React from 'react'
import PropTypes from 'prop-types'

import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Heading from '../Heading'

import { styled, makeStyles } from '@material-ui/core/styles'
import { translations } from '../../config'

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(3)
}))

const DrawerBar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main
}))

const useStyles = makeStyles((theme) => ({
  paper: ({ width }) => ({
    width: '95%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: width
    }
  })
}))

const CustomDrawer = (props) => {
  const { heading, anchor, open, onClose, hideBackdrop, children, width = 800 } = props
  const classes = useStyles({ width })

  return (
    <Drawer
      classes={{
        paper: classes.paper
      }}
      anchor={anchor}
      open={open}
      hideBackdrop={hideBackdrop}
    >
      <DrawerBar>
        <Heading variant='h2' component='h2'>{heading}</Heading>
        <IconButton onClick={onClose} color='secondary'>
          <CloseIcon titleAccess={translations('Close')} />
        </IconButton>
      </DrawerBar>
      <Content>
        {children}
      </Content>
    </Drawer>
  )
}

CustomDrawer.props = {
  anchor: 'right',
  open: false
}

CustomDrawer.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
}

export default CustomDrawer
