import React from 'react'
import StandardForm from '../StandardForm'
import { SdkProvider, useSdk } from '../../../services/sdk'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

const UpdateUserForm = ({
  onSuccess,
  onError,
  organisationId,
  organisationDetails,
  professions,
  initialValues
}) => {

  const { updateUser } = useSdk()

  const onSubmit = async (data) => {
    try {
      const modifiedData = { ...data }
      modifiedData.id = '@me'
      modifiedData.emailCommunicationsPermitted = _.includes(modifiedData.communicationPreferences, 'email')
      modifiedData.smsCommunicationsPermitted = _.includes(modifiedData.communicationPreferences, 'sms')
      if (modifiedData.hospitalOrPracticeOrOrganisation) {
        modifiedData.houseNumberOrName = modifiedData.hospitalOrPracticeOrOrganisation
      }
      const user = await updateUser(modifiedData)
      onSuccess && onSuccess(user)
    } catch (error) {
      onError && onError(error)
      return { [FORM_ERROR]: error.message }
    }
  }

  const formatInitialValues = (user = {}) => {
    const communicationPreferences = []
    if (user.emailCommunicationsPermitted) {
      communicationPreferences.push('email')
    }
    if (user.smsCommunicationsPermitted) {
      communicationPreferences.push('sms')
    }

    const profession = _.get(user, 'professions.0', {})
    const professionId = _.get(profession, 'id')
    const isPublic = profession.type === 'Public'
    const occupation = profession && (isPublic ? 'public' : 'hcp')

    return {
      title: _.get(user, 'title'),
      firstName: _.get(user, 'firstName'),
      lastName: _.get(user, 'lastName'),
      email: _.get(user, 'email'),
      occupation,
      professionId,
      company: _.get(user, 'company'),
      houseNumberOrName: _.get(user, 'houseNumberOrName'),
      hospitalOrPracticeOrOrganisation:  _.get(user, 'houseNumberOrName'),
      address: _.get(user, 'address'),
      addressLineTwo: _.get(user, 'addressLineTwo'),
      city: _.get(user, 'city'),
      country: _.get(user, 'country'),
      postalCode: _.get(user, 'postalCode'),
      telephoneCountryCode: _.get(user, 'telephoneCountryCode'),
      telephone: _.get(user, 'telephone'),
      telephoneExtension: _.get(user, 'telephoneExtension'),
      communicationPreferences,
    }
  }

  return (
    <StandardForm
      schemaName={'updateUser'}
      onSubmit={onSubmit}
      professions={professions}
      organisationDetails={organisationDetails}
      initialValues={formatInitialValues(initialValues)}
      submitText={'Update'}
    />
  )
}

const WrappedUpdateUserForm = (props) => {
  const {
    auth,
    baseUrl,
    organisationId,
    platformId,
    ...rest
  } = props

  return (
    <SdkProvider
      auth={auth}
      baseUrl={baseUrl}
      organisationId={organisationId}
      platformId={platformId}
    >
      <UpdateUserForm organisationId={organisationId} auth={auth} {...rest} />
    </SdkProvider>
  )
}

export default WrappedUpdateUserForm