import _ from 'lodash'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { compose, withHandlers } from 'recompose'
import { selectors as themeSelectors } from '../../../store/modules/themes'
import ThemeSelect from './ThemeSelect'
import modalService from '../../../services/modalService'
import ThemeDetailsModal from '../../../containers/Themes/ThemeDetailsModal'

const mapStateToProps = (state, { options }) => ({
  options: options || themeSelectors.getThemesForDropdown(state)
})

const _openModal = ({ dispatch, schema, label, editing, fieldName, themeId, formName, themeType = 'STANDARD', fetchThemes, onCreateCallback }) => {
  modalService.open({
    component: ThemeDetailsModal,
    hideBottomBar: true,
    fullScreen: true,
    inModal: true,
    modalIndex: 0,
    schema,
    label,
    themeId,
    themeType,
    formName,
    onComplete: (values) => {
      dispatch(change(formName, fieldName, values.id))
      if(_.isFunction(onCreateCallback)) {
        onCreateCallback(values.id)
      }
      if(_.isFunction(fetchThemes)) {
        fetchThemes()
      }
      modalService.close()
    },
    onClose: () => modalService.close(),
    onBackClick: () => modalService.close()
  })
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onAdd: ({ dispatch, schema, label, formName, name: fieldName, themeType, fetchThemes, onCreateCallback }) => ({ formName }) => {
      _openModal({ dispatch, schema, label, editing: false, fieldName, formName, themeType, fetchThemes, onCreateCallback })
    },
    onEdit: ({ dispatch, schema, label, formName, id, themeType, fetchThemes }) => ({ value: themeId, formName }) => {
      _openModal({ dispatch, schema, label, editing: true, themeId, formName, themeType, fetchThemes })
    }
  })
)(ThemeSelect)
