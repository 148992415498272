import React from 'react'
import { styled } from '@material-ui/core/styles'

import { translations } from '../../config'

import Heading from '../../components/Heading'
import Container from '../../components/Container'

const SuccessContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  textAlign: 'center'
})

const SuccessScreen = ({ heading, message }) => (
  <SuccessContainer withMarginTop>
    <Heading component='h1'>{heading || translations('Success Screen Default Heading')}</Heading>
    <p>{message}</p>
  </SuccessContainer>
)

export default SuccessScreen
