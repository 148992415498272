import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../../../components/Button'
import AddQuestionModal from './AddQuestionModal/index'
import AddSectionModal from './AddSectionModal/index'
import { translations } from '../../../../config'

import styles from './style'

const QuestionSectionModal = ({
  classes,
  closeModal,
  openModal,
  sectionNames,
  addQuestion,
  addSection,
  questionNames
}) => (
  <div className={classes.listContainer}>
    <h3 style={{ marginBottom: '3rem' }}>
      {translations('Add Question/Section')}
    </h3>
    <div className={classes.buttonContainer}>
      <Button
        raised
        buttonType='primary'
        onClick={() =>
          openModal(() => (
            <AddQuestionModal
              sectionNames={sectionNames}
              questionNames={questionNames}
              openModal={openModal}
              closeModal={closeModal}
              addQuestion={addQuestion}
            />
          ))
        }
      >
        {translations('Add Question')}
      </Button>
      <Button
        raised
        buttonType='primary'
        onClick={() =>
          openModal(() => (
            <AddSectionModal
              classes={classes}
              closeModal={closeModal}
              sectionNames={sectionNames}
              addSection={addSection}
            />
          ))
        }
      >
        {translations('Add Section')}
      </Button>
      <Button
        raised
        buttonType='primary'
        onClick={() =>
          openModal(() => (
            <AddQuestionModal
              sectionNames={sectionNames}
              questionNames={questionNames}
              openModal={openModal}
              closeModal={closeModal}
              addQuestion={addQuestion}
              heading={true}
            />
          ))
        }
      >
        {translations('Add Heading')}
      </Button>
    </div>
    <div className={classes.buttonContainer}>
      <Button
        raised
        buttonType='white'
        onClick={closeModal}
        style={{ marginRight: 0 }}
      >
        {translations('Cancel')}
      </Button>
    </div>
  </div>
)
QuestionSectionModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  sectionNames: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  addQuestion: PropTypes.func,
  addSection: PropTypes.func,
  questionNames: PropTypes.func
}

export default withStyles(styles)(QuestionSectionModal)
