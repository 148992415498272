import { createSlice } from '@reduxjs/toolkit'
import { SLICE_NAME } from './constants'

import { createExtraReducers } from '../toolkit-utils'

const initialState = {
  verifyOta: {}
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetStore(proxy, { payload }) {
      const { retry } = payload
      return {
        ...initialState,
        verifyOta: {
          ...initialState.verifyOta,
          retry
        }
      }
    }
  },
  extraReducers: createExtraReducers(SLICE_NAME)
})

export const store = slice.reducer
export const actions = slice.actions
