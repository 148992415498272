import React from 'react'
import _ from 'lodash'
import { styled } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

import Dropdown from '../../../../components/Fields/Dropdown'
import Checkbox from '../../../../components/Fields/Checkbox'

import { translations } from '../../../../config'

import {
  actions as reportConfigurationActions,
  hooks as reportConfigurationHooks
} from '../../../../store/modules/reportConfiguration'

import { categoryOptions } from '../../../../store/modules/formViews/selectors'

const versionOptions = [
  { label: 'All', value: '' },
  { label: 'Version 1', value: 1 },
  { label: 'Version 2', value: 2 }
]

const Container = styled('div')(({ theme }) => (
  {
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 1200,
    margin: '0 auto',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 20,
      paddingRight: 20
    }
  }
))

const FullWidthFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%'
}))

const ReportConfigurationFilters = () => {
  //hooks to get currently applied filters
  const dispatch = useDispatch()
  const filters = reportConfigurationHooks.useFormViewFilters()

  const updateFilter = ({ name, value }) => {
    dispatch(reportConfigurationActions.updateFormViewFilters({
      filters: {
        [name]: value
      }
    }))
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <FullWidthFormControl>
            <Dropdown
              label={translations('Version')}
              value={_.get(filters, 'versionId', '')}
              options={versionOptions}
              onChange={(e) => updateFilter({ name: 'versionId', value: e.target.value })}
              noErrorTextLabel
              shrink
            />
          </FullWidthFormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FullWidthFormControl>
            <Dropdown
              label={translations('Category')}
              value={_.get(filters, 'category', '')}
              options={categoryOptions}
              onChange={(e) => updateFilter({ name: 'category', value: e.target.value })}
              noErrorTextLabel
              shrink
            />
          </FullWidthFormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FullWidthFormControl>
            <Checkbox
              label={translations('Include Archived')}
              value={_.get(filters, 'includeArchived', false)}
              onClick={() => updateFilter({ name: 'includeArchived', value: !filters.includeArchived })}
            />
          </FullWidthFormControl>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ReportConfigurationFilters