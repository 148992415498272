import { connect } from 'react-redux'
import WebviewScreen from './WebviewScreen'
import { env, environments } from '../../../config'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const editable = env !== environments.PRODUCTION
  return {
    ...ownProps,
    editable
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(WebviewScreen)
