import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Grid, IconButton, styled, useTheme } from "@material-ui/core"
import { useWebsites } from '../../contexts/WebsiteManagementContext'
import { OPEN } from '../../contexts/constants'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const Block = styled('div')(({ theme, published }) => {
  return ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    height: '75px',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    border: `2px solid ${theme.palette.grey[300]}`,
    fontSize: '20px',
    fontWeight: '400',
    textAlign: 'left',
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      width: '50%'
    },
    [theme.breakpoints.down('md')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      height: '100px',
      textAlign: 'center',
      paddingTop: theme.spacing(3)
    },
    backgroundImage: !published ? `linear-gradient(45deg, ${theme.palette.grey[100]} 25%, #eaeaea 25%, #eaeaea 50%, ${theme.palette.grey[100]} 50%, ${theme.palette.grey[100]} 75%, #eaeaea 75%, #eaeaea 100%)` : 'none',
    backgroundColor: !published ? theme.palette.grey[100] : '#fff',
    backgroundSize: !published ? '8px 8px' : 'initial'
  })
})

const ContentBlock = (props) => {
  const {
    content
  } = props

  const {
    id = '',
    title,
    published
  } = content

  const {
    state,
    fetchPage,
    toggleOverlay,
    togglePageVisiblity
  } = useWebsites()

  const icon = published ? <Visibility /> : <VisibilityOff />

  const handleOnClick = async () => {
    await fetchPage({ id })
    toggleOverlay({ status: OPEN })
  }

  const toggleVisiblity = (e) => {
    e.stopPropagation()
    togglePageVisiblity({ id })
  }

  return (
    <Block onClick={handleOnClick} published={published}>
      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item md={11} xs={12}>
          {title}
        </Grid>
        <Grid item md={1} xs={12}>
          <IconButton onClick={toggleVisiblity}>
            {icon}
          </IconButton>
        </Grid>
      </Grid>
    </Block>
  )


}

export default ContentBlock