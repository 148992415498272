import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import style from './style'

const MenuGrid = ({
  widgets,
  classes,
  renderWidget
}) => {
  return (
    <div className={classes.gridWrapper}>
      <Grid container spacing={2} className={classes.widgetContainer}>
        {widgets.map(widget => {
          return (
              <Grid item xs={6} sm={4} lg={3} key={widget.id} className={classes.gridItemContainer}>
                {renderWidget(widget)}
              </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

MenuGrid.propTypes = {

}

MenuGrid.defaultProps = {
  widgets: PropTypes.arrayOf(PropTypes.shape({}))
}

export default withStyles(style)(MenuGrid)
