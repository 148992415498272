import React, { useCallback, useEffect, useMemo } from 'react'
import Form from '../../../../../../../components/Form'
import FormBody from '../../../../../../../components/Form/FormBody'
import FormError from '../../../../../../../components/Form/FormError'
import BasicModalOverlay from '../../../../../../../components/BasicModalOverlay'
import { translations } from '../../../../../../../config'
import { useDispatch, useSelector } from 'react-redux'
import { getReassignmentError, getReassignmentStatus } from '../../../../../../../store/modules/reportDetails/selectors'
import { actions as reportDetailActions } from '../../../../../../../store/modules/reportDetails'
import { PENDING, SUCCESS } from '../../../../../../../store/middleware/redux-promise'
import Button from '../../../../../../../components/Button/Button'
import { submit } from 'redux-form'
import { getUserStandardOrganisationOptions } from '../../../../../../../store/modules/auth/selectors'
import _ from 'lodash'

const formId = 'ReportReassignmentForm'
const ReportReassignmentForm = Form(formId)

const ReportReassignmentModal = (props) => {
  const {
    onSubmit,
    reset,
    close,
    organisationId
  } = props

  const dispatch = useDispatch()
  const status = useSelector(getReassignmentStatus)
  const error = useSelector(getReassignmentError)

  const actions = [
    {
      text: translations('Cancel'),
      onClick: close,
    },
    {
      text: translations('Reassign Report Organisation'),
      primary: true,
      success: true,
      hasFormValidation: true,
      onClick: () => {
        dispatch(submit(formId))
      }
    }
  ]

  useEffect(() => {
    if (status === SUCCESS) {
      close()
    }
  }, [status])

  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  const organisations = useSelector(getUserStandardOrganisationOptions)
  const filteredOrganisations = _.filter(organisations, org => organisationId !== org.value)

  const getSchema = useCallback(() => {
    if (organisations && !_.isEmpty(organisations)) {
      return [
        {
          id: 'organisation',
          field: 'Dropdown',
          props: {
            label: translations('Organisation'),
            name: 'organisation',
            required: true,
            shrink: false,
            options: filteredOrganisations
          }
        }
      ]
    }
    return [
      {
        id: 'organisation',
        field: 'OrganisationAssociationLookup',
        props: {
          label: translations('Organisation'),
          name: 'organisation',
          required: true,
          shrink: false,
          getCustomValue: ({ id }) => { return id }
        }
      }
    ]
  }, [organisations])

  const schema = [
    {
      id: 'organisation',
      field: 'OrganisationAssociationLookup',
      props: {
        label: translations('Organisation'),
        name: 'organisation',
        required: true,
        shrink: false,
        getCustomValue: ({ id }) => { return id }
      }
    }
  ]

  return (
    <BasicModalOverlay
      submitting={status === PENDING}
      title={translations('Report Reassignment - Header')}
      actions={actions}
    >
      <div style={{ marginTop: 30 }}>
        <ReportReassignmentForm onSubmit={onSubmit}>
          <FormBody>
            <FormBody editing schema={getSchema()} layout={['organisation:12']} />
            <FormError error={error} />
          </FormBody>
        </ReportReassignmentForm>
        <Button></Button>
      </div>
    </BasicModalOverlay>
  )
}

export default ReportReassignmentModal