import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import actions from './actions'

class DevicesListMiddleware {
  fetchDevices = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const matchesNewReport = matchPath(path, { path: '/reports/new', exact: true })
      if (matchesNewReport) {
        dispatch(actions.fetchDevices())
      }
    }
  }
}

export default new DevicesListMiddleware()
