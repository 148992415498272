import _ from 'lodash'
import { useSelector } from 'react-redux'

import { selectors as organisationSelectors } from './'
import { getProfessionOptions } from './helpers'

export const useOrganisationName = (organisationId) => {
  const organisations = useSelector(organisationSelectors.getAllOrganisations)
  const selectedOrganisation = organisations.find(org => org.id === organisationId)
  const selectedOrgName = _.get(selectedOrganisation, 'name')
  return selectedOrgName
}

export const useOrganisationProfessions = (organisationId) => {
  const organisations = useSelector(organisationSelectors.getAllOrganisations)
  const selectedOrganisation = organisations.find(org => org.id === organisationId)
  const selectedOrgProfessions = _.get(selectedOrganisation, 'professions', [])
  return selectedOrgProfessions
}

export const useOrganisationProfessionsOptions = (organisationId) => {
  const professions = useOrganisationProfessions(organisationId)
  const professionOptions = getProfessionOptions(professions)
  return professionOptions
}