import React, { Fragment, useEffect, useState } from 'react'
import { styled, withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import _ from 'lodash'

import Heading from '../../../../components/Heading'
import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import H2 from '../../../../components/H2'
import MoreMenu from '../../AnalyticsMoreMenu'

import { translations } from '../../../../config'

import style from './style'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

const AnalyticsHeader = props => {
  const {
    classes,
  } = props

  const dispatch = useDispatch()

  const back = () => {
    dispatch(push('/monitoring-and-analytics/analytics'))
  }

  return (
    <Fragment>
      {/* desktop */}
      <Hidden xsDown>
        <SubHeader
          className={classes.headerWithDropdown}
          leftContent={<BackBar onClick={back} />}
          rightContent={<MoreMenu />}
          centerContent={<Heading component={'h1'}>{translations('Analytics')}</Heading>
        }
        />
      </Hidden>
      {/* mobile */}
      <Hidden smUp>
        <Fragment>
          <SubHeader
            leftContent={<BackBar />}
            centerContent={<H2 value={_.toUpper(translations('Analytics'))} />}
          />
        </Fragment>
      </Hidden>
    </Fragment>
  )
}

export default withStyles(style)(AnalyticsHeader)
