import { useSelector } from 'react-redux'
import { selectors as authSelectors } from '../auth'
import { selectors as userDetailsSelectors } from './'
import _ from 'lodash'

export const useSelectedUserProfession = () => {
  const currentUserOrganisationId = useSelector(authSelectors.getUserSelectedOrganisationId)
  const currentProfessions = useSelector(userDetailsSelectors.getSelectedUserProfessions)
  const currentProfession = _.filter(currentProfessions, prof => prof.organisationId === currentUserOrganisationId)
  return _.get(currentProfession, '0', {})
}

