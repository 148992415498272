import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'

import MenuGrid from '../../../components/MenuGrid'
import MenuWidget from '../../../components/MenuWidget'

const CommunicationsMenuScreen = ({
  widgets,
  onMenuItemClick,
  onBackClick,
  pageTitle
}) => {
  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
      />
      <MenuGrid widgets={widgets} renderWidget={(widget) => {
        return (
          <MenuWidget
            id={widget.id} {...widget}
            onClick={() => onMenuItemClick(widget.id)}
          />
        )
      }} />
    </div>
  )
}

CommunicationsMenuScreen.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string
  })),
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default CommunicationsMenuScreen
