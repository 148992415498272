import moment from 'moment'
import { translations } from '../../../../../config'
import * as validators from '../../../../../validators'

const getSchema = ({
  otherFollowUpNames,
  organisationLanguages
} = {}) => (
  [
    {
      id: 'name',
      field: 'Input',
      props: {
        label: translations('Name'),
        required: true,
        shrink: true,
        validate: [validators.valueBlocklist(otherFollowUpNames, translations('Name must be unique'), true /* ignore case */)]
      }
    },
    {
      id: 'scheduledDate',
      field: 'Date',
      props: {
        required: true,
        shrink: true,
        name: 'scheduledDate',
        label: translations('FollowUp - Form Input Scheduled Date'),
        startDate: moment().add(1, 'day').format('YYYYMMDD'),
        endDate: moment().add(10, 'years').format('YYYYMMDD')
      }
    },
    {
      id: 'message',
      field: 'MultiLanguageInput',
      props: {
        shrink: true,
        name: 'message',
        label: translations('FollowUp - Form Input Message'),
        validate: [validators.maxLength(1024, 'Field')],
        languages: organisationLanguages
      }
    }
  ]
)

export default getSchema
