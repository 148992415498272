const style = theme => ({
  followupContainer: {
    marginBottom: theme.spacing(4)
  },
  followup: {
    backgroundColor: ({ placeholder }) => placeholder ? `${theme.palette.grey['100']}` : '#ffffff',
    borderStyle: ({ placeholder }) => placeholder ? 'dashed' : 'solid',
    marginBottom: theme.spacing(1)
  },
  followupToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  followupAction: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  followupBlocks: {
    display: 'flex',
    flexDirection: 'column'
  },
  blockTitle: {
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    background: theme.palette.grey['200'],
    color: theme.palette.grey['800'],
    display: 'block',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    lineHeight: 1
  },
  followupItems: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  followUpHeader: {
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    background: theme.palette.grey['200'],
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    display: 'flex'
  }
})

export default style
