import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  status: undefined,
  error: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_LANGUAGES:
      return reducers.fetchLanguages(state, action)
    default:
      return state
  }
}
