import { asyncActions } from '../../../../../store/modules/terminology'


export const config = ({ translations }) => ({
    formName: 'newArrangement',
    title: 'Terminology - Add Arrangement',
    schema: [
        {
            id: 'name',
            field: 'Input',
            props: {
                label: translations('Name'),
                required: true,
                validate: []
            }
        },
        {
            id: 'description',
            field: 'Input',
            props: {
                label: translations('Description')
            }
        }

    ],
    onSubmit(response, dispatch) {
        const terminologyArrangementRequest = {
            terminologyArrangement: {
                name: response.name,
                description: response.description
            },
            options: {
                useIgnoredTerms: response.useIgnoredTerms
            }
        }
        return dispatch(asyncActions.createTerminologyArrangement(terminologyArrangementRequest))
    }
})
