import React from "react"
import modalService from '../../../../services/modalService'
import ConfigureEntryModal from "./ConfigureEntryModal"
import { useDispatch, useSelector } from 'react-redux'
import fp from 'lodash/fp'
import { selectors, asyncActions } from '../../../../store/modules/terminology'

export const useEntryConfigureModal = ({ entryId } = { entryId: null }) => {
 return () => modalService.open({
    component: ConfigureEntryModal,
    fullScreen: false,
    entryId
  })
}




export const useGetInitialValues = ({ entity }) => {
    const primaryDictionaryId = useSelector(selectors.getPrimaryDictionaryIdForArrangement)
    const terminologyArrangementId = useSelector(selectors.getActiveArrangementId)
    const isUsageTypeList = useSelector(selectors.isUsageTypeList)
    const initialActionType = fp.getOr('NONE', 'action.actionType', entity)
    const initialValues = fp.compose(
      fp.merge({
        terminologyDictionaryId: primaryDictionaryId,
        terminologyArrangementId,
        actionType: initialActionType,
        isUsageTypeList
      }),
      fp.pick(['id', 'term', 'definition'])
      )(entity)
      const isMappingAction = fp.includes(initialActionType, ['APPLY_MEDDRA_MAPPING', 'APPLY_ENTRY_MAPPING', 'APPLY_FREE_TEXT_MAPPING'])
    if (isMappingAction) {
      initialValues.actionType = 'APPLY_MAPPING'

      switch (initialActionType) {
        case 'APPLY_MEDDRA_MAPPING':
          initialValues.mappingOption = 'meddra'
          initialValues.mapToMeddraId = { value: entity.action.meddra.id, label: entity.action.meddra.name }
          break
        case 'APPLY_ENTRY_MAPPING':
          initialValues.mappingOption = entity.action.mappedEntry.dictionary.id
          initialValues.mapToEntryId = { value: entity.action.mappedEntry.id, label: entity.action.mappedEntry.term }
          break
        case 'APPLY_FREE_TEXT_MAPPING':
        initialValues.mappingOption = 'freeText'
        initialValues.mapToFreeText = entity.action.mapToFreeText
        break
        default:
          break
      }
    }
    return initialValues
}

export const useGetExistingEntry = ({ entryId }) => {
    const dispatch = useDispatch()
    React.useEffect(() => {
        if (!!entryId) {
            dispatch(asyncActions.fetchArrangementTerminologyEntryById({ entryId }))
        }
    }, [dispatch, entryId ])
}
