export default theme => ({
  simpleItemInner: {
    padding: 9,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  simpleItemText: {
    margin: 0,
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexShrink: 1,
    minWidth: 0
  },
  simpleItemValue: {
    margin: 0,
    marginLeft: 5,
    flexShrink: 0
  }
})
