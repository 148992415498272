import { promiseReducer } from '../../util'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class PlatformDetailsReducers {
  fetchPlatform = promiseReducer(
    (state, action) => ({
      ...state,
      platform: action.result
    })
  )
  updatePlatform = promiseReducer(
    (state, action) => {
      return {
        ...state,
        platform: action.result
      }
    }
  )
  createPlatform = promiseReducer(
    (state, action) => {
      return {
        ...state
      }
    }
  )
}

export default new PlatformDetailsReducers()
