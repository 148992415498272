import _ from 'lodash'
import React from 'react'
import Section from '../Section'
import { ActionButton } from '../ReportSummaryModal'
import { translations } from '../../../../../config'

const SendActions = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection,
    report
  } = props

  const EXAMPLE = <ActionButton>{translations('Example 1')}</ActionButton>
  const EXAMPLE2 = <ActionButton>{translations('Example 2')}</ActionButton>

  const rows = [
    { type: 'ACTION', value: EXAMPLE },
    { type: 'ACTION', value: EXAMPLE2 },
  ]

  return <></>

  return (
    <Section
      name='Send To...'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
    />
  )
}

export default SendActions