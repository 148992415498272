import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  status: undefined,
  error: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_SELECTED_RECEIVER:
      return reducers.fetchSelectedReceiver(state, action)
    case constants.FETCH_SELECTED_RECEIVER_FOR_PUBLIC_REPORT_FORM:
      return reducers.fetchSelectedReceiverForPublicForm(state, action)
    default:
      return state
  }
}
