import React, { useMemo, useRef } from 'react'
import _ from 'lodash'
import { Form } from 'react-final-form'
import { getIn } from 'final-form'
import createDecorator from 'final-form-focus'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'
import StandardFormDisplay from './StandardFormDisplay'
import styled, { ThemeProvider } from 'styled-components'
import { baseTheme } from '../../styles/themes'
import arrayMutators from 'final-form-arrays'

const formSchemaLibrary = new FormSchemaLibrary()

const StandardForm = (props) => {
  const {
    schemaName,
    schemaArgs,
    onSubmit,
    actions,
    theme,
    organisationDetails,
    professions,
    submitText,
    fullWidthSubmit,
    initialValues,
    privacyPolicyLink
  } = props

  const touchedInputs = useRef({})

  const findInput = (inputs, errors) => {
    const notTouched = !_.some(touchedInputs.current.value, Boolean)
    if (notTouched) return null
    return inputs.find(input => {
      const name = input.name || _.get(input, 'dataset.name')
      return name && getIn(errors, name)
    })
  }
  const focusOnErrors = useMemo(() => createDecorator(null, findInput), [])

  return (
    <ThemeProvider theme={theme || baseTheme}>
      <Form
        component={StandardFormDisplay}
        schemaName={schemaName}
        schemaArgs={schemaArgs}
        onSubmit={onSubmit}
        decorators={[focusOnErrors]}
        formSchemaLibrary={formSchemaLibrary}
        subscription={{
          values: true,
          submitting: true,
          submitError: true,
          submitErrors: true,
          submitFailed: true,
          submitSucceeded: true,
          touched: true,
        }}
        mutators={{
          ...arrayMutators
        }}
        actions={actions}
        organisationDetails={organisationDetails}
        professions={professions}
        submitText={submitText}
        fullWidthSubmit={fullWidthSubmit}
        initialValues={initialValues}
        privacyPolicyLink={privacyPolicyLink}
      />
    </ThemeProvider>
  )
}

export default StandardForm