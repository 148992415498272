import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import { actions as languagesActions } from '../languages'
import * as authSelectors from '../auth/selectors'

class LanguagesMiddleware {
  fetchLanguagesForOrgDetails = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const matchesOrgDetails = matchPath(path, { path: '/organisations/:id', exact: true })
      const matchesMyOrgDetails = matchPath(path, { path: '/my-organisation', exact: true })
      const matchesOrgTranslations = matchPath(path, { path: ['/organisations/:id/translations', '/organisations/:id/translations/*'], exact: true })
      const matchesOrgPages = matchPath(path, { path: '/organisations/:id/page-management', exact: true })

      const result = matchesOrgDetails || matchesOrgTranslations || matchesOrgPages || matchesMyOrgDetails
      const orgId = _.get(result, 'params.id') || authSelectors.getSelectedOrganisation(getState())
      if (result && orgId !== 'new') {
        dispatch(languagesActions.fetchLanguages())
      }
    }
  }
}

export default new LanguagesMiddleware()
