import _ from 'lodash'

/**
 * Apply default options if required
 * @param {object} payload - Data for the section
 * @returns {object} Payload with defaults
 */
export const withDefaults = (editing) => (payload) => {
  return {
    ...(!editing && {
      visible: true,
      isCustom: true
    }),
    ...payload
  }
}

/**
 * Apply conditions logic based on the value to isForThromboticEvents
 * @param {object} payload - Data for the section
 * @returns {object} Payload with conditions
 */
export const isForThromboticEvents = (payload) => {
  const nextPayload = _.clone(payload)
  const isForThromboticEvents = _.get(payload, 'isForThromboticEvents')

  if (_.isBoolean(isForThromboticEvents)) {
    const conditions = isForThromboticEvents ? {
      any: [
        {
          fact: 'relateToThromboticEvents',
          value: '1',
          operator: 'equal'
        }
      ]
    } : {}
    _.set(nextPayload, 'conditions', conditions)
  }

  return nextPayload
}

/**
 * Apply conditions logic based on the value to isForMyocarditis
 * @param {object} payload - Data for the section
 * @returns {object} Payload with conditions
 */
export const isForMyocarditis = (payload) => {
  const nextPayload = _.clone(payload)
  const isForMyocarditis = _.get(payload, 'isForMyocarditis')
  if (_.isBoolean(isForMyocarditis)) {
    const conditions = isForMyocarditis ? {
      any: [
        {
          fact: 'relateToMyocarditis',
          value: '1',
          operator: 'equal'
        }
      ]
    } : {}
    _.set(nextPayload, 'conditions', conditions)
  }

  return nextPayload
}
