import { constants as reportDetailsConstants } from '../reportDetails'
import { constants as ackDetailsConstants } from '../ackDetails'
import { constants as json2xmlConstants } from '../json2xml'
import { selectors as authSelectors } from '../auth'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { getFormValues } from 'redux-form'

class Json2xmlActions {
  json2xml = ({ formSchemaId }) => ({
    type: json2xmlConstants.JSON_2_XML,
    promise: (dispatch, getState) => {
      const state = getState()
      const fields = getFormValues(reportDetailsConstants.NEW_FORM_NAME)(state)
      const userId = authSelectors.getActiveUserId(state)
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      return digitalStoreSdk.reports.json2xml({ report: fields, userId, organisationId, formSchemaId })
    }
  })
  ackJson2xml = ({ reportId }) => ({
    type: json2xmlConstants.ACK_JSON_2_XML,
    promise: (dispatch, getState) => {
      const state = getState()
      const fields = getFormValues(ackDetailsConstants.NEW_ACK_FORM_NAME)(state)
      return digitalStoreSdk.acks.json2xml({ reportId, ack: fields })
    }
  })
}

export default new Json2xmlActions()
