import React from 'react'
import _ from 'lodash'

import ReportConfigurationFollowUpScreen from '../ReportConfigurationFollowUpScreen'

const ReportConfigurationFollowUpPanel = () => {
  return (
    <ReportConfigurationFollowUpScreen />
  )
}

ReportConfigurationFollowUpPanel.propTypes = {}

export default ReportConfigurationFollowUpPanel
