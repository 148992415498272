import _ from 'lodash'
import { connect } from 'react-redux'
import { selectors as authSelectors } from '../../../store/modules/auth'
import AppDetails from './AppDetails'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const userFullName = authSelectors.getActiveUserFullName(state)
  const organisationName = (authSelectors.getSelectedOrganisation(state) || {}).name
  const versionNo = window.env.REACT_APP_VERSION || process.env.REACT_APP_VERSION
  const buildNo = window.env.REACT_APP_RELEASE_VERSION || 'local'
  const selectedOrganisation = authSelectors.getSelectedOrganisation(state)
  const privacyPolicyLink = _.get(selectedOrganisation, 'details.privacyPolicyLink')

  return {
    ...ownProps,
    organisationName,
    userFullName,
    versionNo,
    buildNo,
    privacyPolicyLink
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AppDetails)
