import { connect } from 'react-redux'
import { compose } from 'recompose'
import { actions as authActions } from '../../../../store/modules/auth'

import ProductItem from './ProductItem'

const mapDispatchToProps = {
  editWatchList: authActions.editWatchList
}

const enhance = compose(
  connect(null, mapDispatchToProps)
)

export default enhance(ProductItem)
