import fp from 'lodash/fp'

export class Diagnostics {
    /**
     *
     * @param {import('@sentry/react')} service
     */
    constructor(service) {
        /**
         * @type {import('@sentry/react')}
         * @private
         */
        this.service = service
        this.errorBoundary = service.ErrorBoundary
        /**
         * @private
         */
        this.initalised = false
    }

    initialise(options) {
        if (this.initalised !== true) {
            this.service.init(options)
            this.initalised = true
        }
    }
    /**
     * @private
     * @returns
     */
    isNotInitialised() {
        return this.initalised === false
    }
    addBreadcrumb(options) {
        if (this.isNotInitialised()) {
            return
        }
        this.service.addBreadcrumb(options)
    }
    trackReportMetadata({
        userId,
        extReportId,
        reportId,
        languageCode,
        followUpUserId,
        formView,
        success
    }) {
        if (this.isNotInitialised()) {
            return
        }
        if (userId) {
            this.service.setUser({ id: userId })
        }
        if (languageCode) {
            this.service.setTag("language_code", languageCode);
        }
        if (extReportId) {
            this.service.setTag("ext_report_id", extReportId)
        }
        if (reportId) {
            this.service.setTag("report_id", reportId)
        }
        if (fp.isObject(formView)) {
            const [formViewId, organisationId, formSchemaId] = fp.at(['id', 'organisationId', 'formSchemaId'], formView)
            if (formViewId) {
                this.service.setTag("form_view_id", formViewId)
            }
            if (formSchemaId) {
                this.service.setTag("form_schema_id", formSchemaId)
            }
            if (organisationId) {
                this.service.setTag("organisation_id", organisationId)
            }
        }
        this.service.setContext('report', {
            followUpUserId,
            success
        })

    }
    getErrorBoundary() {
        if (this.isNotInitialised()) {
            return
        }
        return this.errorBoundary
    }
    getStatus() {
        return {
            isInitialised: this.initalised,
            hasErrorBoundary: !!this.hasErrorBoundary
        }
    }
    /**
     *  Use the form schema to test the report JSON.
     * @param {string} formSchemaName
     */
    setFormSchemaName(formSchemaName) {
        if (this.isNotInitialised()) {
            return
        }
        this.formSchemaName = formSchemaName
    }
    /**
      *  Use the form schema to test the report JSON.
      * @param {string} formSchemaName
      */
    checkReportJson({ reportJson }) {
        if (this.isNotInitialised()) {
            return
        }
        if (this.formSchemaName) {
            switch (this.formSchemaName) {
                case 'mhraR2Report':
                    this.checkMedReports({ reportJson })
                    break;
                case 'mhraR3Report':
                    this.checkMedReports({ reportJson })
                    break;
                case 'devicesReport':
                    break
                default:
                    break
            }
        }
    }
    /**
     * Run tests on a report JSON for any errors that could have occured during the form completion process.
     * @param {object} parameters
     * @param {object} paramters.reportJson
     */
    checkMedReports({ reportJson }) {
        if (this.isNotInitialised()) {
            return
        }
        const medReportTests = [
            {
                isMalformed: isRepeatableEmpty({ reportJson, repeatableKey: 'drug' }),
                tag: 'r_empty_drug'
            },
            {
                isMalformed: isRepeatableEmpty({ reportJson, repeatableKey: 'reaction' }),
                tag: 'r_empty_reaction'
            },
            {
                isMalformed: isARepeatableInstanceMissingValues({ reportJson, repeatableKey: 'drug', mandatoryKeys: ['medicinalproduct'] }),
                tag: 'r_partial_drug'
            },
            {
                isMalformed: isARepeatableInstanceMissingValues({ reportJson, repeatableKey: 'reaction', mandatoryKeys: ['reactionoutcome'] }),
                tag: 'r_partial_reaction'
            },
            {
                isMalformed: isValueEmpty({ reportJson, fieldKey: 'qualification_primarysource' }),
                tag: 'qualification_primarysource'
            }
        ]
        let isMalformedReport = false

        fp.forEach(({ tag, isMalformed }) => {
            if (isMalformed) {
                isMalformedReport = true
            }
            this.service.setTag(tag, isMalformed ? 'yes' : 'no')

        }, medReportTests)

        if (isMalformedReport) {
            this.service.captureException(new Error('Malformed Report'))
        }
    }
}

const isRepeatableEmpty = ({ reportJson, repeatableKey }) => {
    return fp.compose(
        fp.isEmpty,
        fp.get(repeatableKey)
    )(reportJson)
}

const isARepeatableInstanceMissingValues = ({ reportJson, repeatableKey, mandatoryKeys }) => {
    const repeatable = fp.get(repeatableKey, reportJson)
    if (fp.isEmpty(repeatable)) {
        return false
    }
    return fp.any(repeatableInstance => {
        if (fp.isEmpty(repeatableInstance)) {
            return true
        }
        for (const key of mandatoryKeys) {
            const value = fp.get(key, repeatableInstance)
            if (fp.isUndefined(value)) {
                return true
            }
        }
        return false
    }, repeatable)
}

const isValueEmpty = ({ reportJson, fieldKey }) => {
    const field = fp.get(fieldKey, reportJson)
    return fp.isEmpty(field)
}
