
import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import ReportsSearchScreen from './ReportsSearchScreen'
import ReportDetailsScreen from './ReportDetailsScreen'

import TimedLogoutComponent from '../../components/TimedLogout'
import ReportAuditTrail from './ReportAuditTrail'
import ReportAmendmentScreen from './ReportAmendmentScreen'

export default [
  <PrivateRoute key='/report-management' path='/report-management' exact component={TimedLogoutComponent(ReportsSearchScreen)} />,
  <PrivateRoute key='/report-management/:id' path='/report-management/:id' exact component={TimedLogoutComponent(ReportDetailsScreen)} />,
  <PrivateRoute key='/report-management/:id/audit' path='/report-management/:id/audit' exact component={TimedLogoutComponent(ReportAuditTrail)} />,
  <PrivateRoute key='/report-management/:id/amend' path='/report-management/:id/amend' exact component={TimedLogoutComponent(ReportAmendmentScreen)} />
]
