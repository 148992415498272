import mhraR2Ack from './mhraR2Ack'
import mhraR2Report from './mhraR2Report'

import mhraR3Report from './mhraR3Report'

import redantAck from './redantAck'
import redantReport from './redantReport'

import trilogyAck from './trilogyAck'
import trilogyReport from './trilogyReport'

const formSchemas = {
  mhraR2Ack,
  mhraR2Report: mhraR2Report.formSchema,
  mhraR3Report: mhraR3Report.formSchema,

  redantAck,
  redantReport: redantReport.formSchema,

  trilogyAck,
  trilogyReport: trilogyReport.formSchema
}

const initialFieldMap = {
  mhraR2Report: mhraR2Report.initialFieldMap,
  mhraR3Report: mhraR3Report.initialFieldMap,
  redantReport: redantReport.initialFieldMap,
  trilogyReport: trilogyReport.initialFieldMap
}

export {
  formSchemas,
  initialFieldMap
}
