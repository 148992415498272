import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import { StyledInput } from '../TextInput'
import FieldMeta from '../FieldMeta'
import Field from '../Field'
import _ from 'lodash'
import NullFlavourField from '../NullFlavourField'

const StyledTextarea = styled(StyledInput)`
  min-height: ${rem('160px')};
  ${media.greaterThan('small')`
    min-height: ${rem('250px')};    
  `}
`

/**
 * A multi-line plain-text editing control, useful when you want to allow users to enter a sizeable amount of free-form text
 */
const Textarea = (props) => {
  const {
    input: {
      name,
      value,
      onChange,
      onFocus,
      onBlur
    },
    meta: {
      error,
      touched
    },
    id,
    label,
    hint,
    required,
    nullFlavours,
    disabled
  } = props

  const fieldInvalid = error && touched
  const errorMessage = fieldInvalid ? error : undefined

  const hasNullFlavours = nullFlavours && !_.isEmpty(nullFlavours)

  const renderTextarea = () => {
    return (
      <Field error={errorMessage}>
        <FieldMeta name={id} label={label} error={errorMessage} hint={hint} required={required} disabled={disabled} />
        <StyledTextarea
          as={'textarea'}
          id={id}
          name={name}
          value={value}
          error={errorMessage}
          aria-required={required}
          aria-invalid={fieldInvalid}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
      </Field>
    )
  }

  if (hasNullFlavours) {
    return (
      <NullFlavourField
        {...props}
        render={renderTextarea}
      />
    )
  }

  return (renderTextarea())
}

Textarea.propTypes = {
  /** Value of the input */
  value: PropTypes.string,
  /** Input ID */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Field validation message  */
  error: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** Input props based from React Final Form
   * 
   * `name` - Used to associate label and input
   *
   * `value` - Value of the input
   * 
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   * 
   * `onFocus` - Function called when focus has been given to the field
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }),
  /** Meta props based from React Final Form
   * 
   * `error` - Field validation message
   * 
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
}

Textarea.defaultProps = {
  required: false,
  input: {},
  meta: {}
}

export default Textarea
