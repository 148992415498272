import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import style from './style'

const P = (props) => {
  const { type, value, onClick, className, classes, style, role, dontBreakOut } = props
  const getStyle = type => {
    const classTypes = {
      large: classes.large,
      hubTileText: classes.hubTileText,
      promoText: classes.promoText,
      textButton: classes.textButton,
      alert: classes.alert,
      inline: classes.inline,
      bold: classes.bold,
      greenText: classes.greenText,
      error: classes.error
    }
    return classTypes[type] || classes.p
  }
  const pClass = getStyle(type)
  return (
    <div
      className={classNames(className, pClass, {
        [classes.dontBreakOut]: dontBreakOut
      })}
      onClick={onClick}
      style={style}
      role={role}
    >
      {value}
    </div>
  )
}

P.propTypes = {
  type: PropTypes.oneOf([
    'large',
    'hubTileText',
    'promoText',
    'textButton',
    'alert',
    'inline',
    'greenText',
    'error'
  ]),
  value: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  onClick: PropTypes.func
}

export default withStyles(style)(P)
