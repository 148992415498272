import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { push } from 'connected-react-router'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import CommunicationsTemplateForm from '../CommunicationsTemplateForm'
import {
  actions as communicationTemplateActions,
  selectors as communicationTemplateSelectors
} from '../../../store/modules/communicationTemplates'

import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

import { useIsSystemTemplate } from '../../../store/modules/communicationTemplates/hooks'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import toastService from '../../../services/toastService'
import { translations } from '../../../config'

// eslint-disable-next-line react/prop-types
const renderHeader = ({ pageTitle }) => {
  const { title, titleWithName } = pageTitle
  return (
    <SubHeader
      leftContent={<BackBar />}
      centerContent={<Fragment>
        <Heading component='h1' uppercase>{title}</Heading>
        <Helmet>
          <title>{titleWithName}</title>
        </Helmet>
      </Fragment>}
    />
  )
}

const renderForm = ({ channel, type, id, initialValues }) => {
  return <CommunicationsTemplateForm channel={channel} id={id} type={type} initialValues={initialValues} />
}

const CommunicationsEditScreen = (props) => {
  const { classes, match } = props
  const dispatch = useDispatch()
  const channel = _.get(match, 'params.channel')
  const id = _.get(match, 'params.id')
  const isSystemTemplate = useIsSystemTemplate(id)
  const pageTitle = useSelector(platformSelectors.getPageTitle)(`Communications Edit - Page Title ${_.capitalize(channel)}`)
  const organisationId = useSelector(authSelectors.getUserSelectedOrganisationId)
  const orgTemplates = useSelector(communicationTemplateSelectors.getOrganisationCommunicationTemplates)
  const systemTemplates = useSelector(communicationTemplateSelectors.getSystemCommunicationTemplatesState)
  const templates = isSystemTemplate ? systemTemplates : orgTemplates
  const template = _.get(templates, `entities.${id}`)
  
  const [initialValues, setInitiaValues] = useState(null)
  const [type, setType] = useState(null)

  useEffect(async () => {
    if(!template) {
      try {
        switch(channel) {
          case 'sms':
            dispatch(communicationTemplateActions.fetchOrganisationSmsTemplate({ organisationId, id }))
          case 'push':
            dispatch(communicationTemplateActions.fetchOrganisationPushTemplate({ organisationId, id }))
          default:
            try {
              await dispatch(communicationTemplateActions.fetchOrganisationEmailTemplate({ organisationId, id }))
            } catch (error) {
                await dispatch(communicationTemplateActions.fetchSystemEmailTemplate({id}))
            }
        }
      } catch (error) {
        dispatch(push('/communications'))
        toastService.action({
          type: 'error',
          message: translations(_.get(error, 'code') ? error.code : 'Generic Error Page Title')
        })
      }
    }
  }, [])

  useEffect(() => {
    if (template) {
      const { name, content } = template
      switch(channel) {
        case 'sms':
          setInitiaValues({
            name,
            content: _.reduce(content, (memo, item, key) => {
              memo[key] = _.pick(item, ['body'])
              return memo
            }, {})
          })
          setType(_.get(template, 'smsTemplate.type'))
          break
        case 'push':
          setInitiaValues({
            name,
            content: _.reduce(content, (memo, item, key) => {
              memo[key] = _.pick(item, ['body'])
              return memo
            }, {})
          })
          setType(_.get(template, 'pushTemplate.type'))
          break
          
        default:
          setInitiaValues({
            name,
            content: _.reduce(content, (memo, item, key) => {
              memo[key] = _.pick(item, ['subject', 'body'])
              return memo
            }, {})
          })
          setType(_.get(template, 'emailTemplate.type'))
          break
      }
    }
  }, [template, channel])

  return <Fragment>
    {renderHeader({ pageTitle })}
    <div className={classes.container}>
      {initialValues && renderForm({ channel, type, id, initialValues }) }
    </div>
  </Fragment>
}

export default withStyles(style)(CommunicationsEditScreen)
