import { useCallback, useEffect } from "react"
import { asyncActions } from "../../../../../store/modules/announcementManagement"
import { routerActions } from "connected-react-router"
import toastService from "../../../../../services/toastService"
import { dateFormat, translations } from "../../../../../config"
import modalService from "../../../../../services/modalService"
import ConfirmationModal from "../../../../../components/ConfirmationModal"
import _ from "lodash"
import moment from "moment"

const getDate = ({ args, dateField, timeField }) => {
  const date = args[dateField]
  const time = args[timeField]
  if (!date && !time) {
    return
  }
  if (date && time) {
    const dateTime = _.join([date, time], ' ')
    return moment(dateTime).valueOf()
  }
  return moment(date, dateFormat).valueOf()
}

export const useUpsertAnnouncement = ({ dispatch }) => useCallback(async args => {
  try {
    args.publishFromDate = getDate({ args, dateField: 'fromDate', timeField: 'fromTime' })
    args.publishToDate = getDate({ args, dateField: 'toDate', timeField: 'toTime' })

    await dispatch(asyncActions.saveDetail(args))
    toastService.action({
      type: 'success',
      message: translations('Announcement Management - Upsert success'),
      autoHideDuration: 6000
    })
  } catch (error) {
    toastService.action({
      type: 'error',
      message: translations('Announcement Management - Upsert failure'),
      autoHideDuration: 6000
    })
  }
}, [dispatch])

export const useDeleteAnnouncement = ({ dispatch, id }) => useCallback(async args => {
  modalService.open({
    component: ConfirmationModal,
    disableBackdropClick: true,
    onConfirmation: async () => {
      try {
        await dispatch(asyncActions.deleteDetail({ id }))
        toastService.action({
          type: 'success',
          message: translations('Announcement Management - Delete success'),
          autoHideDuration: 6000
        })
      } catch (error) {
        toastService.action({
          type: 'error',
          message: translations('Announcement Management - Delete failure'),
          autoHideDuration: 6000
        })
      } finally {
        await dispatch(routerActions.goBack())
      }
    },
    title: translations('Announcement Management - Delete Announcement'),
    message: translations('Announcement Management - Delete Announcement Confirmation')

  })
}, [dispatch, id])

export const useGetAnnouncementById = ({ dispatch, id }) => useEffect(() => {
  if (!id) {
    return
  }
  return dispatch(asyncActions.getDetail({ id }))
}, [dispatch, id])
