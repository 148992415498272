import { colors } from '../../config/theme'

const styles = theme => ({
  ulBullet: {
    display: 'block',
    margin: '1px 3px 0px 0px'
  },
  li: {
    ...theme.p.p,
    color: colors.text,
    marginBottom: '3px',
    '&:before': {
      content: ' "" ',
      display: 'inline-block',
      width: '6px',
      height: '6px',
      marginRight: '11px',
      background: theme.palette.primary.main,
      marginTop: '9px'
    }
  }
})

export default theme => styles(theme)
