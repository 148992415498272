import React from 'react'
import * as redux from 'react-redux'
import { submit } from 'redux-form'

import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import Form, { FormBody } from '../../../../components/Form'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import { translations } from '../../../../config'

const TerminologyModal = ({
  onSubmit,
  initialValues = {},
  dismiss,
  title,
  formName,
  schema,
  closeModal
}) => {
  const dispatch = redux.useDispatch()

  const ModalForm = React.useMemo(() => {
    return Form(formName)
  }, [formName])

  const actions = React.useMemo(() => {
    return [
      {
        success: true,
        text: translations('Confirm'),
        primary: true,
        onClick: () => {
          dispatch(submit(formName))
          closeModal()
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [dismiss, dispatch, formName])


  return (
    <BasicModalOverlay title={translations(title)} actions={actions}>
      <ModalForm onSubmit={onSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <FormBody
            schema={schema}
            editing
            fullWidthFields
          />
        </ContentBoxBody>
      </ModalForm>
    </BasicModalOverlay>
  )
}

export default TerminologyModal
