import moment from 'moment'
import _ from 'lodash'

export const defaultStartYear = moment().subtract(120, 'years').format('YYYY')

export const getYears = (startYear, finalYearOffset = 0, endYear) => {
    const resolvedStartYear = !!startYear ? startYear : defaultStartYear
    const resolvedEndYear = !!endYear ? endYear : moment().year()
    const numericEndYearWithOffset = _.toNumber(resolvedEndYear) + _.toNumber(finalYearOffset)
    const years = _.map(_.range(numericEndYearWithOffset, resolvedStartYear - 1), _.toString)
    return years
}
