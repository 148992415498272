import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { name, pageSize } from './constants'
import { GenericSelectors } from '../toolkit-utils/selectors'
import { createExtraReducers, createStatefulThunk } from '../toolkit-utils'
export const selectors = new GenericSelectors(name)


export const getChangeRequests = createStatefulThunk({
    typePrefix: `changeManagement/fetchAll`,
    path: 'master',
    options: {
        isPaginated: true
    },
    asyncFunction: async (_args, thunkApi) => {
        const state = thunkApi.getState()
        const filters = selectors.getMasterFilters(state)
        const page = selectors.getMasterPageNumber(state)
        const data = await digitalStoreSdk
            .changeRequests
            .fetchAll({ filters, pagination: { page, size: pageSize } })
        return data
    }
})

const getChangeRequestById = createAsyncThunk(
    'changeManagement/fetchById',
    async ({ changeRequestId }, thunkApi) => {
        try {
            const data = await digitalStoreSdk.changeRequests.fetchById({ changeRequestId })
            return thunkApi.fulfillWithValue({ data }, { path: ['detail', changeRequestId] })
        } catch (error) {
            return thunkApi.rejectWithValue({ error }, { path: ['detail', changeRequestId] })
        }
    },
    {
        getPendingMeta: ({ arg }) => {
            return { path: ['detail', arg.changeRequestId] }
        }
    }
)

const initialState = {
    master: {
        filters: {},
        pagination: {
            page: 1,
            total: 0,
            count: 0
        },
        data: []
    },
    detail: {}
}


const slice = createSlice({
    name,
    initialState,
    reducers: {
        updateFilters(state, action) {
            _.set(state, 'master.filters', action.payload.filters)
            _.set(state, 'master.pagination.page', initialState.master.pagination.page)
            _.set(state, 'master.data', initialState.master.data)
        },
        incrementPage(state) {
            state.master.pagination.page++
        },
        resetStore() {
            return initialState
        }
    },
    extraReducers: createExtraReducers(name)
})
export const asyncActions = {
    getChangeRequests,
    getChangeRequestById
}


export const actions = slice.actions
export const store = slice.reducer
export * as middleware from './middleware'
