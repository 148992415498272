import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import BackBar from '../../../components/BackBar'
import { withStyles } from '@material-ui/core/styles'

import style from './style'
import { assets, translations, auth, loginTypes } from '../../../config'
import MainLogo from '../../../components/MainLogo'
import P from '../../../components/P'
import Heading from '../../../components/Heading'
import VisuallyHidden from '../../../components/VisuallyHidden'
import ForgotPasswordForm from '../ForgotPasswordForm'

class ForgotPasswordScreen extends Component {
  state = {
    submitted: false
  }

  onSubmissionComplete = () => {
    this.setState({ submitted: true })
  }

  render = () => {
    const { classes, pageTitle, goBack } = this.props

    const forgotPasswordText = auth.loginType === loginTypes.USERNAME
      ? translations('Username Forgot Password Text')
      : translations('Email Forgot Password Text')

    return (
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <MainLogo />
          <VisuallyHidden>
            <h1>{pageTitle.title}</h1>
          </VisuallyHidden>
          <Helmet>
            <title>{pageTitle.titleWithName}</title>
          </Helmet>
          {this.state.submitted
            ? <div>
              <div className={classes.checkContainer}>
                <img className={classes.logo} src={assets.checkcircle} alt='' />
              </div>
              <div className={classes.header}>
                <Heading>{translations('Submitted')}</Heading>
              </div>
              <div className={classes.textContainer}>
                <P value={translations('Thank you for submitting a forgotten password request. If the email address you submitted is associated to an existing account, then a password reset email will be sent to the email address assigned to that account.')} />
              </div>
            </div>
            : <div>
              <div className={classes.textContainer}>
                <P value={forgotPasswordText} />
              </div>
              <ForgotPasswordForm onSubmissionComplete={this.onSubmissionComplete} />
            </div>
          }
          <div className={classes.forgotLinkWrapper}>
            <BackBar onClick={goBack} showTextXs />
          </div>
        </div>
      </div>
    )
  }
}

ForgotPasswordScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  goBack: PropTypes.func.isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(style)(ForgotPasswordScreen)
