import React, { useState, useEffect } from 'react'
import Dropdown from '../../../../components/Fields/Dropdown'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'
import RightButtonContent from '../../../../components/Form/RightButtonContent'
import ToggleIconButton from '../../../../components/ToggleIconButton'
import Heading from '../../../../components/Heading'

import { translations } from '../../../../config'

const SelectReportDropdown = (props) => {
  const {
    classes,
    selectedReport,
    selectReport,
    onMenuItemClick,
    formOptions,
    forms
  } = props

  const [isOpen, setIsOpen] = useState(true)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return(
    <div className={classes.formContentWrapper}>
    <ContentBox
      id={'select-report'}
      contentId={'select-report'}
      hidden={!isOpen}
    >
      <ContentBoxHeader xsLeft={2} xsText={8} xsRight={2}>
        <TextContent>
          <Heading id={'select-report'}>
            {translations('Select report')}
          </Heading>
        </TextContent>
        <RightButtonContent>
          <div className={classes.rightButtonContent}>
            <ToggleIconButton
              id={'select-report'}
              targetId={'select-report'}
              onClick={toggleOpen}
              active={isOpen}
            />
          </div>
        </RightButtonContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <div className={classes.bodyContainer}>
          <Dropdown
            options={formOptions}
            value={selectedReport ? selectedReport.id : null}
            onChange={(e) => { selectReport(e.target.value) }}
            givenClasses={{ overridingRootClasses: classes.dropdownRoot }}
          />
        </div>
      </ContentBoxBody>
    </ContentBox>
  </div>
  )
}

export default SelectReportDropdown