import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'

export const getActiveArticleId = state => state.newsArticles.activeArticleId
export const getArticles = state => state.newsArticles.entries
export const getStatuses = state => state.newsArticles.statuses
export const getErrors = state => state.newsArticles.errors

export const getActiveArticle = createSelector([
  getActiveArticleId,
  getArticles
], (activeId, articles) => {
  return articles[activeId]
})

export const getActiveArticleStatus = createSelector([
  getActiveArticleId,
  getStatuses
], (activeId, statuses) => {
  return statuses[activeId]
})

export const getActiveArticleError = createSelector([
  getActiveArticleId,
  getErrors
], (activeId, errors) => {
  return errors[activeId]
})

export const getIsActiveArticleLoading = createSelector([
  getActiveArticle,
  getActiveArticleStatus
], (article, status) => {
  return !article && status === PENDING
})

export const getArticleById = (id) => createSelector([
  getArticles
], (articles) => {
  return articles[id]
})

export const getArticleStatusById = (id) => createSelector([
  getStatuses
], (statuses) => {
  return statuses[id]
})

export const getArticleErrorById = (id) => createSelector([
  getErrors
], (errors) => {
  return errors[id]
})
