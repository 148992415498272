export const RESET_STORE = 'digitalStore/reportDetails/RESET_STORE'
export const SUBMIT_REPORT = 'digitalStore/reportDetails/SUBMIT_REPORT'
export const SUBMIT_REPORT_DRAFT = 'digitalStore/reportDetails/SUBMIT_REPORT_DRAFT'
export const NEW_FORM_NAME = 'newForm'
export const FETCH_REPORT = 'digitalStore/reportDetails/FETCH_REPORT'
export const UPDATE_REPORT_DRAFT = 'digitalStore/reportDetails/UPDATE_REPORT_DRAFT'
export const LOAD_EXISTING_REPORT = 'digitalStore/reportDetails/LOAD_EXISTING_REPORT'
export const REGENERATE_REPORT_BY_ID = 'digitalStore/reports/REGENERATE_REPORT_BY_ID'
export const VALIDATE_REPORT = 'digitalStore/reportDetails/VALIDATE_REPORT'
export const UPDATE_REPORT_XML = 'digitalStore/reportDetails/UPDATE_REPORT_XML'
export const RESET_VALIDATION = 'digitalStore/reportDetails/RESET_VALIDATION'
export const RESET_UPDATE_XML = 'digitalStore/reportDetails/RESET_UPDATE_XML'

export const FETCH_REPORT_FOLLOWUP_NOTIFICATION = 'digitalStore/reportDetails/FETCH_REPORT_FOLLOWUP_NOTIFICATION'
export const RESET_REPORT_FOLLOWUP_NOTIFICATION = 'digitalStore/reportDetails/RESET_REPORT_FOLLOWUP_NOTIFICATION'

export const REASSIGN_REPORT_TO_ORGANISATION = 'digitalStore/reportDetails/REASSIGN_REPORT_TO_ORGANISATION'
export const RESET_REPORT_REASSIGNMENT = 'digitalStore/reportDetails/RESET_REPORT_REASSIGNMENT'
export const FETCH_ANONYMISED_REPORT = 'digitalStore/reportDetails/FETCH_ANONYMISED_REPORT'