import { createAsyncThunk } from '@reduxjs/toolkit'
import { createStatefulThunk } from '../toolkit-utils'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as platformSelectors } from '../platforms'
import { SLICE_NAME, PUBLISH_STATES } from './constants'
import { GenericSelectors } from '../toolkit-utils/selectors'

const genericSelector = new GenericSelectors(SLICE_NAME)

export const getMaster = createStatefulThunk({
  typePrefix: 'announcementManagement/getMaster',
  path: 'master',
  options: {
    isPaginated: true
  },
  asyncFunction: async (_args, thunkApi) => {
    const state = thunkApi.getState()
    const page = genericSelector.getMasterPageNumber(state)

    return await digitalStoreSdk
      .announcementManagement
      .fetchAnnouncementsForManagement({ query: { page, limit: 20 } })
  }
})

export const getDetail = createAsyncThunk(
  'announcementManagement/getDetail',
  async ({ id }, thunkApi) => {
    try {
      const data = await digitalStoreSdk
        .announcementManagement
        .fetchAnnouncementForManagementById({ id })
      return thunkApi.fulfillWithValue({ data }, { path: ['detail', id] })
    } catch (error) {
      console.log({ error })
      return thunkApi.rejectWithValue({ error }, { path: ['detail', id] })
    }
  },
  {
    getPendingMeta: ({ arg }) => {
      return { path: ['detail', arg.id] }
    }
  }
)

export const saveDetail = createStatefulThunk({
  typePrefix: 'announcementManagement/saveDetail',
  path: 'detail',
  asyncFunction: async (args, thunkApi) => {
    const state = thunkApi.getState()
    const platformId = platformSelectors.getCurrentPlatformId(state)
    const payload = {
      id: args.id,
      publishType: args.isPublished === false ? PUBLISH_STATES.UNPUBLISHED : PUBLISH_STATES.PUBLISHED,
      publishFromDate: args.publishFromDate,
      publishToDate: args.publishToDate,
      announcementPlatforms: [
        {
          platformId
        }
      ],
      announcementLanguages: [
        {
          languageId: '14403116-1937-494c-830b-a1e1bb0fc004',
          title: args.title,
          body: args.body
        }
      ],
      assignedPlatforms: args.assignedPlatforms,
      unassignedPlatforms: args.unassignedPlatforms
    }
    return args.id
      ? await digitalStoreSdk
        .announcementManagement
        .updateAnnouncement({ payload })
      : await digitalStoreSdk
        .announcementManagement
        .createAnnouncement({ payload })
  }
})

export const deleteDetail = createStatefulThunk({
  typePrefix: 'announcementManagement/deleteDetail',
  path: 'detail',
  asyncFunction: async args => {
    try {
      return await digitalStoreSdk
        .announcementManagement
        .deleteAnnouncement({ payload: { id: args.id } })
    } catch (error) {
      console.log(error)
      throw error
    }
  }
})
