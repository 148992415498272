import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import { SUCCESS } from '../../middleware/redux-promise'
import * as constants from './constants'
import actions from './actions'

class FilesMiddleware {
  fetchFilesMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const isRoutingToPharmaceuticalReferrals = matchPath(
        path,
        { path: '/major-safety-review-upload', exact: true }
      )
      if (isRoutingToPharmaceuticalReferrals) {
        dispatch(actions.fetchFiles({}))
      }
    }
    if (
      (
        action.type === constants.CREATE_FILE ||
        action.type === constants.UPDATE_FILE ||
        action.type === constants.DELETE_FILE
      ) &&
      action.status === SUCCESS
    ) {
      dispatch(actions.fetchFiles({}))
    }
  }
}

export default new FilesMiddleware()
