import React from 'react'
import PropTypes from 'prop-types'

import BasicModalOverlay from '../BasicModalOverlay'

import { translations } from '../../config'

const ConfirmationModal = ({
  onConfirmation,
  dismiss,
  title,
  message
}) => {
  return (
    <BasicModalOverlay
      title={title}
      text={message}
      actions={[
        {
          text: translations('Cancel'),
          onClick: dismiss
        },
        {
          text: translations('Confirm'),
          primary: true,
          onClick: () => {
            onConfirmation()
            dismiss()
          }
        }
      ]}
    />
  )
}

ConfirmationModal.propTypes = {
  onConfirmation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export default ConfirmationModal
