"use strict";

var _computedHidden = require("../../../../../../formHelpers/computedHidden");

var _computedValue = require("../../../../../../formHelpers/computedValue");

var _options = require("../../../../../../formHelpers/options");

var _formHelpers = require("./formHelpers");

var schema = function schema(validators) {
  return [{
    id: 'reactionduetomedicationerror',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      name: 'reactionduetomedicationerror',
      label: 'Do you think this reaction occurred as a result of a mistake made in the prescription, dosing, dispensing or administration of the medication?',
      options: (0, _options.mapValuesAt1)(['Yes', 'No'])
    }
  }, {
    id: 'medicationerrormoreinformation',
    field: 'Input',
    computedHidden: (0, _computedHidden.visibleIf)(_formHelpers.general.isReactionDueToMedicationError),
    computedValue: (0, _computedValue.clearIfNot)(_formHelpers.general.isReactionDueToMedicationError),
    props: {
      shrink: true,
      required: false,
      name: 'medicationerrormoreinformation',
      label: 'Please provide further details of the medication error. Please do not add any patient identifiable information to the free text.',
      validate: [validators.maxLength(1000, 'Field')],
      multiline: true,
      rows: 5
    }
  }];
};

module.exports = schema;