import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import Button from '../../../components/Button'
import { useSelector } from 'react-redux'
import { selectors as exportManagementSelectors, actions as exportManagementActions } from '../../../store/modules/exportManagement'
import { actions as analyticsActions } from '../../../store/modules/platformAnalytics'

import { useDispatch } from 'react-redux'
import linkService from '../../../services/linkService'
import toastService from '../../../services/toastService'
import { SUCCESS, FAIL } from '../../../store/middleware/redux-promise'
import { STATUS_TYPES } from '../ExportManagementScreen/ExportManagementScreen/ExportManagementScreen'
import _ from 'lodash'

const DownloadExportButton = ({ id, recordStatus }) => {
  const dispatch = useDispatch()

  const isDeleted = recordStatus === STATUS_TYPES.EXPIRED
  const isDisabled = recordStatus !== STATUS_TYPES.SUCCESS

  const downloadExport = async () => {
    try {
      const res = await dispatch(exportManagementActions.fetchExportFile({ id }))
      if (res && res.link) {
        linkService.open({ link: res.link })
      }
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations("Export Management - Fetch Failure")
      })
    } finally {
      dispatch(exportManagementActions.clearLink())
    }
  }

  const reExport = async () => {
    try {
      await dispatch(exportManagementActions.regenerateExport({ id }))
      toastService.action({
        type: 'success',
        message: translations('Analytics Export - Processing Message'),
        autoHideDuration: 6000
      })
    } catch (error) {
      toastService.action({
        type: 'error',
        message: translations('Analytics Export - Failed Message'),
        autoHideDuration: 6000
      })
    }
  }

  if (isDeleted) {
    return (
      <Button
        buttonType='primary'
        onClick={reExport}
      >
        {translations('Export Management Button - Re-Export')}
      </Button>
    )
  }

  return (
    <Button
      buttonType='primary'
      disabled={isDisabled}
      onClick={downloadExport}
    >
      {translations('Export Management Button - Download')}
    </Button>
  )
}

export default DownloadExportButton