import _ from 'lodash'
import { change, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import RepeatableForm from './RepeatableForm'
import { utilities } from '@redant/mhra-form-schema-library'

import Form from '../Form'
import * as validators from '../../validators'
import { authCombinedSelectors } from '../../store/modules/combinedSelectors'
import { selectors as formViewSelectors } from '../../store/modules/formViews'

let previousFormValues = {}

const connectedComponent = compose(
  connect((state, ownProps) => {
    const { formId = 'repeatableform' } = ownProps
    return {
      formValues: getFormValues(formId)(state),
      organisationDetails: authCombinedSelectors.getDetailsForSelectedOrganisation(state),
      formViewDetails: formViewSelectors.getCurrentFormViewDetails(state)
    }
  }),
  withHandlers({
    onSubmit: ({ onComplete }) => (values) => {
      onComplete({ values })
    },
    closeModal: ({ onClose }) => () => {
      onClose()
    },
    changeField: ({ dispatch }) => (...values) => dispatch(change(...values))
  }),
  withPropsOnChange(['formValues', 'organisationDetails', 'parentFormValues', 'formViewDetails', 'repeatableValidate'], ({ schema, formValues, changeField, parentFormValues, formId = 'repeatableform', organisationDetails, formViewDetails, repeatableValidate, ...props }) => {
    if (!formValues) {
      return
    }

    const parser = utilities.parseDynamicSchema(formValues, previousFormValues, changeField, formId, null, parentFormValues, organisationDetails, formViewDetails)
    previousFormValues = formValues
    const parsedSchema = parser(schema)

    const validate = (formValues) => {
      let errors = validators.requiredRepeatables(parsedSchema)(formValues)

      // Custom sync validator for a repeatable form
      if (_.isFunction(repeatableValidate)) {
        const formErrors = repeatableValidate(formValues)
        if (_.isObject(formErrors)) {
          errors = _.assign(errors, formErrors)
        }
      }

      return errors
    }

    const checkDisabled = () => {
      const valuesWithoutParentValues = _.omit(formValues, ['_parentFormValues'])
      return _.isEmpty(valuesWithoutParentValues) || _.every(valuesWithoutParentValues, _.isEmpty)
    }

    const isDisabled = checkDisabled()

    return {
      isDisabled,
      schema: parsedSchema,
      validate
    }
  }),
  withPropsOnChange(['formId'], ({ formId = 'repeatableform' }) => {
    return {
      Form: Form(formId)
    }
  })
)(RepeatableForm)

export default connectedComponent
