import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import TerminologyScreen from './TerminologyScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

const routes = [
  <PrivateRoute key='/terminology' path='/terminology' exact component={TimedLogoutComponent(TerminologyScreen)} />
]

export default routes
