"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleDestructive = exports.handleAdditive = exports.action = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var _utilities = require("./utilities");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var action = {
  ADDITIVE: "ADDITIVE",
  DESTRUCTIVE: "DESTRUCTIVE",
  NO_OPERATION: "NO_OPERATION"
};
exports.action = action;

var handleAdditive = function handleAdditive(_ref) {
  var type = _ref.type,
      targetValueFromReduxStore = _ref.targetValueFromReduxStore,
      formValues = _ref.formValues;
  return function (value, field) {
    if (type === action.ADDITIVE) {
      if ((0, _utilities.alreadyExists)(value, targetValueFromReduxStore)) {
        return targetValueFromReduxStore;
      } else if (_fp["default"].isArray(targetValueFromReduxStore)) {
        var cleanValues = removeRepeatables(formValues, targetValueFromReduxStore, field);
        return _fp["default"].concat(cleanValues, value);
      }

      return value;
    }
  };
};

exports.handleAdditive = handleAdditive;

var handleDestructive = function handleDestructive(_ref2) {
  var type = _ref2.type,
      targetValueFromReduxStore = _ref2.targetValueFromReduxStore,
      formValues = _ref2.formValues;
  return function (value, field) {
    if (type === action.DESTRUCTIVE) {
      return removeRepeatables(formValues, targetValueFromReduxStore, field);
    }

    return value;
  };
};

exports.handleDestructive = handleDestructive;

var removeRepeatables = function removeRepeatables(formValues, targetValueFromReduxStore, field) {
  var repeatablesId = _fp["default"].compose(_fp["default"].map('id'), _fp["default"].get(['computedRepeatables', field, 'repeatables']))(formValues);

  if (_fp["default"].isEmpty(repeatablesId)) {
    return targetValueFromReduxStore;
  }

  var noRepeatables = _fp["default"].reject(_fp["default"].compose(_fp["default"].includes(repeatablesId), _fp["default"].get('id')))(targetValueFromReduxStore);

  return noRepeatables;
};