import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'

import modalService from '../../../../services/modalService'
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal'
import DataTable from '../../../../components/DataTable/DataTable'
import { DataTableCell, DataTableRow } from '../../../../components/DataTable'
import { getCurrentPlatformId } from '../../../../store/modules/platformDetails/selectors'
import { deletePlatformApp } from '../../../../store/modules/pwa/asyncActions'
import { translations } from '../../../../config'

const PlatformAppsTable = ({
  platformApps,
  setSelectedApp
}) => {
  const dispatch = useDispatch()
  const platformId = useSelector(getCurrentPlatformId)

  const handleEdit = (id) => setSelectedApp(id)

  const handleDelete = useCallback((id) => {
    modalService.open({
      component: ConfirmationModal,
      title: translations('Platform Management - Delete Platform App Header'),
      message: translations('Platform Management - Delete Platform App Message'),
      onConfirmation: () => dispatch(deletePlatformApp({ id, platformId }))
    })
  })

  return (
    <DataTable
      data={platformApps}
      tableId={'platform-apps-table'}
      tableHeading={translations('Platform Apps - Table Header')}
      rowComponent={PlatformAppRow}
      rowProps={{ handleEdit, handleDelete }}
      headers={[
        { label: 'Name', id: 'name', type: 'label', width: '80%' },
        { label: '', id: 'actions', type: 'label', width: '20%' },
      ]}
    />
  )
}

const PlatformAppRow = ({
  entry,
  handleEdit,
  handleDelete
}) => {
  return (
    <DataTableRow>
      <DataTableCell>{entry.name}</DataTableCell>
      <DataTableCell align='right'>
        <IconButton
          aria-label={translations('Edit')}
          onClick={() => handleEdit(entry.id)}
        >
          <Edit />
        </IconButton>
        <IconButton
          aria-label={translations('Delete')}
          onClick={() => handleDelete(entry.id)}
        >
          <Delete />
        </IconButton>
      </DataTableCell>
    </DataTableRow >
  )
}

export default PlatformAppsTable