import React, { useState, useEffect } from 'react'
import { styled, withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import moment from 'moment'
import Dropdown from '../../../../components/Fields/Dropdown'
import style from './style'
import { useDispatch, useSelector } from 'react-redux'
import { selectors as analyticsSelectors } from '../../../../store/modules/platformAnalytics'
import { actions as platformActions } from '../../../../store/modules/platforms'
import { actions as platformAnalyticsActions } from '../../../../store/modules/platformAnalytics'
import { selectors as authSelectors } from '../../../../store/modules/auth'
 import { authCombinedSelectors } from '../../../../store/modules/combinedSelectors'
import { replace } from 'connected-react-router'
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs'
import { FormControl } from '@material-ui/core'
import { translations, dateFormat, timeFormat } from '../../../../config'
import DatePicker from '../../../../components/Fields/DatePicker'
import { getCurrentPlatformId } from '../../../../store/modules/platforms/selectors'
import { DrugsAsyncLookup } from '../../../../components/Fields/asyncLookup'

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const reportTypeOptions = [
  { label: translations('Analytics - Report Filter - All Reports'), value: 'AllReports' },
  { label: translations('Analytics - Report Filter - Initial Report Only'), value: 'InitialReportOnly' },
  { label: translations('Analytics - Report Filter - Latest Version of Report'), value: 'LatestVersionOfReport' }
]

const dateRangeOptions = [
  {
    label: translations('Analytics - Date Filter - Last 24 Hours'),
    value: 'today'
  },
  {
    label: translations('Analytics - Date Filter - Last 7 Days'),
    value: 'thisWeek'
  },
  {
    label: translations('Analytics - Date Filter - Last 30 Days'),
    value: 'thisMonth'
  },
  {
    label: translations('Analytics - Date Filter - Past Year'),
    value: 'pastYear'
  },
  {
    label: translations('Analytics - Date Filter - Custom'),
    value: 'custom'
  }
]

const formatDate = (dateTime) => {
  if (dateTime) {
    return moment(dateTime).format('MM/DD/YYYY')
  } else {
    return moment().format('MM/DD/YYYY')
  }
}

const AnalyticsFilters = props => {
  const {
    classes,
  } = props

  const dispatch = useDispatch()
  const location = useLocation()
  const query = _.get(location, 'search')
  const queries = qs.parse(query, { ignoreQueryPrefix: true })

  const selectedOrganisationId = useSelector(authSelectors.getUserSelectedOrganisationId)
  const canFilterReportTypeAnalytics = useSelector(authCombinedSelectors.canFilterReportTypeAnalytics)
  const loggedInUserOrganisationsOptions = useSelector(authSelectors.getUserOrganisationsOptions)

  const organisationId = useSelector(analyticsSelectors.getSelectedOrganisationId) || selectedOrganisationId
  const { timePeriod = 'today', dateFrom, dateTo, organisationIds = [organisationId], reportType = "AllReports", sourceId = 'AllSources', productName = null } = queries
  const orgIds = typeof organisationIds === 'string' ? [organisationIds] : organisationIds
  const [pickerDateFrom, setDateFrom] = useState(null)
  const [pickerDateTo, setDateTo] = useState(null)

  const platformId = useSelector(getCurrentPlatformId)

  const selectTimePeriod = (e) => {
    const timePeriod = e.target.value
    const {startDate, endDate} = platformAnalyticsActions.getTimePeriodDate(timePeriod)

    if (timePeriod === 'custom') {
      dispatch(replace({ search: qs.stringify({ ...queries, timePeriod, dateFrom: pickerDateFrom, dateTo: pickerDateTo }, { arrayFormat: 'repeat' }) }))
    } else {
      dispatch(replace({ search: qs.stringify({ ..._.omit(queries, ['dateFrom', 'dateTo']), timePeriod  }, { arrayFormat: 'repeat' }) }))
      setDateFrom(formatDate(startDate))
      setDateTo(formatDate(endDate))
    }
  }

  const sourceOptions = useSelector(analyticsSelectors.getSourcesForSelectedOrganisationAsOptionsIncludedAll)

  const { widgetType } = useParams()
  const [selectedProduct, setProduct] = useState(null)

  useEffect(() => {
    if (productName) {
      setProduct(productName)
    }
    if (platformId) {
      setOrganisationIds(orgIds)
      dispatch(platformActions.fetchPlatformOrOrganisationsSources({ platformId, organisationIds: orgIds }))
    }

    if (timePeriod) {
      const {startDate, endDate} = platformAnalyticsActions.getTimePeriodDate(timePeriod, dateFrom, dateTo)
      setDateFrom(formatDate(startDate))
      setDateTo(formatDate(endDate))
    }
  }, [])

  const selectDateFrom = (e) => {
    const dateFrom = e
    setDateFrom(formatDate(dateFrom))
    dispatch(replace({ search: qs.stringify({ ...queries, timePeriod: 'custom', dateFrom, dateTo: pickerDateTo }, { arrayFormat: 'repeat' }) }))
  }

  const selectDateTo = (e) => {
    const dateTo = e
    setDateTo(formatDate(dateTo))
    dispatch(replace({ search: qs.stringify({ ...queries, timePeriod: 'custom', dateFrom: pickerDateFrom, dateTo  }, { arrayFormat: 'repeat' }) }))
  }

  const onChangeOrganisation = event => {
    const ids = event.target.value
    setOrganisationIds(ids)
    dispatch(platformActions.fetchPlatformOrOrganisationsSources({ platformId, organisationIds: ids }))
  }

  const onChangeSource = event => {
    const sourceId = event.target.value
    dispatch(replace({ search: qs.stringify({ ...queries, sourceId }) }))
  }

  function onChangeProduct(option) {
    const productName = _.get(option, 'label', '')
    const newQueries = productName ? {...queries, productName} :  _.omit(queries, ['productName'])
    setProduct(productName)
    dispatch(replace({ search: qs.stringify( newQueries ) }))
  }

  const setOrganisationIds = (orgIds) => {
    const organisationIds = typeof orgIds === 'string' ? [orgIds] : orgIds
    dispatch(replace({ search: qs.stringify({ ...queries, organisationIds }, { arrayFormat: 'repeat' }) }))
  }

  const onChangeReportType = event => {
    const reportType = event.target.value
    dispatch(replace({ search: qs.stringify({ ...queries, reportType }, { arrayFormat: 'repeat' }) }))
  }

  const organisationComponent =
    _.size(loggedInUserOrganisationsOptions) > 1 ?
      (
        <FormControl className={classes.dropdown}>
          <Dropdown
            label={'Organisation'}
            value={orgIds}
            options={loggedInUserOrganisationsOptions}
            onChange={onChangeOrganisation}
            passedProps={{ multiple: true }}
            multiple={true}
            noErrorTextLabel
            class={classes.organisation}
          />
        </FormControl>
      ) : null

  const dropdownProps = []

  if (widgetType !== 'user' && canFilterReportTypeAnalytics) {
    dropdownProps.push({
      options: reportTypeOptions,
      value: reportType,
      onChange: onChangeReportType,
      name: 'select-reportType',
      label: 'Report Type'
    })
  }

  if (widgetType !== 'user' && !_.isEmpty(sourceOptions)) {
    dropdownProps.push({
      options: sourceOptions,
      value: sourceId,
      onChange: onChangeSource,
      name: 'select-source',
      label: 'Report Source'
    })
  }

  return (
    <div class={classes.centerContent}>
      <div className={classes.filters}>
        {organisationComponent}
        {
          dropdownProps.map((someProps, i) =>
              <FormControl className={classes.dropdown}>
                <Dropdown
                  key={someProps.name}
                  {...someProps}
                  givenClasses={classes}
                  noNullOption
                />
              </FormControl>
          )
        }
        {widgetType !== 'user' &&
          <div className={classes.dropdown}>
            <DrugsAsyncLookup
              placeholder={translations('Analytics - Product Filter - All Products')}
              value={selectedProduct}
              onChange={onChangeProduct}
              noErrorTextLabel
              organisationId={organisationId}
              label={'Product'}
            />
          </div>
        }
        <FormControl className={classes.dropdown}>
          <Dropdown
            key='select-timePeriod'
            value={timePeriod}
            onChange={selectTimePeriod}
            label={'Date Range'}
            options={dateRangeOptions}
            noNullOption
            noErrorTextLabel
            shrink
            />
        </FormControl>
        <FormControl className={classes.dropdown}>
          <DatePicker
            label={translations('Date From')}
            value={pickerDateFrom}
            onChange={selectDateFrom}
            noErrorTextLabel
            class={classes.datepicker}
            shrink
          />
        </FormControl>
        <FormControl className={classes.dropdown}>
          <DatePicker
            label={translations('Date To')}
            value={pickerDateTo}
            onChange={selectDateTo}
            noErrorTextLabel
            class={classes.datepicker}
            shrink
          />
        </FormControl>
      </div>
    </div>
  )
}

export default withStyles(style)(AnalyticsFilters)
