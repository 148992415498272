import { createSlice } from '@reduxjs/toolkit'
import { createExtraReducers } from '../toolkit-utils'
import { SLICE_NAME } from './constants'

const initialState = {
  platformApps: {
    data: [],
  },
  platformWebsites: {
    data: []
  },
  selectedApp: {
    data: {}
  }
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetStore() {
      return initialState
    },
    resetSelectedApp(state) {
      state.selectedApp = initialState.selectedApp
    }
  },
  extraReducers: createExtraReducers(SLICE_NAME)
})

export const getPlatformApps = state => state.pwa.platformApps.data
export const getPlatformWebsites = state => state.pwa.platformWebsites.data
export const getSelectedApp = state => state.pwa.selectedApp.data

export const actions = slice.actions
export const store = slice.reducer