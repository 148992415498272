"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSchemaVersion = exports.findObjectByKeyAndValue = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

var getSchemaVersion = function getSchemaVersion(versions) {
  return function (version) {
    var matched = _lodash["default"].find(versions, {
      version: version
    });

    var latest = _lodash["default"].last(versions);

    return {
      schema: !version || !matched ? latest.path.schema : matched.path.schema,
      versions: versions
    };
  };
};

exports.getSchemaVersion = getSchemaVersion;

var findObjectByKeyAndValue = function findObjectByKeyAndValue(object, key, value) {
  if (!object || _typeof(object) !== "object") return null;
  if (key in object && object[key] === value) return object;

  for (var prop in object) {
    if (_typeof(object[prop]) === "object") {
      var foundObject = findObjectByKeyAndValue(object[prop], key, value);
      if (foundObject) return foundObject;
    }
  }

  return null;
};

exports.findObjectByKeyAndValue = findObjectByKeyAndValue;