
const styles = {
  container: {
    display: 'flex'
  },
  dropdown: {
    padding: '15px 25px 0 0'
  },
  inputContainer: {
    width: '50%'
  },
  wrapped: {
    width: '40%',
    display: 'grid',
    marginRight: '2em'
  }
}

export default styles