import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormLabel, FormControlLabel, FormHelperText } from '@material-ui/core'
import { Radio, RadioGroup } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './style'

const Radiolist = ({
  label,
  onChange,
  givenClass,
  options,
  value,
  smallLabel,
  classes,
  meta: { error, touched } = {}
}) => {
  return (
    <Fragment>
      <FormLabel
        key='label'
        classes={{
          focused: classes.floatingLabelFocusStyle,
          root: smallLabel ? classes.smallLabel : null
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup key='group' onChange={onChange} value={value}>
        {options.map((option) => (
          <FormControlLabel
            key={option.label}
            className={option.disabled ? classes.disabled : ''}
            control={<Radio/>}
            {...option}
          />
        ))}
      </RadioGroup>
      <FormHelperText className={classes.errorStyle} key='helper-text' role='alert'>{touched && error && error}</FormHelperText>
    </Fragment>
  )
}

Radiolist.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({}))
}

export default withStyles(styles)(Radiolist)
