import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import _ from 'lodash'

export const getIsLoading = state => _.get(state, 'edqm.status') === PENDING
export const getError = state => _.get(state, 'edqm.error')
export const getIsInitial = state => !state.edqm.status
export const getStatus = state => state.edqm.status
export const getPage = state => state.edqm.page
export const getSize = state => state.edqm.size
export const getTotal = state => _.get(state, 'edqm.count')
export const getDrugs = state => _.get(state, 'edqm.rows')
export const getHasResults = state => _.get(state, 'edqm.rows') && _.get(state, 'edqm.rows').length
export const getDrugsForSearchResults = state => _.map(_.get(state, 'edqm.rows'), (result) => {
  const formattedValue = `${result.name}: (${result.code})`

  return {
    ...result,
    label: formattedValue,
    name: formattedValue
  }
})

export const getHasMore = createSelector([
  getTotal,
  getDrugs
], (total, results) => {
  return total && results.length < total
})
