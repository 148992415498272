import _ from 'lodash'
import React from 'react'
import { FormControlLabel, FormGroup, styled, Switch } from '@material-ui/core'
import { useWebsites } from '../../contexts'
import { translations } from '../../../../config'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  marginLeft: 10
}))

const GeneralTab = (props) => {
  const {
    state,
    updateSettings
  } = useWebsites()

  const handlePublishChange = (e) => {
    updateSettings({ fieldName: 'published', value: !state.settings.published })
  }

  return (
    <Container>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={state.settings.published}
              onChange={handlePublishChange}
              name='publish'
              color='primary'
              size='medium'
            />
          }
          label={translations('Publish')}
        />
      </FormGroup>
    </Container>
  )
}

export default GeneralTab
