import React from "react"
import modalService from '../../../../services/modalService'
import ConfigureArrangementModal from "./ConfigureArrangementModal"
import { useDispatch, useSelector } from 'react-redux'
import fp from 'lodash/fp'
import { selectors, asyncActions } from '../../../../store/modules/terminology'

export const useEntryConfigureModal = ({ editing } = { editing: false }) => React.useCallback(() => {
  modalService.open({
    component: ConfigureArrangementModal,
    fullScreen: false,
    editing
  })
}, [editing])




export const useGetInitialValues = ({ activeArrangement, organisationId, editing }) => {
  if (!editing) {
    return {
      organisationId
    }
  }
  return {
    id: activeArrangement.id,
    name: activeArrangement.name,
    description: activeArrangement.description,
    organisationId: activeArrangement.organisationId,    
    useMeddra: fp.get('action.useMeddraType', activeArrangement) === 'YES',
    addSnomedTerms: fp.any({ relationshipType: 'SECONDARY' }, activeArrangement.arrangementDictionaries)
  }
}
