const styles = theme => ({
  container: {
    width: '100%',
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.up('sm')]: {
      width: '46%'
    }
  },
  smallModal: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: '38%'
    }
  },
  wideModal: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '75%'
    }
  },
  largeModal: {
    width: '100%',
    maxWidth: 1600
  },
  zoomModal: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 750
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 900
    }
  },
  fullScreenContainer: {
    overflow: 'auto'
  },
  noPadding: {
    padding: 0
  },
  overflowContent: {
    overflow: 'visible'
  }
})

export default theme => styles(theme)
