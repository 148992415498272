import React from 'react'
import Editable from '../Editable'
import { Dropdown } from '../../../components/Fields'
import P from '../../P'
import _ from 'lodash'

const getValueFromOptions = ({ value, options, allowNullValue }) => {
  const matchedOption = _.find(_.flatten(options), (opt) => {
    if(allowNullValue) {
      if (_.has(opt, 'value')) return opt.value === value
    } else {
      if (opt && opt.value) return opt.value === value
    }
    return value === opt
  })

  if (matchedOption && matchedOption.label) return matchedOption.label

  return value
}

const mapValuesFromArray = ({ values, options, allowNullValue }) => {
  const mapped = _.map(values, value => {
    return getValueFromOptions({ value, options, allowNullValue })
  })

  return mapped.join(', ')
}

export default Editable({
  input: (props) => (
    <Dropdown {...props} />
  ),
  display: ({ label, value, options, isEmpty, hideIfEmpty, allowNullValue, ...rest }) => {
    if (isEmpty && hideIfEmpty) return null
    const modifiedValue = value instanceof Array ? mapValuesFromArray({values: value, options, allowNullValue}) : getValueFromOptions({ value, options, allowNullValue })
 
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={_.isString(modifiedValue) ? modifiedValue : '-'} />
      </div>
    )
  }
})
