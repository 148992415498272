import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import { translation } from '../../services/translations'

const Text = styled.span`
  display: block;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.secondaryText};
  font-size: ${props => `${props.theme.type.label.small}px`};
  line-height: 1.25;
  ${media.greaterThan('small')`
    font-size: ${props => `${props.theme.type.label.large}px`};
    line-height: 1.4;
  `}
`

/**
 * Provide a helpful hint for an input 
 */
const Hint = (props) => {
  const { message } = props
  return (
    <Text {...props}>{translation(message)}</Text>
  )
}

Hint.propTypes = {
  /** The hint message */
  message: PropTypes.string.isRequired
}

export default Hint
