import { createSelector } from 'reselect'
import _ from 'lodash'
import fp from 'lodash/fp'

const getFormState = (state) => {
    return _.get(state, 'form')
}
/**
 * @description Get registered field names and
 * any non-registered field names where that value need to be retained.
 *
 * ## Unregistered field names
 * ### `causality`
 * This field needs to be kept, as the JSON object is stored along with `causalityAssessment`.
 * This fixes issue MSS-229.
 *
 */
const getFieldNames = ({ registeredFields }) => {
    const keptUnregisteredFields = ['causality']
    return fp.compose(
        fp.concat(keptUnregisteredFields),
        fp.keysIn,
    )(registeredFields)
}

export const getRegisteredFormValues = (formRef, includeInitial = true) => createSelector(
    [getFormState],
    (stateForm) => {
        if (!_.isNull(formRef.current)) {
            const formRefCurrent = _.get(formRef, 'current')
            const values = _.get(formRefCurrent, 'values')
            const form = _.get(formRefCurrent, 'props.form')
            const initialValues = _.get(formRefCurrent, 'props.initialValues')
            const registeredFields = _.get(stateForm, `${form}.registeredFields`)
            const fieldNames = getFieldNames({ registeredFields })
            const registeredValues = _.pick(values, fieldNames)
            return {
                ...(includeInitial && initialValues),
                ...registeredValues
            }
        }
        return {}
    }
)

export const getRegisteredFormValuesByFormName = (formName, includeInitial = true) => createSelector(
    [getFormState],
    (stateForm) => {
        const form = _.get(stateForm, formName)
        const initialValues = _.get(form, 'initial')
        const values = _.get(form, 'values')
        const registeredFields = _.get(form, `registeredFields`)
        const fieldNames = getFieldNames({ registeredFields })
        const registeredValues = _.pick(values, fieldNames)
        return {
            ...(includeInitial && initialValues),
            ...registeredValues
        }
    }
)
