import _ from 'lodash'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Delete } from '@material-ui/icons'

import SaveButton from './SaveButton'
import MoreMenu from '../../../../../components/MoreMenu/MoreMenu'
import { useDispatch } from 'react-redux'
import { translations } from '../../../../../config'
import { useDeleteAnnouncement } from '../AnnouncementsForm/hooks'

const Container = styled.div`
  padding-right: 15px;
`

const RightContent = ({ isEditing }) => {
  const dispatch = useDispatch()
  const { id: announcementId } = useParams()

  const onDelete = useDeleteAnnouncement({
    id: announcementId,
    dispatch
  })

  return (
    <Container>
      <SaveButton announcementId={announcementId} isEditing={isEditing} />
      <MoreMenu id={announcementId} options={[
        {
          label: translations('Announcement Management - Delete Announcement'),
          onClick: onDelete,
          iconComponent: Delete
        }
      ]} />
    </Container>
  )
}

export default RightContent