import React, { Fragment } from 'react'
import _ from 'lodash'

import BarChart from './BarChart'
import Legend from './Legend'

const Chart = props => {
  const { classes } = props
  return <Fragment>
    <BarChart
      {...props}
      classes={_.pick(classes, 'barChartContainer')}
    />
    <Legend
      {...props}
      classes={_.pick(classes, 'legendContainer')}
    />
  </Fragment>
}

export default Chart
