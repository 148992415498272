import _ from 'lodash'
import React, { Fragment, useEffect } from 'react'
import style from './style'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import NotFoundScreen from '../../NotFound/NotFoundScreen'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import BackBar from '../../../components/BackBar'
import FullScreenProgress from '../../../components/FullScreenLoader'
import UserRegistrationForm from './UserRegistrationForm'
import MoreUserRegistrationForm from './MoreUserRegistrationForm'
import SabreUserRegistrationForm from './SabreUserRegistrationForm'

import { translations } from '../../../config'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { asyncActions as organisationInviteAsyncActions } from '../../../store/modules/organisationInvites'

const UserRegistrationScreen = (props) => {
  const {
    classes,
    isPublic,
    platformId,
    isOrganisationInvite,
    isPublicUserRegistrationAllowed,
    isMoreRegistrationEnabled,
    isSabreRegistrationEnabled,
    isLoading,
    getPageTitle
  } = props

  const dispatch = useDispatch()

  const params = useParams()
  const organisationInviteId = _.get(params, 'organisationInviteId')

  useEffect(() => {
    if (organisationInviteId && platformId) {
      dispatch(organisationInviteAsyncActions.fetchOrganisationInvite({
        organisationInviteId,
        platformId
      }))
    }
  }, [organisationInviteId, platformId])

  if (
    isPublic
    && !isPublicUserRegistrationAllowed
    && !isLoading
    && !isMoreRegistrationEnabled
    && !isSabreRegistrationEnabled
    && !isOrganisationInvite
  ) {
    return <NotFoundScreen />
  }

  const pageTitle = getPageTitle(isPublic ? 'Create account' : 'Add a new user')

  const getRegistrationForm = () => {
    if (isMoreRegistrationEnabled && !isOrganisationInvite) {
      return <MoreUserRegistrationForm isPublic={isPublic} />
    }
    if (isSabreRegistrationEnabled) {
      return <SabreUserRegistrationForm 
        isPublic={isPublic} 
        isOrganisationInvite={isOrganisationInvite}
        organisationInviteId={organisationInviteId}
      />
    }
    return <UserRegistrationForm
      isPublic={isPublic}
      isOrganisationInvite={isOrganisationInvite}
      isMoreRegistration={isMoreRegistrationEnabled}
    />
  }
  const registrationForm = getRegistrationForm()

  return <div>
    <SubHeader
      leftContent={isOrganisationInvite ? <></> : <BackBar />}
      centerContent={(
        <Fragment>
          <Heading component='h1' uppercase>
            {pageTitle.title}
          </Heading>
          <Helmet>
            <title>{pageTitle.titleWithName}</title>
          </Helmet>
        </Fragment>
      )}
    />
    <div className={classes.contentContainer}>
      <p className={classes.requiredFieldsText}>{translations('required fields')}</p>
      {registrationForm}
    </div>
  </div>
}

UserRegistrationScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isPublic: PropTypes.bool,
  isPublicUserRegistrationAllowed: PropTypes.bool,
  isLoading: PropTypes.bool,
  getPageTitle: PropTypes.func.isRequired
}

export default FullScreenProgress(
  withStyles(style)(UserRegistrationScreen)
)
