import React from 'react'
import { connect } from 'react-redux'
import {
  compose,
  withHandlers,
  withStateHandlers,
  withPropsOnChange,
} from 'recompose'

import ReportNameModal from './ReportName'
import modalService from '../../../../../services/modalService'

const enhancer = compose(
  withPropsOnChange(['fields'], (props) => {
    const {fields} = props
    return fields
  }),
  withHandlers({
    handleSubmit: ({ submitNewReport, setName }) => async (formData) => {
      try {
        const { newreportname } = formData
        await setName(newreportname)
        submitNewReport()
      } catch (err) {
        console.log(err)
      }
    },
    closeModal: () => () => {
      modalService.close()
    }
  })
)

export default enhancer(ReportNameModal)
