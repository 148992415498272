import React from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import withHeightOffset from '../../components/withHeightOffset'
import style from './style'

const _renderImage = ({ classes, src, mobileSrc, alt }) => {
  return (
    <div>
      {mobileSrc && <img className={classes.footerMobileLogo} src={mobileSrc} alt={alt} />}
      <img className={mobileSrc ? classes.footerDesktopLogo : classes.footerLogo} src={src} alt={alt} />
    </div>
  )
}

const _renderFooterLogo = ({ classes, src, mobileSrc, alt, link }) => {
  if (!link) return _renderImage({ classes, src, mobileSrc, alt })
  return (
    <a className={classes.footerLink} href={link} target={'_blank'}>
      {_renderImage({ classes, src, mobileSrc, alt })}
    </a>
  )
}

const Footer = ({ classes, src, alt, mobileSrc, link, heightOffsetRef }) => {
  if (!src) return null
  return (
    <footer
      className={classes.footer}
      ref={heightOffsetRef}
    >
      {_renderFooterLogo({ classes, src, mobileSrc, alt, link })}
    </footer>
  )
}

export default compose(
  withStyles(style),
  withHeightOffset
)(Footer)
