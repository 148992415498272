import i18next from 'i18next'

import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import digitalStoreSdk from '../../../digitalStoreSdk'

class MedDRAActions {
  _searchMedDRA = (next = false) => ({ query }) => ({
    type: next ? constants.SEARCH_MEDDRA_NEXT : constants.SEARCH_MEDDRA,
    query,
    promise: (dispatch, getState) => {
      const state = getState()
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      const size = selectors.getSize(state)
      const languageCode = i18next.language

      const searchParams = {
        query,
        size,
        organisationId,
        languageCode
      }

      if (next) searchParams.page = selectors.getPage(state) + 1

      return digitalStoreSdk.meddras.searchMeddras(searchParams)
    }
  })

  searchMedDRA = this._searchMedDRA(false)

  searchMedDRANext = this._searchMedDRA(true)

  fetchMeddraVersions = () => ({
    type: constants.FETCH_MEDDRA_VERSIONS,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.meddras.fetchMeddraVersions()
    }
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new MedDRAActions()
