import React, { useCallback, useEffect, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import TabPanel from '@material-ui/lab/TabPanel'
import { styled } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { change } from 'redux-form'

import ReusableTabs from '../../../../../components/ReusableTabs'
import ConfigTab from './ConfigTab'
import InfoTab from './InfoTab'
import { translations } from '../../../../../config'
import { selectors } from '../../../../../store/modules/announcementManagement'
import { announcementsFormName } from '../../constants'

const Content = styled('div')(({ }) => ({}))

const DrawerActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  background: theme.palette.primary.main,
  height: '65px'
}))

const MobileDrawer = styled(Drawer)(({ theme }) => ({}))

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: 0,
  margin: 0
}))

const AnnouncementsSidebar = ({
  announcementId,
  isEditing
}) => {
  const [isPublished, setIsPublished] = useState()
  const [sidebarOpen, setSidebarOpen] = useState(true)

  const dispatch = useDispatch()
  const {
    entity = {}
  } = useSelector(selectors.genericSelectors.getDetailById({ id: announcementId }))

  useEffect(() => {
    setIsPublished(entity.publishType === 'PUBLISHED')
  }, [entity])

  const toggleSidebar = useCallback(() => {
    setSidebarOpen(!sidebarOpen)
  }, [sidebarOpen, setSidebarOpen])

  const handlePublishChange = useCallback(() => {
    const newState = !isPublished
    setIsPublished(newState)
    dispatch(change(announcementsFormName, 'isPublished', newState))
  }, [isPublished, setIsPublished, dispatch])

  return (
    <Content>
      <Hidden mdUp>
        <IconButton
          color='secondary'
          onClick={toggleSidebar}
          aria-label='Open'
        >
          <ChevronLeftIcon />
        </IconButton>
        <MobileDrawer
          open={sidebarOpen}
          onClose={toggleSidebar}
          anchor='right'
          variant='temporary'
        >
          <DrawerActions>
            <IconButton
              color='secondary'
              onClick={toggleSidebar}
              aria-label='Close'
            >
              <CloseIcon />
            </IconButton>
          </DrawerActions>
          <Tabs
            entity={entity}
            isEditing={isEditing}
            isPublished={isPublished}
            handlePublishChange={handlePublishChange}
          />
        </MobileDrawer>
      </Hidden>
      <Hidden smDown>
        <Tabs
          entity={entity}
          isEditing={isEditing}
          isPublished={isPublished}
          handlePublishChange={handlePublishChange}
        />
      </Hidden>
    </Content>
  )
}

const tabs = [
  { label: translations('Config'), value: 'config' },
  { label: translations('Info'), value: 'info' },
]

const Tabs = ({
  entity,
  isEditing,
  isPublished,
  handlePublishChange
}) => {
  return (
    <ReusableTabs
      tabs={tabs}
      shouldUseQuery={false}
      ariaLabel={'Announcement Management - Sidebar Tabs'}
    >
      <StyledTabPanel value='config'>
        <ConfigTab
          entity={entity}
          isEditing={isEditing}
          isPublished={isPublished}
          handlePublishChange={handlePublishChange}
        />
      </StyledTabPanel>
      <StyledTabPanel value='info'>
        <InfoTab
          entity={entity}
          isEditing={isEditing}
        />
      </StyledTabPanel>
    </ReusableTabs>
  )
}

export default AnnouncementsSidebar
