import * as constants from './constants'
import reducers from './reducers'

const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.FETCH_SSO_PROVIDER:
      return reducers.fetchSSOProvider(state, action)
    case constants.UPDATE_SSO_PROVIDER:
      return reducers.updateSSOProvider(state, action)
    case constants.CREATE_SSO_PROVIDER:
      return reducers.createSSOProvider(state, action)
    case constants.DELETE_SSO_PROVIDER:
      return reducers.deleteSSOProvider(state, action)
    default:
      return state
  }
}
