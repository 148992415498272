import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ApplicationAccessPanel from '../ApplicationAccessPanel'
import MultipleApplicationAccessPanel from '../ApplicationAccessPanel/MultipleApplicationAccessPanel'
import SubmissionServiceUsersPanel from '../SubmissionServiceUsersPanel'
import { translations } from '../../../../config'
import { getHasSubmissionServiceUsersEnabled, getHasMultipleApplicationsEnabled } from '../../../../store/modules/platforms/selectors'
import { useSelector, useDispatch } from 'react-redux'
import {
  actions as developerActions,
  hooks as developerHooks
} from '../../../../store/modules/developer'
import { hooks as authHooks } from '../../../../store/modules/auth'

const Panel = ({ value, index, children }) => {
  if (value === index) {
    return (
      children
    )
  }
  return (<></>)
}

const TabPanel = (props) => {

  const [tabIndex, setTabIndex] = useState(0)

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex)
    switch (newIndex) {
      case 0:
        if (hasMultipleApplicationsEnabled) {
          dispatch(developerActions.fetchApplications({ organisationId }))
        } else {
          dispatch(developerActions.getDefaultApplication({ organisationId }))
        }
        break
      case 1:
        dispatch(developerActions.fetchApplications({ organisationId, submissionServiceUsers: true }))
        break
    }
  }

  const dispatch = useDispatch()
  const hasSubmissionServiceUsersEnabled = useSelector(getHasSubmissionServiceUsersEnabled)
  const hasMultipleApplicationsEnabled = useSelector(getHasMultipleApplicationsEnabled)
  const apiLabel = hasMultipleApplicationsEnabled ? 'Applications' : 'API Access Keys'

  const organisationId = authHooks.useOrganisation()
  const defaultApplication = developerHooks.useDefaultApplication()
  const application = _.get(defaultApplication, 'application')
  const isLoading = _.get(defaultApplication, 'status') === 'PENDING'

  useEffect(() => {
    if (!hasMultipleApplicationsEnabled && organisationId) {
      dispatch(developerActions.getDefaultApplication({ organisationId }))
    }
    if (hasMultipleApplicationsEnabled && organisationId) {
      dispatch(developerActions.fetchApplications({ organisationId }))
    }
  }, [hasMultipleApplicationsEnabled, organisationId])

  return (
    <>
      <Tabs value={tabIndex} onChange={handleChange}>
        <Tab label={translations(apiLabel)} {...a11yProps(0)} />
        {hasSubmissionServiceUsersEnabled &&
          <Tab label={translations('Submission Service Users')} {...a11yProps(1)} />
        }
      </Tabs>
      <Panel value={tabIndex} index={0}>
        {hasMultipleApplicationsEnabled &&
          <MultipleApplicationAccessPanel />
        }
        {application &&
          <ApplicationAccessPanel
            isLoading={isLoading}
            application={application}
          />
        }
      </Panel>
      {hasSubmissionServiceUsersEnabled &&
        <Panel value={tabIndex} index={1}>
          <SubmissionServiceUsersPanel />
        </Panel>
      }
    </>
  )
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default TabPanel