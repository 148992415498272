const styles = (theme) => {
  return {
    container: {
      paddingTop: 10
    },
    status: {
      marginTop: -10,
      marginBottom: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    statusMessage: {
      paddingRight: 20
    },
    actions: {
      border: `1px solid ${theme.palette.grey[200]}`,
      marginBottom: theme.spacing(4),
      borderRadius: 4
    },
    actionTooltip: {
      marginBottom: -5
    },
    actionButton: {
      color: theme.palette.grey['900']
    },
    section: {
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
      maxHeight: 320,
      overflow: 'auto',
      '& > h3': {
        marginBottom: theme.spacing(2)
      },
      '&:last-child': {
        marginBottom: 0
      }
    },
    list: {
      listStyle: 'none outside',
      margin: 0,
      padding: 0
    },
    listItem: {
      padding: '5px 10px',
      margin: '0 0 5px 0',
      borderLeft: `4px solid ${theme.palette.grey[300]}`,
      '&:last-child': {
        marginBottom: 0
      }
    }
  }
}

export default styles
