import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class RolesReducers {
  fetchAllRoles = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllRolesPending(state, action)
      case SUCCESS:
        return this.fetchAllRolesSuccess(state, action)
      case FAIL:
        return this.fetchAllRolesFail(state, action)
      default:
        return state
    }
  }

  fetchAllRolesPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  fetchAllRolesSuccess = (state, action) => {
    return {
      ...state,
      roles: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  fetchAllRolesFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }

}

export default new RolesReducers()
