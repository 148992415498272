"use strict";

var _formHelpers = require("./formHelpers");

var schema = function schema(validators) {
  return [{
    id: 'reactionexperienced',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: false,
      multiple: true,
      defaultValue: null,
      name: 'reactionexperienced',
      label: 'Please select any reactions experienced from the list',
      options: _formHelpers.general.pahoReactionExperiencedOptions,
      validate: [validators.minimumOf1(['reactionexperienced', 'reaction'], 'One type of reaction is required')]
    }
  }];
};

module.exports = schema;