import { Popper, Tooltip } from "@material-ui/core"
import { InfoRounded } from "@material-ui/icons"
import { styled } from '@material-ui/core/styles'

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '10px'
}))

const Text = styled('p')(({
  margin: 0,
  padding: 0
}))

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  color: theme.palette.grey['400']
}))

const TextWithInfoTooltip = ({
  text,
  message
}) => {
  return (
    <Container>
      <Text>{text}</Text>
      <StyledTooltip
        title={message}
        placement={'right-end'}
      >
        <InfoRounded />
      </StyledTooltip>
    </Container>
  )
}

export default TextWithInfoTooltip