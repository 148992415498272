import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'

import { TableHead, TableCell, TableRow } from '@material-ui/core'
import VisuallyHidden from '../../VisuallyHidden'

import { withStyles } from '@material-ui/core/styles'
import styles from './style'

// eslint-disable-next-line react/prop-types
const renderLabel = ({ visuallyHidden = false, label }) => {
  if (visuallyHidden) {
    return (
      <VisuallyHidden>{label}</VisuallyHidden>
    )
  }
  return label
}

const DataTableHeader = (props) => {
  const {
    classes,
    headers = []
  } = props

  return (
    <TableHead>
      <TableRow className={classes.row}>
        {_.map(headers, (row) => {
          const { id, label, options = {} } = row
          const { visuallyHidden = false, align } = options

          const cellClass = classNames(classes.cell, {
            [classes.center]: align === 'center',
            [classes.right]: align === 'right'
          })

          return (
            <TableCell
              key={id}
              className={cellClass}
            >
              {renderLabel({ visuallyHidden, label })}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

DataTableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired
}

export default withStyles(styles)(DataTableHeader)
