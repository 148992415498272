import React from 'react'
import _ from 'lodash'
import Input from '../../../../components/Fields/Input'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import TitleIcon from '@material-ui/icons/Title'
import ViewIcon from '@material-ui/icons/RemoveRedEye'
import SettingsIcon from '@material-ui/icons/Settings'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import DeleteConfirmModal from '../Modals/DeleteConfirmModal/index'
import RepeatableModal from '../Modals/RepeatableModal/index'
import EditSectionModal from '../Modals/EditSectionModal'
import TooltipDetails from '../TooltipDetails'

import { translations } from '../../../../config'
import style from './style'

const EditableQuestion = (props) => {
  const {
    id,
    sectionId,
    name,
    visible,
    isCustom,
    isHeading,
    classes,
    fields,
    openModal,
    closeModal,
    questionKey,
    sectionKey,
    sectionLength,
    toggleVisibility,
    moveQuestion,
    moveFieldSection,
    configureField,
    deleteQuestion,
    editRepeatables,
    questionNames,
    checkCustom,
    checkHeading,
    displayName,
    mandatory,
    hideFromForm,
    isForAssessment,
    isLimitedToYesNoUnk,
    answerMap,
    disableDefaultMapping,
    lookupOptions,
    dropdownOptions,
    schemaField,
    audienceChanged,
    nestedSections,
    helperText,
    startYear
  } = props
  const requiredProp = _.get(schemaField, 'props.required', false)
  const fieldType = _.get(schemaField, 'field')

  return (
    <div className={classes.formContainer}>
      {isHeading ? <TitleIcon /> : <span style={{ width: '1.8em' }} />}
      {displayName && (
        <TooltipDetails
          label={translations('Report Configuration - Display name')}
          title={displayName}
        />
      )}
      <Input
        value={name}
        style={{ width: '100%', fontWeight: isHeading ? 900 : 400 }}
        className={classes.inputStyle}
        disabled
      />
      <div style={{ display: 'flex' }}>
        {fields && (
          <IconButton
            size='small'
            className={classes.button}
            style={{ color: 'black' }}
            onClick={() => {
              openModal(() => (
                <RepeatableModal
                  fields={fields}
                  editRepeatables={editRepeatables}
                  sectionKey={sectionKey}
                  questionKey={questionKey}
                  questionNames={questionNames}
                  name={name}
                  openModal={openModal}
                  rootCloseModal={closeModal}
                  configureField={configureField}
                  isHeading={isHeading}
                  schemaFields={schemaField}
                  sectionId={sectionId}
                  repeatableId={id}
                  initialValues={{
                    displayName,
                    mandatory: !_.isNil(mandatory) ? mandatory : requiredProp,
                    helperText,
                    isForAssessment
                  }}
                  wideModal
                  audienceChanged={audienceChanged}
                  allNestedSections={[...nestedSections, id]}
                  repeatableNestedSections={[]}
                />
              ))
            }}
          >
            <EditIcon fontSize='inherit' />
          </IconButton>
        )}
        <div
          className={
            isHeading ? classes.editWrapperHeading : classes.editWrapper
          }
        >
          <div style={{ position: 'relative' }}>
            <IconButton
              size='small'
              className={classes.button}
              style={{ color: 'black' }}
              onClick={() => {
                toggleVisibility({ visible, nestedSections: [...nestedSections, id] })
              }}
            >
              <ViewIcon fontSize='inherit' />
              {!visible && <span className={classes.notVisible} />}
            </IconButton>
          </div>
          {sectionLength !== 1 && (
            <div
              className={classes.arrowWrapper}
              style={{ minWidth: fields ? 'unset' : '80px' }}
            >
              {questionKey === 0 ? (
                <div />
              ) : (
                <IconButton
                  size='small'
                  className={classes.button}
                  style={{ color: 'black' }}
                  onClick={() => {
                    moveQuestion({nestedSections, questionKey, direction: 'up'})
                  }}
                >
                  <ArrowUpwardIcon fontSize='inherit' />
                </IconButton>
              )}
              {questionKey !== sectionLength - 1 && (
                <IconButton
                  size='small'
                  className={classes.button}
                  style={{ color: 'black' }}
                  onClick={() => {
                    moveQuestion({nestedSections, questionKey, direction: 'down'})
                  }}
                >
                  <ArrowDownwardIcon fontSize='inherit' />
                </IconButton>
              )}
            </div>
          )}
          <IconButton
            size='small'
            className={classes.button}
            style={{ color: 'black' }}
            onClick={() => {
              moveFieldSection({questionKey, isHeading})
            }}
          >
            <ImportExportIcon fontSize='inherit' />
          </IconButton>
          {!isHeading && (
            <IconButton
              size='small'
              className={classes.button}
              style={{ color: 'black' }}
              onClick={() => {
                openModal(() => (
                  <EditSectionModal
                    title={name}
                    fieldType={fieldType}
                    initialValues={{
                      displayName,
                      mandatory: !_.isNil(mandatory) ? mandatory : requiredProp,
                      hideFromForm,
                      isLimitedToYesNoUnk,
                      answerMap,
                      disableDefaultMapping,
                      isForAssessment,
                      startYear,
                      helperText,
                      dropdownOptions,
                      lookupOptions: {
                        ...lookupOptions
                      }
                    }}
                    isQuestion={true}
                    isCustom={isCustom}
                    onCallback={(values) =>
                      configureField({
                        nestedSections: [...nestedSections, id],
                        details: values
                      })
                    }
                  />
                ))
              }}
              aria-label={
                isHeading
                  ? translations('Report Configuration - Configure Question')
                  : translations('Report Configuration - Configure Question')
              }
            >
              <SettingsIcon fontSize='inherit' />
            </IconButton>
          )}
          {(checkCustom || checkHeading) && (
            <IconButton
              size='small'
              className={classes.button}
              style={{
                color: isCustom || isHeading ? 'black' : '#fff',
                cursor: isCustom || isHeading ? 'pointer' : 'auto',
              }}
              onClick={() =>
                (isCustom || isHeading) &&
                openModal(() => (
                  <DeleteConfirmModal
                    callback={() => deleteQuestion({ sectionKey, questionKey })}
                    translation={'Question'}
                  />
                ))
              }
            >
              <DeleteIcon fontSize='inherit' />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  )
}

EditableQuestion.propTypes = {
  id: PropTypes.string,
  sectionId: PropTypes.string,
  name: PropTypes.string,
  visible: PropTypes.bool,
  isCustom: PropTypes.bool,
  classes: PropTypes.shape({}),
  fields: PropTypes.array,
  openModal: PropTypes.func,
  questionKey: PropTypes.number,
  sectionKey: PropTypes.number,
  sectionLength: PropTypes.number,
  toggleVisibility: PropTypes.func,
  moveQuestion: PropTypes.func,
  moveFieldSection: PropTypes.func,
  deleteQuestion: PropTypes.func,
  editRepeatables: PropTypes.func,
  displayName: PropTypes.string,
  configureField: PropTypes.func,
  helperText: PropTypes.string,
  startYear: PropTypes.number
}

export default withStyles(style)(EditableQuestion)
