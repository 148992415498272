import { connect } from 'react-redux'
import _ from 'lodash'
import { isValid, getFormValues } from 'redux-form'
import { compose, withHandlers } from 'recompose'

import MoreUserRegistrationForm, { formId } from './MoreUserRegistrationForm'
import { actions as userDetailsActions } from '../../../../store/modules/userDetails'
import { selectors as roleSelectors } from '../../../../store/modules/roles'
import { selectors as organisationDetailsSelectors } from '../../../../store/modules/organisationDetails'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'

const mapStateToProps = (state, ownProps) => {
  const isFormValid = isValid(formId)(state)
  const { roleId, organisationIds, telephone } = getFormValues(formId)(state) || {}
  let disabled = false
  const role = roleSelectors.getRoleById(roleId)(state)
  if (role && !role.isAdmin && organisationIds && organisationIds.length === 0) {
    disabled = true
  } else if (role && role.isAdmin) {
    disabled = false
  }
  const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(state)
  const currentPlatformId = platformSelectors.getCurrentPlatformId(state)

  return {
    isFormValid: isFormValid && disabled,
    organisationId,
    telephone,
    currentPlatformId,
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, isPublic, organisationId, currentPlatformId }) => (data) => {
      const modifiedData = _.omit(data, 'confirmPassword')
      modifiedData.platformId = currentPlatformId
      modifiedData.organisationIds = []
      if (isPublic) {
        modifiedData.isPublic = true
        modifiedData.organisationIds = [organisationId]
      }
      if (data.otherUserGroup) {
        modifiedData.accountTypes = [data.otherUserGroup]
      }
      if (data.ukrpAssociation) {
        modifiedData.organisationIds.push(data.ukrpAssociation)
      }
      if (data.mfrAssociation) {
        modifiedData.organisationIds.push(data.mfrAssociation)
      }
      if (data.authRepAssociation) {
        modifiedData.organisationIds.push(data.authRepAssociation)
      }
      modifiedData.organisationIds = _.compact(modifiedData.organisationIds)
      if(isPublic) {
        return dispatch(userDetailsActions.registerUser(modifiedData))
      }
      return dispatch(userDetailsActions.createUser(modifiedData))
    }
  })
)(MoreUserRegistrationForm)
