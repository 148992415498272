import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import { SUCCESS } from '../../middleware/redux-promise'
import { actions as organisationsActions } from '../organisations'
import { selectors as platformSelectors } from '../platforms'
import { constants as authConstants } from '../auth'
import _ from 'lodash'

class OrganisationsMiddleware {
  fetchOrganisationsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const platformId = platformSelectors.getCurrentPlatformId(getState())
    const onLogin = action.type === authConstants.LOGIN && action.status === SUCCESS
    const onSSOLogin = action.type === authConstants.GET_SSO_AUTH_CONTEXT && action.status === SUCCESS
    const isRoutingToViewUser = (
      action.type === LOCATION_CHANGE &&
      matchPath(action.payload.location.pathname, { path: '/users/:id', exact: true })
    )
    const isRoutingToSelectReport = (
      action.type === LOCATION_CHANGE &&
      matchPath(action.payload.location.pathname, { path: '/select-report', exact: true })
    )
    const isRoutingToViewPlatform = (
      action.type === LOCATION_CHANGE &&
      matchPath(action.payload.location.pathname, { path: '/platforms/:id', exact: true })
    )
    const isRoutingToNewPlatform = (
      action.type === LOCATION_CHANGE &&
      matchPath(action.payload.location.pathname, { path: '/platforms/new', exact: true })
    )

    if (onLogin || onSSOLogin || isRoutingToViewUser || isRoutingToNewPlatform || isRoutingToSelectReport) {
      dispatch(organisationsActions.fetchAllOrganisations({ platformId }))
    }
    if (isRoutingToViewPlatform) {
      const platformPath = matchPath(action.payload.location.pathname, { path: '/platforms/:id', exact: true })
      const selectedPlatformId = _.get(platformPath, 'params.id')
      dispatch(organisationsActions.fetchAllOrganisations({ platformId: selectedPlatformId }))
      dispatch(organisationsActions.fetchUnassignedOrganisations())
    }
  }
}

export default new OrganisationsMiddleware()
