import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as tagSelectors } from '../tags'

class NewsFeedActions {
  fetchFeeds = () => ({
    type: constants.FETCH_NEWS_FEEDS,
    promise: digitalStoreSdk.news.fetchNewsFeeds
  })
  fetchDistinctNewsFeedSources = () => ({
    type: constants.FETCH_DISTINCT_NEWS_FEED_SOURCES,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.news.fetchDistinctNewsFeedSources({
        organisationId: (getState().auth.user || {}).selectedOrganisationId
      })
    }
  })
  deleteFeed = (id) => ({
    type: constants.DELETE_NEWS_FEED,
    payload: { id },
    promise: (_, getState) => digitalStoreSdk.news.deleteNewsFeed({
      id,
      organisationId: (getState().auth.user || {}).selectedOrganisationId
    })
  })
  createFeed = ({ languageId, url, source }) => ({
    type: constants.CREATE_NEWS_FEED,
    promise: (_, getState) => digitalStoreSdk.news.createNewsFeed({
      languageId,
      url,
      source,
      organisationId: (getState().auth.user || {}).selectedOrganisationId
    })
  })
  clearErrors = () => ({
    type: constants.CLEAR_NEWS_FEED_ERRORS
  })
}

export default new NewsFeedActions()
