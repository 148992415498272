import { 
  UPDATE_SETTINGS,
  CREATE_WEBSITE,
  UPDATE_WEBSITE,
  IS_SAVING,
  USE_EXISTING_WEBSITE,
  SET_THEME_OPTIONS,
  FETCH_PAGE,
  TOGGLE_OVERLAY,
  UPDATE_PAGE,
  TOGGLE_PAGE_VISIBILITY
} from './constants'

export const updateSettings = (payload) => ({ type: UPDATE_SETTINGS, payload })
export const setIsSaving = (payload) => ({ type: IS_SAVING, payload })
export const useExistingWebsite = (payload) => ({ type: USE_EXISTING_WEBSITE, payload })

export const createWebsite = (payload) => ({ type: CREATE_WEBSITE, payload })
export const updateWebsite = (payload) => ({ type: UPDATE_WEBSITE, payload })

export const fetchPage = (payload) => ({ type: FETCH_PAGE, payload })
export const updatePage = (payload) => ({ type: UPDATE_PAGE, payload })
export const togglePageVisiblity = (payload) => ({ type: TOGGLE_PAGE_VISIBILITY, payload })

export const setThemeOptions = (payload) => ({ type: SET_THEME_OPTIONS, payload })

export const toggleOverlay = (payload) => ({ type: TOGGLE_OVERLAY, payload })