import React from 'react'
import Editable from '../Editable'
import { Radiolist } from '../../../components/Fields'
import P from '../../P'

export default Editable({
  input: (props) => (
    <Radiolist {...props} />
  ),
  display: ({ label, value }) => (
    <div>
      <P value={label} type='textButton' />
      <P value={value} />
    </div>
  )
})
