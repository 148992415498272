const style = theme => ({
  notificationScreen: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      padding: '20px 30px'
    }
  },
  notificationHeaders: {
    lineHeight: '60px',
    background: 'white',
    border: '1px solid #a8a8a8'
  },
  noNotificationsContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 19,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  newNotificationButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default style
