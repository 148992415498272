import React from 'react'
import fp from 'lodash'
import { styled } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Heading from '../../../components/Heading'
import { translations } from '../../../config'
import { Link } from 'react-router-dom'
const Detail = styled(Typography)(() => ({
    padding: '0 0 8px 0'
}))

const ProfileLink = styled(Link)(() => ({
    color: 'inherit',
    textDecoration: 'inherit'
}))

const EmphasisedValueDetail = ({ label, value, link }) => {
    if (value === null) {
        return null
    }
    let text = <strong>{value}</strong>
    if (link) {
        text = (<ProfileLink to={link}>{text}</ProfileLink>)
    }
    return (
        <Detail>
            {translations(label, { interpolation: { escapeValue: false } })}: {text}
        </Detail>
    )
}

export const DetailsText = styled(Typography)(() => ({
    padding: '5px'
}))

const ChangeRequestEntryCardContainer = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '10px 10px 10px 10px',
    margin: '0px 0 8px 0',
    backgroundColor: theme.palette.grey[100],
    fontSize: 14,
    lineHeight: 1,
    borderRadius: '4px'
}))

const ChangeRequestEntryCard = ({ details, message, hide }) => {
    if (hide) {
        return null
    }

    const formattedDetails = fp.map(details, ({ label, value, link }) => {
        return EmphasisedValueDetail({ label, value, link })
    })

    const description = EmphasisedValueDetail({ label: message.subject, value: message.body })
    return <ChangeRequestEntryCardContainer>
        {formattedDetails}
        {description}
    </ChangeRequestEntryCardContainer>
}

export default ChangeRequestEntryCard
