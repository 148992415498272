import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import Table from '../Table'
import TableHeader from '../TableHeader'
import TableRow from '../TableRow'
import TableCell from '../TableCell'

import style from './style'

const _getCellPropsFromDatum = ({ datum, onClick }) => {
  const allProps = _.get(datum, 2)
  const pickedProps = _.pick(allProps, ['xs', 'sm', 'md', 'lg', 'onClick', 'align'])

  if (_.get(allProps, 'useRowOnClick') === true) {
    pickedProps.onClick = onClick
  }

  return pickedProps
}

const AutoTable = props => {
  const { rows, noHeader } = props

  const firstRow = _.get(rows, 0)
  const { data: firstRowData } = firstRow || {}
  const showHeader = noHeader || !firstRowData

  const headerEl = (
    showHeader
    ? null
    : <TableHeader>
      {(firstRowData || []).map(datum => {
        const label = _.get(datum, 0)
        return <TableCell
          heading
          key={label}
          children={label}
          {..._getCellPropsFromDatum({ datum })}
        />
      })}
    </TableHeader>
  )

  const rowEls = rows.map((row, i) => {
    const { data, onClick, key, deactivated } = row || {}
    return <TableRow
      key={key || i}
      onClick={onClick}
      deactivated={deactivated}
    >
      {(data || []).map(datum => {
        const label = _.get(datum, 0)
        const value = _.get(datum, 1)
        return <TableCell
          key={label}
          children={value}
          {..._getCellPropsFromDatum({ datum, onClick })}
        />
      })}
    </TableRow>
  })

  return <Table
    header={headerEl}
    rows={rowEls}
  />
}

AutoTable.defaultProps = {
  rows: [],
  noHeader: false
}

AutoTable.propTypes = {
  rows: PropTypes.array,
  noHeader: PropTypes.bool
}

export default withStyles(style)(AutoTable)
