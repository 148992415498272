import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import { translations } from '../../../../config'
import P from '../../../../components/P'
import BottomBar from '../../../../components/BottomBar'


import ChartsComponent from '../../organisationHealthCheck/OrganisationHealthCheckCharts'
import ChartsContainer from './ChartsContainer'
import style from './style'
import { selectors as healthCheckSelectors } from '../../../../store/modules/platformHealthCheck'
import qs from 'qs'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useLocation } from 'react-router-dom';
import HealthCheckFilters from '../HealthCheckFilters/HealthCheckFilters'

const HealthCheckContent = (props) => {

  const { classes } = props

  const location = useLocation()

  const query = _.get(location, 'search')

  const organisationHealthCheck = useSelector(healthCheckSelectors.getSelectedOrganisationHealthCheck)
  const organisationId = useSelector(healthCheckSelectors.getSelectedOrganisationId)

  const { timePeriod = '24hr', organisationIds = [organisationId], sourceId = 'AllSources' } = qs.parse(query, { ignoreQueryPrefix: true }) || {}

  const lastUpdated = moment(_.get(organisationHealthCheck, 'lastUpdated', moment())).format('hh:mm:ss - DD/MM/YYYY')

  return (
    <>
      <HealthCheckFilters/>
      <div className={classes.container}>
        <ChartsContainer>
          <ChartsComponent
            ids={organisationIds}
            organisationHealthCheck={organisationHealthCheck}
            timePeriod={timePeriod}
            sourceId={sourceId}
          />
        </ChartsContainer>
      </div>
      <BottomBar relative>
        <div className={classes.meta}>
          <P value={`${translations('Last Updated At:')} ${lastUpdated}`} />
        </div>
      </BottomBar>
    </>
  )
}

export default withStyles(style)(HealthCheckContent)
