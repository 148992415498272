"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSchemaDiff = exports.diffAndPatchJson = void 0;

var _deepDiff = _interopRequireDefault(require("deep-diff"));

var _utilities = require("../utilities");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _ = require('lodash');

var logChange = function logChange(_ref) {
  var symbol = _ref.symbol,
      field = _ref.field,
      body = _ref.body;

  if (_utilities.isDevMode) {
    return;
  }

  var message = "".concat(symbol, " ").concat(field.id);

  if (field.fieldId) {
    message += " in field ".concat(field.fieldId);
  }

  if (field.sectionId) {
    message += " in section ".concat(field.sectionId);
  }

  if (body) {
    message += ' ' + body;
  }

  return message;
};

var handleChangeAddition = function handleChangeAddition(document, change) {
  var incomingChange = _.get(change, 'rhs');

  if (_.has(incomingChange, 'id')) {
    logChange({
      symbol: '+',
      field: incomingChange
    });

    _deepDiff["default"].applyChange(document, true, change);
  }
};

var handleChangeRemoval = function handleChangeRemoval(document, change) {
  var documentFragment = _.get(change, 'lhs');

  switch (true) {
    case !_.has(documentFragment, 'id'):
      return;

    case documentFragment.isCustom === true:
      logChange({
        symbol: '-',
        field: documentFragment,
        body: '(no action - custom question)'
      });
      return;

    case documentFragment.isHeading === true:
      logChange({
        symbol: '-',
        field: documentFragment,
        body: '(no action - heading)'
      });
      return;

    case documentFragment.isBlock === true:
      logChange({
        symbol: '-',
        field: documentFragment,
        body: '(no action - block)'
      });
      return;

    default:
      logChange({
        symbol: '-',
        field: documentFragment
      });

      _deepDiff["default"].applyChange(document, true, change);

      return;
  }
};

var createDiffsFromFlattened = function createDiffsFromFlattened(_ref2) {
  var source = _ref2.source,
      remote = _ref2.remote;
  var flattenedSource = (0, _utilities.flattenViewJSON)({
    viewJson: source
  });
  var flattenedRemote = (0, _utilities.flattenViewJSON)({
    viewJson: remote
  });
  return _deepDiff["default"].diff(flattenedSource, flattenedRemote);
};

var diffAndPatchJson = function diffAndPatchJson(_ref3) {
  var source = _ref3.source,
      remote = _ref3.remote;

  var document = _.cloneDeep((0, _utilities.flattenViewJSON)({
    viewJson: source
  }));

  var delta = createDiffsFromFlattened({
    source: source,
    remote: remote
  });

  var _iterator = _createForOfIteratorHelper(delta),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var change = _step.value;

      switch (change.kind) {
        case 'N':
          handleChangeAddition(document, change);
          break;

        case 'D':
          handleChangeRemoval(document, change);
          break;

        default:
          continue;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return (0, _utilities.unflattenViewJSON)(document);
};

exports.diffAndPatchJson = diffAndPatchJson;

var getBlankState = function getBlankState(remote) {
  return {
    counts: {
      added: 0,
      removed: 0
    },
    addedFields: [],
    removedFields: [],
    unifiedViewJSON: remote
  };
};

var getSchemaDiff = function getSchemaDiff(_ref4) {
  var source = _ref4.source,
      remote = _ref4.remote;

  if (_.isEmpty(source)) {
    return getBlankState(remote);
  }

  var delta = createDiffsFromFlattened({
    source: source,
    remote: remote
  });
  var addedFields = [];
  var removedFields = [];

  var _iterator2 = _createForOfIteratorHelper(delta),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var change = _step2.value;

      if (change.kind === 'N' && _.get(change, 'rhs.id')) {
        addedFields.push(change.rhs);
      }

      if (change.kind === 'D' && _.get(change, 'lhs.id')) {
        if (change.lhs.isCustom || change.lhs.isHeading || change.lhs.isBlock) {
          continue;
        }

        removedFields.push(change.lhs);
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  var result = {
    counts: {
      added: _.size(addedFields),
      removed: _.size(removedFields)
    },
    addedFields: addedFields,
    removedFields: removedFields,
    unifiedViewJSON: diffAndPatchJson({
      source: source,
      remote: remote
    })
  };
  return result;
};

exports.getSchemaDiff = getSchemaDiff;