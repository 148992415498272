import { connect } from 'react-redux'
import _ from 'lodash'
import {
  compose,
  withHandlers,
  withProps
} from 'recompose'

import { selectors as formViewSelectors, actions as formViewActions } from '../../../../../store/modules/formViews'

import modalService from '../../../../../services/modalService'
import toastService from '../../../../../services/toastService'

import { translations } from '../../../../../config'
import SchemaUpdatesModal from './SchemaUpdatesModal'

import FormSchemaLibrary from '@redant/mhra-form-schema-library'

const formSchemaLibrary = new FormSchemaLibrary()

const enhancer = compose(
  connect((state) => {
    return {
      schemaName: formViewSelectors.getCurrentFormViewFormSchemaName(state),
      viewJSON: formViewSelectors.getCurrentFormViewVersionJSON(state),
      currentNotEditableFields: formViewSelectors.getNotEditableJSON(state),
      formViewVersionMeta: formViewSelectors.getCurrentFormViewVersionMetaFields(state)
    }
  }),
  withProps((props) => {
    const { schemaName, viewJSON, formViewVersionMeta = {} } = props

    const {
      counts,
      addedFields,
      removedFields,
      unifiedViewJSON
    } = formSchemaLibrary.getSchemaChanges(viewJSON, schemaName)
    /**
     * The hashes and version stored against the Form View in the database.
     */
    const {
      schemaLibraryVersion,
      schemaVersionJsonHash
    } = _.pick(formViewVersionMeta, ['schemaLibraryVersion', 'schemaVersionJsonHash'])

    const addedCount = _.get(counts, 'added', 0)
    const removedCount = _.get(counts, 'removed', 0)
    let hasUpdates = false
    const hasSchemaVersioning = !_.isNil(schemaLibraryVersion) && !_.isNil(schemaVersionJsonHash)
    if (hasSchemaVersioning) {
      const isGreaterVersion = formSchemaLibrary.VERSION.localeCompare(schemaLibraryVersion, undefined, { numeric: true, sensitivity: 'base' }) === 1
      if (isGreaterVersion && schemaVersionJsonHash !== formSchemaLibrary.getDefaultViewJsonHash(schemaName)) {
        hasUpdates = _.add(addedCount, removedCount) > 0
      }
    } else {
      hasUpdates = _.add(addedCount, removedCount) > 0
    }
    return {
      hasUpdates,
      addedCount,
      removedCount,
      addedFields,
      removedFields,
      unifiedViewJSON
    }
  }),
  withHandlers({
    closeModal: () => () => {
      modalService.close()
    },
    onAccept: ({ dispatch, addedFields, currentNotEditableFields, unifiedViewJSON, toggleEdit }) => () => {
      /**
       * Update 'notEditableFields' is required for { editable: false } fields
       * to work correctly with the updated report configuration.
       */
      const getNotEditableFields = () => {
        const notEditableFields = _.filter(addedFields, { field: { editable: false } })
        const mappedNotEditableFields = _.map(notEditableFields, (item) => {
          const { field, sectionId } = item
          const { id, name, visible } = field

          return {
            id,
            name,
            visible,
            editable: false,
            section: _.split(sectionId, '.')
          }
        })

        return [...currentNotEditableFields, ...mappedNotEditableFields]
      }
      dispatch(formViewActions.setNotEditableJSON(getNotEditableFields()))
      dispatch(formViewActions.setFormViewSchemaUpdated())

      /**
       * Setting edit to true will enable the 'Save'.
       * These updates do not automatically save the configuration, this is to
       * allow the user to review the updates.
       */
      dispatch(formViewActions.setViewJSON(unifiedViewJSON))
      toggleEdit(true)

      /**
       * Close and notify the user
       */
      modalService.close()
      toastService.action({
        type: 'success',
        message: translations('Schema Updates Check - Configuration updated'),
        autoHideDuration: 6000
      })
    }
  }),
  withProps((props) => {
    const { closeModal, hasUpdates, editing, onAccept } = props

    const actions = [
      {
        text: translations('Close'),
        onClick: closeModal
      }
    ]

    if (hasUpdates && !editing) {
      actions.push({
        text: translations('Accept Changes'),
        onClick: onAccept,
        primary: true
      })
    }

    return { actions }
  })
)

export default enhancer(SchemaUpdatesModal)
