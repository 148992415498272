import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

const UnsavedChangesDialog = (props) => {

  const chooseCancel = () => {
    handleClose('CANCEL')
  }

  const chooseDiscard = () => {
    handleClose('DISCARD')
  }

  const chooseSave = () => {
    handleClose('SAVE')
  }

  const { open, handleClose, headerText, contentText } = props
  return (
    <Dialog
      open={open}
      onClose={chooseCancel}
    >
      <DialogTitle>
        {headerText}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={chooseCancel}>Cancel</Button>
        <Button onClick={chooseDiscard}>Discard changes</Button>
        <Button onClick={chooseSave}>Save changes</Button>
      </DialogActions>
    </Dialog>
  )
}

export default UnsavedChangesDialog