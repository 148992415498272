import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'
import { isPristine, isSubmitting, isInvalid } from 'redux-form'
import { push } from 'connected-react-router'
import moment from 'moment'
import { PENDING } from '../../../../../store/middleware/redux-promise'
import NewsForm from './NewsForm'
import uploadService from '../../../../../services/uploadService'
import { actions as newsArticleActions, selectors as newsArticleSelectors } from '../../../../../store/modules/newsArticles'
import { actions as newsFeedActions } from '../../../../../store/modules/newsFeeds'
import { actions as languagesActions } from '../../../../../store/modules/languages'
import { actions as tagsActions, selectors as tagsSelectors } from '../../../../../store/modules/tags'
import { newsFeedCombinedSelectors } from '../../../../../store/modules/combinedSelectors'
import modalService from '../../../../../services/modalService'
import { translations } from '../../../../../config'
import _ from 'lodash'
import fp from 'lodash/fp'

export default compose(
  connect((state, ownProps) => {
    const id = ownProps.articleId
    let article = newsArticleSelectors.getArticleById(id)(state)
    if (article) {
      // We need to set the tags in a format the schema can understand - an array of strings rather than custom objects.
      article = fp.set('tags', _.map(article.tags, 'name'), article)
    }
    const tagsOptions = tagsSelectors.getTagNamesAsDropdownOptions(state)
    return {
      isPristine: isPristine('news')(state),
      isSubmitting: isSubmitting('news')(state),
      isInvalid: isInvalid('news')(state),
      initialValues: (
        article ||
        { publishedDate: moment().toISOString() }
      ),
      isLoading: newsArticleSelectors.getArticleStatusById(id)(state) === PENDING,
      error: newsArticleSelectors.getArticleErrorById(id)(state),
      isEditing: !!id,
      languageOptions: newsFeedCombinedSelectors.getFeedLanguageOptionsForSelectedOrg(state),
      tagsOptions
    }
  }),
  withHandlers({
    getNewsArticle: ({ articleId, dispatch }) => () => {
      if (articleId) {
        dispatch(newsArticleActions.setActiveArticle(articleId))
        dispatch(newsArticleActions.getArticle(articleId))
      }
    },
    fetchFeeds: ({ dispatch }) => () => {
      dispatch(newsFeedActions.fetchFeeds())
    },
    fetchLanguages: ({ dispatch }) => () => {
      dispatch(languagesActions.fetchLanguages())
    },
    fetchTags: ({ dispatch }) => () => {
      dispatch(tagsActions.fetchTags())
    }
  }),
  lifecycle({
    componentDidMount () {
      this.props.fetchLanguages()
      this.props.getNewsArticle()
      this.props.fetchFeeds()
      this.props.fetchTags()
    }
  }),
  withHandlers({
    handleSubmit: (props) => async (formData) => {
      const { dispatch, articleId } = props

      formData.documents = await Promise.all(
        (formData.documents || []).map(async document => {
          const { id, title, url } = document

          let documentUrl = ''
          if (url) {
            if (url.toString() === '[object File]') {
              documentUrl = await uploadService.upload(url)
            } else {
              documentUrl = url
            }
          }

          return {
            id,
            title,
            url: documentUrl
          }
        })
      )

      if (articleId) {
        return dispatch(newsArticleActions.updateArticle(articleId, formData))
      } else {
        return dispatch(newsArticleActions.createArticle(formData))
      }
    },
    handleDelete: ({ dispatch, articleId }) => () => {
      modalService.action({
        title: translations('Confirm article delete, this can not be undone.'),
        actions: [{
          text: translations('Cancel')
        }, {
          text: translations('Continue'),
          onClick: () => {
            dispatch(newsArticleActions.deleteArticle(articleId))
              .then(() => dispatch(push('/news')))
          }
        }]
      })
    }
  })
)(NewsForm)
