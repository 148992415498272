import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { styled } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  '&:first-child': {
    marginTop: 0
  }
}))

const Divider = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  '&:before': {
    content: '""',
    display: 'block',
    width: '100%',
    height: 1,
    background: theme.palette.grey[300]
  }
}))

/**
 * List of meta data or flag with an optional tooltip
 */
export const DividerHeading = (props) => {
  const { heading, actions } = props

  return (
    <Container>
      <Grid container alignItems='center'>
        <Grid item>
          <Typography component={'h5'} variant={'body2'}>{heading}</Typography>
        </Grid>
        <Grid item xs>
          <Divider />
        </Grid>
        <Grid item>
          {_.map(actions, (action) => {
            const { onClick, label, iconComponent: IconComponent } = action
            return (
              <IconButton onClick={onClick} size='small' aria-label={label}>
                <IconComponent />
              </IconButton>
            )
          })}
        </Grid>
      </Grid>
    </Container>
  )
}

DividerHeading.defaultProps = {
  actions: []
}

DividerHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    iconComponent: PropTypes.node
  }))
}

export default DividerHeading
