import { createSelector } from 'reselect'
import _ from 'lodash'
import { PENDING } from '../../middleware/redux-promise'

import { FETCH_THEME_DETAILS, FETCH_THEME_DETAILS_FOR_FORM } from './constants'

export const getThemeDetails = state => _.get(state, 'themeDetails.theme')

export const getIsLoading = state => state.themeDetails.status === PENDING && (state.themeDetails.action === FETCH_THEME_DETAILS)
export const getIsFormDetailsLoading = state => state.themeDetails.status === PENDING && (state.themeDetails.action === FETCH_THEME_DETAILS_FOR_FORM)

export const getThemeDetailsForForm = createSelector([
  getThemeDetails
], (theme) => {
  if (theme) {
    return {
      id: theme.id,
      name: theme.name,
      domain: theme.domain,
      ...theme.details
    }
  }
  return {}
})
