import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import P from '../../../../components/P'
import ButtonLink from '../../../../components/ButtonLink'
import { styled } from '@material-ui/styles'
import { DataTable, DataTableRow, DataTableCell } from '../../../../components/DataTable'
import { translations } from '../../../../config'
import { useDispatch } from 'react-redux'
import { actions as developerActions } from '../../../../store/modules/developer'
import modalService from '../../../../services/modalService'
import SubmissionServiceUserModal from './SubmissionServiceUserModal'
import ConfirmationModal from '../ApplicationAccessPanel/ConfirmationModal'
import toastService from '../../../../services/toastService'

const TableContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2)
}))

const BlankState = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey['100'],
  textAlign: 'center'
}))

const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  gap: 10
}))

const EditAction = styled(ButtonLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'underline',
  '&:hover, &:active, &:focus': {
    color: 'black'
  }
}))

const RowAction = styled(ButtonLink)(({ theme }) => ({
  color: theme.palette.error.main,
  textDecoration: 'underline',
  '&:hover, &:active, &:focus': {
    color: theme.palette.error.dark
  }
}))

const SubmissionServiceUsersTable = (props) => {
  const { isLoading, data, rowProps = {} } = props

  if (!isLoading && _.isEmpty(data)) {
    return (
      <BlankState>
        <P role='status' value={translations('No results found')} />
      </BlankState>
    )
  }

  return (
    <>
      <TableContainer>
        <DataTable
          data={data}
          rowComponent={SSURow}
          isLoading={isLoading}
          tableId={'submission-service-users-table'}
          tableHeading={'Submission Service Users'}
          rowProps={rowProps}
          headers={[
            { id: 'description', label: translations('Description') },
            { id: 'username', label: translations('Username'), options: { align: 'left' } },
            { id: 'createdBy', label: translations('Created by'), options: { align: 'right' } },
            { id: 'createdAt', label: translations('Created at'), options: { align: 'right' } },
            { id: 'actions', label: translations('Options'), options: { align: 'right' } }
          ]}
        />
      </TableContainer>
    </>
  )
}

const SSURow = ({ entry }) => {
  const {
    id,
    applications,
    username,
    createdByUser,
    createdAt
  } = entry

  const dispatch = useDispatch()
  const description = _.get(applications, '0.name')
  const applicationId = _.get(applications, '0.id')
  const displayName = _.get(createdByUser, 'displayName', '-')

  const handleRevoke = () => {
    modalService.open({
      component: ConfirmationModal,
      title: translations('Confirmation'),
      message: translations('Developer - Revoke Submission Service message'),
      onConfirmation: async () => {
        try {
          await dispatch(developerActions.deleteApplication({ id: applicationId, submissionServiceUser: true }))
          toastService.action({
            type: 'success',
            message: translations('Developer - Revoke Submission Service User Success'),
            autoHideDuration: 6000
          })
        } catch (error) {
          toastService.action({
            type: 'error',
            message: translations('Developer - Revoke Submission Service User Failure'),
            autoHideDuration: 6000
          })
        }
      }
    })
  }

  const handleUpdate = () => {
    modalService.open({
      component: SubmissionServiceUserModal,
      data: {
        id,
        username,
        description
      },
      applicationId,
      isUpdate: true
    })
  }

  return (
    <DataTableRow>
      <DataTableCell component='th' scope='row' heading>{description}</DataTableCell>
      <DataTableCell align={'left'}>{username || '-'}</DataTableCell>
      <DataTableCell align={'right'}>{displayName}</DataTableCell>
      <DataTableCell align={'right'}>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</DataTableCell>
      <DataTableCell align={'right'}>
        <ActionsContainer>
          <EditAction onClick={handleUpdate}>{'Edit'}</EditAction>
          <RowAction onClick={handleRevoke}>{'Revoke'}</RowAction>
        </ActionsContainer>
      </DataTableCell>
    </DataTableRow>
  )
}

export default SubmissionServiceUsersTable