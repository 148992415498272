import {
  LOAD_FORM_VIEW,
  UPDATE_PUBLIC,
  UPDATE_PUBLISHED,
  UPDATE_SOURCES,
  UPDATE_GENERAL_DETAILS,
  UPDATE_ADVANCED_DETAILS,
  TOGGLE_SECTION,
  SET_UNEDITED,
  UPDATE_TIMEFRAME_DETAILS,
  ADD_REPEATABLE_INSTANCE,
  UPDATE_REPEATABLE_INSTANCE,
  DELETE_REPEATABLE_INSTANCE
} from './constants'

export const loadFormView = (payload) => ({ type: LOAD_FORM_VIEW, payload })
export const togglePublished = (payload) => ({ type: UPDATE_PUBLISHED, payload })
export const togglePublic = (payload) => ({ type: UPDATE_PUBLIC, payload })
export const updateSources = (payload) => ({ type: UPDATE_SOURCES, payload })
export const updateGeneralDetails = (payload) => ({ type: UPDATE_GENERAL_DETAILS, payload })
export const updateAdvancedDetails = (payload) => ({ type: UPDATE_ADVANCED_DETAILS, payload })
export const updateTimeframeDetails = (payload) => ({ type: UPDATE_TIMEFRAME_DETAILS, payload })
export const addRepeatableInstance = (payload) => ({ type: ADD_REPEATABLE_INSTANCE, payload })
export const updateRepeatableInstance = (payload) => ({ type: UPDATE_REPEATABLE_INSTANCE, payload })
export const deleteRepeatableInstance = (payload) => ({ type: DELETE_REPEATABLE_INSTANCE, payload })

export const toggleSection = (payload) => ({ type: TOGGLE_SECTION, payload })
export const setUnedited = () => ({ type: SET_UNEDITED })
