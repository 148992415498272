import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { submit } from 'redux-form'
import { useDispatch } from 'react-redux'

import P from '../../../../../components/P'
import CopyToClipboardButton from '../../../../../components/CopyToClipboardButton'
import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import {
  constants as developerConstants,
  actions as developerActions,
  hooks as developerHooks
} from '../../../../../store/modules/developer'
import { PENDING } from '../../../../../store/middleware/redux-promise'
import * as validators from '../../../../../validators'

import { styled } from '@material-ui/core/styles'
import { translations } from '../../../../../config'

const formName = developerConstants.GENERATE_KEY_FORM
const GenerateForm = Form(formName)

const Message = styled('div')(({ theme }) => ({
  background: theme.palette.grey['100'],
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2)
}))

const GenerateKeyModal = (props) => {
  const { onSubmit, dismiss, organisationId, applicationId } = props
  const [apiKey, setApiKey] = useState()
  const dispatch = useDispatch()
  const { result, status } = developerHooks.useTemporaryApiKey()

  const handleSubmit = async (values) => {
    const { name } = values
    await dispatch(developerActions.createAPIKey({ organisationId, applicationId, name }))
  }

  useEffect(() => {
    const key = _.get(result, 'apiKey')
    if (key) {
      setApiKey(key)
    }
  }, [result])

  const actions = useMemo(() => {
    if (!apiKey) {
      return [
        {
          success: true,
          text: translations('Generate'),
          primary: true,
          onClick: () => {
            dispatch(submit(formName))
          }
        },
        {
          text: translations('Cancel'),
          onClick: dismiss
        }
      ]
    }

    return [
      {
        text: translations('Close'),
        onClick: () => {
          onSubmit()
          dismiss()
        }
      }
    ]
  }, [dispatch, dismiss, apiKey, onSubmit])

  const schema = useMemo(() => {
    return [
      {
        id: 'name',
        field: 'Input',
        props: {
          label: translations('Developer - Key name'),
          name: 'name',
          help: 'Developer Key name - Help',
          validate: [validators.maxLength(250, 'Field')],
          maxLength: 250,
          required: true
        }
      }
    ]
  }, [])

  return (
    <BasicModalOverlay submitting={status === PENDING} actions={actions} title={translations('Developer - Generate API Key')}>
      <GenerateForm onSubmit={handleSubmit}>
        <ContentBoxBody>
          {apiKey ? (
            <>
              <Message>
                <strong>API Key:</strong> {apiKey}
                <CopyToClipboardButton text={apiKey} />
              </Message>
              <P type={'alert'} value={translations('Developer - Close Warning')} />
            </>
          ) : (
            <FormBody
              schema={schema}
              editing
              fullWidthFields
            />
          )}
        </ContentBoxBody>
      </GenerateForm>
    </BasicModalOverlay>
  )
}

GenerateKeyModal.propTypes = {
  /** Modal actions */
  actions: PropTypes.array.isRequired,
  /** Callback when form is submitted */
  onSubmit: PropTypes.func,
  /** Closes the modal */
  dismiss: PropTypes.func,
  /** Title of the modal */
  title: PropTypes.string.isRequired
}

export default GenerateKeyModal
