import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import BottomBar from '../BottomBar'
import Button from '../Button'
import style from './style'

const RegistrationButton = props => (
  <BottomBar>
    <Button
      buttonType='primary'
      to={props.to}
    >
      {props.children}
    </Button>
  </BottomBar>
)

export default withStyles(style)(RegistrationButton)
