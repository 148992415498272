export default theme => ({
  reportTileContainer: {
    width: 360,
    flexShrink: 0,
    margin: `0 ${theme.chartTileGutter / 2}px ${theme.chartTileGutter}px`
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%'
    // }
  },
  reportTile: {
    backgroundColor: 'white',
    boxShadow: '0 2px 6px 0 rgba(0,0,0,0.08)',
    position: 'relative',
    minWidth: 360,
    overflow: 'hidden',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto'
    }
  },
  reportTileInner: {
    paddingTop: '72%'
  },
  reportTileEvenFurtherInner: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
})
