import _ from 'lodash'
import React from 'react'
import { styled, Divider } from '@material-ui/core'

import Actions from './Sections/Actions'
import ScheduleAnnouncementForm from './Sections/ScheduleAnnouncementForm'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: 20,
  justifyContent: 'center'
}))

const ConfigTab = ({
  entity,
  isEditing,
  isPublished,
  handlePublishChange,
}) => {

  if (!isEditing) {
    return <Container />
  }

  return (
    <Container>
      <Actions
        isPublished={isPublished}
        handlePublishChange={handlePublishChange}
      />
      <Divider />
      <ScheduleAnnouncementForm entity={entity} />
    </Container>
  )
}

export default ConfigTab
