import React from 'react'
import PropTypes from 'prop-types'
import BasicModalOverlay from '../../../components/BasicModalOverlay'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import Chip from '../../../components/Chip'
import { translations } from '../../../config'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'
import _ from 'lodash'

const StatusModal = (props) => {

    const {
        classes,
        modalTitle,
        status,
        label,
        message,
        errors,
    } = props

    const hasErrors = _.size(errors) > 0

    return (
        <BasicModalOverlay title={modalTitle} >
            <div className={classes.container}>
                <div className={classes.messageContainer}>
                    {message}
                    <Chip variant={status} label={label} />
                </div>
                {hasErrors && (
                    <div className={classes.section}>
                        <Heading variant="h3">{translations("Post Report - Error Header")}</Heading>
                        <div className={classes.list}>
                            {_.map(errors, (error, index) => {
                                return (<li key={index} className={classes.listItem}>
                                    <P value={_.isString(error.message) ? error.message : JSON.stringify(error.message)}/>
                                </li>)
                            })}
                        </div>
                    </div>
                )}
            </div>
        </BasicModalOverlay>
    )
}
  
  StatusModal.propTypes = {
    classes: PropTypes.any,
    modalTitle: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    message: PropTypes.string,
    errors: PropTypes.array,
}
  
export default withStyles(styles)(StatusModal)