import React, { useMemo } from 'react'
import _ from 'lodash'
import { getFormError, submit } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'

import Form, { FormBody } from '../../../../components/Form'
import P from '../../../../components/P'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'

import { styled } from '@material-ui/core/styles'
import { translations } from '../../../../config'
import { useShallowEqualSelector } from '../../../../hooks'
import { selectors } from '../../../../store/modules/terminology'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { onSubmit } from './onSubmit'
import * as hooks from './hooks'

const FORM_NAME = 'TERMINOLOGY_CONFIGURE_ARRANGEMENT_MODAL'
const TerminologyEntryForm = Form(FORM_NAME)

const Content = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2)
}))

const Spacing = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1)
}))
const ConfigureArrangementModal = (props) => {
  const { editing, dismiss } = props
  const dispatch = useDispatch()
  const isUsageTypeList = useSelector(selectors.isUsageTypeList)
  const activeArrangement = useSelector(selectors.getActiveArrangement)
  const { id: organisationId } = useSelector(authSelectors.getSelectedOrganisation)

  const formError = useShallowEqualSelector(getFormError(FORM_NAME))
  const initialValues = hooks.useGetInitialValues({ activeArrangement, organisationId, editing })

  const actions = useMemo(() => {
    return [
      {
        success: true,
        text: translations('Confirm'),
        primary: true,
        onClick: () => {
          dispatch(submit(FORM_NAME))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [dismiss, dispatch])

  const schema = useMemo(() => {
    const schema = [
      {
          id: 'name',
          field: 'Input',
          props: {
              label: translations('Name'),
              required: true,
              validate: []
          }
      },
      {
          id: 'description',
          field: 'Input',
          props: {
              label: translations('Description')
          }
      },
      
  ]

  if (!isUsageTypeList) {
    schema.push({
      id: 'useMeddra',
      field: 'Checkbox',
      props: {
          label: translations('Terminology Arrangement Configure - Use MedDRA'),
          help: translations('Terminology Arrangement Configure - Use MedDRA')
      }
    })
  }
      return schema
  }, [])

  const title = editing ? translations('Terminology - Edit Arrangement') : translations('Terminology - Add Arrangement')

  return (
    <BasicModalOverlay actions={actions} title={title}>
      <TerminologyEntryForm
        onSubmit={onSubmit({ dismiss, dispatch })}
        initialValues={initialValues}>
        <ContentBoxBody>
          <Content>
            <Grid container spacing={2}>
              {_.isString(formError) && (
                <Grid item xs={12}>
                  <Spacing>
                    <P value={formError} type={'error'} />
                  </Spacing>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormBody
                  schema={schema}
                  layout={[]}
                  editing
                  fullWidthFields
                />
              </Grid>
            </Grid>
          </Content>
        </ContentBoxBody>
      </TerminologyEntryForm>
    </BasicModalOverlay>
  )
}

export default ConfigureArrangementModal
