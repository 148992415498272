import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const VisuallyHidden = ({ classes, children, ...rest }) => (
  <div className={classes.root} children={children} {...rest} />
)

VisuallyHidden.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.any
}

export default withStyles(style)(VisuallyHidden)
