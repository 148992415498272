import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import { withRouter } from 'react-router'
import _ from 'lodash'

import { selectors as rolesSelectors } from '../../../store/modules/roles'
import { selectors as organisationDetailsSelectors } from '../../../store/modules/organisationDetails'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

import UserRegistrationScreen from './UserRegistrationScreen'

const mapStateToProps = state => {
  const areRolesLoading = rolesSelectors.getIsLoading(state)
  const isOrganisationLoading = organisationDetailsSelectors.getIsLoading(state)
  const organisationDetails = organisationDetailsSelectors.getCurrentOrganisationDetails(state)
  const organisationPlatform = organisationDetailsSelectors.getCurrentOrganisationPlatform(state)
  const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(state)
  const { publicUserRegistration, enableWorkspaces } = _.get(organisationPlatform, 'permissions', {})
  const platformId = platformSelectors.getCurrentPlatformId(state)
  // this needs to exist because of organisationDetails resetStore middleware jank
  const hasOrganisationLoaded = !!(!isOrganisationLoading && organisationId)

  return {
    isLoading: areRolesLoading,
    hasOrganisationLoaded,
    organisationDetails,
    organisationPlatform,
    isPublicUserRegistrationAllowed: publicUserRegistration,
    isMoreRegistrationEnabled: platformSelectors.getHasMoreRegistrationEnabled(state),
    isSabreRegistrationEnabled: platformSelectors.getHasSabreRegistrationEnabled(state),
    enableWorkspaces,
    getPageTitle: platformSelectors.getPageTitle(state),
    platformId
  }
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  withPropsOnChange(
    ['match'],
    ({ match }) => {
      const path = _.get(match, 'path', '')
      const isPublic = (path === '/users/new/public/:orgId')
        || (path === '/register')
        || (path === '/users/new/invite/:organisationInviteId')
      const isOrganisationInvite = path === '/users/new/invite/:organisationInviteId'
      return {
        isPublic,
        isOrganisationInvite
      }
    }
  ),
  withPropsOnChange(
    ['isPublic', 'hasOrganisationLoaded', 'isOrganisationInvite', 'isMoreRegistrationEnabled', 'isSabreRegistrationEnabled'],
    ({ isPublic, isMoreRegistrationEnabled, isSabreRegistrationEnabled, hasOrganisationLoaded, isOrganisationInvite }) => {
      if ((isPublic && (isMoreRegistrationEnabled || isSabreRegistrationEnabled || isOrganisationInvite))) {
        return { isLoading: false }
      }
      if ((isPublic && !hasOrganisationLoaded)) {
        return { isLoading: true }
      }
    }
  )
)(UserRegistrationScreen)
