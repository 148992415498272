export default theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 auto 20px',
    justifyContent: 'center'
  }
  // containerOuter: {
  //   display: 'flex',
  //   justifyContent: 'center'
  // },
  // containerInner: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   justifyContent: 'flex-start',
  //   flexShrink: 1,
  //   flexGrow: 0
  // }
})
