import _ from 'lodash'
import React from 'react'
import Section from '../Section'
import { useSelector, useDispatch } from 'react-redux'
import { getHasRCBetaEnabled } from '../../../../../store/modules/platforms/selectors'
import { getCanEditReportConfig } from '../../../../../store/modules/auth/selectors'
import { push } from 'connected-react-router'
import { getTooltipDetailButton, getCopyDetailButton } from './utils'

const audienceMap = {
  '1': 'Industry',
  '2': 'Healthcare Professional',
  '3': 'Member of Public'
}

const ReportConfigurationDetails = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection,
    report
  } = props

  const dispatch = useDispatch()

  const hasRCBetaEnabled = useSelector(getHasRCBetaEnabled)
  const formViewVersion = _.get(report, 'formViewVersion')

  if(!formViewVersion || !hasRCBetaEnabled) {
    return <></>
  }

  const name = _.get(formViewVersion, 'formViews.name')
  const systemReference = _.get(formViewVersion, 'formViews.id')
  const audience = _.get(audienceMap, _.get(formViewVersion, 'formViews.details.audienceId'))
  const category = _.get(formViewVersion, 'formViews.category')

  const viewRC = () => {
    dispatch(push(`report-configuration-beta/${systemReference}`))
  }

  const idButton = getCopyDetailButton({ text: systemReference, code: true })
  const rcNameButton = getTooltipDetailButton({ text: name, hoverText: 'View Report Configuration', onClick: viewRC })
  
  const rows = [
    { name: 'Name', type: 'DETAIL', value: rcNameButton },
    { name: 'System Reference', type: 'DETAIL', value: idButton },
    { name: 'Audience', type: 'DETAIL', value: audience },
    { name: 'Category', type: 'DETAIL', value: _.capitalize(category) },
  ]

  return (
    <Section
      name='Report Configuration Details'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
      collapsible={false}
    />
  )
}

export default ReportConfigurationDetails