import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import BackBar from '../../../components/BackBar'
import Container from '../../../components/Container'
import BottomBar from '../../../components/BottomBar'
import ButtonsContainer from '../../../components/ButtonsContainer'
import Button from '../../../components/Button'
import P from '../../../components/P'

import style from './style'
import ProductCharts from './ProductCharts'

const renderSubHeader = (props) => {
  const { drug, getPageTitle } = props
  const { name } = drug || {}
  const pageTitle = getPageTitle(name || 'Products Title')
  return <SubHeader
    leftContent={<BackBar />}
    centerContent={(
      <Fragment>
        <Heading component='h1'>{pageTitle.title}</Heading>
        <Helmet>
          <title>{pageTitle.titleWithName}</title>
        </Helmet>
      </Fragment>
    )}
  />
}

const renderDetails = (props) => {
  const { drug } = props
  const { activeSubstances } = drug || {}
  return <Container withMarginTop withMarginBottom>
    <P value={translations('Product Details - Active Substances Header') + ':'} />
    <P value={activeSubstances} />
  </Container>
}

const renderCharts = (props) => {
  const { drug } = props

  return <Container withMarginBottom>
    <ProductCharts drug={drug} />
  </Container>
}

const renderButtons = (props) => {
  const { buttons = [], width, classes } = props
  const isXs = width === 'xs'
  return <BottomBar
    relative={isXs}
    className={classes.bottomBar}
  >
    <ButtonsContainer align='center' column={isXs}>
      {buttons.map(buttonProps => {
        return <Button
          key={buttonProps.children}
          buttonType='primary'
          className={isXs ? classes.button : ''}
          {...buttonProps}
        />
      })}
    </ButtonsContainer>
  </BottomBar>
}

const ViewProductScreen = (props) => {
  const { classes } = props
  return <div className={classes.container}>
    {renderSubHeader(props)}
    {renderDetails(props)}
    {renderCharts(props)}
    {renderButtons(props)}
  </div>
}

ViewProductScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  getPageTitle: PropTypes.func.isRequired
}

export default withStyles(style)(ViewProductScreen)
