import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

import style from './style'

const Loader = (props) => {
  const { classes, size } = props
  return (
    <div className={classes.root}>
      <CircularProgress size={size} />
    </div>
  )
}

Loader.defaultProps = {
  size: 50
}

Loader.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  size: PropTypes.number
}

export default withStyles(style)(Loader)
