import { connect } from 'react-redux'
import { compose } from 'recompose'
import { actions as appActions } from '../../store/modules/app'
import DrawerBar from './DrawerBar'
import { selectors as themeSelectors } from '../../store/modules/themes'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const primaryContrastColor = themeSelectors.getPrimaryContrastColor(state)

  return {
    ...ownProps,
    primaryContrastColor
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(DrawerBar)
