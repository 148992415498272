import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.UPLOAD:
      return reducers.upload(state, action)
    case constants.START_UPLOAD:
      return reducers.startUpload(state, action)
    case constants.COMPLETE_UPLOAD:
      return reducers.completeUpload(state, action)
    case constants.HIDE:
      return reducers.hide(state, action)
    case constants.SET_PROGRESS:
      return reducers.setProgress(state, action)
    case constants.CLEAR:
      return defaultState
    default:
      return state
  }
}
