import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { selectors as reportsSelectors, constants as authConstants } from '../auth'
import drugDictionaryActions from './actions'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class DrugDictionaryMiddleware {
  loadDrugDictionaryMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const isRoutingToProducts = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/products'
    const isRoutingToWatchList = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/watch-list'
    if (isRoutingToProducts || isRoutingToWatchList) {
      dispatch(drugDictionaryActions.searchDrugDictionary({ watchListOnly: !!isRoutingToWatchList }))
    }
  }

  updateDrugDictionaryWatchingValuesMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === authConstants.EDIT_WATCH_LIST) {
      const { payload, status } = action
      // if pending, optimistically update drug dictionary
      if (status === PENDING) {
        dispatch(drugDictionaryActions.updateWatching(payload))
      }
      // if fail, reverse the change
      if (status === FAIL) {
        dispatch(drugDictionaryActions.updateWatching(
          _.mapValues(payload, (watching) => !watching)
        ))
      }
    }
  }
}

export default new DrugDictionaryMiddleware()
