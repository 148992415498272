import React from 'react'
import Counter from './Counter'

class CounterContainer extends React.Component {
  onChange = (direction) => {
    const currentValue = parseInt(this.props.value) || 1
    const newValue = direction === '+' ? currentValue + 1 : Math.max(1, currentValue - 1)
    this.props.onChange(newValue)
  }

  _generateProps = () => ({
    ...this.props,
    onChange: this.onChange
  })

  render () {
    const props = this._generateProps()
    return <Counter {...props} />
  }
}

export default CounterContainer
