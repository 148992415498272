import fp from 'lodash/fp'
import { translations } from '../../../../config'
import digitalStoreSdk from '../../../../digitalStoreSdk'
import toastService from '../../../../services/toastService'
import { asyncActions, actions } from '../../../../store/modules/terminology'

export const onSubmit = ({ dismiss, dispatch }) => async (formValues) => {
    try {
        const editing = !!formValues.id
        let payload = {
            terminologyEntry: {
                id: formValues.id,
                term: formValues.term,
                definition: formValues.isUsageTypeList ? formValues.term : formValues.definition,
                terminologyDictionaryId: formValues.terminologyDictionaryId,
                action: {
                    arrangementId: formValues.terminologyArrangementId,
                    actionType: formValues.actionType
                }
            }
        }

        switch (formValues.actionType) {
            case 'APPLY_MAPPING': {
                let mappingType = 'APPLY_ENTRY_MAPPING'
                if (formValues.mappingOption === 'meddra') {
                    mappingType = 'APPLY_MEDDRA_MAPPING'
                    payload = fp.set(
                        'terminologyEntry.action.mapToMeddraId',
                        formValues.mapToMeddraId.value,
                        payload
                    )
                } else if (formValues.mappingOption === 'freeText') {
                    mappingType = 'APPLY_FREE_TEXT_MAPPING'
                    payload = fp.set(
                        'terminologyEntry.action.mapToFreeText',
                        formValues.mapToFreeText,
                        payload
                    )
                } else {
                    payload = fp.set(
                        'terminologyEntry.action.mapToEntryId',
                        formValues.mapToEntryId.value,
                        payload
                    )
                }
                payload = fp.set(
                    'terminologyEntry.action.actionType',
                    mappingType,
                    payload
                )
            }
                break
            default:
                break
        }
        const apiRequest = editing
            ? digitalStoreSdk
                .terminology
                .updateTerminologyEntryById
            : digitalStoreSdk
                .terminology
                .createTerminologyEntry

        await apiRequest(payload)
        await dispatch(actions.resetMasterExcludingFilters())
        await dispatch(asyncActions.getTerminologyArrangementEntries())
        const successMessage = editing
        ? 'Terminology Entry Configure - Successful Update'
        : 'Terminology Entry Configure - Successful Add'
        toastService.action({
            type: 'success',
            message: translations(successMessage, { term: formValues.term })
        })
        dismiss()

    } catch (error) {
        toastService.action({
            type: 'error',
            message: error.message
        })
        throw error
    }
}
