const styles = theme => ({
  followupAction: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  divider: {
    marginTop: -theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  container: {
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: 3,
      background: theme.palette.grey['300']
    }
  }
})

export default styles