import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { compose, withPropsOnChange } from 'recompose'
import { selectors as themeSelectors } from '../../store/modules/themes'

import withHeightOffset from '../withHeightOffset'

import style from './style'

const BottomBar = props => {
  const { absolute, relative, heightOffsetRef, heightOffset, footerLogo, children, classes, className } = props

  const divClass = classNames(className, classes.buttonContainer, {
    [classes.absolute]: absolute,
    [classes.relative]: relative
  })

  const ref = relative ? undefined : heightOffsetRef

  const divProps = {
    className: divClass,
    children,
    ref,
    style: {
      margin: footerLogo ? '10px 0 10px 0' : 0,
      bottom: footerLogo ? heightOffset : 0
    }
  }

  return <div {...divProps} />
}

const enhance = compose(
  connect(state => {
    const logo = themeSelectors.getThemeFooterLogo(state)
    return {
      footerLogo: logo
    }
  }),
  withStyles(style),
  withPropsOnChange(['relative'], props => {
    return { disableHeightOffset: !!props.relative }
  }),
  withHeightOffset
)

export default enhance(BottomBar)
