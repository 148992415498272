const styles = (theme) => {
  return {
    nowrap: {
      whiteSpace: 'nowrap'
    },
    center: {
      textAlign: 'center'
    },
    right: {
      textAlign: 'right'
    },
    heading: {
      fontWeight: '700'
    },
    compact: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  }
}

export default styles
