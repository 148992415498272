import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import ReportAcknowledgementsScreen from './ReportAcknowledgementsScreen'
import NewAcknowledgement from './NewAcknowledgement'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/reports/:reportid/acknowledgements' path='/reports/:reportid/acknowledgements' exact component={TimedLogoutComponent(ReportAcknowledgementsScreen)} />,
  <PrivateRoute key='/reports/:reportid/acknowledgements/:id' path='/reports/:reportid/acknowledgements/:id' exact component={TimedLogoutComponent(NewAcknowledgement)} />
]
