import React, { useMemo, Fragment } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import { Hidden } from '@material-ui/core'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'
import KeyValueCard from '../../../../components/KeyValueCard'
import NumberOfResultsAnnouncer from '../../../../components/NumberOfResultsAnnouncer'
import { AutoTable } from '../../../../components/Table'
import { getFullName } from '../../../../helpers'

import {
  actions as AccountDeletionRequestActions,
} from '../../../../store/modules/accountDeletionRequest'

import { useAccountDeletionRequest, useIsLoading, useTotal, useError, useHasMore } from '../../../../store/modules/accountDeletionRequest/hooks'

import { translations } from '../../../../config'
import { push } from 'connected-react-router'

import styles from './style'
import { useInitialFetch } from '../../../../hooks'

const AccountDeletionRequestList = (props) => {
  const { classes } = props
  const dispatch = useDispatch()

  const accountDeletionRequest = useAccountDeletionRequest()
  const isLoading = useIsLoading()
  const totalAccountDeletionRequest = useTotal()
  const accountDeletionRequestError = useError()
  const hasMore = useHasMore()

  const hasNoResults = !isLoading && totalAccountDeletionRequest === 0

  useInitialFetch(AccountDeletionRequestActions.searchAccountDeletionRequestFresh)

  const rows = useMemo(() => {
    return _.map(accountDeletionRequest, (record) => {

      const onClick = () => {
        dispatch(push(`/users/${_.get(record, 'id')}`))
      }

      const data = [
        ['Users Name', getFullName(record), { xs: 3, cardDatumWidth: '50%', onClick }],
        ['Users Role', _.get(record, 'role.name'), { xs: 3, cardDatumWidth: '50%' }],
        ['Deletion Request Date', moment(_.get(record, 'accountDeletionRequestedAt')).format('YYYY/MM/DD'), { xs: 5, cardDatumWidth: '100%' }]
      ].map(datum => {
        const [label, value, props] = datum
        return [translations(label), value, props]
      })

      return {
        key: _.get(record, 'id'),
        onClick,
        data,
      }

    })
  }, [accountDeletionRequest])

  const fetchAccountDeletionRequestList = async () => {
    await dispatch(AccountDeletionRequestActions.searchAccountDeletionRequestNext())
  }

  if (accountDeletionRequestError) {
    return (
      <div className={classes.content}>
        {translations('Account Deletion Request - Fetch data error')}
      </div>
    )
  }

  return (
    <>
    <NumberOfResultsAnnouncer count={accountDeletionRequest.length} />

    <Fragment>
      <div className={classes.gridContainer}>
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchAccountDeletionRequestList}
          hasMore={hasMore}
        >
          {
            rows &&
            <>
              <Hidden xsDown>
                <AutoTable rows={rows} />
              </Hidden>
              <Hidden smUp>
                {rows.map(row => {
                  return <div className={classes.cardContainer} key={row.key}>
                    <KeyValueCard {...row} />
                  </div>
                })}
              </Hidden>
            </>
          }
          {hasNoResults &&
            <div className={`${classes.content} ${classes.uppercase}`} role='status'>{translations('No results found')}</div>
          }
        </InfiniteScroll>
      </div>
    </Fragment>
    </>
  )
}

export default withStyles(styles)(AccountDeletionRequestList)
