import { createSlice } from '@reduxjs/toolkit'
import fp from 'lodash/fp'
import _ from 'lodash'
import { ACTION_OPTION_TOGGLE_TYPES, SLICE_NAME } from './constants'
import * as asyncActions from './asyncActions'
import * as selectors from './selectors'
import { createExtraReducers } from '../toolkit-utils'


const initialState = {
    arrangements: {
        activeArrangementId: null,
        data: []
    },
    master: {
        filters: {
            actionType: [
                ACTION_OPTION_TOGGLE_TYPES.actioned.value,
                ACTION_OPTION_TOGGLE_TYPES.unprocessed.value,
            ],
            sort: 'createdAt_DESC'
        },
        pagination: {
            page: 1,
            total: 0,
            count: 0
        },
        data: []
    },
    detail: {}
}



const slice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setFirstArrangementAsActive(state) {
            state.arrangements.activeArrangementId = fp.compose(
                fp.get('id'),
                fp.first,
                fp.get('arrangements.data'),
            )(state)
        },
        setArrangementAsActive(state, action) {
            state.arrangements.activeArrangementId = action.payload.activeArrangementId
        },
        resetMasterExcludingFilters(state) {
            state.master.data = initialState.master.data
            state.master.pagination = initialState.master.pagination
        },
        resetMaster(state) {
            state.master = initialState.master
        },
        updateFilters(state, action) {
            _.set(state, 'master.filters', action.payload.filters)
            _.set(state, 'master.pagination.page', initialState.master.pagination.page)
            _.set(state, 'master.data', initialState.master.data)
        },
        incrementPage(state) {
            state.master.pagination.page++
        },
        resetStore() {
            return initialState
        }
    },
    extraReducers: createExtraReducers(SLICE_NAME)
})

export { selectors, asyncActions }
export const actions = slice.actions
export const store = slice.reducer
