
const styles = theme => {
  return {
    container: {
      position: 'relative'
    },
    scrollContainer: {
      overflowY: 'auto'
    }
  }
}

export default styles
