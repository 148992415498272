import React from 'react'

import Editable from '../Editable'
import { MultiLanguageInput } from '../../Fields'
import P from '../../P'

import { getFieldDisplayValue } from '../../../helpers'

export default Editable({
  input: (props) => {
    return (
      <MultiLanguageInput {...props} />
    )
  },
  display: ({ label, value, format, hideIfEmpty, isEmpty, type }) => {
    if (isEmpty && hideIfEmpty) return null
    const { displayValue, isUrl } = getFieldDisplayValue({ value, type })
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={format ? format(value) : displayValue} dontBreakOut={isUrl} />
      </div>
    )
  }
})
