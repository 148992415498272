import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import ContentBox from '../../../../../components/ContentBox/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../../components/Form/TextContent'
import Heading from '../../../../../components/Heading'
import { translations } from '../../../../../config'

import FormBody from '../../../../../components/Form/FormBody'
import style from './style'
import _ from 'lodash'

const AccountType = props => {
  const {
    classes,
    options,
    handleChange,
    singular
  } = props

  const label = singular ? 'Select user group' : 'Select user groups'

  const schema = [{
    id: 'accountTypes',
    field: 'Dropdown',
    props: {
      label: translations(label),
      name: 'accountTypes',
      options,
      shrink: true,
      required: true,
      multiple: true,
      handleChange
    }
  }]

  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading className={classes.contentBoxHeader}>
            {translations('Account Type')}
          </Heading>
        </TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <FormBody
          layout={['accountTypes:12']}
          schema={schema}
          editing
        />
      </ContentBoxBody>
    </ContentBox>
  )
}

export default withStyles(style)(AccountType)
