"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValidators = void 0;
Object.defineProperty(exports, "viewJSONtoValidations", {
  enumerable: true,
  get: function get() {
    return _viewJSONtoValidations["default"];
  }
});

var _moment = _interopRequireDefault(require("moment"));

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = _interopRequireDefault(require("lodash/fp"));

var _config = require("../config");

var _computedValue = require("../formHelpers/computedValue");

var _viewJSONtoValidations = _interopRequireDefault(require("./viewJSONtoValidations"));

var _patterns = require("./patterns");

var _formHelpers = require("../formSchemas/devicesReport/7.2.0/formHelpers");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var _getField = function _getField(allValues, field) {
  return _lodash["default"].get((0, _computedValue.convertImmutable)(allValues), field);
};

var _getFields = function _getFields(allValues, fields) {
  return _lodash["default"].at((0, _computedValue.convertImmutable)(allValues), fields);
};

var _getSingleDateFromRepeatable = function _getSingleDateFromRepeatable(values, comparator) {
  if (comparator === 'after') return _moment["default"].max(values);
  if (comparator === 'before') return _moment["default"].min(values);
  return null;
};

var _getDateFormat = function _getDateFormat(value) {
  var formats = {
    4: 'YYYY',
    6: 'YYYYMM',
    8: 'YYYYMMDD'
  };
  return formats[_lodash["default"].size(value)];
};

var _getCompareValue = function _getCompareValue(childField, compareField, comparator) {
  if (childField) {
    if (_lodash["default"].isArray(compareField) || _lodash["default"].isArray(_lodash["default"].invoke(compareField, 'toJS'))) {
      var valueArray = compareField.map(function (val) {
        return (0, _moment["default"])(val[childField], 'YYYYMMDD');
      });
      return _getSingleDateFromRepeatable(valueArray, comparator);
    } else return (0, _moment["default"])(compareField, 'YYYYMMDD');
  } else {
    return (0, _moment["default"])(compareField, 'YYYYMMDD');
  }
};

var getValidators = function getValidators(translations) {
  var validators = {
    checkoutDate: function checkoutDate(value) {
      if (value) {
        var formattedDate = (0, _moment["default"])(value, 'DD/MM/YYYY');

        if (formattedDate.isAfter((0, _moment["default"])())) {
          return 'Date cannot be in the future';
        }
      }
    },
    dateTimeNotInPast: function dateTimeNotInPast(value) {
      if (value) {
        var givenDate = (0, _moment["default"])(value);

        if (!givenDate.isValid()) {
          return 'Error - Invalid Date';
        }

        if (!givenDate.isAfter((0, _moment["default"])().subtract({
          hours: 1,
          minutes: 1
        }))) {
          return 'Error - Date In Past';
        }
      } else {
        return 'Error - Invalid Date';
      }
    },
    dateTimeTodayOrFuture: function dateTimeTodayOrFuture(value) {
      if (value) {
        var givenDate = (0, _moment["default"])(value);

        if (!givenDate.isValid()) {
          return 'Error - Invalid Date';
        }

        if ((0, _moment["default"])(givenDate).isBefore((0, _moment["default"])(), "day")) {
          return 'Error - Date In Past';
        }
      } else {
        return 'Error - Invalid Date';
      }
    },
    dateTimeNotInFuture: function dateTimeNotInFuture(dateFormatField, dateFormat) {
      return function (value, allValues) {
        var compareField = _getField(allValues, dateFormatField);

        if (!compareField || !value) return null;

        var format = _lodash["default"].invert(dateFormat)[compareField];

        var date = (0, _moment["default"])(value, format.toUpperCase());

        if (date.isAfter((0, _moment["default"])())) {
          return translations('Error - Date In Future');
        }
      };
    },
    dateTimeNotInFutureWithoutFormat: function dateTimeNotInFutureWithoutFormat(value) {
      var format = (0, _formHelpers.getFormatForDate)(value, {
        yyyymmdd: 'YYYYMMDD',
        yyyymm: 'YYYYMM',
        yyyy: 'YYYY'
      });

      if (!format || !value) {
        return;
      }

      var date = (0, _moment["default"])(value, _lodash["default"].toUpper(format));

      if (date.isAfter((0, _moment["default"])())) {
        return translations('Error - Date In Future');
      }
    },
    required: function required(value) {
      return _lodash["default"].isNil(value) ? 'Error - Field Is Required' : undefined;
    },
    minLength: function minLength(min, field) {
      return function (value) {
        var errorMessage = translations('Error - Fieldname Min Limit', {
          field: field,
          min: min
        });
        return value && value.length < min ? errorMessage : undefined;
      };
    },
    maxLength: function maxLength(max, field) {
      return function (value) {
        var errorMessage = translations('Error - Fieldname Max Limit', {
          field: field,
          max: max
        });
        return value && value.length > max ? errorMessage : undefined;
      };
    },
    maxNumericLength: function maxNumericLength(max, field) {
      return function (value) {
        var errorMessage = translations('Error - Fieldname Max Numeric Limit', {
          field: field,
          max: max
        });
        return value && !/^[0-9]*$/i.test(value) || (value && value.length) > max ? errorMessage : undefined;
      };
    },
    maxDecimalLength: function maxDecimalLength(max, field) {
      return function (value) {
        var errorMessage = translations('Error - Fieldname Max Numeric Limit', {
          field: field,
          max: max
        });

        if (value) {
          ['+', '-', '.'].forEach(function (c) {
            return value.includes(c) && max++;
          });
        }

        return (value && value.length) > max ? errorMessage : undefined;
      };
    },
    numeric: function numeric(value) {
      var errorMessage = translations('Error - Must Be A Number');
      return value && !/^[0-9]*$/i.test(value) ? errorMessage : undefined;
    },
    decimal: function decimal(value) {
      var errorMessage = translations('Error - Must Be A Decimal');
      return value && !/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i.test(value) ? errorMessage : undefined;
    },
    isTimeWith15MinBlock: function isTimeWith15MinBlock(value) {
      var errorMessage = translations('Error - Must Be in 15 minute blocks MM and Hour in hourly blocks in Format of HH:MM');
      return value && !/(^(([01]\d)|(2[0-3])):(?:00|15|30|45))$/i.test(value) ? errorMessage : undefined;
    },
    isAcceptedDateFormat: function isAcceptedDateFormat(dateFormats) {
      return function (value) {
        if (!value) return undefined;
        var date = {
          year: value.substring(0, 4),
          month: value.substring(4, 6),
          day: value.substring(6, 8)
        };
        var error = translations('Error - Invalid Date');

        _lodash["default"].forEach(dateFormats, function (format) {
          if (format === 'YYYYMMDD' && !!date.year && !!date.month && !!date.day) {
            error = undefined;
          }

          if (format === 'YYYYMM' && !!date.year && !!date.month) {
            error = undefined;
          }

          if (format === 'YYYY' && !!date.year) {
            error = undefined;
          }
        });

        return error;
      };
    },
    isDateBeforeOrEqual: function isDateBeforeOrEqual(compareFieldSelector, fieldName, childField) {
      return function (value, allValues) {
        var compareField = _getField(allValues, compareFieldSelector);

        if (!compareField) return null;
        var thisValue = (0, _moment["default"])(value, 'YYYYMMDD');

        var compareValue = _getCompareValue(childField, compareField, 'after');

        if (thisValue.isAfter(compareValue)) {
          return translations('Error - Date Cannot Be After {{field}}', {
            field: translations(fieldName)
          });
        }
      };
    },
    isDateAfterOrEqual: function isDateAfterOrEqual(compareFieldSelector, fieldName, childField) {
      return function (value, allValues) {
        var compareField = _getField(allValues, compareFieldSelector);

        if (!compareField) return null;
        var thisValue = (0, _moment["default"])(value, 'YYYYMMDD');

        var compareValue = _getCompareValue(childField, compareField, 'before');

        if (thisValue.isBefore(compareValue)) {
          return translations('Error - Date Cannot Be Before {{field}}', {
            field: translations(fieldName)
          });
        }
      };
    },
    isDateOnOrAfterStart: function isDateOnOrAfterStart(startDate) {
      return function (value) {
        if (!value) return undefined;

        var FORMAT = _getDateFormat(value);

        var valueMoment = (0, _moment["default"])(value, FORMAT);
        var startMoment = (0, _moment["default"])(startDate, FORMAT);

        if (valueMoment.isBefore(startMoment)) {
          return translations('Error - Date Cannot Be Before {{field}}', {
            field: startMoment.format('LL')
          });
        }
      };
    },
    isDateOnOrBeforeEnd: function isDateOnOrBeforeEnd(endDate) {
      return function (value) {
        if (!value) return undefined;

        var FORMAT = _getDateFormat(value);

        var valueMoment = (0, _moment["default"])(value, FORMAT);
        var endMoment = (0, _moment["default"])(endDate, FORMAT);

        if (valueMoment.isAfter(endMoment)) {
          return translations('Error - Date Cannot Be After {{field}}', {
            field: endMoment.format('LL')
          });
        }
      };
    },
    safetyReportIdFormat: function safetyReportIdFormat(value) {
      if (!value) return null;
      var strings = value.split('-');

      if (strings.length < 3 || !_lodash["default"].find(_config.countryCodesDropdownOptions, function (c) {
        return c.value === strings[0];
      })) {
        return translations('Error - Safety Report IDs must be in correct format');
      }
    },
    isValidEmail: function isValidEmail(value) {
      if (value && !_patterns.emailRegex.test(value)) {
        return translations('Error - Invalid Email');
      }
    },
    isPrimaryReporter: function isPrimaryReporter(value, formValues) {
      var mandatoryFields = _fp["default"].compose(_fp["default"].at(['reportercountry', 'qualification']), _fp["default"].find({
        id: value
      }), _fp["default"].get('primarysource'))(formValues);

      if (_fp["default"].some(_fp["default"].isUndefined, mandatoryFields)) {
        return translations('Error - Primary Reporter must have country and qualification');
      }
    },
    minimumOf1: function minimumOf1(fields, translationKey) {
      return function (_value, formValues) {
        var mandatoryFields = _getFields(formValues, fields);

        if (_fp["default"].every(function (value) {
          return !value || _fp["default"].isNil(value) || _fp["default"].isEmpty(value) && !_lodash["default"].isBoolean(value);
        }, mandatoryFields)) {
          return translations(translationKey);
        }
      };
    },
    isOneFieldSetComplete: function isOneFieldSetComplete(_ref, translationKey) {
      var _ref2 = _slicedToArray(_ref, 2),
          firstValues = _ref2[0],
          secondValues = _ref2[1];

      return function (_value, formValues) {
        var allTrue = _fp["default"].all(_fp["default"].identity);

        var areFieldsNil = function areFieldsNil(paths, formValues) {
          return _fp["default"].compose(_fp["default"].some(_fp["default"].isNil), _fp["default"].at(paths))(formValues);
        };

        var isFirstPathsNil = areFieldsNil(firstValues, formValues);
        var isSecondPathsNil = areFieldsNil(secondValues, formValues);

        if (allTrue([isFirstPathsNil, isSecondPathsNil])) {
          return translations(translationKey);
        }
      };
    },
    handle: function handle() {
      var fn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _fp["default"].always([]);
      return function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var validationArgs = fn.apply(void 0, _toConsumableArray(_lodash["default"].map(args, _computedValue.convertImmutable)));

        if (!_lodash["default"].isUndefined(validationArgs)) {
          return translations.apply(void 0, _toConsumableArray(_lodash["default"].castArray(validationArgs)));
        }
      };
    },
    isBelow: function isBelow(upperBoundary) {
      return function (field) {
        if (field > upperBoundary) {
          return translations('Error - Value Cannot Exceed {{upperBoundary}}', {
            upperBoundary: upperBoundary
          });
        }
      };
    },
    isAbove: function isAbove(lowerBoundary) {
      return function (field) {
        if (field <= lowerBoundary) {
          return translations('Error - Value Must Be Greater Than {{lowerBoundary}}', {
            lowerBoundary: lowerBoundary
          });
        }
      };
    },
    isBelowUnit: function isBelowUnit(_ref3) {
      var field = _ref3.field,
          limit = _ref3.limit,
          unit = _ref3.unit;
      return function (value) {
        if (value >= limit) {
          return translations('Error - {{field}} exceeds {{limit}} {{unit}}', {
            field: field,
            limit: limit,
            unit: unit
          });
        }
      };
    },
    isNumericField: function isNumericField(_ref4) {
      var field = _ref4.field;
      return function (value, allValues) {
        var errorMessage = translations('Error - Must Be A Number');

        var fieldValue = _getField(allValues, field);

        return fieldValue && !/^[0-9]*$/i.test(fieldValue) ? errorMessage : undefined;
      };
    },
    earliestDateisAfter: function earliestDateisAfter(sourceGroup, sourceDate, comparatorGroup, comparatorDate, label) {
      return function (value, allValues) {
        var data = _lodash["default"].cloneDeep(allValues);

        var sourceValues = _getField(data, sourceGroup);

        var comparatorValues = _getField(data, comparatorGroup);

        var sourceDateValue = _getField(data, [sourceDate]);

        var sourceObject = _lodash["default"].pick(data, [sourceDate]);

        var sourceUpdateId = _lodash["default"].get(data, 'id');

        var updatedSourceValues = sourceUpdateId ? _lodash["default"].merge(sourceValues, [sourceObject]) : sourceValues;

        var mapSourceToMoment = _lodash["default"].map(updatedSourceValues, function (source) {
          return (0, _moment["default"])(source[sourceDate]);
        });

        var mapComparatorToMoment = _lodash["default"].map(comparatorValues, function (source) {
          return (0, _moment["default"])(source[comparatorDate]);
        });

        var allSourceDates = [].concat(_toConsumableArray(mapSourceToMoment), _toConsumableArray(sourceDateValue ? [(0, _moment["default"])(sourceDateValue)] : []));

        var minSource = _moment["default"].min(allSourceDates);

        var maxComparator = _moment["default"].max(mapComparatorToMoment);

        if (minSource.isAfter(maxComparator)) {
          return translations('Error - Date Cannot Be After {{field}}', {
            field: translations(label)
          });
        }
      };
    },
    earliestDateisBefore: function earliestDateisBefore(sourceGroup, sourceDate, comparatorGroup, comparatorDate, label, filterSourceFn) {
      return function (value, allValues) {
        var data = _lodash["default"].cloneDeep(allValues);

        var sourceValues = _getField(data, sourceGroup);

        var comparatorValues = _getField(data, comparatorGroup);

        var sourceDateValue = _getField(data, [sourceDate]);

        var sourceObject = _lodash["default"].pick(data, [sourceDate]);

        var sourceUpdateId = _lodash["default"].get(data, 'id');

        var updatedSourceValues = sourceUpdateId ? _lodash["default"].merge(sourceValues, [sourceObject]) : sourceValues;

        var mapSourceToMoment = _lodash["default"].map(_lodash["default"].filter(updatedSourceValues, filterSourceFn), function (source) {
          return (0, _moment["default"])(source[sourceDate]);
        });

        var mapComparatorToMoment = _lodash["default"].map(comparatorValues, function (source) {
          return (0, _moment["default"])(source[comparatorDate]);
        });

        var allSourceDates = [].concat(_toConsumableArray(mapSourceToMoment), _toConsumableArray(sourceDateValue ? [(0, _moment["default"])(sourceDateValue)] : []));

        var minComparator = _moment["default"].min(mapComparatorToMoment);

        if (_lodash["default"].some(allSourceDates, function (source) {
          return source.isSameOrBefore(minComparator);
        })) {
          return;
        }

        return translations('Error - Date Cannot Be After {{field}}', {
          field: translations(label)
        });
      };
    },
    isLessThan: function isLessThan(limit) {
      return function (value) {
        if (value && !_lodash["default"].lt(value, limit)) {
          return translations('Error - Less than', {
            limit: limit
          });
        }
      };
    },
    isLessThanOrEqual: function isLessThanOrEqual(limit) {
      return function (value) {
        if (value && !_lodash["default"].lte(value, limit)) {
          return translations('Error - Less than or equal', {
            limit: limit
          });
        }
      };
    },
    isGreaterThan: function isGreaterThan(limit) {
      return function (value) {
        if (value && !_lodash["default"].gt(value, limit)) {
          return translations('Error - Greater than', {
            limit: limit
          });
        }
      };
    },
    isGreaterThanOrEqual: function isGreaterThanOrEqual(limit) {
      return function (value) {
        if (value && !_lodash["default"].gte(value, limit)) {
          return translations('Error - Greater than or equal', {
            limit: limit
          });
        }
      };
    },
    isBetween: function isBetween(lowerBoundary, upperBoundary) {
      return function (value) {
        if (!value) {
          return undefined;
        }

        var compareValue = _lodash["default"].toNumber(value);

        var inRange = _lodash["default"].inRange(compareValue, lowerBoundary, upperBoundary);

        var isLowerLimit = compareValue === lowerBoundary;
        var isUpperLimit = compareValue === upperBoundary;

        if (!inRange && !isLowerLimit && !isUpperLimit) {
          return translations('Error - Between limit', {
            lowerBoundary: lowerBoundary,
            upperBoundary: upperBoundary
          });
        }
      };
    },
    simplePhoneNumber: function simplePhoneNumber(value) {
      return value && !/^[0-9]{0,15}$/i.test(value) ? translations('Error - Invalid Telephone Number') : undefined;
    },
    phoneNumberCountryCode: function phoneNumberCountryCode(value) {
      return value && !/^\+[0-9 ]*[^\s]$/i.test(value) ? translations('Error - Invalid Country Code') : undefined;
    },
    countryIsoCode: function countryIsoCode(value) {
      return value && !/^[\w]{2}$/i.test(value) ? translations('Error - Invalid Country Code') : undefined;
    },
    requiredWith: function requiredWith(_ref5) {
      var fieldWith = _ref5.fieldWith,
          withName = _ref5.withName,
          fieldName = _ref5.fieldName;
      return function (value, form) {
        var field = _lodash["default"].get(form, fieldWith, undefined);

        if (field && !value) {
          return withName && fieldName ? translations("Error - Missing required with field with names", {
            withName: withName,
            fieldName: fieldName
          }) : translations("Error - Missing required with field", {
            fieldWith: fieldWith
          });
        }

        return undefined;
      };
    }
  };

  validators.pattern = function (pattern, patternCustom) {
    var patternFlags = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'gi';
    var patternCustomError = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'Error - Pattern mismatch';
    return function (value) {
      if (!value) {
        return undefined;
      }

      switch (pattern) {
        case 'email':
          return validators.isValidEmail(value);

        case 'custom':
          if (patternCustom) {
            try {
              var regex = new RegExp(patternCustom, patternFlags);
              return !regex.test(value) ? translations(patternCustomError) : undefined;
            } catch (error) {
              console.error(error);
              return undefined;
            }
          }

        default:
          return undefined;
      }
    };
  };

  return validators;
};

exports.getValidators = getValidators;