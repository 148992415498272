import React, { useCallback } from 'react'
import fp from 'lodash/fp'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Form, { FormBody, FormError } from '../../../../../components/Form'
import Loader from '../../../../../components/Loader'
import style from './style'
import * as hooks from './hooks'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from '../../../../../store/modules/announcementManagement'
import { announcementsFormName, scheduleFormName, platformsFormName } from '../../constants'

const AnnouncementsForm = Form(announcementsFormName)

const AnnouncementEditorForm = ({
  announcementId,
  error,
  isEditing,
}) => {
  const dispatch = useDispatch()

  hooks.useGetAnnouncementById({
    id: announcementId,
    dispatch
  })

  const {
    isLoading,
    entity
  } = useSelector(selectors.genericSelectors.getDetailById({ id: announcementId }))

  const initialValues = fp.compose(
    fp.set('isPublished', fp.propEq('publishType', 'PUBLISHED')(entity)),
    fp.set('id', announcementId),
    fp.pick(['languageId', 'title', 'body']),
    fp.first,
    fp.get('announcementLanguages')
  )(entity)

  if (isEditing && isLoading) {
    return (
      <Loader />
    )
  }

  return (
    <>
      <FormError>{error}</FormError>
      <AnnouncementsForm
        editing
        initialValues={initialValues}
        destroyOnUnmount={false}
        enableReinitialize={true}
        keepDirtyOnReinitialize={false}
      >
        <FormBody
          schema={_getSchema()}
          layout={layout}
        />
      </AnnouncementsForm>
    </>
  )
}

const layout = [
  'title:12',
  'body:12',
]

const _getSchema = () => {
  return [
    {
      id: 'title',
      field: 'Input',
      props: {
        label: 'Title',
        required: true,
        shrink: true
      }
    },
    {
      id: 'body',
      field: 'Wysiwyg',
      props: {
        label: 'Body',
        shrink: true
      }
    }
  ]
}

export default withStyles(style)(AnnouncementEditorForm)
