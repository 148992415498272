import fp from 'lodash/fp'
import { createSelector } from 'reselect'
import { selectors as authSelectors } from '../auth'
import { selectors as formViewSelectors } from '../formViews'
import * as selectedReceiverSelectors from '../selectedReceiver/selectors'
/**
 * Either a formSchema or a formView
 * @typedef {object} Form
 */
/**
 * Checks whether the `publish` property is set `true`.
 * Publish property appears on root for views and within `details` for formSchemas.
 * The publish value in formSchema details should be ignored/removed 
 * Formschemas should always be published and readily available to the receiver
 * Whereas Formviews do have this publish/un-publish ability
 * We can check for a details column existance to know its a formSchema and automatically published, 
 * Formviews have a specific publish column
 * 
 * @type {function(Form):boolean}
 */
const isPublished = fp.overSome([
  fp.matchesProperty('publish', true),
  fp.matchesProperty('details', {})
])
/**
 * @type {function(Form):boolean}
 */
const isPublic = fp.matchesProperty('public', true)
/**
 * Applies public access restrictions and removes unpublished forms to a combined array of formViews and formSchemas.
 *
 * For Example, If the user user is public, only published public forms are shown.
 * If they are super admin, only published forms are shown.
 *
 * @type {function(State):Form[]}
*/
export const getPublishedForms = createSelector(
  [
    authSelectors.getRoleValue,
    selectedReceiverSelectors.getAllSelectedReceiverReportSchemas,
    formViewSelectors.getAllFormViews
  ],
    (roleValue = null, formSchemas = [], formViews = []) => {
      const isPublicUser = roleValue === 6 || fp.isNil(roleValue)
      const handlePublicAccess = isPublicUser ? isPublic : fp.identity
      const forms = fp.concat(formSchemas, formViews)
      return fp.filter(
            fp.overEvery([
              handlePublicAccess,
              isPublished
            ])
        )(forms)
    })
