import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { compose } from 'recompose'

import { Chip, FormControl } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import DownloadIcon from '@material-ui/icons/CloudDownload'

import { translations } from '../../../../config'
import Button from '../../../../components/Button'
import Dropdown from '../../../../components/Fields/Dropdown'
import DatePicker from '../../../../components/Fields/DatePicker'
import Checkbox from '../../../../components/Fields/Checkbox'
import { withFiltersContainer } from '../../../../components/FiltersContainer'

import styles from './style'
import { getColumnName, isColumnVisible } from '../../helpers'
import { useSelector } from 'react-redux'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { useApplicationOptions } from '../../../../store/modules/combinedHooks/reportManagementCombinedHooks'
import { useCurrentOrganisationAndPlatformSourcesOptions } from '../../../../store/modules/combinedHooks/rcCombinedHooks'
import { useQuery } from '../../../../hooks'
import { LocationLookup } from '../../../../components/Fields/asyncLookup'
import { getCurrentOrganisationId } from '../../../../store/modules/organisationDetails/selectors'

const ReportsSearchFilters = (props) => {
  const {
    classes,
    statusOptions,
    typesOptions,
    reportStages,
    downloadReports,
    filters,
    updateFilter,
    hasLocationProvider
  } = props

  const queryFilters = useQuery()
  const combinedFilters = useMemo(() => {
    return { ...queryFilters, ...filters }
  }, [filters, queryFilters])

  const {
    reportStatus,
    reportForm,
    reportSource,
    reportStage,
    dateFrom,
    dateTo,
    application,
    latestVersion,
    legacyUnassignedLocation
  } = combinedFilters

  const [locations, setLocations] = useState([])

  const organisationId = useSelector(getCurrentOrganisationId)
  const columnMappings = useSelector(platformSelectors.getReportManagementColumnMappings)
  const sourceOptions = useCurrentOrganisationAndPlatformSourcesOptions({ rcSupport: false })
  const applicationOptions = useApplicationOptions()
  const hasPlatformReportsBulkDownloadDisabled = useSelector(platformSelectors.getHasPlatformReportsBulkDownloadDisabled)
  const stageOptions = _.get(reportStages, reportForm)
  // Used to space the filters in a more aesthetic appealing fashion
  const filtersOffset = _.isEmpty(stageOptions) ? 0 : 1

  const updateDropDown = (name) => (e) => {
    updateFilter({ name, value: e.target.value })
  }

  const updateDate = (name) => (e) => {
    updateFilter({ name, value: e })
  }

  const updateCheckbox = (name) => (e) => {
    updateFilter({ name, value: e.target.checked })
  }

  const updateLocation = (name) => (options) => {
    const uniqueOptions = _.uniqBy(options, 'value')
    setLocations(uniqueOptions)
    updateFilter({ name, value: _.map(uniqueOptions, 'value') })
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        {isColumnVisible('Status', columnMappings) &&
          <Grid item xs={12} md={2 + filtersOffset} className={classes.leftContent}>
            <FormControl className={classes.storesFilter}>
              <Dropdown
                label={translations('Status')}
                value={reportStatus || null}
                options={statusOptions}
                onChange={updateDropDown('reportStatus')}
                noErrorTextLabel
                shrink
              />
            </FormControl>
          </Grid>
        }
        {isColumnVisible('Report Form', columnMappings) &&
          <Grid item xs={12} md={3} className={classes.leftContent}>
            <FormControl className={classes.storesFilter}>
              <Dropdown
                label={getColumnName('Report Form', columnMappings)}
                value={reportForm || null}
                options={typesOptions}
                onChange={updateDropDown('reportForm')}
                noErrorTextLabel
                shrink
              />
            </FormControl>
          </Grid>
        }
        {!_.isEmpty(stageOptions) && isColumnVisible('Type of Report', columnMappings) &&
          <Grid item xs={12} md={3} className={classes.leftContent}>
            <FormControl className={classes.storesFilter}>
              <Dropdown
                label={translations('Type of Report')}
                value={reportStage || null}
                options={stageOptions}
                onChange={updateDropDown('reportStage')}
                noErrorTextLabel
                shrink
              />
            </FormControl>
          </Grid>}
        {!_.isEmpty(sourceOptions) && isColumnVisible('Report Source', columnMappings) &&
          <Grid item xs={12} md={2 + filtersOffset} className={classes.leftContent}>
            <FormControl className={classes.storesFilter}>
              <Dropdown
                label={translations('Report Source')}
                value={reportSource || null}
                options={sourceOptions}
                onChange={updateDropDown('reportSource')}
                noErrorTextLabel
                shrink
                multiple
              />
            </FormControl>
          </Grid>}
        {isColumnVisible('Date', columnMappings) &&
          <Grid item xs={12} md={2 + filtersOffset} className={classes.leftContent}>
            <FormControl className={classes.storesFilter}>
              <DatePicker
                label={translations('Date From')}
                value={dateFrom}
                onChange={updateDate('dateFrom')}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
        }
        {isColumnVisible('Date', columnMappings) &&
          <Grid item xs={12} md={2 + filtersOffset} className={classes.leftContent}>
            <FormControl className={classes.storesFilter}>
              <DatePicker
                label={translations('Date To')}
                value={dateTo}
                onChange={updateDate('dateTo')}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
        }
        {isColumnVisible('Application', columnMappings) &&
          <Grid item xs={12} md={2 + filtersOffset} className={classes.leftContent}>
            <FormControl className={classes.storesFilter}>
              <Dropdown
                label={translations('Application')}
                options={applicationOptions}
                value={application || null}
                onChange={updateDropDown('application')}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
        }
        {
          hasLocationProvider && isColumnVisible('Location', columnMappings) && (
            <>
              <Grid item xs={12} md={3 + filtersOffset} className={classes.leftContent}>
                <FormControl className={classes.storesFilter}>
                  <LocationLookup
                    label={translations('Location')}
                    value={locations}
                    onChange={updateLocation('locations')}
                    noErrorTextLabel
                    organisationId={organisationId}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip key={`${option.label}-${index}`} variant="outlined" label={option.label} {...getTagProps({ index })} />
                      ))
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2 + filtersOffset} className={classes.leftContent}>
                <FormControl className={classes.storesFilter}>
                  <Dropdown
                    label={translations('Legacy / Unassigned Location')}
                    value={legacyUnassignedLocation}
                    options={[
                      {
                        value: 'Unassigned',
                        label: 'Unassigned'
                      },
                      {
                        value: 'Legacy',
                        label: 'Legacy'
                      }
                    ]}
                    onChange={updateDropDown('legacyUnassignedLocation')}
                    noErrorTextLabel
                    shrink
                  />
                </FormControl>
              </Grid>
            </>
          )
        }
        {isColumnVisible('Latest Version', columnMappings) &&
          <Grid container xs={12} md={3 + filtersOffset} className={classes.leftContent} alignItems='center'>
            <FormControl className={classes.storesFilter}>
              <Checkbox
                label={translations('Display Latest Versions Only')}
                value={latestVersion}
                onChange={updateCheckbox('latestVersion')}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
        }
        {!hasPlatformReportsBulkDownloadDisabled && <Grid item xs={12} md={1} className={classes.buttonCol}>
          <Button onClick={downloadReports} aria-label={translations('Download Reports Button')}>
            <DownloadIcon />
          </Button>
        </Grid>}
      </Grid>
    </div>
  )
}

export default compose(
  withStyles(styles),
  withFiltersContainer
)(ReportsSearchFilters)
