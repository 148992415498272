import React from 'react'
import { GridColumnMenuContainer } from '@mui/x-data-grid'
import MenuItem from '@material-ui/core/MenuItem'

import { translations } from '../../../../../config'

const ColumnMenu = (props) => {
  const { hideMenu, currentColumn, onDeleteLanguage, ...rest } = props

  return (
    <GridColumnMenuContainer
      hideMenu={hideMenu}
      currentColumn={currentColumn}
      {...rest}
    >
      <MenuItem
        onClick={() => onDeleteLanguage({
          id: currentColumn.languageId,
          languageName: currentColumn.headerName
        })}
      >
        {translations('Manage Translations - Table Delete Language Option')}
      </MenuItem>
    </GridColumnMenuContainer>
  )
}

export default ColumnMenu
