import React from 'react'
import PropTypes from 'prop-types'

import ListSubheader from '@material-ui/core/ListSubheader'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

const SubHeader = props => {
  const { id, label, isSticky, classes } = props
  return (
    <ListSubheader
      disableSticky={!isSticky}
      id={id}
      classes={classes}
    >
      {label}
    </ListSubheader>
  )
}

SubHeader.defaultProps = {
  isSticky: true
}

SubHeader.propTypes = {
  isSticky: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.string
}

export default withStyles(styles)(SubHeader)
