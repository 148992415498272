import { LOCATION_CHANGE, push } from 'connected-react-router'
import { matchPath } from 'react-router'
import { actions as healthCheckActions } from '.'
import _ from 'lodash'
import qs from 'qs'

import * as authSelectors from '../auth/selectors'

class HealthCheck {
  getHealthCheckForOrganisation = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const { pathname: path, search: query } = action.payload.location
      const isLoggedIn = authSelectors.getIsLoggedIn(state)
      const pathData = matchPath(path, { path: '/monitoring-and-analytics/healthCheck/organisation' })
      let organisationId = _.get(authSelectors.getSelectedOrganisation(state), 'id')
      const { timePeriod = '24hr', sourceId = 'AllSources', organisationIds = [ organisationId ] } = qs.parse(query, { ignoreQueryPrefix: true }) || {}
      const orgIds = typeof organisationIds === 'string'? [ organisationIds ]: organisationIds

      if (isLoggedIn && pathData) {
        _.forEach([
          healthCheckActions.setTimePeriodFilter(timePeriod),
          healthCheckActions.setSourceIdFilter(sourceId),
          healthCheckActions.setOrganisationIdsFilter(orgIds),
          healthCheckActions.getOrganisationHealthCheckUsingFilters()
        ], action => {
          dispatch(action)
        })
      }
    }
  }
  redirectToOrganisation = ({ getState, dispatch }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const { pathname: path } = action.payload.location
      const isLoggedIn = authSelectors.getIsLoggedIn(state)
      const pathData = matchPath(path, { path: '/monitoring-and-analytics/healthCheck', exact: true })
      let organisationId = _.get(authSelectors.getSelectedOrganisation(state), 'id')
      if (isLoggedIn && pathData) {
        organisationId = _.get(pathData, 'params.id', organisationId)
        dispatch(push(`/monitoring-and-analytics/healthCheck/organisation/?timePeriod=24hr&organisationIds=${organisationId}`))
      }
    }
  }

}

export default new HealthCheck()
