import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { compose } from 'recompose'
import { useParams } from 'react-router-dom'

import { FormControl, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Dropdown from '../../../../components/Fields/Dropdown'
import { translations } from '../../../../config'
import {
  actions as reportConfigurationActions,
  hooks as reportConfigurationHooks
} from '../../../../store/modules/reportConfiguration'

import styles from './style'


const ReportConfigurationFollowUpSearchFilters = ({ classes }) => {
  const dispatch = useDispatch()
  const { id: formViewId } = useParams()
  const filters = reportConfigurationHooks.useFollowUpFilters(formViewId) || {}
  const cohortOptions = reportConfigurationHooks.useFollowUpCohorts(formViewId)
  const triggerOptions = reportConfigurationHooks.useFollowUpTriggers(formViewId)
  const {
    cohorts,
    triggers
  } = filters

  useEffect(() => {
    dispatch(reportConfigurationActions.searchFollowUpFresh({ formViewId }))
  }, [])

  const updateCohort = (e) => {
    const newValue = e.target.value
    dispatch(
      reportConfigurationActions.changeFollowUpFilters({ formViewId, filters: { cohorts: newValue } })
    )
  }

  const updateTrigger = (e) => {
    const newValue = e.target.value
    dispatch(
      reportConfigurationActions.changeFollowUpFilters({ formViewId, filters: { triggers: newValue } })
    )
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} className={classes.leftContent}>
          <FormControl className={classes.storesFilter}>
            <Dropdown
              label={translations('FollowUps - Preview As Cohort')}
              value={cohorts || null}
              options={cohortOptions}
              onChange={updateCohort}
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} className={classes.leftContent}>
          <FormControl className={classes.storesFilter}>
            <Dropdown
              label={translations('FollowUps - Preview By Trigger')}
              value={triggers || null}
              options={triggerOptions}
              onChange={updateTrigger}
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )
}

ReportConfigurationFollowUpSearchFilters.propTypes = {

}

export default compose(
  withStyles(styles),
)(ReportConfigurationFollowUpSearchFilters)
