import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '../../../../components/Button'
import { translations } from '../../../../config'

const EditButtons = ({
  isEditing,
  id,
  style
}) => (
  <Fragment>
     {isEditing !== id && (
      <Button
        disabled={!isEditing ? false : isEditing === id ? false : true}
        buttonType='primary'
        to={`/report-configuration/${id}`}
        style={style}
      >
        {translations('View')}
      </Button>
    )}
  </Fragment>
)

EditButtons.propTypes = {
  isEditing: PropTypes.bool,
  id: PropTypes.string
}

export default EditButtons
