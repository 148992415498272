import _ from 'lodash'

import { translation } from '../services/translations'

export const getComputedRequiredFieldInvalid = ({required, error, touched}) => {
    let fieldInvalid = error && touched
    if (required) {
        if (error !== translation('Error - Field Is Required')) {
            fieldInvalid = error
        }
    } else {
        if (error === translation('Error - Field Is Required')) {
            fieldInvalid = null
        }
    }
    return fieldInvalid
}
