import React, { useMemo } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import { Hidden } from '@material-ui/core'

import InfiniteScroll from 'react-infinite-scroller'
import KeyValueCard from '../../../../components/KeyValueCard'
import NumberOfResultsAnnouncer from '../../../../components/NumberOfResultsAnnouncer'

import {
  actions as invitedUsersActions,
  hooks as invitedUsersHooks
} from '../../../../store/modules/invitedUsers'

import { translations } from '../../../../config'
import DataTable from '../../../../components/DataTable/DataTable'
import InvitedUsersRow from '../InvitedUsersRow/InvitedUsersRow'

import styles from './style'
import { useInitialFetch } from '../../../../hooks'

const headers = [
  { id: 'inviteeEmail', label: translations('Invited Users - Invitee Email') },
  { id: 'inviteStatus', label: translations('Invited Users - Invite Status') },
  { id: 'inviteMessage', label: translations('Invited Users - Accepted At') },
  { id: 'author', label: translations('Invited Users - Author') },
  { id: 'createdAt', label: translations('Invited Users - Created') }
]

const InvitedUsersTable = (props) => {
  const { classes } = props

  const dispatch = useDispatch()

  const invitedUsers = invitedUsersHooks.useInvitedUsers()
  const isLoading = invitedUsersHooks.useIsLoadingInvitedUsers()
  const hasBeenSearched = invitedUsersHooks.useHasBeenSearched()
  const totalInvitedUsers = invitedUsersHooks.useTotalInvitedUsers()
  const invitedUserError = invitedUsersHooks.useInvitedUserError()
  const hasMore = invitedUsersHooks.useHasMore()

  useInitialFetch(invitedUsersActions.fetchInvitedUsers)

  const hasNoResults = !isLoading && hasBeenSearched && totalInvitedUsers === 0

  const rows = useMemo(() => {
    return _.map(invitedUsers, ({ email: inviteeEmail, invite }) => {
      const { status: inviteStatus, user, id: inviteId, createdAt, updatedAt } = invite
      const { lastName: authorLastName, firstName: authorFirstName } = user
      const authorDisplayName = `${authorFirstName} ${authorLastName}`
      return {
        key: inviteId,
        inviteStatus,
        inviteeEmail,
        authorDisplayName,
        createdAt,
        updatedAt
      }
    })
  }, [invitedUsers])

  const fetchedInvitedUsersCount = useMemo(() => {
    return _.size(invitedUsers)
  }, [invitedUsers])

  const fetchInvitedUsers = () => {
    dispatch(invitedUsersActions.fetchInvitedUsers())
  }

  if (invitedUserError) {
    return (
      <div className={classes.content}>
        {translations('Sorry, an error has occurred. Please try again.')}
      </div>
    )
  }

  return (
    <>
      <NumberOfResultsAnnouncer count={rows.length} />
      <div className={classes.gridContainer}>
        <InfiniteScroll
          pageStart={0}
          className={classes.container}
          loadMore={fetchInvitedUsers}
          hasMore={hasMore}
          initialLoad={false}
          useWindow
        >
          {
            rows &&
            <>
              <Hidden xsDown>
                <DataTable
                  headers={headers}
                  data={rows}
                  tableId={'case-management-table'}
                  tableHeading={'Cases'}
                  count={fetchedInvitedUsersCount}
                  total={totalInvitedUsers}
                  rowComponent={InvitedUsersRow}
                />
              </Hidden>
              <Hidden smUp>
                {rows.map(row => {
                  return <div className={classes.cardContainer} key={row.key}>
                    <KeyValueCard {...row} />
                  </div>
                })}
              </Hidden>
            </>
          }
          {hasNoResults &&
            <div className={`${classes.content} ${classes.uppercase}`} role='status'>{translations('No results found')}</div>
          }
        </InfiniteScroll>
      </div>
    </>
  )
}

export default withStyles(styles)(InvitedUsersTable)
