import React, { useEffect } from 'react'
import _ from 'lodash'
import style from '../../style'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { FormControl } from '@material-ui/core'
import { getFormValues, change } from 'redux-form'

import { translations } from '../../../../../config'
import { useSelector, useDispatch } from 'react-redux'
import { getSelectedUserAccountType } from '../../../../../store/modules/userDetails/selectors'
import { getAllRoles } from '../../../../../store/modules/roles/selectors'


import { Dropdown } from '../../../../../components/EditableFields'
import * as validators from '../../../../../validators'
import { UserAccountTypes } from '../../../UsersSearchScreen/UsersSearchFilters/UsersSearchFilters'
import { userAccountTypeEnum } from '../../../../../containers/Users/constants'

const AccountType = (props) => {
  const {
    classes,
    editing,
    formId
  } = props

  const dispatch = useDispatch()
  const formState = useSelector(getFormValues(formId))
  const selectedAccountType = _.get(formState, 'accountType')
  const userAccountType = useSelector(getSelectedUserAccountType)
  const allRoles = useSelector(getAllRoles)
  const standardRoleId = _.chain(allRoles)
      .find(_.matchesProperty('name', 'Standard'))
      .get('id', 6)
      .value()
  const accountTypeOptions = _.filter(UserAccountTypes, type => type.value !== userAccountTypeEnum.GUEST)
  
  useEffect(() => {
    if (editing && selectedAccountType === userAccountTypeEnum.ASSESSOR) {
      dispatch(change(formId, 'roleId', standardRoleId))
    }
  }, [editing, selectedAccountType])

  return (
    <Grid item xs={12} sm={6}>
      <FormControl className={classes.dropDownStandard}>
        <Dropdown
          label={translations('User Search - Account Type')}
          name='accountType'
          options={accountTypeOptions}
          editing={editing}
          validate={[validators.required]}
          classes={{ root: classes.dropDownStandard }}
          displayValue={translations(`User Account Type - ${userAccountType}`)}
        />
      </FormControl>
    </Grid>
  )
}

export default withStyles(style)(AccountType)
