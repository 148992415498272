import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'
import { utilities } from '@redant/mhra-form-schema-library'

import Form, { FormSubmit } from '../../../../../components/Form'
import { constants as reportDetailsConstants } from '../../../../../store/modules/reportDetails'
import { useNewReportFormValues } from '../../../../../store/modules/combinedHooks/newReportCombinedHooks'

import Button from '../../../../../components/Button'
import { translations } from '../../../../../config'
import * as validators from '../../../../../validators'

import FormSection from '../FormSection'
import style from './style'
import { usePrevious } from '../../../../../helpers'
import { useRef } from 'react'

const ReportForm = Form(reportDetailsConstants.NEW_FORM_NAME, { forceUnregisterOnUnmount: true })

const NewReportForm = (props) => {
  const {
    classes,
    onChange,
    editing,
    initialValues,
    onDownloadXml,
    onDownloadPdf,
    json2xmlLoading,
    json2pdfLoading,
    isPublic,
    hasXML,
    canExportPdf,
    canExportAssessmentPdf,
    onDownloadAssessmentPdf,
    hasJSONReportExport,
    onDownloadJSONReport,
    changeField,
    organisationDetails,
    formViewDetails,
    professions,
    formSchema,
    initialFieldMap,
    drug,
    updateReport,
    reportStatus,
    organisationId,
    formViewVersionId,
    formSchemaId,
    followUpNotificationId,
    onSubmitReport
  } = props

  const formValues = useNewReportFormValues() || {}
  const formRef = useRef(null)
  const [formSections, setFormSections] = useState([])
  const [validator, setValidator] = useState()
  const [previousFormValues, setPreviousFormValues] = useState(formValues)
  const initialFieldMapRef = usePrevious(initialFieldMap)

  const onSubmit = async (fields) => {
    const reporters = _.get(fields, 'primarysource', [])
    _.forEach(reporters, reporter => {
      _.unset(reporter, 'primarysourceforreg')
    })
    const primarySourceId = _.get(fields, 'primarysourceid')
    const primarySource = _.find(reporters, { id: primarySourceId })
    _.set(primarySource, 'primarysourceforreg', 1)

    if (reportStatus === 'draft') {
      return updateReport({ formViewVersionId, formSchemaId})
    } else {
      let actionParams = {
        fields,
        formViewVersionId,
        formSchemaId,
        followUpNotificationId
      }
      if (isPublic) {
        actionParams = {
          ...actionParams,
          organisationId,
          formViewVersionId,
          formSchemaId,
          isPublic: true
        }
      }
      return onSubmitReport(actionParams)
    }
  }

  useEffect(() => {
    if (!_.isEqual(previousFormValues, formValues)) {
      setPreviousFormValues(formValues)
    }
  }, [formValues])

  useEffect(() => {
    const updater = (async () => {
      const schema = formSchema
      const schemaSections = await utilities.parseDynamicSchemaSections({ schema, formValues, changeField, formName: reportDetailsConstants.NEW_FORM_NAME })
      const parser = utilities.parseDynamicSchema(formValues, previousFormValues, changeField, reportDetailsConstants.NEW_FORM_NAME, null, {}, organisationDetails, formViewDetails, professions)

      const nextFormSections = {}
      for (const sectionKey in schemaSections) {
        const formSection = schemaSections[sectionKey]
        let formSectionSchema = parser(formSection.schema)
        if (utilities.sectionShouldRender({ fields: formSectionSchema })) {
          // if no drugs already added to report, modify medicine section schema
          if (!_.get(formValues, 'drug.length') && sectionKey === _.get(initialFieldMapRef, 'medicinalProduct.group')) {
            // if reporting a side effect via a drug selection in products then
            // stick that drug into the drug form by default
            const field = _.get(initialFieldMapRef, 'medicinalProduct.field')
            _.set(formSectionSchema, `0.props.initialValues.${field}`, drug)
          }
          // need to allow parser to compute values first before hiding section
          if (!formSection.hideFromForm) {
            nextFormSections[sectionKey] = {
              ...formSection,
              schema: formSectionSchema
            }
          }
        }
      }
      setFormSections(nextFormSections)
      setValidator(() => validators.requiredRepeatables(nextFormSections))
    })()
  }, [previousFormValues, initialValues, initialFieldMapRef])

  const [firstSection] = _.keys(formSections)

  const handleSubmit = () => {
    onSubmit(formValues)
  }

  return (
    <div className={classes.container}>
      <ReportForm ref={formRef} initialValues={initialValues} onChange={onChange} onSubmit={handleSubmit} validate={validator}>
        <div className={classes.formContentWrapper}>
          {
            _.map(formSections, (section, key) => {
              const isFirstSection = firstSection === key
              return (
                <FormSection
                  key={key}
                  id={key}
                  initialOpen={isFirstSection || (!editing || section.initialOpen)}
                  initialValues={initialValues}
                  editing={editing}
                  firstSection={firstSection}
                  {...section}
                />
              )
            })
          }
        </div>
        {canExportPdf && <Button disabled={json2pdfLoading} onClick={onDownloadPdf} big fullWidth className={classes.footerButton} variant='contained' raised>{translations('Export PDF')}</Button>}
        {canExportAssessmentPdf && <Button disabled={json2pdfLoading} onClick={onDownloadAssessmentPdf} big fullWidth className={classes.footerButton} variant='contained' raised>{translations('Export Assessment PDF')}</Button>}
        {hasXML && !isPublic && <Button disabled={json2xmlLoading} onClick={onDownloadXml} big fullWidth className={classes.footerButton} variant='contained' raised>{translations('Export XML')}</Button>}
        {hasJSONReportExport && !isPublic && <Button onClick={onDownloadJSONReport} big fullWidth className={classes.footerButton} variant='contained' raised>{translations('Export JSON')}</Button>}
        {editing && <FormSubmit noContainer big fullWidth className={classes.footerButton}>{translations('Validate & Send')}</FormSubmit>}
      </ReportForm>
    </div>
  )
}

export default withStyles(style)(NewReportForm)
