import React from 'react'
import EditCheckbox from './EditCheckBox'
import PropTypes from 'prop-types'
import EditButtons from './EditButtons'
import { translations } from '../../../../config'

export function mobileTable({
  id,
  name,
  publicVal,
  publish,
  report,
  toggleEdit,
  onSubmit,
  reset,
  togglePermission,
  isEditing,
  getRevision,
  classes
}) {
  return [
    [translations('Report Name'), name,
      { cardDatumWidth: '50%' }
    ],
    [translations('Report Format'), getRevision ? `R${getRevision}` : 'R2',
      { cardDatumWidth: '50%' }
    ],
    [
      translations('Publish Report'),
      <EditCheckbox
        isEditing={isEditing}
        id={id}
        togglePermission={togglePermission}
        value={!!publish}
        payload={{
          publish: !publish
        }}
        classes={classes}
      />,
      { cardDatumWidth: '33%' }
    ],
    [
      translations('Public'),
      <EditCheckbox
        isEditing={isEditing}
        id={id}
        togglePermission={togglePermission}
        value={!!publicVal}
        payload={{
          public: !publicVal
        }}
        classes={classes}
      />,
      { cardDatumWidth: '33%' }
    ],
    [
      translations(''),
      <EditButtons
        isEditing={isEditing}
        id={id}
        report={report}
        toggleEdit={toggleEdit}
        reset={reset}
        onSubmit={onSubmit}
        style={{marginTop: '1.5em'}}
      />,
      { cardDatumWidth: '33%' }
    ]
  ]
}

mobileTable.propTypes = {
  classes: PropTypes.shape({}),
  id: PropTypes.string,
  name: PropTypes.string,
  publicVal: PropTypes.bool,
  publish: PropTypes.bool,
  report: PropTypes.string,
  toggleEdit: PropTypes.func,
  onSubmit: PropTypes.func,
  reset: PropTypes.func,
  togglePermission: PropTypes.func,
  isEditing: PropTypes.bool,
}
