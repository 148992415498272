import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'
import actions from './actions'
import * as constants from './constants'
import * as authSelectors from '../auth/selectors'
import { SUCCESS } from '../../middleware/redux-promise'
import linkService from '../../../services/linkService'

class ResourcesMiddleware {
  loadResourcesMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToResources = matchPath(action.payload.location.pathname, { path: '/resources', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isLoggedIn && isRoutingToResources) {
        dispatch(actions.fetchResourcesForCurrentOrganisation())
      }
    }
  }
  resourcesLinkLoadMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === constants.FETCH_RESOURCE && action.status === SUCCESS) {
      const { link } = action.result
      linkService.open({ link })
    }
  }
}

export default new ResourcesMiddleware()
