import React from 'react'

import Editable from '../Editable'
import P from '../../P'
import { TerminologyLookupDropdown } from '../../../components/Fields'

const TerminologyLookupInput = Editable({
  input: (props) => {
    return (
      <TerminologyLookupDropdown 
        {...props}
      />
    )
  },
  display: (props) => {
    const { label, value } = props
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={value} />
      </div>
    )
  }
})

export default TerminologyLookupInput