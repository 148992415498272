import InfoOutlined from '@material-ui/icons/InfoOutlined'
import { styled } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import _ from 'lodash'
import marked from 'marked'
import { colors } from '../../../config/theme'


const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'left', 
  backgroundColor: theme.palette.grey[100],
  padding: '30px',
  flexDirection: 'column',
  flexWrap: 'nowrap', 
  [theme.breakpoints.down('lg')]: {
    margin: '50px 50px 0 50px'
  },
  [theme.breakpoints.up('lg')]: {
    margin: '69px 69px 0 69px'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    margin: '20px 20px 0 20px'
  },
}))

const BodyContainer =  styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },

}))

const Content = styled('div')(({ theme, hasLinks }) => ({
  display: 'block',
  paddingRight: '40px',
  marginRight: '40px',
  width: 'auto',
  borderRight: hasLinks ? `1px solid ${theme.palette.grey[300]}` : 'none', // Border divider visible only if there are links to display
  [theme.breakpoints.down('sm')]: {
    borderRight: 'none',
    borderBottom: hasLinks ? `1px solid ${theme.palette.grey[300]}` : 'none', 
    paddingBottom: '40px',
    marginBottom: '40px'
  },
  '& li': {
    color: colors.text,
    fontFamily: theme.base.fontFamily,
    fontSize: '13px',
    marginBottom: '3px',
  },
  '& p': {
    color: colors.text,
    fontFamily: theme.base.fontFamily,
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 1.5,
    textOverflow: 'ellipsis'
  },
  '& h1': {
    color: colors.text,
    fontFamily: theme.base.fontFamily,
    fontSize: theme.h1.fontSize,
    fontWeight: theme.h1.fontWeight
  },
  '& h2': {
    color: colors.text,
    fontFamily: theme.base.fontFamily,
    fontSize: theme.h2.fontSize,
    fontWeight: theme.h2.fontWeight
  },
  '& h3': {
    color: colors.text,
    fontFamily: theme.base.fontFamily,
    fontSize: theme.h3.fontSize,
    fontWeight: theme.h3.fontWeight
  },
  '& a': {
    color: 'black'
  }
}))

const Title = styled('h3')(({ theme }) => ({
  margin: 0, 
  color: theme.palette.text.primary,
}))

const InfoTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex', 
  alignItems: 'center', 
  gap: '10px', 
  marginLeft: '0px', 
}))


const LinksContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column', 
  gap: '20px',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column', 
    gap: '20px', 
    alignItems: 'stretch', 
    width: '50%', 
    marginLeft: '20px',
  }
}))


const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary, 
  textDecoration: 'underline',
  width: '100%', 
}))

const renderLinks = (links) => {
  return links.map((link, index) => (
    <StyledLink key={index} href={link.url} target="_blank">
      {link.label}
    </StyledLink>
  ))
}


const Banner = ({ newReportBanner }) => {
  const dataHTML = marked(_.get(newReportBanner,'body'))
  const hasLinks = !!_.get(newReportBanner, 'links') && _.get(newReportBanner, 'links').length > 0

  return (   <Container>
    <InfoTitleContainer>
      <InfoOutlined fontSize={'large'} />
      <Title>{_.get(newReportBanner, 'title')}</Title>
    </InfoTitleContainer>
    <BodyContainer>
      <Content hasLinks={hasLinks} dangerouslySetInnerHTML={{ __html: dataHTML }} />
      {hasLinks && (
        <LinksContainer>
          {renderLinks(_.get(newReportBanner, 'links'))}
        </LinksContainer>
      )}
    </BodyContainer>
  </Container>)
}

export default Banner
