import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'

import { selectors as drugDictionarySelectors } from '../../../../store/modules/drugDictionary'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'

import ProductsList from './ProductsList'
import style from './style'
import { withStyles } from '@material-ui/core/styles'

const mapStateToProps = (state) => {
  const isLoading = drugDictionarySelectors.getIsLoading(state)
  const error = drugDictionarySelectors.getError(state)
  const isInitial = drugDictionarySelectors.getIsInitial(state)
  const results = drugDictionarySelectors.getDrugs(state)
  const hasMore = drugDictionarySelectors.getHasMore(state)
  const hasResults = drugDictionarySelectors.getHasResults(state)
  const getIsInitial = drugDictionarySelectors.getIsInitial(state)
  const isWatchListOnlyData = drugDictionarySelectors.getIsWatchListOnly(state)
  const hasWatchListPermissions = platformSelectors.getHasWatchListPermissions(state)

  return {
    isLoading,
    error,
    isInitial,
    results,
    hasMore,
    hasResults,
    getIsInitial,
    isWatchListOnlyData,
    hasWatchListPermissions
  }
}

const enhance = compose(
  connect(mapStateToProps),
  withStyles(style),
  withPropsOnChange(
    ['isWatchListOnlyData', 'watchListOnly'],
    props => {
      const { isWatchListOnlyData, watchListOnly } = props
      const invalidResults = !!isWatchListOnlyData !== !!watchListOnly
      return { invalidResults }
    }
  ),
  withPropsOnChange(
    ['results', 'invalidResults'],
    ({ results, invalidResults }) => {
      const rows = (results && !invalidResults) ? results : []
      return { rows }
    }
  )
)

export default enhance(ProductsList)
