const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 35,
    width: 35,
    borderRadius: '50%',
    border: '1px solid #000',
    userSelect: 'none',
    transition: 'border-color 0.2s',
    margin: '0 5px 0 5px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      height: 40,
      width: 40
    }
  },
  disabled: {
    cursor: 'not-allowed'
  }
})

export default styles
