import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Editable from '../Editable'
import { PasswordConfirm } from '../../../components/Fields'
import P from '../../P'

import styles from './style'

const EditablePasswordConfirm = Editable({
  input: (props) => {
    return (
      <PasswordConfirm {...props} />
    )
  },
  display: ({ label, classes }) => (
    <div>
      <P value={label} type='textButton' />
      <P value={'********'} />
    </div>
  )
})

export default withStyles(styles)(EditablePasswordConfirm)
