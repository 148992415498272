
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import Form, { FormBody, FormSubmit, FormSection } from '../../../components/Form'
import { translations } from '../../../config'
import styles from './style'
import BottomBar from '../../../components/BottomBar'
import { constants as platformDetailsConstants } from '../../../store/modules/platformDetails'

const PlatformDetailsForm = Form(platformDetailsConstants.NEW_PLATFORM_FORM_NAME)

const NewPlatformScreen = ({ classes, onSubmit, schema, pageTitle }) => {
  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1' uppercase>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
      />
      <div className={classes.contentContainer}>
        <div className={classes.requiredDeactivateButtonContainer}>
          <P value={translations('required fields')} className={classes.requiredText} />
        </div>
        <PlatformDetailsForm onSubmit={onSubmit}>
          <FormBody>
            <FormSection
              title={translations('Platform')}
              schema={schema.schema}
            />
          </FormBody>
          <FormSubmit
            ContainerComponent={BottomBar}
            label={translations('Create Platform')}
            disabled={false}
          />
        </PlatformDetailsForm>
      </div>
    </div>
  )
}

NewPlatformScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  schema: PropTypes.object,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(NewPlatformScreen)
