import React, { Fragment, useMemo, useState } from 'react'
import TabPanel from '@material-ui/lab/TabPanel'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { styled } from '@material-ui/core'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import AnnouncementsForm from './components/AnnouncementsForm'
import * as platformSelectors from '../../../store/modules/platforms/selectors'
import AnnouncementsSidebar from './components/AnnouncementsSideBar'
import EditorLayout from '../../../components/EditorLayout'
import RightContent from './components/RightContent'
import PreviewAnnouncement from './components/PreviewAnnouncement'
import ReusableTabs from '../../../components/ReusableTabs'
import ConfigurePlatforms from './components/ConfigurePlatforms'
import { translations } from '../../../config'
import { getCurrentUserRole } from '../../../store/modules/combinedSelectors/authCombinedSelectors'

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: '30px',
  justifyContent: 'center',
  alignItems: 'center',
}))

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  width: '50%',
  [theme.breakpoints.down('lg')]: {
    width: '80%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  }
}))

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  margin: 0,
  padding: 0
}))

const AnnouncementEditScreen = ({ match }) => {
  const announcementId = match.params.id
  const isEditing = !!announcementId
  const pageTitle = useSelector(platformSelectors.getPageTitle)(isEditing ? 'Edit Announcement' : 'Create Announcement')
  const isPlatformManager = useSelector(getCurrentUserRole) <= 2

  const tabs = useMemo(() => {
    const tabs = [{ label: translations('Edit'), value: 'edit' }]
    if (isPlatformManager) {
      tabs.push({ label: translations('Platforms'), value: 'platforms' })
    }
    return tabs
  }, [isPlatformManager])

  return (
    <EditorLayout
      isLoading={false}
      header={
        <SubHeader
          centerContent={(
            <Fragment>
              <Heading component='h1'>{translations(pageTitle.title)}</Heading>
              <Helmet>
                <title>{pageTitle.titleWithName}</title>
              </Helmet>
            </Fragment>
          )}
          leftContent={(
            <BackBar />
          )}
          rightContent={(
            <RightContent
              isEditing={isEditing}
            />
          )}
        />
      }
      main={tabs &&
        (
          <ReusableTabs
            tabs={tabs}
            shouldUseQuery={false}
            ariaLabel={'Announcement Management - Tabs'}
            unmountOnExit={false}
          >
            <StyledTabPanel value='edit'>
              <Content>
                <InnerContainer>
                  <AnnouncementsForm
                    announcementId={announcementId}
                    isEditing={isEditing}
                  />
                  <PreviewAnnouncement />
                </InnerContainer>
              </Content>
            </StyledTabPanel>
            {
              isPlatformManager && (
                <StyledTabPanel value='platforms'>
                  <Content>
                    <InnerContainer>
                      <ConfigurePlatforms
                        announcementId={announcementId}
                        isEditing={isEditing}
                      />
                    </InnerContainer>
                  </Content>
                </StyledTabPanel>
              )
            }
          </ReusableTabs>
        )
      }
      side={
        <AnnouncementsSidebar
          announcementId={announcementId}
          isEditing={isEditing}
        />
      }
    />
  )
}

export default AnnouncementEditScreen
