export default theme => ({
  barChartContainer: {
    margin: '0 -15px',
    width: 'auto',
    height: 250,
    [theme.breakpoints.up('sm')]: {
      margin: 0,
      height: 350
    }
  },
  formControl: {
    minWidth: 100
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
})
