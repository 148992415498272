import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'
import { isPristine, isSubmitting, isInvalid } from 'redux-form'
import { push } from 'connected-react-router'
import PageForm from './PageForm'
import modalService from '../../../../../services/modalService'
import { translations } from '../../../../../config'
import _ from 'lodash'

import { actions as pagesActions } from '../../../../../store/modules/pages'

export default compose(
  connect((state, ownProps) => {
    const page = ownProps.page
    const organisationId = ownProps.organisationId
    const languageOptions = ownProps.languageOptions
    return {
      initialValues: (
        page
      ),
      isPristine: isPristine('page')(state),
      isSubmitting: isSubmitting('page')(state),
      isInvalid: isInvalid('page')(state)
    }
  }),
  withHandlers({
    handleSubmit: (props) => async (formData) => {
      const { dispatch, page, organisationId} = props
      if (!page.new) {
        await dispatch(pagesActions.updatePage(page.id, {
          ...formData,
          path: _.kebabCase(formData.title)}
        ))
        modalService.continue({
          title: translations('Pages - Update Ack')
        }).then(() => {
          dispatch(push(`/organisations/${page.organisationId}/page-management/`))
        })
      } else {
        await dispatch(pagesActions.createPage(page.id, {
          organisationId: organisationId, 
          path: _.kebabCase(formData.title),
          ...formData}
        ))
        modalService.continue({
          title: translations('Pages - Created Ack')
        }).then(() => {
          dispatch(push(`/organisations/${organisationId}/page-management/`))
        })
      }
    },
    handleDelete: ({ dispatch, page, organisationId }) => () => {
      modalService.action({
        title: translations('Pages - Delete Confirm'),
        actions: [{
          text: translations('Cancel')
        }, {
          text: translations('Continue'),
          onClick: () => {
            dispatch(pagesActions.deletePage(page.id))
              .then(() => dispatch(push(`/organisations/${organisationId}/page-management/`)))
          }
        }]
      })
    },
    getPublicLink: ({ page, organisationId }) => () => {
      return `${window.location.origin}/page/${page.path}?organisationId=${organisationId}&languageId=${page.languageId}`
    }
  })
)(PageForm)
