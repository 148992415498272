import moment from 'moment'
import { getFormValues } from 'redux-form'
import { announcementsFormName } from '../../constants'
import { useSelector } from 'react-redux'

import Announcement from '../../../../../components/Announcement'
import Heading from '../../../../../components/Heading'

import { translations } from '../../../../../config'

const PreviewAnnouncement = () => {
  const formValues = useSelector(getFormValues(announcementsFormName)) || {}

  const { title = '', body = {} } = formValues
  const createdAt = moment(Date.now()).format('DD/MM/YYYY')

  return (
    <>
      <Heading variant='h2'>{translations('Preview')}</Heading>
      <Announcement fullWidth={true} title={title} body={body} createdAt={createdAt} />
    </>
  )
}

export default PreviewAnnouncement