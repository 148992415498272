import { matchPath } from 'react-router'
import { LOCATION_CHANGE } from 'connected-react-router'
import _ from 'lodash'
import { selectors as authSelectors, constants as authConstants } from '../auth'
import { actions as userDetailActions } from '../userDetails'
import * as notificationsSelectors from './selectors'
import actions from './actions'
import * as notificationsConstants from './constants'
import { SUCCESS } from '../../middleware/redux-promise'

class NotificationsMiddleware {
  loadNotificationsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const isRoutingToNotificationsResult = matchPath(action.payload.location.pathname, { path: '/notifications', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(state)
      if (isRoutingToNotificationsResult && isLoggedIn) {
        const selectedUserId = notificationsSelectors.getSelectedUserId(state)
        const filters = notificationsSelectors.getNotificationsFilters(state)
        selectedUserId
          ? dispatch(actions.fetchUserNotificationsFresh({ ...filters, id: selectedUserId }))
          : dispatch(actions.fetchPersonalNotificationsFresh({...filters}))
      }
    }
  }
  getTotalNotificationsNotViewed = ({ dispatch, getState }) => next => action => {
    next(action)
    const shouldFetchTotalNotificationsNotViewed = (action.type === authConstants.LOGIN && action.status === 'SUCCESS') 
      || action.type === authConstants.UPDATE_AUTH_USER 
      || (action.type === authConstants.SET_AUTH_CONTEXT  && action.status === 'SUCCESS')
    if (shouldFetchTotalNotificationsNotViewed) {
      dispatch(actions.resetStore())
      dispatch(actions.getTotalNotificationsNotViewed())
    }
  }
  clearNotificationsOnScreenLoad = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE && action.payload.location.pathname === '/notifications') {
      dispatch(userDetailActions.updateUser({
        id: authSelectors.getCurrentUser(getState()).id,
        lastNotificationReadDate: new Date()
      }))
    }
  }
  removeUnflaggedNotificationFromList = ({ dispatch, getState }) => next => action => {
    // call next first so that the success is called before removing from list
    next(action)
    if (
      action.type === notificationsConstants.SET_NOTIFICATION_USER_STATUS &&
      action.status === SUCCESS &&
      _.get(action, 'payload.status.isFlagged') === false &&
      notificationsSelectors.getNotificationsFilters(getState()).isFlagged === true) {
      const { id } = action.payload
      dispatch(actions.removeNotificationFromList({ id }))
    }
  }
  removeActionedNotificationFromList = ({ dispatch, getState }) => next => action => {
    // call next first so that the success is called before removing from list
    next(action)
    if (
      action.type === notificationsConstants.SET_NOTIFICATION_ACTIONED_STATUS &&
      action.status === SUCCESS &&
      !_.isNull(_.get(action, 'payload.actionedByUserId')) &&
      notificationsSelectors.getNotificationsFilters(getState()).toDo === true) {
      const { id } = action.payload
      dispatch(actions.removeNotificationFromList({ id }))
    }
  }
}

export default new NotificationsMiddleware()
