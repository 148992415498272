"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _utils = require("../utils");

var versions = [{
  version: '1.0.0',
  path: require('./1.0.0')
}];

var _default = (0, _utils.getSchemaVersion)(versions);

exports["default"] = _default;