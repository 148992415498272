import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'

export const ConditionsActionButton = (props) => {
  const { label, handleOnClick, className, icon: IconComponent } = props

  if (IconComponent) {
    return (
      <IconButton
        onClick={handleOnClick}
        className={className}
        size='small'
        aria-label={label}
      >
        <IconComponent />
      </IconButton>
    )
  }

  return (
    <Button
      onClick={handleOnClick}
      color='secondary'
      variant='outlined'
      className={className}>
      {label}
    </Button>
  )
}

ConditionsActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  handleOnClick: PropTypes.func.isRequired,
  icon: PropTypes.any
}

export default ConditionsActionButton
