import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../config'
import style from './style'

const NotFoundScreen = ({
  classes,
  onBackClick,
  pageTitle
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Heading className={classes.heading} variant='h1'>{pageTitle.title}</Heading>
        <Helmet>
          <title>{pageTitle.titleWithName}</title>
        </Helmet>
        <P value={translations('404 Intro')} type={'large'} />
        <a href='#' className={classes.goBack} onClick={onBackClick}>Go back</a>
      </div>
    </div>
  )
}

NotFoundScreen.propTypes = {
  classes: PropTypes.object,
  onBackClick: PropTypes.func.isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(style)(NotFoundScreen)
