import React from 'react'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import style from './style'

const FormError = (props) => {
  const { children, error, givenClass, translate, classes } = props
  const errorMessage = error || children
  if (!errorMessage) return null
  return <div
    className={cx(classes.generalError, givenClass)}
    children={translate ? translations(errorMessage) : errorMessage}
  />
}

export default withStyles(style)(FormError)
