import { colors } from '../../../config/theme'

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.background
  },
  formContainer: {
    position: 'relative',
    padding: '20px',
    width: 300
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 100,
    margin: 0
  },
  logo: {
    width: '250px'
  },
  forgotLinkWrapper: {
    textAlign: 'center',
    marginTop: 18
  },
  forgotLink: {
    fontSize: 14,
    color: '#000'
  }
}

export default theme => styles
