import { compose, withPropsOnChange } from 'recompose'
import _ from 'lodash'

import AutocompleteDropdown from './AutocompleteDropdown'

export default compose(
  withPropsOnChange(
    ['options'],
    ({ options, value }) => {
      const allOptions = _.flatten(options)
      const defaultInputValue = _.get(
        allOptions.find(({ value: optValue }) => optValue === value),
        'label'
      )
      return { defaultInputValue }
    }
  )
)(AutocompleteDropdown)
