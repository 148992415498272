import React, { useState } from 'react'
import PropTypes from 'prop-types'

import BasicModalOverlay from '../../../../../components/BasicModalOverlay'

import { translations } from '../../../../../config'

const ConfirmationModal = (props) => {
  const { onConfirmation, dismiss, title, message } = props
  const [isSubmitting, setIsSubmitting ] = useState(false)

  return (
    <BasicModalOverlay
      title={title}
      text={message}
      submitting={isSubmitting}
      actions={[
        {
          text: translations('Cancel'),
          onClick: dismiss
        },
        {
          text: translations('Confirm'),
          primary: true,
          success: true,
          onClick: async () => {
            try {
              setIsSubmitting(true)
              await onConfirmation()
              dismiss()              
            } catch (error) {
              setIsSubmitting(false)
              console.log(error)
            }
          }
        }
      ]}
    />
  )
}

ConfirmationModal.propTypes = {
  onConfirmation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export default ConfirmationModal
