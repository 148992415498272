import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router'

const PublicRoute = (props) => {
  const { component: RouteComponent, ...rest } = props

  return (
    <Route {...rest}
      render={props => {
        return <RouteComponent isPublic {...props} />
      }}
    />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired
}

export default PublicRoute
