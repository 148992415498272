import ReactGA from 'react-ga4'
import { LOCATION_CHANGE } from 'connected-react-router'
import { environment } from '../../config'
ReactGA.initialize(environment.GA_TRACKING_ID)

class GAMiddleware {
  pageViewMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.payload && action.type === LOCATION_CHANGE) {
      const { pathname, search } = action.payload.location
      ReactGA.send({
        hitType: 'pageview',
        page: pathname + search
      })
    }
    next(action)
  }
}

export default new GAMiddleware()
