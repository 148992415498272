"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.manageExistingValues = exports.liftSingleRepeatables = exports.attachIdsToRepeatables = exports.alreadyExists = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _v = _interopRequireDefault(require("uuid/v4"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var liftSingleRepeatables = function liftSingleRepeatables(value) {
  return _fp["default"].isObject(value) ? _fp["default"].castArray(value) : value;
};

exports.liftSingleRepeatables = liftSingleRepeatables;

var attachIdsToRepeatables = function attachIdsToRepeatables(value) {
  return _fp["default"].isArray(value) ? _fp["default"].map(setId, value) : value;
};

exports.attachIdsToRepeatables = attachIdsToRepeatables;

var setId = function setId(repeatable) {
  return _fp["default"].set('id', (0, _v["default"])(), repeatable);
};

var manageExistingValues = function manageExistingValues(_ref) {
  var formValues = _ref.formValues,
      origin = _ref.origin,
      targetValueFromReduxStore = _ref.targetValueFromReduxStore;
  return function (value, field) {
    var existingValue = _fp["default"].get(['computedRepeatables', field], formValues);

    if (_fp["default"].compose(_fp["default"].isEmpty, _fp["default"].get('repeatables'))(existingValue)) {
      return value;
    }

    if (alreadyExists(value, targetValueFromReduxStore)) {
      return value;
    } else {
      var currentRepeatableIds = _fp["default"].get('repeatables', existingValue);

      return _fp["default"].reject(_fp["default"].compose(_fp["default"].includes(currentRepeatableIds), _fp["default"].get('id')), value);
    }
  };
};

exports.manageExistingValues = manageExistingValues;

var alreadyExists = function alreadyExists(value, targetValueFromReduxStore) {
  var removeId = _fp["default"].unset('id');

  var isRepeatable = _fp["default"].isArray(targetValueFromReduxStore);

  if (isRepeatable) {
    var removeIdFromArrays = _fp["default"].map(removeId);

    var instancesExistInRepeatable = _fp["default"].compose(_fp["default"].some, _fp["default"].overSome, _fp["default"].map(_fp["default"].isEqual), removeIdFromArrays, _fp["default"].castArray)(value);

    return instancesExistInRepeatable(removeIdFromArrays(targetValueFromReduxStore));
  } else {
    var valueWithoutId = removeId(value);
    var targetValueWithoutId = removeId(targetValueFromReduxStore);

    if (_fp["default"].isEqual(valueWithoutId, targetValueWithoutId)) {
      return true;
    }
  }
};

exports.alreadyExists = alreadyExists;