"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setDateNow = exports.not = exports.noopOrCallback = exports.noop = exports.match = exports.joinFields = exports.isFieldLogicActive = exports.hasRepeatableBeenAdded = exports.getYearsSince = exports.getTodayDate = exports.getTimestamp = exports.getOrgMeddra = exports.getMonthsSince = exports.getInitialRepeatableSizeByKey = exports.getInitialRepeatableMetadataByKey = exports.getInitialRepeatableMetadata = exports.getDuration = exports.getDaysSince = exports.getCurrentUserTelephone = exports.getCurrentUserLastName = exports.getCurrentUserId = exports.getCurrentUserFirstName = exports.getCurrentUserEmail = exports.getCurrentUser = exports.getContextValues = exports.get = exports.convertImmutable = exports.computeProfessionXmlFrom = exports.clearOrCallback = exports.clearIfNot = exports.clearIf = exports.clearField = exports.branch = exports.always = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireWildcard(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var not = _fp["default"].negate;
exports.not = not;
var get = _fp["default"].get;
exports.get = get;

var clearField = _fp["default"].always('');

exports.clearField = clearField;
var noop = _fp["default"].noop;
exports.noop = noop;

var clearOrCallback = function clearOrCallback(predicateFn, computedValueFn) {
  return _fp["default"].cond([[predicateFn, computedValueFn], [not(predicateFn), clearField]]);
};

exports.clearOrCallback = clearOrCallback;

var clearIf = function clearIf(fn) {
  return _fp["default"].cond([[fn, clearField], [not(fn), noop]]);
};

exports.clearIf = clearIf;

var clearIfNot = function clearIfNot(fn) {
  return _fp["default"].cond([[not(fn), clearField], [fn, noop]]);
};

exports.clearIfNot = clearIfNot;

var noopOrCallback = function noopOrCallback(predicateFn, computedValueFn) {
  return _fp["default"].cond([[predicateFn, computedValueFn], [not(predicateFn), noop]]);
};

exports.noopOrCallback = noopOrCallback;
var always = _fp["default"].always;
exports.always = always;

var getDuration = function getDuration(timeMeasurements, targetMeasurement) {
  return function (duration, unit) {
    return function (values) {
      var _fp$at = _fp["default"].at([duration, unit], values),
          _fp$at2 = _slicedToArray(_fp$at, 2),
          durationValue = _fp$at2[0],
          unitValue = _fp$at2[1];

      var unitForMoment = _fp["default"].compose(_fp["default"].get(unitValue), _fp["default"].invert)(timeMeasurements);

      var durationForMoment = _fp["default"].toNumber(durationValue);

      if (unitForMoment === 'decade') {
        durationForMoment = durationForMoment * 10;
        unitForMoment = 'Years';
      }

      return _fp["default"].invoke(targetMeasurement, _moment["default"].duration(durationForMoment, unitForMoment));
    };
  };
};

exports.getDuration = getDuration;

var convertImmutable = function convertImmutable(value) {
  var convertedValue = _lodash["default"].invoke(value, 'toJS');

  if (convertedValue) {
    return convertedValue;
  } else {
    return value;
  }
};

exports.convertImmutable = convertImmutable;

var computeProfessionXmlFrom = function computeProfessionXmlFrom(field) {
  return function (values, _parentFormValues, _org, _formview, professions) {
    return _fp["default"].compose(_fp["default"].toString, _fp["default"].get('professionXML'), function (id) {
      return _fp["default"].find({
        id: id
      }, professions);
    }, _fp["default"].get(field))(values);
  };
};

exports.computeProfessionXmlFrom = computeProfessionXmlFrom;

var getOrgMeddra = function getOrgMeddra(_values, _parentFormValues, org) {
  return _fp["default"].get('meddraVersion', org);
};

exports.getOrgMeddra = getOrgMeddra;

var match = function match(predicates) {
  return function () {
    var cases = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var conditions = [];

      _lodash["default"].forEach(cases, function () {
        var computedFn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _fp["default"].always(undefined);
        var caseName = arguments.length > 1 ? arguments[1] : undefined;

        var predicate = _lodash["default"].get(predicates, caseName);

        if (predicate) {
          conditions.push([function () {
            return predicate.apply(void 0, args);
          }, function () {
            return computedFn.apply(void 0, args);
          }]);
        }
      });

      var values = _fp["default"].first(args);

      return _fp["default"].cond(conditions)(values);
    };
  };
};

exports.match = match;

var getSince = function getSince(unit) {
  return function (date) {
    return (0, _moment["default"])().diff((0, _moment["default"])(date, 'YYYYMMDD', true), unit, false);
  };
};

var getYearsSince = getSince('years');
exports.getYearsSince = getYearsSince;
var getMonthsSince = getSince('months');
exports.getMonthsSince = getMonthsSince;
var getDaysSince = getSince('days');
exports.getDaysSince = getDaysSince;

var branch = function branch(predicate) {
  return function (leftFn, rightFn) {
    return _fp["default"].cond([[predicate, leftFn], [_fp["default"].identity, rightFn]]);
  };
};

exports.branch = branch;

var isFieldLogicActive = function isFieldLogicActive(fieldName) {
  return function (formValues, _parentFormValues, _organisationDetails, formViewDetails) {
    var formViewDetailsLogic = _fp["default"].getOr(false, fieldName, formViewDetails);

    if (formViewDetailsLogic === true) {
      return formViewDetailsLogic;
    }

    return _fp["default"].compose(_fp["default"].any(_fp["default"].isEqual(true)), _fp["default"].at([fieldName, "_parentFormValues.".concat(fieldName)]))(formValues);
  };
};

exports.isFieldLogicActive = isFieldLogicActive;

var joinFields = function joinFields(fields) {
  return _fp["default"].compose(_fp["default"].join(' '), _fp["default"].compact, _fp["default"].at(fields));
};

exports.joinFields = joinFields;

var setDateNow = function setDateNow(values) {
  return function (field) {
    var fieldValue = _fp["default"].get(field, values);

    if (!fieldValue) {
      return (0, _moment["default"])().format('YYYYMMDDhhmmss');
    }

    return fieldValue;
  };
};

exports.setDateNow = setDateNow;

var getTodayDate = function getTodayDate() {
  return (0, _moment["default"])().format('YYYYMMDD');
};

exports.getTodayDate = getTodayDate;

var getTimestamp = function getTimestamp() {
  return (0, _moment["default"])().format('YYYYMMDDhhmmss');
};

exports.getTimestamp = getTimestamp;

var getContextValues = _fp["default"].compose(_fp["default"].find(_fp["default"].identity), _fp["default"].at(['_context', '_parentFormValues._context']));

exports.getContextValues = getContextValues;

var getCurrentUser = _fp["default"].compose(_fp["default"].get('currentUser'), getContextValues);

exports.getCurrentUser = getCurrentUser;

var getCurrentUserId = _fp["default"].compose(_fp["default"].get('id'), getCurrentUser);

exports.getCurrentUserId = getCurrentUserId;

var getCurrentUserEmail = _fp["default"].compose(_fp["default"].get('email'), getCurrentUser);

exports.getCurrentUserEmail = getCurrentUserEmail;

var getCurrentUserTelephone = _fp["default"].compose(_fp["default"].get('telephone'), getCurrentUser);

exports.getCurrentUserTelephone = getCurrentUserTelephone;

var getCurrentUserFirstName = _fp["default"].compose(_fp["default"].get('firstName'), getCurrentUser);

exports.getCurrentUserFirstName = getCurrentUserFirstName;

var getCurrentUserLastName = _fp["default"].compose(_fp["default"].get('lastName'), getCurrentUser);

exports.getCurrentUserLastName = getCurrentUserLastName;

var getInitialRepeatableMetadata = _fp["default"].compose(_fp["default"].get('repeatableMetadata'), getContextValues);

exports.getInitialRepeatableMetadata = getInitialRepeatableMetadata;

var getInitialRepeatableMetadataByKey = function getInitialRepeatableMetadataByKey(key) {
  return _fp["default"].compose(_fp["default"].get(key), getInitialRepeatableMetadata);
};

exports.getInitialRepeatableMetadataByKey = getInitialRepeatableMetadataByKey;

var getInitialRepeatableSizeByKey = function getInitialRepeatableSizeByKey(key) {
  return _fp["default"].compose(_fp["default"].getOr(0, 'size'), getInitialRepeatableMetadataByKey(key));
};

exports.getInitialRepeatableSizeByKey = getInitialRepeatableSizeByKey;

var hasRepeatableBeenAdded = function hasRepeatableBeenAdded(key) {
  return function (values) {
    var initialSize = getInitialRepeatableSizeByKey(key)(values);

    var currentSize = _fp["default"].compose(_fp["default"].size, _fp["default"].get(key))(values);

    if (!_fp["default"].any(_lodash.isNumber, [initialSize, currentSize])) {
      return false;
    }

    return currentSize > initialSize;
  };
};

exports.hasRepeatableBeenAdded = hasRepeatableBeenAdded;