import { useMemo, useState } from 'react'
import _ from 'lodash'
import MoreMenu from './MoreMenu'
import { translations } from '../../config'

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityOnIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import DeleteIcon from '@material-ui/icons/Delete'
import ChangeSectionIcon from '@material-ui/icons/ImportExport'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useMoreOptions = (props) => {
  const { onEdit, onMove, onChangeSection, onChangeBlock, onRemoveFromBlock, onAddToBlock, onDelete, onToggleVisibility, onCloseAllBlocks, visible, isFirst, isLast } = props
  const [hasMoreMenu, setHasMoreMenu] = useState(false)

  const moreOptions = useMemo(() => {
    const options = []

    if (onEdit) {
      options.push({
        label: translations('Edit'),
        onClick: onEdit,
        iconComponent: EditIcon
      })
    }

    if (onToggleVisibility) {
      options.push({
        label: visible ? translations('Hide') : translations('Show'),
        onClick: onToggleVisibility,
        iconComponent: visible ? VisibilityOffIcon : VisibilityOnIcon
      })
    }

    if (!isFirst && onMove) {
      options.push({
        label: translations('Move Up'),
        onClick: () => onMove({ direction: 'UP' }),
        iconComponent: ArrowUpwardIcon
      })
    }

    if (!isLast && onMove) {
      options.push({
        label: translations('Move Down'),
        onClick: () => onMove({ direction: 'DOWN' }),
        iconComponent: ArrowDownwardIcon
      })
    }

    if (onChangeSection) {
      options.push({
        label: translations('Change Section'),
        onClick: onChangeSection,
        iconComponent: ChangeSectionIcon
      })
    }

    if (onChangeBlock) {
      options.push({
        label: translations('Change Block'),
        onClick: onChangeBlock,
        iconComponent: ChangeSectionIcon
      })
    }

    if (onRemoveFromBlock) {
      options.push({
        label: translations('Remove From Block'),
        onClick: onRemoveFromBlock,
        iconComponent: RemoveIcon
      })
    }

    if (onAddToBlock) {
      options.push({
        label: translations('Add To Block'),
        onClick: onAddToBlock,
        iconComponent: AddIcon
      })
    }

    if (onDelete) {
      options.push({
        label: translations('Delete'),
        onClick: onDelete,
        iconComponent: DeleteIcon
      })
    }

    if (onCloseAllBlocks) {
      options.push({
        label: translations('Close All Blocks'),
        onClick: onCloseAllBlocks,
        iconComponent: KeyboardArrowUpIcon
      })
    }

    setHasMoreMenu(_.size(options) > 0)

    return options
  }, [onEdit, onMove, onChangeSection, onChangeBlock, onDelete, onToggleVisibility, onCloseAllBlocks, visible, isFirst, isLast])

  return {
    moreMenuProps: {
      options: moreOptions
    },
    hasMoreMenu,
    MoreMenu
  }
}

export default useMoreOptions
