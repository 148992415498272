import React from 'react'
import _ from 'lodash'

import Skeleton from '@material-ui/lab/Skeleton'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'
import Heading from '../../../../components/Heading'
import RightButtonContent from '../../../../components/Form/RightButtonContent'
import Button from '../../../../components/Button'

import { useInitialFetch } from '../../../../hooks'
import { hooks as authHooks } from '../../../../store/modules/auth'
import {
  actions as developerActions,
} from '../../../../store/modules/developer'
import { PENDING } from '../../../../store/middleware/redux-promise'
import modalService from '../../../../services/modalService'

import { styled } from '@material-ui/core/styles'
import { translations } from '../../../../config'
import ApplicationAccessPanel from './ApplicationAccessPanel'
import GenerateApplicationModal from './GenerateApplicationModal/GenerateApplicationModal'
import { useSelector } from 'react-redux'
import { getApplications } from '../../../../store/modules/developer/selectors'

const Content = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10
})

const GenerateButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
}))

const SkeletonContainer = styled('div')(({ theme }) => ({
  '& > span': {
    marginBottom: theme.spacing(1)
  }
}))

const LoadingState = () => {
  return (
    <SkeletonContainer>
      <Skeleton variant={'rect'} height={45} />
      <Skeleton variant={'text'} />
      <Skeleton variant={'text'} />
      <Skeleton variant={'text'} />
      <Skeleton variant={'text'} />
    </SkeletonContainer>
  )
}

const MultipleApplicationAccessPanel = (props) => {
  const {
  } = props

  const organisationId = authHooks.useOrganisation()
  const applications = useSelector(getApplications)
  const isLoading = _.get(applications, 'status') === PENDING
  const data = _.get(applications, 'result', [])

  const onCreate = () => {
    modalService.open({
      component: GenerateApplicationModal,
      disableBackdropClick: true,
      organisationId
    })
  }

  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading>{translations(`Applications`)}</Heading>
        </TextContent>
        <RightButtonContent>
        </RightButtonContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <Content>
          <p>{translations('Developer - API access intro')}</p>
          {
            isLoading ? LoadingState() :
              data.map(application => {
                return (
                  <ApplicationAccessPanel
                    multiple={true}
                    application={application}
                  />
                )
              })
          }
          <GenerateButton
            onClick={onCreate}
            disabled={isLoading}
            raised
            color='primary'
          >
            {translations('Create Application')}
          </GenerateButton>
        </Content>
      </ContentBoxBody>
    </ContentBox>
  )
}

export default MultipleApplicationAccessPanel