import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'

import * as validators from '../../../validators'
import style from './style'
import Form, { FormSubmit } from '../../../components/Form'
import { Input } from '../../../components/Fields'
import { auth, loginTypes, translations } from '../../../config'
import { SubmissionError } from 'redux-form'

const ForgotPasswordForm = Form('forgotPassword')

const emailLabel = auth.loginType === loginTypes.USERNAME
? translations('Username')
: translations('Email')
const emailFieldName = auth.loginType === loginTypes.USERNAME
? 'username'
: 'email'
const emailFieldValidators = auth.loginType === loginTypes.USERNAME
? [validators.required]
: [validators.email, validators.required]
const ForgotPasswordFormWrapper = ({
  onSubmit,
  errorMessage,
  classes
}) => {
  return (
    <div>
      <div className={classes.generalError}>{errorMessage}</div>
      <ForgotPasswordForm onSubmit={onSubmit} submitLabel={translations('Submit')}>
        <FormControl className={classes.field}>
          <Input
            label={emailLabel}
            name={emailFieldName}
            validate={emailFieldValidators}
            autoCapitalize='none'
            autoComplete='off'
            autoCorrect='off'
            spellCheck='off'
          />
        </FormControl>
        <FormSubmit containerClass={classes.centerButtonContainer} label={translations('Submit')} buttonClass={classes.fullWidthButton} />
      </ForgotPasswordForm>
    </div>
  )
}

ForgotPasswordFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default withStyles(style)(ForgotPasswordFormWrapper)
