const style = (theme) => ({
  banner: {
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  bannerContent: {
    padding: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '640px'
    }
  },
  closeButton: {
    alignSelf: 'flex-start',
    color: 'white'
  }
})

export default style