import FormSchemaLibrary from '@redant/mhra-form-schema-library'
import _ from 'lodash'

const formSchemaLibrary = new FormSchemaLibrary()

export const getChanges = ({ viewJSON, schemaName, formViewVersionMeta = {} }) => {
    const {
        counts,
        addedFields,
        removedFields,
        unifiedViewJSON
    } = formSchemaLibrary.getSchemaChanges(viewJSON, schemaName)

    const {
        schemaLibraryVersion,
        schemaVersionJsonHash
    } = _.pick(formViewVersionMeta, ['schemaLibraryVersion', 'schemaVersionJsonHash'])

    const addedCount = _.get(counts, 'added', 0)
    const removedCount = _.get(counts, 'removed', 0)
    let hasUpdates = false
    const hasSchemaVersioning = !_.isNil(schemaLibraryVersion) && !_.isNil(schemaVersionJsonHash)
    if (hasSchemaVersioning) {
        const isGreaterVersion = formSchemaLibrary.VERSION.localeCompare(schemaLibraryVersion, undefined, { numeric: true, sensitivity: 'base' }) === 1
        if (isGreaterVersion && schemaVersionJsonHash !== formSchemaLibrary.getDefaultViewJsonHash(schemaName)) {
            hasUpdates = _.add(addedCount, removedCount) > 0
        }
    } else {
        hasUpdates = _.add(addedCount, removedCount) > 0
    }

    return {
        hasUpdates,
        addedCount,
        removedCount,
        addedFields,
        removedFields,
        unifiedViewJSON,
    }
}

