import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

const StyledH1 = styled.h1`
  color: ${props => props.theme.colors.secondary};
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;
  font-size: ${props => props.theme.type.heading1.small}px;
  ${media.greaterThan('medium')`
     margin-bottom: 20px;
     font-size: ${props => props.theme.type.heading1.medium}px;
  `}
  ${media.greaterThan('large')`
    font-size: ${props => props.theme.type.heading1.large}px;
  `}
`
/**
 * The main heading
 */
const H1 = ({ children }) => {
  return (
    <StyledH1>{children}</StyledH1>
  )
}

H1.propTypes = {
  /** The value of the heading tag */
  children: PropTypes.node.isRequired
}

export default H1
