import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Form, { FormBody } from '../../../../components/Form'
import { translations } from '../../../../config'
import { reviewStatuses } from '../../../../store/modules/submissionReports/constants'

const ReviewFormWrapper = ({
  onApprove,
  onReject,
  receiverStatus,
  reviewStatus,
  submissionReportId,
  dismiss,
  formName,
  onReviewSuccess
}) => {
  const ReviewForm = Form(formName)

  const getSchema = () => {
    if ((reviewStatus === reviewStatuses.APPROVE && receiverStatus === 'REJECTED') || reviewStatus === reviewStatuses.REJECT) {
      return [
        {
          id: 'comment',
          field: 'Input',
          props: {
            label: reviewStatus === reviewStatuses.APPROVE && receiverStatus === 'REJECTED'
              ? translations('Report Review - Comment - APPROVE')
              : translations('Report Review - Comment - REJECT'),
            name: 'comment',
            multiline: true
          }
        }
      ]
    }
    return null // No input field if approving a report that wasn't previously rejected
  }
  
  const handleSubmit = useCallback(async ({ comment }) => {
    const formData = { submissionReportId, comment: comment, dismiss, onReviewSuccess }
    reviewStatus === reviewStatuses.APPROVE ? onApprove(formData) : onReject(formData)
  }, [onApprove, onReject])

  return (
    <div>
      <ReviewForm editing={true} onSubmit={handleSubmit}>
        <FormBody
          editing={true}
          schema={getSchema()}
          layout={['comment:12']}
          fullWidthFields
        />
      </ReviewForm>
    </div>
  )
}

ReviewFormWrapper.propTypes = {
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  receiverStatus: PropTypes.string,
  reviewStatus: PropTypes.string.isRequired,
  submissionReportId: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired
}

export default ReviewFormWrapper
