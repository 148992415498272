import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { selectors as authSelectors, actions as authActions, constants as authConstants } from '../auth'
import usersActions from './actions'
import * as userDetailsConstants from '../userDetails/constants'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { selectors as rolesSelectors } from '../roles'
import rolesActions from '../roles/actions'

class UsersMiddleware {
  loadInitialUsersMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const isRoutingToUsers = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/users'
    const isRoutingToNotifications = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/notifications'
    const isLoggedIn = authSelectors.getIsLoggedIn(getState())
    const allRoles = rolesSelectors.getAllRoles(getState())
    if (isRoutingToUsers && isLoggedIn) {
      if (!allRoles) {
        dispatch(rolesActions.fetchAllRoles()).then(() =>
          dispatch(usersActions.searchUsersFresh({}))
        )
      } else {
        dispatch(usersActions.searchUsersFresh({}))
      }
    }

    if (isRoutingToNotifications) {
      dispatch(usersActions.resetStore())
      dispatch(usersActions.searchUsersFresh({ includeDeactivated: false }))
    }
  }

  updateUserListOnUserProfileUpdateMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if ((action.type === userDetailsConstants.UPDATE_USER && action.status === 'SUCCESS') ||
        (action.type === userDetailsConstants.CHANGE_USER_PASSWORD && action.status === 'SUCCESS') ||
        (action.type === userDetailsConstants.CHANGE_CURRENT_USER_PASSWORD && action.status === 'SUCCESS') ||
        (action.type === userDetailsConstants.USER_ACCOUNT_STATUS_TOGGLE && action.status === 'SUCCESS')) {
      const user = action.result
      dispatch(usersActions.updateUserInList({user}))
    }
  }

  updateUserListOnUserProfileLoadMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if ((action.type === userDetailsConstants.FETCH_USER_DETAILS || action.type === userDetailsConstants.FETCH_CURRENT_USER_DETAILS) && action.status === 'SUCCESS') {
      const user = action.result
      dispatch(usersActions.updateUserInList({user}))
    }
  }

  resetOrganisationOnUserLogout = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === authConstants.LOGOUT && action.status === 'SUCCESS') {
      dispatch(usersActions.resetStore())
    }
  }

  logOutIfUserChangesOwnStoreMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if ((action.type === userDetailsConstants.UPDATE_USER && action.status === 'SUCCESS')) {
      const currentUser = authSelectors.getCurrentUser(getState())
      const resultUserId = _.get(action, 'result.id')
      const currentOrgId = authSelectors.getUserSelectedOrganisationId(getState())
      const newOrgs = _.get(action, 'result.organisations')
      const isInWorkspace = currentOrgId === _.get(action, 'result.workspaceId')
      const hasOrganisationAccess = _.find(newOrgs, org => org.id === currentOrgId)
      
      if (resultUserId === currentUser.id && !isInWorkspace && !hasOrganisationAccess) {
        modalService.action({
          title: translations('Logging Out'),
          text: translations('You have changed your own Organisation to one different to what you are currently logged in as. You will need to log in again.'),
          disableBackdropClick: true,
          actions: [
            {
              success: true,
              text: translations('Continue'),
              primary: true,
              onClick: () => {
                dispatch(authActions.logout())
              }
            }
          ]
        })
      }
      const user = action.result
      dispatch(usersActions.updateUserInList({user}))
    }
  }
}

export default new UsersMiddleware()
