import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import CommunicationsTemplateForm from '../CommunicationsTemplateForm'


import { withStyles } from '@material-ui/core/styles'
import style from './style'

// eslint-disable-next-line react/prop-types
const renderHeader = ({ pageTitle }) => {
  const { title, titleWithName } = pageTitle
  return (
    <SubHeader
      leftContent={<BackBar />}
      centerContent={<Fragment>
        <Heading component='h1' uppercase>{title}</Heading>
        <Helmet>
          <title>{titleWithName}</title>
        </Helmet>
      </Fragment>}
    />
  )
}

const renderForm = ({channel, type}) => {
  return <CommunicationsTemplateForm channel={channel} type={type} /> 
}

const CommunicationsAddScreen = (props) => {
  const { pageTitle, classes, channel, type } = props
  return <Fragment>
    {renderHeader({ pageTitle })}
    <div className={classes.container}>
      {renderForm({ channel, type })}
    </div>
  </Fragment>
}

CommunicationsAddScreen.propTypes = {
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired,
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  channel: PropTypes.string
}

export default withStyles(style)(CommunicationsAddScreen)
