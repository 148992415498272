"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reporterSchema = exports.miscDeviceSchema = exports.attachmentSchema = void 0;

var attachmentSchema = function attachmentSchema(validators) {
  return [{
    id: 'attachmentfile',
    field: 'Input',
    props: {
      label: 'File Name',
      name: 'attachmentfile',
      required: true,
      shrink: true
    }
  }, {
    id: 'attachmenturl',
    field: 'FilePicker',
    props: {
      label: 'Select File',
      name: 'attachmenturl',
      accept: ['.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.jpg', '.jpeg', '.png', '.pdf', '.html', '.mp4', '.avi'],
      required: true,
      shrink: true
    }
  }, {
    id: 'attachmentcomments',
    field: 'Input',
    props: {
      label: 'File Comments',
      name: 'attachmentcomments',
      required: false,
      shrink: true,
      multiline: true,
      maxLength: 1000,
      validate: [validators.maxLength(1000, 'Field')]
    }
  }];
};

exports.attachmentSchema = attachmentSchema;

var reporterSchema = function reporterSchema(validators) {
  return [{
    id: 'reporterContactPersonFirstName',
    field: 'Input',
    computedValue: function computedValue(values) {
      return _.get(values, 'reportergivename_primarysource', null);
    },
    props: {
      shrink: true,
      required: true,
      name: 'reporterContactPersonFirstName',
      label: "Contact's first name",
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'reporterContactPersonSecondName',
    field: 'Input',
    computedValue: function computedValue(values) {
      return _.get(values, 'reporterfamilyname_primarysource', null);
    },
    props: {
      shrink: true,
      required: true,
      name: 'reporterContactPersonSecondName',
      label: "Contact's last name",
      validate: [validators.maxLength(50, 'Field')]
    }
  }];
};

exports.reporterSchema = reporterSchema;

var miscDeviceSchema = function miscDeviceSchema(validators) {
  return [{
    id: 'nomenclatureCode',
    field: 'Input',
    props: {
      shrink: true,
      name: 'nomenclatureCode',
      label: "Medical device nomenclature code",
      required: false,
      validate: [validators.numeric, validators.maxLength(50, 'Field')],
      helperText: 'Please enter the GMDN code that most closely matches your investigational device.'
    }
  }, {
    id: 'modelNum',
    field: 'Input',
    props: {
      shrink: true,
      required: true,
      name: 'modelNum',
      label: "Model",
      validate: [validators.maxLength(300, 'Field')]
    }
  }];
};

exports.miscDeviceSchema = miscDeviceSchema;