import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { push } from 'connected-react-router'
import _ from 'lodash'

import PlatformsManagementScreen from './PlatformsManagementScreen'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { translations } from '../../../config'

const mapStateToProps = state => {
  const canEditPlatform = authSelectors.getHasPlatformDetailsPermissions(state)
  const results = platformSelectors.getAllPlatforms(state)

  const res = {
    results,
    canEditPlatform,
    pageTitle: platformSelectors.getPageTitle(state)('Platform Management')
  }

  return res
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onPlatformClick: ({ dispatch }) => ({ id }) => {
      dispatch(push(`/platforms/${id}`))
    }
  }),
  withPropsOnChange(
    ['results'],
    props => {
      const { results, onPlatformClick } = props
      let rows = []
      if (results && results.length) {
        rows = results
          .map(platform => {
            const { id, name, domain } = platform
            const onClick = () => onPlatformClick(platform)
            const data = [
              ['Name', name, { xs: 4, cardDatumWidth: '50%', onClick }],
              ['Hostname', domain, { xs: 4, cardDatumWidth: '50%' }]
            ].map(datum => {
              const [label, value, props] = datum
              return [translations(label), value, props]
            })

            return {
              key: id,
              onClick,
              data
            }
          })
      }
      return { rows }
    }
  )
)(PlatformsManagementScreen)
