import React from 'react'
import classNames from 'classnames'

import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../config'
import { MenuItem, ListItemText } from '@material-ui/core'
import style from './style'

const Option = props => {
  const {
    label,
    details,
    value,
    nullOption,
    divider,
    dropdownLabel,
    dropdownProps,
    isFocused, // used in AutocompleteDropdown
    isSelected: isSelectedProp, // used in AutocompleteDropdown
    classes,
    hidden,
    // rest is where material-ui sets props (eg onClick)
    ...rest
  } = props
  const translatedlabel = translations(label)
  const { getIsSelected = () => false } = dropdownProps || {}
  const isSelected = isSelectedProp || getIsSelected(value)
  const secondaryText = details || ''

  if (dropdownLabel) {
    // label OPTION
    return <MenuItem className={classNames(classes.option, classes.nullOption)} disabled value=''>{translatedlabel}</MenuItem>
  } else if (nullOption) {
    // NULL OPTION
    const className = classNames(classes.option, classes.nullOption)
    return (
      <MenuItem className={className} {...rest}>
        {translatedlabel}
      </MenuItem>
    )
  } else if (divider) {
    // DIVIDER
    return <Divider className={classes.divider} />
  } else {
    // NORMAL OPTION
    const className = classNames(classes.option, {
      [classes.focused]: isFocused,
      [classes.selected]: isSelected,
      [classes.hidden]: hidden && !isSelected
    })
    return (
      <MenuItem
        className={className}
        disabled={hidden}
        {...rest}
      >
        <ListItemText
          primary={translatedlabel}
          secondary={secondaryText}
        />
      </MenuItem>
    )
  }
}

export default withStyles(style)(Option)
