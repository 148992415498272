import _ from 'lodash'
import { promiseReducer } from '../../util'

class Json2xmlReducers {
  json2xml = promiseReducer(
    (state, action) => {
      return {
        ...state
      }
    }
  )

  ackJson2xml = promiseReducer(
    (state, action) => {
      return {
        ...state
      }
    }
  )
}

export default new Json2xmlReducers()
