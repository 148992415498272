import { createSelector } from '@reduxjs/toolkit'
import fp from 'lodash/fp'
import { ACTION_OPTION_TOGGLE_TYPES, ARRANGEMENT_PATH, SLICE_NAME } from './constants'
import { getEntityWithStatus } from '../toolkit-utils/getEntityWithStatus'
import { GenericSelectors } from '../toolkit-utils/selectors'

export const getTerminologyArrangements = createSelector([
    fp.get([SLICE_NAME, 'arrangements'])
], getEntityWithStatus({ canBeEmpty: true }))

export const getActiveArrangementId = fp.get([SLICE_NAME, 'arrangements', 'activeArrangementId'])
export const getTerminologyArrangementsData = fp.get([SLICE_NAME, 'arrangements', 'data'])

export const getActiveArrangement = createSelector([
    getActiveArrangementId,
    getTerminologyArrangementsData
], (activeId, arrangements) => {
    const activeArrangement = fp.find({ id: activeId }, arrangements)
    return activeArrangement
})

export const getActiveArrangementDictionaries = createSelector(
    [
        getActiveArrangement
    ],
    (activeArrangement) => {
        return fp.get('arrangementDictionaries', activeArrangement)
    }
)

export const isUsageTypeAction = createSelector([
    getActiveArrangement
], fp.propEq('usageType', 'ACTION'))

export const isUsageTypeList = createSelector([
    getActiveArrangement
], fp.propEq('usageType', 'LIST'))

export const doesArrangementUseDefinitions = createSelector([
    getActiveArrangement
], fp.propEq('action.useDefinitionsType', 'YES'))

export const getAvaliableDictionariesContextTypes = createSelector([
    getTerminologyArrangementsData,
    getActiveArrangementId,
], (arrangements, activeArrangementId) => {
    return [
        ACTION_OPTION_TOGGLE_TYPES.actioned,
        ACTION_OPTION_TOGGLE_TYPES.unprocessed,
        ACTION_OPTION_TOGGLE_TYPES.ignored
    ]
})


export const getMappingOptions = createSelector([
    getActiveArrangementDictionaries
], (arrangementDictionaries) => {
    const options = fp.map(arrangementDictionary => {
        return {
            label: arrangementDictionary.dictionary.name,
            value: arrangementDictionary.dictionaryId
        }
    }, arrangementDictionaries)
    options.unshift({
        label: 'MedDRA',
        value: 'meddra'
    },
        {
            label: 'Free text',
            value: 'freeText'
        })
    return options
})

export const getPrimaryDictionaryIdForArrangement = createSelector([
    getActiveArrangementDictionaries
], fp.compose(
    fp.get('dictionaryId'),
    fp.find({ relationshipType: 'PRIMARY' })
))

export const getSecondaryDictionariesForArrangement = createSelector([
    getActiveArrangementDictionaries
], fp.compose(
    fp.map('dictionary'),
    fp.filter({ relationshipType: 'SECONDARY' })
))

export const doesArrangementHaveSecondaryDictionaries = createSelector([
    getActiveArrangementDictionaries
], fp.any({ relationshipType: 'SECONDARY' }))

export const genericSelectors = new GenericSelectors(SLICE_NAME)
