"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schema = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _schema = require("./schema");

var _view = _interopRequireDefault(require("./view"));

var _constants = require("../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var schema = {
  type: _constants.FORM_TYPE_CONSTANTS.REPORT,
  getFormSchema: _schema.getFormSchema,
  getFormView: function getFormView() {
    return _fp["default"].cloneDeep(_view["default"]);
  },
  getLocationField: function getLocationField(viewJSON) {
    return undefined;
  },
  attachmentFields: ['footnotes', 'notificationAttachments', 'confirmationAttachments'],
  attachmentTypeGroups: {
    "default": 'footnotes',
    footnotes: 'footnotes',
    notification: 'notificationAttachments',
    confirmation: 'confirmationAttachments'
  }
};
exports.schema = schema;