"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  value: 'Yes',
  label: 'Yes'
}, {
  value: 'No',
  label: 'No'
}];
exports["default"] = _default;