"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _config = require("../../../../../../config");

var _formHelpers = require("../../../formHelpers");

var _common = require("../common");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var dateFormats = {
  yyyymmdd: '102',
  yyyymm: '610',
  yyyy: '602'
};

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'canSaveDraft',
    field: 'Input',
    computedValue: function computedValue(values) {
      return !_lodash["default"].isNil(values.reportType);
    },
    props: {
      type: 'hidden',
      name: 'canSaveDraft',
      label: 'Can save draft',
      required: false
    }
  }, {
    id: 'reportType',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'reportType',
      label: 'Type of Trend report',
      options: (0, _formHelpers.mapValuesAt1)(['Trend', 'Trend: Follow up', 'Trend: Final'])
    }
  }, {
    id: 'adverseEventDateFrom',
    field: 'Date',
    props: {
      shrink: true,
      required: true,
      name: 'adverseEventDateFrom',
      label: 'Reporting period from',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('adversefromdateformat', dateFormats), validators.isDateBeforeOrEqual('adverseEventDateTo', 'Date of incident to')],
      helperText: 'Date range the report covers'
    }
  }, {
    id: 'adverseEventDateTo',
    field: 'Date',
    props: {
      shrink: true,
      required: true,
      name: 'adverseEventDateTo',
      label: 'Reporting period to',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.dateTimeNotInFuture('adversetodateformat', dateFormats), validators.isDateAfterOrEqual('adverseEventDateFrom', 'Date of incident from')],
      helperText: 'Date range the report covers'
    }
  }].concat(_toConsumableArray((0, _common.miscDeviceSchema)(validators)), _toConsumableArray((0, _common.reporterSchema)(validators)), [{
    id: 'imdrfCodeChoice1',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'imdrfCodeChoice1',
      label: 'IMDRF Annex A code (1st Code)',
      autocomplete: true,
      options: _config.annexA
    }
  }, {
    id: 'imdrfClinicalCodeChoice1',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'imdrfClinicalCodeChoice1',
      label: 'IMDRF Annex E code (1st Code)',
      autocomplete: true,
      options: _config.annexE
    }
  }, {
    id: 'imdrfHealthCodeChoice1',
    field: 'Dropdown',
    props: {
      shrink: true,
      required: true,
      name: 'imdrfHealthCodeChoice1',
      label: 'IMDRF Annex F code (1st Code)',
      autocomplete: true,
      options: _config.annexF
    }
  }]);
};

exports.getFormSchema = getFormSchema;