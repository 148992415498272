import React, { useEffect, useImperativeHandle, useState, forwardRef } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { replace } from 'connected-react-router'

import FormSchemaLibrary, { utilities } from '@redant/mhra-form-schema-library'
import { styled } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'

import Button from '../../../../components/Button'
import toastService from '../../../../services/toastService'

import { translations } from '../../../../config'
import {
  useSidebar,
  useFormViews,
  useConditions,
  useRCEditor
} from '../../../../contexts'
import * as conditionsActions from '../../../../contexts/ConditionsContext/actions'
import {
  actions as reportConfigurationActions,
  selectors as reportConfigurationSelectors
} from '../../../../store/modules/reportConfiguration'
import {
  hooks as authHooks
} from '../../../../store/modules/auth'

const Wrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2)
}))
const toastError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Save Report Config')
})
const toastSuccess = () => toastService.action({
  type: 'success',
  message: translations('Successfully - Save Report Config')
})

const SaveReportConfiguration = (props, ref) => {
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const { id: formViewId, tab = 'editor' } = useParams()
  const {
    state: rcSidebarState,
    selectors: rcSidebarSelectors,
    actions: rcSidebarActions,
    dispatch: rcSidebarDispatch
  } = useSidebar()
  const {
    state: rcFormViewState,
    actions: rcFormViewActions,
    dispatch: rcFormViewDispatch
  } = useFormViews()
  const {
    state: conditionsState,
    dispatch: conditionsDispatch,
    getConditionsForSave,
    getConditionsForResource
  } = useConditions()
  const { state: rcEditorState } = useRCEditor()
  const organisationId = authHooks.useOrganisation()
  const isNewFormView = rcEditorState.isNewFormView
  const { isEdited: isSidebarEdited, repeatableInstancesEdited } = rcSidebarState
  const { isEdited: isFormViewEdited, formSchemaUpdated } = rcFormViewState
  const { isEdited: isConditionsEdited } = conditionsState
  const { formSchemaId, name, versionId } = rcEditorState
  const { archivedAt, type } = useSelector(reportConfigurationSelectors.getFormViewMeta(formViewId))

  const handleSave = (showToast = true) => {
    setSaving(true)
    const details = rcSidebarSelectors.getUnloaded(rcSidebarState)
    const repeatableInstances = rcSidebarSelectors.getRepeatableInstances(rcSidebarState)
    const viewJSON = utilities.unflattenViewJSON(rcFormViewState, getConditionsForResource)
    const conditions = getConditionsForSave()
    const meta = getFormSchemaMetaData(type)
    const params = {
      name,
      formSchemaId,
      organisationId,
      viewJSON,
      conditions,
      versionId,
      schemaLibraryVersion: meta.schemaLibraryVersion,
      schemaVersionJsonHash: meta.schemaVersionJsonHash,
      repeatableInstances
    }
    let firstAction = Promise.resolve({ formViewId })
    const isEdited = (isFormViewEdited || isNewFormView || isConditionsEdited || repeatableInstancesEdited)

    if (isEdited) {
      if (isNewFormView) {
        firstAction = dispatch(reportConfigurationActions.createReportConfiguration({ params }))
      } else {
        let params = { id: formViewId, viewJSON, conditions, repeatableInstances }
        if (formSchemaUpdated || repeatableInstancesEdited) {
          params.schemaLibraryVersion = meta.schemaLibraryVersion
          params.schemaVersionJsonHash = meta.schemaVersionJsonHash
        }
      firstAction = dispatch(reportConfigurationActions.updateReportConfigurationVersion(params))
    }
  }
  firstAction
    .then(({ formViewId: id }) => {
      if (isSidebarEdited || isNewFormView) {
        dispatch(reportConfigurationActions.updateReportConfiguration({ id, params: { ...details } }))
          .then(res => {
            showToast && toastSuccess()
            dispatch(reportConfigurationActions.fetchFormViewById({ formViewId: id, silent: true }))
            return res
          })
          .catch(err => {
            console.log(err)
            toastError()
          })
      } else {
        showToast && toastSuccess()
        dispatch(reportConfigurationActions.fetchFormViewById({ formViewId: id, silent: true }))
      }
      if (isNewFormView) {
        dispatch(replace(`/report-configuration-beta/${id}/${tab}`))
      }
      return id
    })
    .catch(() => {
      toastError()
    })
    .finally(() => {
      setSaving(false)
      rcFormViewDispatch(rcFormViewActions.setFormViewSchemaUpdateSaved())
      rcFormViewDispatch(rcFormViewActions.setUnedited())
      rcSidebarDispatch(rcSidebarActions.setUnedited())
      conditionsDispatch(conditionsActions.setUnedited())
    })
}

useImperativeHandle(ref, () => ({
  handleSave
}))

useEffect(() => {
  const isArchived = !_.isNil(archivedAt)
  if ((isNewFormView || isSidebarEdited || isFormViewEdited || isConditionsEdited || repeatableInstancesEdited) && !saving && !isArchived) {
    setButtonDisabled(false)
  } else {
    setButtonDisabled(true)
  }
}, [saving, isNewFormView, isSidebarEdited, isFormViewEdited, isConditionsEdited, repeatableInstancesEdited])
return (
  <Wrapper>
    <Button
      disabled={buttonDisabled}
      variant='contained'
      color='primary'
      onClick={handleSave}
    >
      {isNewFormView ? translations('Create') : translations('Save')}
      {saving && <CircularProgress size={23} thickness={2} />}
    </Button>
  </Wrapper>
)
}

export default forwardRef(SaveReportConfiguration)

const getFormSchemaMetaData = (formSchemaName) => {

  const formSchemaLibrary = new FormSchemaLibrary()
  return {
    schemaLibraryVersion: formSchemaLibrary.VERSION,
    schemaVersionJsonHash: formSchemaLibrary.getDefaultViewJsonHash(formSchemaName)
  }
}