import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Input } from '@material-ui/core'
import { Tabs, Tab } from '@material-ui/core'
import { FormHelperText } from '@material-ui/core'
import { translations } from '../../../../config'
import InputLabel from '../../../InputLabel'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import _ from 'lodash'
import style from './style'

const mapStateToProps = (state) => {
  return { state }
}
const enhancedComponent = compose(
  withStyles(style),
  connect(mapStateToProps),
  withHandlers({
    setField: ({ formName, dispatch }) => (value, field) => {
      dispatch(change(formName, field, value))
    },
  }),
  ImperialWrapper
)

function ImperialWrapper(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        selectedFormat: _.get(this.props.imperialToggle, 'initial.value'),
        imperialValue: {},
        metricValue: null,
        fieldError: null
      }
    }

    componentWillReceiveProps(nextProps) {
      const formValues = _.get(this.props.state, 'form.newForm.values')
      const nextFormValues = _.get(nextProps, 'state.form.newForm.values')

      const formatFieldName = _.get(
        this.props.imperialToggle,
        'formatFieldName'
      )
      const metricFieldName = _.get(
        this.props.imperialToggle,
        'metricFieldName'
      )
      const imperialFieldName = _.get(
        this.props.imperialToggle,
        'imperialFieldName'
      )

      const format = _.get(formValues, [formatFieldName])
      const nextFormat = _.get(nextFormValues, [formatFieldName])
      const metricField = _.get(formValues, [metricFieldName])
      const nextMetricField = _.get(nextFormValues, [metricFieldName])
      const imperialField = _.get(formValues, [imperialFieldName])
      const nextImperialField = _.get(nextFormValues, [imperialFieldName])

      if (nextFormat !== format) {
        this.setState({
          selectedFormat: nextFormat,
        })
      }
      if (nextMetricField !== metricField) {
        this.setState({
          metricValue: nextMetricField,
        })
      }
      if (nextImperialField !== imperialField) {
        this.setState({
          imperialValue: nextImperialField,
        })
      }
    }

    switchFormat = (label) => {
      const formatFieldName = _.get(
        this.props.imperialToggle,
        'formatFieldName'
      )
      this.setState({
        selectedFormat: label,
      })
      this.props.setField(label, formatFieldName)
    }

    updateMetric = (e) => {
      const value = e.target.value
      const metricFieldName = _.get(
        this.props.imperialToggle,
        'metricFieldName'
      )
      const imperialFieldName = _.get(
        this.props.imperialToggle,
        'imperialFieldName'
      )
      this.setState({
        metricValue: value,
        imperialValue: null,
      })
      this.props.setField(value, this.props.id)
      this.props.setField(value, metricFieldName)
      this.props.setField(null, imperialFieldName)
    }
    updateImperial = (e, label) => {
      const value = e.target.value
      const imperialFieldName = _.get(
        this.props.imperialToggle,
        'imperialFieldName'
      )
      const metricFieldName = _.get(
        this.props.imperialToggle,
        'metricFieldName'
      )
      this.setState({
        imperialValue: {
          ...this.state.imperialValue,
          [label]: value,
        },
        metricValue: null,
      })
      this.props.setField(
        { ...this.state.imperialValue, [label]: value },
        imperialFieldName
      )
      this.props.setField(null, metricFieldName)
    }

    imperialFormat = (format, selectedFormat, initialFormat) => {
      if (format) {
        return format !== _.get(initialFormat, 'value') ? true : false
      }
      if (selectedFormat) {
        return selectedFormat !== _.get(initialFormat, 'value') ? true : false
      }
    }

    render() {
      const { imperialToggle, classes, label, state, meta: { error } } = this.props
      const { selectedFormat, metricValue, imperialValue } = this.state
      const formValues = _.get(state, 'form.newForm.values')
      const formatFieldName = _.get(imperialToggle, 'formatFieldName')
      const metricFieldName = _.get(imperialToggle, 'metricFieldName')
      const imperialFieldName = _.get(imperialToggle, 'imperialFieldName')
      const format = _.get(formValues, [formatFieldName])
      const metricField = _.get(formValues, [metricFieldName])
      const imperialField = _.get(formValues, [imperialFieldName])
      const tabLabels = _.get(imperialToggle, 'tabLabels')
      const imperialOptions = _.get(imperialToggle, 'imperialOptions')
      const initialFormat = _.get(imperialToggle, 'initial')
      const newProps = _.omit(this.props, 'classes')

      if (tabLabels) {
        return (
          <Fragment>
            <InputLabel htmlFor={label} key='label' shrink={true}>
             {label}
            </InputLabel>
            <div className={classes.wrapperContainer}>
            {this.imperialFormat(format, selectedFormat, initialFormat) ? (
              <Fragment>
                {_.map(imperialOptions, (option) => (
                  <div className={classes.inputContainer}>
                    <Input
                      value={
                        _.get(imperialField, [option]) ||
                        _.get(imperialValue, [option])
                      }
                      onChange={(e) => this.updateImperial(e, option)}
                      placeholder={option}
                    />
                  </div>
                ))}
              </Fragment>
            ) : (
              <div className={classes.inputContainer}>
                <Input
                  value={metricField || metricValue}
                  onChange={(e) => this.updateMetric(e)}
                  placeholder={_.get(initialFormat, 'label')}
                />
              </div>
            )}
            <div className={classes.tabsContainer}>
              <Tabs
                value={format || selectedFormat}
                onChange={(e, val) => this.switchFormat(val)}
                centered
                fullWidth
                indicatorColor='primary'
              >
                {_.map(tabLabels, (label) => (
                  <Tab
                    classes={{
                       root: classes.tabRoot
                    }}
                    label={label}
                    value={label}
                  />
                ))}
              </Tabs>
            </div>
          </div>
          <FormHelperText className={classes.errorStyle} key='helper-text' role='alert'>{error}</FormHelperText>
        </Fragment>
        )
      }
      return <WrappedComponent {...newProps} />
    }
  }
}
export default enhancedComponent
