
const styles = () => {
  return {
    lists: {
      paddingTop: 10
    },
    checkContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 0 0 0'
    },
    checkIcon: {
      width: 44,
      height: 44
    },
    checkLabel: {
      paddingLeft: 10,
      fontSize: 16
    }
  }
}

export default styles
