import { connect } from 'react-redux'
import { compose, withProps, withHandlers, lifecycle } from 'recompose'

import SingleReportScreen from './SingleReportScreen'
import { actions as reportDetailsActions, selectors as reportDetailsSelectors } from '../../../../store/modules/reportDetails'
import _ from 'lodash'
import { getStandaloneReportTitle, noPermissionRoles } from '../../helpers'
import { selectors as authSelectors } from '../../../../store/modules/auth'

const mapStateToProps = (state) => {
  return {
    report: reportDetailsSelectors.getReport(state),
    userRole: authSelectors.getRoleValue(state)
  }
}

const enhancer = compose(
  connect(mapStateToProps),
  lifecycle({
    componentDidMount () {
      if (!noPermissionRoles.includes(this.props.userRole)) {
        const reportId = _.get(this.props, 'match.params.id')

        this.props.dispatch(reportDetailsActions.fetchReportById({
          id: reportId
        }))
      }
    }
  }),
  withProps((props) => {
    const { report, userRole } = props
    if (report) {
      return {
        pageTitle: getStandaloneReportTitle(report)
      }
    }
    return {
      noPermission: noPermissionRoles.includes(userRole)
    }
  }),
  withHandlers({
  })
)

export default enhancer(SingleReportScreen)
