import _ from 'lodash'
import { connect } from 'react-redux'
import { formValueSelector, change, touch } from 'redux-form'
import { compose, withHandlers } from 'recompose'

import { selectors as platformSelectors } from '../../../../../store/modules/platforms'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { selectors as organisationSelectors } from '../../../../../store/modules/organisations'
import { selectors as rolesSelectors } from '../../../../../store/modules/roles'
import { authCombinedSelectors } from '../../../../../store/modules/combinedSelectors'
import { userDetailCombinedSelector } from '../../../../../store/modules/combinedSelectors'

import Organisations from './Organisations'

const mapStateToProps = (state, ownProps) => {
  const getFormValue = formValueSelector(ownProps.formId)
  const selectedRoleId = getFormValue(state, 'roleId') || ''
  const selectedRole = rolesSelectors.getRoleById(selectedRoleId)(state)
  const formattedSelectedOrganisationNames = userDetailCombinedSelector.getSelectedUserOrganisationsOnCurrentPlatformFormatted(state)
  const isUserAdmin = authSelectors.getIsUserAdmin(state)
  const userOrgs = authSelectors.getUserOrganisationsOptions(state)
  const platformOrgs = platformSelectors.getCurrentPlatformOrganisationsOptions(state)
  const isRolesAndOrganisationsEditable = authCombinedSelectors.isCurrentUserAbleToEditRoleAndOrganisations(state)
  const isRolesAndOrganisationsViewable = authCombinedSelectors.isCurrentUserAbleToViewRoleAndOrganisations(state)
  return {
    selectedRole,
    organisationOptions: isUserAdmin ? platformOrgs : userOrgs,
    formattedSelectedOrganisationNames,
    isMultiSelector: selectedRole ? selectedRole.multipleOrganisations : undefined,
    isRolesAndOrganisationsEditable,
    isRolesAndOrganisationsViewable
  }
}

const enhance = compose(
  connect(mapStateToProps),
  withHandlers({
    handleChange: ({ dispatch, formId }) => () => {
      dispatch(change(formId, 'professionId', null))
      dispatch(touch(formId, 'organisationIds'))
      dispatch(touch(formId, 'professionId'))
    }
  })
)

export default enhance(Organisations)
