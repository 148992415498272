
const styles = theme => {
  const { subheaderHeight, bottomBarHeight } = theme
  return {
    container: {
      position: 'relative'
    },
    scollContainerInModal: {
      height: `calc(100vh - ${subheaderHeight + bottomBarHeight}px)`,
      overflowY: 'auto'
    },
    scrollContainer: {}
  }
}

export default styles
