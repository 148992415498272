import { translations } from '../../../../config'
import * as validators from '../../../../validators'

const getSchema = ({
  otherCohortNames
} = {}) => (
  [
    {
      id: 'name',
      field: 'Input',
      props: {
        label: translations('Name'),
        required: true,
        validate: [validators.valueBlocklist(otherCohortNames, translations('Name must be unique'), true /* ignore case */)]
      }
    },
    {
      id: 'description',
      field: 'Input',
      props: {
        label: translations('Description')
      }
    }
  ]
)

export default getSchema
