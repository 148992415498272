import { connect } from 'react-redux'
import _ from 'lodash'
import { isValid, getFormValues } from 'redux-form'
import { compose, withHandlers } from 'recompose'

import UserRegistrationForm, { formId } from './UserRegistrationForm'
import { actions as userDetailsActions } from '../../../../store/modules/userDetails'
import { selectors as roleSelectors } from '../../../../store/modules/roles'
import { selectors as organisationDetailsSelectors } from '../../../../store/modules/organisationDetails'
import { selectors as organisationInvitesSelector } from '../../../../store/modules/organisationInvites'

const mapStateToProps = (state, props) => {
  const isFormValid = isValid(formId)(state)

  const { roleId, organisationIds, telephone } = getFormValues(formId)(state) || {}
  let disabled = false
  const role = roleSelectors.getRoleById(roleId)(state)
  if (role && !role.isAdmin && organisationIds && organisationIds.length === 0) {
    disabled = true
  } else if (role && role.isAdmin) {
    disabled = false
  }

  const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(state)
  const organisationInvite = organisationInvitesSelector.getOrganisationInvite(state)
  
  return {
    isFormValid: isFormValid && disabled,
    organisationId,
    telephone,
    organisationInvite,
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, isPublic, organisationId, isOrganisationInvite, organisationInvite }) => (data) => {
      const modifiedData = _.omit(data, 'confirmPassword')
      if (isPublic) {
        modifiedData.isPublic = true
        modifiedData.organisationIds = [organisationId]
      }
      if(isOrganisationInvite && organisationInvite) {
        modifiedData.organisationIds = [organisationInvite.organisationId]
        modifiedData.accountTypes = [_.get(organisationInvite, 'userGroupId')]
        modifiedData.email = organisationInvite.email
        modifiedData.organisationInviteId = organisationInvite.id
      }
      if(isPublic) {
        return dispatch(userDetailsActions.registerUser(modifiedData))
      }
      return dispatch(userDetailsActions.createUser(modifiedData))    
    }
  })
)(UserRegistrationForm)
