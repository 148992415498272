const styles = theme => {
  return {
    buttonContainer: {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        marginRight: 10
      }
    },
    heading: {
      marginBottom: theme.spacing(2)
    }
  }
}

export default styles
