import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import NewsForm from './components/NewsForm'

const CreateNewsScreen = ({ classes, match, getPageTitle }) => {
  const articleId = match.params.id
  const pageTitle = getPageTitle(articleId ? 'Edit News Title' : 'Create News Title')
  return (
    <div className={classes.container}>
      <SubHeader
        centerContent={(
          <Fragment>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
        leftContent={(
          <BackBar />
        )}
      />
      <div className={classes.form}>
        <NewsForm articleId={articleId} />
      </div>
    </div>
  )
}

CreateNewsScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  match: PropTypes.object,
  getPageTitle: PropTypes.func.isRequired
}

export default withStyles(style)(CreateNewsScreen)
