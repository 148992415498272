import _ from 'lodash'
import {
  getOrganisationEmailTemplatesForSchema,
  getAllEntities,
  getSystemEntities
} from './selectors'
import { useShallowEqualSelector } from '../../../hooks'

export const useOrganisationEmailTemplates = () => {
  return useShallowEqualSelector(getOrganisationEmailTemplatesForSchema)
}

export const useIsConfigurable = (type, isSystemEmailTemplate) => {
  const allEntities = useShallowEqualSelector(getAllEntities)
  const typeEntity = _.find(_.values(allEntities), entity => entity.type === type)
  return isSystemEmailTemplate ? _.get(typeEntity, 'isConfigurableSystem') : _.get(typeEntity, 'isConfigurableOrganisation')
}

export const useIsSystemTemplate = (id) => {
  const systemEntities = useShallowEqualSelector(getSystemEntities)
  const ids = _.keys(systemEntities)
  return _.includes(ids, id)
}
