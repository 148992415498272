const style = (theme) => ({
  tooltip: {
    textTransform: 'none',
    fontWeight: 700,
    marginTop: 5,
    textAlign: 'center',
    '& strong, & span': {
      paddingLeft: 15,
      paddingRight: 15,
      display: 'block',
      fontWeight: 400,
      fontSize: 14
    },
    '& strong': {        
      fontWeight: 700,
      marginBottom: 4,
      fontSize: 11,
      textTransform: 'uppercase'
    }
  },
  tooltipIcon: {
    display: 'inline-block',    
    marginRight: 8,
    width: 20,
    height: 20,
    color: theme.palette.grey['600']
  }
})

export default style
