import { connect } from 'react-redux'
import { submit } from 'redux-form'
import _ from 'lodash'
import {
  compose,
  withHandlers,
  withPropsOnChange
} from 'recompose'

import modalService from '../../../../services/modalService'
import toastService from '../../../../services/toastService'
import { translations } from '../../../../config'
import ExportModal from './ExportModal'
import * as reports from '../../../../store/modules/reports'
import * as validators from '../../../../validators'
import { selectors as organisationDetailsSelectors } from '../../../../store/modules/organisationDetails'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { selectors as reportSelectors } from '../../../../store/modules/reports'
import { getColumnName } from '../../helpers'
import moment from 'moment'

const formId = reports.constants.EXPORT_REPORTS_FORM

const dateValidator = validators.isDateAfterOrEqual('startDate', 'Start Date')

const enhancer = compose(
  connect((state) => {
    const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(state)
    const columnMappings = platformSelectors.getReportManagementColumnMappings(state)
    const filters = reportSelectors.getFilters(state)
    const { typesOptions } = platformSelectors.getCurrentPlatformReportManagementFilters(state)

    const schema = [
      {
        id: 'reportForm',
        field: 'Dropdown',
        props: {
          label: getColumnName('Report Form', columnMappings),
          name: 'reportForm',
          options: typesOptions,
          required: true,
          multiple: true,
          validate: [
            validators.requiredMultiSelect,
          ],
        }
      },
      {
        id: 'startDate',
        field: 'Date',
        props: {
          label: translations('Start Date'),
          name: 'startDate',
          required: true,
        }
      },
      {
        id: 'endDate',
        field: 'Date',
        props: {
          label: translations('End Date'),
          name: 'endDate',
          required: true,
          validate: [dateValidator]
        }
      }]
    return {
      isSubmitting: reports.selectors.getIsfetchingReportsExport(state),
      organisationId,
      schema,
      initialValues: {
        reportForm: _.get(filters, 'reportForm') ? [_.get(filters, 'reportForm')] : null,
        startDate: moment(filters.dateFrom).format('YYYYMMDD'),
        endDate: _.get(filters, 'dateTo') ? moment(filters.dateTo).format('YYYYMMDD') : moment().format('YYYYMMDD')
      },
    }
  }),
  withHandlers({
    closeModal: () => () => {
      modalService.close()
    },
    onSubmit: ({ dispatch }) => () => {
      dispatch(submit(formId))
    },
    handleSubmit: (props) => async (formData) => {
      try {
        const { dispatch, organisationId } = props
        const filters = {
          organisationId: organisationId,
          dateFrom: formData.startDate,
          dateTo: formData.endDate,
          reportForm: formData.reportForm
        }

        await dispatch(reports.actions.exportFilteredReports({filters}))
      
        toastService.action({
          type: 'success',
          message: translations('Analytics Export - Processing Message'),
          autoHideDuration: 6000
        })

        modalService.close()
      } catch (error) {
        let errorTranslation = 'Failure - Export Reports'

        if (_.startsWith(error.message, 'Failure -')) {
          errorTranslation = error.message
        }

        if (_.get(error, 'code')) {
          errorTranslation = error.code
        }

        toastService.action({
          type: 'error',
          message: translations(errorTranslation),
          autoHideDuration: 6000
        })
      }
    }
  }),
  withPropsOnChange(['isSubmitting'], (props) => {
    const { onSubmit, closeModal, isSubmitting } = props
    return {
      actions: [
        {
          success: true,
          text: translations('Export'),
          submitting: isSubmitting,
          onClick: onSubmit,
          primary: true
        },
        {
          text: translations('Cancel'),
          onClick: closeModal
        }
      ]
    }
  })
)

export default enhancer(ExportModal)
