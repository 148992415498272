import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'

import { Input } from '../../../components/Fields'

import * as validators from '../../../validators'
import style from './style'
import Form, { FormBody, FormError, FormSubmit } from '../../../components/Form'
import { Radiolist } from '../../../components/Fields'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import { assets, translations } from '../../../config'
import * as schema from '../../Organisations/formSchemas/organisationDetailsSchema'

const CompanyAccountRequest = Form('company-account-request')

const emailFieldValidators = [validators.email, validators.required]

const textLengthValidators = [validators.maxLength(250, 'Field')]

const MedDraLicenceNumberValidator = [validators.maxNumericLength(5, 'Field'), validators.required]

const organisationLevel = [
  { label: translations('Correct Option For Organisation - Level 2'), value: '2' },
  { label: translations('Correct Option For Organisation - Level 1'), value: '1' }
]

const submissionTypeOptions = [
  { label: 'ICSR', value: 'ICSR' },
  { label: 'SUSAR', value: 'SUSAR' },
  { label: 'ICSR and SUSAR', value: 'ICSR and SUSAR' }
]

class CompanyAccountRequestWrapper extends Component {
  state = {
    submitted: false,
    showMhraCompanyNumber: false
  }

  renderSuccess = () => {
    const { classes } = this.props
    return (
      <div>
        <div className={classes.checkContainer}>
          <img className={classes.logo} src={assets.checkcircle} alt='' />
        </div>
        <div className={classes.header}>
          <Heading component='h1'>{translations('Request New Account - Success Heading')}</Heading>
        </div>
        <div className={classes.textContainer}>
          <P value={translations('Request New Account - Success Message')} />
        </div>
      </div>
    )
  }
  onSubmissionComplete = (params) => {
    const { isSabreRequestCompanyAccountRegistration, platformId } = this.props
    params.isSabreRequestCompanyAccountRegistration = isSabreRequestCompanyAccountRegistration
    params.platformId = platformId
    this.setState({ submitted: true })
    this.props.onSubmit(params)
  }
  changeOrganisationLevel = (e) => {
    if (e.target.value === '2') {
      this.setState({ showMhraCompanyNumber: true })
    } else {
      this.setState({ showMhraCompanyNumber: false })
    }
  }
  renderForm = () => {
    const { classes } = this.props
    return (
      <div>
        <CompanyAccountRequest onSubmit={this.onSubmissionComplete} submitLabel={translations('Submit')}>
          <FormError />
          <FormBody>
            <div className={classes.formBody}>
              <FormControl className={classes.field}>
                <Input
                  label={`${translations('First Name')} *`}
                  name='firstName'
                  className={classes.field}
                  validate={validators.required}
                />
              </FormControl>
              <FormControl className={classes.field}>
                <Input
                  label={`${translations('Last Name')} *`}
                  name='lastName'
                  className={classes.field}
                  validate={validators.required}
                />
              </FormControl>
              <FormControl className={classes.field}>
                <Input
                  label={`${translations('Email')} *`}
                  name={'email'}
                  validate={emailFieldValidators}
                  autoCapitalize='none'
                  autoComplete='off'
                  autoCorrect='off'
                  spellCheck='off'
                />
              </FormControl>
              <FormControl className={classes.field}>
                <Input
                  label={translations('Company Name')}
                  name='companyName'
                  className={classes.field}
                  validate={textLengthValidators}
                />
              </FormControl>
              <FormControl className={classes.field}>
                <Input
                  label={`${translations('MHRA Company Number')}`}
                  name='mhraCompanyNumber'
                  className={classes.field}
                  validate={textLengthValidators}
                />
              </FormControl>
              <FormControl className={classes.field}>
                <Input
                  label={translations('Login - Existing Sender')}
                  name='existing'
                  className={classes.field}
                  validate={textLengthValidators}
                />
              </FormControl>
              <FormControl className={classes.field}>
                <Radiolist
                  label={`${translations('Correct Option For Organisation')} *`}
                  options={organisationLevel}
                  name='organisationLevel'
                  validate={validators.required}
                  onChange={this.changeOrganisationLevel}
                />
              </FormControl>
              {this.state.showMhraCompanyNumber &&
                <FormControl className={classes.field}>
                  <Input
                    label={`${translations('MedDRA Licence Number')} *`}
                    name='meddraLicenceNumber'
                    className={classes.field}
                    validate={MedDraLicenceNumberValidator}
                  />
                </FormControl>
              }
              <FormControl className={classes.field}>
                <Radiolist
                  label={translations('Submission Type')}
                  options={submissionTypeOptions}
                  name='submissionType'
                />
              </FormControl>
            </div>
          </FormBody>
          <FormSubmit containerClass={classes.centerButtonContainer} label={translations('Submit')} buttonClass={classes.fullWidthButton} />
        </CompanyAccountRequest>
      </div>
    )
  }

  renderSabreCompanyAccountRegistrationForm = () => {
    const { classes, newOrgContactDetailsFormValues, initialValues, readOnly } = this.props
    const sabreRegistrationFormSchema = schema.sabreReportingOrganisationContactDetailsFormName(newOrgContactDetailsFormValues).schema
    return (
      <div>
        <CompanyAccountRequest
          initialValues={initialValues}
          editing={!readOnly}
          onSubmit={this.onSubmissionComplete}
          givenClass={classes.field}
        >
          <FormError />            
          <FormBody
            fullWidthFields
            schema={sabreRegistrationFormSchema}
          >
          </FormBody>
          {!readOnly && (
            <FormSubmit
              containerClass={classes.centerButtonContainer}
              label={translations('Submit')}
              buttonClass={classes.fullWidthButton}
            />
          )}
        </CompanyAccountRequest>
      </div>
    )
  }
  render() {
    const { isSabreRequestCompanyAccountRegistration } = this.props
    return this.state.submitted ? this.renderSuccess() : 
      isSabreRequestCompanyAccountRegistration ? this.renderSabreCompanyAccountRegistrationForm() : this.renderForm()
  }
}

CompanyAccountRequestWrapper.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func
}

export default withStyles(style)(CompanyAccountRequestWrapper)
