import _ from 'lodash'
import { mix } from 'polished'

import { colors } from '../../../config/theme'

import getAggregateData from './getAggregateData'
import { parseOrZero, getItemValue } from './helpers'

const getChartColor = (i, total) => {
  total = total <= 1 ? 2 : total
  const transparency = (i / (total - 1)) * 0.8
  const color = mix(transparency, '#fff', colors.primary)
  return color
}

const getChartData = props => {
  const { items, reportSchema } = props
  if (!(items && reportSchema)) return
  const { data } = reportSchema
  const { aggregateFrom, entries } = data


  if (_.size(entries)) {
    const data = _.first(items)
    return _.map(entries, (entry, i) => {
      return { name: entry.label, value: _.get(data, entry.path), color: getChartColor(i, entries.length)}
    })

  }

  if (aggregateFrom) {
    const values = getAggregateData(props)

    return _.chain(values)
      .keys()
      .filter(key => key !== '__TARGET')
      .map((key, i, keys) => {
        return {
          name: key,
          value: values[key],
          color: getChartColor(i, keys.length)
        }
      })
      .value()
  }
}

export default getChartData
