"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.visibleIfisNotDistributingInAllDeviceDistributionCountries = exports.visibleIfIsNomenclatureSystemOther = exports.visibleIfIsNomenclatureSystemGMDN = exports.visibleIfFscaAttachmentFSNTypeHasOther = exports.reportTypeMatch = exports.isReportTypeNotAFollowUpOrFinal = exports.isNotDistributingInAllDeviceDistributionCountries = exports.isNomenclatureSystemOther = exports.isNomenclatureSystemGMDN = exports.isAuthorisedRep = exports.doesFscaAttachmentFsnTypeIncludeOther = exports.clearIfisNotDistributingInAllDeviceDistributionCountries = exports.clearIfIsNotNomenclatureSystemGMDN = exports.clearIfIsNomenclatureSystemOther = exports.clearIfFscaAttachmentFSNTypeDoesNoHasOther = exports.areNoDistributionOptionsSelected = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var computedValue = _interopRequireWildcard(require("../../../../../../formHelpers/computedValue"));

var computedHidden = _interopRequireWildcard(require("../../../../../../formHelpers/computedHidden"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var reportTypeMatch = computedValue.match({
  INITIAL: computedHidden.fieldIs('reportType', 'Initial'),
  FOLLOW_UP: computedHidden.fieldIs('reportType', 'Follow Up'),
  FINAL: computedHidden.fieldIs('reportType', 'Final'),
  DEFAULT: computedValue.always(true)
});
exports.reportTypeMatch = reportTypeMatch;
var doesFscaAttachmentFsnTypeIncludeOther = computedHidden.selectedInMultipleOption('fscaAttachmentFSNType', 'fscaAttachmentFSNOther');
exports.doesFscaAttachmentFsnTypeIncludeOther = doesFscaAttachmentFsnTypeIncludeOther;
var visibleIfFscaAttachmentFSNTypeHasOther = computedHidden.visibleIf(doesFscaAttachmentFsnTypeIncludeOther);
exports.visibleIfFscaAttachmentFSNTypeHasOther = visibleIfFscaAttachmentFSNTypeHasOther;
var clearIfFscaAttachmentFSNTypeDoesNoHasOther = computedValue.clearIfNot(doesFscaAttachmentFsnTypeIncludeOther);
exports.clearIfFscaAttachmentFSNTypeDoesNoHasOther = clearIfFscaAttachmentFSNTypeDoesNoHasOther;
var isAuthorisedRep = computedHidden.fieldIs('statusReporter', 'Authorised representative');
exports.isAuthorisedRep = isAuthorisedRep;
var isNotDistributingInAllDeviceDistributionCountries = computedHidden.fieldIsFalsey('distribution_all');
exports.isNotDistributingInAllDeviceDistributionCountries = isNotDistributingInAllDeviceDistributionCountries;
var visibleIfisNotDistributingInAllDeviceDistributionCountries = computedHidden.visibleIf(isNotDistributingInAllDeviceDistributionCountries);
exports.visibleIfisNotDistributingInAllDeviceDistributionCountries = visibleIfisNotDistributingInAllDeviceDistributionCountries;
var clearIfisNotDistributingInAllDeviceDistributionCountries = computedValue.clearIfNot(isNotDistributingInAllDeviceDistributionCountries);
exports.clearIfisNotDistributingInAllDeviceDistributionCountries = clearIfisNotDistributingInAllDeviceDistributionCountries;
var isNomenclatureSystemOther = computedHidden.fieldIs('nomenclatureSystem', 'Other');
exports.isNomenclatureSystemOther = isNomenclatureSystemOther;
var visibleIfIsNomenclatureSystemOther = computedHidden.visibleIf(isNomenclatureSystemOther);
exports.visibleIfIsNomenclatureSystemOther = visibleIfIsNomenclatureSystemOther;
var clearIfIsNomenclatureSystemOther = computedValue.clearIfNot(isNomenclatureSystemOther);
exports.clearIfIsNomenclatureSystemOther = clearIfIsNomenclatureSystemOther;
var isNomenclatureSystemGMDN = computedHidden.fieldIs('nomenclatureSystem', 'GMDN');
exports.isNomenclatureSystemGMDN = isNomenclatureSystemGMDN;
var visibleIfIsNomenclatureSystemGMDN = computedHidden.visibleIf(isNomenclatureSystemGMDN);
exports.visibleIfIsNomenclatureSystemGMDN = visibleIfIsNomenclatureSystemGMDN;
var clearIfIsNotNomenclatureSystemGMDN = computedValue.clearIfNot(isNomenclatureSystemGMDN);
exports.clearIfIsNotNomenclatureSystemGMDN = clearIfIsNotNomenclatureSystemGMDN;

var areNoDistributionOptionsSelected = function areNoDistributionOptionsSelected(values) {
  return !(!_.isNil(values['distributionEEA']) || !_.isNil(values['distributionOutsideEU']) || !_.isNil(values['distribution_all']) && values['distribution_all']);
};

exports.areNoDistributionOptionsSelected = areNoDistributionOptionsSelected;

var isReportTypeNotAFollowUpOrFinal = function isReportTypeNotAFollowUpOrFinal(values) {
  return _fp["default"].compose(function (reportType) {
    return !['Follow Up', 'Final'].includes(reportType);
  }, _fp["default"].get('reportType'))(values);
};

exports.isReportTypeNotAFollowUpOrFinal = isReportTypeNotAFollowUpOrFinal;