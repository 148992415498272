import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import SubmissionReportsSearchInput from './SubmissionReportsSearchInput'
import { actions as submissionReportsActions, selectors as submissionReportsSelectors } from '../../../../store/modules/submissionReports'
import { addQuery } from '../../../../helpers/query'

const mapStateToProps = state => {
  return {
    query: submissionReportsSelectors.getQuery(state),
    filters: submissionReportsSelectors.getFilters(state),
    savedSearches: submissionReportsSelectors.getSavedSearches(state),
    activeSavedSearchId: submissionReportsSelectors.getActiveSavedSearchId(state),
    activeSavedSearch: submissionReportsSelectors.getActiveSavedSearch(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    changeQuery: ({ dispatch, filters }) => ({ query }) => {
      const newFilters = {
        ...filters,
        query
      }
      dispatch(submissionReportsActions.changeFilters({ filters: newFilters }))
    },
    search: ({ query, dispatch }) => () => {
      dispatch(submissionReportsActions.searchFresh())
    },
    setSavedSearch: ({ dispatch }) => ({ id }) => {
      dispatch(submissionReportsActions.setSavedSearch({ id }))
        .then(() => {
          dispatch(submissionReportsActions.searchFresh())
        })
    },
    createNewSavedSearch: ({ dispatch }) => (params) => {
      dispatch(submissionReportsActions.createNewSavedSearch(params))
    },
    updateSavedSearch: ({ dispatch }) => (params) => {
      dispatch(submissionReportsActions.updateSavedSearch(params))
    },
    deleteSavedSearch: ({ dispatch, activeSavedSearchId }) => (id) => {
      dispatch(submissionReportsActions.deleteSavedSearch(id))
        .then(() => {
          if (id === activeSavedSearchId) {
            dispatch(submissionReportsActions.searchFresh())
          }
        })
    },
    discardFilters: ({ dispatch }) => () => {
      dispatch(submissionReportsActions.discardFilters())
        .then(() => {
          dispatch(submissionReportsActions.searchFresh())
        })
    }
  })
)(SubmissionReportsSearchInput)
