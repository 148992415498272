import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ContentBox from '../../../ContentBox'
import ContentBoxHeader from '../../../ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../ContentBox/ContentBoxBody'
import TextContent from '../../TextContent'
import Heading from '../../../Heading'
import { translations } from '../../../../config'

import FormBody from '../../FormBody'
import style from './style'

const CommunicationPreferences = props => {
  const {
    classes,
    telephone
  } = props

  const schema = [
  {
    id: 'emailCommunicationsPermitted',
    field: 'Checkbox',
    props: {
      label: translations('Email'),
      name: 'emailCommunicationsPermitted',
      required: false
    }
  },
  ]
  if (telephone){
    schema.push(
      {
        id: 'smsCommunicationsPermitted',
        field: 'Checkbox',
        props: {
          label: translations('SMS'),
          name: 'smsCommunicationsPermitted',
          required: false
        }
      },
    )
  }
  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading className={classes.contentBoxHeader}>
            {translations('Communications Preference Form Section')}
          </Heading>
        </TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <FormBody
          schema={schema}
          editing
        />
      </ContentBoxBody>
    </ContentBox>
  )
}

CommunicationPreferences.propTypes = {
  schema: PropTypes.object,
  classes: PropTypes.object
}

export default withStyles(style)(CommunicationPreferences)
