import React from 'react'
import { FormControl } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Input } from '../../../components/Fields'
import * as validators from '../../../validators'
import { translations, passwordRequirements } from '../../../config'
import Column from '../../Form/Column'
import Row from '../../Form/Row'
import List from '../../../components/Form/FormSections/List'

import style from './style'

const PasswordConfirm = ({classes}) => (
  <div>
    <Row>
      <Column>
        <FormControl className={classes.field}>
          <Input
            label={translations('Mandatory Current Password')}
            name='currentPassword'
            type='password'
            validate={[validators.password]}
            shrink
          />
        </FormControl>
      </Column>
      <Column>
        <FormControl className={classes.field}>
          <Input
            label={translations('Mandatory New Password')}
            name='password'
            type='password'
            validate={[validators.password]}
            shrink
          />
        </FormControl>
        <FormControl className={classes.field}>
          <Input
            label={translations('Mandatory Confirm New Password')}
            name='confirmPassword'
            type='password'
            validate={[validators.passwordConfirmation]}
            shrink
            />
        </FormControl>
      </Column>
    </Row>
    <Row>
      <Column>
        <div className={classes.rowNoMargin}>
          <List listTitle={translations('Password Requirements Title')} items={passwordRequirements} />
        </div>
      </Column>
    </Row>
  </div>
)

export default withStyles(style)(PasswordConfirm)
