import { colors } from '../../../config/theme'

const styles = {
  footer: {
    backgroundColor: colors.lightGrey
  },
  footerMenuButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 9,
    width: '100%'
  },
  logoutButton: {
    padding: 9,
    width: '100%'
  },
  accountButton: {
    backgroundColor: 'white',
    border: '1px solid black',
    width: '100%',
    margin: '10px 0'
  },
  organisationButton: {
    marginBottom: 0
  }
}

export default theme => styles
