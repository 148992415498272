import Role from './Role'
import { connect } from 'react-redux'
import { formValueSelector, change, touch } from 'redux-form'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'

import { authCombinedSelectors } from '../../../../../store/modules/combinedSelectors'
import { selectors as roleSelectors } from '../../../../../store/modules/roles'
import { stringManipulation } from '../../../../../helpers'
import { translations } from '../../../../../config'

const mapStateToProps = (state, ownProps) => {
  const selectedRoleId = formValueSelector(ownProps.formId)(state, 'roleId')
  const selectedAccountType = formValueSelector(ownProps.formId)(state, 'accountType')

  const selectedRole = roleSelectors.getRoleById(selectedRoleId)(state)
  const isPublicRole = !!_.get(selectedRole, 'isPublic')

  const formattedRoleName = selectedRole && translations(`Role - ${selectedRole.name}`)

  const availableRoleNamesForUserOptions = authCombinedSelectors.getAvailableRoleNameOptionsForUser(state)
  const availableRoleNamesWithoutSuperAdmin = availableRoleNamesForUserOptions 
    && availableRoleNamesForUserOptions.filter(roleOption => roleOption.name !== 'Super_admin')

  const isRolesAndOrganisationsEditable = authCombinedSelectors.isCurrentUserAbleToEditRoleAndOrganisations(state)
  const isRolesAndOrganisationsViewable = authCombinedSelectors.isCurrentUserAbleToViewRoleAndOrganisations(state)

  return {
    selectedRole,
    isPublicRole,
    formattedRoleName,
    roleOptions: availableRoleNamesWithoutSuperAdmin,
    isRolesAndOrganisationsEditable,
    isRolesAndOrganisationsViewable,
    selectedAccountType
  }
}

const enhance = compose(
  connect(mapStateToProps),
  withHandlers({
    handleChange: ({ dispatch, formId }) => () => {
      dispatch(change(formId, 'organisationIds', []))
      dispatch(change(formId, 'professionId', null))
      dispatch(touch(formId, 'roleId'))
      dispatch(touch(formId, 'organisationIds'))
      dispatch(touch(formId, 'professionId'))
    }
  })
)

export default enhance(Role)
