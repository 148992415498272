import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translations } from '../../config'
import { withStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import SubHeader from '../SubHeader'
import LookupInputModalSearch from './LookInputModalSearch'
import LookupInputModalTable from './LookupInputModalTable'
import styles from './style'

class LookupInputModal extends Component {
  state = {
    query: null
  }

  updateQuery = ({ query }) => {
    const { onSearch } = this.props
    this.setState({ query })
    onSearch({ query })
  }

  searchNext = () => {
    const { onSearchNext } = this.props
    const { query } = this.state
    onSearchNext({ query })
  }

  componentDidMount = () => {
    const { onSearch } = this.props
    onSearch({ query: null })
  }

  render () {
    const { classes, onClose, error, noResults, hasMore, results, onChange, searchPlaceholder } = this.props
    return (
      <div className={classes.container}>
        <SubHeader
          className={classes.subHeader}
          notFixed
          centerContent={(
            <LookupInputModalSearch
              searchPlaceholder={searchPlaceholder}
              onSearch={this.updateQuery} />
          )}
          rightContent={(
            <div>
              <IconButton aria-label={translations('Close')} onClick={() => onClose()}><CloseIcon /></IconButton>
            </div>
          )}
        />
        <div className={classes.scrollContainer}>
          <LookupInputModalTable
            error={error}
            hasSearched={!!this.state.query}
            hasMore={hasMore}
            results={results}
            onResultClick={onChange}
            onSearchNext={this.searchNext}
            noResults={noResults}
          />
        </div>
      </div>
    )
  }
}
LookupInputModal.propTypes = {
  classes: PropTypes.object,
  searchPlaceholder: PropTypes.string,
  onSearchNext: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string,
  noResults: PropTypes.bool,
  hasSearched: PropTypes.bool,
  hasMore: PropTypes.bool,
  results: PropTypes.array,
  onChange: PropTypes.func.isRequired
}

export default withStyles(styles)(LookupInputModal)
