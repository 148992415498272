import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from '@reach/combobox'
import { translation } from '../../services/translations'
import _ from 'lodash'
import FieldMeta from '../FieldMeta'
import Field from '../Field'
import Spinner from '../Spinner'
import NullFlavourField from '../NullFlavourField'

import { useSdk } from '../../services/sdk/hooks'
import { useDebounce } from '../../services/utilities'
import { StyledInput } from '../TextInput'
import { StyledSelectBox } from '../SelectBox/SelectBox'

export const StyledComboboxOption = styled(ComboboxOption)`
  padding: 8px;
`

const OptionName = styled.p`
  font-size: 16px;
  margin: 0;
`

const OptionDetails = styled.p`
  font-size: 14px;
  margin: 0;
`

const P = styled.p`
  margin: 8px;
`

const Input = styled(StyledInput)`
  ${props => props.disabled && `> div, label {
    background-color: #FAFAFA;
    user-select: none;
    pointer-events: none;
  }`}
`

const StyledCombobox = styled(Combobox)`
  position: relative;
  display: flex;
  align-items: center;
`

export const SearchingSpinnerWrapper = styled.div`
  position: absolute;
  width: 100%; 
  height: 0;
  display: flex;
  justify-content: end;
  padding: 0 10px;
`

export const SearchingSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
  margin-top: -10px;
`

/**
 * AutoComplete
 */

const AutoComplete = (props) => {
  const {
    input: {
      name,
      value,
      onChange,
      onFocus,
      onBlur
    } = {},
    meta: {
      error,
      touched
    } = {},
    id,
    label,
    hint,
    required,
    searchType,
    lookupOptions,
    showInitially,
    detailsList,
    handleChangeOtherFields,
    nullFlavours,
    disabled,
    isMulti,
    restrictInput,
    formatResults,
    displayResults,
    lookupOptionsRequired
  } = props

  const sdk = useSdk()
  const [searchTerm, setSearchTerm] = useState('')
  const [results, setResults] = useState([])
  const [fallbackresults, setFallbackResults] = useState([])
  const [searchError, setError] = useState()
  const [isSearching, setIsSearching] = useState(false)
  const fieldInvalid = error && touched
  const errorMessage = fieldInvalid ? error : undefined
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [matchedResult, setResultsMatch] = useState()
  const hasNullFlavours = nullFlavours && !_.isEmpty(nullFlavours)
  const [multiSelectedOption, setMultiSelectedOption] = useState()

  const parseResults = (res) => {
    const rows = _.get(res, 'rows') || res
    return _.isArray(rows) ? rows : []
  }

  const search = useCallback(async ({ query, size, page, lookupOptions }) => {
    if (lookupOptionsRequired && !lookupOptions) {
      return
    }
    setIsSearching(true)

    try {
      const searchResults = await sdk[searchType]({
        query,
        size,
        page,
        lookupOptions
      })
      setIsSearching(false)

      const results = parseResults(searchResults)
      setFallbackResults(results)
      setResults(results)
    } catch (e) {
      setIsSearching(false)
      setError(e.message)
    }
  }, [lookupOptionsRequired])

  useEffect(() => {
    if (isMulti && value) {
      const optionValue = _.map(value, val => ({ label: val, value: val }))
      setMultiSelectedOption(optionValue)
    } else if (restrictInput && value) {
      const label = displayResults ? displayResults(value) : value
      setMultiSelectedOption({ label, value })
    }
  }, [value, touched])

  useEffect(() => {
    if (_.isFunction(handleChangeOtherFields)) {
      handleChangeOtherFields(matchedResult)
    }
  }, [matchedResult])

  useEffect(() => {
    if (showInitially) {
      search({ query: '', size: null, page: null, lookupOptions })
    }
  }, [showInitially, _.get(lookupOptions, 'listName')])

  useEffect(() => {
    const meetsMinSearchTermLength = _.size(debouncedSearchTerm) >= 3

    if (_.size(debouncedSearchTerm) === 0 && _.size(fallbackresults) > 0) {
      setResults(fallbackresults)
    } else if (searchType && (debouncedSearchTerm && meetsMinSearchTermLength)) {
      search({ query: debouncedSearchTerm, lookupOptions })
    } else {
      setResults([])
    }
  },
    [debouncedSearchTerm]
  )

  const handleChangeMulti = useCallback((event) => {
    const value = _.isArray(event) ? _.map(event, (option) => option.value) : _.get(event, 'value')
    if (_.isFunction(handleChangeOtherFields)) {
      handleChangeOtherFields(value)
    }
    setMultiSelectedOption(event)
    onChange(value)
    onBlur(value)
  }, [isMulti])

  const handleSearchTermChange = (event) => {
    const eventValue = event.target.value
    setSearchTerm(eventValue)
    if (onChange) {
      onChange(eventValue)
    }
    if (!_.isNull(matchedResult)) {
      setResultsMatch(null)
    }
  }

  const handleSelect = (value) => {
    onChange(value)
    setResultsMatch(_.find(results, { name: value }))
  }

  const multiSelectOptions = useMemo(() => {
      return _.map(results, result => {
        const value = formatResults ? formatResults(result) : result.name
        const label = displayResults ? displayResults(result) : result.name

        return {
          label,
          value
        }
      })
  }, [isMulti, restrictInput, results])


  const renderADRSearch = () => {
    return (
      <Field error={errorMessage}>
        <FieldMeta name={name} label={label} error={errorMessage} hint={hint} required={required} disabled={disabled}/>
        <StyledCombobox
          aria-label={'autoselect'}
          openOnFocus={true}
          onSelect={handleSelect}
          disabled={disabled}
        >
          {isMulti || restrictInput
            ? (
              <StyledSelectBox
                value={multiSelectedOption}
                options={multiSelectOptions}
                inputId={id}
                classNamePrefix={'mhraUISelect'}
                error={errorMessage}
                isMulti={isMulti}
                aria-required={required}
                aria-invalid={fieldInvalid}
                placeholder={translation('Please select')}
                onChange={handleChangeMulti}
                isOptionDisabled={true}
                disabled={disabled}
                isDisabled={disabled}
              />
            ) :
            <Input
              as={ComboboxInput}
              id={id}
              name={name}
              value={value}
              onChange={handleSearchTermChange}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={disabled}
            />
          }

          {isSearching && <SearchingSpinnerWrapper><SearchingSpinner color={'black'} /></SearchingSpinnerWrapper>}
          {results && (
            <ComboboxPopover style={{ maxHeight: '200px', overflow: 'auto' }} className={'mhraUI'}>
              {!searchTerm && <P>{translation('Begin typing to start your search')}</P>}
              <ComboboxList>
                {_.size(results) > 0 &&
                  mapResultsToOptions(results)
                }
              </ComboboxList>
              {debouncedSearchTerm && !isSearching && !searchError && (!_.size(results) || _.size(results) === 0) && <P>No results found</P>}
              {debouncedSearchTerm && searchError && <P>{searchError}</P>}
            </ComboboxPopover>
          )}
        </StyledCombobox>
      </Field>
    )
  }

  const mapResultsToOptions = (results) => {
    const options = _.map(results, item => {
      const name = formatResults ? formatResults(item) : item.name
      const key = item.id || item.name
      const details = _.join(_.compact(_.map(detailsList, detail => _.get(item, detail))), ', ')
      return (
        <StyledComboboxOption key={key} value={name}>
          <OptionName>
            {name}
          </OptionName>
          <OptionDetails>
            {details}
          </OptionDetails>
        </StyledComboboxOption>
      )
    })
    return options
  }

  if (hasNullFlavours) {
    return (
      <NullFlavourField
        {...props}
        render={renderADRSearch}
      />
    )
  }

  return (renderADRSearch())
}

AutoComplete.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** The type of search you want to perform */
  type: PropTypes.oneOf(['drugSearch', 'medDRASearch', 'hospitalSearch', 'terminologySearch']),
  /** Input props based from React Final Form
   *
   * `name` - Used to associate label and input
   *
   * `value` - Value of the input
   *
   * `type` - Native HTML input type
   *
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   *
   * `onFocus` - Function called when focus has been given to the field
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  /** Meta props based from React Final Form
   *
   * `error` - Field validation message
   *
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
}

export default AutoComplete
