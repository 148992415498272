import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import Button from '../Button'
import style from './style'

const TopRightButton = ({ children, classes, className, ...props }) => {
  const buttonClasses = cx(
    classes.topRightButton,
    {
      [className]: className
    }
  )
  return (
    <Button
      {...props}
      raised
      color='primary'
      className={buttonClasses}
    >
      {children}
    </Button>
  )
}

export default withStyles(style)(TopRightButton)