import textContentStyles from '../Form/TextContent/style'

const styles = theme => ({
  editButton: {
    width: '80%',
    [theme.breakpoints.up('sm')]: {
      width: '120px'
    }
  },
  editLeftButtonGridItem: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start'
    },
    alignItems: 'center'
  },
  editRightButtonGridItem: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    },
    alignItems: 'center'
  },
  contentContainer: {
    padding: '30px 20px',
    [theme.breakpoints.up('sm')]: {
      padding: '30px 40px'
    },
    display: 'inherit'
  },
  cancelButton: {
    width: '80%',
    [theme.breakpoints.up('sm')]: {
      width: '120px'
    },
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid',
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  formContentBoxHeader: {
    fontSize: '19px',
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0 5px'
    }
  }
})

export default theme => ({
  ...textContentStyles,
  ...styles(theme)
})
