
import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { change } from 'redux-form'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

import { FormBody } from '../../components/Form'
import { translations } from '../../config'

const AbstractPagination = (props) => {
  const {
    id,
    givenClass,
    columnStyle,
    rowStyle,
    editable,
    label,
    Form,
    formId,
    fetch,
    update,
    submitDetails = {},
    data,
    dataCount,
    clickable,
    rowsPerPageOptions,
    hasAssigned = true,
    includeUnassigned = true,
    enableSearch = true,
    headers,
    parseData,
    path,
    limit = 100,
    enablePagination = true,
    groupOptionsBy,
    collapsible,
    initialQuery = ''
  } = props

  const dispatch = useDispatch()
  const [loadedData, setLoadedData] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState(initialQuery)

  const loadData = useCallback(({ limit, offset, fresh }) => {
    const count = limit + offset
    const loaded = _.size(loadedData)
    if (count > loaded && loaded < dataCount || fresh) {
      setLoading(true)
      fetch({ query: searchQuery, limit, offset })
    }
  }, [loadedData, setLoading, fetch])

  const onInputChange = useCallback((e) => {
    setSearchQuery(e.target.value)
  }, [setSearchQuery])

  const onOptionClick = useCallback((id) => {
    dispatch(push(`/${path}/${id}`))
  }, [dispatch])

  useEffect(() => {
    if (loading && !_.isEmpty(data)) {
      if(parseData && _.isFunction(parseData)) {
        const parsedData = parseData(data, includeUnassigned)
        setLoadedData([...loadedData, ...parsedData])
      } else {
        setLoadedData([...loadedData, ...data])
      }
      setLoading(false)
    }
  }, [data, loading, dispatch])

  useEffect(() => {
    if(hasAssigned) {
      const sortedAlphabeticallyData = _.sortBy(loadedData, 'results')
      const sortedAssignedData = sortedAlphabeticallyData.sort(
        (a, b) => Number(b.assigned) - Number(a.assigned))
      dispatch(change(formId, id, sortedAssignedData))
    } else {
      dispatch(change(formId, id, loadedData))
    }
  }, [loadedData, dispatch])

  useEffect(() => {
    setLoadedData([])
    loadData({ limit, offset: 0, fresh: true })
  }, [searchQuery, dispatch])

  const schema = [
    {
      id: id,
      field: 'TableList',
      props: {
        name: id,
        required: false,
        headers,
        count: dataCount,
        onPageChange: loadData,
        onRowsPerPageChange: loadData,
        onInputChange: onInputChange,
        onOptionClick: clickable ? onOptionClick : null,
        query: searchQuery,
        enableSearch,
        rowsPerPageOptions: rowsPerPageOptions,
        defaultRowsPerPage: 10,
        enablePagination,
        groupOptionsBy,
        isLoading: loading
      }
    }
  ]

  const handleSubmit = async (params) => {
    const localParams = { ...params, ...submitDetails }
    const data = localParams[id]
    const difference = _.difference(data, loadedData)

    localParams.data = {}

    const assigned = _.filter(difference, datum => datum.assigned)
    localParams.data.assigned = _.map(assigned, 'id')

    const unassigned = _.filter(difference, datum => !datum.assigned)
    localParams.data.unassigned = _.map(unassigned, 'id')

    return update(localParams)
  }

  const handleCancel = () => {
    setSearchQuery('')
    setLoadedData([])
    loadData({ limit, offset: 0, fresh: true })
  }

  return (
    <Form
      editable={editable}
      givenClass={givenClass}
      boxName={translations(label)}
      formId={formId}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      collapsible={collapsible}
    >
      <FormBody
        schema={schema}
        globalClasses={{
          col: columnStyle,
          row: rowStyle,
        }}
        layout={[`${id}:12`]}
      />
    </Form>
  )
}

export default AbstractPagination