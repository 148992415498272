import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers } from 'recompose'

import ReportDetailsScreen from './ReportDetailsScreen'

import { translations } from '../../../config'

const mapStateToProps = state => {
  const isLoading = false

  return {
    isLoading
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({

  })
)(ReportDetailsScreen)
