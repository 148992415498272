import _ from 'lodash'
import { createSelector } from 'reselect'

import { PENDING, SUCCESS } from '../../middleware/redux-promise'

export const getIsLoading = state => state.users.status === PENDING
export const getResults = state => _.get(state.users, 'results', [])
export const getHasMore = state => state.users.total != null &&
state.users.results.length < state.users.total
export const getUsersHasBeenSearched = state => {
  return (
    state.users.query ||
    !_.isEmpty(state.users.roleIds) ||
    !_.isEmpty(state.users.organisationIds) ||
    state.users.status === SUCCESS
  )
}
export const getError = state => state.users.error
export const getIsInitial = state => !state.users.status
export const getQuery = state => state.users.query
export const getSize = state => state.users.size
export const getPage = state => state.users.page

export const getRoleIds = state => state.users.roleIds
export const getOrganisationIds = state => state.users.organisationIds
export const getIncludeDeactivated = state => state.users.includeDeactivated
export const getChangeRequestStatuses = state => state.users.changeRequestStatuses
export const getAccountTypes = state => state.users.accountTypes

export const getNumberOfActiveFilters = createSelector(
  [getRoleIds, getOrganisationIds, getIncludeDeactivated],
  (roleIds, organisationIds, changeRequestStatuses, includeDeactivated) => {
    let total = 0
    if (roleIds && roleIds.length) total++
    if (organisationIds && organisationIds.length) total++
    if (changeRequestStatuses && changeRequestStatuses.length) total++
    if (includeDeactivated) total++
    return total
  }
)
