import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormBody } from '../../../../../components/Form'
import ContentBox from '../../../../../components/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../../components/Form/TextContent'
import RightButtonContent from '../../../../../components/Form/RightButtonContent'
import ToggleIconButton from '../../../../../components/ToggleIconButton'
import Heading from '../../../../../components/Heading'
import style from './style'
import { translations } from '../../../../../config'

const FormSection = (props) => {
  const {
    id,
    title,
    classes,
    initialOpen,
    schema,
    layout,
    editing,
    initialValues,
    firstSection
  } = props
  const [isOpen, setIsOpen] = useState(initialOpen)

  const contentId = `${id}Content`
  const controlId = `${id}Controls`
  
  useEffect(() => {
    if (firstSection === id) {
      setIsOpen(true)
    }
  }, [firstSection])

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ContentBox id={id} contentId={contentId} hidden={!isOpen}>
      <ContentBoxHeader xsLeft={2} xsText={8} xsRight={2}>
        <TextContent>
          <Heading id={id}>{translations(title)}</Heading>
        </TextContent>
        <RightButtonContent>
          <div className={classes.rightButtonContent}>
            <ToggleIconButton
              id={controlId}
              targetId={contentId}
              onClick={toggleOpen}
              active={isOpen}
            />
          </div>
        </RightButtonContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <FormBody
          initialValues={initialValues}
          schema={schema}
          layout={layout}
          editing={editing}
          hidden={!isOpen}
        />
      </ContentBoxBody>
    </ContentBox>
  )
}

FormSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  schema: PropTypes.array.isRequired,
  layout: PropTypes.array,
  classes: PropTypes.shape({}).isRequired,
  editing: PropTypes.bool,
  initialValues: PropTypes.object,
  initialOpen: PropTypes.bool
}

export default withStyles(style)(FormSection)
