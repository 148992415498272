require('document.contains/auto');

import VigilanceHubForm from './components/VigilanceHubForm'
import StandardForm from './components/StandardForm'
import LoginForm from './components/StandardForm/LoginForm'
import RegistrationForm from './components/StandardForm/RegistrationForm'
import ResetPasswordForm from './components/StandardForm/ResetPasswordForm'
import UpdateUserForm from './components/StandardForm/UpdateUserForm'
import UpdatePasswordForm from './components/StandardForm/UpdatePasswordForm'
import AboutYouForm from './components/StandardForm/AboutYouForm'
import ReportSubmissionGuideForm from './components/StandardForm/ReportSubmissionGuideForm'

export default VigilanceHubForm

export { StandardForm, LoginForm, RegistrationForm, ResetPasswordForm, UpdateUserForm, UpdatePasswordForm, AboutYouForm, ReportSubmissionGuideForm } 