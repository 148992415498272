import _ from 'lodash'

const withMultipleProviders = (...providers) => {
  return (Component) => {
    return (props) =>
      _.reduceRight(
        providers,
        (acc, Provider) => {
          return <Provider>{acc}</Provider>
        }, <Component {...props} />
      )
  }
}

export default withMultipleProviders
