import _ from 'lodash'
import { PENDING } from '../../middleware/redux-promise'
import * as constants from './constants'

export const getNotificationsSize = state => state.notifications.size
export const getNotificationsPage = state => state.notifications.page
export const getNotifications = state => _.get(state.notifications, 'results', [])
export const getNotificationsIsLoadingInitial = state => _.get(state.notifications, 'status') === PENDING && _.get(state.notifications, 'action') === constants.FETCH_PERSONAL_NOTIFICATIONS_FRESH
export const getNotificationsHasMore = state => state.notifications.total != null && state.notifications.results.length < state.notifications.total
export const getTotalNotificationsNotViewed = state => state.notifications.totalNotificationsNotViewed
export const getNotificationsFilters = state => state.notifications.filters
export const getSelectedUserId = state => _.get(state.notifications, 'selectedUserId')
