import _ from 'lodash'
import { CREATE_APPLICATION, UPDATE_APPLICATION, DELETE_APPLICATION } from './constants'
import { getUserSelectedOrganisationId } from '../auth/selectors'
import developerActions from './actions'

class DeveloperMiddleware {
  fetchApplications = ({ dispatch, getState }) => next => action => {
    next(action)
    if (_.includes(
      [
        CREATE_APPLICATION,
        DELETE_APPLICATION,
        UPDATE_APPLICATION
      ], action.type)
      && action.status === 'SUCCESS'
    ) {
      const submissionServiceUsers = !_.isNil(_.get(action, 'payload.submissionServiceUser'))
      const organisationId = getUserSelectedOrganisationId(getState())
      dispatch(developerActions.fetchApplications({ organisationId, submissionServiceUsers }))
    }
  }
}

export default new DeveloperMiddleware()
