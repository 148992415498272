import React, { Component, Fragment } from 'react'

// the use case for this HOC is when you have a component with a fixed
// or absolute position and therefore you need to push down the rest
// of the page content by a distance equal to the height of the component
const withHeightOffset = Comp => (
  class extends Component {
    state = {
      height: 0
    }

    onUpdate = () => {
      if (!this.state.height && this._ref) {
        const height = this._ref.offsetHeight
        this.setState({ height })
      }
    }

    componentDidMount () {
      this.onUpdate()
    }
  
    componentDidUpdate () {
      this.onUpdate()
    }

    setRef = (el) => {
      if (el) this._ref = el
    }

    render () {
      const { disableHeightOffset, ...rest } = this.props
      return <Fragment>
        <Comp {...rest} heightOffsetRef={this.setRef} heightOffset={this.state.height} />
        <div style={{
          height: disableHeightOffset ? 0 : this.state.height
        }} />
      </Fragment>
    }
  }
)

export default withHeightOffset
