const styles = (theme) => {
  return {
    container: {
      position: 'relative'
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      position: 'relative',
      margin: '50px 0 0 0'
    },
    reportsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '60%',
      overflowY: 'auto',
      margin: '0 0 30px 0',
      [theme.breakpoints.down('md')]: {
        width: '95%'
      },
    },
    reportContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      borderStyle: 'solid',
      borderWidth: '1px',
      color: 'black',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '50px',
      marginBottom: '10px',
    },
    deleteReportButton: {
      width: '100%'
    }
  }
}

export default styles