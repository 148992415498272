import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'
import BackBar from './BackBar'
import _ from 'lodash'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const pop = () => dispatch(goBack())
  const onClick = ownProps.onClick || pop || _.noop
  return {
    ...ownProps,
    onClick
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BackBar)
