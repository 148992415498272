import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'

import ProductsSearchScreen from './ProductsSearchScreen'
import ViewProductScreen from './ViewProductScreen'
import WatchListSearchScreen from './WatchListSearchScreen'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/products' path='/products' exact component={TimedLogoutComponent(ProductsSearchScreen)} />,
  <PrivateRoute key='/product:id' path='/product/:id' exact component={TimedLogoutComponent(ViewProductScreen)} />,
  <PrivateRoute key='/watch-list' path='/watch-list' exact component={TimedLogoutComponent(WatchListSearchScreen)} />
]
