import _, { repeat } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-final-form';
import { v4 as uuidv4 } from 'uuid'
import { translation } from '../../services/translations'
import ReportFormSection from '../ReportFormSection';
import ReportFormField from '../ReportFormField';
import styled from 'styled-components';
import { useFormStatusState } from '../../services/formStatusContext';

const EmptyLabel = styled.div`
  width: 100%;
  display: flex;
  align-text: center;
  justify-content: center;
  justify-text: center;
  fontSize: 14;
  padding-top: 10px;
`

const CombinedRepeatable = (props) => {
  const {
    sourceField,
    targetField,
    requiredFieldValue,
    repeatableGroupId,
    schema,
    change,
    getState,
    getFieldState,
    emptyTranslationKey
  } = props

  const { setCombinedRepeatable } = useFormStatusState()

  const formState = useFormState()
  const formValues = formState.values
  const formErrors = formState.errors

  const { id: sourceFieldId, groupKey, labelKey, labelExtra, labelPlaceholder = 'Item', filterValues = [] } = sourceField
  const { id: targetFieldId } = targetField

  const required = _.get(formValues, requiredFieldValue)
  const initialFormValues = _.get(formValues, repeatableGroupId, null)
  const [initial, setInitial] = useState(true)

  const [sourceValues, setSourceValues] = useState()
  const [targetValues, setTargetValues] = useState()

  const [addedTarget, setAddedTarget] = useState()
  const [removedTarget, setRemovedTarget] = useState()  

  useEffect(() => {
    setCombinedRepeatable(repeatableGroupId)
    if (initialFormValues) {
      const initialValues = _.values((_.groupBy(initialFormValues, 'groupId')))
      _.forEach(initialValues, (value, index) => {
        const fieldId = `${repeatableGroupId}_${index}`
        change(fieldId, value)
      })
    }
  }, [])

  useEffect(() => {
    const newSourceValues = sourceFilterFunction(_.get(formValues, sourceFieldId), filterValues)
    const newTargetValues = _.get(formValues, targetFieldId)
    if (initial) {
      setSourceValues(newSourceValues)
      setTargetValues(newTargetValues)
      setInitial(false)
      return
    }
    if (!_.isEqual(sourceValues, newSourceValues)) {
      setSourceValues(newSourceValues)
    }
    if (!_.isEqual(targetValues, newTargetValues)) {
      setTargetValues(newTargetValues)
    }
    if (_.size(newTargetValues) < _.size(targetValues)) {
      const removedTarget = _.first(_.differenceBy(targetValues, newTargetValues, 'id'))
      setRemovedTarget(removedTarget)
      setAddedTarget(null)
    }
    if (_.size(newTargetValues) > _.size(targetValues)) {
      const addedTarget = _.last(newTargetValues)
      setAddedTarget(addedTarget)
      setRemovedTarget(null)
    }
  }, [formValues])

  useEffect(() => {
    if (_.isEmpty(sourceValues)) { return }
    const index = _.size(sourceValues) - 1
    const source = sourceValues[index]
    const fieldId = `${repeatableGroupId}_${index}`
    const fieldValues = formValues[fieldId]
    const fieldSize = _.size(fieldValues, 0)
    if (fieldSize > 0) { return }
    const repeatables = _.map(targetValues, target => {
      const targetName = _.get(target, targetField.labelKey)
      const targetId = _.get(target, 'id')
      const groupId = _.get(source, groupKey)
      return createRepeatableItem({ groupId, targetName, targetId })
    })
    change(fieldId, repeatables)
  }, [sourceValues])

  useEffect(() => {
    if (!removedTarget) { return }
    _.forEach(sourceValues, (source, index) => {
      const fieldId = `${repeatableGroupId}_${index}`
      const fieldValues = formValues[fieldId]
      const removedTargetId = _.get(removedTarget, 'id')
      const filteredFieldValues = _.filter(fieldValues, repeatable => repeatable.targetId !== removedTargetId)
      change(fieldId, filteredFieldValues)
      setRemovedTarget(null)
    })
  }, [removedTarget])

  useEffect(() => {
    if (!addedTarget) { return }
    _.forEach(sourceValues, (source, index) => {
      const fieldId = `${repeatableGroupId}_${index}`
      const fieldValues = formValues[fieldId]
      const fieldValuesClone = _.cloneDeep(fieldValues) || []
      const targetName = _.get(addedTarget, targetField.labelKey)
      const targetId = _.get(addedTarget, 'id')
      const groupId = _.get(source, groupKey)
      const repeatable = createRepeatableItem({ groupId, targetName, targetId })
      fieldValuesClone.push(repeatable)
      change(fieldId, fieldValuesClone)
      setAddedTarget(null)
    })
  }, [addedTarget])

  if (_.isEmpty(sourceValues) || _.isEmpty(targetValues)) {
    const emptyTranslation = translation(emptyTranslationKey)
    return (
      <EmptyLabel>{emptyTranslation}</EmptyLabel>
    )
  }

  const renderSections = () => {
    return _.map(sourceValues, (source, index) => {
      const schemaClone = _.cloneDeep(schema)
      const schemaWithExtraProps = _.reduce(schemaClone, (memo, field) => {
        const { id } = field
        if (id === repeatableGroupId) {
          const label = _.get(source, labelKey) || `${labelPlaceholder} ${index + 1}`
          const labelDescription = _.chain(labelExtra)
            .map(label => _.get(source, label)).compact().join(', ').value()
          const fieldId = `${id}_${index}`
          field.id = fieldId
          field.props = {
            ...field.props,
            formId: fieldId,
            name: fieldId,
            required,
            label: !_.isEmpty(labelDescription) ? `${label} - ${labelDescription}` : label,
            sourceInitialValues: {
              sourceName: label
            },
            targets: targetValues,
            targetLabelKey: _.get(targetField, 'labelKey')
          }
        }
        memo.push(field)
        return memo
      }, [])

      return (
        <ReportFormSection
          key={index}
          schema={schemaWithExtraProps}
          change={change}
          getState={getState}
          getFieldState={getFieldState}
        />
      )
    })
  }

  return (
    <>
      {renderSections()}
    </>
  )
}

const createRepeatableItem = ({ groupId, targetName, targetId }) => ({
  id: uuidv4(),
  targetName,
  targetId,
  groupId,
  disableDelete: true,
  prepopulated: true
})

const sourceFilterFunction = (sourceArray = [], filterValues = []) => {
  return _.filter(sourceArray, (value) => {
    return _.every(filterValues, (filterValue, filterKey) => {
      return _.includes(_.castArray(filterValue), value[filterKey])
    })
  })
}

export default CombinedRepeatable