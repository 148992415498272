import React from 'react'

import Editable from '../Editable'
import { ColorPicker } from '../../../components/Fields'
import P from '../../P'

export default Editable({
  input: (props) => {
    return (
      <ColorPicker {...props} />
    )
  },
  display: ({ label, value, format, hideIfEmpty, isEmpty }) => {
    if (isEmpty && hideIfEmpty) return null
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={format ? format(value) : value} />
      </div>
    )
  }
})
