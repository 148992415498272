import _ from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFormValues, isDirty, isValid, submit } from 'redux-form'
import { translations } from '../../../../../../config'

import Button from '../../../../../../components/Button'
import { announcementsFormName, platformsFormName, scheduleFormName } from '../../../constants'
import { asyncActions, selectors } from '../../../../../../store/modules/announcementManagement'
import { useUpsertAnnouncement } from '../../AnnouncementsForm/hooks'

const SaveButton = ({ announcementId, isEditing }) => {
  const dispatch = useDispatch()
  const onSubmit = useUpsertAnnouncement({ dispatch })

  const { entity } = useSelector(selectors.genericSelectors.getDetailById({ id: announcementId }))

  const isAnnouncementsFormDirty = useSelector(isDirty(announcementsFormName))
  const isAnnouncementsFormValid = useSelector(isValid(announcementsFormName))

  const isPlatformsFormDirty = useSelector(isDirty(platformsFormName))
  const isPlatformsFormValid = useSelector(isValid(platformsFormName))

  const isScheduleFormDirty = useSelector(isDirty(scheduleFormName))
  const isScheduleFormValid = useSelector(isValid(scheduleFormName))

  const scheduleValues = useSelector(getFormValues(scheduleFormName))
  const announcementsFormValues = useSelector(getFormValues(announcementsFormName))
  const platformsValues = useSelector(getFormValues(platformsFormName))

  const canSave = (
    (isAnnouncementsFormDirty || isScheduleFormDirty || isPlatformsFormDirty)
    && isAnnouncementsFormValid
    && isScheduleFormValid
    && (isPlatformsFormValid && isPlatformsFormValid)
  )

  const action = isEditing ? 'Save' : 'Create'

  const handleSubmit = useCallback(async () => {
    const assignedPlatforms = []
    const unassignedPlatforms = []

    if (isPlatformsFormDirty) {
      const { assigned, unassigned } = checkPlatformDifferences({
        isPlatformsFormDirty,
        initialPlatformValues: entity.platforms,
        currentPlatformValues: platformsValues.platforms
      })
      assignedPlatforms.push(...assigned)
      unassignedPlatforms.push(...unassigned)
    }

    await onSubmit({
      ...scheduleValues,
      ...announcementsFormValues,
      assignedPlatforms,
      unassignedPlatforms
    })

  }, [onSubmit, isPlatformsFormDirty, entity, platformsValues, announcementsFormValues, scheduleValues])

  return (
    <Button
      variant='contained'
      color='primary'
      onClick={() => {
        handleSubmit().then(() => {
          dispatch(asyncActions.getDetail({ id: announcementId }))
        })
      }}
      disabled={!canSave}
    >
      {translations(`Announcement Management - ${action} Announcement`)}
    </Button>
  )
}

const checkPlatformDifferences = ({
  initialPlatformValues,
  currentPlatformValues
}) => {
  const assigned = []
  const unassigned = []

  for (const currentValue of currentPlatformValues) {
    const initialValue = initialPlatformValues.find(initialValue => initialValue.id === currentValue.id)
    if (initialValue.assigned === currentValue.assigned) {
      continue
    }
    if (currentValue.assigned) {
      assigned.push(currentValue.id)
      continue
    }
    unassigned.push(currentValue.id)
  }

  return { unassigned, assigned }
}

export default SaveButton