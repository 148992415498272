import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import P from '../../P'

import style from './style'

const Table = props => {
  const { data, cols, xsCols, classes, limit } = props

  const firstRow = _.get(data, 0)
  const rows = (limit > 1) ? _.take(data, limit) : data

  return <div role='table'>
    <div role='rowgroup'>
      <div role='row' className={`${classes.header} ${classes.row} ${classes[`table-${cols}-${xsCols}`]}`}>
        {(firstRow || []).map(datum => {
          const label = _.get(datum, 0)
          const cellProps = _.get(datum, 2)
          if (!_.isEmpty(label)) {
            return <P
              key={`h-${label}`}
              value={label}
              role='columnheader'
              {...cellProps}
            />
          }
        })}
      </div>
    </div>
    <div role='rowgroup'>
      {(rows || []).map((row, index) => {
        return (
          <div role='row' className={`${classes.row} ${classes[`table-${cols}-${xsCols}`]}`} key={_.uniqueId('tableRow_')}>
            {
              (row || []).map(datum => {
                const label = _.get(datum, 0)
                const value = _.get(datum, 1)
                const cellProps = _.get(datum, 2)

                const cellComponent = React.isValidElement(value)
                  ? value
                  : <P value={value} />

                return (
                  <div
                    key={`${index}-${label}`}
                    role='cell'
                    {...cellProps}
                  >
                    {cellComponent}
                  </div>
                )
              })
            }
          </div>
        )
      })}
    </div>
  </div>
}

Table.defaultProps = {
  data: []
}

Table.propTypes = {
  data: PropTypes.array,
  cols: PropTypes.number,
  xsCols: PropTypes.number,
  classes: PropTypes.shape({}),
  limit: PropTypes.number
}

export default withStyles(style)(Table)
