import React, { Fragment } from 'react'
import FullScreenLoader from './FullScreenLoader'

export { FullScreenLoader }

// export default (WrappedComponent) => {
//   return (props) => {
//     return props.isLoading
//       ? <FullScreenLoader />
//       : <WrappedComponent {...props} />
//   }
// }

// above: old HOC - removes component from DOM on load
// below: new HOC - makes component invisible on load

// this new version is better for multiple reasons -
// stops page jumping to top after load.
// stops unnecessary mounts, allowing componentDidMount
// logic to actually work, and improving perf.

// but it might cause property access errors, so I'm
// leaving the old version commented out in case the new
// one creates so many bugs that it's not sensible to use it

export default (WrappedComponent) => {
  return (props) => {
    const { isLoading, loadingMessage } = props
    const style = { opacity: Number(!isLoading) }
    return <Fragment>
      <div style={style}>
        <WrappedComponent {...props} />
      </div>
      {isLoading ? <FullScreenLoader loadingMessage={loadingMessage} /> : null}
    </Fragment>
  }
}
