import { colors } from '../../config/theme'

const styles = theme => ({
  container: {
    border: `1px solid ${colors.contentBoxGrey}`,
    width: '100%',
    margin: '0 auto 30px auto',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15
    }
  },
  header: {
    fontSize: '19px',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      minHeight: '60px'
    }
  },
  content: {
    padding: '20px 15px',
    [theme.breakpoints.up('sm')]: {
      padding: '30px 40px'
    },
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderTop: `1px solid ${colors.contentBoxGrey}`,
    '&[hidden]': {
      display: 'none',
      borderTop: 0
    }
  }
})

export default theme => styles(theme)
