import React, { createContext, useReducer, useEffect, useState, useMemo, useCallback, useContext } from 'react'
import _ from 'lodash'
import fp from 'lodash/fp'

import logging from 'use-reducer-logger'

import * as actions from './actions'
import reducer from './reducers'

import digitalStoreSdk from '../../digitalStoreSdk'
import { isDevMode, conditionsFormatter } from '../../helpers'



const initialState = {
  fields: [],
  conditions: [],
  resources: {},
  isEdited: false
}

const { formatRulesEngine, formatQueryBuilder } = conditionsFormatter

const conditionsStore = createContext(initialState)
const { Provider } = conditionsStore

const ConditionsProvider = ({ children, schemaFields = {}, initialConditions }) => {
  const [internalResourceConditions, setInternalResourceConditions] = useState({})
  const [reportFields, setReportFields] = useState({})
  const [state, dispatch] = useReducer(
    isDevMode ? logging(reducer) : reducer,
    initialState
  )

  useEffect(() => {
    dispatch(actions.setResourceConditions(internalResourceConditions))
  }, [internalResourceConditions])

  useEffect(() => {
    const formattedConditions = _.map(initialConditions, (entry) => {
      const { id: conditionVersionId, condition = {}, conditionId, conditions = [] } = entry
      const { name, description } = condition
      return {
        id: conditionId,
        conditionVersionId,
        name,
        description,
        conditions: formatQueryBuilder(conditions)
      }
    })
    dispatch(actions.setConditions(formattedConditions))
  }, [initialConditions])

  const flatSchemaFields = useMemo(() => {
    const flatFields = {}
    for (const field in schemaFields) {
      const schemaField = schemaFields[field]
      if (schemaField.id) {
        flatFields[schemaField.id] = {
          id: schemaField.id,
          field: schemaField.field,
          props: schemaField.props
        }
      }
    }
    return flatFields
  }, [schemaFields])

  const conditionsFields = useMemo(() => {
    const resolveSchemaField = ({ fieldId }) => {
      return _.get(flatSchemaFields, fieldId)
    }

    const getEditorType = (schemaField) => {
      let editorType = 'text'
      const schemaType = _.get(schemaField, 'field')
      const values = _.compact(_.get(schemaField, 'props.options', []))

      if (!_.isEmpty(values) && (schemaType === 'Dropdown' || schemaType === 'Checklist' || schemaType === 'Radio')) {
        editorType = 'select'
      }

      return editorType
    }

    const getInputType = (schemaField) => {
      let inputType
      const schemaType = _.get(schemaField, 'field')
      const propOpts = _.get(schemaField, 'props.options', [])
      const baseOpts = _.get(schemaField, 'options', [])
      const dropdownOptions = _.get(schemaField, 'dropdownOptions', [])
      const values = _.without([...baseOpts, ...propOpts, ...dropdownOptions], [undefined, null])

      switch (schemaType) {
        case 'Date':
          inputType = 'date'
          break
        case 'Dropdown':
        case 'Checklist':
        case 'Radio':
        case 'Radiolist':
          if (!_.isEmpty(values)) {
            inputType = 'select'
          }
          break
        default:
          break
      }
      return inputType
    }

    const getValues = ({ field, schemaField }) => {
      const fieldOptions = _.get(field, 'options', [])
      const propOptions = _.get(schemaField, 'props.options', [])
      const dropdownOptions = _.get(field, 'dropdownOptions', [])
      const options = _.without([...fieldOptions, ...propOptions, ...dropdownOptions], [null, undefined])
      let mappedOptions

      if (_.isArray(options)) {
        const optionsPayload = _.map(options, (option) => {
          if (_.isPlainObject(option)) {
            const { value, label } = option

            if (!_.isUndefined(value) && !_.isUndefined(label)) {
              return {
                name: value,
                label
              }
            }
          } else {
            return {
              name: option,
              label: option
            }
          }
        })

        if (_.size(_.compact(optionsPayload)) > 0) {
          mappedOptions = optionsPayload
        }
      }

      return mappedOptions
    }



    const schemaToFieldQuery = (field) => {
      const isInternalField = _.get(field, 'fieldId', false)
      const fieldId = _.get(field, 'id')
      const schemaField = resolveSchemaField({ fieldId })

      const payload = {
        id: _.get(schemaField, 'id', fieldId),
        name: _.get(schemaField, 'id', fieldId),
        label: field.name,
        isHeading: field.isHeading || false,
        isBlock: field.isBlock || false,
        isCustom: field.isCustom || false,
        isEditable: field.isEditable || false,
        valueEditorType: getEditorType(schemaField),
        inputType: getInputType(field.isCustom ? field : schemaField),
        field: _.get(field.isCustom ? field : schemaField, 'field'),
        props: _.get(schemaField, 'props', {}),
        displayName: _.get(field, 'displayName')
      }
      const options = getValues({ field, schemaField })
      if (_.size(options) > 0) {
        payload.values = options
      }

      if (isInternalField) {
        const parentId = _.get(field, 'fieldId')
        const reportParentField = _.get(reportFields, parentId)
        payload.parentId = parentId
        payload.parentDisplayName = _.get(reportParentField, 'name')
      }

      return payload
    }

    const mappedInternalFields = fp.compose(
      fp.map((field) => _.get(field, 'fields', []))
    )(reportFields)

    let nextReportFields = _.cloneDeep(reportFields)
    _.forEach(mappedInternalFields, (fields) => {
      const mappedIdFields = _.map(fields, (field) => {
        return { ...field, id: `${field.fieldId}.${field.id}` }
      })
      const repeatableFields = _.mapKeys(mappedIdFields, field => `${field.id}`)
      nextReportFields = { ...nextReportFields, ...repeatableFields }
    })
    const excludedFields = ['susarEditable', 'spontaneousEditable']
    const mappedFields = fp.compose(
      fp.map(schemaToFieldQuery),
      fp.filter((field) => fp.get('editable', field) !== false),
      fp.filter((field) => fp.get('fields', field) === undefined),
      fp.filter((field) => !fp.includes(fp.get('id', field), excludedFields))
    )(nextReportFields)

    return mappedFields
  }, [reportFields, flatSchemaFields])

  useEffect(() => {
    dispatch(actions.updateFields({ fields: conditionsFields }))
  }, [conditionsFields])

  const getConditionsForSave = () => {
    const payload = _.map((state.conditions), (condition) => {
      return _.get(condition, 'conditionVersionId')
    })
    return payload
  }

  const getConditionsForViewJSON = () => {
    const payload = _.map((state.conditions), (condition) => {
      return _.pick(condition, ['conditionVersionId', 'id'])
    })
    return payload
  }

  const addCondition = async (payload = [], upgradeInfo = {}) => {
    const { previousConditionFields = [], previousConditionSections = [] } = upgradeInfo
    const initialObjects = []
    const formattedConditionArray = _
      .chain(payload)
      .castArray()
      .map((conditionObject) => {
        const internalConditions = _.get(conditionObject, 'conditions')
        const formattedConditions = internalConditions.all || internalConditions.any
          ? formatQueryBuilder(internalConditions)
          : formatRulesEngine(internalConditions)
        const nullCheckedConditionObject = _.omitBy(conditionObject, _.isNull)
        initialObjects.push(nullCheckedConditionObject)
        return {
          ...nullCheckedConditionObject,
          conditions: formattedConditions
        }
      })
      .value()
    const conditionsResult = await digitalStoreSdk.conditions.createMultipleConditions(formattedConditionArray)
    _.forEach(conditionsResult, (conditionObject, index) => {
      const { id, conditionVersionId } = conditionObject
      const nextCondition = initialObjects[index]
      dispatch(actions.addCondition({
        ...nextCondition,
        id,
        conditionVersionId
      }))
    })
    if (_.size(previousConditionFields) > 0 || _.size(previousConditionSections) > 0) {
      const combinedConditions = _.union(previousConditionFields, previousConditionSections)
      _.forEach(combinedConditions, (resource) => {
        const previousResourceConditions = _.get(resource, 'conditions')
        const resourceId = _.get(resource, 'id')
        const isBlock = _.get(resource, 'isBlock')
        _.forEach(previousResourceConditions, (resourceCondition) => {
          const resourceType = isBlock ? 'BLOCK' : 'SECTION'
          const previousResourceConditionVersionId = _.get(resourceCondition, 'conditionVersionId')
          const previousConditionIndex = _.findIndex(formattedConditionArray, ({ oldVersionId }) => oldVersionId === previousResourceConditionVersionId)
          if (previousConditionIndex !== -1) {
            const conditionId = _.get(conditionsResult, `${previousConditionIndex}.id`)
            dispatch(actions.attachToResource({ resourceId, resourceType, conditionId }))
          }
        })
      })
    }
  }

  const updateCondition = async (payload = {}) => {
    const formatPayload = _.omitBy(payload, _.isNull)
    const prevCondition = _.find(state.conditions, { id: formatPayload.id })

    if (_.isEqual(prevCondition, payload)) {
      return
    }
    const conditions = formatRulesEngine(_.get(formatPayload, 'conditions'))
    const conditionsResult = await digitalStoreSdk.conditions.updateCondition({
      ...formatPayload,
      conditionId: _.get(formatPayload, 'id'),
      conditions
    })
    dispatch(actions.updateCondition({
      ...formatPayload,
      conditionVersionId: conditionsResult.conditionVersionId
    }))
  }


  const fieldLookup = useCallback(({ field, value, operator }) => {
    const matchedField = _.find(state.fields, { id: field }) || {}

    const getValue = () => {
      let mappedValue = value
      const options = _.get(matchedField, 'props.options') || _.get(matchedField, 'values')
      if (options) {
        const matchedOption = _.find(options, { value }) || _.find(options, { name: value })
        mappedValue = _.get(matchedOption, 'label', value)
      }
      if (_.isArray(value)) {
        const isDate = operator === 'isBetween' || operator === 'isNotBetween'
        if (isDate) {
          mappedValue = _.join(value, ' - ')
        } else if (_.every(value, (value) => typeof value == 'string')) {
          mappedValue = _.join(value, ', ')
        } else {
          mappedValue = _.join(_.map(value, ({ label }) => label), ', ')
        }
      }
      return mappedValue
    }

    return {
      friendlyName: _.get(matchedField, 'displayName', _.get(matchedField, 'label', field)),
      friendlyValue: getValue()
    }
  }, [state.fields])

  const getConditions = useCallback(({ filters } = {}) => {
    if (_.isObject(filters)) {
      const filteredConditions = getConditionsForResource(filters)
      return filteredConditions
    } else {
      return state.conditions
    }
  }, [state.conditions, state.resources])

  const getConditionsForResource = useCallback(({ resourceType, resourceId }) => {
    const conditionIds = _.get(state, `resources.${resourceType}.${resourceId}`)
    const resourceConditions = _.filter(state.conditions, (condition) => {
      return _.includes(conditionIds, _.get(condition, 'id'))
    })
    return resourceConditions
  }, [state.conditions, state.resources])

  const attachToResource = ({ resourceId, resourceType, conditionId }) => {
    dispatch(actions.attachToResource({
      resourceId,
      resourceType,
      conditionId
    }))
  }

  const removeFromResource = ({ resourceId, resourceType, conditionId }) => {
    dispatch(actions.removeFromResource({
      resourceId,
      resourceType,
      conditionId
    }))
  }

  const replaceResourceConditions = (({ resourceId, resourceType, conditionIds }) => {
    dispatch(actions.replaceResourceConditions({
      resourceId,
      resourceType,
      conditionIds
    }))
  })

  return (
    <Provider
      value={{
        state,
        dispatch,
        getConditionsForSave,
        setReportFields,
        fieldLookup,
        addCondition,
        updateCondition,
        attachToResource,
        removeFromResource,
        replaceResourceConditions,
        getConditionsForResource,
        getConditions,
        getConditionsForViewJSON,
        setInternalResourceConditions
      }}>
      {children}
    </Provider>
  )
}

export const useConditions = () => useContext(conditionsStore)

export default { ConditionsProvider, conditionsStore }
