import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import P from '../../../../../components/P'
import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../../../config'
import { constants as formViewConstants } from '../../../../../store/modules/formViews'
import * as validators from '../../../../../validators'

import styles from './style'

const ImportForm = Form(formViewConstants.IMPORT_CONFIGURATION_FORM)

class ImportModal extends Component {
  state = {
    tabIndex: 'byID'
  }

  handleChange = (event, value) => {
    this.setState({ tabIndex: value })
  }

  render = () => {
    const {
      classes,
      actions,
      handleSubmit
    } = this.props
    const { tabIndex } = this.state

    const formSchema = {
      'byID': [
        {
          id: 'formViewId',
          field: 'Input',
          props: {
            label: translations('Import Report Configuration - Import via report configuration ID'),
            name: 'sourceFormViewId',
            validate: [validators.isUUID]
          }
        }
      ],
      'byJSON': [
        {
          id: 'viewJSON',
          field: 'Input',
          props: {
            label: translations('Import Report Configuration - Import via viewJSON'),
            name: 'viewJSON',
            multiline: true,
            rows: 10,
            validate: [validators.isViewJSON]
          }
        }
      ]
    }

    return (
      <BasicModalOverlay
        title={translations('Import Report Configuration - Modal Heading')}
        actions={actions}
      >
        <ImportForm onSubmit={handleSubmit}>
          <ContentBoxBody>
            <Tabs value={tabIndex} onChange={this.handleChange} centered fullWidth indicatorColor='primary'>
              <Tab classes={{ root: classes.tabRoot }} label={translations('Import Report Configuration - By Id')} value={'byID'} />
              <Tab classes={{ root: classes.tabRoot }} label={translations('Import Report Configuration - By JSON')} value='byJSON' />
            </Tabs>
            <div className={classes.tabContainer}>
              <FormBody
                schema={formSchema[tabIndex]}
                editing
                fullWidthFields
                />
              { tabIndex === 'byJSON' && <P type={'alert'} className={classes.warning} value={translations('Import Report Configuration - Advanced feature')} />}
            </div>
          </ContentBoxBody>
        </ImportForm>
      </BasicModalOverlay >
    )
  }
}

ImportModal.propTypes = {
  classes: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired
}

export default withStyles(styles)(ImportModal)
