import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import FormBody from '../../Form/FormBody'
import Editable from '../Editable'
import styles from './style'

const renderCombinedRepeatable = ({ props, editing }) => {
  const {
    schema,
    formName,
    formId,
    formValues,
    layout,
    repeatableGroupId,
    sourceField,
    targetField,
    sourceFilterFunction,
    required = false,
    emptyTranslationKey,
    classes
  } = props

  const { id: sourceFieldId, groupKey, labelKey, labelExtra, labelPlaceholder = 'Item' } = sourceField
  const { id: targetFieldId } = targetField
  const sourceValues = sourceFilterFunction(formValues[sourceFieldId])
  const targetValues = formValues[targetFieldId]

  if (_.isEmpty(sourceValues) || _.isEmpty(targetValues)) {
    const translation = editing ? translations(emptyTranslationKey) : '-'
    return (
      <div className={classes.emptyLabel}>
        <p>{translation}</p>
      </div>
    )
  }

  return _.map(sourceValues, (source, index) => {
    const schemaClone = _.cloneDeep(schema)
    const schemaWithExtraProps = _.reduce(schemaClone, (memo, field) => {

      const { id } = field
      if (id === repeatableGroupId) {
        const label = _.get(source, labelKey) || `${labelPlaceholder} ${index + 1}`
        const labelDescription = _.chain(labelExtra).map(label => _.get(source, label)).compact().join(', ').value()

        field.props = {
          ...field.props,
          required,
          groupId: source[groupKey],
          label: !_.isEmpty(labelDescription) ? `${label} - ${labelDescription}` : label,
          sourceInitialValues: {
            sourceName: label
          }
        }
      }

      memo.push(field)
      return memo
    }, [])

    return (
      <FormBody
        schema={schemaWithExtraProps}
        editing={editing}
        formName={formName}
        formId={formId}
        layout={layout}
      />
    )
  })
}

const EditableCombinedRepeatable = Editable({
  input: (props) => renderCombinedRepeatable({ props, editing: true }),
  display: (props) => renderCombinedRepeatable({ props, editing: false })
})

export default withStyles(styles)(EditableCombinedRepeatable)
