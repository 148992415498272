const styles = (theme) => {
    return {
      container: {
        position: 'relative'
      },
      buttonContainer : {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'flex-end'
      },
      listContainer: {
        padding: 18,
      }
    }
  }
  
  export default styles
  