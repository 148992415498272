import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import digitalStoreSdk from '../../../digitalStoreSdk'
import  * as constants from './constants'
import * as selectors from './selectors'
import middleware from './middleware'

const initialState = {
  created: {},
  loaded: {}
}

const createOrganisationInvite = createAsyncThunk(
  constants.CREATE_ORGANISATION_INVITE,
  async (fields, { rejectWithValue }) => {
    try {
      return await digitalStoreSdk.organisations.createOrganisationInvite(fields)
    } catch (error) {
      return rejectWithValue(error)
    }
  } 
)

const fetchOrganisationInvite = createAsyncThunk(
  constants.FETCH_ORGANISATION_INVITE,
  async ({ organisationInviteId, platformId }, { rejectWithValue }) => {
    try {
      return await digitalStoreSdk.organisations.fetchOrganisationInvite({ organisationInviteId, platformId })
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  } 
)

const slice = createSlice({
  name: constants.NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrganisationInvite.pending, (state, action) => {
        state.created.status = 'PENDING'
      })
      .addCase(createOrganisationInvite.fulfilled, (state, action) => {
        state.created = action.payload
        state.created.status = 'FULFILLED'
      })
      .addCase(createOrganisationInvite.rejected, (state, action) => {
        state.created.status = 'REJECTED'
        state.created.error = action.error
      })
      .addCase(fetchOrganisationInvite.pending, (state, action) => {
        state.loaded.status = 'PENDING'
      })
      .addCase(fetchOrganisationInvite.fulfilled, (state, action) => {
        state.loaded = action.payload
        state.loaded.status = 'FULFILLED'
      })
      .addCase(fetchOrganisationInvite.rejected, (state, action) => {
        state.loaded.status = 'REJECTED'
        state.loaded.error = action.payload.message
      })
    }
})

export const store = slice.reducer
export const actions = slice.actions
export const asyncActions = {
  createOrganisationInvite,
  fetchOrganisationInvite
}
export { selectors, middleware }