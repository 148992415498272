import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { submit } from 'redux-form'
import { useDispatch } from 'react-redux'

import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { constants as reportConfigurationConstants } from '../../../../../store/modules/reportConfiguration'

import { translations } from '../../../../../config'

const formName = reportConfigurationConstants.CHANGE_BLOCK_FORM
const ChangeForm = Form(formName)

const ChangeBlockModal = (props) => {
  const { onSubmit, dismiss, title, blockOptions, fieldType } = props
  const dispatch = useDispatch()

  const handleSubmit = useCallback(({ blockId }) => {
    onSubmit({ destination: blockId })
    dismiss()
  }, [onSubmit, dismiss])

  const actions = useMemo(() => {
    return [
      {
        success: true,
        text: translations('Confirm'),
        primary: true,
        onClick: () => {
          dispatch(submit(formName))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [onSubmit, dismiss])

  const schema = useMemo(() => {
    return [
      {
        id: 'destination',
        field: 'Dropdown',
        props: {
          label: translations(`Move To Block - ${fieldType}`),
          name: 'blockId',
          options: blockOptions,
          required: true
        }
      }
    ]
  }, [fieldType, blockOptions])

  return (
    <BasicModalOverlay actions={actions} title={title}>
      <ChangeForm onSubmit={handleSubmit}>
        <ContentBoxBody>
          <FormBody
            schema={schema}
            editing
            fullWidthFields
          />
        </ContentBoxBody>
      </ChangeForm>
    </BasicModalOverlay>
  )
}

ChangeBlockModal.props = {
  blockOptions: [],
  fieldType: 'QUESTION'
}

ChangeBlockModal.propTypes = {
  /** Modal actions */
  actions: PropTypes.array.isRequired,
  /** Callback when form is submitted */
  onSubmit: PropTypes.func,
  /** Closes the modal */
  dismiss: PropTypes.func,
  /** Title of the modal */
  title: PropTypes.string.isRequired,
  /** Available options to move the field to */
  blockOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  })).isRequired,
  /** High level type of field  */
  fieldType: PropTypes.oneOf(['QUESTION', 'HEADING', 'REPEATABLE'])
}

export default ChangeBlockModal
