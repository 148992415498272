// The only prop you need to pass in is onSubmit
// to get the form data
import { reduxForm } from 'redux-form'
import Form from './Form'
import FormBody from './FormBody'
import FormError from './FormError'
import FormSubmit from './FormSubmit'
import Row from './Row'
import Column from './Column'
import FormSection from './FormSection'
import FormFieldset from './FormFieldset'

export default (formId, opts = {}) => reduxForm({
  form: formId,
  ...opts
})(Form)

export { FormBody, FormError, FormSubmit, Row, Column, FormSection, FormFieldset }
