import { compose, withPropsOnChange } from 'recompose'

import BarChart from './BarChart'

const enhance = compose(
  withPropsOnChange(['data'], (props) => {
    const { data = [] } = props
    const filteredData = data.filter(datum => !datum.hidden)
    return { data: filteredData }
  })
)

export default enhance(BarChart)
