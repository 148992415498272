import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'

class ResourcesActions {
  fetchResources = () => ({
    type: constants.FETCH_RESOURCES,
    promise: () => {
      return digitalStoreSdk.resources
        .fetchResources()
    }
  })
  fetchResourcesForCurrentOrganisation = () => ({
    type: constants.FETCH_RESOURCES,
    promise: (dispatch, getState) => {
      const selectedOrg = authSelectors.getUserSelectedOrganisationIdOrTemplateId(getState())

      return digitalStoreSdk.resources
        .fetchResources({ prefix: selectedOrg })
    }
  })
  fetchResource = ({ key, bucketName }) => {
    return {
      type: constants.FETCH_RESOURCE,
      promise: () => {
        return digitalStoreSdk.resources
          .fetchResource({ key: encodeURI(key), bucketName })
          .catch(error => {
            throw error
          })
      }
    }
  }
  deleteResource = ({ key, bucketName }) => {
    return {
      type: constants.DELETE_RESOURCE,
      promise: () => {
        return digitalStoreSdk.resources
          .deleteResource({ key: encodeURI(key), bucketName })
      }
    }
  }
}

export default new ResourcesActions()
