import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class tagReducers {
  fetchTags = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchTagsPending(state, action)
      case SUCCESS:
        return this.fetchTagsSuccess(state, action)
      case FAIL:
        return this.fetchTagsFail(state, action)
      default:
        return state
    }
  }
  fetchTagsPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchTagsSuccess = (state, action) => {
    return {
      ...state,
      status: SUCCESS,
      action: undefined,
      error: undefined,
      result: action.result
    }
  }
  fetchTagsFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }
}

export default new tagReducers()
