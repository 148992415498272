import React, { useContext, useCallback, useMemo } from 'react'
import _ from 'lodash'

import { selectors as formViewSelectors } from '../../../../../store/modules/formViews'
import { hooks as reportConfigurationHooks } from '../../../../../store/modules/reportConfiguration'

import { ReportConfigurationSidebarContext } from '../../../../../contexts'
import Form, { FormBody } from '../../../../../components/Form'
import { getSchema } from './schema'

const {
  timeframeOptions
} = formViewSelectors

const TimeframeForm = Form('ReportConfiguration/Timeframe')

const TimeframeSection = ({ formViewId }) => {
  const { dispatch, actions, state: { timeframe } } = useContext(ReportConfigurationSidebarContext.reportConfigurationSidebarStore)

  const schema = useMemo(() => {
    return getSchema({ timeframeOptions })
  }, [timeframeOptions])

  const handleUpdate = useCallback((updated) => {
    dispatch(actions.updateTimeframeDetails(updated))
  }, [timeframe])

  return (
    <TimeframeForm
      editing
      onChange={handleUpdate}
      initialValues={timeframe}
    >
      <FormBody
        schema={schema}
        editing
        fullWidthFields
      />
    </TimeframeForm>
  )
}

export default TimeframeSection
