import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { FormControl } from '@material-ui/core'

import ContentBox from '../../../../../components/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../../components/Form/TextContent'
import Heading from '../../../../../components/Heading'
import { Input } from '../../../../../components/Fields'
import List from '../../../../../components/Form/FormSections/List'
import { translations, passwordRequirements } from '../../../../../config'

import * as validators from '../../../../../validators'
import style from './style'

const SetPassword = ({ classes }) => (
  <ContentBox>
    <ContentBoxHeader>
      <TextContent>
        <Heading className={classes.contentBoxHeader}>{translations('Password')}</Heading>
      </TextContent>
    </ContentBoxHeader>
    <ContentBoxBody>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.field}>
            <Input
              label={`${translations('User Registration Password')} *`}
              name='password'
              type='password'
              validate={[validators.password]}
              shrink
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.field}>
            <Input
              label={`${translations('User Registration Confirm Password')} *`}
              name='confirmPassword'
              type='password'
              validate={[validators.passwordConfirmation]}
              shrink
            />
          </FormControl>
        </Grid>
      </Grid>
      <List listTitle={translations('Password Requirements Title')} items={passwordRequirements} />
    </ContentBoxBody>
  </ContentBox>
)
SetPassword.propTypes = {
}
export default withStyles(style)(SetPassword)
