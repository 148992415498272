import React from 'react'
import PropTypes from 'prop-types'

import BasicModalOverlay from '../../../../../../components/BasicModalOverlay'

import { translations } from '../../../../../../config'

const ConfirmDeleteModal = (props) => {
  const { onConfirmation, dismiss, title, message } = props

  return (
    <BasicModalOverlay
      title={title}
      text={message}
      actions={[
        {
          text: translations('Confirm'),
          primary: true,
          onClick: () => {
            onConfirmation()
            dismiss()
          }
        },
        {
          text: translations('Cancel'),
          onClick: dismiss
        }
      ]}
    />
  )
}

ConfirmDeleteModal.propTypes = {
  onConfirmation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export default ConfirmDeleteModal
