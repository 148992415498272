import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const defaultHeadlineMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4'
}

const Heading = (props) => {
  const {
    classes,
    className: classNameProp,
    component: componentProp,
    variant,
    uppercase,
    alignCenter,
    ...other
  } = props

  const className = classNames(
    classes.root,
    {
      [classes[variant]]: variant,
      [classes.uppercase]: uppercase,
      [classes.alignCenter]: alignCenter
    },
    classNameProp
  )

  const Component = componentProp || defaultHeadlineMapping[variant] || 'span'

  return <Component
    className={className}
    {...other}
  />
}

Heading.defaultProps = {
  variant: 'h2',
  uppercase: false
}

Heading.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  component: PropTypes.oneOfType([ PropTypes.string, PropTypes.func, PropTypes.object ]),
  variant: PropTypes.oneOf([ 'h1', 'h2', 'h3', 'h4' ]),
  uppercase: PropTypes.bool
}

export default withStyles(style)(Heading)
