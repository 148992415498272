import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'

import * as constants from './constants'
import drugDetailActions from './actions'
import { SUCCESS } from '../../middleware/redux-promise'

class DrugDetailsMiddleware {
  loadDrugDetailsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const isRoutingToDrugDetails = matchPath(path, { path: '/product/:id', exact: true })
      if (isRoutingToDrugDetails) {
        const drugId = _.get(isRoutingToDrugDetails, 'params.id')
        dispatch(drugDetailActions.fetchDrug({ id: drugId }))
      }
    }
  }
}

export default new DrugDetailsMiddleware()
