import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import actions from './actions'
import * as authSelectors from '../auth/selectors'

class ReceiversMiddleware {
  fetchReceiversMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToOrganisationDetails = matchPath(action.payload.location.pathname, { path: '/organisations/:id', exact: true })
      const isRoutingToMyOrganisationDetails = matchPath(action.payload.location.pathname, { path: '/my-organisation', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isLoggedIn && (isRoutingToOrganisationDetails || isRoutingToMyOrganisationDetails)) {
        dispatch(actions.fetchReceivers())
      }
    }
  }
}

export default new ReceiversMiddleware()
