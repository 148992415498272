// A dynamic schema can have "computed fields" which will be processed
// on form update by "parseDynamicSchema". The then static schema will be passed into the form
// component as normal
import * as validators from '../../../validators'
import _ from 'lodash'

import {
  getFormatForDate
} from '../formHelpers'

import countryCodes from './countryCodes'
import custAffiliateLocation from './custAffiliateLocation'
import dailyDoseUnits from './dailyDoseUnits'
import formOfAdministration from './formOfAdministration'
import administrationRoutes from './administrationRoutes'
import testunits from './testunits'
import formStrengthUnits from './formStrengthUnits'

const getAbbvieFormatForDate = (value) => {
  return getFormatForDate(value, {
    yyyymmdd: '3',
    yyyymm: '2',
    yyyy: '1'
  })
}

const initialFieldMap = {
  medicinalProduct: {
    group: 'drugs',
    field: 'medicinalproduct'
  }
}

const formSchema = {
  admin: {
    title: 'Admin',
    initialOpen: true,
    schema: [
      {
        id: 'canSaveDraft',
        field: 'Input',
        computedValue: (values) => values.primarysourcecountry && values.receiptdate,
        props: {
          type: 'hidden',
          name: 'canSaveDraft',
          label: 'Can save draft',
          required: false
        }
      },
      {
        id: 'causalityAssessmentRequired',
        field: 'Input',
        computedValue: (values) => {
          // What makes causality required for trilogy?
          //
          // const reportType = values.reporttype === '2'
          // const primarysource = _.chain(values).get('primarysource').some(v => v.observestudytype === '1').value()
          // const isRequired = reportType && primarysource
          // return isRequired ? 'true' : 'false'
          return 'false'
        },
        props: {
          type: 'hidden',
          shrink: true,
          name: 'causalityAssessmentRequired',
          label: 'Causality required',
          required: false
        }
      },
      {
        id: 'reporttype',
        field: 'Input',
        computedValue: () => '2',
        props: {
          type: 'hidden',
          name: 'reporttype',
          label: 'Type of report',
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'receiptdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'receiptdate',
          label: 'Date of receipt of the most recent information for this report',
          required: true,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
          helperText: 'YYYY/MM/DD'
        }
      },
      {
        id: 'primarysourcecountry',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'primarysourcecountry',
          label: 'Primary source country',
          options: [
            { label: 'SOUTH AFRICA', value: '143' }
          ],
          required: false
        }
      },
      {
        id: 'affiliatename',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'affiliatename',
          label: 'Affiliate Name',
          autocomplete: true,
          options: custAffiliateLocation,
          required: false
        }
      },
      {
        id: 'occurcountry',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'occurcountry',
          label: 'Country where the reaction/event occurred',
          autocomplete: true,
          options: countryCodes,
          required: false
        }
      },
      {
        id: 'initialorfollowup',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'initialorfollowup',
          label: 'Initial or follow up',
          options: [
            { value: '1', label: 'Initial' },
            { value: '2', label: 'Follow-up' }
          ],
          required: false
        }
      },
      {
        id: 'narrativeincludeclinical',
        field: 'Input',
        computedValue: (values) => {
          const {
            partnerawarenessdate,
            consenttocontactpatientphysician,
            sendercomment
          } = values

          const partnerawarenessdateString = partnerawarenessdate ? `Source/Date Vendor or Reporter Informed Company: ${partnerawarenessdate}` : undefined
          const narrative = [
            ...partnerawarenessdateString ? [partnerawarenessdateString] : [],
            ...consenttocontactpatientphysician ? [consenttocontactpatientphysician] : [],
            ...sendercomment ? [sendercomment] : []
          ]

          return narrative.join(`\n`)
        },
        props: {
          type: 'hidden',
          name: 'narrativeincludeclinical',
          label: 'Can save draft',
          required: false
        }
      }
    ]
  },
  source: {
    title: 'Source',
    schema: [
      {
        id: 'sourcetype',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'sourcetype',
          label: 'Source Type',
          required: true,
          options: [
            { label: 'Solicited', value: 'solicited' },
            { label: 'Spontaneous', value: 'spontaneous' }
          ]
        }
      },
      {
        id: 'programid',
        field: 'Input',
        props: {
          shrink: true,
          name: 'programid',
          label: 'Program ID',
          required: true,
          validate: [validators.maxLength(20)]
        }
      },
      {
        id: 'vendorcasereference',
        field: 'Input',
        props: {
          shrink: true,
          name: 'vendorcasereference',
          label: 'Vendor Case Reference #',
          required: true,
          validate: [validators.maxLength(20)]
        }
      },
      {
        id: 'partnerawarenessdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'partnerawarenessdate',
          label: 'Source/Date Vendor or Reporter Informed Company',
          required: true,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
          helperText: 'YYYY/MM/DD'
        }
      }
    ]
  },
  reporter: {
    title: 'Reporter',
    schema: [
      {
        id: 'reportergivename',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reportergivename',
          label: 'Reporter given name',
          required: false,
          validate: [validators.maxLength(360, 'Field')]
        }
      },
      {
        id: 'reporterfamilyname',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reporterfamilyname',
          label: 'Reporter family name',
          required: false,
          validate: [validators.maxLength(360, 'Field')]
        }
      },
      {
        id: 'qualification',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'qualification',
          label: 'Type of contact',
          options: [
            { label: 'Consumer/other non health professional', value: '10' },
            { label: 'Lawyer', value: '4' },
            { label: 'Other health professional', value: '9' },
            { label: 'Pharmacist', value: '3' },
            { label: 'Physician', value: '1' }
          ],
          required: false
        }
      },
      {
        id: 'reportercountry',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'reportercountry',
          label: 'Reporter country',
          autocomplete: true,
          options: countryCodes,
          required: false
        }
      },
      {
        id: 'reporterhcp',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'reporterhcp',
          label: 'Is reporter a Healthcare Professional?',
          required: false,
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ]
        }
      },
      {
        id: 'reporterorganization',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reporterorganization',
          label: 'Reporter organization',
          required: false,
          validate: [validators.maxLength(650, 'Field')]
        }
      },
      {
        id: 'reporteremail',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reporteremail',
          label: 'Reporter Email',
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'reportertel',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reportertel',
          label: 'Reporter Phone Number',
          required: false,
          validate: [validators.maxLength(20, 'Field')]
        }
      },
      {
        id: 'primaryreporter',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'primaryreporter',
          label: 'Is the Reporter email the primary source?',
          required: false,
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ]
        }
      },
      {
        id: 'donotreportname',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'donotreportname',
          label: 'Do not report name',
          required: false,
          options: [
            { value: '1', label: 'Yes' }
          ]
        }
      },
      {
        id: 'consenttocontactpatientphysician',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'consenttocontactpatientphysician',
          label: 'Consent to contact patient’s physician?',
          required: false,
          options: [
            { value: 'Patient consented to contact physician.', label: 'Yes' },
            { value: 'Patient did not consent to contacting the physician.', label: 'No' }
          ]
        }

      }
    ]
  },
  patient: {
    title: 'Patient',
    schema: [
      {
        id: 'patientinitial',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientinitial',
          label: 'Patient initials',
          required: true,
          validate: [validators.maxLength(360, 'Field')]
        }
      },
      {
        id: 'patientbirthdateformat',
        field: 'Input',
        computedValue: (values) => getAbbvieFormatForDate(values.patientbirthdate),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientbirthdateformat',
          label: 'Date of birth format',
          required: false
        }
      },
      {
        id: 'patientbirthdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'patientbirthdate',
          label: 'Date of birth',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYYMM', 'YYYY'])],
          helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
        }
      },
      {
        id: 'patientonsetage',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientonsetage',
          label: 'Age',
          required: false,
          validate: [validators.maxNumericLength(3, 'Field')]
        }
      },
      {
        id: 'patientonsetageunit',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientonsetageunit',
          label: 'Age Unit',
          required: false,
          options: [
            { value: '98', label: 'Unknown' },
            { value: '07', label: 'Years' },
            { value: '06', label: 'Months' },
            { value: '05', label: 'Weeks' },
            { value: '04', label: 'Days' },
            { value: '03', label: 'Hours' }
          ]
        }
      },
      {
        id: 'patientheight',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientheight',
          label: 'Height',
          required: false,
          validate: [validators.maxNumericLength(4, 'Field')]
        }
      },
      {
        id: 'patientheightunit',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientheightunit',
          label: 'Height unit',
          required: false,
          options: [
            { value: '1', label: 'Centimeter' },
            { value: '2', label: 'Inch' }
          ]
        }
      },
      {
        id: 'patientweight',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientweight',
          label: 'Weight',
          required: false,
          validate: [validators.maxNumericLength(6, 'Field')]
        }
      },
      {
        id: 'patientweightunit',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientweightunit',
          label: 'Weight unit',
          required: false,
          options: [
            { value: '1', label: 'Kilogram' },
            { value: '2', label: 'Pound' },
            { value: '3', label: 'Ounce' },
            { value: '4', label: 'Unknown' }
          ]
        }
      },
      {
        id: 'patientsex',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientsex',
          label: 'Patient Sex',
          required: false,
          options: [
            { value: '1', label: 'Male' },
            { value: '2', label: 'Female' }
          ]
        }
      },
      {
        id: 'patientpregnant',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientpregnant',
          label: 'Was the patient pregnant at the time of the adverse drug reaction?',
          required: false,
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' },
            { value: '8', label: 'Unknown' },
            { value: '9', label: 'Not specified' },
            { value: 'C97', label: 'Not reported' }
          ]
        }
      },
      {
        id: 'gestationperiod',
        field: 'Input',
        computedHidden: ({ patientpregnant }) => patientpregnant !== '1',
        props: {
          shrink: true,
          name: 'gestationperiod',
          label: 'Gestation Period',
          required: false,
          validate: [validators.maxNumericLength(4, 'Field')]
        }
      },
      {
        id: 'gestationperiodunit',
        field: 'Dropdown',
        computedHidden: ({ patientpregnant }) => patientpregnant !== '1',
        props: {
          shrink: true,
          name: 'gestationperiodunit',
          label: 'Gestation Period Unit',
          required: false,
          options: [
            { value: '04', label: 'Days' },
            { value: '06', label: 'Months' },
            { value: '07', label: 'Trimester' },
            { value: '05', label: 'Weeks' }
          ]
        }
      },
      {
        id: 'patienteddformat',
        field: 'Input',
        computedValue: (values) => getAbbvieFormatForDate(values.patientedd),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patienteddformat',
          label: 'EDD Format',
          required: false
        }
      },
      {
        id: 'patientedd',
        field: 'Date',
        computedHidden: ({ patientpregnant }) => patientpregnant !== '1',
        props: {
          shrink: true,
          endYearOffset: 1,
          name: 'patientedd',
          label: 'What is the Expected Delivery Date (EDD)?',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
          helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
        }
      },
      {
        id: 'lastmenstrualdateformat',
        field: 'Input',
        computedValue: (values) => getAbbvieFormatForDate(values.patientlastmenstrualdate),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'lastmenstrualdateformat',
          label: 'Last Menstrual Date Format',
          required: false
        }
      },
      {
        id: 'patientlastmenstrualdate',
        field: 'Date',
        computedHidden: ({ patientpregnant }) => patientpregnant !== '1',
        props: {
          shrink: true,
          name: 'patientlastmenstrualdate',
          label: 'Last Menstrual Date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
          helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
        }
      },
      {
        id: 'allergyyesno',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'allergyyesno',
          label: 'Does the patient have allergies?',
          required: false,
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ]
        }
      }
    ]
  },
  patientHistory: {
    title: 'Patient History',
    layout: [
      'patienthistory:12',
      'patientmedicalhistorytext:12'
    ],
    schema: [
      {
        id: 'patienthistory',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'patienthistory',
          label: 'Medical history episode',
          repeatableLabelPlaceholder: 'Episode',
          repeatableLabel: ['patientepisodename'],
          required: false,
          schema: [
            {
              id: 'patientepisodename',
              field: 'Input',
              props: {
                shrink: true,
                name: 'patientepisodename',
                label: 'Patient episode name',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'patientmedicalstartdateformat',
              field: 'Input',
              computedValue: (values) => getAbbvieFormatForDate(values.patientmedicalstartdate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientmedicalstartdateformat',
                label: 'Patient medical history start date format',
                required: true
              }
            },
            {
              id: 'patientmedicalstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'patientmedicalstartdate',
                label: 'Patient medical history start date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'patientmedicalenddateformat',
              field: 'Input',
              computedValue: (values) => getAbbvieFormatForDate(values.patientmedicalenddate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientmedicalenddateformat',
                label: 'Patient medical end date format',
                required: true
              }
            },
            {
              id: 'patientmedicalenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'patientmedicalenddate',
                label: 'Patient medical end date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
              }
            }
          ]
        }
      },
      {
        id: 'patientmedicalhistorytext',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientmedicalhistorytext',
          label: 'Medical history text',
          required: false,
          validate: [validators.maxLength(10000, 'Field')]
        }
      }
    ]
  },
  patientDeath: {
    title: 'Patient Death',
    schema: [
      {
        id: 'patientdeathdateformat',
        field: 'Input',
        computedValue: (values) => getAbbvieFormatForDate(values.patientdeathdate),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientdeathdateformat',
          label: 'Patient death date format',
          required: true
        }
      },
      {
        id: 'patientdeathdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'patientdeathdate',
          label: 'Patient death date',
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
          helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
        }
      },
      {
        id: 'patientautopsyyesno',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientautopsyyesno',
          label: 'Was an autopsy performed?',
          required: false,
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' },
            { value: '3', label: 'Unknown' }
          ]
        }
      },
      {
        id: 'autopsydate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'autopsydate',
          label: 'Date of autopsy',
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
          helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
        }
      },
      {
        id: 'autopsyresult',
        field: 'Input',
        props: {
          shrink: true,
          name: 'autopsyresult',
          label: 'Autopsy findings',
          required: false,
          validate: [validators.maxLength(200, 'Field')]
        }
      },
      {
        id: 'patientdeath',
        field: 'Repeatable',
        computedEditable: (values) => values.patientautopsyyesno === '1',
        props: {
          shrink: true,
          name: 'patientdeath',
          hideIfEmpty: true,
          required: true,
          label: 'Patient Autopsy',
          repeatableLabelPlaceholder: 'Autopsy',
          repeatableLabel: ['patientdeathreport'],
          schema: [
            {
              id: 'patientdetermineautopsy',
              field: 'Input',
              computedValue: ({ patientdeathreport }) => patientdeathreport ? '1' : null,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientdetermineautopsy',
                label: 'Patient Determine Autopsy',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'patientdeathreport',
              field: 'Input',
              props: {
                shrink: true,
                name: 'patientdeathreport',
                label: 'Autopsy-determined cause of death',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            }
          ]
        }
      }
    ]
  },
  reaction: {
    title: 'Reaction',
    layout: [
      'reaction:12'
    ],
    schema: [
      {
        id: 'reaction',
        field: 'Repeatable',
        props: {
          name: 'reaction',
          shrink: true,
          label: 'Reaction',
          repeatableLabel: ['primarysourcereaction'],
          repeatableLabelPlaceholder: 'Reaction',
          required: true,
          schema: [
            {
              id: 'primarysourcereaction',
              field: 'Input',
              props: {
                shrink: true,
                name: 'primarysourcereaction',
                label: 'Reaction as reported by primary source',
                required: true,
                validate: [validators.maxLength(200, 'Field')]
              }
            },
            {
              id: 'eventserious',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'eventserious',
                label: 'Serious?',
                required: false,
                options: [
                  { value: '01', label: 'Yes' },
                  { value: '02', label: 'No' }
                ]
              }
            },
            {
              id: 'seriousnessdeath',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'seriousnessdeath',
                label: 'Results in death',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' }
                ]
              }
            },
            {
              id: 'seriousnesslifethreatening',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'seriousnesslifethreatening',
                label: 'Life threatening',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' }
                ]
              }
            },
            {
              id: 'seriousnesshospitalization',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'seriousnesshospitalization',
                label: 'Caused hospitalization',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' }
                ]
              }
            },
            {
              id: 'seriousnesshospitalizationpro',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'seriousnesshospitalizationpro',
                label: 'Prolonged hospitalization',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' }
                ]
              }
            },
            {
              id: 'seriousnessdisabling',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'seriousnessdisabling',
                label: 'Disabling/Incapacitating',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' }
                ]
              }
            },
            {
              id: 'seriousnesscongenitalanomali',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'seriousnesscongenitalanomali',
                label: 'Congenital anomaly/birth defect',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' }
                ]
              }
            },
            {
              id: 'seriousnessother',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'seriousnessother',
                label: 'Other medically important',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' }
                ]
              }
            },
            {
              id: 'eventoccurcountry',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'eventoccurcountry',
                label: 'County of occurrence',
                autocomplete: true,
                options: countryCodes,
                required: false
              }
            },
            {
              id: 'medicallyconfirm',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'medicallyconfirm',
                label: 'Was the case medically confirmed, if not initially from health professional?',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' }
                ]
              }
            },
            {
              id: 'reactionstartdateformat',
              field: 'Input',
              computedValue: (values) => getAbbvieFormatForDate(values.reactionstartdate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'reactionstartdateformat',
                label: 'Reaction start date format',
                required: false
              }
            },
            {
              id: 'reactionstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'reactionstartdate',
                label: 'Reaction start date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'reactionenddateformat',
              field: 'Input',
              computedValue: (values) => getAbbvieFormatForDate(values.reactionenddate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'reactionenddateformat',
                label: 'Reaction end date format',
                required: false
              }
            },
            {
              id: 'reactionenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'reactionenddate',
                label: 'Reaction end date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'reactionoutcome',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'reactionoutcome',
                label: 'Reaction outcome',
                required: false,
                options: [
                  { value: '02', label: 'Death' },
                  { value: '12', label: 'Fetus fatal' },
                  { value: 'C01', label: 'Improved' },
                  { value: '09', label: 'Not recovered/not resolved' },
                  { value: '03', label: 'Permanent Sequelae' },
                  { value: '07', label: 'Recovered/resolved' },
                  { value: '10', label: 'Recovered/resolved with sequelae' },
                  { value: '08', label: 'Recovering/resolving' },
                  { value: '06', label: 'Unknown' },
                  { value: 'C02', label: 'Worsened' },
                  { value: '14', label: 'Not available' }
                ]
              }
            },
            {
              id: 'druginteraction',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'druginteraction',
                label: 'Drug interaction',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' }
                ]
              }
            }
          ]
        }
      }
    ]
  },
  test: {
    title: 'Test',
    schema: [
      {
        id: 'test',
        field: 'Repeatable',
        props: {
          name: 'test',
          shrink: true,
          label: 'Test',
          repeatableLabel: ['testname'],
          repeatableLabelPlaceholder: 'Test',
          required: false,
          schema: [
            {
              id: 'testname',
              field: 'Input',
              props: {
                shrink: true,
                name: 'testname',
                label: 'Test name',
                required: false,
                validate: [validators.maxLength(35, 'Field')]
              }
            },
            {
              id: 'testdateformat',
              field: 'Input',
              computedValue: (values) => getAbbvieFormatForDate(values.testdate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'testdateformat',
                label: 'Test date format',
                required: false
              }
            },
            {
              id: 'testdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'testdate',
                label: 'Test date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'testresult',
              field: 'Input',
              props: {
                shrink: true,
                name: 'testresult',
                label: 'Test result',
                required: false,
                validate: [validators.maxLength(50, 'Field')]
              }
            },
            {
              id: 'testunit',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'testunit',
                label: 'Test result (unit)',
                required: false,
                options: testunits
              }
            },
            {
              id: 'testresulttext',
              field: 'Input',
              props: {
                shrink: true,
                name: 'testresulttext',
                label: 'Test result text',
                required: false,
                validate: [validators.maxLength(2000, 'Field')]
              }
            }
          ]
        }
      }
    ]
  },
  drugs: {
    title: 'Drug(s)',
    schema: [
      {
        id: 'drug',
        field: 'Repeatable',
        props: {
          name: 'drug',
          shrink: true,
          label: 'Drug therapy',
          repeatableLabel: ['medicinalproduct', 'otherproduct'],
          repeatableLabelPlaceholder: 'Drug therapy',
          required: true,
          schema: [
            {
              id: 'drugcharacterization',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugcharacterization',
                label: 'Drug characterisation',
                required: true,
                options: [
                  { value: '1', label: 'Suspect' },
                  { value: '2', label: 'Concomitant' },
                  { value: '9', label: 'Drug not Administered' },
                  { value: '6', label: 'Drug used to treat AE' },
                  { value: '8', label: 'Interacting' },
                  { value: '7', label: 'Past' },
                  { value: '3', label: 'Premedication' }
                ]
              }
            },
            {
              id: 'otherproduct',
              field: 'Input',
              props: {
                shrink: true,
                name: 'otherproduct',
                label: 'Other product (if not produced by Abbvie)',
                required: false,
                validate: [validators.maxLength(70, 'Field')]
              }
            },
            {
              id: 'medicinalproduct',
              field: 'DrugLookupInput',
              props: {
                shrink: true,
                name: 'medicinalproduct',
                label: 'Medicinal product',
                required: false,
                validate: [validators.maxLength(255, 'Field')]
              }
            },
            {
              id: 'tradename',
              field: 'Input',
              computedValue: (values) => values.medicinalproduct,
              props: {
                type: 'hidden',
                shrink: true,
                name: 'tradename',
                label: 'Trade name',
                required: false,
                validate: [validators.maxLength(255, 'Field')]
              }
            },
            {
              id: 'obtaindrugcountry',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'obtaindrugcountry',
                label: 'Country where drug was obtained',
                required: false,
                options: countryCodes
              }
            },
            {
              id: 'actiondrug',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'actiondrug',
                label: 'Action taken with product',
                required: false,
                options: [
                  { value: 'C5', label: 'Dosage Decreased' },
                  { value: '5', label: 'Dose Increased' },
                  { value: 'C11', label: 'Dose Not Changed' },
                  { value: '2', label: 'Drug discontinued' },
                  { value: '3', label: 'Not Applicable' },
                  { value: '8', label: 'Unknown' }
                ]
              }
            },
            {
              id: 'dechallenge',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'dechallenge',
                label: 'Dechallenge product',
                required: false,
                options: [
                  { label: 'No', value: 'C2' },
                  { label: 'Not Applicable', value: '3' },
                  { label: 'Unknown', value: '4' },
                  { label: 'Yes', value: 'C1' }
                ]
              }
            },
            {
              id: 'rechallenge',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'rechallenge',
                label: 'Rechallenge product',
                required: false,
                options: [
                  { label: 'No rechallenge', value: '3' },
                  { label: 'Yes rechall, no recur', value: '2' },
                  { label: 'Yes rechall, unk recur', value: '4' },
                  { label: 'Yes rechall, yes recur', value: '1' }
                ]
              }
            },
            {
              id: 'drugbatchnumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugbatchnumb',
                label: 'Drug batch number',
                required: true,
                validate: [validators.maxLength(35, 'Field')]
              }
            },
            {
              id: 'expdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'expdate',
                endYearOffset: 5,
                label: 'Expiry Date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'drugstartdateformat',
              field: 'Input',
              computedValue: (values) => getAbbvieFormatForDate(values.drugstartdate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'drugstartdateformat',
                label: 'Drug start date format',
                required: false
              }
            },
            {
              id: 'drugstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'drugstartdate',
                label: 'Drug start date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'drugstartdatetext',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugstartdatetext',
                label: 'Drug start date text',
                required: false,
                validate: [validators.maxLength(30, 'Field')]
              }
            },
            {
              id: 'drugenddateformat',
              field: 'Input',
              computedValue: (values) => getAbbvieFormatForDate(values.drugenddate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'drugenddateformat',
                label: 'Drug start date format',
                required: false
              }
            },
            {
              id: 'drugenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'drugenddate',
                label: 'Drug end date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'drugenddatetext',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugenddatetext',
                label: 'Drug end date text',
                required: false,
                validate: [validators.maxLength(30, 'Field')]
              }
            },
            {
              id: 'drugtreatmentduration',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugtreatmentduration',
                label: 'Drug treatment duration',
                required: false,
                validate: [validators.maxNumericLength(5, 'Field')]
              }
            },
            {
              id: 'drugtreatmentdurationunit',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugtreatmentdurationunit',
                label: 'Drug treatment duration unit',
                required: false,
                options: [
                  {
                    'label': 'Days',
                    'value': '04'
                  },
                  {
                    'label': 'Hours',
                    'value': '03'
                  },
                  {
                    'label': 'Minutes',
                    'value': '02'
                  },
                  {
                    'label': 'Months',
                    'value': '06'
                  },
                  {
                    'label': 'Not reported',
                    'value': '97'
                  },
                  {
                    'label': 'Once',
                    'value': '11'
                  },
                  {
                    'label': 'Other',
                    'value': 'C99'
                  },
                  {
                    'label': 'Seconds',
                    'value': '01'
                  },
                  {
                    'label': 'Unknown',
                    'value': '98'
                  },
                  {
                    'label': 'Weeks',
                    'value': '05'
                  },
                  {
                    'label': 'Years',
                    'value': '07'
                  }
                ]
              }
            },
            {
              id: 'drugdurationtext',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugdurationtext',
                label: 'Drug duration text',
                required: false,
                validate: [validators.maxLength(40, 'Field')]
              }
            },
            {
              id: 'dailydose',
              field: 'Input',
              props: {
                shrink: true,
                name: 'dailydose',
                label: 'Daily dose',
                required: false,
                validate: [validators.maxNumericLength(9, 'Field')]
              }
            },
            {
              id: 'dailydoseunit',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'dailydoseunit',
                label: 'Daily dose unit',
                required: false,
                options: dailyDoseUnits
              }
            },
            {
              id: 'drugdosagetext',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugdosagetext',
                label: 'Drug dosage text',
                required: false,
                validate: [validators.maxLength(200, 'Field')]
              }
            },
            {
              id: 'unitdose',
              field: 'Input',
              props: {
                shrink: true,
                name: 'unitdose',
                label: 'Unit dose',
                required: false,
                validate: [validators.maxNumericLength(9, 'Field')]
              }
            },
            {
              id: 'unitdoseunit',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'unitdoseunit',
                label: 'Unit dose unit',
                required: false,
                options: dailyDoseUnits
              }
            },
            {
              id: 'drugdosageform',
              field: 'Dropdown',
              props: {
                shrink: true,
                autocomplete: true,
                name: 'drugdosageform',
                label: 'Drug dosage form',
                required: false,
                options: formOfAdministration
              }
            },
            {
              id: 'formstrength',
              field: 'Input',
              props: {
                shrink: true,
                name: 'formstrength',
                label: 'Form strength',
                required: false,
                validate: [validators.maxLength(11, 'Field')]
              }
            },
            {
              id: 'formstrengthunit',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'formstrengthunit',
                label: 'Form strength unit',
                required: false,
                options: formStrengthUnits,
                validate: [validators.maxLength(9, 'Field')]
              }
            },
            {
              id: 'drugadministrationroute',
              field: 'Dropdown',
              props: {
                shrink: true,
                autocomplete: true,
                name: 'drugadministrationroute',
                label: 'Drug administration route',
                required: false,
                options: administrationRoutes,
                validate: [validators.maxLength(9, 'Field')]
              }
            },
            {
              id: 'frequency',
              field: 'Input',
              props: {
                shrink: true,
                name: 'frequency',
                label: 'Frequency',
                required: false,
                validate: [validators.maxNumericLength(3, 'Field')]
              }
            },
            {
              id: 'frequencytime',
              field: 'Input',
              props: {
                shrink: true,
                name: 'frequencytime',
                label: 'Frequency Time',
                required: false,
                validate: [validators.maxNumericLength(3, 'Field')]
              }
            },
            {
              id: 'frequencytimeunit',
              field: 'Dropdown',
              props: {
                shrink: true,
                autocomplete: true,
                name: 'frequencytimeunit',
                label: 'Frequency Time Unit',
                required: false,
                options: [
                  { label: 'Cyclical', value: '14' },
                  { label: 'Days', value: '04' },
                  { label: 'Dose', value: 'C13' },
                  { label: 'Every othe rday', value: 'C14' },
                  { label: 'Hours', value: '03' },
                  { label: 'Minutes', value: '02' },
                  { label: 'Months', value: '06' },
                  { label: 'Once', value: '11' },
                  { label: 'Seconds', value: '01' },
                  { label: 'Total', value: '15' },
                  { label: 'Trimester', value: '13' },
                  { label: 'Weeks', value: '05' },
                  { label: 'Years', value: '07' }
                ],
                validate: [validators.maxNumericLength(3, 'Field')]
              }
            },
            {
              id: 'frequencytext',
              field: 'Input',
              props: {
                shrink: true,
                name: 'frequencytext',
                label: 'Frequency text',
                required: false,
                validate: [validators.maxLength(40, 'Field')]
              }
            },
            {
              id: 'indication',
              field: 'Repeatable',
              props: {
                formId: 'indicationform',
                name: 'indication',
                shrink: true,
                label: 'Indication',
                repeatableLabel: ['drugindication'],
                repeatableLabelPlaceholder: 'Indication',
                required: true,
                modalIndex: 1,
                schema: [
                  {
                    id: 'drugindication',
                    field: 'Input',
                    props: {
                      shrink: true,
                      name: 'drugindication',
                      label: 'Drug indication',
                      required: true,
                      validate: [validators.maxLength(250, 'Field')]
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    ]
  },
  causality: {
    title: 'Causality Assessment',
    layout: [
      'causalityAssessment:12'
    ],
    schema: [
      { // supress causality field not existing warning
        id: 'causality',
        field: 'input',
        props: {
          type: 'hidden'
        }
      },
      {
        id: 'causalityAssessment',
        field: 'CombinedRepeatable',
        props: {
          name: 'causalityAssessment',
          label: 'Causality',
          layout: [
            'causality:12'
          ],
          sourceField: {
            id: 'drug',
            groupKey: 'id',
            labelKey: 'medicinalproduct',
            labelPlaceholder: 'Drug therapy',
            labelExtra: ['otherproduct', 'indication.0.drugindication']
          },
          targetField: {
            id: 'reaction',
            labelKey: 'primarysourcereaction'
          },
          repeatableGroupId: 'causality',
          requiredFieldValue: 'causalityAssessmentRequired',
          prepopulate: true,
          emptyTranslationKey: 'Causality Empty',
          schema: [
            {
              id: 'causality',
              field: 'Repeatable',
              props: {
                hideIfEmpty: false,
                formId: 'causalityForm',
                name: 'causalityForm',
                label: 'Causality test',
                repeatableLabelPlaceholder: 'Reaction',
                repeatableLabel: ['targetName', 'drugassessmentsource', 'drugassessmentmethod', 'drugresult'],
                shrink: true,
                modalIndex: 1,
                schema: [
                  {
                    id: 'sourceName',
                    field: 'Input',
                    props: {
                      shrink: true,
                      name: 'sourceName',
                      label: 'Medicinal product',
                      disabled: true,
                      required: false
                    }
                  },
                  {
                    id: 'targetId',
                    field: 'Dropdown',
                    computedOptions: (values, parentFormValues) => {
                      return _.map(parentFormValues.reaction, (reaction) => {
                        return { value: reaction.id, label: _.get(reaction, 'reactionmeddrallt', _.get(reaction, 'primarysourcereaction')) }
                      })
                    },
                    props: {
                      shrink: true,
                      name: 'targetId',
                      label: 'Reaction assessed',
                      required: true,
                      disabledWhenPrepopulate: true
                    }
                  },
                  {
                    id: 'targetName',
                    field: 'Input',
                    computedValue: (values, parentFormValues) => {
                      const reaction = _.find(parentFormValues.reaction, { id: values.targetId })
                      return _.get(reaction, 'reactionmeddrallt', _.get(reaction, 'primarysourcereaction'))
                    },
                    props: {
                      type: 'hidden',
                      shrink: true,
                      name: 'targetName',
                      label: 'Reaction assessed name'
                    }
                  },
                  {
                    id: 'drugassessmentsource',
                    field: 'Input',
                    props: {
                      shrink: true,
                      name: 'drugassessmentsource',
                      label: 'Source of assessment',
                      required: true,
                      validate: [validators.maxLength(60, 'Field')]
                    }
                  },
                  {
                    id: 'drugassessmentmethod',
                    field: 'Input',
                    props: {
                      shrink: true,
                      name: 'drugassessmentmethod',
                      label: 'Method of assessment',
                      required: true,
                      validate: [validators.maxLength(35, 'Field')]
                    }
                  },
                  {
                    id: 'drugresult',
                    field: 'Input',
                    props: {
                      shrink: true,
                      name: 'drugresult',
                      label: 'Result of assessment',
                      required: true,
                      validate: [validators.maxLength(35, 'Field')]
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    ]
  },
  summary: {
    title: 'Summary',
    schema: [
      {
        id: 'sendercomment',
        field: 'Input',
        props: {
          shrink: true,
          name: 'sendercomment',
          label: `Sender's comments`,
          required: false,
          validate: [validators.maxLength(20000)]
        }
      }
    ]
  }
}

export default {
  initialFieldMap,
  formSchema
}
