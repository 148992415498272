const styles = theme => ({
  field: {
    marginBottom: '20px',
    width: '100%'
  },
  columnStyle: {
    padding: '0 20px',
    alignSelf: 'flex-start'
  },
  rowStyle: {
    marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0px'
    }
  },
  formContainer: {
    width: '100%'
  },
  fieldWrapper: {
    margin: '10px'
  },
  contentContainer: {
    margin: '20px 7.5%',
    display: 'flex',
    flexDirection: 'column'
  },
  submitError: {
    textAlign: 'right'
  },
  noDataMessage: {
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  dropDownStandard: {
    width: '100%'
  },
  flexGridContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  requiredDeactivateButtonContainer: {
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'space-between'
  },
  requiredText: {
    paddingRight: 10
  },
  requiredTextDeactivate: {
    margin: '20px 0 0 7.5%'
  },
  addDrugsListButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  duplicateButton: {
    marginRight: 10,
    [theme.breakpoints.up('sm')]: {
      marginRight: 20
    }
  }
})

export default theme => styles(theme)
