import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import P from '../../../components/P'
import Form, { FormBody } from '../../../components/Form'
import BasicModalOverlay from '../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../config'
import { constants as analyticsConstants } from '../../../store/modules/platformAnalytics'
import styles from './style'

const ExportForm = Form(analyticsConstants.EXPORT_ANALYTICS_FORM)

const ExportModal = (props) => {
  const {
    classes,
    actions,
    handleSubmit,
    schema,
    initialValues
  } = props 

  return (
    <BasicModalOverlay
      title={translations('Export Analytics - Modal Heading')}
      actions={actions}
    >
      <ExportForm onSubmit={handleSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <div className={classes.container}>
            <div className={classes.intro}>
              <P value={translations('Export Analytics - Intro')} />
            </div>
            <FormBody
              schema={schema}
              editing
              fullWidthFields
            />
          </div>
        </ContentBoxBody>
      </ExportForm>
    </BasicModalOverlay>
  )
}

ExportModal.propTypes = {
  classes: PropTypes.any,
  actions: PropTypes.array.isRequired,
  schema: PropTypes.array.isRequired,
  initialValues: PropTypes.shape({
    organisationId: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  })
}

export default withStyles(styles)(ExportModal)
