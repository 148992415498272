import React from 'react'

import Editable from '../Editable'
import { ImagePicker } from '../../../components/Fields'
import P from '../../P'
import { withStyles } from '@material-ui/core/styles'

export default Editable({
  input: (props) => {
    return (
      <ImagePicker {...props} />
    )
  },
  display: ({ label, value, format, hideIfEmpty, isEmpty }) => {
    if (isEmpty && hideIfEmpty) return null
    return (
      <div>
        <P value={label} type='textButton' />
        <DisplayImage src={value} alt='' />
      </div>
    )
  }
})

const style = {
  displayImage: {
    height: 50,
    maxWidth: '100%'
  }
}

const DisplayImage = withStyles(style)(({classes, ...props}) => {
  return <img className={classes.displayImage} {...props}/>
})
