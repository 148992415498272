import _ from 'lodash'
import digitalStoreSdk from '../../digitalStoreSdk'

class ReportUploadService {
  post = ({ file, userId, organisationId }) => {
    return digitalStoreSdk
      .reports
      .postReport({
        file,
        userId,
        organisationId
      })
  }

  load = ({ file, userId, organisationId, formSchemaId }) => {
    return digitalStoreSdk
      .reports
      .loadReport({
        file,
        userId,
        organisationId,
        formSchemaId
      })
  }
}

export default new ReportUploadService()
