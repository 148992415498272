import digitalStoreSdk from '../../../../digitalStoreSdk'
import _ from 'lodash'
import i18next from 'i18next'

export const createImplementation = ({ props }) => ({
  minimumSearchLength: 3,
  multiple: false,
  asyncFunction: async ({
    value,
  }) => {
    const searchParams = {
      query: value,
      organisationId: props.organisationId,
      languageCode: i18next.language
    }

    const data = await digitalStoreSdk.drugs.searchDrugs(searchParams)
    const { rows } = data

    const mappedOptions = _.map(rows, row => ({
      value: row.id,
      label: row.name
    }))

    return mappedOptions
  },
  renderOption: (option) => {
    return (
      <span>{option.label}</span>
    )
  }
})
