import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import Form from '../../../../components/Form'

import FormBody from '../../../../components/Form/FormBody'
import FormError from '../../../../components/Form/FormError'
import FormSubmit from '../../../../components/Form/FormSubmit'
import PersonalDetails from '../../../../components/Form/FormSections/PersonalDetails'
import CommunicationPreferences from '../../../../components/Form/FormSections/CommunicationPreferences'
import BottomBar from '../../../../components/BottomBar'
import schema from '../../../../components/Form/commonSchemas/userPersonalDetails'
import SetPassword from '../../../Users/UserRegistrationScreen/UserRegistrationForm/SetPassword'
import Terms from '../UserRegistrationForm/Terms'
import { translations } from '../../../../config'
import { formStyle } from './style'
import UserGroupAndOrganisation from './UserGroupAndOrganisation'

export const formId = 'user-registration'

const MoreUserRegistrationForm = Form(formId)

const MoreUserRegistrationFormWrapper = ({
  onSubmit,
  isFormValid,
  isPublic,
  telephone,
}) => {

  return (
    <div>
      <MoreUserRegistrationForm onSubmit={onSubmit} submitLabel={translations('Create user')}>
        <FormBody>
          <div>
            <UserGroupAndOrganisation
              formId={formId}
            />
            <PersonalDetails
              formId={formId}
              schema={schema({ allFieldsMandatory: true })}
            />
            <CommunicationPreferences
              formId={formId}
              telephone={telephone}
            />
            <SetPassword />
            {isPublic ? <Terms label={'User Registration - Terms and Conditions Agreement'} /> : null}
          </div>
        </FormBody>
        <FormError />
        <FormSubmit
          ContainerComponent={BottomBar}
          label={translations('Create user')}
          disabled={isFormValid}
        />
      </MoreUserRegistrationForm>
    </div>
  )
}

MoreUserRegistrationFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
}


export default withStyles(formStyle)(MoreUserRegistrationFormWrapper)
