import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../../../config'
import { constants as formViewConstants } from '../../../../../store/modules/formViews'

const ConfigureReportForm = Form(formViewConstants.CONFIGURE_REPORT_FORM, { enableReinitialize: true })

const ConfigureModal = (props) => {
  const {
    handleSubmit,
    actions,
    formViewDetails,
    audiences,
    emailTemplateOptions,
    sourceOptions = [],
    reportTypeOptions = [],
    observeStudyTypeOptions = [],
    categoryOptions = [],
    subCategoryOptions = [],
    nullFlavourOptions = []
  } = props

  return (
    <BasicModalOverlay
      title={translations('Configure Report')}
      actions={actions}
    >
      <ConfigureReportForm onSubmit={handleSubmit} initialValues={formViewDetails}>
        <ContentBoxBody>
          <FormBody
            layout={[
              'category:6',
              'subCategory:6',
              'reporttype:6',
              'observestudytype:6'
            ]}
            schema={[
              {
                id: 'category',
                field: 'Dropdown',
                props: {
                  label: 'Category',
                  name: 'category',
                  options: categoryOptions
                }
              },
              {
                id: 'subCategory',
                field: 'Dropdown',
                props: {
                  label: 'Sub Category',
                  name: 'subCategory',
                  options: subCategoryOptions
                }
              },
              {
                id: 'reporttype',
                field: 'Dropdown',
                props: {
                  label: 'Report Type',
                  name: 'reporttype',
                  options: reportTypeOptions
                }
              },
              {
                id: 'observestudytype',
                field: 'Dropdown',
                props: {
                  label: 'Study type',
                  name: 'observestudytype',
                  options: observeStudyTypeOptions
                }
              },
              {
                id: 'audienceId',
                field: 'Dropdown',
                props: {
                  label: 'Audience',
                  name: 'audienceId',
                  options: audiences,
                  noNullOption: true
                }
              },
              {
                id: 'senderevid',
                field: 'Input',
                props: {
                  label: 'Report Identifier',
                  name: 'senderevid'
                }
              },
              {
                id: 'emailTemplateId',
                field: 'Dropdown',
                props: {
                  label: `${translations('Acknowledgement email template')}`,
                  name: 'emailTemplateId',
                  options: emailTemplateOptions,
                  disabled: _.size(emailTemplateOptions) < 1,
                  shrink: true
                }
              },
              {
                id: 'sources',
                field: 'Dropdown',
                props: {
                  label: `${translations('Source')}`,
                  name: 'sources',
                  options: sourceOptions,
                  multiple: true,
                  disabled: _.size(sourceOptions) < 1,
                  shrink: true
                }
              },
              {
                id: 'showNullFlavours',
                field: 'Dropdown',
                props: {
                  label: `${translations('Report Configuration - Null flavours label')}`,
                  name: 'showNullFlavours',
                  options: nullFlavourOptions,
                  multiple: false
                }
              },
              {
                id: 'waiveArtefactValidation',
                field: 'Checkbox',
                props: {
                  label: 'Skip internal XML validation (Standard E2B ICH R3 medicines form)',
                  name: 'waiveArtefactValidation'
                }
              },
              {
                id: 'useEuRules',
                field: 'Checkbox',
                props: {
                  label: 'Use EU validation (Standard E2B ICH R3 medicines form)',
                  name: 'useEuRules'
                }
              },
              {
                id: 'aefilogic',
                field: 'Checkbox',
                props: {
                  label: 'Is a Adverse Events Following Immunisation (AEFI) report (Standard E2B ICH R3 medicines form)',
                  name: 'aefilogic'
                }
              },
              {
                id: 'isLateralFlowReport',
                field: 'Checkbox',
                props: {
                  label: 'Is a Lateral Flow Report (MIR)',
                  name: 'isLateralFlowReport'
                }
              },
              {
                id: 'isSyringeReport',
                field: 'Checkbox',
                props: {
                  label: 'Is a Syringe Report (MIR)',
                  name: 'isSyringeReport'
                }
              },
              {
                id: 'mandatoryMedDRA',
                field: 'Checkbox',
                props: {
                  label: 'Allow Mandatory MedDRA',
                  name: 'mandatoryMedDRA'
                }
              },
              {
                id: 'receiverEmail',
                field: 'Input',
                props: {
                  label: 'Override Internal Report Notification Email Address',
                  name: 'receiverEmail'
                }
              }
            ]}
            editing
            fullWidthFields
          />
        </ContentBoxBody>
      </ConfigureReportForm>
    </BasicModalOverlay>
  )
}

ConfigureModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  formViewDetails: PropTypes.object,
  audiences: PropTypes.array,
  emailTemplateOptions: PropTypes.array,
  sourceOptions: PropTypes.array,
  reportTypeOptions: PropTypes.array,
  observeStudyTypeOptions: PropTypes.array
}

export default ConfigureModal
