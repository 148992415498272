import react from 'react'
import _ from 'lodash'
import { addQuery } from '../../../../helpers/query'
import { asyncActions, actions }  from '../../../../store/modules/terminology'

export const useHandleTabInQuery = (currentQuery, tabs) => react.useEffect(() => {
    const isInTabs = _.findIndex(tabs, { value: currentQuery.currentTab }) !== -1
    if (_.isUndefined(currentQuery.currentTab) || !isInTabs) {
        addQuery({ currentTab: "receivers" })
    }
}, [currentQuery, tabs])

export const useGetTerminologyArrangement = ({ dispatch }) => react.useEffect(() => {
    (async () => {
        await dispatch(asyncActions.getTerminologyArrangements())
        await dispatch(actions.setFirstArrangementAsActive())
    })()
}, [])

export const useSetActiveTerminologyArrangement = ({ dispatch }) => react.useCallback((e, selectedTab) => {
    (async () => {
        await dispatch(actions.setArrangementAsActive({ activeArrangementId: selectedTab }))
    })()
})
