import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  size: 20,
  page: 1,
  totalNotificationsNotViewed: 0,
  filters: {
    isFlagged: false,
    toDo: false
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_PERSONAL_NOTIFICATIONS_FRESH:
      return reducers.fetchPersonalNotificationsFresh(state, action)
    case constants.FETCH_PERSONAL_NOTIFICATIONS_NEXT:
      return reducers.fetchPersonalNotificationsNext(state, action)
    case constants.FETCH_USER_NOTIFICATIONS_FRESH:
      return reducers.fetchUserNotificationsFresh(state, action)
    case constants.FETCH_USER_NOTIFICATIONS_NEXT:
      return reducers.fetchUserNotificationsNext(state, action)
    case constants.SET_NOTIFICATION_USER_STATUS:
      return reducers.setNotificationUserStatus(state, action)
    case constants.SET_TOTAL_NOTIFICATIONS_NOT_VIEWED:
      return reducers.setTotalNotificationsNotViewed(state, action)
    case constants.GET_TOTAL_NOTIFICATIONS_NOT_VIEWED:
      return reducers.getTotalNotificationsNotViewed(state, action)
    case constants.SET_NOTIFICATION_ACTIONED_STATUS:
      return reducers.setNotificationActionedStatus(state, action)
    case constants.UPDATE_NOTIFICATION_FILTERS:
      return reducers.updateNotificationFilters(state, action)
    case constants.REMOVE_NOTIFICATION_FROM_LIST:
      return reducers.removeNotificationFromList(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
