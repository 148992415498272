const styles = theme => {
  return {
    container: {
      position: 'relative'
    },
    content: {
      position: 'relative',
      paddingBottom: theme.spacing(5)
    },
    message: {
      padding: theme.spacing(3),
      textAlign: 'center',
      textTransform: 'uppercase'
    }
  }
}

export default styles
