import { PENDING } from '../../middleware/redux-promise'
import { createSelector } from 'reselect'
import _ from 'lodash'
import fp from 'lodash/fp'
import * as constants from './constants'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

import { translations } from '../../../config'

const ROOT_KEY = 'platformAnalytics'
export const getOrganisationAnalytics = state => fp.get([ROOT_KEY, 'result'], state) 

export const getIsLoadingOrganisationAnalytics = state => fp.get([ROOT_KEY, 'status'], state) === PENDING && fp.get([ROOT_KEY, 'action'], state) === constants.GET_ORGANISATION_ANALYTICS

export const getIsfetchingOrganisationAnalyticsExport = state => {
    return fp.get([ROOT_KEY, 'status'], state) === PENDING && fp.get([ROOT_KEY, 'action'], state) === constants.EXPORT_ANALYTICS
}

export const getFilters = fp.getOr({}, [ROOT_KEY, 'filters'])
const getFilter = (filterName, defaultTo) => createSelector([getFilters], filters => fp.getOr(defaultTo, filterName, filters))
export const getSelectedOrganisationId = getFilter('organisationId', '')
export const getTimePeriodDates = getFilter('timePeriod', {})

export const getSelectedOrganisationAnalytics = state => {
    return fp.get([ROOT_KEY, 'result'], state) 
}

export const getSourcesForSelectedOrganisationAsOptionsIncludedAll = createSelector([
  platformSelectors.getSourcesForPlatformAsOptions
  ], (options = []) => {
    const combinedOptions = [{label: translations('Analytics Option - All Sources'), value: 'AllSources'}, ...options]
    return combinedOptions
  })