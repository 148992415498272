import { PENDING } from '../../middleware/redux-promise'
import { createSelector } from 'reselect'
import fp from 'lodash/fp'
export const getState = fp.get('tags')
export const getIsLoading = (state) => getState(state).status === PENDING
export const getResult = (state) => getState(state).result
export const getError = (state) => state.error
export const getSize = (state) => state.size
/**
 * Get all tags as dropdown options with names as both value and label.
 */
export const getTagNamesAsDropdownOptions = createSelector([getResult], (results = {}) => {
    return fp.compose(
        fp.map(tag => {
            const name = fp.get('name', tag)
            return {
                value: name,
                label: name
            }
        }),
        fp.get('tags')
    )(results)
})
/**
 * Get tags used by newsFeedContent as options for dropdown boxes with the tag's name as the label and tag's id as the value.
 */
export const getNewsFeedContentTagsAsDropdownOptions = createSelector([getResult], (results = {}) => {
    const [tags = {}, newsFeedContent = []] = fp.at(['tags', 'resources.newsFeedContent'], results)
    return fp.map((id) => {
        return {
            value: id,
            label: fp.get([id, 'name'], tags)
        }
    }, newsFeedContent)
})
/**
 * Resolves given tag IDs to their names, such as when being used to query the API
 * The returned selector always returns an array of strings.
 * @param {string | string[]} ids Tag ID(s)
 */
export const resolveIdToName = (ids) =>
    createSelector([getResult], (results = {}) => {
    const { tags } = results
    return fp.compose(
        fp.map(id => fp.get([id, 'name'], tags)),
        fp.castArray
        )(ids)
})
