const styles = theme => {
  return ({
    searchContainer: {
      maxWidth: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
        minWidth: '320px',
      },
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  })
}

export default styles
