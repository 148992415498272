import _ from 'lodash'
import React from 'react'
import Section from '../Section'
import { ActionButton } from '../ReportSummaryModal'
import { translations } from '../../../../../config'
import { useSelector } from 'react-redux'
import { selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import ReportEnhancementModal from '../../../../../components/ReportEnhancementModal'
import modalService from '../../../../../services/modalService'

const MiscActions = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection,
    report
  } = props

  const hasEnhancements = useSelector(reportDetailsSelectors.getHasEnhancements)
  const enhancements = useSelector(reportDetailsSelectors.getEnhancements)

  const viewEnhancements = async () => {
    modalService.open({
      component: ReportEnhancementModal,
      enhancements,
      largeModal: true,
      modalIndex: 2
    })
  }

  const viewEnhancementsButton = 
    <ActionButton onClick={viewEnhancements}>
      {translations('View Enhancements')}
    </ActionButton>

  // Need to be implemented
  const performAnAutomation = <ActionButton>{translations('Perform An Automation')}</ActionButton>
  const validateXML = <ActionButton>{translations('Validate XML')}</ActionButton>

  const rows = [
    { type: 'ACTION', value: viewEnhancementsButton }
    // { type: 'ACTION', value: performAnAutomation },
    // { type: 'ACTION', value: validateXML },
  ]

  if(!hasEnhancements) {
    return <></>
  }

  return (
    <Section
      name='Miscellaneous'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
    />
  )
}

export default MiscActions