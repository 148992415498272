import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import P from '../../../../components/P'

import style from './style'

let InChart = props => {
  const { name, value, classes } = props
  return (
    <div className={classes.simpleItemInner}>
      <P
        type='textButton'
        value={name}
        className={classes.simpleItemText}
      />
      <P
        type='textButton'
        value={value}
        className={classes.simpleItemValue}
      />
    </div>
  )
}

InChart.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.shape({})
}

InChart = withStyles(style)(InChart)

export { InChart }
