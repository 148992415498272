import FormViewContext, { useFormViews } from './FormViewContext'
import FollowUpContext from './FollowUpContext'
import ReportConfigurationEditorContext, { useRCEditor } from './ReportConfigurationEditorContext'
import ReportConfigurationSidebarContext, { useSidebar } from './ReportConfigurationSidebarContext'
import ConditionsContext, { useConditions } from './ConditionsContext'
import _ from 'lodash'

export const useRCDetails = () => {
  const [formViews, conditions, sidebar, editor] = [useFormViews(), useConditions(), useSidebar(), useRCEditor()]
  return {
    edited: _.get(formViews, 'state.isEdited') || _.get(conditions, 'state.isEdited') || _.get(sidebar, 'state.isEdited'),
    isNew: _.get(editor, 'state.isNewFormView')
  }
}

export {
  FormViewContext,
  FollowUpContext,
  ReportConfigurationEditorContext,
  ReportConfigurationSidebarContext,
  ConditionsContext,
  useFormViews,
  useConditions,
  useSidebar,
  useRCEditor
}