import React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import Skeleton from '@material-ui/lab/Skeleton'
import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'
import Heading from '../../../../components/Heading'
import RightButtonContent from '../../../../components/Form/RightButtonContent'
import Button from '../../../../components/Button'

import { styled } from '@material-ui/core/styles'
import { translations } from '../../../../config'
import modalService from '../../../../services/modalService'
import SubmissionServiceUserModal from './SubmissionServiceUserModal'
import SubmissionServiceUsersTable from './SubmissionServiceUsersTable'

import { useOrganisation } from '../../../../store/modules/auth/hooks'
import { PENDING } from '../../../../store/middleware/redux-promise'
import { getApplications } from '../../../../store/modules/developer/selectors'

const Content = styled('div')({
  width: '100%'
})

const Action = styled('div')(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  }
}))

const SkeletonContainer = styled('div')(({ theme }) => ({
  '& > span': {
    marginBottom: theme.spacing(1)
  }
}))

const LoadingState = () => {
  return (
    <SkeletonContainer>
      <Skeleton variant={'rect'} height={45} />
      <Skeleton variant={'text'} />
      <Skeleton variant={'text'} />
      <Skeleton variant={'text'} />
      <Skeleton variant={'text'} />
    </SkeletonContainer>
  )
}

const SubmissionServiceUsersPanel = (props) => {
  const {
  } = props

  const organisationId = useOrganisation()
  const submissionServiceUsers = useSelector(getApplications)
  const isLoading = _.get(submissionServiceUsers, 'status') === PENDING
  const data = _.get(submissionServiceUsers, 'result')

  const onCreate = () => {
    modalService.open({
      component: SubmissionServiceUserModal,
      organisationId
    })
  }

  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading>{translations(`Submission Service Users`)}</Heading>
        </TextContent>
        <RightButtonContent>
          <Action>
            <Button
              onClick={onCreate}
              disabled={isLoading}
              raised
              color='primary'
            >
              {translations('Create User')}
            </Button>
          </Action>
        </RightButtonContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <Content>
          {isLoading && <LoadingState />}
          {!isLoading &&
            <SubmissionServiceUsersTable
              data={data}
            />
          }
        </Content>
      </ContentBoxBody>
    </ContentBox>
  )
}

export default SubmissionServiceUsersPanel
