import { connect } from 'react-redux'
import { change } from 'redux-form'
import LoginForm from './LoginForm'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })
const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const { from, isAssessorLogin = false } = ownProps
  const currentUsername = authSelectors.getActiveUsername(state)

  const changeField = (field, value) => {
    return dispatch(change('login', field, value))
  }

  const onSubmit = ({ email, username, password }) => {
    return dispatch(authActions.login({ email, username, password, from, isAssessorLogin }))
  }

  return {
    ...ownProps,
    onSubmit,
    currentUsername,
    changeField
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(LoginForm)
