import _ from 'lodash'
import { connect } from 'react-redux'
import { isSubmitting, isInvalid, submit, getFormValues } from 'redux-form'
import {
  compose,
  withHandlers,
  withPropsOnChange
} from 'recompose'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'

import {
  selectors as formViewSelectors,
  actions as formViewActions,
  constants as formViewConstants
} from '../../../../../store/modules/formViews'

import { selectors as communicationTemplateSelectors } from '../../../../../store/modules/communicationTemplates'
import { getCurrentOrganisationSources } from '../../../../../store/modules/combinedSelectors/authCombinedSelectors'

import modalService from '../../../../../services/modalService'
import toastService from '../../../../../services/toastService'
import { translations } from '../../../../../config'
import ConfigureModal from './ConfigureModal'

const formSchemaLibrary = new FormSchemaLibrary()

const enhancer = compose(
  connect((state) => {
    return {
      isSubmitting: isSubmitting(formViewConstants.CONFIGURE_REPORT_FORM)(state),
      isInvalid: isInvalid(formViewConstants.CONFIGURE_REPORT_FORM)(state),
      formViewId: formViewSelectors.getCurrentFormViewId(state),
      formViewDetails: formViewSelectors.getCurrentFormViewDetails(state),
      formViewCategory: formViewSelectors.getCurrentFormViewCategory(state),
      formViewSources: formViewSelectors.getCurrentFormViewSources(state),
      audiences: formViewSelectors.getAudiencesAsDropdown(state),
      acknowledgementTemplates: communicationTemplateSelectors.getOrganisationAcknowledgementTemplates(state),
      organisationSources: getCurrentOrganisationSources(state),
      formValues: getFormValues(formViewConstants.CONFIGURE_REPORT_FORM)(state),
      formViewSubCategory: formViewSelectors.getCurrentFormViewSubCategory(state)
    }
  }),
  withHandlers({
    closeModal: () => () => {
      modalService.close()
    },
    onSubmit: ({ dispatch }) => () => {
      dispatch(submit(formViewConstants.CONFIGURE_REPORT_FORM))
    },
    handleSubmit: (props) => async (formData) => {
      try {
        const { dispatch, onSave } = props
        let { formViewId } = props
        if (!formViewId) {
          const formView = await onSave()
          formViewId = _.get(formView, 'formViewId')
        }

        const details = _.mapValues(
          _.pick(formData, [
            'showNullFlavours',
            'waiveArtefactValidation',
            'artefactFormat',
            'audienceId',
            'emailTemplateId',
            'senderevid',
            'useEuRules',
            'aefilogic',
            'isLateralFlowReport',
            'isSyringeReport',
            'mandatoryMedDRA',
            'reporttype',
            'observestudytype',
            'useNoReceiver',
            'receiverEmail'
          ]),
          value => (value === '' || _.isNull(value)) ? undefined : value
        )
        const [category, subCategory] = _.at(formData, ['category', 'subCategory'])
        const sources = _.get(formData, 'sources', [])

        await dispatch(formViewActions.updateFormView({
          id: formViewId,
          params: {
            details,
            sources,
            ...!_.isUndefined(category) && { category },
            ...!_.isUndefined(subCategory) && { subCategory }
          }
        }))
        await dispatch(formViewActions.fetchFormViewById({ id: formViewId }))

        modalService.close()
        toastService.action({
          type: 'success',
          message: translations('Successfully - Save Report Config')
        })
      } catch (e) {
        toastService.action({
          type: 'error',
          message: translations('Failure - Save Report Config')
        })
      }
    }
  }),
  withPropsOnChange(['formViewDetails', 'acknowledgementTemplates', 'formViewCategory', 'formViewSources', 'formViewSubCategory'], (props) => {
    const { formViewDetails = {}, acknowledgementTemplates, formViewCategory, formViewSources, formViewSubCategory } = props
    const defaultOptions = formSchemaLibrary.getOptions()
    const sources = _.map(formViewSources, 'id')
    let mergedOptions = { ...defaultOptions, ...formViewDetails, category: formViewCategory, sources, subCategory: formViewSubCategory }
    const emailTemplateId = _.get(mergedOptions, 'emailTemplateId')

    if (!_.find(acknowledgementTemplates, { id: emailTemplateId })) {
      mergedOptions = _.omit(mergedOptions, 'emailTemplateId')
    }

    return { formViewDetails: mergedOptions }
  }),
  withPropsOnChange(['acknowledgementTemplates'], (props) => {
    const { acknowledgementTemplates } = props
    const emailTemplateOptions = _.map(acknowledgementTemplates, (template) => {
      return {
        value: _.get(template, 'id'),
        label: _.get(template, 'name')
      }
    })
    return { emailTemplateOptions }
  }),
  withPropsOnChange(['organisationSources'], (props) => {
    const { organisationSources = [] } = props
    const sourceOptions = _.map(organisationSources, (source) => {
      return {
        value: _.get(source, 'id'),
        label: _.get(source, 'name')
      }
    })
    return { sourceOptions }
  }),
  withPropsOnChange([], () => {
    const reportTypeOptions = [
      { value: '1', label: 'Spontaneous' },
      { value: '2', label: 'Report from study' },
      { value: '3', label: 'Other' },
      { value: '4', label: 'Not available to sender (unknown)' }
    ]
    return { reportTypeOptions }
  }),
  withPropsOnChange([], () => {
    const observeStudyTypeOptions = [
      { value: '1', label: 'Clinical trials' },
      { value: '2', label: 'Individual patient use' },
      { value: '3', label: 'Other studies' }
    ]
    return { observeStudyTypeOptions }
  }),
  withPropsOnChange([], () => {
    const categoryOptions = formViewSelectors.categoryOptions
    const subCategoryOptions = formViewSelectors.subCategoryOptions
    return { categoryOptions, subCategoryOptions }
  }),
  withPropsOnChange([], () => {
    const nullFlavourOptions = [
      {value: true, label: 'Show Null Flavour selector on questions where supported'},
      {value: false, label: 'Hide Null Flavour selector and use default values'  }
    ]
    return { nullFlavourOptions }
  }),
  withPropsOnChange(['isSubmitting'], (props) => {
    const { onSubmit, closeModal, isSubmitting } = props
    return {
      actions: [
        {
          success: true,
          text: translations('Save'),
          submitting: isSubmitting,
          onClick: onSubmit,
          primary: true
        },
        {
          text: translations('Cancel'),
          onClick: closeModal
        }
      ]
    }
  })
)

export default enhancer(ConfigureModal)
