import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'

import NewPlatformScreen from './NewPlatformScreen'
import { selectors as platformDetailsSelectors, actions as platformDetails } from '../../../store/modules/platformDetails'
import { selectors as organisationsSelectors } from '../../../store/modules/organisations'
import { selectors as themesSelectors } from '../../../store/modules/themes'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { platformCombinedSelectors } from '../../../store/modules/combinedSelectors'
import FullScreenLoader from '../../../components/FullScreenLoader'
import { platformDetails as schema } from '../formSchemas/platformDetailsSchema'

const mapStateToProps = state => {
  const isLoading =
    platformDetailsSelectors.getIsLoading(state) ||
    organisationsSelectors.getOrganisationsIsLoading(state) ||
    themesSelectors.getIsLoadingAllThemes(state)

  const dropdownOrganisations = platformCombinedSelectors.getAvailableOrganisationsForPlatformOptions(state)

  const res = {
    isLoading,
    dropdownOrganisations,
    schema,
    pageTitle: platformSelectors.getPageTitle(state)('Create Platform')
  }

  return res
}

const enhancer = compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, match: { params: { id } } }) => (fields) => {
      return dispatch(platformDetails.createPlatform({ fields }))
    }
  }),
  withPropsOnChange(
    ['dropdownOrganisations'], (props) => {
      const { dropdownOrganisations } = props
      const updatedSchema = _.cloneDeep(schema)
      const organisationsIndex = _.findIndex(updatedSchema.schema, field => field.id === 'organisations')
      if(updatedSchema.schema[organisationsIndex] && updatedSchema.schema[organisationsIndex].props) {
        updatedSchema.schema[organisationsIndex].props.options = [dropdownOrganisations]
      }
      return { schema: updatedSchema }
    }
  ),
  FullScreenLoader
)

export default enhancer(NewPlatformScreen)
