export default theme => {
  return {
    link: {
      width: '100%',
      justifyContent: 'start',
      backgroundColor: '#fff',
      border: `1px solid ${theme.palette.grey[300]}`,
      paddingLeft: 14,
      paddingRight: 14,
      marginBottom: 14,
      transition: '.3s border-color',
      '&:hover, &:active, &:focus': {
        borderColor: theme.palette.primary.main
      },
      '&:focus $label': {
        textDecoration: 'underline'
      }
    },
    checkbox: {
      marginRight: 0
    },
    label: {
      ...theme.p.p,
      paddingTop: 14,
      paddingBottom: 14,
      display: 'block',
      lineHeight: 1.5
    }
  }
}
