import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import c from 'classnames'

import style from './style'

const A = ({ href, target, classes, className, value }) => (
  <a href={href} target={target} className={c(classes.a, className)}>{value}</a>
)

A.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string
}

export default withStyles(style)(A)
