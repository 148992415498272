import { connect } from 'react-redux'
import { compose } from 'recompose'

import { selectors as platformSelectors } from '../../../store/modules/platforms'
import CompanyAccountRequestScreen from './CompanyAccountRequestScreen'

const mapStateToProps = state => {
  return {
    ...state,
    pageTitle: platformSelectors.getPageTitle(state)('Request company Title')
  }
}

export default compose(
  connect(
    mapStateToProps
  )
)(CompanyAccountRequestScreen)
