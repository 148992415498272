import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import style from '../../style'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { FormControl } from '@material-ui/core'
import { Dropdown } from '../../../../../components/EditableFields'

import { translations } from '../../../../../config'
import { useSelector } from 'react-redux'
import { getSelectedUserUserGroups } from '../../../../../store/modules/userDetails/selectors'

const UserGroups = (props) => {
  const {
    classes,
  } = props

  const userGroups = useSelector(getSelectedUserUserGroups)
  const [displayValue, setDisplayValue] = useState()

  useEffect(() => {
    if(userGroups && !_.isEmpty(userGroups)) {
      const displayValue = _.map(userGroups, userGroup => userGroup.name)
      setDisplayValue(displayValue)
    }
  }, [userGroups])

  if(!userGroups || _.isEmpty(userGroups)) {
    return <></>
  }

  return (
    <Grid item xs={12} sm={6}>
      <FormControl className={classes.dropDownStandard}>
      <Dropdown
          label={translations('User Form - User Group Dropdown Label')}
          name={'userGroups'}
          editing={false}
          classes={{root: classes.dropDownStandard}}
          displayValue={displayValue}
          strict
        />
      </FormControl>
    </Grid>
  )
}

export default withStyles(style)(UserGroups)
