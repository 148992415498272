import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  results: [],
  permissions: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_ALL_FORMSCHEMAS:
      return reducers.fetchAllFormSchemas(state, action)
    case constants.UPDATE_FORMSCHEMA:
      return reducers.updateFormSchema(state, action)
    case constants.TOGGLE_PERMISSION:
      return reducers.togglePermission(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
