import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { compose } from 'recompose'
import moment from 'moment'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { translations } from '../../../../config'
import DatePicker from '../../../../components/Fields/DatePicker'
import { withFiltersContainer } from '../../../../components/FiltersContainer'
import { withStyles } from '@material-ui/core/styles'
import { actions } from '../../../../store/modules/announcementManagement'
import * as filtersLogic from './reducer'
import styles from './style'
import { useDispatch } from 'react-redux'

export const handleFilter = ({ type, dispatch }) => event => {
  return dispatch({
    type,
    payload: {
      value: _.has(event, 'target') ? event.target.value : event
    }
  })
}
const AnnouncementManagementSearchFilters = (props) => {
  const {
    classes,
  } = props

  const [stateFilters, dispatch] = React.useReducer(filtersLogic.reducer, {}, filtersLogic.initialiseState({
    dateFrom: moment().subtract(1, 'week').format('MM/DD/YYYY'),
    dateTo: moment().format('MM/DD/YYYY')
  }))
  const dispatchRedux = useDispatch()
  // Callback needs to take debounce, regardless of exhaustive-deps.
  // https://stackoverflow.com/a/61786423
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFilterChange = React.useCallback(_.debounce(filters => {
    dispatchRedux(actions.updateFilters({ filters }))
  }, 100), [dispatchRedux])

  React.useEffect(() => {
    onFilterChange(stateFilters)
  }, [stateFilters, onFilterChange])

  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={2} justify={'center'} className={classes.filterBlock}>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <DatePicker
                label={translations('Date From')}
                value={stateFilters.dateFrom}
                onChange={handleFilter({ type: filtersLogic.types.SET_FILTER_DATE_FROM, dispatch })}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <DatePicker
                label={translations('Date To')}
                value={stateFilters.dateTo}
                onChange={handleFilter({ type: filtersLogic.types.SET_FILTER_DATE_TO, dispatch })}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

AnnouncementManagementSearchFilters.propTypes = {
  classes: PropTypes.shape({}).isRequired,
}

export default compose(
  withStyles(styles),
  withFiltersContainer
)(AnnouncementManagementSearchFilters)
