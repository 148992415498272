import { Chip, Tooltip } from '@material-ui/core'
import { translations } from '../../../../config'
import fp from 'lodash/fp'
import moment from 'moment'
import { Info } from '@material-ui/icons'
import getFormSchemaLabel from '../../../../helpers/getFormSchemaLabel'
import _ from 'lodash'

export const getStatusLabel = (status) => {
  const statuses = {
    draft: translations('Report Management - Status DRAFT'),
    complete: translations('Report Management - Status SUBMITTED'),
    ackFailed: translations('Report Management - Status ACK FAIL'),
    ackPassed: translations('Report Management - Status ACK SUCCESS'),
    inProgress: translations('Report Management - Status IN PROGRESS'),
    inReview: translations('Report Management - Status IN REVIEW')
  }

  const translated = statuses[status]
  return translated || status
}

export const displaySafetyReportId = ({ report }) => {
  let [extReportId, workflowType] = fp.at(['extReportId', 'workflowType'], report)
  if (workflowType === 'READ_ONLY') {
    extReportId += ` (${translations('Read Only Safety Report External Report ID Prefix')})`
  }
  return extReportId
}

export const getLocationString = (location) => {
  const { name, parent } = location

  if (name === 'Unassigned') {
    return translations('Location - Unknown Regions')
  }

  return `${parent ? parent.name + ' - ' : ''}${name}`
}

export const hasLatestVersion = ({ report }) => {
  const otherVersions = report.otherVersions
  const otherVersion = _.find(otherVersions, otherVersion => {
    return moment(otherVersion.createdAt).isAfter(moment(report.updatedAt))
  })
  const hasOtherVersion = !_.isNil(otherVersion)
  if (hasOtherVersion) {
    return (
      <Tooltip title={translations("A newer version of this report is available")}>
        <Info />
      </Tooltip>
    )
  }
  return <></>
}

export const getTypeOfReportValue = (typeOfReport) => {
  return typeOfReport || '-'
}

export const getApplication = ({ report }) => {
  const application = _.get(report, 'application.0.name', '-')
  return application
}

export const getSource = ({ report }) => {
  const source = _.get(report, 'source.name', '-')
  return source
}

export const getReportFormStatus = (isShowReportStatusBadge, reportForm, reportStatus) => {
  return <>
    {isShowReportStatusBadge &&
      <Tooltip title={translations(`SABRE Report Status - ${reportStatus}`)} style={{ 'margin-right': '5px' }}>
        <Chip Filled size="small" label={translations(`SABRE Report Status Icon - ${reportStatus}`)} />
      </Tooltip>
    }
    {getFormSchemaLabel(reportForm)}
  </>
}

export const getShotStatus = (submissionMeta, n3URL, nonN3URL) => {
  const key = _.get(submissionMeta, '0.details.surveyURL')
  const n3surveyURL = n3URL + key
  const nonN3surveyURL = nonN3URL + key
  const surveyState = _.get(submissionMeta, '0.details.surveyState')

  if (!surveyState) {
    return ''
  }

  return (
    <>
      <a href={nonN3surveyURL} target="_blank" onClick={e => { e.stopPropagation() }}>{surveyState}</a>
      &nbsp;
      <a href={n3surveyURL} target="_blank" onClick={e => { e.stopPropagation() }}>N-3</a>
    </>
  )
}

export const getReactionTypeWithEventCategoryAndStorageSubcategory = ({ reportType, reportJSON }) => {
  const sar = () => {
    const reportStatus = _.get(reportJSON, 'reportStatus')

    const reactionTypeNtf = _.get(reportJSON, 'reactionTypeNtf')
    const reactionTypeOtherNtf = _.get(reportJSON, 'reactionTypeOtherNtf')

    const reactionTypeCnf = _.get(reportJSON, 'reactionTypeCnf')
    const reactionTypeOtherCnf = _.get(reportJSON, 'reactionTypeOtherCnf')

    const reactionTypeChange = _.get(reportJSON, 'reactionTypeChange')

    const handleNtfLabel = () => {
      return reactionTypeOtherNtf ? `${reactionTypeNtf} - ${reactionTypeOtherNtf}` : reactionTypeNtf
    }

    const handleCnfLabel = () => {
      return reactionTypeOtherCnf ? `${reactionTypeCnf} - ${reactionTypeOtherCnf}` : reactionTypeCnf
    }

    switch (reportStatus) {
      case 'Notification':
        return handleNtfLabel()
      case 'Confirmation':
        if (reactionTypeChange === 'No') {
          return handleNtfLabel()
        } else {
          return handleCnfLabel()
        }
      default:
        return ''
    }
  }

  const sae = () => {
    const deviation = _.get(reportJSON, 'deviation')
    const deviationOther = _.get(reportJSON, 'deviationOther')

    return deviationOther ? `${deviation} - ${deviationOther}` : deviation
  }

  switch (reportType) {
    case 'sabreSAE':
      return sae()
    case 'sabreSAR':
      return sar()
    default:
      return ''
  }
}

export const getReactionOrEventRelatedTo = ({ reportType, reportJSON }) => {
  const sar = () => {
    const reportStatus = _.get(reportJSON, 'reportStatus')

    const componentNtf = _.join(moveFieldToEnd({
      results: _.get(reportJSON, 'componentNtf'),
      fieldName: 'Other'      
    }), ', ')
    const componentOtherNtf = _.get(reportJSON, 'componentOtherNtf')

    const componentCnf = _.join(moveFieldToEnd({
      results: _.get(reportJSON, 'componentCnf'),
      fieldName: 'Other'
    }), ', ')
    const componentOtherCnf = _.get(reportJSON, 'componentOtherCnf')

    switch (reportStatus) {
      case 'Notification':
        return componentOtherNtf ? `${componentNtf} - ${componentOtherNtf}` : componentNtf
      case 'Confirmation':
        return componentOtherCnf ? `${componentCnf} - ${componentOtherCnf}` : componentCnf
      default:
        return ''
    }
  }

  const sae = () => {
    const specification = _.get(reportJSON, 'specification')
    const specificationOther = _.get(reportJSON, 'specificationOther')

    return specificationOther ? `${specification} - ${specificationOther}` : specification
  }

  switch (reportType) {
    case 'sabreSAE':
      return sae()
    case 'sabreSAR':
      return sar()
    default:
      return ''
  }
}

const moveFieldToEnd = ({ results, fieldName = 'Other' }) => {
  return _.concat(
    _.without(results, fieldName),
    _.includes(results, fieldName) ? [fieldName] : []
  )
}
