import _ from 'lodash'
import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { change, getFormValues, initialize } from 'redux-form'
import {
  compose,
  withStateHandlers,
  withHandlers,
  withPropsOnChange,
  withState,
  lifecycle
} from 'recompose'
import withWidth from '@material-ui/core/withWidth'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'
import { constants as reportDetailsConstants } from '../../../store/modules/reportDetails'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as formViewSelectors, actions as formViewActions, constants as formViewConstants } from '../../../store/modules/formViews'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { actions as communicationTemplateActions } from '../../../store/modules/communicationTemplates'
import { actions as sourcesActions } from '../../../store/modules/sources'
import ReportQuestions from './ReportQuestions'
import modalService from '../../../services/modalService'
import { editableFields } from './helpers'

const enhancer = compose(
  withWidth(),
  connect((state) => {
    return {
      pageTitle: platformSelectors.getPageTitle(state)(
        'Report Configuration Title'
      ),
      orgId: authSelectors.getUserSelectedOrganisationId(state),
      getNewBaseSchema: formViewSelectors.getNewSchemaBase(state),
      getFormViewById: formViewSelectors.getFormViewById(state),
      formViewDetails: formViewSelectors.getCurrentFormViewDetails(state),
      formSchemaUpdated: formViewSelectors.getFormSchemaUpdated(state),
      formSchemaName: formViewSelectors.getCurrentFormViewFormSchemaName(state),
      viewJSON: editableFields(formViewSelectors.getViewJSON(state)),
      notEditable: formViewSelectors.getNotEditableJSON(state),
      formValues: getFormValues(reportDetailsConstants.NEW_FORM_NAME)(state) || {},
      organisationDetails: authCombinedSelectors.getDetailsForSelectedOrganisation(state),
      professions: authCombinedSelectors.getOrganisationProfessions(state)
    }
  }, dispatch => ({
    fetchOrganisationEmailTemplates: ({ organisationId }) => dispatch(communicationTemplateActions.fetchOrganisationEmailTemplates({ organisationId })),
    fetchOrganisationSources: ({ organisationId }) => dispatch(sourcesActions.fetchOrganisationSources({ organisationId })),
    changeField: (...props) => dispatch(change(...props)),
    initialize: () => dispatch(initialize(formViewConstants.REPORT_CONFIGURATION_FORM, {}))
  })),
  withState('audienceChanged', 'setAudienceChanged', false),
  withStateHandlers(
    {
      editing: false
    },
    {
      toggleEdit: () => (value) => {
        return {
          editing: value
        }
      }
    }
  ),
  withPropsOnChange(['isLoading', 'viewJSON', 'notEditable', 'getFormViewById', 'getNewBaseSchema', 'selectedSection'], (props) => {
    const { viewJSON, selectedSection, notEditable, getFormViewById, getNewBaseSchema } = props
    const getName = _.get(getFormViewById ? getFormViewById : getNewBaseSchema, 'name', '')
    const titleName = getName
    return {
      isLoading: _.size(viewJSON) === 0,
      viewJSON,
      selectedSection,
      notEditable,
      titleName
    }
  }),
  withPropsOnChange(['getNewBaseSchema', 'getFormViewById'], (props) => {
    const { getNewBaseSchema, getFormViewById } = props
    let allSchemaFields
    const formSchemaLibrary = new FormSchemaLibrary()
    const schemaName = _.get(getNewBaseSchema, 'schemaName') || _.get(getFormViewById, 'formSchema.name')
    if (schemaName) {
      allSchemaFields = formSchemaLibrary.getAllFields(schemaName)
    }
    return {
      schemaFields: allSchemaFields
    }
  }),
  withHandlers({
    toggleVisibility: ({ dispatch, toggleEdit, viewJSON }) => ({ visible, nestedSections, clone = false }) => {
      toggleEdit(true)
      dispatch(formViewActions.toggleVisibility({ viewJSON, visible, nestedSections, clone }))
    },
    moveQuestion: ({ dispatch, toggleEdit, viewJSON }) => ({ nestedSections, questionKey, direction }) => {
      toggleEdit(true)
      dispatch(formViewActions.moveQuestion({ viewJSON, nestedSections, questionKey, direction }))
    },
    moveQuestionToSection: ({ dispatch, toggleEdit, viewJSON }) => ({ questionKey, nestedSections, moveToSections, isHeading }) => {
      toggleEdit(true)
      dispatch(formViewActions.moveQuestionToSection({ viewJSON, questionKey, nestedSections, moveToSections, isHeading }))
      modalService.close()
    },
    addQuestion: ({ dispatch, toggleEdit, viewJSON }) => ({ name, visible, section, heading }) => {
      if (name && section) {
        toggleEdit(true)
        const actionParams = _.pickBy({ viewJSON, name, visible, section, heading }, _.identity);
        dispatch(formViewActions.addQuestion(actionParams))
        modalService.close()
      }
    },
    configureField: ({ dispatch, toggleEdit, viewJSON }) => ({ nestedSections, details = {} }) => {
      toggleEdit(true)
      if (!_.isNil(nestedSections)) {
        dispatch(formViewActions.configureField({ viewJSON, nestedSections, details }))
        modalService.close()
      }
    },
    addSection: ({ dispatch, toggleEdit, viewJSON }) => ({ sectionName }) => {
      if (sectionName) {
        toggleEdit(true)
        dispatch(formViewActions.addSection({ viewJSON, sectionName }))
        modalService.close()
      }
    },
    deleteQuestion: ({ dispatch, toggleEdit, viewJSON }) => ({ sectionKey, questionKey }) => {
      toggleEdit(true)
      dispatch(formViewActions.deleteQuestion({ viewJSON, sectionKey, questionKey }))
      modalService.close()
    },
    deleteSection: ({ dispatch, toggleEdit, viewJSON }) => ({ sectionKey }) => {
      toggleEdit(true)
      dispatch(formViewActions.deleteSection({ viewJSON, sectionKey }))
      modalService.close()
    },
    editRepeatables: ({ dispatch, toggleEdit, viewJSON }) => ({ fields, nestedSections }) => {
      toggleEdit(true)
      dispatch(formViewActions.editRepeatables({ viewJSON, fields, nestedSections }))
      modalService.close()
    },
    openModal: () => (component, noPadding = false) => {
      modalService.open({
        component,
        wideModal: true,
        noPadding
      })
    },
    closeModal: () => () => {
      modalService.close()
    },
    sectionNames: ({ viewJSON }) => () => {
      let options = []
      viewJSON.map(section => (
        options.push({ label: section.name, value: section.id })
      ))
      return options
    },
    questionNames: ({ viewJSON }) => () => {
      let questions = []
      function mapFields(fields) {
        return _.map(fields, (field) => {
          if (_.size(_.get(field, 'fields'))) {
            mapFields(field.fields)
          }
          questions.push(field.name)
          return field
        })
      }
      mapFields(viewJSON)
      return questions
    },
    onSave: ({ dispatch, orgId, viewJSON, getNewBaseSchema, getFormViewById, toggleEdit, notEditable, formSchemaName, formSchemaUpdated }) => () => {
      toggleEdit(false)
      dispatch(formViewActions.setFormViewSchemaUpdateSaved())
      const data = _.cloneDeep(viewJSON)
      // "notEditable" is an array of fields with the property {editable: false}
      // these fields are removed from the viewJSON in the middleware
      // now just before saving the view they are added back into the viewJSON array
      // the reason they were removed => interference with moving question position by index
      /**
       *
       * @typedef {Object}[] notEditable
       * @property {id: string, section: Array }
       * id = field id
       * section = list of section Ids from top level to root where the field was removed
       */
      if (_.size(notEditable)) {
        _.forEach(notEditable, (field) => {
          const nestedSections = []
          _.forEach(field.section, (section) => {
            const currentSection = _.size(nestedSections) ? _.get(data, _.join(nestedSections, '.')) : data
            // get the section index again incase of moving position
            const sectionIndex = _.findIndex(currentSection, item => item.id === section)
            nestedSections.push(`${sectionIndex}.fields`)
          })
          // for fields in the top section (not repeatables),
          // check if the section is visible
          // if section is not visible push them into the first visible section
          if (_.size(nestedSections) < 2) {
            const isVisible = _.get(data, `${_.join(nestedSections, '.').slice(0, -7)}.visible`)
            if (isVisible) {
              _.invoke(data, `${_.join(nestedSections, '.')}.push`, field)
            } else {
              const firstVisibleSection = _.findIndex(data, (section => section.visible))
              _.invoke(data, `${firstVisibleSection}.fields.push`, field)
            }
          } else {
            _.invoke(data, `${_.join(nestedSections, '.')}.push`, field)
          }
        })
      }
      const meta = getFormSchemaMetaData(formSchemaName)
      if (getNewBaseSchema) {
        const { name, formSchemaId, category } = getNewBaseSchema
        const params = {
          name,
          formSchemaId,
          organisationId: orgId,
          versionId: 1,
          viewJSON: data
        }
        return dispatch(formViewActions.createFormVersion({ params }))
          .then(res => {
            // if new config and audience not saved/changed then save the default into formview details
            dispatch(formViewActions.updateFormView({
              id: res.formViewId,
              params: {
                details: {
                  showNullFlavours: true,
                  audienceId: 1
                },
                ...category && { category }
              }
            }))
            dispatch(replace({
              pathname: `/report-configuration/${res.formViewId}`
            }))
            dispatch(formViewActions.resetBaseSchema())
            return res
          })
      } else {
        const id = _.get(getFormViewById, 'id')
        const params = {
          viewJSON: data,
        }
        if (formSchemaUpdated) {
          params.schemaLibraryVersion = meta.schemaLibraryVersion
          params.schemaVersionJsonHash = meta.schemaVersionJsonHash
        }

        return dispatch(formViewActions.updateFormVersion({
          id,
          ...params
        }))
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchOrganisationEmailTemplates({ organisationId: this.props.orgId })
      this.props.fetchOrganisationSources({ organisationId: this.props.orgId })
    }
  })
)

/**
 * Get MetaData to from the form schema library for troubleshooting form views versions.
 * @param {string} formSchemaName
 */
const getFormSchemaMetaData = (formSchemaName) => {
  const formSchemaLibrary = new FormSchemaLibrary()
  return {
    schemaLibraryVersion: formSchemaLibrary.VERSION,
    schemaVersionJsonHash: formSchemaLibrary.getDefaultViewJsonHash(formSchemaName)
  }
}

export default enhancer(ReportQuestions)
