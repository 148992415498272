import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const Spacing = props => {
  const style = _.pick(props, ['height', 'width'])
  return <div style={style} />
}

export default withStyles(style)(Spacing)
