import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import ProductsList from './ProductsList'
import ProductsSearchBar from './ProductsSearchBar'
import VisuallyHidden from '../../../components/VisuallyHidden'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const ProductsSearchScreen = (props) => {
  const { search, loadMore, watchListOnly, pageTitle } = props

  const searchBarProps = { search, watchListOnly }
  const listProps = { loadMore, watchListOnly }

  return <Fragment>
    <VisuallyHidden>
      <h1>{pageTitle.title}</h1>
    </VisuallyHidden>
    <Helmet>
      <title>{pageTitle.titleWithName}</title>
    </Helmet>
    <ProductsSearchBar {...searchBarProps} />
    <ProductsList {...listProps} />
  </Fragment>
}

ProductsSearchScreen.propTypes = {
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(style)(ProductsSearchScreen)
