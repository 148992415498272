import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import NewFeedForm from './components/NewsFeedForm'
import FeedList from './components/FeedList'

const NewsFeedScreen = ({ classes, pageTitle }) => (
  <div className={classes.container}>
    <SubHeader
      centerContent={(
        <Fragment>
          <Heading component='h1'>{pageTitle.title}</Heading>
          <Helmet>
            <title>{pageTitle.titleWithName}</title>
          </Helmet>
        </Fragment>
      )}
      leftContent={(
        <BackBar />
      )}
    />
    <div className={classes.form}>
      <NewFeedForm />
    </div>
    <div className={classes.listContainer}>
      <FeedList />
    </div>
  </div>
)

NewsFeedScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(style)(NewsFeedScreen)
