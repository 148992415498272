import _ from 'lodash'
import fp from 'lodash/fp'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { compose, withProps, withHandlers } from 'recompose'
import { withRouter } from 'react-router'

import NewReportForm from './NewReportForm'

import { actions as reportDetailsActions, constants as reportDetailsConstants, selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import { selectors as selectedReceiverSelectors } from '../../../../../store/modules/selectedReceiver'
import { authCombinedSelectors } from '../../../../../store/modules/combinedSelectors'
import { actions as reportsActions } from '../../../../../store/modules/reports'
import { actions as json2xmlActions, selectors as json2xmlSelectors } from '../../../../../store/modules/json2xml'
import { selectors as formViewSelectors } from '../../../../../store/modules/formViews'
import { actions as notificationsActions } from '../../../../../store/modules/notifications'
import { translations } from '../../../../../config'
import modalService from '../../../../../services/modalService'

const enhancer = compose(
  connect(state => ({
    reportId: reportDetailsSelectors.getReportId(state),
    reportStatus: reportDetailsSelectors.getReportStatus(state),
    initialValues: reportDetailsSelectors.getDetails(state),
    json2xmlLoading: json2xmlSelectors.getIsLoading(state),
    json2jsonLoading: false,
    organisationDetails: authCombinedSelectors.getDetailsForSelectedOrganisation(state),
    professions: authCombinedSelectors.getOrganisationProfessions(state),
    hasXML: reportDetailsSelectors.getHasXML(state),
    hasJSON: reportDetailsSelectors.getHasJSON(state),
    viewJSON: formViewSelectors.getViewJSON(state),
    isLoading: selectedReceiverSelectors.getIsLoading(state) || reportDetailsSelectors.getIsLoading(state),
    canExportPdf: reportDetailsSelectors.getCanExportPdf(state),
    canExportAssessmentPdf: authCombinedSelectors.canExportAssessessmentPdf(state),
    hasJSONReportExport: reportDetailsSelectors.getHasJSONReportExport(state)
  }), dispatch => ({
    changeField: (...props) => dispatch(change(...props)),
    submitReport: async ({ fields, organisationId, isPublic, formViewVersionId, formSchemaId, followUpNotificationId, attachmentFields }) => {
      try {
        await dispatch(reportDetailsActions.submitReport({ fields, organisationId, isPublic, formViewVersionId, formSchemaId, attachmentFields }))
        if (followUpNotificationId) {
          dispatch(notificationsActions.setNotificationActionedStatus({ id: followUpNotificationId, isActioned: true }))
        }
      } catch (error) {
        console.log(error)
      }
    },
    updateReport: ({ formViewVersionId, formSchemaId, attachmentFields }) => dispatch(reportDetailsActions.updateReport({ formViewVersionId, formSchemaId, attachmentFields })),
    downloadXml: ({ formSchemaId }) => {
      return dispatch(json2xmlActions.json2xml({ formSchemaId }))
        .catch(() => {
          modalService.continue({
            title: translations('Json2Xml Fail Modal Title'),
            text: translations('Json2Xml Fail Modal Text')
          })
        })
    },
    downloadPdf: (reportId) => {
      return dispatch(reportsActions.fetchReportForId({ id: reportId, format: 'pdf' }))
    },
    downloadAssessmentPdf: (reportId) => {
      return dispatch(reportsActions.fetchReportForId({ id: reportId, format: 'pdf', options: { showAssessmentFields: true } }))
    },
    downloadJSONReport: ({ dispatch, report }) => () => {
      dispatch(reportsActions.fetchReportForId({ id: report.id, format: 'json' }))
      modalService.close()
    },
  })),
  withRouter,
  withHandlers({
    onDownloadXml: ({ downloadXml, formSchemaId }) => () => downloadXml({ formSchemaId }),
    onDownloadPdf: ({ reportId, downloadPdf }) => () => downloadPdf(reportId),
    onDownloadAssessmentPdf: ({ reportId, downloadAssessmentPdf }) => () => downloadAssessmentPdf(reportId),
    onSubmitReport: ({ attachmentFields, submitReport }) => actionParams => submitReport({...actionParams, attachmentFields}),
    onDownloadJSONReport: ({ reportId, downloadJSONReport }) => () => downloadJSONReport(reportId),
  }),
  withProps(props => {
    if (!props.isLoading) {
      const { initialValues, defaultValues, formSchema } = props || {}
      let modifiedProps = { formSchema }
      if (_.isUndefined(initialValues) && !_.isEmpty(defaultValues)) {
        modifiedProps = fp.set('initialValues', defaultValues, modifiedProps)
      }
      return modifiedProps
    }
  })
)

export default enhancer(NewReportForm)
