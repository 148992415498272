import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import { LinearProgress } from '@material-ui/core'

const ReportsLoadingBar = ({ classes, isLoading }) => {
  return (
    <div className={classes.container} >
      { isLoading && <LinearProgress mode='indeterminate' style={{ height: 2 }} />}
    </div >
  )
}

export default withStyles(style)(ReportsLoadingBar)
