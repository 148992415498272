import { useShallowEqualSelector } from '../../../hooks'

import {
  getIsLoading,
  getResults,
  getTotal,
  getError,
  getHasMore
} from './selectors'

export const useIsLoading = () => {
  return useShallowEqualSelector(getIsLoading)
}

export const useAccountDeletionRequest = () => {
  return useShallowEqualSelector(getResults)
}

export const useTotal = () => {
  return useShallowEqualSelector(getTotal)
}

export const useError = () => {
  return useShallowEqualSelector(getError)
}

export const useHasMore = () => {
  return useShallowEqualSelector(getHasMore)
}