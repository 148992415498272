import { pure } from 'recompose'

import DataTable from './DataTable'
import DataTableRow from './DataTableRow'
import DataTableCell from './DataTableCell'

const EnhancedDataTable = pure(DataTable)

export { EnhancedDataTable as DataTable, DataTableRow, DataTableCell }
export default EnhancedDataTable
