import fp from 'lodash/fp'
import * as config from './config'

export const getOrganisationValueObject = ({ organisation, isWorkspace }) => {
    if (!isWorkspace) {
        return organisation
    }
    return fp.omit([
        'details.sendergivename',
        'details.senderfamilyname',
        'details.senderemailaddress',
        'details.importedData.appian.REP_FIRST_NAME',
        'details.importedData.appian.REP_SURNAME',
        'details.importedData.appian.REP_EMAIL_ADDR'
    ], organisation)
}

export const getValueFromOrganisationOption = (organisation) => {
    if (organisation === null) {
        return ''
    }
    return organisation.name || organisation || ''
}

export const getDropdownDetailsStringFromOrganisationOption = fp.compose(
    fp.join(', '),
    fp.compact,
    fp.at(
        [
            'details.senderstreetaddress',
            'details.senderstreetaddressLine2',
            'details.sendercity'
        ]
    )
)

export const getOptionLabel = (option) => {
    const selection = getDropdownDetailsStringFromOrganisationOption(option)
    return selection
}

export const getOptionSelected = (option, value) => {
    const selection = getValueFromOrganisationOption(option)
    return selection === value
}

export const handleAsyncStatusMessagesToUser = ({ isLoading, isEmpty, isInitial, minimumSearchLength = config.MIN_SEARCH_LENGTH }) => {
    switch (true) {
        // If you want to show loading.
        case isLoading:
            return {
                loading: true,
                loadingText: undefined
            }
        case isInitial:
            return {
                loading: true,
                loadingText: `For suggestions, please provide at least ${minimumSearchLength} characters`
            }
        // If you want to tell the user no suggestions are avaliable
        // commented out in case that means they think that free text isn't avaliable.
        // case isEmpty:
        //     return {
        //         loading: true,
        //         loadingText: 'No suggestions found'
        //     }
        default:
            return {
                loading: false,
                loadingText: undefined
            }
    }
}
