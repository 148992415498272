import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSdk } from '../../services/sdk/hooks'
import { useDebounce } from '../../services/utilities'
import _ from 'lodash'
import FieldMeta from '../FieldMeta'
import Field from '../Field'
import { StyledSelectBox } from '../SelectBox/SelectBox'
import reducer, { initializer } from './responseReducer'
import { SearchingSpinner } from '../ADRSearch/ADRSearch'
import { ActionTypes } from 'react-select'
import styled from 'styled-components'
import NullFlavourField from '../NullFlavourField'

const StyledADROptions = styled(StyledSelectBox)`
 .mhraUISelect__menu {
    box-shadow: none;
    background-color: white;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    border: solid 1px hsla(0, 0%, 0%, 0.25);
    background: hsla(0, 100%, 100%, 0.99);
    font-size: 85%;

}
.mhraUISelect__menu-notice {
   text-align: inherit;
   color: ${props => props.theme.colors.primaryText}
}
`

/**
 * Asynchronously fetch options from the Vigilance Hub.
 * The user is capable of searching for value via freeform entry,
 * but they must select a value provided.
 */
const ADROptions = (props) => {
  const {
    input = {},
    meta = {},
    id,
    label,
    hint,
    required,
    searchType,
    lookupOptions,
    placeholder = '',
    nullFlavours,
    isMulti,
    disabled
  } = props

  const {
    name,
    value,
    onChange,
    onFocus,
    onBlur
  } = input || {}

  const {
    error,
    touched
  } = meta

  const sdk = useSdk()
  const [userInput, setUserInput] = useState(value || '')
  const [loadOptions, setLoadOptions] = useState()
  const [selectedOption, setSelectedOption] = useState({})
  const fieldInvalid = error && touched
  const errorMessage = fieldInvalid ? error : undefined
  const debouncedSearchTerm = useDebounce(userInput, 500)
  const [response, dispatch] = React.useReducer(reducer, {}, initializer)

  const hasNullFlavours = nullFlavours && !_.isEmpty(nullFlavours)

  useEffect(
    () => {
      (async () => {
        const meetsMinSearchTermLength = _.size(debouncedSearchTerm) >= 3
        if (!searchType || !debouncedSearchTerm || !meetsMinSearchTermLength) {
          dispatch({ type: 'INITIAL' })
          return
        }
        dispatch({ type: 'LOADING' })
        try {
          const data = await _.invoke(sdk, searchType, ({ query: debouncedSearchTerm, lookupOptions }))
          dispatch({ type: 'FULFILLED', payload: { data } })
        } catch (error) {
          dispatch({ type: 'REJECTED', payload: { error } })
          onChange('')
        }
      })()
    }, [debouncedSearchTerm, value]
  )

  // Deal with prepopulation of the form before any async request is made.
  // https://react-select.com/async
  useEffect(
    () => {
      let optionValue
      if (isMulti && value) {
        optionValue = _.map(value, val => ({value: val, label: val}))
        setUserInput('')
      } else {
        if (!touched && value) {
          optionValue = { label: value, value }
          setLoadOptions(() => [
            optionValue
          ])
        }
      }
      setSelectedOption(optionValue)

    }, [value, touched]
  )

  /**
   * @type {(option: { value: string, label: string }, eventMeta: { action: ActionTypes }) => void}
   */
  const handleOptionSelection = (option = {}, eventMeta) => {
    if (eventMeta.action === 'clear') {
      setUserInput('')
      onChange('')
      onBlur('')
      setSelectedOption(option)
      return
    }

    const { value } = option
    onChange(value)
    onBlur(value)
    setSelectedOption(option)
  }

  const renderADROptions = () => {
    return (
      <Field error={errorMessage}>
        <FieldMeta name={id} label={label} error={errorMessage} hint={hint} required={required} disabled={disabled}/>
        <StyledADROptions
          name={name}
          value={selectedOption}
          options={response.results}
          noOptionsMessage={response.noOptionsMessage}
          inputId={id}
          classNamePrefix={'mhraUISelect'}
          isClearable
          aria-required={required}
          aria-invalid={fieldInvalid}
          placeholder={placeholder}
          isLoading={response.loading}
          onChange={handleOptionSelection}
          onFocus={onFocus}
          onBlur={onBlur}
          onInputChange={setUserInput}
          inputValue={userInput}
          loadOptions={loadOptions}
          isMulti={isMulti}
          disabled={disabled}
          isDisabled={disabled}
          components={
            {
              LoadingIndicator: SearchingSpinner,
              DropdownIndicator: null,
            }
          }
        />
      </Field>
    )
  }

  if (hasNullFlavours) {
    return (
      <NullFlavourField
        {...props}
        render={renderADROptions}
      />
    )
  }

  return(renderADROptions())

}

ADROptions.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** The type of search you want to perform */
  type: PropTypes.oneOf(['drugSearch', 'medDRASearch', 'hospitalSearch', 'locationSearch', 'terminologySearch']),
  /** Input props based from React Final Form
   *
   * `name` - Used to associate label and input
   *
   * `value` - Value of the input
   *
   * `type` - Native HTML input type
   *
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   *
   * `onFocus` - Function called when focus has been given to the field
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  /** Meta props based from React Final Form
   *
   * `error` - Field validation message
   *
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
}

export default ADROptions
