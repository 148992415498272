import React from 'react'
import { Route, Redirect } from 'react-router'
import PrivateRoute from '../../components/PrivateRoute'
import UsersSearchScreen from './UsersSearchScreen'
import UserRegistrationScreen from './UserRegistrationScreen'
import ViewUserScreen from './ViewUserScreen'
import ViewInvitedUsersScreen from './ViewInvitedUsersScreen'
import ViewAccountDeletionRequestList from './ViewAccountDeletionRequestList'

import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/users/invited' path='/users/invited' exact component={TimedLogoutComponent(ViewInvitedUsersScreen)} />,
  <PrivateRoute key='/users/deletion-requested' path='/users/deletion-requested' exact component={TimedLogoutComponent(ViewAccountDeletionRequestList)} />,
  <PrivateRoute key='/users' path='/users' exact component={TimedLogoutComponent(UsersSearchScreen)} />,
  <PrivateRoute key='/users/add' path='/users/add' exact component={TimedLogoutComponent(UserRegistrationScreen)} />,
  <PrivateRoute key='/users/:id' path='/users/:id' exact component={TimedLogoutComponent(ViewUserScreen)} />,
  <Route path="/users/new/more"><Redirect to="/register"/></Route>,
  <Route key='/register' path='/register' exact component={UserRegistrationScreen} />,
  <Route key='/users/new/public' path='/users/new/public/:orgId' exact component={UserRegistrationScreen} />,
  <Route key='/users/new/invite/:organisationInviteId' path='/users/new/invite/:organisationInviteId' exact component={UserRegistrationScreen} />,
  <PrivateRoute key='/my-profile' path='/my-profile' exact component={TimedLogoutComponent(ViewUserScreen)} />,
]
