import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'

import { translations } from '../../../../config'
import Dropdown from '../../../../components/Fields/Dropdown'
import Chart from '../../../../components/Chart'
import P from '../../../../components/P'
import InfoIcon from '@material-ui/icons/Info'

import style from './style'

const ProductCharts = props => {
  const {
    handleChangeChart,
    metaDistribution,
    chartOptions,
    chartData,
    noData,
    isLoadingMeta,
    classes
  } = props

  if (noData) {
    return <div className={classes.statusContainer}>
      <InfoIcon />
      <P value={translations('Product Details - No Chart Data')} />
    </div>
  }

  return <div>
    <FormControl className={classes.formControl}>
      <Dropdown
        label={translations('Product Details - Charts Dropdown Label')}
        noNullOption
        onChange={handleChangeChart}
        options={chartOptions}
        value={metaDistribution}
      />
    </FormControl>
    <div>
      <Chart
        classes={classes}
        data={chartData}
        isLoading={isLoadingMeta}
        xLabel={translations('Product Details - Chart Label')}
      />
    </div>
  </div>
}

export default withStyles(style)(ProductCharts)
