import _ from 'lodash'
import React from 'react'
import { styled } from '@material-ui/core'
import Information from './Sections/Information'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
}))

const InfoTab = ({
  isEditing,
  entity
}) => {
  const {
    createdByUser,
    modifiedByUser,
    createdAt,//
    modifiedByUserAt
  } = entity

  if (!isEditing) {
    return <Container />
  }

  return (
    <Container>
      <Information
        createdByUser={createdByUser?.displayName}
        createdByUserAt={createdAt}
        modifiedByUser={modifiedByUser?.displayName}
        modifiedByUserAt={modifiedByUserAt}
      />
    </Container>
  )
}

export default InfoTab
