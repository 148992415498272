import { useSelector } from 'react-redux'

import Loader from '../../../../../components/Loader'
import Form, { FormBody, FormError } from '../../../../../components/Form'
import { selectors } from '../../../../../store/modules/announcementManagement'
import { platformsFormName } from '../../constants'

const ConfigurePlatformsForm = Form(platformsFormName)

const ConfigurePlatforms = ({
  announcementId,
  isEditing
}) => {
  const {
    isLoading,
    entity
  } = useSelector(selectors.genericSelectors.getDetailById({ id: announcementId }))

  const {
    platforms
  } = entity

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <FormError></FormError>
      <ConfigurePlatformsForm
        editing={isEditing}
        initialValues={{ platforms }}
        enableReinitialize={true}
        keepDirtyOnReinitialize={false}
        destroyOnUnmount={false}
      >
        <FormBody
          schema={[
            {
              id: 'platforms',
              field: 'TableList',
              props: {
                shrink: true,
                required: false,
                headers,
                enableSearch: false,
                enablePagination: false
              }
            },
          ]}
          layout={['platforms:12']}
        />
      </ConfigurePlatformsForm>
    </>
  )
}

export default ConfigurePlatforms

const headers = [
  { label: 'Platform', id: 'name', type: 'label', width: '60%' },
  { label: 'Assigned', id: 'assigned', type: 'checkbox', width: '20%', align: 'center' },
  { label: 'Announcements Enabled', id: 'hasAnnouncementsEnabled', type: 'checkbox', width: '20%', align: 'center', readOnly: true },
]