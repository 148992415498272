"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pahoReactionExperiencedOptions = void 0;
var pahoReactionExperiencedOptions = [{
  label: 'Fever >38',
  value: 'feverGreaterThan38'
}, {
  label: 'Pain, swelling, redness at the vaccination site',
  value: 'painSwellingRednessAtTheVaccinationSite'
}, {
  label: 'Neurological problems',
  value: 'neurologicalProblems'
}, {
  label: 'Clotting problems',
  value: 'clottingProblems'
}, {
  label: 'Allergic reaction',
  value: 'allergicReaction'
}, {
  label: 'Another reaction',
  value: 'anotherReaction'
}];
exports.pahoReactionExperiencedOptions = pahoReactionExperiencedOptions;