import React from 'react'
import Button from "../../../../components/Button/Button"
import * as hooks from '../ConfigureEntryModal/hooks'

export const AddEntryButton = () => {
    const launchAddEntryButton = hooks.useEntryConfigureModal()
    return <Button
        fullWidth
        onClick={launchAddEntryButton}
    >Add New Entry</Button>
}
