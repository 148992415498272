const style = theme => ({
  timeframes: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  timeframeHeader: {
    position: 'relative',
    borderRadius: 4,
    // border: `1px solid ${theme.palette.grey['200']}`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'inline-block',
    marginLeft: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: theme.spacing(3),
      height: 1,
      background: theme.palette.secondary.main,
      transform: `translateX(-100%) translateY(-50%)`
    },
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: 0,
      height: 14,
      width: 14,
      background: theme.palette.secondary.main,
      border: '2px solid #ffffff',
      transform: `translateX(-100%) translateY(-50%)`,
      borderRadius: 14,
      marginLeft: -15
    }
  },
  list: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3)
  },
})

export default style