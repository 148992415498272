import theme, { colors } from '../../config/theme'

const styles = {
  p: {
    ...theme.p.p,
    color: colors.text
  },
  large: {
    ...theme.p.large,
    color: colors.text
  },
  hubTileText: {
    ...theme.p.hubTileText,
    color: colors.text
  },
  promoText: {
    ...theme.p.promoText,
    color: colors.text
  },
  promoTextWhite: {
    ...theme.p.promoText,
    color: colors.whiteText
  },
  textButton: {
    ...theme.p.textButton,
    color: colors.text
  },
  alert: {
    ...theme.p.alert,
    color: colors.error
  },
  error: {
    ...theme.p.p,
    color: colors.error
  },
  inline: {
    ...theme.p.inline,
    color: colors.text
  },
  bold: {
    ...theme.p.p,
    color: colors.text,
    fontWeight: 'bold'
  },
  greenText: {
    ...theme.p.p,
    color: colors.actionedGreen
  },
  // https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
  dontBreakOut: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    MsWordBreak: 'break-all',
    wordBreak: 'break-all',
    wordBreak: 'break-word',
    MsHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto'
  }
}

export default theme => styles
