import VisuallyHidden from "@reach/visually-hidden"
import _ from "lodash"
import React from "react"
import PropTypes from 'prop-types'
import styled from "styled-components"
import media from "styled-media-query"

import ErrorMessage from "../ErrorMessage"
import Field from "../Field"
import Hint from "../Hint"
import Label from "../Label"
import { StyledSelect } from "../SelectBox/SelectBox"

const hours = _.range(0, 24).map(digit => _.padStart(digit, 2, 0))
const mins = _.range(0, 60).map(digit => _.padStart(digit, 2, 0))

const SelectBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`

const SelectBoxColumn = styled.div`
  & + & {
    margin-left: 10px;
  }
  ${media.greaterThan('small')`
    & + & {
      margin-left: 15px;
    }
  `}
`

const TimeSelect = styled(StyledSelect)`
  display: flex;
  flex-direction: row;
  width: ${props => props.big ? '120px' : '80px'};
`

const Time = ({
  input: {
    name,
    value,
    onChange,
    onFocus,
    onBlur,
  },
  meta: {
    error,
    touched
  },
  id,
  label,
  hint,
  required,
  disabled
}) => {

  const fieldInvalid = error && touched
  const errorMessage = fieldInvalid ? error : undefined

  const hourValue = value?.substring(0, 2)
  const minuteValue = value?.substring(2, 4)

  const handleChange = type => event => {
    const { value } = event.target
    const newDate = type === 'hour' ? `${value}${minuteValue || '00'}` : `${hourValue}${value}` 
    onChange(newDate)
  }

  return (
    <Field as={'fieldset'} error={errorMessage} tabIndex={error ? '-1' : ''} name={name}>
    <VisuallyHidden><legend>{label}</legend></VisuallyHidden>

    <Label as='h3' required={required}>{label}</Label>
    {errorMessage && <ErrorMessage error={errorMessage} />}
    {hint && <Hint message={hint} />}
    <SelectBoxRow>
      <SelectBoxColumn>
        <Label name={`${id}_hours`} nested required>Hours</Label>
        <TimeSelect
          id={`${id}_hours`}
          aria-required={required}
          aria-invalid={fieldInvalid}
          label='hours'
          disabled={disabled}
          as={'select'}
          value={hourValue}
          big
          error={errorMessage}
          onChange={handleChange('hour')}
          oFocus={onFocus}
          onBlur={onBlur}
        >
          {['hh', ...hours].map(opt => <option key={`${opt}`} value={opt !== 'hh' ? opt : ''}>{opt}</option> )}
        </TimeSelect>
      </SelectBoxColumn>
      <SelectBoxColumn>
        <Label name={`${id}_minutes`} nested required>Minutes</Label>
        <TimeSelect
          id={`${id}_minutes`}
          aria-required={required}
          aria-invalid={fieldInvalid}
          label='minutes'
          disabled={!minuteValue || disabled}
          as={'select'}
          value={minuteValue}
          error={errorMessage}
          onChange={handleChange('minute')}
          oFocus={onFocus}
          onBlur={onBlur}
          >
          {['mm', ...mins].map(opt => <option key={`${opt}`} value={opt !== 'mm' ? opt : ''}>{opt}</option>)}
        </TimeSelect>
      </SelectBoxColumn>

    </SelectBoxRow>
  </Field>
  )
}

Time.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** If the field is disabled */
  disabled: PropTypes.bool,
  /** Input props based from React Final Form
   * 
   * `name` - Used to associate label and input
   *
   * `value` - Value of the input
   * 
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   * 
   * `onFocus` - Function called when focus has been given to the field
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  /** Meta props based from React Final Form
   * 
   * `error` - Field validation message
   * 
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
}

Time.defaultProps = {
  required: false,
  input: {},
  meta: {}
}

export default Time