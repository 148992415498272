import React, { useCallback } from 'react'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { styled } from '@material-ui/core/styles'

import { DataTableRow, DataTableCell } from '../../../../../components/DataTable'
import ButtonLink from '../../../../../components/ButtonLink'
import ConfirmationModal from '../ConfirmationModal'

import {
  actions as developerActions
} from '../../../../../store/modules/developer'

import modalService from '../../../../../services/modalService'
import toastService from '../../../../../services/toastService'
import { translations } from '../../../../../config'

const RowAction = styled(ButtonLink)(({ theme }) => ({
  color: theme.palette.error.main,
  textDecoration: 'underline',
  '&:hover, &:active, &:focus': {
    color: theme.palette.error.dark
  }
}))

const APIKeyRow = (props) => {
  const dispatch = useDispatch()
  const { entry, organisationId, applicationId, } = props
  const { apiKey = {} } = entry
  const { id, name, createdByUser = {}, createdAt } = apiKey
  const displayName = _.get('displayName', createdByUser, '-')

  const onDelete = useCallback(() => {
    modalService.open({
      component: ConfirmationModal,
      title: translations('Confirmation'),
      message: translations('Developer - Revoke API Key message', { name }),
      onConfirmation: async () => {
        try {
          await dispatch(developerActions.removeAPIKey({
            organisationId,
            applicationId,
            apiKeyId: id
          }))
          toastService.action({
            type: 'success',
            message: translations('Developer - Success revoke API Key'),
            autoHideDuration: 6000
          })
        } catch (error) {
          toastService.action({
            type: 'error',
            message: translations('Developer - Failure revoke API Key'),
            autoHideDuration: 6000
          })
        }
      }
    })
  }, [id, applicationId, organisationId, dispatch, name])

  return (
    <DataTableRow>
      <DataTableCell component='th' scope='row' heading>{name}</DataTableCell>
      <DataTableCell align={'right'}>{displayName}</DataTableCell>
      <DataTableCell align={'right'}>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</DataTableCell>
      <DataTableCell align={'right'}>
        <RowAction onClick={onDelete}>Revoke</RowAction>
      </DataTableCell>
    </DataTableRow>
  )
}

APIKeyRow.propTypes = {
  entry: PropTypes.object.isRequired
}

export default APIKeyRow
