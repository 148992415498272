import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Snackbar, SnackbarContent } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'

import { withStyles } from '@material-ui/core/styles'

import style from './style'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  default: InfoIcon
}

const Toast = ({
  id,
  anchorOrigin,
  isOpen,
  autoHideDuration,
  onClose,
  message,
  classes,
  type,
  other
}) => {
  const Icon = variantIcon[type]
  return (
    <Snackbar
      anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'left' }}
      open={isOpen}
      autoHideDuration={autoHideDuration || 2000}
      onClose={onClose}
      {...other}>
      <SnackbarContent
        className={classNames(classes[type])}
        classes={{
          root: classes.content,
          action: classes.action
        }}
        message={message && (
          <span id='client-snackbar' className={classNames(classes.message)}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>)
        }
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  )
}
Toast.propTypes = {
  isOpen: PropTypes.bool,
  autoHideDuration: PropTypes.number,
  classes: PropTypes.shape({}),
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'default']).isRequired
}

export default withStyles(style)(Toast)
