export const fileManagerStatus = {
    UNINTIALISED: 'UNINTIALISED',
    IDLE: 'IDLE',
    IN_PROGRESS: 'IN_PROGRESS',
    CANCELLED: 'CANCELLED',
    COMPLETED: 'COMPLETED'
}

export const individualFileStatus = {
    QUEUED: 'QUEUED',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
}