import { connect } from 'react-redux'
import { change, getFormValues, registerField, unregisterField, getFormSyncErrors } from 'redux-form'
import { compose, withHandlers, withPropsOnChange, lifecycle } from 'recompose'
import _ from 'lodash'
import uuid from 'uuid/v4'
import CombinedRepeatable from './CombinedRepeatable'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'

const createRepeatableItem = ({ groupId, targetName, targetId }) => ({
  id: uuid(),
  groupId,
  targetName,
  targetId,
  disableDelete: true,
  prepopulated: true
})

const mapStateToProps = (state, { formName, sourceField, targetField, repeatableGroupId, id, requiredFieldValue }) => {
  const formValues = getFormValues(formName)(state) || {}
  const formErrors = _.get(getFormSyncErrors(formName)(state), id)

  return {
    formValues,
    formErrors,
    required: formValues[requiredFieldValue] === 'true',
    sourceValues: formValues[sourceField.id],
    targetValues: formValues[targetField.id],
    repeatableValues: formValues[repeatableGroupId],
    organisationDetails: authCombinedSelectors.getDetailsForSelectedOrganisation(state)
  }
}

const enhancer = compose(
  connect(mapStateToProps),
  withHandlers({
    registerField: ({ formName, id, dispatch, editable }) => () => {
      if (editable) {
        dispatch(registerField(formName, id, 'Field'))
      }
    },
    unregisterField: ({ formName, id, dispatch }) => () => {
      dispatch(unregisterField(formName, id))
    },
    changeField: ({ dispatch }) => (...values) => dispatch(change(...values))
  }),
  lifecycle({
    componentDidMount () {
      this.props.registerField()
    },
    componentWillUnmount () {
      this.props.unregisterField()
    }
  }),
  withPropsOnChange(['editable'], props => {
    if (props.editable) {
      props.registerField()
    } else {
      props.unregisterField()
    }
  }),
  withPropsOnChange(['sourceField'], (props) => {
    const { sourceField: { filterValues = [] } } = props

    const sourceFilterFunction = (sourceArray = []) => {
      return _.filter(sourceArray, (value) => {
        const filterMap = _.map(filterValues, (filterValue, filterKey) => {
          return _.includes(_.castArray(filterValue), value[filterKey])
        })
        return _.every(filterMap)
      })

    }

    return { sourceFilterFunction }
  }),
  withPropsOnChange(['sourceValues'], (props) => {
    const { sourceValues, sourceFilterFunction } = props

    return {
      sourceValues: sourceFilterFunction(sourceValues)
    }
  }),
  withPropsOnChange(['sourceValues', 'targetValues', 'repeatableValues'], (props) => {
    const {
      changeField,
      formName,
      repeatableGroupId,
      prepopulate = false,
      sourceValues = [],
      targetValues = [],
      repeatableValues = [],
      targetField = {}
    } = props

    if (!prepopulate) return

    const sourceIds = _.map(sourceValues, 'id')
    const targetIds = _.map(targetValues, 'id')
    const cloneRepeatableValues = _.cloneDeep(repeatableValues)

    const newRepeatableValues = _.chain(cloneRepeatableValues)
      .groupBy('groupId')
      .thru((groups) => {
        return _.reduce(sourceIds, (memo, id) => {
          memo[id] = _.has(groups, id) ? groups[id] : []
          return memo
        }, {})
      })
      .map((group, key) => {
        return _.reduce(targetValues, (memo, target) => {
          const onlyPrepopulated = _.filter(group, 'prepopulated')
          if (_.size(onlyPrepopulated) < _.size(targetIds) && !_.some(group, { targetId: target.id })) {
            memo.push(createRepeatableItem({
              groupId: key,
              targetId: target.id,
              targetName: _.get(target, targetField.labelKey)
            }))
          }
          return memo
        }, [])
      }, {})
      .flatten()
      .value()

    const mergedRepeatableValues = [ ...cloneRepeatableValues, ...newRepeatableValues ]
    const isEqual = _.isEqual(cloneRepeatableValues, mergedRepeatableValues)

    if (isEqual) return

    changeField(formName, repeatableGroupId, mergedRepeatableValues)
  }),
  withPropsOnChange(['targetValues'], (props) => {
    const {
      changeField,
      formName,
      repeatableGroupId,
      targetValues = [],
      repeatableValues = [],
      targetField
    } = props

    const targetIds = _.map(targetValues, 'id')
    const cloneRepeatableValues = _.cloneDeep(repeatableValues)

    const mergedRepeatableValues = _.chain(cloneRepeatableValues)
      .groupBy('targetId')
      .pick(targetIds)
      .values()
      .flatten()
      .reduce((memo, values) => {
        const targetValue = _.find(targetValues, { id: values.targetId })
        memo.push({
          ...values,
          targetName: _.get(targetValue, targetField.labelKey)
        })
        return memo
      }, [])
      .value()

    changeField(formName, repeatableGroupId, mergedRepeatableValues)
  }),
  withPropsOnChange(['sourceValues'], (props) => {
    const {
      changeField,
      formName,
      repeatableGroupId,
      sourceValues = [],
      repeatableValues = []
    } = props

    const sourceIds = _.map(sourceValues, 'id')
    const cloneRepeatableValues = _.cloneDeep(repeatableValues)

    const mergedRepeatableValues = _.chain(cloneRepeatableValues)
      .groupBy('groupId')
      .pick(sourceIds)
      .values()
      .flatten()
      .value()

    changeField(formName, repeatableGroupId, mergedRepeatableValues)
  }),
  withPropsOnChange(['formErrors'], (props) => {
    const {
      changeField,
      formName,
      repeatableGroupId,
      formErrors = {},
      repeatableValues = []
    } = props
    const cloneRepeatableValues = _.cloneDeep(repeatableValues)

    const mergedRepeatableValues = _.reduce(cloneRepeatableValues, (memo, field) => {
      field.error = _.has(formErrors, field.id) ? formErrors[field.id] : undefined
      memo.push(field)
      return memo
    }, [])

    changeField(formName, repeatableGroupId, mergedRepeatableValues)
  })
)

export default enhancer(CombinedRepeatable)
