import _ from 'lodash'
import { promiseReducer } from '../../util'

class MedDRAReducers {
  searchDrugDictionary = promiseReducer(
    (state, action) => {
      const results = _.get(action, 'result.rows')
      const total = _.get(action, 'result.count')
      return {
        ...state,
        results,
        total,
        page: 1
      }
    }
  )

  searchDrugDictionaryNext = promiseReducer(
    (state, action) => {
      const { page, results } = state
      const newResults = _.get(action, 'result.rows')
      const total = _.get(action, 'result.count')

      return {
        ...state,
        results: [...results, ...newResults],
        total,
        page: page + 1
      }
    }
  )

  fetchMeddraVersions = promiseReducer(
    (state, action) => {
      return {
        ...state,
        versions: _.get(action, 'result')
      }
    }
  )
}

export default new MedDRAReducers()
