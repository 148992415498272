"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var _utils = require("../../registration/1.0.0/utils");

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'password',
    field: 'Input',
    props: {
      shrink: true,
      name: 'password',
      label: 'Password',
      type: 'password',
      required: true,
      validate: [_utils.validatePassword]
    }
  }, {
    id: 'confirmPassword',
    field: 'Input',
    props: {
      shrink: true,
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: 'password',
      required: true,
      validate: [_utils.matchingPassword]
    }
  }, {
    id: 'passwordInfo',
    field: 'InfoList',
    props: {
      shrink: true,
      name: 'passwordInfo',
      label: 'Your password must:',
      helpText: 'Have at least one capital letter,Have at least one lower case letter,Have at least one numeric character,Be at least 9 characters or more long'
    }
  }];
};

exports.getFormSchema = getFormSchema;