import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { styled, withStyles } from '@material-ui/core/styles'
import { InputLabel } from '@material-ui/core'
import style from './style'
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const EditorContainer = styled('div')(({ theme }) => ({
  marginTop: 30
}))

const Wysiwyg = ({
  classes,
  value,
  label,
  onChange,
  onBlur,
  onFocus,
  shrink,
  ...props
}) => {
  return (
    <>
      <div className={classes.label}>
        <InputLabel
          htmlFor={label}
          key='label'
          shrink={shrink}
        >
          {label}
        </InputLabel>
      </div>
      <EditorContainer>
        <Editor
          initialContentState={value} // Can only set default state with value, having a fully-controlled component with redux-form is SLOOOOW as hell
          onContentStateChange={onChange}
          toolbarClassName={classes.toolbar}
          wrapperClassName={classes.wrapper}
          editorClassName={classes.editor}
          onBlur={onBlur}
          onFocus={onFocus}
          toolbar={{
            options: ['inline', 'blockType', 'list', 'link', 'image', 'history'],
            inline: {
              options: ['bold', 'italic']
            },
            blockType: {
              options: ['Normal', 'H2', 'H3', 'Blockquote']
            }
          }}
          stripPastedStyles={true}
        />
      </EditorContainer>
    </>
  )
}

export default withStyles(style)(Wysiwyg)
