import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import _ from 'lodash'

export const getIsLoading = state => state.hospitals.status === PENDING
export const getError = state => state.hospitals.error
export const getIsInitial = state => !state.hospitals.status
export const getStatus = state => state.hospitals.status
export const getPage = state => state.hospitals.page
export const getSize = state => state.hospitals.size
export const getTotal = state => state.hospitals.total
export const getHasResults = state => _.get(state, 'hospitals.results', []).length > 0
export const getHospitals = state => _.get(state, 'hospitals.results', [])
export const getHospitalsForDropdown = state => _.map(_.get(state, 'hospitals.results', []), (result) => result.name)
export const getHospitalsForSearchResults = state => _.map(_.get(state, 'hospitals.results', []), (result) => {
  return {
    ...result,
    label: `${result.name} - ${result.code}`,
    name: `${result.name} - ${result.code}`
  }
})

export const getHasMore = createSelector([
  getTotal,
  getHospitals
], (total, results) => {
  return total && results.length < total
})

export const getHospitalsAsOptions = createSelector([
  getHospitals
], (hospitals) => {
  return hospitals.map(hospital => ({ label: hospital, value: hospital }))
})
