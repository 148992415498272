import _ from 'lodash'
import React from 'react'

import FiltersContainer from './FiltersContainer'

export default FiltersContainer

// this is useful because of the way our modalService and our index/component file
// structure work. sometimes, putting the contents of a modal as children of FiltersContainer
// inside a render function doesn't allow the contents of the modal to subscribe to redux state.
// this HOC is a workaround for that.
export const withFiltersContainer = (Comp) => {
  return (props) => {
    return <FiltersContainer {..._.pick(props, ['numberOfActiveFilters'])}>
      <Comp {...props} />
    </FiltersContainer>
  }
}
