import React from 'react'
import fp from 'lodash/fp'
import { useApiRequestHandler } from './useApiRequestHandler'
import Autocomplete from '../Autocomplete'
import { withStyles } from '@material-ui/styles'
import * as utils from './utils'
import style from './style'
import { translations } from '../../../config'
import { useSelector } from 'react-redux'
import { selectors as authSelectors } from '../../../store/modules/auth'

const OrganisationLookupInput = (props) => {
    const {
        classes,
        value,
        onChange,
        handleChangeOtherFields,
        minimumSearchLength
    } = props

    const {
        isLoading,
        isEmpty,
        isInitial,
        useApiRequest,
        results
    } = useApiRequestHandler({ minimumSearchLength })

    useApiRequest({ value })

    const isWorkspace = useSelector(authSelectors.isLoggedIntoWorkspace)

    const handleInputChange = ({ target: { value = '' } }) => {
        onChange(value)
    }

    const handleOptionChange = (_position, selectedOrganisation) => {
        const selection = utils.getValueFromOrganisationOption(selectedOrganisation)
        const organisation = utils.getOrganisationValueObject({ organisation: selectedOrganisation, isWorkspace })
        onChange(selection)
        handleChangeOtherFields(organisation)
    }

    const { loadingText, loading } = utils.handleAsyncStatusMessagesToUser({ isLoading, isEmpty, isInitial, minimumSearchLength })
    const noOptionsText = translations('Organisation Lookup - No Options Text')
    return (
        <Autocomplete
            {...props}
            getOptionLabel={utils.getOptionLabel}
            getOptionSelected={utils.getOptionSelected}
            renderOption={organisation => {
                const details = utils.getDropdownDetailsStringFromOrganisationOption(organisation)
                return (
                    <>
                        <span>{organisation.friendlyName}</span>
                        {details && <span className={classes.subtext}>{details}</span>}
                    </>
                )
            }}
            value={value}
            inputValue={value}
            freeSolo
            options={results}
            handleInputChange={handleInputChange}
            handleChange={handleOptionChange}
            loading={loading}
            loadingText={loadingText}
            filterOptions={fp.identity}
            noOptionsText={noOptionsText}
        />
    )

}

export default withStyles(style)(OrganisationLookupInput)
