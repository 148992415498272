import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { touch } from 'redux-form'
import PhoneNumber from './PhoneNumber'
import OptionsFormatter from '../Wrappers/OptionsFormatter'

import { countryPhoneDictionary } from '../../../config'

class PhoneNumberContainer extends React.Component {
  numbersOnly = val => {
    return val.replace(/\D+/g, '')
  }
  getCountryCodeFromNumber = (value) => {
    const trimmedValue = value.trim()
    if (!trimmedValue || !trimmedValue.startsWith('+')) {
      return
    } else if (trimmedValue.includes(' ')) {
      const [ numberCode ] = trimmedValue.split(' ')
      return countryPhoneDictionary[numberCode]
    } else if (trimmedValue.length <= 4) {
      return countryPhoneDictionary[trimmedValue]
    } else {
      let num = trimmedValue
      let countryCode
      let i = 1
      while (!countryCode && i < (num.length - 1)) {
        countryCode = countryPhoneDictionary[num.slice(0, i)]
        i++
      }
      return countryCode
    }
  }

  handleChangeCode = e => {
    const { value } = e.target
    if (value) {
      const [ countryCode, countryCodeNumber ] = value.split(' ')
      const { number } = this.formatValue(this.props.value)
      const nextValue = number
        ? `${countryCodeNumber} ${number}`
        : countryCodeNumber
      return nextValue
    } else {
      return value
    }
  }

  handleChangeNumber = number => {
    const { dialCode } = this.formatValue(this.props.value)
    const nextValue = dialCode
      ? `${dialCode} ${this.numbersOnly(number)}`
      : this.numbersOnly(number)
    return nextValue
  }

  onChange = (e, type) => {
    type === 'number'
    ? this.props.onChange(this.handleChangeNumber(e))
    : this.props.onChange(this.handleChangeCode(e))
  }

  onBlur = (e, type) => {
    type === 'number'
    ? this.props.onBlur(this.handleChangeNumber(e))
    : this.props.onBlur(this.handleChangeCode(e))
  }

  onChangeCode = (value) => {
    const { dialCode } = value || {}
    const { number } = this.formatValue(this.props.value)
    const nextValue = number
      ? `${dialCode} ${number}`
      : dialCode
    return nextValue
  }

  formatValue = (value) => {
    const escapedValue = _.replace(value, '+', '')
    const code = this.getCountryCodeFromNumber(value)

    if (code && value && value.startsWith('+')) {
      const { dialCode, code: countryCodeIsoAlpha2, countryCode: countryCodeIsoAlpha3 } = code
      const numberCode = `${countryCodeIsoAlpha2} ${dialCode}`
      const countryPhoneNumber = value.replace(dialCode, '').replace(/ /g, '')
      return {
        code: numberCode,
        countryCode: countryCodeIsoAlpha3,
        dialCode,
        number: countryPhoneNumber
      }
    } else {
      return {
        code: undefined,
        countryCode: undefined,
        dialCode: undefined,
        number: escapedValue
      }
    }
  }

  _generateProps = () => ({
    ...this.props,
    value: this.formatValue(this.props.value),
    onBlur: this.onBlur,
    onChange: this.onChange
  })

  render () {
    const props = this._generateProps()
    return <PhoneNumber {...props} />
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { formId, name } = ownProps
  const touchTelephoneField = () => {
    dispatch(touch(formId, name))
  }
  return {
    touchTelephoneField
  }
}

export default connect(null, mapDispatchToProps)(OptionsFormatter(PhoneNumberContainer))
