import React, { useContext } from 'react'

import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'

import BackBar from '../../../../components/BackBar'
import modalService from '../../../../services/modalService'
import { getDiscardModal } from '../../../../helpers/modalSchemas'
import { useRCDetails } from '../../../../contexts'

const EditorBackBar = () => {
  const dispatch = useDispatch()
  const RCDetails = useRCDetails()

  const _routerBack = () => dispatch(goBack())

  const handleBackOnClick = () => {
    if (RCDetails.isNew || RCDetails.edited) {
      modalService.action(getDiscardModal({ handleBack: () => { _routerBack() } }))
    } else {
      _routerBack()
    }
  }
  return (
    <BackBar onClick={handleBackOnClick} />
  )
}

export default EditorBackBar