import {
  TOGGLE_SIDE_MENU,
  TOGGLE_BASKET,
  OPEN_WEBVIEW,
  APP_READY,
  ON_API_FAIL,
  ON_API_DISMISS,
  CHANGE_CLIENT_BOOK_TAB,
  CHANGE_SNAPSHOT_METRIC_TAB,
  CHANGE_SNAPSHOT_CAROUSEL_TAB
} from './constants'

class AppActions {
  toggleSideMenu = ({ isOpen }) => ({
    type: TOGGLE_SIDE_MENU,
    isOpen
  })

  toggleBasket = ({ isOpen }) => ({
    type: TOGGLE_BASKET,
    isOpen
  })

  openWebView = ({ url }) => {
    window.webkit.messageHandlers.reactNative.postMessage({
      event: OPEN_WEBVIEW,
      data: { url }
    })
  }

  appReady = ({ ready }) => ({
    type: APP_READY,
    ready
  })

  onApiFail = () => ({
    type: ON_API_FAIL
  })

  onApiDismiss = () => ({
    type: ON_API_DISMISS
  })

  changeClientBookTab = ({ clientBookTab }) => ({
    type: CHANGE_CLIENT_BOOK_TAB,
    clientBookTab
  })
  changeSnapshotMetricTab = ({ snapshotMetricTab }) => ({
    type: CHANGE_SNAPSHOT_METRIC_TAB,
    snapshotMetricTab
  })
  changeSnapshotCarouselTab = ({ snapshotCarouselTab }) => ({
    type: CHANGE_SNAPSHOT_CAROUSEL_TAB,
    snapshotCarouselTab
  })
}

export default new AppActions()
