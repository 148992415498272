import React, { Component } from 'react'
import NullFlavourInput from '../../NullFlavour'
import { withStyles } from '@material-ui/core/styles'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import Dropdown from '../../Dropdown'
import SwitchInput from '../../SwitchInput'
import { change } from 'redux-form'
import _ from 'lodash'

import style from './style'

const mapStateToProps = state => ({ state })

const enhancedComponent = compose(
  withStyles(style),
  connect(mapStateToProps),
  withHandlers({
    setField: ({ formName, id, dispatch }) => (value, reset) => {
      dispatch(change(formName, id, reset ? '' : value))
    }
  }),
  NullableWrapper
)

function NullableWrapper(WrappedComponent) {
  return class extends Component {
    state = {
      nullFlavourValue: '🚫',
      otherValue: '',
      checked: false
    }
    setValue = (val) => {
      const { setField } = this.props 
        setField(val, val === '🚫')
        this.setState({
          nullFlavourValue: val
        })
    }

    setOtherValue = (val) => {
      const { setField } = this.props 
      setField(val, val === '')
      this.setState({
        otherValue: val
      })
    }

    resetOther = () => {
      const { setField } = this.props 
      setField('', true)
      this.setState({
        otherValue: ''
      })
    }
    
    setChecked = (value) => {
      this.setState({
        checked: value,
      })
    }
    render() {
      const { nullFlavours, otherOptionInput, value, shrink, label, classes, options } = this.props
      const { nullFlavourValue, checked, otherValue } = this.state
      const newprops = _.omit(this.props, 'classes')
      const checkMulti = _.get(this.props, 'multiple')

      const nullFlavourOptions = [{ value: '🚫', label: '🚫' }, ..._.get(nullFlavours, 'options', [])]

      if (otherOptionInput) {
        const wrappedValue = checked
        ? ''
        : checkMulti ?
        [...value]
        : _.get(
            _.find(options, option => option.value === otherValue),
            'value'
          )
        return (<SwitchInput 
        classes={classes}
        checked={checked}
        label={label}
        shrink={shrink}
        otherValue={otherValue}
        setOtherValue={this.setOtherValue}
        resetOther={this.resetOther}
        setChecked={this.setChecked}
        >
        <WrappedComponent
          {...newprops}
          style={{ width: '100%' }}
          onChange={e => {
            if (e.target.value !== otherValue) {
              this.setOtherValue(checkMulti ? [...value, e.target.value] : e.target.value, false)
            }
            this.props.onChange(this.setChecked(false))
          }}
          value={wrappedValue}
        />
        </SwitchInput>)
      }
      else if (_.size(nullFlavourOptions) > 1) {
        const checkProps = _.find(nullFlavourOptions, { value: value })
        if (nullFlavourValue !== '🚫' || checkProps) {
          return (
              <NullFlavourInput 
                nullFlavours={nullFlavourOptions}
                setNullFlavour={this.setValue}
                nullFlavourValue={nullFlavourValue}
                label={label}
                shrink={shrink}
                checkProps={checkProps}
                props={newprops}
              />
          )
        } else {
          return (
            <div className={classes.container}>
              <div className={classes.dropdown}>
                <Dropdown
                  options={nullFlavourOptions}
                  value={nullFlavourValue}
                  onChange={e => this.setValue(e.target.value, true)}
                  style={{ width: '100px' }}
                  noNullOption={true}
                  noErrorTextLabel={true}
                  disableUnderline={true}
                />
              </div>
              <div className={classes.wrapped}>
                <WrappedComponent {...newprops} style={{ width: '100%' }} />
              </div>
            </div>
          )
        }
      } else {
        return <WrappedComponent {...newprops} />
      }
    }
  }
}
export default enhancedComponent
