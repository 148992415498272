
import { connect } from 'react-redux'
import _ from 'lodash'
import { compose } from 'recompose'
import Button from './Button'
import { getFormValues } from 'redux-form'

export default compose(
  connect((state, ownProps) => {
    const formValues = getFormValues(ownProps.formName)(state)
    const validationFields = _.get(ownProps, 'validationFields', [])
    const validationFieldValues = _.pick(formValues, validationFields)
    let buttonValidationDisabled = false
    _.forEach(validationFieldValues, fieldValue => {
      if (!fieldValue || fieldValue === '-') {
        buttonValidationDisabled = true
      }
    })
    if (Object.keys(validationFieldValues).length !== validationFields.length) {
      buttonValidationDisabled = true
    }
    return {
      buttonValidationDisabled,
      validationFieldValues,
      validationFields,
      formValues
    }
  })
)(Button)
