import _ from 'lodash'
import { connect } from 'react-redux'
import { isSubmitting, isPristine, isInvalid, submit, getFormValues } from 'redux-form'
import moment from 'moment'
import {
  compose,
  withHandlers,
  withPropsOnChange
} from 'recompose'

import { constants as formViewConstants } from '../../../../../store/modules/formViews'
import modalService from '../../../../../services/modalService'
import ConfigureModal from './EditSectionModal'
import * as validators from '../../../../../validators'

import { translations } from '../../../../../config'
import drugTypes from '../../../../../config/configFiles/drugTypes.json'
import { defaultStartYear as stringStartYear } from '../../../../../helpers'

const defaultStartYear = _.toNumber(stringStartYear) + 1

const isLimitedToYesNoUnkOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '2' },
  { label: 'Unknown', value: '3' }
]

const getYears = (startYear = defaultStartYear, finalYearOffset = 0) => {
  let year = moment().year() + finalYearOffset
  const years = []

  while (year >= startYear) {
    years.push({ label: year.toString(), value: year })
    year--
  }

  return years
}

const enhancer = compose(
  connect((state) => {
    return {
      isSubmitting: isSubmitting(formViewConstants.CONFIGURE_SECTION_FORM)(state),
      isPristine: isPristine(formViewConstants.CONFIGURE_SECTION_FORM)(state),
      isInvalid: isInvalid(formViewConstants.CONFIGURE_SECTION_FORM)(state),
      formValues: getFormValues(formViewConstants.CONFIGURE_SECTION_FORM)(state)
    }
  }),
  withHandlers({
    closeModal: () => () => {
      modalService.close()
    },
    onSubmit: ({ dispatch }) => () => {
      dispatch(submit(formViewConstants.CONFIGURE_SECTION_FORM))
    },
    handleSubmit: (props) => (formData) => {
      const { onCallback } = props
      const answerMapKeys = _.map(isLimitedToYesNoUnkOptions, ({ value }) => `answerMap_${value}`)
      let fieldViewJson = { ...formData || {} }

      if (formData.isLimitedToYesNoUnk === true) {
        fieldViewJson.field = 'Dropdown'
        fieldViewJson.dropdownOptions = isLimitedToYesNoUnkOptions
        fieldViewJson.answerMap = {}

        _.forEach(isLimitedToYesNoUnkOptions, ({ value }) => {
          const answerMapKey = `answerMap_${value}`
          const answerMapValue = _.get(formData, answerMapKey)
          if (answerMapValue) {
            fieldViewJson.answerMap[value] = answerMapValue
          }
        })
      } else if (formData.isLimitedToYesNoUnk === false) {
        fieldViewJson.field = 'Input'
        fieldViewJson.dropdownOptions = []
        fieldViewJson.disableDefaultMapping = []
      }

      if (formData.dropdownOptions) {
        try {
          fieldViewJson.dropdownOptions = JSON.parse(fieldViewJson.dropdownOptions)
        } catch (error) {
          console.log(error)
        }
      }

      fieldViewJson = _.omit(fieldViewJson, answerMapKeys)

      if (_.isFunction(onCallback)) {
        onCallback(fieldViewJson)
      }
    }
  }),
  withPropsOnChange(['isSubmitting', 'isPristine'], (props) => {
    const { onSubmit, closeModal, isSubmitting, isPristine } = props
    return {
      actions: [
        {
          success: true,
          text: translations('Save'),
          submitting: isSubmitting,
          disabled: isPristine,
          onClick: onSubmit,
          primary: true
        },
        {
          text: translations('Cancel'),
          onClick: closeModal
        }
      ]
    }
  }),
  withPropsOnChange(['initialValues'], (props) => {
    const { initialValues } = props
    const { answerMap, dropdownOptions } = initialValues
    const parsedInitialValues = _.clone(initialValues)

    if (_.isPlainObject(answerMap)) {
      _.forEach(answerMap, (answer, answerKey) => {
        _.set(parsedInitialValues, `answerMap_${answerKey}`, answer)
      })
    }

    if (_.isArray(dropdownOptions)) {
      parsedInitialValues.dropdownOptions = JSON.stringify(dropdownOptions, null, 2)
    }

    _.unset(parsedInitialValues, 'answerMap')

    return {
      initialValues: parsedInitialValues
    }
  }),
  withPropsOnChange(['isQuestion', 'isCustom', 'fieldType', 'formValues'], (props) => {
    const { isQuestion, isCustom, fieldType, formValues } = props

    const schema = [{
      id: 'displayName',
      field: 'Input',
      props: {
        label: translations('Display name'),
        name: 'displayName'
      }
    }]

    if (isQuestion) {
      schema.push({
        id: 'mandatory',
        field: 'Checkbox',
        props: {
          label: translations('Mandatory'),
          name: 'mandatory'
        }
      })
      schema.push({
        id: 'helperText',
        field: 'Input',
        props: {
          label: translations('Field hint text'),
          name: 'helperText',
          help: translations('Report Configuration - HelperText Help')
        }
      })
    }

    if (fieldType === 'Date') {
      schema.push({
        id: 'startYear',
        field: 'Dropdown',
        props: {
          shrink: true,
          label: translations('Start Year'),
          name: 'startYear',
          options: getYears()
        }
      })
    }

    if (fieldType === 'DrugLookupInput' || fieldType === 'MedDRALookupInput') {
      schema.push({
        id: 'restrictFreeformEntry',
        field: 'Checkbox',
        props: {
          shrink: true,
          label: translations('Restrict Freeform Entry'),
          name: 'restrictFreeformEntry',
          help: translations('Report Configuration - restrictFreeformEntry Help')
        }
      })
    }

    if (fieldType === 'DrugLookupInput') {
      schema.push({
        id: 'type',
        field: 'Dropdown',
        props: {
          multiple: true,
          shrink: true,
          label: translations('Report Configuration - DrugType'),
          name: 'lookupOptions.type',
          options: drugTypes,
          noNullOption: true
        }
      })
    }

    if (isQuestion) {
      schema.push({
        id: 'hideFromForm',
        field: 'Checkbox',
        props: {
          label: translations('Hide From Form'),
          name: 'hideFromForm'
        }
      })
      if (isCustom) {
        schema.push({
          id: 'isLimitedToYesNoUnk',
          field: 'Checkbox',
          props: {
            label: translations('Accept only Yes, No or Unknown values'),
            name: 'isLimitedToYesNoUnk',
            help: translations('Report Configuration - isLimitedToYesNoUnk Help')
          }
        })
      }
      if (_.get(formValues, 'isLimitedToYesNoUnk') === true) {
        _.forEach(isLimitedToYesNoUnkOptions, (option) => {
          schema.push({
            id: `answerMap_${option.value}`,
            field: 'Input',
            props: {
              label: translations(`Report Configuration - AnswerMap - ${option.label}`),
              name: `answerMap_${option.value}`,
              help: translations('Report Configuration - AnswerMap Help'),
              multiline: true,
              rows: 5,
              validate: [validators.isJSON]
            }
          })
        })
        schema.push({
          id: 'disableDefaultMapping',
          field: 'Dropdown',
          props: {
            label: translations('Report Configuration - Disable default mapping'),
            name: 'disableDefaultMapping',
            options: _.map(isLimitedToYesNoUnkOptions, (option) => ({
              label: translations(`Report Configuration - AnswerMap - ${option.label}`),
              value: option.value
            })),
            multiple: true
          }
        })
      }
    }

    schema.push({
      id: 'isForAssessment',
      field: 'Checkbox',
      props: {
        label: translations('Is for Assessment'),
        name: 'isForAssessment',
        help: translations('Report Configuration - isForAssessment Help')
      }
    })

    if (!isQuestion) {
      schema.push({
        id: 'isForThromboticEvents',
        field: 'Checkbox',
        props: {
          label: translations('Is used for thrombotic events'),
          name: 'isForThromboticEvents',
          help: translations('Report Configuration - isForThromboticEvents Help')
        }
      })
      schema.push({
        id: 'isForMyocarditis',
        field: 'Checkbox',
        props: {
          label: translations('Is used for myocarditis reporting'),
          name: 'isForMyocarditis',
          help: translations('Report Configuration - isForMyocarditis Help')
        }
      })
    }

    if (!isQuestion) {
      schema.push({
        id: 'hideFromForm',
        field: 'Checkbox',
        props: {
          label: translations('Hide section from form'),
          name: 'hideFromForm',
          help: translations('Report Configuration - hideSectionFromForm Help')
        }
      })
    }

    if (!isCustom) {
      if (fieldType === 'Dropdown') {
        schema.push({
          id: 'dropdownOptions',
          field: 'Input',
          props: {
            label: translations('Report Configuration - Custom dropdown options'),
            name: 'dropdownOptions',
            help: translations('Report Configuration - DropdownOptions Help'),
            multiline: true,
            rows: 5,
            validate: [validators.isJSON, validators.isDropdownOptions]
          }
        })
      }
    }

    return {
      schema
    }
  })
)

export default enhancer(ConfigureModal)
