import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { withStyles } from '@material-ui/core/styles'
import AlertBadge from '../AlertBadge'
import Heading from '../../components/Heading'
import Banner from '../../components/Banner'
import { translations } from '../../config'
import style from './style'

const _renderLogoImage = ({ classes, logoSrc, logoAlt }) => (
  <img className={classes.whiteLetters} src={logoSrc} alt={logoAlt} />
)

const _renderLogo = ({ classes, logoSrc, logoAlt, logoLink }) => {
  if (!logoLink) return _renderLogoImage({ classes, logoSrc, logoAlt })
  return (
    <a className={classes.headerLink} href={logoLink} target={'_blank'}>
      {_renderLogoImage({ classes, logoSrc, logoAlt })}
    </a>
  )
}

const _renderMenuButton = ({ isLoggedIn, openMenu, classes, headerContrastColor }) => {
  if (!isLoggedIn) {
    return null
  }

  return (
    <IconButton color='inherit' aria-label={translations('Menu')} onClick={openMenu} className={classes.burger}>
      <AlertBadge type='notifications'>
        <MenuIcon htmlColor={headerContrastColor} color='secondary' />
      </AlertBadge>
    </IconButton>
  )
}

const Header = ({
  children,
  rightElement: RightElement,
  openMenu,
  classes,
  logoSrc,
  logoAlt,
  logoLink,
  headerContrastColor,
  headerTitle,
  isLoggedIn,
  hasPendingChanges,
  pendingChangesOrganisations,
  ...props
}) => {

  return (
    <AppBar
      position='static'
      className={classes.appBar}
    >
      <Toolbar className={classes.toolBar}>
        {_renderMenuButton({ isLoggedIn, openMenu, classes, headerContrastColor})}
        <div className={classes.titleContainer}>
          {headerTitle && <Heading component='h1' className={classes.title}>{headerTitle}</Heading>}
        </div>
        <div className={classes.logoContainer}>
          {_renderLogo({ classes, logoSrc, logoAlt, logoLink })}
        </div>
      </Toolbar>
      {hasPendingChanges && <Banner
        title={translations('Pending Approval - Title', { organisations: pendingChangesOrganisations })}
        description={translations('Pending Approval - Description')}
        withClose
      />}
    </AppBar>
  )
}

Header.propTypes = {
  RightElement: PropTypes.element,
  isLoggedIn: PropTypes.bool
}

Header.defaultProps = {
  isLoggedIn: false
}

export default withStyles(style)(Header)
