import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import Editable from '../../../../components/Editable'
import { FormBody, FormError } from '../../../../components/Form'
import ManageContentBox from '../../../../components/ManageContentBox'
import { translations } from '../../../../config'

import AccountType from './AccountType'
import Organisations from './Organisations'
import Role from './Role'
import Professions from './Professions'
import UserGroups from './UserGroups'
import style from '../style'

const renderFields = (props) => {
  const {
    classes,
    editing,
    editable,
    constituent,
    formId,
    isPublic,
    hideRoles,
    hideOrganisations,
    hideProfessions,
    hideUserGroups,
    isAccountTypeEditable
  } = props

  const getComponents = () => {
    const components = []

    if (isAccountTypeEditable) {
      components.push(AccountType)
    }

    if(!hideRoles) {
      components.push(Role)
    }
    if(!hideOrganisations) {
      components.push(Organisations)
    }
    if(!hideProfessions) {
      components.push(Professions)
    }
    if(!hideUserGroups) {
      components.push(UserGroups)
    }
    return components
  }

  const components = getComponents()
  
  return <Grid className={classes.flexGridContainer} container spacing={2}>
    {components.map((Comp, index) => {
      return <Comp
        key={index}
        editing={editing || constituent}
        editable={editable || constituent}
        formId={formId}
        isPublic={isPublic}
      />
    })}
  </Grid>
}

const WrappedRolesAndOrganisationsForm = Editable((props) => {
  const {
    classes,
    initialValues,
    editing,
    toggleEdit,
    editable,
    isSubmitting,
    onSubmit,
    formId,
    constituent,
    FormComponent,
    isPublic,
    isRolesAndOrganisationsViewable,
    hideOrganisations
  } = props

  let boxName
  if (!isRolesAndOrganisationsViewable || isPublic) {
    boxName = translations('Profession')
  } else if(hideOrganisations) {
    boxName = translations('Role')
  } else {
    boxName = translations('Role and Organisation(s)')
  }

  return constituent ? (
    <ManageContentBox
      name={boxName}
      content={renderFields(props)}
    />
  ) : (
    <FormComponent
      editable={editable}
      editing={editing}
      toggleEdit={toggleEdit}
      isSubmitting={isSubmitting}
      enableReinitialize
      initialValues={initialValues}
      givenClass={classes.formContainer}
      boxName={boxName}
      onSubmit={onSubmit}
      formId={formId}
    >
      <FormError givenClass={classes.submitError} />
      <FormBody>
        {renderFields(props)}
      </FormBody>
    </FormComponent>
  )
})

export default withStyles(style)(WrappedRolesAndOrganisationsForm)
