import _ from 'lodash'
import fsService from '../fsService'
import uploadService from '../uploadService'

class ImageUploadService {
  _imageTypes = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/svg+xml': 'svg',
    'image/x-icon': 'x-icon'
  }
  upload = (localImageURL) => {
    return fsService.loadBlob(localImageURL)
      .then((blob) => {
        const blobWithTimestamp = _.set(blob, 'name', `${(new Date()).getTime()}.${this._imageTypes[blob.type]}`)
        return uploadService.upload(blobWithTimestamp)
      })
  }
  openImagePickerLibrary = (fileSelectedCordova) => {
    if (window.cordova) {
      navigator.camera.getPicture(fileSelectedCordova, _.noop, {
        quality: 25, // Does not limit images uploaded from photo picker
        destinationType: window.Camera.DestinationType.FILE_URI,
        sourceType: window.Camera.PictureSourceType.PHOTOLIBRARY,
        encodingType: window.Camera.EncodingType.JPEG,
        mediaType: window.Camera.MediaType.PICTURE,
        allowEdit: false,
        correctOrientation: true
      })
    }
  }
  openImagePickerCamera = (fileSelectedCordova) => {
    if (window.cordova) {
      navigator.camera.getPicture(fileSelectedCordova, _.noop, {
        quality: 25, // Does not limit images uploaded from photo picker
        destinationType: window.Camera.DestinationType.FILE_URI,
        sourceType: window.Camera.PictureSourceType.CAMERA,
        encodingType: window.Camera.EncodingType.JPEG,
        mediaType: window.Camera.MediaType.PICTURE,
        allowEdit: false,
        correctOrientation: true
      })
    }
  }
}

export default new ImageUploadService()
