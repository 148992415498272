"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _mhraRulesEngine = _interopRequireDefault(require("@redant/mhra-rules-engine"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }

function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); Object.defineProperty(Constructor, "prototype", { writable: false }); return Constructor; }

var JsonRulesService = function () {
  function JsonRulesService() {
    _classCallCheck(this, JsonRulesService);
  }

  _createClass(JsonRulesService, [{
    key: "validateConditions",
    value: function () {
      var _validateConditions = _asyncToGenerator(regeneratorRuntime.mark(function _callee(_ref) {
        var conditions, data, validateConditions, validateConditionsResult, validateMessage;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                conditions = _ref.conditions, data = _ref.data;
                validateConditions = {
                  success: false
                };
                _context.prev = 2;
                _context.next = 5;
                return (0, _mhraRulesEngine["default"])({
                  conditions: conditions,
                  data: data
                });

              case 5:
                validateConditionsResult = _context.sent;
                validateMessage = _lodash["default"].get(validateConditionsResult, 'message');

                if (_lodash["default"].isString(validateMessage) || _lodash["default"].isArray(validateMessage) && _lodash["default"].size(validateMessage) > 0) {
                  validateConditions = validateConditionsResult;
                }

                _context.next = 13;
                break;

              case 10:
                _context.prev = 10;
                _context.t0 = _context["catch"](2);
                console.log(_context.t0);

              case 13:
                return _context.abrupt("return", validateConditions);

              case 14:
              case "end":
                return _context.stop();
            }
          }
        }, _callee, null, [[2, 10]]);
      }));

      function validateConditions(_x) {
        return _validateConditions.apply(this, arguments);
      }

      return validateConditions;
    }()
  }]);

  return JsonRulesService;
}();

var _default = new JsonRulesService();

exports["default"] = _default;