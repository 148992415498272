import React from 'react'

import BasicModalOverlay from '../BasicModalOverlay'
import { FormBody } from '../Form'
import Spacing from '../Spacing'
import P from '../P'

let FormModal = (props) => {
  const {
    formId,
    actions,
    title,
    text,
    schema,
    layout,
    initialValues,
    onSubmit,
    FormComp
  } = props

  const formProps = { initialValues, onSubmit }
  const basicModalOverlayProps = { actions, title }
  const formBodyProps = { schema, layout, formId, editing: true, fullWidthFields: true }

  return <FormComp {...formProps}>
    <BasicModalOverlay {...basicModalOverlayProps}>
      {text ? <P value={text} /> : null}
      {text ? <Spacing height={16} /> : null}
      <FormBody {...formBodyProps} />
    </BasicModalOverlay>
  </FormComp>
}

export default FormModal
