export const PENDING = 'websites/PENDING'
export const SUCCESS = 'websites/SUCCESS'
export const FAIL = 'websites/FAIL'
export const UPDATE_SETTINGS = 'websites/UPDATE_SETTINGS'
export const CREATE_WEBSITE = 'websites/CREATE_WEBSITE'
export const UPDATE_WEBSITE = 'websites/UPDATE_WEBSITE'
export const IS_SAVING = 'websites/IS_SAVING'
export const USE_EXISTING_WEBSITE = 'websites/USE_EXISTING_WEBSITE'
export const SET_THEME_OPTIONS = 'websites/SET_THEME_OPTIONS'
export const FETCH_PAGE = 'websites/FETCH_PAGE'
export const UPDATE_PAGE = 'websites/UPDATE_PAGE'
export const TOGGLE_PAGE_VISIBILITY = 'websites/TOGGLE_PAGE_VISIBILITY'
export const TOGGLE_OVERLAY = 'websites/TOGGLE_OVERLAY'
export const OPEN = 'websites/OPEN'
export const CLOSE = 'websites/CLOSE'