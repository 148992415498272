import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { getCurrentLanguage } from '../../../config'
import { selectors as authSelectors } from '../auth'
import { selectors as tagSelectors } from '../tags'
import _ from 'lodash'

class NewsActions {
  fetchNews = ({ newsType = 'default', drugName }) => ({
    type: constants.FETCH_NEWS,
    newsType,
    drugName,
    promise: (dispatch, getState) => {
      const size = selectors.getSize(getState())
      const page = selectors.getPage(getState())
      const language = authSelectors.getIsUserAdmin(getState()) ? null : getCurrentLanguage()
      const filters = selectors.getFilters(getState(), newsType)
      const tags = tagSelectors.resolveIdToName(filters.tags)(getState())
      const source = _.get(filters, 'source')

      return digitalStoreSdk.news
        .fetchNews({
          page,
          size,
          language,
          watchlist: newsType === 'watchlist',
          drugName,
          tags,
          source
        })
    }
  })

  fetchNewsNext = ({ newsType = 'default' }) => ({
    type: constants.FETCH_NEWS_NEXT,
    newsType,
    promise: (dispatch, getState) => {
      const size = selectors.getSize(getState())
      const page = selectors.getPage(getState())
      const language = authSelectors.getIsUserAdmin(getState()) ? null : getCurrentLanguage()
      const filters = selectors.getFilters(getState(), newsType)
      const tags = tagSelectors.resolveIdToName(filters.tags)(getState())
      const source = _.get(filters, 'source')

      return digitalStoreSdk.news
        .fetchNews({
          page: page + 1,
          size,
          language,
          watchlist: newsType === 'watchlist',
          tags,
          source
        })
    }
  })

  changeFilters = ({ filters, newsType = 'default' }) => {
    return {
      type: constants.CHANGE_NEWS_FILTERS,
      page: 1,
      filters,
      newsType
    }
  }

  changeFilter = ({ filter, newsType = 'default' }) => ({
    type: constants.CHANGE_NEWS_FILTER,
    filter,
    newsType
  })
}

export default new NewsActions()
