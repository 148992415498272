import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'

import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import RemoveIcon from '@material-ui/icons/Delete'

import Checkbox from '../../Fields/Checkbox'
import OperatorDisplay from '../OperatorDisplay'

import { styled } from '@material-ui/core/styles'
import { translations } from '../../../config'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { Draggable } from 'react-beautiful-dnd'

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center'
}))

const Container = styled('div')(({ theme, isSelected }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.grey[isSelected ? 500 : 300]}`,
  background: 'white',
  flex: 1
}))

const Header = styled('header')(({ theme, useDragAndDrop }) => ({
  padding: theme.spacing(1, 1, 1, useDragAndDrop ? 5 : 1),
  cursor: 'pointer',
  backgroundColor: '#fff'
}))

const Content = styled('div')(({ theme, useDragAndDrop }) => ({
  padding: theme.spacing(1, 1, 1, useDragAndDrop ? 5 : 1),
  borderTop: `1px solid ${theme.palette.grey[300]}`
}))

const Footer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  background: theme.palette.grey[100],
  borderTop: `1px solid ${theme.palette.grey[200]}`
}))

const RuleContainer = styled('div')(({ theme, hasRules }) => ({
  padding: theme.spacing(0.5),
  marginTop: hasRules ? theme.spacing(0.5) : 0
}))

const RuleItem = styled('div')(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  fontSize: theme.typography.pxToRem(12),
  '& > span': {
    position: 'absolute',
    marginTop: -theme.spacing(2),
    background: 'white',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[300]}`
  }
}))

const ActionItem = styled('div')(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  fontSize: theme.typography.pxToRem(12),
  '& > span': {
    position: 'absolute',
    marginTop: -theme.spacing(2),
    background: 'white',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[300]}`
  }
}))

const CheckboxControl = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2)
}))

const DragHandleContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  display: 'flex',
  alignContent: 'center',
  flexWrap: 'wrap',
  background: 'rgb(238, 238, 238)',
  color: 'rgb(117, 117, 117)',
  '&:hover': {
    background: 'rgb(224, 224, 224)',
    color: 'black'
  }
}))

const ConditionActionsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

const operatorMap = {
  equal: '=',
  notEqual: '!=',
  lessThan: '<',
  greaterThan: '>',
  lessThanInclusive: '<=',
  greaterThanInclusive: '>=',
  stringContains: 'Contains',
  stringDoesNotContain: '!Contains',
  isEmpty: 'Empty',
  isNotEmpty: '!Empty',
  isBetween: 'Between',
  isNotBetween: '!Between',
  inList: 'In List',
  notInList: 'Not In List'
}

/**
 * Display of a single condition and rules
 */
export const ConditionsItem = (props) => {
  const { entry, fieldLookup, readOnly, onEdit, onDelete, onSelect, isSelected, useDragAndDrop, index } = props
  const { id, name, description, conditions, actions, position } = entry

  const [isOpen, setOpen] = useState(false)
  const rules = _.get(conditions, 'rules')
  const baseCombinator = _.get(conditions, 'combinator', 'and')

  const onOpenToggle = (event) => {
    event.stopPropagation()
    setOpen(!isOpen)
  }

  const onLookup = useCallback(({ field, value, operator }) => {
    return _.isFunction(fieldLookup)
      ? fieldLookup({ field, value, operator })
      : { friendlyName: field, friendlyValue: value }
  }, [fieldLookup])

  const getRuleContainer = ({ field, value, operator, combinator, baseCombinator, rulesCount, index, rules: nestedRuleArray, ...rest }) => {
    const { friendlyName, friendlyValue } = onLookup({ field, value, operator })
    return (
      <RuleContainer key={`${field}_${value}`} hasRules={!!rulesCount}>
        <RuleItem hasRules={!!rulesCount}>
          {nestedRuleArray && <span>{translations('Rule Group')}</span>}
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={5}><strong>{friendlyName}</strong></Grid>
            <Grid item xs={2}>{operatorMap[operator]}</Grid>
            <Grid item xs={5}>{friendlyValue}</Grid>
          </Grid>
          {
            nestedRuleArray && (
              <>
                {
                  _.map(nestedRuleArray, (nestedRule, index) => {
                    return getRuleContainer({ ...nestedRule, index, rulesCount: _.size(nestedRuleArray), combinator })
                  })
                }
              </>
            )
          }
        </RuleItem>
        {(index < (rulesCount - 1)) && (
          <>
            <OperatorDisplay label={baseCombinator || combinator} />
          </>
        )}
      </RuleContainer>
    )
  }

  const getActionContainer = (props) => {
    const { id, action, details = {}, prefixOperation } = props
    const { field, value, operator, repeatable } = details
    const { friendlyName, friendlyValue } = onLookup({ field, value, operator })

    return (
      <RuleContainer key={id} hasRules={true}>
        <ActionItem>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={2}><strong>{translations(`Integration Management - ${action}`)}</strong></Grid>
            {prefixOperation && <Grid item xs={5}>{prefixOperation}</Grid>}
            <Grid item xs={5}>{friendlyName || repeatable}</Grid>
            {!prefixOperation && <Grid item xs={5}>{friendlyValue}</Grid>}
          </Grid>
        </ActionItem>
      </RuleContainer>
    )
  }

  const getConditionItemRow = ({ dragHandleProps, innerRef, draggableProps = {} } = {}) => (
    <Row
      ref={innerRef}
      {...draggableProps}
    >
      {_.isFunction(onSelect) && (
        <CheckboxControl>
          <Checkbox
            hideLabel
            value={isSelected}
            label={isSelected ? translations('Conditions Builder - Apply', { name }) : translations('Conditions Builder - Remove', { name })}
            onClick={() => {
              onSelect({ conditionId: id })
            }}
          />
        </CheckboxControl>
      )}
      <Container isSelected={isSelected}>
        {useDragAndDrop && (
          <DragHandleContainer {...dragHandleProps}>
            <DragIndicatorIcon />
          </DragHandleContainer>
        )}
        <Header onClick={(event) => onOpenToggle(event)} useDragAndDrop={useDragAndDrop}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>
              <IconButton size='small' onClick={(event) => onOpenToggle(event)} aria-label={translations(isOpen ? 'Close' : 'Open')}>
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography component={'h2'} variant={'body1'}><strong>{name}</strong></Typography>
              {_.isString(description) && (
                <div style={{ flexDirection: 'row' }}>
                  {_.map(_.split(description, '\n'), (line, index) => {
                    return (
                      <Typography component={'p'} variant={'body2'} key={index} >
                        {line}
                      </Typography>
                    )
                  })}
                </div>
              )}
            </Grid>
          </Grid>
        </Header>
        <Collapse in={isOpen} timeout='auto' unmountOnExit>
          <Content useDragAndDrop={useDragAndDrop}>
            {_.size(actions) > 0 && (
              <Typography component={'h4'} variant={'body1'}>{translations(`Conditions Builder - Conditions`)}</Typography>
            )}
            {_.map(rules, (rule, index) => {
              return getRuleContainer({ ...rule, baseCombinator, index, rulesCount: _.size(rules) })
            })
            }
            {_.size(actions) > 0 && (
              <ConditionActionsContainer>
                <Typography component={'h4'} variant={'body1'}>{translations(`Integration Management - Actions`)}</Typography>
                {
                  _.map(actions, (action) => {
                    return getActionContainer(action)
                  })
                }
              </ConditionActionsContainer>
            )}
          </Content>
        </Collapse>
        {!readOnly && (_.isFunction(onEdit) || _.isFunction(onDelete)) && (
          <Footer>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs>
                <Grid container spacing={0} alignItems='center' justify={'flex-end'}>
                  <Grid item>
                    <Grid container spacing={2} alignItems='center'>
                      {_.isFunction(onEdit) && (
                        <Grid item>
                          <IconButton
                            onClick={() => onEdit({ initialValues: entry })}
                            size={'small'}
                            aria-label={translations('Edit')}
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      )}
                      {_.isFunction(onDelete) && (
                        <Grid item>
                          <IconButton onClick={() => onDelete({ id })} size={'small'} aria-label={translations('Delete')}>
                            <RemoveIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Footer>
        )}
      </Container>
    </Row>
  )
  if (useDragAndDrop) {
    return (
      <Draggable draggableId={id || uuidv4()} index={position || index}>
        {provided => (
          getConditionItemRow(provided)
        )}
      </Draggable>
    )
  }
  return getConditionItemRow()
}


ConditionsItem.propTypes = {
  entry: PropTypes.object,
  fieldLookup: PropTypes.func,
  readOnly: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool
}

export default ConditionsItem
