import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  error: undefined,
  total: undefined,
  status: undefined,
  tags: []
}


const tagsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_TAGS:
      return reducers.fetchTags(state, action)
    default:
      return state
  }
}

export default tagsReducer
