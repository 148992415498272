import React from 'react'
import StandardForm from '../StandardForm'
import { SdkProvider, useSdk } from '../../../services/sdk'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

const UpdatePasswordForm = ({
  onSuccess,
  onError,
  themeId
}) => {

  const { changeCurrentUserPassword } = useSdk()

  const onSubmit = async (data) => {
    try {
      const modifiedData = _.omit(data, 'confirmPassword')
      await changeCurrentUserPassword(modifiedData)
      onSuccess && onSuccess()
    } catch (error) {
      onError && onError(error)
      return { [FORM_ERROR]: error.message }
    }
  }

  return (
    <StandardForm
      schemaName={'updatePassword'}
      onSubmit={onSubmit}
      submitText={'Update'}
    />
  )
}

const WrappedUpdatePasswordForm = (props) => {
  const {
    auth,
    baseUrl,
    organisationId,
    platformId,
    websiteId,
    themeId,
    ...rest
  } = props

  return (
    <SdkProvider
      auth={auth}
      baseUrl={baseUrl}
      organisationId={organisationId}
      platformId={platformId}
      websiteId={websiteId}
    >
      <UpdatePasswordForm 
        {...rest} 
        themeId={themeId} 
      />
    </SdkProvider>
  )
}

export default WrappedUpdatePasswordForm