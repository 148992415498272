import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import CompanyAccountRequestForm from './CompanyAccountRequestForm'
import { actions as authActions } from '../../../store/modules/auth'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { getFormValues } from 'redux-form'
import { constants as organisationDetailsConstants } from '../../../store/modules/organisationDetails'

const mapStateToProps = state => ({
  isSabreRequestCompanyAccountRegistration: platformSelectors.getHasSabreRequestCompanyAccountRegistrationEnabled(state),
  platformId: platformSelectors.getCurrentPlatformId(state),
  newOrgContactDetailsFormValues: getFormValues(organisationDetailsConstants.NEW_REPORTING_ORGANISATION_CONTACT_DETAILS_FORM_NAME)(state)
})


export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch }) => params => {
      return dispatch(authActions.requestNewCompanyAccount(params))
    }
  })
)(CompanyAccountRequestForm)
