import { theme } from '../../../config'
const { totalHeaderHeight } = theme

export default theme => ({
  listContainer: {
    margin: '20px 7.5% 0'
  },
  collapsibleContainer: {
    margin: '0 auto',
    paddingTop: '20px',
    paddingBottom: '20px',
    maxWidth: '1280px',
    '& .MuiGrid-item': {
      padding: '0 8px'
    }
  },
  collapsibleRowContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: `calc(50px - ${theme.spacing(2)}px)`,
    '&:first-child': {
      borderTop: '1px solid rgb(224, 224, 224)'
    }
  },
  groupedListContainer: {
    margin: '0 7.5%'
  },
  buttonsContainer: {
    display: 'flex',
    
    padding: '10px 0',
    margin: '0px -5px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'stretch'
    }
  },
  buttonContainer: {
    position: 'relative',
    zIndex: 1,
    padding: '5px',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0
    }
  },
  noDataPlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 19,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    height: `calc(100vh - ${totalHeaderHeight}px)`
  }
})
