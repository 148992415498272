"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.joinPatientStreetNameAndAddress = exports.joinHealthcareFacilityStreetNameAndAddress = exports.isAefiLogicNotActive = exports.isAefiLogicActive = exports.hasNotOtherReactionBeenSubmitted = exports.hasNotHadAdverseEvent = exports.hasHadAdverseEvent = exports.computeAefiCountry = exports.clearIfNoAdverseEvent = exports.aefiReactionOptions = void 0;

var _fp = _interopRequireDefault(require("lodash/fp"));

var _lodash = _interopRequireDefault(require("lodash"));

var computedHidden = _interopRequireWildcard(require("../computedHidden"));

var computedValue = _interopRequireWildcard(require("../computedValue"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var isAefiLogicActive = computedValue.isFieldLogicActive('aefilogic');
exports.isAefiLogicActive = isAefiLogicActive;
var isAefiLogicNotActive = computedValue.not(isAefiLogicActive);
exports.isAefiLogicNotActive = isAefiLogicNotActive;
var aefiReactionOptions = [{
  label: 'Severe local reaction',
  value: 'severeLocalReaction'
}, {
  label: 'Severe local reaction > 3 days',
  value: 'severeLocalReactionGreaterThan3Days'
}, {
  label: 'Severe local reaction beyond nearest joint',
  value: 'severeLocalReactionBeyondNearestJoint'
}, {
  label: 'Afebrile seizure',
  value: 'afebrileSeizure'
}, {
  label: 'Febrile seizure',
  value: 'febrileSeizure'
}, {
  label: 'Abscess',
  value: 'abscess'
}, {
  label: 'Sepsis',
  value: 'sepsis'
}, {
  label: 'Encephalopathy',
  value: 'encephalopathy'
}, {
  label: 'Toxic shock syndrome',
  value: 'toxicshocksyndrome'
}, {
  label: 'Thrombocytopenia',
  value: 'thrombocytopenia'
}, {
  label: 'Anaphylaxis',
  value: 'anaphylaxis'
}, {
  label: 'Fever >38°C',
  value: 'feverGreaterThan38Celsius'
}];
exports.aefiReactionOptions = aefiReactionOptions;
var hasNotOtherReactionBeenSubmitted = computedValue.not(computedHidden.isRepeatableOrMultipleOptionIsNotEmpty('reaction'));
exports.hasNotOtherReactionBeenSubmitted = hasNotOtherReactionBeenSubmitted;
var hasHadAdverseEvent = computedHidden.isRepeatableOrMultipleOptionIsNotEmpty('adverseEventsExperienced');
exports.hasHadAdverseEvent = hasHadAdverseEvent;
var hasNotHadAdverseEvent = computedValue.not(hasHadAdverseEvent);
exports.hasNotHadAdverseEvent = hasNotHadAdverseEvent;
var clearIfNoAdverseEvent = computedValue.clearIfNot(hasHadAdverseEvent);
exports.clearIfNoAdverseEvent = clearIfNoAdverseEvent;
var joinPatientStreetNameAndAddress = computedValue.joinFields(['patientaddresshousenameornumber', 'patientaddressline1', 'patientaddressline2']);
exports.joinPatientStreetNameAndAddress = joinPatientStreetNameAndAddress;
var joinHealthcareFacilityStreetNameAndAddress = computedValue.joinFields(['healthcarefacilityaddressline1', 'healthcarefacilityaddressline2']);
exports.joinHealthcareFacilityStreetNameAndAddress = joinHealthcareFacilityStreetNameAndAddress;

var computeAefiCountry = _fp["default"].cond([[isAefiLogicActive, _fp["default"].compose(_fp["default"].find(_fp["default"].identity), _fp["default"].at(['primarysourcecountry', '_parentFormValues.primarysourcecountry']))], [_fp["default"].identity, _fp["default"].noop]]);

exports.computeAefiCountry = computeAefiCountry;