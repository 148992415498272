import textContentStyle from '../../../components/Form/TextContent/style'

const styles = (theme) => ({
  grid: {
    width: '100%'
  }
})

export default theme => ({
  ...styles(theme),
  ...textContentStyle
})
