import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'

import { translations } from '../../../config'
import { styled, Typography } from '@material-ui/core'
import ListValueEditor from './ListValueEditor'

const NullValueDiv = styled('div')(({ theme }) => ({
  flex: '1 1 0%'
}))

const ValueEditor = (props) => {
  const {
    operator,
    value,
    handleOnChange,
    title,
    className,
    inputType,
    values
  } = props

  const nullValueOperators = [
    'null',
    'notNull',
    'isEmpty',
    'isNotEmpty',
    'inFuture',
    'inPast'
  ]

  const isNullValueOperator = _.includes(nullValueOperators, operator)
  const [betweenValue, setBetweenValue] = useState(['', ''])

  const handleBetweenChange = ({ isStart, value }) => {
    const nextValue = _.clone(betweenValue)
    if (isStart) {
      nextValue[0] = value
    }
    if (!isStart) {
      nextValue[1] = value
    }
    setBetweenValue(nextValue)
    handleOnChange(nextValue)
  }

  useEffect(() => {
    if (isNullValueOperator && value !== '') {
      handleOnChange('')
    }
  }, [handleOnChange, isNullValueOperator, value])

  if (isNullValueOperator) {
    return <NullValueDiv />
  }

  switch (inputType) {
    case 'select':
      switch (operator) {
        case 'inList':
        case 'notInList':
          const safeValue = _.castArray(value || [])
          const mappedSafeValue = _.map(safeValue, (val) => {
            const indexOfVal = _.findIndex(values, { name: val })
            if (indexOfVal !== -1) {
              return _.find(values, { name: val })
            }
            return val
          })
          return (
            <FormControl className={className} title={title} size={'small'} fullWidth>
              <ListValueEditor
                freeSolo={false}
                options={values}
                value={mappedSafeValue}
                onChange={((e, value) => {
                  handleOnChange(value)
                })}
              />
            </FormControl>
          )
        default:
          return (
            <FormControl className={className} size={'small'}>
              <Select value={value} onChange={(e) => handleOnChange(e.target.value)}>
                {values && _.map(values, (v) => (
                  <MenuItem key={v.name} value={v.name}>
                    {v.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
      }
    case 'checkbox':
      return (
        <Checkbox
          className={className}
          onChange={(e) => handleOnChange(e.target.checked)}
          checked={!!value}
          size={'small'}
        />
      )

    case 'radio':
      return (
        <FormControl className={className} title={title} component='fieldset'>
          <RadioGroup value={value} onChange={(e) => handleOnChange(e.target.value)} size={'small'}>
            {_.map(values, (v) => (
              <FormControlLabel key={v.name} value={v.name} control={<Radio />} label={v.label} />
            ))}
          </RadioGroup>
        </FormControl>
      )

    case 'text':
    case 'date':
      switch (operator) {
        case 'isBetween':
        case 'isNotBetween':
          const mappedInputType = inputType === 'text' ? 'number' : inputType
          return (
            <FormControl className={className} title={title} size={'small'} fullWidth style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              <Input
                type={mappedInputType}
                value={_.get(value, '0')}
                title={title}
                onChange={(e) => handleBetweenChange({ isStart: true, value: e.target.value })}
                placeholder={translations('Conditions Builder - Between Start')}
              />
              <Typography>And</Typography>
              <Input
                type={mappedInputType}
                value={_.get(value, '1')}
                title={title}
                onChange={(e) => handleBetweenChange({ isStart: false, value: e.target.value })}
                placeholder={translations('Conditions Builder - Between End')}
              />
            </FormControl >
          )
        case 'inList':
        case 'notInList':
          return (
            <FormControl className={className} title={title} size={'small'} fullWidth>
              <ListValueEditor
                options={[]}
                value={value}
                onChange={((e, value) => handleOnChange(value))}
              />
            </FormControl>
          )
        default: //do nothing
      }
    default:
      return (
        <FormControl className={className} title={title} size={'small'}>
          <Input
            type={inputType || 'text'}
            value={value}
            title={title}
            onChange={(e) => handleOnChange(e.target.value)}
            placeholder={translations('Condition Builder - Value editor placeholder')}
          />
        </FormControl>
      )
  }
}

export default ValueEditor
