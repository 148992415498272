import React from 'react'
import { asyncActions, actions }  from '../../../../store/modules/terminology'
import fp from 'lodash/fp'

export const useClearEntriesOnActiveArrangementIdChange = ({ activeArrangementId, dispatch, entity }) => React.useEffect(() => {
    dispatch(actions.resetMasterExcludingFilters())

}, [activeArrangementId, dispatch])

export const useGetTerminologyArrangementEntries = ({ dispatch, activeArrangementId, filters, page }) => {
    React.useEffect(() => {
        let promise = null
            ;(async () => {
            if (!fp.isEmpty(filters)) {
                promise = dispatch(asyncActions.getTerminologyArrangementEntries())
            }
        })()
        /**
         * Cancel the action to fetch entries if the component dismounts.
         * This is used where the tab has been switched by the user,
         * but redux is still fetching the entries.
         *
         * This could result in a bug where dictionary arrangements could show entries from other arrangements.
         */
        return () => {
            if (!fp.isNil(promise)) {
                promise.abort()
            }
        }
    }, [activeArrangementId, filters, page, dispatch])
}

export const useLoadMore = ({ dispatch }) => React.useCallback(() => {
    dispatch(actions.incrementPage())
}, [dispatch])
