import * as validators from '../../../validators'

export default {
  messagAck: {
    title: 'Message Acknowledgement',
    initialOpen: true,
    schema: [
      {
        id: 'messagenumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'messagenumb',
          label: `Acknowledgement message number`,
          required: true,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'icsrmessagenumb',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'icsrmessagenumb',
          label: `ICSR message number`,
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'icsrmessagesenderidentifier',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'icsrmessagesenderidentifier',
          label: `ICSR message sender ID`,
          required: false,
          validate: [validators.maxLength(60, 'Field')]
        }
      },
      {
        id: 'icsrmessagereceiveridentifier',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'icsrmessagereceiveridentifier',
          label: `ICSR message receiver ID`,
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'icsrmessagedate',
        field: 'Date',
        props: {
          disabled: true,
          shrink: true,
          name: 'icsrmessagedate',
          label: 'ICSR message date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
          helperText: 'YYYY/MM/DD'
        }
      },
      {
        id: 'transmissionacknowledgmentcode',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'transmissionacknowledgmentcode',
          label: 'Transmission acknowledgement code',
          options: [
            { value: '01', label: 'All Reports loaded into database' },
            { value: '02', label: 'ICSR Error, not all reports loaded into the database, check section B' },
            { value: '03', label: 'SGML parsing error, no data extracted ' }
          ],
          required: true
        }
      },
      {
        id: 'parsingerrormessage',
        field: 'Input',
        computedDisabled: ({ transmissionacknowledgmentcode }) => transmissionacknowledgmentcode !== '03',
        props: {
          shrink: true,
          name: 'parsingerrormessage',
          label: 'Parsing error message',
          validate: [validators.maxLength(250, 'Field')]
        }
      }
    ]
  },
  safetyReportAck: {
    title: 'Safety Report Acknowledgement',
    initialOpen: true,
    schema: [
      {
        id: 'safetyreportid',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'safetyreportid',
          label: `Safety report ID`,
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'safetyreportversion',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'safetyreportversion',
          label: `Safety report version number`,
          required: false,
          validate: [validators.maxLength(2, 'Field')]
        }
      },
      {
        id: 'localreportnumb',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'localreportnumb',
          label: `Local report number`,
          required: false
        }
      },
      {
        id: 'authoritynumb',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'authoritynumb',
          label: `Regulatory authority's case report number`,
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'companynumb',
        field: 'Input',
        props: {
          disabled: true,
          shrink: true,
          name: 'companynumb',
          label: `Other sender’s case report number`,
          required: false,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'receiptdate',
        field: 'Date',
        props: {
          disabled: true,
          shrink: true,
          name: 'receiptdate',
          label: 'Recipt date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
          helperText: 'YYYY/MM/DD'
        }
      },
      {
        id: 'reportacknowledgmentcode',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'reportacknowledgmentcode',
          label: 'Acknowledgment code for a report',
          options: [
            { value: '01', label: 'Report loaded successfully' },
            { value: '02', label: 'Report not loaded' }
          ],
          required: true
        }
      },
      {
        id: 'errormessagecomment',
        field: 'Input',
        props: {
          shrink: true,
          name: 'errormessagecomment',
          label: 'Error message or comment',
          required: false,
          validate: [validators.maxLength(250, 'Field')]
        }
      }
    ]
  }
}
