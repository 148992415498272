import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  error: undefined,
  query: undefined,
  status: undefined,
  count: undefined,
  rows: undefined,
  page: 1,
  organisationLocations: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SEARCH_LOCATIONS:
      return reducers.searchLocations(state, action)
    case constants.RESET_STORE:
      return defaultState
    case constants.FETCH_ORGANISATION_LOCATIONS:
      return reducers.fetchOrganisationLocations(state, action)
    case constants.FETCH_LOCATION_PROVIDERS:
      return reducers.fetchLocationProviders(state, action)
    default:
      return state
  }
}
