import _ from 'lodash'

import ListItems from '../ListItems'

const NullComp = () => null
const getListItem = props => {
  const dataType = _.chain(props)
    .get('reportSchema.chartOptions.dataType')
    .toLower()
    .value()

  const _listItem = ListItems[_.toLower(dataType)] || ListItems.simple || {}
  const listItem = {
    ChartItemComponent: _listItem.InChart || NullComp,
    FullListItemComponent: _listItem.InFullList || _listItem.InChart || NullComp,
    onClickItem: (_listItem.onClickItem && _listItem.onClickItem(props.dispatch)) || undefined
  }

  return listItem
}

export default getListItem
