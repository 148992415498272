import React from 'react'

import Editable from '../Editable'
import { Input } from '../../../components/Fields'
import P from '../../P'

export default Editable({
  input: (props) => {
    return (
      <Input {...props} />
    )
  },
  display: ({ label, value, format, hideIfEmpty, isEmpty, type }) => {
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={value && value !== '-' ? 'Please press \'EDIT\' to view the Identity Provider Metadata' : 'Not yet provided'} />
      </div>
    )
  }
})
