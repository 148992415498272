import { connect } from 'react-redux'
import ReportsLoadingBar from './ReportsLoadingBar'
import { selectors as reportsSelectors } from '../../../../store/modules/reports'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const isLoadingReports = reportsSelectors.getIsLoading(state)
  return {
    ...ownProps,
    isLoading: isLoadingReports
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ReportsLoadingBar)
