import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import P from '../../../../components/P'
import BottomBar from '../../../../components/BottomBar'
import organisationCharts from '../../organisationReporting/organisationCharts'
import ChartsContainer from './ChartsContainer'
import style from './style'
import { selectors as analyticsSelectors } from '../../../../store/modules/platformAnalytics'
import { selectors as authSelectors } from '../../../../store/modules/auth'

import qs from 'qs'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom';
import { translations, dateFormat, timeFormat } from '../../../../config'
import Loader from '../../../../components/Loader'

import moment from 'moment'

const RetailAnalyticsContent = (props) => {

  const { classes } = props
  const location = useLocation();

  const organisationAnalytics = useSelector(analyticsSelectors.getOrganisationAnalytics)
  const isLoading = useSelector(analyticsSelectors.getIsLoadingOrganisationAnalytics)
  const selectedOrganisationId = useSelector(authSelectors.getUserSelectedOrganisationId)
  const dateAndTimeFormat = `${timeFormat} - ${dateFormat}`
  const organisationId = useSelector(analyticsSelectors.getSelectedOrganisationId) || selectedOrganisationId
  
  const { widgetType } = useParams()
  const { 
    timePeriod = 'today', 
    organisationIds = [organisationId], 
    productName, 
    reportType, 
    sourceId = 'AllSources',
    dateFrom,
    dateTo
  } = qs.parse(location.search, { ignoreQueryPrefix: true })

  const dates = useSelector(analyticsSelectors.getTimePeriodDates)
  const { startDate, endDate } = dates

  let dateRangeText = ''

  if (startDate && endDate) {
    dateRangeText = translations('Date Range',
      {
        start: moment(startDate, 'YYYY-MM-DDTHH:mm:ss.SSS').format(dateAndTimeFormat),
        end: moment(endDate).format(dateAndTimeFormat)
      })
  }

  const ChartsComponent = organisationCharts

  return (
    <>
    <React.Fragment>
      <div className={classes.container}>
        {isLoading ? <Loader /> : <ChartsContainer>
          <ChartsComponent
            widgetType={widgetType}
            ids={organisationIds}
            organisationAnalytics={organisationAnalytics}
            timePeriod={timePeriod}
            productName={productName}
            reportType={reportType}
            sourceId={sourceId}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        </ChartsContainer>}
      </div>
      <BottomBar relative>
        <div className={classes.meta}>
          <P value={dateRangeText} />
        </div>
      </BottomBar>
    </React.Fragment>
    </>
  )
}

export default withStyles(style)(RetailAnalyticsContent)
