import { compose, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import getChartData from './getChartData'
import getListData from './getListData'
import getListItem from './getListItem'




const withReportData = compose(
  withPropsOnChange(
    ['items', 'reportSchema', 'dispatch'],
    props => {
      const computedProps = {
        chartData: getChartData(props),
        listData: getListData(props),
        listItem: getListItem(props),
      }
      return computedProps
    }
  )
)

export default withReportData
