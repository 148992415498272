import _ from 'lodash'
import { promiseReducer } from '../../util'

class SubmissionsReducers {
  searchFresh = promiseReducer(
    (state, action) => {
      const { query, dateFrom, dateTo } = action
      const results = _.get(action, 'result.results')
      const total = _.get(action, 'result.total')
      return {
        ...state,
        page: 1,
        total,
        results,
        query,
        dateFrom,
        dateTo,
        selected: []
      }
    }
  )

  searchNext = promiseReducer(
    (state, action) => {
      const { results } = state
      const { query, dateFrom, dateTo } = action
      const newResults = _.get(action, 'result.results')
      const total = _.get(action, 'result.total')
      return {
        ...state,
        total,
        page: state.page + 1,
        results: [...results, ...newResults],
        query,
        dateFrom,
        dateTo
      }
    }
  )

  changeFilter = (state, action) => {
    const { filter } = action
    const { filters } = state
    return {
      ...state,
      filters: {
        ...filters,
        [filter.name]: filter.value
      }
    }
  }

  changeFilters = (state, action) => {
    const { filters } = action
    return {
      ...state,
      filters
    }
  }

  toggleSelected = (state, action) => {
    const { submissionReportId } = action
    return {
      ...state,
      selected: _.xor(state.selected, [submissionReportId])
    }
  }

  toggleSelectAll = (state, action) => {
    const { selected, results } = state
    const selectedCount = _.size(selected)
    const resultsCount = _.size(results)
    let nextSelected

    if (selectedCount === resultsCount) {
      nextSelected = []
    } else {
      nextSelected = _.map(state.results, 'id')
    }

    return {
      ...state,
      selected: nextSelected
    }
  }

  clearSelected = (state) => {
    return {
      ...state,
      selected: []
    }
  }

  fetchSavedSearches = (state, payload) => {
    const savedSearches = _.get(payload, 'result')
    const savedSearchId = _.get(state, 'activeSavedSearch')
    const savedSearchObject = _.find(savedSearches, { id: savedSearchId })
    const savedSearchFilters = _.get(savedSearchObject, 'filters')
    const { dateFrom, dateTo, userId } = _.get(state, 'filters')
    return {
      ...state,
      savedSearches,
      filters: {
        ...savedSearchFilters,
        dateFrom,
        dateTo,
        userId
      }
    }
  }

  setSavedSearch = (state, payload) => {
    const { id } = payload
    const savedSearchObject = _.find(_.get(state, 'savedSearches'), { id })
    const savedSearchFilters = _.get(savedSearchObject, 'filters')
    const { dateFrom, dateTo, ...rest } = _.get(state, 'filters')
    const newState = {
      ...state,
      activeSavedSearch: id,
      filters: {
        ...savedSearchFilters,
        dateFrom,
        dateTo
      }
    }
    if (!state.activeSavedSearch && _.isEmpty(state.previousFilters)) {
      newState.previousFilters = { dateFrom, dateTo, ...rest }
    }
    return newState
  }
  discardSavedFilters = (state) => {
    const previousFilters = _.get(state, 'previousFilters')
    const { dateFrom, dateTo } = _.get(state, 'filters')
    return {
      ...state,
      filters: {
        ...previousFilters,
        dateFrom,
        dateTo
      },
      activeSavedSearch: undefined,
      previousFilters: {}
    }
  }
}

export default new SubmissionsReducers()
