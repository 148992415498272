"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schema = void 0;

var _schema = require("./schema");

var _view = _interopRequireDefault(require("./view"));

var _formHelpers = require("./formHelpers");

var _constants = require("../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var schema = {
  type: _constants.FORM_TYPE_CONSTANTS.REPORT,
  getFormSchema: _schema.getFormSchema,
  getFormView: function getFormView() {
    return JSON.parse(JSON.stringify(_view["default"]));
  },
  getLocationField: function getLocationField(viewJSON) {
    return undefined;
  },
  getCountryCode: function getCountryCode(value) {
    return (0, _formHelpers.castToCountryCode)(value);
  }
};
exports.schema = schema;