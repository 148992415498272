import React, { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import BackBar from '../../../components/BackBar'
import Container from '../../../components/Container'
import BottomBar from '../../../components/BottomBar'
import ButtonsContainer from '../../../components/ButtonsContainer'
import Button from '../../../components/Button'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useWindowSize } from '../../../../src/hooks'
import { selectors as exportManagementSelectors, actions as exportManagementActions } from '../../../store/modules/exportManagement'
import { makeStyles } from '@material-ui/core/styles'
import ContentBox from '../../../components/ContentBox'
import ContentBoxHeader from '../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../components/Form/TextContent'
import { useInitialFetch } from '../../../hooks'
import { useParams } from 'react-router-dom'
import ExportSharedWithTable from '../ExportSharedWithTable'
import DownloadExportButton from '../DownloadExportButton/DownloadExportButton'

import _ from 'lodash'

import style from './style'

const renderSubHeader = (pageTitle) => {
  return <SubHeader
    leftContent={<BackBar />}
    centerContent={(
      <Fragment>
        <Heading component='h1'>{pageTitle}</Heading>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      </Fragment>
    )}
  />
}

const renderDetails = (recordDetails, classes) => {
  return <Container withMarginTop withMarginBottom>
    <div className={classes.formDetails}>
      <dl>
        <div className={classes.title}>
          <dt>{translations('Export Management - Name')}:</dt>
          <dd>{_.get(recordDetails, 'name')}</dd>
        </div>
      </dl>
      <dl>
        <div className={classes.title}>
          <dt>{translations('Export Management - Author')}:</dt>
          <dd>{_.get(recordDetails, 'author.firstName') + ' ' + _.get(recordDetails, 'author.lastName')}</dd>
        </div>
      </dl>
      <dl>
        <div className={classes.title}>
          <dt>{translations('Export Management - Email')}:</dt>
          <dd>{_.get(recordDetails, 'author.email')}</dd>
        </div>
      </dl>
      <dl>
        <div className={classes.title}>
          <dt>{translations('Export Management - Status')}:</dt>
          <dd>{_.get(recordDetails, 'status')}</dd>
        </div>
      </dl>
      <dl>
        <div className={classes.title}>
          <dt>{translations('Export Management - Created Date')}:</dt>
          <dd>{moment(_.get(recordDetails, 'createdAt')).format('DD/MM/YYYY HH:mm')}</dd>
        </div>
      </dl>
    </div>
    </Container>
}

const ViewExportManagementDetails = () => {

  const { id } = useParams()

  useInitialFetch(() => exportManagementActions.fetchExportById({ id }))

  const useStyles = makeStyles(style)
  const classes = useStyles()
  const recordDetails = useSelector(exportManagementSelectors.getSelectedRecordDetails)
  const [width] = useWindowSize()
  
  const shareExports = () => {
    // For further implementation
    console.log("shareExports ")
  }

  const buttons = [
    {
      children: translations('Export Management Button - Share'),
      onClick: shareExports
    }
  ]

  const renderButtons = (width, classes) => {
    const isXs = width < 600

    const {
      id,
      status,
      organisationId,
    } = recordDetails

    return <BottomBar
      relative={isXs}
      className={classes.bottomBar}
    >
      <ButtonsContainer align='center' column={isXs}>
        <DownloadExportButton id={id} recordStatus={status}/>
        {buttons.map(buttonProps => {
          return <Button
            key={buttonProps.children}
            buttonType='primary'
            className={isXs ? classes.button : ''}
            {...buttonProps}
          />
        })}
      </ButtonsContainer>
    </BottomBar>
  }


  return (

    <div className={classes.container}>
      {renderSubHeader(_.get(recordDetails, 'name'))}
      <div className={classes.outerBox}>
        <ContentBox>
          <ContentBoxHeader>
            <TextContent>
              <Heading>{translations(`Details`)}</Heading>
            </TextContent>
          </ContentBoxHeader>
          <ContentBoxBody>
            {renderDetails(recordDetails, classes)}
          </ContentBoxBody>
        </ContentBox>
        {!_.isEmpty(_.get(recordDetails, 'sharedWith')) &&
          <ContentBox>
            <ContentBoxHeader>
              <TextContent>
                <Heading>{translations(`Shared With`)}</Heading>
              </TextContent>
            </ContentBoxHeader>
            <ContentBoxBody>
              <ExportSharedWithTable data={_.get(recordDetails, 'sharedWith')} />
            </ContentBoxBody>
          </ContentBox>
        }
        {renderButtons(width, classes)}
      </div>
    </div>

  )
}

export default withStyles(style)(ViewExportManagementDetails)
