import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import InputLabel from '../../../InputLabel'

import style from './style'

const Label = props => {
  const { classes, label, noFloatingLabel, shrink, required, htmlFor, id, staticLabel } = props

  if (noFloatingLabel || !label) return null

  return <InputLabel
    FormControlClasses={{
      focused: classes.floatingLabelFocusStyle
    }}
    required={noFloatingLabel ? false : required}
    htmlFor={htmlFor || label}
    shrink={!!shrink}
    id={id}
    staticLabel={staticLabel}
  >
    {!noFloatingLabel && label}
  </InputLabel>
}

Label.defaultProps = {
  required: false,
  shrink: false
}

Label.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  noFloatingLabel: PropTypes.bool,
  shrink: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  required: PropTypes.bool,
  htmlFor: PropTypes.string,
  id: PropTypes.string
}

export default withStyles(style)(Label)
