const styles = (theme) => {
  return {
    container: {},
    content: {},
    modalAction: {
      paddingRight: theme.spacing(2)
    },
    heading: {
      marginBottom: theme.spacing(2)
    },
    summary: {
      marginBottom: theme.spacing(3),
      '& > h2': {
        marginBottom: theme.spacing(0.5)
      }
    },
    grid: {},
    gridContent: {
      padding: theme.spacing(3)
    },
    code: {},
    meta: {
      fontSize: 14,
      lineHeight: 1.4,
      margin: 0,
      marginTop: theme.spacing(0.5),
      '& > strong': {
        textTransform: 'capitalize'
      }
    },
    section: {
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
      '& > h3': {
        marginBottom: theme.spacing(2)
      },
      '&:last-child': {
        marginBottom: 0
      }
    },
    list: {
      listStyle: 'none outside',
      margin: 0,
      padding: 0
    },
    listItem: {
      padding: '8px 10px',
      margin: '0 0 15px 0',
      borderLeft: `4px solid ${theme.palette.grey[300]}`,
      '& > h4': {
        marginBottom: theme.spacing(0.5)
      },
      '&:last-child': {
        marginBottom: 0
      }
    }
  }
}

export default styles
