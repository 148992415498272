"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  computeReactionSeriousnessDeath: true,
  computeReactionOutcome: true,
  computeReportType: true,
  isInEuCountry: true,
  isMandatoryDrugIntervalUnitRequired: true,
  isUsingEURules: true,
  euValidator: true,
  isEu: true
};
exports.computeReportType = exports.computeReactionSeriousnessDeath = exports.computeReactionOutcome = void 0;
Object.defineProperty(exports, "euValidator", {
  enumerable: true,
  get: function get() {
    return _utils.euValidator;
  }
});
Object.defineProperty(exports, "isEu", {
  enumerable: true,
  get: function get() {
    return _utils.isEu;
  }
});
exports.isUsingEURules = exports.isMandatoryDrugIntervalUnitRequired = exports.isInEuCountry = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var computedHidden = _interopRequireWildcard(require("../../../../formHelpers/computedHidden"));

var computedValue = _interopRequireWildcard(require("../../../../formHelpers/computedValue"));

var _validators = require("./validators");

Object.keys(_validators).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _validators[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _validators[key];
    }
  });
});

var _utils = require("./utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var computeReactionSeriousnessDeath = (0, _utils.euComputedFn)(computedValue.noopOrCallback(computedHidden.fieldIsDefined('reactionoutcome'), _lodash["default"].matchesProperty('reactionoutcome', '5')));
exports.computeReactionSeriousnessDeath = computeReactionSeriousnessDeath;
var computeReactionOutcome = (0, _utils.euComputedFn)(computedValue.noopOrCallback(computedHidden.fieldIs('reactionseriousnessdeath', true), computedValue.always('5')));
exports.computeReactionOutcome = computeReactionOutcome;
var computeReportType = (0, _utils.euComputedFn)(computedValue.noopOrCallback(computedHidden.fieldIs('observestudytype', ['1', '2', '3']), computedValue.always('2')));
exports.computeReportType = computeReportType;
var isInEuCountry = (0, _utils.euComputedFn)(computedHidden.fieldIs('studyregistrationcountry', 'EU'));
exports.isInEuCountry = isInEuCountry;
var isMandatoryDrugIntervalUnitRequired = (0, _utils.euComputedFn)(computedHidden.fieldIs('drugintervaldosagedefinition', ['a', 'mo', 'wk', 'd', 'h', 'm']));
exports.isMandatoryDrugIntervalUnitRequired = isMandatoryDrugIntervalUnitRequired;
var isUsingEURules = (0, _utils.euComputedFn)(_lodash["default"].stubTrue);
exports.isUsingEURules = isUsingEURules;