import { styled } from '@material-ui/core'

import { DataTableCell, DataTableRow } from '../../../../components/DataTable'
import { translations } from '../../../../config'

import ButtonLink from '../../../../components/ButtonLink'

const EditAction = styled(ButtonLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'underline',
  '&:hover, &:active, &:focus': {
    color: 'black'
  }
}))

const RowAction = styled(ButtonLink)(({ theme }) => ({
  color: theme.palette.error.main,
  textDecoration: 'underline',
  '&:hover, &:active, &:focus': {
    color: theme.palette.error.dark
  }
}))

const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  gap: 10,
  cursor: 'pointer'
}))

const UserGroupRow = ({
  entry = {},
  onOptionClick,
  openUserGroupModal,
  handleDelete,
  canEdit
}) => {
  const {
    id,
    name,
    description
  } = entry

  return (
    <DataTableRow
      id={id}
      hover={true}
      onClick={onOptionClick({ id })}
      style={{ cursor: 'pointer' }}
    >
      <DataTableCell compact>{name}</DataTableCell>
      <DataTableCell compact>{description}</DataTableCell>
      {canEdit && (
        <DataTableCell align={'right'}>
          <ActionsContainer>
            <EditAction onClick={(e) => {
              e.stopPropagation()
              openUserGroupModal({ data: entry, isUpdate: true })
            }}>
              {translations('Edit')}
            </EditAction>
            <RowAction onClick={(e) => {
              e.stopPropagation()
              handleDelete(id)
            }}>
              {translations('Delete')}
            </RowAction>
          </ActionsContainer>
        </DataTableCell>
      )}
    </DataTableRow>
  )
}

export default UserGroupRow
