import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import BasicModalOverlay from '../../../components/BasicModalOverlay'
import P from '../../../components/P'
import List from '../../../components/List'

import style from './style'

class CreateAccountSelectOrganisationModal extends Component {
  renderItem = (item) => {
    const { classes } = this.props
    const { name } = item
    return <div className={classes.item}>
      <P value={name} />
    </div>
  }

  render () {
    const { organisations, goToCreateAccountForm } = this.props

    return <BasicModalOverlay
      title={translations('Create Account Select Organisation Modal Title')}
    >
      <List
        items={organisations}
        renderItem={this.renderItem}
        onClickItem={goToCreateAccountForm}
        noMargin
      />
    </BasicModalOverlay>
  }
}

export default withStyles(style)(CreateAccountSelectOrganisationModal)
