import { push, LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'

import * as newsArticlesConstants from './constants'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

import newsArticleActions from './actions'
import * as newsArticleSelectors from './selectors'

class NewsMiddleware {
  loadNewsArticleMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const state = getState()
      const pathname = action.payload.location.pathname
      const pathsToMatch = ['/news/:id/view', '/news/public/:id']
      const result = pathsToMatch.reduce((memo, path) => (memo || matchPath(pathname, { path, exact: true })), false)

      const isRoutingToNewsArticle = result
      if (isRoutingToNewsArticle) {
        const { id } = result.params
        dispatch(newsArticleActions.setActiveArticle(id))
        const articles = newsArticleSelectors.getArticles(state)
        if (!articles[id]) {
          dispatch(newsArticleActions.getArticle(id))
        }
      }
    }
  }
  showModalOnCreateSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === newsArticlesConstants.CREATE_ARTICLE && action.status === 'SUCCESS') {
      modalService.action({
        title: translations('Create News Article Success Modal Title'),
        text: translations('Create News Article Success Modal Text'),
        disableBackdropClick: true,
        actions: [
          {
            success: true,
            text: translations('Continue'),
            onClick: () => {
              dispatch(push(`/news`))
            },
            primary: true
          }
        ]
      })
    }
  }
  showModalOnUpdateSuccess = ({ dispatch }) => next => action => {
    if (action.type === newsArticlesConstants.UPDATE_ARTICLE && action.status === 'SUCCESS') {
      modalService.action({
        title: translations('Edit News Article Success Modal Title'),
        text: translations('Edit News Article Success Modal Text'),
        disableBackdropClick: true,
        actions: [
          {
            success: true,
            text: translations('Continue'),
            onClick: () => {
              dispatch(push(`/news`))
            },
            primary: true
          }
        ]
      })
      next(action)
    } else {
      next(action)
    }
  }
}

export default new NewsMiddleware()
