import theme, { colors } from '../../../config/theme'

const styles = {
  button: {
    width: 30,
    height: 30,
    marginRight: 10,
    fontSize: 26,
    borderRadius: 2
  },
  notVisible: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 13,
    width: '30px',
    height: '3px',
    backgroundColor: '#000',
    transform: 'rotate(45deg)'
  }
}

export default () => styles
