import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import Heading from '../../../../components/Heading'

import MenuGrid from '../../../../components/MenuGrid'
import MenuWidget from '../../../../components/MenuWidget'

const AnalyticsMenu = ({
  isDetail,
  widgets,
  pageTitle = { title: 'Monitoring & Analytics', titleWithName: 'Monitoring & Analytics' }
}) => {
  const dispatch = useDispatch()
  const onBackClick = (e) => {
    e.preventDefault()

    if (isDetail) {
      dispatch(push('/monitoring-and-analytics'))
    } else {
      dispatch(push('/'))
    }
  }
  const onMenuItemClick = (id) => {
    dispatch(push(`/monitoring-and-analytics/${id}`))
  }
  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </>
        )}
      />
      <MenuGrid widgets={widgets} renderWidget={(widget) => {
        return (
          <MenuWidget
            id={widget.id} {...widget}
            onClick={() => onMenuItemClick(widget.id)}
          />
        )
      }} />
    </div>
  )
}
AnalyticsMenu.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string
  })),
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}
export default AnalyticsMenu
