import { translations } from "../config";

const getFormSchemaLabel = (type) => ({
  devicesReport: translations('REPORT DEVICES'),
  inprReport: translations('REPORT INPR'),
  mhraR2Report: translations('REPORT R2'),
  mhraR3Report: translations('REPORT R3'),
  halmedAppReport: translations('REPORT Halmed'),
  larebAppReport: translations('REPORT Lareb'),
  redantReport: translations('REPORT RedAnt'),
  trilogyReport: translations('REPORT Trilogy'),
  yellowcardReport: translations('REPORT Yellowcard'),
  yellowcardR2Report: translations('REPORT R2Yellowcard'),
  webradrAppReport: translations('REPORT Webradr'),
  psrReport: translations('REPORT PSR'),
  saeReport: translations('REPORT SAE'),
  trendReport: translations('REPORT TREND'),
  fscaReport: translations('REPORT FSCA'),
  sabreSAE: translations('REPORT SABRESAE'),
  sabreSAR: translations('REPORT SABRESAR')
})[type] || type

export default getFormSchemaLabel