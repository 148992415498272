import { promiseReducer } from '../../util'
import _ from 'lodash'

class OrganisationDetailsReducers {
  fetchOrganisationById = promiseReducer(
    (state, action) => ({
      ...state,
      organisation: {
        ...state.organisation,
        ...action.result
      }
    })
  )
  fetchOrganisationReceivers = promiseReducer(
    (state, action) => {
      const receivers = _.get(action, 'result', [])

      // IMPROVEMENT
      // In a future phase acknowledgement templates should be configured with receivers.
      // This fix is to allow opening on acknowledgements on the client. The above suggestion
      // would involve changing API logic around receiving acknowledgement's from third parties.
      //
      // Currently there are only 2 acknowledgement templates and these organisations
      // would never share receivers.
      // The risk of selecting the first match is very low.
      const ackFormSchema = _.filter(receivers, 'receiver.ackFormSchema')
      const ackFormSchemaName = _.get(ackFormSchema, '0.receiver.ackFormSchema')

      return {
        ...state,
        organisation: {
          ...state.organisation,
          receivers,
          ackFormSchemaName
        }
      }
    }
  )
  updateOrganisation = promiseReducer(
    (state, action) => {
      return {
        ...state,
        organisation: {
          ...state.organisation,
          ...action.result.organisation,
          receivers: action.result.orgReceivers
        }
      }
    }
  )
  createOrganisation = promiseReducer(
    (state, action) => {
      return {
        ...state
      }
    }
  )
  createDrugsLists = promiseReducer(
    (state, action) => ({
      ...state,
      organisation: action.result
    })
  )
  importDrugMeta = promiseReducer(
    (state, action) => ({
      ...state,
      organisation: action.result
    })
  )
  setProfessionsForOrganisation = promiseReducer(
    (state, action) => ({
      ...state,
      organisation: {
        ...state.organisation,
        professions: action.result
      }
    })
  )
  setProfessionGroupsForOrganisation = promiseReducer(
    (state, action) => {
      return ({
        ...state,
        organisation: {
          ...state.organisation,
          professionGroups: action.result
        }
      })
    }
  )
  setLocationsForOrganisation = promiseReducer(
    (state, action) => {
      return ({
        ...state,
        organisation: {
          ...state.organisation,
          locations: action.result.results,
          locationProviderId: action.result.locationProvider
        }
      })
    }
  )
}

export default new OrganisationDetailsReducers()
