import React from 'react'
import PropTypes from 'prop-types'
import { translations } from '../../config'
import { withStyles } from '@material-ui/core/styles'

import SubmittingButton from '../SubmittingButton'
import styles from './style'

const SubmissionReportsLoadMore = (props) => {
  const { classes, onClick, isLoading = false } = props

  return (
    <div className={classes.container}>
      <SubmittingButton
        onClick={onClick}
        isSubmitting={isLoading}>
          {translations('Load more results')}
      </SubmittingButton>
    </div>
  )
}

SubmissionReportsLoadMore.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default withStyles(styles)(SubmissionReportsLoadMore)
