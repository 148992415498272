import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Form, { FormBody } from '../../../../../components/Form'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../../../config'
import { constants as formViewConstants } from '../../../../../store/modules/formViews'

import style from './style'

const EditSectionForm = Form(formViewConstants.CONFIGURE_SECTION_FORM)

const EditSectionModal = (props) => {
  const {
    classes,
    handleSubmit,
    actions,
    initialValues,
    title,
    schema
  } = props

  return (
    <BasicModalOverlay
      title={translations('Configure Section', { name: title })}
      actions={actions}
    >
      <div className={classes.container}>
        <EditSectionForm onSubmit={handleSubmit} initialValues={initialValues}>
          <ContentBoxBody>
            <FormBody
              schema={schema}
              editing
              fullWidthFields
            />
          </ContentBoxBody>
        </EditSectionForm>
      </div>
    </BasicModalOverlay>
  )
}

EditSectionModal.propTypes = {
  classes: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  schema: PropTypes.object.isRequired
}

export default withStyles(style)(EditSectionModal)
