import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import classNames from 'classnames'

export const H3 = ({ value, className, classes }) => {
  return (
    <div className={classNames(classes.h3, className)}>
      {value}
    </div>
  )
}

H3.propTypes = {
  value: PropTypes.any
}

export default withStyles(style)(H3)
