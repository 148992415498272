import React, { useState, useRef, useEffect } from 'react'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { useTheme, withStyles, styled } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import { translations } from '../../../config'
import _ from 'lodash'

const SavedSearchMenuItem = styled('div')(({ theme }) => ({
  width: `calc(100% - 60px)`,
  textOverflow: 'ellipsis',
  textAlign: 'left',
  minHeight: '48px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  '& small': {
    color: `${theme.palette.grey[500]}`
  },
  '&:last-child': {
    borderBottom: '0px'
  }
}))

const MenuItemWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  display: 'flex',
  textOverflow: 'ellipsis',
  flexDirection: 'row',
  borderBottom: `1px solid ${theme.palette.grey[300]}`
}))

const MenuItemActions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%'
}))

const DropDownMenuButton = withStyles((theme) => ({
  root: {
    color: '#7B7B7B',
    borderRadius: 0,
    border: '1px solid white',
    borderLeft: '1px solid rgba(0, 0, 0, 0.25)',
    textTransform: 'none',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.25)'
    }
  }
}))(Button)

const SavedSearchMenuList = withStyles((theme) => ({
  root: {
    padding: '0px',
    maxHeight: '50vh',
    overflow: 'auto'
  }
}))(MenuList)

const SavedSearchGrow = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: '480px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80vw'
    }
  }
}))(Grow)

const CreateNewButton = withStyles((theme) => ({
  root: {
    width: '100%'
  }
}))(Button)

const SavedSearchMenu = ({ menuButtonTitle, menuElements, onMenuItemSelect, activeElementId, onSave, onDelete, onCreate, onDiscard }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const prevOpen = useRef(open)
  const theme = useTheme()
  const shouldShowTitle = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  const onMenuButtonClick = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const onMenuClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const onMenuItemClick = (payload) => {
    onMenuItemSelect(payload)
    setOpen(false)
  }

  const onMenuKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const onMenuCreate = () => {
    onCreate()
    setOpen(false)
  }

  const onMenuDiscard = () => {
    onDiscard()
    setOpen(false)
  }

  const onMenuDelete = (e, props) => {
    e.stopPropagation()
    onDelete(props)
    setOpen(false)
  }

  const onMenuSave = (e, props) => {
    e.stopPropagation()
    onSave(props)
    setOpen(false)
  }

  const getButton = (shouldShow) => {
    if (!onMenuItemSelect || !menuElements) {
      return
    }
    if (shouldShow) {
      return (
        <DropDownMenuButton
          ref={anchorRef}
          aria-controls={open ? 'saved-search-menu' : undefined}
          aria-haspopup='true'
          aria-label={menuButtonTitle}
          onClick={onMenuButtonClick}
          endIcon={<ExpandMoreIcon />}
        >
          {menuButtonTitle}
        </DropDownMenuButton>
      )
    }
    return (
      <IconButton
        ref={anchorRef}
        variant='outlined'
        color='secondary'
        aria-controls={open ? 'saved-search-menu' : undefined}
        aria-haspopup='true'
        aria-label={menuButtonTitle}
        onClick={onMenuButtonClick}
      >
        <ExpandMoreIcon />
      </IconButton>
    )
  }

  const getActiveStyle = (isActive) => {
    return (
      isActive
        ? {
          borderLeft: `4px solid ${theme.palette.primary.main}`,
          paddingLeft: '12px',
          background: theme.palette.grey[200]
        }
        : {}
    )
  }

  return (
    <>
      {getButton(shouldShowTitle)}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <SavedSearchGrow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onMenuClickAway}>
                <SavedSearchMenuList
                  autoFocusItem={open}
                  id='saved-search-menu'
                  onKeyDown={onMenuKeyDown}
                >
                  {
                    (onCreate || onDiscard)
                    && (
                      <MenuItemWrapper>
                        {
                          onCreate &&
                          (
                            <CreateNewButton
                              endIcon={<AddIcon />}
                              onClick={onMenuCreate}
                            >
                              {translations('Create New')}
                            </CreateNewButton>
                          )
                        }
                        {
                          onDiscard &&
                          (
                            <CreateNewButton
                              endIcon={<RemoveIcon />}
                              onClick={onMenuDiscard}
                            >
                              {translations('Discard Filter')}
                            </CreateNewButton>
                          )
                        }
                      </MenuItemWrapper>
                    )
                  }
                  {_.map(menuElements, (props) => {
                    const { id, name, description } = props
                    const isActive = activeElementId && id === activeElementId

                    return (
                      <MenuItemWrapper
                        onClick={() => { onMenuItemClick(props) }}
                        style={getActiveStyle(isActive)}
                      >
                        <SavedSearchMenuItem
                          key={id}
                        >
                          <Typography noWrap variant={'body1'}>{name}</Typography>
                          <small>{description}</small>
                        </SavedSearchMenuItem>
                        <MenuItemActions>
                          {
                            onSave && isActive
                            && (
                              <IconButton
                                size='small'
                                color='secondary'
                                onClick={(e) => onMenuSave(e, props)}
                              >
                                <SaveIcon />
                              </IconButton>
                            )
                          }
                          {
                            onDelete
                            && (
                              <IconButton
                                size='small'
                                onClick={(e) => onMenuDelete(e, props)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )
                          }
                        </MenuItemActions>
                      </MenuItemWrapper>
                    )
                  })}
                </SavedSearchMenuList>
              </ClickAwayListener>
            </Paper>
          </SavedSearchGrow>
        )}
      </Popper>
    </>
  )
}

export default SavedSearchMenu