import textContentStyle from '../../../components/Form/TextContent/style'
import formStyles from '../../../components/Form/style'

const styles = (theme) => ({
  contentContainer: {
    padding: 50,
    [theme.breakpoints.down('xs')]: {
      padding: 15
    }
  }
})

export default styles

export const formStyle = theme => ({
  ...formStyles,
  ...textContentStyle,
  registrationButton: styles.registrationButton
})
