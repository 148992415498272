import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'

export const getIsLoading = state => state.platformDetails.status === PENDING
export const getCurrentPlatformDetails = state => _.get(state, 'platformDetails.platform')
export const getPlatformDetailsForForm = state => {
  const platform = _.get(state, 'platformDetails.platform')
  const platformOrganisationsForDropwdown = _.get(platform, 'organisations', []).map((org) => org.id)
  return {
    id: platform.id,
    name: platform.name,
    domain: platform.domain,
    appId: platform.appId,
    themeId: platform.themeId,
    organisations: platformOrganisationsForDropwdown,
    config: platform.config,
    formSchemaFieldExclusions: platform.formSchemaFieldExclusions,
    ...platform.details,
    ...platform.permissions
  }
}

export const getCurrentPlatformOrganisations = state => _.get(state, 'platformDetails.platform.organisations')
export const getCurrentPlatformName = state => _.get(state, 'platformDetails.platform.name')
export const getCurrentPlatformId = state => _.get(state, 'platformDetails.platform.id')
export const getCurrentPlatformHasAppsEnabled = state => _.get(state, 'platformDetails.platform.permissions.enablePlatformApps', false)
