import { theme } from '../../../config'

const styles = {
  container: {
    height: '100%'
  },
  screenContainer: {
    paddingTop: theme.headerHeight,
    display: 'block'
  },
  environment: {
    position: 'fixed',
    padding: '10px 12px',
    bottom: 0,
    right: 0,
    background: '#fff',
    zIndex: 2000,
    opacity: 0.5
  },
  noPermissionMessage: {
    marginTop: '10%',
    textAlign: 'center'
  }
}

export default styles
