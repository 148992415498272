export default theme => {
  return {
    button: {
      padding: 0,
      border: 0,
      color: 'inherit',
      fontSize: 'inherit',
      cursor: 'pointer',
      background: 'transparent',
      outline: 'none',
      textAlign: 'inherit',
      fontWeight: 'inherit',
      '&:hover, &:active, &:focus': {
        textDecoration: 'underline'
      }
    }
  }
}
