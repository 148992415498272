import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
import withWidth from '@material-ui/core/withWidth'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import NewsSearchScreen from './NewsSearchScreen'

export default compose(
  withWidth(),
  connect(
    (state) => ({
      isConfigureVisible: authSelectors.getHasConfigureNews(state),
      hideBottomBar: !authSelectors.getHasCreateNewsPermissions(state),
      hasWatchListPermissions: platformSelectors.getHasWatchListPermissions(state),
      pageTitle: platformSelectors.getPageTitle(state)('News Title')
    })
  ),
  withState('newsType', 'setNewsType', 'default')
)(NewsSearchScreen)
