import { connect } from 'react-redux'
import { compose } from 'recompose'

import UsersSearchScreen from './UsersSearchScreen'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'

const mapStateToProps = (state, ownProps) => {
  const pageTitle = platformSelectors.getPageTitle(state)('Users Management Title')

  const getAddUserPath = () => {
    return '/users/add'
  }
  
  const addUserPath = getAddUserPath()
  const hasUserInvitesEnabled = platformSelectors.getHasOrganisationInvitesEnabled(state)

  return {
    hasUserInvitesEnabled,
    pageTitle,
    addUserPath
  }
}

const enhancer = compose(
  connect(mapStateToProps)
)

export default enhancer(UsersSearchScreen)
