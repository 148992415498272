import _ from 'lodash'
import { translation } from '../../services/translations'

export const initializer = () => {
    return {
        loading: false,
        results: [],
        noOptionsMessage: () => translation('Begin typing to start your search')
    }

}

export default (state, action) => {
    const { type, payload } = action || {}
    switch (type) {
        case 'INITIAL':
            return initializer()
        case 'LOADING':
            return {
                loading: true,
                results: state.results,
                noOptionsMessage: undefined
            }
        case 'FULFILLED':
            return {
                loading: false,
                results: _.map(payload.data.rows, (result) => ({ label: result.name, value: result.name })),
                noOptionsMessage: () => translation('No results found')
            }
        case 'REJECTED':
            return {
                loading: false,
                noOptionsMessage: payload.error.message,
                results: []
            }
        default:
            return { ...state }
    }
}