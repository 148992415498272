import React, { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import _ from 'lodash'
import PropTypes from 'prop-types'

import Skeleton from '@material-ui/lab/Skeleton'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import P from '../../../../../components/P'
import DataTable from '../../../../../components/DataTable'
import SubmissionReportsRow from '../../SubmissionReportsRow'
import ListViewLoadMore from '../../../../../components/ListViewLoadMore'

import { translations } from '../../../../../config'
import digitalStoreSdk from '../../../../../digitalStoreSdk'

import { styled } from '@material-ui/core/styles'

const Table = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  marginLeft: -theme.spacing(2),
  marginRight: -theme.spacing(2)
}))

const SkeletonContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  '& > span': {
    marginBottom: theme.spacing(2)
  }
}))

const ErrorMessage = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2)
}))

const SubmissionReportsModal = (props) => {
  const { dismiss, extReportId, organisationId, submissionId, receiverName, receiverId } = props
  const size = 50
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])

  const state = useAsync(() => digitalStoreSdk.submissions.fetchSubmissionReports({
    submissionId,
    organisationId,
    receiverId,
    page,
    size
  }), [page, size, organisationId, submissionId, receiverId])

  useEffect(() => {
    setData(prevData => {
      const results = _.get(state, 'value.results', [])
      return [...prevData, ...results]
    })
    setTotal(_.get(state, 'value.total', 0))
  }, [state.value])

  useEffect(() => {
    setCount(_.size(data))
  }, [data])

  const renderStates = () => {
    if (state.loading && _.isEmpty(data)) {
      return (
        <SkeletonContainer>
          <Skeleton variant={'rect'} height={30} />
          <Skeleton variant={'rect'} height={30} />
          <Skeleton variant={'rect'} height={30} />
          <Skeleton variant={'rect'} height={30} />
          <Skeleton variant={'rect'} height={30} />
        </SkeletonContainer>
      )
    }

    if (state.error) {
      return (
        <ErrorMessage>
          <P type={'error'} value={translations('Case Management - Case history error')} />
        </ErrorMessage>
      )
    }

    return (
      <>
      <Table>
        <DataTable
          headers={[
            { id: 'receiver', label: translations('Receiver'), options: { align: 'center' } },
            { id: 'receiverStatus', label: translations('Receiver Status'), options: { align: 'center' } },
            { id: 'validationStatus', label: translations('Validation Status'), options: { align: 'center' } },
            { id: 'createdAt', label: translations('Created'), options: { align: 'right' } },
            { id: 'updatedAt', label: translations('Updated'), options: { align: 'right' } }
          ]}
          data={data}
          tableId={'case-management-history-table'}
          tableHeading={'Submission History'}
          rowComponent={SubmissionReportsRow}
          primaryColumnWidth={'auto'}
          rowProps={{
            readOnly: true,
            hideSubmissionStatus: true
          }}
        />
      </Table>
      {(count < total) && <ListViewLoadMore onClick={() => setPage((prevPage) => prevPage + 1)} isLoading={state.loading} />}
    </>
    )
  }

  return (
    <BasicModalOverlay
      title={extReportId}
      actions={[
        {
          text: translations('Close'),
          onClick: dismiss
        }
      ]}
    >
      <P type={'large'} value={translations('Case Management - Case history sending to receiver', { receiverName })} />
      {renderStates()}
    </BasicModalOverlay>
  )
}

SubmissionReportsModal.props = {
  receiverName: 'None'
}

SubmissionReportsModal.propTypes = {
  dismiss: PropTypes.func,
  extReportId: PropTypes.string.isRequired,
  organisationId: PropTypes.string.isRequired,
  submissionId: PropTypes.string.isRequired,
  receiverId: PropTypes.string.isRequired,
  receiverName: PropTypes.string
}

export default SubmissionReportsModal
