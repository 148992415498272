import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { AutoTable } from '../../../../../components/Table'
import style from './style'

const FeedList = ({ rows }) => (
  <AutoTable rows={rows} />
)

export default withStyles(style)(FeedList)
