import fp from 'lodash/fp'
import * as enums from './enums'

class FileManagerReducers {
  setManagerStatus = status => (state, _action) => {
    return {
      ...state,
      status
    }
  }
  setManagerStatusToIdle = this.setManagerStatus(enums.fileManagerStatus.IDLE)
  setManagerStatusToInProgress = this.setManagerStatus(enums.fileManagerStatus.IN_PROGRESS)
  setManagerStatusToCancelled = this.setManagerStatus(enums.fileManagerStatus.CANCELLED)
  setManagerStatusToCompleted = this.setManagerStatus(enums.fileManagerStatus.COMPLETED)
  setUploadFileStatus = (state, action) => {
    return {
      ...state,
      fileProgress: {
        ...state.fileProgress,
        [action.payload.id]: {
          ...state.fileProgress[action.payload.id],
          progress: action.payload.progress,
        },
      },
    }
  }
  setUploadFile = (state, action) => {
    return {
      ...state,
      files: {
        ...state.files,
        ...modifyFiles(state.files, action.payload.files),
      },
    }
  }
  setIndividualFile = (status, options) => (state, action) => {
    const operationOptions = fp.defaults({ removeFile: false }, options)
    const { id, ...update } = action.payload
    const file = {
      ...state.files[id],
      ...update,
      status
    }

    if (operationOptions.removeFile === true) {
      file.file = null
    }

    return {
      ...state,
      files: {
        ...state.files,
        [id]: file

      }
    }
  }
  setIndividualFileStatusPending = this.setIndividualFile(enums.individualFileStatus.PENDING)
  setIndividualFileStatusSuccess = this.setIndividualFile(enums.individualFileStatus.SUCCESS, { removeFile: true })
  setIndividualFileStatusFailure = this.setIndividualFile(enums.individualFileStatus.FAILURE, { removeFile: true })

  setAttachmentStatus = (attachmentStatus) => (state, action) => {
    const { id, ...update } = action.payload
    const file = {
      ...state.files[id],
      ...update,
      attachmentStatus
    }

    return {
      ...state,
      files: {
        ...state.files,
        [id]: file

      }
    }
  }
  setAttachmentFileStatusPending = this.setAttachmentStatus(enums.individualFileStatus.PENDING)
  setAttachmentFileStatusSuccess = this.setAttachmentStatus(enums.individualFileStatus.SUCCESS)
  setAttachmentFileStatusFailure = this.setAttachmentStatus(enums.individualFileStatus.FAILURE)
}



export const modifyFiles = (existingFiles, files) => {
  const filePairs = fp.compose(
    fp.toPairs,
    fp.toArray,
  )(files)

  let fileToUpload = {}
  for (const [index, file] of filePairs) {
    const id = fp.size(existingFiles) + fp.toNumber(index) + 1
    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        status: enums.individualFileStatus.QUEUED,
        fileName: file.file.name,
        size: file.file.size,
        file: file.file,
        attachments: file.attachments
      },
    }
  }
  return fileToUpload
}


export default new FileManagerReducers()
