import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import SearchInput from '../../../../components/SearchInput'
import { translations } from '../../../../config'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import styles from './style'

import { useParams } from 'react-router-dom'
import { actions as reportsActions, hooks as reportsHooks } from '../../../../store/modules/reportConfiguration'

const ReportsSearchInput = ({ classes }) => {
  const { id: formViewId } = useParams()
  const dispatch = useDispatch()
  const filters = reportsHooks.useFilters(formViewId)
  const { query } = filters

  const handleSearchReports = useCallback(
    _.debounce(({ filters }) => {
      dispatch(reportsActions.searchReportsFresh({ formViewId, filters }))
    }, 300),
    [query]
  )

  const updateQuery = (e) => {
    const newQuery = e.target.value
    const newFilters = { ...filters, query: newQuery }

    dispatch(reportsActions.changeReportFilters({ formViewId, filters: newFilters }))
    handleSearchReports({ filters: newFilters })
  }
  const clearQuery = () => {
    const newQuery = ''
    const newFilters = { ...filters, query: newQuery }

    dispatch(reportsActions.changeReportFilters({ formViewId, filters: newFilters }))
    handleSearchReports({ filters: newFilters })
  }

  return (
    <div className={classes.searchContainer}>
      <SearchInput
        value={query}
        placeholder={translations('Report Management - Search Field Placeholder')}
        onChange={updateQuery}
        onClear={clearQuery}
        autoFocus={false}
      />
    </div>
  )
}

export default withStyles(styles)(ReportsSearchInput)
