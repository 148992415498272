import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { actions as communicationTemplateActions, selectors as communicationTemplateSelectors } from '../../../../store/modules/communicationTemplates'

import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { selectors as authSelectors } from '../../../../store/modules/auth'

import FullScreenLoader from '../../../../components/FullScreenLoader'
import CommunicationsPushScreen from './CommunicationsPushScreen'

const mapStateToProps = (state) => {
  return {
    pageTitle: platformSelectors.getPageTitle(state)('Communications - Page Title Push'),
    organisationId: authSelectors.getUserSelectedOrganisationId(state),
    isLoading: communicationTemplateSelectors.isLoadingAllTemplates(state),
    pushTemplateTypes: communicationTemplateSelectors.getCommunicationTemplatesTypes(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPushTemplates: () => dispatch(communicationTemplateActions.fetchPushTemplates()),
    fetchOrganisationPushTemplates: ({ organisationId }) => dispatch(communicationTemplateActions.fetchOrganisationPushTemplates({ organisationId }))
  }
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount () {
      this.props.fetchPushTemplates()
      this.props.fetchOrganisationPushTemplates({ organisationId: this.props.organisationId })
    }
  }),
  FullScreenLoader
)

export default enhance(CommunicationsPushScreen)
