import _ from 'lodash'
import { connect } from 'react-redux'
import qs from 'qs'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { getFormValues } from 'redux-form'

import NewOrganisationScreen from './NewOrganisationScreen'
import { selectors as organisationDetailsSelectors, actions as organisationDetails, constants as organisationDetailsConstants } from '../../../store/modules/organisationDetails'
import { selectors as receiversSelectors } from '../../../store/modules/receivers'
import { selectors as themesSelectors } from '../../../store/modules/themes'
import { selectors as meddraSelectors } from '../../../store/modules/meddra'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import FullScreenLoader from '../../../components/FullScreenLoader'
import * as orgSchema from '../formSchemas/organisationDetailsSchema'

export default compose(
  connect(state => {
    const isLoading = receiversSelectors.getIsLoading(state) ||
      themesSelectors.getIsLoadingAllThemesInitial(state) ||
      organisationDetailsSelectors.getIsLoading(state)

    return {
      isLoading,
      meddraVersionOptions: meddraSelectors.getVersionsAsOptions(state),
      pageTitle: platformSelectors.getPageTitle(state)('Create Organisation'),
      sourceOrganisationDetails: organisationDetailsSelectors.getCurrentOrganisationSelector(state),
      orgContactDetailsFormValues: getFormValues(organisationDetailsConstants.REPORTING_ORGANISATION_CONTACT_DETAILS_FORM_NAME)(state)
    }
  }),
  withHandlers({
    onSubmit: ({ dispatch, location }) => (fields) => {
      const { sourceOrganisationId } = qs.parse(_.get(location, 'search'), { ignoreQueryPrefix: true })

      if (sourceOrganisationId) {
        fields.sourceOrganisationId = sourceOrganisationId
      }
      fields.orgName = fields.name

      return dispatch(organisationDetails.createOrganisation({ fields }))
    }
  }),
  withPropsOnChange(
    ['meddraVersionOptions'],
    (props) => {
      const { meddraVersionOptions } = props
      const organisationConfigurationSchema = _.cloneDeep(orgSchema.organisationConfiguration.schema)
      const meddraVersionSchema = organisationConfigurationSchema.find(x => x.id === 'meddraVersion')
      meddraVersionSchema.props.options = meddraVersionOptions
      return { organisationConfigurationSchema }
    }
  ),
  withPropsOnChange(
    ['sourceOrganisationDetails'],
    (props) => {
      const { sourceOrganisationDetails } = props
      return {
        hasSourceOrganisationDetails: !_.isEmpty(sourceOrganisationDetails)
      }
    }
  ),
  withPropsOnChange(
    ['sourceOrganisationDetails'],
    (props) => {
      const { sourceOrganisationDetails } = props

      // key: Name of field within the form
      // value: Path to the data within sourceOrganisationDetails
      // For example: { senderfax: 'details.senderfax' }
      const initialValuesMap = {
        receiverId: 'receiverId'
      }

      const initialValues = _.reduce(initialValuesMap, (memo, path, key) => {
        const value = _.get(sourceOrganisationDetails, path)
        if (value) {
          memo[key] = value
        }
        return memo
      }, {})

      // Redux forms accepts the first value and an empty object would be accepted
      if (_.isEmpty(initialValues)) return null

      return { initialValues }
    }
  ),
  FullScreenLoader
)(NewOrganisationScreen)
