import React, { useCallback, useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import fp from 'lodash/fp'
import PropTypes from 'prop-types'
import { submit } from 'redux-form'
import { useDispatch } from 'react-redux'

import Form from '../../../../../components/Form'
import TabbedFormModal from '../../../../../components/TabbedFormModal'
import { constants as reportConfigurationConstants } from '../../../../../store/modules/reportConfiguration'

import { getSchema } from './schema'
import * as sectionUtils from './utils'
import { translations } from '../../../../../config'

const SectionForm = Form(reportConfigurationConstants.SECTION_FORM)

const SectionModal = (props) => {
  const { onSubmit, dismiss, fieldType, editing, initialValues } = props
  const [schemaSections, setSchemaSections] = useState({})
  const dispatch = useDispatch()

  const handleSubmit = useCallback((formData) => {
    const payload = fp.compose(
      sectionUtils.isForThromboticEvents,
      sectionUtils.isForMyocarditis,
      sectionUtils.withDefaults(editing)
    )(formData)

    onSubmit({ payload })
    dismiss()
  }, [onSubmit, dismiss])

  const actions = useMemo(() => {
    return [
      {
        success: true,
        text: translations('Confirm'),
        primary: true,
        onClick: () => {
          dispatch(submit(reportConfigurationConstants.SECTION_FORM))
        }
      },
      {
        text: translations('Cancel'),
        onClick: dismiss
      }
    ]
  }, [onSubmit, dismiss])

  useEffect(() => {
    const schemaSections = getSchema({ editing })
    setSchemaSections(schemaSections)
  }, [editing])

  const title = useMemo(() => {
    let translation
    if (editing === true) {
      translation = _.get(initialValues, 'name', 'Unknown')
    } else {
      translation = translations('Report Configuration - Modal - Add SECTION')
    }
    return translation
  }, [fieldType, editing, initialValues])

  return (
    <TabbedFormModal
      formComponent={SectionForm}
      handleSubmit={handleSubmit}
      actions={actions}
      schemaSections={schemaSections}
      title={title}
      initialValues={initialValues}
      tabsLabel={editing ? translations('Edit section tabs') : translations('Create section tabs')}
    />
  )
}

SectionModal.defaultProps = {
  editing: false,
  initialValues: {}
}

SectionModal.propTypes = {
  /** Modal actions */
  actions: PropTypes.array.isRequired,
  /** Callback when form is submitted */
  onSubmit: PropTypes.func,
  /** Closes the modal */
  dismiss: PropTypes.func,
  /** Modal is editing not creating a field */
  editing: PropTypes.bool,
  /** Data to pre-populate form */
  initialValues: PropTypes.object
}

export default SectionModal
