import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers } from 'recompose'
import ReportAcknowledgementsFilters from './ReportAcknowledgementsFilters'
import { actions as reportAcknowledgementsActions, selectors as reportAcknowledgementsSelectors } from '../../../../store/modules/reportAcknowledgements'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { translations } from '../../../../config'
import modalService from '../../../../services/modalService'

const mapStateToProps = state => {
  const results = reportAcknowledgementsSelectors.getResults(state)
  return {
    results
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    downloadAcks: ({ dispatch, filters, results }) => () => {
      if (results.length && results.length > 0) {
        const ids = _.map(results, res => res.id)
        dispatch(reportAcknowledgementsActions.fetchAckXMLForIds({ ids }))
      } else {
        modalService.action({
          title: translations('No acknowledgements'),
          text: translations('There are no acknowledgements to export'),
          actions: [
            {
              text: translations('Continue')
            }
          ]
        })
      }
    }
  })
)(ReportAcknowledgementsFilters)
