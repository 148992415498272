import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_DRUG:
      return reducers.fetchDrug(state, action)
    case constants.CHANGE_META_DISTRIBUTION:
      return reducers.changeMetaDistribution(state, action)
    default:
      return state
  }
}
