const styles = theme => ({
  checkbox: {
    marginLeft: -6,
    marginBottom: theme.spacing(1)
  },
  container: {
    width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(1)
  },
  field: {
    width: '100%',
    marginTop: theme.spacing(1)
  }
})

export default styles