import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  organisation: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_ORGANISATION_BY_ID:
      return reducers.fetchOrganisationById(state, action)
    case constants.FETCH_ORGANISATION_RECEIVERS:
      return reducers.fetchOrganisationReceivers(state, action)
    case constants.UPDATE_ORGANISATION:
      return reducers.updateOrganisation(state, action)
    case constants.CREATE_ORGANISATION:
      return reducers.createOrganisation(state, action)
    case constants.CREATE_DRUGS_LISTS:
      return reducers.createDrugsLists(state, action)
    case constants.IMPORT_DRUG_META:
      return reducers.importDrugMeta(state, action)
    case constants.SET_PROFESSIONS_FOR_ORGANISATION:
      return reducers.setProfessionsForOrganisation(state, action)
    case constants.SET_PROFESSION_GROUPS_FOR_ORGANISATION:
      return reducers.setProfessionGroupsForOrganisation(state, action)
    case constants.SET_LOCATIONS_FOR_ORGANISATION:
      return reducers.setLocationsForOrganisation(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
