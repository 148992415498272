import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  error: undefined,
  status: undefined,
  currentTheme: {
    id: '39d9d9a9-87aa-47b6-a242-4f9609af0859',
    name: 'Offline',
    domain: '',
    details: {
      primaryColor: '#999999',
      headerBackgroundColor: '#000000',
      headerContrastColor: '#FFFFFF',
      primaryContrastColor: '#FFFFFF'
    }
  },
  allThemes: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.FETCH_CURRENT_THEME:
      return reducers.fetchCurrentTheme(state, action)
    case constants.FETCH_ALL_THEMES:
      return reducers.fetchAllThemes(state, action)
    default:
      return state
  }
}
