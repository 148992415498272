export default [
  {
    'label': 'Ampoule',
    'value': 'C27'
  },
  {
    'label': 'Becquerel(s)',
    'value': '33'
  },
  {
    'label': 'Capsule',
    'value': 'C28'
  },
  {
    'label': 'Centigram',
    'value': '11'
  },
  {
    'label': 'Curies',
    'value': '19'
  },
  {
    'label': 'Dosage forms',
    'value': '20'
  },
  {
    'label': 'Dram',
    'value': '15'
  },
  {
    'label': 'Drop(s)',
    'value': '41'
  },
  {
    'label': 'Gigabecquerel(s)',
    'value': '34'
  },
  {
    'label': 'Grain',
    'value': '14'
  },
  {
    'label': 'Gram',
    'value': '01'
  },
  {
    'label': 'IU (International Unit)',
    'value': '05'
  },
  {
    'label': 'International units millions',
    'value': '22'
  },
  {
    'label': 'International units thousands',
    'value': '21'
  },
  {
    'label': 'Iu/kilogram',
    'value': '39'
  },
  {
    'label': 'Kilobecquerel(s)',
    'value': '36'
  },
  {
    'label': 'Kilogram',
    'value': '12'
  },
  {
    'label': 'Liters',
    'value': '17'
  },
  {
    'label': 'Megabecquerel(s)',
    'value': '35'
  },
  {
    'label': 'Microcurie(s)',
    'value': '37'
  },
  {
    'label': 'Microgram',
    'value': '03'
  },
  {
    'label': 'Microgram(s)/kilogram',
    'value': '28'
  },
  {
    'label': 'Microgram(s)/sq.meter',
    'value': '30'
  },
  {
    'label': 'Microlitre(s)',
    'value': '32'
  },
  {
    'label': 'Micromol',
    'value': '26'
  },
  {
    'label': 'Millicuries',
    'value': '18'
  },
  {
    'label': 'Milliequivalents',
    'value': '23'
  },
  {
    'label': 'Milligram',
    'value': '02'
  },
  {
    'label': 'Milligram(s)/kilogram',
    'value': '27'
  },
  {
    'label': 'Milligram(s)/sq.meter',
    'value': '29'
  },
  {
    'label': 'Milligram/Milliliters',
    'value': '06'
  },
  {
    'label': 'Millilitre(s)',
    'value': '31'
  },
  {
    'label': 'Millimol',
    'value': '25'
  },
  {
    'label': 'Mol',
    'value': '24'
  },
  {
    'label': 'Nanocurie(s)',
    'value': '38'
  },
  {
    'label': 'Nanogram',
    'value': '04'
  },
  {
    'label': 'OTHER',
    'value': 'C30'
  },
  {
    'label': 'Ounce',
    'value': '16'
  },
  {
    'label': 'PUFF',
    'value': 'C31'
  },
  {
    'label': 'Percent',
    'value': '40'
  },
  {
    'label': 'Picogram',
    'value': '10'
  },
  {
    'label': 'Pound',
    'value': '13'
  },
  {
    'label': 'TABLET',
    'value': 'C32'
  },
  {
    'label': 'UNKNOWN',
    'value': 'C33'
  },
  {
    'label': 'Unit',
    'value': 'C29'
  }
]
