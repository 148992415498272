import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import { translations, passwordRequirements } from '../../../config'
import P from '../../../components/P'
import VisuallyHidden from '../../../components/VisuallyHidden'
import style from './style'
import MigrationForm from '../MigrationForm'
import MainLogo from '../../../components/MainLogo'

import List from '../../../components/Form/FormSections/List'

const MigrationScreen = ({ classes, pageTitle }) => (
  <div className={classes.container}>
    <div className={classes.formContainer}>
      <MainLogo />
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <div className={classes.content}>
        <P style={{ marginBottom: 15 }} value={translations('User migration - Intro')} />
        <P style={{ marginBottom: 15 }} value={translations('User migration - To continue')} />
      </div>
      <div className={classes.content}>
        <List listTitle={translations('Password Requirements Title')} items={passwordRequirements} />
      </div>
      <div className={classes.content}>
        <MigrationForm />
      </div>
    </div>
  </div>
)

MigrationScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(style)(MigrationScreen)
