import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import InfiniteScroll from 'react-infinite-scroller'
import _ from 'lodash'
import fp from 'lodash/fp'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { Table, TableHeader, TableRow, TableCell } from '../../../../components/Table'
import { translations } from '../../../../config'
import styles from './style'
import Heading from '../../../../components/Heading'
import KeyValueCard from '../../../../components/KeyValueCard'
import Can from '../../../../components/Can'

const formatTags = fp.compose(
  (tagNames) => {
    if (tagNames.length >= 2) {
      return `${tagNames[0]} (+${tagNames.length - 1} more)`
    }
    return fp.join(', ', tagNames)
  },
  fp.map('name')
)

class NewsSearchTable extends Component {
  formatDate = (date) => {
    return moment(date)
      .local()
      .format('DD/MM/YYYY HH:mm')
  }
  renderError = () => {
    const { classes } = this.props
    return <div className={classes.content}>Sorry, an error has occurred. Please try again.</div>
  }

  renderTableRows = () => {
    const { results, onArticleClick, onDeleteClick, onEditClick } = this.props
    return _.chain(results)
      .map(article => {
        const { id, title, publishedDate, newsFeed, tags } = article
        const onClick = () => onArticleClick(article)
        return (
          <TableRow key={id} onClick={onClick}>
            <TableCell xs={3} sm={3} md={3} lg={3} onClick={onClick}>{title}</TableCell>
            <TableCell xs={2} sm={2} md={2} lg={2}>{newsFeed.source}</TableCell>
            <TableCell xs={2} sm={2} md={2} lg={2}>{this.formatDate(publishedDate)}</TableCell>
            <TableCell xs={2} sm={2} md={2} lg={2}>{formatTags(tags)}</TableCell>
            <Can actions='updateNewsArticle'>
              <TableCell xs={1} sm={1} md={1} lg={1}>
                <IconButton aria-label='Edit' onClick={(e) => onEditClick(e, article.id)}>
                  <EditIcon />
                </IconButton>
              </TableCell>
            </Can>
            <Can actions='deleteNews'>
              <TableCell xs={1} sm={1} md={1} lg={1}>
                <IconButton aria-label='Delete' onClick={(e) => onDeleteClick(e, article.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </Can>
          </TableRow>
        )
      })
      .value()
  }

  render() {
    const { error, noResults, loadMore, hasMore, results, classes, inModal, fullHeight, onDeleteClick, onArticleClick, onEditClick } = this.props
    if (error && noResults) {
      return this.renderError()
    } else {
      const gridContainerClass = classNames(classes.gridContainer, {
        [classes.inModal]: inModal,
        [classes.gridContainerFullHeight]: fullHeight
      })

      return (
        <div className={gridContainerClass}>
          <InfiniteScroll
            pageStart={0}
            className={classes.container}
            loadMore={loadMore}
            hasMore={hasMore}
            initialLoad={false}
            useWindow={false}
          >
            {
              results &&
              <Fragment>
                <Hidden xsDown>
                  <Table
                    header={(
                      <TableHeader>
                        <TableCell xs={3} sm={3} md={3} lg={3} heading>{translations('News Article')}</TableCell>
                        <TableCell xs={2} sm={2} md={2} lg={2} heading>{translations('Source')}</TableCell>
                        <TableCell xs={2} sm={2} md={2} lg={2} heading>{translations('Published Date')}</TableCell>
                        <TableCell xs={2} sm={2} md={2} lg={2} heading>{translations('Tags')}</TableCell>
                        <Can actions='updateNewsArticle'>
                          <TableCell xs={1} sm={1} md={1} lg={1} heading />
                        </Can>
                        <Can actions='deleteNews'>
                          <TableCell xs={1} sm={1} md={1} lg={1} heading />
                        </Can>
                      </TableHeader>
                    )}
                    rows={this.renderTableRows()}
                  />
                </Hidden>
                <Hidden smUp>
                  {results.map((item, key) => {
                    const data = [
                      [translations('News Article'), item.title, { xs: 12, cardDatumWidth: '100%', onClick: () => onArticleClick(item) }],
                      [translations('Source'), item.newsFeed && item.newsFeed.source, { xs: 12, cardDatumWidth: '100%' }],
                      [translations('Published Date'), this.formatDate(item.publishedDate), { xs: 12, cardDatumWidth: '100%' }],
                      [translations('Tags'), formatTags(item.tags), { xs: 12, cardDatumWidth: '100%' }]
                    ]
                    return (
                      <KeyValueCard
                        key={key}
                        className={classes.card}
                        onClick={() => onArticleClick(item)}
                        data={data}
                        actions={[{
                          label: 'Edit',
                          onClick: (e) => onEditClick(e, item.id),
                          icon: <EditIcon />,
                          permissions: 'updateNewsArticle'

                        },
                        {
                          label: 'Delete',
                          onClick: (e) => onDeleteClick(e, item.id),
                          icon: <DeleteIcon />,
                          permissions: 'deleteNews'
                        }]}
                      />
                    )
                  })}
                </Hidden>
              </Fragment>
            }
            {noResults &&
              <div className={classes.noResults} role='status'>
                <Heading component='h2' variant='h3'>{translations('No results found')}</Heading>
              </div>
            }
          </InfiniteScroll>
        </div>
      )
    }
  }
}

NewsSearchTable.defaultProps = {
  fullHeight: false
}

export default withStyles(styles)(NewsSearchTable)
