import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { useQuery } from '../../../hooks'
import { translations } from '../../../config'
import { FullScreenLoader } from '../../../components/FullScreenLoader'
import ErrorScreen from '../../../components/ErrorScreen'
import SuccessScreen from '../../../components/SuccessScreen'

import { actions } from '../../../store/modules/ota'
import { verifyOta } from '../../../store/modules/ota/actions'
import {
  getVerifyOtaStatus,
  getVerifyOtaData,
  getVerifyOtaErrorCode,
  getVerifyOtaErrorRetryable,
  getVerifyOtaErrorMessage,
  getVerifyOtaRetry
} from '../../../store/modules/ota/selectors'

const REDIRECT_DELAY = 2000

const OtaActionType = {
  REDIRECT: 'REDIRECT',
  REDIRECT_WITH_STATE: 'REDIRECT_WITH_STATE',
  SHOW_MESSAGE: 'SHOW_MESSAGE'
}

const VerifyOtaScreen = () => {
  const query = useQuery()
  const dispatch = useDispatch()

  const otaStatus = useSelector(getVerifyOtaStatus)
  const otaData = useSelector(getVerifyOtaData)
  const otaErrorCode = useSelector(getVerifyOtaErrorCode)
  const otaErrorMessage = useSelector(getVerifyOtaErrorMessage)
  const otaErrorIsRetryable = useSelector(getVerifyOtaErrorRetryable)
  const otaRetry = useSelector(getVerifyOtaRetry)

  const otaError = otaErrorCode || otaErrorMessage
  const hasResult = !!otaData || !!otaError
  const isLoading = otaStatus === 'PENDING'

  const onRetry = useCallback(() => {
    dispatch(actions.resetStore({ retry: true }))
  }, [dispatch])

  useEffect(() => {
    const { otaId } = query

    if (otaId && !isLoading && !hasResult) {
      dispatch(verifyOta({ otaId, retry: otaRetry }))
    }

    if (!otaId) {
      dispatch(push('/login'))
    }
  }, [dispatch, query, hasResult, isLoading, otaRetry])

  useEffect(() => {
    if (otaData && otaData.type) {
      let timeout

      switch (otaData.type) {
        case OtaActionType.REDIRECT:
          timeout = setTimeout(() => {
            window.location.replace(otaData.redirect)
          }, REDIRECT_DELAY)
          break

        case OtaActionType.REDIRECT_WITH_STATE:
          timeout = setTimeout(() => {
            dispatch(push(otaData.redirect, otaData.state))
          }, REDIRECT_DELAY)
          break

        default:
          break
      }

      return () => clearTimeout(timeout)
    }
  }, [dispatch, otaData])

  if (isLoading) {
    return <FullScreenLoader />
  }

  return (
    <>
      {otaData && (
        <SuccessScreen message={translations(otaData.message || 'OTA Success Redirection Message', otaData.meta)} />
      )}
      {otaError && (
        <ErrorScreen
          title={translations(otaError)}
          {...(otaErrorIsRetryable && {
            onClick: onRetry,
            buttonText: translations('OTA Retry Button Text')
          })} />
      )}
    </>
  )
}

export default VerifyOtaScreen
