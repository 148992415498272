"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateSafetyReportId = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var randomStringWithoutVowelsAndX = function randomStringWithoutVowelsAndX(length) {
  var values = ['B', 'C', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'Y', 'Z'];
  return _lodash["default"].sampleSize(values, length).join('');
};

var generateSafetyReportId = function generateSafetyReportId(_ref) {
  var sendercountrycode = _ref.sendercountrycode,
      senderevid = _ref.senderevid,
      _ref$timestamp = _ref.timestamp,
      timestamp = _ref$timestamp === void 0 ? undefined : _ref$timestamp;
  return "".concat(sendercountrycode, "-").concat(senderevid, "-").concat((0, _moment["default"])(timestamp).format('YYYYMMDDHHmmssSSSS'), "-").concat(randomStringWithoutVowelsAndX(5));
};

exports.generateSafetyReportId = generateSafetyReportId;