import React, { Fragment } from 'react'
import Editable from '../Editable'
import { IconCheckbox } from '../../Fields'
import P from '../../P'
import { translations } from '../../../config'

export default Editable({
  input: (props) => (
    <IconCheckbox {...props} />
  ),
  display: ({ label, value }) => {
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={value === true ? translations('Yes') : translations('No')} />
      </div>
    )
  }
})
