export const SLICE_NAME = 'websiteManagement'

export const FETCH_WEBSITE = `${SLICE_NAME}/fetchWebsite`
export const FETCH_WEBSITES = `${SLICE_NAME}/fetchWebsites`
export const CREATE_WEBSITE = `${SLICE_NAME}/createWebsite`

export const THEME_FORM_NAME = `${SLICE_NAME}/THEME_FORM_NAME`
export const CONTENT_FORM_NAME = `${SLICE_NAME}/WEBSITE_CONTENT_FORM`
export const SETTINGS_FORM_NAME = `${SLICE_NAME}/WEBSITE_SETTINGS_FORM`
export const GENERAL_SETTINGS_FORM_NAME = `${SLICE_NAME}/GENERAL_SETTINGS_FORM`
export const PAGE_FORM_NAME = `${SLICE_NAME}/PAGE_FORM`