import React, { useCallback, useMemo } from 'react'
import _ from 'lodash'

import { selectors as formViewSelectors } from '../../../../../store/modules/formViews'
import { hooks as communicationTemplateHooks } from '../../../../../store/modules/communicationTemplates'
import { hooks as reportConfigurationHooks } from '../../../../../store/modules/reportConfiguration'

import { useSidebar } from '../../../../../contexts'
import Form, { FormBody } from '../../../../../components/Form'
import { getSchema } from './schema'

const {
  categoryOptions,
  observeStudyTypeOptions,
  reportTypeOptions,
  subCategoryOptions
} = formViewSelectors

const GeneralForm = Form('ReportConfiguration/General')

const GeneralSection = ({ formViewId, isFollowUp }) => {
  const isNewFormView = formViewId === 'new'
  const { dispatch, actions, state: { general } } = useSidebar()
  const emailTemplateOptions = communicationTemplateHooks.useOrganisationEmailTemplates()
  const editor = reportConfigurationHooks.useFormViewEditing()
  const audiences = isNewFormView
    ? reportConfigurationHooks.useEditorAudiencesForDropdown(editor.template)
    : reportConfigurationHooks.useAudiencesForDropdown(formViewId)

  const schema = useMemo(() => {
    return getSchema({ emailTemplateOptions, audiences, categoryOptions, reportTypeOptions, observeStudyTypeOptions, subCategoryOptions, isFollowUp })
  }, [formViewId, emailTemplateOptions, isFollowUp])
  const handleUpdate = useCallback((updated) => {
    dispatch(actions.updateGeneralDetails(updated))
  }, [general])

  return (
    <GeneralForm
      editing
      onChange={handleUpdate}
      initialValues={general}
    >
      <FormBody
        schema={schema}
        editing
        fullWidthFields
      />
    </GeneralForm>
  )
}

export default GeneralSection