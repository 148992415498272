import _ from 'lodash'
import fp from 'lodash/fp'
import { PENDING, FAIL, SUCCESS } from '../../middleware/redux-promise'
import { formSchemas, initialFieldMap } from '../../../config'
// Access authCombinedSelectors directly to avoid possible circular dependencies with other combined selectors.
import * as authCombinedSelectors from '../../../store/modules/combinedSelectors/authCombinedSelectors'
import { createSelector } from 'reselect'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'

import getFormSchemaLabel from '../../../helpers/getFormSchemaLabel'

const formSchemaLibrary = new FormSchemaLibrary()

export const getHasFailed = state => _.get(state.selectedReceiver, 'status') === FAIL
export const getHasSucceeded = state => _.get(state.selectedReceiver, 'status') === SUCCESS
export const getIsLoading = state => _.get(state.selectedReceiver, 'status') === PENDING
export const getOrganisations = state => _.get(state.organisations, 'results')
export const getOrganisationDetails = state => _.get(state, 'organisationDetails')

//organisations can now have multiple receivers
export const getCurrentOrganisationReceivers = state => _.get(state, 'organisationDetails.organisation.receivers', [])

export const getCurrentOrganisationReceiversFormSchemas = (flatten = false) => createSelector([
  getCurrentOrganisationReceivers
], (receivers) => {
  const formSchemas = fp.compose(
    (schemas) => flatten ? fp.flatten(schemas) : schemas,
    fp.uniqBy('id'),
    fp.map('formSchema')
  )(receivers)

  return formSchemas
})

export const getAllSelectedReceiverReportSchemas = createSelector([
  getCurrentOrganisationReceiversFormSchemas(false)
], (schemas) => {
  schemas = _.map(schemas, (element) => {
      const reportName = _.get(element, 'name')
      if (reportName) {
        element['publicName'] = getFormSchemaLabel(reportName)
      }
      return element
  })
  return schemas
})

export const getSelectedReceiverReportSchema = state => {
  const getOrgSchema = authCombinedSelectors.getDetailsForSelectedOrganisation(state)
  const orgRevision = _.get(getOrgSchema, 'revision')
  const formSchemas = _.get(state, 'selectedReceiver.receiver.formSchemas')
  const revisionSchemas = _.filter(formSchemas, 'details.revision')
  let selectedReceiverReportSchema
  if (_.size(revisionSchemas)) {
    selectedReceiverReportSchema = _.find(revisionSchemas, (item) => {
      const revision = _.get(item, 'details.revision')
      return item.type === 'report' && parseInt(revision) === parseInt(orgRevision)
    }) || {}
    const reportName = _.get(selectedReceiverReportSchema, 'name')
    selectedReceiverReportSchema['publicName'] = getFormSchemaLabel(reportName)
  } else {
    selectedReceiverReportSchema = _.filter(formSchemas, { type: 'report' })
    selectedReceiverReportSchema = _.map(selectedReceiverReportSchema, schema => {
      const reportName = _.get(schema, 'name')
      schema['publicName'] = getFormSchemaLabel(reportName)
      return schema
    })
  }
  return selectedReceiverReportSchema
}

export const getSelectedReceiverAckSchema = createSelector([
  getCurrentOrganisationReceiversFormSchemas(true)
], (formSchemas) => {
  return _.find(formSchemas, { type: 'acknowledgement' }) || {}
})

const getSelectedReceiverReportSchemaProperty = (field) => createSelector([
  getSelectedReceiverReportSchema
], (schema) => {
  if (!_.isArray(schema)) {
    return _.get(schema, field, null)
  }
  return fp.compose(
    fp.getOr(null, field),
    fp.find({ standard: 'Standard' })
  )(schema)
})

export const getSelectedReceiverReportSchemaName = getSelectedReceiverReportSchemaProperty('name')

export const getSelectedReceiverAckSchemaName = createSelector([
  getSelectedReceiverAckSchema
], (schema) => {
  return schema.name
})

export const getReportSchema = createSelector([
  getSelectedReceiverReportSchemaName
], (schemaName) => {
  if (!schemaName) {
    return {}
  }
  return formSchemaLibrary.processSchema(schemaName)
})

export const getReportSchemaInitialFieldMap = createSelector([
  getSelectedReceiverReportSchemaName
], (schemaName) => {
  return _.get(initialFieldMap, schemaName)
})

const _formatFields = fields => {
  let translations = {}

  const _formatField = field => {
    if (field.title) {
      translations[field.title] = field.title
    }
    if (field.schema) {
      _.forEach(field.schema, _formatField)
    }
    if (field.props && field.props.label) {
      translations[field.props.label] = field.props.label
    }
    if (field.props && _.isArray(field.props.options)) {
      _.forEach(field.props.options, option => {
        translations[option.label] = option.label
      })
    }
    if (field.props && field.props.schema) {
      _.forEach(field.props.schema, _formatField)
    }
  }

  _.forEach(fields, _formatField)

  return translations
}

export const getReportSchemaTranslations = createSelector([
  getAllSelectedReceiverReportSchemas
], (reportSchemas) => {
  const schemaNames = _.flatMap(reportSchemas, 'name')
  const schemaTranslations = _.reduce(schemaNames, (memo, schemaName) => {
    let translations = {}
    try {
      const schemaFields = formSchemaLibrary.getAllFields(schemaName)
      translations = _formatFields(schemaFields)
    } catch (error) {
      console.log(error)
    }
    return _.merge(memo, translations)
  }, {})

  return schemaTranslations
})

export const getAckFormSchemaName = createSelector(
  [getOrganisationDetails],
  (organisationDetails) => {
    return _.get(organisationDetails, 'organisation.ackFormSchemaName')
  }
)

export const getAckSchema = createSelector([
  getAckFormSchemaName
], (schemaName) => {
  const legacySchemaName = schemaName === 'mhraR3Ack' ? 'mhraR2Ack' : schemaName
  return _.get(formSchemas, legacySchemaName)
})

export const getAckSchemaTranslations = createSelector([
  getAckSchema
], (ackSchema) => {
  return _formatFields(ackSchema)
})

export const getIsReportSchemaAvailable = createSelector([
  getReportSchema
], schema => {
  return !!schema
})

export const getIsAckSchemaAvailable = createSelector([
  getAckSchema
], schema => {
  return !!schema
})