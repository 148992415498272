import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

const StyledField = styled.div`
  border: 0;
  padding: 0;
  margin: 0;
  padding-top: ${props => `${props.theme.form.spacing.small}px`};
  padding-bottom: ${props => `${props.theme.form.spacing.small}px`};
  ${media.greaterThan('small')`    
    padding-top: ${props => `${props.theme.form.spacing.large}px`};
    padding-bottom: ${props => `${props.theme.form.spacing.large}px`};
  `}
  &:focus {
    outline: none;
  }
`;

const FieldContent = styled.div`
  border-left-style: solid;
  border-left-color: ${({ error, theme }) => error ? `${theme.colors.error}` : ''};
  border-left-width: ${props => props.error ? `4px ` : '0'};
  padding-left: ${props => `${props.theme.form.spacing.small}px`};
  padding-right: ${props => `${props.theme.form.spacing.small}px`};
  ${media.greaterThan('small')`
    border-left-width: ${props => props.error ? `8px ` : '0'};
    padding-left: ${props => `${props.theme.form.spacing.large}px`};
    padding-right: ${props => `${props.theme.form.spacing.large}px`};
  `}
`;

/**
 * Form Field
 * Generic wrapper to control spacing between other fields
 */
const Field = (props) => {
  const { children, error, as, name, tabIndex } = props
  return (
    <StyledField as={as} data-name={name} tabIndex={tabIndex}>
      <FieldContent error={error}>
        {children}
      </FieldContent>
    </StyledField>
  )
}

Field.propTypes = {
  /** The component to wrap in a generic field */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]).isRequired,
  /** Field has failed validation */
  error: PropTypes.string,
  /** HTML element of the wrapper element */
  as: PropTypes.string,
  /** Used to associate label and input */
  name: PropTypes.string,
  /** Indicates that its element can be focused, and where it participates in sequential keyboard navigation */
  tabIndex: PropTypes.string
}

Field.defaultProps = {
  tabIndex: ''
}

export default Field
