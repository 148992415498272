import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'
import _ from 'lodash'

export const getIsLoading = state => _.get(state, 'locations.status') === PENDING
export const getError = state => _.get(state, 'locations.error')
export const getIsInitial = state => !state.locations.status
export const getStatus = state => state.locations.status
export const getTotal = state => _.get(state, 'locations.count')
export const getResults = state => _.get(state, 'locations.rows')
export const getHasResults = state => _.get(state, 'locations.rows') && _.get(state, 'locations.rows').length

export const getHasMore = createSelector([
  getTotal,
  getResults
], (total, results) => {
  return total && results.length < total
})

export const getOrganisationRegions = state => {
  const regions = _.get(state, 'locations.organisationLocations.regions')
  return _.map(regions, (region) => ({ value: region.locationId, label: region.name }))
}
export const getOrganisationDistricts = state => {
  const districts = _.get(state, 'locations.organisationLocations.districts')
  return _.map(districts, (district) => ({ value: district.locationId, label: district.name }))
}

export const getLocationProviders = state => state.locations.providers
export const getLocationProvidersAsOptions = createSelector([
  getLocationProviders
], (locationProviders = []) => {
  return locationProviders.map(provider => ({
    label: provider.name,
    value: provider.id
  }))
})

export const getNextSyncDate = state => state.locations.nextSyncDate