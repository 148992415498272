import { colors } from '../../config/theme'

const style = theme => ({
  container: {
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
    height: '80px',
    margin: '10px 0',
    [theme.breakpoints.up('sm')]: {
      padding: '0 20px',
      height: '100px'
    }
  },
  resourceKey: {
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  typeText: {
    marginTop: 5
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '55%',
    minWidth: 0,
    flexShrink: 1
  },
  icon: {
    minHeight: 35,
    width: 'auto',
    maxWidth: 25,
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      minHeight: 50,
      width: 'auto',
      maxWidth: 35,
      marginRight: 25
    }
  },
  button: {
    width: 80,
    [theme.breakpoints.up('sm')]: {
      width: 120
    }
  },
  textContainer: {
    width: '100%',
    minWidth: 0,
    flexShrink: 1
  },
  deleteIcon: {
    height: 24,
    width: 'auto'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
})

export default style
