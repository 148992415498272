import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import P from '../../../components/P'
import VisuallyHidden from '../../../components/VisuallyHidden'
import style from './style'
import SelectOrganisationForm from '../SelectOrganisationForm'
import MainLogo from '../../../components/MainLogo'

const SelectOrganisationScreen = ({ classes, pageTitle }) => (
  <div className={classes.container}>
    <div className={classes.formContainer}>
      <MainLogo />
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <div className={classes.forgotLinkWrapper}>
        <P value={translations('Please select your organisation')} />
        <SelectOrganisationForm />
      </div>
    </div>
  </div>
)

SelectOrganisationScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired
}

export default withStyles(style)(SelectOrganisationScreen)
