import _ from 'lodash'
import * as constants from './constants'
import { promiseReducer } from '../../util'

class FilesReducers {
  createFile = promiseReducer(_.identity)

  updateFile = promiseReducer(_.identity)

  deleteFile = promiseReducer(_.identity)

  fetchFiles = promiseReducer(
    (state, action) => ({
      ...state,
      files: action.result
    })
  )
}

export default new FilesReducers()
