import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import Section, { SectionTypes } from '../Section'
import { ActionButton } from '../ReportSummaryModal'
import { translations } from '../../../../../config'
import { selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import { useAbilities } from '../../../../../ability/ability-context'
import { selectors as platformSelectors } from '../../../../../store/modules/platforms'


const AuditActions = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection
  } = props

  const dispatch = useDispatch()

  const reportId = useSelector(reportDetailsSelectors.getReportId)
  const platformHasAuditEnabled = useSelector(platformSelectors.getHasReportAuditingEnabled)

  const ability = useAbilities()
  const canUseAuditOptionsForReport = platformHasAuditEnabled && ability.can('view', 'reportAudit')

  if (!canUseAuditOptionsForReport) {
    return <></>
  }

  const showAuditHistoryForReport = () => {
    dispatch(push(`/report-management/${reportId}/audit`))
  }

  const updateReportButtonLegacy =
    <ActionButton onClick={showAuditHistoryForReport}>{translations('Show Amend History')}</ActionButton>

  const rows = [
    { type: SectionTypes.ACTION, value: canUseAuditOptionsForReport ? updateReportButtonLegacy : null },
  ]

  return (
    <Section
      name='Amend Actions'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
    />
  )
}

export default AuditActions