import { visibility } from '../../../config/theme'

const styles = theme => {
  const { hideForExtraSmall, hideForSmall } = visibility(theme)
  return {
    hideForExtraSmall,
    hideForSmall,
    cell: {
      display: 'flex'
    },
    truncate: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      justifyContent: 'left'
    },
    alignCenter: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    },
    truncatePadding: {
      padding: 10
    }
  }
}

export default theme => styles(theme)
