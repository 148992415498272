import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormHelperText } from '@material-ui/core'
import { Input, InputLabel } from '@material-ui/core'

const InputField = ({
  value,
  label,
  onChange,
  onBlur,
  passedProps,
  meta: { error, touched } = {},
  shrink,
  autoFocus,
  getRef,
  placeholder,
  ...props
}) => {
  return (
    <Input
      inputRef={getRef}
      id={label}
      error={!!(touched && error)}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      placeholder={placeholder}
      {...passedProps}
      key='input'
      type={'hidden'}
    />
  )
}

export default InputField
