import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { asyncActions, selectors, actions } from '../../../../store/modules/changeManagement'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import VisuallyHidden from '../../../../components/VisuallyHidden'
import LoadingBar from '../../../../components/LoadingBar'
import P from '../../../../components/P'
import DataTable from '../../../../components/DataTable'
import ListViewLoadMore from '../../../../components/ListViewLoadMore'
import ChangeMananagementRow from '../ChangeManagementRow'
import ChangeManagementSearchFilters from '../ChangeManagementSearchFilters'
import { translations } from '../../../../config'
import styles from './style'
import fp from 'lodash/fp'
import { useQuery } from '../../../../hooks'
import { useChangeManagementModal } from '../hooks'

const headers = [
    { id: 'subject', label: translations('Change Request - Subject') },
    { id: 'ApprovalStatus', label: translations('Change Request - Approval Status') },
    { id: 'author' , label: translations('Change Request - Author')},
    { id: 'createdAt', label: translations('Change Request - Created') }
]

const renderResults = ({ data, isLoading, isEmpty, isError, errorMessage, classes }) => {
    let statusText = null
    switch (true) {
        case isEmpty:
            statusText = 'Change Request - No Results'
            break
        case isLoading:
            statusText = 'Change Request - Loading'
            break
        case isError:
            statusText = 'Change Request - Error'
            break
        default:
            break
    }
    if (statusText) {
        return (<div className={classes.message}>
            <P role='status' value={translations(statusText)} />
        </div>)
    }

    return (
        <Fragment>
            <DataTable
                headers={headers}
                data={data}
                tableId={'change-management-table'}
                tableHeading={'Change Requests'}
                rowComponent={ChangeMananagementRow}
            />
        </Fragment>
    )
}

const ChangeManagement = (props) => {
    const filters = useSelector(selectors.getMasterFilters)
    const page = useSelector(selectors.getMasterPageNumber)
    const canLoadMore = useSelector(selectors.getCanLoadMore)
    const dispatch = useDispatch()
    const query = useQuery()
    const showChangeManagementModal = useChangeManagementModal({ changeRequestId: query.changeRequestId })

    React.useEffect(() => {
        return () => {
            dispatch(actions.resetStore())
        }
    }, [dispatch])

    React.useEffect(() => {
        (async () => {
            if (!fp.isEmpty(filters)) {
                await dispatch(asyncActions.getChangeRequests())
            }
        })()
    }, [dispatch, filters, page])

    const { entity, isLoading, isEmpty, isError } = useSelector(selectors.getMaster)

    React.useEffect(() => {
        if (query.changeRequestId) {
            showChangeManagementModal()
        }
    }, [query.changeRequestId])

    const loadMore = React.useCallback(() => {
        dispatch(actions.incrementPage())
    }, [dispatch])

    const {
        classes,
        pageTitle = {},
    } = props
    return (
        <div className={classes.container}>
            <VisuallyHidden>
                <h1>{pageTitle.title}</h1>
            </VisuallyHidden>
            <Helmet>
                <title>{pageTitle.titleWithName}</title>
            </Helmet>
            <SubHeader
                leftContent={(
                    <BackBar />
                )}
            />
            <ChangeManagementSearchFilters/>
            <div className={classes.content}>
                <LoadingBar isLoading={isLoading} />
                {renderResults({
                    classes,
                    headers,
                    data: entity,
                    isLoading,
                    isEmpty,
                    isError
                })}
                {canLoadMore && <ListViewLoadMore isLoading={isLoading} onClick={loadMore} />}
            </div>
        </div>
    )
}

ChangeManagement.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    pageTitle: PropTypes.shape({
        title: PropTypes.string.isRequired,
        titleWithName: PropTypes.string
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasResults: PropTypes.bool,
    canLoadMore: PropTypes.bool,
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
}

export default withStyles(styles)(ChangeManagement)
