import React from 'react'
import Editable from '../../../../components/Editable'
import FormContentBox from '../../../../components/FormContentBox'
import { translations } from '../../../../config'
import { FormBody, FormError } from '../../../../components/Form'
import { useShallowEqualSelector } from '../../../../hooks'
import { getFormValues, change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectors as userDetailsSelectors } from '../../../../store/modules/userDetails'

const formId = 'communication-preferences'
const CommunicationPreferencesForm = Editable(FormContentBox(formId))

const CommunicationPreferences = ({
  classes,
  editable,
  initialValues,
  onSubmit,
  canManageUsers,
  isViewingOrEditingAnotherUser
}) => {
  const dispatch = useDispatch()
  const isUserPlatformManager = useSelector(userDetailsSelectors.isSelectedUserPlatformManager)
  const formValues = useShallowEqualSelector(getFormValues(formId)) || {}
  const isEmailCommunicationsPermitted = formValues?.emailCommunicationsPermitted
  const isWeeklyTransmissionsReportsEnabled = formValues?.isWeeklyTransmissionsReportsEnabled
  
  const onEmailCommunicationsPermittedChange = (isChecked) => {
    if (isWeeklyTransmissionsReportsEnabled && !isChecked) {
      dispatch(change(formId, 'isWeeklyTransmissionsReportsEnabled', false))
    }
  }

  return (
    <CommunicationPreferencesForm
      editable={editable}
      enableReinitialize
      initialValues={initialValues}
      givenClass={classes.formContainer}
      onSubmit={onSubmit}
      boxName={translations('Communication Preferences')}
      formId={formId}
    >

      <FormError givenClass={classes.submitError} />
      <FormBody
        schema={getSchema({
          initialValues,
          canManageUsers,
          isViewingOrEditingAnotherUser,
          isEmailCommunicationsPermitted,
          onEmailCommunicationsPermittedChange,
          isUserPlatformManager
        })}
        globalClasses={{
          col: classes.columnStyle,
          row: classes.rowStyle
        }}
      />
    </CommunicationPreferencesForm>
  )
}

const getSchema = ({
  initialValues,
  canManageUsers,
  isViewingOrEditingAnotherUser,
  isEmailCommunicationsPermitted,
  onEmailCommunicationsPermittedChange,
  isUserPlatformManager
}) => {
  const preferencesSchema = [
    {
      id: 'emailCommunicationsPermitted',
      field: 'Checkbox',
      props: {
        ...canManageUsers && isViewingOrEditingAnotherUser && !initialValues.emailCommunicationsPermitted && { disabled: true },
        label: translations('Email'),
        name: 'emailCommunicationsPermitted',
        required: false,
        onChange: (e) => onEmailCommunicationsPermittedChange(e.target.checked)
      }
    }
  ]
  if (isUserPlatformManager) {
    preferencesSchema.push({
      id: 'isWeeklyTransmissionsReportsEnabled',
      field: 'Checkbox',
      props: {
        label: translations('Weekly Transmission Reports'),
        name: 'isWeeklyTransmissionsReportsEnabled',
        required: false,
        disabled: !isEmailCommunicationsPermitted,
      }
    })
  }
  if (initialValues.telephone) {
    preferencesSchema.push({
      id: 'smsCommunicationsPermitted',
      field: 'Checkbox',
      props: {
        ...canManageUsers && isViewingOrEditingAnotherUser && !initialValues.smsCommunicationsPermitted && { disabled: true },
        label: translations('SMS'),
        name: 'smsCommunicationsPermitted',
        required: false,
      }
    })
  }
  return preferencesSchema
}

export default CommunicationPreferences