import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'

export const submissionsState = state => _.get(state, 'submissions')

export const getResults = createSelector(
  [submissionsState],
  (submission) => _.get(submission, 'results')
)

export const isLoading = createSelector(
  [submissionsState],
  (submission) => _.get(submission, 'status') === PENDING
)

export const getFilters = createSelector(
  [submissionsState],
  (submission) => _.get(submission, 'filters')
)

export const getQuery = createSelector(
  [submissionsState],
  (submission) => _.get(submission, 'filters.query')
)

export const getSearchSize = createSelector(
  [submissionsState],
  (submission) => _.get(submission, 'size')
)

export const getSearchPage = createSelector(
  [submissionsState],
  (submission) => _.get(submission, 'page')
)

export const getNumberOfActiveFilters = createSelector(
  getFilters,
  (filters) => {
    return _.reduce(filters, (total, value) => {
      return value ? total + 1 : total
    }, 0)
  }
)
