import _ from 'lodash'
import Professions from '../Professions'
import { useSelectedUserProfession } from '../../../../../../store/modules/userDetails/hooks'
import { selectors as userDetailsSelectors } from '../../../../../../store/modules/userDetails'
import { useSelector } from 'react-redux'
import { useLoggedInUserOrganisationName, useLoggedInUserOrganisationProfessionsOptions } from '../../../../../../store/modules/auth/hooks'

const EditUserProfessions = ({ editing }) => {
  const currentUserRoleId = useSelector(userDetailsSelectors.getSelectedUserValue)
  const currentProfession = useSelectedUserProfession()
  const organisationName = useLoggedInUserOrganisationName()
  const professionOptions = useLoggedInUserOrganisationProfessionsOptions()

  const showProfessionDropdown = professionOptions && currentUserRoleId >= 4
  if (!showProfessionDropdown) {
    return <></>
  }
  return (
    <Professions
      professionOptions={professionOptions}
      selectedProfessionName={currentProfession.name}
      editing={editing}
      organisationName={organisationName}
    />
  )
}

export default EditUserProfessions