import * as authConstants from '../auth/constants'
// import { REHYDRATE } from 'redux-persist/constants'
import appActions from './actions'
import { FAIL, SUCCESS } from '../../middleware/redux-promise'

import { selectors as networkSelectors } from '../../modules/network'

class AppMiddleware {
  logoutSuccessCloseSideMenuMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === authConstants.LOGOUT) {
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    }
  }

  appReadyMiddleware = ({dispatch, getState}) => next => (action) => {
    next(action)
    // if (action.type === REHYDRATE) {
    //   dispatch(appActions.appReady({ ready: true }))
    // }
  }

  onOffLineApiFailMiddleware = ({dispatch, getState}) => next => (action) => {
    next(action)
    const isConnected = networkSelectors.isConnected(getState())
    if (action.status === FAIL && !isConnected) {
      dispatch(appActions.onApiFail())
    }
  }
}

export default new AppMiddleware()
