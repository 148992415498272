import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { styled } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { modalSchemas } from '../../../helpers'
import { Can } from '../../../ability/ability-context'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import { DataTable } from '../../../components/DataTable'
import FormViewRow from '../FormViewRow'
import Button from '../../../components/Button'
import BottomBar from '../../../components/BottomBar'
import FormModal from '../../../components/FormModal'

import { useInitialFetch } from '../../../hooks'
import {
  actions as reportConfigurationActions,
  hooks as reportConfigurationHooks
} from '../../../store/modules/reportConfiguration'
import { actions as formSchemasActions } from '../../../store/modules/formSchemas'
import { hooks as authHooks } from '../../../store/modules/auth'
import { actions as organisationDetailsActions } from '../../../store/modules/organisationDetails'
import { actions as communicationTemplateActions } from '../../../store/modules/communicationTemplates'

import modalService from '../../../services/modalService'

import { translations } from '../../../config'
import ReportConfigurationFilters from './ReportConfigurationFilters'
import ReportConfigurationSearchInput from './ReportConfigurationSearchInput'

const ReportConfigurationAddButton = (props) => {
  return (
    <BottomBar>
      <Button
        buttonType='primary'
        onClick={props.onClick}
      >
        {props.children}
      </Button>
    </BottomBar>
  )
}

const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(6),
  maxWidth: 1200,
  margin: '0 auto'
}))

const ReportConfigurationScreen = () => {
  const dispatch = useDispatch()
  const organisationId = authHooks.useOrganisation()

  useInitialFetch(reportConfigurationActions.fetchFormViews)
  useInitialFetch(formSchemasActions.fetchAllFormSchemas)
  useInitialFetch(() => organisationDetailsActions.fetchOrganisationReceivers({ organisationId }))
  useInitialFetch(() => communicationTemplateActions.fetchOrganisationEmailTemplates({ organisationId }))

  const { formViews, formSchemas } = reportConfigurationHooks.useFilteredFormViews()
  const isLoading = reportConfigurationHooks.useFormViewsLoading()

  const saveReportConfiguration = (args) => {
    const [type, id] = _.split(args.template, '.')
    let formSchema = { name: id }
    if (type === 'FORM_VIEW') {
      formSchema = _.get(_.find(formViews, ['id', id]), 'formSchema')
    }
    const formSchemaType = _.get(formSchema, 'name')
    dispatch(reportConfigurationActions.setInitialEditorValues({ ...args, type: formSchemaType }))
    modalService.close()
    dispatch(push('/report-configuration-beta/new'))
  }

  const actions = [
    {
      success: true,
      primary: true,
      text: translations('Confirm')
    },
    {
      text: translations('Close'),
      onClick: modalService.close()
    }
  ]

  return (
    <div>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={(<ReportConfigurationSearchInput />)}
        isLoading={isLoading}
      />
      <Content>
        <ReportConfigurationFilters />
        <DataTable
          data={formViews}
          rowComponent={FormViewRow}
          isLoading={isLoading}
          tableId={'report-configuration-table'}
          tableHeading={'Report Configurations'}
          headers={[
            { id: 'name', label: translations('Name') },
            { id: 'version', label: translations('Version'), options: { align: 'center' } },
            { id: 'type', label: translations('Type'), options: { align: 'center' } },
            { id: 'category', label: translations('Category'), options: { align: 'left' } },
            { id: 'audience', label: translations('Audience'), options: { align: 'left' } },
            { id: 'publish', label: translations('Published'), options: { align: 'center' } },
            { id: 'sourceCount', label: translations('Source(s)'), options: { align: 'center' } },
            { id: 'archived', label: translations('Archived'), options: { align: 'center' } },
            { id: 'usage', label: translations('Usage'), options: { align: 'center' } },
            { id: 'updatedAt', label: translations('Updated'), options: { align: 'right' } }
          ]}
        />
      </Content>
      <Can I='create' a='reportConfigurationNext'>
        <ReportConfigurationAddButton
          onClick={() => modalService.open({
            formId: 'reportConfiguration',
            title: translations('FormView - Configure Modal Heading'),
            component: FormModal,
            actions: actions,
            schema: modalSchemas.getNewFormViewSchema({ formViews, formSchemas }),
            onSubmit: saveReportConfiguration
          })}
        >
          {translations('FormView - Create Report Configuration')}
        </ReportConfigurationAddButton>
      </Can>
    </div>
  )
}

ReportConfigurationScreen.propTypes = {}

export default ReportConfigurationScreen
