import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { compose } from 'recompose'
import digitalStoreSdk from '../../../../digitalStoreSdk'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'

import { translations } from '../../../../config'
import Dropdown from '../../../../components/Fields/Dropdown'
import DatePicker from '../../../../components/Fields/DatePicker'
import { withFiltersContainer } from '../../../../components/FiltersContainer'
import { selectors as exportManagementSelectors, actions as exportManagementActions } from '../../../../store/modules/exportManagement'
import { selectors as authSelectors } from '../../../../store/modules/auth'

import { withStyles } from '@material-ui/core/styles'
import styles from './style'

const ExportManagementSearchFilters = (props) => {
  const {
    classes,
  } = props

  const filters = useSelector(exportManagementSelectors.getFilters)
  const organisationId = useSelector(authSelectors.getUserSelectedOrganisationId)

  const [stateFilters, setStateFilters] = useState(filters)
  const [userDisplayName, setUserDisplayName] = useState(null)
  const dispatch = useDispatch()

  const exportTypeEnum = {
    SHARED_WITH_ME: 'Shared With Me',
    MY_DOWNLOADS: 'My Downloads'
  }
  
  useEffect(() => {
    if (filters?.userId && !userDisplayName) {
      digitalStoreSdk.users.fetchUserDetails({ id: filters.userId })
        .then(user => {
          setUserDisplayName(user.displayName)
        })
    }
  }, [])

  useEffect(() => {
    if (!_.isEqual(stateFilters, filters)) {
      setStateFilters(filters)
    }
  }, [filters])

  const updateFilters = useCallback(
    _.debounce((filter) => {
      updateFiltersAction(filter)
    }, 500)
    , [filters]
  )

  const setFilter = (filter) => {
    setStateFilters({ ...stateFilters, ...filter })
    updateFilters(filter)
  }

  const updateFiltersAction = (filter) => {
    const newFilters = { ...filters, ...filter }

    dispatch(exportManagementActions.changeFilters({ filters: newFilters }))
    dispatch(exportManagementActions.fetchExportFresh( newFilters ))
  }

  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={2} className={classes.filterBlock} justify={'center'}>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <DatePicker
                label={translations('Date From')}
                value={stateFilters.dateFrom}
                onChange={(e) => setFilter({ dateFrom: e })}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <DatePicker
                label={translations('Date To')}
                value={stateFilters.dateTo}
                onChange={(e) => setFilter({ dateTo: e })}
                noErrorTextLabel
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} className={classes.leftContent}>
            <FormControl className={classes.formControl}>
              <Dropdown
                label={translations('Export Type')}
                options={[
                  {value: exportTypeEnum.MY_DOWNLOADS, label: translations('Export Management - My Downloads')}, 
                  {value: exportTypeEnum.SHARED_WITH_ME, label: translations('Export Management - Shared With Me')}
                ]}
                value={stateFilters.exportType || null}
                onChange={(e) => setFilter({ exportType: e.target.value })}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

ExportManagementSearchFilters.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    exportType: PropTypes.oneOf([
      'My Downloads',
      'Shared With Me',
      ''
    ]),
  })
}

export default compose(
  withStyles(styles),
  withFiltersContainer
)(ExportManagementSearchFilters)
