import { connect } from 'react-redux'
import { compose, withHandlers, withState } from 'recompose'

import { actions as drugDictionaryActions, selectors as drugDictionarySelectors } from '../../../store/modules/drugDictionary'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

import ProductsSearchScreen from './ProductsSearchScreen'

const mapStateToProps = (state, ownProps) => {
  const isLoading = drugDictionarySelectors.getIsLoading(state)
  return {
    isLoading,
    pageTitle: platformSelectors.getPageTitle(state)(ownProps.watchListOnly ? 'Watch List Title' : 'Products Title')
  }
}

const searchDrugDictionary = drugDictionaryActions.searchDrugDictionary
const searchDrugDictionaryNext = drugDictionaryActions.searchDrugDictionaryNext
const mapDispatchToProps = {
  searchDrugDictionary,
  searchDrugDictionaryNext
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  // this is the query which corresponds to the current results
  withState('query', 'setQuery', ''),
  withHandlers({
    search: ({ searchDrugDictionary, setQuery, watchListOnly }) => (query) => {
      searchDrugDictionary({ query, watchListOnly })
      setQuery(query)
    },
    loadMore: ({ searchDrugDictionaryNext, query, watchListOnly, isLoading }) => () => {
      if (!isLoading) {
        searchDrugDictionaryNext({ query, watchListOnly })
      }
    }
  })
)

export default enhance(ProductsSearchScreen)
