import _ from 'lodash'
import { useEffect } from 'react'
import { FormControl, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Dropdown from '../../../../../components/Fields/Dropdown'
import Checkbox from '../../../../../components/Fields/Checkbox'
import { translations } from '../../../../../config'
import { actions as translationActions, selectors as translationSelectors } from '../../../../../store/modules/translations'
import { getTranslationKeyTagOptions } from '../../../../../store/modules/translations/selectors'

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
`

const TableFilters = ({ updateFilters, setShowSource, showSource }) => {
  const dispatch = useDispatch()

  const organisationLanguages = useSelector(translationSelectors.getCurrentOrganisationLanguagesAsOptions)
  const translationTags = useSelector(getTranslationKeyTagOptions)

  const keyScopeOptions = [
    { label: translations('Manage Translations - Key Scope Organisation Label'), value: 'ORGANISATION' },
    { label: translations('Manage Translations - Key Scope Global Label'), value: 'GLOBAL' }
  ]

  const [keyScope, setKeyScope] = useState(null)
  const [languages, setLanguages] = useState([])
  const [emptyTranslations, setEmptyTranslations] = useState(false)
  const [tags, setTags] = useState([])

  useEffect(() => {
    dispatch(translationActions.fetchTranslationTags())
  }, [])

  useEffect(() => {
    const languageValues = _.map(organisationLanguages, 'value')
    setLanguages(languageValues)
    updateFilters({ languages: languageValues })
  }, [organisationLanguages])

  return (
    <Container>
      <Grid container spacing={6}>
        <Grid item xs={12} md={2}>
          <FormControl style={{ width: '100%' }}>
            <Dropdown
              label={translations('Manage Translations - Key Scope Filter')}
              value={keyScope}
              options={keyScopeOptions}
              onChange={(e) => {
                setKeyScope(e.target.value)
                updateFilters({ keyScope: e.target.value })
              }}
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl style={{ width: '100%' }}>
            <Dropdown
              label={translations('Manage Translations - Languages Filter')}
              value={languages}
              options={organisationLanguages}
              onChange={(e) => {
                const newSelectedLanguages = e.target.value
                if (!_.isEmpty(newSelectedLanguages)) {
                  setLanguages(newSelectedLanguages)
                  updateFilters({ languages: newSelectedLanguages })
                }
              }}
              multiple
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl style={{ width: '100%' }}>
            <Dropdown
              label={translations('Manage Translations - Tags Filter')}
              value={tags}
              options={translationTags}
              onChange={(e) => {
                const selectedTags = e.target.value
                setTags(selectedTags)
                updateFilters({ tags: selectedTags })
              }}
              multiple
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl style={{ width: '100%', marginTop: 30 }}>
            <Checkbox
              label={translations('Manage Translations - Empty Translations Filter')}
              value={emptyTranslations}
              onClick={() => {
                setEmptyTranslations(!emptyTranslations)
                updateFilters({ 'emptyTranslations': !emptyTranslations })
              }}
              multiple
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl style={{ width: '100%', marginTop: 30 }}>
            <Checkbox
              label={translations('Manage Translations - Show Source')}
              value={showSource}
              onClick={() => setShowSource(!showSource)}
              multiple
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  )
}

export default TableFilters