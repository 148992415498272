import _ from 'lodash'
import React from 'react'
import { translations } from '../../../config'
import { styled, CircularProgress } from '@material-ui/core'

import Button from '../../../components/Button'
import toastService from '../../../services/toastService'
import { useWebsites } from '../contexts/WebsiteManagementContext'

const Wrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2)
}))

const toastError = ({ method, error }) => toastService.action({
  type: 'error',
  message: `${translations(`Failure - ${method} Website Management`)} - ${error}`
})

const toastSuccess = ({ method }) => toastService.action({
  type: 'success',
  message: translations(`Success - ${method} Website Management`)
})

const SaveWebsite = (props) => {
  const {
    isNewWebsite
  } = props

  const {
    state,
    createWebsite,
    updateWebsite,
    settingsFormErrors
  } = useWebsites()

  const isSaving = _.get(state, 'isSaving')
  const isEdited = _.get(state, 'isEdited')
  const hasErrors = !_.isEmpty(settingsFormErrors)
  const isDisabled = hasErrors || isSaving || !isEdited

  const handleSave = async () => {
    const method = isNewWebsite ? 'Create' : 'Update'
    try {
      if (isNewWebsite) {
        await createWebsite()
      } else {
        await updateWebsite()
      }
      toastSuccess({ method })
    } catch (error) {
      toastError({ method, error })
    }
  }

  return (
    <Wrapper>
      <Button
        disabled={isDisabled}
        variant='contained'
        color='primary'
        onClick={handleSave}
      >
        {isNewWebsite ? translations('Create') : translations('Save')}
        {isSaving && <CircularProgress size={23} thickness={2} />}
      </Button>
    </Wrapper>
  )
}

export default SaveWebsite