import moment from 'moment'
import _ from 'lodash'

export const getDays = (month, year, startDate, endDate) => {
  let day = month ? moment(month, 'MM').daysInMonth() : 31
  if (year && moment(year, 'YYYY').isLeapYear() && month == 2) {
    day++
  }
  let days = []
  while (day > 0) {
    days.unshift(day > 9 ? day.toString() : `0${day.toString()}`)
    day--
  }
  if (year === startDate.year && month === startDate.month) {
    days = days.slice(days.indexOf(startDate.day))
  }
  if (year === endDate.year && month === endDate.month) {
    days = days.slice(0, days.indexOf(endDate.day) + 1)
  }
  return days
}

export const getMonths = (selectedYear, startDate, endDate) => {
  let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  if (selectedYear === startDate.year) {
    months = months.slice(months.indexOf(startDate.month))
  }
  if (selectedYear === endDate.year) {
    months = months.slice(0, months.indexOf(endDate.month) + 1)
  }
  return months
}

export const parseDate = (value) => {
  if (value) {
    return {
      year: value.substring(0, 4),
      month: value.substring(4, 6),
      day: value.substring(6, 8)
    }
  } else {
    return { day: '', month: '', year: '' }
  }
}

export const stringifyDate = (e, currentValue, type) => {
  const { value } = e.target
  const currentDates = parseDate(currentValue)
  const { day, month, year } = { ...currentDates, [type]: value || '' }

  const newDate = {
    day: month && year ? day : '',
    month: year ? month : '',
    year
  }

  const date = `${newDate.year}${newDate.month}${newDate.day}`
  return date || null
}
