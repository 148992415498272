import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/HighlightOff'
import SearchIcon from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles'
import SearchMenu from './SearchMenu'

import { translations } from '../../config'
import style from './style'

const SearchInput = (props) => {
  const {
    value = '',
    placeholder,
    onChange,
    onClear,
    classes,
    autoFocus = true,
    menuItems,
    onMenuItemSelect,
    menuButtonTitle = 'Saved Searches',
    onMenuSave,
    onMenuDelete,
    onMenuCreate,
    onMenuDiscard,
    activeMenuItemId
  } = props
  const id = _.uniqueId('searchInput_')
  const clearable = _.size(value) > 0 && _.isFunction(onClear)
  const hasCreateAndMenu = _.isFunction(onMenuItemSelect) && _.isFunction(onMenuCreate)
  const hasMenuAndItems = _.isFunction(onMenuItemSelect) && _.size(menuItems) > 0
  const hasMenu = hasCreateAndMenu || hasMenuAndItems

  return (
    <div className={classes.container}>
      <label
        htmlFor={id}
        className={classes.searchIconContainer}
        aria-label={translations('Search')}
      >
        <SearchIcon className={classes.icon} />
      </label>
      <input
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        type={'text'}
        className={classes.searchInput}
        autoCapitalize='none'
        autoComplete='off'
        autoCorrect='off'
        spellCheck='off'
        autoFocus={autoFocus}
      />
      {clearable && (
        <IconButton aria-label={translations('Clear')} onClick={onClear}>
          <ClearIcon />
        </IconButton>
      )}
      {hasMenu && (
        <SearchMenu
          onSave={onMenuSave}
          onDelete={onMenuDelete}
          onCreate={onMenuCreate}
          onDiscard={onMenuDiscard}
          activeElementId={activeMenuItemId}
          menuButtonTitle={menuButtonTitle}
          menuElements={menuItems}
          onMenuItemSelect={onMenuItemSelect}
        />
      )}
    </div>
  )
}

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.shape({}).isRequired,
  autoFocus: PropTypes.bool,
  onClear: PropTypes.func,
  onMenuItemSelect: PropTypes.func,
  onMenuDelete: PropTypes.func,
  onMenuSave: PropTypes.func,
  onMenuCreate: PropTypes.func,
  menuButtonTitle: PropTypes.string,
  activeMenuItemId: PropTypes.string
}

export default withStyles(style)(SearchInput)
