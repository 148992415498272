"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reportersAsOptions = exports.isVaccineOrBiological = exports.isUnbrandedSubstance = exports.isTestNameRequired = exports.isStudyAndTrial = exports.isReportTypeFromStudy = exports.isPrimaryReporter = exports.isNotUnbrandedSubstance = exports.isDrugAdministrationRouteTransmammaryOrTransplacental = exports.isClinicalTrial = exports.inferPrimaryReporter = exports.hasSingleOrNoReporters = exports.hasReportersWithAckPermission = exports.hasNoReporters = exports.handleEDQMListROA = exports.handleEDQMListPharma = exports.getReporterRepeatables = exports.getFormatForDate = exports.getFirstReporterId = exports.enforceSeriousnessOnSUSAR = exports.attachmentNotConfirmed = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = _interopRequireDefault(require("lodash/fp"));

var computedHidden = _interopRequireWildcard(require("../../../formHelpers/computedHidden"));

var _sideEffects = require("../../../formHelpers/sideEffects");

var _validators = require("../../../formHelpers/validators");

var serious = _interopRequireWildcard(require("../../../formHelpers/sideEffects/serious"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var getFormatForDate = function getFormatForDate(value, formats) {
  if (!value) return '';
  if (!formats) return '';
  var date = {
    year: value.substring(0, 4),
    month: value.substring(4, 6),
    day: value.substring(6, 8)
  };

  if (date.year && date.month && date.day) {
    return formats.yyyymmdd || '';
  } else if (date.year && date.month) {
    return formats.yyyymm || '';
  } else if (date.year) {
    return formats.yyyy || '';
  } else {
    return '';
  }
};

exports.getFormatForDate = getFormatForDate;

var getReporterRepeatables = _fp["default"].getOr([], 'primarysource');

exports.getReporterRepeatables = getReporterRepeatables;

var isPrimaryReporter = function isPrimaryReporter(values) {
  var primarySourceAllocation = _fp["default"].get('_parentFormValues.primarysourceid', values);

  var repeatableId = _fp["default"].get('id', values);

  if (_fp["default"].isUndefined(primarySourceAllocation)) {
    return true;
  }

  return primarySourceAllocation === repeatableId;
};

exports.isPrimaryReporter = isPrimaryReporter;

var hasSingleOrNoReporters = _fp["default"].compose(function (length) {
  return length === 1 || length === 0;
}, _fp["default"].size, getReporterRepeatables);

exports.hasSingleOrNoReporters = hasSingleOrNoReporters;

var hasNoReporters = _fp["default"].compose(_fp["default"].isEmpty, getReporterRepeatables);

exports.hasNoReporters = hasNoReporters;

var hasReportersWithAckPermission = function hasReportersWithAckPermission(values) {
  var reporters = _fp["default"].getOr([], 'primarysource', values);

  var _iterator = _createForOfIteratorHelper(reporters),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var reporter = _step.value;

      if (_lodash["default"].get(reporter, 'ackpermissionhcp') === '1') {
        return true;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return false;
};

exports.hasReportersWithAckPermission = hasReportersWithAckPermission;

var getFirstReporterId = _fp["default"].get('primarysource.0.id');

exports.getFirstReporterId = getFirstReporterId;

var inferPrimaryReporter = function inferPrimaryReporter(values) {
  var singleOrNoReporters = hasSingleOrNoReporters(values);
  var reportsWithAckPermission = hasReportersWithAckPermission(values);

  if (singleOrNoReporters && !reportsWithAckPermission) {
    return getFirstReporterId(values);
  }

  return undefined;
};

exports.inferPrimaryReporter = inferPrimaryReporter;

var reportersAsOptions = function reportersAsOptions(values) {
  var reporters = getReporterRepeatables(values);
  return _lodash["default"].map(reporters, function (reporter, index) {
    var reporterName = _fp["default"].compose(function (name) {
      return _fp["default"].isEmpty(name) ? "Reporter ".concat(index + 1) : name;
    }, _fp["default"].join(' '), _fp["default"].compact, _fp["default"].at(['reportergivename', 'reporterfamilyname']))(reporter);

    return {
      value: reporter.id,
      label: reporterName
    };
  });
};

exports.reportersAsOptions = reportersAsOptions;

var isTestNameRequired = _fp["default"].allPass([computedHidden.fieldIsTruthy('testdate')]);

exports.isTestNameRequired = isTestNameRequired;

var isVaccineOrBiological = function isVaccineOrBiological(values) {
  return _fp["default"].compose(function (type) {
    return _fp["default"].includes(type, ['vaccine', 'biological']);
  }, _fp["default"].get('producttype'))(values);
};

exports.isVaccineOrBiological = isVaccineOrBiological;
var isUnbrandedSubstance = computedHidden.fieldIs('substancetype', 'substance');
exports.isUnbrandedSubstance = isUnbrandedSubstance;
var isNotUnbrandedSubstance = computedHidden.fieldIsNot('substancetype', 'substance');
exports.isNotUnbrandedSubstance = isNotUnbrandedSubstance;

var isDrugAdministrationRouteTransmammaryOrTransplacental = function isDrugAdministrationRouteTransmammaryOrTransplacental(values) {
  return _fp["default"].compose(function (administrationRoutes) {
    return _fp["default"].any(_fp["default"].equals(true), [_fp["default"].includes('Transmammary', administrationRoutes), _fp["default"].includes('Transplacental', administrationRoutes)]);
  }, _fp["default"].map('drugadministrationroute'), _fp["default"].get('drug'))(values);
};

exports.isDrugAdministrationRouteTransmammaryOrTransplacental = isDrugAdministrationRouteTransmammaryOrTransplacental;
var isReportTypeFromStudy = computedHidden.fieldIs('_parentFormValues.reporttype', '2');
exports.isReportTypeFromStudy = isReportTypeFromStudy;
var isClinicalTrial = computedHidden.fieldIs('_parentFormValues.observestudytype', '1');
exports.isClinicalTrial = isClinicalTrial;

var isStudyAndTrial = _fp["default"].allPass([isReportTypeFromStudy, isClinicalTrial]);

exports.isStudyAndTrial = isStudyAndTrial;
var enforceSeriousnessOnSUSAR = (0, _validators.checkIf)(function (_value, allValues) {
  return isStudyAndTrial(allValues);
}, (0, _validators.checkMinimumOf1FieldIs)({
  fields: serious.reactionFields,
  predicate: serious.isOneSeriousnessYes,
  translationKey: 'Error - One seriousness must be yes for SUSAR'
}));
exports.enforceSeriousnessOnSUSAR = enforceSeriousnessOnSUSAR;

var handleEDQMListROA = _sideEffects.audience.match({
  MOP: function MOP() {
    return 'ROUTES_OF_ADMINISTRATION_CONDENSED';
  },
  HCP: function HCP() {
    return 'ROUTES_OF_ADMINISTRATION_CONDENSED';
  },
  INDUSTRY: function INDUSTRY() {
    return 'ROUTES_OF_ADMINISTRATION';
  }
});

exports.handleEDQMListROA = handleEDQMListROA;

var handleEDQMListPharma = _sideEffects.audience.match({
  MOP: function MOP() {
    return 'PHARMA_CONDENSED';
  },
  HCP: function HCP() {
    return 'PHARMA_CONDENSED';
  },
  INDUSTRY: function INDUSTRY() {
    return 'PHARMA';
  }
});

exports.handleEDQMListPharma = handleEDQMListPharma;
var attachmentNotConfirmed = computedHidden.fieldIsNot('attachmentConfirmation', true);
exports.attachmentNotConfirmed = attachmentNotConfirmed;