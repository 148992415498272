import { colors } from '../../config/theme'

const styles = {
  container: {
    padding: 15,
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.1)',
    backgroundColor: 'white',
    alignItems: 'center',
    margin: '20px 0 20px 0'
  }
}

export default theme => styles
