import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { reset } from 'redux-form'
import NewsFeedForm, { newFeedFormName } from './NewsFeedForm'
import { actions as newsFeedsActions, selectors as newsFeedsSelectors } from '../../../../../store/modules/newsFeeds'
import { selectors as languagesSelectors } from '../../../../../store/modules/languages'
import modalService from '../../../../../services/modalService'
import { translations } from '../../../../../config'

const enhancer = compose(
  connect(state => ({
    error: newsFeedsSelectors.getCreateError(state),
    languageOptions: languagesSelectors.getLanguagesAsOptions(state)
  })),
  withHandlers({
    handleSubmit: ({ dispatch }) => async ({ url, languageId, source }) => {
      await dispatch(newsFeedsActions.createFeed({ url, languageId, source }))
      dispatch(reset(newFeedFormName))
      modalService.continue({
        title: translations('Create Feed Success Modal Title'),
        text: translations('Create Feed Success Modal Text')
      })
    }
  })
)

export default enhancer(NewsFeedForm)
