import { DialogTitle, IconButton, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

export const DetailsContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  '& p':{
    fontSize: 14,
    lineHeight: 1
  }
}))

export const CenterContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})


export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: '25px 25px 15px 25px',
  '& p': {
    fontSize: 14,
    lineHeight: 1
  }
}))

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  color: theme.palette.grey[500]
}))
