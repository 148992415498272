import { connect } from 'react-redux'
import { selectors as themeSelectors } from '../../store/modules/themes'
import MainLogo from './MainLogo'

const mapStateToProps = state => {
  const src = themeSelectors.getThemeLogo(state)
  const name = themeSelectors.getThemeLogoAlt(state)
  return {
    src,
    name
  }
}

export default connect(mapStateToProps)(MainLogo)
