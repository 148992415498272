import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'

import { useQuery } from '../../../hooks'
import { translations } from '../../../config'
import SuccessScreen from '../../../components/SuccessScreen'
import Button from '../../../components/Button'
import Container from '../../../components/Container'

const ActionContainer = styled('div')({
  display: 'flex',
  'flex-direction': 'column',
  'align-items': 'center'
})

const EmailVerifiedScreen = () => {
  const dispatch = useDispatch()

  const { redirectUrl } = useQuery()

  const onLoginClicked = useCallback(() => {
    if (redirectUrl) {
      window.location.replace(redirectUrl)
    } else {
      dispatch(push('/login'))
    }
  }, [dispatch, redirectUrl])

  return (
    <Container withMarginTop>
      <SuccessScreen
        heading={translations('Email Verified Successfully Heading')}
        message={translations('Email Verified Successfully Message')}
      />
      <ActionContainer>
        <Button onClick={onLoginClicked} buttonType="primary">
          {translations('Email Verified Login Button')}
        </Button>
      </ActionContainer>
    </Container>
  )
}

export default EmailVerifiedScreen
