"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  value: 'Male',
  label: 'Male'
}, {
  value: 'Female',
  label: 'Female'
}];
exports["default"] = _default;