import React from 'react'

import Checkbox from '../Checkbox'

const TermsCheckbox = ({ privacyPolicyLink, ...props }) => {
  return (
    <Checkbox {...props} link={privacyPolicyLink} linkTarget="_blank" />
  )
}

export default TermsCheckbox
