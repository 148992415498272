import React from 'react'
import { styled } from '@material-ui/core/styles'
import { AddDictionaryButtonLarge } from '../AddDictionaryButton'

const Content = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '50px'
  }))


export const NoDictionariesView = () => {
    return <Content>
        <AddDictionaryButtonLarge/>
    </Content>
}
