import { colors } from '../../config/theme'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    flex: 1
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  footer: {},
  menuItem: {
    fontSize: 16,
    borderBottom: '1px solid',
    borderBottomColor: colors.border,
    padding: '10px 15px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 15,
      paddingBottom: 15
    },
    fontWeight: 600,
    textTransform: 'uppercase',
    width: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
    lineHeight: 1.45
  },
  rootMenu: {
    /* Allows content to fill the viewport and go beyond the bottom */
    height: '100%',
    width: 280,
    [theme.breakpoints.up('sm')]: {
      width: 320
    }
  }
})

export default theme => styles(theme)
