
import React from 'react'
import { FormControl } from '@material-ui/core'
import PropTypes from 'prop-types'
import * as validators from '../../../validators'
import style from './style'
import Form, { FormBody, FormSubmit } from '../../../components/Form'
import { Dropdown, OrganisationAssociationLookup } from '../../../components/Fields'
import { translations } from '../../../config'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../../components/Button'
import _ from 'lodash'

const SelectOrganisationForm = Form('select-organisation')

const SelectOrganisationFormWrapper = ({
  onSubmit,
  organisationId,
  organisations,
  signInToWorkspace,
  canUserSeeWorkspaceOption,
  classes
}) => {

  const organisationComponent = organisations && !_.isEmpty(organisations)
    ? <Dropdown
      options={organisations}
      name='organisationRegionId'
      validate={validators.required}
      strict
    />
    : <OrganisationAssociationLookup
      name='organisationRegionId'
      validate={validators.required}
      getCustomValue={(organisation) => `${organisation.id}_${organisation.regionId}_${organisation.receiverId}`}
      strict
    />

  return (
    <div>
      <SelectOrganisationForm onSubmit={onSubmit} submitLabel={translations('Continue')} initialValues={{ organisationId }}>
        <FormBody>
          <div className={classes.formBody}>
            <FormControl className={classes.field}>
              {organisationComponent}
            </FormControl>
          </div>
        </FormBody>
        <FormSubmit containerClass={classes.centerButtonContainer} label={translations('Continue')} buttonClass={classes.fullWidthButton} />
        {canUserSeeWorkspaceOption && <div className={classes.workspaceAlternative}>
          <Button
            name="workspace"
            hyperlink
            onClick={signInToWorkspace}
          >{translations('Organisation Selector - Sign in to workspace')}
          </Button>
        </div>
        }</SelectOrganisationForm>
    </div>
  )
}

SelectOrganisationFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default withStyles(style)(SelectOrganisationFormWrapper)
