import i18next from 'i18next'
import en from './configFiles/translations/en'
import LanguageDetector from 'i18next-browser-languagedetector'
import bcp47 from 'bcp-47'
import fp from 'lodash/fp'

i18next
.use(LanguageDetector)
.init({
  debug: false,
  fallbackLng: 'en',
  keySeparator: false,
  detection: {
    order: [ 'navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain' ]
  },
  resources: {
    en: { translation: en }
  },
  nsSeparator: '__'
})

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    navigator.globalization.getPreferredLanguage(
      (locale) => {
        const parsedLocale = bcp47.parse(locale.value)
        i18next.changeLanguage(parsedLocale.language)
      }
    )
  }, false)
}

export const defaultTranslations = en
export const translations = (key, options) => i18next.t(key, options)
export const translationsExists = (key, options) => {
  if (!key) return false
  return i18next.exists(key, options)
}
export const getCurrentLanguage = () => i18next.language
export const addResources = translations => {
  return new Promise((resolve, reject) => {
    for (let lang in translations) {
      i18next.addResourceBundle(lang, 'translation', translations[lang], false, true)
    }
    resolve()
  })
}

export const deriveTranslationFromError = (error) => {
  const [
    code = '',
    properties = {},
  ] = fp.at(['code', 'meta.properties'], error)
  return translations(code, properties)
}