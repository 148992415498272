export default {
  text: {
    position: 'absolute',
    top: '14px',
    fontSize: '19px',
    textTransform: 'uppercase',
    width: '100%',
    textAlign: 'center'
  },
  contentBoxHeader: {
    fontSize: '19px',
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}
