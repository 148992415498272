import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import P from '../P'
import Heading from '../Heading'
import Button from '../Button'
import ButtonsContainer from '../ButtonsContainer'
import FormSubmit from '../Form/FormSubmit'
import { LinearProgress } from '@material-ui/core'
import style from './style'

class BasicModalOverlay extends PureComponent {
  state = {
    automaticActionProgress: 0,
    automaticActionInterval: 0
  }

  componentDidMount () {
    if (this.props.automaticAction) {
      this.automaticActionCountdown = setInterval(this._automaticActionProgress, 1000)
    }
  }

  componentWillUnmount () {
    this._clearAutomaticCountdown()
  }

  componentDidUpdate () {
    if (this.state.automaticActionProgress >= 100) {
      this._onAutomaticAction()
    }
  }

  _clearAutomaticCountdown = () => {
    if (this.automaticActionCountdown) {
      clearInterval(this.automaticActionCountdown)
    }
  }

  _automaticActionProgress = () => {
    this.setState((state, props) => {
      const { automaticActionInterval, automaticActionProgress } = state
      const { automaticActionTimeout } = props

      return {
        automaticActionInterval: automaticActionInterval + 1,
        automaticActionProgress: automaticActionProgress < 100 ? ((1000 * automaticActionInterval) / automaticActionTimeout) * 100 : 100
      }
    })
  }

  _onAutomaticAction = () => {
    this._clearAutomaticCountdown()
    this.props.automaticAction()
  }

  _renderTimeout = () => {
    const { classes, automaticAction } = this.props

    if (!automaticAction) return null

    return (
      <div className={classes.automaticActionTimer}>
        <LinearProgress mode='determinate' value={this.state.automaticActionProgress} />
      </div>
    )
  }

  _renderButtons = () => {
    const { actions, submitting } = this.props
    return _.chain(actions)
      .partition(action => action.success)
      .thru(([dismissActions, successActions]) => [...successActions, ...dismissActions])
      .map((action) => {
        const { onClick, primary, text, success, ...rest } = action
        if (success) {
          return (
            <FormSubmit
              buttonType={primary ? 'primary' : 'white'}
              noContainer
              onClick={onClick}
              submitting={submitting}
              key={text}
              {...rest}
            >
              {text}
            </FormSubmit>
          )
        } else {
          return (
            <Button
              onClick={onClick}
              buttonType={primary ? 'primary' : 'white'}
              key={text}
              {...rest}
            >
              {text}
            </Button>
          )
        }
      })
      .value()
  }

  render () {
    const {
      title,
      text,
      classes,
      actions,
      children
    } = this.props
    return (
      <Fragment>
        { title && <Heading className={classes.title}>{title}</Heading> }
        {children || <P className={classes.text} value={text} />}
        {
          actions && actions.length
            ? <ButtonsContainer
              className={classes.buttonsContainer}
              align='right'
              isModal
            >
              {this._renderButtons()}
            </ButtonsContainer>
            : null
        }
        {this._renderTimeout()}
      </Fragment>
    )
  }
}

BasicModalOverlay.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  automaticAction: PropTypes.func,
  submitting: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.any,
  actions: PropTypes.arrayOf(PropTypes.shape({
    success: PropTypes.bool,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    primary: PropTypes.bool
  }))
}

export default withStyles(style)(BasicModalOverlay)
