import React, { useEffect, useContext, useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import digitalStoreSdk from '../../../../digitalStoreSdk'

import { utilities } from '@redant/mhra-form-schema-library'
import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/core'

import Button from '../../../../components/Button'
import { useQuery } from '../../../../hooks'
import { translations } from '../../../../config'
import toastService from "../../../../services/toastService"
import FollowUpContext from '../../../../contexts/FollowUpContext'
import ConditionsContext from '../../../../contexts/ConditionsContext'
import { actions as conditionActions } from '../../../../contexts/ConditionsContext'

import ReportConfigurationEditorPanel from '../../ReportConfigurationEditorPanel'
import Content from '../Components/Content'
import Actions from '../Components/Actions'

const GridFlexStart = styled(Grid)(({ theme }) => ({
  marginRight: 'auto'
}))

const toastError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Save Report Config')
})
const toastSuccess = () => toastService.action({
  type: 'success',
  message: translations('Successfully - Save Report Config')
})

const ManageFollowUpContents = (props) => {
  const { onClose, setHasEdits, formViewId } = props
  const dispatch = useDispatch()
  const { followUp: followUpMeta } = useQuery()
  const { state: FollowUpState } = useContext(FollowUpContext.followUpStore)
  const {
    getConditionsForSave,
    getConditionsForResource,
    state: conditionsState,
    dispatch: conditionsDispatch
  } = useContext(ConditionsContext.conditionsStore)
  const { isEdited } = FollowUpState
  const { isEdited: conditionsEdited } = conditionsState
  const [conditionsInitiallyEdited] = useState(conditionsEdited)

  const onSave = () => {
    const { followUpReportFormViewId } = followUpMeta
    const viewJSON = utilities.unflattenViewJSON(FollowUpState, getConditionsForResource)
    const conditions = getConditionsForSave()
    digitalStoreSdk.formView.updateFormVersion({ id: followUpReportFormViewId, params: { viewJSON, conditions } })
      .then(() => {
        !conditionsInitiallyEdited && conditionsDispatch(conditionActions.setUnedited())
        onClose({ saving: true })
        toastSuccess()
      })
      .catch(ex => {
        toastError()
      })

  }

  const onDetailClick = () => {
    if (!_.isUndefined(followUpMeta)) {
      const { followUpId, followUpReportFormViewId } = followUpMeta
      dispatch(push(`/report-configuration-beta/${followUpReportFormViewId}?currentTab=reports`))
    }
  }

  useEffect(() => {
    setHasEdits(isEdited || conditionsEdited)
  }, [setHasEdits, isEdited, conditionsEdited])

  return (
    <>
      <Actions>
        <Grid container spacing={2} direction='row' justify='flex-end'>
          <GridFlexStart item>
            <Button onClick={onDetailClick} >
              {translations('Advanced')}
            </Button>
          </GridFlexStart>
          <Grid item>
            <Button onClick={onClose} >
              {translations('Cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type='submit'
              raised
              buttonType='primary'
              onClick={onSave}
              disabled={!(isEdited || conditionsEdited)}
            >
              {translations('Save')}
            </Button>
          </Grid>
        </Grid>
      </Actions>
      <Content>
        <ReportConfigurationEditorPanel formViewStore={FollowUpContext.followUpStore} formViewId={formViewId} />
      </Content>
    </>
  )
}

ManageFollowUpContents.props = {}

ManageFollowUpContents.propTypes = {}

export default ManageFollowUpContents
