// not sure this should actually be used
// preferably just use form schemas

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'
import Heading from '../../../../components/Heading'
import { translations } from '../../../../config'

import FormBody from '../../FormBody'
import style from './style'

const PersonalDetails = props => {
  const {
    schema,
    classes
  } = props
  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading className={classes.contentBoxHeader}>
            {translations('Personal Details')}
          </Heading>
        </TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <FormBody
          schema={schema}
          editing
        />
      </ContentBoxBody>
    </ContentBox>
  )
}

PersonalDetails.propTypes = {
  dayValue: PropTypes.string
}

export default withStyles(style)(PersonalDetails)
