import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { getImage } from '../Images'
import P from '../P'
import AlertBadge from '../../containers/AlertBadge'
import style from './style'
import { translations } from '../../config'

const MenuWidget = ({
  title,
  image: imageKey,
  badgeType,
  onClick,
  classes
}) => {
  const icon = getImage(imageKey)
  return (
    <ButtonBase
      className={classes.container}
      onClick={onClick}
      focusRipple
      >
      <div className={classes.inner}>
        <div className={classes.parentCorner}>
          <AlertBadge type={badgeType} />
        </div>
        <img src={icon} className={classes.image} alt={''} />
        <div className={classes.title}>
          <P className={classes.titleText} value={translations(`App Name - ${title}`)} type='hubTileText' />
        </div>
      </div>
    </ButtonBase>
  )
}

MenuWidget.propTypes = {
  title: PropTypes.string.isRequired
}

export default withStyles(style)(MenuWidget)
