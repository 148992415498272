"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schema = void 0;

var _schema = require("./schema");

var _view = _interopRequireDefault(require("./view.json"));

var _constants = require("../../../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var schema = {
  type: _constants.FORM_TYPE_CONSTANTS.STANDARD,
  getFormSchema: function getFormSchema(validators) {
    return (0, _schema.getFormSchema)(validators);
  },
  getFormView: function getFormView() {
    return JSON.parse(JSON.stringify(_view["default"]));
  }
};
exports.schema = schema;