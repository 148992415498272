import _ from 'lodash';
import React from 'react';
import { translations } from '../../../../config';
import { useSelector } from 'react-redux';
import { selectors as reportDetailsSelectors } from '../../../../store/modules/reportDetails'
import { authCombinedSelectors } from '../../../../store/modules/combinedSelectors'

import { styled, Grid, IconButton, Typography } from '@material-ui/core';
import CopyToClipboardButton from '../../../../components/CopyToClipboardButton';
import Close from '@material-ui/icons/Close';
import Button from '../../../../components/Button';
import IconText from '../../../../components/IconText';
import Warning from '@material-ui/icons/Warning';
import { getIsReportEditable } from '../../../../store/modules/reportDetails/selectors';
const Header = styled(Grid)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#F4F4F4'
}))

const ReportIdContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1),
  width: '100%',
  marginLeft: 5,
  marginTop: 5
}))

const ExpandButtonContainers = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: theme.spacing(1),
  width: '100%',
  marginLeft: 5
}))

const ReportId = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: 5
}))

const ExitButton = styled(IconButton)(({ theme }) => ({
  marginLeft: 20
}))

const ExpandButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: 0
  }
}))

const WarningContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  marginLeft: 10,
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    justifyContent: 'center'
  }
}))

const ReportSummaryModalHeader = (props) => {
  const {
    reportId,
    handleClose,
    toggleAllSections,
    showCopyButton,
    enableExpandButtons = false
  } = props

  const isReportEditable = useSelector(getIsReportEditable)
  const hasNewerVersion = useSelector(reportDetailsSelectors.getHasNewerVersion)
  const showUpdates = useSelector(authCombinedSelectors.userCanSeeReportUpdates)

  const ShowReportId = ({ reportId, showCopyButton }) => {
    return (
      <>
        <ReportId variant={'h6'}>{reportId}</ReportId>
        {showCopyButton && <CopyToClipboardButton text={reportId} />}
      </>
    )
  }

  return (
    <Header xs={12} container direction='row'>
      <Grid container xs={6} lg={6} direction='row'>
        <ReportIdContainer>
          <ShowReportId reportId={reportId} showCopyButton={showCopyButton} />
        </ReportIdContainer>
      </Grid>
      <Grid container justify='flex-end' xs={6} lg={6} >
        <ExpandButtonContainers>
          {enableExpandButtons &&
            <>
              <ExpandButton onClick={() => toggleAllSections({ open: true })}>
                {translations('Expand All Sections')}
              </ExpandButton>
              <ExpandButton onClick={() => toggleAllSections({ open: false })}>
                {translations('Close All Sections')}
              </ExpandButton>
            </>
          }
          <ExitButton onClick={handleClose}>
            <Close />
          </ExitButton>
        </ExpandButtonContainers>
      </Grid>
      {hasNewerVersion && showUpdates &&
        <Grid container xs={12}>
          <WarningContainer>
            <IconText
              icon={Warning}
              text={translations('A newer version of this report is available')}
            />
          </WarningContainer>
        </Grid>
      }
      {
        !isReportEditable &&
        <Grid container xs={12}>
          <WarningContainer>
            <IconText
              icon={Warning}
              text={translations('Report Summary Modal - Read Only Message')}
            />
          </WarningContainer>
        </Grid>
      }
    </Header>
  )
}

export default ReportSummaryModalHeader