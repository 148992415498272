import { createSelector } from 'reselect'
import _ from 'lodash'
import { PENDING } from '../../middleware/redux-promise'
import { translations } from '../../../config'

export const getIsLoading = state => state.organisationDetails.status === PENDING
export const getLanguages = state => _.get(state, 'languages.result', [])

export const getLanguagesAsOptions = createSelector(
  [getLanguages],
  (languages) => {
    if (!languages.map) return []
    return languages.map(language => {
      return {
        label: translations(`Language - ${language.isoCode}`),
        value: language.id
      }
    })
  }
)
export const getError = state => state.error
