import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import VisuallyHidden from '../../../../components/VisuallyHidden'
import UsersSearchInput from '../UsersSearchInput'
import UsersSearchTable from '../UsersSearchTable'
import UsersSearchFilters from '../UsersSearchFilters'
import UsersLoadingBar from '../UsersLoadingBar'

import styles from './style'
import UsersMoreMenu from '../UsersMoreMenu/UsersMoreMenu'
import { translations } from '../../../../config'
import RegistrationButton from '../../../../components/RegistrationButton'

const UsersSearchScreen = ({
  onUserClick,
  onBackClick,
  classes,
  hideBottomBar,
  disabledUserId,
  inModal,
  pageTitle,
  addUserPath
}) => {
  const scrollContainerClass = (inModal) ? classes.scrollContainerInModal : classes.scrollContainer
  return (
    <div className={classes.container}>
      <UsersLoadingBar />
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={(
          <UsersSearchInput />
        )}
        rightContent={(
          <UsersMoreMenu />
        )}
      />
      <div className={scrollContainerClass}>
        <UsersSearchFilters />
        <UsersSearchTable onUserClick={onUserClick} disabledUserId={disabledUserId} />
      </div>
      {!hideBottomBar && (
        <RegistrationButton to={addUserPath}>
          {translations('Register User')}
        </RegistrationButton>
      )}
    </div>
  )
}

UsersSearchScreen.propTypes = {
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
  inModal: PropTypes.bool,
  hideBottomBar: PropTypes.bool,
  onUserClick: PropTypes.func,
  onBackClick: PropTypes.func,
  disabledUserId: PropTypes.string
}

export default withStyles(styles)(UsersSearchScreen)
