import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../../config'
import styles from './style'

const ReportList = ({
  schemas,
  classes,
  categoryModal,
  openModal,
  setNewReportType
}) => (
  <ul className={classes.list}>
    {_.map(schemas, schema => (
      <li
        key={_.get(schema, 'id')}
        className={classes.listItem}
        onClick={() => {
          openModal(() => categoryModal)
          setNewReportType(schema)
        }}
      >
        {!_.get(schema, 'formSchemaId') ? _.get(schema, 'publicName') : _.get(schema, 'name')}
      </li>
    ))}
  </ul>
)

const NewReportModal = ({
  classes,
  formSchemas,
  formViews,
  setNewReportType,
  openModal,
  categoryModal
}) => {
  return (
    <div className={classes.listContainer}>
      <h3>{translations('Select Report Config')}</h3>
      {formSchemas && (
        <ul className={classes.list}>
          <ReportList
            schemas={_.filter(formSchemas, (schema) => !_.isNil(schema))}
            classes={classes}
            categoryModal={categoryModal}
            openModal={openModal}
            setNewReportType={setNewReportType}
          />
        </ul>
      )}
      {formViews && (
        <div>
          <h3>{translations('Custom Reports Title')}</h3>
          <ul className={classes.list}>
            <ReportList
              schemas={formViews}
              classes={classes}
              categoryModal={categoryModal}
              openModal={openModal}
              setNewReportType={setNewReportType}
            />
          </ul>
        </div>
      )}
    </div>
  )
}

NewReportModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  formSchemas: PropTypes.array,
  formViews: PropTypes.array,
  devices: PropTypes.array,
  setNewReportType: PropTypes.func,
  categoryModal: PropTypes.func
}

export default withStyles(styles)(NewReportModal)
