import { selectors as authSelectors } from '../auth'
import { constants as ackDetailsConstants } from '../ackDetails'
import digitalStoreSdk from '../../../digitalStoreSdk'

class AckDetailsActions {
  fetchAckById = ({ id }) => ({
    type: ackDetailsConstants.FETCH_ACK,
    promise: (dispatch, getState) => digitalStoreSdk.acks.fetchAckById({ id })
  })

  submitAck = ({ reportId, fields }) => ({
    type: ackDetailsConstants.SUBMIT_ACK,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.acks.sendAck({ reportId, ackJSON: fields })
    }
  })

  resetStore = () => ({
    type: ackDetailsConstants.RESET_STORE
  })
}

export default new AckDetailsActions()
