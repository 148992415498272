import React, { createContext, useReducer, useEffect, useContext } from 'react'
import { isDevMode } from '../../helpers'
import reducer from './reducers'
import * as actions from './actions'
import * as constants from './constants'
import logging from 'use-reducer-logger'

const initialState = {
  isNewFormView: false,
  formViewId: undefined,
  sidebarOpen: false,
  name: undefined,
  formSchemaId: undefined,
  type: constants.REPORT
}

const reportConfigurationEditorStore = createContext(initialState)
const { Provider } = reportConfigurationEditorStore

const ReportConfigurationEditorProvider = ({ children, name, formSchemaId, type }) => {
  const [state, dispatch] = useReducer(
    isDevMode ? logging(reducer) : reducer,
    initialState
  )
  useEffect(() => {
    dispatch(actions.loadInitialData({ name, formSchemaId, type }))
  }, [name, formSchemaId, type])
  return (
    <Provider
      value={{
        state,
        dispatch,
        actions
      }}>
      {children}
    </Provider>
  )
}

export const useRCEditor = ()=> useContext(reportConfigurationEditorStore)

export default { ReportConfigurationEditorProvider, reportConfigurationEditorStore }
