/**
 * The Constant for fetching the Analytics for the organisation within the analytics view.
 */
export const GET_ORGANISATION_ANALYTICS = 'digitalStore/platformAnalytics/GET_ORGANISATION_ANALYTICS'
/**
 * The Constant for altering the criteria for fetching the report - ie the organisation ID.
 */
export const CHANGE_REPORTS_FILTERS = 'digitalStore/platformAnalytics/CHANGE_ANALYTICS_FILTERS'
/**
 * The Constant for fetching the Analytics export.
 */
export const EXPORT_ANALYTICS = 'digitalStore/platformAnalytics/EXPORT_ANALYTICS'
/**
 * The the Form ID for redux forms, for the modal that requesting a CSV Export.
 */
export const EXPORT_ANALYTICS_FORM = 'digitalStore/formView/EXPORT_ANALYTICS_FORM'