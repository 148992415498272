import React from 'react'
import Button from '../Button'
import { withStyles } from '@material-ui/core/styles'

import style from './style'

const ActionBar = ({ classes, title, buttonTitle, onClick, hideButton, action }) => {
  return (
    <div className={action==='delete'? classes.deleteActionBar:classes.actionBar} role='status'>
      <p className={classes.actionBarHeader}>{title}</p>
      {!hideButton && (
        <Button onClick={onClick} className={action==='delete'?classes.deleteButton: classes.reactivateButton} raised color='primary'>
          {buttonTitle}
        </Button>
      )}
    </div>
  )
}

export default withStyles(style)(ActionBar)
