import React from 'react'
import { useSelector } from 'react-redux'

import NewReport from '../../NewReport'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import P from '../../../../components/P'
import { styled} from '@material-ui/core/styles'
import { translations } from '../../../../config'

const ErrorMessage = styled('div')(({ theme }) => ({
  padding: theme.spacing(2)
}))

const SingleReportScreen = ({
  pageTitle,
  noPermission
}) => {
  const standalonePageTitle = useSelector(platformSelectors.getPageTitle)(pageTitle)
  if (noPermission) {
    return (
      <ErrorMessage>
        <P type={'error'} value={noPermission ? translations('Shared Reports - No Permission') : translations('Shared Reports - No Reports')} />
      </ErrorMessage>
    )
  } else {
    return (
      <NewReport isStandalonePage={true} standalonePageTitle={standalonePageTitle}  />
    )
  }
}


export default SingleReportScreen
