import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import Chip from '../../../../components/Chip'
import { DataTableRow, DataTableCell } from '../../../../components/DataTable'
import { translations } from '../../../../config'

import { handleInviteMessage, handleInviteStatus } from '../handlers'

import styles from './style'

const InvitedUsersRow = ({ classes, entry }) => {
  const {
    key,
    inviteStatus,
    inviteeEmail,
    authorDisplayName,
    createdAt,
    updatedAt
  } = entry

  const statusCharacteristics = handleInviteStatus({ inviteStatus })
  return (
    <DataTableRow className={classes.rowClass} key={key}>
      <DataTableCell>{inviteeEmail}</DataTableCell>
      <DataTableCell>
        <Chip
          variant={statusCharacteristics.chipVariant}
          label={translations(statusCharacteristics.chipLabel)}
        />
      </DataTableCell>
      <DataTableCell compact nowrap>{handleInviteMessage({ inviteStatus, updatedAt, createdAt })}</DataTableCell>
      <DataTableCell compact nowrap>{authorDisplayName}</DataTableCell>
      <DataTableCell compact nowrap>{moment(createdAt).format('DD/MM/YYYY hh:mm')}</DataTableCell>
    </DataTableRow>
  )
}


export default withStyles(styles)(InvitedUsersRow)