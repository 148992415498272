import React, { PureComponent, Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { SubmissionError } from 'redux-form'
import _ from 'lodash'
import { FormBody, FormError } from '../Form'
import SubmittingButton from '../SubmittingButton'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../config'
import ManageContentBox from '../ManageContentBox'

import styles from './style'
import { Collapse, IconButton } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

const FormContentBox = (props) => {
  const {
    boxName,
    classes,
    children,
    isSubmitting,
    isValid,
    editing,
    toggleEdit,
    handleSubmit,
    editable,
    editButtonText = translations('Edit'),
    saveButtonText = translations('Save'),
    saveDisabled = false,
    resetForm,
    destroyForm,
    onCancel,
    onSubmit,
    initialValues,
    error,
    extraProps,
    collapsible
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)

  const onClick = () => {
    if (!editing) {
      toggleEdit()
    }
  }

  const onCancelButtonClick = () => {
    // reset form so changes are not persisted on cancel
    resetForm()
    toggleEdit()
    if (destroyForm) {
      destroyForm()
    }
    if (onCancel) {
      onCancel()
    }
  }

  const submit = (values) => {
    // destroy form will not always exist
    if (editing) {
      const submitResult = onSubmit(values)
      if (submitResult && submitResult.then) {
        return submitResult
          .then(() => {
            // only do these after the submit otherwise it can interfere
            if (destroyForm) {
              destroyForm()
            }
            toggleEdit(false)
          })
          .catch(error => {
            throw new SubmissionError({ _error: error.message })
          })
      } else {
        return submitResult
      }
    }
  }

  const handleRenderChildren = (children) => {
    const content = _.chain([children])
      .flatten()
      .map(child => {
        switch (child.type) {
          case FormBody:
            return React.cloneElement(child, { formName: props.form, editing, initialValues, extraProps, key: 'body', isOpen })
          case FormError:
            return React.cloneElement(child, { error, key: 'error' })
          default:
            return React.cloneElement(child, { editing })
        }
      })
      .value()
    if (collapsible) {
      return (
        <Collapse
          in={isOpen}
          timeout='auto'
        >
          {content}
        </Collapse>
      )
    }
    return content
  }

  const getLeftContentButton = () => {
    if (editing && editable) {
      return (
        <SubmittingButton
          type={'button'}
          raised
          color='primary'
          className={classes.cancelButton}
          onClick={onCancelButtonClick}
        >
          {translations('Cancel')}
        </SubmittingButton>
      )
    }
    if (!editing && collapsible) {
      return (
        <IconButton aria-label={translations(isOpen ? 'Close' : 'Open')} onClick={toggleOpen}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      )
    }
  }

  const getRightContentButton = () => {
    if(collapsible && !isOpen) {
      return null
    }
    return (
      <Fragment>
        {/* Buttons are rendered this way to stop the auto submit bug */}
        {editing && editable &&
          (
            <SubmittingButton
              type={'submit'}
              raised
              color='primary'
              className={classes.editButton}
              disabled={!isValid || saveDisabled}
              isSubmitting={isSubmitting}
              onClick={onClick}
            >
              {saveButtonText}
            </SubmittingButton>
          )
        }
        {!editing && editable &&
          (
            <SubmittingButton
              type={'button'}
              raised
              color='primary'
              className={classes.editButton}
              disabled={!isValid}
              isSubmitting={isSubmitting}
              onClick={onClick}
            >
              {editButtonText}
            </SubmittingButton>
          )
        }
      </Fragment>
    )
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <ManageContentBox
        name={boxName}
        leftButtonContent={getLeftContentButton()}
        rightButtonContent={getRightContentButton()}
        content={handleRenderChildren(children)}
      />
    </form>
  )
}

FormContentBox.propTypes = {
  boxName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),
  editing: PropTypes.bool,
  toggleEdit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  destroyForm: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  error: PropTypes.string,
  initialValues: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editButtonText: PropTypes.string,
  saveButtonText: PropTypes.string,
  saveDisabled: PropTypes.bool,
  extraProps: PropTypes.shape({})
}

export default withStyles(styles)(FormContentBox)
