import React from 'react'
import Checkbox from '../../../../components/Fields/Checkbox'
import PropTypes from 'prop-types'

const EditCheckBox = ({ id, value, togglePermission, payload, classes }) => (
  <Checkbox
    containerClass={classes.checkboxStyle}
    hideLabel
    value={value}
    onClick={e => {
      e.stopPropagation()
      e.preventDefault()
      togglePermission({
        id,
        ...payload
      })
    }}
  />
)

EditCheckBox.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string,
  togglePermission: PropTypes.func,
  payload: PropTypes.object,
  classes: PropTypes.shape({}),
}

export default EditCheckBox
