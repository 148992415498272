import React, { useRef, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import style from './style'
import DecisionCard from './DecisionCard'
import toastService from '../../../../services/toastService'
import RejectionBox from './RejectionBox'
import ApproveBox from './ApproveBox'
import { Collapse } from '@material-ui/core'
import digitalStoreSdk from '../../../../digitalStoreSdk'
import { translations } from '../../../../config'

const ChangeRequest = (props) => {

  const {
    classes,
    changeRequest
  } = props

  const {
    id: changeRequestId,
    organisationName,
    organisationId,
    userId
  } = changeRequest

  const DECISIONS = {
    APPROVE: 'APPROVE',
    REJECT: 'REJECT'
  }

  const [display, setDisplay] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [decision, setDecision] = useState(null)
  const [isDeciding, setIsDeciding] = useState(false)
  const [rejectionMessage, setRejectionMessage] = useState('')

  const message = translations('Change Request - User Request Message', { organisationName, interpolation: { escapeValue: false } })

  const onStep = (type) => {
    setIsOpen(false)
    const isAlreadyOpen = type === decision
    const timeout = isAlreadyOpen ? 500 : getTimeout(type)
    const newDecision = isAlreadyOpen ? null : type
    setTimeout(() => {
      setDecision(newDecision)
      setIsOpen(!isAlreadyOpen)
    }, timeout)
  }

  const getTimeout = (type) => {
    switch (type) {
      case DECISIONS.APPROVE:
        const isRejectOpen = decision === DECISIONS.REJECT
        return isRejectOpen ? 500 : 0
      case DECISIONS.REJECT:
        const isApproveOpen = decision === DECISIONS.APPROVE
        return isApproveOpen ? 500 : 0
    }
  }

  const reset = () => {
    setIsOpen(false)
    setDecision(null)
    setTimeout(() => {
      setDisplay(false)
    }, 500)
  }

  const onApprove = async () => {
    try {
      setIsDeciding(true)
      await digitalStoreSdk.changeRequests.approveChangeRequest({ changeRequestId, organisationId, userId })
      toastService.action({
        type: 'success',
        message: translations('Change Request - Approve Message Success', { organisationName, interpolation: { escapeValue: false } })
      })
      reset()
    } catch (error) {
      setIsDeciding(false)
      toastService.action({
        type: 'error',
        message: error.message
      })
      throw error
    }
  }

  const onReject = async (rejectionMessage) => {
    try {
      setIsDeciding(true)
      await digitalStoreSdk.changeRequests.declineChangeRequest({ changeRequestId, rejectionMessage, organisationId, userId })
      let message = translations('Change Request - Reject Message Success', { organisationName, interpolation: { escapeValue: false } })
      if (rejectionMessage) {
        message = translations('Change Request - Reject Message Success With Message', { organisationName, rejectionMessage, interpolation: { escapeValue: false } })
      }
      toastService.action({
        type: 'success',
        message
      })
      reset()
    } catch (error) {
      setIsDeciding(false)
      toastService.action({
        type: 'error',
        message: error.message
      })
      throw error
    }
  }

  if (!display) {
    return (<></>)
  }

  return (
    <div className={classes.container}>
      <DecisionCard
        classes={classes}
        message={message}
        onApprove={() => onStep(DECISIONS.APPROVE)}
        onReject={() => onStep(DECISIONS.REJECT)}
      />
      <Collapse in={isOpen} timeout={500} className={classes.expandable}>
        <RejectionBox
          classes={classes}
          disabled={isDeciding}
          onReject={onReject}
          visible={decision === DECISIONS.REJECT}
          rejectionMessage={rejectionMessage}
          setRejectionMessage={setRejectionMessage}
        />
        <ApproveBox
          classes={classes}
          disabled={isDeciding}
          onApprove={onApprove}
          visible={decision === DECISIONS.APPROVE}
        />
      </Collapse>
    </div>
  )

}

export default withStyles(style)(ChangeRequest)
