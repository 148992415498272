import React, { useMemo, useRef } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'

import Skeleton from '@material-ui/lab/Skeleton'
import SubHeader from '../../../components/SubHeader'
import ScreenHeading from '../../../components/ScreenHeading'
import EditorLayout from '../../../components/EditorLayout'
import SaveReportConfiguration from './SaveReportConfiguration'
import EditorBackBar from './EditorBackBar'
import ReportConfigurationEditor from '../ReportConfigurationEditor'
import ReportConfigurationSide from '../ReportConfigurationSide'
import ConfigurationMoreMenu from '../ReportConfigurationEditorScreen/ConfigurationMoreMenu/ConfigurationMoreMenu'

import { actions as reportConfigurationActions, hooks as reportConfigurationHooks } from '../../../store/modules/reportConfiguration'
import { hooks as formSchemaHooks } from '../../../store/modules/formSchemas'
import { actions as communicationTemplateActions } from '../../../store/modules/communicationTemplates'
import { actions as sourcesActions } from '../../../store/modules/sources'
import { hooks as authHooks } from '../../../store/modules/auth'
import { useInitialFetch } from '../../../hooks'

import {
  FormViewContext,
  ReportConfigurationEditorContext,
  ReportConfigurationSidebarContext,
  ConditionsContext
} from '../../../contexts'
import { constants as reportConfigurationEditorConstants } from '../../../contexts/ReportConfigurationEditorContext'
import { Can } from '../../../ability/ability-context'

export const FormParamsContext = React.createContext({})
const ReportConfigurationEditorScreen = () => {
  const { id: formViewId } = useParams()
  const organisationId = authHooks.useOrganisation()
  useInitialFetch(() => communicationTemplateActions.fetchOrganisationEmailTemplates({ organisationId }))
  useInitialFetch(() => sourcesActions.fetchOrganisationSources({ organisationId }))

  const isNewFormView = formViewId === 'new'
  let viewJson
  let formViewName
  let formSchemaId
  let formViewType
  let formViewVersionMeta = {}
  const formView = isNewFormView
    ? reportConfigurationHooks.useFormViewEditing()
    : reportConfigurationHooks.useFormViewById(formViewId)
  const isLoading = reportConfigurationHooks.useFormViewsLoading()
  const editor = reportConfigurationHooks.useFormViewEditing()

  if (!isNewFormView) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useInitialFetch(() => reportConfigurationActions.fetchFormViewById({ formViewId }))
    const formView = reportConfigurationHooks.useFormViewById(formViewId)
    viewJson = _.get(formView, 'formViewVersions.0.viewJSON', [])
    formViewName = _.get(formView, 'name')
    formSchemaId = _.get(formView, 'formSchemaId')
    formViewType = _.get(formView, 'type')
    const [schemaLibraryVersion, schemaVersionJsonHash] = _.at(formView, ['formViewVersions.0.schemaLibraryVersion', 'formViewVersions.0.schemaVersionJsonHash'])
    formViewVersionMeta = {
      schemaLibraryVersion,
      schemaVersionJsonHash
    }
  }

  if (isNewFormView && _.split(_.get(editor, 'template'), '.')[0] === 'FORM_VIEW') {
    const id = _.split(_.get(editor, 'template'), '.')[1]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useInitialFetch(() => reportConfigurationActions.fetchFormViewById({ formViewId: id }))
    const formView = reportConfigurationHooks.useFormViewById(id)
    viewJson = _.get(formView, 'formViewVersions.0.viewJSON', [])
    formViewName = _.get(editor, 'name')
    formSchemaId = _.get(formView, 'formSchemaId')
    formViewType = _.get(formView, 'type')
    formViewVersionMeta = {}
  }

  if (isNewFormView && _.split(_.get(editor, 'template'), '.')[0] === 'FORM_SCHEMA') {
    const name = _.split(_.get(editor, 'template'), '.')[1]
    const formSchemaLibrary = new FormSchemaLibrary()
    const standardBase = formSchemaLibrary.getDefaultView(name, null)
    viewJson = standardBase.sections
    formViewName = _.get(editor, 'name')
    formSchemaId = formSchemaHooks.useSchemaByName(name)
    formViewType = reportConfigurationEditorConstants.REPORT
  }

  const { type: formSchemaName } = reportConfigurationHooks.useCurrentFormViewMeta()
  const schemaFields = reportConfigurationHooks.useSchemaFields({ formSchema: formSchemaName })

  const initialConditions = useMemo(() => {
    return _.get(formView, 'formViewVersions.0.conditions')
  }, [formView.formViewVersions])

  const saveRef = useRef(null)
  const handleSave = (showToastMessage) => {
    saveRef.current.handleSave(showToastMessage)
  }

  return (
    <ReportConfigurationEditorContext.ReportConfigurationEditorProvider name={formViewName} formSchemaId={formSchemaId} type={formViewType}>
      <ReportConfigurationSidebarContext.ReportConfigurationSidebarProvider formView={formView}>
        <FormViewContext.FormViewProvider viewJson={viewJson} formViewVersionMeta={formViewVersionMeta}>
          <ConditionsContext.ConditionsProvider formSchemaName={formSchemaName} schemaFields={schemaFields} initialConditions={initialConditions}>
            <EditorLayout
              isLoading={isLoading}
              header={
                <SubHeader
                  leftContent={<EditorBackBar />}
                  centerContent={(isLoading) ? <Skeleton variant='text' width={120} /> : <ScreenHeading label={formViewName || ''} />}
                  rightContent={(
                    <Can I='update' a='reportConfigurationNext'>
                      <SaveReportConfiguration ref={saveRef} />
                      <ConfigurationMoreMenu />
                    </Can>
                  )}
                  isLoading={isLoading}
                />
              }
              main={<ReportConfigurationEditor formViewId={formViewId} handleSave={handleSave} />}
              side={<ReportConfigurationSide formViewId={formViewId} />}
            />
          </ConditionsContext.ConditionsProvider>
        </FormViewContext.FormViewProvider>
      </ReportConfigurationSidebarContext.ReportConfigurationSidebarProvider>
    </ReportConfigurationEditorContext.ReportConfigurationEditorProvider>
  )
}

export default ReportConfigurationEditorScreen
