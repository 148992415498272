export default theme => ({
  deleteButton: {
    backgroundColor: 'red',
    color: 'white',
    '&:hover, &:active, &:focus': {
      backgroundColor: 'red',
      color: 'white'
    },
  }
})
