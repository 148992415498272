import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import QueryBuilder from 'react-querybuilder'

import RemoveIcon from '@material-ui/icons/Delete'

import CombinatorSelector from '../CombinatorSelector'
import ConditionsActionButton from '../ConditionsActionButton'
import ValueSelector from '../ValueSelector'
import FieldSelector from '../FieldSelector'
import ValueEditor from '../ValueEditor'
import ConditionsRule from '../ConditionsRule'

import { styled } from '@material-ui/core/styles'
import { translations } from '../../../config'
import withAdditionalPropsNonOverriding from '../../../hocs/withAdditionalPropsNonOverriding'

const Container = styled('div')(({ theme }) => ({
  '& .ruleGroup': {
    padding: theme.spacing(2),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[100],
    borderRadius: '4px',
    background: 'rgba(189, 189, 189, 0.2)'
  },
  '& .ruleGroup-header': {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: 'flex',
    '& button': {
      marginRight: theme.spacing(1)
    }
  },
  '& .rule-operators': {
    maxWidth: '110px'
  },
  '& .rule': {
    alignItems: 'flex-end'
  }
}))

/**
 * Visual conditions builder
 */
export const ConditionsBuilder = (props) => {
  const { fields, onQueryChange, query, forceEquals } = props
  const queryPayload = _.isObject(query) ? query : undefined
  const WrappedValueSelector = withAdditionalPropsNonOverriding({ forceEquals })(ValueSelector)

  return (
    <Container>
      <QueryBuilder
        query={queryPayload}
        fields={fields}
        context={{}}
        operators={[
          { name: 'equal', label: '=' },
          { name: 'notEqual', label: '!=' },
          { name: 'lessThan', label: '<' },
          { name: 'greaterThan', label: '>' },
          { name: 'lessThanInclusive', label: '<=' },
          { name: 'greaterThanInclusive', label: '>=' },
          { name: 'stringContains', label: 'Contains' },
          { name: 'stringDoesNotContain', label: '!Contains' },
          { name: 'isEmpty', label: 'Empty' },
          { name: 'isNotEmpty', label: '!Empty' },
          { name: 'isBetween', label: 'Between' },
          { name: 'isNotBetween', label: '!Between' },
          { name: 'inList', label: 'In List' },
          { name: 'notInList', label: '!In List' },
          { name: 'startsWith', label: 'Starts With' },
          { name: 'endsWith', label: 'Ends With' },
          { name: 'inFuture', label: 'In Future' },
          { name: 'inPast', label: 'In Past' }
        ]}
        combinators={[
          { name: 'and', label: 'AND' },
          { name: 'or', label: 'OR' }
        ]}
        showCombinatorsBetweenRules={true}
        controlElements={{
          combinatorSelector: CombinatorSelector,
          addRuleAction: (props) => <ConditionsActionButton {...props} />,
          addGroupAction: (props) => <ConditionsActionButton {...props} />,
          removeRuleAction: (props) => <ConditionsActionButton {...props} icon={RemoveIcon} />,
          removeGroupAction: (props) => <ConditionsActionButton {...props} icon={RemoveIcon} />,
          fieldSelector: FieldSelector,
          operatorSelector: WrappedValueSelector,
          rule: ConditionsRule,
          valueEditor: ValueEditor
        }}
        onQueryChange={onQueryChange}
        translations={{
          fields: {
            title: translations('Fields')
          },
          operators: {
            title: translations('Operators')
          },
          value: {
            title: translations('Value')
          },
          removeRule: {
            label: translations('Remove Rule'),
            title: translations('Remove Rule')
          },
          removeGroup: {
            label: translations('Remove Group'),
            title: translations('Remove Group')
          },
          addRule: {
            label: translations('Add Rule'),
            title: translations('Add Rule')
          },
          addGroup: {
            label: translations('Add Group'),
            title: translations('Add Group')
          },
          combinators: {
            title: translations('Combinators')
          },
          notToggle: {
            title: translations('Invert this group')
          }
        }}
      />
    </Container>
  )
}

ConditionsBuilder.propTypes = {
  fields: PropTypes.array.isRequired,
  onQueryChange: PropTypes.func,
  forceEquals: PropTypes.bool
}

export default ConditionsBuilder
