import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class TagsActions {
fetchTags = ({ resources, organisationIds } = {}) => ({
    type: constants.FETCH_TAGS,
    resources,
    organisationIds,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.tags.fetchTags({ resources, organisationIds })
    }
  })
}

export default new TagsActions()
