import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Input from '../../../../../components/Fields/Input'
import { IconButton } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import EditSectionModal from '../../Modals/EditSectionModal'
import EditIcon from '@material-ui/icons/Edit'
import ViewIcon from '@material-ui/icons/RemoveRedEye'
import Form from '../../../../../components/Form'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../../../../config'
import TooltipDetails from '../../TooltipDetails'
import Button from '../../../../../components/Button'
import { constants as formViewConstants } from '../../../../../store/modules/formViews'
import Repeatable from './index'

import styles from './style'

const RepeatableForm = Form(formViewConstants.REPEATABLE_CONFIGURATION_FORM)

const RepeatableModal = ({
  classes,
  closeModal,
  fields,
  editRepeatables,
  toggleVisibility,
  moveQuestion,
  newQuestionText,
  setNewQuestionText,
  questionNames,
  addQuestion,
  name,
  openModal,
  configureField,
  isHeading,
  sectionId,
  repeatableId,
  schemaFields,
  repeatableNestedSections,
  allNestedSections,
  error
}) => {
  const sectionLength = fields.length
  const getSchemaFields = _.get(schemaFields, 'props.schema')
  return (
    <RepeatableForm
    >
      <div className={classes.listContainer}>
        <h3>{name}</h3>
        <p style={{ marginBottom: '1rem' }}>
          {translations('Edit Repeatables')}
        </p>
        {_.map(fields, (field, key) => {
          const mandatory = _.get(field, 'mandatory')
          const displayName = _.get(field, 'displayName')
          const hideFromForm = _.get(field, 'hideFromForm')
          const isForAssessment = _.get(field, 'isForAssessment')
          const isForThromboticEvents = _.get(field, 'isForThromboticEvents')
          const isForMyocarditis = _.get(field, 'isForMyocarditis')
          const lookupOptions = _.get(field, 'lookupOptions')
          const helperText = _.get(field, 'helperText')
          const startYear = _.get(field, 'startYear')
          const restrictFreeformEntry = _.get(field, 'restrictFreeformEntry')
          const visible = _.get(field, 'visible')
          const isCustom = _.get(field, 'isCustom')
          const isLimitedToYesNoUnk = _.get(field, 'isLimitedToYesNoUnk')
          const disableDefaultMapping = _.get(field, 'disableDefaultMapping')
          const dropdownOptions = _.get(field, 'dropdownOptions')
          const answerMap = _.get(field, 'answerMap')
          const matchField = _.find(
            getSchemaFields,
            (schemaField) => schemaField.id === field.id
          )
          const nestedFields = _.get(field, 'fields')
          const requiredProp = _.get(matchField, 'props.required')
          const fieldType = _.get(matchField, 'field')
          return (
            <div className={classes.formContainer}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                {displayName && (
                  <TooltipDetails
                    label={translations('Report Configuration - Display name')}
                    title={displayName}
                  />
                )}
                <Input
                  value={field.name}
                  style={{ width: '100%' }}
                  className={classes.inputStyle}
                  disabled
                />
                <div className={classes.editWrapper}>
                  {_.size(nestedFields) > 0 && (
                    <IconButton
                      size='small'
                      className={classes.button}
                      style={{ color: 'black' }}
                      onClick={() => {
                        openModal(() => {
                          return (
                            <Repeatable
                              classes={classes}
                              openModal={openModal}
                              closeModal={closeModal}
                              editRepeatables={editRepeatables}
                              toggleVisibility={toggleVisibility}
                              moveQuestion={moveQuestion}
                              name={field.name}
                              configureField={configureField}
                              isHeading={isHeading}
                              sectionId={sectionId}
                              repeatableId={repeatableId}
                              schemaFields={{}}
                              fields={nestedFields}
                              repeatableNestedSections={[...repeatableNestedSections, field.id]}
                              allNestedSections={allNestedSections}
                              initialValues={{
                                displayName,
                                mandatory: !_.isNil(mandatory)
                                  ? mandatory
                                  : requiredProp
                              }}
                              wideModal
                            />
                          )
                        })
                      }}
                    >
                      <EditIcon fontSize='inherit' />
                    </IconButton>
                  )}

                  <IconButton
                    size='small'
                    className={classes.button}
                    style={{ color: 'black' }}
                    onClick={() => {
                      toggleVisibility({
                        fields,
                        clone: true,
                        visible,
                        nestedSections: [field.id]
                      })
                    }}
                  >
                    <ViewIcon fontSize='inherit' />
                    {!visible && <span className={classes.notVisible} />}
                  </IconButton>
                </div>

                {sectionLength !== 1 && (
                  <div className={classes.arrowWrapper}>
                    {key === 0 ? (
                      <div />
                    ) : (
                        <IconButton
                          size='small'
                          className={classes.button}
                          style={{ color: 'black' }}
                          onClick={() => {
                            moveQuestion({ fields, questionKey: key, direction: 'up' })
                          }}
                        >
                          <ArrowUpwardIcon fontSize='inherit' />
                        </IconButton>
                      )}
                    {key !== sectionLength - 1 && (
                      <IconButton
                        size='small'
                        className={classes.button}
                        style={{ color: 'black' }}
                        onClick={() => {
                          moveQuestion({ fields, questionKey: key, direction: 'down' })
                        }}
                      >
                        <ArrowDownwardIcon fontSize='inherit' />
                      </IconButton>
                    )}
                  </div>
                )}
                <IconButton
                  size='small'
                  className={classes.button}
                  style={{ color: 'black' }}
                  onClick={() => {
                    openModal(() => (
                      <EditSectionModal
                        title={field.name}
                        initialValues={{
                          mandatory: mandatory || requiredProp,
                          displayName,
                          hideFromForm,
                          isForAssessment,
                          isForThromboticEvents,
                          isForMyocarditis,
                          helperText,
                          startYear,
                          restrictFreeformEntry,
                          isLimitedToYesNoUnk,
                          disableDefaultMapping,
                          dropdownOptions,
                          answerMap,
                          lookupOptions: {
                            ...lookupOptions
                          }
                        }}
                        fieldType={fieldType}
                        isQuestion={true}
                        isCustom={isCustom}
                        onCallback={(values) =>
                          configureField({
                            nestedSections: [...allNestedSections, ...repeatableNestedSections, field.id],
                            details: values
                          })
                        }
                      />
                    ))
                  }}
                  aria-label={
                    isHeading
                      ? translations(
                        'Report Configuration - Configure Question'
                      )
                      : translations(
                        'Report Configuration - Configure Question'
                      )
                  }
                >
                  <SettingsIcon fontSize='inherit' />
                </IconButton>
              </div>
            </div>
          )
        })}
        <div className={classes.addQestionContainer}>
          <Input
            value={newQuestionText}
            onChange={(e) => {
              setNewQuestionText({ questionText: e, questionNames })
            }}
            style={{ width: '100%' }}
            className={classes.inputStyle}
            placeholder={translations('Report Configuration - Add New Repeatable Question Placeholder')}
          />
          <Button
            raised
            className={classes.addQuestionButton}
            onClick={() => {
              if (error) return
              addQuestion({
                fields,
                nestedSections: [...allNestedSections, ...repeatableNestedSections],
              })
            }}
          >
            {translations('Report Configuration - Add New Repeatable Question')}
          </Button>
        </div>
        {error && <div className={classes.errorText}>{translations('Error - Must Be A Unique Name')}</div>}
        <div className={classes.buttonContainer}>
          <Button raised buttonType='white' onClick={() => closeModal()}>
            {translations('Cancel')}
          </Button>
          <Button
            raised
            buttonType='primary'
            onClick={() => editRepeatables({ fields, nestedSections: [...allNestedSections, ...repeatableNestedSections] })}
          >
            {translations('Confirm')}
          </Button>
        </div>
      </div>
    </RepeatableForm>
  )
}

RepeatableModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func,
  fields: PropTypes.array,
  editRepeatables: PropTypes.func,
  toggleVisibility: PropTypes.func,
  moveQuestion: PropTypes.func,
  sectionKey: PropTypes.number,
  questionKey: PropTypes.number,
  name: PropTypes.string
}

export default withStyles(styles)(RepeatableModal)
