import { connect } from 'react-redux'
import { compose } from 'recompose'

import ResetPasswordScreen from './ResetPasswordScreen'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

const enhancer = compose(
  connect(state => ({
    pageTitle: platformSelectors.getPageTitle(state)('Reset password Title')
  }))
)

export default enhancer(ResetPasswordScreen)
