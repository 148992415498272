import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { submit } from 'redux-form'

import { withStyles } from '@material-ui/core/styles'

import Form, { FormBody } from '../../../../../../components/Form'
import BasicModalOverlay from '../../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../../../../config'
import { constants as submissionReportConstants } from '../../../../../../store/modules/submissionReports'

import style from './style'
import schema from './schema'

const formId = submissionReportConstants.EDITOR_MODAL
const FilterEditorModalForm = Form(formId)

const FilterEditorModal = (props) => {
  const {
    onSubmit,
    dismiss,
    initialValues,
    classes,
    isNew
  } = props
  const dispatch = useDispatch()

  return (
    <BasicModalOverlay
      title={isNew ? translations('Create New Saved Search') : translations('Update Saved Search')}
      actions={[
        {
          text: translations('Save'),
          primary: true,
          success: true,
          onClick: () => {
            dispatch(submit(formId))
          }
        },
        {
          text: translations('Cancel'),
          onClick: dismiss
        }
      ]}
    >
      <FilterEditorModalForm onSubmit={onSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <div className={classes.container}>
            <FormBody
              schema={schema}
              editing
              fullWidthFields
            />
          </div>
        </ContentBoxBody>
      </FilterEditorModalForm>
    </BasicModalOverlay>
  )
}

FilterEditorModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  schema: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object
}

export default withStyles(style)(FilterEditorModal)
