import {
  compose,
  withHandlers,
  withStateHandlers,
  withPropsOnChange,
  withProps
} from 'recompose'

import RepeatableModal from './RepeatableModal'
import modalService from '../../../../../services/modalService'
import _ from 'lodash'

const recurseSection = ({viewJSON, nestedSections}) => {
  let sectionIndexs = []
  _.forEach(nestedSections, (section, index) => {
    const currentSection = _.size(sectionIndexs) ? _.get(viewJSON, _.join(sectionIndexs, '.')) : viewJSON
    const sectionIndex = _.findIndex(currentSection, item => item.id === section)
    const determineSuffix = nestedSections.length-1 === index ? '' : '.fields'
    sectionIndexs.push(`${sectionIndex}${determineSuffix}`)
  })
  return sectionIndexs
}

const enhancer = compose(
  withProps(props => {
    const {questionNames} = props
    return questionNames
  }),
  withPropsOnChange(['fields', 'newQuestionText'], (props) => {
    const { fields, newQuestionText } = props
    return { fields, newQuestionText }
  }),
  withStateHandlers(
    {
      newQuestionText: ''
    },
    {
      toggleVisibility: () => ({fields, visible, nestedSections}) => {
        const cloneFields = _.cloneDeep(fields)
        const currentState = [...cloneFields]
        const sectionIndexs = recurseSection({viewJSON: cloneFields, nestedSections})
        _.set(currentState, `${sectionIndexs.join('.')}.visible`, !visible)
        return {
          fields: currentState
        }
      },
      moveQuestion: () => ({fields, questionKey, direction}) => {
        const cloneFields = _.cloneDeep(fields)
        const currentState = [...cloneFields]
        const moveTo = direction === 'up' ? questionKey-1 : questionKey+1
        const current = currentState.splice(questionKey, 1)[0];
        currentState.splice(moveTo, 0, current);
        return {
          fields: currentState
        }
      },
      setNewQuestionText: () => ({ questionText, questionNames }) => {
        let error = false
        const checkExists = questionText && questionNames().find(el => el && el.toLowerCase() === questionText.toLowerCase())
        if (checkExists){
          error = true
        }
        return {
          newQuestionText: questionText,
          error
        }
      },
      addQuestion: ({ newQuestionText }) => ({ fields, nestedSections }) => {
        if (newQuestionText){
          const cloneFields = _.cloneDeep(fields)
          const currentState = [...cloneFields]
          const id = _.camelCase(newQuestionText)
          const newField = {
              'id': id,
              'name': newQuestionText,
              'visible': true,
              'isCustom': true,
              'nestedSections': _.drop(nestedSections).join('.')
          }
          currentState.push(newField)
          return {
            fields: currentState,
            newQuestionText: ''
          }
        }
      }
    }
  ),
  withHandlers({
    openModal: () => component => {
      modalService.open({
        component
      })
    },
    closeModal: () => () => {
      modalService.close()
    }
  })
)

export default enhancer(RepeatableModal)