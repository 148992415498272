import _ from 'lodash'
import { promiseReducer } from '../../util'

class SubmissionsReducers {
  searchFresh = promiseReducer(
    (state, action) => {
      const { query, dateFrom, dateTo } = action
      const results = _.get(action, 'result.results')
      const total = _.get(action, 'result.total')
      return {
        ...state,
        page: 1,
        total,
        results,
        query,
        dateFrom,
        dateTo
      }
    }
  )

  searchNext = promiseReducer(
    (state, action) => {
      const { results } = state
      const { query, dateFrom, dateTo } = action
      const newResults = _.get(action, 'result.results')
      const total = _.get(action, 'result.total')
      return {
        ...state,
        total,
        page: state.page + 1,
        results: [ ...results, ...newResults ],
        query,
        dateFrom,
        dateTo
      }
    }
  )

  changeFilter = (state, action) => {
    const { filter } = action
    const { filters } = state
    return {
      ...state,
      filters: {
        ...filters,
        [filter.name]: filter.value
      }
    }
  }

  changeFilters = (state, action) => {
    const { filters } = action
    return {
      ...state,
      filters
    }
  }
}

export default new SubmissionsReducers()
