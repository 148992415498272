import React from 'react'
import ContentBox from '../../ContentBox'
import ContentBoxHeader from '../../ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../ContentBox/ContentBoxBody'
import TextContent from '../../Form/TextContent'
import Heading from '../../Heading'
import { FormBody } from '../../Form'

const FormSection = ({ title, schema }) => {
  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading>{title}</Heading>
        </TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <FormBody
          schema={schema}
          editing
        />
      </ContentBoxBody>
    </ContentBox>
  )
}

export default FormSection
