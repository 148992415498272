import React from 'react'
import Editable from '../Editable'
import { OptionArray } from '../../Fields'
import P from '../../P'

export default Editable({
  input: (props) => (
    <OptionArray {...props} />
  ),
  display: ({ label, value }) => {
    const modifiedValue = value instanceof Array ? value.join(', ') : value
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={modifiedValue} />
      </div>
    )
  }
})