import { colors } from '../../../config/theme'

const styles = {
  floatingLabelFocusStyle: { color: colors.accent },
  smallLabel: { fontSize: '0.75em' },
  disabled: { opacity: 0.33 },
  errorStyle: { color: colors.error }
}

export default theme => styles
