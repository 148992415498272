import { selectReportConstants } from './constants'

export const selectReportReducer = (state, action) => {
  switch(action.type) {
    case selectReportConstants.SELECT_REPORT_TYPE:
      const { selectedReport, hasSelectedMirOrFsca } = action.payload
      return {
        ...state,
        selectedReport,
        hasSelectedMirOrFsca
      }
    case selectReportConstants.SELECT_ORGANISATION:
      const { selectedOrganisation } = action.payload
      return {
        ...state,
        selectedOrganisation
      }
    case selectReportConstants.CLEAR_ORGANISATION:
      return {
        ...state,
        selectedOrganisation: undefined
      }
    case selectReportConstants.SELECT_PRODUCT:
      const { selectedProduct } = action.payload
      return {
        ...state,
        selectedProduct
      }
    case selectReportConstants.SET_PRODUCTS:
      const { products, productOptions } = action.payload
      return { 
        ...state,
        products,
        productOptions
      }
    case selectReportConstants.CLEAR_PRODUCT:
      return {
        ...state,
        selectedProduct: undefined
      }
    case selectReportConstants.CLEAR_PRODUCTS:
      return {
        ...state,
        products: undefined,
        productOptions: undefined
      }
  }
}