import _ from 'lodash'
import { promiseReducer } from '../../util'

class HospitalsUKReducers {
  fetchAllHospitals = promiseReducer(
    (state, action) => {
      return {
        ...state,
        hospitals: _.get(action, 'result')
      }
    }
  )

  searchHospitals = promiseReducer(
    (state, action) => {
      const results = _.get(action, 'result.rows')
      const total = _.get(action, 'result.count')
      return {
        ...state,
        results,
        total,
        page: 1
      }
    }
  )

  searchHospitalsNext = promiseReducer(
    (state, action) => {
      const { page, results } = state
      const newResults = _.get(action, 'result.rows')
      const total = _.get(action, 'result.count')

      return {
        ...state,
        results: [...results, ...newResults],
        total,
        page: page + 1
      }
    }
  )
}

export default new HospitalsUKReducers()
