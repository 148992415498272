import React, { Fragment, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

import { translations } from '../../../config'
import { hooks as reportConfigurationHooks, actions as reportConfigurationActions } from '../../../store/modules/reportConfiguration'
import InsertButton from '../../../components/FormviewEditor/InsertButton'
import modalService from '../../../services/modalService'
import toastService from '../../../services/toastService'
import CohortItem from './CohortItem'
import CohortModal from './CohortModal'

const toastError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Save Cohort')
})
const toastSuccess = () => toastService.action({
  type: 'success',
  message: translations('Success - Save Cohort')
})

const AddCohortButton = ({
  onSubmit,
  otherCohortNames
}) => {
  const openNewCohortModal = () => {
    modalService.open({
      title: translations('Cohorts - Add New Cohort'),
      component: CohortModal,
      disableBackdropClick: true,
      onSubmit,
      closeModal: () => modalService.close(),
      otherCohortNames
    })
  }
  return (
    <InsertButton
      fullWidth
      size='large'
      onClick={openNewCohortModal}
    >
      {translations('Cohorts - Add New Cohort')}
    </InsertButton>

  )
}

const ReportConfigurationCohortsPanel = ({ formViewId }) => {
  const cohorts = reportConfigurationHooks.useFormViewCohorts({ formViewId })
  const otherCohortNames = _.map(cohorts, ({ name }) => name)

  const dispatch = useDispatch()

  useEffect(() => {
    if (formViewId) {
      dispatch(reportConfigurationActions.searchCohortsFresh({ formViewId }))
    }
  }, [])

  const addCohort = async (params) => {
    try {
      const res = await dispatch(reportConfigurationActions.createCohort({ params, formViewId }))
      modalService.close()
      toastSuccess()
      dispatch(reportConfigurationActions.searchCohortsFresh({ formViewId }))
      return res
    } catch (err) {
      toastError()
    }
  }

  return (
    <Fragment>
      {_.map(cohorts, cohort => (<CohortItem key={cohort.name} {...cohort} otherCohortNames={_.without(otherCohortNames, cohort.name)} />))}
      <AddCohortButton
        otherCohortNames={otherCohortNames}
        onSubmit={addCohort}
      />
    </Fragment>
  )
}

export default ReportConfigurationCohortsPanel
