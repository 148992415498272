import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isDirty, submit } from 'redux-form'
import { IconButton } from '@material-ui/core'
import { ArrowLeft } from '@material-ui/icons'

import ContentBox from '../../../components/ContentBox'
import ContentBoxHeader from '../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../components/Form/TextContent'
import Heading from '../../../components/Heading'
import PlatformAppsTable from './components/PlatformAppsTable'
import LeftButtonContent from '../../../components/Form/LeftButtonContent'
import Button from '../../../components/Button'
import RightButtonContent from '../../../components/Form/RightButtonContent'
import PlatformAppTable, { PLATFORM_APP_FORM_NAME } from './components/PlatformAppTable'
import { getPlatformApps } from '../../../store/modules/pwa'
import { createPlatformApp } from '../../../store/modules/pwa/asyncActions'
import { getCurrentPlatformId } from '../../../store/modules/platformDetails/selectors'
import { translations } from '../../../config'

const PlatformApps = () => {
  const dispatch = useDispatch()
  const [selectedApp, setSelectedApp] = useState()
  const platformApps = useSelector(getPlatformApps)
  const platformId = useSelector(getCurrentPlatformId)

  const isAppFormDirty = useSelector(isDirty(PLATFORM_APP_FORM_NAME))
  const handleSubmit = () => {
    dispatch(submit(PLATFORM_APP_FORM_NAME))
  }

  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading>{translations('Platform Management - Platform Apps')}</Heading>
        </TextContent>
        <LeftButtonContent>
          {
            selectedApp && (
              <IconButton
                aria-label={translations('Go Back')}
                onClick={() => setSelectedApp(null)}
              >
                <ArrowLeft />
              </IconButton>
            )
          }
        </LeftButtonContent>
        <RightButtonContent>
          {
            selectedApp ? (
              <Button
                buttonType='primary'
                disabled={!isAppFormDirty}
                onClick={handleSubmit}
              >
                {translations('Save')}
              </Button>
            ) : (
              <Button
                buttonType='primary'
                onClick={() => dispatch(createPlatformApp({ platformId }))}
              >
                {translations('Platform Apps - Create App')}
              </Button>
            )
          }
        </RightButtonContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        {
          selectedApp ? (
            <PlatformAppTable
              id={selectedApp}
              goBack={() => setSelectedApp(null)}
            />
          ) : (
            <PlatformAppsTable
              platformApps={platformApps}
              setSelectedApp={setSelectedApp}
            />
          )
        }
      </ContentBoxBody>
    </ContentBox >
  )
}

export default PlatformApps