import React from 'react'
import { useSelector } from 'react-redux'

import { styled, Typography } from '@material-ui/core'

import Heading from '../../../../../../components/Heading'

import { translations } from '../../../../../../config'
import { useQuery } from '../../../../../../hooks'
import { selectors as formSchemaSelectors } from '../../../../../../store/modules/formSchemas'


const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))
const Header = styled('div')(({ theme }) => ({
  paddingBottom: '5px'
}))
const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))

const Block = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(3)
}))

const IntegrationManagementEditReportScreenGeneralSidePanel = () => {
  const { formSchemaId } = useQuery()
  const { friendlyName } = useSelector(formSchemaSelectors.getSchemaById(formSchemaId))
  return (
    <Wrapper>
      <Block>
        <Header>
          <Heading variant='h3' component='h3'>{translations('Reporting Form')}</Heading>
        </Header>
        <Content>
          <Typography variant={'body2'}>{friendlyName}</Typography>
        </Content>
      </Block>
    </Wrapper>
  )
}

export default IntegrationManagementEditReportScreenGeneralSidePanel
