import _ from 'lodash'
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import logging from 'use-reducer-logger'
import reducer from './reducer'
import * as actions from './actions'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { isDevMode } from '../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getUserSelectedOrganisationId } from '../../../store/modules/auth/selectors'
import { push } from 'connected-react-router'
import { useQuery } from '../../../hooks'
import { useParams } from 'react-router'
import { getFormSyncErrors } from 'redux-form'
import { SETTINGS_FORM_NAME } from '../../../store/modules/websiteManagement/constants'
import { FAIL, PENDING, SUCCESS } from './constants'

const initialState = {
  editedFields: {},
  isEdited: false,
  isSaving: false,
  settings: {
    name: undefined,
    description: undefined,
    domain: undefined,
    published: undefined,
    themeId: undefined,
    showPartnershipLogo: undefined
  },
  themeOptions: [],
  content: [],
  page: {},
  isSavingPage: false,
  overlayOpen: false
}

const websiteManagementStore = createContext(initialState)
const { Provider } = websiteManagementStore

const WebsiteManagementProvider = (props) => {
  const {
    children,
    selectedWebsite
  } = props

  const [state, dispatch] = useReducer(
    isDevMode ? logging(reducer) : reducer,
    initialState
  )
  const query = useQuery()
  const params = useParams()
  const reduxDispatch = useDispatch()

  const currentWebsiteId = _.get(params, 'id')
  const organisationId = useSelector(getUserSelectedOrganisationId)
  const settingsFormErrors = useSelector(getFormSyncErrors(SETTINGS_FORM_NAME))

  useEffect(() => {
    fetchOrganisationThemes()
  }, [])

  useEffect(() => {
    if (selectedWebsite) {
      dispatch(actions.useExistingWebsite({ selectedWebsite }))
    }
  }, [selectedWebsite])

  const updateSettings = ({ fieldName, value }) => {
    dispatch(actions.updateSettings({ fieldName, value }))
  }

  const refresh = ({ id, reload }) => {
    const currentTab = _.get(query, 'currentTab')
    reduxDispatch(push(`/website-management/${id}?currentTab=${currentTab}`))
    if (reload) {
      window.location.reload(false)
    }
  }

  const fetchOrganisationThemes = async () => {
    const themes = await digitalStoreSdk.organisations.fetchOrganisationThemes({ id: organisationId })
    dispatch(actions.setThemeOptions({ themes }))
  }

  const createWebsite = async () => {
    const data = {
      ...state.editedFields,
      organisationId
    }
    try {
      dispatch(actions.createWebsite({ status: PENDING }))
      const result = await digitalStoreSdk.website.createWebsite({ data })
      dispatch(actions.createWebsite({ status: SUCCESS }))
      refresh({ id: result.id, reload: true })
    } catch (error) {
      dispatch(actions.createWebsite({ status: FAIL }))
      throw error
    }
  }

  const updateWebsite = async () => {
    const data = state.editedFields
    try {
      dispatch(actions.updateWebsite({ status: PENDING }))
      await digitalStoreSdk.website.updateWebsite({ id: currentWebsiteId, data })
      dispatch(actions.updateWebsite({ status: SUCCESS }))
      refresh({ id: currentWebsiteId })
    } catch (error) {
      dispatch(actions.updateWebsite({ status: FAIL }))
      throw error
    }
  }

  const fetchPage = async ({ id }) => {
    try {
      dispatch(actions.fetchPage({ status: PENDING }))
      const page = await digitalStoreSdk.website.fetchPage({ id })
      dispatch(actions.fetchPage({ page, status: SUCCESS }))
    } catch (error) {
      dispatch(actions.fetchPage({ status: FAIL }))
      throw error
    }
  }

  const updatePage = async ({ id, values }) => {
    try {
      dispatch(actions.updatePage({ status: PENDING }))
      digitalStoreSdk.pages.updatePageById({ id, ...values })
      dispatch(actions.updatePage({ status: SUCCESS }))
    } catch (error) {
      dispatch(actions.updatePage({ status: FAIL }))
      throw error
    }
  }

  const togglePageVisiblity = ({ id }) => {
    dispatch(actions.togglePageVisiblity({ id }))
  }

  const toggleOverlay = ({ status }) => {
    dispatch(actions.toggleOverlay({ status }))
  }

  return (
    <Provider
      value={{
        state,
        dispatch,
        updateSettings,
        createWebsite,
        updateWebsite,
        fetchPage,
        updatePage,
        togglePageVisiblity,
        toggleOverlay,
        fetchOrganisationThemes,
        settingsFormErrors
      }}
    >
      {children}
    </Provider>
  )
}

export const useWebsites = () => useContext(websiteManagementStore)

export default { WebsiteManagementProvider, websiteManagementStore }
