export const CREATE_USER = 'digitalStore/user/CREATE_USER'
export const REGISTER_USER = 'digitalStore/user/REGISTER_USER'
export const FETCH_USER_DETAILS = 'digitalStore/user/FETCH_USER_DETAILS'
export const FETCH_CURRENT_USER_DETAILS = 'digitalStore/user/FETCH_CURRENT_USER_DETAILS'
export const UPDATE_USER = 'digitalStore/user/UPDATE_USER'
export const CHANGE_USER_PASSWORD = 'digitalStore/user/CHANGE_USER_PASSWORD'
export const CHANGE_CURRENT_USER_PASSWORD = 'digitalStore/user/CHANGE_CURRENT_USER_PASSWORD'
export const USER_ACCOUNT_STATUS_TOGGLE = 'digitalStore/user/USER_ACCOUNT_STATUS_TOGGLE'
export const FETCH_USER_ORGANISATION_PREFERENCES_COUNT = 'digitalStore/user/FETCH_USER_ORGANISATION_PREFERENCES_COUNT'
export const FETCH_USER_ORGANISATION_PREFERENCES = 'digitalStore/user/FETCH_USER_ORGANISATION_PREFERENCES'
export const UPDATE_USER_ORGANISATION_PREFERENCES = 'digitalStore/user/UPDATE_USER_ORGANISATION_PREFERENCES'
export const RESET_STORE = 'digitalStore/user/RESET_STORE'

export const USER_ORGANISATIONS_FORM_NAME = 'user-organisations-form'