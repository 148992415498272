import React from 'react'
import DataTable from '../../../../components/DataTable'
import DictionaryEntryRow from '../DictionaryEntryRow'
import ListViewLoadMore from '../../../../components/ListViewLoadMore'
import { translations } from '../../../../config'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from '../../../../store/modules/terminology'
import * as hooks from './hooks'
import EmptyResultBanner from '../../../../components/EmptyResultBanner'
import fp from 'lodash/fp'
const headers = [
    { id: 'term', label: translations('Terminology Table Header - Term') },
    { id: 'definition', label: translations('Terminology Table Header - Definition') },
    { id: 'dictionaryName', label: translations('Terminology Table Header - Dictionary')},
    { id: 'action', label: translations('Terminology Table Header - Incoming Report Action') },
    { id: 'createdAt', label: translations('Terminology Table Header - Created At') },
    { id: 'options', label: translations('Terminology Table Header - Options'), options: { align: 'right', visuallyHidden: true }},
    { id: 'delete', label: translations('Terminology Table Header - Delete'), options: { align: 'right', visuallyHidden: true }}
]

const TerminologyEntriesTable = (props) => {
    const activeArrangementId = useSelector(selectors.getActiveArrangementId)
    const filters = useSelector(selectors.genericSelectors.getMasterFilters)
    const pagination = useSelector(selectors.genericSelectors.getMasterPagination)
    const canLoadMore = useSelector(selectors.genericSelectors.getCanLoadMore)
    const showDictionaryColumn = useSelector(selectors.doesArrangementHaveSecondaryDictionaries)
    const showDefinitionColumn = useSelector(selectors.doesArrangementUseDefinitions)
    const isUsageTypeAction = useSelector(selectors.isUsageTypeAction)
    const tableHeaders = React.useMemo(() => {
        return fp.compose(
            (headers) => showDictionaryColumn ? headers : fp.reject({ id: 'dictionaryName'}, headers),
            (headers) => showDefinitionColumn ? headers : fp.reject({ id: 'definition'}, headers),
            (headers) => isUsageTypeAction ? headers : fp.reject({ id: 'action'}, headers),
            fp.clone
        )(headers)
    }, [showDictionaryColumn, showDefinitionColumn, isUsageTypeAction])
    const dispatch = useDispatch()

    const entity = useSelector(selectors.genericSelectors.getMaster)
    const loadMore = hooks.useLoadMore({ dispatch })
    hooks.useClearEntriesOnActiveArrangementIdChange({
        dispatch,
        activeArrangementId,
        entity
    })
    hooks.useGetTerminologyArrangementEntries({
        dispatch,
        activeArrangementId,
        filters,
        page: pagination.page
    })
    if (entity.isEmpty) {
        return <EmptyResultBanner/>
    }
    return (<>
    <DataTable
          headers={tableHeaders}
          data={entity.entity}
          tableId={'terminology-entries-table'}
          tableHeading={'Terminology entries'}
          rowComponent={DictionaryEntryRow}
          total={pagination.total}
          count={pagination.count}
          isLoading={entity.isLoading}
          isEmpty={entity.isEmpty}
        />
          {canLoadMore && <ListViewLoadMore isLoading={entity.isLoading} onClick={loadMore} />}
    </>)
}

export default TerminologyEntriesTable
