import { environment } from './config'
import DigitalStoreSDK from '@redant/digital-store-client-sdk-mhr'

export const baseUrl = environment.API_URL

const sdk = new DigitalStoreSDK({
  baseUrl
})

window.sdk = sdk

export default sdk
