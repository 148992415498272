"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateInitialValues = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = _interopRequireDefault(require("lodash/fp"));

var _uuid = require("uuid");

var _constants = require("../../constants");

var _formHelpers = require("../../formSchemas/devicesReport/7.2.0/formHelpers");

var _audiences = _interopRequireDefault(require("./audiences"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var generateComputedValues = function generateComputedValues(_ref) {
  var schemaName = _ref.schemaName,
      organisationDetails = _ref.organisationDetails,
      _ref$formViewDetails = _ref.formViewDetails,
      formViewDetails = _ref$formViewDetails === void 0 ? {} : _ref$formViewDetails,
      _ref$productDetails = _ref.productDetails,
      productDetails = _ref$productDetails === void 0 ? {} : _ref$productDetails,
      timestamp = _ref.timestamp;
  var audience = formViewDetails.audienceId;

  switch (audience) {
    case _constants.AUDIENCE_CONSTANTS.HCP:
    case _constants.AUDIENCE_CONSTANTS.MOP:
      return _audiences["default"].hcpAndMop.generateComputedValues({
        schemaName: schemaName,
        organisationDetails: organisationDetails,
        formViewDetails: formViewDetails,
        timestamp: timestamp
      });

    case _constants.AUDIENCE_CONSTANTS.INDUSTRY:
      return _audiences["default"].industry.generateComputedValues({
        schemaName: schemaName,
        organisationDetails: organisationDetails,
        formViewDetails: formViewDetails,
        productDetails: productDetails,
        timestamp: timestamp
      });

    default:
      return {};
  }
};

var generateUserValues = function generateUserValues(_ref2) {
  var schemaName = _ref2.schemaName,
      userDetails = _ref2.userDetails,
      formViewDetails = _ref2.formViewDetails,
      isWorkspace = _ref2.isWorkspace;
  var audience = formViewDetails.audienceId;

  switch (audience) {
    case _constants.AUDIENCE_CONSTANTS.HCP:
    case _constants.AUDIENCE_CONSTANTS.MOP:
      return _audiences["default"].hcpAndMop.generateUserValues({
        schemaName: schemaName,
        userDetails: userDetails,
        isWorkspace: isWorkspace
      });

    case _constants.AUDIENCE_CONSTANTS.INDUSTRY:
      return _audiences["default"].industry.generateUserValues({
        schemaName: schemaName,
        userDetails: userDetails,
        isWorkspace: isWorkspace
      });

    default:
      return {};
  }
};

var generateTargetValues = function generateTargetValues(_ref3) {
  var schemaName = _ref3.schemaName,
      _ref3$reportTarget = _ref3.reportTarget,
      reportTarget = _ref3$reportTarget === void 0 ? {} : _ref3$reportTarget;

  if (_lodash["default"].isObject(reportTarget) && _lodash["default"].isEmpty(reportTarget)) {
    return {};
  }

  switch (schemaName) {
    case _constants.FORM_SCHEMAS_CONSTANTS.MHRA_R2_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.MHRA_R3_REPORT:
      return {
        drug: [_objectSpread({
          id: (0, _uuid.v4)(),
          _isOpen: true,
          drugcharacterization: '1'
        }, reportTarget)]
      };

    case _constants.FORM_SCHEMAS_CONSTANTS.MIR_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.FSCA_REPORT:
      return reportTarget;

    default:
      return {};
  }
};

var generateDefaultValues = function generateDefaultValues(_ref4) {
  var schemaName = _ref4.schemaName,
      userDetails = _ref4.userDetails,
      organisationDetails = _ref4.organisationDetails;

  switch (schemaName) {
    case _constants.FORM_SCHEMAS_CONSTANTS.MHRA_R2_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.MHRA_R3_REPORT:
      return {
        _defaultValues: {
          sendertype: organisationDetails.sendertype
        }
      };
  }
};

var generateContext = function generateContext(_ref5) {
  var schemaName = _ref5.schemaName,
      userDetails = _ref5.userDetails,
      initialValues = _ref5.initialValues;

  switch (schemaName) {
    case _constants.FORM_SCHEMAS_CONSTANTS.SABRE_SAE_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.SABRE_SAR_REPORT:
      return {
        _context: {
          currentUser: _fp["default"].pick(['id', 'title', 'firstName', 'lastName', 'email', 'telephone', 'details'])(userDetails),
          repeatableMetadata: _fp["default"].compose(_fp["default"].mapValues(function (repeatable) {
            return {
              size: _fp["default"].size(repeatable)
            };
          }), _fp["default"].pickBy(_fp["default"].isArray))(initialValues)
        }
      };

    default:
      return {};
  }
};

var generateDeviceValues = function generateDeviceValues(_ref6) {
  var organisationId = _ref6.organisationId,
      schemaName = _ref6.schemaName,
      organisationDetails = _ref6.organisationDetails;

  switch (schemaName) {
    case _constants.FORM_SCHEMAS_CONSTANTS.MIR_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.FSCA_REPORT:
      return {
        mfrNameId: organisationId,
        mfrCountry: (0, _formHelpers.castToCountryCode)(_lodash["default"].get(organisationDetails, 'country')),
        ARCountry: (0, _formHelpers.castToCountryCode)(_lodash["default"].get(organisationDetails, 'importedData.appian.REP_COUNTRY'))
      };

    case _constants.FORM_SCHEMAS_CONSTANTS.PSR_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.SAE_REPORT:
    case _constants.FORM_SCHEMAS_CONSTANTS.TREND_REPORT:
      return {
        mfrNameId: organisationId,
        mfrCountry: (0, _formHelpers.castToCountryCode)(_lodash["default"].get(organisationDetails, 'country'))
      };

    default:
      return {};
  }
};

var generateInitialValues = function generateInitialValues(_ref7) {
  var schemaName = _ref7.schemaName,
      _ref7$initialValues = _ref7.initialValues,
      initialValues = _ref7$initialValues === void 0 ? {} : _ref7$initialValues,
      _ref7$followUp = _ref7.followUp,
      followUp = _ref7$followUp === void 0 ? {} : _ref7$followUp,
      _ref7$userDetails = _ref7.userDetails,
      userDetails = _ref7$userDetails === void 0 ? {} : _ref7$userDetails,
      _ref7$organisationDet = _ref7.organisationDetails,
      organisationDetails = _ref7$organisationDet === void 0 ? {} : _ref7$organisationDet,
      _ref7$productDetails = _ref7.productDetails,
      productDetails = _ref7$productDetails === void 0 ? {} : _ref7$productDetails,
      _ref7$formView = _ref7.formView,
      formView = _ref7$formView === void 0 ? {} : _ref7$formView,
      reportTarget = _ref7.reportTarget,
      organisationId = _ref7.organisationId,
      isWorkspace = _ref7.isWorkspace,
      timestamp = _ref7.timestamp,
      _ref7$options = _ref7.options,
      options = _ref7$options === void 0 ? {} : _ref7$options;

  var skipUserDetails = _lodash["default"].get(options, 'skipUserDetails', false);

  var skipComputedValues = _lodash["default"].get(options, 'skipComputedValues', false);

  var skipDeviceValues = _lodash["default"].get(options, 'skipDeviceValues', false);

  var formViewDetails = _lodash["default"].defaults(_lodash["default"].get(formView, 'details', {}), {
    audienceId: 1
  });

  var computedValues = skipComputedValues ? {} : generateComputedValues({
    schemaName: schemaName,
    organisationDetails: organisationDetails,
    formViewDetails: formViewDetails,
    productDetails: productDetails,
    timestamp: timestamp
  });
  var userValues = skipUserDetails ? {} : generateUserValues({
    schemaName: schemaName,
    userDetails: userDetails,
    formViewDetails: formViewDetails,
    isWorkspace: isWorkspace
  });
  var reportTargetValues = generateTargetValues({
    schemaName: schemaName,
    reportTarget: reportTarget
  });
  var defaultValues = generateDefaultValues({
    schemaName: schemaName,
    userDetails: userDetails,
    organisationDetails: organisationDetails
  });
  var context = generateContext({
    schemaName: schemaName,
    userDetails: userDetails,
    initialValues: initialValues
  });
  var deviceValues = skipDeviceValues ? {} : generateDeviceValues({
    organisationId: organisationId,
    schemaName: schemaName,
    organisationDetails: organisationDetails
  });

  var followUpReportValues = _lodash["default"].get(followUp, 'report.reportJSON', {});

  var parsedInitialValues = _lodash["default"].merge({}, computedValues, userValues, reportTargetValues, deviceValues, initialValues, followUpReportValues, defaultValues, context);

  return parsedInitialValues;
};

exports.generateInitialValues = generateInitialValues;