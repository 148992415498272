const styles = theme => ({
  languageList: {
    marginBottom: 20
  },
  contentContainer: {
    margin: '20px 7.5%',
    display: 'flex',
    flexDirection: 'column'
  },
  blockList: {
    marginBottom: 20
  },
  gridContainer: {
    margin: '10px auto 0 auto',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 15,
      paddingRight: 15,
      margin: 0
    }
  },
  cardContainer: {
    width: '100%',
    maxWidth: 400,
    padding: '7.5px 0'
  },
  noDataMessage: {
    textAlign: 'center',
    textTransform: 'uppercase'
  }
})

export default theme => styles(theme)
