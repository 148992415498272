const styles = theme => {
  return {
    container: {
      paddingTop: 20,
      paddingLeft: 10,
      paddingRight: 10,
      maxWidth: 1200,
      margin: '0 auto',
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 20,
        paddingRight: 20
      }
    },
    leftContent: {
      paddingLeft: 20
    },
    rightContent: {
      paddingRight: 20
    },
    storesFilter: {
      width: '100%'
    },
    checkboxContainer: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 11,
        paddingLeft: 10
      }
    },
    roleFilter: {
      width: '100%'
    },
    buttonCol: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center'
    }
  }
}

export default styles
