import _ from 'lodash'
import React from 'react'
import Section from '../Section'
import { ActionButton } from '../ReportSummaryModal'
import { translations } from '../../../../../config'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import { selectors as platformSelectors } from '../../../../../store/modules/platforms'
import { useAbilities } from '../../../../../ability/ability-context'

const AckActions = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection,
  } = props
  
  const dispatch = useDispatch()
  const ability = useAbilities()

  const reportId = useSelector(reportDetailsSelectors.getReportId)
  const revision = useSelector(reportDetailsSelectors.getRevision)
  const reportStatus = useSelector(reportDetailsSelectors.getReportStatus)
  const fromRegulatoryAgency = useSelector(reportDetailsSelectors.getFromRegulatoryAgency)
  const isArchived = useSelector(reportDetailsSelectors.getIsReportArchived)
  const hasCreateAckPermissions = useSelector(platformSelectors.getHasCreateAckPermissions)
  const isCorrectRevision = (revision === 2 || revision === 3)
  const isDraft = reportStatus === 'draft'
  const hasPermission = ability.can('viewAcknowledgement', 'reportManagement')

  const canCreateAck = fromRegulatoryAgency 
    && isCorrectRevision
    && hasCreateAckPermissions 
    && !isArchived 
    && !isDraft

  const canViewAcks = !isArchived && (reportStatus === 'ackFailed' || reportStatus === 'ackPassed') && hasPermission

  if(!canCreateAck && !canViewAcks) {
    return null
  }

  const createAck = () => {
    dispatch(push(`/reports/${reportId}/acknowledgements/new`))
  }

  const viewAcks = () => {
    dispatch(push(`/reports/${reportId}/acknowledgements`))
  }

  const createAckButton = 
    <ActionButton onClick={createAck}>{translations('Create Acknowledgement')}</ActionButton>

  const viewAckButton =
    <ActionButton onClick={viewAcks}>{translations('View Acknowledgement')}</ActionButton>

  const rows = [
    { type: 'ACTION', value: canCreateAck ? createAckButton : null },
    { type: 'ACTION', value: canViewAcks ? viewAckButton : null }
  ]

  return (
    <Section
      name='Acknowledgement'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
    />
  )
}

export default AckActions