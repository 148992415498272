const styles = {
  container: {
    padding: 20,
    paddingBottom: 0,
    fontSize: 13,
    lineHeight: '18px',
    textAlign: 'center'
  }
}

export default theme => styles
