import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { useIsConfigurable } from '../../../store/modules/communicationTemplates/hooks'
import ContentBox from '../../../components/ContentBox'
import ContentBoxHeader from '../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../components/Form/TextContent'
import Heading from '../../../components/Heading'
import Button from '../../../components/Button'
import { selectors as communicationTemplateSelectors } from '../../../store/modules/communicationTemplates'

import TemplateItem from '../TemplateItem'

import { translations } from '../../../config'
import style from './style'

const TemplateSection = (props) => {
  const { type, classes, channel, isSystemEmailTemplate = false, emailTemplates = null } = props
  const isConfigurable = useIsConfigurable(type, isSystemEmailTemplate)
  const dispatch = useDispatch()

  const templateTypes = useSelector(communicationTemplateSelectors.getTemplatesIDsByType)
  const defaultTemplateIds = useSelector(communicationTemplateSelectors.getDefaultTemplatesByType)
  const defaultTemplateId = _.get(defaultTemplateIds, type)
  const canAddEmailTemplate = channel !== 'email' || isConfigurable

  let templates = _.get(templateTypes, type)
  if (channel === 'email' && emailTemplates) {
    templates = _.get(emailTemplates, type)
  }
  const onAdd = () => {
    dispatch(push(`/communications/add/${channel}/${_.toLower(type)}?isSystemEmailTemplate=${isSystemEmailTemplate}`))
  }

  const renderEmptyMessage = () => {
    return (
      <div className={classes.emptyMessage}>
        {canAddEmailTemplate ? 
          translations('Create a template and they will show here.'):
          translations('No Templates.')
        }
      </div> 
    )
  }

  return (
    <ContentBox key={type} givenContentClass={classes.contentBox}>
      <ContentBoxHeader>
        <TextContent>
          <Heading>{translations(`Communication Type - ${type}`)}</Heading>
        </TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <div className={classes.content}>
          {_.size(templates) === 0 && renderEmptyMessage()}
          {_.size(templates) > 0 && <ul className={classes.list}>
            {_.map(templates, (id) => {
              const fallbackDefaultEmailTemplateId = isSystemEmailTemplate && templates[0]
              return (
                <li key={id} className={classes.listItem}>
                  <TemplateItem
                    channel={channel}
                    id={id}
                    defaultTemplateId={defaultTemplateId}
                    isSystemEmailTemplate={isSystemEmailTemplate}
                    fallbackDefaultEmailTemplateId={fallbackDefaultEmailTemplateId}
                  />
                </li>
              )
            })}
          </ul>}
          {canAddEmailTemplate && <div className={classes.actions}>
            <Button buttonType={'primary'} onClick={onAdd}>{translations('Communications - Add Template')}</Button>
          </div>}
        </div>
      </ContentBoxBody>
    </ContentBox>
  )
}

export default withStyles(style)(TemplateSection)
