import React from 'react'
import PropTypes from 'prop-types'

import { styled } from '@material-ui/core/styles'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  paddingTop: 5,
  paddingBottom: 5
}))

export const Operator = styled('span')(({ theme, selected }) => ({
  display: 'block',
  borderRadius: 3,
  border: `1px solid ${theme.palette.grey[200]}`,
  fontSize: 10,
  textTransform: 'uppercase',
  lineHeight: 1,
  padding: '2px 5px',
  background: selected ? theme.palette.primary.main : '#fff'
}))

export const OperatorDisplay = (props) => {
  const { label, selected } = props

  return (
    <Container>
      <Operator selected>{label}</Operator>
    </Container>
  )
}

OperatorDisplay.propTypes = {
  label: PropTypes.string.isRequired
}

export default OperatorDisplay
