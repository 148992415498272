import _ from 'lodash'

import { PENDING, SUCCESS } from '../../middleware/redux-promise'

export const getIsLoading = state => state.accountDeletionRequest.status === PENDING
export const getResults = state => _.get(state, 'accountDeletionRequest.results', [])
export const getHasMore = state => state.accountDeletionRequest.total != null &&
state.accountDeletionRequest.results.length < state.accountDeletionRequest.total
export const getError = state => state.accountDeletionRequest.error
export const getIsInitial = state => !state.accountDeletionRequest.status
export const getQuery = state => state.accountDeletionRequest.query
export const getSize = state => state.accountDeletionRequest.size
export const getPage = state => state.accountDeletionRequest.page
export const getTotal = state => state.accountDeletionRequest.total

