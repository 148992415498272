import _ from 'lodash'
import React from 'react'
import StandardForm from '../StandardForm'
import { SdkProvider, useSdk } from '../../../services/sdk'
import { FORM_ERROR } from 'final-form'

const AboutYouForm = ({
  onSuccess,
  onError,
  organisationId,
  organisationDetails,
  professions,
  audienceId,
  privacyPolicyLink
}) => {

  const { register } = useSdk()

  const occupations = {
    2: 'hcp',
    3: 'public'
  }
  const occupation = _.get(occupations, audienceId)

  const onSubmit = async (data) => {
    try {
      const enableRegister = data?.enableRegister
      const modifiedData = _.clone(data)
      modifiedData.isPublic = true
      modifiedData.organisationIds = [organisationId]
      modifiedData.emailCommunicationsPermitted = _.includes(modifiedData.communicationPreferences, 'email')
      modifiedData.smsCommunicationsPermitted = _.includes(modifiedData.communicationPreferences, 'sms')
      if (modifiedData.hospitalOrPracticeOrOrganisation) {
        modifiedData.houseNumberOrName = modifiedData.hospitalOrPracticeOrOrganisation
      }
      if (enableRegister) {
        const userDetails = await register(modifiedData)
        onSuccess && onSuccess(userDetails)
        return
      }
      onSuccess && onSuccess({ ...modifiedData })
    } catch (error) {
      onError && onError(error)
      return { [FORM_ERROR]: error.message }
    }
  }

  return (
    <StandardForm
      initialValues={{ occupation }}
      schemaName={'aboutYou'}
      onSubmit={onSubmit}
      professions={professions}
      organisationDetails={organisationDetails}
      privacyPolicyLink={privacyPolicyLink}
    />
  )
}

const WrappedAboutYouForm = (props) => {
  const {
    baseUrl,
    organisationId,
    platformId,
    ...rest
  } = props

  return (
    <SdkProvider
      baseUrl={baseUrl}
      organisationId={organisationId}
      platformId={platformId}
    >
      <AboutYouForm organisationId={organisationId} {...rest} />
    </SdkProvider>
  )
}

export default WrappedAboutYouForm