import React from 'react'
import PrivateRoute from '../../../../components/PrivateRoute'
import { slugify } from '../../../../helpers'
import userSchema from '../../../../config/configFiles/organisationReportingSchemaUser.json'
import reportSchema from '../../../../config/configFiles/organisationReportingSchemaReport.json'

import OrganisationReportingFullList from './OrganisationFullList'

// this only needs to be done once so it can be outside a render function
// because schema is static
const OrganisationReportingScreens = (
  [...userSchema, ...reportSchema]
    .map((report, i) => {
      const path = `/monitoring-and-analytics/analytics/${report.id}-${slugify(report.title || 'report')}`
      return (
        report.fullList
        ? <PrivateRoute
          key={path}
          path={path}
          component={() => <OrganisationReportingFullList reportSchema={report} />}
          exact
        />
        : null
      )
    })
    .filter(Boolean)
)

export default OrganisationReportingScreens
