import { connect } from 'react-redux'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import { push } from 'connected-react-router'
import qs from 'qs'
import withWidth from '@material-ui/core/withWidth'

import { actions as drugDetailsActions, selectors as drugDetailsSelectors } from '../../../store/modules/drugDetails'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { actions as newsActions } from '../../../store/modules/news'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'

import withFullScreenLoader from '../../../components/FullScreenLoader'
import { translations } from '../../../config'
import modalService from '../../../services/modalService'
import NewsSearchTable from '../../News/NewsSearchScreen/NewsSearchTable'

import toastService from '../../../services/toastService'

import ViewProductScreen from './ViewProductScreen'

const mapStateToProps = (state) => {
  const isDrugDetailsLoading = drugDetailsSelectors.getIsLoading(state)
  const isWatchListLoading = authSelectors.getIsLoadingEditWatchList(state) // for when adding/remove to/from watch list
  const isLoading = isDrugDetailsLoading || isWatchListLoading
  const isLoadingMeta = drugDetailsSelectors.getIsLoadingMeta(state)
  const drug = drugDetailsSelectors.getDrug(state)
  const hasWatchListPermissions = platformSelectors.getHasWatchListPermissions(state)
  const isNewsAllowed = authCombinedSelectors.getIsNewsAllowedForSelectedOrganisation(state)

  return {
    isLoading,
    isLoadingMeta,
    drug,
    hasWatchListPermissions,
    isNewsAllowed,
    getPageTitle: platformSelectors.getPageTitle(state)
  }
}

const mapDispatchToProps = {
  editWatchList: authActions.editWatchList,
  fetchDrug: drugDetailsActions.fetchDrug,
  push
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    toggleWatching: ({ drug, editWatchList, fetchDrug }) => (watching) => {
      const { id, name } = drug || {}
      editWatchList({ [name]: watching })
        .then(() => fetchDrug({ id }))
        .then((res) => {
          toastService.action({
            type: 'success',
            message: translations('Successfully - Watch List', { action: watching ? 'added to' : 'removed from' })
          })
        })
        .catch(() => {
          toastService.action({
            type: 'error',
            message: translations('Failure - Watch List', { action: watching ? 'add to' : 'remove from' })
          })
        })
    },
    addToNewReport: ({ drug, push, dispatch }) => () => {
      const { name } = drug
      const queryString = qs.stringify({ type: 'sideEffects', drug: name })
      push(`/select-report?${queryString}`)
    },
    openRelatedNewsModal: ({ drug, dispatch }) => () => {
      // Fetch articles for this drug and display modal
      dispatch(newsActions.fetchNews({ newsType: 'default', drugName: drug.name.toLowerCase() }))
      modalService.open({
        component: NewsSearchTable,
        newsType: 'default',
        wideModal: true,
        inModal: true
      })
    }
  }),
  withPropsOnChange(
    ['drug', 'isNewsAllowed', 'hasWatchListPermissions'], (props) => {
      const {
        drug,
        isNewsAllowed,
        hasWatchListPermissions,
        toggleWatching,
        addToNewReport,
        openRelatedNewsModal
      } = props
      const { watching } = drug || {}
      const buttons = [
        ...(hasWatchListPermissions ? [{
          children: translations(
            watching
            ? 'Product Details Buttons - Remove From Watch List'
            : 'Product Details Buttons - Add To Watch List'
          ),
          onClick: () => toggleWatching(!watching)
        }] : []),
        {
          children: translations('Product Details Buttons - Add To New Report'),
          onClick: addToNewReport
        },
        ...(isNewsAllowed ? [{
          children: translations('View related news'),
          onClick: openRelatedNewsModal
        }] : [])
      ]
      return { buttons }
    }
  ),
  withPropsOnChange(
    ['isLoading', 'isLoadingMeta'], (props) => {
      const { isLoading, isLoadingMeta } = props
      // don't show full screen loader if only loading meta
      // for a different meta distribution
      return { isLoading: isLoading && !isLoadingMeta }
    }
  ),
  withFullScreenLoader,
  withWidth()
)

export default enhance(ViewProductScreen)
