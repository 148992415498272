import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Highlight, { defaultProps } from 'prism-react-renderer'
import githubTheme from 'prism-react-renderer/themes/github'

import { withStyles } from '@material-ui/core/styles'
import styles from './style'

class SyntaxHighlighter extends React.PureComponent {
  render () {
    const { classes, code, language } = this.props

    return (
      <div className={classes.container}>
        <Highlight {...defaultProps} code={code} language={language} theme={githubTheme}>
          {({ style, tokens, getLineProps, getTokenProps }) => {
            return (
              <pre className={classes.pre} style={style}>
                <code className={classes.code}>
                  {_.map(tokens, (line, lineIndex) => (
                    <div className={classes.line} key={lineIndex} {...getLineProps({ line, key: lineIndex })}>
                      <span className={classes.lineNumber}>{lineIndex + 1}</span>
                      <span className={classes.lineContent}>
                        {_.map(line, (token, tokenIndex) => (
                          <span key={tokenIndex} {...getTokenProps({ token, tokenIndex })} />
                        ))}
                      </span>
                    </div>
                  ))}
                </code>
              </pre>
            )
          }}
        </Highlight>
      </div>
    )
  }
}

SyntaxHighlighter.propTypes = {
  classes: PropTypes.any,
  code: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
}

export default withStyles(styles)(SyntaxHighlighter)
