
const styles = () => {
    return {
      container: {
        paddingTop: 10
      },
      intro: {
        paddingBottom: 20,
        '&> div': {
          marginBottom: 15
        }
      }
    }
  }
  
  export default styles
  