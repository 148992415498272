import React from 'react'
import _ from 'lodash'
import { styled } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import SearchInput from '../../../../components/SearchInput'

import { translations } from '../../../../config'
import {
  actions as reportConfigurationActions,
  hooks as reportConfigurationHooks
} from '../../../../store/modules/reportConfiguration'

const Container = styled('div')(({ theme }) => (
  {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
))

const ReportConfigurationSearchInput = () => {
  const dispatch = useDispatch()
  const filters = reportConfigurationHooks.useFormViewFilters()
  const query = _.get(filters, 'query', '')

  const updateQuery = (e) => {
    dispatch(reportConfigurationActions.updateFormViewFilters({
      filters: {
        query: e.target.value
      }
    }))
  }

  const clearQuery = () => {
    dispatch(reportConfigurationActions.updateFormViewFilters({
      filters: {
        query: ''
      }
    }))
  }

  return (
    <Container>
      <SearchInput
        value={query}
        placeholder={translations('Report Configuration - Form View Search Field Placeholder')}
        onChange={updateQuery}
        onClear={clearQuery}
        autoFocus={false}
      />
    </Container>
  )
}

export default ReportConfigurationSearchInput