import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import CommunicationsHeader from '../../CommunicationsHeader'
import TemplateSection from '../../TemplateSection'

import style from './style'

const renderTemplateSections = (types) => {
  return _.map(types, (type) => {
    return <TemplateSection channel='sms' key={type} type={type} />
  })
}

const CommunicationsSmsScreen = (props) => {
  const { pageTitle, classes, smsTemplateTypes } = props

  return <Fragment>
    <CommunicationsHeader channel ='sms' pageTitle = {pageTitle} />
    <div className={classes.container}>
      {renderTemplateSections(smsTemplateTypes)}
    </div>
  </Fragment>
}

CommunicationsSmsScreen.propTypes = {
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired,
  classes: PropTypes.object.isRequired,
  smsTemplateTypes: PropTypes.array,
}

export default withStyles(style)(CommunicationsSmsScreen)
