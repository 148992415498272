import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  error: undefined,
  query: undefined,
  status: undefined,
  results: [],
  page: 1,
  size: 10
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SEARCH_HOSPITALS:
      return reducers.searchHospitals(state, action)
    case constants.SEARCH_HOSPITALS_NEXT:
      return reducers.searchHospitalsNext(state, action)
    case constants.FETCH_HOSPITALS:
      return reducers.fetchAllHospitals(state, action)
    case constants.RESET_STORE:
      return defaultState
    default:
      return state
  }
}
