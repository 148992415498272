import { compose, withState, withPropsOnChange, withHandlers } from 'recompose' 
import _ from 'lodash'

import Chart from './Chart'
import colors from './colors'

const enhance = compose(
  // add colors to data
  // this is the first HOC so that colors don't get recalculated
  // when hiddenData state changes (bad perf)
  withPropsOnChange(['data'], props => {
    const { data = [] } = props
    const len = data.length
    let colorsToUse = []

    const orderedColors = [colors.blue, colors.orange, colors.purple]

    if (len <= 36) {
      // eg: 1 - just blue, 2 - blue & orange, 3 - blue, orange & purple
      const numOfColors = Math.ceil(len / 12)

      // eg: 7 - 7 shades of blue & 7 shades of orange
      const numOfShadesForEachColor = Math.ceil(len / numOfColors)

      orderedColors.slice(0, numOfColors).forEach(color => {
        colorsToUse.push(...color[numOfShadesForEachColor])
      })
    } else {
      colorsToUse = colors.largeData
    }

    const dataWithColors = data.map((datum, index) => {
      return { ...datum, color: colorsToUse[index] }
    })

    return { data: dataWithColors }
  }),
  withState('hiddenData', 'setHiddenData', {}),
  withHandlers({
    toggleDatum: ({ setHiddenData }) => (key) => {
      setHiddenData(hiddenData => {
        return {
          ...hiddenData,
          [key]: !hiddenData[key]
        }
      })
    }
  }),
  // add hidden key to data (dependent on state)
  withPropsOnChange(['hiddenData', 'data'], props => {
    const { hiddenData, data } = props
    const dataWithHiddenKeys = data.map(datum => {
      return {
        ...datum,
        hidden: !!hiddenData[datum.key]
      }
    })
    return { data: dataWithHiddenKeys }
  })
)

export default enhance(Chart)
