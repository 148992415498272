import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import fp from 'lodash/fp'

const handleChanges = (onChange) => (_event, change) => {
    if (!fp.isEmpty(change)) {
        onChange(change)
    }
}
const TerminologyToggleGroup = (props) => {
    const { value, onChange, options } = props
    return (
        <ToggleButtonGroup
            value={value}
            onChange={handleChanges(onChange)}
            aria-label="text formatting"
        >
            {fp.map((entry) => {
                return (
                    <ToggleButton value={entry.value} aria-label={entry.label}>
                {entry.label}
            </ToggleButton>
                )
            }, options)
            }
        </ToggleButtonGroup>
    );
}

export default TerminologyToggleGroup
