import { styled } from '@material-ui/core'

const Content = styled('div')(({ theme }) => ({
  maxWidth: 1400,
  margin: '0 auto',
  padding: theme.spacing(2)
}))

const ContentContainer = ({ children }) => {
  return (
    <Content>
      {children}
    </Content>
  )
}

export default ContentContainer
