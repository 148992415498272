import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@material-ui/core'
import { push } from 'connected-react-router'
import { Edit } from '@material-ui/icons'
import { useCallback } from 'react'

import { translations } from '../../../../config'
import { getSelectedUser, getSelectedUserUserGroups } from '../../../../store/modules/userDetails/selectors'
import { DataTable, DataTableCell, DataTableRow } from '../../../../components/DataTable'

import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'
import Heading from '../../../../components/Heading'
import RightButtonContent from '../../../../components/Form/RightButtonContent'
import Button from '../../../../components/Button'

const UserGroupsForm = () => {
  const dispatch = useDispatch()

  const user = useSelector(getSelectedUser) || {}
  const {
    id,
    displayName
  } = user
  const userGroups = useSelector(getSelectedUserUserGroups)

  const handleAdd = useCallback(() => {
    if (id && displayName) {
      dispatch(push(`/permissions/user-groups`, { user: { id, displayName } }))
    }
  }, [id, displayName, dispatch, push])

  return (
    <ContentBox>
      <ContentBoxHeader>
        <TextContent>
          <Heading>{translations('User Management - User Group Header')}</Heading>
        </TextContent>
        <RightButtonContent>
          <Button buttonType={'primary'} onClick={handleAdd}>{translations('User Management - Add User Group')}</Button>
        </RightButtonContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <DataTable
          data={userGroups}
          rowComponent={UserGroupRow}
          tableId={'users-groups-table'}
          tableHeading={translations('User Management - User Group Header')}
          headers={[
            { id: 'name', label: translations('Name') },
            { id: 'actions', label: '', options: { align: 'right' } }
          ]}
        />
      </ContentBoxBody>
    </ContentBox>
  )
}

const UserGroupRow = ({ entry }) => {
  const {
    id,
    name
  } = entry

  const dispatch = useDispatch()

  const handleEdit = useCallback(() => {
    dispatch(push(`/permissions/user-group/${id}`))
  }, [id])

  return (
    <DataTableRow>
      <DataTableCell component='th' scope='row' heading>{name}</DataTableCell>
      <DataTableCell align={'right'}>
        <IconButton onClick={handleEdit} aria-label={translations('Edit')}>
          <Edit />
        </IconButton>
      </DataTableCell>
    </DataTableRow>
  )
}

export default UserGroupsForm