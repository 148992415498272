import React, { useState } from 'react'
import { translations } from '../../../config'
import modalService from '../../../services/modalService'
import StatusModal from '../modals/StatusModal'
import Chip from '../../../components/Chip'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import LinearProgress from '@material-ui/core/LinearProgress'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { IconButton, Collapse, Box, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core'
import _ from 'lodash'

const FileManagerItem = (props) => {

  const { file, statusData } = props

  const attachments = file.attachments
  const hasAttachments = attachments && !_.isEmpty(attachments)

  const [isAttachmentTableOpen, setIsAttachmentTableOpen] = useState(hasAttachments)
  const toggleAttachmentTable = () => { setIsAttachmentTableOpen(!isAttachmentTableOpen) }

  const Overflow = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    width: 100
  }))

  return (
    <>
      <TableRow key={file.id}>
        <TableCell component='th' scope='row' style={{ borderBottom: 'none' }} >
          {file.fileName}
        </TableCell>
        <TableCell style={{ borderBottom: 'none'}}>
          {statusData.performingAsync ? <LinearProgress /> 
          : <Overflow onClick={() => openModal(statusData)}>{statusData.details}</Overflow>}
        </TableCell>
        <TableCell align={'left'} style={{ borderBottom: 'none' }}>
          <Chip
            variant={statusData.variant}
            label={statusData.label}
            onClick={() => openModal(statusData)}
          />
        </TableCell>
        <TableCell style={{ borderBottom: 'none'}}>
            <IconButton
              disabled={!hasAttachments}
              onClick={toggleAttachmentTable}
              size={'small'}
            >
              {isAttachmentTableOpen ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
            </IconButton>
        </TableCell>
      </TableRow>
      <CollapsibleAttachmentTable attachments={attachments} attachmentStatus={statusData.attachment} open={isAttachmentTableOpen} />
    </>
  )
}

const CollapsibleAttachmentTable = (props) => {

  const { open, attachments, attachmentStatus } = props

  return (
    <TableRow>
      <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1, paddingBottom: '10px' }}>
            <Typography variant="string" gutterBottom component="div">
              Attachments:
            </Typography>
            <Table size='small'>
              <TableHead>
                <TableCell>File</TableCell>
                <TableCell>Status</TableCell>
              </TableHead>
              <TableBody>
                {attachments.map((attachment) => {
                  return (
                      <TableRow>
                      <TableCell>
                        {attachment.attachmentfile}
                      </TableCell>
                      <TableCell>
                        <Chip
                          variant={attachmentStatus.variant}
                          label={attachmentStatus.label}
                          onClick={() => openModal(attachmentStatus)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                  })}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

const openModal = (statusData) => {
  modalService.open({
    component: StatusModal,
    fullScreen: false,
    modalTitle: translations('Post Report - Report Status'),
    status: statusData.variant,
    label: statusData.label,
    message: statusData.details,
    errors: statusData.errors
  })
}

export default FileManagerItem