// A dynamic schema can have "computed fields" which will be processed
// on form update by "parseDynamicSchema". The then static schema will be passed into the form
// component as normal
import _ from 'lodash'
import fp from 'lodash/fp'
import * as validators from '../../../validators'
import { drugAdministrationRoutes, countryCodes } from '../../../config'

import {
  susarEditable,
  spontaneousEditable,
  hasFatalReaction,
  getFormatForDate,
  stringToValueAndLabel
} from '../formHelpers'

const getRedAntFormatForDate = (value) => {
  return getFormatForDate(value, {
    yyyymmdd: '102',
    yyyymm: '610',
    yyyy: '602'
  })
}

const initialFieldMap = {
  medicinalProduct: {
    group: 'medicine',
    field: 'medicinalproduct'
  }
}

const formSchema = {
  admin: {
    title: 'Admin',
    initialOpen: true,
    schema: [
      {
        id: 'canSaveDraft',
        field: 'Input',
        computedValue: (values) => values.safetyreportid && values.messagenumb,
        props: {
          type: 'hidden',
          name: 'canSaveDraft',
          label: 'Can save draft',
          required: false
        }
      },
      {
        id: 'susarEditable',
        field: 'Input',
        computedValue: (values) => (
          values.reporttype === '2' &&
          !!values.primarysource &&
          !!_.find(values.primarysource, v => v.observestudytype === '1')
        ) ? 'true' : 'false',
        props: {
          type: 'hidden',
          name: 'susarEditable'
        }
      },
      {
        id: 'spontaneousEditable',
        field: 'Input',
        computedValue: (values) => values.reporttype === '1' ? 'true' : 'false',
        props: {
          type: 'hidden',
          name: 'spontaneousEditable'
        }
      },
      {
        id: 'messagetype',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'messagetype',
          required: true,
          label: 'Message Type',
          options: fp.map(stringToValueAndLabel)(['ICHICSR', 'BACKLOG'])
        }
      },
      {
        id: 'safetyreportid',
        field: 'Input',
        props: {
          shrink: true,
          name: 'safetyreportid',
          label: `Sender's Safety Report Unique Identifier`,
          required: true,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'messagenumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'messagenumb',
          label: 'Batch Number',
          required: true
        }
      },
      {
        id: 'primarysourcecountry',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'primarysourcecountry',
          label: 'Primary source country',
          autocomplete: true,
          options: countryCodes,
          required: true
        }
      },
      {
        id: 'occurcountry',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'occurcountry',
          label: 'Country where the reaction/event occurred',
          autocomplete: true,
          options: countryCodes,
          required: false
        }
      },
      {
        id: 'transmissiondateformat',
        field: 'Input',
        computedValue: (values) => getRedAntFormatForDate(values.transmissiondate),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'transmissiondateformat',
          label: 'Transmission Date Format',
          required: true
        }
      },
      {
        id: 'transmissiondate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'transmissiondate',
          label: 'Date of this transmission',
          required: true,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
          helperText: 'YYYY/MM/DD'
        }
      },
      {
        id: 'reporttype',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'reporttype',
          label: 'Type of report',
          options: [
            { value: '1', label: 'Spontaneous' },
            { value: '2', label: 'Report from study' },
            { value: '3', label: 'Other' },
            { value: '4', label: 'Not available to sender (unknown)' }
          ],
          required: true
        }
      },
      {
        id: 'receivedateformat',
        field: 'Input',
        computedValue: (values) => getRedAntFormatForDate(values.receivedate),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'receivedateformat',
          label: 'Received Date Format',
          required: true
        }
      },
      {
        id: 'receivedate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'receivedate',
          label: 'Date report was first received from source',
          required: true,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
          helperText: 'YYYY/MM/DD'
        }
      },
      {
        id: 'receiptdateformat',
        field: 'Input',
        computedValue: (values) => getRedAntFormatForDate(values.receiptdate),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'receiptdateformat',
          label: 'Receipt Date Format',
          required: true
        }
      },
      {
        id: 'receiptdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'receiptdate',
          label: 'Date of receipt of the most recent information for this report',
          required: true,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
          helperText: 'YYYY/MM/DD'
        }
      },
      {
        id: 'fulfillexpeditecriteria',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'fulfillexpeditecriteria',
          label: 'Does this case fulfill the local criteria for an expedited report?',
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ],
          required: true
        }
      },
      {
        id: 'authoritynumb',
        field: 'Input',
        computedEditable: (values) => !values.companynumb,
        props: {
          shrink: true,
          name: 'authoritynumb',
          label: `Regulatory authority's case report number`,
          required: true,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'companynumb',
        field: 'Input',
        computedEditable: (values) => !values.authoritynumb,
        props: {
          shrink: true,
          name: 'companynumb',
          label: `Other sender's case report number`,
          required: true,
          validate: [validators.maxLength(100, 'Field')]
        }
      },
      {
        id: 'medicallyconfirm',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'medicallyconfirm',
          label: 'Was the case medically confirmed, if not initially from health professional?',
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ],
          required: false
        }
      }
    ]
  },
  duplicates: {
    title: 'Duplicates / Nullifications',
    layout: [
      'duplicate:12',
      'reportduplicate:12'
    ],
    schema: [
      {
        id: 'duplicate',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'duplicate',
          label: 'Duplicate',
          options: [
            { value: '1', label: 'Yes' }
          ],
          required: false
        }
      },
      {
        id: 'reportduplicate',
        field: 'Repeatable',
        computedEditable: (values) => values.duplicate === '1',
        props: {
          shrink: true,
          name: 'reportduplicate',
          label: 'Report Duplicates',
          repeatableLabel: ['duplicatesource'],
          schema: [
            {
              id: 'duplicatesource',
              field: 'Input',
              props: {
                shrink: true,
                name: 'duplicatesource',
                label: 'Duplicate Source',
                required: true,
                validate: [validators.maxLength(50, 'Field')]
              }
            },
            {
              id: 'duplicatenumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'duplicatenumb',
                label: 'Duplicate number',
                required: false,
                validate: [validators.maxLength(100, 'Field')]
              }
            }
          ]
        }
      },
      {
        id: 'linkedreport',
        field: 'Repeatable',
        computedEditable: (values) => values.duplicate === '1',
        props: {
          shrink: true,
          name: 'linkedreport',
          label: 'Linked Reports',
          repeatableLabel: ['linkreportnumb'],
          schema: [
            {
              id: 'linkreportnumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'linkreportnumb',
                label: 'Linked report number',
                required: true,
                validate: [validators.maxLength(100, 'Field')]
              }
            }
          ]
        }
      },
      {
        id: 'casenullification',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'casenullification',
          label: 'Report Nullification',
          options: [
            { value: '1', label: 'Yes' }
          ],
          required: false
        }
      },
      {
        id: 'nullificationreason',
        field: 'Input',
        computedEditable: (values) => values.casenullification === '1',
        props: {
          shrink: true,
          name: 'nullificationreason',
          label: 'Reason for nullification',
          required: true,
          validate: [validators.maxLength(200, 'Field')]
        }
      }
    ]
  },
  reporter: {
    title: 'Reporter',
    layout: [
      'primarysource:12'
    ],
    schema: [
      {
        id: 'primarysource',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'primarysource',
          label: 'Reporter(s)',
          repeatableLabelPlaceholder: 'Reporter',
          repeatableLabel: ['reportergivename', 'reporterfamilyname'],
          repeatableSeparator: ' ',
          required: true,
          schema: [
            {
              id: 'reportertitle',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reportertitle',
                label: 'Reporter Title',
                required: false,
                validate: [validators.maxLength(50, 'Field')]
              }
            },
            {
              id: 'reportergivename',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reportergivename',
                label: 'Reporter given name',
                required: false,
                validate: [validators.maxLength(35, 'Field')]
              }
            },
            {
              id: 'reporterfamilyname',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reporterfamilyname',
                label: 'Reporter family name',
                required: false,
                validate: [validators.maxLength(50, 'Field')]
              }
            },
            {
              id: 'reporterorganization',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reporterorganization',
                label: 'Reporter organization',
                required: false,
                validate: [validators.maxLength(60, 'Field')]
              }
            },
            {
              id: 'reporterstreet',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reporterstreet',
                label: 'Reporter street',
                required: false,
                validate: [validators.maxLength(100, 'Field')]
              }
            },
            {
              id: 'reportercity',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reportercity',
                label: 'Reporter city',
                required: false,
                validate: [validators.maxLength(35, 'Field')]
              }
            },
            {
              id: 'reporterstate',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reporterstate',
                label: 'Reporter state or province',
                required: false,
                validate: [validators.maxLength(40, 'Field')]
              }
            },
            {
              id: 'reporterpostcode',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reporterpostcode',
                label: 'Reporter postcode',
                required: false,
                validate: [validators.maxLength(15, 'Field')]
              }
            },
            {
              id: 'reportercountry',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'reportercountry',
                label: 'Reporter country code',
                autocomplete: true,
                options: countryCodes,
                required: false
              }
            },
            {
              id: 'qualification',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'qualification',
                label: 'Reporter qualification',
                options: [
                  { value: '1', label: 'Physician' },
                  { value: '2', label: 'Pharmacist' },
                  { value: '3', label: 'Other Health Professional' },
                  { value: '4', label: 'Lawyer' },
                  { value: '5', label: 'Consumer or other non health professional' }
                ],
                required: true
              }
            },
            {
              id: 'literaturereference',
              field: 'Input',
              props: {
                shrink: true,
                name: 'literaturereference',
                label: 'Literature reference(s)',
                required: false,
                validate: [validators.maxLength(500, 'Field')]
              }
            },
            {
              id: 'studyname',
              field: 'Input',
              props: {
                shrink: true,
                name: 'studyname',
                label: 'Study name',
                required: false,
                validate: [validators.maxLength(100, 'Field')]
              }
            },
            {
              id: 'sponsorstudynumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'sponsorstudynumb',
                label: 'Sponsor study number',
                required: false,
                validate: [validators.maxLength(35, 'Field')]
              }
            },
            {
              id: 'observestudytype',
              field: 'Dropdown',
              computedEditable: (values, parentFormValues) => parentFormValues.reporttype === '2',
              props: {
                shrink: true,
                name: 'observestudytype',
                label: 'Study type',
                options: [
                  { value: '1', label: 'Clinical trials' },
                  { value: '2', label: 'Individual patient use' },
                  { value: '3', label: 'Other studies' }
                ],
                required: false
              }
            }
          ]
        }
      }
    ]
  },
  patient: {
    title: 'Patient',
    schema: [
      {
        id: 'patientinitial',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientinitial',
          label: 'Patient initials',
          required: true,
          validate: [validators.maxLength(10, 'Field')]
        }
      },
      {
        id: 'patientgpmedicalrecordnumb',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientgpmedicalrecordnumb',
          label: 'Patient record number',
          required: false,
          validate: [validators.maxLength(20, 'Field')]
        }
      },
      {
        id: 'patientonsetage',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientonsetage',
          label: 'Patient age at time of onset of reaction/event',
          required: false,
          validate: [validators.maxNumericLength(5, 'Field')]
        }
      },
      {
        id: 'patientonsetageunit',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientonsetageunit',
          label: 'Patient age unit',
          required: false,
          options: [
            { value: '800', label: 'Decade' },
            { value: '801', label: 'Year' },
            { value: '802', label: 'Month' },
            { value: '803', label: 'Week' },
            { value: '804', label: 'Day' },
            { value: '805', label: 'Hour' }
          ]
        }
      },
      {
        id: 'gestationperiod',
        field: 'Input',
        props: {
          shrink: true,
          name: 'gestationperiod',
          label: 'Gestation period',
          required: false,
          validate: [validators.maxNumericLength(3, 'Field')]
        }
      },
      {
        id: 'gestationperiodunit',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'gestationperiodunit',
          label: 'Gestation period unit',
          required: false,
          options: [
            { value: '802', label: 'Month' },
            { value: '803', label: 'Week' },
            { value: '804', label: 'Day' },
            { value: '810', label: 'Trimester' }
          ]
        }
      },
      {
        id: 'patientagegroup',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientagegroup',
          label: 'Patient age group',
          required: false,
          options: [
            { value: '1', label: 'Neonate' },
            { value: '2', label: 'Infant' },
            { value: '3', label: 'Child' },
            { value: '4', label: 'Adolescent' },
            { value: '5', label: 'Adult' },
            { value: '6', label: 'Elderly' }
          ]
        }
      },
      {
        id: 'patientweight',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientweight',
          label: 'Patient weight (kg)',
          required: false,
          validate: [validators.maxNumericLength(6, 'Field')]
        }
      },
      {
        id: 'patientheight',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientheight',
          label: 'Patient height (cm)',
          required: false,
          validate: [validators.maxNumericLength(3, 'Field')]
        }
      },
      {
        id: 'patientsex',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientsex',
          label: 'Patient sex',
          required: false,
          options: [
            { value: '1', label: 'Male' },
            { value: '2', label: 'Female' }
          ]
        }
      },
      {
        id: 'lastmenstrualdateformat',
        field: 'Input',
        computedValue: (values) => getRedAntFormatForDate(values.patientlastmenstrualdate),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'lastmenstrualdateformat',
          label: 'Transmission Date Format',
          required: true
        }
      },
      {
        id: 'patientlastmenstrualdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'patientlastmenstrualdate',
          label: 'Last menstrual period date',
          required: false,
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
        }
      }
    ]
  },
  patientHistory: {
    title: 'Patient History',
    layout: [
      'medicalhistoryepisode:12',
      'patientmedicalhistorytext:12',
      'patientpastdrugtherapy:12'
    ],
    schema: [
      {
        id: 'medicalhistoryepisode',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'medicalhistoryepisode',
          label: 'Medical history episode',
          repeatableLabelPlaceholder: 'Episode',
          repeatableLabel: ['patientepisodename'],
          required: false,
          schema: [
            {
              id: 'patientepisodename',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'patientepisodename',
                label: 'Patient medical history',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'patientmedicalstartdateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.patientmedicalstartdate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientmedicalstartdateformat',
                label: 'Patient medical history start date format',
                required: true
              }
            },
            {
              id: 'patientmedicalstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'patientmedicalstartdate',
                label: 'Patient medical history start date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'patientmedicalcontinue',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'patientmedicalcontinue',
                label: 'Patient medical history continuing?',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' },
                  { value: '3', label: 'Unknown' }
                ]
              }
            },
            {
              id: 'patientmedicalenddateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.patientmedicalenddate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientmedicalenddateformat',
                label: 'Patient medical history end date format',
                required: true
              }
            },
            {
              id: 'patientmedicalenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'patientmedicalenddate',
                label: 'Patient medical history end date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'patientmedicalcomment',
              field: 'Input',
              props: {
                shrink: true,
                name: 'patientmedicalcomment',
                label: 'Patient medical history comment',
                validate: [validators.maxLength(100, 'Field')],
                required: false
              }
            }
          ]
        }
      },
      {
        id: 'patientmedicalhistorytext',
        field: 'Input',
        props: {
          shrink: true,
          name: 'patientmedicalhistorytext',
          label: 'Patient medical history text',
          validate: [validators.maxLength(10000, 'Field')],
          required: false
        }
      },
      {
        id: 'patientpastdrugtherapy',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'patientpastdrugtherapy',
          label: 'Patient past drug therapy',
          repeatableLabelPlaceholder: 'Therapy',
          repeatableLabel: ['patientdrugname'],
          required: false,
          schema: [
            {
              id: 'patientdrugname',
              field: 'DrugLookupInput',
              props: {
                shrink: true,
                name: 'patientdrugname',
                label: 'Past drug name',
                required: false,
                validate: [validators.maxLength(100, 'Field')]
              }
            },
            {
              id: 'patientdrugstartdateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.patientdrugstartdate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientdrugstartdateformat',
                label: 'Past drug start date format',
                required: true
              }
            },
            {
              id: 'patientdrugstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'patientdrugstartdate',
                label: 'Past drug start date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'patientdrugenddateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.patientdrugenddate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'patientdrugenddateformat',
                label: 'Past drug end date format',
                required: true
              }
            },
            {
              id: 'patientdrugenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'patientdrugenddate',
                label: 'Past drug end date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'patientdrugindication',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'patientdrugindication',
                label: 'Past drug indication',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'patientdrugreaction',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'patientdrugreaction',
                label: 'Past drug reaction',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            }
          ]
        }
      }
    ]
  },
  death: {
    title: 'Death',
    layout: [
      'patientdeathdate:12',
      'patientdeathcause:12',
      'patientautopsyyesno:12',
      'patientautopsy:12'
    ],
    schema: [
      {
        id: 'patientdeathdateformat',
        field: 'Input',
        computedValue: (values) => getRedAntFormatForDate(values.patientdeathdate),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'patientdeathdateformat',
          label: 'Date of death format',
          required: true
        }
      },
      {
        id: 'patientdeathdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'patientdeathdate',
          label: 'Date of death',
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
          helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
        }
      },
      {
        id: 'patientdeathcause',
        field: 'Repeatable',
        computedEditable: (values) => !!values.patientdeathdate,
        props: {
          shrink: true,
          label: 'Cause of death',
          name: 'patientdeathcause',
          hideIfEmpty: true,
          required: true,
          repeatableLabelPlaceholder: 'Cause of death',
          repeatableLabel: ['patientdeathreport'],
          schema: [
            {
              id: 'patientdeathreport',
              field: 'MedDRALookupInput',
              props: {
                required: false,
                shrink: true,
                name: 'patientdeathreport',
                label: 'Reported cause of death',
                validate: [validators.maxLength(250, 'Field')]
              }
            }
          ]
        }
      },
      {
        id: 'patientautopsyyesno',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'patientautopsyyesno',
          label: 'Was autopsy done?',
          required: false,
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' },
            { value: '3', label: 'Unknown' }
          ]
        }
      },
      {
        id: 'patientautopsy',
        field: 'Repeatable',
        computedEditable: (values) => values.patientautopsyyesno === '1',
        props: {
          shrink: true,
          name: 'patientautopsy',
          hideIfEmpty: true,
          required: true,
          label: 'Patient Autopsy',
          repeatableLabelPlaceholder: 'Patient Autopsy',
          repeatableLabel: ['patientdetermineautopsy'],
          schema: [
            {
              id: 'patientdetermineautopsy',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'patientdetermineautopsy',
                label: 'Autopsy-determined cause of death',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            }
          ]
        }
      }
    ]
  },
  parent: {
    title: 'Parent',
    layout: [
      'parentidentification:6',
      'parentage:6',
      'parentageunit:6',
      'parentlastmenstrualdate:6',
      'parentweight:6',
      'parentheight:6',
      'parentsex:6',
      'parentmedicalhistoryepisode:12',
      'parentmedicalrelevanttext:6'
    ],
    schema: [
      {
        id: 'parentidentification',
        field: 'Input',
        props: {
          shrink: true,
          name: 'parentidentification',
          label: 'Parent initials',
          validate: [validators.maxLength(10, 'Field')]
        }
      },
      {
        id: 'parentage',
        field: 'Input',
        props: {
          shrink: true,
          name: 'parentage',
          label: 'Parent age (years)',
          validate: [validators.maxNumericLength(2, 'Field')]
        }
      },
      {
        id: 'parentageunit',
        field: 'Input',
        computedValue: (values) => values.parentage ? '801' : '',
        props: {
          shrink: true,
          name: 'parentageunit',
          label: 'Parent age unit',
          type: 'hidden'
        }
      },
      {
        id: 'parentlastmenstrualdateformat',
        field: 'Input',
        computedValue: (values) => getRedAntFormatForDate(values.parentlastmenstrualdate),
        props: {
          type: 'hidden',
          shrink: true,
          name: 'parentlastmenstrualdateformat',
          label: 'Parent last menstrual period date format',
          required: true
        }
      },
      {
        id: 'parentlastmenstrualdate',
        field: 'Date',
        props: {
          shrink: true,
          name: 'parentlastmenstrualdate',
          label: 'Parent last menstrual period date',
          validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
          helperText: 'YYYY/MM/DD'
        }
      },
      {
        id: 'parentweight',
        field: 'Input',
        props: {
          shrink: true,
          name: 'parentweight',
          label: 'Parent weight (kg)',
          validate: [validators.maxNumericLength(6, 'Field')]
        }
      },
      {
        id: 'parentheight',
        field: 'Input',
        props: {
          shrink: true,
          name: 'parentheight',
          label: 'Parent height (cm)',
          validate: [validators.maxNumericLength(3, 'Field')]
        }
      },
      {
        id: 'parentsex',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'parentsex',
          label: 'Parent sex',
          required: false,
          options: [
            { value: '1', label: 'Male' },
            { value: '2', label: 'Female' }
          ]
        }
      },
      {
        id: 'parentmedicalhistoryepisode',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'parentmedicalhistoryepisode',
          label: 'Parent medical history episode',
          repeatableLabelPlaceholder: 'Medical history episode',
          required: false,
          schema: [
            {
              id: 'parentmedicalepisodename',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'parentmedicalepisodename',
                label: 'Parent medical history',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'parentmedicalstartdateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.parentmedicalstartdate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'parentmedicalstartdateformat',
                label: 'Parent medical history start date format',
                required: true
              }
            },
            {
              id: 'parentmedicalstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'parentmedicalstartdate',
                label: 'Parent medical history start date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'parentmedicalcontinue',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'parentmedicalcontinue',
                label: 'Parent medical history continuing?',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' },
                  { value: '3', label: 'Unknown' }
                ]
              }
            },
            {
              id: 'parentmedicalenddateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.parentmedicalenddate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'parentmedicalenddateformat',
                label: 'Parent medical history end date format',
                required: true
              }
            },
            {
              id: 'parentmedicalenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'parentmedicalenddate',
                label: 'Parent medical history end date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            }
          ]
        }
      },
      {
        id: 'parentmedicalrelevanttext',
        field: 'Input',
        props: {
          shrink: true,
          name: 'parentmedicalrelevanttext',
          label: 'Parent medical history relevant text',
          validate: [validators.maxLength(10000, 'Field')]
        }
      }
    ]
  },
  medicine: {
    title: 'Drug',
    layout: [
      'drug:12'
    ],
    schema: [
      {
        id: 'drug',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'drug',
          label: 'Drugs',
          formId: 'drugrepeatable',
          repeatableLabelPlaceholder: 'Drug',
          repeatableLabel: ['medicinalproduct', 'drugcharacterization'],
          required: true,
          schema: [
            {
              id: 'drugcharacterization',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugcharacterization',
                label: 'Drug characterisation',
                required: true,
                options: [
                  { value: '1', label: 'Suspect' },
                  { value: '2', label: 'Concomitant' },
                  { value: '3', label: 'Interacting' }
                ]
              }
            },
            {
              id: 'medicinalproduct',
              field: 'DrugLookupInput',
              props: {
                shrink: true,
                name: 'medicinalproduct',
                label: 'Medicinal product',
                required: true,
                validate: [validators.maxLength(70, 'Field')]
              }
            },
            {
              id: 'activesubstancename',
              field: 'Input',
              props: {
                shrink: true,
                name: 'activesubstancename',
                label: 'Active substance',
                required: false,
                validate: [validators.maxLength(100, 'Field')]
              }
            },
            {
              id: 'obtaindrugcountry',
              field: 'Dropdown',
              props: {
                shrink: true,
                autocomplete: true,
                options: countryCodes,
                name: 'obtaindrugcountry',
                label: 'Country where drug was obtained',
                required: false
              }
            },
            {
              id: 'drugbatchnumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugbatchnumb',
                label: 'Batch/Lot number',
                required: false,
                validate: [validators.maxLength(35, 'Field')]
              }
            },
            {
              id: 'drugauthorizationholder',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugauthorizationholder',
                label: 'Drug authorisation holder',
                required: false,
                validate: [validators.maxLength(60, 'Field')]
              }
            },
            {
              id: 'drugstructuredosagenumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugstructuredosagenumb',
                label: 'Dose (number)',
                required: false,
                validate: [validators.maxNumericLength(8, 'Field')]
              }
            },
            {
              id: 'drugstructuredosageunit',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugstructuredosageunit',
                label: 'Dose (unit)',
                required: false,
                validate: [validators.maxNumericLength(3, 'Field')]
              }
            },
            {
              id: 'drugseparatedosagenumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugseparatedosagenumb',
                label: 'Number of separate doses',
                required: false,
                validate: [validators.maxNumericLength(3, 'Field')]
              }
            },
            {
              id: 'drugintervaldosageunitnumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugintervaldosageunitnumb',
                label: 'Number of units in interval',
                required: false,
                validate: [validators.maxNumericLength(3, 'Field')]
              }
            },
            {
              id: 'drugintervaldosagedefinition',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugintervaldosagedefinition',
                label: 'Dosage time interval unit',
                required: false,
                options: [
                  { value: '801', label: 'Year' },
                  { value: '802', label: 'Month' },
                  { value: '803', label: 'Week' },
                  { value: '804', label: 'Day' },
                  { value: '805', label: 'Hour' },
                  { value: '806', label: 'Minute' }
                ]
              }
            },
            {
              id: 'drugcumulativedosagenumb',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugcumulativedosagenumb',
                label: 'Cumulative dose to first reaction',
                required: false,
                validate: [validators.maxNumericLength(10, 'Field')]
              }
            },
            {
              id: 'drugcumulativedosageunit',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugcumulativedosageunit',
                label: 'Cumulative dose to first reaction (unit)',
                required: false,
                validate: [validators.maxLength(3, 'Field')]
              }
            },
            {
              id: 'drugdosagetext',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugdosagetext',
                label: 'Dosage text',
                required: false,
                validate: [validators.maxLength(100, 'Field')]
              }
            },
            {
              id: 'drugdosageform',
              field: 'Input',
              props: {
                shrink: true,
                name: 'drugdosageform',
                label: 'Pharmaceutical form',
                required: false,
                validate: [validators.maxLength(50, 'Field')]
              }
            },
            {
              id: 'drugadministrationroute',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugadministrationroute',
                label: 'Route of administration',
                required: false,
                options: drugAdministrationRoutes
              }
            },
            {
              id: 'drugparadministration',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugparadministration',
                label: 'Parent route of administration',
                required: false,
                options: drugAdministrationRoutes
              }
            },
            {
              id: 'reactiongestationperiod',
              field: 'Input',
              props: {
                shrink: true,
                name: 'reactiongestationperiod',
                label: 'Gestation period at time of exposure',
                required: false,
                validate: [validators.maxNumericLength(3, 'Field')]
              }
            },
            {
              id: 'reactiongestationperiodunit',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'reactiongestationperiodunit',
                label: 'Gestation period at time of exposure (unit)',
                required: false,
                options: [
                  { value: '802', label: 'Month' },
                  { value: '803', label: 'Week' },
                  { value: '804', label: 'Day' },
                  { value: '810', label: 'Trimester' }
                ]
              }
            },
            {
              id: 'drugindication',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'drugindication',
                label: 'Drug indication',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'drugstartdateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.drugstartdate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'drugstartdateformat',
                label: 'Drug start date format',
                required: true
              }
            },
            {
              id: 'drugstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'drugstartdate',
                label: 'Drug start date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'drugenddateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.drugenddate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'drugenddateformat',
                label: 'Drug end date format',
                required: true
              }
            },
            {
              id: 'drugenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'drugenddate',
                label: 'Drug end date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'actiondrug',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'actiondrug',
                label: 'Action taken with drug',
                required: false,
                options: [
                  { value: '1', label: 'Drug withdrawn' },
                  { value: '2', label: 'Dose reduced' },
                  { value: '3', label: 'Dose increased' },
                  { value: '4', label: 'Dose not changed' },
                  { value: '5', label: 'Unknown' },
                  { value: '6', label: 'Not applicable' }
                ]
              }
            },
            {
              id: 'drugrecurreadministration',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'drugrecurreadministration',
                label: 'Did reaction recur on readministration?',
                required: false,
                options: [
                  { value: '1', label: 'Yes' },
                  { value: '2', label: 'No' },
                  { value: '3', label: 'Unknown' }
                ]
              }
            },
            {
              id: 'drugrecuraction',
              field: 'Input',
              computedEditable: (values) => values.drugrecurreadministration === '1',
              props: {
                shrink: true,
                name: 'drugrecuraction',
                label: 'If yes, which reaction(s)/event(s) recurred?',
                required: false,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'susarEditable',
              field: 'Input',
              computedValue: (values, parentFormValues) => susarEditable(parentFormValues) ? 'true' : 'false',
              props: {
                type: 'hidden',
                name: 'susarEditable'
              }
            },
            {
              id: 'spontaneousEditable',
              field: 'Input',
              computedValue: (values, parentFormValues) => spontaneousEditable(parentFormValues) ? 'true' : 'false',
              props: {
                type: 'hidden',
                name: 'spontaneousEditable'
              }
            },
            {
              id: 'drugreactionrelatedness',
              field: 'Repeatable',
              computedEditable: (values) => susarEditable(values) || spontaneousEditable(values),
              props: {
                hideIfEmpty: true,
                formId: 'drugreactionrelatednessform',
                name: 'drugreactionrelatedness',
                label: 'Relatedness of drug to reaction',
                repeatableLabel: ['drugreactionasses'],
                repeatableLabelPlaceholder: 'Related reaction',
                shrink: true,
                required: true,
                modalIndex: 1,
                schema: [
                  {
                    id: 'drugreactionasses',
                    field: 'MedDRALookupInput',
                    computedEditable: (values, parentFormValues) => susarEditable(parentFormValues) || spontaneousEditable(parentFormValues),
                    props: {
                      modalIndex: 2,
                      hideIfEmpty: true,
                      shrink: true,
                      name: 'drugreactionasses',
                      label: 'Reaction assessed',
                      required: true,
                      validate: [validators.maxLength(250, 'Field')]
                    }
                  },
                  {
                    id: 'drugassessmentsource',
                    field: 'Input',
                    computedEditable: (values, parentFormValues) => susarEditable(parentFormValues) || spontaneousEditable(parentFormValues),
                    props: {
                      hideIfEmpty: true,
                      shrink: true,
                      name: 'drugassessmentsource',
                      label: 'Source of assessment',
                      required: false,
                      validate: [validators.maxLength(60, 'Field')]
                    }
                  },
                  {
                    id: 'drugassessmentmethod',
                    field: 'Input',
                    computedEditable: (values, parentFormValues) => susarEditable(parentFormValues),
                    props: {
                      hideIfEmpty: true,
                      shrink: true,
                      name: 'drugassessmentmethod',
                      label: 'Method of assessment',
                      required: false,
                      validate: [validators.maxLength(35, 'Field')]
                    }
                  },
                  {
                    id: 'drugresult',
                    field: 'Input',
                    computedEditable: (values, parentFormValues) => susarEditable(parentFormValues),
                    props: {
                      hideIfEmpty: true,
                      shrink: true,
                      name: 'drugresult',
                      label: 'Result of assessment',
                      required: false,
                      validate: [validators.maxLength(35, 'Field')]
                    }
                  }
                ]
              }
            }
            // {
            //   id: 'drugreactionasses',
            //   field: 'MedDRALookupInput',
            //   computedEditable: (values, parentFormValues) => spontaneousEditable(values, parentFormValues) || susarEditable(values, parentFormValues),
            //   props: {
            //     hideIfEmpty: true,
            //     shrink: true,
            //     name: 'drugreactionasses',
            //     label: 'Reaction assessed',
            //     required: true,
            //     validate: [validators.maxLength(250, 'Field')]
            //   }
            // },
            // {
            //   id: 'drugassessmentsource',
            //   field: 'Input',
            //   computedEditable: (values, parentFormValues) => spontaneousEditable(values, parentFormValues) || susarEditable(values, parentFormValues),
            //   props: {
            //     hideIfEmpty: true,
            //     shrink: true,
            //     name: 'drugassessmentsource',
            //     label: 'Source of assessment',
            //     required: false,
            //     validate: [validators.maxLength(60, 'Field')]
            //   }
            // },
            // {
            //   id: 'drugassessmentmethod',
            //   field: 'Input',
            //   computedEditable: (values, parentFormValues) => susarEditable(values, parentFormValues),
            //   props: {
            //     hideIfEmpty: true,
            //     shrink: true,
            //     name: 'drugassessmentmethod',
            //     label: 'Method of assessment',
            //     required: false,
            //     validate: [validators.maxLength(35, 'Field')]
            //   }
            // },
            // {
            //   id: 'drugresult',
            //   field: 'Input',
            //   computedEditable: (values, parentFormValues) => susarEditable(values, parentFormValues),
            //   props: {
            //     hideIfEmpty: true,
            //     shrink: true,
            //     name: 'drugresult',
            //     label: 'Result of assessment',
            //     required: false,
            //     validate: [validators.maxLength(35, 'Field')]
            //   }
            // }
          ]
        }
      }
    ]
  },
  reaction: {
    title: 'Reaction',
    layout: [
      'reaction:12'
    ],
    schema: [
      {
        id: 'reaction',
        field: 'Repeatable',
        props: {
          name: 'reaction',
          shrink: true,
          label: 'Reaction',
          repeatableLabel: ['primarysourcereaction', 'reactionmeddrallt'],
          required: true,
          schema: [
            {
              id: 'primarysourcereaction',
              field: 'Input',
              props: {
                shrink: true,
                name: 'primarysourcereaction',
                label: 'Reaction as reported by primary source',
                required: true,
                validate: [validators.maxLength(200, 'Field')]
              }
            },
            {
              id: 'reactionmeddrallt',
              field: 'MedDRALookupInput',
              props: {
                shrink: true,
                name: 'reactionmeddrallt',
                label: 'Reaction (MedDRA LLT)',
                required: true,
                validate: [validators.maxLength(250, 'Field')]
              }
            },
            {
              id: 'reactionstartdateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.reactionstartdate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'reactionstartdateformat',
                label: 'Reaction start date format',
                required: true
              }
            },
            {
              id: 'reactionstartdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'reactionstartdate',
                label: 'Reaction start date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'reactionenddateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.reactionenddate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'reactionenddateformat',
                label: 'Reaction end date format',
                required: true
              }
            },
            {
              id: 'reactionenddate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'reactionenddate',
                label: 'Reaction end date',
                required: false,
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'reactionoutcome',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'reactionoutcome',
                label: 'Reaction outcome',
                required: true,
                options: [
                  { value: '1', label: 'Recovered/resolved' },
                  { value: '2', label: 'Recovering/resolving' },
                  { value: '3', label: 'Not recovered/not resolved' },
                  { value: '4', label: 'Reaction ended but with after effects' },
                  { value: '5', label: 'Fatal' },
                  { value: '6', label: 'Unknown' }
                ]
              }
            }
          ]
        }
      },
      {
        id: 'seriousnessdeath',
        field: 'Dropdown',
        computedValue: hasFatalReaction,
        props: {
          disabled: true,
          shrink: true,
          name: 'seriousnessdeath',
          label: 'Results in death',
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ]
        }
      },
      {
        id: 'seriousnesslifethreatening',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'seriousnesslifethreatening',
          label: 'Life threatening',
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ]
        }
      },
      {
        id: 'seriousnesshospitalization',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'seriousnesshospitalization',
          label: 'Caused/prolonged hospitalization',
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ]
        }
      },
      {
        id: 'seriousnessdisabling',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'seriousnessdisabling',
          label: 'Disabling/Incapacitating',
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ]
        }
      },
      {
        id: 'seriousnesscongenitalanomali',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'seriousnesscongenitalanomali',
          label: 'Congenital anomaly/birth defect',
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ]
        }
      },
      {
        id: 'seriousnessother',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'seriousnessother',
          label: 'Other medically important',
          options: [
            { value: '1', label: 'Yes' },
            { value: '2', label: 'No' }
          ]
        }
      }
    ]
  },
  test: {
    title: 'Test',
    layout: [
      'test:12'
    ],
    schema: [
      {
        id: 'test',
        field: 'Repeatable',
        props: {
          shrink: true,
          name: 'test',
          label: 'Test',
          repeatableLabel: ['testdate', 'testname'],
          schema: [
            {
              id: 'testdateformat',
              field: 'Input',
              computedValue: (values) => getRedAntFormatForDate(values.testdate),
              props: {
                type: 'hidden',
                shrink: true,
                name: 'testdateformat',
                label: 'Reaction end date format',
                required: true
              }
            },
            {
              id: 'testdate',
              field: 'Date',
              props: {
                shrink: true,
                name: 'testdate',
                label: 'Test date',
                validate: [validators.isAcceptedDateFormat(['YYYYMMDD', 'YYYY', 'YYYYMM'])],
                helperText: 'YYYY, YYYY/MM, YYYY/MM/DD'
              }
            },
            {
              id: 'testname',
              field: 'Input',
              props: {
                shrink: true,
                name: 'testname',
                label: 'Test name',
                validate: [validators.maxLength(100, 'Field')]
              }
            },
            {
              id: 'testresult',
              field: 'Input',
              props: {
                shrink: true,
                name: 'testresult',
                label: 'Test Result',
                validate: [validators.maxLength(50, 'Field')]
              }
            },
            {
              id: 'testunit',
              field: 'Input',
              props: {
                shrink: true,
                name: 'testunit',
                label: 'Test result (unit)',
                validate: [validators.maxLength(35, 'Field')]
              }
            }
          ]
        }
      },
      {
        id: 'resultstestsprocedures',
        field: 'Input',
        props: {
          shrink: true,
          name: 'resultstestsprocedures',
          label: 'Test additional text',
          validate: [validators.maxLength(2000, 'Field')]
        }
      }
    ]
  },
  narrative: {
    title: 'Narrative',
    schema: [
      {
        id: 'narrativeincludeclinical',
        field: 'Input',
        props: {
          shrink: true,
          name: 'narrativeincludeclinical',
          label: 'Case narrative',
          validate: [validators.maxLength(20000, 'Field')]
        }
      },
      {
        id: 'reportercomment',
        field: 'Input',
        props: {
          shrink: true,
          name: 'reportercomment',
          label: `Reporter's comments`,
          validate: [validators.maxLength(500, 'Field')]
        }
      },
      {
        id: 'sendercomment',
        field: 'Input',
        props: {
          shrink: true,
          name: 'sendercomment',
          label: `Sender's comments`,
          validate: [validators.maxLength(2000, 'Field')]
        }
      }
    ]
  }
}

export default {
  initialFieldMap,
  formSchema
}
