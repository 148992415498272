import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Paper } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

import { selectors as reportDetailsSelectors, actions as reportDetailsActions } from '../../../../../store/modules/reportDetails'
import MarkdownScreen from '../../../../../components/MarkdownScreen'
import Button from '../../../../../components/Button'
import P from '../../../../../components/P'
import { translations } from '../../../../../config'

const StyledPaper = styled(Paper)(() => ({
  height: '100%'
}))

const ErrorContainer = styled(Grid)(() => ({
  height: '100%',
  textAlign: 'center',
  boxSizing: 'border-box',
  padding: 10
}))

const Notification = ({ notificationId }) => {
  const followUpNotification = useSelector(reportDetailsSelectors.getSelectedFollowupNotification)
  const hasError = useSelector(reportDetailsSelectors.getSelectedFollowUpNotificationError)
  const dispatch = useDispatch()

  function handleRetry() {
    dispatch(reportDetailsActions.fetchReportFollowupNotification({ id: notificationId }))
  }

  return (
    <StyledPaper variant='outlined' square='true'>
      { hasError ?
        <ErrorContainer container alignItems='center' justify='center' direction='column'>
          <P value={translations('Report - Notification Error')}/>
          <Button onClick={handleRetry}>{translations('Retry')}</Button>
        </ErrorContainer>
        :
        <MarkdownScreen markdownData={`## ${followUpNotification?.title}\n${followUpNotification?.body}`}/>
      }
    </StyledPaper>
  )
}

export default Notification