"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var fscaHelpers = _interopRequireWildcard(require("./formHelpers"));

var _computedHidden = require("../../../../../../formHelpers/computedHidden");

var _computedValue = require("../../../../../../formHelpers/computedValue");

var _options = require("../../../../../../formHelpers/options");

var _config = require("../../../../../../config");

var _general = require("../../../../../../formHelpers/devices/general");

var _devices = require("../../../../../../formHelpers/devices");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'mfrInternalNo',
    field: 'Input',
    props: {
      shrink: true,
      name: 'mfrInternalNo',
      label: 'Reference assigned by the manufacturer',
      required: true,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'reportDate',
    field: 'Date',
    computedValue: _devices.audience.match({
      INDUSTRY: function INDUSTRY() {
        return (0, _computedValue.getTodayDate)();
      }
    }),
    props: {
      shrink: true,
      disabled: true,
      name: 'reportDate',
      label: 'Date of submission',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'ncaIncidenceReportNo',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ncaIncidenceReportNo',
      label: 'Incidence reference number assigned by NCA',
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'ncaCoordinating',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ncaCoordinating',
      label: 'Name of the co-ordinating NCA Competent Authority (if applicable)',
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'mfrContactName',
    field: 'Input',
    props: {
      shrink: true,
      name: 'mfrContactName',
      label: 'Contact Name',
      required: true,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'ncpName',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ncpName',
      label: 'National contact point organisation name',
      required: true,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'ncpContactPerson',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ncpContactPerson',
      label: 'Name of the contact person',
      required: true,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'ncpEmail',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ncpEmail',
      label: 'E-mail',
      required: true,
      validate: [validators.isValidEmail, validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'ncpPhone',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ncpPhone',
      label: 'Phone',
      required: true,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'ncpCountry',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'ncpCountry',
      label: 'Country',
      required: true,
      options: _config.deviceDistributionCountriesDropdownOptions
    }
  }, {
    id: 'ncpAddress',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ncpAddress',
      label: 'Address',
      required: true,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'ncpCity',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ncpCity',
      label: 'City name',
      required: true,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'ncpPostcode',
    field: 'Input',
    props: {
      shrink: true,
      name: 'ncpPostcode',
      label: 'Postcode',
      required: true,
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'ARContactPerson',
    field: 'Input',
    computedRequired: fscaHelpers.isAuthorisedRep,
    props: {
      shrink: true,
      name: 'ARContactPerson',
      label: 'Contact Name',
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'deviceClass',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'deviceClass',
      label: 'Class',
      required: true,
      options: (0, _options.mapValueAndLabel)(['AIMD Active implants', 'MDD Class III', 'MDD Class IIb', 'MDD Class IIa', 'MDD Class I', 'IVD Devices for self-testing', 'IVD Annex II List A', 'IVD Annex II List B', 'IVD General'])
    }
  }, {
    id: 'devicemfrDate',
    field: 'Date',
    props: {
      disabled: false,
      shrink: true,
      name: 'devicemfrDate',
      label: 'Device manufacturing date',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'nomenclatureCodeDefinedInText',
    field: 'Input',
    props: {
      shrink: true,
      name: 'nomenclatureCodeDefinedInText',
      label: 'Nomenclature text',
      validate: [validators.maxLength(50, 'Field')]
    }
  }, {
    id: 'fscaBackground',
    field: 'Input',
    props: {
      shrink: true,
      name: 'fscaBackground',
      label: 'Background information and reason for the FSCA',
      required: true,
      multiline: true,
      rows: 5
    }
  }, {
    id: 'fscaDescription',
    field: 'Input',
    props: {
      shrink: true,
      name: 'fscaDescription',
      label: 'Description and justification of the action (corrective / preventive)',
      required: true,
      multiline: true,
      rows: 5
    }
  }, {
    id: 'fscaAdvice',
    field: 'Input',
    props: {
      shrink: true,
      name: 'fscaAdvice',
      label: 'Advice on actions to be taken by the distributor and the user',
      multiline: true,
      rows: 5
    }
  }, {
    id: 'fscaProgress',
    field: 'Input',
    computedRequired: (0, fscaHelpers.reportTypeMatch)({
      INITIAL: _computedHidden.optional,
      FOLLOW_UP: _computedHidden.optional,
      FINAL: _computedHidden.required
    }),
    props: {
      shrink: true,
      name: 'fscaProgress',
      label: 'Progress of FSCA, together with reconciliation data',
      multiline: true,
      rows: 2
    }
  }, {
    id: 'fscaTimeSchedule',
    field: 'Input',
    props: {
      shrink: true,
      name: 'fscaTimeSchedule',
      label: 'Time schedule for the implementation of the different actions',
      required: true
    }
  }, {
    id: 'fscaAttachmentStatus',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'fscaAttachmentStatus',
      label: 'FSN status',
      options: [{
        label: 'Draft FSN',
        value: 'Draft'
      }, {
        label: 'Final FSN',
        value: 'Final'
      }]
    }
  }, {
    id: 'fscaAttachmentFSNType',
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'fscaAttachmentFSNType',
      label: 'Attached Please Find',
      multiple: true,
      options: [{
        label: 'Field Safety Notice (FSN) in English',
        value: 'fscaAttachmentFSNEnglish'
      }, {
        label: 'FSN in national language',
        value: 'fscaAttachmentFSNNational'
      }, {
        label: 'Others (please specify)',
        value: 'fscaAttachmentFSNOther'
      }]
    }
  }, {
    id: 'fscaAttachmentOther',
    field: 'Input',
    computedRequired: fscaHelpers.doesFscaAttachmentFsnTypeIncludeOther,
    computedHidden: fscaHelpers.visibleIfFscaAttachmentFSNTypeHasOther,
    computedValue: fscaHelpers.clearIfFscaAttachmentFSNTypeDoesNoHasOther,
    props: {
      shrink: true,
      name: 'fscaAttachmentOther',
      label: 'Attached Please Find (Other)'
    }
  }, {
    id: 'fscaComments',
    field: 'Input',
    props: {
      shrink: true,
      name: 'fscaComments',
      label: 'Comments',
      multiline: true,
      rows: 5
    }
  }, {
    id: 'distributionEEA',
    field: 'Dropdown',
    computedHidden: fscaHelpers.visibleIfisNotDistributingInAllDeviceDistributionCountries,
    computedValue: fscaHelpers.clearIfisNotDistributingInAllDeviceDistributionCountries,
    computedRequired: fscaHelpers.areNoDistributionOptionsSelected,
    props: {
      label: 'Distribution (Multiple choice)',
      name: 'distributionEEA',
      required: false,
      shrink: true,
      options: _config.deviceDistributionCountriesDropdownOptions,
      multiple: true,
      validate: [validators.minimumOf1(_general.distributionFieldsValidation.fields, _general.distributionFieldsValidation.error)]
    }
  }, {
    id: 'distributionOutsideEU',
    field: 'Dropdown',
    computedRequired: fscaHelpers.areNoDistributionOptionsSelected,
    props: {
      shrink: true,
      name: 'distributionOutsideEU',
      label: 'Other Countries (including Northern Ireland)',
      multiple: true,
      options: _config.nonDeviceDistributionCountriesDropdownOptions,
      validate: [validators.minimumOf1(_general.distributionFieldsValidation.fields, _general.distributionFieldsValidation.error)]
    }
  }];
};

exports.getFormSchema = getFormSchema;