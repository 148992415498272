import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Drawer from '../../../../components/Drawer'
import { useQuery } from '../../../../hooks'
import { FollowUpContext } from '../../../../contexts'
import { hooks as reportConfigurationHooks, actions as reportConfigurationActions } from '../../../../store/modules/reportConfiguration'
import { removeQuery } from '../../../../helpers/query'
import ConfirmationModal from '../../ReportConfigurationEditorPanel/modals/ConfirmationModal/ConfirmationModal'
import modalService from '../../../../services/modalService'
import { translations } from '../../../../config'

import ManageFollowUpContents from './ManageFollowUpContents'

const viewJsonPath = 'viewJSON.formViewVersions.0.viewJSON'

const ManageFollowUpDrawer = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)
  const [hasEdits, setHasEdits] = useState(false)
  const { followUp: followUpMeta, reporter } = useQuery()
  const { followUpId, followUpReportFormViewId } = followUpMeta || {}
  const { id: reportConfigurationId } = useParams() || ''
  const followUpDetails = reportConfigurationHooks.useFollowUpReportConfiguration({ reportConfigurationId, followUpId }) || {}
  const viewJSON = _.get(followUpDetails, viewJsonPath, [])

  const handleClose = ({ saving }) => {
    if (hasEdits && !saving) {
      modalService.open({
        component: ConfirmationModal,
        title: translations('Warning'),
        message: translations(`FollowUp - Unsaved Changes`),
        onConfirmation: () => {
          onClose()
        }
      })
    } else {
      onClose()
    }
  }
  const onClose = () => {
    setOpen(false)
    removeQuery(['followUp'])
  }

  useEffect(() => {
    setOpen(!_.isUndefined(followUpMeta))
  }, [followUpMeta])

  useEffect(() => {
    if (!_.isUndefined(followUpId)) {
      dispatch(reportConfigurationActions.fetchFollowUpViewJSON({ followUpReportFormViewId, followUpId, reportConfigurationId }))
    }
  }, [followUpId])

  return (
    <Drawer
      heading={'Follow Up Report Configuration'}
      anchor={'right'}
      open={open}
      onClose={handleClose}
      hideBackdrop={!_.isUndefined(reporter)}
    >
      <FollowUpContext.FollowUpProvider viewJson={viewJSON}>
        <ManageFollowUpContents onClose={handleClose} setHasEdits={setHasEdits} formViewId={reportConfigurationId} />
      </FollowUpContext.FollowUpProvider>
    </Drawer>
  )
}

ManageFollowUpDrawer.props = {}

ManageFollowUpDrawer.propTypes = {}

export default ManageFollowUpDrawer
