import React, { Component } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { translations } from '../../../../config'
import Option from '../Option'
import Label from '../Label'
import ErrorText from '../ErrorText'
import Downshift from '../Downshift'
import VirtualizedMenuList from '../VirtualizedMenuList'
import CustomPaper, { paperHeight } from '../Paper'
import style from './style'

const renderSuggestion = props => {
  const { suggestion, index, itemProps, highlightedIndex, dropdownProps } = props

  const isHighlighted = highlightedIndex === index

  return <Option
    key={index}
    dropdownProps={dropdownProps}
    isFocused={isHighlighted}
    {...suggestion}
    {...itemProps}
  />
}

const getSuggestions = ({ inputValue, options: optionGroups }) => {
  let count = 0
  const suggestions = []

  const maxCount = 1000000

  // find up to 5 options with labels that contain inputValue
  for (let i = 0; i < optionGroups.length; i++) {
    // iterate through groups of options
    const options = optionGroups[i]
    const suggestionsForGroup = []

    for (let j = 0; j < options.length && count <= maxCount; j++) {
      // iterate through each option in a group
      const option = options[j]

      // if option has a value, and the label matches the input,
      // OR the input is blank - push it
      if (
        option.value && option.label &&
        (
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
          !inputValue
        )
      ) {
        // iterate count so that max 5 suggestions are returned
        count++
        suggestionsForGroup.push(option)
      }
    }

    // if the group contains any matching items, push the matching suggestions
    // for that group to the array of arrays we will return
    if (suggestionsForGroup.length) {
      suggestions.push(suggestionsForGroup)
    }
  }

  // if no matching options found, show a no results message
  if (!suggestions.length) {
    suggestions.push([{
      label: translations('No Results'),
      value: null,
      nullOption: true
    }])
  }

  return suggestions
}

class AutocompleteDropdown extends Component {
  getSelectedItemFromInputValue = inputValue => {
    const { options, handleChangeOtherFields } = this.props
    const selectedItem = _.flatten(options).find(({ value }) => value === inputValue)
    if (_.isFunction(handleChangeOtherFields)) {
      handleChangeOtherFields(selectedItem)
    }
    return selectedItem
  }

  getInitialSelectedItem = () => {
    const { value } = this.props
    const selectedItem = (
      value
        ? (
          value.value
            ? value
            : this.getSelectedItemFromInputValue(value)
        )
        : null
    )
    return selectedItem
  }

  state = {
    selectedItem: this.getInitialSelectedItem(),
    inputValue: ''
  }

  componentDidUpdate(prevProps) {
    const { options, value } = this.props
    if (!_.isEqual(prevProps.options, options) || value !== prevProps.value) {
      this.setState({ selectedItem: this.getInitialSelectedItem() })
    }
  }

  handleInput = (e) => {
    this.setState({ inputValue: e.target.value })
    if (this.props.fetch) {
      this.props.fetch(e.target.value)
    }
  }

  onChange = (item = {}) => {
    const { onChange, handleChange } = this.props
    if (onChange) {
      onChange(item.value)
    }
    if (handleChange) {
      handleChange(item.value)
    }
  }

  itemToString = (item) => {
    const { value, label } = item || {}
    if (value) return label
    return ''
  }

  getItemValue = (item) => {
    const { value } = item || {}
    if (value) return value
    return ''
  }

  handleStateChange = (changes) => {
    const { selectedItem } = changes
    if (selectedItem) {
      this.setState({ selectedItem })
    }
  }

  clear = () => {
    this.setState({ selectedItem: null })
    this.onChange({ value: null })
  }

  renderClearButton = () => {
    const { classes } = this.props
    const { selectedItem } = this.state
    if (selectedItem) {
      return <div className={classes.clearButtonContainer}>
        <div
          className={classes.clearButton}
          onClick={this.clear}
        />
      </div>
    } else {
      return null
    }
  }

  _searchHeight = 60
  renderSearch = inputProps => {
    const { classes } = this.props
    const { ref, ...restOfInputProps } = inputProps
    return <div
      className={classes.searchContainer}
      style={{ height: this._searchHeight }}
    >
      <div className={classes.searchBar}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          inputRef={ref}
          fullWidth
          classes={{
            root: classes.searchInput
          }}
          inputProps={restOfInputProps}
          disableUnderline
        />
      </div>
    </div>
  }

  renderDownshift = params => {
    const {
      options,
      name,
      classes,
      className,
      noErrorTextLabel,
      noFloatingLabel,
      meta,
      label,
      listWidth,
      shrink,
      required,
      onBlur
    } = this.props

    const { selectedItem } = this.state

    const {
      getLabelProps,
      getInputProps,
      getItemProps,
      highlightedIndex,
      inputValue,
      isOpen,
      openMenu
    } = params

    const containerClass = classNames(classes.container, className)

    const labelEl = <Label
      {...getLabelProps()}
      label={label}
      noFloatingLabel={noFloatingLabel}
      shrink={_.isUndefined(shrink) ? selectedItem : shrink}
      required={required}
    />

    let suggestionsCount = -1
    const suggestionEls = _.chain(getSuggestions({ inputValue, options }))
      .map((suggestionsGroup, i, suggestionsGroups) => {
        return suggestionsGroup.map((suggestion, j) => {
          suggestionsCount++
          return renderSuggestion({
            dropdownProps: this.props,
            highlightedIndex,
            index: suggestionsCount,
            itemProps: getItemProps({ item: suggestion }),
            suggestion
          })
        })
      })
      .flatten()
      .value()
    const listHeight = Math.min(suggestionEls.length * 48, paperHeight)
    const suggestionsEl = (
      isOpen
        ? <Paper
          className={classes.paper}
          component={CustomPaper}
          minWidth={listWidth}
          height={listHeight + this._searchHeight}
        >
          {this.renderSearch(getInputProps({
            id: name,
            onChange: this.handleInput,
            onFocus: openMenu
          }))}
          <VirtualizedMenuList height={listHeight}>
            {suggestionEls}
          </VirtualizedMenuList>
        </Paper>
        : null
    )

    const clearButtonEl = this.renderClearButton()
    const errorEl = <ErrorText
      meta={meta}
      noErrorTextLabel={noErrorTextLabel}
    />

    const selectEl = <Input
      {...getInputProps()}
      value={this.itemToString(selectedItem)}
      readOnly
      onClick={openMenu}
      onFocus={openMenu}
      className={classes.selectInput}
      onBlur={() => onBlur(this.getItemValue(selectedItem))}
    />

    return <div className={containerClass}>
      {labelEl}
      <div className={classes.inputContainer}>
        {selectEl}
        <ArrowDropDownIcon color={'action'} className={classes.selectIcon} />
        {clearButtonEl}
      </div>
      {suggestionsEl}
      {errorEl}
    </div>
  }

  render() {
    return <Downshift
      onChange={this.onChange}
      defaultHighlightedIndex={0}
      itemToString={this.itemToString}
      selectedItem={this.state.selectedItem}
      inputValue={this.state.inputValue}
      onStateChange={this.handleStateChange}
    >
      {this.renderDownshift}
    </Downshift>
  }
}

export default withStyles(style)(AutocompleteDropdown)
