import React, { Fragment } from 'react'
import { compose, withPropsOnChange, lifecycle } from 'recompose'
import _ from 'lodash'

import fsService from '../services/fsService'

let removableEventListener = (el, event, fn) => {
  el.addEventListener(event, fn)
  return () => {
    el.removeEventListener(event, fn)
  }
}

const enhancer = compose(
  withPropsOnChange([], ({ onChange, onClick }) => {
    let inputRef
    let removeListener = () => {}

    return {
      removeListener,
      setRef: ref => {
        if (!ref) {
          removeListener()
          return
        }

        inputRef = ref
        removeListener = removableEventListener(ref, 'change', onChange)
      },
      onClick: (e) => {
        if (window.cordova && window.device.platform.toLowerCase() === 'android' && window.chooser) {
          // https://www.npmjs.com/package/cordova-plugin-chooser
          window.chooser.getFile()
            .then(file => {
              // get file blob
              const uri = _.get(file, 'uri')
              if (uri) {
                return fsService.loadBlob(uri)
              } else {
                throw new Error('no file')
              }
            })
            .then(blob => {
              // faking the same structure as an event from a file input
              const evt = { target: { files: [blob] } }
              onChange(evt)
            })
        } else {
          inputRef.value = null
          inputRef.click()
          onClick && onClick(e)
        }
      }
    }
  }),
  lifecycle({
    componentWillUnmount () {
      this.props.removeListener()
    }
  })
)

const FileBrowserEnhancer = ({ setRef, onClick, renderContent, accept = [], multiple = false }) => (
  <Fragment>
    <input type='file' ref={setRef} multiple={multiple} style={{ display: 'none' }} accept={accept.join(',')} />
    {renderContent({ onClick })}
  </Fragment>
)

export default enhancer(FileBrowserEnhancer)
