import moment from 'moment'
import { translations } from '../../../../../../../config'

const formatDate = (date) => moment(date).format('DD/MM/YYYY HH:mm')

const Information = ({
  createdByUser,
  createdByUserAt,
  modifiedByUser,
  modifiedByUserAt
}) => {

  return (
    <>
      {
        createdByUser && (
          <p>{`${translations('Created By')}: ${createdByUser}`}</p>
        )
      }
      {
        createdByUserAt && (
          <p>{`${translations('Created At')}: ${formatDate(createdByUserAt)}`}</p>
        )
      }
      {
        modifiedByUser && (
          <p>{`${translations('Modified By')}: ${modifiedByUser}`}</p>
        )
      }
      {
        modifiedByUserAt && (
          <p>{`${translations('Modified At')}: ${formatDate(modifiedByUserAt)}`}</p>
        )
      }
    </>
  )
}

export default Information