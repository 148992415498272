import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'

import MoreVertIcon from '@material-ui/icons/MoreVert'

import { translations } from '../../config'

/**
 * Extra configuration options for sections and fields
 */
export const MoreMenu = (props) => {
  const { id, options } = props
  const elementId = `more-menu-${id}`

  const [anchorEl, setAnchorEl] = React.useState(null)

  const onOpen = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleClick = ({ event, onClick }) => {
    event.stopPropagation()
    onClick({ event })
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label={translations('More options')}
        size='small'
        aria-controls={elementId}
        aria-haspopup='true'
        onClick={onOpen}
        color='inherit'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={elementId}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {_.map(options, (option) => {
          const { label, onClick = _.noop, iconComponent: Icon, divider = false } = option
          return (
            <MenuItem
              key={label}
              dense
              button
              divider={divider}
              onClick={(event) => handleClick({ event, onClick })}
            >
              { Icon && <ListItemIcon><Icon fontSize='small' /></ListItemIcon> }
              {label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

MoreMenu.defaultProps = {
  options: []
}

MoreMenu.propTypes = {
  /** Unique ID to associate the button and menu DOM elements */
  id: PropTypes.string,
  /** List of options to display in menu */
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    iconComponent: PropTypes.node
  })).isRequired
}

export default MoreMenu
