import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import style from './style'

const BlockList = ({ children, classes, className }) => {
  return (
    <ul className={cx(className, classes.fieldWrap)}>
      {children}
    </ul>
  )
}

export default withStyles(style)(BlockList)
