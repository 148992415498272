import { colors } from '../../../config/theme'

const styles = {
  dateWrapper: {
    width: '100%',
    display: 'inline-flex',
    marginBottom: '-20px',
    marginTop: '14px'
  },
  dateFieldWrapper: {
    width: '33.3%',
    minWidth: 0
  },
  dropdownRoot: {
    width: '100%'
  },
  marginLeft: {
    marginLeft: '20px'
  },
  errorStyle: { color: colors.error },
  floatingLabelFocusStyle: { color: colors.accent },
  hiddenInput: {
    opacity: 0,
    position: 'absolute',
    height: 0
  },
  dateIconContainer: {
    position: 'relative',
    marginTop: 8,
  },
  pickerInput: {
    opacity: 0,
    height: '100%',
    width: '100%',
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  disabledIcon: {
    color: 'rgba(0, 0, 0, 0.38)'
  }
}

export default theme => styles
