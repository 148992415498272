import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { styled } from '@material-ui/core/styles'

const Container = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }))

const SkeletonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    width: '100%',
    maxWidth: 720,
    '& > span': {
      marginBottom: theme.spacing(1)
    }
  }))
  
  const SkeletonSection = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(4),
    '& > :first-child': {
      marginBottom: theme.spacing(2)
    }
   }))
  
  const SkeletonFieldContainer = styled('div')(({ theme }) => ({
   marginBottom: theme.spacing(2)
  }))
  
  const SkeletonField = (props = {}) => {
    const { width: fieldWidth = '100%' } = props
    return (
      <SkeletonFieldContainer>
        <Skeleton variant={'text'} width={'50%'} />
        <Skeleton variant={'rect'} width={fieldWidth} height={44} />
      </SkeletonFieldContainer>
    )
  }

const ReportLoader = () => {
    return (
      <Container>
        <SkeletonContainer>
          <SkeletonSection>
            <Skeleton variant={'rect'} height={50} />
            <SkeletonField />
            <SkeletonField width={'75%'} />
            <SkeletonField />
            <SkeletonField />
            <SkeletonField width={'60%'} />
          </SkeletonSection>
          <SkeletonSection>
            <Skeleton variant={'rect'} height={50} />
            <SkeletonField />
            <SkeletonField width={'60%'} />
          </SkeletonSection>
        </SkeletonContainer>
      </Container>
    )
  }

export default ReportLoader