import _ from 'lodash'
import fp from 'lodash/fp'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'

export const submissionReportsState = state => _.get(state, 'submissionReports')

export const getResults = createSelector(
  [submissionReportsState],
  (submissionReports) => _.get(submissionReports, 'results')
)

export const isLoading = createSelector(
  [submissionReportsState],
  (submissionReports) => _.get(submissionReports, 'status') === PENDING
)

export const getFilters = createSelector(
  [submissionReportsState],
  (submissionReports) => _.get(submissionReports, 'filters', {})
)

export const getQuery = createSelector(
  [submissionReportsState],
  (submissionReports) => _.get(submissionReports, 'filters.query')
)

export const getSearchSize = createSelector(
  [submissionReportsState],
  (submissionReports) => _.get(submissionReports, 'size')
)

export const getSearchPage = createSelector(
  [submissionReportsState],
  (submissionReports) => _.get(submissionReports, 'page')
)

export const getTotal = createSelector(
  [submissionReportsState],
  (submissionReports) => _.get(submissionReports, 'total')
)

export const getSavedSearches = createSelector(
  [submissionReportsState],
  (submissionReports) => _.get(submissionReports, 'savedSearches')
)

export const getActiveSavedSearch = createSelector(
  [submissionReportsState],
  (submissionReports) => {
    const activeSearchId = _.get(submissionReports, 'activeSavedSearch')
    return _.find(_.get(submissionReports, 'savedSearches'), { id: activeSearchId })
  }
)

export const getActiveSavedSearchId = createSelector(
  [submissionReportsState],
  (submissionReports) => _.get(submissionReports, 'activeSavedSearch')
)

export const getNumberOfActiveFilters = createSelector(
  getFilters,
  (filters) => {
    return _.reduce(filters, (total, value) => {
      return value ? total + 1 : total
    }, 0)
  }
)

export const getSelected = createSelector(
  [submissionReportsState],
  (submissionReports) => _.get(submissionReports, 'selected')
)

export const getSelectedCount = createSelector(
  [getSelected],
  (selected) => _.size(selected)
)

export const isSelected = (id) => createSelector([
  getSelected
], (selected) => {
  return _.includes(selected, id)
})

export const getSelectedResults = createSelector([
  getResults,
  getSelected
], (submissionReports, selected) => {
  const selectedSubmissionReports = _.map(selected, (id) => {
    return _.find(submissionReports, { id })
  })
  return selectedSubmissionReports
})

export const getSelectedReceiverId = createSelector([
  getSelectedResults
], (submissionReports) => {
  const receiverIds = fp.compose(
    fp.uniq,
    fp.map('receiverId')
  )(submissionReports)
  return _.size(receiverIds) === 1 ? receiverIds[0] : undefined
})

export const getCanRetrySelected = createSelector([
  getSelectedResults
], (submissionReports) => {
  const unsupportedReports = _.reject(submissionReports, (submissionReport) => {
    const receiverStatus = _.get(submissionReport, 'receiverStatus')
    return receiverStatus === 'FAILED' || receiverStatus === 'TIMED_OUT' || receiverStatus === 'NOT_SENT'
  })
  return _.isEmpty(unsupportedReports)
})
