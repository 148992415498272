import React from 'react'
import styled from 'styled-components'

const InfoListWrapper = styled.div`
  width: auto;
  background-color: #F8F8F8;
  padding: 15px;
  margin: 15px;
`

const Heading = styled.p`
  margin: 0 0 8px 0;
  line-height: 24px;
`

const StyledInfoList = styled.ul`
  margin: 0;
`

const InfoListItem = styled.li`
  margin: 0 0 8px 0;
  padding: 0;
  line-height: 20px;
  &:last-child {
    margin: 0;
  }
`

const InfoList = (props) => {
  const { id, label, helpText } = props
  const infoList = helpText.split(',').map(requirement => {
    return requirement.trim()
  })

  return (
    <InfoListWrapper id={id}>
      {label && <Heading>{label}</Heading>}
      <StyledInfoList>
        {infoList.map(info => {
          return <InfoListItem key={info}>{info}</InfoListItem>
        })}
      </StyledInfoList>
    </InfoListWrapper>
  )
}

export default InfoList
