const style = theme => ({
  item: {
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey['300']}`,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 4
  },
  cohortHeader: {
    '& > .MuiChip-root': {
      marginRight: theme.spacing(1)
    }
  },
  incompleteChip: {
    backgroundColor: theme.palette.error.dark,
    color: '#fff'
  }
})

export default style
