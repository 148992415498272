const style = (theme) => ({
    formContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      border: '1px solid black',
      padding: 10,
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
      }
    },
    inputStyle: {
      color: 'black',
      width: '100%',
      paddingLeft: '1em',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '3em',
      }
    },
    buttonContainer: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        '& button': {
          marginRight: 10
        }
    },
    addQestionContainer: {
      display: 'flex',
      justifyContent: 'center',
      border: '1px solid black',
      '& input': {
        padding: '8px 0 0 10px'
      }
    },
    addQuestionButton: {
      background: theme.lightGrey,
      whiteSpace: 'nowrap',
      border: 'none',
      borderLeft: '2px solid grey',
      width: '50%',
      padding: 5
    },
    button: {
      width: 30,
      height: 30,
      marginRight: 10,
      fontSize: 26,
      borderRadius: 2
    },
    editWrapper: {
      display: 'flex'
    },
    arrowWrapper: {
      display: 'flex',
      minWidth: '80px',
      justifyContent: 'space-between'
    },
    arrowDown: {
      transform: 'translateY(100%)',
      width: 0,
      height: 10,
      borderTop: '8px solid black',
      borderRight: '8px solid transparent',
      borderLeft: '8px solid transparent'
    },
    arrowUp: {
      transform: 'translateY(100%)',
      width: 0,
      height: 10,
      borderRight: '8px solid transparent',
      borderLeft: '8px solid transparent',
      borderBottom: '8px solid black'
    },
    notVisible: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: 13,
      width: '30px',
      height: '3px',
      backgroundColor: '#000',
      transform: 'rotate(45deg)'
    },
    errorText: {
      color: 'red',
      margin: 0,
      fontSize: '0.75rem',
      textAlign: 'left',
      minHeight: '1em',
      lineHeight: '1em',
      paddingTop: '0.3em'
    }
  })
  
  export default style
  