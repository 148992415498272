import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import media from 'styled-media-query'

import H2 from '../../components/H2'
import P from '../../components/P'
import Spinner from '../../components/Spinner'
import Button from '../../components/Button'

import { useFormView, useFollowUp, useOrganisationDetails } from '../../services/sdk/hooks'
import { addResources, translation } from '../../services/translations'

import FormSchemaLibrary, { constants as formSchemaConstants } from '@redant/mhra-form-schema-library'

const Loading = styled.div`
  padding: 50px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ErrorContainer = styled.div`
  padding: 15px;
`

const ErrorContent = styled.div`
  padding: 10px;
  border-left: ${({ theme }) => `4px ${theme.colors.error} solid`};
  padding-left: ${props => `${props.theme.form.spacing.small}px`};
  padding-right: ${props => `${props.theme.form.spacing.small}px`};
  ${media.greaterThan('small')`
    border-left-width: 8px;
    padding-left: ${props => `${props.theme.form.spacing.large}px`};
    padding-right: ${props => `${props.theme.form.spacing.large}px`};
  `}
  p {
    margin: 0 0 5px 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const RetryContainer = styled.div`
  width: 100%;
`

const RetryContent = styled.p`
  margin-left: 15px;
  width: 100%;
`

const ReportConfigurationContext = React.createContext()

const ReportConfigurationProvider = (props) => {
  const {
    children,
    formViewId,
    formSchemaId,
    formSchemaName,
    followUpUserId,
    reportLoadingTimeout,
    contactPageLink,
    organisationDetails: passedOrganisationDetails,
    productDetails,
    defaultTranslations
  } = props

  const formSchemaLibrary = new FormSchemaLibrary()

  const [organisationDetails, setOrganisationDetails] = useState({})
  const [professions, setProfessions] = useState([])
  const [formView, setFormView] = useState({})
  const [followUp, setFollowUp] = useState()
  const [schemaName, setSchemaName] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState()
  const [exceededLoadingTimer, setExceededLoadingTimer] = useState(false)
  const [defaultViewJSON, setDefaultViewJson] = useState(null)
  const [isUserLoggedIntoWorkspace, setIsWorkspace] = useState(false)

  const fetchOrganisationDetails = useOrganisationDetails()
  const fetchFormView = useFormView({ id: formViewId, disableRequest: _.isNil(formViewId) })
  const fetchFollowUp = useFollowUp({ followUpUserId, disableRequest: _.isNil(followUpUserId) })

  useEffect(() => {
    if (!formViewId && formSchemaName) {
      const defaultView = formSchemaLibrary.getDefaultView(formSchemaName)
      const defaultViewJSON = defaultView.sections
      setDefaultViewJson(defaultViewJSON)
    }
  }, [formViewId, formSchemaName])

  const formSchemaType = useMemo(() => {
    let formType = formSchemaConstants.FORM_TYPE_CONSTANTS.REPORT
    if (formSchemaName) {
      const schemaFormType = formSchemaLibrary.getSchemaFormType(formSchemaName)
      if (_.isString(schemaFormType)) {
        formType = schemaFormType
      }
    }

    return formType
  }, [formSchemaName])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setExceededLoadingTimer(true)
    }, _.defaultTo(reportLoadingTimeout, 10000))
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    const isSuccess = (resource) => _.get(resource, 'status') === 'SUCCESS'
    const isError = (resource) => _.get(resource, 'status') === 'ERROR'

    if (isError(fetchOrganisationDetails) || (isError(fetchFormView)) || isError(fetchFollowUp)) {
      setErrorMessage('Your report form was unable to load, please go back and try again.')
      return setIsLoading(false)
    }

    if (isSuccess(fetchOrganisationDetails) && (isSuccess(fetchFormView) || isSuccess(fetchFollowUp) || defaultViewJSON)) {
      const organisationResponse = _.get(fetchOrganisationDetails, 'response')
      const formViewResponse = _.get(fetchFormView, 'response')
      const followUpResponse = _.get(fetchFollowUp, 'response')
      const isWorkspace = _.get(organisationResponse, 'systemType') === 'WORKSPACE'
      if (_.isBoolean(isWorkspace)) {
        setIsWorkspace(isWorkspace)
      }
      if (passedOrganisationDetails) {
        setOrganisationDetails(passedOrganisationDetails)
      } else if (!isWorkspace) {
        setOrganisationDetails(_.get(organisationResponse, 'details'))
      }
      setProfessions(_.get(organisationResponse, 'professions'))
      const organisationTranslations = _.get(organisationResponse, 'translations')

      addResources({
        ...organisationTranslations,
        en: {
          ...defaultTranslations,
          ...organisationTranslations.en
        }
      })

      if (formViewResponse) {
        setFormView(formViewResponse)
        setSchemaName(_.get(formViewResponse, 'formSchema.name'))
      }

      if (followUpResponse) {
        setFollowUp(followUpResponse)
        setFormView(_.get(followUpResponse, 'followUp.formView'))
        setSchemaName(_.get(followUpResponse, 'followUp.formView.formSchema.name'))
      }

      if (defaultViewJSON && !isSuccess(fetchFormView)) {
        setSchemaName(formSchemaName)
      }

      setIsLoading(false)
    }
  }, [fetchOrganisationDetails, fetchFormView, fetchFollowUp, defaultViewJSON])

  if (isLoading) {
    return (
      <Loading>
        <Spinner />
        {exceededLoadingTimer &&
          <RetryContainer>
            <RetryContent
              dangerouslySetInnerHTML={{
                __html: translation('Sorry, we appear to be having a problem loading the page. Please select the retry button below. If this continues to occur, please {{- openLinkTag}}contact us{{- closeLinkTag}} to let us know.',
                  {
                    openLinkTag: `${contactPageLink && `<a href="${contactPageLink}" target="_blank">`}`,
                    closeLinkTag: `${contactPageLink && '</a>'}`
                  })
              }}
            />
            <P style={{ width: '100%', textAlign: 'center' }}>
              <Button
                type='button'
                onClick={() => {
                  if (!_.isUndefined(window)) {
                    window.location.reload()
                  }
                }}
              >
                {translation('Retry')}
              </Button>
            </P>
          </RetryContainer>}
      </Loading>
    )
  }

  if (errorMessage) {
    return (
      <ErrorContainer>
        <ErrorContent>
          <H2>Something went wrong</H2>
          <P>{errorMessage}</P>
          <P>We are sorry for any inconvenience caused.</P>
        </ErrorContent>
      </ErrorContainer>
    )
  }

  return (
    <ReportConfigurationContext.Provider value={{
      organisationDetails,
      productDetails,
      formView,
      followUp,
      professions,
      formSchemaId,
      schemaName,
      defaultViewJSON,
      isUserLoggedIntoWorkspace,
      formSchemaType
    }}>
      {children}
    </ReportConfigurationContext.Provider>
  )
}

const useReportConfigurationState = () => {
  const context = React.useContext(ReportConfigurationContext)
  if (context === undefined) {
    throw new Error('useReportConfigurationState must be used within a ReportConfigurationProvider')
  }
  return context
}

export { ReportConfigurationProvider, useReportConfigurationState }
