import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import moment from 'moment'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import H2 from '../../../components/H2'
import Button from '../../../components/Button'
import GroupList from '../../../components/GroupList'
import List from '../../../components/List'
import { translations } from '../../../config'
import { useSelector } from 'react-redux'

import withReportData from '../withReportData'
import style from './style'
import AnalyticsFilters from '../AnalyticsScreen/AnalyticsFilters/AnalyticsFilters'
import { default as CollapsibleRow } from '../../../components/AuditRow/AuditRow'
import Container from '../../../components/Container'
import Loader from '../../../components/Loader'
import { selectors as analyticsSelectors } from '../../../store/modules/platformAnalytics'

const ReportFullList = (props) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(analyticsSelectors.getIsLoadingOrganisationAnalytics)
  const { groupedListData, listData, classes, listItem, itemProps, reportSchema, items, timePeriodDates } = props

  const NoDataPlaceholder = () => {
    return (
      <div className={classes.noDataPlaceholder}>{translations('No data placeholder')}</div>
    )
  }

  const renderHashLinks = () => {
    const nonEmptyGroupListData = groupedListData
      .filter(({ items }) => !!items.length)
    return (
      <div className={classes.buttonsContainer}>
        {
          nonEmptyGroupListData
          .map(({ name: groupName }) => (
            <div className={classes.buttonContainer} key={groupName}>
              <Link to={`#GroupList-${groupName}`} replace>
                <Button
                  buttonType='white'
                >
                  {groupName}
                </Button>
              </Link>
            </div>
          ))
        }
      </div>
    )
  }

  const renderList = () => {
    const ItemComponent = _.get(listItem, 'FullListItemComponent')
    const onClickItem = _.get(listItem, 'onClickItem')
    const { seeAllPageType, title } = reportSchema
    const seeAllPageItemKey = _.get(reportSchema, 'seeAllPageItemKey')
    const seeAllPageItems = _.get(items, ['0', seeAllPageItemKey])

    if (seeAllPageType === 'collapsible' && !_.isEmpty(seeAllPageItems)) {
      if (title === 'Locations') {
        const locationsBreakdown = seeAllPageItems
        const renderChildLocationRow = (childLocations) => {
          if (!_.isEmpty(childLocations)) {
            return (
              _.map(childLocations, (value, key) => {
                return (
                  <div className={classes.collapsibleRowContent} key={key}>
                    <span>{key}</span>
                    <span>{value}</span>
                  </div>
                )
              })
            )
          }
        }
  
        const sortedLocationsBreakdown = _.chain(locationsBreakdown)
          .toPairs()
          .sortBy(([key, value]) => -value.count)
          .fromPairs()
          .value()
  
        return (
          <Container>
            <div className={classes.collapsibleContainer}>
              {_.map(sortedLocationsBreakdown, (value, key) => {
                const rightGridItems = [{
                  visible: true,
                  component: <p>{value?.count}</p>
                }]
              
                return (
                  <CollapsibleRow
                    key={key}
                    id={key}
                    startOpen={false}
                    collapseContent={renderChildLocationRow(value?.childLocations)}
                    rightGridItems={rightGridItems}
                    rowTitle={key}
                    disabled={_.isEmpty(value?.childLocations)}
                  />
                  )
                })}
            </div>
          </Container>
        )
      }
    }
    
    if (seeAllPageType === 'list') {
      if (title === 'Unprocessed Reports') {
        const data = _.chain(seeAllPageItems)
          .countBy('extReportId')
          .map((value, key) => ({ name: key, value }))
          .orderBy(['value'], ['desc'])
          .value()

        return (
          <div className={classes.listContainer}>
            {seeAllPageItems.length > 0 ? <List
              items={data}
              ItemComponent={ItemComponent}
              noMargin
              onClickItem={(item) => {
                return dispatch(push(`/transmission-management?query=${item.name}&dateFrom=${moment(timePeriodDates.startDate).format('MM/DD/YYYY')}&dateTo=${moment(timePeriodDates.endDate).format('MM/DD/YYYY')}`))
              }}
            /> : <NoDataPlaceholder />
            }
          </div>
        )
      }
    }

    if (groupedListData) {
      return (
        <div className={classes.groupedListContainer}>
          {renderHashLinks()}
          <GroupList
            data={groupedListData}
            ItemComponent={ItemComponent}
            noMargin
            onClickItem={onClickItem}
            itemProps={itemProps}
          />
        </div>
      )
    } else if (listData) {
      return (
        <div className={classes.listContainer}>
          <List
            items={listData}
            ItemComponent={ItemComponent}
            noMargin
            onClickItem={onClickItem}
          />
        </div>
      )
    } else {
      return <NoDataPlaceholder />
    }

  }

  return (
    <div>
      <SubHeader
        leftContent={(<BackBar />)}
        centerContent={(<H2 value={_.toUpper(reportSchema.title)} />)}
      />
      <AnalyticsFilters/>
      {isLoading ? <Loader /> : renderList()}
    </div>
  )
}

export default compose(
  withReportData,
  withStyles(style)
)(ReportFullList)
