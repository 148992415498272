import React, { useState } from 'react'
import { IconButton, ListItem, ListItemIcon, Menu, TableCell, TableRow, withStyles } from '@material-ui/core'
import {
  Done as DoneIcon,
  Schedule as ScheduleIcon,
  Send as SendIcon,
  MoreVert as MoreVertIcon,
  CancelScheduleSend as CancelScheduleSendIcon,
  PanTool as HoldIcon,
  Edit
} from '@material-ui/icons'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import style from './style'
import modalService from '../../../../services/modalService'
import toastService from '../../../../services/toastService'
import ConfirmationModal from '../../modals/ConfirmationModal'
import { translations } from '../../../../config'
import { actions as reportConfigurationActions } from '../../../../store/modules/reportConfiguration'
import { addQuery } from '../../../../helpers/query'

import { Can } from '../../../../ability/ability-context'

const toastError = (message) => toastService.action({
  type: 'error',
  message
})
const toastSuccess = (message) => toastService.action({
  type: 'success',
  message
})

const ReporterFollowUpRow = ({
  id,
  type,
  scheduledDate,
  followUp,
  rowId,
  reporterName,
  onUpdate,
  classes
}) => {
  const { followUpEvent, followUpTimeframe, cohort, id: followUpId, followUpFormViewId: followUpReportFormViewId } = followUp

  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()

  const icons = {
    SCHEDULED: ScheduleIcon,
    SENT: SendIcon,
    COMPLETE: DoneIcon,
    CANCELLED: CancelScheduleSendIcon,
    ON_HOLD: HoldIcon
  }

  const IconComponent = icons[type] || ScheduleIcon

  const handleMoreClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMoreClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const openCancelFollowUpUserModal = () => {
    modalService.open({
      component: ConfirmationModal,
      disableBackdropClick: true,
      message: translations('FollowUpUser - Cancel Message', { name: reporterName }),
      title: translations('FollowUps - Cancel Title'),
      onConfirmation: cancelFollowUpUser
    })
  }

  const editFollowUpReportConfiguration = () => {
    addQuery({
      followUp: {
        followUpReportFormViewId,
        followUpId
      }
    })
  }

  const cancelFollowUpUser = async () => {
    try {
      const timestamp = moment().format('LLLL')
      const res = await dispatch(reportConfigurationActions.updateFollowUpUser({
        followUpUserId: id,
        params: {
          type: 'CANCELLED',
          transitionType: 'MANUAL',
          details: { creationReason: `Cancelled by user on ${timestamp}` }
        }
      }))
      onUpdate()
      toastSuccess(translations('Success - Cancel FollowUpUser', { name: reporterName }))
      return res
    } catch (err) {
      console.log(err)
      toastError(translations('Failure - Cancel FollowUpUser', { name: reporterName }))
    }
  }

  return (
    <TableRow className={classes.root}>
      <TableCell>{_.get(followUpEvent, 'name', '-')}</TableCell>
      <TableCell align='center'>{cohort.type}</TableCell>
      <TableCell align='center'>{`${_.get(followUpTimeframe, 'value', '-')} ${_.lowerCase(_.get(followUpTimeframe, 'type'))}`}</TableCell>
      <TableCell align='center'>{moment(scheduledDate).format('DD/MM/YYYY')}</TableCell>
      <TableCell align='center'>
        <div className={classes.status}>
          <IconComponent />
          {_.upperCase(type)}
        </div>
      </TableCell>
      <TableCell align='right'>
        {type === 'SCHEDULED' && (
          <>
            <IconButton aria-label='More options' size='small' aria-controls={`row-more-menu-${rowId}`} aria-haspopup='true' onClick={handleMoreClick} color='inherit'>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`row-more-menu-${rowId}`}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMoreClose}
            >
              <Can I='viewEditorTab' a='reportConfigurationNext'>
                <ListItem button onClick={editFollowUpReportConfiguration}>
                  <ListItemIcon>
                    <Edit fontSize='small' />
                  </ListItemIcon>
                  {translations('Edit Report')}
                </ListItem>
              </Can>
              <ListItem button onClick={openCancelFollowUpUserModal}>
                <ListItemIcon>
                  <CancelScheduleSendIcon fontSize='small' />
                </ListItemIcon>
                {translations('FollowUp - Cancel Send')}
              </ListItem>
            </Menu>
          </>
        )}
      </TableCell>
    </TableRow>
  )
}

export default withStyles(style)(ReporterFollowUpRow)
