import React from 'react'

import Editable from '../Editable'
import { Date } from '../../../components/Fields'
import P from '../../P'

export default Editable({
  input: (props) => {
    return (
      <Date {...props} />
    )
  },
  display: ({ label, value, isEmpty, hideIfEmpty }) => {
    if (isEmpty && hideIfEmpty) return null
    return (
      <div>
        <P value={label} type='textButton' />
        <P value={value} />
      </div>
    )
  }
})
