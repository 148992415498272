import React from 'react'
import uploadService from '../services/uploadService'
import Button from '../components/Button'

export const getFieldDisplayValue = ({ value, label, type }) => {
  let displayValue = ''
  let isUrl = false
  let isS3Url = false
  let downloadURL = null

  const handleOnS3LinkClick = async ({ name, folderName, type, attachmentId }) => {
    
    await uploadService.downloadUrl({ fileName: name, contentType: type, folderName, attachmentId }).then(url => {
      window.open(url)
    })
  }

  try {
    let testUrl = value.url ? value.url : value
    downloadURL = new window.URL(testUrl)
    // check if URL is to a private S3 bucket and if so request & display safe url.
    isS3Url = RegExp('(s3-|s3\.)?(.*)\.amazonaws\.com', 'g').test(testUrl)
    displayValue = isS3Url
      ? <Button
        raised
        type='button'
        color='primary'
      >VIEW</Button>
      : <a href={downloadURL.href} target='_blank'>{downloadURL.href}</a>
    isUrl = true
  } catch (e) {
    displayValue = value
    isUrl = false
  }

  if (type === 'password') {
    displayValue = '********'
  }

  return {
    displayValue,
    isUrl,
    onClick: isUrl ? () => handleOnS3LinkClick(value) : null
  }
}

export default getFieldDisplayValue
