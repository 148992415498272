import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { translations } from '../../../../../../config'

import P from '../../../../../../components/P'
import Heading from '../../../../../../components/Heading'

import style from './style'

const Diff = (props) => {
  const { classes, title, fields, count = 0, direction } = props
  const hasChanges = count > 0

  const listItem = cx(classes.listItem, {
    [classes.listItemAdd]: (direction === 'ADD'),
    [classes.listItemRemove]: (direction === 'REMOVE')
  })

  return (
    <div>
      <Heading variant='h3' component='h3'>{title}</Heading>
      <div className={classes.content}>
        { hasChanges
          ? (
            <ul className={classes.list}>
              {_.map(fields, (field) => {
                const id = _.get(field, 'id')
                const name = _.get(field, 'name', 'Missing name')

                let text = name

                if (_.get(field, 'fieldId')) {
                  text += ` (in repeatable ${_.startCase(_.get(field, 'fieldId'))})`
                }

                if (_.get(field, 'sectionId')) {
                  text += ` (in section ${_.startCase(_.get(field, 'sectionId'))})`
                }

                return (
                  <li key={id} className={listItem}>
                    <P value={text} />
                  </li>
                )
              })}
            </ul>
          )
          : (
            <div className={classes.blankState}>
              <P value={translations('No Changes')} />
            </div>
          )
        }
      </div>
    </div>
  )
}

Diff.propTypes = {
  classes: PropTypes.any,
  title: PropTypes.string.isRequired,
  fields: PropTypes.array,
  count: PropTypes.number,
  direction: PropTypes.oneOf(['ADD', 'REMOVE'])
}

export default withStyles(style)(Diff)
