import { colors } from '../../config/theme'

const styles = (theme) => ({
  base: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.grey[600],
    borderStyle: 'dashed',
    backgroundColor: theme.palette.grey[100],
    outline: 'none',
    transition: 'border .24s ease-in-out',
    justifyContent: 'center'
  },
  active: {
    borderColor: colors.primary
  },
  success: {
    borderColor: colors.success
  },
  reject: {
    borderColor: colors.error
  }
})

export default styles