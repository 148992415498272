import React, { Fragment } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import ViewIcon from '@material-ui/icons/RemoveRedEye'
import { withStyles } from '@material-ui/core/styles'
import { FormControlLabel, FormHelperText } from '@material-ui/core'
import classNames from 'classnames'
import style from './style'
import _ from 'lodash'

const IconCheckboxComponent = (props) => {
  const {
    value,
    visibility,
    classes
  } = props
  const determineHidden = !_.isNil(visibility) ? visibility : value
  return (
    <IconButton
      size='small'
      className={classes.button}
      style={{ color: 'black' }}
      onClick={props.onClick}
    >
      <ViewIcon fontSize='inherit' />
      {determineHidden === false && <span className={classes.notVisible} />}
    </IconButton>
  )
}

export default withStyles(style)(IconCheckboxComponent)
