import ConfirmationModal from '../../../../../components/ConfirmationModal'
import FormModal from '../../../../../components/FormModal'
import { translations } from '../../../../../config'
import modalService from '../../../../../services/modalService'

export const openAddRepeatableInstanceModal = (onSubmit, repeatableOptions) => {
  modalService.open({
    component: FormModal,
    title: translations('Report Configuration - Add Repeatable Instance'),
    formId: 'add-repeatable-instance',
    schema: schema(repeatableOptions),
    onSubmit
  })
}

export const openEditRepeatableInstanceModal = (onSubmit, initialValues, repeatableOptions) => {
  modalService.open({
    component: FormModal,
    title: translations('Report Configuration - Edit Repeatable Instance'),
    formId: 'edit-repeatable-instance',
    schema: schema(repeatableOptions),
    initialValues,
    onSubmit
  })
}

export const openDeleteRepeatableInstanceModal = (onSubmit, id) => {
  modalService.open({
    component: ConfirmationModal,
    onConfirmation: () => onSubmit(id),
    message: translations('Report Configuration - Delete Repeatable Instance Message')
  })
}

export const schema = (repeatableOptions) => [
  {
    id: 'name',
    field: 'Input',
    props: {
      label: translations('Friendly Name'),
      name: 'name',
      required: true
    }
  },
  {
    id: 'repeatableType',
    field: 'Dropdown',
    props: {
      label: translations('Repeatable Type'),
      name: 'repeatableType',
      required: true,
      options: repeatableOptions
    }
  }
]