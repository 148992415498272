import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import toastService from '../../../../services/toastService'

import Checkbox from '../../../../components/Fields/Checkbox'
import { Link } from 'react-router-dom'
import ButtonBase from '@material-ui/core/ButtonBase'

import { translations } from '../../../../config'
import style from './style'

class ProductItem extends PureComponent {
  renderCheckbox = () => {
    const { isWatched, canWatch, name, editWatchList, classes } = this.props

    if (!canWatch) return null

    return (
      <Checkbox
        hideLabel
        containerClass={classes.checkbox}
        value={!!isWatched}
        label={!isWatched ? translations('Add to Watchlist List', { name }) : translations('Remove From Watchlist List', { name })}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          editWatchList({ [name]: !isWatched })
            .then(() => {
              toastService.action({
                type: 'success',
                message: translations('Successfully - Watch List', { action: !isWatched ? 'added to' : 'removed from' })
              })
            })
            .catch(() => {
              toastService.action({
                type: 'error',
                message: translations('Failure - Watch List', { action: !isWatched ? 'add to' : 'remove from' })
              })
            })
        }}
      />
    )
  }

  render () {
    const { classes, name, id } = this.props

    return (
      <ButtonBase component={Link} to={`/product/${id}`} className={classes.link} disableRipple>
        {this.renderCheckbox()}
        <span className={classes.label}>{name}</span>
      </ButtonBase>
    )
  }
}

ProductItem.defaultProps = {
  isWatched: false,
  canWatch: false
}

ProductItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isWatched: PropTypes.bool,
  canWatch: PropTypes.bool,
  editWatchList: PropTypes.func
}

export default withStyles(style)(ProductItem)
