import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import ExportManagementScreen from './ExportManagementScreen'
import ViewExportManagementDetails from './ViewExportManagementDetails/ViewExportManagementDetails'

import TimedLogoutComponent from '../../components/TimedLogout'

export default ([
  <PrivateRoute key='/export-management' path='/export-management' exact component={TimedLogoutComponent(ExportManagementScreen)} />,
  <PrivateRoute key='/export-management/:id' path='/export-management/:id' exact component={TimedLogoutComponent(ViewExportManagementDetails)} />
])
