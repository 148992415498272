import {
  TOGGLE_SIDE_MENU,
  TOGGLE_BASKET,
  APP_READY,
  CHANGE_CLIENT_BOOK_TAB,
  CLIENT_BOOK_CONTACT_DETAILS,
  ON_API_FAIL,
  ON_API_DISMISS,
  CHANGE_SNAPSHOT_METRIC_TAB,
  SNAPSHOT_THIS_MONTH,
  SNAPSHOT_PURCHASES,
  CHANGE_SNAPSHOT_CAROUSEL_TAB
} from './constants'

import reducers from './reducers'

const defaultState = {
  isMenuOpen: false,
  appReady: false,
  apiFail: false,
  clientBookTab: CLIENT_BOOK_CONTACT_DETAILS,
  snapshotMetricTab: SNAPSHOT_THIS_MONTH,
  snapshotCarouselTab: SNAPSHOT_PURCHASES
}

export default (state = defaultState, action) => {
  const newState = {
    ...defaultState,
    ...state
  } // this is for adding new keys to a persist-whitelisted module

  switch (action.type) {
  case TOGGLE_SIDE_MENU:
    return reducers.toggleSideMenu(newState, action)
  case TOGGLE_BASKET:
    return reducers.toggleBasket(newState, action)
  case APP_READY:
    return reducers.appReady(newState, action)
  case ON_API_FAIL:
    return reducers.onApiFail(newState, action)
  case ON_API_DISMISS:
    return reducers.onApiDismiss(newState, action)
  case CHANGE_CLIENT_BOOK_TAB:
    return reducers.changeClientBookTab(newState, action)
  case CHANGE_SNAPSHOT_METRIC_TAB:
    return reducers.changeSnapshotMetricTab(newState, action)
  case CHANGE_SNAPSHOT_CAROUSEL_TAB:
    return reducers.changeSnapshotCarouselTab(newState, action)
  default:
    return state
  }
}
