"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.testField = exports.susarEditable = exports.spontaneousEditable = exports.getFormatForDate = exports.getDoseTranslation = exports.equalsInRepeatable = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var susarEditable = function susarEditable() {
  var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return values.susarEditable === 'true';
};

exports.susarEditable = susarEditable;

var spontaneousEditable = function spontaneousEditable() {
  var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return values.spontaneousEditable === 'true';
};

exports.spontaneousEditable = spontaneousEditable;

var getFormatForDate = function getFormatForDate(value, formats) {
  if (!value) return '';
  if (!formats) return '';
  var date = {
    year: value.substring(0, 4),
    month: value.substring(4, 6),
    day: value.substring(6, 8),
    hour: value.substring(9, 10),
    minute: value.substring(11, 12),
    second: value.substring(13, 14)
  };

  if (date.year && date.month && date.day && date.hour && date.minute && date.second) {
    return formats.yyyymmddhhmmss || '';
  } else if (date.year && date.month && date.day && date.hour && date.minute) {
    return formats.yyyymmddhhmm || '';
  } else if (date.year && date.month && date.day && date.hour) {
    return formats.yyyymmddhh || '';
  } else if (date.year && date.month && date.day) {
    return formats.yyyymmdd || '';
  } else if (date.year && date.month) {
    return formats.yyyymm || '';
  } else if (date.year) {
    return formats.yyyy || '';
  } else {
    return '';
  }
};

exports.getFormatForDate = getFormatForDate;

var _equals = function _equals(value, test) {
  return value === test;
};

var _notequals = function _notequals(value, test) {
  return value !== test;
};

var _test = function _test(_ref) {
  var operator = _ref.operator,
      value = _ref.value,
      test = _ref.test;
  if (!value) return false;

  if (operator === 'equals') {
    return _equals(value, test);
  } else if (operator === 'notequals') {
    return _notequals(value, test);
  } else if (operator === 'truthy') {
    return !!value;
  } else if (operator === 'falsey') {
    return !value;
  } else {
    return false;
  }
};

var testField = function testField(_ref2) {
  var operator = _ref2.operator,
      value = _ref2.value,
      test = _ref2.test,
      isTrue = _ref2.isTrue,
      isFalse = _ref2.isFalse;
  return _test({
    operator: operator,
    value: value,
    test: test
  }) ? isTrue : isFalse;
};

exports.testField = testField;

var equalsInRepeatable = function equalsInRepeatable(_ref3) {
  var repeatable = _ref3.repeatable,
      field = _ref3.field,
      operator = _ref3.operator,
      test = _ref3.test,
      isTrue = _ref3.isTrue,
      isFalse = _ref3.isFalse;
  return function (values) {
    if (!values[repeatable]) return null;

    var predicate = _lodash["default"].find(values[repeatable], function (repeatableField) {
      if (!repeatableField[field] || !operator) return undefined;
      return _test({
        operator: operator,
        value: repeatableField[field],
        test: test
      });
    });

    return predicate ? isTrue : isFalse;
  };
};

exports.equalsInRepeatable = equalsInRepeatable;

var getDoseTranslation = function getDoseTranslation(value) {
  var startsWith = value.charAt(0);

  switch (startsWith) {
    case '1':
      return ["Error - first dose already assigned", {}];

    case '2':
      return ["Error - second dose already assigned", {}];

    case '3':
      return ["Error - booster dose already assigned", {}];

    case '4':
      return ["Error - booster dose already assigned", {}];

    default:
      return ["Error - dose already assigned to another entry", {}];
  }
};

exports.getDoseTranslation = getDoseTranslation;