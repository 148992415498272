import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'
import actions from './actions'
import * as authSelectors from '../auth/selectors'

let nonEditableFields = []

function removeNotEditableFields (arr, sections) {
  if (!sections) sections = []
  _.map(arr, (item) => {
    if (_.size(_.get(item, 'fields'))) {
      removeNotEditableFields(item.fields, [...sections, item.id])
      return
    }
    if (_.get(item, 'editable', null) === false) {
      nonEditableFields.push({
        ...item,
        section: sections.length ? sections : item.id
      })
    }
  })
  return nonEditableFields
}

class FormViewMiddleware {
  fetchFormViewMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const isRoutingToReportConfiguration = matchPath(path, { path: '/report-configuration', exact: true })
      const isRoutingToNewConfiguration = matchPath(path, { path: '/report-configuration/new/:name', exact: true })
      const isRoutingToEditCurrentConfiguration = matchPath(path, { path: '/report-configuration/:id', exact: true })
      const isRoutingToSelectReport = matchPath(path, { path: '/select-report', exact: true })
      const isRoutingToReports = matchPath(path, { path: '/reports', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      const organisationId = authSelectors.getUserSelectedOrganisationId(getState())
      const result = isLoggedIn && matchPath(path, { path: '/report-configuration/:id', exact: true })
      const formViewId = _.get(result, 'params.id')
      if (
        isLoggedIn && (isRoutingToReportConfiguration || isRoutingToSelectReport || isRoutingToReports)
      ) {
        const isSelectingReports = (isRoutingToSelectReport || isRoutingToReports)
        dispatch(actions.resetStore())
        dispatch(actions.clearNotEditableJSON())
        dispatch(actions.fetchAllFormViews({
          organisationId,
          params: {
            version: 'ALL',
            source: isSelectingReports ? 'vigilancehubforms' : 'ALL',
            ...isSelectingReports && {
              publish: true
            }
          }
        }))
      }
      if (
        isLoggedIn && isRoutingToEditCurrentConfiguration
      ) {
        dispatch(actions.fetchFormViewById({ id: formViewId }))
          .then(res => {
            const getViewJSON = _.get(res, 'formViewVersions[0].viewJSON')
            const getNotEditable = _.get(getState(), 'formViews.notEditable')
            if (!getNotEditable) {
              const notEditable = removeNotEditableFields(getViewJSON)
              dispatch(actions.setNotEditableJSON(notEditable))
              nonEditableFields = []
            }
          })
      }
      if (
        isLoggedIn && isRoutingToNewConfiguration
      ) {
        const getViewJSON = _.get(action, 'payload.location.state.viewJSON')
        const getNotEditable = _.get(getState(), 'formViews.notEditable')
        if (!getNotEditable) {
          const notEditable = removeNotEditableFields(getViewJSON)
          dispatch(actions.setNotEditableJSON(notEditable))
          nonEditableFields = []
        }
      }
    }
  }
}

export default new FormViewMiddleware()
