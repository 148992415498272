import { connect } from 'react-redux'
import { destroy, initialize } from 'redux-form'
import Password from './Password'

import { selectors as userDetailSelectors } from '../../../../store/modules/userDetails'

const mapStateToProps = (state, ownProps) => ({
  isSelectedUserLocked: userDetailSelectors.getSelectedUserIsLocked(state)
})

const mapDispatchToProps = (dispatch) => ({
  destroyPasswordForm () {
    dispatch(destroy('password'))
  },
  initializeForm () {
    // need to set values here otherwise the validation does not work
    dispatch(initialize('password', { password: '', confirmPassword: '' }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Password)
