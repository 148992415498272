import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import { withStyles } from '@material-ui/core/styles'

import Container from '../../../../components/Container'
import Heading from '../../../../components/Heading'
import NumberOfResultsAnnouncer from '../../../../components/NumberOfResultsAnnouncer'
import { translations } from '../../../../config'

import style from './style'
import ProductItem from '../ProductItem'

const ProductsList = props => {
  const {
    isLoading,
    error,
    isInitial,
    hasMore,
    hasResults,
    loadMore,
    classes,
    rows,
    hasWatchListPermissions
  } = props

  const isError = !!error && !isLoading
  const isNoResults = !isInitial && !hasResults && !isLoading

  if (isError || isNoResults) {
    const getText = () => {
      if (isNoResults || isError) return 'Tabular Data No Results Text'
    }
    return (
      <Container withMarginTop withMarginBottom>
        <Heading component='h1' className={classes.center} role='status'>{translations(getText())}</Heading>
      </Container>
    )
  }

  return (
    <div>
      <NumberOfResultsAnnouncer count={rows.length} />
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        initialLoad={false}
        >
        <ul className={classes.list}>
          {rows.map((row) => {
            const { id, name, watching } = row
            return (
              <li key={id} className={classes.listItem}>
                <ProductItem
                  id={id}
                  name={name}
                  isWatched={watching}
                  canWatch={hasWatchListPermissions}
                  />
              </li>
            )
          })}
        </ul>
      </InfiniteScroll>
    </div>
  )
}

ProductsList.defaultProps = {
  hasWatchListPermissions: false,
  rows: []
}

ProductsList.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    watching: PropTypes.bool
  })),
  hasWatchListPermissions: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  isInitial: PropTypes.bool,
  hasMore: PropTypes.bool,
  hasResults: PropTypes.number,
  loadMore: PropTypes.func
}

export default withStyles(style)(ProductsList)
