import _ from 'lodash'
import { styled } from '@material-ui/core'
import { useWebsites } from '../../contexts/WebsiteManagementContext'
import ContentBlock from './ContentBlock'
import { translations } from '../../../../config'

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 20,
  overflowY: 'auto'
}))

const ContentTab = () => {
  const {
    state
  } = useWebsites()
  
  const renderContent = () => {
    const content = state.content
    if(!content || _.isEmpty(content)) {
      return (
        <>
          {translations('Website Management - Empty Content')}
        </>
      )
    }
    return content.map(item => {
      return (
        <ContentBlock content={item} />
      )
    })
  }

  return (
    <Container>
      {renderContent()}
    </Container>
  )
}

export default ContentTab