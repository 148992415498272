import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useQuery } from '../../hooks'
import { addQuery } from '../../helpers/query'
import { translations } from '../../config'
import { AppBar, styled } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'

const TabBar = styled(AppBar)(({ theme }) => ({
  border: 0,
  borderBottom: `1px solid ${theme.palette.grey[200]}`
}))

const ReusableTabs = (props) => {
  const {
    tabs = [],
    children,
    shouldUseQuery = true,
    unmountOnExit = true,
    ariaLabel
  } = props

  const [selectedTab, setSelectedTab] = useState()
  const currentQuery = useQuery()
  const currentTab = shouldUseQuery ? currentQuery.currentTab : selectedTab

  useEffect(() => {
    setSelectedTab(tabs[0].value)
    if (shouldUseQuery) {
      addQuery({ currentTab: tabs[0].value })
    }
  }, [])

  const handleChange = (event, selectedTab) => {
    setSelectedTab(selectedTab)
    if (shouldUseQuery) {
      addQuery({ currentTab: selectedTab })
    }
  }

  const mapTabs = () => {
    const mappedTabs = _.map(tabs, ({ label, value, ...rest }) => {
      return (
        <Tab label={label} style={{ textTransform: 'none' }} value={value} {...rest} />
      )
    })
    return mappedTabs
  }

  return (
    <>
      <TabContext value={currentTab}>
        <TabBar position='static' color='transparent' variant='outlined'>
          <TabList
            variant={'scrollable'}
            scrollButtons={'auto'}
            onChange={handleChange}
            aria-label={translations(ariaLabel)}
            indicatorColor={'primary'}
            unmountOnExit={unmountOnExit}
          >
            {mapTabs()}
          </TabList>
        </TabBar>
        {children}
      </TabContext>
    </>
  )
}

export default ReusableTabs