import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'

import VisuallyHidden from '../VisuallyHidden'
import Heading from '../Heading'
import DataTableHeader from './DataTableHeader'
import DataTableSkeleton from './DataTableSkeleton'

import styles from './style'
import P from '../P'
import { translations } from '../../config'

const DataTable = (props) => {
  const {
    classes,
    headers,
    data,
    tableId,
    tableHeading,
    count,
    total,
    rowComponent: RowComponent,
    rowProps,
    rowKey,
    toolbarComponent: ToolbarComponent,
    toolbarProps,
    isLoading,
    primaryColumnWidth
  } = props
  const [headerColumnsCount, setHeaderColumnsCount] = useState(0)
  const [displayCount, setDisplayCount] = useState(false)

  useEffect(() => {
    setHeaderColumnsCount(_.size(headers))
  }, [headers])

  useEffect(() => {
    setDisplayCount(_.isNumber(count) && _.isNumber(total))
  }, [count, total])

  const renderBody = useCallback(() => {
    if (_.isEmpty(data) && isLoading) {
      return <DataTableSkeleton colSpan={headerColumnsCount} />
    }

    return _.map(data, (entry) => {
      return <RowComponent key={entry[rowKey]} entry={entry} {...rowProps} />
    })
  }, [data, rowKey, rowProps, RowComponent, headerColumnsCount])

  return (
    <Paper className={classes.root} elevation={0}>
      <VisuallyHidden>
        <Heading component='h2' id={tableId}>{tableHeading}</Heading>
      </VisuallyHidden>
      <div className={classes.toolbar}>
        {!_.isUndefined(ToolbarComponent) && (
          <div className={classes.toolbarContent}>
            <ToolbarComponent {...toolbarProps} />
          </div>
        )}
        {displayCount && (
          <div className={classes.meta}>
            <P value={translations('Count with total', { count, total })} />
          </div>
        )}
      </div>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby={tableId}>
          <colgroup>
            <col style={{ width: primaryColumnWidth }} />
          </colgroup>
          <DataTableHeader headers={headers} />
          <TableBody>
            {renderBody()}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

DataTable.defaultProps = {
  rowKey: 'id',
  isLoading: false,
  primaryColumnWidth: '20%'
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  tableId: PropTypes.string.isRequired,
  tableHeading: PropTypes.string.isRequired,
  count: PropTypes.number,
  rowComponent: PropTypes.func.isRequired,
  rowProps: PropTypes.object,
  rowKey: PropTypes.string,
  toolbarComponent: PropTypes.func,
  toolbarProps: PropTypes.shape({
    navId: PropTypes.string.isRequired,
    navHeading: PropTypes.string.isRequired
  }),
  isLoading: PropTypes.bool,
  primaryColumnWidth: PropTypes.string
}

export default withStyles(styles)(DataTable)
