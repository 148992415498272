import { colors } from '../../../config/theme'

const styles = {
  container: {
    display: 'flex'
  },
  inputContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  readOnlyInputContainer: {
    all: 'unset',
    width: '100px',
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }

}

export default theme => styles
