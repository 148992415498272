import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import SubHeader from '../SubHeader'
import P from '../P'
import Heading from '../Heading'
import SyntaxHighlighter from '../SyntaxHighlighter'

import { translations } from '../../config'
import styles from './style'

// eslint-disable-next-line react/prop-types
const renderList = ({ context, data = [], classes }) => {
  if (_.isEmpty(data)) {
    return null
  }

  return (
    <section className={classes.section}>
      <Heading component='h3' variant={'h3'}>{translations('Enhancements - List', { context })}</Heading>
      <ul className={classes.list}>
        {_.map(data, (changes, index) => {
          const { name, ...metaData } = changes
          return (
            <li className={classes.listItem} key={index}>
              <Heading component='h4' variant={'h4'}>{name}</Heading>
              {_.map(metaData, (metaValue, metaKey) => {
                return (
                  <p className={classes.meta} key={metaKey}>
                    <strong>{metaKey}: </strong>
                    <span>{metaValue}</span>
                  </p>
                )
              })}
            </li>
          )
        })}
      </ul>
    </section>
  )
}

const ReportEnhancementModal = (props) => {
  const { classes, closeModal, status, message, products, reactions, xml } = props

  return (
    <div className={classes.container}>
      <SubHeader
        notFixed
        inModal
        centerContent={(
          <Heading component='h1'>{translations('Enhancements - Modal title')}</Heading>
        )}
        rightContent={(
          <div className={classes.modalAction}>
            <IconButton aria-label={translations('Close')} onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
      />
      <div className={classes.content}>
          <Grid
            container
            spacing={0}
            className={classes.grid}
          >
            <Grid item xs={12} md={6} className={classes.gridContent}>
              <div className={classes.summary}>
                <Heading component='h2' className={classes.heading}>{status}</Heading>
                <P type={'large'} value={message} />
              </div>
              {renderList({ context: 'products', data: products, classes })}
              {renderList({ context: 'reactions', data: reactions, classes })}
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridContent}>
              <Heading className={classes.heading} component='h2'>{translations('Enhancements - XML')}</Heading>
              <div className={classes.code}>
                <SyntaxHighlighter language={'xml'} code={xml} />
              </div>
            </Grid>
          </Grid>
      </div>
    </div>
  )
}

ReportEnhancementModal.propTypes = {
  classes: PropTypes.any,
  closeModal: PropTypes.func.isRequired,
  xml: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  message: PropTypes.string,
  products: PropTypes.array,
  reactions: PropTypes.array
}

export default withStyles(styles)(ReportEnhancementModal)
