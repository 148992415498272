import { translations } from '../../../../../config'

const commonFields = ({ editing }) => ({
  name: {
    id: 'name',
    field: 'Input',
    props: {
      label: translations('Name'),
      name: 'name',
      required: true,
      disabled: editing
    }
  },
  displayName: {
    id: 'displayName',
    field: 'Input',
    props: {
      label: translations('Display name'),
      name: 'displayName'
    }
  }
})

const sectionSchema = ({ editing }) => {
  const fields = commonFields({ editing })

  const createSchema = {
    general: {
      title: 'General',
      layout: [],
      schema: [
        fields.name,
        fields.displayName
      ]
    }
  }

  const updateSchema = {
    general: createSchema.general,
    appearance: {
      title: 'Appearance',
      layout: [],
      schema: [
        {
          id: 'hideFromForm',
          field: 'Checkbox',
          props: {
            label: translations('Read only'),
            name: 'hideFromForm',
            help: translations('Report Configuration - hideSectionFromForm Help')
          }
        },
        {
          id: 'isForThromboticEvents',
          field: 'Checkbox',
          props: {
            label: translations('Is used for thrombotic events'),
            name: 'isForThromboticEvents',
            help: translations('Report Configuration - isForThromboticEvents Help')
          }
        },
        {
          id: 'isForMyocarditis',
          field: 'Checkbox',
          props: {
            label: translations('Is used for myocarditis reporting'),
            name: 'isForMyocarditis',
            help: translations('Report Configuration - isForMyocarditis Help')
          }
        },
        {
          id: 'isForAssessment',
          field: 'Checkbox',
          props: {
            label: translations('Is for Assessment'),
            name: 'isForAssessment',
            help: translations('Report Configuration - isForAssessment Help')
          }
        }
      ]
    }
  }

  return editing ? updateSchema : createSchema
}

export const getSchema = ({ editing = false }) => {
  return sectionSchema({ editing })
}
