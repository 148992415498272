import React, { Component, Children } from 'react'
import { List, AutoSizer } from 'react-virtualized'

import { paperHeight } from '../Paper'

class VirtualizedMenuList extends Component {
  rowRenderer = (params) => {
    const { index, style } = params
    const { children, classes } = this.props
    const childrenArr = Children.toArray(children)

    return (
      <div style={style} className={classes.listItem}>
        {childrenArr[index]}
      </div>
    )
  }

  render () {
    const { children, className, dropdownValue, height, rowRenderer } = this.props
    const rowCount = Children.toArray(children).length
    return <div>
      <AutoSizer>
        {({ width }) => (
          <List
            className={className}
            rowRenderer={rowRenderer}
            height={height || paperHeight}
            width={width}
            rowHeight={48}
            rowCount={rowCount}
            dropdownValue={dropdownValue}
          />
        )}
      </AutoSizer>
    </div>
  }
}

export default VirtualizedMenuList
