import _ from 'lodash'
import { createSelector } from 'reselect'
import { selectors as platformDetailsSelectors } from '../platformDetails'
import { selectors as platformSelectors } from '../platforms'
import { selectors as organisationSelectors } from '../organisations'

export const getAvailableOrganisationsForPlatformOptions = createSelector([
  platformDetailsSelectors.getCurrentPlatformDetails,
  organisationSelectors.getAllOrganisations,
  organisationSelectors.getUnassignedOrganisations
], (currentPlatform, organisations, unassignedOrganisations) => {
  return _.chain(_.compact(_.concat(organisations, unassignedOrganisations)))
    .filter(org => {
      return _.get(org, 'platformId') === null || _.get(org, 'platformId') === currentPlatform.id
    })
    .map(org => ({ value: org.id, label: org.displayName || org.name }))
    .value()
})

export const getHasLoadReportsPermissions = createSelector([
  platformSelectors.getCurrentPlatformPermissions
], (currentPlatformPermissions) => {
  return _.get(currentPlatformPermissions, 'loadReport', false)
})
