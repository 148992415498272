"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countryPhoneDictionary = exports.countryPhoneCodes = exports.countriesISO3 = exports.countriesFullNamesValues = exports.countriesDirectory = exports.countries = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _countries = _interopRequireDefault(require("./countries.json"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var sortWithPriority = function sortWithPriority(arr, topItemCodes, sortBy) {
  var sortedArr = arr.slice().sort(function (a, b) {
    if (a[sortBy] > b[sortBy]) return 1;
    if (a[sortBy] < b[sortBy]) return -1;
    return 1;
  }).filter(function (item) {
    return !topItemCodes.find(function (i) {
      return item[sortBy] === i[sortBy];
    });
  });
  var finalArray = arr.filter(function (item) {
    return topItemCodes.find(function (i) {
      return item[sortBy] === i[sortBy];
    });
  }).concat(sortedArr);
  return finalArray;
};

var allCountries = _countries["default"].allCountries,
    prioritized = _countries["default"].prioritized;
var orderedNumByNameList = sortWithPriority(allCountries, prioritized, 'name');
var orderedNumByCodeList = sortWithPriority(allCountries, prioritized, 'code');

var countryPhoneCodes = _lodash["default"].map(orderedNumByCodeList, function (country) {
  return "".concat(country.code, " ").concat(country.dialCode);
});

exports.countryPhoneCodes = countryPhoneCodes;
var countryPhoneDictionary = orderedNumByNameList.reduce(function (memo, next) {
  return _objectSpread(_objectSpread({}, memo), {}, _defineProperty({}, next.dialCode, next));
}, {});
exports.countryPhoneDictionary = countryPhoneDictionary;
var countries = orderedNumByNameList.map(function (country) {
  return country.name;
});
exports.countries = countries;

var countriesFullNamesValues = _lodash["default"].map(allCountries, function (_ref) {
  var name = _ref.name;
  return {
    label: name,
    value: name
  };
});

exports.countriesFullNamesValues = countriesFullNamesValues;

var countriesISO3 = _lodash["default"].map(allCountries, function (_ref2) {
  var name = _ref2.name,
      countryCode = _ref2.countryCode;
  return {
    label: "".concat(name, " (").concat(countryCode, ")"),
    value: countryCode
  };
});

exports.countriesISO3 = countriesISO3;

var countriesDirectory = _lodash["default"].map(allCountries, function (_ref3) {
  var name = _ref3.name,
      dialCode = _ref3.dialCode,
      emoji = _ref3.emoji,
      code = _ref3.code;
  return {
    label: "".concat(emoji, " ").concat(name, " (").concat(dialCode, ")"),
    value: code
  };
});

exports.countriesDirectory = countriesDirectory;