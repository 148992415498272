import _ from 'lodash'
import React from 'react'
import StandardForm from '../StandardForm'
import { SdkProvider, useSdk } from '../../../services/sdk'
import { FORM_ERROR } from 'final-form'

const ReportSubmissionGuideForm = ({
  onSuccess,
  formViews,
  userId,
  audienceId
}) => {

  const onSubmit = async (values) => {
    try {
      const formViewId = values.formViewId
      const formView = _.find(formViews, formView => formView.id === formViewId)
      onSuccess(formView)
    } catch (error) {
      console.log(error)
    }
  }
       
  return (
    <StandardForm
      schemaName={'reportSubmissionGuide'}
      initialValues={{
        formViews,
        occupation: audienceId,
        existingUserWithOccupation: userId && audienceId,
        forceSubmit: onSubmit
      }}
      onSubmit={onSubmit}
    />
  )
}

const WrappedReportSubmissionGuideForm = (props) => {
  const {
    baseUrl,
    organisationId,
    platformId,
    ...rest
  } = props

  return (
    <SdkProvider
      baseUrl={baseUrl}
      organisationId={organisationId}
      platformId={platformId}
    >
      <ReportSubmissionGuideForm {...rest} />
    </SdkProvider>
  )
}

export default WrappedReportSubmissionGuideForm