import _ from 'lodash'
import { LOCATION_CHANGE } from 'connected-react-router'
import { selectors as authSelectors } from '../auth'
import newsActions from './actions'
import { actions as tagActions } from '../tags'

class NewsMiddleware {
  loadInitialNewsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const isRoutingToNews = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/news'
    const isLoggedIn = authSelectors.getIsLoggedIn(getState())
    if (isRoutingToNews && isLoggedIn) {
      dispatch(newsActions.fetchNews({ newsType: 'default' }))
      dispatch(newsActions.fetchNews({ newsType: 'watchlist' }))
      dispatch(tagActions.fetchTags({ resources: ['newsFeedContent'] }))
    }
  }
}

export default new NewsMiddleware()
