import _ from 'lodash'
import {
  compose,
  flattenProp,
  withHandlers
} from 'recompose'

import ReportEnhancementModal from './ReportEnhancementModal'

const enhancer = compose(
  flattenProp('enhancements'),
  withHandlers({
    closeModal: ({ dismiss }) => () => {
      dismiss()
    }
  })
)

export default enhancer(ReportEnhancementModal)
