import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import P from '../P'
import ConditionsList from './ConditionsList'

import { styled } from '@material-ui/core/styles'
import { translations } from '../../config'

const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2)
}))

const BlankState = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  borderLeft: `4px solid ${theme.palette.grey[300]}`
}))

/**
 * Visual conditions builder
 */
export const Conditions = (props) => {
  const { conditions, onEdit, onDelete, fieldLookup, readOnly, onSelect, selectedLookup, showCombinator } = props
  return (
    <Container>
      {_.isEmpty(conditions) && (
        <BlankState>
          <P value={translations('No conditions')} />
        </BlankState>
      )}
      <ConditionsList
        conditions={conditions}
        onEdit={onEdit}
        onDelete={onDelete}
        fieldLookup={fieldLookup}
        readOnly={readOnly}
        onSelect={onSelect}
        selectedLookup={selectedLookup}
        showCombinator={showCombinator}
      />
    </Container>
  )
}

Conditions.defaultProps = {
  conditions: [],
  readOnly: false
}

Conditions.propTypes = {
  conditions: PropTypes.array.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool
}

export default Conditions
