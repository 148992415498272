import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import _ from 'lodash'
import { Accordion, AccordionDetails, AccordionSummary, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'

import Drawer from '../../../../components/Drawer'
import { translations } from '../../../../config'
import { removeQuery } from '../../../../helpers/query'
import { useQuery } from '../../../../hooks'
import { actions as reportConfigurationActions, hooks as reportConfigurationHooks } from '../../../../store/modules/reportConfiguration'
import Content from '../Components/Content'
import ReporterFollowUpRow from './ReporterFollowUpRow'
import modalService from '../../../../services/modalService'
import InsertButton from '../../../../components/FormviewEditor/InsertButton'
import toastService from '../../../../services/toastService'
import AdHocFollowUpModal from './AdHocFollowUpModal'
import style from './style'
import moment from 'moment'


const toastError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Save Report Config')
})
const toastSuccess = () => toastService.action({
  type: 'success',
  message: translations('Successfully - Save Report Config')
})
const duplicateError = () => toastService.action({
  type: 'error',
  message: translations('Failure - Save Duplicate Report Config'),
  autoHideDuration: 3000
})

const AddIndividualFollowUpButton = ({
  onSubmit,
  otherFollowUpNames,
  formViewId,
  organisationLanguages,
  reporterUser,
  reportId,
  extReportId
}) => {
  const openNewCohortModal = () => {
    modalService.open({
      title: translations('FollowUp - Add Individual Followup'),
      component: AdHocFollowUpModal,
      disableBackdropClick: true,
      onSubmit,
      closeModal: () => modalService.close(),
      otherFollowUpNames,
      formViewId,
      organisationLanguages,
      reporterUser,
      reportId,
      extReportId
    })
  }
  return (
    <InsertButton
      fullWidth
      size='large'
      onClick={openNewCohortModal}
    >
      {translations('FollowUp - Add Individual Followup')}
    </InsertButton>

  )
}

const ReporterFollowUpDrawer = ({ classes, organisationLanguages }) => {
  const [open, setOpen] = useState(false)
  const { reporter } = useQuery()
  const dispatch = useDispatch()
  const { id: formViewId } = useParams()
  const reporterUser = reportConfigurationHooks.useFormViewReporter({ userId: reporter, formViewId })
  const reporterFollowUps = reportConfigurationHooks.useFormViewReporterFollowUps({ userId: reporter })
  const activeFormView = reportConfigurationHooks.useFormViewById(formViewId)
  const followUps = reportConfigurationHooks.useTimeframeSortedFollowUps(formViewId) || []
  const groupedFollowUps = _.groupBy(reporterFollowUps, 'report.extReportId')
  const reporterName = _.startCase(_.get(reporterUser, 'fullName'))

  const otherFollowUpNames = _.without(_.map(followUps, ({ formView: { name } = {} }) => name), [null, undefined, ''])

  const formSchemaLibrary = new FormSchemaLibrary()

  useEffect(() => {
    const shouldOpen = !_.isUndefined(reporter)
    if (shouldOpen) {
      fetchReporterFollowUps()
      removeQuery(['followUp'])
    }
    setOpen(shouldOpen)
  }, [reporter])

  const fetchReporterFollowUps = () => {
    dispatch(reportConfigurationActions.fetchFormViewReporterFollowUps({ userId: reporter, reportFormViewId: formViewId }))
  }

  const handleClose = () => {
    setOpen(false)
    removeQuery(['reporter', 'followUp'])
  }

  const addIndividualFollowUp = ({ scheduledDate, ...params }) => {
    const schemaName = _.get(activeFormView, 'formSchema.name')
    const standardBase = formViewId && _.get(formSchemaLibrary.getDefaultView(schemaName, null), 'sections', [])
    dispatch(reportConfigurationActions.createIndividualFollowUp({
      params: {
        ...params,
        viewJSON: standardBase,
        scheduledDate: moment(scheduledDate).format('YYYY-MM-DD')
      },
      formViewId,
      userId: reporter
    }))
      .then(res => {
        modalService.close()
        toastSuccess()
        fetchReporterFollowUps()
        return res
      })
      .catch(err => {
        // if duplicate FormView error
        if (err.code === 'RA-MHR-157-06') {
          duplicateError()
        } else {
          toastError()
        }
      })
  }

  return (
    <Drawer
      heading={translations('All Reporters Followups', { name: reporterName })}
      anchor={'right'}
      open={open}
      onClose={handleClose}
    >
      <Content>
        {
          _.map(groupedFollowUps, (reportFollowUps, extReportId, index) => {
            const firstFU = _.head(reportFollowUps)
            const reportId = _.get(firstFU, 'reportId')

            return (
              <Accordion key={extReportId} defaultExpanded={_.size(groupedFollowUps) === 1}>
                <AccordionSummary
                  id={`followup-${index}-header`}
                  aria-controls={`followup-${index}-content`}
                  expandIcon={<ExpandMore />}
                >
                  <Typography>{extReportId}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.column}>
                  <TableContainer component={Paper} variant={'outlined'} style={{ marginBottom: 20 }}>
                    <Table aria-label='collapsible table' fixedHeader={false} style={{ tableLayout: 'auto' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'>{translations('FollowUp - Stage')}</TableCell>
                          <TableCell align='center'>{translations('Type')}</TableCell>
                          <TableCell align='center'>{translations('Timeframe')}</TableCell>
                          <TableCell align='center'>{translations('FollowUp - Send Date')}</TableCell>
                          <TableCell align='center'>{translations('Status')}</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {_.map(reportFollowUps, (row, index) => (
                          <ReporterFollowUpRow
                            {...row}
                            key={index}
                            rowId={`${extReportId}_${index}`}
                            reporterName={reporterName}
                            onUpdate={fetchReporterFollowUps}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <AddIndividualFollowUpButton
                    otherFollowUpNames={otherFollowUpNames}
                    onSubmit={addIndividualFollowUp}
                    formViewId={formViewId}
                    organisationLanguages={organisationLanguages}
                    reporterUser={reporterUser}
                    reportId={reportId}
                    extReportId={extReportId}
                  />
                </AccordionDetails>
              </Accordion>
            )
          })
        }
        {
          _.isEmpty(groupedFollowUps) &&
          <Content>
            <Typography variant={'h6'}>{translations('All Reporters Followups No Results', { name: reporterName })}</Typography>
          </Content>
        }
      </Content>
    </Drawer>
  )
}

export default withStyles(style)(ReporterFollowUpDrawer)
