const styles = theme => ({
  textWrap: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    '& span':{
      fontSize: 13,
    }
  }
})

export default styles
