import * as constants from './constants'
import { constants as organisationDetailsConstants } from '../organisationDetails'
import reducers from './reducers'

const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_ALL_ORGANISATIONS:
      return reducers.fetchAllOrganisations(state, action)
    case constants.FETCH_UNASSIGNED_ORGANISATIONS:
      return reducers.fetchUnassignedOrganisations(state, action)
    case constants.FETCH_ORGANISATION_BY_ID:
      return reducers.fetchOrganisationById(state, action)
    // Allow us to keep organisations in sync
    case constants.UPDATE_ORGANISATION:
    case organisationDetailsConstants.UPDATE_ORGANISATION:
      return reducers.updateOrganisation(state, action)
    case constants.FETCH_PAGINATED_ORGANISATIONS:
      return reducers.fetchPaginatedOrganisations(state, action)
    case constants.CLEAR_PAGINATED_ORGANISATIONS:
      return reducers.clearPaginatedOrganisations(state, action)
    case constants.SEARCH_ORGANISATIONS:
      return reducers.searchOrganisations(state, action)
    default:
      return state
  }
}
