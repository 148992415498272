import * as constants from './constants'
import reducers from './reducers'
import moment from 'moment'

const defaultState = {
  page: 1,
  size: 50,
  error: undefined,
  query: undefined,
  total: undefined,
  status: undefined,
  results: [],
  filters: {
    dateFrom: moment().format('MM/DD/YYYY')
  },
  selected: [],
  savedSearches: [],
  activeSavedSearch: undefined,
  previousFilters: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SEARCH_SUBMISSION_REPORTS_FRESH:
      return reducers.searchFresh(state, action)
    case constants.SEARCH_SUBMISSION_REPORTS_NEXT:
      return reducers.searchNext(state, action)
    case constants.CHANGE_SUBMISSION_REPORTS_FILTER:
      return reducers.changeFilter(state, action)
    case constants.CHANGE_SUBMISSION_REPORTS_FILTERS:
      return reducers.changeFilters(state, action)
    case constants.TOGGLE_SELECTED_SUBMISSION_REPORTS:
      return reducers.toggleSelected(state, action)
    case constants.CLEAR_SELECTED:
      return reducers.clearSelected(state, action)
    case constants.TOGGLE_SELECT_ALL:
      return reducers.toggleSelectAll(state, action)
    case constants.FETCH_SAVED_SEARCHES:
      return reducers.fetchSavedSearches(state, action)
    case constants.SET_SAVED_SEARCH:
      return reducers.setSavedSearch(state, action)
    case constants.DISCARD_SAVED_FILTERS:
      return reducers.discardSavedFilters(state, action)
    default:
      return state
  }
}
