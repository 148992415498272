import { connect } from 'react-redux'
import { compose } from 'recompose'
import { selectors as receiverSelectors } from '../../../store/modules/receivers'
import ReceiverSelect from './ReceiverSelect'

export default compose(
  connect(state => ({
    options: receiverSelectors.getReceiversForDropdown(state)
  }))
)(ReceiverSelect)
