import { Grid, styled } from '@material-ui/core'
import React from 'react'
import InsertButton from '../../../../components/FormviewEditor/InsertButton'
import { translations } from '../../../../config'

const Actions = styled('div')({})

const AddConditionButton = ({ onAdd }) => {
  return (
    <Actions>
      <Grid container spacing={2}>
        <Grid item xs>
          <InsertButton onClick={() => onAdd()} fullWidth size='large'>{translations('Add Condition')}</InsertButton>
        </Grid>
      </Grid>
    </Actions>
  )
}

export default AddConditionButton
