import _ from 'lodash'
import { useSelector } from 'react-redux'

import { selectors as organisationDetailsSelectors } from './'
import { getProfessionOptions } from '../organisations/helpers'

export const useCurrentOrganisationProfessions = () => {
  const professions = useSelector(organisationDetailsSelectors.getCurrentOrganisationProfessions)
  return professions
}

export const useCurrentOrganisationProfessionsOptions = () => {
  const professions = useCurrentOrganisationProfessions()
  const options = getProfessionOptions(professions)
  return options
}
