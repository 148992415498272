import _ from 'lodash'
import { useParams } from 'react-router-dom'
import {
  getFormViews,
  isLoadingFormViews,
  getFormViewById,
  getReportResultsById,
  isLoadingReports,
  getReportsHasBeenSearched,
  getHasMore,
  getCohorts,
  getReportFilters,
  getReportsPage,
  getReportsError,
  isLoadingFormViewReporters,
  canLoadMoreReporters,
  getFormViewReportersQuery,
  getFormViewReportersFilters,
  getFormViewReportersById,
  getFollowUpFilters,
  getFollowUpById,
  getTimeframesById,
  getFollowUpCohorts,
  getFollowUpTriggers,
  getTimeframeSortedFollowUps,
  getDefaultFilters,
  getEditor,
  getFormsAndSchemasForDropdown,
  getFormViewMeta,
  getFormViewAudiencesForDropdown,
  getEditorAudiencesForDropdown,
  getSchemaField,
  getSchemaFields,
  getVersionCapabilities,
  getFormViewUsage,
  getFilteredFormViews,
  getFormViewFilters,
  getTimeFramesForDropdown,
  getFollowUpEvents,
  getFollowUpReportConfiguration,
  getFormViewCohortsById,
  getFollowUpDependencies,
  getFormViewFollowUpsByReporterId,
  getFormViewReporter,
  getReporterLatestReportByExtReportId
} from './selectors'
import { useShallowEqualSelector } from '../../../hooks'

/**
 * @description Get Form Views
 * @returns {Array} Array of form view IDs
 */
export const useFormViews = () => {
  return useShallowEqualSelector(getFormViews)
}

/**
 * @description Get Form Views loading is PENDING
 * @returns {Boolean}
 */
export const useFormViewsLoading = () => {
  return useShallowEqualSelector(isLoadingFormViews)
}

/**
 * @description Get Form Views by ID
 * @returns {Object} Form view details
 */
export const useFormViewById = (formViewId) => {
  const formView = useShallowEqualSelector(getFormViewById(formViewId))
  return formView || {}
}

/**
 * @description Get reports by formViewId
 * @returns {Object} Reports associated to this formViewId
 */
export const useReportsById = (formViewId) => {
  const reports = useShallowEqualSelector(getReportResultsById(formViewId))
  return reports || {}
}

/**
 * @description Gets loading status of the reports
 * @returns {Boolean} if PENDING == true
 */
export const useReportsPending = () => {
  return useShallowEqualSelector(isLoadingReports)
}

export const useReportsHasMore = (formViewId) => {
  return useShallowEqualSelector(getHasMore(formViewId))
}

export const useReportsPage = (formViewId) => {
  return useShallowEqualSelector(getReportsPage(formViewId))
}

export const useReportsHaveBeenSearched = () => {
  return useShallowEqualSelector(getReportsHasBeenSearched)
}

export const useReportHasError = () => {
  return useShallowEqualSelector(getReportsError)
}

export const useReportsLoading = () => {
  return useShallowEqualSelector(isLoadingReports)
}

export const useCohorts = (formViewId) => {
  const cohorts = useShallowEqualSelector(getCohorts(formViewId))
  return cohorts || []
}

export const useFilters = (formViewId) => {
  const filters = useShallowEqualSelector(getReportFilters(formViewId))
  return filters || []
}
export const useDefaultFilters = () => {
  return useShallowEqualSelector(getDefaultFilters)
}
/**
 * @description Get if Form View Reporters is loading
 * @returns {Boolean}
 */
export const useFormViewReportersLoading = () => {
  return useShallowEqualSelector(isLoadingFormViewReporters)
}

/**
 * @description Can load more reporters
 * @returns {Boolean}
 */
export const useCanLoadMoreReporters = ({ formViewId }) => {
  return useShallowEqualSelector(canLoadMoreReporters(formViewId))
}

/**
 * @description Get Form View Reporters
 * @returns {Object} Form view reporters
 */
export const useFormViewReporters = ({ formViewId }) => {
  return useShallowEqualSelector(getFormViewReportersById(formViewId))
}

/**
 * @description Get Form View Reporters Query
 * @returns {String} Form view reporters Query
 */
export const useReportersQuery = ({ formViewId }) => {
  return useShallowEqualSelector(getFormViewReportersQuery(formViewId))
}

export const useReportersFilters = ({ formViewId }) => {
  return useShallowEqualSelector(getFormViewReportersFilters(formViewId))
}

export const useFollowUpFilters = (formViewId) => {
  return useShallowEqualSelector(getFollowUpFilters(formViewId))
}

export const useFollowUpCohorts = (formViewId) => {
  return useShallowEqualSelector(getFollowUpCohorts(formViewId))
}

export const useFollowUpTriggers = (formViewId) => {
  return useShallowEqualSelector(getFollowUpTriggers(formViewId))
}

export const useFollowUpById = (formViewId) => {
  return useShallowEqualSelector(getFollowUpById(formViewId))
}

export const useTimeframeSortedFollowUps = (formViewId) => {
  return useShallowEqualSelector(getTimeframeSortedFollowUps(formViewId))
}

export const useTimeframeById = (formViewId) => {
  return useShallowEqualSelector(getTimeframesById(formViewId))
}

export const useTimeframeForDropdown = (formViewId) => {
  return useShallowEqualSelector(getTimeFramesForDropdown(formViewId))
}

export const useFollowUpEvents = (formViewId) => {
  return useShallowEqualSelector(getFollowUpEvents(formViewId))
}

export const useFormViewEditing = () => {
  return useShallowEqualSelector(getEditor)
}

export const useFormViewDropdown = () => {
  return useShallowEqualSelector(getFormsAndSchemasForDropdown)
}

export const useFilteredFormViews = () => {
  return useShallowEqualSelector(getFilteredFormViews)
}

export const useFormViewFilters = () => {
  return useShallowEqualSelector(getFormViewFilters)
}

export const useFormViewMetaData = (formViewId) => {
  return useShallowEqualSelector(getFormViewMeta(formViewId))
}

export const useFormViewUsage = (formViewId) => {
  return useShallowEqualSelector(getFormViewUsage(formViewId))
}

export const useFormViewVersionCapabilities = (formViewId) => {
  return useShallowEqualSelector(getVersionCapabilities(formViewId))
}

export const useFormViewVersionHasFormBuilder = (formViewId) => {
  const capabilities = useShallowEqualSelector(getVersionCapabilities(formViewId))
  return _.get(capabilities, 'formBuilder', false)
}

export const useAudiencesForDropdown = (formViewId) => {
  return useShallowEqualSelector(getFormViewAudiencesForDropdown(formViewId))
}

export const useEditorAudiencesForDropdown = (template) => {
  return useShallowEqualSelector(getEditorAudiencesForDropdown(template))
}

export const useCurrentFormViewMeta = () => {
  const { id: formViewId } = useParams()
  const isNewFormView = formViewId === 'new'
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formView = isNewFormView ? useFormViewEditing() : useFormViewMetaData(formViewId)
  return formView
}

export const useSchemaField = ({ formSchema, fieldId, parentId }) => {
  return useShallowEqualSelector(getSchemaField({ formSchema, fieldId, parentId }))
}

export const useSchemaFields = ({ formSchema }) => {
  return useShallowEqualSelector(getSchemaFields({ formSchema })) || {}
}

export const useFollowUpReportConfiguration = ({ reportConfigurationId, followUpId }) => {
  return useShallowEqualSelector(getFollowUpReportConfiguration({ reportConfigurationId, followUpId })) || {}
}

/**
 * @description Get Form View Cohorts
 * @returns {Object} Form view cohorts
 */
export const useFormViewCohorts = ({ formViewId }) => {
  return useShallowEqualSelector(getFormViewCohortsById(formViewId))
}

export const useFollowUpDependencies = ({ formViewId }) => {
  return useShallowEqualSelector(getFollowUpDependencies(formViewId))
}

/**
 * @description Get followups for a form view reporter
 * @returns {Object} Form view reporter followups
 */
export const useFormViewReporterFollowUps = ({ userId }) => {
  return useShallowEqualSelector(getFormViewFollowUpsByReporterId(userId))
}

/**
 * @description Get a single reporter
 * @returns {Object} Form view reporter
 */
export const useFormViewReporter = ({ userId, formViewId }) => {
  return useShallowEqualSelector(getFormViewReporter(formViewId, userId))
}

/**
 * @description Get the latest version of a report for the user
 * @returns {Object} report
 */
export const useLatestReporterReport = ({ userId, extReportId }) => {
  return useShallowEqualSelector(getReporterLatestReportByExtReportId(userId, extReportId))
}
