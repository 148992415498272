import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'
import { promiseReducer } from '../../util'
import { LOGIN, SELECT_ORGANISATION } from './constants'
class AuthReducers {
  login = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type,
          migrationRequired: false
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: this.withProfessionId({ userDetails: {...state.user, ...action.result }}),
          error: {},
          migrationRequired: this.isMigrationRequired(state, action),
          fromLocation: action.from
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error,
          migrationRequired: false
        }
      default:
        return state
    }
  }

  logout = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: undefined,
          migrationRequired: false
        }
      case FAIL:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: undefined,
          migrationRequired: false
        }
      default:
        return state
    }
  }

  selectOrganisation = (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        selectedOrganisationId: action.organisationId,
        selectedRegionId: action.regionId,
        selectedReceiverId: action.receiverId,
      },
      migrationRequired: this.isMigrationRequired(state, action)
    }
  }

  loadSelectedOrganisation = promiseReducer(
    (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          selectedOrganisation: action.result
        },
        migrationRequired: this.isMigrationRequired(state, action)
      }
    }
  )

  updateUser = (state, action) => {
    const { user } = action
    return {
      ...state,
      user: this.withProfessionId({ userDetails: {...state.user, ...user }})
    }
  }

  editWatchList = promiseReducer(
    (state, action) => {
      const watchList = _.get(action, 'result.watchList')
      return {
        ...state,
        user: {
          ...state.user,
          watchList
        }
      }
    }
  )

  getAuthContext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: this.withProfessionId({ userDetails: {...state.user, ...action.result }}),
          error: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  getSSOAuthContext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: this.withProfessionId({ userDetails: { ...state.user, ...action.result, loginMethod: 'sso' }}),
          error: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  withProfessionId = ({ userDetails }) => {
    const selectedOrganisationId = _.get(userDetails, 'selectedOrganisationId')
    const professions = _.get(userDetails, 'professions')
    const profession = _.find(professions, { organisationId: selectedOrganisationId })
    const professionId = _.get(profession, 'id')

    return { ...userDetails, professionId }
  }

  isMigrationRequired = (state, action) => {
    let showMigration = false
    let validPasswordPolicy = true
    let organisationId

    if (action.type === LOGIN) {
      validPasswordPolicy = !_.get(action, 'result.passwordPolicy.isValid')
      organisationId = _.get(state, 'user.selectedOrganisationId')
    }

    if (action.type === SELECT_ORGANISATION) {
      validPasswordPolicy = !_.get(state, 'user.passwordPolicy.isValid')
      organisationId = _.get(action, 'organisationId')
    }

    showMigration = validPasswordPolicy && !_.isEmpty(organisationId)
    return showMigration
  }
}
export default new AuthReducers()
