import general from './configFiles/general'

const {
  loginTypes,
  communicationOptions,
  paymentConfig,
  titles,
  passwordRequirements,
  disableLogoutTimer,
  logoutTime,
  loyaltyTiers,
  clientReporting,
  checkoutModules,
  referralModules,
  currencyCode,
  auth,
  componentConfigs,
  dateFormat,
  longDateFormat,
  timeFormat,
  newsDateFormat,
  emailDateFormat,
  noPrices,
  addressLookupCountries,
  retailAnalytics,
  checkoutBackdateLimit,
  footerLink,
  footerLogoAlt,
  headerLink,
  headerLogoAlt,
  viewOtherUsersNotificationsEnabled,
  sendOtherUsersNotificationsEnabled
} = general

export {
  loginTypes,
  communicationOptions,
  paymentConfig,
  titles,
  passwordRequirements,
  disableLogoutTimer,
  logoutTime,
  loyaltyTiers,
  clientReporting,
  checkoutModules,
  referralModules,
  currencyCode,
  auth,
  componentConfigs,
  dateFormat,
  longDateFormat,
  timeFormat,
  newsDateFormat,
  emailDateFormat,
  noPrices,
  addressLookupCountries,
  retailAnalytics,
  checkoutBackdateLimit,
  footerLink,
  footerLogoAlt,
  headerLink,
  headerLogoAlt,
  viewOtherUsersNotificationsEnabled,
  sendOtherUsersNotificationsEnabled
}
