import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import ManageContentBox from '../ManageContentBox'
import Button from '../Button'
import Heading from '../Heading'
import TableModal from './TableModal'
import { translations } from '../../config'

import modalService from '../../services/modalService'

import Table from './Table'
import style from './style'

const renderUploadButton = props => {
  const { onClickUpload, editable } = props
  return (
    editable && !window.cordova
    ? <Button
      onClick={onClickUpload}
      children={translations('Upload')}
      color='primary'
      raised
    />
    : null
  )
}

const renderContent = props => {
  const { data, noDataMessage, classes, name, limit } = props
  const modalIndex = 0
  const showViewAll = _.size(data) > limit

  if (data && data.length) {
    const onClick = () => modalService.open({
      component: TableModal,
      title: name,
      fullScreen: true,
      tableProps: _.omit(props, ['classes', 'limit']),
      modalIndex,
      onClose: () => modalService.close({ modalIndex })
    })

    return (
      <Fragment>
        <Table {..._.omit(props, 'classes')} />
        { showViewAll && (
          <div className={classes.viewAllContainer}>
            <Button
              onClick={onClick}
              children={translations('View all')}
              raised
            />
          </div>
        )}
      </Fragment>
    )
  } else {
    return <Heading
      children={noDataMessage}
      variant='h3'
      className={classes.noResultsMessage}
    />
  }
}

const ImportDataPanel = props => {
  const { name } = props
  return <ManageContentBox
    name={name}
    rightButtonContent={renderUploadButton(props)}
    content={renderContent(props)}
  />
}

ImportDataPanel.defaultProps = {
  limit: 2
}

ImportDataPanel.propTypes = {
  name: PropTypes.string,
  onClickUpload: PropTypes.func,
  data: PropTypes.array
}

export default withStyles(style)(ImportDataPanel)
