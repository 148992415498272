import _ from 'lodash'
import React from 'react'
import Section, { SectionTypes } from '../Section'
import { ActionButton } from '../ReportSummaryModal'
import { translations } from '../../../../../config'
import { actions as reportDetailsActions, selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import { authCombinedSelectors } from '../../../../../store/modules/combinedSelectors'
import { useDispatch, useSelector } from 'react-redux'
import modalService from '../../../../../services/modalService'
import AdminActionModal from '../../../../../components/AdminActionModal/AdminActionModal'

const AdminActions = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection
  } = props

  const dispatch = useDispatch()

  const reportId = useSelector(reportDetailsSelectors.getReportId)
  const canUseAdminOptionsForReport = useSelector(authCombinedSelectors.canUseAdminOptionsForReport)

  if (!canUseAdminOptionsForReport) {
    return <></>
  }

  const showAdminOptionsForReport = () => {
    modalService.open({
      component: AdminActionModal,
      modalIndex: 2,
      actions: [
        {
          text: translations('Regenerate Report'),
          primary: true,
          success: true,
          onClick: () => {
            dispatch(reportDetailsActions.regenerateReportById({ id: reportId }))
          }
        },
        {
          text: translations('Cancel'),
        }
      ]
    })
  }

  const updateReportButtonLegacy =
    <ActionButton onClick={showAdminOptionsForReport}>{translations('Show Admin Options')}</ActionButton>

  const rows = [
    { type: SectionTypes.ACTION, value: canUseAdminOptionsForReport ? updateReportButtonLegacy : null },
  ]

  return (
    <Section
      name='Admin Actions'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
    />
  )
}

export default AdminActions