import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import VisuallyHidden from '@reach/visually-hidden'

import Field from '../Field'
import FieldMeta from '../FieldMeta'
import ErrorMessage from '../ErrorMessage'
import Hint from '../Hint'
import Checkbox from '../Checkbox'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.singleRow ? 'row' : 'column' };
  margin-left: ${(props) => props.singleRow ? '-12px' : '0px' }};
  `

/**
 * Checklist 
 */
const Checklist = (props) => {
  const {
    meta: {
      error,
      touched
    },
    fields,
    id,
    hint,
    link,
    label,
    options,
    required,
    checkboxInterfaceActive,
    checkboxInterfaceInactive,
    singleRow = false
  } = props
  const { push, remove, name } = fields
  const fieldInvalid = error && touched
  const errorMessage = fieldInvalid ? error : undefined

  const handleChange = (status, value) => {
    if (status) push(value)
    else remove(fields.value.indexOf(value))
  }

  return (
    <Field as='fieldset' data-name={name} error={fieldInvalid}>
      <VisuallyHidden><legend>{label}</legend></VisuallyHidden>
      <FieldMeta
        name={id}
        label={label}
        link={link}
        required={required}
      />
      <Container singleRow={singleRow}>
        {options.map(option => {

          const value = option.value || option.label

          return (
            <Checkbox
              key={value}
              {...option}
              id={`${id}.${value}`}
              required={required}
              isGroup={true}
              input={{
                onChange: status => handleChange(status, value),
                value: _.includes(fields.value, value),
                name,
              }}
              checkboxInterfaceActive={checkboxInterfaceActive}
              checkboxInterfaceInactive={checkboxInterfaceInactive}
            />
          )
        })}
      </Container>
      {errorMessage && <ErrorMessage error={errorMessage} />}
      {hint && <Hint message={hint} />}
    </Field>
  )
}

Checklist.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** Array of Checkbox props  */
  options: PropTypes.array,
  /** Field props based from React Final Form Arrays
 * 
 * `push` - Call to add to the array of values
 * 
 * `remove` - Call to remove a specific value from the array of values
 * 
 * `name` - name of the array field within the form
*/
  fields: PropTypes.shape({
    push: PropTypes.func,
    remove: PropTypes.func,
    name: PropTypes.string
  }),
  /** Meta props based from React Final Form
   * 
   * `error` - Field validation message
   * 
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  }),
  checkboxInterfaceActive: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  checkboxInterfaceInactive: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  /** If the list should be rendered on a single row instead of vertically */
  singleRow: PropTypes.bool
}

Checklist.defaultProps = {
  required: false,
  meta: {},
  fields: {},
  options: [],
  checkboxInterfaceActive: true,
  checkboxInterfaceInactive: false,
  singleRow: false
}

export default Checklist
