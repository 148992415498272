import React from 'react'
import {
  BarChart as RechartBarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Cell,
  CartesianGrid,
  Text
} from 'recharts'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

import Heading from '../../Heading'

import style from './style'
import { translations } from '../../../config'

const TickComp = props => {
  const { value } = props.payload
  const style = {
    fontSize: 13,
    transform: `translateY(-3px)`
  }
  return <Text
    {...props}
    verticalAnchor='end'
    children={value}
    style={style}
  />
}

const BarChart = props => {
  const { data = [], xLabel, isLoading, classes } = props
  return <div className={classes.barChartContainer}>
    <ResponsiveContainer width={'100%'}>
      <RechartBarChart
        barCategoryGap={0}
        data={isLoading ? null : data}
        layout='horizontal'
        margin={{ top: 20 }}
      >
        <CartesianGrid
          vertical={false}
        />
        <XAxis
          axisLine={false}
          dataKey='key'
          label={{ value: xLabel, fontSize: 14 }}
          padding={{ left: 40 }}
          tick={false}
          type='category'
        />
        <YAxis
          allowDecimals={false}
          axisLine={false}
          dataKey='value'
          mirror
          tick={<TickComp />}
          tickSize={0}
          type='number'
        />
        <Bar
          dataKey='value'
          isAnimationActive={false}
        >
          {data.map((datum, index) => {
            return <Cell
              fill={datum.color}
              key={datum.key}
            />
          })}
        </Bar>
      </RechartBarChart>
    </ResponsiveContainer>
    {
      isLoading
      ? <CircularProgress size={50} className={classes.centerAbsolutely} />
      : (
        data.length
        ? null
        : <Heading
          variant='h3'
          children={translations('Bar Chart - No Data')}
          className={classes.centerAbsolutely}
        />
      )
    }
  </div>
}

export default withStyles(style)(BarChart)
