import { connect } from 'react-redux'
import { isSubmitting, isInvalid, submit } from 'redux-form'
import _ from 'lodash'
import {
  compose,
  withHandlers,
  withPropsOnChange
} from 'recompose'
import digitalStoreSdk from '../../../../../digitalStoreSdk'

import {
  selectors as formViewSelectors,
  actions as formViewActions,
  constants as formViewConstants
} from '../../../../../store/modules/formViews'

import modalService from '../../../../../services/modalService'
import toastService from '../../../../../services/toastService'
import { translations } from '../../../../../config'
import ImportModal from './ImportModal'

const enhancer = compose(
  connect((state) => {
    return {
      isSubmitting: isSubmitting(formViewConstants.IMPORT_CONFIGURATION_FORM)(state),
      isInvalid: isInvalid(formViewConstants.IMPORT_CONFIGURATION_FORM)(state),
      formViewId: formViewSelectors.getCurrentFormViewId(state),
      formSchemaName: formViewSelectors.getCurrentFormViewFormSchemaName(state)
    }
  }),
  withHandlers({
    closeModal: () => () => {
      modalService.close()
    },
    onSubmit: ({ dispatch }) => () => {
      dispatch(submit(formViewConstants.IMPORT_CONFIGURATION_FORM))
    },
    handleSubmit: (props) => async (formData) => {
      try {
        const { dispatch, onSave, formSchemaName } = props
        let { formViewId } = props

        // Only one of the form fields will be completed
        let { sourceFormViewId, viewJSON } = formData

        // Prevent unnecessary updates
        if (_.isString(formViewId) && formViewId === sourceFormViewId) {
          throw new Error('Failure - Report Configuration ID identical')
        }

        // viewJSON from form input requires parsing
        if (viewJSON) {
          viewJSON = JSON.parse(viewJSON)
        }

        // Fetch formView by ID and validate the formSchema is of the same type
        if (sourceFormViewId) {
          const sourceFormView = await digitalStoreSdk.formView.fetchFormViewById({ id: sourceFormViewId })

          if (_.get(sourceFormView, 'formSchema.name') !== formSchemaName) {
            throw new Error('Failure - Report Configuration Schema Mismatch')
          }

          viewJSON = _.get(sourceFormView, 'formViewVersions.0.viewJSON')
        }

        // Save current report configuration if it's unsaved
        if (!formViewId) {
          const formView = await onSave()
          formViewId = _.get(formView, 'formViewId')
        }

        return dispatch(formViewActions.updateFormVersion({
          id: formViewId,
          viewJSON
        }))
          .then(() => {
            modalService.close()
            toastService.action({
              type: 'success',
              message: translations('Successfully - Save Report Config'),
              autoHideDuration: 6000
            })
          })
      } catch (error) {
        let errorTranslation = 'Failure - Save Report Config'

        if (_.startsWith(error.message, 'Failure -')) {
          errorTranslation = error.message
        }

        if (_.get(error, 'code')) {
          errorTranslation = error.code
        }

        toastService.action({
          type: 'error',
          message: translations(errorTranslation),
          autoHideDuration: 6000
        })
      }
    }
  }),
  withPropsOnChange(['isSubmitting'], (props) => {
    const { onSubmit, closeModal, isSubmitting } = props
    return {
      actions: [
        {
          success: true,
          text: translations('Import'),
          submitting: isSubmitting,
          onClick: onSubmit,
          primary: true
        },
        {
          text: translations('Cancel'),
          onClick: closeModal
        }
      ]
    }
  })
)

export default enhancer(ImportModal)
