import _ from 'lodash'
import { promiseReducer } from '../../util'

class DrugDictionaryReducers {
  searchDrugDictionary = promiseReducer(
    (state, action) => {
      const { query, watchListOnly } = action
      const results = _.get(action, 'result.rows')
      const total = _.get(action, 'result.count')
      return {
        ...state,
        results: [...results],
        total,
        page: 1,
        query,
        watchListOnly
      }
    }
  )

  searchDrugDictionaryNext = promiseReducer(
    (state, action) => {
      const { page, results } = state
      const { query, watchListOnly } = action

      // only append new page of results if they match the query and watchListOnly
      // state of the results already in the store. this prevents janky stuff when
      // switching rapidly between products and watch list page, for instance
      if (query === state.query && watchListOnly === state.watchListOnly) {
        const newResults = _.get(action, 'result.rows')
        const total = _.get(action, 'result.count')
        return {
          ...state,
          results: [...results, ...newResults],
          total,
          page: page + 1,
          query,
          watchListOnly
        }
      } else {
        return state
      }
    }
  )

  updateWatching = (state, action) => {
    const { payload } = action
    
    const { results = [], watchListOnly } = state
    const updatedResults = [...results]

    _.forEach(payload, (watching, drugName) => {
      const matchingDrugIndex = results.findIndex(drug => {
        return _.get(drug, 'name', '').toLowerCase() === drugName.toLowerCase()
      })
      if (matchingDrugIndex > -1) {
        if (watchListOnly && !watching) {
          // so if we're in watch list only mode the drug instantly
          // disappears instead of just having an unticked checkbox
          updatedResults.splice(matchingDrugIndex, 1)
        } else {
          const updatedDrug = {
            ...results[matchingDrugIndex],
            watching
          }
          updatedResults[matchingDrugIndex] = updatedDrug
        }
      }
    })

    return {
      ...state,
      results: updatedResults
    }
  }
}

export default new DrugDictionaryReducers()
