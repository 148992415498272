import React, { createContext, useEffect, useContext, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { asyncActions as terminologyActions, selectors as terminologySelectors } from '../../../../../../../store/modules/terminology'

const initialState = []

const dictionaryStore = createContext(initialState)
const { Provider } = dictionaryStore

const DictionaryProvider = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(terminologyActions.getTerminologyArrangements())
  }, [dispatch])

  const arrangementDictionaries = useSelector(terminologySelectors.getTerminologyArrangementsData)

  const dictionaries = useMemo(() => {
    if (!arrangementDictionaries) {
      return []
    }
    const mappedDictionaries = _.map(arrangementDictionaries, ({ name, description, id }) => {
      return {
        id,
        displayName: name,
        label: description
      }
    })
    return mappedDictionaries
  }, [arrangementDictionaries])

  return (
    <Provider
      value={dictionaries}>
      {children}
    </Provider>
  )
}

export const useDictionaries = () => useContext(dictionaryStore)

// eslint-disable-next-line import/no-anonymous-default-export
export default { DictionaryProvider, useDictionaries }
