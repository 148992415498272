import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Form, { FormBody } from '../../../components/Form'
import BasicModalOverlay from '../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../config'
import { constants as communicationTemplateConstants } from '../../../store/modules/communicationTemplates'

import style from './style'

const ConfigureReportForm = Form(communicationTemplateConstants.CONFIGURE_COMMUNICATIONS_FORM)

const ConfigureModal = (props) => {
  const {
    handleSubmit,
    actions,
    schema,
    initialValues,
    classes
  } = props
  return (
    <BasicModalOverlay
      title={translations('Communications - Configure Modal Heading')}
      actions={actions}
    >
      <ConfigureReportForm onSubmit={handleSubmit} initialValues={initialValues}>
        <ContentBoxBody>
          <div className={classes.container}>
            <FormBody
              schema={schema}
              editing
              fullWidthFields
            />
          </div>
        </ContentBoxBody>
      </ConfigureReportForm>
    </BasicModalOverlay>
  )
}

ConfigureModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  schema: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object
}

export default withStyles(style)(ConfigureModal)
