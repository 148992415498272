import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'

import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { makeStyles, styled } from '@material-ui/core/styles'

import { translations } from '../../../config'

const OptionLabel = styled('p')({
  fontSize: 14
})

const OptionMeta = styled('p')(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.grey[800],
  marginTop: 3
}))

const useStyles = makeStyles((theme) => ({
  option: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '& p': {
      margin: 0
    }
  },
  noOptions: {
    fontSize: 14
  }
}))

const filterOptions = createFilterOptions({
  trim: true,
  stringify: (option) => {
    return _.concat([option.label, option.displayName]).join('')
  }
})

const FieldSelector = (props) => {
  const {
    className,
    handleOnChange,
    options,
    value,
    label,
    placeholder,
    disableGrouping,
    disabled
  } = props
  const classes = useStyles()
  const [autocompleteValue, setAutocompleteValue] = useState({})

  const autocompleteOptions = useMemo(() => {
    return _.sortBy(_.map(options, (option) => {

      return {
        label: option.label,
        displayName: option.displayName,
        value: option.id,
        parentDisplayName: option.parentDisplayName || translations('All Fields')
      }
    }), 'parentDisplayName')
  }, [options])

  useEffect(() => {
    const resolvedOption = _.find(autocompleteOptions, { value })
    setAutocompleteValue(resolvedOption)
  }, [value, autocompleteOptions])

  return (
    <div className={className}>
      <Autocomplete
        id='conditions-field-selector'
        classes={classes}
        options={autocompleteOptions}
        size={'small'}
        value={autocompleteValue}
        groupBy={(option) => !disableGrouping && _.toUpper(_.get(option, 'parentDisplayName', ''))}
        autoComplete
        autoHighlight
        fullWidth
        selectOnFocus
        disableClearable
        disabled={disabled}
        filterOptions={filterOptions}
        onChange={(event, { value: newValue } = {}) => {
          handleOnChange(newValue || '')
        }}
        getOptionSelected={(option, currentValue) => {
          return _.isEqual(_.get(option, 'value'), _.get(currentValue, 'value'))
        }}
        getOptionLabel={(option) => {
          const keys = ['displayName', 'label', 'value', 'props.displayName', 'props.label', 'props.value', 'id']
          for (const key of keys) {
            const value = _.get(option, key)
            if (value) {
              return value
            }
          }
        }}
        renderOption={(option) => {
          const { label, displayName } = option
          return (
            <>
              <OptionLabel variant={'body1'}>{displayName || label}</OptionLabel>
              {_.isString(displayName) && <OptionMeta variant={'caption'}>{label}</OptionMeta>}
            </>
          )
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label || translations('Condition Builder - Field selector')}
              placeholder={placeholder || translations('Condition Builder - Field selector placeholder')}
              size={'small'}
              disabled={disabled}
              InputLabelProps={{
                shrink: true
              }}
            />
          )
        }}
      />
    </div>
  )
}

export default FieldSelector
