import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { translations } from '../../../../config'

import { push } from 'connected-react-router'

import { styled } from '@material-ui/core/styles'
import { getSelectedUserId } from '../../../../store/modules/userDetails/selectors'
import { useAbilities } from '../../../../ability/ability-context'

import ContentBox from '../../../../components/ContentBox'
import ContentBoxHeader from '../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../components/Form/TextContent'
import RightButtonContent from '../../../../components/Form/RightButtonContent'
import Heading from '../../../../components/Heading'
import digitalStoreSdk from '../../../../digitalStoreSdk'
import Button from '../../../../components/Button'
import Loader from '../../../../components/Loader'


const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridGap: '10px',
  gridTemplateRows: 'auto',
  ['@media (min-width: 700px)']: {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  width: '100%'
}))

const Column = styled('div')(({ theme }) => ({
  padding: '20px 10px',
  border: '1px solid #A8A8A8',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  textAlign: 'center',
  flexShrink: 0,
  gridColumn: 'span 1'
}))

const StatusCount = styled('div')(({ theme }) => ({
  width: '100%',
  fontSize: '60px',
  paddingBottom: '10px',
  ['@media (min-width: 700px) and (max-width: 900px)']: {
    fontSize: '40px'
  }
}))

const StatusText = styled('div')(({ theme }) => ({
  textWrap: 'nowrap'
}))


const RightButtonGridItem = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end'
  },
  alignItems: 'center',
  width: '100%'
}))


const ViewButton = styled(Button)(({ theme }) => ({
  width: '80%',
  [theme.breakpoints.up('sm')]: {
    width: '120px',
    marginBottom: '0'
  },
  marginBottom: '25px'
}))

const ReportSummary = () => {
  const dispatch = useDispatch()
  const selectedUserId = useSelector(getSelectedUserId)

  const ability = useAbilities()
  const isPermitted = ability.can('view', 'caseManagement')

  const [reportSummary, setReportSummary] = useState(null)

  useEffect(async () => {
    if (!_.isEmpty(selectedUserId)) {
      const userSubmissionReportSummary = await digitalStoreSdk.users.fetchUserSubmissionReportSummary({ userId: selectedUserId })
      setReportSummary(userSubmissionReportSummary.results)
    }
  }, [selectedUserId])

  const handleClickViewBtn = () => {
    dispatch(push(`/transmission-management?userId=${selectedUserId}`))
  }

  return (
    <ContentBox>
      <ContentBoxHeader xsRight={12}>
        <TextContent>
          <Heading >
            {translations('Case Management - Report summary')}
          </Heading>
        </TextContent>
        {isPermitted && <RightButtonContent>
          <RightButtonGridItem>
            <ViewButton
              onClick={() => handleClickViewBtn()}
              children={translations('View')}
              color='primary'
            />
          </RightButtonGridItem>
        </RightButtonContent>}
      </ContentBoxHeader>
      <ContentBoxBody>
        {!reportSummary ? <Loader /> : 
          <Grid>
            <Column><StatusCount>{_.get(reportSummary, 'summary.SUCCESS.subTotal', 0)}</StatusCount><StatusText>{translations('Case Management Status - SUCCESS')}</StatusText></Column>
            <Column><StatusCount>{_.get(reportSummary, 'summary.PENDING.subTotal', 0)}</StatusCount><StatusText>{translations('Case Management Status - PENDING')}</StatusText></Column>
            <Column><StatusCount>{_.get(reportSummary, 'summary.FAILED.subTotal', 0)}</StatusCount><StatusText>{translations('Case Management Status - FAILED')}</StatusText></Column>
            <Column><StatusCount>{_.get(reportSummary, 'summary.TIMED_OUT.subTotal', 0)}</StatusCount><StatusText>{translations('Case Management Status - TIMED_OUT')}</StatusText></Column>
            <Column><StatusCount>{_.get(reportSummary, 'summary.NOT_SENT.subTotal', 0)}</StatusCount><StatusText>{translations('Case Management Status - NOT_SENT')}</StatusText></Column>
            <Column><StatusCount>{_.get(reportSummary, 'summary.IN_REVIEW.subTotal', 0)}</StatusCount><StatusText>{translations('Case Management Status - IN_REVIEW')}</StatusText></Column>
            <Column><StatusCount>{_.get(reportSummary, 'summary.REJECTED.subTotal', 0)}</StatusCount><StatusText>{translations('Case Management Status - REJECTED')}</StatusText></Column>
          </Grid>
        }
      </ContentBoxBody>
    </ContentBox>
  )
}

export default ReportSummary