import { useEffect } from 'react'

import modalService from '../../services/modalService'
import { translations } from '../../config'
import { showValidationSummary } from '../CaseManagement/CaseSearchScreen/CaseSearchScreen/utils'

export const useExistingReportErrors = (existingReport) => {
  useEffect(() => {
    const hasValidationErrors = existingReport?.validationErrors?.length

    if (hasValidationErrors) {
      showValidationSummary()({ validationStatus: 'INVALID', validationErrors: existingReport.validationErrors })
    }

    if (existingReport && !hasValidationErrors) {
      modalService.action({
        title: translations('Load Report Failure'),
        text: translations(existingReport.errorCode || 'Error - Load Report Generic'),
        actions: [
          {
            text: translations('Okay'),
            primary: true
          }
        ]
      })
    }
  }, [existingReport])
}