import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Form, { FormBody } from '../../../../../components/Form'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import { translations } from '../../../../../config'
import { uniqueNameValidator } from '../../helpers'

import Button from '../../../../../components/Button'
import styles from '../style'

const AddQuestionToForm = Form('AddQuestionToForm')

const AddQuestionModal = ({
  classes,
  sectionNames,
  closeModal,
  questionNames,
  heading,
  handleSubmit
}) => {
  return (
    <div className={classes.listContainer}>
      <h3>
        {translations(heading ? 'Add Heading Title' : 'Add Question Title')}
      </h3>
      <AddQuestionToForm onSubmit={handleSubmit}>
        <ContentBoxBody>
          <FormBody
            schema={[
              {
                id: 'newfieldname',
                field: 'Input',
                props: {
                  name: 'newfieldname',
                  shrink: true,
                  label: heading ? 'Heading' : 'Question',
                  validate: [uniqueNameValidator(questionNames)],
                  required: true
                }
              },
              {
                id: 'newfieldvisible',
                field: 'Checkbox',
                props: {
                  name: 'newfieldvisible',
                  label: 'Is visible in report?'
                }
              },
              {
                id: 'newquestionmultisection',
                field: 'Dropdown',
                props: {
                  name: 'newquestionmultisection',
                  shrink: true,
                  label: translations(
                    heading
                      ? 'Select Section for Heading'
                      : 'Select Section For Question'
                  ),
                  required: true,
                  options: sectionNames()
                }
              }
            ]}
            editing
            fullWidthFields
          />
        </ContentBoxBody>
        <div className={classes.buttonContainer}>
          <Button raised buttonType='white' onClick={() => closeModal()}>
            {translations('Cancel')}
          </Button>
          <Button raised buttonType='primary' type='submit'>
            {translations('Confirm')}
          </Button>
        </div>
      </AddQuestionToForm>
    </div>
  )
}

AddQuestionModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func,
  sectionNames: PropTypes.func,
  questionNames: PropTypes.func,
  heading: PropTypes.bool,
  handleSubmit: PropTypes.func
}

export default withStyles(styles)(AddQuestionModal)
