import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import { styled } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Heading from '../../../../components/Heading'

import { translations } from '../../../../config'
import { formSchemaNameMatrix } from '../../../../helpers'

import {
  hooks as reportConfigurationHooks,
  selectors as reportConfigurationSelectors
} from '../../../../store/modules/reportConfiguration'

const dateTimeFormat = 'DD/MM/YYYY, HH:mm:ss'

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))
const Header = styled('div')(({ theme }) => ({
  paddingBottom: '5px'
}))
const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))

const Block = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(3)
}))

const GeneralPanel = () => {
  const { id: formViewId } = useParams()
  const isNewFormView = formViewId === 'new'
  const { type, updatedAt, createdAt, archivedAt, versionId, formViewType } = isNewFormView
    ? reportConfigurationHooks.useFormViewEditing()
    : reportConfigurationHooks.useFormViewMetaData(formViewId)
  const usage = !isNewFormView ? reportConfigurationHooks.useFormViewUsage(formViewId) : reportConfigurationSelectors.defaultUsage

  const formattedUpdated = updatedAt ? moment(updatedAt).format(dateTimeFormat) : translations('unsaved')
  const formattedCreated = createdAt ? moment(createdAt).format(dateTimeFormat) : translations('unsaved')
  const formattedArchived = archivedAt && moment(archivedAt).format(dateTimeFormat)
  const friendlyName = formSchemaNameMatrix.getLabel(type)
  const friendlyType = formViewType === 'REPORT' ? 'Report' : 'Follow Up'
  const usageBreakpoints = [7, 30, 90]
  return (
    <Wrapper>
      <Block>
        <Header>
          <Heading variant='h2' component='h2'>{translations('Details')}</Heading>
        </Header>
        <Content>
          <Typography variant={'body2'} color={'textSecondary'}>Version Number: {versionId}</Typography>
          <Typography variant={'body2'} color={'textSecondary'}>Type: {friendlyName}</Typography>
          <Typography variant={'body2'} color={'textSecondary'}>Form View Type: {friendlyType}</Typography>
          <Typography variant={'body2'} color={'textSecondary'}>Updated At: {formattedUpdated}</Typography>
          <Typography variant={'body2'} color={'textSecondary'}>Created At: {formattedCreated}</Typography>
          {archivedAt && <Typography variant={'body2'} color={'textSecondary'}>Archived At: {formattedArchived}</Typography>}
        </Content>
      </Block>
      <Block>
        <Header>
          <Heading variant='h2' component='h2'>{translations('Usage Stats')}</Heading>
        </Header>
        {
          _.map(usageBreakpoints, (days) => {
            const key = `last${days}Days`
            const count = _.get(usage, key, 0)
            return <Typography variant={'body2'} color={'textSecondary'} >{translations(`Report Configuration - Usage lastNDays`, { days, count })}</Typography>
          })
        }
      </Block>
    </Wrapper>
  )
}

export default GeneralPanel
