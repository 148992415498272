import { connect } from 'react-redux'
import SetPassword from './SetPassword'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {

  return {
    ...ownProps,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SetPassword)