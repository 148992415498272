import {
  getIsLoading,
  getInvitedUsers,
  getTotalResults,
  getHasBeenSearched,
  getError,
  getHasMore,
  getParams
} from './selectors'

import { useShallowEqualSelector } from '../../../hooks'

export const useIsLoadingInvitedUsers = () => {
  return useShallowEqualSelector(getIsLoading)
}

export const useInvitedUsers = () => {
  return useShallowEqualSelector(getInvitedUsers)
}

export const useTotalInvitedUsers = () => {
  return useShallowEqualSelector(getTotalResults)
}

export const useHasBeenSearched = () => {
  return useShallowEqualSelector(getHasBeenSearched)
}

export const useInvitedUserError = () => {
  return useShallowEqualSelector(getError)
}

export const useHasMore = () => {
  return useShallowEqualSelector(getHasMore)
}

export const useParams = () => {
  return useShallowEqualSelector(getParams)
}
