import React from 'react'
import _ from 'lodash'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/styles'

import { translations } from '../../../../config'
import Dropdown from '../../../../components/Fields/Dropdown'

import styles from './style'

const inviteStatuses = ['PENDING', 'REJECTED', 'ACCEPTED']

const InvitedUsersFilters = ({ classes, filters, searchUsers }) => {
  const handleChangeStatus = (event) => {
    searchUsers({ filters: { statuses: event.target.value } })
  }
  return (
    <div className={classes.container}>
      <Grid container spacing={2} justify={'center'} className={classes.filterBlock}>
        <Grid item xs={12} md={2} className={classes.leftContent}>
          <FormControl className={classes.formControl}>
            <Dropdown
              label={translations('Invited Users - Status')}
              multiple
              value={filters.statuses}
              options={inviteStatuses}
              onChange={handleChangeStatus}
              passedProps={{ multiple: true }}
              noErrorTextLabel
              shrink
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )

}

export default withStyles(styles)(InvitedUsersFilters)
