import { selectReportConstants as consts } from "./constants"
import digitalStoreSdk from '../../../digitalStoreSdk'
import _ from "lodash"

const {
  SELECT_REPORT_TYPE,
  SELECT_ORGANISATION,
  CLEAR_ORGANISATION,
  
  SET_PRODUCTS,
  CLEAR_PRODUCTS,
  SELECT_PRODUCT,
  CLEAR_PRODUCT,

  MIN_SEARCH_LENGTH,
  MIR_SHORT_CODE,
  FSCA_SHORT_CODE,
  MIR_REPORT_NAME,
  FSCA_REPORT_NAME
} = consts

export const selectReport = dispatch => ( selectedReport ) => {
  if(!selectedReport) {
    dispatch({ type: CLEAR_ORGANISATION })
    dispatch({ type: CLEAR_PRODUCT })
    dispatch({ type: CLEAR_PRODUCTS })
    dispatch({ type: SELECT_REPORT_TYPE, payload: { selectedReport: undefined, hasSelectedMirOrFsca: false } })
    return
  }
  const hasSelectedMirOrFsca = 
    _.get(selectedReport, 'formSchema.shortCode') === MIR_SHORT_CODE ||
    _.get(selectedReport, 'formSchema.shortCode') === FSCA_SHORT_CODE || 
    _.get(selectedReport, 'name') === MIR_REPORT_NAME || 
    _.get(selectedReport, 'name') === FSCA_REPORT_NAME

  if(!hasSelectedMirOrFsca) {
    dispatch({ type: CLEAR_ORGANISATION })
    dispatch({ type: CLEAR_PRODUCT })
  }
  dispatch({ type: SELECT_REPORT_TYPE, payload: { selectedReport, hasSelectedMirOrFsca } })
}

export const selectOrganisation = dispatch => ( selectedOrganisation ) => {
  dispatch({ type: CLEAR_PRODUCT })
  dispatch({ type: CLEAR_PRODUCTS })
  dispatch({ type: SELECT_ORGANISATION, payload: { selectedOrganisation } })
}

export const getProducts = dispatch => async (search) => {
  dispatch({ type: CLEAR_PRODUCT })

  const searchParams = {
    size: 50,
    languageCode: 'en',
    query: search
  }

  if(search.length >= MIN_SEARCH_LENGTH) {
    const appianProducts = await digitalStoreSdk.appianProducts.fetchProducts(searchParams)
    const productOptions = mapProductsToOptions(appianProducts)
    dispatch({ type: SET_PRODUCTS, payload: { products: appianProducts, productOptions } })
  } else {
    dispatch({ type: CLEAR_PRODUCTS })
  }
}

export const selectProduct = dispatch => ( selectedProduct ) => {
  dispatch({ type: SELECT_PRODUCT, payload: { selectedProduct }})
}

const mapProductsToOptions = (products) => {
  return _.map(products, product => {
    return {
      label: product.displayName,
      value: product.id
    }
  })
}